<template>
  <div class="audio-recorder">
    <audio-recorder
      :before-recording="callback"
      :pause-recording="callback"
      :after-recording="callback"
    > </audio-recorder>
  </div>
</template>

<script>
import Vue from 'vue';
import AudioRecorder from 'vue-audio-recorder';
import '/public/css/vue-audio-recorder.scss';
Vue.use(AudioRecorder);

export default {
  name: "VoiceMessageRecorder",
  props: {
    recording_check: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    recording_check() {
      if (this.recording_check) {
        this.startRecording()
      } else if (!this.recording_check) {
        this.stopRecordingAndGetAudioObject()
      }
    }
  },
  methods: {
    callback (data) {
      if (data && data.url) {
        this.$emit('getAudiUrl', data)
      }
    },
    startRecording() {
      $( ".ar-icon__lg" ).click();
    },
    stopRecordingAndGetAudioObject() {
      $(".ar-icon--rec").click();
      $( ".ar-recorder__stop" ).click();
    },
  }
};
</script>

<style scoped>

</style>
