import {
  myFavoritesEvents,
  myInvitesEvents,
  declineInvite,
  myTickets,
  getSingleOrder,
  sendTicketViaEmail,
  getSimilarEvent,
} from "../../apis/APIs";

const INITIAL_STATE = {
  myFavoritesGoingEventList: { data: [], total: 0 },
  myFavoritesInteresedEventList: { data: [], total: 0 },
  myInvitesEventList: "",
  myTicketsUpcomingList: { data: [], total: 0 },
  myTicketsPastList: { data: [], total: 0 },
  detail_Single_Tikcet: "",
  similar_events: { data: [] },
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async myFavoritesEvents({ commit }, payload) {
    let { data } = await myFavoritesEvents(payload);
    if (payload.value === "going") {
      commit("set_my_favorites_going_event_list", data.information);
    } else if (payload.value === "interested") {
      commit("set_my_favorites_interested_event_list", data.information);
    }
  },
  async myInvitesEvents({ commit }, limit) {
    let { data } = await myInvitesEvents(limit);
    commit("set_my_invites_event_list", data.information);
  },
  async declineInvite({ commit }, invite_id) {
    await declineInvite(invite_id);
  },
  async myTickets({ commit }, payload) {
    let { data } = await myTickets(payload);
    if (payload.value === "past") {
      commit("set_my_Tickets_Past_List", data.information);
    } else if (payload.value === "upcoming") {
      commit("set_my_Tickets_Upcoming_List", data.information);
    }
  },
  async getSingleTicket({ commit }, params) {
    let { data } = await getSingleOrder(params);
    commit("set_single_ticket", data.information);
  },
  async sendTicketViaEmail({}, payload) {
    await sendTicketViaEmail(payload.ticket_id, { email: payload.email });
  },
  async getSimilar_event({ commit }, payload) {
    let { data } = await getSimilarEvent(payload);
    commit("set_similarEvent_events", data.information);
  },
};

export const mutations = {
  set_similarEvent_events(state, payload) {
    state.similar_events = payload;
  },
  set_my_favorites_going_event_list(state, payload) {
    state.myFavoritesGoingEventList = payload;
  },
  set_my_favorites_interested_event_list(state, payload) {
    state.myFavoritesInteresedEventList = payload;
  },
  set_my_invites_event_list(state, payload) {
    state.myInvitesEventList = payload;
  },
  set_my_Tickets_Past_List(state, payload) {
    state.myTicketsPastList = payload;
  },
  set_my_Tickets_Upcoming_List(state, payload) {
    state.myTicketsUpcomingList = payload;
  },
  set_single_ticket(state, payload) {
    state.detail_Single_Tikcet = payload;
  },
};

const getters = {
  GET_SIMILAR_EVENTS(state) {
    return state.similar_events;
  },
  GET_MY_FAVORITES_GOING_EVENT_LIST(state) {
    return state.myFavoritesGoingEventList;
  },
  GET_MY_FAVORITES_INTERESTED_EVENT_LIST(state) {
    return state.myFavoritesInteresedEventList;
  },
  GET_MY_INVITES_EVENT_LIST(state) {
    return state.myInvitesEventList;
  },
  GET_MY_TICKETS_PAST_LIST(state) {
    return state.myTicketsPastList;
  },
  GET_MY_TICKETS_UPCOMING_LIST(state) {
    return state.myTicketsUpcomingList;
  },
  GET_SINGLE_TICKET(state) {
    return state.detail_Single_Tikcet;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
