<template>
  <div class="Signup_pop_overlay">
    <div class="wr_my_wallet_modals">
      <div class="wr_title">
        <div>
          <div
            class="ic_prev_close"
            @click="$parent.openCloseModals({modal:'my_wallet'})"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 1L2 6L7 11" stroke="#47505B" stroke-width="1.5" />
            </svg>
          </div>
          <div class="title">Top Up Wallet</div>
        </div>
        <div
          class="ic_prev_close"
          @click="$parent.openCloseModals({modal:'top_Up_Wallet'})"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="wr_content">
        <span>Top Up Amount</span>
        <div class="div_amount">
          <input type="number" />
          <div>$</div>
        </div>
        <span>Payment Method</span>
        <div @click.stop="open_sel_pay_methods" class="wr_pay_method">
          <div class="d-flex">
            <div class="ic_Payment_Method">
              <img
                :src="
                  require(`@/assets/icons/ic_Payment_Method/${pay_method.img}`)
                "
                alt=""
              />
            </div>
            {{ pay_method.name }}
          </div>
          <div :class="{ arrow: select_pay_methods }">
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#D1433A"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="wr_sel_pay_methode" v-if="select_pay_methods">
            <div
              v-for="item in pay_methods_data"
              :key="item.name"
              class="d-flex one_method"
              @click.stop="chnage_pay_methods(item)"
            >
              <div class="ic_Payment_Method">
                <img
                  :src="require(`@/assets/icons/ic_Payment_Method/${item.img}`)"
                  alt=""
                />
              </div>
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="wr_btn">
        <button
          @click="$parent.openCloseModals({modal:'top_Up_Wallet'})"
          class="cancel"
        >
          CANCEL
        </button>
        <button class="confirm" @click="$parent.openCloseModals({modal:pay_method.type,type:pay_method.name})">TOP UP WALLET</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select_pay_methods: false,
      pay_method: { id: 1, name: "Credit Card", img: "Credit_Card.svg",type:'cart' },
      pay_methods_data: [
        { id: 1, name: "Credit Card", img: "Credit_Card.svg",type:'cart' },
        { id: 2, name: "PayPal", img: "PayPal.svg",type:'payPalStripeAliPay' },
        { id: 3, name: "Stripe", img: "Stripe.svg",type:'payPalStripeAliPay' },
        { id: 4, name: "AliPay", img: "AliPay.svg",type:'payPalStripeAliPay' },
        { id: 5, name: "Bank Transfer", img: "Bank_Transfer.svg",type:'bankTransfer' },
      ],
    };
  },
  methods: {
    open_sel_pay_methods() {
      this.select_pay_methods = !this.select_pay_methods;
    },
    chnage_pay_methods(method) {
      this.pay_method = method;
      this.select_pay_methods = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_content {
  margin: 30px 0;
  span {
    color: #47505b;
    font-weight: 700;
    font-size: 13px;
    font-family: HelveticaNeueBold;
    margin-bottom: 7px;
    display: block;
  }
  .div_amount {
    display: flex;
    height: 30px;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    justify-content: space-between;
    margin-bottom: 17px;
    input {
      width: calc(100% - 40px);
      height: 100%;
      border: none;
      outline: none;
      border-radius: 4px;
      padding-left: 10px;
      font-family: HelveticaNeueNormal;
      font-weight: 400;
      font-size: 13px;
      color: #47505b;
    }
    div {
      width: 40px;
      height: 100%;
      border-radius: 0px 4px 4px 0px;
      background: #e6eaf0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #47505b;
      opacity: 0.7;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 13px;
    }
  }
  .wr_pay_method {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    padding: 0 14px 0 20px;
    height: 30px;
    color: #47505b;
    font-family: HelveticaNeueBold;
    font-weight: 700;
    font-size: 12px;
    transition: all 0.3s;
    svg {
      transition: all 0.3s;
    }
    .arrow {
      svg {
        transition: all 0.3s;
        transform: rotate(180deg);
      }
    }
    .ic_Payment_Method {
      //   width: 14px;
      margin-right: 15px;
      img {
        width: 100%;
      }
    }
    .wr_sel_pay_methode {
      width: 100.7%;
      position: absolute;
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      background: #fff;
      padding: 10px 0px;
      left: -1px;
      top: 30px;
      .one_method {
        height: 30px;
        cursor: pointer;
        padding: 0 20px;
        display: flex;
        align-items: center;
        &:hover {
          background: #D1433A1A;
        }
      }
    }
  }
}
</style>