import Vue from "vue";
import ListOptionStore from "@/components/Common/Elements/ListOptionStore.vue";
import { VEmojiPicker } from "v-emoji-picker";
Vue.component("ListOptionStore", ListOptionStore);
Vue.component("EmojiPicker", VEmojiPicker);
Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});
