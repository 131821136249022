<template>
  <div id="app">
    <PlaylistModal />
    <router-view />
    <full-page-loader :show="loading" class="position-on-top" />
    <ListOptionStore />
    <notifications group="foo" class="notification-style" />
    <NewChat />
    <SingleChat />
    <GroupChat />
    <MainLaunchRoom v-if="get_active_launch_Room" />
    <ChatAttachmentModal />
    <CreateGroupModal />
    <div
      class="modal create-group fade show p-0"
      id="create-event-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style="display: none"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        style="margin: auto; min-width: 770px; top: auto"
      >
        <EventPostContainer />
      </div>
    </div>
    <VueNotificationToast />
  </div>
</template>
<script>
import PlaylistModal from "@/components/Common/UiList/CreatePlayList1.vue";
import SingleChat from "./components/Chat/SingleChat";
import GroupChat from "./components/Chat/GroupChat";
import CreateGroupModal from "./components/Chat/CreateGroupModal";
import NewChat from "./components/Chat/NewChat";
import ChatAttachmentModal from "./components/FullViewChat/ChatAttachmentModal";
import MainLaunchRoom from "./components/FullViewChat/Components/launchRoom/MainLaunchRoom";
import EventPostContainer from "./components/Events/components/CreateEvents/EventPostContainer";
import VueNotificationToast from "./components/Common/NotificationToast/VueNotificationToast";
export default {
  name: "launchLoader",
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
    get_active_launch_Room() {
      return this.$store.getters.get_active_launch_Room;
    },
    getCreateEventModalCheck() {
      return this.$store.getters.getCreateEventModalCheck;
    },
  },
  components: {
    PlaylistModal,
    SingleChat,
    GroupChat,
    MainLaunchRoom,
    NewChat,
    ChatAttachmentModal,
    CreateGroupModal,
    EventPostContainer,
    VueNotificationToast,
  },
  watch: {
    $route() {
      this.$store.dispatch("loading", false);
      if (this.$route.name !== null && this.$route.name !== "EmbedComponent") {
        this.$store.dispatch("getArticleCategories");
        this.$store.dispatch("getPostTags");
      }
    },
  },
  mounted() {
    $(".FB_reactions").facebookReactions();
  },
};
</script>

<style scoped lang="scss">
.position-on-top {
  z-index: +9999999999999;
}
.notification-style {
  margin-top: 5rem;
}
</style>

<style lang='scss'>
.mx-datepicker-main {
  border-radius: 4px;
  border: none;
}
.mx-btn-icon-left,
.mx-btn-icon-right {
  border-radius: 4px !important;
  display: flex;
  align-items: center;
}
.menu {
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  background: #e6eaf0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #D1433A1A;
  }
  .menu_modal {
    top: 30px;
    left: 0;
    z-index: 12;
    width: 210px;
    position: absolute;
    box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
    border-radius: 4px;
    background: #ffffff;
    padding: 7px 0;
    span {
      position: relative;
      z-index: -1;
      rotate: (25deg);
      span {
        position: absolute;
        width: 14px;
        height: 14px;
        background: #ffffff;
        top: -8px;
        left: 8.5px;
        -ms-transform: skewY(45deg);
        transform: skewY(45deg);
      }
    }
    ul {
      padding: unset;
      list-style-type: none;
      margin: unset;
      li {
        z-index: 10;
        cursor: pointer;
        padding: 10px 26px;
        display: flex !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        font-family: HelveticaNeueMedium !important;
        color: #071526 !important;
        letter-spacing: 0 !important;
        margin-top: 0 !important;
        svg {
          margin-right: 10px;
        }
        &:hover {
          background-color: #D1433A1A;
        }
      }
    }
  }
}
.create-event-btn {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  box-shadow: 0px 15px 40px rgba(71, 80, 91, 0.05);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  transition: all 0.3s;
  :hover {
    transition: all 0.3s;
    background: #D1433A;
    color: #ffffff;
    svg {
      path {
        fill: #ffffff;       
      }
    }
  }
  svg {
    margin-right: 8px;
  }
  button {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1.5px solid #D1433A;
    color: #D1433A;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
    background: #ffffff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
