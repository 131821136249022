<template>
  <!-- Feelings/Activity-->
  <div>
  <div class="post-filed-options-c" v-if="!selectedTab" :style="is_edit_post ? 'border-bottom:' + 'none' : ''">
    <div v-if="!selectedActivity" class="upload-photos-section mt-3">
      <div class="row">
        <div class="col">
          <div class="upload-photos-title">
            <div class="back-photo-icon" @click="goBack">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 9L1 5L5 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="bac-text-tite">Feeleings / Activity</div>
          </div>
<!--          <div class="hows-feeling"  v-if="!is_edit_post">-->
<!--                        <span class="fealing-div" @click="clearFeeling()">-->
<!--                            <img :src="selectedFeeling.artwork_url"/>-->
<!--                          &lt;!&ndash;                             <p>{{ selectedFeeling.title }}</p>&ndash;&gt;-->
<!--                        </span>-->
<!--            <input type="text" v-model="feeling " name="create_post_form_feeling_text" :disabled="true"-->
<!--                   :placeholder="`How are you ${selectedActivity.title} ?`"/>-->
<!--          </div>-->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="doing-detail">
            <div class="doing-drop-down">
              <ul class="pt-2">
                <li v-for="(eachOp, index) in activities" :key="'activity'+index" @click="selectActivity(eachOp)">
                  <div class="drop-detail">
                    <img :src="eachOp.artwork_url"/>
                    <span>{{ eachOp.title }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="upload-photos-section mt-3">
      <div class="row">
        <div class="col">
          <div class="upload-photos-title">
            <div class="back-photo-icon" @click="selectedActivity=null">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 9L1 5L5 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="bac-text-tite">Feeleings / Activity</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="doing-detail">
            <div class="hows-feeling" v-if="!selectedFeeling">
                        <span class="fealing-div" @click="selectedActivity=null">
                            <img :src="selectedActivity.artwork_url"/>
                            <p>{{ selectedActivity.title }}</p>
                        </span>
              <span class="fealing-div" v-if="selected_feeling && selected_feeling.html" v-html="selected_feeling.html">
                        </span>
              <input type="text" v-model="feeling" name="create_post_form_feeling_text"
                     :placeholder="`What are you ${selectedActivity.title} ?`"/>
            </div>
            <div class="hows-feeling" v-else>
                        <span class="fealing-div" @click="clearFeeling()">
                            <img :src="selectedFeeling.artwork_url"/>
                            <!-- <p>{{ selectedFeeling.title }}</p> -->
                        </span>
              <input type="text" v-model="feeling " name="create_post_form_feeling_text" :disabled="true"
                     :placeholder="`How are you ${selectedActivity.title} ?`"/>
            </div>
            <div class="doing-drop-down" v-if="selectedActivity && selectedActivity.emoticons && selectedActivity.emoticons.length > 0">
              <ul class="pt-2">
                <li v-for="(eachOp, index) in selectedActivity.emoticons" :key="'feeling'+index"
                    @click="selectFeeling(eachOp)">
                  <div class="drop-detail">
                    <img :src="eachOp.artwork_url"/>
                    <span>{{ eachOp.title }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="row" v-if="!is_edit_post">
          <div class="col">
            <div class="Sponsors-section upload-photo-sponsers">
              <div class="action-block ">
                <div class="switch-button">
                  <label class="switch "> <input type="checkbox" checked="checked" class="default"> <span
                      class="slider round"></span> </label>
                </div>
                For All Users
              </div>
              <div class="select-audience-button" v-if="!selectedTab" @click="selectedTab = 'selectAudience'">
                <span v-if="!audience"> Choose Audience</span>
                <span v-if="audience"> {{audience.toUpperCase().replace(/_/g, ' ')}}</span>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
      <SelectAudience @goBackToPostMain="goBackToPostMain"  :selected-tab="audience"/>
  </div>
</div>
  <!-- Exit -->

</template>
<script>

import {mapGetters, mapMutations} from 'vuex';
import {feelings} from '../../../components/utils/feelings';
import {CREATE_POST_FORM_FEELING, CREATE_POST_FORM_FEELING_TEXT} from '../../../store/types.js';
import SelectAudience from './components/selectAudience';
import audience from "../../Events/components/Detail/Audience.vue";

export default {
  name: "FeelingActivity",
  data() {
    return {
      selectedActivity: null,
      selectedFeeling: null,
      feeling: null,
      feelingList: [{
        text: '',
        svg: ''
      }],
      feelings: feelings,
      selected_feeling: undefined,
      selectedTab:"",
      audience:null,
      check:false,
    }
  },
  props: {
    is_edit_post: {
      type: Boolean,
    },
    post: {
      type: Object,
    },
    share_model: {
      type: Boolean,
      default:false
    }
  },
  computed: {
    ...mapGetters({
      form_data: "create_feeling_post_form_map_to_props"
    }),
    activities() {
      return this.$store.getters.getPostsActivities
    },
    feeling_value() {
      const {form_data} = this;

      return form_data && form_data.feeling_value ? form_data.feeling_value : "";
    },
    errors() {
      const {form_data} = this;

      return form_data && form_data.errors && form_data.errors.length ? form_data.errors : [];
    },
    loading() {
      const {form_data} = this;

      return form_data && form_data.loading && form_data.loading ? form_data.loading : false;
    }
  },
  components:{
    SelectAudience
  },
  mounted() {
    this.init();
  },
  watch: {
    feeling(value) {
      if (value)
        this.$store.commit("SET_TYPE_DESCRIPTION", value)
      else
        this.$store.commit("SET_TYPE_DESCRIPTION", null)
    },
    selectedActivity(value) {
      if (value)
        this.$store.commit("SET_TYPE", value.title)
      else
        this.$store.commit("SET_TYPE", null)
    },
    selectedTab(value) {
      if (value) this.hideTopBarAndShowPostBtn();
    }
  },
  methods: {
    ...mapMutations([
      "updateCreatePostFormData"
    ]),
    async init() {
      if(this.is_edit_post){
        this.feeling = this.post && this.post.post_type_description;
        await this.selectActivityAndFeelingEditCase()
      }
    },
    async selectActivityAndFeelingEditCase(){
      const activity = this.activities.filter(e => e.title === this.post.post_type)
      this.selectedActivity = activity[0]
      const feelings = this.selectedActivity.emoticons.filter(i => i.title === this.post.post_type_description)
      this.selectedFeeling = feelings[0]
    },
    goBack() {
      if(this.share_model){
        this.$emit('closeThisModel');
        return
      }
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if(createPostOptionsContainer !== null)
        if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
      this.$parent.goBack();
    },
    openShareModal() {
      let element = this.$refs.SharePageModel.$el
      $(element).SharePageModel('show')
    },
    onChange(e) {
      const {name, value} = e.target || {};

      this.updateCreatePostFormData({
        [name]: value
      });
    },
    selectFeeling(feeling) {
      this.selectedFeeling = feeling
      this.feeling = feeling.title
    },
    selectActivity(activity) {
      this.selectedActivity = activity
      this.feeling = null
    },
    clearFeeling() {
      this.selectedFeeling = null
      this.feeling = null
    },
    hideTopBarAndShowPostBtn() {
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      createPostOptionsContainer.style.display = "none";
    },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      this.audience = audience.toLowerCase().replace(/ /g, '_');
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
    }
  }
}
</script>
<style lang="scss" scoped>
.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 40px;
  background: #F3F3F3;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
.hows-feeling{
  input {
    width: 100%;
    height: 40px;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    color: rgba(7, 21, 38, 0.6);
    font-size: 15px;
    font-weight: 500;
    padding-left: 160px;
  }
}
</style>
