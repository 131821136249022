  <template>
  <div class="ag-container">
    <PMSubHeader :current_page_user="(currentPage && currentPage.current_user) ? currentPage.current_user : null" />
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div v-if="memberRequestList && memberRequestList.length > 0" class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/icons/club-member-requests.svg`)" alt="icon">Member Requests
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="row mt_10 main-content-div">
                <div v-if="memberRequestList && memberRequestList.length > 0" class="col-md-12 content-div">
                  <div v-for="(member_request, index) in memberRequestList" :key="index" class="frind_list_box">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper">
                        <img :src="(member_request.user && member_request.user.artwork_url) ? member_request.user.artwork_url : ''" alt="user_image">
                      </div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name"> {{(member_request.user && member_request.user.name) ? member_request.user.name : 'Name'}}</h4>
                      <p class="friend_profile_location">
                        <span class="location_map">
                          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                  fill="#47505B" />
                          </svg>
                        </span>
                        <span class="mr-0">{{(member_request.user && member_request.user.country && member_request.user.country.name) ? member_request.user.country.name : 'Country'}}</span>
                      </p>
                    </div>
                    <div class="btn-div">
                      <div class="btn btn-accept" @click="acceptAndDeclineRequest(1, member_request.id, index)">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                      <div class="btn btn-cross" @click="acceptAndDeclineRequest(0, member_request.id, index)">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="memberRequestList && memberRequestList.length > 9" class="col-md-12">
                <div class="show_more_btn">
                  <div class="show-more musicbar_more"><a href="javascript:;">
                    <span class="show_more_bg">
                      <svg
                        width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                          stroke-linejoin="round">

                        </path>
                        <path d="M1 3.85498H7.48702" stroke="#071526"
                              stroke-width="1.3" stroke-linecap="round"
                              stroke-linejoin="round">
                        </path>
                      </svg>
                      SHOW 10 MORE USERS
                    </span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-content-here-company" :class="{'no-background-color':memberListLoading}">
            <div class="text-center" v-if="memberListLoading">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
            <div v-else class="no-content-here no-event-here">
              <div class="no-detail-content">
                <div class="category-content-icon">
                  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="70" height="70" fill="#D7D7D7"/>
                    <rect width="1920" height="3000" transform="translate(-710 -460)" fill="#F0F2F5"/>
                    <rect x="-350" y="-50" width="770" height="300" rx="5" fill="#E6EAF0"/>
                    <rect x="10" width="70" height="70" fill="#E6EAF0"/>
                    <rect width="70" height="70" fill="#E6EAF0"/>
                    <mask id="path-4-inside-1_14041_28937" fill="white">
                      <path d="M45.0008 15C45.0008 20.5228 40.5236 25 35.0008 25C29.4779 25 25.0008 20.5228 25.0008 15C25.0008 9.47715 29.4779 5 35.0008 5C40.5236 5 45.0008 9.47715 45.0008 15Z"/>
                    </mask>
                    <path d="M45.0008 15C45.0008 20.5228 40.5236 25 35.0008 25C29.4779 25 25.0008 20.5228 25.0008 15C25.0008 9.47715 29.4779 5 35.0008 5C40.5236 5 45.0008 9.47715 45.0008 15Z" stroke="#47505B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-4-inside-1_14041_28937)"/>
                    <mask id="path-5-inside-2_14041_28937" fill="white">
                      <path d="M61.6666 14.9987C61.6666 18.6806 58.6819 21.6654 55 21.6654C51.3181 21.6654 48.3333 18.6806 48.3333 14.9987C48.3333 11.3168 51.3181 8.33203 55 8.33203C58.6819 8.33203 61.6666 11.3168 61.6666 14.9987Z"/>
                    </mask>
                    <path d="M61.6666 14.9987C61.6666 18.6806 58.6819 21.6654 55 21.6654C51.3181 21.6654 48.3333 18.6806 48.3333 14.9987C48.3333 11.3168 51.3181 8.33203 55 8.33203C58.6819 8.33203 61.6666 11.3168 61.6666 14.9987Z" stroke="#47505B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-5-inside-2_14041_28937)"/>
                    <mask id="path-6-inside-3_14041_28937" fill="white">
                      <path d="M21.6659 14.9987C21.6659 18.6806 18.6811 21.6654 14.9992 21.6654C11.3173 21.6654 8.33252 18.6806 8.33252 14.9987C8.33252 11.3168 11.3173 8.33203 14.9992 8.33203C18.6811 8.33203 21.6659 11.3168 21.6659 14.9987Z"/>
                    </mask>
                    <path d="M21.6659 14.9987C21.6659 18.6806 18.6811 21.6654 14.9992 21.6654C11.3173 21.6654 8.33252 18.6806 8.33252 14.9987C8.33252 11.3168 11.3173 8.33203 14.9992 8.33203C18.6811 8.33203 21.6659 11.3168 21.6659 14.9987Z" stroke="#47505B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-6-inside-3_14041_28937)"/>
                    <mask id="path-7-inside-4_14041_28937" fill="white">
                      <path d="M45 28.333H25C23.1591 28.333 21.6667 29.8254 21.6667 31.6663V53.7486C21.6667 53.9157 21.6783 54.0789 21.7116 54.2427C22.0399 55.8599 24.3655 64.9997 35 64.9997C45.6345 64.9997 47.9602 55.8599 48.2884 54.2427C48.3217 54.0789 48.3334 53.9157 48.3334 53.7486V31.6663C48.3334 29.8254 46.841 28.333 45 28.333Z"/>
                    </mask>
                    <path d="M45 28.333H25C23.1591 28.333 21.6667 29.8254 21.6667 31.6663V53.7486C21.6667 53.9157 21.6783 54.0789 21.7116 54.2427C22.0399 55.8599 24.3655 64.9997 35 64.9997C45.6345 64.9997 47.9602 55.8599 48.2884 54.2427C48.3217 54.0789 48.3334 53.9157 48.3334 53.7486V31.6663C48.3334 29.8254 46.841 28.333 45 28.333Z" stroke="#47505B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-7-inside-4_14041_28937)"/>
                    <mask id="path-8-inside-5_14041_28937" fill="white">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 29.333C18.3333 28.7807 17.8856 28.333 17.3333 28.333H8.33333C6.49238 28.333 5 29.8254 5 31.6663V49.0647C5 49.2433 5.01324 49.4178 5.05262 49.592C5.38584 51.0661 7.49373 58.333 16.6667 58.333C17.4115 58.333 18.1097 58.2851 18.7642 58.1963C19.4113 58.1084 19.7354 57.3989 19.4771 56.7992C18.7626 55.1406 18.4647 53.7424 18.3699 53.2196C18.3426 53.0686 18.3333 52.9227 18.3333 52.7693V29.333Z"/>
                    </mask>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 29.333C18.3333 28.7807 17.8856 28.333 17.3333 28.333H8.33333C6.49238 28.333 5 29.8254 5 31.6663V49.0647C5 49.2433 5.01324 49.4178 5.05262 49.592C5.38584 51.0661 7.49373 58.333 16.6667 58.333C17.4115 58.333 18.1097 58.2851 18.7642 58.1963C19.4113 58.1084 19.7354 57.3989 19.4771 56.7992C18.7626 55.1406 18.4647 53.7424 18.3699 53.2196C18.3426 53.0686 18.3333 52.9227 18.3333 52.7693V29.333Z" stroke="#47505B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-8-inside-5_14041_28937)"/>
                    <mask id="path-9-inside-6_14041_28937" fill="white">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M51.6667 29.333C51.6667 28.7807 52.1144 28.333 52.6667 28.333H61.6667C63.5076 28.333 65 29.8254 65 31.6663V49.0647C65 49.2433 64.9868 49.4178 64.9474 49.592C64.6142 51.0661 62.5063 58.333 53.3333 58.333C52.5885 58.333 51.8903 58.2851 51.2358 58.1963C50.5887 58.1084 50.2646 57.3989 50.5229 56.7992C51.2374 55.1406 51.5353 53.7424 51.6301 53.2196C51.6574 53.0686 51.6667 52.9227 51.6667 52.7693V29.333Z"/>
                    </mask>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.6667 29.333C51.6667 28.7807 52.1144 28.333 52.6667 28.333H61.6667C63.5076 28.333 65 29.8254 65 31.6663V49.0647C65 49.2433 64.9868 49.4178 64.9474 49.592C64.6142 51.0661 62.5063 58.333 53.3333 58.333C52.5885 58.333 51.8903 58.2851 51.2358 58.1963C50.5887 58.1084 50.2646 57.3989 50.5229 56.7992C51.2374 55.1406 51.5353 53.7424 51.6301 53.2196C51.6574 53.0686 51.6667 52.9227 51.6667 52.7693V29.333Z" stroke="#47505B" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" mask="url(#path-9-inside-6_14041_28937)"/>
                  </svg>
                </div>
                <div class="no-detail-content-heading">
                  You haven't any member request yet.
                </div>
                <div class="no-detail-content-subheading">
                  Invite member to join page.
                </div>
                <div class="add-new-product-buttons">
                  <button data-toggle="modal"
                          data-target="#create-group"
                          data-backdrop="false">
                    INVITE MEMBER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PMSubHeader from "../PMSubHeader";
import { acceptAndDeclineMemberRequest, getCurrentPageMemberRequests } from "../../../apis/APIs";

export default {
  name: "MemberRequests",
  data() {
    return {
      memberRequestList : [],
      limit: 8,
      offset: 0,
      memberListLoading: false
    }
  },
  components:{
    PMSubHeader,
  },
  computed: {
    currentPage(){
      return this.$store.getters.getCurrentPage
    }
  },
 async mounted() {
    await this.memberRequests()
  },
  methods: {
    async memberRequests() {
      try {
        this.memberListLoading = true
        const payload = {
          page_id: this.$route.params.id,
          showMore: false
        }
        const { data } = await getCurrentPageMemberRequests(payload.page_id, 0, '8');
        const { information } = data || {}
        this.memberRequestList = information.data
        this.memberListLoading = false
      } catch (e) {
        this.memberListLoading = false
        console.error(e)
      }
    },
    async showMore() {
      const payload = {
        page_id: this.$route.params.id,
        showMore: true
      }
      const { data } = await this.$store.dispatch('getCurrentPageMemberRequest', payload);
      this.memberRequestList = data.data
    },
    async acceptAndDeclineRequest (action, id, index) {
      try {
        this.memberListLoading = true
        const payload = {
          response_id: id,
          action: (action === 1) ? 1 : 0
        }
        const { data } = await acceptAndDeclineMemberRequest(payload)
        const {success, message} = data || {}
        if (success === 1) {
          this.memberRequestList.splice(index, 1)
          this.notificationToast(true, 'Member Request', message, 5000, 'success')
        } else {
          this.memberRequestList.splice(index, 1)
          this.notificationToast(true,"Member Request", message, 5000, "error");
        }
        this.memberListLoading = false
      } catch (e) {
        this.memberListLoading = false
        console.error(e)
      }
    }
  }
};
</script>

<style scoped lang="scss">
.main-content-div {
  padding-left: 20px;
  padding-right: 20px;

  .content-div {
    display: contents;

    .frind_list_box {
      width: 350px;
      display: flex;
      margin-bottom: 15px;

      .btn-div {
        margin-left: auto;
        display: flex;
        justify-content: space-evenly;
        width: 70px;

        .btn-accept {
          background: #15CA86;
          border-radius: 4px;
          height: 30px;
          width: 30px;
          display: flex;

          svg {
            margin: auto;
          }
        }

        .btn-cross {
          border: 1px solid #FF0039;
          border-radius: 4px;
          height: 30px;
          width: 30px;
          display: flex;

          svg {
            margin: auto;
          }
        }
      }

      &:nth-child(even) {
        margin-left: 15px;
      }
    }
  }
}
.no-background-color {
  background-color: unset;
}

</style>
