<template>
  <div class="modal custom-modal modalContactInfo d-block">
    <div class="modals Signup_pop_overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Contact Info</h5>
          <button
            @click="$parent.openCloseModals({ modal: 'contactInfo' })"
            type="button"
            class="close"
          >
            <div class="svg-icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L4.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.5 4.5L13.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-row">
              <div class="col">
                <label for="">First Name<span>*</span></label>
                <input
                  type="text"
                  class="form-control text-input"
                  :class="{ required: required.first_name }"
                  placeholder="First name"
                  v-model="GET_CONTACT_INFO.first_name"
                  @input="reqField('first_name')"
                />
              </div>
              <div class="col">
                <label for="">Last Name<span>*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last name"
                  v-model="GET_CONTACT_INFO.last_name"
                  :class="{ required: required.last_name }"
                  @input="reqField('last_name')"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <label>Birth Date<span>*</span></label>
                <div
                  class="date-picker-container"
                  @click.stop="showDatePicker"
                  :class="{ required: required.birthdate }"
                >
                  <p class="mb-0" v-if="GET_CONTACT_INFO.birthdate">
                    {{
                      GET_CONTACT_INFO.birthdate
                        ? isMoment(GET_CONTACT_INFO.birthdate)
                        : contactBirthdate
                    }}
                  </p>
                  <p class="mb-0" style="color: #8b949f" v-else>DD/MM/YYYY</p>
                  <date-picker
                    v-model="GET_CONTACT_INFO.birthdate"
                    type="date"
                    :show-week-number="false"
                    :open="showDate"
                    :confirm="false"
                    :disabled-date="disabledAfterDate"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col w-240">
                <label for="">Country<span>*</span></label>
                <div
                  @click.stop="openCountryList = !openCountryList"
                  class="country"
                  :class="{ required: required.country_name }"
                >
                  <div>{{ GET_CONTACT_INFO.country_name }}</div>
                  <span :class="{ arrow: openCountryList }"
                    ><svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 5L9 1"
                        stroke="#D1433A"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <perfect-scrollbar v-if="openCountryList" class="countryList">
                    <ul>
                      <li
                        @click="selectCountry(item)"
                        v-for="item in $store.getters.getCountries"
                        :key="item.list"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </perfect-scrollbar>
                </div>
              </div>
              <div class="col w-80">
                <label for="">ZIP<span>*</span></label>
                <input
                  type="number"
                  v-model="GET_CONTACT_INFO.zip"
                  :class="{ required: required.zip }"
                  @input="reqField('zip')"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <label for="">Phone Number<span>*</span></label>
                <input
                  type="tel"
                  class="form-control"
                  placeholder="Phone Number"
                  v-model="GET_CONTACT_INFO.phone"
                  :class="{ required: required.phone }"
                  @input="reqField('phone')"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group mb-0">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      class=""
                      id="exampleCheck1"
                      v-model="GET_CONTACT_INFO.is_default"
                    />
                    <label class="" for="exampleCheck1"
                      >Set Contact Info as Default</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-row">
              <button
                @click="$parent.openCloseModals({ modal: 'contactInfo' })"
                class="btn btn-cancel"
              >
                <span>CANCEL</span>
              </button>
              <button @click.stop="addContact" class="btn btn-addcinfo">
                <span>ADD CONTACT INFO</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      contactBirthdate: "",
      myContacts: "",
      openCountryList: false,
      showDate: false,
      required: {
        first_name: false,
        last_name: false,
        birthdate: false,
        country_name: false,
        zip: false,
        phone: false,
      },
    };
  },
  methods: {
    ...mapMutations(["set_comtact_info"]),
    ...mapActions(["addContactInfo"]),
    selectCountry(country) {
      this.required.country = false;
      this.set_comtact_info({
        ...this.GET_CONTACT_INFO,
        country_name: country.name,
        country_id: country.id,
      });
    },
    disabledAfterDate(date) {
      const today = new Date();
      return date > today || date > new Date(today.getTime());
    },
    isMoment(date) {
      this.required.birthdate = false;
      return moment(date).startOf("day").format("Do MMM, YYYY");
    },
    showDatePicker() {
      this.showDate = !this.showDate;
    },
    reqField(payload) {
      for (let i in this.required) {
        if (i === payload) {
          this.required[i] = false;
        }
      }
    },
    async addContact() {
      console.log("this.GET_CONTACT_INFO", this.GET_CONTACT_INFO);
      let addNewcontact = true;
      for (let i in this.GET_CONTACT_INFO) {
        if (!this.GET_CONTACT_INFO[i] && i != "is_default" && i != "country") {
          this.required[i] = true;
          addNewcontact = false;
        }
      }
      if (addNewcontact) {
        await this.$store.dispatch("loading", true);
        await this.addContactInfo(this.GET_CONTACT_INFO);
        await this.$store.dispatch("loading", false);
        this.$parent.openCloseModals({ modal: "contactInfo" });
      }
    },
  },
  computed: {
    ...mapGetters(["GET_CONTACT_INFO"]),
  },
  watch: {
    "GET_CONTACT_INFO.birthdate"() {
      this.showDate = false;
      this.contactBirthdate = this.isMoment(this.GET_CONTACT_INFO.birthdate);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
input::placeholder {
  font-family: HelveticaNeueNormal !important;
}
.btn-addcinfo {
  &:hover {
    background: #DA6961 !important;
  }
}
.date-picker-container {
  border-radius: 4px;
  width: 100%;
  border: 1px solid #e6eaf0;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  p {
    color: #47505b;
  }
}
.country {
  position: relative;
  width: 100%;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  padding-left: 10px;
  font-family: HelveticaNeueMedium;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  .countryList {
    height: 200px;
    z-index: 1;
    position: absolute;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    width: 100.7%;
    padding: 15px 0;
    top: 30px;
    left: -1px;
    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      li {
        padding-left: 14px;
        cursor: pointer;
        &:hover {
          background: #D1433A1A;
        }
      }
    }
  }
  span {
    transition: all 0.3s;
  }
  .arrow {
    transition: all 0.3s;
    transform: rotate(180deg);
  }
}
.required {
  border: 1px solid red !important;
}
</style>