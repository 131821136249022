<template lang="">
    <span>
        <a href="#" @click="submit">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="3.26416" x2="15" y2="3.26416" stroke="#C4CCD6" stroke-width="2" stroke-linecap="round"></line>
                <path d="M5.1765 5.48877L6.11768 12.0182" stroke="#C4CCD6" stroke-linecap="round"></path>
                <path d="M8 5.48877L8 12.0182" stroke="#C4CCD6" stroke-linecap="round"></path>
                <path d="M10.8232 5.48877L9.88207 12.0182" stroke="#C4CCD6" stroke-linecap="round"></path>
                <path d="M2.82324 3.44824H13.1762L12.4629 12.2206C12.3785 13.2589 11.5112 14.0585 10.4695 14.0585H5.52996C4.48824 14.0585 3.62097 13.2589 3.53654 12.2206L2.82324 3.44824Z" stroke="#C4CCD6" stroke-width="1.5"></path>
                <path d="M4.70557 3.44824H11.2938V2.99971C11.2938 1.89514 10.3984 0.999713 9.2938 0.999713H6.70556C5.601 0.999713 4.70557 1.89514 4.70557 2.99971V3.44824Z" stroke="#C4CCD6" stroke-width="1.5"></path>
            </svg>
        </a>
    </span>

</template>
<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import {delete_session} from '../../../../apis/APIs';

export default {
  data() {
    return ({
      loading: false
    })
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    })
  },
  methods: {
    async submit() {
      const {user_data} = this.user || {};
      const {user_id} = user_data || {};
      const {loading} = this;
      const {id} = this.$attrs;

      // const query = `?id=${id}`;

      try {
        this.loading = true
        const res = await delete_session(id);
        this.$parent.submit();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  }
}
</script>
<style lang="">

</style>
