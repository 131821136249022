<template>
  <div>
    <h1 class="title_orders">MY ORDERS</h1>
    <main class="magazine">
      <section>
        <div class="">
          <div class="row">
            <div class="col">
              <div class="breadcrumbs-item">
                <router-link class="back-link" to="/events/page/overview">
                  <div class="svg-icon">
                    <svg
                      width="7"
                      height="14"
                      viewBox="0 0 7 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.86328 12.7285L0.134281 7.00052L5.86328 1.27152"
                        stroke="#071526"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="text-link">OVERVIEW</div>
                </router-link>
                <div class="title">Order #{{GET_MY_ORDER_SINGLE.id}}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
        <div class="">
          <div class="row">
            <div class="col-sm-8">
              <div class="buy-item-body">
                <div class="ticket-info-tabs">
                  <h6>Thank You for Your Order!</h6>
                  <p>
                    Your tickets will be send to your e-mail. You can print it
                    or use and show it from your phone.
                  </p>
                </div>
              </div>
              <OrdersCart
                v-for="item in GET_MY_ORDER_SINGLE.cart"
                :key="item.id"
                :paramsItems="item"
                @openCloseSendEmailOrPhoneModals="
                  openCloseSendEmailOrPhoneModals
                "
                @openCloseContractModal="openCloseContractModal"
              />
              <SendUsersModal
                v-if="sendEmailOrPhone"
                @openCloseSendEmailOrPhoneModals="
                  openCloseSendEmailOrPhoneModals
                "
                :single_cart="single_cart.ticket"
                :modal_title_and_send_type="modal_title_and_send_type"
              />
              <DownloadContract
                v-if="showDownloadContract"
                @openCloseContractModal="openCloseContractModal"
              />
            </div>
            <div class="col-sm-4">
              <div class="sidebar">
                <CartSummary
                  :events_Ticket_Total_Amoun="events_Ticket_Total_Amoun"
                  :paramsOrders="GET_MY_ORDER_SINGLE"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import OrdersCart from "./OrdersCart.vue";
import CartSummary from "./CartSummary.vue";
import SendUsersModal from "./SendUsersModal.vue";
import DownloadContract from "./DownloadContract.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    OrdersCart,
    CartSummary,
    SendUsersModal,
    DownloadContract,
  },
  data() {
    return {
      events_Ticket_Total_Amoun: [],
      sendEmailOrPhone: false,
      showDownloadContract: false,
      single_cart: {},
      modal_title_and_send_type: {},
    };
  },
  methods: {
    ...mapActions(["getMyOrderSingle"]),
    forParamsCartSummary() {
      this.mySelectEventName = this.GET_MY_ORDER_SINGLE.cart
        .map((el) => el.ticket.event.title)
        .filter((v, i, a) => a.indexOf(v) === i);
      this.events_Ticket_Total_Amoun = [];
      for (let i = 0; i < this.mySelectEventName.length; i++) {
        let ticket_Total_Amoun = 0;
        this.GET_MY_ORDER_SINGLE.cart.forEach((el) => {
          if (this.mySelectEventName[i] === el.ticket.event.title) {
            ticket_Total_Amoun += el.ticket.price * el.quantity;
          }
        });
        this.events_Ticket_Total_Amoun.push({
          eventTitle: this.mySelectEventName[i],
          total_amoun: ticket_Total_Amoun,
          id: Date.now() * Math.random(),
        });
      }
    },
    openCloseSendEmailOrPhoneModals(params) {
      this.sendEmailOrPhone = !this.sendEmailOrPhone;
      this.GET_MY_ORDER_SINGLE.cart.forEach((el) => {
        if (el.id === params.ticket_id) {
          this.single_cart = el;
        }
      });
      this.modal_title_and_send_type = params;
    },
    openCloseContractModal() {
      this.showDownloadContract = !this.showDownloadContract;
    },
  },
  computed: {
    ...mapGetters(["GET_MY_ORDER_SINGLE"]),
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.getMyOrderSingle();
    await this.$store.dispatch("loading", false);
    console.log("this.GET_MY_ORDER_SINGLE", this.GET_MY_ORDER_SINGLE);
    this.forParamsCartSummary();
  },
};
</script>

<style lang="scss" scoped>
.title_orders {
  margin: 30px 0 0 0;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  font-family: HelveticaNeueBold;
  color: #000000;
}
.breadcrumbs-item {
  margin-top: 20px !important;
}
.buy-item-body {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 0 0 29px !important;
}
.ticket-info-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h6 {
    color: #071526;
    margin-bottom: 14px;
    font-weight: 700;
    font-size: 16px;
    opacity: 0.9;
    font-family: HelveticaNeueBold;
  }
  p {
    color: #47505b;
    font-weight: 400;
    font-size: 12px;
    font-family: HelveticaNeueNormal;
    margin: 0;
  }
}
.notify-board {
  background: #D1433A1A;
  border: 1px solid #D1433A1A;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.notify-board p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #071526;
  opacity: 0.9;
  padding-left: 20px;
  margin-bottom: 0;
}
.time-icon {
  background: #D1433A;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.terms-conditions {
  display: flex;
  align-items: center;
  padding: 17px 20px !important;
}
.terms-conditions a {
  color: #D1433A;
}
.terms-conditions p {
  margin-bottom: 0;
  margin-left: 25px;
}

// custom checkbox

.container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 14px;
  cursor: pointer;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-family: HelveticaNeueNormal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #47505b;
  width: 100%;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1.3px solid #c4ccd6;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #D1433A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.terms-conditions label {
  margin-top: -8px;
}

.agree-to-terms {
  white-space: nowrap;
}
.terms-and-conditions {
  &:hover {
    text-decoration: underline !important;
  }
}

.magazine .widget.contact-widget select {
  background-image: url(./../../../../../assets/icons/icons/select-arrow.svg);
  background-repeat: no-repeat;
  background-position: 95%;
}
</style>