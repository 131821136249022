<template>
  <div>
    <div class="widget summary-widget">
      <div class="widget-title">Cart Summary</div>
      <div class="card-list">
        <div
          v-for="item in events_Ticket_Total_Amoun"
          :key="item.id"
          class="title"
        >
          {{
            item.eventTitle.length > 30
              ? item.eventTitle.slice(0, 30) + "..."
              : item.eventTitle
          }}
          <span>$ {{ item.total_amoun }}</span>
        </div>
      </div>
      <div v-if="!$route.path.includes('my/orders')">
        <div class="card-list">
          <div class="title">
            Total Gross
            <span
              >$
              {{
                events_Ticket_Total_Amoun.reduce((total, el) => {
                  return total + el.total_amoun;
                }, 0)
              }}</span
            >
          </div>
          <div class="title">
            Discount
            <span class="minus-balance"
              >– $
              {{
                GET_PROMO.information ? GET_PROMO.information.price : 0
              }}</span
            >
          </div>
        </div>
        <div class="total-row">
          <div class="title">
            Total
            <span
              >$
              {{
                events_Ticket_Total_Amoun.reduce((total, el) => {
                  return total + el.total_amoun;
                }, 0) -
                (GET_PROMO.information ? GET_PROMO.information.price : 0)
              }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="$route.path.includes('my/orders')">
        <div class="card-list">
          <div class="title">
            Total Gross
            <span>$ {{ paramsOrders.gross_total }}</span>
          </div>
          <div class="title">
            Discount
            <span class="minus-balance">– $ {{ paramsOrders.discount }}</span>
          </div>
        </div>
        <div class="total-row">
          <div class="title">
            Total
            <span>$ {{ paramsOrders.amount }}</span>
          </div>
        </div>
      </div>
      <div class="promo">
        <a
          v-if="!$route.path.includes('my/orders')"
          @click="promo_modal = !promo_modal"
          >Got a Promocode?</a
        >
      </div>
    </div>
    <div class="wr_promo_code" v-if="promo_modal">
      <span>Add Code</span>
      <div>
        <div style="display: block">
          <input type="text" v-model="promoCode" />
          <p v-if="verifyPromo" class="verifyPromo">
            {{ GET_PROMO.message }}
          </p>
        </div>
        <button @click="sendPromoCode">APPLY</button>
      </div>
    </div>
    <div v-if="$route.path.includes('my/orders')" class="wr_contact_detalis">
      <div class="sections1">
        <h5>Contact & Payment Details</h5>
        <div>
          <span>Paid by</span>
          <p>PayPal</p>
        </div>
        <div style="margin-top: 10px">
          <span>E-mail</span>
          <p style="color: #D1433A">{{ paramsOrders.email }}</p>
        </div>
      </div>
      <div class="sections2">
        <h5>Contact Info</h5>
        <h6>Evgenii Ivanov</h6>
        <span
          >{{
            paramsOrders.contact_info &&
            paramsOrders.contact_info.country_name.slice(0, 20)
          }}
          /
          {{
            paramsOrders.contact_info &&
            paramsOrders.contact_info.birthdate.slice(0, 10)
          }}
          /
          {{
            paramsOrders.contact_info && paramsOrders.contact_info.phone
          }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      promo_modal: false,
      promoCode: "",
      verifyPromo: false,
    };
  },
  props: ["events_Ticket_Total_Amoun", "paramsOrders"],

  methods: {
    ...mapActions(["promoVerification"]),
    async sendPromoCode() {
      await this.$store.dispatch("loading", true);
      await this.promoVerification({
        event_id: 77,
        promoable_type: "Event",
        code: this.promoCode,
      });
      await this.$store.dispatch("loading", false);
      if (this.GET_PROMO.success === 0) {
        this.verifyPromo = true;
      } else {
        this.promo_modal = !this.promo_modal;
        this.verifyPromo = false;
        this.promoCode = "";
      }
    },
  },
  computed: {
    ...mapGetters(["GET_PROMO"]),
  },
};
</script>

<style lang="scss" scoped>
.wr_promo_code {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  margin-bottom: 10px;
  span {
    font-family: HelveticaNeueBold;
    font-size: 16px;
    line-height: 20px;
    color: #071526;
    opacity: 0.9;
  }
  input {
    margin-right: 10px;
    width: 250px;
    height: 40px;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    padding: 0 20px;
    color: #8b949f;
    text-transform: uppercase;
  }
  div {
    display: flex;
  }
  button {
    background: #D1433A;
    border-radius: 4px;
    width: 70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
  }
  .verifyPromo {
    color: red;
    margin: 3px 0 0 0;
  }
}
.promo {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  color: #D1433A !important;
  font-weight: 500;
  font-size: 15px;
  font-family: HelveticaNeueMedium;
}
.wr_contact_detalis {
  .sections1,
  .sections2 {
    margin-top: 10px;
    padding: 24px 20px;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    h5 {
      color: #071526;
      opacity: 0.9;
      font-weight: 700;
      font-size: 16px;
      font-family: HelveticaNeueBold;
      margin-bottom: 20px;
    }
    h6 {
      font-weight: 700;
      font-size: 15px;
      font-family: HelveticaNeueBold;
      color: #47505b;
      margin-bottom: 0;
    }
    div {
      display: flex;
      justify-content: space-between;
      color: #47505b;
    }
    p {
      margin: 0;
      font-weight: 700;
      font-size: 15px;
      font-family: HelveticaNeueBold;
    }
    .sections1:first-child {
      margin-bottom: 10px !important;
    }
    .sections1 {
      span {
        font-weight: 500;
        font-size: 15px;
        font-family: HelveticaNeueMedium;
      }
    }
  }
  .sections2 {
    span {
      color: #8b949f;
      font-weight: 400;
      font-size: 13px;
      font-family: HelveticaNeueNormal;
    }
  }
}
</style>