var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"for_forums_width"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"new-thread-wrapper m-t-30"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(_vm.GET_MAIN_FORUM_THREADS.forum.name))]),_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.goTo({
              active_tab: 'new_thread',
              type: 'forum',
              title: 'New Thread',
            })}}},[_c('div',{staticClass:"btn btn-new-thread"},[_c('div',{staticClass:"svg-icon"},[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"c-line-1",attrs:{"d":"M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{staticClass:"c-line-1",attrs:{"d":"M7 4.66699V9.33366","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{staticClass:"c-line-1",attrs:{"d":"M4.66699 7H9.33366","stroke":"white","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_vm._v(" New Thread ")])])]),_c('div',{staticClass:"for_line"}),_c('div',{staticClass:"forums-item"},[_vm._m(0),_vm._l((_vm.GET_MAIN_FORUM_THREADS.threads.data),function(thread){return _c('div',{key:thread.id,staticClass:"item-content for_height_item-content"},[_c('div',{staticClass:"item-content-row"},[_c('div',{staticClass:"item-desc",staticStyle:{"margin":"unset"}},[_c('div',{staticClass:"item-inner-title"},[_c('a',{on:{"click":function($event){return _vm.goTo({
                      active_tab: 'thread_reply',
                      thread_id: thread.id,
                      title: thread.title,
                    })}}},[_vm._v(_vm._s(thread.title))])]),_c('div',{staticClass:"item-inner-subtitle with-name"},[_vm._v(" by"),_c('a',{on:{"click":function($event){return _vm.redirectWidgetsToProfilePage($event, thread.author_details.username)}}},[_vm._v(_vm._s(thread.author_details.full_name))]),_c('i',[_vm._v(".")]),_vm._v(_vm._s(_vm.dateFormat(thread.created_at))+" "),_c('i',[_vm._v(".")]),_c('span',[_vm._v(_vm._s(thread.views)+" views")])])]),_c('div',{staticClass:"threads ml-auto"},[_c('span'),_vm._v(_vm._s(thread.replies_count)+" ")]),_c('div',{staticClass:"replies"},[_vm._v(" "+_vm._s(_vm.dateFormat(thread.updated_at))+" ")])])])})],2),_c('Pagination',{attrs:{"count":_vm.GET_MAIN_FORUM_THREADS.forum.threads_count,"id":String(_vm.GET_MAIN_FORUM_THREADS.forum.id),"keyId":'forum_id',"actionName":'getForumThreads'}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-head"},[_c('div',{},[_vm._v("Threads")]),_c('div',{staticClass:"ml-auto th-title"},[_vm._v("Replies")]),_c('div',{staticClass:"ls-title"},[_vm._v("Last Post")])])
}]

export { render, staticRenderFns }