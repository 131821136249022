<template>
  <div class="buy-item">
    <div class="item-title">
      {{ paramsItems.ticket.event.title }}
      <span
        >{{ paramsItems.quantity }}/ ${{
          paramsItems.ticket.price * paramsItems.quantity
        }}</span
      >
    </div>
    <div class="buy-item-title">
      <div>ITEM</div>
      <div>COUNT</div>
      <div>PRICE</div>
    </div>
    <div class="buy_item">
      <div class="img-text-wrap">
        <div class="img-wrap">
          <img :src="paramsItems.ticket.event.artwork_url" alt="" />
        </div>
        <div class="text-wrap">
          <div class="title">
            {{
              paramsItems.ticket.name.length > 42
                ? paramsItems.ticket.name.slice(0, 42) + "..."
                : paramsItems.ticket.name
            }}
          </div>
          <div class="subtitle">
            {{
              paramsItems.ticket.event.location.length > 42
                ? paramsItems.ticket.event.location.slice(0, 42) + "..."
                : paramsItems.ticket.event.location
            }}
          </div>
        </div>
      </div>
      <div class="counter">
        <div class="count">{{ paramsItems.quantity }}</div>
      </div>
      <div class="price-remove">
        <div class="price">
          ${{ paramsItems.ticket.price * paramsItems.quantity }}
        </div>
      </div>
      <div class="wr_download_and_menu">
        <div @click="openModals('download')" class="pdf">DOWNLOAD PDF</div>
        <div @click.stop="openMenuModal = !openMenuModal" class="menu">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.9987 8.66683C8.36689 8.66683 8.66536 8.36835 8.66536 8.00016C8.66536 7.63197 8.36689 7.3335 7.9987 7.3335C7.63051 7.3335 7.33203 7.63197 7.33203 8.00016C7.33203 8.36835 7.63051 8.66683 7.9987 8.66683Z"
              fill="#47505B"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.9987 3.99984C8.36689 3.99984 8.66536 3.70136 8.66536 3.33317C8.66536 2.96498 8.36689 2.6665 7.9987 2.6665C7.63051 2.6665 7.33203 2.96498 7.33203 3.33317C7.33203 3.70136 7.63051 3.99984 7.9987 3.99984Z"
              fill="#47505B"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
              fill="#47505B"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div v-if="openMenuModal" class="menu_modal">
            <span><span></span></span>
            <ul>
              <li
                @click="
                  openModals({
                    ticket_id: paramsItems.id,
                    title: 'Send Ticket via E-mail',
                    send_type: 'Your E-mail',
                  })
                "
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66536 2.6665H13.332C14.0654 2.6665 14.6654 3.2665 14.6654 3.99984V11.9998C14.6654 12.7332 14.0654 13.3332 13.332 13.3332H2.66536C1.93203 13.3332 1.33203 12.7332 1.33203 11.9998V3.99984C1.33203 3.2665 1.93203 2.6665 2.66536 2.6665Z"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6654 4L7.9987 8.66667L1.33203 4"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Send Ticket via E-mail
              </li>
              <li
                @click="
                  openModals({
                    ticket_id: paramsItems.id,
                    title: 'Send QR via SMS',
                    send_type: 'Your Phone',
                  })
                "
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1_151896)">
                    <path
                      d="M6.66797 8.66648C6.95427 9.04923 7.31954 9.36594 7.739 9.59511C8.15846 9.82428 8.62231 9.96056 9.09907 9.9947C9.57583 10.0288 10.0544 9.96006 10.5022 9.793C10.95 9.62594 11.3567 9.36453 11.6946 9.02648L13.6946 7.02648C14.3018 6.39781 14.6378 5.5558 14.6302 4.68181C14.6226 3.80782 14.2721 2.97178 13.654 2.35375C13.036 1.73573 12.2 1.38516 11.326 1.37757C10.452 1.36997 9.60998 1.70595 8.9813 2.31315L7.83464 3.45315"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33347 7.33332C9.04716 6.95057 8.68189 6.63387 8.26243 6.40469C7.84297 6.17552 7.37913 6.03924 6.90237 6.0051C6.4256 5.97095 5.94707 6.03974 5.49924 6.2068C5.0514 6.37386 4.64472 6.63527 4.3068 6.97332L2.3068 8.97332C1.69961 9.60199 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.028 2.3474 13.646C2.96543 14.2641 3.80147 14.6146 4.67546 14.6222C5.54945 14.6298 6.39146 14.2938 7.02013 13.6867L8.16013 12.5467"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_151896">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Send QR via SMS
              </li>
              <li @click="openModals('download')">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33464 1.3335H4.0013C3.64768 1.3335 3.30854 1.47397 3.05849 1.72402C2.80844 1.97407 2.66797 2.31321 2.66797 2.66683V13.3335C2.66797 13.6871 2.80844 14.0263 3.05849 14.2763C3.30854 14.5264 3.64768 14.6668 4.0013 14.6668H12.0013C12.3549 14.6668 12.6941 14.5264 12.9441 14.2763C13.1942 14.0263 13.3346 13.6871 13.3346 13.3335V5.3335L9.33464 1.3335Z"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.33203 1.3335V5.3335H13.332"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6654 8.6665H5.33203"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6654 11.3335H5.33203"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66536 6H5.9987H5.33203"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Open Full Contract
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openMenuModal: false,
    };
  },
  props: {
    paramsItems: Object,
  },

  methods: {
    openModals(params) {
      if (params === "download") {
        this.$emit("openCloseContractModal");
      } else {
        this.$emit("openCloseSendEmailOrPhoneModals", params);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-item-title {
  div {
    width: 102px !important;
  }
  div:first-child {
    width: 365px !important;
  }
}
.buy_item {
  display: flex;
  height: 70px;
  background: white;
  border-radius: 2px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 0 10px;
  .img-wrap {
    width: 50px;
    height: 50px;
    border-radius: 2px;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
  }
  .title {
    color: #071526;
    font-weight: 700;
    font-size: 13px;
    font-family: HelveticaNeueBold;
  }
  .subtitle {
    color: #47505b;
    opacity: 0.7;
    font-weight: 400;
    font-size: 12px;
    font-family: HelveticaNeueNormal;
  }
  .img-text-wrap {
    display: flex;
    width: 370px;
    padding-right: 10px;
    align-items: center;
  }

  .counter {
    width: unset !important;
    .count {
      width: 60px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 40px 0 0 !important;
      background: #f3f3f3 !important;
      border: 1px solid #e6eaf0;
      border-radius: 2px;
    }
  }
  .price-remove {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D1433A1A;
    border-radius: 2px;
  }
  .wr_download_and_menu {
    display: flex;
    .pdf {
      margin-right: 10px;
      cursor: pointer;
      margin-left: 60px;
      width: 130px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.5px solid #D1433A;
      border-radius: 4px;
      color: #D1433A;
      &:hover {
        background: #D1433A;
        color: #ffffff;
      }
    }
  }
}
</style>
