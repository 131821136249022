<template>
   <span class="friend_tab_search">
       <input type="search" name="Search" placeholder="Search" v-model="eventsSearchKey" v-on:keyup="SearchEvents" autocomplete="off"/>
       <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.1 6.543C12.1 3.48168 9.61518 0.999998 6.55 0.999998C3.48482 0.999998 1 3.48168 1 6.543C1 9.60431 3.48482 12.086 6.55 12.086C9.61518 12.086 12.1 9.60431 12.1 6.543Z"
            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.5 12.501L14.5 14.501" stroke="#8B949F" stroke-width="1.3"
                stroke-linecap="round" stroke-linejoin="round"/>
       </svg>
    </span>
</template>
<script>
export default {
  props:['currentActiveEventTab', 'SearchEventKey'],
  data(){
    return{
      eventsSearchKey: null,
      showMore: false,
    }
  },
  watch:{
    currentActiveEventTab (){
      this.eventsSearchKey = ''
    }
  },
  methods:{
    SearchEvents(){

      let payload = {
        showMore : this.showMore,
        page_id: this.$route.params.id,
        type : this.currentActiveEventTab,
        search: this.eventsSearchKey,
      }
      if(this.currentActiveEventTab === 'upcoming'){
        this.$store.dispatch('getPageUpcomingEvents', payload)
      }else if(this.currentActiveEventTab === 'past'){
        this.$store.dispatch('getPagePastEvents', payload)
      }
      this.changeSearch()
    },
    changeSearch() {
      this.$parent.$parent.active_page_search();
    }
  }
}
</script>