<template>
  <div>
    <div v-if="activeTab === 'group' || activeTab === 'single' " class="post-detail">

      <!-- For a small chat box in new feed -->
        <div v-if="chat_type === 'single_chat'" class="p-2" @click="pushPostDetail(item.id)" style="word-break: break-all;">
          <p class="link-text text-white mb-0">https://auddiodev.theplayground.co.uk/home/page/post-detail/{{item.id}}</p>
        </div>

      <!-- For a full Chat page -->
      <div news-feed-pic-detials v-else>
        <div class="user_and_link p-2">
          <h1
            @click="redirectWidgetsToProfilePage($event, item && item.user && item.user.username ? item.user.username : '')">
            {{ name }}
          </h1>
          <p class="">shared a post</p>
          <span class="user_dot">
            <svg fill="none" height="2" viewBox="0 0 2 2" width="2" xmlns="http://www.w3.org/2000/svg">
              <circle cx="1" cy="1" fill="#8B949F" r="1" />
            </svg>
          </span>
          <p class="post-time">{{ time }}</p>
          <span class="icon_globe">
            <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
              <path d="M1 7H13" stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="1.5" />
              <path
                d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </svg>
          </span>

        </div>
        <div class="p-2" @click="pushPostDetail(item.id)" >
          <p class="link-text">https://auddiodev.theplayground.co.uk/home/page/post-detail/{{item.id}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import { SUCCESS } from "../../../store/types";
import AppConfig from "../../../configs/AppConfig";
export default {
  name: "SharePostLink",
  data() {
    return {
      write_comment: false,
      comment_count: 0,
      is_drop_down_open: false,

      edit_post: "",
      is_edit_post: false,
      edit_post_loading: false,

      is_edit_poll_vote_local: false,

      selected_poll_option: undefined,
      change_vote_count: 0,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    item(value) {
      this.init();
    },
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    isPostIsActivity() {
      return !!this.activities.some((e) => e.title === this.post_type);
    },
    activities() {
      return this.$store.getters.getPostsActivities;
    },
    postContainPredefinedClass() {
      if (this.item.post_type === "coloured") {
        return this.item.post_type_description;
      }
      return " ";
    },
    postHasPredefinedClass() {
      if (this.item.post_type === "coloured") {
        return !this.item.post_type_description.includes("#");
      }
      return false;
    },
    post_type() {
      const { post_type } = this.item || {};

      return post_type;
    },
    shareable_post_type() {
      const { shareable_item } = this.item || {};
      return shareable_item.post_type;
    },
    category() {
      const { category } = this.item || {};
      return category;
    },
    photos() {
      const { photo } = this.item;

      return photo && photo.length ? photo : [];
    },
    poll_options() {
      const { options } = this.item;

      return options && options.length ? options : [];
    },
    data_photos() {
      const { artwork_url } = this.item;

      return artwork_url && artwork_url.length
        ? artwork_url.map((ele) =>
          Object.assign(ele, {
            image: `${AppConfig.post_image_url}/${ele.source}`,
          })
        )
        : [];
    },
    data_video() {
      const { artwork_url } = this.item;http://localhost:8080/home/page/post-detail/2455

        return artwork_url && artwork_url.length
          ? artwork_url.map((ele) => {
            return {
              source: ele.artwork_url,
              properties: ele.custom_properties,
              name: ele.name,
              images: ele.thumb_url,
            };
          })
          : [];
    },
    data_photos_length() {
      const { artwork_url } = this.item;

      return artwork_url && artwork_url.length ? artwork_url.length : 0;
    },
    name() {
      const { user } = this.item || {};
      return `${user.full_name}`;
    },
    user_picture() {
      const { user } = this.item || {};

      return user && user.artwork_url ? user.artwork_url : "";
    },
    time() {
      const { created_at } = this.item || {};
      return moment(created_at).fromNow();
    },
    sameUserInShared() {
      return !!(
        this.item.shareable_item &&
        this.item.user_id === this.item.shareable_item.user_id
      );
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    comment_check: {
      type: Boolean,
      default: false,
    },
    sharedPost: {
      type: Boolean,
      default: false,
    },
    pageCheck: {
      type: String,
      default: "",
    },
    userPicture: {
      type: String,
      default: "",
    },
    activeTab: {
      type: String,
      default: ""
    },
    chat_type: {
      type: String,
      default: ""
    }
  },
  components: {

  },
  methods: {
    ...mapActions(["get_post_data"]),
    init() {
      const { comment_count } = this.item || {};
      this.comment_count = comment_count;
    },
    pushPostDetail: async function (id) {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("getActivities");
      this.$store.commit("SET_POST_OFFSET", 0);
      const {status} = await this.$store.dispatch("getIndividualPost", id);
      if (status === 'success') {
        if (this.pageCheck) {
          const pathname = `/home/page/${this.pageCheck}/post-detail/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        } else {
          const pathname = `/home/page/post-detail/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        }
      }
    },
    open_post_image_detail: async function (id) {
      await this.$store.dispatch("getActivities");
      this.$store.commit("SET_POST_OFFSET", 0);
      const { status } = await this.$store.dispatch("getIndividualPost", id);
      if (status === SUCCESS) {
        if (this.pageCheck === "timeline") {
          const pathname = `/home/page/${this.pageCheck}/feed-image/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        } else {
          const pathname = `/home/page/feed-image/${id}`;
          if (this.expandable) return;
          await this.$router.push(pathname);
        }
      }
    },
    toggleCommentSection(e) {
      if (e) e.stopPropagation();
      this.write_comment = !this.write_comment;
    },
    editPost() {
      this.is_edit_post = true;
      this.edit_post = this.item && this.item.text ? this.item.text : "";
    },
    cancelEditPost() {
      this.is_edit_post = false;
      this.edit_post = "";
    },
    incrementCommentCount() {
      this.comment_count = parseInt(this.comment_count) + 1;
    },
    async editSubmit(e) {
      if (e) e.stopPropagation();

      const { edit_post, edit_post_loading } = this;
      const post_id = this.item.id;

      const { user_data } = this.user || {};
      const user_id = user_data.id;

      try {
        const query = `?action=2&user_id=${user_id}&post_id=${post_id}&text=${edit_post}`;

        this.edit_post_loading = true;
        this.message = "";

        const { success, message } = await create_post_api(query);
        this.edit_post_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.$parent.init();
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }
      } catch (error) {
        console.error("post edit ===> error", error);
        this.edit_post_loading = false;
      }
    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.submit();
      }
    },
    getChangeVoteCountAction(item) {
      const { selected_poll_option } = this;

      const { option_id } = item;

      if (selected_poll_option === undefined) return 0;

      if (typeof selected_poll_option === "string") {
        return option_id === selected_poll_option ? 1 : -1;
      }

      return 0;
    },
    select_poll_option(value) {
      this.selected_poll_option = value;
    },
    stop_propagation(e) {
      if (e) e.stopPropagation();
    },
    getEmoji(feeling) {
      const activityIndex = this.activities.findIndex(
        (e) => e.title === "Feeling"
      );
      if (activityIndex !== null) {
        if (this.activities[activityIndex].emoticons) {
          const feelingIndex = this.activities[
            activityIndex
            ].emoticons.findIndex((ele) => ele.title === feeling);
          if (feelingIndex !== -1) {
            return this.activities[activityIndex].emoticons[feelingIndex]
              ? this.activities[activityIndex].emoticons[feelingIndex]
                .artwork_url
              : false;
          }
        }
      }
      return false;
    },
    reset_data() {
      this.edit_post = "";
      this.is_edit_post = false;
      this.edit_post_loading = false;
    },
    commentsCount(comments_count) {
      if (comments_count > this.comment_count) {
        this.comment_count = comments_count;
      }
    },
    addCommentInList(comment) {
      this.$emit("addCommentInList", comment);
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-text {
  color: #D1433A;
  font-size: 15px;
  font-weight: 700;
}

.post-detail {
  margin-top: 0!important;
  .full-content {
    font-family: HelveticaNeueNormal;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #071526;
  }

  .news-feed-post-detail {
    .useinfo_photoimage {
      .repost-and-share-card {
        border-radius: 2px;
        background: #f7f7f7;
        padding: 30px 20px 20px 20px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.news-feed-pic-detials-photo-video {
  border-left: none!important;
  padding-left: 0!important;
}
</style>
