<template>
  <VueVideoThumbnail
    :video-src="video_source"
    show-play-button
    :snapshot-at-duration-percent="50"
    :width="500"
    :height="300"
    :chunks-quantity="10"
    @snapshotCreated="results"
  >
    <template #snapshot="{snapshot}">
      <img
        v-if="snapshot"
        :src="snapshot"
        alt="snapshot"
      >
    </template>
  </VueVideoThumbnail>
</template>

<script>
import VueVideoThumbnail from 'vue-video-thumbnail'
export default {
  name: "VideoThumbnail",
  components : {
    VueVideoThumbnail
  },
  props: {
    video_source : {
      type: String,
    }
  },
  methods: {
    results(value) {
    }
  }
};
</script>

<style scoped>

</style>
