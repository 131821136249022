<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 5.3335C13.1046 5.3335 14 4.43807 14 3.3335C14 2.22893 13.1046 1.3335 12 1.3335C10.8954 1.3335 10 2.22893 10 3.3335C10 4.43807 10.8954 5.3335 12 5.3335Z"
                stroke="#47505B" stroke-width="1.5"></path>
              <path
                d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z"
                stroke="#47505B" stroke-width="1.5"></path>
              <path
                d="M12 14.6665C13.1046 14.6665 14 13.7711 14 12.6665C14 11.5619 13.1046 10.6665 12 10.6665C10.8954 10.6665 10 11.5619 10 12.6665C10 13.7711 10.8954 14.6665 12 14.6665Z"
                stroke="#47505B" stroke-width="1.5"></path>
              <path d="M5.72656 9.00684L10.2799 11.6602" stroke="#47505B" stroke-width="1.5"></path>
              <path d="M10.2732 4.33984L5.72656 6.99318" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Social Links</p>
          <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <!--          <div v-if="success_message" class="alert alert-success" role="alert">-->
          <!--            {{ success_message }}-->
          <!--          </div>-->
          <div class="d-flex flex-row justify-content-between">
            <div class="form-group ">
              <label class="social">Facebook Profile URL</label>
              <div class="social-input-group">
                <div class="social-icon-group">
                  <div class="svg-icon">
                    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1 c-fill-1"
                        d="M6.47898 17H3.97628C3.55826 17 3.2182 16.6519 3.2182 16.2239V10.4446H1.75808C1.34005 10.4446 1 10.0963 1 9.66846V7.19202C1 6.76404 1.34005 6.41589 1.75808 6.41589H3.2182V5.17578C3.2182 3.94617 3.59534 2.90002 4.30871 2.15063C5.02529 1.39783 6.02673 1 7.20475 1L9.11342 1.00317C9.53074 1.00391 9.87019 1.35205 9.87019 1.7793V4.07861C9.87019 4.50659 9.53026 4.85474 9.11235 4.85474L7.82726 4.85522C7.43534 4.85522 7.33554 4.93567 7.3142 4.96033C7.27903 5.00122 7.23718 5.11682 7.23718 5.43604V6.41577H9.01577C9.14967 6.41577 9.27939 6.44958 9.39088 6.51331C9.63137 6.65088 9.78089 6.91101 9.78089 7.19214L9.77993 9.66858C9.77993 10.0963 9.43988 10.4445 9.02185 10.4445H7.23718V16.2239C7.23718 16.6519 6.89701 17 6.47898 17ZM4.13439 16.062H6.32088V10.0247C6.32088 9.73889 6.54801 9.50647 6.82702 9.50647H8.86375L8.86458 7.35388H6.8269C6.54789 7.35388 6.32088 7.12146 6.32088 6.83569V5.43604C6.32088 5.06958 6.35724 4.65283 6.62742 4.3396C6.95388 3.96094 7.46837 3.91724 7.82702 3.91724L8.95401 3.91675V1.94092L7.20403 1.93799C5.31086 1.93799 4.13439 3.17871 4.13439 5.17578V6.83569C4.13439 7.12134 3.90737 7.35388 3.62836 7.35388H1.91618V9.50647H3.62836C3.90737 9.50647 4.13439 9.73889 4.13439 10.0247V16.062ZM9.11163 1.94116H9.11175H9.11163Z"
                        fill="#47505B" stroke="#47505B" stroke-width="0.3"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <input type="text" class="s-link-input" v-model="facebook_url">
              <br />
              <p class="error_msg error_msg_red" v-if="Errors[0] === 'Invalid website link'">
                Invalid website link</p>
            </div>
            <div class="form-group ">
              <label class="social">Google+ Profile URL</label>
              <div class="social-input-group">
                <div class="social-icon-group">
                  <div class="svg-icon">
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.8978 6.80843C11.7087 6.80843 12.5623 6.80843 13.3733 6.80843C13.8854 6.80843 13.9281 6.85374 13.9281 7.35212C13.9708 8.93788 13.672 10.4783 12.8184 11.8376C11.2819 14.3295 8.33698 15.5528 5.60544 14.7372C3.12997 14.0123 1.5508 12.2453 0.910591 9.61749C0.398426 7.48804 0.825231 5.49451 2.14832 3.72752C3.38606 2.05115 5.05059 1.145 7.05657 1.00907C8.80647 0.91846 10.343 1.50746 11.6661 2.73076C12.0075 3.04791 12.0502 3.31975 11.7087 3.63691C11.41 3.95406 11.1112 4.27121 10.7698 4.58836C10.5137 4.8149 10.2576 4.86021 10.0015 4.63367C9.87348 4.54306 9.74544 4.40713 9.57472 4.31652C8.0809 3.31975 6.54441 3.27445 5.05059 4.27121C3.55678 5.35859 2.95925 6.89904 3.25801 8.75665C3.55678 10.5689 4.58111 11.7016 6.24564 12.2453C8.03822 12.789 10.0442 11.8829 10.8978 10.1612C10.9405 10.0706 10.9832 9.93464 11.0258 9.84403C11.0685 9.7081 10.9832 9.52688 10.8551 9.48157C10.6844 9.48157 10.5137 9.43626 10.343 9.43626C9.74544 9.43626 9.14791 9.43626 8.55039 9.43626C8.33698 9.43626 8.16626 9.43626 7.95286 9.39095C7.73946 9.34565 7.56874 9.20972 7.56874 8.98319C7.56874 8.4395 7.52606 7.89581 7.56874 7.35212C7.56874 7.08027 7.73946 6.89904 7.99554 6.85374C8.20894 6.80843 8.37966 6.80843 8.59307 6.80843C9.36131 6.80843 10.1296 6.80843 10.8978 6.80843Z"
                        stroke="#47505B"></path>
                      <path
                        d="M21.3971 8.07816C21.3971 9.12023 21.3545 9.16554 20.4155 9.16554C19.3912 9.16554 19.4338 9.12023 19.4338 10.2076C19.4338 11.2044 19.3912 11.2497 18.4095 11.2497C18.2388 11.2497 18.0681 11.2497 17.8973 11.2497C17.5559 11.2044 17.3852 11.0684 17.3852 10.6607C17.3852 10.3435 17.3852 9.98107 17.3852 9.66392C17.3852 9.25615 17.2998 9.16554 16.9584 9.16554C16.6596 9.16554 16.3182 9.16554 16.0194 9.16554C15.5926 9.12023 15.4646 8.98431 15.4219 8.57654C15.4219 8.25939 15.4219 7.89693 15.4219 7.57977C15.4646 7.17201 15.5926 7.03609 16.0194 6.99078C16.3608 6.99078 16.7023 6.99078 17.0437 6.94547C17.2145 6.94547 17.3852 6.80955 17.3852 6.58301C17.3852 6.22055 17.3852 5.85809 17.4279 5.49563C17.4705 4.99725 17.5986 4.86133 18.0681 4.86133C18.3668 4.86133 18.7083 4.86133 19.007 4.86133C19.3058 4.90664 19.4765 5.04256 19.4765 5.40502C19.5192 5.72217 19.4765 5.99401 19.4765 6.31117C19.4765 6.90016 19.5192 6.94547 20.1167 6.94547C20.3728 6.94547 20.6289 6.94547 20.885 6.94547C21.2691 6.99078 21.3971 7.1267 21.4398 7.48916C21.3971 7.7157 21.3971 7.89693 21.3971 8.07816Z"
                        stroke="#47505B"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <input type="text" class="s-link-input" v-model="google_url">
              <br />
              <p class="error_msg error_msg_red" v-if="Errors[1] === 'Invalid website link'">
                Invalid website link</p>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="form-group ">
              <label class="social">Instagram Profile URL</label>
              <div class="social-input-group">
                <div class="social-icon-group">
                  <div class="svg-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 1H4.66667C2.65 1 1 2.65 1 4.66667V12C1 14.0167 2.65 15.6667 4.66667 15.6667H12C14.0167 15.6667 15.6667 14.0167 15.6667 12V4.66667C15.6667 2.65 14.0167 1 12 1ZM15 12C15 13.65 13.65 15 12 15H4.66667C3.01667 15 1.66667 13.65 1.66667 12V4.66667C1.66667 3.01667 3.01667 1.66667 4.66667 1.66667H12C13.65 1.66667 15 3.01667 15 4.66667V12Z"
                        fill="#47505B" stroke="#47505B" stroke-width="0.5"></path>
                      <path
                        d="M8.33366 4.6665C6.31699 4.6665 4.66699 6.3165 4.66699 8.33317C4.66699 10.3498 6.31699 11.9998 8.33366 11.9998C10.3503 11.9998 12.0003 10.3498 12.0003 8.33317C12.0003 6.3165 10.3503 4.6665 8.33366 4.6665ZM8.33366 11.3332C6.68366 11.3332 5.33366 9.98317 5.33366 8.33317C5.33366 6.68317 6.68366 5.33317 8.33366 5.33317C9.98366 5.33317 11.3337 6.68317 11.3337 8.33317C11.3337 9.98317 9.98366 11.3332 8.33366 11.3332Z"
                        fill="#47505B" stroke="#47505B" stroke-width="0.5"></path>
                      <path
                        d="M12.6667 2.8335C12.0167 2.8335 11.5 3.35016 11.5 4.00016C11.5 4.65016 12.0167 5.16683 12.6667 5.16683C13.3167 5.16683 13.8333 4.65016 13.8333 4.00016C13.8333 3.35016 13.3167 2.8335 12.6667 2.8335ZM12.6667 4.50016C12.3833 4.50016 12.1667 4.2835 12.1667 4.00016C12.1667 3.71683 12.3833 3.50016 12.6667 3.50016C12.95 3.50016 13.1667 3.71683 13.1667 4.00016C13.1667 4.2835 12.95 4.50016 12.6667 4.50016Z"
                        fill="#47505B" stroke="#47505B" stroke-width="0.5"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <input type="text" class="s-link-input" v-model="instagram_url">
              <br />
              <p class="error_msg error_msg_red" v-if="Errors[2] === 'Invalid website link'">
                Invalid website link</p>
            </div>
            <div class="form-group ">
              <label class="social">Twitter Profile URL</label>
              <div class="social-input-group">
                <div class="social-icon-group">
                  <div class="svg-icon">
                    <TwitterIcon />
                  </div>
                </div>
              </div>
              <input type="text" class="s-link-input" v-model="twitter_url">
              <br />
              <p class="error_msg error_msg_red" v-if="Errors[3] === 'Invalid website link'">
                Invalid website link</p>
            </div>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="form-group ">
              <label class="social">LinkedIn Profile URL</label>
              <div class="social-input-group">
                <div class="social-icon-group">
                  <div class="svg-icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.28758 6.46403H4.7026V17.464H1.28758V6.46403ZM2.97712 1C4.05554 1 4.95423 1.89869 4.95423 2.97712C4.95423 4.05554 4.05554 4.95423 2.97712 4.95423C1.89869 4.95423 1 4.05554 1 2.97712C1 1.89869 1.89869 1 2.97712 1Z"
                        stroke="#47505B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                        d="M6.82324 6.46404H10.0945V7.97383H10.1664C10.6337 7.11109 11.7481 6.2124 13.4016 6.2124C16.8526 6.2124 17.4997 8.4771 17.4997 11.4607V17.4999H14.0846V12.1078C14.0846 10.8496 14.0487 9.19605 12.3232 9.19605C10.5618 9.19605 10.2742 10.598 10.2742 12V17.428H6.85919V6.46404H6.82324Z"
                        stroke="#47505B" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <input type="text" class="s-link-input" v-model="linkedin_url">
              <br />
              <p class="error_msg error_msg_red" v-if="Errors[4] === 'Invalid website link'">
                Invalid website link</p>
            </div>
            <div class="form-group ">
              <label class="social">YouTube Profile URL</label>
              <div class="social-input-group">
                <div class="social-icon-group">
                  <div class="svg-icon">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.20068 10.0005H9.00073C8.90073 9.90049 8.80078 9.80044 8.80078 9.70044V5.00049C8.80078 4.90049 8.90073 4.70044 9.00073 4.70044C9.05573 4.65124 9.12689 4.62402 9.20068 4.62402C9.27448 4.62402 9.34575 4.65124 9.40076 4.70044L13.0007 7.10059C13.2007 7.20059 13.2007 7.40059 13.1007 7.60059L13.0007 7.70044L9.40076 10.0005H9.20068Z"
                        fill="#47505B"></path>
                      <path
                        d="M3.5878 1.00031H16.4122C16.7535 0.995304 17.0926 1.05153 17.409 1.16535C17.7255 1.27916 18.013 1.44833 18.2544 1.6629C18.4958 1.87748 18.6861 2.13306 18.8141 2.41437C18.9422 2.69568 19.0053 2.99694 18.9997 3.30036V11.3004C19.0053 11.6038 18.9422 11.905 18.8141 12.1863C18.6861 12.4677 18.4958 12.7232 18.2544 12.9378C18.013 13.1524 17.7255 13.3216 17.409 13.4354C17.0926 13.5492 16.7535 13.6054 16.4122 13.6004H3.5878C3.24647 13.6054 2.90743 13.5492 2.59097 13.4354C2.27451 13.3216 1.98699 13.1524 1.7456 12.9378C1.50421 12.7232 1.31391 12.4677 1.18587 12.1863C1.05783 11.905 0.994718 11.6038 1.00035 11.3004V3.30036C0.994718 2.99694 1.05783 2.69568 1.18587 2.41437C1.31391 2.13306 1.50421 1.87748 1.7456 1.6629C1.98699 1.44833 2.27451 1.27916 2.59097 1.16535C2.90743 1.05153 3.24647 0.995304 3.5878 1.00031Z"
                        stroke="#47505B" stroke-width="1.2"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <input type="text" class="s-link-input" v-model="youtube_url">
              <br />
              <p class="error_msg error_msg_red" v-if="Errors[5] === 'Invalid website link'">
                Invalid website link</p>
            </div>
          </div>

          <div class="row">
            <div class="col text-right" style="margin-top: 14px;">
              <button type="button" @click="submit" class="btn save-btn">{{
                loading ? "Please Wait..." : "Save Changes"
              }}
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class=""></div>
    </div>
    <full-page-loader :show="loading" />
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import { EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR, LOG_OUT } from "../../../store/types";
import EyeIcon from '../../../assets/svgs/EyeIcon.vue';
import TwitterIcon from '../../../assets/svgs/TwitterIcon.vue';

export default {
  components: {
    EyeIcon,
    TwitterIcon
  },
  data() {
    return {
      Errors: [],
      noOfErrors: 0,
      success: false,
      facebook_url: "",
      google_url: "",
      instagram_url: "",
      twitter_url: "",
      linkedin_url: "",
      youtube_url: "",
      // vkontake_profile_url: "",

      message: "",
      success_message: "",
      loading: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props"
    }),
    // loading() {
    //   const {loading = false} = this.form_data || {};
    //
    //   return loading;
    // },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions([
      "social_link",
      "fetchUserData"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints"
    ]),
    init() {
      this.loading = true;
      const { user_data } = this.user || {};
      const {
        facebook_url = "",
        instagram_url = "",
        twitter_url = "",
        youtube_url = "",
        soundcloud_url = "",
        linkedin_url = ""
      } = user_data || {};

      this.facebook_url = (facebook_url === 'null') ? '' : facebook_url;
      this.instagram_url = (instagram_url === 'null') ? '' : instagram_url;
      this.twitter_url = (twitter_url === 'null') ? '' : twitter_url;
      this.linkedin_url = (linkedin_url === 'null') ? '' : linkedin_url;
      this.youtube_url = (youtube_url === 'null') ? '' : youtube_url;
      this.google_url = (soundcloud_url === 'null') ? '' : soundcloud_url;
      this.loading = false;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    onChange(e) {
      let { key } = e;

      const value = key === "." ? key : key.replace(/\D/gi, "");

      if (value) return true;

      return e.preventDefault();
    },
    async submit(e) {
      if (e) e.preventDefault();

      const { user_data } = this.user || {};
      const { user_id } = user_data || {};
      const {
        loading,
        facebook_url,
        google_url,
        instagram_url,
        twitter_url,
        linkedin_url,
        youtube_url,
        vkontake_profile_url
      } = this;

      if (loading) return;

      let body = {
        facebook_url,
        google_url,
        instagram_url,
        twitter_url,
        linkedin_url,
        youtube_url,
      };


      this.validationChecks(body)
      if (this.noOfErrors <= 0) {
        this.loading = true;
        const data = await this.social_link(body);
        this.loading = false;
        await this.fetchUserData();
        this.success = true
        this.noOfErrors = 0
        this.Errors = []
      }
      else {
        this.message = "Please try again.";
        console.log("ERROR Length", this.noOfErrors);
        console.log("SHOW ERRORS", this.Errors);
        this.noOfErrors = 0
        this.Errors = []
      }


      // Helper.validate(Object.keys(body), body)
      //     .then(async ({status, response}) => {
      //       console.log("Response Validate :", response);
      //       const res = response.filter(e => e.message === 'Invalid website link.')
      //       console.log("Res ", res);
      //       if (status) {
      //         this.updateEditProfileUIConstraints({
      //           [EDIT_PROFILE_ERRORS]: []
      //         });
      //         try {
      //           this.message = "";
      //           this.loading = true;
      //           const {status, message} = await this.social_link(body);
      //           this.loading = false;
      //           switch (status) {
      //             case SUCCESS:
      //               this.success_message = message;
      //               await this.fetchUserData();
      //               setTimeout(() => {
      //                 this.success_message = ""
      //               }, 5000);
      //               break;
      //             case ERROR:
      //               this.message = message;
      //               break;
      //           }
      //         } catch (e) {
      //           this.message = e && e.message ? e.message : "Please try again.";
      //         }
      //       } else this.updateEditProfileUIConstraints({
      //         [EDIT_PROFILE_ERRORS]: res && res.length ? res : []
      //       });
      //     })
      //     .catch(err => console.log(err));
    },
    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },
    validationChecks(body) {
      if (body.facebook_url) {
        var test = this.validURL(body.facebook_url)
        if (test === true) {
          this.Errors.push('No Error')
        }
        else {
          this.noOfErrors = this.noOfErrors + 1
          this.Errors.push('Invalid website link')
        }
      }
      else {
        this.Errors.push('No Error')
      }
      if (body.google_url) {
        var test = this.validURL(body.google_url)
        if (test === true) {
          this.Errors.push('No Error')
        }
        else {
          this.noOfErrors = this.noOfErrors + 1
          this.Errors.push('Invalid website link')
        }
      }
      else {
        this.Errors.push('No Error')
      }
      if (body.instagram_url) {
        var test = this.validURL(body.instagram_url)
        if (test === true) {
          this.Errors.push('No Error')
        }
        else {
          this.noOfErrors = this.noOfErrors + 1
          this.Errors.push('Invalid website link')
        }
      }
      else {
        this.Errors.push('No Error')
      }
      if (body.twitter_url) {
        var test = this.validURL(body.twitter_url)
        if (test === true) {
          this.Errors.push('No Error')
        }
        else {
          this.noOfErrors = this.noOfErrors + 1
          this.Errors.push('Invalid website link')
        }
      }
      else {
        this.Errors.push('No Error')
      }
      if (body.linkedin_url) {
        var test = this.validURL(body.linkedin_url)
        if (test === true) {
          this.Errors.push('No Error')
        }
        else {
          this.noOfErrors = this.noOfErrors + 1
          this.Errors.push('Invalid website link')
        }
      }
      else {
        this.Errors.push('No Error')
      }
      if (body.youtube_url) {
        var test = this.validURL(body.youtube_url)
        if (test === true) {
          this.Errors.push('No Error')
        }
        else {
          this.noOfErrors = this.noOfErrors + 1
          this.Errors.push('Invalid website link')
        }
      }
      else {
        this.Errors.push('No Error')
      }

    }
  }
};
</script>
