<template>
  <!-- Colored post -->
  <div>
    <div class="post-filed-options-color" v-if="!selectedTab">
      <div class="row" v-if="selected_color">
        <div class="col">
              <div class="cross" :class="[!is_selected_color_hex ? selected_color : '']" @click="closeModal()" v-if="is_edit_post">
                <span>
                  <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1.19141L11 10.8068"
                            stroke="#47505B"
                            stroke-width="1.5"
                          />
                          <path
                            d="M10.8066 1L1.19126 11"
                            stroke="#47505B"
                            stroke-width="1.5"
                          />
                        </svg>
                </span>
              </div>
              <MentionedAndTagBox :checkPost="'color'" @userPageLink="setPageUserLinks">
              <template v-slot:textarea>
              <div
                class="write-board post-something-field"
                :class="[!is_selected_color_hex ? selected_color : '']"
                :style="
                  is_selected_color_hex ? 'background : ' + selected_color : ''
                "
              >
                {{ !is_text_field_text ? "" : "What is on your mind?" }}
                <br v-if="!color_value" />
                {{ !is_text_field_text ? "" : "#Hashtag...@Mention... Link" }}
                <textarea
                  @keydown="removeMentionsOnBackSpace($event)"
                  name="create_post_form_color_post_text"
                  v-model="color_text"
                  @change="onChange"
                />
              </div>
              </template>
            </MentionedAndTagBox>
        </div>
      </div>
      <div class="upload-photos-section-color">
        <div class="row">
          <div class="col">
            <div class="upload-photos-title">
              <div class="back-photo-icon" @click="goBack()">
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 9L1 5L5 1"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <div class="bac-text-tite">Colored Post</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="colored-list">
              <ul class="w-100">
                <div id="colors-list" class="colors-list d-flex">
                  <li
                    v-for="(eachOp, index) in colors"
                    :key="'color' + index"
                    @click="onSelectColor(eachOp, index)"
                  >
                    <div
                      v-if="!eachOp.isHex"
                      class="colored-box"
                      :class="eachOp.color"
                    ></div>
                    <div
                      v-if="eachOp.isHex"
                      class="colored-box"
                      :style="{ backgroundColor: eachOp.color }"
                    ></div>
                  </li>
                </div>
                <li>
                  <input
                    class="color-custom-tag w-20"
                    @keyup.enter="addColor"
                    v-model="color"
                  />
                </li>
                <li>
                  <div class="add-color-btn">
                    <button @click="addColor">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 0C8.55228 0 9 0.447715 9 1V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V1C7 0.447715 7.44772 0 8 0Z"
                          fill="#47505B"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 8C0 7.44772 0.447715 7 1 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H1C0.447715 9 0 8.55228 0 8Z"
                          fill="#47505B"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="!is_edit_post">
          <div class="row">
            <div class="col">
              <div class="Sponsors-section">
                <div class="action-block ">
                  <div class="switch-button">
                    <label class="switch ">
                      <input
                        type="checkbox"
                        checked="checked"
                        class="default"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  For All Users
                </div>
                <div
                  class="select-audience-button"
                  v-if="!selectedTab"
                  @click="selectedTab = 'selectAudience'"
                >
                  <span v-if="!audience"> Choose Audience</span>
                  <span v-if="audience"> {{audience.toUpperCase().replace(/_/g, ' ')}}</span>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div id="create-colored-post-btn" @click="createPost" class="row">
            <div class="col">
              <div class="post-btn"><button>POST</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
      <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
    </div>
  </div>
</template>
<script>
import SelectAudience from "./components/selectAudience";
import { mapGetters, mapMutations } from "vuex";
import { getColors } from "../../../components/utils/Utils";
import { CREATE_POST_FORM_POST_COLOR } from "../../../store/types";
import MentionedAndTagBox from "@/components/Common/UiList/MentionedAndTagBox";
import audience from "../../Events/components/Detail/Audience.vue";
export default {
  data() {
    return {
      colors: getColors(),
      color: "",
      selected_color: null,
      is_selected_color_hex: false,
      color_text: "",
      is_text_field_text: true,
      selectedTab: "",
      audience: null,
      pageUserLinks: [],
      pageNameLength:[],
    };
  },
  props: {
    is_edit_post: {
      type: Boolean,
    },
    post: {
      type: Object,
    },
  },
  watch: {
    color: function() {
      if (this.color && this.color.length >= 7)
        return (this.color = this.color.slice(0, 7));

      if (!this.color || (this.color && !this.color.includes("#")))
        this.color = `#${this.color}`;
    },
    color_text(value) {
      this.is_text_field_text = value ? false : true;
    },
    selected_color(value) {
      this.$store.commit("SET_TYPE_DESCRIPTION", value);
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      createPostOptionsContainer.style.display = "none";
    },
    selectedTab(value) {
      if (value) this.hideTopBarAndShowPostBtn();
    },
  },
  components: {
    SelectAudience,
    MentionedAndTagBox,
  },
  computed: {
    ...mapGetters({
      form_data: "create_color_post_form_map_to_props",
    }),
    color_value() {
      const { form_data } = this;

      return form_data && form_data.color_value ? form_data.color_value : "";
    },
    errors() {
      const { form_data } = this;

      return form_data && form_data.errors && form_data.errors.length
        ? form_data.errors
        : [];
    },
    loading() {
      const { form_data } = this;

      return form_data && form_data.loading && form_data.loading
        ? form_data.loading
        : false;
    },
    shared_with() {
      const { shared_with } = this.form_data;

      return shared_with ? shared_with : "";
    },
  },
  mounted() {
    this.$store.commit("SET_TYPE", "coloured");
    this.scrollColorsList();
    this.color_text =
      this.post && this.post.short_content ? this.post.short_content.replace(/<\/?[^>]+(>|$)/g, "") : "";
    this.selected_color =
      this.post && this.post.post_type_description
        ? this.post.post_type_description
        : "";
  },
  methods: {
    ...mapMutations(["updateCreatePostFormData"]),
    createPost() {
      this.$emit("createColoredPost");
    },
    goBack() {
      this.selected_color = null;
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      if (createPostOptionsContainer.style.display === "none")
        createPostOptionsContainer.style.display = "block";
      this.$parent.goBack();
    },
    onChange(e) {
      const { name, value } = e.target || {};

      this.updateCreatePostFormData({
        [name]: value,
      });
    },
    addColor() {
      if (!this.color) return;

      this.colors.unshift({ color: this.color, isHex: true });
      this.color = "";
    },
    onSelectColor(data, index) {
      const { color, isHex } = data || {};
      this.selected_color = color;
      this.is_selected_color_hex = isHex ? true : false;
    },
    saveContentToStore() {
      let payload = {
        content: this.color_text,
        links: this.pageUserLinks,
        lengthName: this.pageNameLength
      }
      this.$store.commit("SET_SHORT_CONTENT", payload);
    },
    scrollColorsList() {
      const slider = document.getElementById("colors-list");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        // console.log(walk);
      });
    },
    hideTopBarAndShowPostBtn() {
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      createPostOptionsContainer.style.display = "none";
      const postColoredPostBtn = document.getElementById(
        "create-colored-post-btn"
      );
      postColoredPostBtn.style.display = "block";
    },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      this.audience = audience.toLowerCase().replace(/ /g, '_');
      const createPostOptionsContainer = document.getElementById(
        "create-post-option-container"
      );
      if (
        createPostOptionsContainer.style.display === "none" &&
        !this.selected_color
      )
        createPostOptionsContainer.style.display = "block";
    },
    closeModal(){
      this.$emit('closeModal');
    },
    setPageUserLinks(links, length){
      this.pageUserLinks = []
      this.pageNameLength = []
      this.pageUserLinks = [...this.pageUserLinks, ...links]
      this.pageNameLength = [...this.pageNameLength, ...length]
    },
  },
};
</script>
<style lang="scss" scoped>
.colors-list {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: pointer;
}

.colors-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.colors-list {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.items.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  transform: scale(1);
}

.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  width: 250px;
  height: 40px;
  background: #f3f3f3;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
.cross {
  width: 100%;
  color: #fff;
  padding: 10px 20px 0px 0px;
  display: flex;
  justify-content: flex-end;
  span{
    background: #fff;
  }
}
</style>
