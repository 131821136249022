<template>
  <div class="w-100">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto mb-5">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">

                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M4.30846 5.41379C3.87198 5.41379 3.4453 5.28436 3.08237 5.04186C2.71945 4.79937 2.43659 4.4547 2.26955 4.05144C2.10252 3.64818 2.05881 3.20445 2.14397 2.77635C2.22912 2.34826 2.43931 1.95503 2.74795 1.64639C3.05659 1.33775 3.44982 1.12756 3.87792 1.04241C4.30601 0.957252 4.74975 1.00096 5.153 1.16799C5.55626 1.33503 5.90093 1.61789 6.14343 1.98081C6.38592 2.34373 6.51536 2.77041 6.51536 3.2069C6.51536 3.7922 6.28285 4.35354 5.86897 4.76741C5.4551 5.18128 4.89377 5.41379 4.30846 5.41379ZM4.30846 2.10345C4.09022 2.10345 3.87688 2.16817 3.69542 2.28941C3.51396 2.41066 3.37252 2.583 3.28901 2.78463C3.20549 2.98625 3.18364 3.20812 3.22621 3.42217C3.26879 3.63622 3.37388 3.83283 3.5282 3.98715C3.68252 4.14147 3.87914 4.24657 4.09319 4.28914C4.30724 4.33172 4.5291 4.30987 4.73073 4.22635C4.93236 4.14283 5.1047 4.0014 5.22594 3.81994C5.34719 3.63848 5.41191 3.42514 5.41191 3.2069C5.41191 2.91424 5.29565 2.63358 5.08872 2.42664C4.88178 2.21971 4.60111 2.10345 4.30846 2.10345Z"
                      fill="#47505B" stroke="#47505B" stroke-width="0.3"/>
                  <path
                      d="M13.1366 5.41379C12.7001 5.41379 12.2734 5.28436 11.9105 5.04186C11.5476 4.79937 11.2647 4.4547 11.0977 4.05144C10.9306 3.64818 10.8869 3.20445 10.9721 2.77635C11.0572 2.34826 11.2674 1.95503 11.5761 1.64639C11.8847 1.33775 12.2779 1.12756 12.706 1.04241C13.1341 0.957252 13.5779 1.00096 13.9811 1.16799C14.3844 1.33503 14.7291 1.61789 14.9716 1.98081C15.214 2.34373 15.3435 2.77041 15.3435 3.2069C15.3435 3.7922 15.111 4.35354 14.6971 4.76741C14.2832 5.18128 13.7219 5.41379 13.1366 5.41379ZM13.1366 2.10345C12.9183 2.10345 12.705 2.16817 12.5235 2.28941C12.3421 2.41066 12.2006 2.583 12.1171 2.78463C12.0336 2.98625 12.0118 3.20812 12.0543 3.42217C12.0969 3.63622 12.202 3.83283 12.3563 3.98715C12.5106 4.14147 12.7073 4.24657 12.9213 4.28914C13.1354 4.33172 13.3572 4.30987 13.5589 4.22635C13.7605 4.14283 13.9328 4.0014 14.0541 3.81994C14.1753 3.63848 14.24 3.42514 14.24 3.2069C14.24 2.91424 14.1238 2.63358 13.9168 2.42664C13.7099 2.21971 13.4292 2.10345 13.1366 2.10345Z"
                      fill="#47505B" stroke="#47505B" stroke-width="0.3"/>
                  <path
                      d="M14.2414 17.0003H12.0345C11.7418 17.0003 11.4612 16.884 11.2542 16.6771C11.0473 16.4702 10.931 16.1895 10.931 15.8969V12.0348H12.0345V15.8969H14.2414V10.9313H15.3448V7.62099C15.3448 7.47467 15.2867 7.33433 15.1832 7.23087C15.0798 7.1274 14.9394 7.06927 14.7931 7.06927H11.251L8.72414 11.4831L6.19724 7.06927H2.65517C2.50885 7.06927 2.36851 7.1274 2.26504 7.23087C2.16158 7.33433 2.10345 7.47467 2.10345 7.62099V10.9313H3.2069V15.8969H5.41379V12.0348H6.51724V15.8969C6.51724 16.1895 6.40099 16.4702 6.19405 16.6771C5.98711 16.884 5.70645 17.0003 5.41379 17.0003H3.2069C2.91424 17.0003 2.63358 16.884 2.42664 16.6771C2.2197 16.4702 2.10345 16.1895 2.10345 15.8969V12.0348C1.8108 12.0348 1.53013 11.9185 1.32319 11.7116C1.11626 11.5047 1 11.224 1 10.9313V7.62099C1 7.18201 1.17438 6.76101 1.48479 6.45061C1.79519 6.1402 2.21619 5.96582 2.65517 5.96582H6.83724L8.72414 9.27617L10.611 5.96582H14.7931C15.2321 5.96582 15.6531 6.1402 15.9635 6.45061C16.2739 6.76101 16.4483 7.18201 16.4483 7.62099V10.9313C16.4483 11.224 16.332 11.5047 16.1251 11.7116C15.9181 11.9185 15.6375 12.0348 15.3448 12.0348V15.8969C15.3448 16.1895 15.2286 16.4702 15.0216 16.6771C14.8147 16.884 14.534 17.0003 14.2414 17.0003Z"
                      fill="#47505B" stroke="#47505B" stroke-width="0.3"/>
                </svg>

                Partners
              </div>
            </div>
            <div class="panel_body_tabs">
              <!-- Nav tabs -->
              <ul class="nav nav_tabs_iconed mb-0" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#Partners">
                    <div class="svg_icon_tabs">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                          <path
                              d="M10.666 14V12.6667C10.666 11.9594 10.3851 11.2811 9.88497 10.781C9.38487 10.281 8.70659 10 7.99935 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path
                              d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M11.334 7.33333L12.6673 8.66667L15.334 6" stroke="#47505B" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    Partners</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#Invites">
                    <div class="svg_icon_tabs">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                          <path
                              d="M10.666 14V12.6667C10.666 11.9594 10.3851 11.2811 9.88497 10.781C9.38487 10.281 8.70659 10 7.99935 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14"
                              stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path
                              d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                              stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M13.334 5.33203V9.33203" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path d="M15.334 7.33203H11.334" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    Invites</a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div class="tab-content">
                <div id="Partners" class="tab-pane active">
                  <div class="artist-setting-panel">
                    <div class="d-flex" >
                      <div class="d-flex mb-30 main-search-div col-10 p-0">
                        <div class="col-1 d-flex align-items-center justify-content-center">
                          <span><img style="margin: 0!important;" :src="require(`@/assets/icons/search-icon.svg`)"></span>
                        </div>
                        <div class="col-8 d-flex align-items-center">
                          <v-select class="v-select-main-class v-select-without-border" label="title"
                                    :filterable="false"
                                    :options="searchData"
                                    v-model="selected_partner"
                                    :reduce="(option) => option.id"
                                    placeholder="Search for Record Label, Artist or Management partners"
                                    @search="onSearch">
                            <template slot="no-options">
                              Search for Record Label, Artist or Management partners
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                <img class="search-image" :src='option.artwork_url'/>
                                {{ option.title }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                <img class="search-image" :src='option.artwork_url'/>
                                {{ option.title }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                        <div class="col-3 user-roles-main-div">
                          <b-form-select class="user-roles" v-model="selected_role" :options="usersTypesList" value-field="id" text-field="title"></b-form-select>
                        </div>
                      </div>
                      <div class="col-2">
                        <button class="add-button" type="button" :disabled="!selected_role && !selected_partner" @click="addMoveRemovePartner('add')">ADD</button>
                      </div>
                    </div>
                    <div class="no-content-here" v-if="getTotalPartners < 1">
                      <div class="no-detail-content">
                        <div class="category-content-icon">
                          <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.30677 12.4143C8.07911 12.4143 6.87902 12.0503 5.85826 11.3682C4.8375 10.6862 4.04191 9.71675 3.5721 8.58254C3.1023 7.44833 2.97938 6.20027 3.21888 4.9962C3.45839 3.79213 4.04956 2.68612 4.91765 1.81804C5.78573 0.949951 6.89174 0.358776 8.09581 0.119272C9.29988 -0.120233 10.5479 0.00268944 11.6821 0.472495C12.8164 0.9423 13.7858 1.73789 14.4678 2.75865C15.1499 3.77941 15.5139 4.9795 15.5139 6.20716C15.5139 7.8534 14.86 9.43222 13.6959 10.5963C12.5318 11.7604 10.953 12.4143 9.30677 12.4143ZM9.30677 3.10358C8.69294 3.10358 8.0929 3.2856 7.58251 3.62663C7.07213 3.96765 6.67434 4.45237 6.43944 5.01947C6.20453 5.58658 6.14307 6.2106 6.26282 6.81264C6.38258 7.41467 6.67816 7.96768 7.11221 8.40172C7.54625 8.83577 8.09926 9.13135 8.70129 9.25111C9.30333 9.37086 9.92735 9.3094 10.4945 9.07449C11.0616 8.83959 11.5463 8.4418 11.8873 7.93142C12.2283 7.42104 12.4103 6.82099 12.4103 6.20716C12.4103 5.38404 12.0834 4.59463 11.5013 4.0126C10.9193 3.43056 10.1299 3.10358 9.30677 3.10358Z" fill="#47505B"/>
                            <path d="M34.1349 12.4143C32.9072 12.4143 31.7071 12.0503 30.6864 11.3682C29.6656 10.6862 28.87 9.71675 28.4002 8.58254C27.9304 7.44833 27.8075 6.20027 28.047 4.9962C28.2865 3.79213 28.8777 2.68612 29.7458 1.81804C30.6139 0.949951 31.7199 0.358776 32.9239 0.119272C34.128 -0.120233 35.3761 0.00268944 36.5103 0.472495C37.6445 0.9423 38.6139 1.73789 39.296 2.75865C39.978 3.77941 40.3421 4.9795 40.3421 6.20716C40.3421 7.8534 39.6881 9.43222 38.524 10.5963C37.36 11.7604 35.7811 12.4143 34.1349 12.4143ZM34.1349 3.10358C33.5211 3.10358 32.921 3.2856 32.4106 3.62663C31.9003 3.96765 31.5025 4.45237 31.2676 5.01947C31.0327 5.58658 30.9712 6.2106 31.091 6.81264C31.2107 7.41467 31.5063 7.96768 31.9403 8.40172C32.3744 8.83577 32.9274 9.13135 33.5294 9.25111C34.1315 9.37086 34.7555 9.3094 35.3226 9.07449C35.8897 8.83959 36.3744 8.4418 36.7154 7.93142C37.0565 7.42104 37.2385 6.82099 37.2385 6.20716C37.2385 5.38404 36.9115 4.59463 36.3295 4.0126C35.7474 3.43056 34.958 3.10358 34.1349 3.10358Z" fill="#47505B"/>
                            <path d="M37.2429 45.0006H31.0358C30.2127 45.0006 29.4233 44.6737 28.8412 44.0916C28.2592 43.5096 27.9322 42.7202 27.9322 41.8971V31.0345H31.0358V41.8971H37.2429V27.9309H40.3465V18.6202C40.3465 18.2087 40.183 17.8139 39.892 17.5229C39.601 17.2319 39.2063 17.0684 38.7947 17.0684H28.8322L21.7251 29.4827L14.6179 17.0684H4.65537C4.24381 17.0684 3.8491 17.2319 3.55809 17.5229C3.26707 17.8139 3.10358 18.2087 3.10358 18.6202V27.9309H6.20716V41.8971H12.4143V31.0345H15.5179V41.8971C15.5179 42.7202 15.1909 43.5096 14.6089 44.0916C14.0268 44.6737 13.2374 45.0006 12.4143 45.0006H6.20716C5.38404 45.0006 4.59463 44.6737 4.0126 44.0916C3.43056 43.5096 3.10358 42.7202 3.10358 41.8971V31.0345C2.28046 31.0345 1.49105 30.7075 0.909017 30.1255C0.326983 29.5435 0 28.7541 0 27.9309V18.6202C0 17.3855 0.490475 16.2014 1.36353 15.3284C2.23658 14.4553 3.42069 13.9648 4.65537 13.9648H16.4179L21.7251 23.2756L27.0322 13.9648H38.7947C40.0294 13.9648 41.2135 14.4553 42.0866 15.3284C42.9596 16.2014 43.4501 17.3855 43.4501 18.6202V27.9309C43.4501 28.7541 43.1231 29.5435 42.5411 30.1255C41.9591 30.7075 41.1696 31.0345 40.3465 31.0345V41.8971C40.3465 42.7202 40.0195 43.5096 39.4375 44.0916C38.8555 44.6737 38.0661 45.0006 37.2429 45.0006Z" fill="#47505B"/>
                          </svg>


                        </div>
                        <div class="no-detail-content-heading">
                          There are no Partners just yet
                        </div>
                        <div class="no-detail-content-subheading">
                          Link up with partners so as to build alliances on your page!
                        </div>
                      </div>
                    </div>
                    <div class="admins-detail" v-if="getPartnerArtist && getPartnerArtist.length > 0 && getTotalPartners > 0">
                      <h1>ARTIST ({{getPartnerArtist.length}})</h1>
                      <ul>
                        <li v-for="(artist,index) in getPartnerArtist" :key="index">

                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="artist.artwork_url">
                              </div>
                             <div>
                               <div class="artist-admin-name">{{artist.title}}</div>
                               <div class="d-flex ml-2 mt-1">
                                 <div class="d-flex align-items-center">
                                   <div class="svg-icon mr-2">
                                     <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                       <g opacity="0.9">
                                         <path
                                           d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                           stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                           stroke-linejoin="round"></path>
                                         <path
                                           d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                           stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                           stroke-linejoin="round"></path>
                                       </g>
                                     </svg>
                                   </div>
                                   <span>{{fourdigitsnumber(artist.members)}}</span>
                                 </div>
                                 <div class="d-flex ml-2 align-items-center">
                                   <div class="svg-icon mr-2">
                                     <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                       <g opacity="0.9">
                                         <path
                                           d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                           stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                           stroke-linejoin="round"></path>
                                         <path
                                           d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                           stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                           stroke-linejoin="round"></path>
                                         <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                               stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </g>
                                     </svg>
                                   </div>
                                   <span>{{fourdigitsnumber(artist.total_tracks)}}</span>
                                 </div>
                               </div>
                             </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(artist.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">

                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="admins-detail" v-if="getPartnerBookingAgent && getPartnerBookingAgent.length > 0 && getTotalPartners > 0">
                      <h1>BOOKING AGENT ({{getPartnerBookingAgent.length}})</h1>
                      <ul>
                        <li v-for="(agent,index) in getPartnerBookingAgent" :key="index">
                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="agent.artwork_url">
                              </div>
                              <div>
                                <div class="artist-admin-name">{{agent.title}}</div>
                                <div class="d-flex ml-2 mt-1">
                                  <div class="d-flex align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(agent.members)}}</span>
                                  </div>
                                  <div class="d-flex ml-2 align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(agent.total_tracks)}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(agent.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">

                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="admins-detail" v-if="getPartnerDistributor && getPartnerDistributor.length > 0 && getTotalPartners > 0" >
                      <h1>DISTRIBUTOR ({{getPartnerDistributor.length}})</h1>
                      <ul>
                        <li v-for="(distributor,index) in getPartnerDistributor" :key="index">
                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="distributor.artwork_url">
                              </div>
                              <div>
                                <div class="artist-admin-name">{{distributor.title}}</div>
                                <div class="d-flex ml-2 mt-1">
                                  <div class="d-flex align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(distributor.members)}}</span>
                                  </div>
                                  <div class="d-flex ml-2 align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(distributor.total_tracks)}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(distributor.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">

                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="admins-detail" v-if="getPartnerManagement && getPartnerManagement.length > 0 && getTotalPartners > 0">
                      <h1>MANAGEMENT ({{getPartnerManagement.length}})</h1>
                      <ul>
                        <li v-for="(management,index) in getPartnerManagement" :key="index">
                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="management.artwork_url">
                              </div>
                              <div>
                                <div class="artist-admin-name">{{management.title}}</div>
                                <div class="d-flex ml-2 mt-1">
                                  <div class="d-flex align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(management.members)}}</span>
                                  </div>
                                  <div class="d-flex ml-2 align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(management.total_tracks)}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(management.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">

                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="admins-detail" v-if="getPartnerPublishing && getPartnerPublishing.length > 0 && getTotalPartners > 0">
                      <h1>PUBLISHING ({{getPartnerPublishing.length}})</h1>
                      <ul>
                        <li v-for="(publisher,index) in getPartnerPublishing" :key="index">
                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="publisher.artwork_url">
                              </div>
                              <div>
                                <div class="artist-admin-name">{{publisher.title}}</div>
                                <div class="d-flex ml-2 mt-1">
                                  <div class="d-flex align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(publisher.members)}}</span>
                                  </div>
                                  <div class="d-flex ml-2 align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(publisher.total_tracks)}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(publisher.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">

                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="admins-detail" v-if="getPartnerRecordLabel && getPartnerRecordLabel.length > 0 && getTotalPartners > 0">
                      <h1>RECORD LABELS ({{getPartnerRecordLabel.length}})</h1>
                      <ul>
                        <li v-for="(label,index) in getPartnerRecordLabel" :key="index">
                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="label.artwork_url">
                              </div>
                              <div>
                                <div class="artist-admin-name">{{label.title}}</div>
                                <div class="d-flex ml-2 mt-1">
                                  <div class="d-flex align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(label.members)}}</span>
                                  </div>
                                  <div class="d-flex ml-2 align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(label.total_tracks)}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(label.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">

                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="admins-detail" v-if="getPartnerSponsor && getPartnerSponsor.length > 0 && getTotalPartners > 0">
                      <h1>SPONSOR ({{getPartnerSponsor.length}})</h1>
                      <ul>
                        <li v-for="(sponsor,index) in getPartnerSponsor" :key="index">
                          <div class="artist-admin-detail">
                            <div class="ar-admin-name-img">
                              <div class="artist-admin-image">
                                <img :src="sponsor.artwork_url">
                              </div>
                              <div>
                                <div class="artist-admin-name">{{sponsor.title}}</div>
                                <div class="d-flex ml-2 mt-1">
                                  <div class="d-flex align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(sponsor.members)}}</span>
                                  </div>
                                  <div class="d-flex ml-2 align-items-center">
                                    <div class="svg-icon mr-2">
                                      <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.9">
                                          <path
                                            d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path
                                            d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                          <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </g>
                                      </svg>
                                    </div>
                                    <span>{{fourdigitsnumber(sponsor.total_tracks)}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex ml-auto">
                              <button type="button" class="outline_danger_button_md_iconed w110" @click="acceptDeclineInvite(sponsor.partner_id.id,'remove',0)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                REMOVE
                              </button>
                              <div class="dropdown ml-2">
                                <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                                  <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                    <path
                                      d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                      stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                    Cancel Partner
                                  </button>
                                  <div class="border_line"></div>
                                  <button class="dropdown-item" type="button">
                                    <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                    Report user
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex justify-content-end mt_30">
                      <button class="prime_button " type="button">Save Changes</button>
                    </div>
                  </div>
                </div>
                <div id="Invites" class="tab-pane fade">
                  <div class="sponsor_member_listing" v-if="inviteList.length > 0">
                    <div class="search-filter-added">
                      <input
                      type="text"
                      placeholder="Search by name"
                      v-model="search"
                      @input="searchInvites"
                    />
                      <span
                        class="svg-serch"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                               xmlns="http://www.w3.org/2000/svg"><path
                        d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                        stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path><path
                        d="M12.5 12.501L14.5 14.501" stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                        stroke-linejoin="round"></path></svg></span></div>
                    <ul>
                      <li class="" v-for="(invite,index) in inviteList" :key="index">
                        <div class="ar-admin-name-img member_info">
                          <div class="artist-admin-image">
                            <img :src="(invite && invite.invited_page)? invite.invited_page.artwork_url :''">
                          </div>
                          <div>
                            <div class="artist-admin-name">{{(invite && invite.invited_page)? invite.invited_page.title :'name'}}</div>
                            <div class="d-flex ml-2 mt-1">
                              <div class="d-flex align-items-center">
                                <div class="svg-icon mr-2">
                                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.9">
                                      <path
                                        d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                                        stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                      <path
                                        d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                                        stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    </g>
                                  </svg>
                                </div>
                                <span>{{(invite && invite.invited_page)? fourdigitsnumber(invite.invited_page.members) :'0000'}}</span>
                              </div>
                              <div class="d-flex ml-2 align-items-center">
                                <div class="svg-icon mr-2">
                                  <svg width="11" height="12" viewBox="0 0 11 12" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.9">
                                      <path
                                        d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                                        stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                      <path
                                        d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                                        stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                      <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F"
                                            stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </g>
                                  </svg>
                                </div>
                                <span>{{(invite && invite.invited_page)? fourdigitsnumber(invite.invited_page.total_tracks) :'0000'}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="partner_label">
                          {{(invite && invite.role)? invite.role.title :'Role'}}
                        </div>
                        <div class="d-flex ml-auto">
                          <button class="button_accept" @click="acceptDeclineInvite(invite.id,'accept',1)">Accept</button>
                          <button class="button_decline ml-2" @click="acceptDeclineInvite(invite.id,'decline',1)">Decline</button>
                          <div class="dropdown ml-2">
                            <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                              <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path
                                    d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path
                                    d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              </svg>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                              <button class="dropdown-item" type="button">

                                <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                                Cancel Partner
                              </button>
                              <div class="border_line"></div>
                              <button class="dropdown-item" type="button">

                                <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                                Report user
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="d-flex justify-content-end">
                      <button class="prime_button w200" type="button">Save Changes</button>
                    </div>
                  </div>
                  <div v-else class="artist-setting-panel">
                    <div class="no-content-here">
                      <div class="no-detail-content">
                        <div class="category-content-icon">
                          <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.30677 12.4143C8.07911 12.4143 6.87902 12.0503 5.85826 11.3682C4.8375 10.6862 4.04191 9.71675 3.5721 8.58254C3.1023 7.44833 2.97938 6.20027 3.21888 4.9962C3.45839 3.79213 4.04956 2.68612 4.91765 1.81804C5.78573 0.949951 6.89174 0.358776 8.09581 0.119272C9.29988 -0.120233 10.5479 0.00268944 11.6821 0.472495C12.8164 0.9423 13.7858 1.73789 14.4678 2.75865C15.1499 3.77941 15.5139 4.9795 15.5139 6.20716C15.5139 7.8534 14.86 9.43222 13.6959 10.5963C12.5318 11.7604 10.953 12.4143 9.30677 12.4143ZM9.30677 3.10358C8.69294 3.10358 8.0929 3.2856 7.58251 3.62663C7.07213 3.96765 6.67434 4.45237 6.43944 5.01947C6.20453 5.58658 6.14307 6.2106 6.26282 6.81264C6.38258 7.41467 6.67816 7.96768 7.11221 8.40172C7.54625 8.83577 8.09926 9.13135 8.70129 9.25111C9.30333 9.37086 9.92735 9.3094 10.4945 9.07449C11.0616 8.83959 11.5463 8.4418 11.8873 7.93142C12.2283 7.42104 12.4103 6.82099 12.4103 6.20716C12.4103 5.38404 12.0834 4.59463 11.5013 4.0126C10.9193 3.43056 10.1299 3.10358 9.30677 3.10358Z" fill="#47505B"/>
                            <path d="M34.1349 12.4143C32.9072 12.4143 31.7071 12.0503 30.6864 11.3682C29.6656 10.6862 28.87 9.71675 28.4002 8.58254C27.9304 7.44833 27.8075 6.20027 28.047 4.9962C28.2865 3.79213 28.8777 2.68612 29.7458 1.81804C30.6139 0.949951 31.7199 0.358776 32.9239 0.119272C34.128 -0.120233 35.3761 0.00268944 36.5103 0.472495C37.6445 0.9423 38.6139 1.73789 39.296 2.75865C39.978 3.77941 40.3421 4.9795 40.3421 6.20716C40.3421 7.8534 39.6881 9.43222 38.524 10.5963C37.36 11.7604 35.7811 12.4143 34.1349 12.4143ZM34.1349 3.10358C33.5211 3.10358 32.921 3.2856 32.4106 3.62663C31.9003 3.96765 31.5025 4.45237 31.2676 5.01947C31.0327 5.58658 30.9712 6.2106 31.091 6.81264C31.2107 7.41467 31.5063 7.96768 31.9403 8.40172C32.3744 8.83577 32.9274 9.13135 33.5294 9.25111C34.1315 9.37086 34.7555 9.3094 35.3226 9.07449C35.8897 8.83959 36.3744 8.4418 36.7154 7.93142C37.0565 7.42104 37.2385 6.82099 37.2385 6.20716C37.2385 5.38404 36.9115 4.59463 36.3295 4.0126C35.7474 3.43056 34.958 3.10358 34.1349 3.10358Z" fill="#47505B"/>
                            <path d="M37.2429 45.0006H31.0358C30.2127 45.0006 29.4233 44.6737 28.8412 44.0916C28.2592 43.5096 27.9322 42.7202 27.9322 41.8971V31.0345H31.0358V41.8971H37.2429V27.9309H40.3465V18.6202C40.3465 18.2087 40.183 17.8139 39.892 17.5229C39.601 17.2319 39.2063 17.0684 38.7947 17.0684H28.8322L21.7251 29.4827L14.6179 17.0684H4.65537C4.24381 17.0684 3.8491 17.2319 3.55809 17.5229C3.26707 17.8139 3.10358 18.2087 3.10358 18.6202V27.9309H6.20716V41.8971H12.4143V31.0345H15.5179V41.8971C15.5179 42.7202 15.1909 43.5096 14.6089 44.0916C14.0268 44.6737 13.2374 45.0006 12.4143 45.0006H6.20716C5.38404 45.0006 4.59463 44.6737 4.0126 44.0916C3.43056 43.5096 3.10358 42.7202 3.10358 41.8971V31.0345C2.28046 31.0345 1.49105 30.7075 0.909017 30.1255C0.326983 29.5435 0 28.7541 0 27.9309V18.6202C0 17.3855 0.490475 16.2014 1.36353 15.3284C2.23658 14.4553 3.42069 13.9648 4.65537 13.9648H16.4179L21.7251 23.2756L27.0322 13.9648H38.7947C40.0294 13.9648 41.2135 14.4553 42.0866 15.3284C42.9596 16.2014 43.4501 17.3855 43.4501 18.6202V27.9309C43.4501 28.7541 43.1231 29.5435 42.5411 30.1255C41.9591 30.7075 41.1696 31.0345 40.3465 31.0345V41.8971C40.3465 42.7202 40.0195 43.5096 39.4375 44.0916C38.8555 44.6737 38.0661 45.0006 37.2429 45.0006Z" fill="#47505B"/>
                          </svg>

                        </div>
                        <div class="no-detail-content-heading">
                          There are no invites just yet
                        </div>
                        <div class="no-detail-content-subheading">
                          Look out for invites so as to build alliances on your page!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
//  import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import '/public/css/vue-select-style.scss';
import { actionPartnerInvitation, addOrUpdatePageUser, addOrUpdatePartner, removePartner } from "../../../apis/APIs";
import createFormData from "../../../mixins/common";
import appConfig from "../../../configs/AppConfig";

export default {
  data() {
    return {
      selected_partner: '',
      selected_role: '',
      selected_role_name: '',
      inviteList: [],
      search:''
    }
  },
  components: {
    // MusicSubHeader,
    vSelect
  },
  watch:{
    getPartnerInvites: {
      immediate: true,
      handler(newVal) {
        this.inviteList = newVal;
      },
    },
  },
  computed: {
    getPartnerArtist() {
      return this.$store.getters.getCurrentPagePartnerArtist
    },
    getPartnerBookingAgent() {
      return this.$store.getters.getCurrentPagePartnerBookingAgent
    },
    getPartnerDistributor() {
      return this.$store.getters.getCurrentPagePartnerDistributor
    },
    getPartnerManagement() {
      return this.$store.getters.getCurrentPagePartnerManagement
    },
    getPartnerPublishing() {
      return this.$store.getters.getCurrentPagePartnerPublishing
    },
    getPartnerRecordLabel() {
      return this.$store.getters.getCurrentPagePartnerRecordLabel
    },
    getPartnerSponsor() {
      return this.$store.getters.getCurrentPagePartnerSponsor
    },
    getTotalPartners()
    {
      return this.getPartnerArtist.length+this.getPartnerBookingAgent.length+this.getPartnerDistributor.length+
        this.getPartnerManagement.length+this.getPartnerPublishing.length+this.getPartnerRecordLabel.length+this.getPartnerSponsor.length
    },
    getPartnerInvites(){
      return this.$store.getters.getCurrentPagePartnerInvites
    },
    searchData() {
      return this.$store.getters.getSearchedPartnersData
    },
    usersTypesList() {
      return this.$store.getters.getPagePartnerRoles
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    },
  },
  mounted() {
    this.$store.dispatch('getPartnerRoles')
    this.$store.dispatch('getPagePartners', this.$route.params.id)
    this.$store.dispatch('getPagePartnerInvites', this.$route.params.id)
  },
  methods: {
    onSearch(search, loading) {
      loading(true);
      let payload={
        page_id: this.$route.params.id,search,
        title:search
      }
      this.$store.dispatch('searchPartner',payload )
      loading(false);
    },
    async addMoveRemovePartner(function_type, user_type = '', id = '') {
      let body = {};
      let role = '';
      if (function_type === 'add') {
        role = this.getRoleName(this.selected_role)
        body.partner_role_id = this.selected_role

        body.action = 1
      } else if (function_type === 'remove') {
        if (user_type === 'Admin') {
          body.role_id = 5
          role = this.getRoleName(5)
        } else if (user_type === 'Moderator') {
          body.role_id = 6
          role = this.getRoleName(6)
        } else if (user_type === 'Editor') {
          body.role_id = 7
          role = this.getRoleName(7)
        } else if (user_type === 'Advertiser') {
          body.role_id = 8
          role = this.getRoleName(8)
        }
        body.action = 0
      } else if (function_type === 'move') {
        if (user_type === 'Admin') {
          body.role_id = 5
          role = this.getRoleName(5)
        } else if (user_type === 'Moderator') {
          body.role_id = 6
          role = this.getRoleName(6)
        } else if (user_type === 'Editor') {
          body.role_id = 7
          role = this.getRoleName(7)
        } else if (user_type === 'Advertiser') {
          body.role_id = 8
          role = this.getRoleName(8)
        }
        body.action = 1
      }
      try {
        body.invited_by_user = this.userId
        body.invited_by_page = this.$route.params.id
        body.invited_to_page = (id) ? id : this.selected_partner
        body.url = window.location.origin + '/page-manager/page/notification/' + this.$route.params.id +'/'+ role
        await this.$store.dispatch('loading', true);
        const formData = createFormData(body);
        const { data } = await addOrUpdatePartner(formData);
        const { success, message } = data || ''
        await this.$store.dispatch('loading', false);
        if (success === 1 ) {
          this.resetData();
          await this.$store.dispatch('getPagePartners', this.$route.params.id)
          await this.makeToast('success', 'Admins Page', `Successfully ${message}`)
        } else {
          await this.makeToast('error', 'Admins Page', message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false);
        console.error(e.message)
        await this.makeToast('error', 'Admins Page', 'Please try again!')
      }
    },
    resetData() {
      this.selected_partner = ''
      this.selected_role = ''
    },
    getRoleName (value){
      const index = this.usersTypesList.findIndex(e => e.id === value)
      if (index !== -1) {
        return this.usersTypesList[index].title
      }
    },
    async acceptDeclineInvite(invite_id,status,action){
      let body={}
      body.invite_id=invite_id
      body.status=status
      body.action=action
      const formData = createFormData(body);
      await actionPartnerInvitation(this.$route.params.id,formData);
      this.$store.dispatch('getPagePartnerInvites', this.$route.params.id)
    },
    searchInvites() {
      let searchWord = this.search;

        const invites = this.getPartnerInvites;
        const result = invites.filter((o) => o.invited_page.title.toLowerCase().includes(searchWord.toLowerCase()));
        this.inviteList = result;

    },
  }
}
</script>

<style scoped>

.main-search-div {
  border: 1px solid #C4CCD6;
  height: 2.5rem;
  border-radius: 4px;
}

.search-image {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}
.v-select-main-class {
  width: 100%;
  border: none!important;
  /*margin-top: 20px !important;*/
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
.user-roles-main-div {
  border-left: 1px solid #C4CCD6;
  padding: 0;
}
.user-roles {
  border: none;
}

.add-button {
  width: 6.5rem;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  background: #D1433A;
  color: #fff;
  border: 1px solid #D1433A;
  border-radius: 4px;
  transition: 0.2s all ease;
  font-family: 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
}

.admin-detail {
  margin-bottom: 20px;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
}

.admin-detail h1 {
  font-size: 13px;
  font-weight: 700;
  padding: 17px 30px;
  border-bottom: 1px solid #E6EAF0;
  color: #47505B;
  margin-bottom: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

.admin-detail .main-list {
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}

.admin-detail .main-list .sub-list {
  list-style: none;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #E6EAF0;
}

.admin-detail .main-list .sub-list:last-child {
  border-bottom: none;
}

.ar-admin-name-img {
  display: flex;
  align-items: center;
}

.artist-admin-name {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #071526;

}

.artist-admin-image img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}
.status-artist-button {
  width: 110px;
  height: 30px;
  border: 1.5px solid #D1433A;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 700;
  color: #D1433A;
  background-color: #fff;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Helvetica Neue', sans-serif;
}

.status-artist-button:hover {
  background: #D1433A;
  color: #fff;
}

.panel_wrapper {
  margin-top: 59px;
}
</style>
