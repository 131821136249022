<template>
  <div>
    <div
      v-if="$route.path.includes('/event-manager/page/dashboard')"
      class="wr_datePicker"
    >
      <h5>Event Calendar</h5>
      <div class="picker_section">
        <date-picker
          v-model="pickerDate"
          type="date"
          :open="true"
          :confirm="true"
          confirm-text="APPLY"
          :append-to-body="false"
        ></date-picker>
      </div>
    </div>
    <div
      v-if="$route.path.includes('/event-manager/page/dashboard')"
      class="wr_upcoming_event"
    >
      <RelatedUpcomingEvents />
    </div>
    <section
      v-if="!$route.path.includes('/event-manager/page/dashboard')"
      class="wr_event_orders"
    >
      <div class="sec_title">
        <h4>Event Orders</h4>
        <span
          >SEE ALL<span
            class="arrow_btn three_dots_menustyle for_hover_gray_btn"
            ><svg
              width="8"
              height="11"
              viewBox="0 0 8 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 0.728516L6.635 4.90152L1.5 9.44152"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> </span
        ></span>
      </div>
      <EventOrderCardRight
        v-for="item in eventOrdersData"
        :key="item.id"
        :event="item"
      />
    </section>
    <div class="chart_pie">
      <div class="title_pie_chart">Ticket Buyers Statistics</div>
      <ul>
        <li
          @click="handleStastistic(item.id)"
          v-for="item in buyersStatisticdata"
          :key="item.id"
          :class="{ active_statistic: item.active }"
        >
          {{ item.statistic_item }}
        </li>
      </ul>
      <div class="wr_pie">
        <apexchart
          height="100"
          type="pie"
          :options="optionsDashboardCartPia"
          :series="seriesDashboardCartPia"
        ></apexchart>
      </div>
    </div>
    <div
      v-if="!$route.path.includes('/event-manager/page/dashboard')"
      class="delete_event"
    >
      <p>You can delete your Event</p>
      <button class="delete three_dots_menustyle for_hover_gray_btn">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.7">
            <path
              d="M12 4L4 12"
              stroke="#071526"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 4L12 12"
              stroke="#071526"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        Delete Event
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import RelatedUpcomingEvents from "@/components/Events/components/Detail/RelatedUpcomingEvents.vue";
import EventOrderCardRight from "./../EMElement/EventOrderCardRight.vue";
export default {
  components: {
    DatePicker,
    RelatedUpcomingEvents,
    EventOrderCardRight,
  },
  data() {
    return {
      pickerDate: "",
      optionsDashboardCartPia: {
        colors: ["#FC7C5F", "#D1433A"],
        chart: {},
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        legend: {
          fontSize: "13px",
          markers: {
            width: 18,
            height: 18,
          },
        },
        labels: ["Male (32%)", "Female (68%)"],
      },
      seriesDashboardCartPia: [32, 68],

      buyersStatisticdata: [
        { id: 1, statistic_item: "Gender", active: true },
        { id: 2, statistic_item: "Age", active: false },
        { id: 3, statistic_item: "Location", active: false },
      ],
      eventOrdersData: [
        {
          id: 1,
          name: "Soundcrash presents Nina Kraviz +",
          by: "Kelly Stewart",
          price: "300",
          date: "29 May 2020, 04:20 PM",
        },
        {
          id: 2,
          name: "Soundcrash presents Nina Kraviz +",
          by: "Kelly Stewart",
          price: "300",
          date: "29 May 2020, 04:20 PM",
        },
        {
          id: 3,
          name: "Soundcrash presents Nina Kraviz +",
          by: "Kelly Stewart",
          price: "300",
          date: "29 May 2020, 04:20 PM",
        },
        {
          id: 4,
          name: "Soundcrash presents Nina Kraviz +",
          by: "Kelly Stewart",
          price: "300",
          date: "29 May 2020, 04:20 PM",
        },
        {
          id: 5,
          name: "Soundcrash presents Nina Kraviz +",
          by: "Kelly Stewart",
          price: "300",
          date: "29 May 2020, 04:20 PM",
        },
      ],
    };
  },
  methods: {
    handleStastistic(id) {
      this.buyersStatisticdata.forEach((el) => {
        if (el.id === id) {
          el.active = true;
        } else {
          el.active = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_event_orders {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 20px;
  .arrow_btn {
    width: 24px;
    margin-left: 10px;
  }
  .sec_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: HelveticaNeueBold;
    font-weight: 700;
    margin-bottom: 20px;
    h4 {
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      margin: 0;
    }
    span {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #071526;
    }
  }
}
.delete_event {
  font-family: HelveticaNeueNormal;
  font-weight: 400;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  font-style: normal;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #47505b;
    margin: 0;
  }
  .delete {
    letter-spacing: 0.7px;
    align-items: center;
    border: none;
    width: 140px;
    font-size: 11px;
    color: #071526;
    opacity: 0.7;
    svg {
      margin-right: 10px;
    }
  }
}
.wr_upcoming_event {
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 20px;
}
.wr_datePicker {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 19px;
  h5 {
    font-family: HelveticaNeueBold;
    color: #071526;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.9;
    margin-bottom: 15px;
  }
}
.mx-datepicker {
  position: relative;
  z-index: 0;
  width: 330px !important;
  ::v-deep {
    .mx-datepicker-popup {
      box-shadow: unset !important;
      -webkit-box-shadow: unset !important;
    }
    .mx-datepicker-popup {
      border: 1px solid #e6eaf0;
      border-radius: 4px;
      margin-top: 0px;
      position: static !important;
    }
    .mx-calendar {
      width: 330px !important;
      padding: 20px 38px 10px;
    }
    .mx-input {
      border: none;
      box-shadow: none;
      display: none;
    }
  }
}
.chart_pie {
  margin-top: 10px;
  background: #ffffff;
  padding: 20px;
  .title_pie_chart {
    font-family: HelveticaNeueBold;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #071526;
  }
  ul {
    padding: 11px 0 25px;
    font-family: HelveticaNeueBold;
    margin: 0;
    list-style-type: none;
    display: flex;
    border-bottom: 1px solid #e6eaf0;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #D1433A;
    li {
      cursor: pointer;
      margin-right: 17px;
    }
    .active_statistic {
      color: #c4ccd6;
    }
  }
  .wr_pie {
    overflow: hidden;
    margin-left: -110px;
    padding-top: 20px;
    ::v-deep {
      .apx-legend-position-right {
        top: 10px !important;
        right: 110px !important;
      }
      .apexcharts-legend-series {
        display: flex;
        align-items: center;
        margin-bottom: 8px !important;
      }
    }
  }
}
</style>