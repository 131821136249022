<template>
  <div class="row">
    <div class="col">
      <div class="news-feed-post-detail news_feed_post_with_image">
        <div class="useinfo_photoimage">
          <div class="news-feed-post-user-detail-first">
            <span class="news-feed-pic">
              <UserPicture :picture="userPicture" :item="post"/>
              <span class="online-signal"></span>
            </span>
          </div>
          <div class="news-feed-pic-detials-photo-video-interset">
            <div class="article-music-industry  mb-2">
              <div class="user_and_link m_b-10">
                <h1
                  @click="redirectWidgetsToProfilePage($event,post && post.user && post.user.username ? post.user.username : '')"
                >
                  {{ post.user_name ? post.user_name : "username" }}
                </h1>
                <p class="link_share">Wrote an article</p>
                <span class="user_dot">
                  <svg
                    width="2"
                    height="2"
                    viewBox="0 0 2 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="1" cy="1" r="1" fill="#8B949F"></circle></svg>
                </span>
                <p class="post-time">{{ postTime }}</p>
                <span class="icon_globe"
                ><svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M1 7H13"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
                <PostDetailDropDown :post="post" :activeTab="activeTab" />
              </div>
              <div
                class="music-industry-title-header "
                @click="pushPostDetail(post.id)"
              >
                {{ post.title ? post.title : "" }}
              </div>
              <div
                class="artist-timeline-description content-item"
                v-if="post.full_content"
                v-html="more ? post.full_content : shorterContent(post.full_content)"
                @click="pushPostDetail(post.id)"
              ></div>
              <!-- seemore and seeless -->
              <div
                v-if="post.full_content.length > 149"
              >
                <PostHashTags v-show="more" :allTags="item.tags" class="mb-4" />
                <div style="margin-top: -20px">
                  <span class="show-more-less-text" @click="more = !more" v-if="!more"> more</span>
                  <span class="show-more-less-text" @click="more = !more" v-else>less</span>
                </div>
              </div>
              <PostHashTags v-else :allTags="item.tags" />
            </div>
            <div class="news-feed-pic-detials-photo-video1">
              <div
                class="photo-row"
                v-if="post.artwork_url && post.artwork_url.length > 1"
              >
                <div
                  class="photo-timeline"
                  v-for="(eachOp, index) in post.artwork_url"
                  :key="'photo_data_' + index"
                >
                  <img
                    :src="eachOp.original_url"
                    alt=""
                    @click="open_post_image_detail(post.id)"
                    @error="onPostImageError"
                  />
                </div>
              </div>
              <div
                class="photo-row"
                v-if="post.artwork_url && post.artwork_url.length === 1"
              >
                <div
                  class="photo-timeline"
                  v-for="(eachOp, index) in post.artwork_url"
                  :key="'photo_data_' + index"
                >
                  <img
                    :src="eachOp.original_url"
                    alt=""
                    @error="onPostImageError"
                    @click="open_post_image_detail(post.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="news-feed-post-detail-list">
          <div class="news-feed-pic-detials">
            <div class="artist-timeline-activity">
              <div class="activity-main">
                <PostLike :item="post" :index="index" :page_type="page_check" />
                <div @click="toggleCommentSection($event)">
                  <PostComment v-if="item.allow_comments === 1" :post="post" :comments_count="comment_count" />
                </div>
                <PostShare :post="post" />
              </div>
            </div>
          </div>
        </div>

        <CommentInput
          v-if="write_comment && item.allow_comments === 1"
          :item="post"
          :comment_check="comment_check"
          @comments_count="commentsCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PostLike from "../PostItem/PostLike";
import PostComment from "../PostItem/PostComment";
import PostShare from "../PostItem/PostShare";
import CommentInput from "../PostItem/CommentInput";
import PostDetailDropDown from "../PostItem/PostDetailDropDown";
import UserPicture from "../components/UserPicture";
import moment from "moment-timezone";
import PostHashTags from "../components/PostHashTags.vue";

export default {
  name: "Article",
  components: {
    UserPicture,
    PostDetailDropDown,
    CommentInput,
    PostLike,
    PostComment,
    PostShare,
    PostHashTags
  },
  data() {
    return {
      more: false,
      write_comment: false,
      comment_count: 0,
    };
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    item:{
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    comment_check: {
      type: Boolean,
      default: false
    },
    userPicture: {
      type: String,
      default: ""
    },
    page_check: {
      type: String,
      default: ""
    },
    activeTab: {
      type: String,
      default: ""
    }
  },
  computed: {
    postTime() {
      const { updated_at } = this.post;
      return moment(updated_at).fromNow();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const { comment_count } = this.post || {};
      this.comment_count = comment_count;
    },
    open_post_image_detail: function(id) {
      this.$emit("open_post_image_detail", id);
    },
    pushPostDetail: function(id) {
      this.$emit("pushPostDetail", id);
    },
    shorterContent(value) {
      return value.length < 149 ? value.slice(0, 150) : value.slice(0, 150).concat("...");
    },
    toggleCommentSection(e) {
      if (e) e.stopPropagation();
      this.write_comment = !this.write_comment;
    },
    commentsCount(comments_count) {
      if (comments_count > this.comment_count) {
        this.comment_count = comments_count;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.show-more-less-text {
  cursor: pointer;
  margin-top: -5px;
  color: #D1433A;
  font-weight: normal;
  font-family: HelveticaNeueNormal, sans-serif;
  letter-spacing: 0.5px;
  font-size: 14px;
}
.artist-timeline-description {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #071526;
  font-family: HelveticaNeueNormal, sans-serif;
  word-break: break-all;
}
.music-industry-title-header {
  font-style: normal;
  font-weight: normal;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #47505B !important;
  word-break: break-all;
}
</style>
