<template>
  <div class="container-fluid sale-items">
    <div class="row position-relative playlist-margin">
      <div v-for="(song, index) in songList" :key="index"
           :class="['col-sm-2 playlist-col js-container-hover playlist-card',song.isHovering ? 'hover':'']"
           @mouseover="isHoveringFun(true, index)" @mouseout="isHoveringFun(false, index)">
        <div class="for-new-platlist" v-if="index == 0">
          <div class="add-playlist-icon">
            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="70" height="70" rx="8" fill="#D1433A" fill-opacity="0.1"/>
              <path d="M35 26.25V43.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M26.25 35H43.75" stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="playlist-heading-detail">Create New <br>Playlist</div>
          <div class="playlist-heading-sub-detail">Make your own playlist <br>now!</div>
        </div>
        <div class="card slider playlist" v-if="index >= 1">
          <div :class="['card-cover js-card-hover',song.isHovering ? 'hover':'']" style="height: 100%; width: 100%;">
            <div class="cover square">
              <img src="https://auddiodev-api.theplayground.co.uk/common/default/playlist.png" alt="" style="height: 100%; width: 100%;">
            </div>
            <div class="cover-hover">
              <div class="head-item">
                <div class=" btn-slider-head">
                  <div class="svg-icon  ">
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M13.1991 2.22387C12.8748 1.9063 12.4898 1.65438 12.0661 1.48251C11.6423 1.31063 11.1881 1.22217 10.7295 1.22217C10.2708 1.22217 9.8166 1.31063 9.39285 1.48251C8.9691 1.65438 8.58408 1.9063 8.25977 2.22387L7.58631 2.88326L6.91286 2.22387C6.25341 1.60394 5.37204 1.26181 4.45788 1.2709C3.54372 1.28 2.66963 1.63959 2.02319 2.27252C1.37675 2.90545 1.00947 3.76128 1.00018 4.65633C0.990891 5.55138 1.34033 6.41433 1.9735 7.05999L2.64696 7.71938L7.58631 12.5555L12.5257 7.71938L13.1991 7.05999C13.5235 6.74247 13.7808 6.36549 13.9563 5.9506C14.1319 5.53571 14.2222 5.09102 14.2222 4.64193C14.2222 4.19285 14.1319 3.74816 13.9563 3.33327C13.7808 2.91837 13.5235 2.5414 13.1991 2.22387V2.22387Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
                <div class="play btn js-play-card" @click="$event.currentTarget.classList.toggle('active')">
                  <div class="svg-icon ">
                    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="play-type-1">
                      <path class="c-fill-1 c-line-1"
                            d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                            stroke="white" stroke-width="1.3"></path>
                    </svg>
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1 c-fill-1"
                            d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                            fill="white"></path>
                      <path class="c-line-1 c-fill-1"
                            d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                            stroke="white" stroke-width="1.3"></path>
                    </svg>
                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                            stroke-linecap="round"></path>
                      <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                            stroke-linecap="round"></path>
                    </svg>
                  </div>
                </div>
                <div class="btn-more">
                  <div class="dropdown  more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                       aria-expanded="false" data-display="static">
                    <div class="svg-icon more-position">
                      <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more-hor">
                        <path class="c-fill-1"
                              d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                              fill="white"></path>
                        <path class="c-fill-1"
                              d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                              fill="white"></path>
                        <path class="c-fill-1"
                              d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                              fill="white"></path>
                      </svg>
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more">
                        <path
                            d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                            class="c-fill-1"></path>
                        <path
                            d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                            class="c-fill-1"></path>
                        <path
                            d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                            class="c-fill-1"></path>
                      </svg>
                    </div>
                    <ListOption/>
                  </div>
                </div>
              </div>
              <div class="cover-mood js-cover-mood position-moods">
                <div class="mood Electronic" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  #Electronic
                </div>
                <div class="dropdown-menu dropdown-menu-right dropdown-moods">
                  <div class="moods">
                    #Electronic
                  </div>
                  <div class="moods">
                    #Ambient
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-description playlist-card-des card-description-padding">
            <div class="title marquee js-marquee playlist-text">
              <div><a href="#">
                Playlist name
              </a>
              </div>
            </div>
            <div class="description marquee js-marquee playlist-text">
              <div><a href="#">
                by User Name
              </a>
              </div>
            </div>
            <div class="heart-icons-blue">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.9167 2.06063C13.5733 1.72437 13.1657 1.45764 12.717 1.27565C12.2683 1.09367 11.7874 1 11.3018 1C10.8161 1 10.3352 1.09367 9.88655 1.27565C9.43787 1.45764 9.0302 1.72437 8.68682 2.06063L7.97375 2.7588L7.26067 2.06063C6.56244 1.40423 5.62922 1.04197 4.66128 1.0516C3.69335 1.06123 2.76784 1.44198 2.08337 2.11214C1.39891 2.7823 1.01003 3.68847 1.00019 4.63617C0.990355 5.58387 1.36035 6.49759 2.03076 7.18123L2.74384 7.8794L7.97375 13L13.2037 7.8794L13.9167 7.18123C14.2602 6.84503 14.5326 6.44588 14.7185 6.00658C14.9043 5.56728 15 5.09643 15 4.62093C15 4.14543 14.9043 3.67458 14.7185 3.23528C14.5326 2.79598 14.2602 2.39683 13.9167 2.06063V2.06063Z"
                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <div class="followers actibe-followers">
              <div class="text">
                <div class="svg-icon">
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1"
                          d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                          fill="#D1433A"></path>
                    <path class="c-line-1 c-fill-1"
                          d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                          fill="#D1433A"></path>
                    <path class="c-line-1 c-fill-1"
                          d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                          fill="#D1433A"></path>
                  </svg>
                </div>
                Follow 873.7k
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";

export default {
  data() {
    return {
      optionWidth: 0,
      isHovering: false,
      songList: [{
        id: 1,
        name: 'qwe',
        isHovering: false
      }, {
        id: 2,
        name: 'asd',
        isHovering: false
      }, {
        id: 3,
        name: 'asd',
        isHovering: false
      }, {
        id: 4,
        name: 'asd',
        isHovering: false
      }, {
        id: 5,
        name: 'asd',
        isHovering: false
      }, {
        id: 6,
        name: 'asd',
        isHovering: false
      }]
    }
  },
  components: {
    ListOption
  },
  computed: {},
  methods: {
    isHoveringFun: function (val, index) {
      this.songList[index].isHovering = val;
    },
    getHeight: function (e) {
      if (this.optionWidth == 0)
        this.optionWidth = e.screenX;
    }
  }
}
</script>
<style lang="scss">
.sale-items{
  .playlist-margin{
    margin-right: -26px !important;
    margin-left: -28px !important;
    .playlist-card{
      .for-new-platlist{
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
.card-description-padding{
  padding: 10px !important;
}
.playlist-text{
  padding-left: 5px !important;
}
@media screen and (min-width: 2560) {
  .playlist-col {
    border: 2px solid red;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>
