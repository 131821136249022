<template>
  <div>
    <b-card no-body bg-variant="gray">
      <b-tabs card style="background: #f0f2f5;">
        <b-tab title="GRID ELEMENTS" active>
          <b-card-text>
            <div class="row">
              <div class="col-2">
                <h5 class="my-2">GRID_Streaming</h5>
                <Grid :item="song" :key="1" :stream="true" />
              </div>
              <div class="col-2">
                <h5 class="my-2">GRID_Selling</h5>
                <Grid :item="song" :key="2" />
              </div>
              <div class="col-2">
                <h5 class="my-2">GRID_Selling Now</h5>
                <Grid :item="song" :selling-now="true" />
              </div>
              <div class="col-2">
                <h5 class="my-2">GRID_Playlists</h5>
                <Grid :item="song" :type="'playlist'" />
              </div>
              <div class="col-2">
                <h5 class="my-2">GRID_Record Label</h5>
                <Grid :item="song" :type="'record'" />
              </div>
              <div class="col-2">
                <h5 class="my-2">REVIEWS</h5>
                <Grid :item="review" :type="'review'" />
              </div>
            </div>
            <div class="row">
              <div class="col-2 my-2">
                <h5> CARD- Page or Artist</h5>
                <div class="my-2 mx-auto text-left ">
                  <PageOrArtistCard v-if="page" :item="page" />
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="GRID_CHARTS">
          <b-card-text>
            <div class="row">
              <h5> Grid - chart (small)</h5>
              <div class="col-2 my-2" v-for="(n,index) in 18" :key="index">
                <div class="my-2 mx-auto text-left ">
                  <GridChart :small="true" :colorCode="n" type="singles" />
                </div>
              </div>
            </div>
            <div class="row">
              <h5> Grid - chart</h5>
              <div class="col-3 my-2" v-for="(n,index) in 18" :key="index">
                <div class="my-2 mx-auto text-left ">
                  <GridChart :colorCode="n" type="singles" />
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Music files (stream)">
          <b-card-text>
            <div class="row">
              <div class="col-12 my-2">
                <h5> Artist </h5>
                <div class="my-2 mx-auto text-left">
                  <listArtistCardSmall v-if="page" :item="page" />
                </div>
              </div>
              <div class="col-12 my-2">
                <h5> Record </h5>
                <div class="my-2 mx-auto text-left ">
                  <listArtistCardSmall v-if="page" :item="page" type="record" />
                </div>
              </div>
              <div class="col-12 my-2">
                <h5> Single </h5>
                <div class="my-2 mx-auto text-left ">
                  <single v-if="song" :item="song" type="record" />
                </div>
              </div>
              <div class="col-12 my-2 ">
                <h3> SINGLES/ ALBUMS (TOP NEW)</h3>
                <div class="my-2 mx-auto text-left ">
                  <SongOrAlbumRowList class="mx-auto" :item="song" :singles="true" />
                </div>
              </div>
              <div class="col-12 my-2 ">
                <h3> TOP 100 SINGLES/ ALBUMS</h3>
                <div class="my-2 mx-auto text-left ">
                  <SongOrAlbumRowList class="mx-auto" :item="song" :rating="true" />
                </div>
              </div>
              <div class="col-12 my-2 ">
                <h3> SONGS & ALBUMS (3/4 page)</h3>
                <div class="my-2 mx-auto text-left ">
                  <SongOrAlbumRowList class="mx-auto" :item="song" />
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Music files (Selling)">
          <b-card-text>
            <div class="row">
              <div class="col-12 my-2">
                <h5> Artist </h5>
                <div class="my-2 mx-auto text-left">
                  <listArtistCardSmall v-if="page" :item="page" />
                </div>
              </div>
              <div class="col-12 my-2">
                <h5> Record </h5>
                <div class="my-2 mx-auto text-left ">
                  <listArtistCardSmall v-if="page" :item="page" type="record" />
                </div>
              </div>
              <div class="col-12 my-2">
                <h5> Single </h5>
                <div class="my-2 mx-auto text-left ">
                  <single v-if="song" :item="song" type="record" />
                </div>
              </div>
              <div class="col-12 my-2 ">
                <h3> SINGLES/ ALBUMS (TOP NEW)</h3>
                <div class="my-2 mx-auto text-left ">
                  <SongOrAlbumRowList class="mx-auto" :item="song" :singles="true" />
                </div>
              </div>
              <div class="col-12 my-2 ">
                <h3> TOP 100 SINGLES/ ALBUMS</h3>
                <div class="my-2 mx-auto text-left ">
                  <SongOrAlbumRowList class="mx-auto" :item="song" :rating="true" />
                </div>
              </div>
              <div class="col-12 my-2 ">
                <h3> SONGS & ALBUMS (3/4 page)</h3>
                <div class="my-2 mx-auto text-left ">
                  <SongOrAlbumRowList class="mx-auto" :item="song" />
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Full Page Music">
          <b-card-text>
            <div class="row">
              <div class="col-12 my-2 ">
                <h3>CHARTS_SINGLES, REMIXES & ALBUMS_Full page (Streaming)</h3>
                <div class="my-2 mx-auto text-left ">
                  <ChartTopSellingList class="mx-auto" :listData="[song,song]" :type="'singles'" :stream="true" />
                </div>
                <h3>CHARTS_SINGLES, REMIXES & ALBUMS_Full page (Selling)</h3>
                <div class="my-2 mx-auto text-left ">
                  <ChartTopSellingList class="mx-auto" :listData="[song,song]" :type="'singles'" />
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title=" Nav sidebars ">
          <b-card-text>
            <div class="row">
              <div class="col-2">
                <LeftNav category="navigation" type="home"/>
              </div>
              <div class="col-2">
                <LeftNav category="navigation" type="music"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="navigation" type="event"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="navigation" type="job"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="navigation" type="market"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="navigation" type="television"/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <NavigationNavIcons/>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title=" Managers sidebars ">
          <b-card-text>
            <div class="row">
              <div class="col-2">
                <LeftNav category="manager" type="page"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="manager" type="event"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="manager" type="job"/>
              </div>
              <div class="col-2 ">
                <LeftNav category="manager" type="market"/>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="container-fluid">
      <div class="row" v-if="song">

        <div class="row">
          <div class="col-12">
            <h2>MUSIC UI Components (Full page Music files_OVERVIEW PAGES)</h2>
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>SONGS + REMIXES_Full page (Selling)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'song'" :stream="false" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>SONGS + REMIXES _Full page (Streaming)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'song'" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>ALBUMS_Full page (Selling)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'album'" :stream="false" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>ALBUMS_Full page (Streaming)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'album'" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>PODCASTS_Full page (Selling)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'podcast'" :stream="false" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>PODCASTS_Full page (Streaming)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'podcast'" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>PLAYLIST_Full page (Selling)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'playlist'" :stream="false" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>PLAYLIST_Full page (Streaming)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'playlist'" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>SONG_Full_Review page (Streaming)</h3>
          <div class="my-2 mx-auto text-left ">
            <FullPageMusic :listData="[song,song]" :type="'review'" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h2>FULL PAGE MUSIC FILES_(DIRECTLY ON FILES)</h2>
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>SONG_Full_Review page (Streaming)</h3>
          <div class="my-2  text-left ">
            <PageElement1 :song="song" :backgroundType="'type-1'" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h2>PODCAST FEATURED_Music</h2>
          </div>
        </div>
        <div class="col-4 my-2 ">
          <div class="my-2  text-left ">
            <PodcastFeatured :item="song" :backgroundType="'type-1'" />
          </div>
        </div>
        <div class="col-4 my-2 ">
          <div class="my-2  text-left ">
            <PodcastFeatured :item="song" :backgroundType="'type-1'" :stream="true" />
          </div>
        </div>
        <div class="col-4 my-2 ">
          <div class="my-2  text-left ">
            <PodcastFeatured :item="song" :backgroundType="'type-1'" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h2>PODCAST FEATURED_Talk</h2>
          </div>
        </div>
        <div class="col-4 my-2 ">
          <div class="my-2  text-left ">
            <PodcastFeatured :item="song" type="talk" />
          </div>
        </div>
        <div class="col-4 my-2 ">
          <div class="my-2  text-left ">
            <PodcastFeatured :item="song" type="talk" :stream="true" />
          </div>
        </div>
        <div class="col-4 my-2 ">
          <div class="my-2  text-left ">
            <PodcastFeatured :item="song" type="talk" />
          </div>
        </div>
        <div class="col-12 my-2 ">
          <h3>CHARTS_SINGLES, REMIXES & ALBUMS_Full page (Streaming) (Song and Album)</h3>
          <div class="my-2 text-left ">
            <SongAlbumWaveFormBlockListing v-if="album && song" />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Grid from "./Components/Grid";
import GridChart from "./Components/GridChart";
import listArtistCardSmall from "./Components/ListArtistRecordCardSmall";
import FullPageMusic from "./Components/FullPageMusic";
import SongOrAlbumRowList from "./Components/SongOrAlbumRowList";
import single from "./Components/Single";
import PodcastFeatured from "./Components/PodcastFeatured";
import SongAlbumWaveFormBlockListing from "./Components/SongAlbumWaveFormBlockListing";
import ChartTopSellingList from "@/components/Common/Elements/ChartTopSellingList";
import PageOrArtistCard from "@/components/Common/UiComponenets/PageOrArtistCard";
import PageElement1 from "@/components/Common/UiList/PageElement1";


import LeftNav from "../Layout/LeftNav";
import NavigationNavIcons from "../Layout/NavIcons/NavigationNavIcons";

import { getAlbumDetailByID, getPage, getSongDetailByID } from "../../../apis/APIs";

export default {
  name: "MusicUIGridElements",
  components: {
    NavigationNavIcons,
    LeftNav,
    PodcastFeatured,
    PageElement1,
    FullPageMusic,
    SongAlbumWaveFormBlockListing,
    ChartTopSellingList,
    SongOrAlbumRowList,
    single,
    listArtistCardSmall,
    GridChart,
    PageOrArtistCard,
    Grid
  },
  data() {
    return {
      song: null,
      album: null,
      page: null,
      review: {
        "id": 20,
        "rating": 4,
        "comment": "Sounds good!",
        "reviewable_id": 67,
        "created_at": "2022-02-21T08:55:55.000000Z",
        "reviewable_user": {
          "id": 147,
          "name": "Hamza",
          "artwork_url": "http://auddiodev-api.theplayground.co.uk/storage/377/conversions/1633463147-lg.jpg"
        },
        "reviewable": {
          "id": 67,
          "type": "album",
          "title": "my new Album",
          "artwork_url": "http://auddiodev-api.theplayground.co.uk/storage/377/conversions/1633463147-lg.jpg",
          "page": {
            "id": 53,
            "title": "Atif Aslam",
            "user_id": 112,
            "follow_action": "approved"
          },
          "favorite": true,
          "rating": {
            "avg": "4.0",
            "total": 1
          },
          "avg": "4.0",
          "album_songs": []
        }
      }
    };
  },
  mounted() {
    this.getSong();
    this.getAlbum();
    this.getPage();
    this.getMusicData();

  },
  methods: {
    async getSong() {
      try {
        const { data } = await getSongDetailByID(38);
        if (data.success === 1) {
          this.song = data.information;
        }
      } catch (e) {
        console.error("error in fetching song");
      }
    },
    async getAlbum() {
      try {
        const { data } = await getAlbumDetailByID(71);
        if (data.success === 1) {
          this.album = data.information;
        }
      } catch (e) {
        console.error("error in fetching song");
      }
    },
    async getPage() {
      try {
        const { data } = await getPage(24);
        if (data.success === 1) {
          this.page = data.information.data;
        }
      } catch (e) {
        console.error("error in fetching song");
      }
    },
    getMusicData() {
      this.$store.dispatch("getMusicData", { page_id: 62 });
    }
  }
};
</script>

<style  lang="scss">
.leftbar{
  .engage_leftbar{
position: relative !important;
  }
}

</style>
