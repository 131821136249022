<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="reg-user-info"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeRegisterModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <!-- <img src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/logo.png" alt="">  -->
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body body_padding ">
            <div class="body-title Font_semibold">
              <a class="back-arrow" v-on:click="goBack">
                <div class="svg-icon">
                  <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        class="c-line-1"
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M15 10L10 15L15 20"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M20 15H10"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
              1 / 2. User Info
            </div>
            <div class="input-wrap no-icons m-b-17" :class="{ 'form-group--error': $v.first_name.$error }">
              <div class="input-title Font_semibold">First Name</div>
              <input
                  placeholder="First Name"
                  v-model.trim="$v.first_name.$model"
                  @change="onChange"
                  name="signup_form_first_name"
              />
              <label class="error_msg error_msg_red_one"
                     v-if="_handleErrorMessage('first_name') && !first_name">{{ _handleErrorMessage("first_name") }}</label>
              <label class="error_msg error_msg_red_one" v-if="!$v.first_name.alpha">First Name must have at least
                {{ $v.first_name.$params.minLength.min }} letters</label>
<!--              <tree-view :data="$v.first_name" :options="{rootObjectKey: '$v.first_name', maxDepth: 2}"></tree-view>-->

            </div>
            <div class="input-wrap no-icons m-b-17" :class="{ 'form-group--error': $v.last_name.$error }">
              <div class="input-title Font_semibold">Last Name</div>
              <input
                  placeholder="Last Name"
                  v-model.trim="$v.last_name.$model"
                  @change="onChange"
                  name="signup_form_last_name"
              />
              <label class="error_msg error_msg_red_one"
                     v-if="_handleErrorMessage('last_name') && !last_name">{{ _handleErrorMessage("last_name") }}</label>
              <label class="error_msg error_msg_red_one" v-if="!$v.last_name.alpha">Last Name must have at least
                {{ $v.last_name.$params.minLength.min }} letters</label>
<!--              <tree-view :data="$v.last_name" :options="{rootObjectKey: '$v.last_name', maxDepth: 2}"></tree-view>-->
            </div>

            <div class="input-wrap no-icons m-b-17">
              <div class="input-title Font_semibold">Gender</div>
              <div
                  class="dropdown bootstrap-select show-tick filters-select f-s register_gender_slecter"
              >
                <select
                    class="filters-select f-s selectpicker"
                    title="Gender"
                    :value="gender"
                    @change="onChange"
                    name="signup_form_gender">
                  <option
                      :value="eachOp.value"
                      v-for="(eachOp, index) in allGender"
                      :key="index"
                  >{{ eachOp.name }}
                  </option
                  >
                </select>
                <button
                    type="button"
                    class="btn dropdown-toggle btn-light bs-placeholder"
                    data-toggle="dropdown"
                    role="combobox"
                    aria-owns="bs-select-41"
                    aria-haspopup="listbox"
                    aria-expanded="false"
                    title="NULL"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">
                        {{ getGender }}
                      </div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu ">
                  <div
                      class="inner show"
                      role="listbox"
                      id="bs-select-41"
                      tabindex="-1"
                      aria-multiselectable="true"
                  >
                    <ul class="dropdown-menu inner show" role="presentation">
                      <li
                          v-for="(eachOp, index) in allGender"
                          :key="index"
                          @click="updateGender(eachOp)"
                      >
                        <a
                            role="option"
                            class="dropdown-item"
                            id="bs-select-41-0"
                            tabindex="0"
                            aria-selected="false"
                            aria-setsize="2"
                            aria-posinset="1"
                        >
                          <span class=" bs-ok-default check-mark"></span>
                          <span class="text">{{ eachOp.name }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <label class="error_msg error_msg_red_one"
                     v-if="_handleErrorMessage('gender') && !gender">{{ _handleErrorMessage("gender") }}</label>
            </div>

            <div class="input-wrap no-icons m-b-17">
              <div class="input-title Font_semibold">Date of Birth</div>
              <div class="row m-b-10">
                <div class="col-md-4 ">
                  <div class="d-flex flex-wrap align-items-end h-100">
                    <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                      <select class="filters-select selectpicker for-overview" title="Latest" multiple=""
                              data-max-options="1" dropupauto="false" tabindex="-98">
                        <option
                            :value="eachOp.value"
                            v-for="(eachOp, index) in days"
                            :key="index"
                        >{{ eachOp.name }}
                        </option
                        >
                      </select>
                      <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox"
                              aria-owns="bs-select-9" aria-haspopup="listbox" aria-expanded="false" title="Featured">
                        <div class="filter-option">
                          <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{ getDay }}</div>
                          </div>
                        </div>
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start"
                           style="max-height: 108px; overflow: hidden; min-height: 118px; position: absolute; will-change: transform; min-width: 170px; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                        <div class="inner show" role="listbox" id="bs-select-9" tabindex="-1"
                             aria-multiselectable="true" style="max-height: 90px; overflow-y: auto; min-height: 100px;">
                          <ul class="dropdown-menu inner show" role="presentation"
                              style="margin-top: 0px; margin-bottom: 0px;">
                            <li
                                v-for="(eachOp, index) in days"
                                :key="index"
                                v-on:click="updateDay(eachOp)"
                                :class="{ selected: eachOp.name === dayName }"
                            >
                              <a
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-41-0"
                                  tabindex="0"
                                  aria-selected="false"
                                  aria-setsize="2"
                                  aria-posinset="1"
                              >
                                <span class=" bs-ok-default check-mark"></span>
                                <span class="text">{{ eachOp.name }}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="d-flex flex-wrap align-items-end h-100">
                    <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                      <select class="filters-select selectpicker for-overview" title="Latest" multiple=""
                              data-max-options="1" dropupauto="false" tabindex="-98">
                        <option
                            :value="index"
                            v-for="(eachOp, index) in months"
                            :key="index"
                        >{{ eachOp.name }}
                        </option
                        >
                      </select>
                      <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox"
                              aria-owns="bs-select-9" aria-haspopup="listbox" aria-expanded="false" title="Featured">
                        <div class="filter-option">
                          <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{ getMonth }}</div>
                          </div>
                        </div>
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start"
                           style="max-height: 108px; overflow: hidden; min-height: 118px; position: absolute; will-change: transform; min-width: 170px; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                        <div class="inner show" role="listbox" id="bs-select-9" tabindex="-1"
                             aria-multiselectable="true" style="max-height: 90px; overflow-y: auto; min-height: 100px;">
                          <ul class="dropdown-menu inner show" role="presentation"
                              style="margin-top: 0px; margin-bottom: 0px;">
                            <li
                                v-for="(eachOp, index) in months"
                                :key="index"
                                v-on:click="updateMonth(eachOp)"
                                :class="{ selected: eachOp.name === monthName }"
                            >
                              <a
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-41-0"
                                  tabindex="0"
                                  aria-selected="false"
                                  aria-setsize="2"
                                  aria-posinset="1"
                              >
                                <span class=" bs-ok-default check-mark"></span>
                                <span class="text">{{ eachOp.name }}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="d-flex flex-wrap align-items-end h-100">
                    <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                      <select class="filters-select selectpicker for-overview" title="Latest" multiple=""
                              data-max-options="1" dropupauto="false" tabindex="-98">
                        <option
                            :value="eachOp.value"
                            v-for="(eachOp, index) in years"
                            :key="index"
                        >{{ eachOp.name }}
                        </option
                        >
                      </select>
                      <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox"
                              aria-owns="bs-select-9" aria-haspopup="listbox" aria-expanded="false" title="Featured">
                        <div class="filter-option">
                          <div class="filter-option-inner">
                            <div class="filter-option-inner-inner">{{ getYear }}</div>
                          </div>
                        </div>
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start"
                           style="max-height: 108px; overflow: hidden; min-height: 118px; position: absolute; will-change: transform; min-width: 170px; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                        <div class="inner show" role="listbox" id="bs-select-9" tabindex="-1"
                             aria-multiselectable="true" style="max-height: 90px; overflow-y: auto; min-height: 100px;">
                          <ul class="dropdown-menu inner show" role="presentation"
                              style="margin-top: 0px; margin-bottom: 0px;">
                            <li
                                v-for="(eachOp, index) in years"
                                :key="index"
                                v-on:click="updateYear(eachOp)"
                                :class="{ selected: eachOp.name === yearName }"
                            >
                              <a
                                  role="option"
                                  class="dropdown-item"
                                  id="bs-select-41-0"
                                  tabindex="0"
                                  aria-selected="false"
                                  aria-setsize="2"
                                  aria-posinset="1"
                              >
                                <span class=" bs-ok-default check-mark"></span>
                                <span class="text">{{ eachOp.name }}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label class="error_msg error_msg_red_one"
                     v-if="(_handleErrorMessage('day') && !day) || (_handleErrorMessage('year') && !year) || (_handleErrorMessage('month') && !month)">{{ _handleErrorMessage('day') || _handleErrorMessage('year') || _handleErrorMessage('month') }}</label>
            </div>

            <div
                class="btn btn-login m-t-30 Font_semibold next_step_btn"
                v-on:click="goToStep2"
            >
              Next Step
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {minLength, required, alpha} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as Helper from '../../../apis/Helper.js';
import {
  SIGNUP_ERRORS,
  SIGNUP_FORM_GENDER,
  SIGNUP_FORM_DOB_DAY,
  SIGNUP_FORM_DOB_YEAR,
  SIGNUP_FORM_DOB_MONTH, SIGNUP_FORM
} from '../../../store/types';
import * as Utils from '../../../components/utils/Utils';
import LandingHeader from "../LandingHeader";

export default {
  props: {
    clearData: {
      Type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: "",
      first_name: "",
      first_name_check: false,
      last_name_check: false,
      last_name: "",
      genderName: "",
      gender: "",
      allGender: [
        {name: "Male", value: "M", is_selected: false},
        {name: "Female", value: "F", is_selected: false},
        {name: "Other", value: "O", is_selected: false}
      ],

      days: Utils.getMonthDays(new Date().getFullYear(), new Date().getMonth()),
      dayName: "",
      day: "",

      months: Utils.getMonths(),
      monthName: "",
      month: "",

      years: Utils.getYears(),
      yearName: "",
      year: ""
    };
  },
  validations: {
    first_name: {
      required,
      alpha,
      minLength: minLength(4)
    },
    last_name: {
      required,
      alpha,
      minLength: minLength(4)
    }
  },
  computed: {
    ...mapGetters({
      form_data: "signup_user_info_form_map_to_props"
    }),
    loading() {
      const {loading = false} = this.form_data || {};

      return loading;
    },
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    },
    getGender() {

      return this.genderName ? this.genderName : "Gender"
    },
    getDay() {

      return this.dayName ? this.dayName : "Day"
    },
    getYear() {

      return this.yearName ? this.yearName : "Year"
    },
    getMonth() {

      return this.monthName ? this.monthName : "Month"
    }
  },
  mounted() {
    const {gender, day, year, month, last_name, first_name} = this.form_data;

    if (gender) {
      const index = this.allGender.findIndex(ele => ele.value === gender);

      if (index !== -1) {
        this.gender = gender;
        this.genderName = this.allGender[index].name;
      }
    }

    if (day) {
      const index = this.days.findIndex(ele => ele.value === day);

      if (index !== -1) {
        this.day = day;
        this.dayName = this.days[index].name;
      }
    }

    if (month) {
      const index = this.months.findIndex(ele => ele.index === month);

      if (index !== -1) {
        this.month = month;
        this.monthName = this.months[index].name;
      }
    }

    if (year) {
      const index = this.years.findIndex(ele => ele.value === year);

      if (index !== -1) {
        this.year = year;
        this.yearName = this.years[index].name;
      }
    }

    if (first_name) {
      this.first_name = first_name;
    }

    if (last_name) {
      this.last_name = last_name;
    }
  },
  methods: {
    ...mapActions([
      "check_user_exists"
    ]),
    ...mapMutations([
      "updateSignupFormData",
      "updateSignupUIConstraints"
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      return message;
    },
    onChange(e) {
      const {name, value} = e.target || {};

      this.updateSignupFormData({
        [name]: value
      });
    },
    clearFormData() {
      this.yearName = '';
      this.year = ''
      this.dayName = '';
      this.day = '';
      this.monthName = '';
      this.month = '';
      this.getYear = '';
      this.getMonth = '';
      this.getDay = '';
      this.first_name = '';
      this.last_name = '';
      this.gender = '';
      this.genderName = '';
      this.updateSignupFormData({});
    },
    goToStep2() {
      const {loading, first_name, last_name, gender, day, year, month} = this;

      if (loading) return;

      const body = {
        first_name,
        last_name,
        gender,
        day,
        month: month.toString(),
        year
      };

      console.log("test data ===> store", this.$store.state, body, month, Object.keys(body), Helper)

      Helper.validate(Object.keys(body), body)
          .then(async ({status, response}) => {
            if (status) {
              const dob = new Date(year, month, day);

              if (dob > new Date()) {
                return this.updateSignupUIConstraints({
                  [SIGNUP_ERRORS]: [{
                    fieldName: "day",
                    message: "Invalid date of birth"
                  }]
                });
              }

              this.updateSignupUIConstraints({
                [SIGNUP_ERRORS]: []
              });
              try {
                if (this.clearData == true) {
                  this.$emit('clearFormData2', true);
                }
                this.$parent.newModal("register-step-2");
                return;
                this.message = "";
                await this.check_user_exists();
                this.$parent.newModal("register-step-2");
              } catch (e) {
                this.message = e && e.message ? e.message : "Please try again.";
              }
            } else this.updateSignupUIConstraints({
              [SIGNUP_ERRORS]: response && response.length ? response : []
            });
          })
          .catch(err => console.log(err));
    },
    closeRegisterModal: function () {
      this.clearFormData()
      this.$parent.closeModal();
    },
    goBack: function () {
      this.updateSignupUIConstraints({
        [SIGNUP_ERRORS]: []
      });
      this.$parent.newModal("need-signup");
    },
    updateGender: function (row) {
      this.genderName = row.name;
      this.gender = row.value;
      console.log("Gender", row)
      this.updateSignupFormData({
        [SIGNUP_FORM_GENDER]: row.value
      });
    },
    updateDay: function (row) {
      console.log("Update Day")
      this.dayName = row.name;
      this.day = row.value;

      this.updateSignupFormData({
        [SIGNUP_FORM_DOB_DAY]: row.value
      });
    },
    updateMonth: function (row) {
      this.monthName = row.name;
      this.month = row.index;

      this.checkDay();

      console.log("month ===> month", row);
      this.updateSignupFormData({
        [SIGNUP_FORM_DOB_MONTH]: String(row.index)
      });
    },
    updateYear: function (row) {
      this.yearName = row.name;
      this.year = row.value;

      this.checkDay();

      this.updateSignupFormData({
        [SIGNUP_FORM_DOB_YEAR]: row.value
      });
    },
    checkDay() {
      const year = this.year ? this.year : new Date().getFullYear();
      const index = this.months.findIndex(ele => ele.vaule === this.month);
      const month = this.month && index !== -1 ? this.months[index].index + 1 : new Date().getMonth();
      this.days = Utils.getMonthDays(year, month);
      const is_check_date = this.days && this.days.length ? this.days[this.days.length - 1].value < parseInt(this.day) : false;

      if (this.dayName && is_check_date) {
        this.day = "";
        this.dayName = "";

        this.updateSignupFormData({
          [SIGNUP_FORM_DOB_DAY]: this.day
        });
      }
    },
    updateAge: function (row) {
      this.UserAge = row;
    }
  }
};
</script>
