<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="delete-acc" class="settings-content right-setting-bar active">
      <!-- <div class="d-flex flex-row align-items-center justify-content-between"> <div class="setting-title">Delete Account</div> <div class="view-btn d-flex flex-row align-items-center justify-content-center"> <div class="svg-icon"> <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6173 6.09311C14.7887 1.73596 11.4655 1.19779 10 1.19779C8.5345 1.19779 5.21146 1.73593 1.38291 6.09283C1.26356 6.22947 1.19779 6.40475 1.19779 6.58618C1.19779 6.76761 1.26356 6.94287 1.38288 7.0795C5.21145 11.4364 8.5345 11.9746 10 11.9746C11.4655 11.9746 14.7886 11.4364 18.6173 7.07932C18.7365 6.94271 18.8022 6.76753 18.8022 6.58618C18.8022 6.40487 18.7365 6.22971 18.6173 6.09311ZM19.5178 5.30331C15.4639 0.689407 11.8139 0 10 0C8.18612 0 4.53606 0.689407 0.482162 5.30331C0.171335 5.6584 0 6.11427 0 6.58618C0 7.0581 0.171335 7.51397 0.482162 7.86906C4.53606 12.483 8.18612 13.1724 10 13.1724C11.8139 13.1724 15.4639 12.483 19.5178 7.86906C19.8287 7.51397 20 7.0581 20 6.58618C20 6.11427 19.8287 5.6584 19.5178 5.30331Z" fill="#D1433A"></path> <path d="M10.1086 10.1351C9.38085 10.1299 8.67092 9.90927 8.06834 9.50113C7.46576 9.09299 6.99751 8.51557 6.72262 7.84168C6.44774 7.1678 6.37853 6.42761 6.52371 5.71444C6.6689 5.00127 7.02198 4.34706 7.53845 3.83427C8.05492 3.32149 8.71164 2.9731 9.42583 2.83303C10.14 2.69296 10.8797 2.76748 11.5516 3.04719C12.2235 3.3269 12.7975 3.79928 13.2013 4.40478C13.6051 5.01027 13.8206 5.72177 13.8206 6.44957C13.8193 6.9354 13.7221 7.41619 13.5347 7.86444C13.3474 8.31269 13.0735 8.71961 12.7287 9.06191C12.3839 9.40422 11.9751 9.67519 11.5255 9.85934C11.0759 10.0435 10.5944 10.1372 10.1086 10.1351Z" fill="#D1433A"></path> </svg> </div>
                              View Profile
                          </div> </div> -->
      <div class="set-block d-flex flex-column setting-header">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z"
                stroke="#47505B"
                stroke-width="1.5"
              />
              <path d="M10 6L6 10" stroke="#47505B" stroke-width="1.5" />
              <path d="M6 6L10 10" stroke="#47505B" stroke-width="1.5" />
            </svg>
          </div>
          Delete Account
        </div>
        <div class="set-block-content d-flex flex-column delete-acc">
          <div
            class="set-notyfication warning d-flex align-items-center flex-row width730"
          >
            <div class="svg-icon">
              <svg
                width="28"
                height="25"
                viewBox="0 0 28 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="c-fill-1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7195 2.1467L0.426206 21C0.193364 21.4033 0.0701619 21.8604 0.0688581 22.3261C0.0675544 22.7917 0.188194 23.2495 0.418775 23.6541C0.649356 24.0586 0.98184 24.3957 1.38315 24.6318C1.78446 24.868 2.24061 24.9949 2.70621 25H25.2929C25.7585 24.9949 26.2146 24.868 26.6159 24.6318C27.0172 24.3957 27.3497 24.0586 27.5803 23.6541C27.8109 23.2495 27.9315 22.7917 27.9302 22.3261C27.9289 21.8604 27.8057 21.4033 27.5729 21L16.2795 2.1467C16.0418 1.75484 15.7072 1.43085 15.3078 1.20601C14.9084 0.98116 14.4579 0.863037 13.9995 0.863037C13.5412 0.863037 13.0906 0.98116 12.6913 1.20601C12.2919 1.43085 11.9572 1.75484 11.7195 2.1467ZM13.9998 8.00008C14.5521 8.00008 14.9998 8.4478 14.9998 9.00008V14.3334C14.9998 14.8857 14.5521 15.3334 13.9998 15.3334C13.4476 15.3334 12.9998 14.8857 12.9998 14.3334V9.00008C12.9998 8.4478 13.4476 8.00008 13.9998 8.00008ZM13.9998 18.6667C13.4476 18.6667 12.9998 19.1145 12.9998 19.6667C12.9998 20.219 13.4476 20.6667 13.9998 20.6667H14.0132C14.5655 20.6667 15.0132 20.219 15.0132 19.6667C15.0132 19.1145 14.5655 18.6667 14.0132 18.6667H13.9998Z"
                  fill="#FF5F3A"
                ></path>
              </svg>
            </div>
            Once you delete your account you will no longer can access it again
          </div>
          <div
            class="
              delete-btn
              d-flex
              flex-row
              align-items-center
              justify-content-center
            "
          >
            <div class="svg-icon">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  class="c-line-1"
                  x1="0.65"
                  y1="3.35"
                  x2="16.35"
                  y2="3.35"
                  stroke="#95B3D7"
                  stroke-width="1.3"
                  stroke-linecap="round"
                ></line>
                <path
                  class="c-line-1"
                  d="M5.5 5.5L6.5 13.5"
                  stroke="#95B3D7"
                  stroke-linecap="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M8.5 5.5L8.5 13.5"
                  stroke="#95B3D7"
                  stroke-linecap="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M11.5 5.5L10.5 13.5"
                  stroke="#95B3D7"
                  stroke-linecap="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M4.43395 14.095L3.69745 3.65H13.3026L12.566 14.095C12.5162 14.8019 11.9281 15.35 11.2194 15.35H5.78061C5.07187 15.35 4.4838 14.8019 4.43395 14.095Z"
                  stroke="#95B3D7"
                  stroke-width="1.3"
                ></path>
                <path
                  class="c-line-1"
                  d="M5.65 2.35H11.35V2C11.35 1.25442 10.7456 0.65 10 0.65H7C6.25442 0.65 5.65 1.25442 5.65 2V2.35Z"
                  stroke="#95B3D7"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <span @click="submit"> Delete My Account </span>
          </div>
        </div>
      </div>
    </div>
    <full-page-loader :show="loading" />
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import {
  deleteUserAccount,
  getReferedUsers,
  getUserAffilatedLink,
  listReferedUsers,
  search_affliate_users_api,
} from "../../../apis/APIs";
import moment from "moment-timezone";
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
// import InstagramButton from "vue-share-buttons/src/components/InstagramButton";
import VkontakteButton from "vue-share-buttons/src/components/VkontakteButton";
import Pagination from "vue-pagination-2";
import router from "../../../router";

export default {
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  components: {},
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
  },
  methods: {
    async submit() {
      try {
        this.loading = true;

        // const query = `?user_id=${84}`;
        const res = await deleteUserAccount();
        const { success, message } = res.data || {};

        if (success === 1) {
          // await this.makeToast('success', 'Delete Account', `${message}`)
          this.vueNotification("Delete Account", message, "success");
          await router.push({ path: "/" });
          // this.fetchUserData();
          // await router.push({ path: "/" });
        }
      } catch (error) {
        this.loading = false;
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
