<template>
  <div>
    <div class="row artist_profile_row">
      <div class="col">
        <div class="collection-heading">LATEST</div>
      </div>
    </div>
    <MusicCard @getFavoriteSongs="getFavoriteSongs"/>
  </div>

</template>
<script type="application/javascript">
import MusicCard from '@/components/PageManager/Pages/Components/MusicCardComponent.vue';
export default {
  data() {
    return {
    }
  },
  components: {
    MusicCard
  },
  mounted () {
  },
  computed: {
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    getFavoriteSongs() {
      this.$store.dispatch('getMusicData', { page_id: this.current_page.id})
    },
  }
}
</script>
<style>
.music-head .right-block .play-button .svg-icon svg {
  width: 11px !important;
  height: 14px !important;
}
.music-head .right-block .btn.btn-information {
  background-color: #F5F6F8 !important;
}
.music-head .right-block .btn.btn-information span {
  color: #071526 !important;
}
.music-head .right-block .btn.btn-information .svg-icon .c-line-1 {
  stroke: #071526 !important;
}
</style>