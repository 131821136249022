<template>
  <div class="container-fluid m-t-50">
    <div class="row">
      <div class="col">
        <h6>MUSIC FEED</h6>
        <div :class="['dropdown-tabs-wrapper', musicFeedMore ? 'open':'']">
          <div class="dropdown-tabs" v-on:click="musicFeedMore = !musicFeedMore">
            All
            <div class="svg-icon">
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7695 1L6.09052 6.09612L0.999512 1" stroke="#D1433A" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="dropdown-tabs-menu">
            <ul class="nav nav-tabs" id="music-feed-filter" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="music-tab" data-toggle="tab" href="#music" role="tab" aria-controls="music"
                   aria-selected="false">
                  <div class="svg-icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-fill-1" opacity="0.7"
                            d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z"
                            fill="#47505B" stroke="#47505B" stroke-width="0.41"></path>
                      <g opacity="0.7">
                        <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.58883 14.6924C9.58883 15.6832 8.78522 16.4868 7.79442 16.4868C6.80362 16.4868 6 15.6832 6 14.6924C6 13.7016 6.80362 12.8979 7.79442 12.8979C8.78522 12.8979 9.58883 13.7016 9.58883 14.6924ZM7.01569 14.6921C7.01569 15.1219 7.36456 15.4708 7.7944 15.4708C8.22424 15.4708 8.57311 15.1219 8.57311 14.6921C8.57311 14.2622 8.22424 13.9133 7.7944 13.9133C7.36456 13.9133 7.01569 14.2622 7.01569 14.6921Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                              d="M15.1413 12.9995C15.1413 13.9903 14.3377 14.7939 13.3469 14.7939C12.3561 14.7939 11.5525 13.9903 11.5525 12.9995C11.5525 12.0087 12.3561 11.2051 13.3469 11.2051C14.3377 11.2051 15.1413 12.0087 15.1413 12.9995ZM12.5682 12.9995C12.5682 13.4293 12.9171 13.7782 13.3469 13.7782C13.7768 13.7782 14.1256 13.4293 14.1256 12.9995C14.1256 12.5696 13.7768 12.2208 13.3469 12.2208C12.9171 12.2208 12.5682 12.5696 12.5682 12.9995Z"
                              fill="#47505B"></path>
                        <path class="c-fill-1"
                              d="M14.1258 7.40379V12.9993H15.1415V6L8.57324 8.16271V14.6921H9.58895V8.89763L14.1258 7.40379Z"
                              fill="#47505B"></path>
                      </g>
                    </svg>
                  </div>
                  Music
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="podcasts-tab" data-toggle="tab" href="#podcasts" role="tab"
                   aria-controls="podcasts" aria-selected="true">
                  <div class="svg-icon">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1" opacity="0.8"
                            d="M11 1C5.48607 1 1 5.48607 1 11C1 16.5141 5.48607 21 11 21C16.5141 21 21 16.5139 21 11C21 5.48585 16.5141 1 11 1ZM11 20.2364C5.90694 20.2364 1.76364 16.0928 1.76364 11C1.76364 5.90716 5.90694 1.76364 11 1.76364C16.0928 1.76364 20.2364 5.90716 20.2364 11C20.2364 16.0928 16.0928 20.2364 11 20.2364Z"
                            fill="#47505B" stroke="#47505B" stroke-width="0.41"></path>
                      <g opacity="0.8">
                        <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                              d="M11 5C12.3807 5 13.5 6.05824 13.5 7.36364V11.5C13.5 12.8054 12.3807 13.8636 11 13.8636C9.61926 13.8636 8.49997 12.8054 8.49997 11.5V7.36364C8.49997 6.05824 9.61926 5 11 5ZM11.625 16.1907V16.8182H13.5V18H8.5V16.8182H10.375V16.1907C7.90856 15.8999 6 13.9107 6 11.5V10.3182H7.25V11.5C7.25 13.4581 8.92893 15.0455 11 15.0455C13.0711 15.0455 14.75 13.4581 14.75 11.5V10.3182H16V11.5C16 13.9107 14.0914 15.8999 11.625 16.1907ZM9.74999 7.36363C9.74999 6.71093 10.3096 6.18181 11 6.18181C11.6903 6.18181 12.25 6.71093 12.25 7.36363V11.5C12.25 12.1527 11.6903 12.6818 11 12.6818C10.3096 12.6818 9.74999 12.1527 9.74999 11.5V7.36363Z"
                              fill="#47505B"></path>
                      </g>
                    </svg>
                  </div>
                  Podcasts
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane" id="music" role="tabpanel" aria-labelledby="music-tab">
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Tracks" name="Tracks"> <label
                    class="custom-control-label check" for="Tracks">Tracks</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" id="EP"
                                                                   name="EP"> <label class="custom-control-label check"
                                                                                     for="EP">EP's/ LP’s</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Remixes" name="Remixes"> <label
                    class="custom-control-label check" for="Remixes">Remixes</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Playlists" name="Playlists"> <label
                    class="custom-control-label check" for="Playlists">Playlists</label></div>
                <div class="divider"></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method" id="played"
                                                                value="played" class="custom-control-input"> <label
                    class="custom-control-label" for="played">Most played</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method" id="Latest"
                                                                value="Latest" class="custom-control-input"> <label
                    class="custom-control-label" for="Latest">Latest</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method" id="rating"
                                                                value="rating" class="custom-control-input"> <label
                    class="custom-control-label" for="rating">High rating</label></div>
              </div>
              <div class="tab-pane active" id="podcasts" role="tabpanel" aria-labelledby="podcasts-tab">
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="ptalk" name="ptalk"> <label
                    class="custom-control-label check" for="ptalk">Podcasts (Talk)</label></div>
                <div class="custom-control custom-checkbox mb-69"><input type="checkbox" class="custom-control-input"
                                                                         id="pmusic" name="pmusic"> <label
                    class="custom-control-label check" for="pmusic">Podcasts (Music)</label></div>
                <div class="divider"></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method1" id="played1"
                                                                value="played1" class="custom-control-input"> <label
                    class="custom-control-label" for="played1">Most played</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method1" id="Latest1"
                                                                value="Latest1" class="custom-control-input"> <label
                    class="custom-control-label" for="Latest1">Latest</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method1" id="rating1"
                                                                value="rating1" class="custom-control-input"> <label
                    class="custom-control-label" for="rating1">High rating</label></div>
              </div>
              <div class="btn-done js-dropdown-tabs-btn">
                DONE
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <h6>NEWS FEED</h6>
        <div class="dropdown-tabs-wrapper">
          <div class="dropdown-tabs">
            All
            <div class="svg-icon">
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7695 1L6.09052 6.09612L0.999512 1" stroke="#D1433A" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="dropdown-tabs-menu  ">
            <div class="tab-content m-t-0">
              <div class="tab-pane active">
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" id="All"
                                                                   name="All"> <label class="custom-control-label check"
                                                                                      for="All">Saved Articles</label>
                </div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Text" name="Text"> <label
                    class="custom-control-label check" for="Text">Created Articles</label></div>
                <div class="divider"></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method2" id="All-time"
                                                                value="All-time" class="custom-control-input"> <label
                    class="custom-control-label" for="All-time">All time</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method2"
                                                                id="Yesterday" value="Yesterday"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Yesterday">Yesterday</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method2"
                                                                id="Last-week" value="Last-week"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Last-week">Last week</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method2"
                                                                id="Last-month" value="Last-month"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Last-month">Last month</label></div>
              </div>
              <div class="btn-done js-dropdown-tabs-btn">
                DONE
              </div>
            </div>
          </div>
        </div>
      </div>
      4
      <div class="col">
        <h6>EVENTS FEED</h6>
        <div class="dropdown-tabs-wrapper">
          <div class="dropdown-tabs">
            All
            <div class="svg-icon">
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7695 1L6.09052 6.09612L0.999512 1" stroke="#D1433A" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="dropdown-tabs-menu">
            <div class="tab-content m-t-0">
              <div class="tab-pane active">
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" id="All"
                                                                   name="All"> <label class="custom-control-label check"
                                                                                      for="All">All</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Gigs" name="Gigs"> <label
                    class="custom-control-label check" for="Gigs">Gigs</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Clubs" name="Clubs"> <label
                    class="custom-control-label check" for="Clubs">Clubs</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Festivals" name="Festivals"> <label
                    class="custom-control-label check" for="Festivals">Festivals</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Livestreaming" name="Livestreaming"> <label
                    class="custom-control-label check" for="Livestreaming">Livestreaming</label></div>
                <div class="divider"></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method3"
                                                                id="Most-viewed" value="Most-viewed"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Most-viewed">Most viewed</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method3" id="Today"
                                                                value="Today" class="custom-control-input"> <label
                    class="custom-control-label" for="Today">Today</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method3" id="Tomorrow"
                                                                value="Tomorrow" class="custom-control-input"> <label
                    class="custom-control-label" for="Tomorrow">Tomorrow</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method3"
                                                                id="This-week" value="This-week"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="This-week">This week</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method3"
                                                                id="This-weekend" value="This-weekend"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="This-weekend">This weekend</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method3"
                                                                id="Next-week" value="Next-week"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Next-week">Next week</label></div>
              </div>
              <div class="btn-done js-dropdown-tabs-btn">
                DONE
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <h6>JOBS FEED</h6>
        <div class="dropdown-tabs-wrapper">
          <div class="dropdown-tabs">
            All
            <div class="svg-icon">
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7695 1L6.09052 6.09612L0.999512 1" stroke="#D1433A" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="dropdown-tabs-menu">
            <div class="tab-content m-t-0">
              <div class="tab-pane active">
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Artist-Jobs" name="Artist-Jobs"> <label
                    class="custom-control-label check" for="Artist-Jobs">Artist Jobs</label></div>
                <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input"
                                                                   id="Industry-Jobs" name="Industry-Jobs"> <label
                    class="custom-control-label check" for="Industry-Jobs">Industry Jobs</label></div>
                <div class="divider"></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method4"
                                                                id="Most-viewed2" value="Most-viewed"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Most-viewed2">Most viewed</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method4" id="Latest2"
                                                                value="Latest" class="custom-control-input"> <label
                    class="custom-control-label" for="Latest2">Latest</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method4"
                                                                id="High-rating" value="High-rating"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="High-rating">High rating</label></div>
                <div class="custom-control custom-radio"><input type="radio" name="points_payment_method4"
                                                                id="Low-rating" value="Low-rating"
                                                                class="custom-control-input"> <label
                    class="custom-control-label" for="Low-rating">Low rating</label></div>
              </div>
              <div class="btn-done js-dropdown-tabs-btn">
                DONE
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      musicFeedMore: false
    }
  },
  components: {},
  computed: {},
  methods: {}
}
</script>
