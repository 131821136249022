<template>
  <div>
    <div class="photo-list">
      <media-albums-listing @getMediaAlbumsList="getMediaAlbums" :listData="mediaAlbumsList" :totalMediaAlbums="mediaAlbumsCount" @setShowMore="setShowMore" @showMore = "showMore"/>
      <div class="col-12" style="margin-bottom: 30px;">
        <div v-if="mediaAlbumsList.length >= 4 && mediaAlbumsList.length != mediaAlbumsCount && current_page" class="artist-show-more" v-on:click="setLimit">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V11.0003" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M11 6L0.999665 6" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
          SHOW MORE
        </div>
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script type="application/javascript">
import mediaAlbumsListing from "@/components/Home/Profile/media/albums/mediaAlbumsListing.vue";
import { getAllMediaAlbumsOfUsers } from "../../../../../apis/APIs";

export default {
  name: "mediaAlbums",
  props:{
    user_id:{
      type:Number,
      Default:null,
    }
  },
  data() {
    return {
      mediaAlbumsList: [],
      offSetLimit: 0,
      limit: 4,
      loading: false,
      dataLoaded: false,
      mediaAlbumsCount: '',
      current_page: true
    }
  },
  components: {
    mediaAlbumsListing
  },
  mounted() {
    this.getMediaAlbums();
  },
  methods: {
    async getMediaAlbums() {
      try {
        this.loading = true
        let data = await getAllMediaAlbumsOfUsers(this.offSetLimit, this.limit,(this.user_id)?this.user_id:null);
        this.mediaAlbumsList = data.data.information.data;
        this.mediaAlbumsCount = data.data.information.total;
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    setShowMore(){
      this.current_page = false
    },
    showMore(){
      this.current_page = true
    },
    setLimit() {
      this.limit = this.mediaAlbumsList.length + 4;
      this.getMediaAlbums();
    }
  }
}
</script>

<style scoped>

</style>
