<template>
  <!-- Upload Photos -->
  <div>
  <div class="post-filed-options-c" v-if="!selectedTab" :style="is_edit_post ? 'border-bottom:' + 'none' : ''">
    <div class="upload-photos-section mt-3">
      <div class="row">
        <div class="col">
          <div class="upload-photos-title">
            <div class="back-photo-icon" @click="goBack()">
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 9L1 5L5 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <div class="bac-text-tite">Upload Videos</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="upload-video-section">
            <ul>
              <!-- <li>
              <div class="uploaded-image">
                  <img src="img/Rectangle1.png">
              </div>
              <span class="cross-icon">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
              </span>
              </li> -->
              <!-- <li>
              <div class="uploaded-image">
                  <img src="img/Rectangle2.png">
              </div>
              <span class="cross-icon">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
              </span>
              </li> -->

              <li v-for="(eachOp, index) in preview_list" :key="'image'+index">
                <div class="uploaded-video">
                  <video :src="eachOp" class="video-preview" controls="controls"/>
                </div>
                <span class="cross-icon btn" style="background: #454545" @click="removeVideo(index)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#000000"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="white" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.5 4.5L13.5 13.5" stroke="white" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
              </li>
              <li class="upload-photo-section-li">
                <div class="upload-photo-icons">
                  <img :src="require('@/assets/img/add-p.png')" class="sampleVideo">
                  <input type="file" multiple="multiple" accept="video/mp4,video/x-m4v,video/*" name="fileToUpload"
                         @change="uploadVideos" id="fileToUpload">
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" v-if="!is_edit_post">
        <div class="col">
          <div class="Sponsors-section upload-photo-sponsers">
            <div class="action-block ">
              <div class="switch-button">
                <label class="switch "> <input type="checkbox" checked="checked" class="default"> <span
                    class="slider round"></span> </label>
              </div>
              For All Users
            </div>
            <div class="select-audience-button" v-if="!selectedTab" @click="selectedTab = 'selectAudience'">
              <span v-if="!audience"> Choose Audience</span>
              <span v-if="audience"> {{audience.toUpperCase().replace(/_/g, ' ')}}</span>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
      <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
    </div>
  </div>

</template>
<script>
import SelectAudience from './components/selectAudience';
import {mapGetters, mapMutations} from 'vuex';
import {
  CREATE_POST_FORM_IMAGES,
  CREATE_POST_FORM_IS_ALBUM,
  CREATE_POST_FORM_ALBUM_NAME,
  CREATE_POST_FORM_SELECTED_IMAGES
} from '../../../store/types'
import {readURL} from '../../utils/Utils';
import audience from "../../Events/components/Detail/Audience.vue";

export default {
  data() {
    return ({
      videos: [],
      create_album: false,
      album_name: "",
      preview_list: [],
      selectedTab:"",
      audience:null,
    })
  },
  props: {
    is_edit_post: {
      type: Boolean,
    },
    post: {
      type: Object,
    },
  },
  watch: {
    create_album(value) {
      if (value) {
        this.$store.commit('SET_TYPE', 'album')
        this.$store.commit('SET_TYPE_DESCRIPTION', 'share a album')

      } else {
        this.$store.commit('SET_TYPE', 'timeline_photo')
        this.$store.commit('SET_TYPE_DESCRIPTION', 'share a photo')
        this.$store.commit('SET_ALBUM', null)
      }
    },
    selectedTab(value) {
      if (value) this.hideTopBarAndShowPostBtn();
    }
  },
  components: {
    SelectAudience,
  },
  computed: {
    ...mapGetters({
      form_data: "create_album_forms_map_to_props",
    }),
    selectedVideos: {
      set(newValue) {
        this.$store.commit('SET_VIDEOS', newValue)
      },
      get() {
        return this.$store.getters.getVideos
      }
    },
    albumTitle: {
      set(newValue) {
        this.$store.commit('SET_ALBUM', newValue)
      },
      get() {
        return this.$store.getters.getAlbumTitle
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations([
      "updateCreatePostFormData"
    ]),
    init() {
      this.$store.commit('SET_TYPE', 'video')
      this.$store.commit('SET_TYPE_DESCRIPTION', 'shared an video')
      if(this.is_edit_post){
      this.preview_list = this.post && this.post.artwork_url.map((item) => {return item.artwork_url});
      }
    },
    goBack() {
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
      this.$parent.goBack();
    },
    onChange(e) {
      const {name, value} = e.target || {};
      this.updateCreatePostFormData({
        [name]: value
      });
    },
    uploadVideos(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.videos.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
        this.selectedVideos = this.videos
      }
    },
    hideTopBarAndShowPostBtn() {
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      createPostOptionsContainer.style.display = "none";
    },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      this.audience = audience.toLowerCase().replace(/ /g, '_');
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
    },
    removeVideo(index){
      this.preview_list.splice(index, 1);
      this.videos.splice(index, 1);
      const deletedId = this.post.artwork_url[index].id;
      this.post.artwork_url.splice(index, 1);
      this.$emit('deleteMediaList', deletedId)
      // if(this.post){
      //   this.$emit('deleteMediaList', this.post.artwork_url[index].id)
      // }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  width: 250px;
  height: 40px;
  background: #F3F3F3;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
.sampleVideo{
  width: unset;
  height: unset;
  object-fit: none;
}
</style>
