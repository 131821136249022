<template>
  <div class="modal fade modal-add-to-cart contact_info create-new-ticket show" id="move-draft" tabindex="-1"
       role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="padding-right: 15px; display: block;">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button right-20" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="item-title">
          <div class="short-border">
            Move to Drafts
          </div>
        </div>
        <div class="hire-text">
          Are you sure you want move this job <br>to drafts?
        </div>
        <div class="action-item action-hire">
          <div class="btn back-btn" v-on:click="closeModal">Cancel</div>
          <div class="btn check-btn">MOVE</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
