import {
  CURRENT_PAGE,
  CURRENT_SONG,
  ERROR,
  LOADING,
  POST_TOTAL,
  SONG_COMMENT_LIMIT,
  SONG_COMMENT_OFFSET,
  SONG_COMMENT_TOTAL,
  SONG_COMMENTS,
  SUCCESS,
  SONG_PLAY_COUNT,
} from "../types";
import { getCurrentSongComments } from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";
import createFormData from "../../mixins/common";

const INITIAL_STATE = {
  [CURRENT_SONG]: {
    [SONG_COMMENTS]: [],
    [SONG_COMMENT_OFFSET]: 0,
    [SONG_COMMENT_LIMIT]: 8,
    [SONG_COMMENT_TOTAL]: 0,
    [SONG_PLAY_COUNT]: null, // Song Play count,
    [LOADING]: false,
  },
};

export const state = { ...INITIAL_STATE };
export const actions = {
  async getSongComments(
    { state, commit, dispatch },
    { commentable_id, commentable_type, showMore = false }
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch("loading", true);

        if (showMore) {
          commit("NEXT_PAGINATE_POSTS_OFFSET_SET");
        } else {
          commit("CLEAR_CURRENT_SONG_COMMENT_DATA");
        }
        let payload = {
          commentable_id: commentable_id,
          commentable_type: commentable_type,
        };
        const formData = createFormData(payload);
        const { data } = await getCurrentSongComments(
          formData,
          state[CURRENT_SONG][SONG_COMMENT_OFFSET],
          state[CURRENT_SONG][SONG_COMMENT_LIMIT]
        );
        const { information } = data;
        if (showMore) {
          commit("JOIN_SONG_COMMENTS_WITH_OTHER_COMMENTS", information.data);
        } else {
          commit("SET_SONG_COMMENT_DATA", information.data);
        }
        commit("SET_SONG_TOTAL_COMMENTS", information.total);
        dispatch("loading", false);
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        dispatch("loading", false);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
};

export const mutations = {
  CLEAR_CURRENT_SONG_COMMENT_DATA(state) {
    try {
      state[CURRENT_SONG][SONG_COMMENT_OFFSET] = 0;
      state[CURRENT_SONG][SONG_COMMENT_LIMIT] = 8;
      state[CURRENT_SONG][SONG_COMMENT_TOTAL] = 0;
      state[CURRENT_SONG][SONG_COMMENTS] = [];
    } catch (e) {
      console.error(e.message, "unable to clear current song data");
    }
  },
  JOIN_SONG_COMMENTS_WITH_OTHER_COMMENTS(state, payload) {
    let comments = state[CURRENT_SONG][SONG_COMMENTS];
    state[CURRENT_SONG][SONG_COMMENTS] = [...comments, ...payload];
  },
  SET_SONG_COMMENT_DATA(state, page) {
    try {
      state[CURRENT_SONG][SONG_COMMENTS] = page;
    } catch (e) {
      console.error(e.message, "unable to set song comments data");
    }
  },
  SET_SONG_TOTAL_COMMENTS(state, payload) {
    try {
      state[CURRENT_SONG][SONG_COMMENT_TOTAL] = payload;
    } catch (e) {
      console.error(e.message, "unable to SET SONG Total Comments Counts");
    }
  },
  SET_SONG_PLAY_COUNT(state, payload) {
    try {
      state[CURRENT_SONG][SONG_PLAY_COUNT] = payload;
    } catch (e) {
      console.log(e.message, " Unable to set total count");
    }
  },
};
const getters = {
  getCurrentSongComments(state) {
    let _state = Utils.parseState(state);
    return _state[CURRENT_SONG][SONG_COMMENTS];
  },
  // GET SONG PLAY COUNT
  getSongCount(state) {
    let _state = Utils.parseState(state);
    return _state[CURRENT_SONG][SONG_PLAY_COUNT];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
