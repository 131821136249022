<template>
  <div id="app">
    <link-prevue :url="url">
      <template slot-scope="props">
        <div class="card" @click="takeToLink(props.url)">
          <img class="card-img-top" :src="props.img" :alt="props.title" />
          <div class="card-block">
            <div class="card-text">
              <h4 class="card-title">{{props.title}}</h4>
              <p class="card-description">{{props.description}}</p>
            </div>
          </div>
        </div>
      </template>
    </link-prevue>
  </div>
</template>

<script>
import LinkPrevue from 'link-prevue'

export default {
  name: "LinkPreviewComponent",
  props: {
    url: {
      type: String
    }
  },
  components:{
    LinkPrevue
  },
  methods: {
    takeToLink(link) {
      window.open(link)
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  overflow: auto;
  border-radius: 7px 7px 7px 7px;
  background-color: #fff;
  width: 15rem;
  cursor: pointer;

  .card-block {
    border-radius: 0 0 7px 7px;
    background-color: #fff;

    .card-text {
      width: 80%;
      margin: 0 auto;
      text-align: justify;

      .card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #071526;
        margin: 5px 0 5px 0;
      }

      .card-description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #47505B;
      }
    }

    .card-btn {
      margin: 1em 0 1em 0;
      position: relative;
      text-align: center;
    }
  }
}
</style>
