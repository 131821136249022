<template>
  <div>
    <SignupEmail
      v-if="currentModal == 'sign-up-email'"
      @closeModal="closeModal"
      @nextStep="nextStep"
    />
    <LoginModule
      v-if="currentModal == 'login'"
      @closeModal="closeModal"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @newModal="newModal"
    />
    <RestorePassword
      v-if="currentModal == 'restore-password'"
      @closeModal="closeModal"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @newModal="newModal"
    />
    <ConfirmationModal
      v-if="currentModal == 'confirmation-modal'"
      @closeModal="closeModal"
    />
    <NeedSignUp
      v-if="currentModal == 'need-signup'"
      @closeModal="closeModal"
      @newModal="newModal"
    />
    <RegisterStep1
      v-if="currentModal == 'register-step-1'"
      @closeModal="closeModal"
      @newModal="newModal"
      :ref="clearData"
    />
    <RegisterStep2
      v-if="currentModal == 'register-step-2'"
      @closeModal="closeModal"
      @newModal="newModal"
      @clearFormData="formDataClear"
    />
    <VerifyEmail
      v-if="currentModal == 'verify-email'"
      @closeModal="closeModal"
      @newModal="newModal"
    />
    <RegisterCompleteModal
      v-if="currentModal == 'verification-status'"
      @closeModal="closeModal"
      @newModal="newModal"
    />
    <ResetPasswordSecurityCode
      v-if="currentModal == 'reset-password-security-code'"
      @closeModal="closeModal"
      @newModal="newModal"
    />
  </div>
</template>
<script type="application/javascript">
import SignupEmail from "@/components/Landing/modals/SignupEmail.vue";
import LoginModule from "@/components/Landing/modals/LoginModal.vue";
import RestorePassword from "@/components/Landing/modals/RestorePassword.vue";
import ConfirmationModal from "@/components/Landing/modals/ConfirmationModal.vue";
import NeedSignUp from "@/components/Landing/modals/NeedSignUp.vue";
import RegisterStep1 from "@/components/Landing/modals/RegisterStep1.vue";
import RegisterStep2 from "@/components/Landing/modals/RegisterStep2.vue";
import VerifyEmail from "@/components/Landing/modals/VerifyEmail.vue";
import RegisterCompleteModal from "@/components/Landing/modals/RegisterCompleteModal.vue";
import ResetPasswordSecurityCode from "@/components/Landing/modals/ResetPasswordSecurityCode.vue";

import {mapMutations, mapGetters} from 'vuex';

export default {
  data() {
    return {
      currentModal: "",
      modalFor: "",
      step: 1,
      showMore: false,
      clearData: false
    };
  },
  components: {
    SignupEmail,
    LoginModule,
    RestorePassword,
    ConfirmationModal,
    NeedSignUp,
    RegisterStep1,
    RegisterStep2,
    VerifyEmail,
    RegisterCompleteModal,
    ResetPasswordSecurityCode
  },
  computed: {
    ...mapGetters({
      form_data: "system_map_to_props"
    }),
    is_authenticated() {
      return this.form_data && this.form_data.is_authenticated ? this.form_data.is_authenticated : false
    }
  },
  mounted() {
    const { restorePassword, emailConfirmation } = this.$route.query;

    if (restorePassword==='true') {
      this.currentModal = "reset-password-security-code";
    } else if (emailConfirmation==='true') {
      this.currentModal = "verify-email";
    }

    if (this.$route.params.key) {
      this.$store.commit("SET_AFFILIATION_TOKEN_VALUE", this.$route.params.key)
    }
  },
  methods: {
    ...mapMutations([
      "resetSignupState",
      "resetLoginState"
    ]),
    formDataClear(value) {
      this.clearData = value
      if (this.clearData) {
        this.$refs.clearData.clearFormData();
      }
    },
    nextStep: function () {
      if (this.modalFor === "login") {
        if (this.step === 1) {
          this.step = 2;
          this.currentModal = "login";
        }
      } else {
        this.currentModal = "need-signup";
      }
    },
    previousStep: function () {
      // if (this.modalFor == "login") {
      //   if (this.step == 2) {
      //     this.step = 1;
      //     this.currentModal = "sign-up-email";
      //   }
      // } else {
      //   alert("1");
      // }

      this.currentModal = "sign-up-email";
    },
    closeModal: function (modalType) {
      this.resetSignupState();
      this.resetLoginState();
      if (modalType == "sign-up-email") {
        this.currentModal = "";
        this.step = 1;
      } else {
        this.currentModal = "";
        this.step = 1;
      }
    },
    openModal: function (modalType, modalFor) {
      this.modalFor = modalFor;
      if (modalType == "sign-up-email") {
        this.currentModal = "sign-up-email";
      } else if (this.modalFor === "reset-password-security-code") {
        console.log('hihi');
        this.currentModal = "reset-password-security-code";
      } else if (this.modalFor === "verify-email") {
        this.currentModal = "verify-email";
      }
    },
    newModal: function (modalType) {
      this.currentModal = modalType;
    }
  }
};
</script>
