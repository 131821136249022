var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-card"},[_c('div',{staticClass:"page-card-body"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("Cover Image")]),_c('div',{staticClass:"upload-artist-cover"},[_c('img',{attrs:{"src":require(`@/assets/img/upload.png`)}}),_c('input',{attrs:{"type":"file","id":"myfile","name":"myfile"}})])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("Name your Page"),_c('sup',{staticClass:"sup-color"},[_vm._v("*")])]),_c('input',{attrs:{"type":"text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("Page Address")]),_c('div',{staticClass:"page-address"},[_c('span',{staticClass:"email-ico"},[_vm._v(" www.audioground.com/ ")]),_c('input',{attrs:{"type":"text","placeholder":"youraddress"}})]),_c('span',{staticClass:"page-text"},[_vm._v("Can only contain alphanumeric characters (A–Z, 0–9) and periods ('.')")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("Category"),_c('sup',{staticClass:"sup-color"},[_vm._v("*")])]),_c('div',{staticClass:"category-select-n"},[_c('select',{staticClass:"page-control",attrs:{"name":"edit_profile_form_gender"}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Gender")]),_c('option',{attrs:{"value":"M"}},[_vm._v("Male")]),_c('option',{attrs:{"value":"F"}},[_vm._v("Female")]),_c('option',{attrs:{"value":"O"}},[_vm._v("Other")])])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("SubCategory"),_c('sup',{staticClass:"sup-color"},[_vm._v("*")])]),_c('div',{staticClass:"category-select-n"},[_c('select',{staticClass:"page-control",attrs:{"name":"edit_profile_form_relationship_status"}},[_c('option',{attrs:{"value":""}},[_vm._v("Select Relationship")]),_c('option',{attrs:{"value":"Single"}},[_vm._v("Single")]),_c('option',{attrs:{"value":"Married"}},[_vm._v("Married")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("About")]),_c('textarea')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"page-form-n"},[_c('label',[_vm._v("Avatar Image")]),_c('div',{staticClass:"page-avatar-section"},[_c('div',{staticClass:"page-avatar-icon"},[_c('img',{attrs:{"src":"/img/birthday-dp.8f36c8c9.png"}})]),_c('div',{staticClass:"avtatr-upload"},[_c('p',[_vm._v("No Avatar Image")]),_c('div',{staticClass:"avatar-upload-section"},[_c('input',{attrs:{"type":"file","id":"myfile","name":"myfile"}}),_vm._v(" Upload Now "),_c('span',{staticClass:"line"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-btn"},[_c('button',[_vm._v("PUBLISH")])])
}]

export { render, staticRenderFns }