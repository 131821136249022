<template>
  <div class="widget-follow Follow_widget_right width370">
    <div class="widget-title mb-2">
      <span>{{ pageTitle }}</span>
      <div class="link-more near_arrow without-slider in-title cursor-pointer">
        <a @click="redirectToChannel(channelType, altName, pageTitle)"
          >show more
          <span class="sl-arrow"
            ><img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo=" /></span
        ></a>
      </div>
    </div>
    <div v-for="(row, index) in listData.slice(0, 3)" :key="index">
      <WhoToFollowBarTile :eventsPage="eventsPage" :item="row" />
    </div>
  </div>
</template>
<script type="application/javascript">
import WhoToFollowBarTile from "../UiComponenets/Music/WhoToFollowBarTile";
export default {
  data() {
    return {
      listing_type: "grid",
    };
  },
  components: {
    WhoToFollowBarTile,
  },
  computed: {},
  props: {
    altName: String,
    pageTitle: String,
    channelType: {
      type: String,
      default: null,
    },
    pageFrom: String,
    listData: {
      type: Array,
      default: () => [],
    },
    eventsPage: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
