<template>
  <div class="row">
    <div class="col">
        <listArtistCardSmall  v-for="(eachData, index) in listData" :key="index" :item="eachData" />
    </div>
  </div>
</template>
<script type="application/javascript">

import listArtistCardSmall from "@/components/DesignGuide/Music/Components/ListArtistRecordCardSmall";

export default {
  data() {
    return {
      showSubMenu: 'none',
      showMore: false,
      selectedLoved: []
    }
  },
  components: {
    listArtistCardSmall
  },
  props: {
    width: String,
    hasTitleHeader: {
      type: Boolean,
      default: true
    },
    listData: {
      type: Array,
      default: () => []
    }
  },
}
</script>
<style scoped lang="scss">
@import '/src/assets/style/components/popularArtist.scss';
</style>
