<template>
  <div
    class="card genres-list type-list card-width"
    :class="[
      showMore === true ? 'open' : '',
      { 'singles-width': singles },
      { 'rating-width': rating },
      { 'max-width': !rating && !singles },
    ]"
  >
    <div class="row line-1">
      <div class="d-flex align-items-center justify-content-start col-2">
        <b-img
          :src="
            item && item.artwork_url
              ? item.artwork_url
              : 'https://auddiodev-api.theplayground.co.uk/common/default/album.png'
          "
          rounded="left"
          alt="Left-rounded image"
          style="height: 65px"
        ></b-img>
        <div
          class="play-button js-play-button new-play-button m-0"
          :class="[singles || rating ? 'play-button-left' : 'play-box-left']"
          @click="
            playPause($event, item.type === 'song' ? [item] : item.album_songs)
          "
        >
          <div class="svg-icon">
            <svg
              width="13"
              height="16"
              viewBox="0 0 13 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="play-type-1"
            >
              <path
                d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                stroke="white"
                stroke-width="1.3"
                class="c-fill-1 c-line-1"
              ></path>
            </svg>
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                fill="white"
                class="c-line-1 c-fill-1"
              ></path>
              <path
                d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                stroke="white"
                stroke-width="1.3"
                class="c-line-1 c-fill-1"
              ></path>
            </svg>
            <svg
              width="10"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1V13"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                class="c-line-1 c-fill-1"
              ></path>
              <path
                d="M9 1V13"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                class="c-line-1 c-fill-1"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        v-if="rating"
        class="col-1 d-flex align-items-center"
        :class="[singles ? 'p-0' : 'pl-0']"
      >
        <div>
          <span v-if="rating">1</span>
        </div>
        <div
          v-if="rating"
          class="d-flex align-items-center justify-content-end border-line ml-2"
        >
          <div class="artist ml-2 mr-1">
            <a href="#">5</a>
          </div>
          <div>
            <b-img :src="require(`@/assets/icons/up-green-arrow.svg`)"></b-img>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between"
        :class="[rating || singles ? 'col-3' : 'col-4 pl-0']"
      >
        <div v-if="singles">
          <span>1</span>
        </div>
        <div>
          <div class="artist">
            <a href="#">{{ item.page ? getArtist(item.page) : "Artist" }}</a>
          </div>
          <div class="">
            <a href="#">{{ item.title ? item.title : "Song Name" }}</a>
          </div>
        </div>
        <div v-if="!rating && !singles" class="">
          <div class="artist">
            <a href="#">{{ item.page ? getArtist(item.page) : "Artist" }}</a>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-evenly align-items-center pl-0"
        :class="[rating ? 'col-3' : 'col-4']"
      >
        <div class="">
          <span>{{
            item && item.duration
              ? convertSecondsIntoMinuets(item.duration)
              : "0:00"
          }}</span>
        </div>
        <div class="" v-if="!rating && !singles">
          <span>
            {{
              item.created_at
                ? convertDateIntoHumanReadableForm(item.created_at)
                : ""
            }}
          </span>
        </div>
        <div class="popularity">
          <div class="empty">
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
          </div>
          <div class="full">
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
          </div>
          <div class="count">3.2k</div>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-evenly"
        :class="[rating || singles ? 'col-3' : 'col-2 pl-0']"
      >
        <div
          class="wishlist js-wishlist"
          :class="{ active: favorite === true }"
          @click="favouriteUnfavourite(item)"
        >
          <div class="svg-icon">
            <b-spinner
              v-if="spinner"
              small
              label="Small Spinner"
              class="spinner-color"
            ></b-spinner>
            <svg
              v-else
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="c-line-1 c-fill-1"
              ></path>
            </svg>
          </div>
        </div>
        <div class="more dropright">
          <div
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-display="static"
            class="dropdown vertical more-dropdown"
          >
            <div class="svg-icon more-position">
              <svg
                width="15"
                height="3"
                viewBox="0 0 15 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-more-hor"
              >
                <path
                  d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                  fill="white"
                  class="c-fill-1"
                ></path>
                <path
                  d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                  fill="white"
                  class="c-fill-1"
                ></path>
                <path
                  d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                  fill="white"
                  class="c-fill-1"
                ></path>
              </svg>
              <svg
                width="3"
                height="15"
                viewBox="0 0 3 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-more"
              >
                <path
                  d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                  class="c-fill-1"
                ></path>
                <path
                  d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                  class="c-fill-1"
                ></path>
                <path
                  d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                  class="c-fill-1"
                ></path>
              </svg>
            </div>
            <div
              aria-labelledby="moreDropdown"
              class="dropdown-menu absolute-position more-dropdown"
            >
              <a href="#" class="dropdown-item item-selling js-save-wishlist"
                ><span>Buy</span>
                <div class="more-price">$00</div> </a
              ><a href="#" class="dropdown-item item-selling js-save-wishlist"
                ><span>Favorites</span></a
              >
              <div class="dropdown-item js-rate with-submenu">
                <span>Rate</span>
                <div class="more-rate margin-right-this">5.0</div>
                <div class="svg-icon arrow-icon d-flex ml-auto">
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5.3 5.231L1 9.833"
                      stroke="#47505B"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="c-line-1"
                    ></path>
                  </svg>
                </div>
                <div class="submenu" style="display: none">
                  <div class="submenu-item">
                    <div class="text">Click to Rate</div>
                    <div class="submenu-stars with-js">
                      <div data-value="1" class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div data-value="2" class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div data-value="3" class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div data-value="4" class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div data-value="5" class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="submenu-item">
                    <div class="text">Average Rating</div>
                    <div class="submenu-stars">
                      <div class="star svg-icon active">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div class="star svg-icon active">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div class="star svg-icon active">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                      <div class="star svg-icon">
                        <svg
                          width="17"
                          height="14"
                          viewBox="0 0 17 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="c-line-1 c-fill-1"
                        >
                          <path
                            d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                            fill="white"
                            stroke="white"
                            stroke-width="1.3"
                            class="c-line-1 c-fill-1"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="submenu-item rate">9 Ratings</div>
                  <div class="submenu-item sub-see-all d-flex ml-auto">
                    See all songs
                    <div class="svg-icon">
                      <svg
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5.3 5.231L1 9.833"
                          stroke="#47505B"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <a href="#" class="dropdown-item js-repost-album"
                ><span>Repost</span></a
              ><a href="#" class="dropdown-item js-share-album"
                ><span>Share</span
                ><span class="sh_tab"
                  ><svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5.3 5.231L1 9.833"
                      stroke="#47505B"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="c-line-1"
                    ></path></svg></span
              ></a>
            </div>
          </div>
        </div>
        <div class="extend js-extend" @click="showComments()">
          <div class="svg-icon">
            <svg
              width="11"
              height="6"
              viewBox="0 0 11 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="svg-arrow-down"
            >
              <path
                d="M9.83301 1L5.60201 5.3L1.00001 1"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="c-line-4"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="row line-2">
      <div :class="[rating ? 'col-2' : singles ? 'col-1 mr-4' : 'col-1']"></div>
      <div
        v-if="rating || singles"
        class="col-1"
        :class="[singles ? 'p-0' : '']"
      ></div>
      <div
        class="d-flex align-items-center justify-content-evenly"
        :class="[singles || rating ? 'pl-0 col-6' : 'col-8']"
      >
        <button
          :class="[
            'btn btn-information text',
            favorite === true ? 'active-background' : '',
          ]"
          @click="favouriteUnfavourite(item)"
        >
          <div class="svg-icon">
            <b-spinner
              v-if="spinner"
              small
              label="Small Spinner"
              class="spinner-color"
            ></b-spinner>
            <svg
              v-else
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="svg-icon-text"
              ></path>
            </svg>
          </div>
          <span>00</span>
        </button>
        <button class="btn btn-information text">
          <div class="svg-icon">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="svg-icon-text"
              ></path>
            </svg>
          </div>
          <span>00</span>
        </button>
        <button class="btn btn-information text">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9.16667L1 3.33306L15 3.33306L10.9989 1"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="svg-icon-text"
              ></path>
              <path
                d="M15 8L15 12.9548L1 12.9548L5.81381 15"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="svg-icon-text"
              ></path>
            </svg>
          </div>
          <span>000k</span>
        </button>
        <button class="btn btn-information">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9995 10.3359V13.4469C14.9995 13.8595 14.8357 14.2551 14.5439 14.5469C14.2522 14.8386 13.8566 15.0025 13.444 15.0025H2.5555C2.14296 15.0025 1.74731 14.8386 1.4556 14.5469C1.16388 14.2551 1 13.8595 1 13.4469V10.3359"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.8889 4.88876L8.00009 1L4.11133 4.88876"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 1V10.333"
                stroke="#071526"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </button>
      </div>
      <div class="d-flex align-items-center justify-content-evenly p-0 col-3">
        <div class="btn btn-icon-border text">
          <div class="svg-icon">
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="shop-cart-v2"
            >
              <path
                d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z"
                stroke="#D1433A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="c-line-1"
              ></path>
              <path
                d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                stroke="#D1433A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="c-line-1"
              ></path>
            </svg>
          </div>
          <div>$00</div>
        </div>
        <div class="btn btn-play-count">
          <div class="svg-icon">
            <svg
              width="7"
              height="8"
              viewBox="0 0 7 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="small-play-svg"
            >
              <path
                d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                fill="#8B949F"
                class="c-fill-1"
              ></path>
            </svg>
          </div>
          <span>3.2k </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SongOrAlbumTileCard",
  data() {
    return {
      showMore: false,
      favorite: false,
      favoriteCounts: 0,
      spinner: false,
    };
  },
  props: {
    rating: {
      type: Boolean,
      default: false,
    },
    singles: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showComments() {
      this.showMore = !this.showMore;
    },
    async favouriteUnfavourite(object) {
      this.spinner = true;
      const res = await this.setFavourite(object);
      if (res.success === 1) {
        this.favorite = this.favorite !== true;
        this.favorite === true ? this.favoriteCounts++ : this.favoriteCounts--;
        this.getFavoriteSongs();
      }
      this.spinner = false;
    },
  },
};
</script>

<style scoped>
.singles-width {
  max-width: 100%;
}
.rating-width {
  max-width: 100%;
}
.max-width {
  max-width: 770px;
}
.card-width {
  /*max-width: 770px;*/
  /*min-width: 570px*/
}
.card.genres-list .line-1 .play-button {
  top: 12px !important;
}
.card.genres-list .extend {
  margin-right: 15px;
}
.play-pause-col {
  flex: 0 0 12.666667%;
  max-width: 12.666667%;
}
.play-box-left {
  left: 28px !important;
}
.play-pause-col-padding {
  padding-left: 14px;
}
.play-button-left {
  left: 28px !important;
}
.btn.btn-information.text {
  min-width: 56px !important;
  width: auto !important;
}
.play-pause-below-col {
  flex: 0 0 12.666667%;
  max-width: 12.666667%;
}
.number-span {
  margin-left: 7px;
}
.rating-col {
  flex: 0 0 5.333333%;
  max-width: 5.333333%;
  height: 65px;
  margin-left: 16px;
  margin-right: 24px;
  border-left: 1px solid #e6eaf0;
}
.rating-col .artist {
  margin-right: 5px;
  margin-left: 18px;
}
.rating-below-col {
  flex: 0 0 5.333333%;
  max-width: 5.333333%;
  margin-left: 18px;
  margin-right: 24px;
}
.border-none {
  border: none !important;
}
.track {
  width: 6rem !important;
}
.card.genres-list.open .line-2 .btn-icon-border {
  margin-right: 0 !important;
}
.active-background {
  background-color: #D1433A1A !important;
}
.spinner-color {
  color: #D1433A !important;
}
.border-line {
  border-left: 1px solid #e6eaf0;
  /* height: 65px; */
}
.btn.btn-icon-border.text {
  width: auto !important;
  min-width: 60px !important;
}
</style>
