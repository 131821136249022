<template>
  <div class=" sale-items " :class="[header?'m-t-20':'']">
    <div class="row" v-if="header">
      <div class="col-sm-12">
        <h4 class="title-head top-sign-bottom">{{ header ? 'Top 100 By Country' : '' }}</h4>
        <div class="link-more near_arrow without-slider cursor-pointer" style="top: 0"><a
            @click="redirectToAllCountries"
        >
          See all
          <span class="sl-arrow">
                                    <img
                                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
        </a></div>
      </div>
    </div>
    <div class="row ">
      <ChartCountryCard class="col-2" :small="true"  :colorCode="1"/>
      <ChartCountryCard class="col-2" :small="true" v-for="(country,index) in listData.slice(0, 6)" :key="index" :colorCode="Number(index)+2" :country="country"/>
    </div>
  </div>
</template>
<script type="application/javascript">
// import ListOption from "@/components/Common/Elements/ListOptions.vue";
 import ChartCountryCard from "@/components/DesignGuide/Music/Components/GridChart.vue";
export default {
  data() {
    return {
      optionWidth: 0,
      isHovering: false,
    }
  },
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    }
  },
  components: {
      ChartCountryCard
  },
  computed: {},
  methods: {
    isHoveringFun: function (val, index) {
      this.songList[index].isHovering = val;
    },
    redirectToAllCountries(){
      this.$router.push('charts/all-countries')
    }
  }
}
</script>
