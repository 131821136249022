<template>
  <div class="container find-music-filter">
    <div class="row">
      <div class="col">
        <div class="find-jobs-top-wrap">
          <div class="keyword-wrap">
            <input type="text" placeholder="Search Music" @click="$emit('check-user')" />
            <div class="svg-icon">
              <img :src="require(`@/assets/icons/search2.svg`)"/>
            </div>
          </div>
          <div class="genres-wrap">
            <div class="svg-icon genre-ico">
              <img :src="require(`@/assets/icons/list.svg`)"/>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select
                  class="filters-select f-s selectpicker"
                  title="All Genres"
                  multiple=""
                  data-max-options="1"
                  dropupauto="false"
                  tabindex="-98"
              >
                <option
                    :value="key"
                    v-for="(op, key) in optionsList"
                    :key="key"
                >{{ op.name }}
                </option
                >
              </select>
              <button
                  type="button"
                  class="btn dropdown-toggle btn-light bs-placeholder"
                  data-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="All Genres"
              >
                <div class="filter-option" @click="$emit('check-user')">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">
                      {{ selected_value }}
                    </div>
                  </div>
                </div>
              </button>
              <div
                  class="dropdown-menu"
                  style="max-height: 523px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -10px, 0px);"
                  x-placement="top-start"
              >
                <div
                    class="inner show"
                    role="listbox"
                    id="bs-select-1"
                    tabindex="-1"
                    aria-multiselectable="true"
                    style="max-height: 505px; overflow-y: auto; min-height: 100px;"
                >
                  <ul
                      class="dropdown-menu inner show"
                      role="presentation"
                      style="margin-top: 0px; margin-bottom: 0px;"
                  >
                    <li
                        v-for="(op, key) in optionsList"
                        :key="key"
                        v-on:click="updateSelected(op, key)"
                    >
                      <a
                          role="option"
                          class="dropdown-item"
                          :key="key"
                          id="bs-select-1-18"
                          :tabindex="key"
                          aria-selected="false"
                      >
                        <span class=" bs-ok-default check-mark"></span>
                        <span class="text">{{ op.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="btn find-jobs-btn" v-on:click="$emit('check-user')">FIND MUSIC</div>
        </div>
      </div>
    </div>
    <AuthHandler ref="auth-handler"/>
  </div>
</template>
<script type="application/javascript">
import AuthHandler from "./modals/AuthHandler.vue";

export default {
  data() {
    return {
      optionsList: [
        {name: "All Genres", is_selected: false},
        {name: "Afro", is_selected: false},
        {name: "Alternative", is_selected: false},
        {name: "Blues", is_selected: false},
        {name: "Caribbean", is_selected: false},
        {name: "Classical", is_selected: false},
        {name: "Dance", is_selected: false},
        {name: "Easy Listening", is_selected: false},
        {name: "Electronic", is_selected: false},
        {name: "Folk &amp; Acoustic", is_selected: false},
        {name: "Hip Hop / Rap", is_selected: false},
        {name: "House", is_selected: false},
        {name: "Indie", is_selected: false},
        {name: "Jazz", is_selected: false},
        {name: "Pop", is_selected: false},
        {name: "R&amp;B / Soul", is_selected: false},
        {name: "Rock", is_selected: false},
        {name: "Techno", is_selected: false},
        {name: "Wo``rld", is_selected: false}
      ],
      selected_value: "All Genres"
    };
  },
  components: { AuthHandler },
  methods: {
    updateSelected: function (value, key) {
      this.optionsList[key]["is_selected"] = true;
      this.selected_value = value.name;
      console.log(value, "roe");
    },
    openModal: function (modalType, modalFor) {
      this.$refs["auth-handler"].openModal(modalType, modalFor)
    },
  }
};
</script>
