<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="blocking" class="settings-content right-setting-bar active">
      <div class="set-block setting-header d-flex flex-column">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.3911 12.4518C10.1902 13.4202 8.66283 14 7 14C3.13401 14 0 10.866 0 7C0 5.33717 0.579792 3.80976 1.54824 2.6089L11.3911 12.4518ZM11.8649 12.0332L11.4187 12.4794L11.3911 12.4518C11.5553 12.3193 11.7134 12.1796 11.8649 12.0332ZM11.8649 12.0332C11.9219 11.978 11.978 11.9219 12.0332 11.8649L11.8649 12.0332ZM12.4518 11.3911L12.4794 11.4187L12.0332 11.8649C12.1796 11.7134 12.3193 11.5553 12.4518 11.3911ZM12.4518 11.3911L2.6089 1.54824C3.80976 0.579792 5.33717 0 7 0C10.866 0 14 3.13401 14 7C14 8.66283 13.4202 10.1902 12.4518 11.3911ZM2.13946 1.96261L2.58137 1.52071L2.6089 1.54824C2.44628 1.6794 2.28964 1.81768 2.13946 1.96261ZM1.96261 2.13946L2.13946 1.96261C2.07947 2.02051 2.02051 2.07947 1.96261 2.13946ZM1.96261 2.13946C1.81768 2.28964 1.6794 2.44628 1.54824 2.6089L1.52071 2.58137L1.96261 2.13946Z"
                fill="#47505B"
              />
            </svg>
          </div>
          <span> Manage Blocking</span>
          <button
            type="button"
            class="btn-view ml-auto"
            @click="redirectToProfilePage(username)"
          >
            <span class="pr-2">
              <EyeIcon />
          </span>
            View Profile
          </button>
        </div>
        <div class="set-block-content d-flex flex-column blocking">
          <div
            class="set-notyfication warning d-flex align-items-center flex-row width730"
          >
            <div class="svg-icon">
              <svg
                width="28"
                height="25"
                viewBox="0 0 28 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="c-fill-1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7195 2.1467L0.426206 21C0.193364 21.4033 0.0701619 21.8604 0.0688581 22.3261C0.0675544 22.7917 0.188194 23.2495 0.418775 23.6541C0.649356 24.0586 0.98184 24.3957 1.38315 24.6318C1.78446 24.868 2.24061 24.9949 2.70621 25H25.2929C25.7585 24.9949 26.2146 24.868 26.6159 24.6318C27.0172 24.3957 27.3497 24.0586 27.5803 23.6541C27.8109 23.2495 27.9315 22.7917 27.9302 22.3261C27.9289 21.8604 27.8057 21.4033 27.5729 21L16.2795 2.1467C16.0418 1.75484 15.7072 1.43085 15.3078 1.20601C14.9084 0.98116 14.4579 0.863037 13.9995 0.863037C13.5412 0.863037 13.0906 0.98116 12.6913 1.20601C12.2919 1.43085 11.9572 1.75484 11.7195 2.1467ZM13.9998 8.00008C14.5521 8.00008 14.9998 8.4478 14.9998 9.00008V14.3334C14.9998 14.8857 14.5521 15.3334 13.9998 15.3334C13.4476 15.3334 12.9998 14.8857 12.9998 14.3334V9.00008C12.9998 8.4478 13.4476 8.00008 13.9998 8.00008ZM13.9998 18.6667C13.4476 18.6667 12.9998 19.1145 12.9998 19.6667C12.9998 20.219 13.4476 20.6667 13.9998 20.6667H14.0132C14.5655 20.6667 15.0132 20.219 15.0132 19.6667C15.0132 19.1145 14.5655 18.6667 14.0132 18.6667H13.9998Z"
                  fill="#FF5F3A"
                ></path>
              </svg>
            </div>
            Once you block someone, that person can no longer see things you
            post on your timeline
          </div>
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <div class="search-lg d-flex flex-row align-items-center width730">
            <div class="svg-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="svg-search-ico"
              >
                <path
                  class="c-line-1"
                  d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M12.5 12.501L14.5 14.501"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              v-model="search"
              placeholder="Search for blocked people"
            />
          </div>
          <p class="text-center p-1" v-if="search_loading">Loading...</p>
          <table class="table blocking-table">
            <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Block Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div class="user">
                    <div class="user-wrapper">
                      <div class="img-wrapper">
                        <img
                          src="../../../../src/assets/img/defaults/avatar-img.svg"
                          alt=""
                        />
                        <span class="user-status"></span>
                      </div>
                    </div>
                    <div class="name">User Name</div>
                  </div>
                </th>
                <td>
                  <div class="date">
                    14 Oct 2020, 04:30 AM
                    <div class="unblock">
                      <div class="unblock-text">Unblock</div>
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M12 4L4 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M4 4L12 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div class="user">
                    <div class="user-wrapper">
                      <div class="img-wrapper">
                        <img
                          src="../../../../src/assets/img/defaults/avatar-img.svg"
                          alt=""
                        />
                        <span class="user-status"></span>
                      </div>
                    </div>
                    <div class="name">User Name</div>
                  </div>
                </th>
                <td>
                  <div class="date">
                    14 Oct 2020, 04:30 AM
                    <div class="unblock">
                      <div class="unblock-text">Unblock</div>
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M12 4L4 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M4 4L12 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div class="user">
                    <div class="user-wrapper">
                      <div class="img-wrapper">
                        <img
                          src="../../../../src/assets/img/defaults/avatar-img.svg"
                          alt=""
                        />
                        <span class="user-status"></span>
                      </div>
                    </div>
                    <div class="name">User Name</div>
                  </div>
                </th>
                <td>
                  <div class="date">
                    14 Oct 2020, 04:30 AM
                    <div class="unblock">
                      <div class="unblock-text">Unblock</div>
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M12 4L4 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M4 4L12 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div class="user">
                    <div class="user-wrapper">
                      <div class="img-wrapper">
                        <img
                          src="../../../../src/assets/img/defaults/avatar-img.svg"
                          alt=""
                        />
                        <span class="user-status"></span>
                      </div>
                    </div>
                    <div class="name">User Name</div>
                  </div>
                </th>
                <td>
                  <div class="date">
                    14 Oct 2020, 04:30 AM
                    <div class="unblock">
                      <div class="unblock-text">Unblock</div>
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M12 4L4 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M4 4L12 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div class="user">
                    <div class="user-wrapper">
                      <div class="img-wrapper">
                        <img
                          src="../../../../src/assets/img/defaults/avatar-img.svg"
                          alt=""
                        />
                        <span class="user-status"></span>
                      </div>
                    </div>
                    <div class="name">User Name</div>
                  </div>
                </th>
                <td>
                  <div class="date">
                    14 Oct 2020, 04:30 AM
                    <div class="unblock">
                      <div class="unblock-text">Unblock</div>
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M12 4L4 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M4 4L12 12"
                            stroke="#D1433A"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col text-right">
              <button type="submit" class="save-btn">Save Changes</button>
            </div>
          </div>
          <!-- <div class="d-flex flex-column table-row">
            <div class="d-flex flex-row align-items-center list-header">
              <div>User</div>
              <div>Block Date</div>
            </div>
            <div
              v-for="(eachOp, index) in blocked_users_list"
              :key="index"
              class="d-flex flex-row list-row"
            >
              <div class="d-flex flex-row align-items-center">
                <div class="cover-image">
                  <div class="img-wrapper"><img src="" alt="" /></div>
                  <div class="online"></div>
                </div>
                {{ eachOp.name }}
              </div>
              <div
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  justify-content-between
                "
              >
                <div class="date">
                  {{ eachOp.date_time }}
                </div>
                <UnblockUserBtn
                  :id="eachOp.blocked_id"
                  :unblockUser="unblockUser"
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import { block_user_listing_api } from "../../../apis/APIs";
import UnblockUserBtn from "./Components/UnblockUserBtn.vue";
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";

export default {
  data() {
    return {
      blocked_users_list: [],
      list_loading: false,

      search: "",

      message: "",
      success_message: "",
      loading: false,

      search_loading: false,
    };
  },
  watch: {
    search: function (value) {
      this.handleChange(value);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    EyeIcon
  }
  ,
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    },
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
              status: true,
              message:
                this.errors[
                  this.errors.findIndex((ele) => ele.fieldName === key)
                ].message,
            }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    init() {
      this.fetchBlockUsersData();
    },
    async fetchBlockUsersData() {
      try {
        this.list_loading = true;

        const { user_data } = this.user || {};
        const { user_id } = user_data || {};

        const query = `?id=${user_id}`;
        const res = await block_user_listing_api(query);
        const { success, message, information } = res || {};
        this.list_loading = false;

        if (success === 1) {
          this.blocked_users_list = information;
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    async handleChange(value) {
      this.search_loading = true;

      try {
        const { user_data } = this.user || {};
        const { user_id } = user_data || {};

        const query = `?id=${user_id}&search=1&search_text=${value}`;
        const res = await block_user_listing_api(query);
        const { success, message, information } = res || {};
        this.search_loading = false;

        if (success === 1) {
          this.blocked_users_list = information;
        }
      } catch (error) {
        this.search_loading = false;
      }
    },
    async unblockUser(id, cb = () => {}) {
      try {
        const { user_data } = this.user || {};
        const { user_id } = user_data || {};
        const query = `?id=${user_id}&unblock=1&block_user_id=${id}`;
        const res = await block_user_listing_api(query);
        const { success, message, information } = res || {};

        if (success === 0) {
          this.message = message;
        }

        this.fetchBlockUsersData();
        cb();
      } catch (error) {
        cb();
      }
    },
  },
};
</script>
