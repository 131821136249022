<template>
  <b-card class="product-tile-card" body-class="text-center for_padding">
    <b-card-text class="icon-header">
      <div class="icon-container">
        <img :src="product.icon" alt="picture" />
      </div>
    </b-card-text>
    <b-card-text class="card-title">
      {{ product.title }}
    </b-card-text>
    <b-card-text class="card-desc">
      {{ product.description }}
    </b-card-text>
    <b-button
      v-if="buttonAction"
      @click="performAction(product)"
      variant="outline-primary"
      class="mt-0 mb-2"
    >
      {{ product.buttonText }}</b-button
    >
    <b-button
      v-else
      :to="product.url"
      variant="outline-primary"
      class="mt-0 mb-2"
    >
      {{ product.buttonText }}</b-button
    >
  </b-card>
</template>

<script>
export default {
  name: "ProductTile",
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
    buttonAction: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    performAction(value) {
      this.$emit("action", value);
    },
  },
};
</script>

<style scoped lang="scss">
.for_padding {
  padding: 30px;
}
.btn {
  font-size: 11px;
  font-family: HelveticaNeueMedium;
}
.product-tile-card {
  background: #ffffff;
  /* Light grey (New) */

  border: 1px solid #e6eaf0;
  //box-sizing: border-box;
  border-radius: 2px;
  height: 260px;
  width: 230px;
  transition: box-shadow 0.3s;
  &:hover {
    box-sizing: border-box;
    transition: all 0.3s;
    border: none;
    box-shadow: 1px 4px 4px 1px rgba(71, 80, 91, 0.15) !important;
  }
  .card-title {
    font-family: HelveticaNeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    margin-bottom: 3px;

    color: #071526;
  }
  .card-desc {
    font-family: HelveticaNeueNormal;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 14px;
    /* identical to box height, or 182% */

    text-align: center;

    /* Dark Grey (New) */

    color: #47505b;
  }
  .icon-header {
    // margin-top: 1.5rem;
    margin-bottom: 27px;
    .icon-container {
      border-radius: 50%;
      position: relative;
      height: 90px;
      width: 90px;
      left: 0;
      right: 0;
      margin: auto;
      background: #f3f3f3;
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
</style>
