<template>
  <div class="container_xxl friendsWrapper">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h1 class="pages-heading-title">Friends</h1>
          </div>
        </div>
      </div>

      <!-- <div class="container"> -->
      <div class="row d-flex mb-40 align-items-stretch">
        <div class="col-8">
          <div class="friends-wrap bg-transparent width770">
            <nav>
              <div class="nav pages-tbs" style="height: unset" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-explore-tab" data-toggle="tab" href="#nav-explore"
                  role="tab" aria-controls="nav-explore" aria-selected="true"
                  @click="showFilter('chooseFilters', true)">EXPLORE</a>
                <a class="nav-item nav-link" id="nav-friend-tab" data-toggle="tab" href="#nav-friend" role="tab"
                  aria-controls="nav-friend" aria-selected="false" @click="showFilter('chooseFilters', false)">
                  <span>FRIEND REQUESTS</span>
                  <span v-if="
                    $store.state.friends
                      .total_pending_friend_request_count > 0
                  " class="event_counting">{{
                        $store.state.friends.total_pending_friend_request_count
                      }}</span>
                </a>
                <a class="nav-item nav-link" id="nav-sent-tab" data-toggle="tab" href="#nav-sent" role="tab"
                  aria-controls="nav-sent" aria-selected="false" @click="showFilter('chooseFilters', false)">
                  <span>SENT REQUESTS</span>

                  <span v-if="$store.state.friends.total_sent_request_count > 0" class="event_counting">{{
                    $store.state.friends.total_sent_request_count }}</span>
                </a>
                <a class="nav-item nav-link" id="nav-birthday-tab" data-toggle="tab" href="#nav-birthday" role="tab"
                  aria-controls="nav-birthday" aria-selected="false" @click="showFilter('calenderFilters', false)">
                  <span>BIRTHDAYS</span>
                  <span v-if="$store.state.friends.total_birthdays_count > 0" class="event_counting">{{
                    $store.state.friends.total_birthdays_count }}</span>
                </a>
              </div>
            </nav>
            <div class="tab-content bg-transparent" id="nav-tabContent">
              <!--                Friend Suggestion Section:-->
              <div class="tab-pane fade show active" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab">
                <friend-suggestion v-if="
                  $store.state.friends.friends_suggestion.length ||
                  $store.state.friends.explore_friend_list.length ||
                  $store.state.friends.filtered_friends_list.length
                " />
                <friend-card v-else cardTitle="You don't have any friends yet."
                  cardSubTitle="Search city, School, Works places to find friend and connections."
                  cardIconType="FriendsIcon" />
              </div>
              <!--                Friend Request Section-->
              <div class="tab-pane fade" id="nav-friend" role="tabpanel" aria-labelledby="nav-friend-tab">
                <friend-requests v-if="$store.state.friends.pending_friend_requests.length" />
                <friend-card v-else cardTitle="You don't have any friend requests yet."
                  cardSubTitle="Search city, School, Works places to find friend and connections."
                  cardIconType="FriendsIcon" />
              </div>
              <div class="tab-pane fade" id="nav-sent" role="tabpanel" aria-labelledby="nav-sent-tab">
                <sent-friend-request v-if="$store.state.friends.sent_friend_requests.length" />
                <friend-card v-else cardTitle="You don't have any sent requests yet."
                  cardSubTitle="Search city, School, Works places to find friend and connections."
                  cardIconType="FriendsIcon" />
              </div>
              <div class="tab-pane fade" id="nav-birthday" role="tabpanel" aria-labelledby="nav-birthday-tab">
                <friend-birthdays v-if="$store.state.friends.month_birthdays.length" />
                <friend-card v-else cardTitle="You don't have any birthday's from  friends yet."
                  cardSubTitle="Search city, School, Works places to find friend and connections."
                  cardIconType="FriendsIcon" />
              </div>
            </div>
          </div>
        </div>
        <friend-filters :filter="showFilters" :buttonShow="showApplyButton" />
      </div>
      <!-- </div> -->

    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      showMore: false,
      showFilters: "chooseFilters",
      showApplyButton: true,
      clearlist: "",
      day: "",
    };
  },
  mounted() {
    this.$store.dispatch("getFriendsSuggestion", this.clearlist);
    // this.$store.dispatch('exploreFriends');
    this.$store.dispatch("getPendingFriendRequests");
    this.$store.dispatch("getSentRequests");
    this.$store.dispatch("getBirthdays", this.day);
  },
  computed: {},
  components: {
    "friend-suggestion": require("@/components/Home/People/FriendSuggestion")
      .default,
    "friend-requests": require("@/components/Home/People/FriendRequests")
      .default,
    "sent-friend-request":
      require("@/components/Home/People/SentFriendRequests").default,
    "friend-birthdays": require("@/components/Home/People/FriendBirthdays")
      .default,
    "friend-card": require("@/components/Home/People/FriendCard").default,
    "friend-filters": require("@/components/Home/People/FriendFilters").default,
  },
  methods: {
    showFilter(filterType, showButton) {
      this.showFilters = filterType;
      this.showApplyButton = showButton;
    },
  },
};
</script>
<style scoped lang="scss">
.birthday-table td,
th {
  border: none !important;
  text-align: center;
  font-size: 14px;
}

.birthday-table td {
  padding: 11.7px 0px;
}

.birthday-card-btn {
  color: #ffffff;
  background: #D1433A;
  padding: 15px 0px 15px 0px;
  border-radius: 4px !important;
  border: none;
  font-weight: normal;
  font-family: "HelveticaNeueBold";
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.05em;
}

.friend-request-btn {
  color: #D1433A;
  background: #D1433A1A;
  margin: 0;
  border: 1px solid #D1433A;
  padding: 5px 5px 5px 5px;
  border-radius: 2px;
  border: none;
  font-weight: 500;
  font-family: "HelveticaNeuemedium";
}

.friend-request-btn:hover {
  background-color: #D1433A;
  color: #ffffff;
}

.day-color {
  background: #D1433A;
  border-radius: 100px;
  color: white;
}

.c-month-center {
  color: #D1433A;
  font-size: 16px;
  font-weight: 700;
  font-family: "HelveticaNeueMedium";
}

.calender-heading {
  font-size: 17px;
  font-family: "HelveticaNeueMedium";
  font-weight: 700;
}

.birthday-heading {
  font-family: "HelveticaNeueMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #47505b;
}

.birthday-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}

.birthday-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.birthday-btn {
  color: #D1433A;
  background: #fff;
  margin: 0;
  border: 1.3px solid #D1433A;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
}

.birthday-h-font {
  font-family: "HelveticaNeueMedium";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 4px;
  text-align: center;
  letter-spacing: 0.03em !important;
}

.birthday-p-font {
  font-family: "HelveticaNeueMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.birthday-p-font {
  font-family: "HelveticaNeueMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
  color: #47505b;
}

.birthday-a-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #e6eaf0;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}

.icon-center {
  margin: 0 auto;
  display: flex;
}

.icon {
  margin: 3px 8px 0 0 !important;
}

.musicbar_more-frd .show_more_bg {
  display: flex;
  align-items: center;
  font-family: "HelveticaNeueMedium";
}

.birthday-p1-font {
  font-family: "HelveticaNeueNormal";
  letter-spacing: 1px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
  color: #8b949f;
  letter-spacing: 0.5px;

}

.birthday-heading-1 {
  font-family: "HelveticaNeueBold";
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  text-align: left;
  color: #47505b;
}

.send-request-btn {
  color: #D1433A;
  background: #fff;
  margin: 0;
  border: 1px solid #D1433A;
  padding: 3px 5px 3px 5px;
  border-radius: 2px;
  font-weight: 500;
  font-family: "HelveticaNeuemedium";
}

.send-request-btn:hover {
  background-color: #D1433A;
  color: #ffffff;
}

.remove-btn {
  background-color: #e6eaf0 !important;
  color: #47505b !important;
  width: 100px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  border: none !important;
}

.remove-btn:hover {
  background-color: #c4ccd6 !important;
  color: #47505b !important;
}

.float {
  float: right;
}

.event_counting {
  color: #D1433A !important;
  background-color: #D1433A1A !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #47505b;
}
</style>
