<template>
  <div class="posts-container">
    <Posting />
    <Post />
  </div>
</template>

<script>
import Posting from "./Posting.vue";
import Post from "./Post.vue";
export default {
  components: {
    Posting,
    Post
  },
};
</script>

<style lang="scss" scoped>
.posts-container {
  width: 770px;
  max-width: 100%;
}
</style>
