import {
  _TYPE,
  ALBUM_DOWNLOAD_PRICE,
  ALL_RIGHTS_RESERVED,
  CLEAR_FOR_NOT_SALE_COUNTRIES,
  CLEAR_FOR_NOT_SALE_TERRITORY,
  CLEAR_FOR_SALE_COUNTRIES,
  CLEAR_FOR_SALE_TERRITORY,
  COMPLETE_STEP_UPLOAD,
  COMPOSER,
  COPYRIGHT,
  CREATE_ISRC,
  CREATION_COMMON,
  DESCRIPTION,
  DISPLAY_COMMENT,
  DISPLAY_EMBED_CODE,
  DISPLAY_STATS,
  DRAFT,
  EMPTY_SONG,
  EMPTY_TEMP_SONG_FORM,
  EMPTY_TEMP_SONGS_FORM,
  EMPTY_UPLOAD_ALBUM_PAGE_FORM,
  ENABLE_APP_PLAYBACK,
  ENABLE_COMMENT,
  ENABLE_DIRECT_DOWNLOAD,
  ENABLE_OFFLINE_LISTING,
  ERROR,
  EXPLICIT_CONTENT,
  FEATURE_ARTIST,
  GENRE,
  INCLUDE_RES,
  ISRC,
  LET_FUN_PAY_ALBUM,
  LET_FUN_PAY_TRACK,
  METADATA_LANGUAGE,
  MOOD,
  PAGE_ID,
  PICTURES, PODCAST_CATEGORY_ID,
  PREORDER_INFO,
  PRICING_TYPE,
  PRIMARY_ARTIST,
  PRIVACY,
  PRODUCER,
  PUBLISHER,
  RE_MIXER,
  RECORD_LABEL,
  RELEASED_AT,
  SALE_DATE,
  SONG,
  SONG_DETAILS,
  SONG_DOWNLOAD_PRICE,
  SONG_ID_TEMP,
  SONGS,
  SONGS_IDS_TEMP,
  SONGS_NAMES_TEMP,
  SUB_GENRE,
  SUCCESS,
  TAGS,
  TEMP_SONG_FORM,
  TEMP_SONGS_FORM,
  TITLE,
  UPLOAD_ALBUM_PAGE_FORM,
  URL,
  COLLABORATORS, PICTURES_ID, UPLOAD_SONG_PAGE_FORM
} from "../../types";
import {
  albumUploadDetail,
  uploadSongTemp
} from "../../../apis/APIs";
import * as Utils from "../../../components/utils/Utils";
import createFormData, { createRawData } from "../../../mixins/common.js";

const INITIAL_STATE = {
  [TEMP_SONGS_FORM]: {
    [PRIVACY]: null,
    [_TYPE]: null,
    [SONGS]: [],
    [SONGS_IDS_TEMP]: [],
    [SONGS_NAMES_TEMP]: []
  },
  [EMPTY_TEMP_SONGS_FORM]: {
    [PRIVACY]: null,
    [_TYPE]: null,
    [SONGS]: [],
    [SONGS_IDS_TEMP]: [],
    [SONGS_NAMES_TEMP]: []
  },
  [UPLOAD_ALBUM_PAGE_FORM]: {
    [PICTURES]: null,
    [PAGE_ID]: null,
    [TITLE]: null,
    [URL]: null,
    [DESCRIPTION]: null,
    [GENRE]: [],
    [SUB_GENRE]: [],
    [MOOD]: [],
    [TAGS]: [],
    [SONG_DETAILS]: [],
    [PRICING_TYPE]: null,
    [SALE_DATE]: null,
    [PREORDER_INFO]: null,
    [SONG_DOWNLOAD_PRICE]: null,
    [LET_FUN_PAY_TRACK]: null,
    [ALBUM_DOWNLOAD_PRICE]: null,
    [LET_FUN_PAY_ALBUM]: null,
    [CLEAR_FOR_SALE_COUNTRIES]: [],
    [CLEAR_FOR_SALE_TERRITORY]: [],
    [CLEAR_FOR_NOT_SALE_COUNTRIES]: [],
    [CLEAR_FOR_NOT_SALE_TERRITORY]: [],
    [ENABLE_DIRECT_DOWNLOAD]: false,
    [ENABLE_OFFLINE_LISTING]: false,
    [INCLUDE_RES]: false,
    [DISPLAY_EMBED_CODE]: false,
    [ENABLE_APP_PLAYBACK]: false,
    [ENABLE_COMMENT]: false,
    [DISPLAY_COMMENT]: false,
    [DISPLAY_STATS]: false,
    [DRAFT]: false,
    [COLLABORATORS]:[]
  },
  [EMPTY_UPLOAD_ALBUM_PAGE_FORM]: {
    [PICTURES]: null,
    [PAGE_ID]: null,
    [TITLE]: null,
    [URL]: null,
    [DESCRIPTION]: null,
    [GENRE]: [],
    [SUB_GENRE]: [],
    [MOOD]: [],
    [TAGS]: [],
    [SONG_DETAILS]: [],
    [PRICING_TYPE]: null,
    [SALE_DATE]: null,
    [PREORDER_INFO]: null,
    [SONG_DOWNLOAD_PRICE]: null,
    [LET_FUN_PAY_TRACK]: null,
    [ALBUM_DOWNLOAD_PRICE]: null,
    [LET_FUN_PAY_ALBUM]: null,
    [CLEAR_FOR_SALE_COUNTRIES]: [],
    [CLEAR_FOR_SALE_TERRITORY]: [],
    [CLEAR_FOR_NOT_SALE_COUNTRIES]: [],
    [CLEAR_FOR_NOT_SALE_TERRITORY]: [],
    [ENABLE_DIRECT_DOWNLOAD]: false,
    [ENABLE_OFFLINE_LISTING]: false,
    [INCLUDE_RES]: false,
    [DISPLAY_EMBED_CODE]: false,
    [ENABLE_APP_PLAYBACK]: false,
    [ENABLE_COMMENT]: false,
    [DISPLAY_COMMENT]: false,
    [DISPLAY_STATS]: false,
    [DRAFT]: false,
    [COLLABORATORS]:[]
  },
  [COMPLETE_STEP_UPLOAD]: 0,
  [SONG]: {
    [SONG_ID_TEMP]: null,
    [TITLE]: null,
    [PRIMARY_ARTIST]: null,
    [COMPOSER]: null,
    [RECORD_LABEL]: null,
    [PUBLISHER]: null,
    [ISRC]: null,
    [METADATA_LANGUAGE]: null,
    [FEATURE_ARTIST]: null,
    [PRODUCER]: null,
    [RE_MIXER]: null,
    [RELEASED_AT]: null,
    [COPYRIGHT]: null,
    [EXPLICIT_CONTENT]: null,
    [CREATE_ISRC]: false,
    [ALL_RIGHTS_RESERVED]: false,
    [CREATION_COMMON]: false,
    [PODCAST_CATEGORY_ID]:null
  },
  [EMPTY_SONG]: {
    [SONG_ID_TEMP]: null,
    [TITLE]: null,
    [PRIMARY_ARTIST]: null,
    [COMPOSER]: null,
    [RECORD_LABEL]: null,
    [PUBLISHER]: null,
    [ISRC]: null,
    [METADATA_LANGUAGE]: null,
    [FEATURE_ARTIST]: null,
    [PRODUCER]: null,
    [RE_MIXER]: null,
    [RELEASED_AT]: null,
    [COPYRIGHT]: null,
    [EXPLICIT_CONTENT]: null,
    [CREATE_ISRC]: false,
    [ALL_RIGHTS_RESERVED]: false,
    [CREATION_COMMON]: false,
    [PODCAST_CATEGORY_ID]:null
  }
};

export const state = { ...INITIAL_STATE };
export const actions = {
  async uploadAlbumSongsTemporarily({ state, commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch("loading", true);
        const postFormData = state[TEMP_SONGS_FORM];
        const formData = createFormData(postFormData);
        const { data } = await uploadSongTemp(formData);
        const { information, success } = data;
        if (success === 1) {
          commit("ADD_SONGS_OBJECTS_IN_ALBUM", information);
        }
        dispatch("loading", false);
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      } catch (error) {
        dispatch("loading", false);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
          error
        });
      }
    });
  },
  async uploadAlbumDetail({ state, commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch("loading", true);
        const postFormData = state[UPLOAD_ALBUM_PAGE_FORM];
        const rawData = createRawData(postFormData);
        const { data } = await albumUploadDetail(rawData);
        if (data.success === 1) {
          commit("CLEAR_UPLOAD_ALBUM_PAGE_FORM");
          commit("CLEAR_TEMP_ALBUM__FORM");
        }
        dispatch("loading", false);
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      } catch (error) {
        dispatch("loading", false);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
          error
        });
      }
    });
  }
};

export const mutations = {
  CLEAR_TEMP_ALBUM__FORM(state) {
    try {
      state[TEMP_SONG_FORM] = Object.assign({}, state[EMPTY_TEMP_SONG_FORM]);
    } catch (e) {
      console.error(e.message, "unable to CLEAR_TEMP_SONG_FORM");
    }
  },
  CLEAR_UPLOAD_ALBUM_PAGE_FORM(state) {
    try {
      state[UPLOAD_ALBUM_PAGE_FORM] = Object.assign(
        {},
        state[EMPTY_UPLOAD_ALBUM_PAGE_FORM]
      );
    } catch (e) {
      console.error(e.message, "unable to CLEAR_UPLOAD_SONG_PAGE_FORM data");
    }
  },
  SET_SONG_IDS_IN_ALBUM_STEP_1_DATA(state, songs) {
    try {
      for (const song of songs) {
        state[SONG][SONG_ID_TEMP] = song.id;
        state[UPLOAD_ALBUM_PAGE_FORM][SONG_DETAILS].push(state[SONG]);
        state[SONG] = Object.assign({}, state[EMPTY_SONG]);
      }
    } catch (e) {
      console.error(e.message, "unable to SET_SONG_STEP_1_DATA");
    }
  },
  SET_ALBUM_STEP_2_DATA(state, payload) {
    try {
      const { data, songs } = payload;
      state[UPLOAD_ALBUM_PAGE_FORM][PICTURES] = data[PICTURES];
      state[UPLOAD_ALBUM_PAGE_FORM][PICTURES_ID] = data[PICTURES_ID];
      state[UPLOAD_ALBUM_PAGE_FORM][PAGE_ID] = data[PAGE_ID];
      state[UPLOAD_ALBUM_PAGE_FORM][TITLE] = data[TITLE];
      state[UPLOAD_ALBUM_PAGE_FORM][URL] = data[URL];
      state[UPLOAD_ALBUM_PAGE_FORM][DESCRIPTION] = data[DESCRIPTION];
      state[UPLOAD_ALBUM_PAGE_FORM][GENRE] = data[GENRE];
      state[UPLOAD_ALBUM_PAGE_FORM][SUB_GENRE] = data[SUB_GENRE];
      state[UPLOAD_ALBUM_PAGE_FORM][MOOD] = data[MOOD];
      state[UPLOAD_ALBUM_PAGE_FORM][TAGS] = data[TAGS];
      state[UPLOAD_ALBUM_PAGE_FORM][SONG_DETAILS] = songs;
      state[UPLOAD_ALBUM_PAGE_FORM][PODCAST_CATEGORY_ID] = data[PODCAST_CATEGORY_ID]
      state[COMPLETE_STEP_UPLOAD] = 2;
    } catch (e) {
      console.error(e.message, "unable to SET_SONG_STEP_2_DATA");
    }
  },
  SET_ALBUM_STEP_3_DATA(state, songs) {
    try {
      state[UPLOAD_ALBUM_PAGE_FORM][SONG_DETAILS] = songs;
      state[COMPLETE_STEP_UPLOAD] = 3;
    } catch (e) {
      console.error(e.message, "unable to SET_Album_STEP_3_DATA");
    }
  },
  SET_ALBUM_STEP_4_DATA(state, payload) {
    try {
      state[UPLOAD_ALBUM_PAGE_FORM][PRICING_TYPE] = payload[PRICING_TYPE];
      state[UPLOAD_ALBUM_PAGE_FORM][SALE_DATE] = payload[SALE_DATE];
      state[UPLOAD_ALBUM_PAGE_FORM][PREORDER_INFO] = payload[PREORDER_INFO];
      state[UPLOAD_ALBUM_PAGE_FORM][RELEASED_AT] = payload[RELEASED_AT];
      state[UPLOAD_ALBUM_PAGE_FORM][SONG_DOWNLOAD_PRICE] =
        payload[SONG_DOWNLOAD_PRICE];
      state[UPLOAD_ALBUM_PAGE_FORM][LET_FUN_PAY_TRACK] =
        payload[LET_FUN_PAY_TRACK];
      state[UPLOAD_ALBUM_PAGE_FORM][ALBUM_DOWNLOAD_PRICE] =
        payload[ALBUM_DOWNLOAD_PRICE];
      state[UPLOAD_ALBUM_PAGE_FORM][LET_FUN_PAY_ALBUM] =
        payload[LET_FUN_PAY_ALBUM];
      state[COMPLETE_STEP_UPLOAD] = 4;
    } catch (e) {
      console.error(e.message, "unable to SET_Album_STEP_4_DATA");
    }
  },
  SET_ALBUM_COLLABORATORS_DATA(state, collaborators) {
    try {
      state[UPLOAD_ALBUM_PAGE_FORM][COLLABORATORS] = collaborators;
    } catch (e) {
      console.error(e.message, "unable to SET_ALBUM_COLLABORATORS_DATA");
    }
  },
  SET_ALBUM_STEP_5_DATA(state, payload) {
    try {
      state[UPLOAD_ALBUM_PAGE_FORM][CLEAR_FOR_SALE_COUNTRIES] =
        payload[CLEAR_FOR_SALE_COUNTRIES];
      state[UPLOAD_ALBUM_PAGE_FORM][CLEAR_FOR_SALE_TERRITORY] =
        payload[CLEAR_FOR_SALE_TERRITORY];
      state[UPLOAD_ALBUM_PAGE_FORM][CLEAR_FOR_NOT_SALE_COUNTRIES] =
        payload[CLEAR_FOR_NOT_SALE_COUNTRIES];
      state[UPLOAD_ALBUM_PAGE_FORM][CLEAR_FOR_NOT_SALE_TERRITORY] =
        payload[CLEAR_FOR_NOT_SALE_TERRITORY];
      state[COMPLETE_STEP_UPLOAD] = 5;
    } catch (e) {
      console.error(e.message, "unable to SET_Album_STEP_5_DATA");
    }
  },
  SET_ALBUM_STEP_6_DATA(state, payload) {
    try {
      state[UPLOAD_ALBUM_PAGE_FORM][ENABLE_DIRECT_DOWNLOAD] =
        payload[ENABLE_DIRECT_DOWNLOAD];
      state[UPLOAD_ALBUM_PAGE_FORM][ENABLE_OFFLINE_LISTING] =
        payload[ENABLE_OFFLINE_LISTING];
      state[UPLOAD_ALBUM_PAGE_FORM][INCLUDE_RES] = payload[INCLUDE_RES];
      state[UPLOAD_ALBUM_PAGE_FORM][DISPLAY_EMBED_CODE] =
        payload[DISPLAY_EMBED_CODE];
      state[UPLOAD_ALBUM_PAGE_FORM][ENABLE_APP_PLAYBACK] =
        payload[ENABLE_APP_PLAYBACK];
      state[UPLOAD_ALBUM_PAGE_FORM][ENABLE_COMMENT] = payload[ENABLE_COMMENT];
      state[UPLOAD_ALBUM_PAGE_FORM][DISPLAY_COMMENT] = payload[DISPLAY_COMMENT];
      state[UPLOAD_ALBUM_PAGE_FORM][DISPLAY_STATS] = payload[DISPLAY_STATS];
      state[COMPLETE_STEP_UPLOAD] = 6;
    } catch (e) {
      console.error(e.message, "unable to SET_Album_STEP_6_DATA");
    }
  },
  SET_TEMP_SONGS_DATA(state, payload) {
    try {
      state[TEMP_SONGS_FORM] = {
        [PRIVACY]: payload.privacy,
        [_TYPE]: payload.type,
        [SONGS]: payload.songs,
        [SONGS_NAMES_TEMP]: payload[SONGS_NAMES_TEMP]
      };
      state[COMPLETE_STEP_UPLOAD] = 1;
    } catch (e) {
      console.error(e.message, "unable to  SET_TEMP_SONG_DATA data");
    }
  },
  ADD_SONGS_OBJECTS_IN_ALBUM(state, songs) {
    state[UPLOAD_ALBUM_PAGE_FORM][SONG_DETAILS] = [];
    for (const index in songs) {
      state[SONG][SONG_ID_TEMP] = songs[index] ? songs[index].id : null;
      state[SONG][TITLE] = state[TEMP_SONGS_FORM][SONGS_NAMES_TEMP][index]
        ? state[TEMP_SONGS_FORM][SONGS_NAMES_TEMP][index]
        : null;
      state[UPLOAD_ALBUM_PAGE_FORM][SONG_DETAILS].push(state[SONG]);
      state[SONG] = Object.assign({}, state[EMPTY_SONG]);
    }
  }
};
const getters = {
  getAlbumSongsUploadedIn(state) {
    let _state = Utils.parseState(state);
    return _state[UPLOAD_ALBUM_PAGE_FORM][SONG_DETAILS];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
