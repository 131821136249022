<template>
  <div class="container w-auto">
    <div class="row">
      <div class="col-12 user_header_image">
        <div class="cover_image profile_image">
          <cropper
              v-if="reposition"
              style="height: 200px"
              ref="cover-cropper"
              class="cropper"
              :src="coverImage"
              :resize-image="{
		                  adjustStencil: false
	            }"
              image-restriction="stencil"
              :stencil-props="{
                       aspectRatio: 18/4,
                       movable: false,
                       scalable: false,
                      }"/>
          <span v-else class="cover-uploaded-image"
                :style="{backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(8, 0, 0, 0.08) 100%), url('+ coverImage + ')'}"></span>

          <div class="container w-auto position-relative" v-if="edit">
            <div v-if="reposition">
              <div class="save_changes_btn" @click="getImageAndUpload('cover')">
                <div class="upload-btn">
                  <div class="svg-image">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                      <path
                          d="M5.66602 6.66669C6.2183 6.66669 6.66602 6.21897 6.66602 5.66669C6.66602 5.1144 6.2183 4.66669 5.66602 4.66669C5.11373 4.66669 4.66602 5.1144 4.66602 5.66669C4.66602 6.21897 5.11373 6.66669 5.66602 6.66669Z"
                          stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.0007 10L10.6673 6.66669L3.33398 14" stroke="white" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <p>Save changes</p>
                </div>
              </div>
              <div class="cancel_changes_btn" @click="previewCancel()">
                <div class="upload-btn">
                  <p>cancel</p>
                </div>
              </div>
            </div>
            <div class="d-flex" v-else>
              <div v-if="checkCoverImage" class="change_cover_btn">
                <template>
                  <div :class="(checkCoverImage === 1) ? 'upload-btn' : 'upload-btn-blue'" @click="openCoverModel()">
                    <div class="svg-image">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
                            stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M5.66602 6.66669C6.2183 6.66669 6.66602 6.21897 6.66602 5.66669C6.66602 5.1144 6.2183 4.66669 5.66602 4.66669C5.11373 4.66669 4.66602 5.1144 4.66602 5.66669C4.66602 6.21897 5.11373 6.66669 5.66602 6.66669Z"
                            stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.0007 10L10.6673 6.66669L3.33398 14" stroke="white" stroke-linecap="round"
                              stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <p>Change Covers</p>
                  </div>
                </template>
                <!--              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>-->
                <!--                -->
                <!--                <b-dropdown-item @click="openCoverModel()">Upload Picture</b-dropdown-item>-->
                <!--                <b-dropdown-item v-if="coverImagePresent" @click="reposition=true">Reposition</b-dropdown-item>-->
                <!--                <b-dropdown-divider v-if="coverImagePresent" ></b-dropdown-divider>-->
                <!--                <b-dropdown-item v-if="coverImagePresent"  @click="deleteImage('cover')">Delete</b-dropdown-item>-->
                <!--              </b-dropdown>-->
              </div>
              <div v-else class="change_cover_btn_blue">
                <template>
                  <div class="upload-btn-blue" @click="openCoverModel()">
                    <div class="svg-image">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.66602 6.66675C6.2183 6.66675 6.66602 6.21903 6.66602 5.66675C6.66602 5.11446 6.2183 4.66675 5.66602 4.66675C5.11373 4.66675 4.66602 5.11446 4.66602 5.66675C4.66602 6.21903 5.11373 6.66675 5.66602 6.66675Z" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.0007 10.0001L10.6673 6.66675L3.33398 14.0001" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                    </div>
                    <p>Change Covers</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <full-page-loader :show="loading" style="z-index: +10"/>
  </div>
</template>

<script>
import {DeleteUploadedProfileAndCover, uploadProfileAndCover, addPost} from "../../../../apis/APIs";
import {mapGetters} from "vuex";

export default {
  name: "profileAndCover",
  data() {
    return {
      coverPreview: null,
      image: null,
      loading: false,
      reposition: false,
    }
  },
  props: {
    user:{
      type:Object,
      required:true,
    },
    edit:{
      type:Boolean,
      default:true
    },
    preview: {
      type: Boolean,
      default: false
    },
    saveChanges: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    coverImage() {
      if (this.coverPreview) {
        return this.coverPreview
      }
      if (this.user && this.user.cover_photo_url) {
        return this.user.cover_photo_url
      }
      return require(`@/assets/img/cover-img.png`)
    },
    checkCoverImage() {
      return this.user.cover_photo
    },
    coverImagePresent() {

      if (this.user && this.user.cover_photo) {
        return this.user.cover_photo
      }
      return false
    }
  },
  methods: {
    previewImage: function (event) {
      let input = event.target;
      if (input.files) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.coverPreview = e.target.result;
        }
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewCancel() {
      this.reposition = false
    },
    updateCover: async function () {
      try {
        this.loading = true;
        let formdata = new FormData();
        formdata.append("cover_photo", this.image);
        const {data} = await addPost(formdata);
        const {success, message, information} = data || {};

        this.message = "";
        if (success === 1) {
          await this.$store.dispatch('set_user_data', information)
          this.notificationToast(true, 'Cover Updated', message, 5000, 'success')
        } else {
          this.notificationToast(true, 'Cover Updated', message, 5000,'error')
        }
        this.previewCancel()
        this.$emit('saveChangesEmit', false)
        this.loading = false;
      } catch (error) {
        console.error(error)
        this.previewCancel()
        this.$emit('saveChangesEmit', false)
        this.makeToast('error', "Error", error.message)
        this.loading = false;
        this.message = error && error.message ? error.message : "Please try again.";
      }
    },
    async getImageAndUpload(type='profile') {
      try {
        this.loading = true;
        const {canvas} =  this.$refs['cover-cropper'].getResult()
        if (canvas) {
          canvas.toBlob(blob => this.upload(blob,type), 'image/jpeg');
        }
      } catch (error) {
        this.loading = false;
        console.error(error)
        this.profilePreview = null;
        this.image = null;
      }
    },
    async upload(image,type='profile') {
      try {
        this.loading = true;
        const form = new FormData();
        (type !== 'profile') ? form.append('cover_photo', image) : form.append('artwork', image)
        const {data} = await uploadProfileAndCover(form);
        this.loading = false;
        const {success, message, information} = data || {};
        if (success === 1) {
          this.profilePreview = null;
          this.image = null;
          this.modelShow = false
          await this.$store.dispatch('set_user_data', information)
          this.reposition=false
          this.notificationToast(true, `${(type !== 'profile') ? "Cover " : "Profile"} updated`, `${(type !== 'profile') ? "Cover " : "Profile "} uploaded successfully`, 5000, 'success')
          this.loading = false;
        } else {
          this.notificationToast(true,`${(type !== 'profile') ? "Cover " : "Profile"} updated`, `${(type !== 'profile') ? "Cover " : "Profile "} uploaded successfully`, 5000, 'error')
        }
      } catch (error) {
        this.loading = false;
        console.error(error)
        this.notificationToast(true,`${(type !== 'profile') ? "Cover " : "Profile"} updated`, `${(type !== 'profile') ? "Cover " : "Profile "} uploaded successfully`, 5000, 'error')
        this.profilePreview = null;
        this.image = null;

      }
    },
    async deleteImage(type='profile') {
      try {
        const result = await this.confirmationToast('warning', `Are you sure you want to delete ${(type !== 'profile') ? "Cover " : "Profile"} photo!`, "You won't be able to revert this!", "Yes, delete it!", '#3085d6', '#d33');
        if (result.isConfirmed) {
          await this.$store.dispatch('loading',true);
          const form = new FormData();
          (type !== 'profile') ? form.append('type','cover_photo') : form.append('type','profile_photo')
          const {data} = await DeleteUploadedProfileAndCover(form);
          const {success, message, information} = data || {};
          if (success === 1) {
            this.profilePreview = null;
            this.image = null;
            this.modelShow = false
            await this.$store.dispatch('set_user_data', information)
            this.reposition=false
            await this.$store.dispatch('loading',false);
            this.notificationToast(true, `${(type !== 'profile') ? "Cover " : "Profile"} Deleted`, `${(type !== 'profile') ? "Cover " : "Profile "} Deleted successfully`, 5000, 'success')
          } else {
            this.notificationToast(true,`${(type !== 'profile') ? "Cover " : "Profile"} Deleted`, message, 5000, 'error')
            await this.$store.dispatch('loading',false);
          }
        }
      } catch (error) {
        await this.$store.dispatch('loading',false);
        console.error(error)
        this.notificationToast(true, 'error',error.message, 5000, 'error')
        this.profilePreview = null;
        this.image = null;

      }
    },
    async saveChangesEmit() {
    },
    openCoverModel() {
      this.$emit('coverModelOpenCover')
    },

  },
  watch: {
    coverPreview(val) {
      this.$emit('previewEnabled', !!(val))
    },
    preview(val) {
      if (!val) {
        this.profilePreview = null;
        this.image = null;
      }
    },
    saveChanges(val) {
      if (val) {
        if (this.coverPreview) {
          this.updateCover()
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
