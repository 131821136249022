<template>
  <div class="row" style="margin-left: 0.1rem !important;">
    <div v-for="(photo,index) in listData" :key="index" class="col-md-artist-photo">
      <div class="photo-block">
        <img :src="photo.artwork_url" @error="onPostImageError">
      </div>
    </div>
  </div>
</template>

<script  type="application/javascript">
import Vue from "vue";
import VueImg from "v-img";
const vueImgConfig = {
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
}
Vue.use(VueImg, vueImgConfig);
export default {
  name: "mediaPhotosListing",
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => []
    },
    totalMediaPhotos: {

    }
  },
  watch: {
    listData () {
      this.getMediaPhotosLists();
    }
  },
  mounted() {
    // this.getMediaPhotosLists();
  },
  methods: {
    getMediaPhotosLists () {
      this.$emit('getMediaPhotos')
    }
  }
}
</script>

<style scoped>

</style>
