<template>
  <div class="item d-flex flex-row">
    <div class="left-block">
      <div class="cover"><img :src="rowData.artwork_url" alt=""></div>
      <div class="play-button">
        <div class="svg-icon">
          <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg"
               class="svg-play">
            <path class="c-line-1 c-fill-1"
                  d="M2.54164 23C2.30344 22.9942 2.06951 22.9372 1.85664 22.8332C1.61456 22.6948 1.41033 22.5019 1.26122 22.2706C1.11211 22.0393 1.02251 21.7765 1 21.5044V2.51033C1.01292 2.24566 1.08944 1.98761 1.22343 1.75686C1.35742 1.52612 1.54515 1.32909 1.77158 1.18156C2.00949 1.05016 2.28117 0.987834 2.55442 1.00196C2.82766 1.0161 3.091 1.10609 3.31323 1.26129L18.315 10.7561C18.5258 10.8937 18.6986 11.0796 18.8181 11.2974C18.9375 11.5153 19 11.7583 19 12.0051C19 12.252 18.9375 12.495 18.8181 12.7129C18.6986 12.9307 18.5258 13.1166 18.315 13.2542L3.31323 22.7505C3.08759 22.907 2.81876 22.9939 2.54164 23Z"
                  fill="#D1433A" stroke="#D1433A" stroke-width="2"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="right-block flex-column align-items-center justify-content-start">
      <div class="tag-els" v-if="eType == 'music'">
        #Electronic
      </div>
      <div class="talk-tag" v-else>
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="15" fill="#071526"/>
          <path
              d="M22.0865 15.75C22.0865 13.9598 21.3339 12.2429 19.9944 10.977C18.6549 9.71116 16.838 9 14.9436 9C13.0492 9 11.2324 9.71116 9.89288 10.977C8.55333 12.2429 7.80078 13.9598 7.80078 15.75V19.5C7.80078 19.8978 7.96801 20.2794 8.26569 20.5607C8.56337 20.842 8.9671 21 9.38808 21C9.80906 21 10.2128 20.842 10.5105 20.5607C10.8082 20.2794 10.9754 19.8978 10.9754 19.5V17.25C10.975 16.8523 10.8076 16.471 10.51 16.1898C10.2124 15.9086 9.80893 15.7504 9.38808 15.75C9.1086 15.7515 8.8346 15.8235 8.59443 15.9586V15.75C8.59443 14.1587 9.26336 12.6326 10.4541 11.5074C11.6448 10.3821 13.2597 9.75 14.9436 9.75C16.6276 9.75 18.2425 10.3821 19.4332 11.5074C20.6239 12.6326 21.2928 14.1587 21.2928 15.75V15.9586C21.0527 15.8235 20.7787 15.7515 20.4992 15.75C20.0783 15.7504 19.6748 15.9086 19.3773 16.1898C19.0797 16.471 18.9123 16.8523 18.9119 17.25V19.5C18.9119 19.8978 19.0791 20.2794 19.3768 20.5607C19.6745 20.842 20.0782 21 20.4992 21C20.9202 21 21.3239 20.842 21.6216 20.5607C21.9193 20.2794 22.0865 19.8978 22.0865 19.5V15.75ZM9.38808 16.5C9.59849 16.5003 9.8002 16.5794 9.94898 16.72C10.0978 16.8606 10.1815 17.0512 10.1817 17.25V19.5C10.1817 19.6989 10.0981 19.8897 9.94928 20.0303C9.80044 20.171 9.59857 20.25 9.38808 20.25C9.17759 20.25 8.97573 20.171 8.82689 20.0303C8.67805 19.8897 8.59443 19.6989 8.59443 19.5V17.25C8.59471 17.0512 8.67841 16.8606 8.82719 16.72C8.97597 16.5794 9.17768 16.5003 9.38808 16.5ZM21.2928 19.5C21.2928 19.6989 21.2092 19.8897 21.0604 20.0303C20.9116 20.171 20.7097 20.25 20.4992 20.25C20.2887 20.25 20.0868 20.171 19.938 20.0303C19.7892 19.8897 19.7055 19.6989 19.7055 19.5V17.25C19.7055 17.0511 19.7892 16.8603 19.938 16.7197C20.0868 16.579 20.2887 16.5 20.4992 16.5C20.7097 16.5 20.9116 16.579 21.0604 16.7197C21.2092 16.8603 21.2928 17.0511 21.2928 17.25V19.5Z"
              fill="white" stroke="white"/>
        </svg>

      </div>
      <div class="by-title">{{ (rowData.page) ? getArtist(rowData.page) : 'Artist' }}</div>
      <div class="title">{{ (rowData.title) ? rowData.title : 'title' }}</div>
      <div class="subtitle">Episode #{{ (rowData.category) ? rowData.category : 'title' }}</div>
      <div class="text">{{ (rowData.title) ? rowData.title : 'title' }}</div>
      <div class="price-like">
        <div class="heat-ic" @click="favouriteUnfavourite(rowData)">
          <b-spinner v-if="spinner" small label="Small Spinner" class="spinner-color"></b-spinner>
          <svg v-else :class="[(favorite === true) ? 'favourite-icon-fill': '']" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :class="[(favorite === true) ? 'favourite-path-stroke' : '']"
                d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374Z"
                stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <!-- <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374Z" stroke="#D1433A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> -->

        </div>
        <div class="price-total">
          <div class="pric-de">
            $0.99
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">

export default {
  data() {
    return {
      favorite: false,
      spinner: false
    }
  },
  mounted() {
    //  this.popupItem = this.$el
    if(this.rowData){
      this.favorite = this.rowData.favorite
    }
  },

  components: {},
  props: {
    gridTitle: String,
    redirectLink: String,
    eType: {
      type: String,
      default: 'music'
    },
    rowData: {
      type: Object,
      default: () => []
    }
  },
  computed: {},
  methods: {
    async favouriteUnfavourite(object) {
      this.spinner = true
      const res =  await this.setFavourite(object)
      if (res.success === 1) {
        this.favorite = (this.favorite === true) ? false : true;
        (this.favorite === true) ? this.favoriteCounts++ : this.favoriteCounts--
      }
      this.spinner = false
    },
  },
  directives: {}
}
</script>
<style scoped>
.podcast-featured .item{
  max-width: none!important;
}
.favourite-icon-fill {
  fill: #D1433A;
}
.favourite-path-stroke{
  stroke: #D1433A;
}
</style>
