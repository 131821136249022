<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="linked" class="settings-content right-setting-bar active">
      <div class="set-block d-flex flex-column setting-header" v-if="false">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.468 2.35297C13.468 3.29349 12.7056 4.05593 11.7651 4.05593C10.8246 4.05593 10.0621 3.29349 10.0621 2.35297C10.0621 1.41244 10.8246 0.65 11.7651 0.65C12.7056 0.65 13.468 1.41244 13.468 2.35297Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linejoin="round"
              />
              <line
                x1="4.38105"
                y1="6.78596"
                x2="9.67915"
                y2="3.7271"
                stroke="#47505B"
                stroke-width="1.3"
              />
              <path
                d="M4.05593 7.99982C4.05593 8.94034 3.29349 9.70278 2.35297 9.70278C1.41244 9.70278 0.65 8.94034 0.65 7.99982C0.65 7.05929 1.41244 6.29685 2.35297 6.29685C3.29349 6.29685 4.05593 7.05929 4.05593 7.99982Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linejoin="round"
              />
              <path
                d="M13.468 13.6468C13.468 12.7063 12.7056 11.9438 11.7651 11.9438C10.8246 11.9438 10.0621 12.7063 10.0621 13.6468C10.0621 14.5873 10.8246 15.3498 11.7651 15.3498C12.7056 15.3498 13.468 14.5873 13.468 13.6468Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linejoin="round"
              />
              <line
                y1="-0.65"
                x2="6.11771"
                y2="-0.65"
                transform="matrix(0.866025 0.5 0.5 -0.866025 4.70605 8.65112)"
                stroke="#47505B"
                stroke-width="1.3"
              />
            </svg>
          </div>
          Linked Accounts
        </div>
        <div class="set-block-content d-flex flex-column linked-block">
          <div
            class="linked-item d-flex flex-row align-items-center"
            data-action="disconnect"
          >
            <img
              src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/small-logo-1.png"
              alt=""
            />
            <div class="d-flex flex-column">
              <div class="item-title">AudioGround</div>
              <div class="item-subtitle">
                Your account is connected to <span>AudioGround</span>
              </div>
            </div>
            <div class="switch-button d-flex ml-auto">
              <label class="switch">
                <input type="checkbox" checked="checked" class="default" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div
            class="linked-item d-flex flex-row align-items-center"
            data-action="connect"
          >
            <img
              src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/small-logo-2.png"
              alt=""
            />
            <div class="d-flex flex-column">
              <div class="item-title">TicketGround</div>
              <div class="item-subtitle">
                Connect your account to <span>TicketGround</span>
              </div>
            </div>
            <div class="switch-button d-flex ml-auto">
              <label class="switch">
                <input type="checkbox" checked="checked" class="default" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div
            class="linked-item d-flex flex-row align-items-center"
            data-action="connect"
          >
            <div class="svg-icon">
              <img
                src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/small-logo-3.png"
                alt=""
              />
            </div>
            <div class="d-flex flex-column">
              <div class="item-title">JobGround</div>
              <div class="item-subtitle">
                Connect your account to <span>JobGround</span>
              </div>
            </div>
            <div class="switch-button d-flex ml-auto">
              <label class="switch">
                <input type="checkbox" checked="checked" class="default" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="set-block d-flex flex-column setting-header">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7651 5.70593C14.0646 5.70593 15.118 4.65247 15.118 3.35297C15.118 2.05346 14.0646 1 12.7651 1C11.4656 1 10.4121 2.05346 10.4121 3.35297C10.4121 4.65247 11.4656 5.70593 12.7651 5.70593Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linejoin="round"
              />
              <line
                x1="5.38105"
                y1="7.78596"
                x2="10.6791"
                y2="4.7271"
                stroke="#47505B"
                stroke-width="1.3"
              />
              <path
                d="M3.35297 11.3528C4.65247 11.3528 5.70593 10.2993 5.70593 8.99982C5.70593 7.70031 4.65247 6.64685 3.35297 6.64685C2.05346 6.64685 1 7.70031 1 8.99982C1 10.2993 2.05346 11.3528 3.35297 11.3528Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linejoin="round"
              />
              <path
                d="M12.7651 12.2938C14.0646 12.2938 15.118 13.3473 15.118 14.6468C15.118 15.9463 14.0646 16.9998 12.7651 16.9998C11.4656 16.9998 10.4121 15.9463 10.4121 14.6468C10.4121 13.3473 11.4656 12.2938 12.7651 12.2938Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linejoin="round"
              />
              <line
                y1="-0.65"
                x2="6.11771"
                y2="-0.65"
                transform="matrix(0.866025 0.5 0.5 -0.866025 5.70605 9.65112)"
                stroke="#47505B"
                stroke-width="1.3"
              />
            </svg>
          </div>
          Linked Accounts
        </div>
        <div class="set-block-content d-flex flex-column linked-block">
          <div class="linked-item d-flex flex-row align-items-center">
            <div class="svg-icon color-fb soc-icon">
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2165 17.8292V9.69675H7.47994V6.52697H10.2165V4.18949C10.2165 1.47753 11.8724 0 14.2922 0C15.4523 0 16.4471 0.0867915 16.7372 0.124482V2.9592L15.0588 2.95989C13.7431 2.95989 13.4893 3.58541 13.4893 4.50277V6.52594H16.6283L16.2178 9.69537H13.4889V17.8281C16.3101 17.8281 -13.9148 17.8292 10.2165 17.8292Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div class="d-flex flex-column">
              <div class="item-title">Facebook</div>
              <div class="item-subtitle">
                Your account is connected to <span>Facebook</span>
              </div>
            </div>
            <a
              href="#"
              class="action-button connect"
              @click="connectWithFacebook"
              >Connect</a
            >
            <!-- <a href="" class="action-button disconnect">Disconnect</a> -->
          </div>

          <div class="linked-item d-flex flex-row align-items-center">
            <div class="svg-icon color-google soc-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5246 6.4998C15.6245 7.03535 15.679 7.59529 15.679 8.1796C15.679 12.7501 12.6201 16 8 16C6.94933 16.0003 5.9089 15.7936 4.93815 15.3917C3.9674 14.9898 3.08536 14.4005 2.34243 13.6576C1.5995 12.9146 1.01024 12.0326 0.608312 11.0618C0.206388 10.0911 -0.000319969 9.05066 3.71761e-07 8C-0.000319969 6.94933 0.206388 5.9089 0.608312 4.93815C1.01024 3.9674 1.5995 3.08536 2.34243 2.34243C3.08536 1.5995 3.9674 1.01024 4.93815 0.608312C5.9089 0.206387 6.94933 -0.000319969 8 3.71761e-07C10.1601 3.71761e-07 11.9651 0.794799 13.3499 2.08533L11.0947 4.34051V4.33482C10.2552 3.53515 9.18976 3.12475 8 3.12475C5.36042 3.12475 3.21495 5.35473 3.21495 7.99512C3.21495 10.6347 5.36042 12.8696 8 12.8696C10.395 12.8696 12.0252 11.5002 12.36 9.61967H8V6.4998H15.5254H15.5246Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="d-flex flex-column">
              <div class="item-title">Google</div>
              <div class="item-subtitle">
                Connect your account to <span>Google</span>
              </div>
            </div>
            <a href="#" class="action-button connect" @click="connectWithGoogle"
              >Connect</a
            >
          </div>

          <div class="linked-item d-flex flex-row align-items-center">
            <div class="svg-icon color-tw soc-icon">
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.03168 13C11.0694 13 14.3718 7.99808 14.3718 3.66044C14.3718 3.51837 14.3718 3.37694 14.3622 3.23615C15.0047 2.77148 15.5593 2.19613 16 1.53705C15.4009 1.80251 14.7653 1.9766 14.1146 2.0535C14.7998 1.64328 15.3127 0.998062 15.5578 0.237937C14.9134 0.620284 14.2084 0.889743 13.4733 1.03469C12.9783 0.508438 12.3237 0.15997 11.6108 0.0432057C10.8978 -0.0735581 10.1663 0.0478923 9.52931 0.388763C8.89234 0.729633 8.38548 1.27092 8.08716 1.92886C7.78884 2.5868 7.71569 3.32472 7.87904 4.02841C6.57392 3.96299 5.29715 3.62385 4.13161 3.03302C2.96607 2.44218 1.93782 1.61285 1.1136 0.598874C0.693819 1.32149 0.565248 2.17692 0.754066 2.991C0.942885 3.80508 1.43489 4.51659 2.12992 4.98067C1.60751 4.96519 1.0965 4.82428 0.64 4.56982C0.64 4.58326 0.64 4.59734 0.64 4.61142C0.640207 5.36927 0.902567 6.10372 1.38258 6.6902C1.86259 7.27667 2.53071 7.67907 3.2736 7.82913C2.79032 7.96092 2.28325 7.98019 1.79136 7.88545C2.00113 8.53767 2.40951 9.10803 2.95941 9.51675C3.5093 9.92547 4.17319 10.1521 4.85824 10.165C3.69583 11.0785 2.25988 11.5744 0.78144 11.5729C0.520258 11.5724 0.259331 11.5566 0 11.5255C1.50121 12.4888 3.24794 12.9998 5.03168 12.9974"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div class="d-flex flex-column">
              <div class="item-title">Twitter</div>
              <div class="item-subtitle">
                Connect your account to <span>Twitter</span>
              </div>
            </div>
            <a
              href="#"
              class="action-button connect"
              @click="connectWithTwitter"
              >Connect</a
            >
          </div>
          <div class="linked-item d-flex flex-row align-items-center">
            <div class="svg-icon color-in soc-icon">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.28758 5.46403H3.7026V16.464H0.28758V5.46403ZM1.97712 0C3.05554 0 3.95423 0.898689 3.95423 1.97712C3.95423 3.05554 3.05554 3.95423 1.97712 3.95423C0.898689 3.95423 0 3.05554 0 1.97712C0 0.898689 0.898689 0 1.97712 0Z"
                  fill="white"
                ></path>
                <path
                  d="M5.82349 5.46355H9.09471V6.97334H9.16661C9.63393 6.1106 10.7483 5.21191 12.4019 5.21191C15.8529 5.21191 16.4999 7.47661 16.4999 10.4603V16.4994H13.0849V11.1073C13.0849 9.84915 13.0489 8.19556 11.3235 8.19556C9.56203 8.19556 9.27445 9.59752 9.27445 10.9995V16.4276H5.85943V5.46355H5.82349Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div class="d-flex flex-column">
              <div class="item-title">LinkedIn</div>
              <div class="item-subtitle">
                Connect your account to <span>Linkedin</span>
              </div>
            </div>
            <a href="" class="action-button connect">Connect</a>
          </div>
          <div class="linked-item d-flex flex-row align-items-center">
            <div class="svg-icon color-vk soc-icon">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.28758 5.46403H3.7026V16.464H0.28758V5.46403ZM1.97712 0C3.05554 0 3.95423 0.898689 3.95423 1.97712C3.95423 3.05554 3.05554 3.95423 1.97712 3.95423C0.898689 3.95423 0 3.05554 0 1.97712C0 0.898689 0.898689 0 1.97712 0Z"
                  fill="white"
                ></path>
                <path
                  d="M5.82349 5.46355H9.09471V6.97334H9.16661C9.63393 6.1106 10.7483 5.21191 12.4019 5.21191C15.8529 5.21191 16.4999 7.47661 16.4999 10.4603V16.4994H13.0849V11.1073C13.0849 9.84915 13.0489 8.19556 11.3235 8.19556C9.56203 8.19556 9.27445 9.59752 9.27445 10.9995V16.4276H5.85943V5.46355H5.82349Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div class="d-flex flex-column">
              <div class="item-title">Vkontakte</div>
              <div class="item-subtitle">
                Connect your account to <span>Vkontakte</span>
              </div>
            </div>
            <a href="" class="action-button connect">Connect</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { facebook_login, get_ip_address } from "../../../apis/APIs";
import LoginWithTwitter from "login-with-twitter";

const tw = new LoginWithTwitter({
  consumerKey: "PdNekvOFgpy7XV75kClkmQEgY",
  consumerSecret: "mi7SrkZSJQdx7jAwn1ccsf5WuMq7IKc0RDcLd7kDi9sxb",
  callbackUrl: "http://localhost:8081/home/settings",
});

export default {
  data() {
    return {
      loading: false,

      twitter_loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["update_user_data"]),
    connectWithGoogle() {
      this.loading = true;
      this.$gAuth
        .signIn()
        .then(async (GoogleUser) => {
          // on success do something
          const gUser = GoogleUser.getBasicProfile();

          const res_ip = await get_ip_address();
          const { ip } = res_ip.data;

          const email = gUser.getEmail();
          const first_name = gUser.getGivenName();
          const last_name = gUser.getFamilyName();
          const picture = gUser.getImageUrl();
          const google_id = gUser.getId();

          console.log("Google profile ===> ", gUser);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log("error", error);
        });
    },
    async connectWithFacebook() {
      const that = this;
      window.FB.login(function (response) {
        if (response.authResponse) {
          const { accessToken } = response.authResponse || {};
          console.log(
            "You are logged in &amp; cookie set!",
            response.authResponse,
            accessToken
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      });
      return false;
    },
    connectWithTwitter() {
      tw.login((err, tokenSecret, url) => {
        console.log("twitter ===> ", err, tokenSecret, url);
      });
    },
    async init() {
      try {
        this.facebook_sdk_loading = true;
        await this.loadFbSdk();
        this.facebook_sdk_loading = false;
      } catch (error) {
        this.facebook_sdk_loading = false;
      }
    },
    async loadFbSdk() {
      return new Promise((resolve) => {
        window.fbAsyncInit = function () {
          // eslint-disable-line func-names
          FB.init({
            appId: "272797644453089",
            cookie: true,
            version: "v13.0",
            xfbml: false,
          });
          FB.AppEvents.logPageView();
          resolve("SDK Loaded!");
        };
        (function (d, s, id) {
          // eslint-disable-line func-names
          console.log("connect with facebook sdk");
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          const js = d.createElement(s);
          js.id = id;
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      });
    },
  },
};
</script>
<style lang="">
</style>
