<template>
  <div class="row">
    <div class="col-md-12">
      <div class="birthday-card">
        <div class="birthday-card-body">
          <h1 class="birthday-heading m-0">
            TODAY'S BIRTHDAYS ({{ moment().format("DD MMMM YYYY") }})
          </h1>
        </div>
      </div>
    </div>
    <div
      class="col-md-3 pt-3"
      v-for="(today_birthday, index) in $store.state.friends.today_birthdays"
      :key="index"
    >
      <div class="birthday-card">
        <div class="birthday-card-body px-2">
          <div class="col-md-12 px-2">
            <b-img
              :src="today_birthday.artwork_url"
              rounded="circle"
              alt="User Image"
              @error="onProfileImageError($event, getFirstAlphabetOfUserName(today_birthday))"
              style="
                width: 130px !important;
                height: 130px !important;
                margin: 0 auto;
                display: flex;
              "
            ></b-img>
          </div>
          <div class="col-md-12 pt-3 px-0 text-center">
            <h6
              class="birthday-h-font cursor-pointer"
              @click="
                redirectWidgetsToProfilePage($event, today_birthday.username)
              "
            >
              {{ today_birthday.name }}
            </h6>
          </div>
          <div class="col-md-12">
            <h6 class="birthday-p-font">
              {{ moment(today_birthday.birth).format("DD MMM") }}
              <span class="years-old"
                >({{
                  moment().format("YYYY") -
                  moment(today_birthday.birth).format("YYYY")
                }}
                years)</span
              >
            </h6>
          </div>
          <div class="col-md-12 px-2">
            <button class="birthday-btn w-100" type="button">
              <svg
                class="d-inline"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 7.75C11.25 8.05942 11.1271 8.35616 10.9083 8.57496C10.6895 8.79375 10.3928 8.91667 10.0833 8.91667H3.08333L0.75 11.25V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H10.0833C10.3928 0.75 10.6895 0.872916 10.9083 1.09171C11.1271 1.3105 11.25 1.60725 11.25 1.91667V7.75Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style="padding-left: 10px">Send Message</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-12 pt-3"
      v-if="!$store.state.friends.today_birthdays.length"
    >
      <div class="birthday-a-card">
        <div class="birthday-card-body">
          <div class="col-md-12">
            <a>
              <svg
                class="icon-center"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.6668 25V45.8333H8.3335V25"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M45.8332 14.5835H4.1665V25.0002H45.8332V14.5835Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 45.8335V14.5835"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.9998 14.5832H15.6248C14.2435 14.5832 12.9187 14.0344 11.942 13.0577C10.9652 12.0809 10.4165 10.7562 10.4165 9.37484C10.4165 7.9935 10.9652 6.66874 11.942 5.69199C12.9187 4.71524 14.2435 4.1665 15.6248 4.1665C22.9165 4.1665 24.9998 14.5832 24.9998 14.5832Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 14.5832H34.375C35.7563 14.5832 37.0811 14.0344 38.0579 13.0577C39.0346 12.0809 39.5833 10.7562 39.5833 9.37484C39.5833 7.9935 39.0346 6.66874 38.0579 5.69199C37.0811 4.71524 35.7563 4.1665 34.375 4.1665C27.0833 4.1665 25 14.5832 25 14.5832Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div class="col-md-12 pt-3">
            <h6 class="birthday-h-font">No Birthdays Today</h6>
          </div>
          <div class="col-md-12 pt-2">
            <p class="birthday-p1-font">
              We'll let you know about all birthdays,<br />if user added this to
              their profile
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12"
      v-if="
        $store.state.friends.today_birthday_count >
        $store.state.friends.today_birthdays.length
      "
    >
      <div class="show-more musicbar_more-frd">
        <a href="javascript:" @click="showMoreBirthdays('today')">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW 4 MORE BIRTHDAYS
          </span>
        </a>
      </div>
    </div>

    <div class="col-md-12 pt-3">
      <div class="birthday-card">
        <div class="birthday-card-body">
          <h1 class="birthday-heading-1 m-0">
            TOMORROW BIRTHDAYS ({{
              moment().add(1, "days").format("DD MMMM YYYY")
            }})
          </h1>
        </div>
      </div>
    </div>
    <div
      class="col-md-3 pt-3"
      v-for="(tomorrow_birthday, index) in $store.state.friends
        .tomorrow_birthdays"
      :key="index"
    >
      <div class="birthday-card">
        <div class="birthday-card-body px-2">
          <div class="col-md-12 px-2">
            <b-img
              :src="tomorrow_birthday.artwork_url"
              rounded="circle"
              alt="User Image"
              @error="onProfileImageError($event, getFirstAlphabetOfUserName(tomorrow_birthday))"
              style="
                width: 130px !important;
                height: 130px !important;
                margin: 0 auto;
                display: flex;
              "
            ></b-img>
          </div>
          <div class="col-md-12 pt-3 px-0 text-center">
            <h6 class="birthday-h-font">{{ tomorrow_birthday.name }}</h6>
          </div>
          <div class="col-md-12">
            <h6 class="birthday-p-font">
              {{ moment(tomorrow_birthday.birth).format("DD MMM") }}
              <span class="years-old"
                >({{
                  moment().format("YYYY") -
                  moment(tomorrow_birthday.birth).format("YYYY")
                }}
                years)</span
              >
            </h6>
          </div>
          <div class="col-md-12 px-2">
            <button class="birthday-btn w-100" type="button">
              <svg
                class="d-inline"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 7.75C11.25 8.05942 11.1271 8.35616 10.9083 8.57496C10.6895 8.79375 10.3928 8.91667 10.0833 8.91667H3.08333L0.75 11.25V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H10.0833C10.3928 0.75 10.6895 0.872916 10.9083 1.09171C11.1271 1.3105 11.25 1.60725 11.25 1.91667V7.75Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style="padding-left: 10px">Send Message</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-12 pt-3"
      v-if="!$store.state.friends.tomorrow_birthdays.length"
    >
      <div class="birthday-a-card">
        <div class="birthday-card-body">
          <div class="col-md-12">
            <a>
              <svg
                class="icon-center"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.6668 25V45.8333H8.3335V25"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M45.8332 14.5835H4.1665V25.0002H45.8332V14.5835Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 45.8335V14.5835"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.9998 14.5832H15.6248C14.2435 14.5832 12.9187 14.0344 11.942 13.0577C10.9652 12.0809 10.4165 10.7562 10.4165 9.37484C10.4165 7.9935 10.9652 6.66874 11.942 5.69199C12.9187 4.71524 14.2435 4.1665 15.6248 4.1665C22.9165 4.1665 24.9998 14.5832 24.9998 14.5832Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 14.5832H34.375C35.7563 14.5832 37.0811 14.0344 38.0579 13.0577C39.0346 12.0809 39.5833 10.7562 39.5833 9.37484C39.5833 7.9935 39.0346 6.66874 38.0579 5.69199C37.0811 4.71524 35.7563 4.1665 34.375 4.1665C27.0833 4.1665 25 14.5832 25 14.5832Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div class="col-md-12 pt-3">
            <h6 class="birthday-h-font">No Birthdays Tomorrow</h6>
          </div>
          <div class="col-md-12 pt-2">
            <p class="birthday-p1-font">
              We'll let you know about all birthdays,<br />if user added this to
              their profile
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12"
      v-if="
        $store.state.friends.tomorrow_birthday_count >
        $store.state.friends.tomorrow_birthdays.length
      "
    >
      <div class="show-more musicbar_more-frd">
        <a href="javascript:" @click="showMoreBirthdays('tomorrow')">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW 4 MORE BIRTHDAYS
          </span>
        </a>
      </div>
    </div>

    <div class="col-md-12 pt-3">
      <div class="birthday-card">
        <div class="birthday-card-body">
          <h1 class="birthday-heading-1 m-0">NEXT WEEK BIRTHDAYS</h1>
        </div>
      </div>
    </div>
    <div v-if="$store.state.friends.next_week_birthdays.length">
      <div
        class="col-md-3 pt-3"
        v-for="(next_week_birthday, index) in $store.state.friends
          .next_week_birthdays"
        :key="index"
      >
        <div class="birthday-card">
          <div class="birthday-card-body px-2">
            <div class="col-md-12 px-2">
              <b-img
                :src="next_week_birthdays.artwork_url"
                rounded="circle"
                alt="User Image"
                @error="onProfileImageError($event, getFirstAlphabetOfUserName(next_week_birthdays))"
                style="
                  width: 130px !important;
                  height: 130px !important;
                  margin: 0 auto;
                  display: flex;
                "
              ></b-img>
            </div>
            <div class="col-md-12 pt-3 px-0 text-center">
              <h6 class="birthday-h-font">{{ next_week_birthday.name }}</h6>
            </div>
            <div class="col-md-12">
              <h6 class="birthday-p-font">
                {{ moment(next_week_birthday.birth).format("DD MMM") }}
                <span class="years-old"
                  >({{
                    moment().format("YYYY") -
                    moment(next_week_birthday.birth).format("YYYY")
                  }}
                  years)</span
                >
              </h6>
            </div>
            <div class="col-md-12 px-2">
              <button class="birthday-btn w-100" type="button">
                <svg
                  class="d-inline"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.25 7.75C11.25 8.05942 11.1271 8.35616 10.9083 8.57496C10.6895 8.79375 10.3928 8.91667 10.0833 8.91667H3.08333L0.75 11.25V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H10.0833C10.3928 0.75 10.6895 0.872916 10.9083 1.09171C11.1271 1.3105 11.25 1.60725 11.25 1.91667V7.75Z"
                    stroke="#D1433A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span style="padding-left: 10px">Send Message</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-12 pt-3"
      v-if="!$store.state.friends.next_week_birthdays.length"
    >
      <div class="birthday-a-card">
        <div class="birthday-card-body">
          <div class="col-md-12">
            <a>
              <svg
                class="icon-center"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M41.6668 25V45.8333H8.3335V25"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M45.8332 14.5835H4.1665V25.0002H45.8332V14.5835Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 45.8335V14.5835"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M24.9998 14.5832H15.6248C14.2435 14.5832 12.9187 14.0344 11.942 13.0577C10.9652 12.0809 10.4165 10.7562 10.4165 9.37484C10.4165 7.9935 10.9652 6.66874 11.942 5.69199C12.9187 4.71524 14.2435 4.1665 15.6248 4.1665C22.9165 4.1665 24.9998 14.5832 24.9998 14.5832Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25 14.5832H34.375C35.7563 14.5832 37.0811 14.0344 38.0579 13.0577C39.0346 12.0809 39.5833 10.7562 39.5833 9.37484C39.5833 7.9935 39.0346 6.66874 38.0579 5.69199C37.0811 4.71524 35.7563 4.1665 34.375 4.1665C27.0833 4.1665 25 14.5832 25 14.5832Z"
                  stroke="#D1433A"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div class="col-md-12 pt-3">
            <h6 class="birthday-h-font">No Birthdays Next Week</h6>
          </div>
          <div class="col-md-12 pt-2">
            <p class="birthday-p1-font">
              We'll let you know about all birthdays,<br />if user added this to
              their profile
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12"
      v-if="
        $store.state.friends.next_week_birthday_count >
        $store.state.friends.next_week_birthdays.length
      "
    >
      <div class="show-more musicbar_more-frd">
        <a href="javascript:" @click="showMoreBirthdays('next_week')">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW 4 MORE BIRTHDAYS
          </span>
        </a>
      </div>
    </div>

    <div class="col-md-12 pt-3">
      <div class="birthday-card">
        <div class="birthday-card-body">
          <h1 class="birthday-heading-1 m-0">THIS MONTH BIRTHDAYS</h1>
        </div>
      </div>
    </div>
    <div
      class="col-md-3 pt-3"
      v-for="(month_birthday, index) in $store.state.friends.month_birthdays"
      :key="index"
    >
      <div class="birthday-card">
        <div class="birthday-card-body px-2">
          <div class="col-md-12 px-2">
            <b-img
              :src="month_birthday.artwork_url"
              rounded="circle"
              alt="User Image"
              @error="onProfileImageError($event, getFirstAlphabetOfUserName(month_birthday))"
              style="
                width: 130px !important;
                height: 130px !important;
                margin: 0 auto;
                display: flex;
              "
            ></b-img>
          </div>
          <div class="col-md-12 pt-3 px-0 text-center">
            <h6 class="birthday-h-font">{{ month_birthday.name }}</h6>
          </div>
          <div class="col-md-12">
            <h6 class="birthday-p-font">
              {{ moment(month_birthday.birth).format("DD MMM") }}
              <span class="years-old"
                >({{
                  moment().format("YYYY") -
                  moment(month_birthday.birth).format("YYYY")
                }}
                years)</span
              >
            </h6>
          </div>
          <div class="col-md-12 px-2">
            <button class="birthday-btn w-100" type="button">
              <svg
                class="d-inline"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 7.75C11.25 8.05942 11.1271 8.35616 10.9083 8.57496C10.6895 8.79375 10.3928 8.91667 10.0833 8.91667H3.08333L0.75 11.25V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H10.0833C10.3928 0.75 10.6895 0.872916 10.9083 1.09171C11.1271 1.3105 11.25 1.60725 11.25 1.91667V7.75Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style="padding-left: 10px">Send Message</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12"
      v-if="
        $store.state.friends.monthly_birthday_count >
        $store.state.friends.month_birthdays.length
      "
    >
      <div class="show-more musicbar_more-frd">
        <a href="javascript:" @click="showMoreBirthdays('month')">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW MORE BIRTHDAYS
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {};
  },
  created: function () {
    this.moment = moment;
  },
  methods: {
    showMoreBirthdays(day) {
      this.$store.dispatch("getBirthdays", day);
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
.birthday-card {
  .birthday-card-body {
    .years-old {
      color: #a2a9b2;
    }
  }
}
.wr_content {
  display: flex;
  flex-wrap: wrap;
  .mr_r_0 {
    div {
      margin-right: 0px;
    }
  }
}
.birthday-table td,
th {
  border: none !important;
  text-align: center;
  font-size: 14px;
}
.birthday-table td {
  padding: 11.7px 0px;
}
.birthday-card-btn {
  color: #ffffff;
  background: #D1433A;
  padding: 15px 0px 15px 0px;
  border-radius: 4px !important;
  border: none;
  font-weight: normal;
  font-family: "HelveticaNeueBold";
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.05em;
}
.friend-request-btn {
  color: #D1433A;
  background: #D1433A1A;
  margin: 0;
  border: 1px solid #D1433A;
  padding: 5px 5px 5px 5px;
  border-radius: 2px;
  border: none;
  font-weight: 500;
  font-family: "HelveticaNeuemedium";
}
.friend-request-btn:hover {
  background-color: #D1433A;
  color: #ffffff;
}
.day-color {
  background: #D1433A;
  border-radius: 100px;
  color: white;
}
.c-month-center {
  color: #D1433A;
  font-size: 16px;
  font-weight: 700;
  font-family: "HelveticaNeueMedium";
}
.calender-heading {
  font-size: 17px;
  font-family: "HelveticaNeueMedium";
  font-weight: 700;
}
.birthday-heading {
  font-family: "HelveticaNeueMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #47505b;
}
.birthday-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}
.birthday-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.birthday-btn {
  color: #D1433A;
  background: #fff;
  margin: 0;
  border: 1.3px solid #D1433A;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
}
.birthday-h-font {
  font-family: "HelveticaNeueMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.03em;
  margin-bottom: 4px;
  text-align: center;
}
.birthday-p-font {
  font-family: "HelveticaNeueMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  text-align: center;
}
.birthday-p-font {
  font-family: "HelveticaNeueMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  text-align: center;
  color: #47505b;
}
.birthday-a-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #e6eaf0;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}
.icon-center {
  margin: 0 auto;
  display: flex;
}
.icon {
  margin: 3px 8px 0 0 !important;
}

.musicbar_more-frd .show_more_bg {
  display: flex;
  align-items: center;
  font-family: "HelveticaNeueMedium";
}
.birthday-p1-font {
  font-family: "HelveticaNeueNormal";
  letter-spacing: 1px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  text-align: center;
  color: #8b949f;
  letter-spacing: 0.5px;
}
.birthday-heading-1 {
  font-family: "HelveticaNeueBold";
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #47505b;
}
.send-request-btn {
  color: #D1433A;
  background: #fff;
  margin: 0;
  border: 1px solid #D1433A;
  padding: 3px 5px 3px 5px;
  border-radius: 2px;
  font-weight: 500;
  font-family: "HelveticaNeuemedium";
}
.send-request-btn:hover {
  background-color: #D1433A;
  color: #ffffff;
}
.remove-btn {
  background-color: #e6eaf0;
  color: #47505b;
  width: 100px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  border: none;
}
.remove-btn:hover {
  background-color: #c4ccd6;
  color: #47505b;
}
.float {
  float: right;
}
</style>
