<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Hidden</h3>
        <h6>To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="apply_now_settings.visibility" id="switch30"/>
        <label class="switch_sm" for="switch30">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4>What Job Post do you want user to apply?</h4>
        <p>Choose from the list below</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">

          <div class="col-5">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">Your Job Posts</label>
              <div class="select_box">
                <select v-model="apply_now_settings.job_posts" class="form_control">
                  <option value="0">Choose job post from list</option>
                  <option value="1">Job 1</option>
                  <option value="2">Job 2</option>
                  <option value="3">Job 3</option>
                </select>
              </div>

            </div>
          </div>

        </div>

      </div>


    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/check-circle.svg" alt="check"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14.6673 7.38625V7.99958C14.6665 9.4372 14.201 10.836 13.3402 11.9875C12.4794 13.1389 11.2695 13.9812 9.89089 14.3889C8.51227 14.7965 7.03882 14.7475 5.6903 14.2493C4.34177 13.7511 3.19042 12.8303 2.40796 11.6243C1.6255 10.4183 1.25385 8.99163 1.34844 7.55713C1.44303 6.12263 1.99879 4.75714 2.93284 3.6643C3.86689 2.57146 5.12917 1.80984 6.53144 1.49301C7.93371 1.17619 9.40083 1.32114 10.714 1.90625"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.6667 2.66699L8 9.34033L6 7.34033" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        Apply Now
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apply_now_settings: {
        visibility: false,
        job_posts: null
      }
    }
  },
  props: {
    applyNowSettings: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    'apply_now_settings.visibility'() {
      this.$emit('getApplyNowData', this.apply_now_settings)
    },
    'apply_now_settings.job_posts'() {
      this.$emit('getApplyNowData', this.apply_now_settings)
    },
    applyNowSettings() {
      this.setApplyNowSettingsData()
    }
  },
  mounted() {
    this.setApplyNowSettingsData()
  },
  methods: {
    setApplyNowSettingsData() {
      this.apply_now_settings = this.applyNowSettings
    }
  }
}
</script>
