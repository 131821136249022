<template>
  <div>
    <div v-if="getActiveFullChatType && (Object.keys(getActiveChatUser).length !== 0 || Object.keys(getActiveGroup).length !== 0)">
      <div class="heading d-flex align-items-center">
        <div class="title">{{ (getActiveFullChatType == "clubroom") ? "ClubRoom Info" : "PartyRoom Info" }}</div>
      </div>
      <div class="body">
        <div class="user-card">
          <div class="chat-new for-group">
            <div class="cover-image">
              <div v-if="members_list_images" class="group-img-wrapper">
                <div v-for="(image , index) in members_list_images.slice(0,3)" :key="index" class="single-img-wrap">
                  <img :src="image" alt="" />
                </div>
                <div class="single-img-wrap count">
                  +{{ members_list_images.length > 3 ? members_list_images.length - 3 : "0" }}
                </div>
              </div>
              <div v-else class="group-img-wrapper">
                <div class="single-img-wrap">
                  <img src="" alt="" />
                </div>
                <div class="single-img-wrap">
                  <img src="" alt="" />
                </div>
                <div class="single-img-wrap">
                  <img src="" alt="" />
                </div>
                <div class="single-img-wrap count"> +0</div>
              </div>
            </div>
            <div class="content-block d-flex flex-row w-100">
              <div>
                <div class="title">{{ ((getActiveGroup && getActiveGroup.title) ? getActiveGroup.title : 'title') | shortTheLengthOfString }}</div>
                <div class="last-active">{{ convertDateIntoHumanReadableForm(getActiveGroup.last_message_at) }}</div>
                <div class="room-type">
                  <div class="svg-icon">
                    <svg
                      style="margin-top: -3px"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_3494_17866)">
                        <path
                          d="M7 0.583008C6.53587 0.583008 6.09075 0.767382 5.76256 1.09557C5.43437 1.42376 5.25 1.86888 5.25 2.33301V6.99967C5.25 7.4638 5.43437 7.90892 5.76256 8.23711C6.09075 8.5653 6.53587 8.74968 7 8.74968C7.46413 8.74968 7.90925 8.5653 8.23744 8.23711C8.56563 7.90892 8.75 7.4638 8.75 6.99967V2.33301C8.75 1.86888 8.56563 1.42376 8.23744 1.09557C7.90925 0.767382 7.46413 0.583008 7 0.583008V0.583008Z"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.0837 5.83301V6.99967C11.0837 8.08264 10.6535 9.12125 9.88768 9.88703C9.12191 10.6528 8.08329 11.083 7.00033 11.083C5.91736 11.083 4.87875 10.6528 4.11297 9.88703C3.3472 9.12125 2.91699 8.08264 2.91699 6.99967V5.83301"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 11.083V13.4163"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.66699 13.417H9.33366"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3494_17866">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span>Video Room</span>
                </div>
              </div>
              <div class="favor-wrap">
                <div class="svg-icon">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07935 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                      stroke="#47505B"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget about">
          <div class="widget-title">About</div>
          <div class="text">
            {{ ((getActiveGroup && getActiveGroup.description) ? getActiveGroup.description : 'about') | shortTheLengthOfAbout }}
          </div>
        </div>
        <div class="widget Members">
          <div class="widget-title">
            Speakers
            <span>12</span>
          </div>
          <div class="item">
            <div class="img-wrap">
              <div class="img-cover">
                <img src="" alt="" />
              </div>
              <div class="status"></div>
            </div>
            <div class="item-content">
              <div class="item-title">User Name</div>
              <div class="item-subtitle">Singer, Songwriter, Producer</div>
            </div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <div class="img-cover">
                <img src="" alt="" />
              </div>
              <div class="status"></div>
            </div>
            <div class="item-content">
              <div class="item-title">User Name</div>
              <div class="item-subtitle">Singer, Songwriter, Producer</div>
            </div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <div class="img-cover">
                <img src="" alt="" />
              </div>
              <div class="status"></div>
            </div>
            <div class="item-content">
              <div class="item-title">User Name</div>
              <div class="item-subtitle">Singer, Songwriter, Producer</div>
            </div>
          </div>
          <div class="show-all">Show all <span>(12)</span></div>
        </div>
        <div class="widget Members">
          <div class="widget-title">
            Listeners
            <span>80</span>
          </div>
          <div class="item">
            <div class="img-wrap">
              <div class="img-cover">
                <img src="" alt="" />
              </div>
              <div class="status"></div>
            </div>
            <div class="item-content">
              <div class="item-title">User Name</div>
              <div class="item-subtitle">Singer, Songwriter, Producer</div>
            </div>
          </div>
          <div class="item">
            <div class="img-wrap">
              <div class="img-cover">
                <img src="" alt="" />
              </div>
              <div class="status"></div>
            </div>
            <div class="item-content">
              <div class="item-title">User Name</div>
              <div class="item-subtitle">Singer, Songwriter, Producer</div>
            </div>
          </div>
          <div class="show-all">Show all <span>(12)</span></div>
        </div>
        <div class="custom-collapse" role="tablist">
          <b-card no-body>
            <b-button block v-b-toggle.accordion-1>Privacy & Support</b-button>
            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card>
                  <ul class="privacy-support">
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M18.8571 15L15.0476 18.0476H12V22.619H15.0476L18.8571 25.6667V15Z"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M27.9992 18.0477L23.4277 22.6192"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M23.4277 18.0477L27.9992 22.6192"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      Mute Notifications
                    </li>
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="4"
                            fill="transparent"
                          />
                          <path
                            class="c-line-1"
                            d="M24.3582 20.7309V17.4568C24.3853 16.7185 24.268 15.9822 24.0136 15.293C23.7591 14.6038 23.3729 13.9762 22.8785 13.4487C22.3841 12.9212 21.792 12.505 21.1385 12.2255C20.485 11.9459 19.7838 11.809 19.0779 11.8231C18.3895 11.7933 17.7024 11.9078 17.0568 12.1597C16.4113 12.4116 15.8203 12.796 15.3186 13.2902C14.817 13.7844 14.4147 14.3785 14.1353 15.0376C13.856 15.6967 13.7054 16.4076 13.6921 17.1285C13.6999 17.0707 13.642 19.0577 13.642 20.7309C13.642 22.8491 13.1511 23.3429 12 25.1581H26.1575C25.0038 23.3411 24.3582 22.9079 24.3582 20.7309Z"
                            stroke="#071526"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M17.1777 26.9073C17.3989 27.1864 17.675 27.4122 17.9871 27.5694C18.2993 27.7266 18.6402 27.8114 18.9865 27.8181C19.3119 27.8248 19.6334 27.7447 19.921 27.5854C20.2086 27.426 20.4527 27.1927 20.6303 26.9073"
                            stroke="#071526"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                      Ignore messages
                    </li>
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="4"
                            fill="transparent"
                          />
                          <path
                            class="c-line-1"
                            d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
                            stroke="#071526"
                          />
                          <path
                            class="c-line-1"
                            d="M14.4004 25.6001L25.6004 14.4001"
                            stroke="#071526"
                          />
                        </svg>
                      </div>
                      Block
                    </li>
                    <li>
                      <div class="svg-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="40"
                            height="40"
                            rx="4"
                            fill="transparent"
                          />
                          <path
                            class="c-line-1"
                            d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M20 16.8V20"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            class="c-line-1"
                            d="M20 23.2H20.008"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      Report
                    </li>
                  </ul>
                </b-card>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="custom-collapse" role="tablist">
          <b-card no-body>
            <b-button block v-b-toggle.accordion-2>Shared Media & Files</b-button>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card>
                  <div class="media-title">
                    Photos & Video
                    <span>45</span>
                  </div>
                  <div class="media-items-row">
                    <div class="img-wrap">
                      <img src="" alt="" />
                    </div>
                    <div class="img-wrap">
                      <img src="" alt="" />
                    </div>
                    <div class="img-wrap">
                      <img src="" alt="" />
                    </div>
                    <div class="img-wrap with-more">
                      <img src="" alt="" />
                      <span>+42</span>
                    </div>
                  </div>
                  <div class="media-title">
                    Music & Samples
                    <span>23</span>
                  </div>
                  <div class="media-title">
                    Documents & Files
                    <span>12</span>
                  </div>
                  <div class="media-title">
                    Links
                    <span>6</span>
                  </div>
                </b-card>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="heading d-flex align-items-center">
        <div class="title">{{(getLeftBarListingActiveTab === 'clubroom') ? 'ClubRoom Info' : 'PartyRoom Info'}}</div>
      </div>
      <div class="body empty-body-with-search-icon">
        <div class="empty-item">
          <div class="svg-icon">
            <svg
              width="70"
              height="70"
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="70" height="70" fill="transparent" />
              <g clip-path="url(#clip0_3500_20521)">
                <path
                  d="M49.0329 43.7899C52.664 38.835 54.2904 32.6917 53.5866 26.5892C52.8828 20.4867 49.9007 14.8749 45.237 10.8766C40.5734 6.87834 34.572 4.7884 28.4336 5.02493C22.2951 5.26145 16.4724 7.807 12.1302 12.1523C7.78801 16.4976 5.24663 22.3222 5.01449 28.4608C4.78236 34.5994 6.8766 40.5992 10.8782 45.2601C14.8798 49.9209 20.4937 52.8989 26.5968 53.5983C32.6998 54.2978 38.8419 52.667 43.7942 49.0324H43.7904C43.9029 49.1824 44.0229 49.3249 44.1579 49.4636L58.5954 63.9011C59.2986 64.6048 60.2525 65.0003 61.2472 65.0006C62.242 65.001 63.1962 64.6062 63.8998 63.903C64.6035 63.1999 64.999 62.246 64.9993 61.2512C64.9997 60.2565 64.6048 59.3023 63.9017 58.5986L49.4642 44.1611C49.3301 44.0254 49.186 43.9001 49.0329 43.7861V43.7899ZM50.0004 29.3749C50.0004 32.0834 49.467 34.7654 48.4305 37.2677C47.394 39.7701 45.8747 42.0438 43.9595 43.959C42.0443 45.8742 39.7706 47.3934 37.2683 48.4299C34.7659 49.4664 32.084 49.9999 29.3754 49.9999C26.6669 49.9999 23.9849 49.4664 21.4826 48.4299C18.9803 47.3934 16.7066 45.8742 14.7914 43.959C12.8762 42.0438 11.3569 39.7701 10.3204 37.2677C9.28392 34.7654 8.75044 32.0834 8.75044 29.3749C8.75044 23.9048 10.9234 18.6587 14.7914 14.7908C18.6593 10.9229 23.9053 8.74989 29.3754 8.74989C34.8455 8.74989 40.0916 10.9229 43.9595 14.7908C47.8275 18.6587 50.0004 23.9048 50.0004 29.3749Z"
                  fill="#47505B"
                />
              </g>
              <defs>
                <clipPath id="clip0_3500_20521">
                  <rect
                    width="60"
                    height="60"
                    fill="white"
                    transform="translate(5 5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="search-text">Select {{(getLeftBarListingActiveTab === 'clubroom') ? 'ClubRoom' : 'PartyRoom'}} to see info</div>
        </div>
      </div>
    </div>
  </div>

  <!-- OFFER INFO -->
  <!-- <div>
    <div class="heading d-flex align-items-center">
      <div class="title">Offer Info</div>
    </div>
    <div class="body">
      <div class="user-card offer-type">
        <div class="chat-new for-group">
          <div class="content-block d-flex flex-row w-100">
            <div>
              <div class="title">Beat Name 1</div>
              <div class="last-active">Afanasii Petrov</div>
            </div>
          </div>
        </div>
      </div>
      <div class="offer-actions">
        <div class="btn btn-offer">Open Item</div>
        <div class="btn btn-offer">Open Offer</div>
        <div class="btn btn-dropdown">
          <div class="svg-icon">
            <svg
              width="15"
              height="3"
              viewBox="0 0 15 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="c-line-1"
                d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
                fill="#47505B"
              />
              <path
                class="c-line-1"
                d="M7.29297 3C8.1214 3 8.79297 2.32843 8.79297 1.5C8.79297 0.671573 8.1214 0 7.29297 0C6.46454 0 5.79297 0.671573 5.79297 1.5C5.79297 2.32843 6.46454 3 7.29297 3Z"
                fill="#47505B"
              />
              <path
                class="c-line-1"
                d="M13.084 3C13.9124 3 14.584 2.32843 14.584 1.5C14.584 0.671573 13.9124 0 13.084 0C12.2556 0 11.584 0.671573 11.584 1.5C11.584 2.32843 12.2556 3 13.084 3Z"
                fill="#47505B"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="custom-collapse" role="tablist">
        <b-card no-body>
          <b-button block v-b-toggle.accordion-3>Offer from</b-button>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card>
                <div class="user-card inner-collapse">
                  <div class="chat-new for-single">
                    <div class="cover-image">
                      <div class="img-wrapper">
                        <img src="" alt="" />
                      </div>
                    </div>
                    <div class="content-block d-flex flex-row w-100">
                      <div>
                        <div class="title">Daniel Stokes</div>
                        <div class="subtitle">Member</div>
                        <div class="last-active">Active 6h ago</div>
                      </div>
                      <div class="favor-wrap">
                        <div class="svg-icon">
                          <svg
                            width="18"
                            height="16"
                            viewBox="0 0 18 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07935 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                              stroke="#47505B"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div class="custom-collapse border-none" role="tablist">
        <b-card no-body>
          <b-button block v-b-toggle.accordion-4>Info</b-button>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card>
                <ul class="offer-info">
                  <li>Amount:<span>$240</span></li>
                  <li>Dates:<span>20.12.2020</span></li>
                  <li>Status:<span>Confirmed</span></li>
                </ul>
              </b-card>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div> -->
  <!-- OFFER INFO END -->


  <!-- ROOM EMPTY TEXT ONLY (FOR OFFER) -->
  <!--//  <div>-->
  <!--//    <div class="heading d-flex align-items-center">-->
  <!--      <div class="title">Offer Info</div>-->
  <!--    </div>-->
  <!--    <div class="body empty-body">-->
  <!--      <div class="empty-item">-->
  <!--        <div class="search-text">-->
  <!--          Select offer <br />-->
  <!--          to see its info-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!-- ROOM EMPTY TEXT ONLY (FOR OFFER)  END -->
</template>

<script>
export default {
  name: "ClubRoomInfoComponent",
  data() {
    return {
      members_list_images: []
    }
  },
  filters: {
    shortTheLengthOfString: function(string) {
      return (string && string.length > 18) ? string.slice(0, 18)+'...' : string
    },
    shortTheLengthOfAbout: function(string) {
      return (string && string.length > 50) ? string.slice(0, 50)+'...' : string
    }
  },
  watch: {
    getActiveAllGroupMembers() {
      this.setMembersListImages();
    }
  },
  computed: {
    getActiveFullChatType() {
      return this.$store.getters.getActiveFullChatType;
    },
    getActiveGroup() {
      return this.$store.getters.getActiveGroup;
    },
    getLeftBarListingActiveTab() {
      return this.$store.getters.getLeftBarListingActiveTab
    },
    getActiveAllGroupMembers() {
      return this.$store.getters.getActiveAllGroupMembers.members;
    },
  },
  methods: {
    setMembersListImages() {
      if (this.getActiveAllGroupMembers && this.getActiveAllGroupMembers.length > 0) {
        const images = this.getActiveAllGroupMembers.map((e) => e.user.image);
        this.members_list_images = images;
      }
    },
  }
};
</script>

<style scoped lang="scss">
.heading {
  width: inherit;
  height: 79px;
  padding: 0 20px 0 20px;

  // .back-btn {
  //   box-sizing: border-box;
  //   background: #f5f7f9;
  //   border-radius: 4px;
  //   height: 40px;
  //   width: 40px;
  //   border: none;
  //   margin-right: 18px;

  //   svg {
  //     margin: auto;
  //   }
  // }

  .title {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #071526;
    font-family: HelveticaNeueBold, sans-serif;
  }
}

.body {
  padding: 0 20px 20px 20px;

  &.empty-body-with-search-icon {
    .empty-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 770px;
      height: 100%;
      background: #f5f7f9;
      border-radius: 5px;

      .search-text {
        font-family: "HelveticaNeueBold";
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #071526;
        margin-top: 24px;
      }
    }
  }

  &.empty-body {
    .empty-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 770px;
      height: 100%;
    }

    .search-text {
      font-family: "HelveticaNeueNormal";
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #8b949f;
      letter-spacing: 0.5px;
    }
  }

  .user-card {
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    padding: 10px 17px 10px 10px;
    height: 100px;
    width: 310px;

    &.inner-collapse {
      margin-bottom: 20px;
    }

    &.offer-type {
      height: 70px;
      padding: 14px 20px;
    }

    .chat-new {
      max-width: inherit;
      background: transparent;
      margin-bottom: unset;
      padding: unset;
      height: 80px;
      display: flex;
      flex-direction: row;

      &:hover {
        background: unset;
      }

      .favor-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        cursor: pointer;
        margin-left: auto;
      }

      .cover-image {
        max-height: 80px;

        .img-wrapper {
          height: 80px;
          width: 80px;
        }

        img {
          width: inherit;
        }
      }

      .content-block {
        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #071526;
          margin-bottom: 4px;
        }

        .subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #8b949f;
        }

        .last-active {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #8b949f;
        }

        .svg-icon {
          margin-bottom: 8px;
        }
      }

      &.for-group {
        .content-block {
          height: 100%;
          display: flex;
          flex-direction: column;

          .title {
            font-family: "HelveticaNeueMedium";
            font-size: 16px;
            line-height: 20px;
            color: #071526;
            margin-bottom: 4px;
          }

          .room-type {
            display: flex;
            flex-direction: row;
            margin-top: 28px;
            align-items: center;

            .svg-icon {
              margin-bottom: 0;
            }

            span {
              font-family: "HelveticaNeueMedium";
              font-size: 13px;
              line-height: 16px;
              color: #D1433A;
              margin-left: 9px;
            }
          }
        }
      }

      &.for-single {
        align-items: inherit;

        .cover-image {
          max-height: 80px;
          margin-right: 20px;

          .img-wrapper {
            border-radius: 100%;
            background-color: #ccc;
            overflow: hidden;
            height: 80px;
            width: 80px;

            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
        }

        .title {
          margin-top: 4px;
        }

        .last-active {
          position: absolute;
          bottom: 0;
        }

        .content-block {
          position: relative;
        }
      }
    }
  }
}

.group-img-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  .single-img-wrap {
    background-color: grey;
    width: 40px;
    height: 40px;

    img {
      width: 100%;
      object-fit: cover;
    }

    &.count {
      background-color: #D1433A;
      font-family: "HelveticaNeueBold";
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      padding: 8px 0 0 8px;
    }
  }
}

.widget {
  padding: 15px 0;
  border-bottom: 1px solid #e6eaf0;

  .widget-title {
    font-family: "HelveticaNeueBold";
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      color: #8b949f;
    }
  }

  .text {
    font-family: "HelveticaNeueNormal";
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    letter-spacing: 0.5px;
  }

  .item {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;

    .img-wrap {
      width: 40px;
      height: 40px;
      position: relative;
      margin-right: 9px;

      .img-cover {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background-color: #ccc;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .status {
        background: #1cec9e;
        border: 2px solid #ffffff;
        border-radius: 4px;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 100%;
      }
    }

    .item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .item-title {
        font-family: "HelveticaNeueBold";
        font-size: 13px;
        line-height: 16px;
        color: #D1433A;
        opacity: 0.9;
        margin-bottom: 5px;
      }

      .item-subtitle {
        font-family: "HelveticaNeueNormal";
        font-size: 11px;
        line-height: 13px;
        color: #718195;
        letter-spacing: 0.5px;
      }
    }
  }

  .show-all {
    margin-top: 5px;
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: #D1433A;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transition: all 0.3s;
      opacity: 0.8;
    }
  }
}

.custom-collapse {
  border-bottom: 1px solid #e6eaf0;

  &.border-none {
    border-bottom: none !important;
  }

  .btn {
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-color: #e6eaf0;
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    text-align: left;
    padding: 15px 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L6.321 5.76L1.23 1' stroke='%2347505B' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 12px;
      height: 7px;
      z-index: 1;
      top: 50%;
      transform: translatey(-50%);
      right: 0;
    }

    &.not-collapsed {
      &:after {
        transform: rotate(180deg);
        margin-top: -4px;
      }
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: transparent !important;
      color: #071526 !important;
    }
  }

  .card {
    border: none;

    .card-body {
      padding: 0;

      .privacy-support {
        margin: 0;
        padding: 0;
        list-style: none;
        margin: 0 -20px;

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          transition: all 0.3s;
          background-color: #fff;
          cursor: pointer;
          padding: 0 20px;
          font-family: "HelveticaNeueNormal";
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #071526;

          &:hover {
            transition: all 0.3s;
            background-color: #D1433A1A;

            &:hover {
              .svg-icon {
                .c-line-1 {
                  stroke: #D1433A;
                  transition: all 0.3s;
                }
              }
            }
          }

          .svg-icon {
            margin-right: 9px;

            .c-line-1 {
              stroke: #071526;
              transition: all 0.3s;
            }
          }
        }
      }

      .offer-info {
        margin: -3px 0 0 0;
        padding: 0;
        list-style: none;

        li {
          font-family: "HelveticaNeueBold";
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: #47505b;
          margin-bottom: 14px;

          span {
            font-family: "HelveticaNeueNormal";
            margin-left: 4px;
          }
        }
      }
    }

    .media-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: "HelveticaNeueNormal";
      font-weight: normal;
      letter-spacing: 0.5px;
      font-size: 13px;
      line-height: 20px;
      padding: 15px 0;
      border-top: 1px solid #e6eaf0;

      span {
        font-family: "HelveticaNeueBold";
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8b949f;
      }
    }

    .media-items-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px 20px 0;

      .img-wrap {
        position: relative;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        overflow: hidden;
        background-color: #ccc;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        &.with-more {
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            font-family: "HelveticaNeueBold";
            font-weight: normal;
            font-size: 18px;
            line-height: 20px;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

.offer-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #e6eaf0;

  .btn-offer {
    width: 130px;
    height: 30px;
    background-color: #f5f7f9;
    border-radius: 2px;
    font-family: "HelveticaNeueMedium";
    font-weight: normal;
    font-size: 12px;
    color: #D1433A;
    transition: all 0.3s;

    &:hover {
      background-color: #ecf0ff;
      transition: all 0.3s;
    }
  }

  .btn-dropdown {
    width: 30px;
    height: 30px;
    background-color: #f5f7f9;
    border-radius: 2px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #ecf0ff;
      transition: all 0.3s;
    }
  }
}

.body {
  &.empty-body-with-search-icon {
    .empty-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 770px;
      height: 100%;
      background: #f5f7f9;
      border-radius: 5px;

      .search-text {
        font-family: "HelveticaNeueBold";
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #071526;
        margin-top: 24px;
      }
    }
  }

  &.empty-body {
    .empty-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 770px;
      height: 100%;
    }

    .search-text {
      font-family: "HelveticaNeueNormal";
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #8b949f;
      letter-spacing: 0.5px;
    }
  }
}
</style>
