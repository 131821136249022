<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5 16.8H13.5V1.8C13.5 0.80625 12.7441 0 11.8125 0H6.1875C5.25586 0 4.5 0.80625 4.5 1.8V16.8ZM6.75 2.4H11.25V3.6H6.75V2.4ZM18 5.4V15C18 15.9937 17.2441 16.8 16.3125 16.8H14.625V3.6H16.3125C17.2441 3.6 18 4.40625 18 5.4ZM3.375 16.8H1.6875C0.755859 16.8 0 15.9937 0 15V5.4C0 4.40625 0.755859 3.6 1.6875 3.6H3.375V16.8Z" fill="#47505B"/>
                </svg>

                Work & Education
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <div class="artist-setting-panel">
                  <div class="setting-content-inner">
                    <div class="work-experience-section">
                      <div v-if="message" class="alert alert-danger" role="alert">
                        {{ message }}
                      </div>
                      <div v-if="success_message" class="alert alert-success" role="alert">
                        {{ success_message }}
                      </div>
                      <div class="work-heading-block">
                        <div class="work-experience">Work Experience</div>
                        <div class="work-add-more">
                          <button v-if="work_list && work_list.length" v-on:click="addWork()">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="add-btn-active"
                                    d="M7.00008 12.8332C10.2217 12.8332 12.8334 10.2215 12.8334 6.99984C12.8334 3.77818 10.2217 1.1665 7.00008 1.1665C3.77842 1.1665 1.16675 3.77818 1.16675 6.99984C1.16675 10.2215 3.77842 12.8332 7.00008 12.8332Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path class="add-btn-active" d="M7 4.6665V9.33317" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                              <path class="add-btn-active" d="M4.66675 7H9.33342" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            ADD MORE
                          </button>
                        </div>
                      </div>
                      <ul class="add-work-experience">
                        <li class="empty-exp" v-if="work_list && !work_list.length">
                          <div class="empty-experience">
                            <h1>You haven’t added any Work Experience yet</h1>
                            <p>Use button «Add Work Experience» to fill out this information</p>
                            <div class=" ad-work-exp-btn">
                              <button v-on:click="addWork()" class="font-weight-normal">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                                      stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                ADD WORK EXPERIENCE
                              </button>
                            </div>
                          </div>
                        </li>

                        <li v-for="(eachOp, index) in work_list" :key="index">
                          <div class="experiance-detail-section">
                            <div class="experiance-detail-section-heading">
                              <h1>{{ eachOp.title }} /{{ eachOp.company_name }}</h1>
                              <p>{{ new Date(0, eachOp.from_month + 1, 0).toLocaleString('en-us', {month: 'long'}) }}
                                {{ eachOp.from_year }} -
                                {{ eachOp.to_month ? new Date(0, eachOp.to_month + 1, 0).toLocaleString('en-us', {month: 'long'}) : '' }}
                                {{ eachOp.to_year }}</p>
                            </div>
                            <div class="edit-delete" >
                              <button @click="editWork(eachOp)">
                                <!-- <img src="img/edit-icon.png"> -->
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </button>
                              <button type="button" @click="deleteWorkAndEducation(eachOp.id,'work')">
                                <svg width="18"  height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.5 4.5L4.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M4.5 4.5L13.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <p class="exp-des">
                            {{ eachOp.description }}
                          </p>
                        </li>
                        <ul>
                        </ul>
                      </ul>
                    </div>
                    <div class="work-experience-section">
                      <div class="work-heading-block">
                        <div class="work-experience">Education</div>
                        <div class="work-add-more">
                          <button v-if="education_list && education_list.length" v-on:click="addEducation()">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path class="add-btn-active"
                                    d="M7.00008 12.8332C10.2217 12.8332 12.8334 10.2215 12.8334 6.99984C12.8334 3.77818 10.2217 1.1665 7.00008 1.1665C3.77842 1.1665 1.16675 3.77818 1.16675 6.99984C1.16675 10.2215 3.77842 12.8332 7.00008 12.8332Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path class="add-btn-active" d="M7 4.6665V9.33317" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                              <path class="add-btn-active" d="M4.66675 7H9.33342" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            ADD MORE
                          </button>
                        </div>
                      </div>
                      <ul class="add-work-experience">
                        <li class="empty-exp" v-if="education_list && !education_list.length">
                          <div class="empty-experience">
                            <h1>You haven’t added any Education Information yet</h1>
                            <p>Use button «Add Education» to fill out this information</p>
                            <div class=" ad-work-exp-btn">
                              <button v-on:click="addEducation()" class="font-weight-normal">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                                      stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M7 4.6665V9.33317" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M4.66663 7H9.33329" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                                ADD EDUCATION
                              </button>
                            </div>
                          </div>
                        </li>

                        <li v-for="(eachOp, index) in education_list" :key="index">
                          <div class="experiance-detail-section">
                            <div class="experiance-detail-section-heading">
                              <h1 v-if="eachOp.school_name"> {{ eachOp.school_name }}</h1>
                              <h1 v-else>{{ eachOp.title }}</h1>
                              <p v-if="eachOp.degree_name">{{ eachOp.degree_name.name }} / {{ eachOp.from_year }}-{{ eachOp.to_year }}</p>
                              <p v-if="eachOp.degree">{{ eachOp.degree.name }} / {{ eachOp.from }}-{{ eachOp.to }}</p>
                            </div>
                            <div class="edit-delete" >
                              <button @click="editEducation(eachOp)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </button>
                              <button type="button" @click="deleteWorkAndEducation(eachOp.id,'education')">
                                <svg width="18"  height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.5 4.5L4.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M4.5 4.5L13.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <p class="exp-des">
                            {{ eachOp.description }}
                          </p>
                        </li>
                        <ul>
                        </ul>
                      </ul>

                    </div>

                  </div>
                  <div class="d-flex justify-content-end">
                    <button class="prime_button" type="button">Save Changes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddWork v-if="workModal" @click="closeWindow" :item="selected_work_education_list_item" :pageID="current_page.id"/>
    <AddEducation v-if="educationModal" @click="closeWindow" :item="selected_education_list_item" :pageID="current_page.id"/>
    <DeletePageModal v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import AddWork from '@/components/Home/settings/AddWork'
import AddEducation from '@/components/Home/settings/AddEducation'
import WorkEducationDeleteBtn from '@/components/Home/settings/Components/WorkEducationDeleteBtn'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import DeletePageModal from '../DeletePageModal'
import {required, requiredIf} from "vuelidate/lib/validators";
import { deleteEducationOnArtistPage, deleteExperienceOnArtistPage } from "../../../../apis/APIs";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue'

export default {
  data() {
    return {
      deletePage: false,
      success_message:null,
      message:null,
      educationModal: false,
      workModal: false,
      categories: [],
      subCategories: [],
      specifications: [],
      profileAndCoverModel: false,
      profileAndCoverModelType: '',
      selected_education_list_item: undefined,
      selected_work_education_list_item: undefined,
      loading: false

    }
  },
  components: {
    EyeIcon,
    // WorkEducationDeleteBtn,
    AddWork,
    AddEducation,
    // MusicSubHeader,
    DeletePageModal
  },
  computed: {
    pageType(){
      return this.current_page.type
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    education_list(){
     return (this.current_page)? this.current_page.education:[]
    } ,
    work_list(){
     return (this.current_page)?this.current_page.experience:[]
    }
  },
  validations: {
    createNewPageForm: {
      pageName: {
        required
      },
      category: {
        required
      },
      pageAddress: {
        required,
      },
      about: {
        required,
      }
    }
  },
  watch: {

  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.createNewPageForm[name];
      return $dirty ? !$error : null;
    },
    addEducation: function () {
      this.educationModal = !this.educationModal;
      this.workModal = false;

    },
    addWork: function () {
      this.workModal = !this.workModal;
      this.educationModal = false;
    },
    closeWindow: function () {
      this.educationModal = false;
      this.workModal = false;

      this.selected_education_list_item = undefined;
      this.selected_work_education_list_item = undefined;
    },
    editEducation(item) {
      this.selected_education_list_item = item;
      this.addEducation();
    },
    editWork(item) {
      this.selected_work_education_list_item = item;
      this.addWork();
    },
    async deleteWorkAndEducation(id,type) {
      try {
        await this.$store.dispatch('loading', true)
        const {data}  =(type==='work')? await deleteExperienceOnArtistPage(id):await deleteEducationOnArtistPage(id);
        const {success, message} = data || {}
        await this.$store.dispatch('loading', false)
        if (success === 1) {
          const page = data.information || {};
          this.$store.commit('SET_PAGE_DATA', page)
          await this.makeToast('success', 'Page!', message)
        } else {
          await this.makeToast('error', 'Page!', message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.makeToast('error', 'Page!', e.message)
      }
    },
  }
}
</script>
<style>
.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}
</style>
<style scoped lang="scss">
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
}
</style>
