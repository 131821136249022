<template>
  <div class="Signup_pop_overlay">
    <div class="wr_delete_modal">
      <div class="item-title">Delete Thread</div>
      <div class="text">
        Are you sure you want to
        <b
          >delete<br />
          this thread?</b
        >
      </div>
      <div class="wr_btn">
        <div @click="closeModal('showDelete')" class="cancel">CANCEL</div>
        <div @click="deleteThis" class="delete">DELETE</div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    ...mapActions([
      "deleteReply",
      "getMyReplies",
      "deleteThread",
      "getMyThread",
      "getStatisticsForums",
    ]),
    closeModal(showType) {
      this.$parent.showModals(showType);
    },
    async deleteThis() {
      if (this.GET_DELETE_TYPE === "reply") {
        await this.deleteReply(this.GET_REPLY_ID);
        await this.getMyReplies({ offset: 0 });
      } else if (this.GET_DELETE_TYPE === "thread") {
        await this.deleteThread(this.GET_THREAD_ID);
        await this.getMyThread({ offset: 0 });
        this.$parent.changePage("my_thread");
      }
      await this.getStatisticsForums();
      this.closeModal("showDelete");
    },
  },
  computed: {
    ...mapGetters(["GET_REPLY_ID", "GET_THREAD_ID", "GET_DELETE_TYPE"]),
  },
};
</script>
<style lang='scss' scoped>
.Signup_pop_overlay {
  font-family: HelveticaNeueNormal;
 .wr_delete_modal {
    background: #ffffff;
    width: 370px;
    padding: 22px 20px 29px;
    border-radius: 2px;
    .item-title {
      font-family: HelveticaNeueBold;
      color: #071526;
      padding-bottom: 17px;
      border-bottom: 1px solid #e6eaf0;
      font-weight: 700;
      font-size: 18px;
    }
    .text {
      text-align: center;
      font-weight: 400;
      margin: 25px 0;
      font-size: 15px;
      line-height: 20px;
      b {
        font-family: HelveticaNeueBold;
      }
    }
    .wr_btn {
      display: flex;
      justify-content: space-between;
      .delete,
      .cancel {
        width: 160px;
        height: 40px;
        font-family: HelveticaNeueBold;
        font-weight: 700;
        font-size: 13px;
        color: #ffffff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .cancel {
        color: #8b949f;
        border: 1.3px solid #8b949f;
      }
      .delete {
        background: #ff0039;
      }
    }
  }
}
</style>