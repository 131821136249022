<template>
  <div class="post_comment_box">
    <div class="feeb_sec_main">
      <div class="feeb_sec_main_img">
        <img v-if="user_picture" :src="user_picture">
        <img v-if="!user_picture" :src="require(`@/assets/img/user-placeholder.png`)">
        <span class="online-signal"></span>
      </div>
      <div class="feeb_sec_main_detail">
        <div class="feeb_sec_main_person">
          <h1 class="d-flex">{{
              name
            }}
            <color class="post-activity">Replying to</color>
          </h1>
          <span class="f_dot"></span>
          <span class="f_hrs">{{
              time
            }}</span>
          <span class="f_globe">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                                stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 7H13" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path
                                d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                                stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
          <div class="comment-post-dot-dropdown" @click="toggle_drop_down()">
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path class="thr-dot-svg"
                                      d="M9.16634 9.99935C9.16634 10.4596 9.53944 10.8327 9.99967 10.8327C10.4599 10.8327 10.833 10.4596 10.833 9.99935C10.833 9.53911 10.4599 9.16602 9.99967 9.16602C9.53944 9.16602 9.16634 9.53911 9.16634 9.99935Z"
                                      fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path class="thr-dot-svg"
                                      d="M15.0003 9.99935C15.0003 10.4596 15.3734 10.8327 15.8337 10.8327C16.2939 10.8327 16.667 10.4596 16.667 9.99935C16.667 9.53911 16.2939 9.16602 15.8337 9.16602C15.3734 9.16602 15.0003 9.53911 15.0003 9.99935Z"
                                      fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path class="thr-dot-svg"
                                      d="M3.33333 9.99935C3.33333 10.4596 3.70643 10.8327 4.16667 10.8327C4.6269 10.8327 5 10.4596 5 9.99935C5 9.53911 4.6269 9.16602 4.16667 9.16602C3.70643 9.16602 3.33333 9.53911 3.33333 9.99935Z"
                                      fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                        </span>
            <div class="my-post-contanier" v-if="is_drop_down_open">
              <div class="my-post-contaniermain">

                <div class="regular-links">
                  <ul>
                    <li @click="toggle_edit">
                      <a href="javascript:;">
                                                <span>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path class="regular-link-svg"
                                                              d="M8.5 4H4.22222C3.89807 4 3.58719 4.12877 3.35798 4.35798C3.12877 4.58719 3 4.89807 3 5.22222V13.7778C3 14.1019 3.12877 14.4128 3.35798 14.642C3.58719 14.8712 3.89807 15 4.22222 15H12.7778C13.1019 15 13.4128 14.8712 13.642 14.642C13.8712 14.4128 14 14.1019 14 13.7778V9.5"
                                                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                                              stroke-linejoin="round"></path>
                                                        <path class="regular-link-svg"
                                                              d="M12.9485 3.35199C13.1738 3.12661 13.4795 3 13.7982 3C14.117 3 14.4226 3.12661 14.648 3.35199C14.8734 3.57737 15 3.88304 15 4.20177C15 4.5205 14.8734 4.82617 14.648 5.05155L9.26608 10.4335L7 11L7.56652 8.73392L12.9485 3.35199Z"
                                                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                                              stroke-linejoin="round"></path>
                                                    </svg>
                                                </span>
                        Edit Comment
                      </a>
                    </li>
                    <li @click="deleteSubmit">
                      <a href="javascript:;">
                                                <span>
                                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path class="regular-link-svg1"
                                                              d="M9.94 2.26H10.08C10.003 2.26 9.94 2.197 9.94 2.12V2.26H4.62V2.12C4.62 2.197 4.557 2.26 4.48 2.26H4.62V3.52H3.36V2.12C3.36 1.50225 3.86225 1 4.48 1H10.08C10.6978 1 11.2 1.50225 11.2 2.12V3.52H9.94V2.26ZM1.12 3.52H13.44C13.7498 3.52 14 3.77025 14 4.08V4.64C14 4.717 13.937 4.78 13.86 4.78H12.803L12.3707 13.9325C12.3427 14.5293 11.8493 15 11.2525 15H3.3075C2.709 15 2.21725 14.531 2.18925 13.9325L1.757 4.78H0.7C0.623 4.78 0.559999 4.717 0.559999 4.64V4.08C0.559999 3.77025 0.81025 3.52 1.12 3.52ZM3.44225 13.74H11.1177L11.5412 4.78H3.01875L3.44225 13.74Z"
                                                              fill="#47505B"></path>
                                                        <path class="regular-link-svg1"
                                                              d="M9.94 2.26H10.08C10.003 2.26 9.94 2.197 9.94 2.12V2.26ZM9.94 2.26H4.62V2.12C4.62 2.197 4.557 2.26 4.48 2.26H4.62V3.52H3.36V2.12C3.36 1.50225 3.86225 1 4.48 1H10.08C10.6977 1 11.2 1.50225 11.2 2.12V3.52H9.94V2.26ZM1.12 3.52H13.44C13.7498 3.52 14 3.77025 14 4.08V4.64C14 4.717 13.937 4.78 13.86 4.78H12.803L12.3707 13.9325C12.3427 14.5293 11.8492 15 11.2525 15H3.3075C2.709 15 2.21725 14.531 2.18925 13.9325L1.757 4.78H0.7C0.623 4.78 0.56 4.717 0.56 4.64V4.08C0.56 3.77025 0.81025 3.52 1.12 3.52ZM3.44225 13.74H11.1177L11.5412 4.78H3.01875L3.44225 13.74Z"
                                                              stroke="#47505B" stroke-width="0.1"></path>
                                                    </svg>
                                                </span>
                        Delete Comment
                      </a>
                    </li>
                    <span class="seprator-line"></span>
                  </ul>
                </div>
              </div>
            </div>
            <div class="other-post-people" style="display:none;">
              <ul>
                <li>
                  <a href="javascript:;">
                    <div class="other-post-icon">
                      <svg width="12" height="14" viewBox="0 0 12 14" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1H1V13L6 9.85L11 13V1Z" stroke="#47505B" stroke-width="1.5"
                              stroke-linejoin="round"></path>
                      </svg>
                    </div>
                    <div class="other-post-icon-title">
                      <h1>Save post</h1>
                    </div>
                  </a>
                </li>
                <span class="other-post-seprator"></span>
                <li>
                  <a href="javascript:;">
                    <div class="other-post-icon-svg">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.00065 14.6666C11.6825 14.6666 14.6673 11.6819 14.6673 7.99998C14.6673 4.31808 11.6825 1.33331 8.00065 1.33331C4.31875 1.33331 1.33398 4.31808 1.33398 7.99998C1.33398 11.6819 4.31875 14.6666 8.00065 14.6666Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                        <path d="M10 6L6 10" stroke="#47505B" stroke-width="1.5"
                              stroke-linejoin="round"></path>
                        <path d="M6 6L10 10" stroke="#47505B" stroke-width="1.5"
                              stroke-linejoin="round"></path>
                      </svg>

                    </div>
                    <div class="other-post-icon-title">
                      <h1>Unfollow user</h1>
                      <p>Stop seeing posts but stay friends</p>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="javascript:;">
                    <div class="other-post-icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path class="regular-link-svg"
                              d="M6.59935 2.82667C7.05824 2.71926 7.52806 2.66557 7.99935 2.66667C12.666 2.66667 15.3327 8.00001 15.3327 8.00001C14.928 8.75708 14.4454 9.46983 13.8927 10.1267M9.41268 9.41334C9.22958 9.60984 9.00878 9.76744 8.76345 9.87676C8.51812 9.98607 8.25328 10.0448 7.98474 10.0496C7.7162 10.0543 7.44946 10.0049 7.20042 9.90433C6.95139 9.80374 6.72516 9.65403 6.53525 9.46411C6.34533 9.27419 6.19561 9.04797 6.09502 8.79893C5.99443 8.5499 5.94503 8.28315 5.94977 8.01461C5.95451 7.74607 6.01329 7.48124 6.1226 7.23591C6.23191 6.99057 6.38952 6.76977 6.58602 6.58667M11.9593 11.96C10.8197 12.8287 9.43209 13.3099 7.99935 13.3333C3.33268 13.3333 0.666016 8.00001 0.666016 8.00001C1.49528 6.4546 2.64544 5.10441 4.03935 4.04001L11.9593 11.96Z"
                              stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                        <path class="regular-link-svg" d="M0.666016 0.666656L15.3327 15.3333"
                              stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                      </svg>
                    </div>
                    <div class="other-post-icon-title">
                      <h1>Hide this post</h1>
                      <p>See fewer posts like this</p>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="javascript:;">
                    <div class="other-post-icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.66406 14.6667V10" stroke="#47505B" stroke-width="1.5"
                              stroke-linejoin="round"></path>
                        <path
                            d="M2.66406 10.0006C2.66406 10.0006 3.33073 9.33395 5.33073 9.33395C7.33073 9.33395 8.66406 10.6673 10.6641 10.6673C12.6641 10.6673 13.3307 10.0006 13.3307 10.0006V2.00062C13.3307 2.00062 12.6641 2.66729 10.6641 2.66729C8.66406 2.66729 7.33073 1.33395 5.33073 1.33395C3.33073 1.33395 2.66406 2.00062 2.66406 2.00062V10.0006Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                      </svg>
                    </div>
                    <div class="other-post-icon-title">
                      <h1>Report post</h1>

                    </div>
                  </a>
                </li>
                <span class="other-post-seprator"></span>
                <li>
                  <a href="javascript:;">
                    <div class="other-post-icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.66602 8.66666C6.95232 9.04942 7.31759 9.36612 7.73705 9.59529C8.15651 9.82446 8.62035 9.96074 9.09712 9.99489C9.57388 10.029 10.0524 9.96024 10.5002 9.79318C10.9481 9.62613 11.3548 9.36471 11.6927 9.02666L13.6927 7.02666C14.2999 6.39799 14.6359 5.55598 14.6283 4.68199C14.6207 3.808 14.2701 2.97196 13.6521 2.35394C13.0341 1.73591 12.198 1.38535 11.324 1.37775C10.45 1.37016 9.60802 1.70614 8.97935 2.31333L7.83268 3.45333"
                            stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                        <path
                            d="M9.33347 7.33335C9.04716 6.9506 8.68189 6.6339 8.26243 6.40472C7.84297 6.17555 7.37913 6.03927 6.90237 6.00513C6.4256 5.97098 5.94707 6.03977 5.49924 6.20683C5.0514 6.37389 4.64472 6.6353 4.3068 6.97335L2.3068 8.97335C1.69961 9.60203 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.0281 2.3474 13.6461C2.96543 14.2641 3.80147 14.6147 4.67546 14.6223C5.54945 14.6299 6.39146 14.2939 7.02013 13.6867L8.16013 12.5467"
                            stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                      </svg>
                    </div>
                    <div class="other-post-icon-title">
                      <h1>Open post in new tab</h1>

                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="person-reply">
          <!-- <p>@TheWeeknd and @BRITs</p> -->
        </div>
        <div class="per_comment" v-if="!is_edit">{{
            item.content
          }}
        </div>
        <input v-if="is_edit" v-model="comment" placeholder="Comment" v-on:keyup="checkEnter"/>
        <p v-if="is_edit" @click="toggle_edit">Cancel</p>

        <div class="artist-timeline-activity m-t-10">
          <div class="activity-main">
            <!-- <div class="artist-timeline-action">
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            d="M15.762 3.28005C15.3695 2.85662 14.9036 2.52073 14.3909 2.29156C13.8781 2.0624 13.3285 1.94444 12.7735 1.94444C12.2184 1.94444 11.6688 2.0624 11.1561 2.29156C10.6433 2.52073 10.1774 2.85662 9.78493 3.28005L8.96999 4.15923L8.15505 3.28005C7.35707 2.45347 6.29053 1.99729 5.18432 2.00942C4.07811 2.02155 3.02039 2.50101 2.23814 3.34492C1.45589 4.18883 1.01146 5.32992 1.00022 6.52333C0.988977 7.71673 1.41182 8.86733 2.17801 9.72822L2.99295 10.6074L8.96999 17.0556L14.947 10.6074L15.762 9.72822C16.1545 9.30485 16.4658 8.80222 16.6782 8.24902C16.8907 7.69583 17 7.10292 17 6.50414C17 5.90535 16.8907 5.31244 16.6782 4.75925C16.4658 4.20605 16.1545 3.70342 15.762 3.28005V3.28005Z"
            stroke="#47505B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="artist-timeline-action-svg"
            ></path>
        </svg>
        000
    </div> -->
            <CommentLike :item="item" @reactOrUnReact="reactOrUnReact"/>
            <div class="artist-timeline-action" @click="toggleReplySection($event)">
              <svg width="18" height="17" viewBox="0 0 18 17" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17 11.4934C16.9688 11.9892 16.7645 12.451 16.4316 12.7785C16.0987 13.1059 15.6642 13.2725 15.2225 13.242H4.57235L1 16V2.75212C1.03125 2.25636 1.23553 1.79451 1.56841 1.46705C1.90128 1.1396 2.33582 0.973021 2.77748 1.00357H15.2158C15.6575 0.973021 16.092 1.1396 16.4249 1.46705C16.7578 1.79451 16.9621 2.25636 16.9933 2.75212L17 11.4934Z"
                    stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="artist-timeline-action-svg"></path>
              </svg>
              {{
                replies_count
              }}
            </div>
<!--            <div class="artist-timeline-action">-->
<!--              <svg width="18" height="18" viewBox="0 0 18 18" fill="none"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M1 10.1429L1 3.61194L17 3.61194L12.4273 1" stroke="#47505B" stroke-width="2"-->
<!--                      stroke-linecap="round" stroke-linejoin="round" class="artist-timeline-action-svg">-->
<!--                </path>-->
<!--                <path d="M17 9L17 14.6627L1 14.6627L6.5015 17" stroke="#47505B" stroke-width="2"-->
<!--                      stroke-linecap="round" stroke-linejoin="round" class="artist-timeline-action-svg">-->
<!--                </path>-->
<!--              </svg>-->
<!--              000-->
<!--            </div>-->
<!--            <div class="artist-timeline-action">-->
<!--              <svg width="18" height="17" viewBox="0 0 18 17" fill="none"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                    d="M1 13.2527C1 13.2527 2.26951 6.54652 9.88122 6.33177C9.99792 4.55679 9.99792 2.77498 9.88122 1L17 8.32527L9.88122 16V10.3721C9.88122 10.3721 5.70755 9.01246 1 13.2527Z"-->
<!--                    stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
<!--                    class="artist-timeline-action-svg"></path>-->
<!--              </svg>-->
<!--              000-->
<!--            </div>-->
          </div>

        </div>
      </div>
    </div>
    <!-- Reply List End -->
    <ReplyItem v-for="(eachOp, index) in replyList" :key="'reply_item'+index" :reply_count="replyList.length || 0"
               :item="eachOp" :post_id="id" @reactOrUnReact="reactOrUnReactOnReply(eachOp)"/>
    <div class="row " v-if="comment_list_loading">
      <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
    <div class="show_more_btn w-25 mx-auto" v-if="repliesCount>replyList.length ">
      <div class="show-more musicbar_more"><a @click="getRepliesOfComment(true)" href="javascript:;"><span class="show_more_bg"><svg
          width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path
          d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
          stroke-linejoin="round"></path> <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"></path></svg>
                           SHOW MORE
                        </span></a></div>
    </div>
    <!-- Reply Input Start -->
    <ReplyInput v-if="write_reply" :item="item" @replyOfCommentAdded="replyAdded"/>
    <!-- Reply Input End -->

  </div>

</template>
<script>
import {
  addCommentsOfPost,
  editComment,
  getCommentsOfPost,
  getReplyOfComment,
  post_comment_api
} from '../../../../apis/APIs';
import {mapGetters} from 'vuex';
import moment from 'moment';
import ReplyItem from './ReplyItem';
import CommentLike from './CommentLike';
import ReplyInput from './ReplyInput';
import color from '../components/color';
import Vue from "vue";

export default {
  data() {
    return ({
      is_edit: false,
      comment: "",
      comment_loading: false,
      write_reply: false,
      is_drop_down_open: false,
      comment_list_loading: false,
      replyList: [],
      repliesCount:0,
      repliesOffset:0,
      repliesLimit:3,
      message: "",
      success_message: ""
    })
  },
  mounted() {
    this.init();
    this.getRepliesOfComment();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const {node_id = ""} = this.item || {};

      return node_id;
    },
    comment_id() {
      const {comment_id = ""} = this.item || {};

      return comment_id;
    },
    name() {
      const {user} = this.item || {};
      const {name, last_name} = user
      return `${name} ${last_name}`
    },
    user_picture() {
      const {user} = this.item || {};
      return (user.artwork_url) ? user.artwork_url : null
    },
    time() {
      const {updated_at} = this.item || {};
      return moment(updated_at).fromNow();
    },
    replies_count() {
      if (this.repliesCount > this.item.reply_count) {
        return this.repliesCount;
      } else {
        return this.item.reply_count
      }
    }
  },
  components: {
    color,
    ReplyInput,
    CommentLike,
    ReplyItem
  },
  props: ["item"],
  methods: {
    toggleReplySection(e) {
      if (e) e.stopPropagation();

      this.write_reply = !this.write_reply;
    },
    openReplySection() {
      this.write_reply = true;
    },
    init() {
    },
    toggle_drop_down() {
      this.is_drop_down_open = !this.is_drop_down_open;
    },
    refresh_comment_list() {
      this.$parent.init();
    },
    async getRepliesOfComment(showMore = false) {
        try {
          if(showMore){
            this.repliesOffset=Number(this.repliesOffset)+Number(this.repliesLimit)
          }
          this.comment_list_loading = true;
          const formData = new FormData();
          formData.append('parent_id', this.item.id);
          const {data} = await getReplyOfComment(formData,this.repliesOffset,this.repliesLimit);
          if(showMore){
            this.replyList =  [...this.replyList, ...data.data]
          }
          else{
            this.replyList = (data.data) ? data.data : []
          }
          this.repliesCount = (data.total) ? data.total : []
          this.comment_list_loading = false;
        } catch (error) {
          console.error(error)
          this.comment_list_loading = false;
        }
    },
    toggle_edit() {
      this.is_edit = !this.is_edit
    },
    async submit() {

    },
    checkEnter(event) {
      if (event.keyCode === 13) {
        this.submit();
      }
    },
    reset_data() {
      this.is_edit = false;
      this.comment_loading = false;
    },
    async deleteSubmit() {

      const {comment_loading, comment, item} = this;
      const {comment_id} = item || {};

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      try {

        var formdata = new FormData();
        formdata.append("page_action", "3");
        formdata.append("comment_id", comment_id);
        formdata.append("node_id", this.id);
        formdata.append("node_type", "post");
        formdata.append("user_id", user_id);
        formdata.append("time", moment(new Date()).format("YYYY-MM-dd hh:mm:ss"))

        const {success, message} = await editComment(formdata);

        if (success === 1) {
          this.$parent.init();
          this.init_reply_list();
          setTimeout(() => this.success_message = "", 5000);
        } else {
        }

      } catch (error) {
      }
    },
    reactOrUnReact(){
      this.$emit('reactOrUnReact')
    },
    replyAdded(value){
      this.replyList.push(value)
      this.repliesCount = this.replyList.length
    },
    async reactOrUnReactOnReply(reply){
      const temp = reply
      let obj = {
        reaction_able_id: reply.id,
        reaction_able_type: 'Comment',
        reaction_type: 'like'
      }
      if(reply.reacted)
      {
        Vue.set(reply, 'reacted',  null)
        const index = reply.reactions.findIndex(e => e.type = obj.reaction_type)
        if (index !== -1) {
          reply.reactions[index].count = --reply.reactions[index].count
        }
      }
      else
      {
        Vue.set(reply, 'reacted', obj)
        const index = reply.reactions.findIndex(e => e.type = obj.reaction_type)
        if (index !== -1) {
          reply.reactions[index].count = ++reply.reactions[index].count
        }else{
          reply.reactions.push({
            type:obj.reaction_type,
            count:1
          })
        }
      }

      let {success} = await this.$store.dispatch('reactOrUnReact', obj)
      if (!(Boolean(success))) {
        Vue.set(reply, 'reacted', (reply.reacted) ? null : obj)
        reply =temp
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
