<template>
  <div class="px-0">
    <LeftNav v-if="ShowLeftNav" category="navigation" type="home" class="left-bar-placement" />
    <div class="app-container" :class="[{ 'right-nav-space': rightBar }, custom_css_class]">
      <!--    <LeftNav v-if="ShowLeftNav"/>-->
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>
<script>
import LeftNav from "@/components/DesignGuide/Layout/LeftNav.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      form_data: "main_container_full_class_map_to_props",
      rightBar: "getRightBarValue",
    }),
    custom_css_class() {
      const { main_container_full_classes = "" } = this.form_data;

      return main_container_full_classes;
    },
    ShowLeftNav() {
      if (this.$route.meta.showLeftNav === false) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    LeftNav,
  },
  beforeMount() {
    this.showCon();
  },
  methods: {
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
  },
};
</script>
<style scoped lang="scss">
.app-container {
  // padding-right: 18% !important;
  // padding-left: 18% !important;

  padding-right: 18.5% !important;
  margin-left: 230px;
  padding-left: unset !important;
  // padding-left: 120px !important;
}

.right-nav-space {
  padding-right: 280px;
}

@media (max-width: 1919px) {
  .app-container {
    padding-right: 21% !important;
    padding-left: 4% !important;
  }
  
}

@media (min-width: 1930px) {
  .app-container {
    margin-left: unset;
    padding-right: unset !important;
    padding-left: unset !important;
  }
}
</style>