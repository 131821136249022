import {
  getGetStreamAccessToken,
  getGetStreamMessagingAccessToken,
  getListenedSongsForPost,

} from "../apis/APIs";

import {
  ACTIVITY_NOTIFICATIONS,
  CHAT_CLIENT,
  FRIENDS_REQUEST_NOTIFICATIONS,
  GET_STREAM_ACCESS_TOKEN,
  GET_STREAM_FRIENDS,
  GET_STREAM_MESSAGING_ACCESS_TOKEN,
  LAST_MESSAGES,
  LAST_MESSAGES_GROUPS,
  LISTENED_SONGS,
  My_Friends_List,
  NEW_NOTIFICATIONS,
  NOTIFICATIONS,
  OLD_NOTIFICATIONS,
  ONLINE_USERS,
  OTHER_NOTIFICATIONS,
  SUBSCRIPTION,
  TOTAL_READ,
  TOTAL_SEEN,
  UNREAD_MESSAGES_COUNT,
  USER,
  ALL_ONLINE_USERS,
  SEARCHED_MESSAGE_LIST,
  SET_PAGE_ROOMS,
  LAST_MESSAGES_CLUBROOM,
  LAST_MESSAGES_PARTYROOM,
  ADD_MEMBER_CHECK,
  SET_PAGE_AUDIO_VIDEO_ROOMS,
  SET_PAGE_TEXT_ROOMS,
  USER_KEY,
  PAGES_TO_MESSAGES,
  PAGES_TO_MESSAGES_NAME,
  PAGES_TO_MESSAGES_CHECK, PAGES_TO_MESSAGES_TYPE, PAGES_TO_MESSAGES_ID, PAGES_TO_MESSAGES_OBJECT, NOTIFICATION_CLIENT
} from "./types";

const INITIAL_STATE = {
  [GET_STREAM_ACCESS_TOKEN]: "",
  [SUBSCRIPTION]: {},
  [NOTIFICATIONS]: [],
  [FRIENDS_REQUEST_NOTIFICATIONS]: [],
  [OTHER_NOTIFICATIONS]: [],
  [NEW_NOTIFICATIONS]: [],
  [OLD_NOTIFICATIONS]: [],
  [ACTIVITY_NOTIFICATIONS]: [],
  [LISTENED_SONGS]: [],
  [NOTIFICATION_CLIENT]: null,
  [USER]: {},
  [TOTAL_READ]: null,
  [TOTAL_SEEN]: null,
  [CHAT_CLIENT]: {},
  [GET_STREAM_MESSAGING_ACCESS_TOKEN]: "",
  [ONLINE_USERS]: [],
  [LAST_MESSAGES]: [],
  [GET_STREAM_FRIENDS]: [],
  [LAST_MESSAGES_GROUPS]: [],
  [LAST_MESSAGES_CLUBROOM]: [],
  [LAST_MESSAGES_PARTYROOM]: [],
  [SET_PAGE_ROOMS]: [],
  [SET_PAGE_TEXT_ROOMS]: [],
  [SET_PAGE_AUDIO_VIDEO_ROOMS]: [],
  [UNREAD_MESSAGES_COUNT]: null,
  [ALL_ONLINE_USERS]: [],
  [SEARCHED_MESSAGE_LIST]: [],
  [ADD_MEMBER_CHECK]: false,
  [PAGES_TO_MESSAGES]: {
    [PAGES_TO_MESSAGES_OBJECT]: {},
    [PAGES_TO_MESSAGES_CHECK]: false,
    [PAGES_TO_MESSAGES_TYPE]: ''
  }
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async getGetStreamAccessToken({ state, commit, getters }, user_id) {
    try {
      const { data } = await getGetStreamAccessToken(user_id);
      commit("SET_GET_STREAM_ACCESS_TOKEN", data.information);
    } catch (e) {
      console.error(e);
    }
  },
  async getGetStreamMessagingAccessToken({ state, commit, getters }, user_id) {
    try {
      const { data } = await getGetStreamMessagingAccessToken(user_id);
      commit("SET_GET_STREAM_MESSAGING_ACCESS_TOKEN", data.information);
    } catch (e) {
      console.error(e);
    }
  },
  async getListenedSongs({ state, commit, getters }) {
    try {
      const { data } = await getListenedSongsForPost(0, 3);
      const { information } = data || {};
      const { listened } = information || {};
      commit("SET_LISTENED_SONGS", listened.data);
    } catch (e) {
      console.error(e);
    }
  },
};

export const mutations = {
  SET_NOTIFICATION_CLIENT(state, payload) {
    state[NOTIFICATION_CLIENT] = payload
  },
  SET_ALL_USER_ID(state, payload) {
    state[ALL_USER_ID] = payload;
  },
  SET_ALL_ONLINE_USERS(state, payload) {
    state[ALL_ONLINE_USERS] = payload;
  },
  SET_GET_STREAM_ACCESS_TOKEN(state, payload) {
    state[GET_STREAM_ACCESS_TOKEN] = payload;
  },
  SET_GET_STREAM_MESSAGING_ACCESS_TOKEN(state, payload) {
    state[GET_STREAM_MESSAGING_ACCESS_TOKEN] = payload;
  },
  SET_GET_STREAM_SUBSCRIPTION(state, payload) {
    state[SUBSCRIPTION] = payload;
  },
  SET_NOTIFICATIONS(state, payload) {
    state[NOTIFICATIONS] = payload;
    let temp_friend_request_notification = state[NOTIFICATIONS];
    let temp_other_notification = state[NOTIFICATIONS];
    state[FRIENDS_REQUEST_NOTIFICATIONS] = temp_friend_request_notification.filter(function(e) {
      return (
        e.verb === "friend_request_sent" || e.verb === "friend_request_accepted"
      );
    });
    state[ACTIVITY_NOTIFICATIONS] = state[NOTIFICATIONS].filter(function(e) {
      return e.verb === "listening";
    });
    state[OTHER_NOTIFICATIONS] = temp_other_notification.filter(function(e) {
      return (
        e.verb !== "friend_request_sent" &&
        e.verb !== "friend_request_accepted" &&
        e.verb !== "listening"
      );
    });
    const temp = state[OTHER_NOTIFICATIONS];
    state[OTHER_NOTIFICATIONS] = [];
    state[OTHER_NOTIFICATIONS] = temp.filter(function(e) {
      return (
        e.verb === "follow" ||
        e.verb === "page_follow" ||
        e.verb === "post_comment" ||
        e.verb === "reply" ||
        e.verb === "tagged" ||
        e.verb === "page_mention" ||
        e.verb === "post_created" ||
        e.verb === "share_post" ||
        e.verb === "repost_post" ||
        e.verb === "reaction" ||
        e.verb === "page_follow" ||
        e.verb === "page_post_created" ||
        e.verb === "event_created" ||
        e.verb === "event_interest" ||
        e.verb === "playlist" ||
        e.verb === "birthday" ||
        e.verb === "reminder" ||
        e.verb === "event" ||
        e.verb === "album" ||
        e.verb === "song"  ||
        e.verb === "invite"
      );
    });
  },
  SET_NEW_NOTIFICATION(state, payload) {
    state[NOTIFICATIONS].unshift(payload);
    let temp_friend_request_notification = state[NOTIFICATIONS];
    let temp_other_notification = state[NOTIFICATIONS];
    state[
      FRIENDS_REQUEST_NOTIFICATIONS
    ] = temp_friend_request_notification.filter(function(e) {
      return (
        e.verb === "friend_request_sent" || e.verb === "friend_request_accepted"
      );
    });
    state[ACTIVITY_NOTIFICATIONS] = state[NOTIFICATIONS].filter(function(e) {
      return e.verb === "listening";
    });
    state[OTHER_NOTIFICATIONS] = temp_other_notification.filter(function(e) {
      return (
        e.verb !== "friend_request_sent" &&
        e.verb !== "friend_request_accepted" &&
        e.verb !== "listening"
      );
    });
    const temp = state[OTHER_NOTIFICATIONS];
    state[OTHER_NOTIFICATIONS] = [];
    state[OTHER_NOTIFICATIONS] = temp.filter(function(e) {
      return (
        e.verb === "follow" ||
        e.verb === "page_follow" ||
        e.verb === "post_comment" ||
        e.verb === "reply" ||
        e.verb === "tagged" ||
        e.verb === "page_mention" ||
        e.verb === "post_created" ||
        e.verb === "share_post" ||
        e.verb === "repost_post" ||
        e.verb === "reaction" ||
        e.verb === "page_follow" ||
        e.verb === "page_post_created" ||
        e.verb === "event_created" ||
        e.verb === "event_interest" ||
        e.verb === "playlist" ||
        e.verb === "birthday" ||
        e.verb === "reminder" ||
        e.verb === "event" ||
        e.verb === "album" ||
        e.verb === "song" || e.verb === 'invite'
      );
    });
  },
  SET_USER(state, payload) {
    state[USER] = payload;
  },
  SET_CHAT_CLIENT(state, payload) {
    state[CHAT_CLIENT] = payload;
  },
  SET_LISTENED_SONGS(state, payload) {
    state[LISTENED_SONGS] = payload;
  },
  SET_TOTAL_READ(state, payload) {
    state[TOTAL_READ] = payload;
  },
  SET_TOTAL_SEEN(state, payload) {
    state[TOTAL_SEEN] = payload;
  },
  SET_NOTIFICATIONS_TO_EMPTY(state) {
    state[NOTIFICATIONS] = [];
    state[FRIENDS_REQUEST_NOTIFICATIONS] = [];
    state[OTHER_NOTIFICATIONS] = [];
    state[NEW_NOTIFICATIONS] = [];
    state[OLD_NOTIFICATIONS] = [];
    state[ACTIVITY_NOTIFICATIONS] = [];
  },
  SET_ONLINE_USERS(state, payload) {
    state[ONLINE_USERS] = payload;
  },
  SET_GET_STREAM_FRIENDS(state, payload) {
    state[GET_STREAM_FRIENDS] = payload;
  },
  SET_LAST_MESSAGES(state, payload) {
    state[LAST_MESSAGES] = payload;
  },
  SET_LAST_MESSAGES_GROUPS(state, payload) {
    state[LAST_MESSAGES_GROUPS] = payload;
  },
  JOIN_LAST_MESSAGES_GROUPS_WITH_OTHER_GROUPS(state, payload) {
    let groups = state[LAST_MESSAGES_GROUPS]
    state[LAST_MESSAGES_GROUPS] = [...groups, ...payload]
  },
  SET_LAST_MESSAGES_FOR_CHANNELS(state, payload) {
    if (payload.data.room_type === 'text' || payload._data.room_type === 'text') {
      state[LAST_MESSAGES_GROUPS].unshift(payload)
    } else if (payload.data.room_type === 'partyroom' || payload._data.room_type === 'partyroom') {
      state[LAST_MESSAGES_PARTYROOM].unshift(payload)
    } else if (payload.data.room_type === 'clubroom' || payload._data.room_type === 'clubroom') {
      state [LAST_MESSAGES_CLUBROOM].unshift(payload)
    } else if (payload.data.name === 'single' || payload._data.name === 'single') {
      state [LAST_MESSAGES].unshift(payload)
    } else {
      console.log('Unable to Set Last Messages')
    }
  },
  SET_LAST_MESSAGES_CLUBROOM(state, payload) {
    state[LAST_MESSAGES_CLUBROOM] = payload;
  },
  JOIN_LAST_MESSAGES_CLUBROOM_WITH_OTHER_CLUBROOM(state, payload) {
    let clubrooms = state[LAST_MESSAGES_CLUBROOM]
    state[LAST_MESSAGES_CLUBROOM] = [...clubrooms, ...payload]
  },
  SET_LAST_MESSAGES_PARTYROOM(state, payload) {
    state[LAST_MESSAGES_PARTYROOM] = payload;
  },
  JOIN_LAST_MESSAGES_PARTY_ROOM_WITH_OTHER_PARTY_ROOMS(state, payload) {
    let part_rooms = state[LAST_MESSAGES_PARTYROOM]
    state[LAST_MESSAGES_PARTYROOM] = [...part_rooms, ...payload]
  },
  SET_PAGE_ROOMS(state, payload) {
    state[SET_PAGE_ROOMS] = payload;
  },
  SET_PAGE_TEXT_ROOMS(state, payload) {
    state[SET_PAGE_TEXT_ROOMS] = payload;
  },
  SET_PAGE_AUDIO_VIDEO_ROOMS(state, payload) {
    state[SET_PAGE_AUDIO_VIDEO_ROOMS] = payload;
  },
  SET_UNREAD_MESSAGES_COUNT(state, payload) {
    state[UNREAD_MESSAGES_COUNT] = payload;
  },
  SET_SEARCHED_MESSAGES_LIST(state, payload) {
    state[SEARCHED_MESSAGE_LIST] = payload;
  },
  SET_ADD_MEMBER_CHECK(state, payload) {
    state[ADD_MEMBER_CHECK] = payload;
  },
  SET_PAGES_TO_MESSAGES(state, payload) {
    state[PAGES_TO_MESSAGES][PAGES_TO_MESSAGES_CHECK] = payload.check
    state[PAGES_TO_MESSAGES][PAGES_TO_MESSAGES_OBJECT] = payload.object
    state[PAGES_TO_MESSAGES][PAGES_TO_MESSAGES_TYPE] = payload.type
  },
  RESET_PAGES_TO_MESSAGES(state) {
    state[PAGES_TO_MESSAGES][PAGES_TO_MESSAGES_CHECK] = false
    state[PAGES_TO_MESSAGES][PAGES_TO_MESSAGES_OBJECT] = {}
    state[PAGES_TO_MESSAGES][PAGES_TO_MESSAGES_TYPE] = ''
  }
};

const getters = {
  getNotificationClient(state) {
    return state[NOTIFICATION_CLIENT]
  },
  getAccessTokenOfGetStream(state) {
    return state[GET_STREAM_ACCESS_TOKEN];
  },
  getAccessTokenOfGetStreamMessaging(state) {
    return state[GET_STREAM_MESSAGING_ACCESS_TOKEN];
  },
  getGetStreamSubscription(state) {
    return state[SUBSCRIPTION];
  },
  getGetStreamUser(state) {
    return state[USER];
  },
  getGetStreamChatUser(state) {
    return state[CHAT_CLIENT];
  },
  getGetStreamNotifications(state) {
    return state[NOTIFICATIONS];
  },
  getGetStreamOtherNotifications(state) {
    return state[OTHER_NOTIFICATIONS];
  },
  getGetStreamFriendRequestNotifications(state) {
    return state[FRIENDS_REQUEST_NOTIFICATIONS];
  },
  getGetStreamActivityNotifications(state) {
    return state[ACTIVITY_NOTIFICATIONS];
  },
  getListenedSongs(state) {
    return state[LISTENED_SONGS];
  },
  getTotalRead(state) {
    return state[TOTAL_READ];
  },
  getTotalSeen(state) {
    return state[TOTAL_SEEN];
  },
  getOnlineUsers(state) {
    return state[ONLINE_USERS];
  },
  getAllOnlineUsers(state) {
    return state[ALL_ONLINE_USERS];
  },
  getGetStreamFriends(state) {
    return state[GET_STREAM_FRIENDS];
  },

  getlastMessages(state) {
    return state[LAST_MESSAGES];
  },
  getLastMessagesGroups(state) {
    return state[LAST_MESSAGES_GROUPS];
  },
  getLastMessagesClubroom(state) {
    return state[LAST_MESSAGES_CLUBROOM];
  },
  getLastMessagesPartyroom(state) {
    return state[LAST_MESSAGES_PARTYROOM];
  },
  getPageRooms(state) {
    return state[SET_PAGE_ROOMS];
  },
  getPageTextRooms(state) {
    return state[SET_PAGE_TEXT_ROOMS];
  },
  getPageAudioVideoRooms(state) {
    return state[SET_PAGE_AUDIO_VIDEO_ROOMS];
  },
  getUnreadMessagesCount(state) {
    return state[UNREAD_MESSAGES_COUNT];
  },
  getSearchedMessageList(state) {
    return state[SEARCHED_MESSAGE_LIST];
  },
  getAddMemberCheck(state){
    return state[ADD_MEMBER_CHECK];
  },
  getPagesToMessages(state) {
    return state[PAGES_TO_MESSAGES]
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
