<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div class="forum-text-editor-wrapper m-t-30">
          <div class="form-group">
            <label for="Tittle">Keyword(s)</label>
            <input
              v-model="searchKeyword"
              @input="requiredKeyword = false"
              type="text"
              class="form-control"
              id="Tittle"
              aria-describedby="Tittle"
              :class="{ reqKeyword: requiredKeyword }"
            />
          </div>
          <div class="d-flex justify-content-between m-b-20">
            <div class="width_select">
              <div class="select-title">Search for</div>
              <div
                @click.stop="showSelect('showSelThreadsOrReplies')"
                class="wr_select"
                :class="{ focus_sel: showSelThreadsOrReplies }"
              >
                <div>{{ valueThreadsOrReplies }}</div>
                <div v-if="showSelThreadsOrReplies" class="wr_sel_item">
                  <div
                    v-for="item in itemThreadsOrReplies"
                    :key="item.id"
                    @click="
                      changeSelItem({
                        show: 'showSelThreadsOrReplies',
                        value: item.name,
                      })
                    "
                    class="sel_item"
                    :class="{ for_padding: item.id !== 1 }"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div :class="{ arrow: showSelThreadsOrReplies }">
                  <svg
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L4 4.5L7 1.5"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <!--  -->
            </div>
            <div class="width_select">
              <div class="select-title">Search in Forum(s)</div>
              <div
                @click.stop="showSelect('showForumsSelect')"
                class="wr_select"
                :class="{ focus_sel: showForumsSelect }"
              >
                <div>{{ valueForumsSelect }}</div>
                <div v-if="showForumsSelect" class="wr_sel_item">
                  <div
                    v-for="item in GET_FORUM_NAME_LIST"
                    :key="item.id"
                    @click="
                      changeSelItem({
                        show: 'showForumsSelect',
                        value: item.name,
                        id: item.id,
                      })
                    "
                    class="sel_item"
                    :class="{ for_padding: item.id !== 'All Forums' }"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div :class="{ arrow: showForumsSelect }">
                  <svg
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L4 4.5L7 1.5"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div @click="search" class="btn btn-post with-icon">
            <div class="svg-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="c-line-1"
                  d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M14.0001 13.9996L11.1001 11.0996"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            Search
          </div>
        </div>
        <div v-if="resultSearchCount" class="forum-keyword-wrapper m-t-20">
          <div class="wr_keyword_resolt">
            <div class="left-block">
              Keyword(s): <span>{{ GET_SEARCH_KEYWORD }}</span>
            </div>
            <div class="right-block">
              <span>{{ resultSearchCount }}</span>
              Results Found
            </div>
          </div>
          <div class="wr_tabs_search">
            <div @click="changeActiveTabSearch('threads')" class="res_total">
              <p
                :class="{
                  active_tab_search:
                    GET_ACTIVE_TAB_SEARCH === 'threads' ||
                    GET_ACTIVE_TAB_SEARCH === 'all threads and replies',
                }"
              >
                THREADS
              </p>
              <div class="right-block">
                <span>{{
                  this.GET_SEARCH_RESULT.threads
                    ? this.GET_SEARCH_RESULT.threads.total
                    : 0
                }}</span>
              </div>
            </div>
            <div @click="changeActiveTabSearch('replies')" class="res_total">
              <p
                :class="{
                  active_tab_search: GET_ACTIVE_TAB_SEARCH === 'replies',
                }"
              >
                REPLIES
              </p>
              <div class="right-block">
                <span>{{
                  this.GET_SEARCH_RESULT.replies
                    ? this.GET_SEARCH_RESULT.replies.total
                    : 0
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            GET_ACTIVE_TAB_SEARCH === 'threads' ||
            GET_ACTIVE_TAB_SEARCH === 'all threads and replies'
          "
        >
          <div v-if="GET_SEARCH_RESULT.threads">
            <EmptyCardNoButton
              v-if="!GET_SEARCH_RESULT.threads.total"
              :description="' We haven’t found any results fort your search fields. Try different search fields and try again!'"
              :title="'No Results Found'"
              :iconType="'search'"
            />
            <MyThreads
              :type="'search_threads'"
              :THREADS="GET_SEARCH_RESULT.threads"
            />
          </div>
          <div v-else>
            <EmptyCardNoButton
              :description="' We haven’t found any results fort your search fields. Try different search fields and try again!'"
              :title="'No Results Found'"
              :iconType="'search'"
            />
          </div>
        </div>
        <div v-if="GET_ACTIVE_TAB_SEARCH === 'replies'">
          <div v-if="GET_SEARCH_RESULT.replies">
            <EmptyCardNoButton
              v-if="!GET_SEARCH_RESULT.replies.total"
              :description="' We haven’t found any results fort your search fields. Try different search fields and try again!'"
              :title="'No Results Found'"
              :iconType="'search'"
            />
            <MyReplies
              :type="'search_replies'"
              :REPLISE="GET_SEARCH_RESULT.replies"
            />
          </div>
          <div v-else>
            <EmptyCardNoButton
              :description="' We haven’t found any results fort your search fields. Try different search fields and try again!'"
              :title="'No Results Found'"
              :iconType="'search'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapGetters, mapMutations, mapActions } from "vuex";
import EmptyCardNoButton from "../../Common/Elements/EmptyCardNoButton.vue";
import MyReplies from "@/components/Home/Forum/MyReplies.vue";
import MyThreads from "@/components/Home/Forum/MyThreads.vue";
export default {
  data() {
    return {
      active_tab_search: true,
      searchKeyword: "",
      requiredKeyword: false,
      valueThreadsOrReplies: "All Threads and Replies",
      itemThreadsOrReplies: [
        { id: 1, name: "All Threads and Replies" },
        { id: 2, name: "Threads" },
        { id: 3, name: "Replies" },
      ],
      showSelThreadsOrReplies: false,
      //
      valueForumsSelect: "All Forums",
      showForumsSelect: false,
      forumId: "",
    };
  },
  components: {
    EmptyCardNoButton,
    MyReplies,
    MyThreads,
  },
  props: {
    type: String,
  },
  methods: {
    ...mapMutations([
      "changeSearchKeyword",
      "changeSearchParams",
      "changeActiveTabSearch",
      "changeSearchResult",
    ]),
    ...mapActions(["getSearchForumResult"]),
    async search() {
      let body = {
        keyword: this.searchKeyword,
        search_for:
          this.valueThreadsOrReplies === "All Threads and Replies"
            ? ""
            : this.valueThreadsOrReplies.toLowerCase(),
        forum: this.forumId === "All Forums" ? "" : this.forumId,
      };
      this.changeSearchKeyword(this.searchKeyword);
      this.changeSearchParams(body);
      if (this.searchKeyword) {
        await this.getSearchForumResult({ offset: 0 });
      } else {
        this.requiredKeyword = true;
      }
      //
      //
      if (!this.GET_SEARCH_RESULT.threads) {
        this.changeActiveTabSearch("replies");
      } else if (
        this.GET_SEARCH_RESULT.threads &&
        !this.GET_SEARCH_RESULT.threads.total
      ) {
        this.changeActiveTabSearch("replies");
      } else
        this.changeActiveTabSearch(this.valueThreadsOrReplies.toLowerCase());
    },
    changeSelItem(params) {
      if (params.show === "showSelThreadsOrReplies") {
        this.valueThreadsOrReplies = params.value;
      } else if (params.show === "showForumsSelect") {
        this.valueForumsSelect = params.value;
        this.forumId = params.id;
      }
    },

    showSelect(showSel) {
      if (showSel === "showSelThreadsOrReplies") {
        this.showForumsSelect = false;
        this.showSelThreadsOrReplies = !this.showSelThreadsOrReplies;
      } else if (showSel === "showForumsSelect") {
        this.showSelThreadsOrReplies = false;
        this.showForumsSelect = !this.showForumsSelect;
      }
    },
    changeTab() {
      this.active_tab_search = !this.active_tab_search;
    },
  },
  computed: {
    ...mapGetters([
      "GET_ALL_FORUMS",
      "GET_FORUM_NAME_LIST",
      "GET_SEARCH_RESULT",
      "GET_SEARCH_KEYWORD",
      "GET_ACTIVE_TAB_SEARCH",
    ]),
    resultSearchCount() {
      let total = 0;
      if (this.GET_SEARCH_RESULT.replies) {
        total += this.GET_SEARCH_RESULT.replies.total;
      }
      if (this.GET_SEARCH_RESULT.threads) {
        total += this.GET_SEARCH_RESULT.threads.total;
      }
      return total;
    },
  },
  destroyed() {
    this.changeSearchResult({});
    this.changeActiveTabSearch("");
  },
};
</script>
<style lang="scss" scoped>
.right-block {
  display: flex;
  span {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    min-width: 26px;
  }
}
.wr_tabs_search {
  span {
    border-bottom: none !important;
    height: 18px !important;
  }
  .res_total {
    border-bottom: none !important;
    p {
      border-bottom: 3px solid #ffffff !important;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .active_tab_search {
      border-bottom: 3px solid #D1433A !important;
      color: #47505b !important;
    }
  }
}
.width_select {
  width: 49%;
}
.wr_select {
  font-family: HelveticaNeueNormal;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 13px 4px 7px;
  color: #47505b;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  transition: all 0.3s;
  svg {
    transition: all 0.3s;
  }
  .wr_sel_item {
    padding: 10px 0;
    font-family: HelveticaNeueNormal;
    position: absolute;
    width: 100.6%;
    background: #ffffff;
    left: -1px;
    top: 29px;
    box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
    border-radius: 4px;
    .sel_item {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: #D1433A1A;
      }
    }
    .for_padding {
      padding: 0 30px;
    }
  }
  .arrow {
    svg {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }
}
.reqKeyword {
  border: 1px solid #ff0039 !important;
}
.focus_sel {
  border: 1px solid #D1433A !important;
}
</style>