<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="c-line-1"
            d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path class="c-line-1" d="M7 4.66699V9.33366" stroke="white" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"></path>
        <path class="c-line-1" d="M4.66699 7H9.33366" stroke="white" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
</template>

<script>
export default {
    name: "PlusIcon",
};
</script>