<template>
    <div class="show_more_btn">
      <div class="show-more musicbar_more">
        <a @click.prevent="emitShowmore" href="javascript:;">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW MORE
          </span>
        </a>
      </div>
    </div>
  </template>
  
<script>
    export default {
        data() {},

        methods: {
            emitShowmore() {
                this.$emit('emitposts')
            }
        }
    };
</script>
  