<template>
  <span class="friend_tab_search">
     <input type="search" name="Search" placeholder="Search" v-model="friendsSearchKey" v-on:keyup="SearchFriends" style="min-width: 16rem;"/>
     <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.1 6.543C12.1 3.48168 9.61518 0.999998 6.55 0.999998C3.48482 0.999998 1 3.48168 1 6.543C1 9.60431 3.48482 12.086 6.55 12.086C9.61518 12.086 12.1 9.60431 12.1 6.543Z"
          stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5 12.501L14.5 14.501" stroke="#8B949F" stroke-width="1.3"
              stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
  </span>
</template>
<script>
  export default {
    props:['currentActiveFriendTab'],
    data(){
      return{
        friendsSearchKey:null,
        showMore: false,
      }
    },
    watch:{
      currentActiveFriendTab (){
        this.friendsSearchKey = ''
      }
    },
    methods:{
      SearchFriends(){
        this.$emit('searchFriendValue', this.friendsSearchKey)
        let payload = {
          showMore : this.showMore,
          friendsSearchKey : this.friendsSearchKey,
          search_type: this.currentActiveFriendTab
        }
        if(this.currentActiveFriendTab === 'friends'){
          this.$store.dispatch('getMyFriends', payload);
        }else if(this.currentActiveFriendTab === 'followers'){
          this.$store.dispatch('getMyFollowers', payload);
        }else if(this.currentActiveFriendTab === 'followings'){
          this.$store.dispatch('getMyFollowings', payload);
        }
      }
    }
  }
</script>
<style>
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
</style>