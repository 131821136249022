<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Hidden</h3>
        <h6>To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="tickets_settings.visibility" id="switch24"/>
        <label class="switch_sm" for="switch24">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4>What Event do you want to let user book?</h4>
        <p>Choose from list below</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">
          <div class="col-5">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">Event</label>
              <div class="select_box">
                <select v-model="tickets_settings.event" class="form_control">
                  <option value="0">Choose event from list</option>
                  <option value="1">Event 1</option>
                  <option value="2">Event 2</option>
                  <option value="3">Event 3</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/calendar.svg" alt="calendar"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12.6667 2.66699H3.33333C2.59695 2.66699 2 3.26395 2 4.00033V13.3337C2 14.07 2.59695 14.667 3.33333 14.667H12.6667C13.403 14.667 14 14.07 14 13.3337V4.00033C14 3.26395 13.403 2.66699 12.6667 2.66699Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.666 1.33301V3.99967" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M5.33398 1.33301V3.99967" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path d="M2 6.66699H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        Tickets
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tickets_settings: {
        visibility: false,
        event: null
      }
    }
  },
  props: {
    ticketsSettings: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    'tickets_settings.visibility'() {
      this.$emit('getTicketsData',this.tickets_settings)
    },
    'tickets_settings.event'() {
      this.$emit('getTicketsData', this.tickets_settings)
    },
    ticketsSettings() {
      this.setTicketsSettingsData()
    }
  },
  mounted() {
    this.setTicketsSettingsData()
  },
  methods: {
    setTicketsSettingsData () {
      this.tickets_settings = this.ticketsSettings
    }
  }
}
</script>
