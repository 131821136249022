<template>
  <div class="w-100">
    <GroupSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/img/artist-admins.svg`)" alt="icon">Admins
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="event-search-input admin-inputs">
                <input type="text" placeholder="Search for admins or moderators">
                <span class="s-icon"><img :src="require(`@/assets/img/search-icon.png`)"></span>
              </div>

              <div class="admins-detail">
                <h1>ADMINS (2)</h1>
                <ul>
                  <li>
                    <div class="artist-admin-detail">
                      <div class="ar-admin-name-img">
                        <div class="artist-admin-image">
                          <img :src="require(`@/assets/img/artist-pict.png`)">
                          <span class="artist-admin-online"></span>
                        </div>
                        <div class="artist-admin-name">Mike Stevens</div>
                      </div>
                      <div class="remove-artist-admin">
                        <button class="remove-artist-button">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="remove-artist-button-active" d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="remove-artist-button-active" d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          REMOVE
                        </button>
                        <button class="artist-admin-more" @click="showMd = !showMd">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="artist-admin-more-active"
                                  d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="artist-admin-more-active"
                                  d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="artist-admin-more-active"
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </button>
                        <div class="move-modratoe-popup" v-if="showMd">
                          <div class="modrator-up-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 3.3335V12.6668" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path d="M12.6673 8L8.00065 12.6667L3.33398 8" stroke="#47505B" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          <div class="modrator-text">Move to Moderators</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="admins-detail">
                <h1>MODERATORS (3)</h1>
                <ul>
                  <li>
                    <div class="artist-admin-detail">
                      <div class="ar-admin-name-img">
                        <div class="artist-admin-image">
                          <img :src="require(`@/assets/img/artist-pict.png`)">
                          <span class="artist-admin-online"></span>
                        </div>
                        <div class="artist-admin-name">Mike Stevens</div>
                      </div>
                      <div class="remove-artist-admin">
                        <button class="remove-artist-button">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="remove-artist-button-active" d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="remove-artist-button-active" d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          REMOVE
                        </button>
                        <div class="move-modrators">
                          <button class="artist-admin-more" @click="showAd = !showAd">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="artist-admin-more-active"
                                    d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="artist-admin-more-active"
                                    d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="artist-admin-more-active"
                                    d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="move-modratoe-popup" v-if="showAd">
                            <div class="modrator-up-icon">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 12.6665L8 3.33317" stroke="#47505B" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M3.33268 8L7.99935 3.33333L12.666 8" stroke="#47505B" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                            <div class="modrator-text">Move to Administrators</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>


              <div class="d-flex justify-content-end mt_30">
                <button class="prime_button " type="button">Save Changes</button>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import GroupSubHeader from '@/components/Group/Pages/GroupSubHeader'

export default {
  data() {
    return {
      showMd: false,
      showAd: false,
    }
  },
  components: {
    GroupSubHeader
  },
  computed: {},
  methods: {}
}
</script>
