<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.6667 7.3335H3.33333C2.59695 7.3335 2 7.93045 2 8.66683V13.3335C2 14.0699 2.59695 14.6668 3.33333 14.6668H12.6667C13.403 14.6668 14 14.0699 14 13.3335V8.66683C14 7.93045 13.403 7.3335 12.6667 7.3335Z"
                fill="#47505B" stroke="#47505B" stroke-width="1.5"></path>
              <path
                d="M4.6665 7.3335V4.66683C4.6665 3.78277 5.01769 2.93493 5.64281 2.30981C6.26794 1.68469 7.11578 1.3335 7.99984 1.3335C8.88389 1.3335 9.73174 1.68469 10.3569 2.30981C10.982 2.93493 11.3332 3.78277 11.3332 4.66683V7.3335"
                stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Manage Session</p>
          <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <p v-if="loading" class="text-center">Loading...</p>
          <div class="session-table width730">
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Browser</th>
                  <th>OS</th>
                  <th>Date</th>
                  <th class="d-flex justify-content-between br-0"><span>IP</span> <span>Actions</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(eachOp, index) in sessions" :key="index">
                  <td>{{ eachOp.id }}</td>
                  <td>{{ eachOp.user_browser }}
                    <!--<span class="green-txt ml-auto">Active Session</span>-->
                  </td>
                  <td>{{ eachOp.user_os }}</td>
                  <td>{{ parseDate(eachOp.session_date) }}</td>
                  <td class="d-flex justify-content-between br-0">
                    <span>{{ eachOp.user_ip }}</span>
                    <ManageSessionDeleteBtn :id="eachOp.id" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <pagination :records="total_records" v-model="page" :per-page="10" @paginate="callback" />

        </div>
      </div>
      <div class=""></div>
    </div>
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import { EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR } from '../../../store/types';
import ManageSessionDeleteBtn from './Components/ManageSessionDeleteBtn';
import Pagination from 'vue-pagination-2';
import EyeIcon from '../../../assets/svgs/EyeIcon.vue';

export default {
  data() {
    return {
      page: 1,
      sessions: "",
      message: "",
      total_records: ""

    }
  },
  mounted() {
    this.submit();
  },
  watch: {
    page: function () {
      this.submit();
    }
  },
  components: {
    ManageSessionDeleteBtn,
    Pagination,
    EyeIcon
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props"
    }),
    loading() {
      const { loading = false } = this.form_data || {};

      return loading;
    },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions([
      "get_sessions"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints"
    ]),
    parseDate(date) {
      const days = (new Date() - new Date(date)) / (24 * 60 * 60 * 1000);
      const hours = (new Date() - new Date(date)) / (60 * 60 * 1000);
      const minutes = (new Date() - new Date(date)) / (60 * 1000);

      if (days > 1) return `${parseInt(days)} days ago`;
      if (hours > 1) return `${parseInt(hours)} hours ago`;
      if (minutes > 1) return `${parseInt(minutes)} minutes ago`;
      if (minutes < 1) return `few moments ago`;
    },
    async submit(e) {
      if (e) e.preventDefault();

      const { loading, password, old_password, confirm_password } = this;

      if (loading) return;

      let body = {};

      try {
        this.message = "";

        const user = this.$store && this.$store.state[USER_ROOT] ? this.$store.state[USER_ROOT] : undefined;
        const user_key = user && user[USER_KEY] ? user[USER_KEY] : undefined;
        const user_data = user_key && user_key[USER_DATA] ? user_key[USER_DATA] : undefined;

        const id = user_data && user_data.id ? user_data.id : "";

        body.id = id;
        body.page = this.page;

        if (!id) {
          this.message = "User ID is not found.";
          return;
        }

        const { status, message, data, total } = await this.get_sessions(body);

        this.total_records = total;

        switch (status) {
          case SUCCESS:
            this.sessions = data && data.length ? data : [];

            break;
          case ERROR:
            this.message = message;
            break;
        }
      } catch (e) {
        this.message = e && e.message ? e.message : "Please try again.";
      }
    },
    callback: function (page) {
      this.page = page;
    }
  }
};
</script>
