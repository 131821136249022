<template>
  <div class="main-div">
    <div v-if="getNextActiveChatType === 'search'">
      <MessageSearchComponent />
    </div>
    <div v-else-if = "getLeftBarListingActiveTab === 'single' || getLeftBarListingActiveTab === 'group'">
      <UserAndGroupInfoComponent />
    </div>
    <div v-else-if="getLeftBarListingActiveTab === 'clubroom' || getLeftBarListingActiveTab === 'partyroom'">
      <ClubRoomInfoComponent />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueImg from "v-img";
import MessageSearchComponent from "./Components/RightBarComponents/MessageSearchComponent";
import UserAndGroupInfoComponent from "./Components/RightBarComponents/UserAndGroupInfoComponent";
import ClubRoomInfoComponent from "./Components/RightBarComponents/ClubRoomInfoComponent";

const vueImgConfig = {
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
};
Vue.use(VueImg, vueImgConfig);

export default {
  name: "RightBar",
  components: {
    MessageSearchComponent,
    UserAndGroupInfoComponent,
    ClubRoomInfoComponent,
  },
  computed: {
    getNextActiveChatType() {
      return this.$store.getters.getActiveFullChatPreviousType;
    },
    getActiveFullChatType() {
      return this.$store.getters.getActiveFullChatType;
    },
    getLeftBarListingActiveTab() {
      return this.$store.getters.getLeftBarListingActiveTab
    }
  }
};
</script>

<style scoped lang="scss">
.main-div {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  width: 350px;
  height: 880px;
  margin: 19px 20px 30px 5px;
}
.group-img-wrapper {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  .single-img-wrap {
    background-color: grey;
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      object-fit: cover;
    }
    &.count {
      background-color: #D1433A;
      font-family: "HelveticaNeueBold";
      font-size: 11px;
      line-height: 13px;
      color: #ffffff;
      padding: 8px 0 0 8px;
    }
  }
}
.widget {
  padding: 15px 0;
  border-bottom: 1px solid #e6eaf0;
  .widget-title {
    font-family: "HelveticaNeueBold";
    font-size: 14px;
    line-height: 20px;
    color: #071526;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
      color: #8b949f;
    }
  }
  .item {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    .img-wrap {
      width: 40px;
      height: 40px;
      position: relative;
      margin-right: 9px;
      .img-cover {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background-color: #ccc;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .status {
        background: #1cec9e;
        border: 2px solid #ffffff;
        border-radius: 4px;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 100%;
      }
    }
    .item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item-title {
        font-family: "HelveticaNeueBold";
        font-size: 13px;
        line-height: 16px;
        color: #D1433A;
        opacity: 0.9;
        margin-bottom: 5px;
      }
      .item-subtitle {
        font-family: "HelveticaNeueNormal";
        font-size: 11px;
        line-height: 13px;
        color: #718195;
        letter-spacing: 0.5px;
      }
    }
  }
  .show-all {
    margin-top: 5px;
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 20px;
    color: #D1433A;
    cursor: pointer;
  }
}
</style>
