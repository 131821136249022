<template>
  <transition name="pop" appear>
    <div class="event-cancel">
      <div class="event-cancel-top">
        <h4>Cancel Event</h4>
        <button @click.stop="cancel">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="event-cancel-description">
        <p>Are you sure you want to cancel the event set up</p>
      </div>
      <div class="event-cancel-actions">
        <button class="event-cancel-actions-remove" @click.stop="closeEvent">
          Cancel
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancelEvent");
    },
    closeEvent(){
      this.$emit('closeEvent')
    }
  },
};
</script>

<style lang="scss" scoped>
.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
.event-cancel {
  margin: auto;
  margin-top: 200px;
  background: #ffffff;
  border-radius: 2px;
  width: 370px;
  padding: 22px 20px 30px 20px;
  &-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6eaf0;
    padding-bottom: 19px;
    h4 {
      color: #071526;
      font-size: 18px;
      line-height: 22px;
      font-family: HelveticaNeueBold;
    }
    button {
      background: #e6eaf0;
      border-radius: 2px;
      border: none;
      width: 30px;
      height: 30px;
    }
  }
  &-description {
    margin-top: 25px;
    p {
      color: #47505b;
      text-align: center;
      font-size: 15px;
      line-height: 20px;
      font-family: HelveticaNeueNormal;
      padding: 0 5px;
    }
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    button {
      width: 100%;
      height: 40px;
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueBold;
      border-radius: 4px;
      text-transform: uppercase;
    }
    &-back {
      border: 1.3px solid #8b949f;
      background: #ffffff;
      color: #8b949f;
    }
    &-remove {
      border: 1px solid #ff0039;
      background: #fff;
      color: #ff0039;      
    }
  }
}
</style>
