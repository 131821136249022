<template>
 <div class="px-0">
    <LeftNav category="navigation" type="music" class="left-bar-placement" />
    <div
      class="app-container music_app_container"
      :class="[{ 'right-nav-space': rightBar }, custom_css_class]"
    >
      <router-view></router-view>
    </div>
 </div>
</template>
<script>
import { mapGetters } from "vuex";
import LeftNav from "@/components/DesignGuide/Layout/LeftNav.vue";

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      form_data: "main_container_full_class_map_to_props",
      rightBar: "getRightBarValue",
    }),
    custom_css_class() {
      const { main_container_full_classes = "" } = this.form_data;

      return main_container_full_classes;
    },
  },
  props: {
    ShowMusicFooter: Boolean,
    ShowLeftNav: Boolean,
  },
  beforeMount() {
    this.showCon();
  },
  components: {
    LeftNav,
  },
  methods: {
    showCon: function() {
      // alert(this.$route.name)
      this.currentUrl = this.$route.name;
    },
  },
};
</script>
<style scoped lang="scss">
.app-container {
  // padding-right: 21% !important;
  // padding-left: 18% !important;
  max-width: 60.87%;
  width: 100%;
  margin: auto;
}

.right-nav-space {
  padding-right: 280px;
}

@media screen and (min-width: 1288px) and (max-width: 1919px) {
 .app-container {
    max-width: calc(100% - 230px);
    margin-left: auto;
    //margin-right: 0;
    padding: 0px 50px !important;
 }
}

@media screen and (min-width: 1288px) and (max-width: 1919px) {
 .app-container {
    max-width: 64.87%;
 }
}
</style>
