<template>
  <div>
    <div v-if="comment_type === 'Song' || comment_type === 'Album' || comment_type === 'Music Podcast'" class="post-section-like post-section-like-2" :class="{'active':userReact}" @click="reactOrUnReact">
      <svg class="" width="16" height="14" viewBox="0 0 16 14" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path class="post-section-like-svg"
              d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>
        Like
      </span>
      <span class="counts">
        {{ (reaction_count) ? reaction_count : 0 }}
      </span>
    </div>
    <div v-else class="post-section-like" :class="{'active':userReact}" @click="reactOrUnReact">
      <svg class="" width="16" height="14" viewBox="0 0 16 14" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path class="post-section-like-svg"
              d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      {{ (reaction_count) ? reaction_count : 0 }}
    </div>
  </div>
</template>
<script>
import { create_post_comment_like_api } from "../../../../apis/APIs";
import { mapGetters } from "vuex";
import moment from "moment";
import CommentItem from "./CommentItem";

export default {
  data() {
    return ({
      like_loading: false,

      message: "",
      success_message: ""
    });
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    comment_type: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const { comment_id = "" } = this.item || {};

      return comment_id;
    },
    userReact() {
      return this.item.reacted;
    },
    reaction_count() {
      let count = 0;
      for (const reaction of this.item.reactions) {
        count = Number(count) + Number(reaction.count);
      }
      return count;
    }
  },
  methods: {
    reactOrUnReact(e) {
      if (e) e.stopPropagation();
      try {
        this.$emit("reactOrUnReact");
      } catch (e) {

      }
    }
  }
};
</script>
<style lang="scss" scoped>
.post-section-like-2 {
  width: auto !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #fff !important;

  &:hover {
    background-color: #F5F6F8 !important;

    svg {
      path {
        stroke: #D1433A !important;
      }
    }
  }

  &.active {
    background-color: #D1433A !important;

    span {
      color: #fff !important;
    }

    svg {
      path {
        stroke: #fff !important;
      }
    }
  }

  span {
    margin-top: 0.1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
  }

  .counts {
    margin-left: 0.5rem;
    color: #8B949F;
    font-size: 12px !important;
  }
}

/*Ameer Hamza styling -- song details page*/

@media screen and (min-width: 2560px) {
  .post-section-like svg {
    width: var(--sm-font-size);
    height: var(--sm-font-size);
  }
  .post-section-like span {
    font-size: var(--sm-font-size) !important;
  }
  .post-section-like .counts {
    font-size: var(--font-size) !important;
  }
}
</style>
