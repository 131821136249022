<template>
  <div>
    <main class="magazine">
      <section>
        <div class="">
          <div class="row">
            <div class="col">
              <div class="breadcrumbs-item">
                <router-link class="back-link" to="/events/page/overview">
                  <div class="svg-icon">
                    <svg
                      width="7"
                      height="14"
                      viewBox="0 0 7 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.86328 12.7285L0.134281 7.00052L5.86328 1.27152"
                        stroke="#071526"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="text-link">OVERVIEW</div>
                </router-link>
                <div class="title">Shopping cart</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
        <div class="">
          <div class="row">
            <div class="col-sm-8">
              <div class="buy-item-body">
                <div class="ticket-info-tabs">
                  <div class="single-tab">DIGITAL GOODS<span>3</span></div>
                  <div class="single-tab">PHYSICAL ITEMS<span>3</span></div>
                  <div class="single-tab" :class="{ active_tabs: true }">
                    TICKETS<span>2</span>
                  </div>
                </div>
              </div>
              <TicketsShopingCart
                v-for="item in GET_MY_CART_ITEMS"
                :key="item.id"
                :paramsItems="item"
              />
              <ContactInfo v-if="contactInfo" />
              <PaymentMethods v-if="paymentMethods" />
              <MyWallet v-if="my_wallet" />
              <TopUpWallet v-if="top_Up_Wallet" />
              <Cart v-if="cart" />
              <BankTransfer v-if="bankTransfer" />
              <VerificationPayment v-if="verificationPayment" />
              <PayPalStripeAliPay
                v-if="payPalStripeAliPay"
                :paymentType="paymentType"
              />
            </div>
            <div class="col-sm-4">
              <div
                v-if="notify_board && GET_MY_CART_ITEMS.length > 0"
                class="notify-board"
              >
                <div class="time-icon">
                  <svg
                    width="22"
                    height="27"
                    viewBox="0 0 22 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 26C16.5228 26 21 21.5228 21 16C21 10.4772 16.5228 6 11 6C5.47715 6 1 10.4772 1 16C1 21.5228 5.47715 26 11 26Z"
                      stroke="white"
                      stroke-width="2"
                    />
                    <path
                      d="M11 16H7.25M13.5 1H8.5H13.5ZM11 1V6V1ZM17.875 8.5L19.75 6.625L17.875 8.5ZM11 16V12.25V16Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p>Hurry! Complete order to secure tickets</p>
              </div>
              <div class="widget terms-conditions">
                <div></div>
                <div class="d-flex align-items-center">
                  <div>
                    <label class="container p-0">
                      <input
                        @click="isChecked = !isChecked"
                        type="checkbox"
                        checked="checked"
                        v-model="isChecked"
                      />
                      <span class="checkmark">
                        <svg
                          v-show="isChecked"
                          width="13"
                          height="9"
                          viewBox="0 0 13 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.325379 5.75664C-0.629113 4.78942 0.727637 3.97541 1.68213 4.94263L3.68213 6.94263L7.68212 3.44212L10.6821 0.44262C11.7321 -0.524599 12.8292 0.243495 11.8747 1.30744L4.62059 8.56157C4.2388 8.94846 3.57065 9.04518 3.18886 8.56157L0.325379 5.75664Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </label>
                  </div>
                  <p class="agree-to-terms">
                    I have read and agree to the
                    <a href="#" class="terms-and-conditions"
                      >Terms and Conditions</a
                    >
                  </p>
                </div>
              </div>
              <div class="sidebar">
                <CartSummary
                  :events_Ticket_Total_Amoun="events_Ticket_Total_Amoun"
                />
                <YourContactInfo
                  :isChecked="isChecked"
                  :events_Ticket_Total_Amoun="events_Ticket_Total_Amoun"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import TicketsShopingCart from "./TicketsShopingCart.vue";
import CartSummary from "./CartSummary.vue";
import ContactInfo from "./ContactInfo.vue";
import PaymentMethods from "./PaymentMethods.vue";
import YourContactInfo from "./YourContactInfo.vue";
import MyWallet from "./MyWallet.vue";
import TopUpWallet from "./TopUpWallet.vue";
import Cart from "./Cart.vue";
import BankTransfer from "./BankTransfer.vue";
import VerificationPayment from "./VerificationPayment.vue";
import PayPalStripeAliPay from "./PayPalStripeAliPay.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    TicketsShopingCart,
    CartSummary,
    ContactInfo,
    PaymentMethods,
    YourContactInfo,
    MyWallet,
    TopUpWallet,
    Cart,
    BankTransfer,
    VerificationPayment,
    PayPalStripeAliPay,
  },
  data() {
    return {
      contactInfo: false,
      paymentMethods: false,
      my_wallet: false,
      top_Up_Wallet: false,
      cart: false,
      bankTransfer: false,
      verificationPayment: false,
      payPalStripeAliPay: false,
      paymentType: "",
      modals_name: [
        "contactInfo",
        "paymentMethods",
        "my_wallet",
        "top_Up_Wallet",
        "cart",
        "bankTransfer",
        "verificationPayment",
        "payPalStripeAliPay",
      ],
      //
      isChecked: true,
      notify_board: false,
      timerId: null,
      mySelectEventName: [],
      events_Ticket_Total_Amoun: [],
    };
  },
  methods: {
    ...mapActions(["myCartItems"]),
    openCloseModals(params) {
      this.modals_name.forEach((el) => {
        if (el === params.modal) {
          this[params.modal] = !this[params.modal];
          this.paymentType = params.type;
        } else {
          this[el] = false;
        }
      });

      if (params.modal === "paymentMethods") {
        clearTimeout(this.timerId);
      }
    },
    forParamsCartSummary() {
      this.mySelectEventName = this.GET_MY_CART_ITEMS.map(
        (el) => el.ticket.event.title
      ).filter((v, i, a) => a.indexOf(v) === i);
      this.events_Ticket_Total_Amoun = [];
      for (let i = 0; i < this.mySelectEventName.length; i++) {
        let ticket_Total_Amoun = 0;
        this.GET_MY_CART_ITEMS.forEach((el) => {
          if (this.mySelectEventName[i] === el.ticket.event.title) {
            ticket_Total_Amoun += el.ticket.price * el.quantity;
          }
        });
        this.events_Ticket_Total_Amoun.push({
          eventTitle: this.mySelectEventName[i],
          total_amoun: ticket_Total_Amoun,
          id: Date.now() * Math.random(),
        });
      }
    },
  },
  computed: {
    ...mapGetters(["GET_MY_CART_ITEMS", "GET_SINGLE_EVENT_DETAILS"]),
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.myCartItems();
    await this.$store.dispatch("loading", false);
    console.log("this.GET_MY_CART_ITEMS", this.GET_MY_CART_ITEMS);
    this.forParamsCartSummary();
    this.timerId = setTimeout(() => {
      this.notify_board = true;
    }, 15000);
  },
};
</script>

<style lang="scss" scoped>
.buy-item-body {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 0 0 29px !important;
}
.ticket-info-tabs {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 5px;
  div {
    margin-right: 42px;
    font-family: HelveticaNeueBold;
    height: 100%;
    display: flex;
    color: #c4ccd6;
    align-items: center;
    border-bottom: 3px solid #fff;
    span {
      margin-left: 6px;
      min-width: 26px;
      background: #D1433A;
      border-radius: 4px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .active_tabs {
    color: #47505b;
    border-color: #D1433A;
  }
}
.notify-board {
  background: #D1433A1A;
  border: 1px solid #D1433A1A;
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.notify-board p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #071526;
  opacity: 0.9;
  padding-left: 20px;
  margin-bottom: 0;
}
.time-icon {
  background: #D1433A;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.terms-conditions {
  display: flex;
  align-items: center;
  padding: 17px 20px !important;
}
.terms-conditions a {
  color: #D1433A;
}
.terms-conditions p {
  margin-bottom: 0;
  margin-left: 25px;
}

// custom checkbox

.container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 14px;
  cursor: pointer;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-family: HelveticaNeueNormal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #47505b;
  width: 100%;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1.3px solid #c4ccd6;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #D1433A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.terms-conditions label {
  margin-top: -8px;
}

.agree-to-terms {
  white-space: nowrap;
}
.terms-and-conditions {
  &:hover {
    text-decoration: underline !important;
  }
}

.magazine .widget.contact-widget select {
  background-image: url(./../../../../../assets/icons/icons/select-arrow.svg);
  background-repeat: no-repeat;
  background-position: 95%;
}
</style>
<style lang="scss">
.wr_my_wallet_modals {
  background: #ffffff;
  border-radius: 2px;
  width: 370px;
  padding: 20px;
  .wr_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6eaf0;
    div {
      display: flex;
    }
    .title {
      margin-left: 20px;
      color: #071526;
      font-size: 18px;
      font-family: HelveticaNeueBold;
    }
    .ic_prev_close {
      height: 30px;
      width: 30px;
      background: #e4e6eb;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .wr_btn {
    display: flex;
    justify-content: space-between;
    button {
      background: #ffffff;
      border: 1.3px solid #8b949f;
      color: #8b949f;
      font-family: HelveticaNeueBold;
      font-size: 13px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 4px;
    }
    .cancel {
      width: 100px;
    }
    .confirm {
      border: none;
      width: 220px;
      background: linear-gradient(270deg, #0075ff -9.47%, #6a3dcb 98.54%);
      color: #ffffff;
    }
  }
}
</style>