<template>
  <div class="modal d-block custom-modal modalpayment">
    <div class="modals Signup_pop_overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Request Refund</h5>
          <button
            @click="$emit('openCloseRequestRefund')"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <div class="svg-icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L4.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.5 4.5L13.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
        <section>
          <div class="wr_infornation">
            <h5>Information</h5>
            <p>We’ll check your problem and will reach you with e-mail</p>
          </div>
          <div class="wr_problem">
            <h5>Problem</h5>
            <div class="for_border problem">
              <div>Choose category</div>
              <span
                ><svg
                  width="8"
                  height="6"
                  viewBox="0 0 8 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L4 4.5L7 1.5"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div class="wr_message">
            <h5>Your Message</h5>
            <div class="for_border">
              <textarea
                placeholder="Please explain the reason of request"
              ></textarea>
            </div>
          </div>
        </section>
        <div class="btns">
          <button class="cancel" @click="$emit('openCloseRequestRefund')">
            Cancel
          </button>
          <button class="send">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.modal-header {
  border: none !important;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
h5 {
  font-family: HelveticaNeueBold;
  letter-spacing: 0.7px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #47505b;
  margin-bottom: 6px;
}
.wr_infornation {
  background: #f3f3f3;
  border-radius: 4px;
  padding: 15px 20px;
  p {
    font-family: HelveticaNeueNormal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #47505b;
    margin: 0;
  }
}
.for_border {
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}
.wr_problem {
  margin: 17px 0;
  .problem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.7px;
    padding: 0 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #8b949f;
  }
}
.wr_message {
  div {
    width: 100%;
    height: 160px;
    textarea {
      font-family: HelveticaNeueNormal;
      letter-spacing: 0.7px;
      padding: 10px;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #8b949f;
      resize: none;
    }
  }
}
.btns {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  button {
    width: 130px;
    height: 30px;
    border-radius: 4px;
    color: #47505b;
    background: #e6eaf0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #D1433A1A;
    }
  }
  .send {
    background: #D1433A;
    color: #ffffff;
    &:hover {
      background-color: #DA6961;
    }
  }
}
</style>