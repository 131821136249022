<template>
  <div
    class="item"
    :class="{
      item_Sold_Out: !ticketInfo.available_tickets > 0,
      item_active: tickets.countTicket > 0,
    }"
  >
    <div>{{ ticketInfo.name }}</div>
    <div>
      {{
        ticketInfo.available_tickets > 0
          ? ticketInfo.available_tickets + " tickets"
          : "Sold Out"
      }}
    </div>
    <div>$ {{ ticketInfo.price }}</div>
    <div class="counter">
      <div
        class="minus"
        @click="minusTicket"
        :class="{ plus_minus_Sold_Out: !ticketInfo.available_tickets > 0 }"
      >
        <div class="svg-icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="c-line"
              d="M8.00065 14.6663C11.6825 14.6663 14.6673 11.6816 14.6673 7.99967C14.6673 4.31778 11.6825 1.33301 8.00065 1.33301C4.31875 1.33301 1.33398 4.31778 1.33398 7.99967C1.33398 11.6816 4.31875 14.6663 8.00065 14.6663Z"
              stroke="#D1433A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="c-line"
              d="M5.33398 8H10.6673"
              stroke="#D1433A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <input
        @change="changeTicketCount"
        @input="inputTicketCount($event.target.value)"
        type="number"
        class="count"
        name=""
        v-model="tickets.countTicket"
      />
      <div
        class="plus"
        @click="plusTicket"
        :class="{ plus_minus_Sold_Out: !ticketInfo.available_tickets > 0 }"
      >
        <div class="svg-icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="c-line"
              d="M8.00065 14.6663C11.6825 14.6663 14.6673 11.6816 14.6673 7.99967C14.6673 4.31778 11.6825 1.33301 8.00065 1.33301C4.31875 1.33301 1.33398 4.31778 1.33398 7.99967C1.33398 11.6816 4.31875 14.6663 8.00065 14.6663Z"
              stroke="#D1433A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="c-line"
              d="M8 5.33301V10.6663"
              stroke="#D1433A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              class="c-line"
              d="M5.33398 8H10.6673"
              stroke="#D1433A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticketInfo: Object,
  },
  data() {
    return {
      tickets: {
        name: this.ticketInfo.name,
        price: this.ticketInfo.price,
        id: this.ticketInfo.id,
        countTicket: 0,
      },
    };
  },
  methods: {
    plusTicket() {
      if (this.ticketInfo.available_tickets > this.tickets.countTicket) {
        this.tickets.countTicket = +this.tickets.countTicket + 1;
      }
    },
    minusTicket() {
      if (this.tickets.countTicket >= 1) {
        this.tickets.countTicket = +this.tickets.countTicket - 1;
      }
    },
    zeroTicket() {
      this.tickets.countTicket = 0;
    },
    changeTicketCount() {
      if (this.tickets.countTicket === "") {
        this.tickets.countTicket = 0;
      }
    },
    inputTicketCount(value) {
      if (+value[0] === 0) {
        this.tickets.countTicket = value.slice(1);
      } else if (+value < 0) {
        this.tickets.countTicket = 0;
      } else if (+value > this.ticketInfo.available_tickets) {
        this.tickets.countTicket = this.ticketInfo.available_tickets;
      }
    },
  },
  watch: {
    "tickets.countTicket"() {
      this.$emit("changeActiveBtn");
    },
  },
};
</script>

<style lang="scss" scoped>
.item_Sold_Out {
  background: #f3f3f3 !important;
}
.plus_minus_Sold_Out {
  background: #e6eaf0 !important;
  .svg-icon {
    svg {
      path {
        stroke: #47505b !important;
      }
    }
  }
}
.item_active {
  border: 1px solid #D1433A !important;
  background: #D1433A1A !important;
}
</style>