<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="confirmation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog confirmation_link_box">
        <div class="modal-content">
          <div class="close-button" data-dismiss="modal" aria-label="Close">
            <div class="svg-icon" v-on:click="closeModal">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body">
            <div class="body-title Font_semibold">
              Confirmation link has been sent!
            </div>
            <div class="modal-title-sm grey-text">
              Please check your email for the confirmation link. Click on link
              sent via email to confirm account and to gain access to your
              Playground account.
            </div>
            <div
                class="btn btn-login m-t-20 Font_semibold"
                data-dismiss="modal"
                v-on:click="closeModal"
                aria-label="Close"
            >
              OK, CLOSE
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
};
</script>
