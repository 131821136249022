<template>
  <div class="wr_Attendees_card">
    <div class="wr_users_info">
      <div class="wr_imgDiv"><img src="" alt="img user" /></div>
      <div>
        <h6>Uesr Name</h6>
        <span
          ><svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.25 9.5C5.25 9.5 4.5 9.5 4.5 8.75C4.5 8 5.25 5.75 8.25 5.75C11.25 5.75 12 8 12 8.75C12 9.5 11.25 9.5 11.25 9.5H5.25ZM8.25 5C8.84674 5 9.41903 4.76295 9.84099 4.34099C10.2629 3.91903 10.5 3.34674 10.5 2.75C10.5 2.15326 10.2629 1.58097 9.84099 1.15901C9.41903 0.737053 8.84674 0.5 8.25 0.5C7.65326 0.5 7.08097 0.737053 6.65901 1.15901C6.23705 1.58097 6 2.15326 6 2.75C6 3.34674 6.23705 3.91903 6.65901 4.34099C7.08097 4.76295 7.65326 5 8.25 5Z"
              fill="#47505B"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.912 9.49983C3.80082 9.26569 3.74537 9.00899 3.75 8.74983C3.75 7.73358 4.26 6.68733 5.202 5.95983C4.73182 5.81496 4.24196 5.74411 3.75 5.74983C0.75 5.74983 0 7.99983 0 8.74983C0 9.49983 0.75 9.49983 0.75 9.49983H3.912Z"
              fill="#47505B"
            />
            <path
              d="M3.375 5C3.87228 5 4.34919 4.80246 4.70083 4.45083C5.05246 4.09919 5.25 3.62228 5.25 3.125C5.25 2.62772 5.05246 2.15081 4.70083 1.79917C4.34919 1.44754 3.87228 1.25 3.375 1.25C2.87772 1.25 2.40081 1.44754 2.04917 1.79917C1.69754 2.15081 1.5 2.62772 1.5 3.125C1.5 3.62228 1.69754 4.09919 2.04917 4.45083C2.40081 4.80246 2.87772 5 3.375 5Z"
              fill="#47505B"
            />
          </svg>
          2 mutual friends</span
        >
      </div>
    </div>
    <div class="wr_friend_icon for_hover_empty_border_blue">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="28.5"
          height="28.5"
          rx="3.25"
          stroke="#D1433A"
          stroke-width="1.5"
        />
        <path
          d="M19.0345 21.1553V19.776C19.0345 19.0443 18.7439 18.3427 18.2265 17.8253C17.7092 17.308 17.0075 17.0173 16.2759 17.0173H10.7586C10.027 17.0173 9.32533 17.308 8.80798 17.8253C8.29064 18.3427 8 19.0443 8 19.776V21.1553"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5174 14.2585C15.041 14.2585 16.276 13.0234 16.276 11.4998C16.276 9.97629 15.041 8.74121 13.5174 8.74121C11.9939 8.74121 10.7588 9.97629 10.7588 11.4998C10.7588 13.0234 11.9939 14.2585 13.5174 14.2585Z"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.3916 11.4736V15.9125"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.2471 13.6934H23.686"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wr_Attendees_card {
  padding-right: 20px;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 30px 4px 4px 30px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wr_users_info {
    display: flex;
    align-items: center;
    .wr_imgDiv {
      margin-right: 20px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #c3c3c3;
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    div {
      color: #071526;
      h6 {
        font-family: HelveticaNeueMedium;
        font-weight: 500;
        font-size: 13px;
        margin: 0 0 6px 0;
      }
      span {
        font-family: HelveticaNeueNormal;
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;

        font-weight: 400;
        font-size: 11px;
        svg {
          margin-right: 8px;
        }
      }
    }
  }
  .wr_friend_icon {
    cursor: pointer;
  }
}
</style>