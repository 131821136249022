<template>
  <div>
    <div class="magazine">
      <section>
        <div class="">
          <div class="row">
            <div class="col">
              <div class="breadcrumbs-item">
                <router-link
                  :to="{
                    name: 'events.Detail',
                    params: {
                      id: GET_BUYTICKET_INFO && GET_BUYTICKET_INFO[0].event_id,
                    },
                  }"
                  class="back-link"
                >
                  <div class="svg-icon">
                    <svg
                      width="7"
                      height="14"
                      viewBox="0 0 7 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.86328 12.7285L0.134281 7.00052L5.86328 1.27152"
                        stroke="#071526"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="text-link">EVENT</div>
                </router-link>
                <div class="title">
                  BUY TICKETS TO EVENT «{{
                    GET_BUYTICKET_INFO && GET_BUYTICKET_INFO[0].event.title
                  }}»
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
        <div class="">
          <div class="row">
            <div class="col-sm-4">
              <div class="card-wrapper">
                <div class="img_div">
                  <img
                    :src="
                      GET_BUYTICKET_INFO &&
                      GET_BUYTICKET_INFO[0].event.artwork_url
                    "
                    alt=""
                  />
                </div>
                <div class="info-wrap">
                  <div class="title-wrap">
                    <div class="ico-block">
                      {{
                        monthOnly(
                          GET_BUYTICKET_INFO &&
                            GET_BUYTICKET_INFO[0].event.started_at
                        )
                      }}<br />{{
                        dayOnly(
                          GET_BUYTICKET_INFO &&
                            GET_BUYTICKET_INFO[0].event.started_at
                        )
                      }}
                    </div>
                    <div class="text-block">
                      <div class="title">
                        {{
                          GET_BUYTICKET_INFO &&
                          GET_BUYTICKET_INFO[0].event.title
                        }}
                      </div>
                      <div class="subtitle">
                        {{
                          GET_BUYTICKET_INFO &&
                          GET_BUYTICKET_INFO[0].event.privacy.split("_")[0]
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="addres-wrapp">
                    <div class="ico-block">
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.75 7.5C14.75 12.75 8 17.25 8 17.25C8 17.25 1.25 12.75 1.25 7.5C1.25 5.70979 1.96116 3.9929 3.22703 2.72703C4.4929 1.46116 6.20979 0.75 8 0.75C9.79021 0.75 11.5071 1.46116 12.773 2.72703C14.0388 3.9929 14.75 5.70979 14.75 7.5Z"
                            stroke="#47505B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 9.75C9.24264 9.75 10.25 8.74264 10.25 7.5C10.25 6.25736 9.24264 5.25 8 5.25C6.75736 5.25 5.75 6.25736 5.75 7.5C5.75 8.74264 6.75736 9.75 8 9.75Z"
                            stroke="#47505B"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="text-block">
                      <div class="title">
                        {{
                          GET_BUYTICKET_INFO &&
                          GET_BUYTICKET_INFO[0].event.location
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="text-wrapp">
                    {{
                      GET_BUYTICKET_INFO && GET_BUYTICKET_INFO[0].event.about
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="buy-ticket-wrapper">
                <div class="title">
                  Buy Tickets to event «{{
                    GET_BUYTICKET_INFO && GET_BUYTICKET_INFO[0].event.title
                  }}»
                </div>
                <div class="custom-table">
                  <div class="custom-table-head">
                    <div>TICKET</div>
                    <div>AVAILABLE</div>
                    <div>PRICE</div>
                    <div>COUNT</div>
                  </div>
                  <perfect-scrollbar>
                    <div class="custom-table-body">
                      <TicetsInfo
                        @changeActiveBtn="changeActiveBtn"
                        :ref="'ref' + item.id"
                        v-for="item in GET_BUYTICKET_INFO"
                        :key="item.id"
                        :ticketInfo="item"
                      />
                    </div>
                  </perfect-scrollbar>
                </div>
                <div class="button-row">
                  <button
                    class="btn custom-btn btn-cancel"
                    @click.stop="zeroTicket"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    @click="buyTicket"
                    class="btn custom-btn btn-buy"
                    :class="{ active_btn: activeBtn }"
                  >
                    <div class="svg-icon">
                      <svg
                        width="16"
                        height="11"
                        viewBox="0 0 16 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.913105 0.336895L0.913107 0.686266L0.913122 3.32841L0.913109 3.6778L1.26251 3.6778C2.13494 3.67781 2.8347 4.37759 2.8347 5.24999C2.83468 6.12244 2.13493 6.82219 1.26249 6.82219L0.913108 6.82218L0.913122 7.17156L0.913123 9.81372L0.913109 10.1631L1.2625 10.1631L15.2375 10.1631L15.5869 10.1631L15.5869 9.81374L15.5869 7.17157L15.5869 6.8222L15.2375 6.8222C14.3651 6.8222 13.6653 6.12246 13.6653 5.25001C13.6653 4.3776 14.3651 3.67781 15.2375 3.67782L15.5869 3.67782L15.5869 3.32844L15.5869 0.68629L15.5869 0.3369L15.2375 0.336905L1.26249 0.336882L0.913105 0.336895ZM1.61186 1.03565L10.5209 1.03565L10.5209 2.08377L11.2197 2.08378L11.2197 1.03564L14.8881 1.03565L14.8881 3.01181C13.8031 3.18122 12.9666 4.11898 12.9666 5.24999C12.9666 6.38099 13.8032 7.31327 14.8881 7.48273L14.8881 9.46436L11.2197 9.46435L11.2197 8.41623L10.5209 8.41621L10.521 9.46434L1.61186 9.46433L1.61186 7.48272C2.69683 7.31326 3.53344 6.38099 3.53344 5.24999C3.53343 4.11898 2.6969 3.18122 1.61186 3.01181L1.61186 1.03565ZM10.521 3.50312L10.5209 4.18003L11.2197 4.18004L11.2197 3.50311L10.521 3.50312ZM10.521 6.31996L10.5209 6.99688L11.2197 6.99687L11.2197 6.31996L10.521 6.31996Z"
                          fill="white"
                          stroke="white"
                          stroke-width="0.4"
                        />
                      </svg>
                    </div>
                    <span>Buy Tickets</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="open_Added_to_Cart" class="Signup_pop_overlay">
      <div class="wr_added_to_cart">
        <div class="title">
          <span>Added to Cart</span>
          <div @click="close_Added_to_Cart">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 4.5L4.5 13.5"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.5 4.5L13.5 13.5"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="wr_conten">
          <div>Item added to cart.<br>Proceed to Checkout or continue Shopping</div>
          <perfect-scrollbar>
            <div v-for="item in tickets" :key="item.id">
              <div class="tickets_info" v-if="item.countTicket">
                <div>
                  <div class="img_div">
                    <img
                      :src="
                        GET_BUYTICKET_INFO &&
                        GET_BUYTICKET_INFO[0].event.artwork_url
                      "
                      alt=""
                    />
                  </div>
                  <div>{{ item.name }} x {{ item.countTicket }}</div>
                </div>
                <div>$ {{ item.countTicket * item.price }}</div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="wr_btn">
          <button @click="close_Added_to_Cart" class="cencel">CANCEL</button>
          <button @click="checkout" class="checkout">CHECKOUT</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormats from "@/mixins/dateFormats.js";
import TicetsInfo from "./TicketInfo.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [dateFormats],
  components: {
    TicetsInfo,
  },
  data() {
    return {
      activeBtn: null,
      open_Added_to_Cart: false,
      tickets: [],
    };
  },
  methods: {
    ...mapActions(["getbuyTickets", "addToCart"]),
    changeActiveBtn() {
      this.tickets.length = 0;
      let arrCountsTicket = Object.values(this.$refs)
        .filter((el) => el[0] && el)
        .map((el) => {
          this.tickets.push(el[0].tickets);
          return el[0].tickets.countTicket;
        });
      if (!arrCountsTicket.every((el) => el === 0 || el === "")) {
        this.activeBtn = true;
      } else {
        this.activeBtn = false;
      }
    },
    zeroTicket() {
      Object.values(this.$refs)
        .filter((el) => el[0] && el)
        .forEach((el) => el[0].zeroTicket());
    },
    buyTicket() {
      if (this.activeBtn) {
        this.open_Added_to_Cart = true;
      }
    },
    close_Added_to_Cart() {
      this.open_Added_to_Cart = false;
    },
    async checkout() {
      let cartItems = this.tickets
        .filter((el) => el.countTicket > 0)
        .map((el) => ({
          ticket_id: el.id,
          quantity: el.countTicket,
          total_amount: el.countTicket * el.price,
        }));
      await this.addToCart({ cartItems: cartItems });
      this.$router.push('/events/page/shoping/cart');
    },
  },
  computed: {
    ...mapGetters(["GET_BUYTICKET_INFO"]),
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.getbuyTickets(this.$route.params.id);
    await this.$store.dispatch("loading", false);
    console.log("GET_BUYTICKET_INFO", this.GET_BUYTICKET_INFO);
  },
};
</script>

<style lang="scss" scoped>
.ps {
  max-height: 350px !important;
}
.img_div {
  height: 170px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.active_btn {
  background: #D1433A !important;
  &:hover {
    background: #DA6961 !important;
  }
}
.Signup_pop_overlay {
  .wr_added_to_cart {
    width: 370px;
    padding: 20px;
    background: #fff;
    border: 1px solid #e6eaf0;
    border-radius: 2px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      color: #071526;
      font-size: 18px;
      div {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e6eaf0;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background: #dde2e9;
        }
      }
    }
    .wr_conten {
      margin: 20px 0;
      padding: 14px 0 20px 0;
      border-top: 1px solid #e6eaf0;
      border-bottom: 1px solid #e6eaf0;
      font-size: 15px;
      font-family: HelveticaNeueMedium;
      opacity: 0.9;
      color: #071526;
      .ps {
        max-height: 265px !important;
      }
      .tickets_info {
        padding-right: 20px;
        margin: 16px 0px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #D1433A1A;
        box-shadow: 0px 4px 5px rgba(219, 219, 219, 0.1);
        div {
          display: flex;
          align-items: center;
          font-family: HelveticaNeueBold;
          font-size: 13px;
        }
        .img_div {
          margin-right: 20px;
          width: 50px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .wr_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        font-weight: 700;
        color: #8b949f;
        font-size: 13px;
        font-family: HelveticaNeueBold;
        background: #ffffff;
        border: 1.3px solid #8b949f;
        border-radius: 4px;
        outline: none;
        height: 40px;
        width: 100px;
      }
      .checkout {
        border: none;
        width: 220px;
        background: #D1433A;
        color: #fff;
        &:hover {
          background: #DA6961;
        }
      }
    }
  }
}
</style>