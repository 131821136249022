<template>
    <div class="my-3">
        <a v-for="tag in allTags" :key="tag.id" class="d-inline-block mr-2 text-primary text-decoration-underline" href="#">#{{ tag.tag.replace(/#/g, '') }}</a>
    </div>
</template>

<script>
export default {
    props: {
        allTags: {
            type: Array,
        }
    }
}
</script>