<template>
  <div class="widget-follow widgt-friend">
    <div class="widget-title">
      Fans
      <!-- <div class="count-follower">27</div> -->
      <div class="link-more near_arrow without-slider in-title pointerClass"><a v-on:click="changeTab">show All
        <span class="sl-arrow"> <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
      </a>
      </div>
    </div>
    <div v-for="(fan, index) in fans.slice(0,3)" :key="index">
      <FansTile :item="fan" />
    </div>
  </div>
</template>
<script type="application/javascript">
import FansTile from "../../../DesignGuide/Music/Components/FansTile";
import { getFansOfSongsAlbumsPodcast } from "../../../../apis/APIs";

export default {
  data() {
    return {
      fans: []
    }
  },
  components: {
    FansTile
  },
  async mounted(){
    await this.getFansData()
  },
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab('friends');
    },
    async getFansData(){
      const song_id = this.$route.params.id
      try {
        await this.$store.dispatch('loading', true);
        const {data} = await getFansOfSongsAlbumsPodcast(song_id);
        const {information, success} = data || {};
        this.fans = information.data || []
        await this.$store.dispatch('loading', false);
      } catch (e) {
        await this.$store.dispatch('loading', false)
        console.error(e.message)
      }
    }
  }
}
</script>
