import { render, staticRenderFns } from "./CreateModalGroupFriendBar.vue?vue&type=template&id=3674874d&scoped=true"
import script from "./CreateModalGroupFriendBar.vue?vue&type=script&lang=js"
export * from "./CreateModalGroupFriendBar.vue?vue&type=script&lang=js"
import style0 from "./CreateModalGroupFriendBar.vue?vue&type=style&index=0&id=3674874d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3674874d",
  null
  
)

export default component.exports