<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="restore-pass"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeLoginModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <!-- <img src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/logo.png" alt="">  -->
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body restore_pass_body">
            <div class="body-title Font_semibold">
              <a class="back-arrow">
                <div class="svg-icon" v-on:click="goBack">
                  <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        class="c-line-1"
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M15 10L10 15L15 20"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M20 15H10"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
              Restore Password
            </div>
            <div class="input-wrap">
              <div class="svg-icon">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M2.66732 2.66602H13.334C14.0673 2.66602 14.6673 3.26602 14.6673 3.99935V11.9993C14.6673 12.7327 14.0673 13.3327 13.334 13.3327H2.66732C1.93398 13.3327 1.33398 12.7327 1.33398 11.9993V3.99935C1.33398 3.26602 1.93398 2.66602 2.66732 2.66602Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                  <path
                      d="M14.6673 4L8.00065 8.66667L1.33398 4"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                  type="text"
                  placeholder="afanasiipetrov@mail.ru"
                  :value="email"
              />
            </div>
            <div
                class="btn btn-login m-t-20 Font_semibold restore_link"
                v-on:click="restorePassword"
                :disabled="is_valid_btn"
                :style="[is_valid_btn ? { cursor: 'not-allowed' } : { cursor: 'pointer' }]"
            >
              {{ loading ? "please wait..." : "SEND RESTORE LINK" }}
            </div>
            <div class="custom-hr"></div>
            <div class="modal-title-sm grey-text">
              Restore link sent to your e-mail. Follow the instructions from
              e-mail and change your password
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as Helper from '../../../apis/Helper.js';
import {forgotPassword} from '../../../apis/APIs';
import {LOGIN_ERRORS, LOGIN_FORM_EMAIL} from '../../../store/types';

export default {
  data() {
    return {
      email: "",
      message: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      form_data: "login_form_map_to_props",
      check_email_data: "signup_email_form_map_to_props",
    }),
    is_valid_btn() {
      return !this.email;
    }
  },
  mounted() {
    this.init();
  },
  actions: {
    ...mapMutations([
      "updateLoginFormData",
    ]),
  },
  methods: {
    ...mapMutations([
      "updateSignupFormData",
    ]),
    init() {
      const {email = ""} = this.form_data || {};

      if (email) return this.email = email;

      this.email = this.check_email_data && this.check_email_data.email ? this.check_email_data.email : "";
    },
    closeLoginModal: function () {
      this.updateSignupFormData({});
      this.$parent.closeModal();
    },
    goBack: function () {
      this.$parent.newModal("login");
    },
    restorePassword: async function () {
      try {
        const {loading, email} = this;

        if (loading) return;

        // const body = {
        //   email
        // }
        var formdata = new FormData();
        formdata.append('email', email);

        this.loading = true;
        const res = await forgotPassword(formdata);
        this.loading = false;

        const {status, message} = res.data || {};

        this.message = "";

        if (status === 200) {
          this.$parent.newModal("reset-password-security-code");

          this.updateLoginFormData({
            [LOGIN_FORM_EMAIL]: this.email
          })

          console.log("res ===> forgot password one", res);

        } else {
          this.message = message;
        }

      } catch (error) {
        this.loading = false;
        this.message = error && error.message ? error.message : "Please try again.";
      }
    }
  }
};
</script>
