<template>
  <div class="container-fluid px-0 chart-top-selling-list">
    <!-- Top 100 Played Songs Heading -->
    <div class="card weekly-top annotation charts">
      <div class="card genres-list annotation type-list">
        <div class="row line-1">
          <div class="col-2 d-flex align-items-center justify-content-center">
            <div class="chart-tile-p">#</div>
            <div>LW</div>
          </div>
          <div class="col-3 d-flex align-items-center justify-content-center">
            <div class="track ml-5 mr-0">Title</div>
          </div>
          <div class="col-1 d-flex justify-content-between align-items-center">
            <div class="ml-2">Album</div>
          </div>
          <div
            class="col-1 d-flex justify-content-between align-items-center"
          ></div>
          <div
            class="col-1 d-flex justify-content-between align-items-center"
            style=""
          >
            <div>
              <div class="ml-3">Time</div>
            </div>
          </div>
          <div
            class="col-3 d-flex justify-content-start align-items-center pl-0"
          >
            <div
              class="js-genre-date d-flex align-items-center"
              style="margin-right: 34px"
            >
              <div class="text mr-1">Date</div>
              <div class="svg-icon calendar mr-1">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-calendar"
                >
                  <path
                    d="M3.52899 1C3.41273 1 3.31851 1.09423 3.31851 1.21047V2.50292C2.95725 2.59752 2.68709 2.92569 2.68709 3.31522C2.68709 3.77768 3.06651 4.15711 3.52899 4.15711C3.99146 4.15711 4.37088 3.77768 4.37088 3.31522C4.37088 2.92569 4.10071 2.59752 3.73946 2.50292V1.21047C3.73946 1.09423 3.64523 1 3.52899 1ZM8.15942 1C8.04316 1 7.94895 1.09423 7.94895 1.21047V2.50292C7.58769 2.59752 7.31752 2.92569 7.31752 3.31522C7.31752 3.77768 7.69695 4.15711 8.15942 4.15711C8.6219 4.15711 9.00132 3.77768 9.00132 3.31522C9.00132 2.92569 8.73114 2.59752 8.36989 2.50292V1.21047C8.36989 1.09423 8.27567 1 8.15942 1ZM2.05567 1.63142C1.47464 1.63142 1 2.10606 1 2.68708V9.20521C1 9.78622 1.47464 10.2609 2.05567 10.2609H9.62616C10.2072 10.2609 10.6818 9.78622 10.6818 9.20521V2.68708C10.6818 2.10606 10.2072 1.63142 9.62616 1.63142H8.99802C8.88683 1.62984 8.78427 1.73069 8.78427 1.8419C8.78427 1.9531 8.88683 2.05394 8.99802 2.05237H9.62616C9.98085 2.05237 10.2609 2.33238 10.2609 2.68708V4.57806H1.42095V2.68708C1.42095 2.33238 1.70095 2.05237 2.05567 2.05237H2.68379C2.79499 2.05395 2.89756 1.9531 2.89756 1.8419C2.89756 1.73069 2.79499 1.62985 2.68379 1.63142H2.05567ZM4.36759 1.63142C4.25134 1.63142 4.15711 1.72565 4.15711 1.8419C4.15711 1.95814 4.25134 2.05237 4.36759 2.05237H7.31423C7.43047 2.05237 7.5247 1.95814 7.5247 1.8419C7.5247 1.72565 7.43047 1.63142 7.31423 1.63142H4.36759ZM3.52899 2.89427C3.76396 2.89427 3.94993 3.08023 3.94993 3.31522C3.94993 3.55019 3.76396 3.73617 3.52899 3.73617C3.29401 3.73617 3.10804 3.55019 3.10804 3.31522C3.10804 3.08023 3.29401 2.89427 3.52899 2.89427ZM8.15942 2.89427C8.39439 2.89427 8.58037 3.08023 8.58037 3.31522C8.58037 3.55019 8.39439 3.73617 8.15942 3.73617C7.92445 3.73617 7.73847 3.55019 7.73847 3.31522C7.73847 3.08023 7.92445 2.89427 8.15942 2.89427ZM1.42095 4.99901H10.2609V9.20521C10.2609 9.55991 9.98085 9.83992 9.62616 9.83992H2.05567C1.70095 9.83992 1.42095 9.55991 1.42095 9.20521V4.99901Z"
                    fill="#47505B"
                    stroke="#47505B"
                    stroke-width="0.4"
                    class="c-line-1 c-fill-1"
                  ></path>
                </svg>
              </div>
              <div class="svg-icon">
                <svg
                  width="11"
                  height="6"
                  viewBox="0 0 11 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="svg-arrow-down"
                >
                  <path
                    d="M9.83301 1L5.60201 5.3L1.00001 1"
                    stroke="#47505B"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="c-line-4"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="">Popularity</div>
          </div>
          <div
            class="col-1 d-flex align-items-center justify-content-end"
          ></div>
        </div>
      </div>
    </div>
    <!-- Top 100 Played Songs Card Tile -->
    <div class="mb-4">
      <div
        v-for="(song, index) in listData"
        :key="index"
        class="card weekly-top charts w-1170 play-pause-button-style"
        :class="[showMore === index ? 'open' : '']"
      >
        <div class="row no-gutters">
          <div class="col line-1">
            <div class="cover">
              <img
                class="w-100 h-100"
                :src="song.artwork_url"
                alt="Generic placeholder image"
              />
            </div>
            <div
              class="play-button js-play-button new-play-button"
              :class="[
                icon && last_play_song_index === index + '_' + song.id
                  ? 'active'
                  : '',
              ]"
              @click="
                playPauseSong(index + '_' + song.id, itemTypeResponse(song))
              "
            >
              <div class="svg-icon">
                <svg
                  width="13"
                  height="16"
                  viewBox="0 0 13 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="play-type-1"
                >
                  <path
                    class="c-fill-1 c-line-1"
                    d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                    stroke="white"
                    stroke-width="1.3"
                  ></path>
                </svg>
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    fill="white"
                  ></path>
                  <path
                    class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    stroke="white"
                    stroke-width="1.3"
                  ></path>
                </svg>
                <svg
                  width="10"
                  height="14"
                  viewBox="0 0 10 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="c-line-1 c-fill-1"
                    d="M1 1V13"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                  <path
                    class="c-line-1 c-fill-1"
                    d="M9 1V13"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="position up">
              <div class="current-week">1</div>
              <div class="last-week">
                5
                <div class="svg-icon">
                  <svg
                    class="svg-arrow"
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M9.77613 6.30579C9.49401 6.57473 9.05152 6.56317 8.78346 6.27846L5 2.26004L1.21654 6.27846C0.948477 6.56317 0.505993 6.57473 0.223867 6.30579C-0.063294 6.03206 -0.076181 5.57137 0.196705 5.28154L4.49008 0.721538C4.62431 0.578974 4.80891 0.5 5 0.5C5.19109 0.5 5.37569 0.578974 5.50992 0.721538L9.80329 5.28154C10.0762 5.57137 10.0633 6.03206 9.77613 6.30579Z"
                      fill="#16C674"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="tile-music-waves tops">
              <div class="music-player">
                <TileViewWavesPlayer
                  :key="index + '_' + song.id"
                  v-if="audioUrl(song) !== ''"
                  :ref="'songPlayerRef' + index + '_' + song.id"
                  :song-loaded="songLoaded"
                  :is-song-playing.sync="isSongPlaying"
                  :music="audioUrl(song)"
                  :latest_list_id="song.id"
                  @updateIcon="updateIcon"
                />
              </div>
            </div>
            <div
              class="description"
              @click="redirectToFullViewPage($event, song.type, song.id)"
            >
              <div class="first-line">
                <a href="javascript:void(0)">{{
                  song.page ? getArtist(song.page) : "Artist"
                }}</a>
              </div>
              <div class="second-line">
                <a href="javascript:void(0)">{{ song.name }}</a>
              </div>
            </div>
            <div class="album">
              <div class="album">
                {{ song.album ? song.album.title : "Album" }}
              </div>
            </div>
            <div class="time">
              {{
                song && song.duration
                  ? convertSecondsIntoMinuets(song.duration)
                  : "0:00"
              }}
            </div>
            <div class="date">
              {{
                song.created_at
                  ? convertDateIntoHumanReadableForm(song.created_at)
                  : ""
              }}
            </div>
            <div class="other d-flex">
              <div class="popularity">
                <div class="empty">
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                </div>
                <div class="full">
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                  <div class="column"></div>
                </div>
                <div class="count">100K</div>
              </div>
              <div class="actions d-flex align-items-center">
                <div
                  class="wishlist js-wishlist"
                  :class="{ active: song.favorite === true }"
                  @click="favouriteUnFavourite($event, song)"
                >
                  <div class="svg-icon">
                    <b-spinner
                      v-if="spinner && favorite_id === song.id"
                      small
                      label="Small Spinner"
                      class="spinner-color"
                    ></b-spinner>
                    <svg
                      v-else
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                        stroke="#47505B"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="c-line-1 c-fill-1"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="no-sell-empty-div" v-if="stream">&nbsp;</div>
                <div
                  v-else
                  class="btn btn-icon-border shop-cart text m-r-22 float-left"
                >
                  <div class="svg-icon">
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="shop-cart-v2"
                    >
                      <path
                        class="c-line-1"
                        d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="c-line-1"
                        d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div>${{ Math.trunc(getPrice(song)) }}</div>
                </div>
                <div class="more">
                  <div
                    class="dropdown vertical more-dropdown"
                    @click="
                      openListOption(
                        $event,
                        itemTypeResponse(song).type === 'song'
                          ? [itemTypeResponse(song)]
                          : itemTypeResponse(song).album_songs,
                        'default_option_list',
                        song.reviewable ? song.reviewable : song
                      )
                    "
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-display="static"
                  >
                    <div class="svg-icon more-position">
                      <svg
                        width="15"
                        height="3"
                        viewBox="0 0 15 3"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="svg-more-hor"
                      >
                        <path
                          class="c-fill-1"
                          d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                          fill="white"
                        ></path>
                        <path
                          class="c-fill-1"
                          d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                          fill="white"
                        ></path>
                        <path
                          class="c-fill-1"
                          d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                          fill="white"
                        ></path>
                      </svg>
                      <svg
                        width="3"
                        height="15"
                        viewBox="0 0 3 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="svg-more"
                      >
                        <path
                          d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                          class="c-fill-1"
                        ></path>
                        <path
                          d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                          class="c-fill-1"
                        ></path>
                        <path
                          d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                          class="c-fill-1"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="extend js-extend" @click="openOptions(index)">
                  <div class="svg-icon">
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="svg-arrow-down"
                    >
                      <path
                        d="M9.83301 1L5.60201 5.3L1.00001 1"
                        class="c-line-4"
                        stroke="#47505B"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-gutters line-2 align-items-center justify-content-end px-3"
        >
          <div
            class="btn-group-social left-button"
            style="float: left;
    display: inline-flex;
    left: 352px;
    position: relative;}"
          >
            <button
              :class="[
                'btn btn-information text love-button',
                song.favorite === true ? 'active-background active' : '',
              ]"
              @click="favouriteUnFavourite($event, song)"
            >
              <div class="svg-icon">
                <b-spinner
                  v-if="spinner && favorite_id === song.id"
                  small
                  label="Small Spinner"
                  class="spinner-color"
                ></b-spinner>
                <svg
                  v-else
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <span>{{ getCount(song, "loves") }}</span>
            </button>
            <button
              class="btn btn-information text comment-button"
              @click="redirectToFullViewPage($event, song.type, song.id)"
            >
              <div class="svg-icon">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="svg-icon-text"
                  ></path>
                </svg>
              </div>
              <span>{{ getCount(song, "comment_count") }}</span>
            </button>
            <button
              class="btn btn-information text repost-button"
              @click="
                repost(
                  $event,
                  song && song.page && song.page.id ? song.id : '',
                  index
                )
              "
            >
              <div class="svg-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_15137_101379)">
                    <path
                      d="M11.333 0.666016L13.9997 3.33268L11.333 5.99935"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 7.33398V6.00065C2 5.29341 2.28095 4.61513 2.78105 4.11503C3.28115 3.61494 3.95942 3.33398 4.66667 3.33398H14"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.66667 15.3333L2 12.6667L4.66667 10"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 8.66602V9.99935C14 10.7066 13.719 11.3849 13.219 11.885C12.7189 12.3851 12.0406 12.666 11.3333 12.666H2"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_15137_101379">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span>{{ getCount(song, "repost_count") }}</span>
            </button>
            <button
              class="btn btn-information share-button"
              @click="share($event, index)"
            >
              <div class="svg-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.9995 10.334V13.445C14.9995 13.8575 14.8357 14.2532 14.5439 14.5449C14.2522 14.8366 13.8566 15.0005 13.444 15.0005H2.5555C2.14296 15.0005 1.74731 14.8366 1.4556 14.5449C1.16388 14.2532 1 13.8575 1 13.445V10.334"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8889 4.88876L8.00009 1L4.11133 4.88876"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 1V10.333"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </button>
            <PostShare
              :post="song"
              :ref="'share' + index"
              :share_media_check="true"
              :fullViewPageCheck="true"
            />
            <div class="more_btn dropright">
              <button
                class="btn btn-information dropdown more-dropdown"
                @click="
                  openListOption(
                    $event,
                    itemTypeResponse(song).type === 'song'
                      ? [itemTypeResponse(song)]
                      : itemTypeResponse(song).album_songs,
                    'default_option_list',
                    song.reviewable ? song.reviewable : song
                  )
                "
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-display="static"
              >
                <div class="svg-icon-plus more-position">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    width="16.562px"
                    height="16.562px"
                    viewBox="0 0 93.562 93.562"
                    style="enable-background: new 0 0 93.562 93.562"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        class="c-line-1-fill"
                        d="M87.952,41.17l-36.386,0.11V5.61c0-3.108-2.502-5.61-5.61-5.61c-3.107,0-5.61,2.502-5.61,5.61l0.11,35.561H5.61
                       		c-3.108,0-5.61,2.502-5.61,5.61c0,3.107,2.502,5.609,5.61,5.609h34.791v35.562c0,3.106,2.502,5.61,5.61,5.61
                       		c3.108,0,5.61-2.504,5.61-5.61V52.391h36.331c3.108,0,5.61-2.504,5.61-5.61C93.562,43.672,91.032,41.17,87.952,41.17z"
                      />
                    </g>
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div
            class="btn-group-social btn-group-social-2 justify-content-end"
            style="float: right"
          >
            <div
              v-if="stream"
              class="add-queue-btn mx-2 float-left mb-2"
              @click="addToQueueSongs(song)"
            >
              <div class="svg-icon">
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0657 6.13184H18.0657"
                    stroke="#071526"
                    stroke-width="1.5"
                  />
                  <path
                    d="M10.0657 2.33203H17.9657"
                    stroke="#071526"
                    stroke-width="1.5"
                  />
                  <path
                    d="M10.0657 9.93213H18.0657"
                    stroke="#071526"
                    stroke-width="1.5"
                  />
                  <path
                    d="M1.70458 11.375C1.65029 11.3818 1.59536 11.3763 1.54345 11.359C1.49154 11.3417 1.44417 11.313 1.40478 11.275C1.29689 11.212 1.2048 11.1252 1.13549 11.0212C1.06618 10.9173 1.02132 10.7989 1.00463 10.675V1.67502C0.987431 1.54354 1.01848 1.41028 1.09203 1.29995C1.16559 1.18962 1.2768 1.10971 1.40478 1.07502C1.51484 1.02557 1.63398 1 1.75463 1C1.87529 1 1.99467 1.02557 2.10473 1.07502L9.00463 5.57502C9.15331 5.68929 9.25545 5.8536 9.29223 6.03749C9.32901 6.22137 9.29787 6.41234 9.20458 6.57502L9.00463 6.77502L2.10473 11.275C1.96377 11.2601 1.82192 11.2955 1.70458 11.375"
                    stroke="#071526"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div class="price">Add to queue</div>
            </div>
            <button
              v-else
              class="btn btn-icon-border shop text"
              style="float: left"
            >
              <div class="svg-icon">
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="shop-cart-v2"
                >
                  <path
                    class="c-line-1"
                    d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z"
                    stroke="#D1433A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    class="c-line-1"
                    d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                    stroke="#D1433A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <div class="price">${{ Math.trunc(getPrice(song)) }}</div>
            </button>
            <div class="btn btn-play-count pg-play-btn-count">
              <div class="svg-icon">
                <svg
                  width="7"
                  height="8"
                  viewBox="0 0 7 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="small-play-svg"
                >
                  <path
                    class="c-fill-1"
                    d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                    fill="#8B949F"
                  ></path>
                </svg>
              </div>
              <span>{{ getCount(song, "plays") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";
import Vue from "vue";
import { set_favourite_on_songs } from "../../../apis/APIs";
import TileViewWavesPlayer from "@/components/DesignGuide/Music/Components/TileViewWavesPlayer.vue";
import PostShare from "@/components/Home/NewsFeed/PostItem/PostShare";

export default {
  data() {
    return {
      showSubMenu: "none",
      showMore: null,
      selectedFavourite: [],
      wavesurfer: null,
      songLoaded: true,
      isSongPlaying: false,
      icon: false,
      last_play_song_index: null,
      currentPlayingIndex: null,
      favorite_id: null,
      favorite: false,
      spinner: false,
    };
  },
  props: {
    header: Boolean,
    listData: {
      type: Array,
      default: () => [],
    },
    stream: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TileViewWavesPlayer,
    PostShare,
  },
  computed: {},
  mounted() {
    this.getFavouriteLists();
  },
  methods: {
    itemTypeResponse(item) {
      if (item.reviewable) {
        return item.reviewable;
      }
      return item;
    },
    audioUrl(item) {
      if (item.reviewable) {
        if (item.reviewable.type === "song") {
          return item.reviewable.audio_url ? item.reviewable.audio_url : "";
        } else {
          if (item.reviewable.type === "album") {
            return item.reviewable.album_songs.length > 0
              ? item.reviewable.album_songs[0].audio_url
              : "";
          }
        }
      } else {
        if (item.type === "song") {
          return item.audio_url ? item.audio_url : "";
        } else {
          if (item.type === "album") {
            return item.album_songs.length > 0
              ? item.album_songs[0].audio_url
              : "";
          }
        }
      }
    },
    async favouriteUnFavourite(e, object) {
      this.favorite_id = object.id;
      this.spinner = true;
      const res = await this.setFavourite(e, object);
      if (res.success === 1) {
        object.favorite = !object.favorite;
        // this.favorite = (this.favorite !== true);
        object.favorite === true ? object.loves++ : object.loves--;
        // this.getFavoriteSongs();
      }
      this.favorite_id = null;
      this.spinner = false;
    },
    updateIcon(val) {
      this.icon = val;
    },
    async playPauseSong(Index, song) {
      this.$store.commit("SET_CURRENT_PLAYER", "waveSurferPlayer");
      if (
        this.last_play_song_index !== null &&
        this.last_play_song_index !== Index
      ) {
        await this.pauseSong(this.last_play_song_index);
      }
      await this.PlaySong(Index, song);
    },
    changeIcon() {
      this.icon = false;
    },
    async PlaySong(Index, song) {
      this.last_play_song_index = Index;
      this.icon = !this.icon;
      if (this.icon === true) {
        let payload = {
          type: "song",
          id: song.id,
        };
        await this.$store.dispatch("trackPlayed", payload);
        song.plays += 1;
      }
      await this.$refs["songPlayerRef" + Index][0].playPauseSong();
      this.isSongPlaying = this.$refs[
        "songPlayerRef" + Index
      ][0].wavesurfer.isPlaying();
    },
    async pauseSong(Index) {
      this.icon = false;
      await this.$refs["songPlayerRef" + Index][0].pauseSong();
    },
    addToQueueSongs(item) {
      var payload = null;
      if (item.reviewable) {
        if (item.reviewable.type === "song") {
          payload = [item.reviewable];
        } else if (item.reviewable.type === "album") {
          payload = item.reviewable.album_songs;
        }
      } else {
        if (item.type === "song") {
          payload = [item];
        } else if (item.type === "album") {
          payload = item.album_songs;
        }
      }
      this.$store.commit("Set_Add_Songs_To_Queue", payload);
    },
    getFavouriteLists: function() {
      for (let i = 0; i < this.listData.length; i++) {
        Vue.set(
          this.selectedFavourite,
          this.listData[i].id,
          this.listData[i].favorite
        );
      }
    },
    setFavouriteArrayValues: function(id, value) {
      Vue.set(this.selectedFavourite, id, value);
    },
    repost(event, id, index) {
      this.$refs["share" + index][0].repost(event, null, id);
    },
    share(event, index) {
      this.$refs["share" + index][0].openShareModel(event);
    },
    openOptions(index) {
      console.log("function called", index, this.showMore);
      if (this.showMore !== null) {
        if (this.showMore === index) {
          this.showMore = null;
        } else {
          this.showMore = index;
        }
      } else {
        this.showMore = index;
      }
      // this.showMore = ((this.showMore===index) || !!(this.showMore))?null:index
    },
  },
};
</script>
<style scoped lang="scss">
.w-1170.charts.card.weekly-top .line-2 .shop {
  margin-bottom: 10px;
}
.no-sell-empty-div {
  width: 90px;
}
.chart-top-selling-list {
  .description {
    margin-left: 19px;
    width: 156px !important;
    margin-right: 14px;
  }
}
.tile-music-waves {
  .music-player {
    width: 180px;
  }
}
.play-pause-button-style {
  .no-gutters {
    .line-1 {
      .new-play-button.active {
        .svg-icon svg:nth-child(1) {
          display: none !important;
        }
      }
    }
  }
}
.pg-play-btn-count {
  margin-right: 20px !important;
  margin-top: 3px;
  .svg-icon {
    position: relative;
    top: -1px !important;
  }
}
.btn {
  &.btn-information {
    // background: #fff !important;
  }
  &.text {
    &.love-button {
      &:hover {
        background: rgba(247, 35, 129, 0.1) !important;

        svg {
          path {
            stroke: #f72381 !important;
          }
        }
      }
      &.active {
        background: rgba(247, 35, 129, 0.1);

        svg {
          path {
            stroke: #f72381 !important;
          }
        }
      }
    }
    &.share-button {
      &:hover {
        background: #D1433A1A !important;
        svg {
          path {
            stroke: #D1433A !important;
          }
        }
      }
    }
    &.comment-button {
      &:hover {
        background: #D1433A1A !important;
        svg {
          path {
            stroke: #D1433A !important;
          }
        }
      }
    }
    &.repost-button {
      &:hover {
        background: rgba(46, 191, 136, 0.1) !important;
        svg {
          path {
            stroke: #2ebf88 !important;
          }
        }
      }
      &.active {
        background: rgba(46, 191, 136, 0.1) !important;
        svg {
          path {
            stroke: #2ebf88 !important;
          }
        }
      }
    }
  }
  &.share-button {
    &:hover {
      background: #D1433A1A !important;
      svg {
        path {
          stroke: #D1433A !important;
        }
      }
    }
  }
}
.chart-tile-p {
  padding-right: 30px;
  margin-left: 23px;
}
</style>
