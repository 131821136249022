<template>
  <div class="about-work about-section-page">
    <div class="row">
      <div class="col">
        <div class="about-work-text ">
          <h1 class="about-title">About</h1>
          <div class="mt-3 mx-4">
            <div class="row">
              <div class="col-8">
                <span class="about-title float-left" v-if="pageType==='artist'">{{ pateTitle }}</span>
                <div class="edit-delete" v-if="editAble"  @click="gotoCurrentRoutes('aboutandskills')">
                  <button class="mx-3 rounded">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="col-4 d-flex flex-row-reverse">
                <div class="edit-delete " v-if="editAble">
                  <router-link tag="button" :to="'/page-manager/page/availability/'+currentPage.id" class="rounded">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </router-link>
                </div>
                <button class="w-75 hr-rate float-left py-1" v-if="showRatePerHour">
                  <span>{{ ratePerHour }} $/hr</span>
                </button>

              </div>
            </div>

            <p class="px-0">{{ aboutContentDisplayed }} </p>
            <div class="row" v-if="aboutContent && aboutContent.length>360 && !readMore">
              <div class="col-6">
                <a class="px-0 float-left" href="javascript:;" @click="readMore= true"
                >READ
                  MORE</a>
              </div>
              <div class="col-6">
                <div class="edit-delete flex-row-reverse" v-if="editAble">
                  <button class=" rounded">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>

            </div>
            <div class="row" v-if="aboutContent && aboutContent.length>360 && readMore">
              <div class="col-6">
                <a class="px-0" href="javascript:;" @click="readMore= false"
                >READ
                  LESS</a>
              </div>
              <div class="col-6">
                <div class="edit-delete flex-row-reverse" v-if="editAble">
                  <button class=" rounded">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>

            </div>
            <div class="w-100 border-bottom mt-3 horizontal-line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 px-4">
      <div class="row ">
        <div class="col-sm-12 col-md-6">
          <b-form-group
              label-for="category"
              class="artist-page-form-field"
          >
            <template v-slot:label>
              <span class="float-left mt-1">Category</span>
              <div class="edit-delete flex-row-reverse" v-if="editAble"  @click="gotoCurrentRoutes('basicInfo')">
                <button class=" rounded">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
            </template>
            <b-form-select
                id="category"
                :options="pageCategories"
                class="mb-2 mr-sm-2 mb-sm-0"
                size="sm"
                :value="pageCategory"
                :disabled="true"
            >
            </b-form-select>
            <b-form-invalid-feedback
                id="category"
            >This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              label-for="category"
              class="artist-page-form-field"
          >
            <template v-slot:label>
              <span class="float-left mt-1">Genre</span>
              <div class="edit-delete flex-row-reverse" v-if="editAble" @click="gotoCurrentRoutes('aboutandskills')">
                <button class=" rounded">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
              <br v-else/>
            </template>
            <div class="about_tags">
              <ul class="px-0">
                <li v-for="(genre,index) in pageGenres.slice(0, 3)" :key="index"><a class="my-1"
                                                                                    href="javascript:;">{{ genre.name }}</a>
                </li>
                <li v-if="pageGenres.length>2"><a class="my-1" href="javascript:;">+{{ pageGenres.length - 2 }}</a></li>
              </ul>
            </div>
            <b-form-invalid-feedback
                id="category"
            >This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group
              label-for="category"
              class="artist-page-form-field"
          >
            <template v-slot:label>
              <span class="float-left mt-1">Subcategory</span>
              <div class="edit-delete flex-row-reverse" v-if="editAble" @click="gotoCurrentRoutes('basicInfo')">
                <button class=" rounded">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
            </template>
            <b-form-select
                id="category"
                :value="pageSubCategory"
                :options="selectedCategorySubCategories"
                class="mb-2 mr-sm-2 mb-sm-0"
                size="sm"
                :disabled="true"
            >
            </b-form-select>
            <b-form-invalid-feedback
                id="category"
            >This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              v-if="pageSubCategory === 1"
              label-for="category"
              class="artist-page-form-field"
          >
            <template  v-slot:label>

              <span v-if="pageSpecification.length > 0" class="float-left mt-1">Instruments</span>
              <div class="edit-delete flex-row-reverse" v-if="editAble" @click="gotoCurrentRoutes('aboutandskills')">
                <button class=" rounded">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
              <br v-else/>
            </template>
            <div class="about_tags">
              <ul class="px-0" v-if="pageSpecification ">
                <li v-for="(specification,index) in pageSpecification.slice(0,3)" :Key="index"><a class="my-1"
                                                                                                  href="javascript:;">{{ specification.name }}</a>
                </li>
                <li v-if="pageSpecification.length>3"><a class="my-1"
                                                         href="javascript:;">+{{ pageSpecification.length - 3 }}</a>
                </li>
              </ul>
            </div>
            <b-form-invalid-feedback
                id="category"
            >This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  props: {
    editAble: {
      type: Boolean,
      default: false
    },
    showRatePerHour:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      readMore: false,
      category: null
    }
  },
  mounted() {
    this.$store.dispatch('getPageCategories')
  },
  computed: {
    pageCategories() {
      return this.$store.getters.getPageCategories.map(category => {
        return {
          value: category.id,
          text: category.name,
          subCategories: category.page_sub_category
        }
      })
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    pageType() {
      return (this.currentPage) ? this.currentPage.type : null
    },
    aboutContent() {
      let {content} = this.currentPage || {}
      return content
    },
    pateTitle() {
      let {about_title} = this.currentPage || {}
      return about_title
    },
    aboutContentDisplayed() {
      if (!this.aboutContent) return ''
      return (this.aboutContent.length > 360 && this.readMore === false) ? this.aboutContent.slice(0, 360) : this.aboutContent
    },
    selectedCategorySubCategories() {
      if (this.pageCategory !== null) {
        const index = this.pageCategories.findIndex(category => category.value === this.pageCategory)
        if (index !== -1) {
          return this.pageCategories[index].subCategories.map(category => {
            return {
              value: category.id,
              text: category.name,
              specification: category.page_specification
            }
          })
        }
      }
      return []
    },
    pageCategory() {
      return (this.currentPage) ? this.currentPage.page_category_id : null
    },
    pageSubCategory() {
      return (this.currentPage) ? this.currentPage.page_subcategory_id : null
    },
    pageGenres() {
      return ((this.currentPage) && (this.currentPage.genres && this.currentPage.genres.length > 0)) ? this.currentPage.genres : []
    },
    pageSpecification() {
      return (this.currentPage) ? this.currentPage.page_specification : []
    },
    ratePerHour: {
      set(newValue) {
        this.currentPage.rate_per_hour = newValue
      },
      get() {
        return (this.currentPage && this.currentPage.rate_per_hour) ? this.currentPage.rate_per_hour : 0
      },
    },
  },
  methods: {
    gotoCurrentRoutes (route) {
      if (route === 'basicInfo') {
        this.$router.push(`/page-manager/page/page-information/${ this.$route.params.id }`);
      } else if (route === 'aboutandskills') {
        this.$router.push(`/page-manager/page/about-and-skills/${ this.$route.params.id }`);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.about-section-page {
  .about-title {
    margin-top: 5px;
    font-family: HelveticaNeueMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #071526;

  }

  .hr-rate {
    border: 1.5px solid #D1433A;
    border-radius: 4px;
    color: #D1433A;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    background-color: #fff;
  }

  .hr-rate:hover {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    background-color: #D1433A;
  }
}

</style>
