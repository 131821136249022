<template>
    <div class=" col-8" style="padding-left: 0">
      <div class="media-tabs friends_tab">
        <ul id="myTab" role="tablist" class="nav nav-tabs5">
          <li class="nav-item">
            <a id="home-tab" data-toggle="tab" href="#Followers" role="tab" aria-controls="home" aria-selected="true" class="nav-link active" @click="getArtistPageFollowers">
              Followers
            </a>
          </li>
          <li class="nav-item">
            <a id="profile-tab" data-toggle="tab" href="#Following" role="tab" aria-controls="profile" aria-selected="false" class="nav-link" @click="getArtistPageFollowings">
              Following
            </a>
          </li>
          <li class="nav-item">
            <a id="contact-tab" data-toggle="tab" href="#MutualFollowing" role="tab" ria-controls="contact" aria-selected="false" class="nav-link" @click="getArtistPageMutualFollowings">
              Mutual Following
            </a>
          </li>
        </ul>
        <ArtistFollowerSearch :currentActiveFollowerTab="currentActiveFollowerTab"  @searchArtistFollowerValue="searchArtistFollowerValue" />
      </div>
      <div id="myTabContent" class="tab-content">
        <div id="Followers" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade mt_20 show active">
          <ArtistFollower :type="'page'" :currentActiveFollowerTab="currentActiveFollowerTab" :friendsSearchKey="friendsSearchKey"/>
        </div>
        <div id="Following" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade mt_20">
          <ArtistFollowing :type="'page'" :currentActiveFollowerTab="currentActiveFollowerTab" :friendsSearchKey="friendsSearchKey"/>
        </div>
        <div id="MutualFollowing" role="tabpanel" aria-labelledby="contact-tab" class="tab-pane fade mt_20">
          <MutualArtistFollowing :currentActiveFollowerTab="currentActiveFollowerTab" :friendsSearchKey="friendsSearchKey"/>
        </div>
      </div>
    </div>
</template>
<script type="application/javascript">
import ArtistFollower from "@/components/PageManager/Pages/ArtistMoreSection/ArtistFollowers.vue";
import ArtistFollowing from "@/components/PageManager/Pages/ArtistMoreSection/ArtistFollowings.vue";
import MutualArtistFollowing from "@/components/PageManager/Pages/ArtistMoreSection/ArtistMutualFollowing.vue";
import ArtistFollowerSearch from "@/components/PageManager/Pages/ArtistMoreSection/SearchArtistFollowers.vue";
import { your_profile_activity_api } from "../../../../apis/APIs";
  export default {
    data(){
      return{
        currentActiveFollowerTab:'followers',
        friendsSearchKey:''
      }
    },
    components: {
      ArtistFollower,
      ArtistFollowing,
      MutualArtistFollowing,
      ArtistFollowerSearch
    },
    methods:{
      searchArtistFollowerValue(value){
        this.friendsSearchKey = value;
      },
      getArtistPageFollowers(){
        this.friendsSearchKey = '';
        this.currentActiveFollowerTab = 'followers'
        let payload = {
          showMore : false,
          page_id: this.$route.params.id,
          artistFollowersSearchKey: null,
          search_type: this.currentActiveFollowerTab,
          name: null,
        }
        this.$store.dispatch('getArtistPageFollowers', payload)
      },
      getArtistPageFollowings(){
        this.friendsSearchKey = '';
        this.currentActiveFollowerTab = 'followings'
        let payload = {
          showMore : false,
          page_id: this.$route.params.id,
          artistFollowersSearchKey: null,
          search_type: this.currentActiveFollowerTab,
          name: null,
        }
        this.$store.dispatch('getArtistPageFollowings', payload)
      },
      getArtistPageMutualFollowings(){
        this.friendsSearchKey = '';
        this.currentActiveFollowerTab = 'mutuals'
        let payload = {
          showMore : false,
          page_id: this.$route.params.id,
          artistFollowersSearchKey: null,
          search_type: this.currentActiveFollowerTab,
          name: null,
        }
        this.$store.dispatch('getArtistPageMutualFollowings', payload)
      }
    }
  }
</script>
<style>
.center_centerbar_wraper_home {
  flex: 1;
  min-width: 770px !important;
}
</style>
<style>
.center_centerbar_wraper_home {
  flex: 1;
  min-width: 770px !important;
}
</style>
