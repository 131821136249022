<template>
  <div
    class="with-slider"
    :class="[hasContainer ? 'container padding-for-container' : 'col-md-12']"
  >
    <div class="row">
      <div class="col-sm-12" :class="[hasContainer ? 'pl-3' : 'p-0']">
        <h4 class="title-h4 music_slider_landing_title textUp">
          {{ pageTitle ? pageTitle : "Trending Releases" }}
        </h4>
      </div>
    </div>
    <div class="row diferent-drop-position" ref="busstop">
      <div class="music_slider_landing px-0">
        <Singles
          @updateDimension="updateDimension"
          @showList="showList"
          :listData="listData"
          :componentType="componentType"
        />
        <ListOption
          :optionWidth="optionWidth"
          :optionHeight="optionHeight"
          :showOption="showOption"
        />
        <div class="link-more near_arrow more_btn cursor-pointer">
          <a @click="redirectToChannel(channelType, altName, pageTitle)"
            >See more</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import carousel from "vue-owl-carousel";
import Singles from "@/components/Common/Elements/Singles.vue";
import ListOption from "@/components/Common/Elements/ListOptions.vue";

export default {
  data() {
    return {
      email: "",
      optionWidth: 0,
      optionHeight: 0,
      showOption: false,
    };
  },
  props: {
    hasContainer: Boolean,
    pageTitle: String,
    redirectLink: String,
    altName: String,
    componentType: {
      type: String,
      default: "buy",
    },
    channelType: {
      type: String,
      default: null,
    },
    listData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    //  carousel,
    Singles,
    ListOption,
  },
  methods: {
    showList: function (val) {
      this.showOption = val;
    },
    redirect: function (url) {
      this.$router.push({
        name: "music." + this.redirectLink,
        params: { title: "Hot new singles" },
      });
    },
    updateDimension: function (width, height) {
      //   this.optionWidth = parseInt(width) -  (this.$refs.busstop.getBoundingClientRect().left  - (width  * 0.25));
      this.optionWidth =
        parseInt(width) - this.$refs.busstop.getBoundingClientRect().left;
      this.optionHeight = parseInt(height);
      console.log(width, height, "--dimension--");
    },
  },
};
</script>
<style scoped>
.textUp {
  text-transform: capitalize !important;
}
.padding-for-container {
  padding-right: 30px !important;
}
</style>
