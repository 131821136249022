<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="aff-payments" class="settings-content active right-setting-bar">
      <div class="set-block setting-header d-flex flex-column">
        <div class="set-block-head d-flex flex-row align-items-center">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 1.33337H2.66659C1.93021 1.33337 1.33325 1.93033 1.33325 2.66671V5.33337C1.33325 6.06975 1.93021 6.66671 2.66659 6.66671H13.3333C14.0696 6.66671 14.6666 6.06975 14.6666 5.33337V2.66671C14.6666 1.93033 14.0696 1.33337 13.3333 1.33337Z"
                stroke="#47505B"
                stroke-width="1.5"
              />
              <path
                d="M13.3333 9.33337H2.66659C1.93021 9.33337 1.33325 9.93033 1.33325 10.6667V13.3334C1.33325 14.0698 1.93021 14.6667 2.66659 14.6667H13.3333C14.0696 14.6667 14.6666 14.0698 14.6666 13.3334V10.6667C14.6666 9.93033 14.0696 9.33337 13.3333 9.33337Z"
                stroke="#47505B"
                stroke-width="1.5"
              />
              <path d="M4 4H4.00667" stroke="#47505B" stroke-width="1.5" />
              <path d="M4 12H4.00667" stroke="#47505B" stroke-width="1.5" />
            </svg>
          </div>
          Payments
        </div>
        <div class="set-block-content d-flex flex-column affil-pay">
          <div class="row" v-if="message || success_message">
            <div class="col-12">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
              <div
                v-if="success_message"
                class="alert alert-success"
                role="alert"
              >
                {{ success_message }}
              </div>
            </div>
          </div>
          <div class="title-lg">WITHDRAWAL REQUEST</div>
          <div class="d-flex flex-row input-row align-items-center">
            <div class="left-side">Your Balance</div>
            <div class="balance">$0.00</div>
          </div>

          <div class="d-flex flex-row input-row align-items-center">
            <div class="left-side">Email</div>
            <div>
              <input
                type="text"
                v-model="email"
                placeholder="johndoel@mail.com"
              />
              <label
                class="error_msg error_msg_red"
                v-if="_handleErrorMessage('email')"
                >{{ _handleErrorMessage("email") }}</label
              >
            </div>
          </div>

          <div class="d-flex flex-row input-row align-items-center mb-24">
            <div class="left-side dif-pos">Amount (USD)</div>
            <div class="d-flex flex-column">
              <input type="text" v-model="amount" placeholder="238" />
              <div class="small-text">
                The minimum withdrawal request amount is $50
              </div>
              <label
                class="error_msg error_msg_red"
                v-if="_handleErrorMessage('amount')"
                >{{ _handleErrorMessage("amount") }}</label
              >
            </div>
          </div>

          
      

          <div class="d-flex flex-row input-row">
            <div class="left-side pay-title">Payment Method</div>
            <div class="d-flex flex-row align-items-center">
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  name="points_payment_method"
                  v-model="payment_method"
                  id="paypal"
                  value="paypal"
                  class="custom-control-input"
                />
                <label class="custom-control-label pay-label" for="paypal"
                  >Paypal</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  name="points_payment_method"
                  v-model="payment_method"
                  id="skrill"
                  value="skrill"
                  class="custom-control-input"
                />
                <label class="custom-control-label pay-label" for="skrill"
                  >Skrill</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  name="points_payment_method"
                  v-model="payment_method"
                  id="Bank"
                  value="stripe"
                  class="custom-control-input"
                />
                <label class="custom-control-label pay-label" for="Bank"
                  >Bank Transfer</label
                >
              </div>
              <label
                class="error_msg error_msg_red"
                v-if="_handleErrorMessage('payment_method')"
                >{{ _handleErrorMessage("payment_method") }}</label
              >
            </div>
          </div>

          <!-- <div class="d-flex flex-row input-row align-items-center">
            <div class="left-side">Transfer to</div>
            <div>
              <input
                type="text"
                v-model="email"
                placeholder="johndoel@mail.com"
              />
              <label
                class="error_msg error_msg_red"
                v-if="_handleErrorMessage('email')"
                >{{ _handleErrorMessage("email") }}</label
              >
            </div>
          </div> -->


       

         

          <div class="upgrade-btn d-flex" @click="makeawithdrawal">
            MAKE A WITHDRAWAL
          </div>

          <div class="title-lg payment_table_title mb-0">
            WITHDRAWAL HISTORY
          </div>

          <div class="payment_data">
            <div class="small-info">No withdrawal history</div>
            <div class="table_data active">
              <div class="session-table payment_table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Method</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(eachWithdraw, index) in withdrawals"
                      :key="index"
                    >
                      <td>{{ eachWithdraw.created_date }}</td>
                      <td>
                        {{ parseFloat(eachWithdraw.amount).toFixed(2) }} USD
                      </td>
                      <td>{{ eachWithdraw.payment_method }}</td>
                    </tr>
                    <tr>
                      <td>14 Oct 2020, 04:30 AM</td>
                      <td>238 USD</td>
                      <td>Paypal</td>
                    </tr>
                    <tr>
                      <td>14 Oct 2020, 04:30 AM</td>
                      <td>111 USD</td>
                      <td>Skrill</td>
                    </tr>
                    <tr>
                      <td>14 Oct 2020, 04:30 AM</td>
                      <td>100 USD</td>
                      <td>Bank Transfer</td>
                    </tr>
                    <tr>
                      <td>14 Oct 2020, 04:30 AM</td>
                      <td>238 USD</td>
                      <td>Paypal</td>
                    </tr>
                    <tr>
                      <td>14 Oct 2020, 04:30 AM</td>
                      <td>149 USD</td>
                      <td>Paypal</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="page_number">
                <ul class="pagination">
                  <li
                    :class="['page-item', page == n ? 'active' : '']"
                    v-on:click="changePage(n)"
                    v-for="(n, index) in total_page"
                    :key="index"
                  >
                    <a href="#" class="page-link">{{ n }}</a>
                  </li>
                </ul>
              </div> -->
              <div class="pagination-row">
                <div class="pagination-info">
                  <div class="title">
                    Displaying (<span class="visible-pages">1-20</span> of
                    <span class="total-pages">263</span>)
                  </div>
                </div>
                <div class="pagination-action">
                  <ul class="pagination">
                    <li class="page-item disabled">
                      <span class="page-link prev">
                        <div class="svg-icon">
                          <svg
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              class="c-line-1"
                              d="M5.30078 9.83203L1.00078 5.60103L5.30078 0.999031"
                              stroke="#8B949F"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        Previous</span
                      >
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item active">
                      <span class="page-link">
                        2
                        <span class="sr-only">(current)</span>
                      </span>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">5</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">6</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link next" href="#"
                        >Next
                        <div class="svg-icon">
                          <svg
                            width="6"
                            height="11"
                            viewBox="0 0 6 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              class="c-line-1"
                              d="M1 9.83203L5.3 5.60103L0.999999 0.999031"
                              stroke="#8B949F"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg></div
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import { countries } from "../../../components/utils/countries";
import * as Helper from "../../../apis/Helper.js";
import {
  updateConnectedAccount,
  getAllTransfers,
  addTransfer,
} from "../../../apis/APIs";
import { EDIT_PROFILE_ERRORS, SUCCESS, ERROR } from "../../../store/types";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      payment_method: "",
      email: "",
      account_number: "",
      amount: "",
      personal_id_number: "",
      //  country:'US',
      message: "",
      success_message: "",
      loading: false,
      errors: [],
      withdrawals: [],
      page: 1,
      total_page: 1,
    };
  },
  directives: { mask },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
  },
  created() {
    this.getAllTransfers();
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    ...mapMutations(["updateEditProfileFormData", "updateCardUIConstraints"]),
    async getAllTransfers() {
      let user_id = this.user.user_data.user_id;
      try {
        let formdata = new FormData();
        formdata.append("user_id", user_id);
        formdata.append("function_type", "get_all_transactions");
        formdata.append("page", this.page);
        const res = await getAllTransfers(formdata);
        console.log(res, "trans");
        if (res.success) {
          this.withdrawals = res.data;
          let count = res.count;
          this.total_page = count % 10 > 0 ? parseInt(count / 10) + 1 : 1;
        }
      } catch (error) {
        this.list_loading = false;
      }
    },

    closeModal: function () {
      this.$parent.closeWindow();
    },
    goBack: function () {
      this.$parent.backF();
    },
    changePage: function (n) {
      this.page = n;
      this.getAllTransfers();
    },
    async makeawithdrawal() {
      const { user_data } = this.user || {};
      const { user_id } = user_data || {};
      console.log(user_id);

      this.errors = [];

      if (!this.email) {
        this.errors.push({
          fieldName: "email",
          message: "Required",
        });
      }
      if (!this.amount) {
        this.errors.push({
          fieldName: "amount",
          message: "Required",
        });
      }
      if (!this.payment_method) {
        this.errors.push({
          fieldName: "payment_method",
          message: "Required",
        });
      }
      if (this.errors.length > 0) {
        return;
      }

      try {
        var formdata = new FormData();
        formdata.append("user_id", user_id);
        formdata.append("function_type", "add_transactions");
        if (this.email) formdata.append("email", this.email);
        if (this.amount) formdata.append("amount", this.amount);
        if (this.payment_method)
          formdata.append("payment_method", this.payment_method);

        console.log(formdata);

        this.message = "";
        this.loading = true;
        // alert('321');
        let res = await addTransfer(formdata);
        console.log(res, "error");

        if (res.status) {
          this.success_message = res.message;
          this.email = "";
          this.amount = "";
          this.payment_method = "";
          this.getAllTransfers();
          // this.fetchUserData();
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = res.message;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);
      return message;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
              status: true,
              message:
                this.errors[
                  this.errors.findIndex((ele) => ele.fieldName === key)
                ].message,
            }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
  },
};
</script>
