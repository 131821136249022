<template lang="">
    <div class="flex-row align-items-center unblock" @click="handlePress">
        <span>{{
    loading ? "Loading..." : "Unblock"
  }}</span>
         <div class="svg-icon">
             <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path class="c-line-1" fill-rule="evenodd" clip-rule="evenodd" d="M9.59611 0.403727C9.81908 0.626699 9.81908 0.988209 9.59611 1.21118L1.21101 9.59628C0.988036 9.81926 0.626526 9.81926 0.403554 9.59628C0.180582 9.37331 0.180582 9.0118 0.403554 8.78883L8.78866 0.403727C9.01163 0.180755 9.37314 0.180755 9.59611 0.403727Z" fill="#071526"></path>
                 <path class="c-line-1" fill-rule="evenodd" clip-rule="evenodd" d="M0.403727 0.40389C0.626699 0.180918 0.988209 0.180918 1.21118 0.40389L9.59628 8.78899C9.81926 9.01196 9.81926 9.37347 9.59628 9.59645C9.37331 9.81942 9.0118 9.81942 8.78883 9.59645L0.403727 1.21134C0.180755 0.988372 0.180755 0.626862 0.403727 0.40389Z" fill="#071526"></path>
             </svg>
         </div>
     </div>

</template>
<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import * as Utils from '../../../../components/utils/Utils';
import * as Helper from '../../../../apis/Helper.js';
import {SUCCESS, ERROR} from '../../../../store/types';
import moment from 'moment-timezone';

export default {
  data() {
    return ({
      loading: false
    })
  },
  props: ["id", "unblockUser"],
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    })
  },
  mounted() {

  },
  methods: {
    handlePress() {
      this.loading = true;
      this.unblockUser(this.id, () => {
        this.loading = false;
      });
    }
  }
}
</script>
<style lang="">

</style>
