<template>
  <div class="mt-2">
    <div class="row " v-if="comment_list_loading && !comment_check">
      <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <div v-if="!comment_check">
      <single-post-comment-item v-for="(eachOp, index) in commentList" :key="'comment'+index" :item="eachOp" :post="item" @removeComment="removeComment"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getCommentsOfPost} from "../../../../apis/APIs";
import Vue from "vue";
import SinglePostCommentItem from "../PostItem/SinglePostCommentItem";

require("vue-chat-emoji/dist/vue-chat-emoji.min.css");

export default {
  name: "SinglePostComments",
  data() {
    return {
      commentList: [],
      comment: null,
      photo: undefined,
      photo_src: undefined,

      comment_list_loading: false,
      comment_list: [],

      message: "",
      success_message: "",


    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    comment_check: {
      type: Boolean,
      default: false
    },
    input_check: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const {post_id = ""} = this.item || {};

      return post_id;
    }
  },
  components: {
    SinglePostCommentItem
  },
  methods: {
    stopPropagationOnCommentClick(e) {
      if (e) e.stopPropagation();
    },
    async init() {
      try {
        this.comment_list_loading = true;
        const formData = new FormData();
        formData.append('commentable_type', 'Post');
        formData.append('commentable_id', this.$route.params.id);
        const {data} = await getCommentsOfPost(formData);
        this.commentList = (data.information) ? data.information.data : []
        this.comment_list_loading = false;
      } catch (error) {
        console.error(error)
        this.comment_list_loading = false;
      }
    },
    async removeComment(commentId){
      if (this.commentList.length > 0) {
            const replacedIndex = this.commentList.findIndex(e => e.id === commentId);
            if (replacedIndex !== -1) {
              this.commentList.splice(replacedIndex, 1);
            }
        }
    },
    async updateImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;
      this.photo = file;
      this.photo_src = URL.createObjectURL(file);
    },
    reset_data() {
      this.comment = null;
      this.photo = null;
      this.photo_src = null;
    },
    addCommentInList(comment){
      this.commentList.push(comment)
    }
  }
}
</script>

<style scoped>

</style>
