<template>
  <div class="container_xxl">
    <div class="myMainloder">
      <full-page-loader :show="loading" />
    </div>
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h1 class="pages-heading-title">FORUMS</h1>
          </div>
        </div>
      </div>
      <div class="content-forum">
        <div class="container-fluid">
          <div class="row for_flex">
            <div class="col-sm-4">
              <div class="forum-menu">
                <div class="item-title">Forum Navigation</div>
                <ul>
                  <li>
                    <a @click="changePage('home')" :class="[
                      'pointerClass',
                      !activeHomeTab.includes(GET_NAV_MAIN_PATH)
                        ? 'active'
                        : '',
                    ]">
                      <div class="svg-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="c-line-1"
                            d="M2 6.00065L8 1.33398L14 6.00065V13.334C14 13.6876 13.8595 14.0267 13.6095 14.2768C13.3594 14.5268 13.0203 14.6673 12.6667 14.6673H3.33333C2.97971 14.6673 2.64057 14.5268 2.39052 14.2768C2.14048 14.0267 2 13.6876 2 13.334V6.00065Z"
                            stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="c-line-1" d="M6 14.6667V8H10V14.6667" stroke="#D1433A" stroke-width="1.5"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a @click="changePage('my_thread')" :class="[
                      'pointerClass',
                      GET_NAV_MAIN_PATH == 'My Threads' ? 'active' : '',
                    ]">
                      <div class="svg-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="c-line-1"
                            d="M14 10C14 10.3536 13.8595 10.6928 13.6095 10.9428C13.3594 11.1929 13.0203 11.3333 12.6667 11.3333H4.66667L2 14V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V10Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <span>My Threads</span>
                    </a>
                  </li>
                  <li>
                    <a @click="changePage('my_replies')" :class="[
                      'pointerClass',
                      GET_NAV_MAIN_PATH == 'My Replies' ? 'active' : '',
                    ]">
                      <div class="svg-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0131)">
                            <path class="c-line-1" d="M11.3335 0.666992L14.0002 3.33366L11.3335 6.00033"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="c-line-1"
                              d="M2 7.33301V5.99967C2 5.29243 2.28095 4.61415 2.78105 4.11406C3.28115 3.61396 3.95942 3.33301 4.66667 3.33301H14"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="c-line-1" d="M4.66667 15.3333L2 12.6667L4.66667 10" stroke="#47505B"
                              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="c-line-1"
                              d="M14 8.66699V10.0003C14 10.7076 13.719 11.3858 13.219 11.8859C12.7189 12.386 12.0406 12.667 11.3333 12.667H2"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0131">
                              <rect width="16" height="16" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <span>My Replies</span>
                    </a>
                  </li>
                  <li>
                    <a @click="changePage('search')" :class="[
                      'pointerClass',
                      GET_NAV_MAIN_PATH == 'Search' ? 'active' : '',
                    ]">
                      <div class="svg-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="c-line-1"
                            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path class="c-line-1" d="M14.0001 13.9996L11.1001 11.0996" stroke="#47505B"
                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </div>
                      <span>Search</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="content-sidebar">
                <div class="forum-widget">
                  <div class="item-title">What’s Going on</div>
                  <div class="wr_who_online">
                    <div class="for_margin">
                      <p>Who’s online?</p>
                      <div v-if="shouldShowDiv" style="cursor: pointer" @click="changePage('online_peopile_for_forum')">
                        <span>SEE ALL</span>
                        <div class="arrow_icon">
                          <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6.135 5.173L1 9.713" stroke="#47505B" stroke-width="1.3"
                              stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <OnlinePeople :onlinePeople="$store.getters.getAllOnlineUsers" />
                  </div>
                  <p>Forum Statistics</p>
                  <div class="info-block">
                    <div>
                      Threads:
                      <span>{{
                        GET_STATISTICS_FORUM && GET_STATISTICS_FORUM.threads
                        }}</span>
                    </div>
                    <div>
                      Replies:
                      <span>{{
                        GET_STATISTICS_FORUM && GET_STATISTICS_FORUM.replies
                        }}</span>
                    </div>
                    <div>
                      Members:
                      <span>{{
                        GET_STATISTICS_FORUM && GET_STATISTICS_FORUM.members
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <NavigationForum v-if="active_tab != 'home'" :active_tab="active_tab" :key="active_tab" />
              <Home v-if="active_tab == 'home'" />
              <MyThreads v-if="active_tab == 'my_thread'" :type="'no-search'" :THREADS="GET_MY_THREADS" />
              <MyReplies v-if="active_tab == 'my_replies'" :type="'no-search'" :REPLISE="GET_MY_REPLISE" />
              <Search v-if="active_tab == 'search'" />
              <ForumThreads v-if="active_tab == 'forum_thread'" />
              <!-- <SubForumThreads
                v-if="active_tab == 'sub_thread'"
                :type="'without-subform'"
              /> -->
              <NewThread v-if="active_tab == 'new_thread'" />
              <ThreadReply v-if="active_tab == 'thread_reply'"
                :left_nav_active_tab="activeHomeTab.includes(GET_NAV_MAIN_PATH)" />
              <OnlinePeopleForForum v-if="active_tab == 'online_peopile_for_forum'"
                :listOfOnlineUserDetails="listOfOnlineUserData" />
              <ReportModal v-if="showReportModal" />
              <DeleteModal v-if="showDelete" />
              <LinkToPostModal v-if="showLinkToPost" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import Home from "@/components/Home/Forum/Home.vue";
import MyThreads from "@/components/Home/Forum/MyThreads.vue";
import MyReplies from "@/components/Home/Forum/MyReplies.vue";
import Search from "@/components/Home/Forum/Search.vue";
// import SubForumThreads from "@/components/Home/Forum/SubForumThreads.vue";
import ForumThreads from "@/components/Home/Forum/ForumThreads.vue";
import NewThread from "@/components/Home/Forum/NewThread.vue";
import DeleteModal from "@/components/Home/Forum/DeleteModal.vue";
import ThreadReply from "@/components/Home/Forum/ThreadReply.vue";
import ReportModal from "@/components/Home/Forum/ReportModal.vue";
import OnlinePeopleForForum from "@/components/Home/Forum/OnlinePeopleForForum.vue";
import NavigationForum from "@/components/Home/Forum/NavigationForum.vue";
import OnlinePeople from "@/components/Common/Elements/OnlinePeople.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import LinkToPostModal from "./LinkToPostModal";

export default {
  data() {
    return {
      showRightBar: "discover_liked",
      activeHomeTab: ["My Threads", "Search", "My Replies"],
      // active_tab: "my_replies_search",
      active_tab: "home",
      showDelete: false,
      showReportModal: false,
      showLinkToPost: false,
      loading: true,
      listOfOnlineUserData: [],
    };
  },
  watch: {
    getActiveTab(newValue) {
      if (newValue) {
        this.changePage(newValue);
      }
    },
    async getOnlineUsers(newValue) {
      await this.getOnlineUsersDetails(0);
    },
  },
  components: {
    Home,
    MyThreads,
    MyReplies,
    Search,
    ForumThreads,
    // SubForumThreads,
    NavigationForum,
    NewThread,
    DeleteModal,
    ThreadReply,
    ReportModal,
    OnlinePeople,
    OnlinePeopleForForum,
    LinkToPostModal,
  },
  mounted() {
    // this.$store.dispatch("loading", true);

    if (this.$route.params.id) {
      this.setDataForSpecificThread(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions([
      "getStatisticsForums",
      "getMyThread",
      "getMyReplies",
      "getForumsName",
      "getUserId",
    ]),
    ...mapMutations(["changeNavMainPath"]),
    async changePage(page) {
      if (page === "my_thread") {
        await this.getMyThread({ offset: 0 });
        this.changeNavMainPath("My Threads");
        this.loading = false
      } else if (page === "my_replies") {
        await this.getMyReplies({ offset: 0 });
        this.changeNavMainPath("My Replies");
        this.loading = false
      } else if (page === "search") {
        await this.getForumsName();
        this.changeNavMainPath("Search");
        this.loading = false
      } else if (page === "online_peopile_for_forum") {
        await this.getOnlineUsersDetails(0);
        this.changeNavMainPath("Who’s online");
        this.loading = false
      }
      this.loading = false
      this.$store.commit("setActiveTab", "");
      this.active_tab = page;
    },

    showModals(showType) {
      if (showType === "showDelete") {
        this.showDelete = !this.showDelete;
      } else if (showType === "showReport") {
        this.showReportModal = !this.showReportModal;
      } else if (showType === "showLinkToPost") {
        this.showLinkToPost = !this.showLinkToPost;
      }
    },

    async setDataForSpecificThread(thread_id) {
      this.$store.commit("changeThreadId", thread_id);
      await this.$store.dispatch("getThread", {
        thread_id: thread_id,
        offset: 0,
      });
      const { forum, thread } = this.getThreadData;
      this.$store.commit("changeNavMainPath", "Main Section");
      this.$store.commit("changeSubPath2", forum.name);
      this.$store.commit("changeSubPath3", thread.title);
      await this.changePage("thread_reply");
    },

    async getOnlineUsersDetails(offset = 0) {
      const payload = {
        user_ids: {
          user_ids: this.getOnlineUsers,
        },
        offset: offset,
      };
      const { data, message, status } = await this.$store.dispatch(
        "getAllOnlineUsers",
        payload
      );

      this.listOfOnlineUserData = data.data || [];
    },
    // closeModal: function() {
    //   this.showDelete = false;
    // },
  },
  computed: {
    ...mapGetters([
      "GET_STATISTICS_FORUM",
      "GET_NAV_MAIN_PATH",
      "GET_MY_REPLISE",
      "GET_SEARCH_KEYWORD",
      "GET_MY_THREADS",
    ]),
    getActiveTab() {
      return this.$store.getters.GET_ACTIVE_TAB;
    },
    getThreadData() {
      return this.$store.getters.GET_THREAD_DATA;
    },
    getOnlineUsers() {
      const onlineUserIds = this.$store.getters.getAllOnlineUsers;

      return onlineUserIds.map((e) => e.id);
    },
    shouldShowDiv() {
      return this.$store.getters.getAllOnlineUsers.length > 4;
    }
  },
  async created() {
    await this.getStatisticsForums();
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: unset;
}

.for_flex {
  flex-wrap: unset;
}

.for_margin {
  margin: 0 0 13px 0;
}

.wr_who_online {
  border-bottom: 1px solid #e6eaf0;
  padding: 16px 20px 31px;

  p {
    margin: 0;
    padding: 0;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #071526;
    font-size: 12px;
    font-family: HelveticaNeueBold;
    transition: all 0.3s;

    &:hover {
      color: #47505b;
      transition: all 0.3s;
    }
  }

  .arrow_icon {
    width: 24px;
    height: 24px;
    background: #e4e6eb;
    justify-content: center;
    border-radius: 4px;
    margin-left: 10px;
    transition: all 0.3s;

    &:hover {
      background: #d1d9e3;
      transition: all 0.3s;
    }
  }
}
</style>
