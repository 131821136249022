<template>
  <div class="filter-date-picker">
    <div class="filter-calendar" :style="{ display: 'inline-block' }">
      <calendar-panel
        :value="innerValue"
        @select="handleSelect"
        :get-classes="getClasses"
      >
        <template v-slot:footer>
          <button class="mx-btn mx-btn-text">Apply</button>
        </template>
      </calendar-panel>
    </div>
    <div class="filter-apply-btn">
      <button class="btn btn-primary" @click="applyFilters">Apply</button>
    </div>
    <date-picker
      v-model="value"
      type="week"
      :style="{ width: '200px' }"
      style="display: none"
    >
      <template>
        <calendar-panel
          :value="innerValue"
          @select="handleSelect"
          :get-classes="getClasses"
        >
          <template v-slot:footer>
            <button class="mx-btn mx-btn-text">Apply</button>
          </template>
        </calendar-panel>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
const { CalendarPanel } = DatePicker;
function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}
export default {
  components: {
    DatePicker,
    CalendarPanel,
  },
  data() {
    return {
      value: [],
      start_date: "",
      end_date: "",
      innerValue: [new Date(NaN), new Date(NaN)],
    };
  },
  watch: {
    value(newVal) {
      this.$emit("valueUpdate", newVal);
    },
  },
  methods: {
    getClasses(cellDate, currentDates, classes) {
      if (
        !/disabled|active|not-current-month/.test(classes) &&
        currentDates.length === 2 &&
        cellDate.getTime() > currentDates[0].getTime() &&
        cellDate.getTime() < currentDates[1].getTime()
      ) {
        return "in-range";
      }
      return "";
    },
    handleSelect(date) {
      this.start_date = moment(date).startOf("week").format("DD-MM-YYYY");
      this.end_date = moment(date).endOf("week").format("DD-MM-YYYY");
      let weekStart = moment(date).startOf("week");
      let weekEnd = moment(date).endOf("week");
      this.innerValue = [weekStart._d, weekEnd._d];
      this.value = this.innerValue;
    },
    applyFilters() {
      this.$parent.applyWeekFilters({
        startDate: this.start_date,
        endDate: this.end_date,
      });
    },
    clearDate() {
      this.value = [];
      this.start_date = "";
      this.end_date = "";
      this.innerValue = [new Date(NaN), new Date(NaN)];
    },
  },
};
</script>
<style>
.active1 {
  background: #ff0000;
}
.in-range1 {
  background: #ffaaaa;
}
.active2 {
  background: #00ff00;
}
.in-range2 {
  background: #aaffaa;
}
</style>
<style lang="scss">
.filter-date-picker {
  font-family: HelveticaNeueBold, sans-serif;
  font-style: normal;
  .filter-apply-btn {
    margin: 0px 2px 0px 2px !important;
    .btn {
      border: 1px solid #D1433A;
      border-radius: 4px;
      color: #ffffff;
      width: 100%;
      margin: auto;
      height: 40px;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #D1433A !important;
      border-top-right-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
  }
  .filter-calendar {
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    margin: 2px 2px 0px 2px !important;
    .mx-calendar {
      width: 100% !important;
      .mx-calendar-content {
        .mx-table-date th {
          color: #071526 !important;
        }
        .mx-table-date td {
          color: #8b949f !important;
          border-radius: 0% !important;
        }
        .cell.in-range {
          color: #47505b !important;
          background-color: #D1433A1A !important;
        }
        .cell.active:last-child {
          color: #ffffff !important;
          background-color: #D1433A !important;
          border-radius: 0px 20px 20px 0px !important;
        }
        .cell.active:first-child {
          color: #ffffff !important;
          background-color: #D1433A !important;
          border-radius: 20px 0px 0px 20px !important;
        }
        .cell.hover-in-range {
          color: #47505b !important;
          background-color: #D1433A1A !important;
        }
      }
    }
  }
}
</style>
