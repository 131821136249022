import Vue from "vue";
import * as Utils from "../../components/utils/Utils";
import {
    login,
    get_ip_address,
    create_post_api,
    create_new_article_api,
    create_post_poll_api,
    create_album_post_api,
    post_api,
    pin_post,
} from "../../apis/APIs";
import * as SMStorage from "../../apis/SMStorage";
import {
    CREATE_POST_FORM_ARTICLE_CATEGORY,
    CREATE_POST_FORM_ARTICLE_CONTENT,
    CREATE_POST_FORM_ARTICLE_COVER,
    CREATE_POST_FORM_ARTICLE_TAGS,
    CREATE_POST_FORM_ARTICLE_TITLE,
    CREATE_POST_FORM_CREATE_VIDEO,
    CREATE_POST_FORM_CREATE_VIDEO_NAME,
    CREATE_POST_FORM_IMAGES,
    CREATE_POST_FORM_IS_ALBUM,
    CREATE_POST_FORM_POLLS,
    CREATE_POST_FORM_POST_COLOR,
    CREATE_POST_FORM_POST_COLOR_TEXT,
    CREATE_POST_FORM_SELECTED_IMAGES,
    EMPTY,
    ERROR, PIN_POST, POST_LIST,
    SUCCESS,
} from "../types";
import {
    CREATE_POST_ERRORS,
    CREATE_POST_FORM,
    CREATE_POST_FORM_FEELING,
    CREATE_POST_FORM_FEELING_TEXT,
    CREATE_POST_FORM_VALUE,
    CREATE_POST_LOADING,
    CREATE_POST_REQUEST_STATUS,
    MESSAGE,
    POST_KEY,
    STATUS,
    CREATE_POST_FORM_ALBUM_NAME,
    CREATE_POST_FORM_SHARED_WITH,
    POST_LIST_LOADING,
    POST_LIST_REQUEST_STATUS,
    POST_LIST_DATA,
    CREATE_POST_ERROR_MESSAGE
} from "../types";
import moment from "moment";

const INITIAL_STATE = {
    [POST_KEY]: {
        [CREATE_POST_FORM]: {
            [CREATE_POST_FORM_VALUE]: "",
            [CREATE_POST_FORM_FEELING]: "",
            [CREATE_POST_FORM_FEELING_TEXT]: "",

            [CREATE_POST_FORM_POST_COLOR]: "",
            [CREATE_POST_FORM_POST_COLOR_TEXT]: "",

            [CREATE_POST_FORM_ARTICLE_TITLE]: "",
            [CREATE_POST_FORM_ARTICLE_CONTENT]: "",
            [CREATE_POST_FORM_ARTICLE_CATEGORY]: "",
            [CREATE_POST_FORM_ARTICLE_TAGS]: "",
            [CREATE_POST_FORM_ARTICLE_COVER]: "",

            [CREATE_POST_FORM_SELECTED_IMAGES]: [],
            [CREATE_POST_FORM_IS_ALBUM]: "",
            [CREATE_POST_FORM_ALBUM_NAME]: "",

            [CREATE_POST_FORM_CREATE_VIDEO]: "",
            [CREATE_POST_FORM_CREATE_VIDEO_NAME]: "",

            [CREATE_POST_FORM_POLLS]: [],

            [CREATE_POST_FORM_SHARED_WITH]: ""
        },
        [CREATE_POST_ERROR_MESSAGE]: "",
        [CREATE_POST_LOADING]: false,
        [CREATE_POST_REQUEST_STATUS]: {
            [STATUS]: EMPTY,
            [MESSAGE]: ""
        },
        [CREATE_POST_ERRORS]: []
    },
    [PIN_POST] : {},
};

export const state = {...INITIAL_STATE};

export const actions = {
    async create_post({state, commit}, {post_type}) {
        return new Promise(async (resolve, reject) => {
            try {
                const post_key = state[POST_KEY];
                const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                const color = form_data && form_data[CREATE_POST_FORM_POST_COLOR] ? form_data[CREATE_POST_FORM_POST_COLOR] : "";
                const color_value = form_data && form_data[CREATE_POST_FORM_POST_COLOR_TEXT] ? form_data[CREATE_POST_FORM_POST_COLOR_TEXT] : "";

                const post_value = form_data && form_data[CREATE_POST_FORM_VALUE] ? form_data[CREATE_POST_FORM_VALUE] : "";
                const feeling = form_data && form_data[CREATE_POST_FORM_FEELING] ? form_data[CREATE_POST_FORM_FEELING] : "";
                const feeling_value = form_data && form_data[CREATE_POST_FORM_FEELING_TEXT] ? form_data[CREATE_POST_FORM_FEELING_TEXT] : "";

                const privacy = form_data && form_data[CREATE_POST_FORM_SHARED_WITH] ? form_data[CREATE_POST_FORM_SHARED_WITH] : "public";

                commit("updatePostUIConstraints", {
                    [CREATE_POST_LOADING]: true
                });

                let _post_type = post_type;
                let error_message = "";

                if (!post_value && post_type !== "colored_post") {
                    error_message = "post text is required.";
                }
                switch (post_type) {
                    case "upload_photos":
                        const is_album = form_data && form_data[CREATE_POST_FORM_IS_ALBUM] ? form_data[CREATE_POST_FORM_IS_ALBUM] : "";
                        const album_name = form_data && form_data[CREATE_POST_FORM_ALBUM_NAME] ? form_data[CREATE_POST_FORM_ALBUM_NAME] : "";
                        const images = form_data && form_data[CREATE_POST_FORM_IMAGES] ? form_data[CREATE_POST_FORM_IMAGES] : [];

                        _post_type = is_album ? "album" : "photos";

                        if (is_album && !album_name) {
                            error_message = "Album name is required.";
                        }

                        if (!images || (images && !images.length)) {
                            error_message = "No image is selected."
                        }
                        break;
                    case "upload_video":
                        _post_type = "video";

                        const file = form_data && form_data[CREATE_POST_FORM_CREATE_VIDEO] ? form_data[CREATE_POST_FORM_CREATE_VIDEO] : {};

                        if (!file || (file && !file.video)) {
                            error_message = "No video is selected."
                        }

                        break;
                    case "feelings_activity":
                    case "colored_post":
                        _post_type = "";
                        break;
                    case "check_in":
                        _post_type = "check_in";
                        break;
                    case "create_poll":
                        _post_type = "poll";

                        const polls = form_data && form_data[CREATE_POST_FORM_POLLS] ? form_data[CREATE_POST_FORM_POLLS] : "";

                        if (!polls || (polls && !polls.length)) {
                            error_message = "No poll is created."
                        }
                        break;
                    case "article":
                        error_message = "";
                    default:
                        break;
                }

                if (error_message) {
                    return commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false,
                        [CREATE_POST_ERROR_MESSAGE]: error_message
                    });
                }

                let url = `?action=1&user_id=${user.user_id}&user_type=user&in_group=0&group_id=0&group_approved=0&in_event=0&event_id=0&event_approved=0&in_wall=0&wall_id=0&post_type=${_post_type}&public=1&colored_pattern=${color ? color : 0}&origin_id=0&time=${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}&privacy=${privacy}&text=${post_value ? post_value : ""}&boosted=0&boosted_by=0&comments_disabled=1&is_hidden=0&is_anonymous=0&reaction_like_count=0&reaction_love_count=0&reaction_haha_count=0&reaction_yay_count=0&reaction_wow_count=0&reaction_sad_count=0&reaction_angry_count=0&comments=0&shares=0&points_earned=0`;

                url = feeling ? url + `&feeling_action=${feeling ? 'feeling' : ''}&feeling_value=${feeling_value ? feeling_value : 0}` : url;

                const res = await create_post_api(url);

                const {success, message, information} = res || {};

                if (success) {

                    commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false
                    });

                    resolve({
                        status: SUCCESS,
                        message: "",
                        res
                    });
                } else {
                    commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("Post UI ===> error", error);
                commit("updatePostUIConstraints", {
                    [CREATE_POST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async create_new_article({state, commit}, {post_id = ""}) {
        return new Promise(async (resolve, reject) => {
            try {
                const post_key = state[POST_KEY];
                const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                const title = form_data && form_data[CREATE_POST_FORM_ARTICLE_TITLE] ? form_data[CREATE_POST_FORM_ARTICLE_TITLE] : "";
                const content = form_data && form_data[CREATE_POST_FORM_ARTICLE_CONTENT] ? form_data[CREATE_POST_FORM_ARTICLE_CONTENT] : "";
                const cover = form_data && form_data[CREATE_POST_FORM_ARTICLE_COVER] ? form_data[CREATE_POST_FORM_ARTICLE_COVER] : "";
                const category = form_data && form_data[CREATE_POST_FORM_ARTICLE_CATEGORY] ? form_data[CREATE_POST_FORM_ARTICLE_CATEGORY] : "";
                const tags = form_data && form_data[CREATE_POST_FORM_ARTICLE_TAGS] ? form_data[CREATE_POST_FORM_ARTICLE_TAGS] : "";

                var formdata = new FormData();
                formdata.append("title", title);
                formdata.append("tags", tags && tags.length ? tags.toString() : "");
                formdata.append("views", "0");
                if (cover) formdata.append("cover", cover);
                formdata.append("text", content);
                formdata.append("post_id", post_id);
                formdata.append("category_id", category);
                formdata.append("action", "1");


                const res = await create_new_article_api(formdata);

                const {success, message, information} = res || {};

                if (success) {
                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("Post UI ===> error", error);
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async create_poll({state, commit}, {post_id = ""}) {
        return new Promise(async (resolve, reject) => {
            try {
                const post_key = state[POST_KEY];
                const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

                commit("updatePostUIConstraints", {
                    [CREATE_POST_LOADING]: true
                });

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                let polls = form_data && form_data[CREATE_POST_FORM_POLLS] ? form_data[CREATE_POST_FORM_POLLS] : "";
                polls = polls && polls.length ? polls.filter(ele => ele) : [];

                const query = `?post_id=${post_id}&action=1&votes=0&text=${polls}`
                const res = await create_post_poll_api(query);

                const {success, message, information} = res || {};

                if (success === 1) {
                    commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false
                    });

                    resolve({
                        status: SUCCESS,
                        message: ""
                    });
                } else {
                    commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false
                    });

                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("Post UI ===> error", error);

                commit("updatePostUIConstraints", {
                    [CREATE_POST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async create_album({state, commit}, {post_id = ""}) {
        return new Promise(async (resolve, reject) => {
            try {
                const post_key = state[POST_KEY];
                const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                const is_album = form_data && form_data[CREATE_POST_FORM_IS_ALBUM] ? form_data[CREATE_POST_FORM_IS_ALBUM] : "";
                const album_name = form_data && form_data[CREATE_POST_FORM_ALBUM_NAME] ? form_data[CREATE_POST_FORM_ALBUM_NAME] : "";
                const images = form_data && form_data[CREATE_POST_FORM_IMAGES] ? form_data[CREATE_POST_FORM_IMAGES] : [];

                var formdata = new FormData();
                formdata.append("post_id", post_id);

                if (images && images.length) {
                    for (let i = 0; i < images.length; i++) {
                        const blob = await Utils.createBlob(images[i].photo, images[i].photo_name);
                        formdata.append("post_media[]", blob);
                    }
                }
                formdata.append("post_action", "1");
                formdata.append("action", "1");
                formdata.append("is_ablum", is_album ? 1 : 0);
                if (album_name) formdata.append("title", album_name);
                formdata.append("user_id", user.user_id);
                formdata.append("privacy", "public");

                const res = await create_album_post_api(formdata);

                const {success, message, information} = res || {};

                if (success) {
                    resolve({
                        status: SUCCESS,
                        message: ""
                    });
                } else {
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("Post UI ===> error", error);
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async create_video({state, commit}, {post_id = ""}) {
        return new Promise(async (resolve, reject) => {
            try {
                const post_key = state[POST_KEY];
                const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                const file = form_data && form_data[CREATE_POST_FORM_CREATE_VIDEO] ? form_data[CREATE_POST_FORM_CREATE_VIDEO] : [];
                const blob = await Utils.createBlob(file.video, file.video_name);

                commit("updatePostUIConstraints", {
                    [CREATE_POST_LOADING]: true
                });

                var formdata = new FormData();
                formdata.append("post_id", post_id);

                formdata.append("post_action", "2");
                formdata.append("post_media", blob);
                formdata.append("action", "1");
                formdata.append("user_id", user.user_id);
                formdata.append("privacy", "public");

                const res = await create_album_post_api(formdata);

                const {success, message, information} = res || {};

                if (success) {
                    commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false
                    });

                    resolve({
                        status: SUCCESS,
                        message: ""
                    });
                } else {
                    commit("updatePostUIConstraints", {
                        [CREATE_POST_LOADING]: false
                    });

                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("Post UI ===> error", error);
                commit("updatePostUIConstraints", {
                    [CREATE_POST_LOADING]: false
                });

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async update_user_data({state, commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                await SMStorage.storeSMLoginData(data);
                const userData = await SMStorage.getSMLoginData();
                commit("updateUserUIConstraints", ({
                    [USER_DATA]: userData && userData.response ? userData.response : userData
                }));

                const {user_get_started_page} = data || {}
                commit("updateSystemDataUIConstraints", ({
                    [SYSTEM_DATA_IS_AUTHENTICATED]: true,
                    [SYSTEM_DATA_IS_WELCOME_PAGE]: user_get_started_page === "0" ? true : false
                }));

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Update user data ===> error", error);
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async get_post_data({state, commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const post_key = state[POST_KEY];

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                commit("updatePostUIConstraints", {
                    [POST_LIST_LOADING]: true
                });

                const query = `?action=4&user_id=${user.user_id}&data_limit=20&start_point=0`;
                const res = await post_api(query);

                const {success, message, data} = res || {};

                if (success) {
                    commit("updatePostUIConstraints", {
                        [POST_LIST_LOADING]: false,
                        [POST_LIST_DATA]: data
                    });

                    resolve({
                        status: SUCCESS,
                        message: ""
                    });
                } else {
                    commit("updatePostUIConstraints", {
                        [POST_LIST_LOADING]: false
                    });

                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("Post UI ===> error", error);
                commit("updatePostUIConstraints", {
                    [POST_LIST_LOADING]: false
                });

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async reset_color_post({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("updateCreatePostFormData", {
                    [CREATE_POST_FORM_POST_COLOR]: "",
                    [CREATE_POST_FORM_POST_COLOR_TEXT]: ""
                });

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Post UI ===> reset post error", error);

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async reset_upload_photo({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("updateCreatePostFormData", {
                    [CREATE_POST_FORM_SELECTED_IMAGES]: [],
                    [CREATE_POST_FORM_IS_ALBUM]: "",
                    [CREATE_POST_FORM_ALBUM_NAME]: ""
                });

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Post UI ===> reset post error", error);

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async reset_create_poll({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("updateCreatePostFormData", {
                    [CREATE_POST_FORM_POLLS]: []
                });

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Post UI ===> reset post error", error);

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async reset_upload_video({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("updateCreatePostFormData", {
                    [CREATE_POST_FORM_CREATE_VIDEO]: "",
                    [CREATE_POST_FORM_CREATE_VIDEO_NAME]: ""
                });

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Post UI ===> reset post error", error);

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async pin_post ({state, commit, dispatch}, payload) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await pin_post(payload.post.id, payload.type, payload.action, payload.post_tab);
                const {success, message} = data
                if (success === 1) {
                    if (payload.type === 'pin') {
                        commit('setPinPost', payload.post)
                    }
                    if (payload.action === 1 && payload.type === 'pin') {
                        // dispatch('unshiftPinPost', payload.post)
                        commit('setPinPost', {})
                    }
                    if(payload.type === 'hidden' || payload.type === 'timeline') {
                        dispatch('removePost', payload.post.id)
                    }
                }
                resolve({
                    status: SUCCESS,
                    message: message
                });
            } catch (error) {
                Utils.log("Pin Post ===> pin post error", error);

                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    updateCreatePostFormData(state, obj) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[POST_KEY];
            const data = Object.assign(formData[CREATE_POST_FORM], obj);

            _state.test = "data"

            mutations.updatePostState(state, Object.assign(formData, {
                [CREATE_POST_FORM]: data
            }));
        } catch (error) {
            Utils.log("Update Form Data ===> error ", error);
        }
    },
    updatePostUIConstraints(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[POST_KEY];
            const data = Object.assign(formData, obj);

            mutations.updatePostState(state, data);
        } catch (error) {
            Utils.log("Update UI Constraints ===> error ", error);
        }
    },
    updatePostState(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            let formData = _state[POST_KEY];

            state[POST_KEY] = Object.assign(formData, obj);
        } catch (error) {
            Utils.log("Update Login State ===> error ", error);
        }
    },
    resetPostState(_state_data) {
        let _state = Utils.parseState(_state_data);

        const post_key = _state[POST_KEY];

        const data = post_key && post_key[POST_LIST_DATA] ? post_key[POST_LIST_DATA] : [];

        for (let f in state) {
            Vue.set(state, f, INITIAL_STATE[f]);
        }

        mutations.updatePostUIConstraints(_state_data, {
            [POST_LIST_DATA]: data
        });


    },
    setPinPost(state, obj = {}, index) {
        try {
            state[PIN_POST] = obj
        } catch (error) {
            Utils.log("Unable to set Pin Post ", error);
        }
    },
};

const getters = {
    create_post_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];
        const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

        const post_value = form_data && form_data[CREATE_POST_FORM_VALUE] ? form_data[CREATE_POST_FORM_VALUE] : "";
        const video_name = form_data && form_data[CREATE_POST_FORM_CREATE_VIDEO_NAME] ? form_data[CREATE_POST_FORM_CREATE_VIDEO_NAME] : "";

        const error_message = post_key && post_key[CREATE_POST_ERROR_MESSAGE] ? post_key[CREATE_POST_ERROR_MESSAGE] : "";

        const errors = post_key && post_key[CREATE_POST_ERRORS] ? post_key[CREATE_POST_ERRORS] : [];
        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            post_value,
            video_name,

            error_message,

            errors,
            loading
        }
    },
    create_color_post_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];
        const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};


        const color = form_data && form_data[CREATE_POST_FORM_POST_COLOR] ? form_data[CREATE_POST_FORM_POST_COLOR] : "";
        const color_value = form_data && form_data[CREATE_POST_FORM_POST_COLOR_TEXT] ? form_data[CREATE_POST_FORM_POST_COLOR_TEXT] : "";

        const shared_with = form_data && form_data[CREATE_POST_FORM_SHARED_WITH] ? form_data[CREATE_POST_FORM_SHARED_WITH] : "";

        const errors = post_key && post_key[CREATE_POST_ERRORS] ? post_key[CREATE_POST_ERRORS] : [];
        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            color,
            color_value,

            shared_with,

            errors,
            loading
        }
    },
    create_feeling_post_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];
        const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

        const post_value = form_data && form_data[CREATE_POST_FORM_VALUE] ? form_data[CREATE_POST_FORM_VALUE] : "";
        const feeling = form_data && form_data[CREATE_POST_FORM_FEELING] ? form_data[CREATE_POST_FORM_FEELING] : "";
        const feeling_value = form_data && form_data[CREATE_POST_FORM_FEELING_TEXT] ? form_data[CREATE_POST_FORM_FEELING_TEXT] : "";

        const errors = post_key && post_key[CREATE_POST_ERRORS] ? post_key[CREATE_POST_ERRORS] : [];
        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            post_value,
            feeling,
            feeling_value,

            errors,
            loading
        }
    },
    create_article_forms_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];
        const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

        const title = form_data && form_data[CREATE_POST_FORM_ARTICLE_TITLE] ? form_data[CREATE_POST_FORM_ARTICLE_TITLE] : "";
        const content = form_data && form_data[CREATE_POST_FORM_ARTICLE_CONTENT] ? form_data[CREATE_POST_FORM_ARTICLE_CONTENT] : "";
        const cover = form_data && form_data[CREATE_POST_FORM_ARTICLE_COVER] ? form_data[CREATE_POST_FORM_ARTICLE_COVER] : "";
        const category = form_data && form_data[CREATE_POST_FORM_ARTICLE_CATEGORY] ? form_data[CREATE_POST_FORM_ARTICLE_CATEGORY] : "";
        const tags = form_data && form_data[CREATE_POST_FORM_ARTICLE_TAGS] ? form_data[CREATE_POST_FORM_ARTICLE_TAGS] : "";

        const shared_with = form_data && form_data[CREATE_POST_FORM_SHARED_WITH] ? form_data[CREATE_POST_FORM_SHARED_WITH] : "";

        const errors = post_key && post_key[CREATE_POST_ERRORS] ? post_key[CREATE_POST_ERRORS] : [];
        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            title,
            content,
            cover,
            category,
            tags,

            shared_with,

            errors,
            loading
        }
    },
    create_poll_forms_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];
        const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

        const polls = form_data && form_data[CREATE_POST_FORM_POLLS] ? form_data[CREATE_POST_FORM_POLLS] : "";

        const shared_with = form_data && form_data[CREATE_POST_FORM_SHARED_WITH] ? form_data[CREATE_POST_FORM_SHARED_WITH] : "";

        const errors = post_key && post_key[CREATE_POST_ERRORS] ? post_key[CREATE_POST_ERRORS] : [];
        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            polls,

            shared_with,

            errors,
            loading
        }
    },
    create_album_forms_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];
        const form_data = post_key && post_key[CREATE_POST_FORM] ? post_key[CREATE_POST_FORM] : {};

        const is_album = form_data && form_data[CREATE_POST_FORM_IS_ALBUM] ? form_data[CREATE_POST_FORM_IS_ALBUM] : "";
        const album_name = form_data && form_data[CREATE_POST_FORM_ALBUM_NAME] ? form_data[CREATE_POST_FORM_ALBUM_NAME] : "";
        const images = form_data && form_data[CREATE_POST_FORM_IMAGES] ? form_data[CREATE_POST_FORM_IMAGES] : [];

        const shared_with = form_data && form_data[CREATE_POST_FORM_SHARED_WITH] ? form_data[CREATE_POST_FORM_SHARED_WITH] : "";

        const errors = post_key && post_key[CREATE_POST_ERRORS] ? post_key[CREATE_POST_ERRORS] : [];
        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            is_album,
            album_name,
            images,

            shared_with,

            errors,
            loading
        }
    },
    home_map_to_props(state) {
        let _state = Utils.parseState(state);

        const post_key = _state[POST_KEY];

        const data = post_key && post_key[POST_LIST_DATA] ? post_key[POST_LIST_DATA] : [];

        const loading = post_key && post_key[CREATE_POST_LOADING] ? post_key[CREATE_POST_LOADING] : false;

        return {
            data,
            loading
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
