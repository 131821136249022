<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h5 class="page-center">PAGES</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <NewPageForm/>
      </div>
      <div class="col-6">
        <div class="create-new-page-section-n">
          <div class="create-new-page-section-detail-n">
            <span class="page-placeholder-n">
            <img :src="require(`@/assets/img/file-text.png`)">
            </span>
            <h1>Creating New Page</h1>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewPageForm from '@/components/PageManager/Pages/Common/NewPageForm.vue'
export default {
  name: "CreatePage",
  components:{
    NewPageForm
  }
}
</script>

<style scoped>
.page-center{
  text-align: center;
  font-family: 'HelveticaNeueMedium';
  color: #000000;
  font-weight: 700;
  margin-top:40px ;
  margin-bottom: 40px;
  font-family: Drukwide;
}

.create-new-page-section-n {
  background-color: #E6EAF0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-new-page-section-detail-n {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-placeholder-n {
  width: 150px;
  height: 150px;
  background-color: #7E858C;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.create-new-page-section-detail-n h1 {
  font-size: 18px;
  font-weight: 700;
  color: #071526;
  padding-top: 40px;
  font-family: HelveticaNeueMedium;
}
</style>
