<template>
  <div class="venue">
    <h2 class="venue-title mb-0">Venue Type</h2>
    <div class="venue-container">
      <div
        @click="selectVenue($event, item.id)"
        class="venue-box"
        :class="{ active: isSelectedGenre(item.id) }"
        v-for="(item, index) in venue"
        :key="index"
      >
        <div
          class="img"
          :style="{ 'mask-image': `url('` + item.artwork_url + `')` }"
        ></div>

        <p class="mb-0">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      venue: [],
      activeVenue: [],
    };
  },
  mounted() {
    this.getVenue();
  },
  methods: {
    ...mapActions(["getVenueTypes"]),
    ...mapMutations(["set_Events_Searech_For"]),
    async getVenue() {
      try {
        const { status, information } = await this.getVenueTypes();
        this.venue = information;
      } catch (error) {
        console.error(error);
      }
    },
    selectVenue(e, value) {
      e.stopPropagation();
      const index = this.activeVenue.findIndex((elem) => elem === value);
      this.set_Events_Searech_For({
        event_type:
          !this.$route.path.includes("/events/page/overview") &&
          this.$route.meta.event_Type_id,
      });
      if (index !== -1) {
        this.activeVenue.splice(index, 1);
        this.set_Events_Searech_For({ venue_type: "" });
      } else {
        this.activeVenue.length = 0;
        this.activeVenue.push(value);
        this.set_Events_Searech_For({ venue_type: value });
      }
      this.$emit("explorEvents", this.GET_EVENTS_SEARCH_FOR);
    },
    isSelectedGenre(id) {
      const index = this.activeVenue.findIndex((elem) => elem === id);
      return index !== -1;
    },
  },
  computed: {
    ...mapGetters(["GET_EVENTS_SEARCH_FOR"]),
  },
};
</script>

<style lang="scss" scoped>
.venue-container {
  div {
    cursor: pointer;
  }
}
.active {
  border: 1px solid #D1433A !important;
  background: #D1433A !important;

  .img {
    background-color: #ffffff !important;
  }
  &:hover .img {
    background-color: #ffffff !important;
  }

  p {
    color: #ffffff !important;
  }
}
.venue {
  font-family: HelveticaNeueMedium;
  &-title {
    font-family: HelveticaNeueBold;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  &-box {
    width: 170px;
    height: 50px;
    max-width: 100%;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    margin-bottom: 10px;
    margin-right: 28px;
    &:hover {
      background: #D1433A1A;
    }
    &:hover .img {
      background-color: #D1433A;
    }

    .img {
      width: 30px;
      height: 30px;
      background: #000000;
      mask-position: center;
      mask-repeat: no-repeat;
    }
  }
  p {
    margin-left: 16px;
    color: #47505b;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
