<template>
  <div class="wr_Empty_card">
    <div class="svg_div">
      <svg
        v-if="iconType === 'search'"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.0324 38.7899C47.6636 33.835 49.2899 27.6917 48.5861 21.5892C47.8823 15.4867 44.9002 9.87491 40.2366 5.87663C35.5729 1.87834 29.5715 -0.211598 23.4331 0.0249262C17.2947 0.261451 11.4719 2.807 7.1297 7.15229C2.78752 11.4976 0.246138 17.3222 0.0140065 23.4608C-0.218125 29.5994 1.87611 35.5992 5.87773 40.2601C9.87935 44.9209 15.4932 47.8989 21.5963 48.5983C27.6993 49.2978 33.8414 47.667 38.7937 44.0324H38.7899C38.9024 44.1824 39.0224 44.3249 39.1574 44.4636L53.5949 58.9011C54.2981 59.6048 55.252 60.0003 56.2467 60.0006C57.2415 60.001 58.1957 59.6062 58.8993 58.903C59.603 58.1999 59.9985 57.246 59.9988 56.2512C59.9992 55.2565 59.6044 54.3023 58.9012 53.5986L44.4637 39.1611C44.3296 39.0254 44.1855 38.9001 44.0324 38.7861V38.7899ZM44.9999 24.3749C44.9999 27.0834 44.4665 29.7654 43.43 32.2677C42.3935 34.7701 40.8742 37.0438 38.959 38.959C37.0438 40.8742 34.7701 42.3934 32.2678 43.4299C29.7655 44.4664 27.0835 44.9999 24.3749 44.9999C21.6664 44.9999 18.9844 44.4664 16.4821 43.4299C13.9798 42.3934 11.7061 40.8742 9.79087 38.959C7.87566 37.0438 6.35644 34.7701 5.31993 32.2677C4.28343 29.7654 3.74995 27.0834 3.74995 24.3749C3.74995 18.9048 5.92293 13.6587 9.79087 9.79081C13.6588 5.92287 18.9049 3.74989 24.3749 3.74989C29.845 3.74989 35.0911 5.92287 38.959 9.79081C42.827 13.6587 44.9999 18.9048 44.9999 24.3749Z"
          fill="#47505B"
        />
      </svg>
      <svg
        v-if="iconType === 'friends'"
        width="50"
        height="60"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 98 120"
        xml:space="preserve"
        class="icon-center"
      >
        <g>
          <path
            d="M0.46,79.97c0-10.24-0.01-20.48,0.01-30.72c0-0.9,0.08-1.82,0.26-2.7c0.48-2.36,2.01-3.74,4.3-4.31
                      c3.86-0.96,7.78-0.71,11.72-0.68c6.4,0.06,11.41,3.24,16.32,6.76c4.42,3.15,9.06,5.87,14.4,7.14c6.12,1.46,12.25,1.03,18.36-0.12
                      c4.74-0.89,9.46-1.93,14.24-2.55c4.4-0.58,8.8-0.1,13.02,1.37c3.47,1.21,4.48,2.77,4.47,6.39c-0.01,16.42-0.03,32.83-0.04,49.25
                      c0,3.35-0.82,6.33-3.67,8.42c-1.54,1.13-3.29,1.74-5.19,1.75c-6.22,0.02-12.44,0.05-18.66-0.02c-5.02-0.05-8.82-4.08-8.88-9.26
                      c-0.1-9.33-0.14-18.66-0.32-27.99c-0.06-2.83-0.19-5.74-0.85-8.47c-1.36-5.62-6.47-9.63-12.34-10.29c-2.83-0.32-5.6-0.17-8.34,0.53
                      c-1.78,0.45-2.05,0.79-2.06,2.57c-0.11,14.34-0.23,28.68-0.3,43.02c-0.03,5.26-3.66,10.04-9.81,9.88
                      c-6.13-0.17-12.27-0.08-18.4-0.22c-4.8-0.11-8.25-3.76-8.26-8.65c-0.02-10.02,0-20.05,0.01-30.07c0-0.35,0-0.69,0-1.04
                      C0.45,79.97,0.45,79.97,0.46,79.97z M38.31,58.64c-4.89-3.15-9.67-6.3-14.54-9.32c-1.14-0.71-2.52-1.15-3.85-1.41
                      c-3.88-0.74-7.81-0.76-11.73-0.3c-1.86,0.22-1.85,0.24-1.86,2.11C6.31,67.3,6.29,84.88,6.26,102.46c0,2.85-0.03,5.7,0.04,8.55
                      c0.04,1.87,0.92,2.74,2.76,2.91c0.86,0.08,1.72,0.12,2.59,0.12c5.23,0.01,10.45,0.01,15.68,0c2.29,0,3.68-1.37,3.7-3.71
                      c0.07-7.34,0.13-14.68,0.18-22.02c0.05-7.17,0.08-14.34,0.14-21.51c0.03-4.12,2.01-6.71,5.97-7.88
                      C37.65,58.83,37.97,58.74,38.31,58.64z M58.97,62.48c0.17,0.16,0.43,0.43,0.71,0.67c2.76,2.42,4.68,5.39,5.79,8.89
                      c1.22,3.85,1.15,7.85,1.23,11.8c0.17,8.9,0.21,17.79,0.32,26.69c0.03,2.32,1.19,3.54,3.5,3.56c5.79,0.05,11.58,0.07,17.37,0.04
                      c2.7-0.01,3.79-1.2,3.79-3.95c0.02-16.33,0.02-32.66,0.08-48.99c0-1.19-0.49-1.62-1.47-1.85c-4.17-0.97-8.37-1.18-12.59-0.41
                      C71.46,60.08,65.25,61.29,58.97,62.48z"
            class="st0"
            fill="#47505B"
          ></path>
          <path
            d="M78.74,46.07c-10.13,0-18.34-8.25-18.31-18.41c0.03-10.06,8.22-18.2,18.36-18.25c10-0.05,18.3,8.24,18.3,18.29
                      C97.09,37.86,88.89,46.07,78.74,46.07z M91.21,27.76c0-6.85-5.55-12.47-12.37-12.52c-6.83-0.04-12.56,5.64-12.58,12.49
                      c-0.02,6.81,5.59,12.45,12.41,12.46C85.65,40.21,91.21,34.7,91.21,27.76z"
            class="st0"
            fill="#47505B"
          ></path>
          <path
            d="M36.99,18.4C36.98,28.5,28.8,36.68,18.71,36.7C8.56,36.72,0.4,28.43,0.43,18.14C0.46,8.08,8.69-0.04,18.83,0
                      C28.91,0.04,37.01,8.25,36.99,18.4z M6.26,18.36c0.03,6.95,5.6,12.48,12.53,12.46c6.77-0.02,12.37-5.66,12.4-12.51
                      c0.04-6.88-5.67-12.46-12.71-12.43C11.76,5.91,6.23,11.56,6.26,18.36z"
            class="st0"
            fill="#47505B"
          ></path>
        </g>
      </svg>
    </div>
    <div class="col-md-12 pt-3">
      <h6 class="empty_title">{{ title }}</h6>
    </div>
    <div class="col-md-12 pt-2">
      <p class="empty_description">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script >
export default {
  props: {
    title: String,
    description: String,
    iconType: String,
  },
};
</script>
<style lang="scss">
.wr_Empty_card {
  margin-top: 30px;
  background: #e6eaf0;
  border-radius: 5px;
  padding: 83px 0;
  text-align: center;
  .svg_div {
    display: flex;
    justify-content: center;
  }
  .empty_title {
    color: #071526;
    font-size: 16px;
    font-family: HelveticaNeueBold;
  }
  .empty_description {
    color: #47505b;
    font-size: 12px;
    font-family: HelveticaNeueNormal;
  }
  .empty_btn {
    padding: 13px 90px;
    border-radius: 4px;
    background: #D1433A;
    border: 1.3px solid #D1433A;
    color: #ffffff;
    font-family: HelveticaNeueBold;
    font-size: 12px;
  }
}
</style>
