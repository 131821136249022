<template>
  <div class="Signup_pop_overlay">
    <div
      class="modal fade login-type show"
      id="login"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px;"
      aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="close-button"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="closeLoginModal"
          >
            <div class="svg-icon">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                class="svg-close"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6111 1.8703L1.87048 11.6109"
                  class="c-line-1"
                  stroke-width="1.3"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M1.87012 1.8703L11.6107 11.6109"
                  class="c-line-1"
                  stroke-width="1.3"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <!-- <img src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/logo.png" alt=""> -->
            <img
              :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body">
            <div class="body-title Font_semibold">
              <a class="back-arrow" v-on:click="goBack">
                <div class="svg-icon">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                      stroke="#C4CCD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      class="c-line-1"
                      d="M15 10L10 15L15 20"
                      stroke="#C4CCD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      class="c-line-1"
                      d="M20 15H10"
                      stroke="#C4CCD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
              Log in
            </div>
            <p class="error_msg error_msg_red" v-if="message">{{ message }}</p>
            <div class="input-wrap">
              <div class="svg-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66732 2.66602H13.334C14.0673 2.66602 14.6673 3.26602 14.6673 3.99935V11.9993C14.6673 12.7327 14.0673 13.3327 13.334 13.3327H2.66732C1.93398 13.3327 1.33398 12.7327 1.33398 11.9993V3.99935C1.33398 3.26602 1.93398 2.66602 2.66732 2.66602Z"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M14.6673 4L8.00065 8.66667L1.33398 4"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                placeholder="abc@gmail.com"
                :value="email"
                name="login_form_email"
                readonly
              />
            </div>
            <div class="input-wrap m-t-10">
              <div class="svg-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6667 7.33398H3.33333C2.59695 7.33398 2 7.93094 2 8.66732V13.334C2 14.0704 2.59695 14.6673 3.33333 14.6673H12.6667C13.403 14.6673 14 14.0704 14 13.334V8.66732C14 7.93094 13.403 7.33398 12.6667 7.33398Z"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M4.66602 7.33398V4.66732C4.66602 3.78326 5.01721 2.93542 5.64233 2.3103C6.26745 1.68517 7.11529 1.33398 7.99935 1.33398C8.8834 1.33398 9.73125 1.68517 10.3564 2.3103C10.9815 2.93542 11.3327 3.78326 11.3327 4.66732V7.33398"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                :type="type"
                placeholder="Password"
                :value="password"
                @change="onChange"
                autocomplete="off"
                name="login_form_password" />
              <div class="svg-eye-icon cursor-pointer"  @click="hideAndShowPassword">
                 <span class="icon is-small is-right">
                <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
              </span>
              </div>
            </div>
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('_password')">{{ _handleErrorMessage("_password") }}</label>
            <div
              class="btn btn-login m-t-20 Font_semibold login_btn"
              v-on:click="submit"
            >
              {{ loading ? "Please Wait..." : "LOG IN" }}
            </div>
            <div class="custom-hr Login_icons_hr"></div>
            <div class="modal-title-sm fW_500" v-on:click="restorePassword">
              Forgot your password? <a href="#">Restore Password</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as Helper from "../../../apis/Helper.js";
import {
  LOGIN_ERRORS,
  LOGIN_FORM_EMAIL,
  LOGIN_FORM_PASSWORD,
  SERVER_VERIFICATION_ERROR
} from "../../../store/types";
import password from "../../Home/settings/Password";

export default {
  data() {
    return {
      message: "",
      showPassword: false,
      type: 'password'
    };
  },
  computed: {
    ...mapGetters({
      form_data: "login_form_map_to_props",
      check_email_data: "signup_email_form_map_to_props"
    }),
    email() {
      const { email = "" } = this.form_data || {};

      return email;
    },
    password() {
      const { password = "" } = this.form_data || {};

      return password;
    },
    loading() {
      const { loading = false } = this.form_data || {};

      return loading;
    },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    }
  },
  mounted() {
    const { email = "" } = this.check_email_data;

    if (email) {
      this.updateLoginFormData({
        [LOGIN_FORM_EMAIL]: email
      });
    }
  },
  methods: {
    ...mapActions([
      "login_user"
    ]),
    ...mapMutations([
      "updateLoginFormData",
      "updateLoginUIConstraints",
      "updateSignupFormData"
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
            this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    onChange(e) {
      const { name, value } = e.target || {};

      this.updateLoginFormData({
        [name]: value
      });
    },
    submit() {

      const body = {
        email: this.email,
        _password: this.password
      };

      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateLoginUIConstraints({
              [LOGIN_ERRORS]: []
            });
            try {
              this.message = "";
              const { status, response } = await this.login_user();
              switch (status) {
                case SERVER_VERIFICATION_ERROR:
                  this.$parent.newModal("verify-email");
                  break;
                default:
                  this.closeLoginModal();
                  this.$router.replace("/home");
              }
              this.$store.commit('setLoginLoader', true)
              await this.$store.dispatch("getGetStreamAccessToken", this.userId);
              await this.subscribeGetStream();
              await this.$store.dispatch("getGetStreamMessagingAccessToken", this.userId);
              await this.setMessagingConnection()
              await this.$store.dispatch('getTwilioAccessToken');
              this.$store.commit('setLoginLoader', false)
            } catch (e) {
              this.message = e && e.message ? e.message : "Please try again.";
            }
            finally {
              this.updateLoginFormData({
                [LOGIN_FORM_PASSWORD]: "",
              });
              this.$store.commit('setLoginLoader', false)
            }
          } else this.updateLoginUIConstraints({
            [LOGIN_ERRORS]: response && response.length ? response : []
          });
        })
        .catch(err => console.log(err));
    },
    closeLoginModal: function() {
      this.updateSignupFormData({});
      this.$parent.closeModal();
    },
    goBack: function() {
      this.$parent.previousStep();
    },
    restorePassword: function() {
      this.$parent.newModal("restore-password");
    },
    hideAndShowPassword() {
      if(this.showPassword) {
        this.type = 'password'
        this.showPassword =  false
      } else {
        this.type = 'text'
        this.showPassword =  true
      }
    }
  }
};
</script>
<style scoped>
.svg-eye-icon {
  position: absolute;
  left: 17.5rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
