<template>
  <div>
    <About :show-rate-per-hour="true" :edit-able="(currentPage && currentPage.current_user === 'admin') ? true : false"/>
    <Projects :edit-able="(currentPage && currentPage.current_user === 'admin') ? true : false"/>
    <JobHistory/>
    <Experience :edit-able="(currentPage && currentPage.current_user === 'admin') ? true : false"/>
    <Education :edit-able="(currentPage && currentPage.current_user === 'admin') ? true : false"/>
  </div>
</template>

<script>
import About from './TabComponents/About.vue'
import Projects from './TabComponents/Projects.vue'
import JobHistory from './TabComponents/JobHistory.vue'
import Experience from './TabComponents/Experience.vue'
import Education from './TabComponents/Education.vue'

export default {
  name: "WorkInfoTab",
  data() {
    return {
      readMore: false,
      category: null
    }
  },
  components: {
    About,
    Projects,
    JobHistory,
    Experience,
    Education
  },
  mounted() {
    this.$store.dispatch('getPageCategories')
  },
  computed: {
    pageCategories() {
      return this.$store.getters.getPageCategories.map(category => {
        return {
          value: category.id,
          text: category.name,
          subCategories: category.page_sub_category
        }
      })
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    pageType() {
      return (this.currentPage) ? this.currentPage.type : null
    },
    aboutContent() {
      let {content} = this.currentPage || {}
      return content
    },
    pateTitle() {
      let {about_title} = this.currentPage || {}
      return about_title
    },
    aboutContentDisplayed() {
      if (!this.aboutContent) return ''
      return (this.aboutContent.length > 360 && this.readMore === false) ? this.aboutContent.slice(0, 360) : this.aboutContent
    }
  }
}
</script>

<style scoped lang="scss">
.about-section-page {

  .projects {
    .project-card {
      .text-project {
        color: #C4CCD6;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.05em;
        text-align: left;
      }

      .title-project {
        font-size: 15px;
        color: #D1433A;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }

      .date-project {
        color: #C4CCD6;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;

      }

    }


  }


  .horizontal-line {
    border-width: 3px !important;
  }
}

</style>
