<template>
  <div class="w-100">
    <InSightHeader/>
    <div class="container_xxl">
      <div class="row">
        <div class="col-12">
          <div class="edit-share-section artist-music-tab mb_30px">
            <div class="detail-tab">
              <nav>
                <div class="nav top-tab nav-tabs-iconed" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link active" data-toggle="tab" href="#page_insights" role="tab"
                     aria-selected="true">
                    <div class="svg_icon">
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.375 8V15.5" stroke="#47505B" stroke-width="1.5"></path>
                        <path d="M7 0.5V15.5" stroke="#47505B" stroke-width="1.5"></path>
                        <path d="M12.625 4.25V15.5" stroke="#47505B" stroke-width="1.5"></path>
                      </svg>
                    </div>
                    PAGE INSIGHTS</a>
                  <a class="nav-item nav-link" data-toggle="tab" href="#streaming_stats" role="tab"
                     aria-selected="false">
                    <div class="svg_icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12V3.33333L14 2V10.6667" stroke="#C4CCD6" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path
                            d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
                            stroke="#C4CCD6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                            d="M12 12.666C13.1046 12.666 14 11.7706 14 10.666C14 9.56145 13.1046 8.66602 12 8.66602C10.8954 8.66602 10 9.56145 10 10.666C10 11.7706 10.8954 12.666 12 12.666Z"
                            stroke="#C4CCD6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                    </div>
                    STREAMING STATS</a>
                  <a class="nav-item nav-link" data-toggle="tab" href="#selling_stats" role="tab" aria-selected="false">
                    <div class="svg_icon">
                      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.108 13.2218C1.48032 13.2218 1.00783 12.6503 1.12582 12.0338L3.12494 1.58935C3.21513 1.11811 3.62731 0.777344 4.10711 0.777344H13.8887C14.3685 0.777344 14.7806 1.11807 14.8709 1.58927L16.8709 12.0337C16.989 12.6502 16.5165 13.2218 15.8888 13.2218H2.108Z"
                            stroke="#C4CCD6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                            d="M11.2852 3.86133V5.28957C11.2014 5.81026 10.9119 6.27937 10.4774 6.59838C10.0428 6.91739 9.49711 7.06146 8.95483 7.00034C8.41255 7.06146 7.86683 6.91739 7.4323 6.59838C6.99776 6.27937 6.70823 5.81026 6.62451 5.28957V3.86133"
                            stroke="#C4CCD6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>

                    </div>
                    SELLING STATS</a>
                </div>
              </nav>

            </div>
            <div class="dates_selection">
              <div class="select_box select_box_blue">
                <select class="form_control form_control_blue">
                  <option value="1">Last 7 Days</option>
                  <option value="1">Last 15 Days</option>
                  <option value="1">Last 24 Days</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade active show" id="page_insights" role="tabpanel">
          <div class="row">
            <div class="col-12">
              <div class="insights_result">
                <div class="insights_result_header">
                  <h2>Results from Jan 15, 2021 – Jan 22, 2021</h2>
                  <div class="d-flex">
                    <div class="hints">
                      <span class="hint_blue"></span>Organic
                    </div>
                    <div class="hints">
                      <span class="hint_green"></span>Paid
                    </div>
                  </div>
                </div>
                <div class="insights_result_body">
                  <ul class="insights_results_listing">
                    <li class="insights_results_list">
                      <div class="insight_results_list_header">
                        <div>
                          <h4>PAGE VIEWS</h4>
                          <h5>240k</h5>
                        </div>
                        <div>
                          <h6><span class="text_danger">-0.3%</span> last week</h6>
                          <ul>
                            <li class="text_success">+12.0%</li>
                            <li class="text_primary">-12.3%</li>
                          </ul>
                        </div>
                      </div>
                      <div class="insight_results_list_body">
                        <div class="chart-section">
                          <line-chart :chart-data="datacollection" id="myChart"></line-chart>
                        </div>
                      </div>
                    </li>
                    <li class="insights_results_list" v-for="n in 7" :key="n">
                      <div class="insight_results_list_header">
                        <div>
                          <h4>PAGE FOLLOWERS</h4>
                          <h5>24K</h5>
                        </div>
                        <div>
                          <h6><span class="text_danger">-0.3%</span> last week</h6>
                          <ul>
                            <li class="text_success">+12.0%</li>
                            <li class="text_primary">-12.3%</li>
                          </ul>
                        </div>
                      </div>
                      <div class="insight_results_list_body">

                        <img class="img-fluid" :src="require(`@/assets/img/svg/graph.svg`)" alt="icon">
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-9">
              <div class="top_post_insights">
                <div class="top_post_insights_header">
                  <h2>Top Posts</h2>
                  <div>
                    <div class="select_box top-post-select w150">
                      <select class="form_control">
                        <option value="1">Last 7 Days</option>
                        <option value="1">Last 15 Days</option>
                        <option value="1">Last 24 Days</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="top_post_insights_body">
                  <table class="table_insights">
                    <thead>
                    <tr>
                      <td>Post</td>
                      <td class="sorting sorting_acc">Date</td>
                      <td>Reach (organic/paid)</td>
                      <td>Engagement (organic/paid)</td>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="n in 4" :key="n">
                      <td>
                        <div class="insigts_thumb">

                          <img :src="require(`@/assets/img/svg/thumb1.png`)" alt="image"> My Top 10 Tracks
                        </div>
                      </td>
                      <td>
                        <div class="insight_date_time">
                          <h5>10.12.20</h5>
                          <h6>04:30 PM</h6>
                        </div>
                      </td>
                      <td>
                        <div class="reach_tags">
                          <div class="reach_tag">
                            <span class="hint_blue"></span> 240 000
                          </div>
                          <div class="reach_tag">
                            <span class="hint_green"></span> 120 000
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="reach_tags">
                            <div class="reach_tag">
                              <span class="hint_blue"></span> 3 200
                            </div>
                            <div class="reach_tag">
                              <span class="hint_green"></span> 2 400
                            </div>
                          </div>
                          <div class="d-flex ml-auto">
                            <button class="outline_primary_button_md w100">PROMOTE</button>
                            <div class="more_button">
                              <img :src="require(`@/assets/img/svg/more-vertical.svg`)" alt="icon">
                            </div>
                          </div>
                        </div>

                      </td>


                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="more-post-to-show">
                  <a href="javascript:;">
                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.24219 0.691406V7.02141" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M1 3.85547H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                    Show 5 More Posts
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="tab-pane fade" id="streaming_stats" role="tabpanel">
          <div class="row">
            <div class="col-2">
              <div class="page_insights">
                <h6>PLAYS</h6>
                <h2>240k</h2>
              </div>
            </div>
            <div class="col-2">
              <div class="page_insights">
                <h6>UNIQUE LISTENERS</h6>
                <h2>3,8k</h2>
              </div>
            </div>
            <div class="col-2">
              <div class="page_insights">
                <h6>LIKES</h6>
                <h2>2,8k</h2>
              </div>
            </div>
            <div class="col-2">
              <div class="page_insights">
                <h6>PLAYLIST ADDS</h6>
                <h2>1,3k</h2>
              </div>
            </div>
            <div class="col-2">
              <div class="page_insights">
                <h6>REPOSTS</h6>
                <h2 class="text-color-blue">423</h2>
                <a href="javascript:;">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                      <path
                          d="M8.33203 10.834C8.68991 11.3124 9.1465 11.7083 9.67082 11.9947C10.1952 12.2812 10.775 12.4516 11.3709 12.4942C11.9669 12.5369 12.565 12.4509 13.1248 12.2421C13.6846 12.0333 14.193 11.7065 14.6154 11.284L17.1154 8.78396C17.8744 7.99811 18.2943 6.9456 18.2848 5.85312C18.2753 4.76063 17.8371 3.71558 17.0646 2.94304C16.2921 2.17051 15.247 1.73231 14.1545 1.72281C13.062 1.71332 12.0095 2.1333 11.2237 2.89229L9.79036 4.31729"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                          d="M11.6678 9.16702C11.3099 8.68858 10.8533 8.2927 10.329 8.00623C9.80469 7.71977 9.22489 7.54942 8.62893 7.50674C8.03298 7.46406 7.43482 7.55004 6.87502 7.75887C6.31522 7.96769 5.80688 8.29446 5.38448 8.71702L2.88448 11.217C2.12548 12.0029 1.70551 13.0554 1.715 14.1479C1.72449 15.2403 2.1627 16.2854 2.93523 17.0579C3.70777 17.8305 4.75282 18.2687 5.84531 18.2782C6.93779 18.2877 7.9903 17.8677 8.77614 17.1087L10.2011 15.6837"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div class="col-2">
              <div class="page_insights">
                <h6>FOLLOWERS</h6>
                <h2 class="text-color-blue">3,2k</h2>
                <a href="javascript:;">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                      <path
                          d="M8.33203 10.834C8.68991 11.3124 9.1465 11.7083 9.67082 11.9947C10.1952 12.2812 10.775 12.4516 11.3709 12.4942C11.9669 12.5369 12.565 12.4509 13.1248 12.2421C13.6846 12.0333 14.193 11.7065 14.6154 11.284L17.1154 8.78396C17.8744 7.99811 18.2943 6.9456 18.2848 5.85312C18.2753 4.76063 17.8371 3.71558 17.0646 2.94304C16.2921 2.17051 15.247 1.73231 14.1545 1.72281C13.062 1.71332 12.0095 2.1333 11.2237 2.89229L9.79036 4.31729"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                          d="M11.6678 9.16702C11.3099 8.68858 10.8533 8.2927 10.329 8.00623C9.80469 7.71977 9.22489 7.54942 8.62893 7.50674C8.03298 7.46406 7.43482 7.55004 6.87502 7.75887C6.31522 7.96769 5.80688 8.29446 5.38448 8.71702L2.88448 11.217C2.12548 12.0029 1.70551 13.0554 1.715 14.1479C1.72449 15.2403 2.1627 16.2854 2.93523 17.0579C3.70777 17.8305 4.75282 18.2687 5.84531 18.2782C6.93779 18.2877 7.9903 17.8677 8.77614 17.1087L10.2011 15.6837"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>


          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="widget_insights">
                <div class="widget_view_heading">
                  Plays by Sources
                </div>
                <div class="widget_view_body">
                  <ul class="progress_listing">
                    <li>
                      <div class="progress_text">
                        <h3>Playlists</h3>
                        <h4>160k plays / 60%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 60%;"></div>
                      </div>
                    </li>
                    <li>
                      <div class="progress_text">
                        <h3>Trending</h3>
                        <h4>40k plays / 30%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 30%;"></div>
                      </div>
                    </li>
                    <li>
                      <div class="progress_text">
                        <h3>Charts</h3>
                        <h4>20k plays / 15%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 15%;"></div>
                      </div>
                    </li>
                    <li>
                      <div class="progress_text">
                        <h3>Profile</h3>
                        <h4>10k plays / 8%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 8%;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="widget_view_footer">
                  <div class="tags_listed"><span>Also:</span> Chat, Share, Reposts</div>
                  <div>
                    <button class="outline_primary_button_sm w150" v-on:click="showAllList = true">ALL SOURCES (4)
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="widget_insights">
                <div class="widget_view_heading">
                  Plays by GEO
                </div>
                <div class="widget_view_body">
                  <div class="map_view_globe">

                    <img :src="require(`@/assets/img/svg/map.png`)" alt="image">
                  </div>
                </div>
                <div class="widget_view_footer">
                  <div class="tags_listed"><span>Top Countries:</span> United States, Russia, Australia</div>
                  <div>
                    <button class="outline_primary_button_sm w150" @click="geolist = true">COUNTRIES LIST (3)</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="most_view mb-5">
                <div class="most_view_header">
                  Most Played Tracks
                </div>
                <div class="most_view_body">

                  <div class="mb10px" v-for="n in 4" :key="n">
                    <SingleSong/>
                  </div>
                </div>
                <div class="event_more_btn">
                  <div class="show-more musicbar_more"><a href="javascript:;"><span class="show_more_bg"><svg width="9"
                                                                                                              height="8"
                                                                                                              viewBox="0 0 9 8"
                                                                                                              fill="none"
                                                                                                              xmlns="http://www.w3.org/2000/svg"><path
                      d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round"></path> <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                                                            stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              SHOW 4 MORE TRACKS
                           </span></a></div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="most_view mb-5">
                <div class="most_view_header">
                  Most Discussed Tracks
                </div>
                <div class="most_view_body">
                  <div class="mb10px" v-for="n in 4" :key="n">
                    <SingleSong/>
                  </div>
                </div>
                <div class="event_more_btn">
                  <div class="show-more musicbar_more"><a href="javascript:;"><span class="show_more_bg"><svg width="9"
                                                                                                              height="8"
                                                                                                              viewBox="0 0 9 8"
                                                                                                              fill="none"
                                                                                                              xmlns="http://www.w3.org/2000/svg"><path
                      d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round"></path> <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                                                            stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              SHOW 4 MORE TRACKS
                                </span></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="selling_stats" role="tabpanel">
          <div class="row">
            <div class="col-3">
              <div class="page_insights">
                <h6>SALES</h6>
                <h2 class="text-color-blue">240</h2>
              </div>
            </div>
            <div class="col-3">
              <div class="page_insights">
                <h6>GROSS</h6>
                <h2 class="text-color-blue">$3,8k</h2>
              </div>
            </div>
            <div class="col-3">
              <div class="page_insights">
                <h6>REPOSTS</h6>
                <h2>423</h2>
                <a href="javascript:;">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                      <path
                          d="M8.33203 10.834C8.68991 11.3124 9.1465 11.7083 9.67082 11.9947C10.1952 12.2812 10.775 12.4516 11.3709 12.4942C11.9669 12.5369 12.565 12.4509 13.1248 12.2421C13.6846 12.0333 14.193 11.7065 14.6154 11.284L17.1154 8.78396C17.8744 7.99811 18.2943 6.9456 18.2848 5.85312C18.2753 4.76063 17.8371 3.71558 17.0646 2.94304C16.2921 2.17051 15.247 1.73231 14.1545 1.72281C13.062 1.71332 12.0095 2.1333 11.2237 2.89229L9.79036 4.31729"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                          d="M11.6678 9.16702C11.3099 8.68858 10.8533 8.2927 10.329 8.00623C9.80469 7.71977 9.22489 7.54942 8.62893 7.50674C8.03298 7.46406 7.43482 7.55004 6.87502 7.75887C6.31522 7.96769 5.80688 8.29446 5.38448 8.71702L2.88448 11.217C2.12548 12.0029 1.70551 13.0554 1.715 14.1479C1.72449 15.2403 2.1627 16.2854 2.93523 17.0579C3.70777 17.8305 4.75282 18.2687 5.84531 18.2782C6.93779 18.2877 7.9903 17.8677 8.77614 17.1087L10.2011 15.6837"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
            <div class="col-3">
              <div class="page_insights">
                <h6>FOLLOWERS</h6>
                <h2>3,2k</h2>
                <a href="javascript:;">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.4">
                      <path
                          d="M8.33203 10.834C8.68991 11.3124 9.1465 11.7083 9.67082 11.9947C10.1952 12.2812 10.775 12.4516 11.3709 12.4942C11.9669 12.5369 12.565 12.4509 13.1248 12.2421C13.6846 12.0333 14.193 11.7065 14.6154 11.284L17.1154 8.78396C17.8744 7.99811 18.2943 6.9456 18.2848 5.85312C18.2753 4.76063 17.8371 3.71558 17.0646 2.94304C16.2921 2.17051 15.247 1.73231 14.1545 1.72281C13.062 1.71332 12.0095 2.1333 11.2237 2.89229L9.79036 4.31729"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path
                          d="M11.6678 9.16702C11.3099 8.68858 10.8533 8.2927 10.329 8.00623C9.80469 7.71977 9.22489 7.54942 8.62893 7.50674C8.03298 7.46406 7.43482 7.55004 6.87502 7.75887C6.31522 7.96769 5.80688 8.29446 5.38448 8.71702L2.88448 11.217C2.12548 12.0029 1.70551 13.0554 1.715 14.1479C1.72449 15.2403 2.1627 16.2854 2.93523 17.0579C3.70777 17.8305 4.75282 18.2687 5.84531 18.2782C6.93779 18.2877 7.9903 17.8677 8.77614 17.1087L10.2011 15.6837"
                          stroke="#D1433A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </a>
              </div>
            </div>


          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="widget_insights">
                <div class="widget_view_heading">
                  Sales by Sources
                </div>
                <div class="widget_view_body">
                  <ul class="progress_listing">
                    <li>
                      <div class="progress_text">
                        <h3>Playlists</h3>
                        <h4>160k sells / 60%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 60%;"></div>
                      </div>
                    </li>
                    <li>
                      <div class="progress_text">
                        <h3>Trending</h3>
                        <h4>40k sells / 30%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 30%;"></div>
                      </div>
                    </li>
                    <li>
                      <div class="progress_text">
                        <h3>Charts</h3>
                        <h4>20k sells / 15%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 15%;"></div>
                      </div>
                    </li>
                    <li>
                      <div class="progress_text">
                        <h3>Profile</h3>
                        <h4>10k sells / 8%</h4>
                      </div>
                      <div class="progress_widget">
                        <div class="progress_inner" style="width: 8%;"></div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="widget_view_footer">
                  <div class="tags_listed"><span>Also:</span> Chat, Share, Reposts</div>
                  <div>
                    <button class="outline_primary_button_sm w150" v-on:click="showAllList = true">ALL SOURCES (4)
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="widget_insights">
                <div class="widget_view_heading">
                  Sales by GEO
                </div>
                <div class="widget_view_body">
                  <div class="map_view_globe">

                    <img :src="require(`@/assets/img/svg/map.png`)" alt="image">
                  </div>
                </div>
                <div class="widget_view_footer">
                  <div class="tags_listed"><span>Top Countries:</span> United States, Russia, Australia</div>
                  <div>
                    <button class="outline_primary_button_sm w150" @click="geolist = true">COUNTRIES LIST (3)</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="most_view mb-5">
                <div class="most_view_header">
                  Most Played Tracks
                </div>
                <div class="most_view_body">
                  <div class="mb10px" v-for="(n, index) in 4" :key="index">
                    <SingleSong/>
                  </div>
                </div>
                <div class="most_view_footer">

                  <a href="javascript:;" class="more_tracks"><img :src="require(`@/assets/img/svg/more.svg`)"
                                                                  alt="icon"> SHOW 5 MORE TRACKS</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="most_view mb-5">
                <div class="most_view_header">
                  Most Discussed Tracks
                </div>
                <div class="most_view_body">
                  <div class="mb10px" v-for="n in 4" :key="n">
                    <SingleSong/>
                  </div>
                </div>
                <div class="most_view_footer">

                  <a href="javascript:;" class="more_tracks"><img :src="require(`@/assets/img/svg/more.svg`)"
                                                                  alt="icon"> SHOW 5 MORE TRACKS</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <play-by-source-list v-if="showAllList"/>
    <PlaysByGeo v-if="geolist"/>
  </div>
</template>
<script type="application/javascript">
import SingleSong from '../../Common/UiList/SingleSong'
import InSightHeader from './InSightHeader'
import LineChart from './LineChart'
import PlayBySourceList from './PlayBySourceList';
import PlaysByGeo from './PlaysByGeo';

export default {
  data() {
    return {
      datacollection: null,
      gradient: null,
      showAllList: false,
      geolist: false
    }
  },
  components: {
    InSightHeader,
    LineChart,
    SingleSong,
    PlayBySourceList,
    PlaysByGeo
  },
  mounted() {
    this.fillData()
  },
  computed: {},
  methods: {
    fillData() {

      this.datacollection = {
        labels: ["02:00", "04:00", "06:00", "08:00", "10:00"],
        datasets: [
          {
            background: 'aqua',
            data: [2, 1, 5, 2, 7]
          }, {

            data: [5, 4, 7, 4, 1]
          }
        ]
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    closeModal: function () {
      this.showAllList = false;
      this.geolist = false;
    }
  }
}
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
