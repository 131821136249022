<template>
  <div
    class="Signup_pop_overlay"
    @click="showLinkToPostModal('showLinkToPost')"
  >
    <div class="wrap_Report_modal">
      <div class="modal_Report" @click.stop="() => null">
        <div class="content1">
          <h4 class="name">Link to post</h4>
          <div class="text">
            {{
              this.$route.params.id
                ? getLink
                : getLink + "/thread/" + GET_THREAD_DATA.thread.id
            }}
          </div>
          <input style="display: none;" ref="input" type="text" readonly :value="(this.$route.params.id) ? getLink : getLink + '/thread/' + GET_THREAD_DATA.thread.id"/>
          <div class="btn">
            <button
              class="cancel"
              @click.stop="showLinkToPostModal('showLinkToPost')"
            >
              CANCEL
            </button>
            <button @click.stop="copy" class="confirm">COPY</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LinkToPostModal",
  computed:{
    ...mapGetters(["GET_THREAD_DATA"]),
    getLink() {
      return window.location.href
    }
  },
  methods:{
    showLinkToPostModal(showType) {
      this.$parent.showModals(showType);
    },
    copy() {
      this.$refs.input.focus();
      document.execCommand('copy');
      this.notificationToast(true, 'Copy', 'Link copied to the clipboard', 5000, 'success')
      this.showLinkToPostModal('showLinkToPost')
    }
  }
};
</script>

<style scoped lang="scss">
.wrap_Report_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .modal_Report {
    width: 370px;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    box-shadow: 0px 4px 20px rgba(71, 80, 91, 0.25);
    border-radius: 4px;
    .content1 {
      padding: 22px 20px 30px;
      h4 {
        font-family: "HelveticaNeueBold";
        font-size: 18px;
        line-height: 22px;
        color: #071526;
      }
      .text {
        color: #47505b;
        padding: 25px 0 23px;
        font-size: 15px;
        border-bottom: 1px solid #e6eaf0;
        border-top: 1px solid #e6eaf0;
        margin: 17px 0 20px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-family: HelveticaNeueNormal;
        span {
          font-family: HelveticaNeueMedium;
        }
      }
      .btn {
        display: flex;
        justify-content: space-between;
        padding: 0;
        button {
          width: 160px;
          height: 40px;
          font-family: HelveticaNeueBold;
          border-radius: 4px;
          display: flex;
          align-items: center;
          font-size: 13px;
          justify-content: center;
          padding: 0;
        }

        .cancel {
          background: #ffffff;
          border: 1px solid #8b949f;
          color: #8b949f;
        }
        .confirm {
          background: #D1433A;
          border: 1.3px solid #D1433A;
          color: #fff;
        }
      }
    }
  }
  .content2 {
    padding: 35px 20px 20px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #071526;
      font-size: 18px;
      font-family: HelveticaNeueBold;
      svg {
        margin-bottom: 17px;
      }
    }
    .text {
      margin-top: 17px;
      font-size: 15px;
      color: #47505b;
      padding: 24px 0;
      border-bottom: 1px solid #e6eaf0;
      border-top: 1px solid #e6eaf0;
      font-family: HelveticaNeueNormal;
    }
  }
}
</style>
