<template>
  <div class="long-road-section">
    <div class="road-section-detail">
      <!--      <h1>The Long Road</h1>-->
      <p class="description-1">DESCRIPTION</p>
      <!--      <p>Lorem ipsum dolor sit amet, consectetur cb adipiscing elit, sed do eiusmod tempor bilk incididunt ut labore et dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, i sed do eiusmod tempor incididunt ut labore </p>-->
      <p style="word-break: break-all">
      {{ song && song.description ? (isDescriptionExpanded ? song.description : song.description.slice(0, 300)) : "" }}
      </p>
      <a
        v-if="song && song.description && song.description.length > 300"
        class="d-flex justify-content-end align-items-center"
        href="javascript:;"
        @click="toggleDescription"
      >
      <span class="mt-0">{{ isDescriptionExpanded ? 'SHOW LESS' : 'SHOW MORE' }}</span>
        <svg
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            d="M8.03906 0.741211L4.79106 4.04121L1.25706 0.741211"
            stroke="#8B949F"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    </div>
    <div class="road-section-2">
      <div class="road-section-2-main">
        <div class="road-section-2-main-detail">
          <div class="road-section-include">
            <h1>INCLUDES</h1>
            <p>Allbum, streaming, 00 songs</p>
          </div>
          <div class="road-section-include comp1">
            <h1>FILE DETAILS</h1>
            <p>MP3 360kbps, FLAC</p>
          </div>
        </div>
        <div class="road-section-2-main-detail">
          <div class="road-section-include">
            <h1>RECORD LABEL</h1>
            <p>
              {{
                song && song.record_label ? song.record_label : "Record Label"
              }}
            </p>
          </div>
          <div class="road-section-include comp1">
            <h1>COMPOSERS</h1>
            <p>{{ song && song.composer ? song.composer : "Composer" }}</p>
          </div>
        </div>
        <div class="road-section-2-main-detail main-detail-last-child">
          <div class="road-section-include">
            <h1>RELEASE DATE</h1>
            <p>
              {{
                song && song.released_at ? getFormatDate(song.released_at) : ""
              }}
            </p>
          </div>
          <div class="road-section-include comp1">
            <h1 class="copy-right-sec">
              {{
                song && song.all_rights_reserved === 1
                  ? "© All rights reserved"
                  : ""
              }}
            </h1>
          </div>
        </div>
      </div>
      <div class="lower-sections">
        <ul>
          <li v-for="(tag, index) in getTags" :key="index">
            <a class="tag-class" href="javascript:;"
              ><span v-html="getTagWithHash(tag.tag)"></span
            ></a>
          </li>
        </ul>
        <div v-if="false" class="edit-icon">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8162 7.30157L6.94922 3.43457"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.3925 5.01L9.3825 1L1.7205 8.662L1.0075 12.744C0.990989 12.8607 1.00183 12.9796 1.03916 13.0913C1.07649 13.2031 1.13929 13.3046 1.2226 13.3879C1.30591 13.4712 1.40745 13.534 1.5192 13.5713C1.63095 13.6087 1.74984 13.6195 1.8665 13.603L5.6665 12.672L13.3925 5.01Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import moment from "moment";

export default {
  data() {
    return {
      isDescriptionExpanded: false,
    }
  },
  props: {
    song: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getTags() {
      return this.song && this.song.tags && this.song.tags.length > 0
        ? this.song.tags
        : [];
    },
  },
  methods: {
    getFormatDate(date) {
      return moment(date).format(" MMMM Do, YYYY");
    },
    getTagWithHash(tag) {
      return tag.includes("#") ? tag : "#" + tag;
    },
    toggleDescription() {
      this.isDescriptionExpanded = !this.isDescriptionExpanded;
    },
  },
};
</script>
<style lang="scss" scoped>
.event-main {
  .long-road-section {
    .road-section-detail {
      min-width: 200px;
      .description-1 {
        margin-top: 0 !important;
        margin-bottom: 15px;
      }
      a {
        position: relative;
        bottom: 0;
        margin-top: 16px;
        span {
          font-style: normal !important;
          font-weight: normal !important;
          font-size: 11px !important;
          line-height: 13px !important;
          font-family: HelveticaNeueBold, sans-serif;
          color: #8b949f;
          opacity: 0.9;
        }
      }
    }
  }
  .lower-sections {
    .tag-class {
      width: auto !important;
      min-width: 90px !important;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
</style>


<style scoped>
.description-1 {
  font-size: 12px;
  font-weight: 400;
  color: #47505b;
  margin-top: 11px;
  margin-bottom: 0;
  opacity: 0.7;
}
</style>
