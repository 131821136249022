<template>
  <div class="content-sidebar">
    <div class="related-widget widget-info new_widget">
      <div class="widget-item">
        <div class="item">
          <div class="items-main">
            <div class="item-title d-flex align-items-center">POSTS</div>
            <div class="item-subtitle d-flex align-items-center">{{posts | addZeroIfLengthIsLess}}</div>
          </div>
        </div>
        <div class="item">
          <div class="items-main">
            <div class="item-title d-flex align-items-center">MEMBERS</div>
            <div class="item-subtitle d-flex align-items-center">{{ members | addZeroIfLengthIsLess }}</div>
          </div>
        </div>
        <div class="item">
          <div class="items-main">
            <div class="item-title d-flex align-items-center">MEDIAS</div>
            <div class="item-subtitle d-flex align-items-center">{{ medias | addZeroIfLengthIsLess }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClubStatistics",
  filters: {
    addZeroIfLengthIsLess (data) {
      return (data && data < 10) ? '0' + data : data
    }
  },
  computed:{
    posts() {
      return this.$store.getters.getCurrentPagePostsCount;
    },
    members() {
      return this.$store.getters.getCurrentPageMembersCount;
    },
    medias() {
      return this.$store.getters.getCurrentPageMediasCount;
    }
  },
  methods:{
    getFollowers (followers) {
      if (followers > 999) {
        return (followers/1000)+'k';
      }
      else {
        return followers;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.items-main {
  padding: 9px 0 3px 10px;
}
</style>
