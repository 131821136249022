<template>
  <div class="wr_event_list_cart">
    <div class="div_img"><img :src="event.artwork_url" alt="card img" /></div>
    <div
      v-if="do_in_a_few_second"
      class="wr_detali"
      :class="{ for_width: $route.path.includes('/page/invites') }"
    >
      <div class="title_section">
        <h3>
          {{
            event.title.length > 45
              ? event.title.slice(0, 45) + "..."
              : event.title
          }}
        </h3>
        <span
          ><svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
              stroke="white"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
              stroke="white"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ event.going + event.interested }}</span
        >
      </div>
      <div class="event_detali">
        <ul>
          <li>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5035_5893)">
                <path
                  d="M12.25 5.83325C12.25 9.91658 7 13.4166 7 13.4166C7 13.4166 1.75 9.91658 1.75 5.83325C1.75 4.44086 2.30312 3.10551 3.28769 2.12094C4.27226 1.13638 5.60761 0.583252 7 0.583252C8.39239 0.583252 9.72774 1.13638 10.7123 2.12094C11.6969 3.10551 12.25 4.44086 12.25 5.83325Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 7.58325C7.9665 7.58325 8.75 6.79975 8.75 5.83325C8.75 4.86675 7.9665 4.08325 7 4.08325C6.0335 4.08325 5.25 4.86675 5.25 5.83325C5.25 6.79975 6.0335 7.58325 7 7.58325Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_5035_5893">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {{
              event.eventtype.title === "Livestreaming"
                ? "Online"
                : event.location.length > 30
                ? event.location.slice(0, 30) + "..."
                : event.location
            }}
          </li>
          <li>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0833 2.33325H2.91667C2.27233 2.33325 1.75 2.85559 1.75 3.49992V11.6666C1.75 12.3109 2.27233 12.8333 2.91667 12.8333H11.0833C11.7277 12.8333 12.25 12.3109 12.25 11.6666V3.49992C12.25 2.85559 11.7277 2.33325 11.0833 2.33325Z"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33594 1.16675V3.50008"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.66406 1.16675V3.50008"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.75 5.83325H12.25"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ monthDayFormat(event.started_at) }} -
            {{ monthDayFormat(event.end_at) }}
          </li>
          <li>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5035_5903)">
                <path
                  d="M6.9974 12.8334C10.2191 12.8334 12.8307 10.2217 12.8307 7.00008C12.8307 3.77842 10.2191 1.16675 6.9974 1.16675C3.77573 1.16675 1.16406 3.77842 1.16406 7.00008C1.16406 10.2217 3.77573 12.8334 6.9974 12.8334Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 3.5V7L9.33333 8.16667"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            {{ event.start_time }} to
            {{ event.end_time }}
          </li>
          <li>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6693 12.25V11.0833C11.6693 10.4645 11.4234 9.871 10.9859 9.43342C10.5483 8.99583 9.95477 8.75 9.33594 8.75H4.66927C4.05043 8.75 3.45694 8.99583 3.01935 9.43342C2.58177 9.871 2.33594 10.4645 2.33594 11.0833V12.25"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.9974 6.41667C8.28606 6.41667 9.33073 5.372 9.33073 4.08333C9.33073 2.79467 8.28606 1.75 6.9974 1.75C5.70873 1.75 4.66406 2.79467 4.66406 4.08333C4.66406 5.372 5.70873 6.41667 6.9974 6.41667Z"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Minimum Age: 18
          </li>
        </ul>
        <div v-if="!$route.path.includes('/page/my_Tickets')" class="options">
          <div class="top">
            <div class="buttons">
              <button
                class="int"
                :class="{ active_btn: event.user_interest === 'interested' }"
                @click="
                  interestedGoing({
                    event_id: event.id,
                    interest: 'interested',
                  })
                "
              >
                Interested
              </button>
              <button
                class="going"
                :class="{ active_btn: event.user_interest === 'going' }"
                @click="
                  interestedGoing({ event_id: event.id, interest: 'going' })
                "
              >
                Going
              </button>
            </div>
            <div class="settings">
              <button class="share">
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 11.619C1 11.619 2.26951 5.80698 9.88122 5.62087C9.99792 4.08255 9.99792 2.53831 9.88122 1L17 7.34856L9.88122 14V9.12244C9.88122 9.12244 5.70755 7.94414 1 11.619Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button class="setting">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="buy-ticket">
            <router-link
              :to="{
                name: 'buy.Ticket',
                params: { id: event.id },
              }"
            >
              <button>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 11.3759L1.24704 11.623L3.11533 13.4912L3.36238 13.7383L3.60944 13.4912C4.22635 12.8744 5.21598 12.8744 5.83285 13.4912C6.44976 14.1082 6.44976 15.0978 5.83285 15.7147L5.58579 15.9617L5.83285 16.2088L7.70114 18.0771L7.94818 18.3241L8.19523 18.0771L18.0771 8.19522L18.3241 7.94818L18.0771 7.70113L16.2088 5.83284L15.9618 5.58579L15.7147 5.83284C15.0978 6.44973 14.1082 6.44975 13.4913 5.83284C12.8744 5.21595 12.8744 4.22632 13.4913 3.60942L13.7383 3.36238L13.4913 3.11533L11.623 1.24705L11.3759 1L11.1289 1.24705L1.24704 11.1289L1 11.3759ZM1.98819 11.3759L8.28786 5.07626L9.02899 5.8174L9.5231 5.3233L8.78195 4.58217L11.3759 1.98819L12.7733 3.38554C12.1258 4.27258 12.1974 5.52718 12.9972 6.32693C13.7969 7.12666 15.0477 7.19432 15.9347 6.54696L17.3359 7.94818L14.7419 10.5422L14.0008 9.80103L13.5067 10.2951L14.2479 11.0362L7.94817 17.3359L6.54696 15.9347C7.19432 15.0477 7.12668 13.7969 6.32694 12.9972C5.52718 12.1974 4.27257 12.1258 3.38554 12.7733L1.98819 11.3759ZM10.0326 6.82102L10.5113 7.29967L11.0054 6.80558L10.5267 6.32693L10.0326 6.82102ZM12.0244 8.81283L12.5031 9.2915L12.9972 8.79739L12.5185 8.31874L12.0244 8.81283Z"
                    fill="#D1433A"
                    stroke="#D1433A"
                    stroke-width="0.4"
                  />
                </svg>
                <p class="mb-0">Buy Tickets</p>
              </button>
            </router-link>
          </div>
        </div>
        <div v-if="$route.path.includes('/page/my_Tickets')" class="wr_btns">
          <button
            @click="showTicket({ ticket_id: ticket_id, order_id: order_id })"
            :class="{ remaining: event.eventtype.title === 'Livestreaming' }"
            class="show_ticket"
          >
            {{ goSingleBtnContent }}
          </button>
          <button
            @click="moreOrPrint({ ticket_id: ticket_id, order_id: order_id })"
            :class="{ more: event.eventtype.title === 'Livestreaming' }"
            class="print"
          >
            {{ event.eventtype.title === "Livestreaming" ? "MORE" : "PRINT" }}
          </button>
          <div @click.stop="openMenuModal = !openMenuModal" class="menu">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.9987 8.66683C8.36689 8.66683 8.66536 8.36835 8.66536 8.00016C8.66536 7.63197 8.36689 7.3335 7.9987 7.3335C7.63051 7.3335 7.33203 7.63197 7.33203 8.00016C7.33203 8.36835 7.63051 8.66683 7.9987 8.66683Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.9987 3.99984C8.36689 3.99984 8.66536 3.70136 8.66536 3.33317C8.66536 2.96498 8.36689 2.6665 7.9987 2.6665C7.63051 2.6665 7.33203 2.96498 7.33203 3.33317C7.33203 3.70136 7.63051 3.99984 7.9987 3.99984Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div v-if="openMenuModal" class="menu_modal">
              <span><span></span></span>
              <ul>
                <li @click="openModals">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.66536 2.6665H13.332C14.0654 2.6665 14.6654 3.2665 14.6654 3.99984V11.9998C14.6654 12.7332 14.0654 13.3332 13.332 13.3332H2.66536C1.93203 13.3332 1.33203 12.7332 1.33203 11.9998V3.99984C1.33203 3.2665 1.93203 2.6665 2.66536 2.6665Z"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6654 4L7.9987 8.66667L1.33203 4"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Send Ticket via E-mail
                </li>
                <li @click="openModals">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_151896)">
                      <path
                        d="M6.66797 8.66648C6.95427 9.04923 7.31954 9.36594 7.739 9.59511C8.15846 9.82428 8.62231 9.96056 9.09907 9.9947C9.57583 10.0288 10.0544 9.96006 10.5022 9.793C10.95 9.62594 11.3567 9.36453 11.6946 9.02648L13.6946 7.02648C14.3018 6.39781 14.6378 5.5558 14.6302 4.68181C14.6226 3.80782 14.2721 2.97178 13.654 2.35375C13.036 1.73573 12.2 1.38516 11.326 1.37757C10.452 1.36997 9.60998 1.70595 8.9813 2.31315L7.83464 3.45315"
                        stroke="#071526"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.33347 7.33332C9.04716 6.95057 8.68189 6.63387 8.26243 6.40469C7.84297 6.17552 7.37913 6.03924 6.90237 6.0051C6.4256 5.97095 5.94707 6.03974 5.49924 6.2068C5.0514 6.37386 4.64472 6.63527 4.3068 6.97332L2.3068 8.97332C1.69961 9.60199 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.028 2.3474 13.646C2.96543 14.2641 3.80147 14.6146 4.67546 14.6222C5.54945 14.6298 6.39146 14.2938 7.02013 13.6867L8.16013 12.5467"
                        stroke="#071526"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_151896">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Send QR via SMS
                </li>
                <li @click="openModals">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.33464 1.3335H4.0013C3.64768 1.3335 3.30854 1.47397 3.05849 1.72402C2.80844 1.97407 2.66797 2.31321 2.66797 2.66683V13.3335C2.66797 13.6871 2.80844 14.0263 3.05849 14.2763C3.30854 14.5264 3.64768 14.6668 4.0013 14.6668H12.0013C12.3549 14.6668 12.6941 14.5264 12.9441 14.2763C13.1942 14.0263 13.3346 13.6871 13.3346 13.3335V5.3335L9.33464 1.3335Z"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33203 1.3335V5.3335H13.332"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6654 8.6665H5.33203"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6654 11.3335H5.33203"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.66536 6H5.9987H5.33203"
                      stroke="#071526"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Open Full Contract
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.path.includes('/page/invites')" class="wr_invite_by">
      <h5>Invited by</h5>
      <div class="invite_by">
        <div class="img_div">
          <img
            :src="event.invited_by.user.artwork_url"
            alt="user images"
          /><span
            :class="{ online_Ofline: userOnlineOrOffline.users[0].online }"
          ></span>
        </div>
        <div>
          <h6>{{ event.invited_by.user.full_name }}</h6>
          <span>{{ dateFormatFromNow(event.updated_at) }}</span>
        </div>
      </div>
      <button @click="openCloseRemoveModal('open')">Decline Invitation</button>
    </div>
    <div v-if="!do_in_a_few_second" class="wr_do_in_a_few_second">
      <div class="icon_div">
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M39.7332 39.7311C47.8692 31.5952 47.8692 18.4043 39.7332 10.2684C31.5973 2.13244 18.4064 2.13244 10.2705 10.2684C2.13453 18.4043 2.13453 31.5952 10.2705 39.7311C18.4064 47.8671 31.5973 47.8671 39.7332 39.7311Z"
            stroke="#D1433A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M30.8945 30.8923L30.8945 19.1072L19.1094 19.1072"
            stroke="#D1433A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.1094 30.8914L30.8945 19.1062"
            stroke="#D1433A"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="wr_content">
        <h5>Event was moved to Interested</h5>
        <span>You can cancel it in {{ do_10_second }}s..</span>
        <button @click.stop="cansel_change_favorites_type">Cancel</button>
      </div>
    </div>
    <RemoveModal
      v-if="showRemoveModals === 'open'"
      :title="'Decline Invitation'"
      :description="'this Invite'"
      :id="event.invited_by.id"
      @openCloseRemoveModal="openCloseRemoveModal"
      @delete_this="decline_This_Invites"
    />
    <SendUsersModal
      v-if="sendEmailModal"
      @openCloseSendEmailOrPhoneModals="openModals"
      :single_cart="dataTicket"
      :modal_title_and_send_type="modal_title_and_send_type"
    />
  </div>
</template>

<script>
import dateFormats from "@/mixins/dateFormats.js";
import getStream from "@/mixins/getStream.js";
import RemoveModal from "@/components/Common/Elements/RemoveModal.vue";
import SendUsersModal from "@/components/Events/components/Detail/buyTickets/SendUsersModal.vue";

import { mapActions } from "vuex";
export default {
  components: {
    RemoveModal,
    SendUsersModal,
  },
  mixins: [dateFormats, getStream],
  props: ["event", "ticket_id", "order_id", "name"],
  data() {
    return {
      dataTicket: { event: this.event, name: this.name, id: this.ticket_id },
      sendEmailModal: false,
      goSingleBtnContent: "",
      userOnlineOrOffline: { users: [{ online: false }] },
      openMenuModal: false,
      showRemoveModals: "close",
      do_in_a_few_second: true,
      do_10_second: 10,
      timer_id_for_interval: "",
      timer_id_for_timeout: "",
      modal_title_and_send_type: { title: "Send Ticket via E-mail" },
    };
  },
  methods: {
    ...mapActions([
      "InterestedGoing",
      "declineInvite",
      "myInvitesEvents",
      "getSingleTicket",
    ]),
    date(date) {
      return moment(date).format("MMM D");
    },
    openCloseSendEmailModals() {
      this.sendEmailModal = !this.sendEmailModal;
    },
    go_Single_Btn_Content(payload) {
      if (payload.eventtype.title === "Livestreaming") {
        if (
          new Date(payload.started_at).getTime() <
            new Date(payload.end_at).getTime() &&
          new Date(payload.end_at).getTime() > new Date().getTime()
        ) {
          this.goSingleBtnContent = "STARTED";
        } else if (new Date(payload.end_at).getTime() < new Date().getTime()) {
          this.goSingleBtnContent = "PAST";
        } else {
          this.goSingleBtnContent =
            this.dateFormatFromNow(payload.started_at).slice(2) + " remaining";
        }
      } else {
        this.goSingleBtnContent = "TICKET INFO";
      }
    },
    async showTicket(params) {
      if (this.event.eventtype.title != "Livestreaming") {
        await this.$store.dispatch("loading", true);
        await this.getSingleTicket({
          order_id: params.order_id,
          ticket_id: params.ticket_id,
        });
        await this.$store.dispatch("loading", false);
        this.$router.push({
          name: "mySingle.Ticket",
          params: {
            order_id: params.order_id,
            ticket_id: params.ticket_id,
          },
        });
      }
    },
    async moreOrPrint(params) {
      if (this.event.eventtype.title === "Livestreaming") {
        await this.$store.dispatch("loading", true);
        await this.getSingleTicket({
          order_id: params.order_id,
          ticket_id: params.ticket_id,
        });
        await this.$store.dispatch("loading", false);
        this.$router.push({
          name: "mySingle.Ticket",
          params: {
            order_id: params.order_id,
            ticket_id: params.ticket_id,
          },
        });
      }
    },
    async interestedGoing(payload) {
      if (this.$route.path.includes("/page/favorites")) {
        this.do_in_a_few_second = !this.do_in_a_few_second;
        this.timer_id_for_interval = setInterval(() => {
          this.do_10_second -= 1;
        }, 1000);
        this.timer_id_for_timeout = setTimeout(() => {
          clearInterval(this.timer_id_for_interval);
          this.changeMyFavoritesData(payload);
        }, 10000);
      } else {
        await this.$store.dispatch("loading", true);
        await this.InterestedGoing(payload);
        if (this.$route.path.includes("/page/invites")) {
          await this.myInvitesEvents(5);
        }
        await this.$store.dispatch("loading", false);
      }
    },
    async changeMyFavoritesData(payload) {
      await this.$store.dispatch("loading", true);
      await this.InterestedGoing(payload);
      await this.$store.dispatch("loading", false);
      this.$emit("getFavoritesData");
    },
    cansel_change_favorites_type() {
      clearInterval(this.timer_id_for_interval);
      clearTimeout(this.timer_id_for_timeout);
      this.do_10_second = 10;
      this.do_in_a_few_second = !this.do_in_a_few_second;
    },
    async decline_This_Invites(id) {
      await this.$store.dispatch("loading", true);
      await this.declineInvite(id);
      await this.myInvitesEvents(5);
      await this.$store.dispatch("loading", false);
      this.openCloseRemoveModal("close");
    },
    openCloseRemoveModal(payload) {
      this.showRemoveModals = payload;
    },
    openModals() {
      this.sendEmailModal = !this.sendEmailModal;
    },
  },
  computed: {
    getClient() {
      return this.$store.getters.getGetStreamChatUser;
    },
  },
  watch: {
    async getClient() {
      await this.checkOnlineUser(
        this.event.invited_by ? this.event.invited_by.user.id : ""
      ).then((res) => (this.userOnlineOrOffline = res));
    },
  },
  async mounted() {
    if (this.$store.getters.getGetStreamChatUser.queryUsers) {
      await this.checkOnlineUser(
        this.event.invited_by ? this.event.invited_by.user.id : ""
      ).then((res) => (this.userOnlineOrOffline = res));
    }
  },
  created() {
    this.go_Single_Btn_Content(this.event);
  },
  destroyed() {
    clearInterval(this.timer_id_for_interval);
    clearTimeout(this.timer_id_for_timeout);
  },
};
</script>

<style lang="scss" scoped>
.wr_event_list_cart {
  margin-bottom: 10px;
  background: #fff;
  display: flex;
  width: 100%;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  height: 170px;
  .div_img {
    width: 170px;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px 0px 0px 4px;
    }
  }
  .for_width {
    width: calc(100% - 440px) !important;
  }
  .wr_detali {
    padding: 14px 20px 17px 20px;
    width: calc(100% - 170px);
    .title_section {
      font-family: HelveticaNeueMedium;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 20px;
        border-radius: 4px;
        background: #ae4ea9;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        svg {
          margin-right: 5px;
        }
      }
    }
    .event_detali {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          letter-spacing: 0.7px;
          line-height: 16px;
          font-family: HelveticaNeueNormal;
          margin-top: 13px;
          display: flex;
          align-items: center;
          color: #47505b;
          font-weight: 400;
          font-size: 13px;
          svg {
            margin-right: 12px;
          }
        }
      }
      .options {
        margin-top: 0;
        .top {
          display: flex;
          justify-content: space-between;
          .buttons {
            display: flex;
            button {
              border: 1px solid #D1433A;
              width: 76px;
              height: 30px;
              max-width: 100%;
              background: #fff;
              color: #D1433A;
              font-size: 11px;
              line-height: 13px;
              font-family: HelveticaNeueBold;
              &:hover {
                background-color: #D1433A1A;
              }
            }
            .int {
              border-radius: 4px 0 0 4px;
            }
            .going {
              border-radius: 0 4px 4px 0;
            }
          }
          .settings {
            display: flex;
            align-items: center;
            margin-left: 10px;
            button {
              background: unset;
              border: 1.5px solid #D1433A;
              border-radius: 4px;
              width: 40px;
              height: 30px;
              &:hover {
                background-color: #D1433A1A;
              }
              svg {
                margin: 0 auto;
              }
            }
            .setting {
              margin-left: 10px;
            }
          }
        }
        .buy-ticket {
          margin-top: 10px;
          &:hover {
            background-color: #D1433A1A;
          }
          button {
            border-radius: 4px;
            border: 1px solid #D1433A;
            width: 250px;
            height: 30px;
            background: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              color: #D1433A;
              font-size: 13px;
              line-height: 16px;
              font-family: HelveticaNeueBold;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .active_btn {
    background: #D1433A !important;
    color: #ffffff !important;
    &:hover {
      background: #DA6961 !important;
    }
  }
  .wr_do_in_a_few_second {
    width: calc(100% - 170px);
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    .icon_div {
      margin-right: 125px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(213, 232, 252, 0.6);
    }
    .wr_content {
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h5 {
        width: 115px;
        font-family: HelveticaNeueMedium;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #071526;
      }
      span {
        width: 85px;
        font-family: HelveticaNeueNormal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: #47505b;
        letter-spacing: 0.7px;
        margin: 0px 0 27px;
      }
      button {
        width: 100%;
        background: #e6eaf0;
        border-radius: 4px;
        color: #47505b;
        font-weight: 500;
        font-size: 11px;
        font-family: HelveticaNeueMedium;
        height: 30px;
        border: none;
        outline: none;
        &:hover {
          background: #D1433A1A;
        }
      }
    }
  }
  .wr_invite_by {
    padding: 17px 20px 20px;
    width: 270px;
    background: #f8f8f8;
    border-left: 1px solid #e6eaf0;
    border-radius: 0px 4px 4px 0px;
    h5 {
      font-family: HelveticaNeueBold;
      color: #47505b;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
    }
    .invite_by {
      padding: 10px;
      border: 1px solid #e6eaf0;
      border-radius: 2px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .img_div {
        margin-right: 20px;
        position: relative;
        background: #c3c3c3;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
        span {
          position: absolute;
          border: 2px solid #ffffff;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background: #999999;
          bottom: 0;
          right: 5px;
        }
        .online_Ofline {
          background: #1cec9e;
        }
      }
      h6 {
        font-family: HelveticaNeueBold;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #071526;
      }
      span {
        font-family: HelveticaNeueMedium;
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        text-align: center;
        color: #8b949f;
      }
    }
    button {
      width: 100%;
      background: #e6eaf0;
      border-radius: 4px;
      color: #47505b;
      font-weight: 500;
      font-size: 11px;
      font-family: HelveticaNeueMedium;
      height: 30px;
      border: none;
      outline: none;
      &:hover {
        background: #D1433A1A;
      }
    }
  }
  .wr_btns {
    display: flex;
    height: 30px;
    button {
      background-color: #ffffff;
      border: 1.5px solid #D1433A;
      border-radius: 2px;
      color: #D1433A;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      font-family: HelveticaNeueBold;
      padding: 0 23px;
    }
    .print {
      margin: 0 10px;
      &:hover {
        background-color: #D1433A1A;
      }
    }
    .show_ticket {
      height: 100%;
      background-color: #D1433A;
      color: #fff;
      &:hover {
        background-color: #DA6961;
      }
    }
    .remaining {
      background-color: #e6eaf0;
      color: #999999;
      border: 1.5px solid #e6eaf0;
      cursor: unset;
      &:hover {
        background-color: #e6eaf0;
      }
    }
    .more {
      background-color: #D1433A;
      color: #fff;
      &:hover {
        background-color: #DA6961;
      }
    }
  }
}
</style>