<template>
  <div v-if="count > 10" class="forum-pagination m-t-20">
    <div class="pagination-info">
      Displaying ({{ (this.seeCurrentPage - 1) * 10 + 1 }} -
      {{ this.seeCurrentPage * 10 > count ? count : this.seeCurrentPage * 10 }}
      of {{ count }})
    </div>
    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      seeCurrentPage: 1,
    };
  },
  props: {
    count: Number,
    actionName: String,
    id: String,
    keyId: String,
  },
  watch: {
    async currentPage() {
      await this.$store.dispatch(`${this.actionName}`, this.payloadAction);
      this.seeCurrentPage = this.currentPage;
    },
  },
  computed: {
    payloadAction() {
      return {
        [this.keyId]: this.id,
        offset: (this.currentPage - 1) * 10,
      };
    },
  },
};
</script>

<style lang="scss">
.b-pagination {
  .page-item {
    margin: 0 !important;
    border: 1px solid #e6eaf0 !important;
  }
  .active {
    button {
      background: #D1433A !important;
      color: white !important;
      // &:hover {
      //   background: #DA6961 !important;
      // }
    }
  }
  .page-link:focus {
    box-shadow: unset !important;
  }
  .page-link {
    background-color: white !important;
    color: #8b949f !important;
    border-radius: 0 !important;
  }
  .page-item:nth-child(1),
  .page-item:nth-last-child(1) {
    display: none !important;
  }
  .page-item:nth-child(2),
  .page-item:nth-last-child(2) {
    position: relative;
    width: 100px;
    span {
      width: 100%;
      color: #D1433A !important;
    }
    button {
      width: 100%;
      color: #D1433A !important;
    }
  }
  .page-item:nth-child(2) {
    span {
      padding-left: 28px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.30078 9.83301L1.00078 5.60201L5.30078 1.00001' stroke='%238B949F' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 6px;
        height: 11px;
        left: 13px;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    button {
      padding-left: 28px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.30078 9.83301L1.00078 5.60201L5.30078 1.00001' stroke='%23416CFF' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 6px;
        height: 11px;
        left: 13px;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .page-item:nth-last-child(2) {
    span {
      padding-right: 28px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9.83301L5.3 5.60201L0.999999 1.00001' stroke='%238B949F' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 6px;
        height: 11px;
        right: 13px;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    button {
      padding-right: 28px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9.83301L5.3 5.60201L0.999999 1.00001' stroke='%23416CFF' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 6px;
        height: 11px;
        right: 13px;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .page-item.disabled {
    span {
      color: #8b949f !important;
    }
  }
}
.forum-pagination {
  .overflow-auto {
    overflow: unset !important;
  }
  .pagination {
    border-radius: 3px !important;
    overflow: hidden;
    margin: 0 !important;
    .page-item {
      &:hover {
        .page-link {
          color: #47505B!important;
          background-color: #D1D9E3!important;
        }
      }
      .page-link {
        font-size: 13px;
      }
    }
  }
}
</style>
