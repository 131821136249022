<template>
  <div :class="['dropdown-menu absolute-position more-dropdown',(showOption)? 'show':'']" aria-labelledby="moreDropdown"
       :style="{'top': ((optionHeight)+'px'), 'left': ( (optionWidth) + 'px')}">

    <a class="dropdown-item item-selling js-rate d-flex flex-row align-items-end" href="#">
      <!-- <div class="svg-icon">


         <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path class="c-line-1"  d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="#071526" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
         <path class="c-line-1"  d="M9.97607 3.76074V5.03974C9.90372 5.50602 9.65349 5.92611 9.27794 6.21178C8.90239 6.49745 8.43074 6.62647 7.96207 6.57174C7.4934 6.62647 7.02176 6.49745 6.64621 6.21178C6.27066 5.92611 6.02043 5.50602 5.94807 5.03974V3.76074" stroke="#071526" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>

      </div> -->
      <span>Buy</span>
      <div class="more-price">$2.66</div>
    </a>
    <a class="dropdown-item item-selling js-save-wishlist" href="#">
      <span>Add to Queue</span>
    </a>
    <a class="dropdown-item item-selling js-save-wishlist" href="#">
      <!-- <div class="svg-icon">
         <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-save-wishlist-1-5">
            <path class="c-line-1" d="M1.72541 12.8707L5.5 8.49576L9.27459 12.8707C9.33584 12.9344 9.4155 12.9772 9.50233 12.9931C9.58917 13.009 9.6788 12.9972 9.7586 12.9594C9.8384 12.9216 9.90434 12.8597 9.94714 12.7824C9.98993 12.705 10.0074 12.6162 9.99713 12.5284V1.68462C10.0092 1.51544 9.95385 1.34836 9.84313 1.22002C9.73241 1.09168 9.57539 1.01255 9.4065 1H1.5935C1.42461 1.01255 1.26759 1.09168 1.15687 1.22002C1.04615 1.34836 0.990767 1.51544 1.00287 1.68462V12.5284C0.992586 12.6162 1.01007 12.705 1.05286 12.7824C1.09566 12.8597 1.1616 12.9216 1.2414 12.9594C1.3212 12.9972 1.41083 13.009 1.49767 12.9931C1.5845 12.9772 1.66416 12.9344 1.72541 12.8707Z" stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
         </svg>
      </div> -->
      <span>Favorites</span>
    </a>
    <div class="dropdown-item js-rate  with-submenu" @mouseenter="showSubMenu = 'block'"
         @mouseleave="showSubMenu = 'none'">
      <!-- <div class="svg-icon">
         <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-star-with-cicre-angle-1-5">
            <path class="c-fill-1 c-line-1" d="M7.00607 10.1737C7.00249 10.171 6.99752 10.171 6.99395 10.1737L3.32587 12.981C3.31814 12.9869 3.30737 12.9794 3.31024 12.9701L4.71327 8.4274C4.7145 8.4234 4.71311 8.41905 4.70979 8.41651L1.02346 5.5972C1.01587 5.59139 1.01997 5.57926 1.02954 5.57926H5.57721C5.5816 5.57926 5.58547 5.5764 5.58677 5.57221L6.98939 1.03083C6.99229 1.02144 7.00558 1.02143 7.00849 1.03081L8.42084 5.57223C8.42214 5.57641 8.42601 5.57926 8.43039 5.57926H12.9705C12.98 5.57926 12.9841 5.59139 12.9766 5.5972L9.30653 8.40594C9.30321 8.40849 9.30182 8.41283 9.30305 8.41683L10.7061 12.9597C10.709 12.969 10.6983 12.9765 10.6905 12.9706L7.00607 10.1737Z" fill="#071526" stroke="#071526" stroke-width="1.5"></path>
         </svg>
      </div> -->
      <span>Rate</span>
      <div class="more-rate margin-right-this">5.0</div>
      <div class="svg-icon arrow-icon d-flex ml-auto">
        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="c-line-1" d="M1 1L5.3 5.231L1 9.833" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </svg>
      </div>
      <div class="submenu" :style="{display: showSubMenu}" @mouseover="showSubMenu = 'block'"
           @mouseout="showSubMenu = 'none'">
        <div class="submenu-item">
          <div class="text">Click to Rate</div>
          <div class="submenu-stars with-js" @click="$event.target.classList.toggle('selected')">
            <div class="star svg-icon" data-value="1">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="2">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="3">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="4">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="5">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="submenu-item">
          <div class="text">Average Rating 321</div>
          <div class="submenu-stars">
            <div class="star svg-icon active">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon active">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon active">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
            <div class="star svg-icon">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="c-line-1 c-fill-1">
                <path class="c-line-1 c-fill-1"
                      d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                      fill="white" stroke="white" stroke-width="1.3"></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="submenu-item rate">9 Ratings</div>
        <div class="submenu-item sub-see-all d-flex ml-auto">
          See all
          <div class="svg-icon">
            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1L5.3 5.231L1 9.833" stroke="#47505B" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <a class="dropdown-item js-repost-album" href="#">
      <!-- <div class="svg-icon">
         <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-repost-1-5">
            <path class="c-line-1" d="M1 8L1 2.99977L13 2.99977L9.57045 1" stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path class="c-line-1" d="M13 7L13 11.247L1 11.247L5.12612 13" stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
         </svg>
      </div> -->
      <span>Repost 321</span>
    </a>
    <a class="dropdown-item js-share-album" href="#">
      <!-- <div class="svg-icon">
         <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-share-1-5">
            <path class="c-line-1" d="M2 11.7561C2.35568 10.0409 3.21256 8.52102 4.42532 7.45413C5.63808 6.38724 7.13208 5.83902 8.65398 5.90244V2L14.6341 7.7561L8.65398 14V10C8.65398 10 5.53756 8.34146 2 11.7561Z" stroke="#071526" stroke-width="1.5" stroke-linecap="round"></path>
         </svg>
      </div> -->
      <span>Share</span>
    </a>
  </div>
</template>
<script type="application/javascript">

export default {
  data() {
    return {
      showSubMenu: 'none',

    }
  },
  props: {
    optionWidth: Number,
    optionHeight: Number,
    showOption: Boolean
  },
  components: {},
  computed: {},

  methods: {},

}
</script>
