<template>
  <div class="footer-container">
    <div class="leave-btn cursor-pointer" @click="leaveRoom">
            <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.25 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V2.91667C1.75 2.60725 1.87292 2.3105 2.09171 2.09171C2.3105 1.87292 2.60725 1.75 2.91667 1.75H5.25"
                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.33325 9.91667L12.2499 7L9.33325 4.08333" stroke="#47505B" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.25 7H5.25" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                </svg>
            </span>
      <p>LEAVE ROOM</p>
    </div>
    <div class="chat-btn">
      <p>Ready for Chat</p>
      <label class="switch">
        <input type="checkbox" />
        <div class="slider round"></div>
      </label>
    </div>
    <div class="video-btn">
      <p>With Video</p>
      <label class="switch">
        <input type="checkbox" />
        <div class="slider round"></div>
      </label>
    </div>
    <div class="join-meet-btn cursor-pointer">
            <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1196_12651)">
                        <path d="M13.4167 4.08333L9.33337 7L13.4167 9.91667V4.08333Z" stroke="white" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M8.16671 2.91667H1.75004C1.10571 2.91667 0.583374 3.439 0.583374 4.08333V9.91667C0.583374 10.561 1.10571 11.0833 1.75004 11.0833H8.16671C8.81104 11.0833 9.33337 10.561 9.33337 9.91667V4.08333C9.33337 3.439 8.81104 2.91667 8.16671 2.91667Z"
                          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1196_12651">
                            <rect width="14" height="14" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </span>
      <p>JOIN AS MEMBER</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  computed: {
    getActiveChannel() {
      return this.$store.getters.getActiveGroup;
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id
    }
  },
  methods: {
    leaveRoom() {
      if (this.getActiveChannel && this.getActiveChannel.created_by && this.getActiveChannel.created_by.id === this.userId.toString()) {
        this.setStreamSidAndStreamingStatusOfTwilioRoomInGetStreamRoom(this.getActiveChannel, '', false)
        this.endStream()
      } else {
        this.leaveStream()
      }
    }
  }
};
</script>
<style scoped lang="scss">
.footer-container {
  width: 1170px;
  height: 80px;
  background: #FFFFFF;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  margin-top: 10px;
  padding: 20px 30px 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .leave-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E6EAF0;
    border-radius: 4px;
    width: 260px;
    height: 40px;

    p {
      margin-bottom: 0px;
      margin-left: 7px;
    }
  }

  .chat-btn {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    align-items: center;
    background: #E6EAF0;
    border-radius: 4px;
    width: 260px;
    height: 40px;

    p {
      margin-bottom: 0px;
      margin-left: 7px;
    }
  }

  .video-btn {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    align-items: center;
    background: #E6EAF0;
    border-radius: 4px;
    width: 260px;
    height: 40px;

    p {
      margin-bottom: 0px;
      margin-left: 7px;
    }
  }

  .join-meet-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #47505B;
    border-radius: 4px;
    width: 260px;
    height: 40px;

    p {
      margin-bottom: 0px;
      margin-left: 7px;
      color: #fff;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-bottom: 0px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C7CFED;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background: #D1433A;
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
    background: #47505B;
  }
}
</style>
