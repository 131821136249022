<template>
  <div class="width770" style="margin-left: 15px;margin-right: -15px">
    <div class="row width770">
      <div class="col p-0">
        <div
          class="page-filter sm-view d-flex d-flex flex-row align-items-center"
        >
          <div class="back-link">
            <a href="#" class="d-flex flex-row align-items-center" @click="changeTrendingView()"
              ><div data-v-d29fc72a="" class="svg-icon">
                <svg
                  data-v-d29fc72a=""
                  width="7"
                  height="14"
                  viewBox="0 0 7 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="c-line-1"
                  ></path>
                </svg>
              </div>
              {{lastUrl}}
            </a>
          </div>
          <div class="filter-title">TRENDING HASHTAGS</div>
        </div>
      </div>
    </div>
    <div class="row flex-column width770">
      <div class="col hashtags-block" v-for="(trend, index) in listData" :key="index">
        <div class="hashtag-text">
          <div class="text-block" @click="changeType(trend.tag)">
            <div class="hashtag-title cursor-pointer">
              <a>#{{ trend.tag.replace(/#/g, '') }}</a>
            </div>
            <div class="hashtag-seeall">
              <a href="javascript:void(0)" class="cursor-pointer">SEE ALL {{
                  trend.total > 1000
                    ? trend.total + "K Posts"
                    : trend.total > 1
                      ? trend.total + " Posts"
                      : trend.total + " Post"
                }}</a>
            </div>
          </div>
        </div>
        <div class="img-block">
          <div class="img-wrapper"  v-for="(image, indexKey) in trend.images" :key="indexKey">
            <img :src="image.artwork_url" alt="" />
          </div>
        </div>
      </div>
      <div class="show_more_btn p-0" v-if="total_trends > listData.length">
        <div class="show-more musicbar_more mt_10">
          <a class="cursor-pointer" @click="showMore"
            ><span class="show_more_bg"
              ><svg
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.24219 0.689941V7.01994"
                  stroke="#071526"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M1 3.85498H7.48702"
                  stroke="#071526"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              SHOW {{ remainingHashtags }} MORE HASHTAGS
            </span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data () {
    return {
      dataLoaded: false,
      loading: false,
      trendingData: [],
      trending_offset : 0,
      trending_limit : 10
    }
  },
  props: {
    // listData: {
    //   type: Array,
    //   default: () => [],
    // },
    lastUrl: {
      type: String,
      default:"News Feed",
    },
    // total_trends: {
    //   type: Number,
    // }
  },


  computed: {
    ...mapGetters({
      listData: "getTrendingList",
      total_trends: "getHashTagCount"
    }),
    remainingHashtags() {
      return Math.max(this.total_trends - this.listData.length, 0);
    }
  },

  methods:{
    showMore() {
     this.$emit('showMoreTrending');
    },
    changeTrendingView()
    {
      this.$store.commit("SET_TRENDING_VIEW_CHECK", 0);
    },
    changeType(type) {
      this.filterType = type;
      this.$store.commit('SET_POST_TYPE', '')
      this.$store.commit('SET_TAG_TYPE', this.filterType.split('#').join('%23'));
      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.dispatch('getPost', false)
      this.$store.commit("SET_TRENDING_VIEW_CHECK", 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrapper img{
  height: 60px !important;
  width: 60px !important;
}
</style>