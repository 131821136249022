<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade login-type show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup add-credit-card-heading">
            <div class="work-experience-heading">
              <div class="back-arrow-link" v-on:click="goBack()">
                <div class="svg-icon">
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00073 1L2.00073 6L7.00073 11" stroke="#47505B" stroke-width="1.5"></path>
                  </svg>
                </div>
              </div>
              <h1 class="add-card">Add Credit Card</h1>
              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="svg-color"></path>
                  <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="svg-color"></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">

              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
              <div v-if="success_message" class="alert alert-success" role="alert">
                {{ success_message }}
              </div>

              <div class="row">
                <div class="col-md-6 p-r">
                  <div class="w-poup-fields add-card-method-field">
                    <input type="text" placeholder="First Name" v-model="f_name">
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('f_name')">{{
                      _handleErrorMessage("f_name") }}</label>
                  </div>
                </div>
                <div class="col-md-6 p-l">
                  <div class="w-poup-fields add-card-method-field">
                    <input type="text" placeholder="Last Name" v-model="l_name">
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('l_name')">{{
                      _handleErrorMessage("l_name") }}</label>
                  </div>
                </div>
              </div>
              <div class="card-detail-fields">
                <div class="row">
                  <div class="col-md-12">
                    <div class="w-poup-fields Card-number">
                      <input type="text" placeholder="Card Number" v-model="card_number" v-mask="'#### #### #### ####'">
                      <span>
                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.1784 1.39453H2.7239C2.02099 1.39453 1.45117 1.95418 1.45117 2.64453V10.1445C1.45117 10.8349 2.02099 11.3945 2.7239 11.3945H14.1784C14.8814 11.3945 15.4512 10.8349 15.4512 10.1445V2.64453C15.4512 1.95418 14.8814 1.39453 14.1784 1.39453Z"
                            stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M1.45117 5.14453H15.4512" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        </svg>
                      </span>
                      <label class="error_msg error_msg_red" v-if="_handleErrorMessage('card_number')">{{
                        _handleErrorMessage("card_number") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 p-r">
                    <div class="w-poup-fields month-year">
                      <input type="text" placeholder="MM/YY" v-model="expiry" v-mask="'##/####'">
                      <span>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.4444 2.5H2.55556C1.69645 2.5 1 3.17157 1 4V14.5C1 15.3284 1.69645 16 2.55556 16H13.4444C14.3036 16 15 15.3284 15 14.5V4C15 3.17157 14.3036 2.5 13.4444 2.5Z"
                            stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M11.1111 1V4" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                          <path d="M4.88892 1V4" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                          <path d="M1 7H15" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        </svg>
                        <label class="error_msg error_msg_red" v-if="_handleErrorMessage('expiry')">{{
                          _handleErrorMessage("expiry") }}</label>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 p-l">
                    <div class="w-poup-fields month-year">
                      <input type="text" placeholder="CVV" v-model="cvv" v-mask="'###'">
                      <span>
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.4444 7.75H2.55556C1.69645 7.75 1 8.42157 1 9.25V14.5C1 15.3284 1.69645 16 2.55556 16H13.4444C14.3036 16 15 15.3284 15 14.5V9.25C15 8.42157 14.3036 7.75 13.4444 7.75Z"
                            stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path
                            d="M4.11108 7.75V4.75C4.11108 3.75544 4.52081 2.80161 5.25011 2.09835C5.97942 1.39509 6.96858 1 7.99997 1C9.03137 1 10.0205 1.39509 10.7498 2.09835C11.4791 2.80161 11.8889 3.75544 11.8889 4.75V7.75"
                            stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <label class="error_msg error_msg_red" v-if="_handleErrorMessage('cvv')">{{
                          _handleErrorMessage("cvv") }}</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="currently-work">
                      <ul class="unstyled centered">
                        <li>
                          <input class="styled-checkbox" v-model="remember_card" id="styled-checkbox-1" type="checkbox"
                            value="value1">
                          <label for="styled-checkbox-1">Remember Me</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row popup_button btnAlign">

                  <!-- <div class="skip_btn work-cancel"> -->
                  <button class="skip_btn work-cancel" v-on:click="closeModal()">CANCEL</button>
                  <!-- </div> -->
                  <!-- <div class="next_step_btn ad-work-exp button"> -->
                  <button class="next_step_btn ad-work-exp button" type="submit" v-on:click="submitForm">
                    ADD CREDIT CARD
                  </button>
                  <!-- </div> -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import { countries } from '../../../components/utils/countries';
import * as Helper from '../../../apis/Helper.js';
import { addCard } from '../../../apis/APIs';
import { EDIT_PROFILE_ERRORS, SUCCESS, ERROR } from '../../../store/types';
import { mask } from 'vue-the-mask'

export default {
  data() {
    return {
      f_name: 'sdf',
      l_name: 'sdf',
      card_number: '4111111111111111',
      expiry: '10/20',
      cvv: '321',
      remember_card: true,
      message: '',
      success_message: '',
      loading: false,
      errors: []

    }
  },
  mounted() {
    // this.update_basic_info_form();
  },
  directives: { mask },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    })
  },
  props: {
    payment_method: String
  },
  methods: {
    ...mapActions([
      "fetchUserData"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateCardUIConstraints"
    ]),

    closeModal: function () {
      this.$parent.closeWindow();
    },
    goBack: function () {
      this.$parent.backF();
    },
    async submitForm() {
      console.log(this);
      const { user_data } = this.user || {};
      const { user_id } = user_data || {};
      console.log(user_id, 'user_data');

      this.errors = [];

      if (!this.f_name) {
        this.errors.push({
          fieldName: "f_name",
          message: "Required"
        })
      }

      if (!this.l_name) {
        this.errors.push({
          fieldName: "l_name",
          message: "Required"
        })
      }

      let card = this.card_number.replace(/\s/g, '');
      // console.log(card);

      if (card) {
        var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
        var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
        var amexpRegEx = /^(?:3[47][0-9]{13})$/;
        var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
        var isValid = false;

        if (visaRegEx.test(card)) {
          isValid = true;
        } else if (mastercardRegEx.test(card)) {
          isValid = true;
        } else if (amexpRegEx.test(card)) {
          isValid = true;
        } else if (discovRegEx.test(card)) {
          isValid = true;
        }
        if (!isValid) {
          this.errors.push({
            fieldName: "card_number",
            message: "Invalid Card Number"
          });
        }
      } else if (!card) {
        this.errors.push({
          fieldName: "card_number",
          message: "Required"
        });
      }

      if (!this.expiry) {
        this.errors.push({
          fieldName: "expiry",
          message: "Required"
        })
      }
      if (!this.cvv) {
        this.errors.push({
          fieldName: "cvv",
          message: "Required"
        })
      }
      // console.log(this.errors, '22');

      // if(this.errors.length) return;

      try {
        var formdata = new FormData();


        formdata.append("id", user_id);
        formdata.append("function_type", 'add_card');
        formdata.append("payment_method", this.payment_method);
        if (this.f_name) formdata.append("f_name", this.f_name);
        if (this.l_name) formdata.append("l_name", this.l_name);
        if (this.card_number) formdata.append("card_number", this.card_number);
        if (this.expiry) formdata.append("expiry", this.expiry);
        if (this.cvv) formdata.append("cvv", this.cvv);
        // formdata.append("additional_info", additional_info);

        console.log(formdata, '555');
        console.log('Form data');


        this.message = "";
        this.loading = true;
        const { success, message } = await addCard(formdata);
        console.log(success, message, 'error');

        if (success) {
          this.success_message = message;
          this.f_name = '';
          this.l_name = '';
          this.card_number = '';
          this.expiry = '';
          this.cvv = '';
          setTimeout(() => {
            this.success_message = "";
            this.$parent.closeWindow();
          }, 5000);
        } else {
          this.message = message;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);
      return message;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
  }
}
</script>
