<template>
  <div class="modal fade modal-add-to-cart contact_info create-new-ticket job-invite show" id="propose-new-offer"
       tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true"
       style="padding-right: 15px; display: block;">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="item-title">
          <div class="short-border">
            Propose New Offer
          </div>
        </div>
        <div class="m-b-17">
          <div class="input-title">Rate</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100"><select
              class="filters-select selectpicker w-100" title="$25 / hr" multiple="" data-max-options="1"
              tabindex="-98">
            <option value="1">$25 / hr</option>
            <option value="2">$25 / hr</option>
            <option value="3">$25 / hr</option>
          </select>
            <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                    role="combobox" aria-owns="bs-select-32" aria-haspopup="listbox" aria-expanded="false"
                    title="$25 / hr">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">$25 / hr</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu"
                 style="max-height: 102px; overflow: hidden; min-height: 0px; position: absolute; will-change: transform; min-width: 1351px; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);"
                 x-placement="bottom-start">
              <div class="inner show" role="listbox" id="bs-select-32" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 84px; overflow-y: auto; min-height: 0px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-32-0" tabindex="0" aria-selected="false"
                         aria-setsize="3" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                      class="text">$25 / hr</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-32-1" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">$25 / hr</span></a>
                  </li>
                  <li><a role="option" class="dropdown-item" id="bs-select-32-2" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">$25 / hr</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="m-b-17">
          <div class="input-title">Weekly Limit</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100"><select
              class="filters-select selectpicker w-100" title="30 hrs / week" multiple="" data-max-options="1"
              tabindex="-98">
            <option value="1">30 hrs / week</option>
            <option value="2">30 hrs / week</option>
            <option value="3">30 hrs / week</option>
          </select>
            <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                    role="combobox" aria-owns="bs-select-33" aria-haspopup="listbox" aria-expanded="false"
                    title="30 hrs / week">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">30 hrs / week</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu"
                 style="max-height: 102px; overflow: hidden; min-height: 0px; position: absolute; will-change: transform; min-width: 1351px; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);"
                 x-placement="bottom-start">
              <div class="inner show" role="listbox" id="bs-select-33" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 84px; overflow-y: auto; min-height: 0px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-33-0" tabindex="0" aria-selected="false"
                         aria-setsize="3" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                      class="text">30 hrs / week</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-33-1" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">30 hrs / week</span></a>
                  </li>
                  <li><a role="option" class="dropdown-item" id="bs-select-33-2" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">30 hrs / week</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="input-title">Manual Time</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100 dropup"><select
              class="filters-select selectpicker w-100" title="Disallowed" multiple="" data-max-options="1"
              tabindex="-98">
            <option value="1">Disallowed</option>
            <option value="2">Allowed</option>
          </select>
            <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                    role="combobox" aria-owns="bs-select-34" aria-haspopup="listbox" aria-expanded="false"
                    title="Disallowed">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">Disallowed</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu"
                 style="max-height: 152px; overflow: hidden; min-height: 0px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -115px, 0px);"
                 x-placement="top-start">
              <div class="inner show" role="listbox" id="bs-select-34" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 134px; overflow-y: auto; min-height: 0px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-34-0" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Disallowed</span></a>
                  </li>
                  <li><a role="option" class="dropdown-item" id="bs-select-34-1" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Allowed</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="action-item">
          <div class="btn back-btn">Cancel</div>
          <div class="btn check-btn">Send Offer</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
