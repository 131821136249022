var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"friend-main-div"},_vm._l((_vm.friendRequestNotifications.slice(0, 8)),function(notification,index){return _c('div',{key:index},_vm._l((notification.activities),function(activity,index1){return _c('div',{key:index1},[(notification.verb === 'friend_request_sent' && !notification.is_read && activity.status === 'pending')?_c('div',{staticClass:"friend-new d-flex flex-row mb-2"},[_c('div',{staticClass:"cover-image"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":activity.user.artwork_url,"alt":""}})])]),_c('div',{staticClass:"content-block f-flex flex-column justify-content-between"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(activity && activity.user && activity.user.full_name ? activity.user.full_name : activity && activity.user && activity.user.name ? activity.user.name : "User Name")+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(activity.user.mutual_friends ? activity.user.mutual_friends : "No")+" mutual friends ")]),_c('div',{staticClass:"d-flex flex-row justify-content-between friends-btn"},[_c('div',{staticClass:"btn btn-accept",on:{"click":function($event){return _vm.acceptRejectFriendRequest(
                activity.request_id,
                1,
                notification.id
              )}}},[_vm._v(" Accept ")]),_c('div',{staticClass:"btn btn-delete",on:{"click":function($event){return _vm.acceptRejectFriendRequest(
                activity.request_id,
                0,
                notification.id
              )}}},[_vm._v(" Decline ")])])])]):_vm._e(),((notification.verb === 'friend_request_accepted' && activity.status === 'confirmed') || (notification.verb === 'friend_request_sent' && activity.status === 'confirmed'))?_c('div',{staticClass:"friend-new d-flex flex-row mb-2"},[_c('div',{staticClass:"cover-image"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":activity &&
              activity.user
                ? activity.user.artwork_url
                : '',"alt":""}})])]),_c('div',{staticClass:"content-block f-flex flex-column justify-content-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s((activity && notification.activities.length > 0 && activity.user) ? activity.user.name : "User Name")+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s((activity.user.mutual_friends) ? activity.user.mutual_friends : "No")+" mutual friends ")]),_vm._m(0,true)])]):_vm._e()])}),0)}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex fr-btn",staticStyle:{"width":"100%"}},[_c('button',{staticClass:"ml-auto"},[_vm._v("Friends")])])
}]

export { render, staticRenderFns }