<template>
  <div class="w-1170">
    <h1 class="EM_Pages_title">ADS</h1>
    <div class="event_manager_contener">
      <div class="w-770 wr_content">
        <article>
          <div>
            <h3>Advertising Tips</h3>
            <p>
              New experience with Advertising? No worries, we have special tips
              page for you. Check this out!
            </p>
          </div>
          <button class="blue_btn for_hover_blue_btn">ADS TIPS</button>
        </article>
        <div class="ads_titles">
          <h4>6 Event advertisements</h4>
          <ul>
            <li
              v-for="item in columeTitles"
              :key="item.id"
              :class="{ act_Cl: item.growthDecrease }"
              :style="{ width: item.width }"
            >
              <span>{{ item.title }}</span>

              <svg
                v-if="item.icon"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_572_173656)">
                  <path
                    d="M4.00033 7.33329C5.84127 7.33329 7.33366 5.84091 7.33366 3.99996C7.33366 2.15901 5.84127 0.666626 4.00033 0.666626C2.15938 0.666626 0.666992 2.15901 0.666992 3.99996C0.666992 5.84091 2.15938 7.33329 4.00033 7.33329Z"
                    stroke="#8B949F"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 5.33333V4"
                    stroke="#8B949F"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 2.66663H4.00444"
                    stroke="#8B949F"
                    stroke-width="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_572_173656">
                    <rect width="8" height="8" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <svg
                v-if="item.growthDecrease"
                width="6"
                height="4"
                viewBox="0 0 6 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 3.25L3 0.75L0.5 3.25"
                  stroke="#D1433A"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </li>
          </ul>
        </div>
        <AdsCard v-for="item in adsData" :key="item.id" :ads="item" />
      </div>
      <EMRightSidebar :adsData="adsData"/>
    </div>
  </div>
</template>

<script>
import EMRightSidebar from "../EMElement/EMRightSidebar.vue";
import AdsCard from "../EMElement/AdsCard.vue";

export default {
  components: {
    EMRightSidebar,
    AdsCard,
  },
  data() {
    return {
      columeTitles: [
        {
          id: 1,
          title: "Tittle",
          icon: false,
          growthDecrease: false,
          width: "88px",
        },
        {
          id: 2,
          title: "Status",
          icon: false,
          growthDecrease: false,
          width: "85px",
        },
        {
          id: 3,
          title: "Daily B.",
          icon: true,
          growthDecrease: false,
          width: "53px",
        },
        {
          id: 4,
          title: "Total B.",
          icon: true,
          growthDecrease: false,
          width: "53px",
        },
        {
          id: 5,
          title: "Spent",
          icon: false,
          growthDecrease: false,
          width: "30px",
        },
        {
          id: 6,
          title: "Views",
          icon: false,
          growthDecrease: false,
          width: "30px",
        },
        {
          id: 7,
          title: "Clicks",
          icon: false,
          growthDecrease: true,
          width: "43px",
        },
        {
          id: 8,
          title: "CTR",
          icon: true,
          growthDecrease: false,
          width: "36px",
        },
        {
          id: 9,
          title: "eCPC",
          icon: true,
          growthDecrease: false,
          width: "44px",
        },
      ],
      adsData: [
        {
          id: 1,
          name: "My Event Ad",
          status: "Active",
          daily: "$24",
          total: "$100",
          spent: "$32",
          view: "24k",
          quantity: "3k",
          CTR: "0,32",
          eCPC: "$0,12",
          bg:'#D1433A',
          activeChart:false,
        },
        {
          id: 2,
          name: "My Page Ad",
          status: "Paused",
          daily: "$28",
          total: "$100",
          spent: "$32",
          view: "24k",
          quantity: "2k",
          CTR: "0,32",
          eCPC: "$0,12",
          bg:'#FDB390',
          activeChart:false,
        },
        {
          id: 5,
          name: "My Post Ad",
          status: "Pending",
          daily: "$29",
          total: "$100",
          spent: "$100",
          view: "24k",
          quantity: "1k",
          CTR: "0,32",
          eCPC: "$0,12",
          bg:'#6540CE',
          activeChart:false,
        },
        {
          id: 6,
          name: "My Event Ad",
          status: "Active",
          daily: "$24",
          total: "$100",
          spent: "$32",
          view: "24k",
          quantity: "1k",
          CTR: "0,32",
          eCPC: "$0,12",
          bg:'#0CAACD',
          activeChart:false,
        },
        {
          id: 4,
          name: "My Event Ad",
          status: "Active",
          daily: "$46",
          total: "$100",
          spent: "$32",
          view: "24k",
          quantity: "1k",
          CTR: "0,32",
          eCPC: "$0,12",
          bg:'#FF0039',
          activeChart:false,
        },
        {
          id: 3,
          name: "My New Ad",
          status: "Moderate",
          daily: "$34",
          total: "$100",
          spent: "$32",
          view: "24k",
          quantity: "1k",
          CTR: "0,32",
          eCPC: "$0,12",
          bg:'#15CA86',
          activeChart:false,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wr_content {
  article {
    background: #e6eaf0;
    border-radius: 4px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #47505b;
    }
    p {
      font-family: HelveticaNeueNormal;
      letter-spacing: 0.2px;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #8b949f;
      margin: 0;
    }
    button {
      height: 40px;
    }
  }
  .ads_titles {
    margin-top: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    font-family: HelveticaNeueBold;
    font-weight: 700;
    h4 {
      font-size: 18px;
      line-height: 22px;
      color: #071526;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 60px 0 0;
      list-style-type: 0;
      margin: 0;
      font-size: 11px;
      line-height: 13px;
      color: #8b949f;
      li {
        display: flex;
        align-items: center;
        span {
          border-bottom: 1px dashed #e6eaf0;
        }
        svg {
          margin-left: 5px;
        }
      }
      .act_Cl {
        color: #D1433A;
      }
    }
  }
}
</style>