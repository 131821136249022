<template>
  <div class="w-100">
    <GroupSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path
                        d="M10.666 14V12.6667C10.666 11.9594 10.3851 11.2811 9.88497 10.781C9.38487 10.281 8.70659 10 7.99935 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                        stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.334 5.3335V9.3335" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M15.334 7.3335H11.334" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                Member Request
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="search-events-panel row">


                <div class="col-md-6 ">
                  <div class="frind_list_box mem-rqu-bottom">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper"><img :src="require(`@/assets/img/fan1.png`)"></div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name">Jane Cooper</h4>
                      <p class="friend_profile_location">
                                   <span class="location_map">
                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" clip-rule="evenodd"
                                               d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                               fill="#47505B"></path>
                                      </svg>
                                   </span>
                        Iran (Islamic Republic of)
                      </p>
                    </div>
                    <div class="member-right-icon">
                      <button class="right-check">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 1L4.99935 8.33333L1.66602 5" stroke="white" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <button class="close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="frind_list_box mem-rqu-bottom">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper"><img :src="require(`@/assets/img/fan1.png`)"></div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name">Jane Cooper</h4>
                      <p class="friend_profile_location">
                                   <span class="location_map">
                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" clip-rule="evenodd"
                                               d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                               fill="#47505B"></path>
                                      </svg>
                                   </span>
                        Iran (Islamic Republic of)
                      </p>
                    </div>
                    <div class="member-right-icon">
                      <button class="right-check">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 1L4.99935 8.33333L1.66602 5" stroke="white" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <button class="close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="frind_list_box mem-rqu-bottom">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper"><img :src="require(`@/assets/img/fan1.png`)"></div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name">Jane Cooper</h4>
                      <p class="friend_profile_location">
                                   <span class="location_map">
                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" clip-rule="evenodd"
                                               d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                               fill="#47505B"></path>
                                      </svg>
                                   </span>
                        Iran (Islamic Republic of)
                      </p>
                    </div>
                    <div class="member-right-icon">
                      <button class="right-check">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 1L4.99935 8.33333L1.66602 5" stroke="white" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <button class="close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="frind_list_box mem-rqu-bottom">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper"><img :src="require(`@/assets/img/fan1.png`)"></div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name">Jane Cooper</h4>
                      <p class="friend_profile_location">
                                   <span class="location_map">
                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" clip-rule="evenodd"
                                               d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                               fill="#47505B"></path>
                                      </svg>
                                   </span>
                        Iran (Islamic Republic of)
                      </p>
                    </div>
                    <div class="member-right-icon">
                      <button class="right-check">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 1L4.99935 8.33333L1.66602 5" stroke="white" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <button class="close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="frind_list_box mem-rqu-bottom">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper"><img :src="require(`@/assets/img/fan1.png`)"></div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name">Jane Cooper</h4>
                      <p class="friend_profile_location">
                                   <span class="location_map">
                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" clip-rule="evenodd"
                                               d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                               fill="#47505B"></path>
                                      </svg>
                                   </span>
                        Iran (Islamic Republic of)
                      </p>
                    </div>
                    <div class="member-right-icon">
                      <button class="right-check">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 1L4.99935 8.33333L1.66602 5" stroke="white" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <button class="close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="frind_list_box mem-rqu-bottom">
                    <div class="friend_profile_image">
                      <div class="friend-image-wrapper"><img :src="require(`@/assets/img/fan1.png`)"></div>
                    </div>
                    <div class="friend_profile_content">
                      <h4 class="friend_profile_name">Jane Cooper</h4>
                      <p class="friend_profile_location">
                                   <span class="location_map">
                                      <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" clip-rule="evenodd"
                                               d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                                               fill="#47505B"></path>
                                      </svg>
                                   </span>
                        Iran (Islamic Republic of)
                      </p>
                    </div>
                    <div class="member-right-icon">
                      <button class="right-check">
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.3327 1L4.99935 8.33333L1.66602 5" stroke="white" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                      <button class="close-btn">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5 3.5L3.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M3.5 3.5L10.5 10.5" stroke="#FF0039" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="show_more_btn">
                <div class=" more-m-req"><a href="javascript:;"><span class="show_more_bg"><svg width="9" height="8"
                                                                                                viewBox="0 0 9 8"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"><path
                    d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round"></path> <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                                                          stroke-linecap="round" stroke-linejoin="round"></path></svg>
                         SHOW 10 MORE USERS
                      </span></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import GroupSubHeader from '@/components/Group/Pages/GroupSubHeader'

export default {
  data() {
    return {}
  },
  components: {
    GroupSubHeader
  },
  computed: {},
  methods: {}
}
</script>
