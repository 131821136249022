<template>
  <div class="app-container main-container_full" :class="custom_css_class">
    <LeftNav v-if="currentUrl != 'book-artist' && currentUrl != 'related-album-list' && ShowLeftNav" class="left-bar-placement" v-show="currentUserIsAdmin" category="manager" :type="pageType"/>
    <router-view v-show="currentPage || currentUrl === 'page.create-new-page'|| currentUrl === 'product.create-song-album' || currentUrl === 'product.create-podcast'"></router-view>
    <div style="height: 60px;">&nbsp;</div>
  </div>
</template>
<script>
import LeftNav from "@/components/DesignGuide/Layout/LeftNav.vue";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      "form_data": "main_container_full_class_map_to_props"
    }),
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    currentUserIsAdmin(){
      return !!(this.currentPage && this.currentPage.current_user && this.currentPage.current_user==='admin')
    },
    custom_css_class() {
      const {main_container_full_classes = ""} = this.form_data;

      return main_container_full_classes
    },
    ShowLeftNav () {
      if(!this.$route.meta.showLeftNav)
      {
        return false
      }
      if(this.currentUrl === 'book-artist' || this.currentUrl === 'related-album-list'){
        return false
      }
      return true
    },
    pageType() {
      if ((this.$store.getters.getCurrentPage && this.$store.getters.getCurrentPage.type === 'artist') || (this.$store.getters.getCurrentPage && this.$store.getters.getCurrentPage.type === 'company')) {
        return 'page'
      } else if (this.$store.getters.getCurrentPage && this.$store.getters.getCurrentPage.type === 'clubroom') {
        return  'club'
      } else if (this.$store.getters.getCurrentPage && this.$store.getters.getCurrentPage.type === 'partyroom') {
        return 'party'
      } else {
        return ''
      }
    }
  },
  watch:{
    'currentPage':{
      immediate: true,
      handler(value) {
        if(!value){
          this.getPageData()
          this.getAnnouncementData()
          this.getUpcomingEventsData()
          this.getPastEventsData()
          this.getPageRooms()
        }
      }
    },
  },
  props: {
    ShowMusicFooter: Boolean,
  },
  beforeMount() {
    this.showCon();
  },
  components: {
    LeftNav,
  },
  methods: {
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
    async getPageData(){
      this.$store.commit('SET_POST_TYPE', "all")
      if(this.$route.params.id){
        await this.$store.dispatch('getPage', {page_id: this.$route.params.id, showMore: false});
      }
      else {
        if(this.currentUserIsAdmin){
          await this.$router.push('/');
        }
      }
    },
    async getAnnouncementData () {
      if (this.$route.params.id) {
        await  this.$store.dispatch('getAnnoncements', {page_id: this.$route.params.id, showMore: false})
      } else  {
        if(this.currentUserIsAdmin){
          await this.$router.push('/');
        }
      }
    },
    async getUpcomingEventsData () {
      if (this.$route.params.id) {
        let payload = {
          showMore : false,
          page_id: this.$route.params.id,
          type : 'upcoming',
          search: '',
        }
        await  this.$store.dispatch('getPageUpcomingEvents', payload)
      } else  {
        if(this.currentUserIsAdmin){
          await this.$router.push('/');
        }
      }
    },
    async getPastEventsData () {
      if (this.$route.params.id) {
        let payload = {
          showMore : false,
          page_id: this.$route.params.id,
          type : 'past',
          search: '',
        }
        await  this.$store.dispatch('getPagePastEvents', payload)
      } else  {
        if(this.currentUserIsAdmin){
          await this.$router.push('/');
        }
      }
    },
    async getPageRooms () {
      if(this.$route.params.id) {
          await this.setPageRooms(this.$route.params.id)
        await this.setPagetextRooms(this.$route.params.id)
      }
    }

  },
};
</script>
