<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade login-type show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup add-credit-card-heading">
            <div class="work-experience-heading">
              <div class="back-arrow-link" v-on:click="goBack()">
                <div class="svg-icon">
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.00073 1L2.00073 6L7.00073 11" stroke="#47505B" stroke-width="1.5"></path>
                  </svg>
                </div>
              </div>
              <h1 class="add-card">Add PayPal</h1>

              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="svg-color"></path>
                  <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="svg-color"></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">

              <div class="redirect-msg"> You're redirected to the PayPal web-site..</div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  methods: {
    closeModal: function () {
      this.$parent.closeWindow();
    },
    goBack: function () {
      this.$parent.backF();
    }
  }
}
</script>
