<template>
  <div class="row my-5">
    <div class="col-12 text-center">
      <h3> SELLING- STREAMING Button</h3>
      <payStreamSwitch/>
    </div>
    <div class="col-4 my-2 text-center">
      <h3> CARD- Song</h3>
      <div class="my-2 text-left ">
        <SongOrAlbumCardWithSquareImage class="mx-auto" v-if="song" :item="song" :buy="true"/>
      </div>
    </div>
    <div class="col-4 my-2 text-center">
      <h3> CARD- Album</h3>
      <div class="my-2 mx-auto text-left ">
        <SongOrAlbumCardWithSquareImage class="mx-auto" v-if="album" :item="album.data" :buy="true"/>
      </div>
    </div>
    <div class="col-4 my-2 text-center">
      <h3> CARD- Page or Artist</h3>
      <div class="my-2 mx-auto text-left ">
        <PageOrArtistCard class="mx-auto" v-if="page" :item="page"/>
      </div>
    </div>
    <div class="col-12 my-2 text-center">
      <h3> SINGLES/ ALBUMS (TOP NEW)</h3>
      <div class="my-2 mx-auto text-left ">
        <SongOrAlbumRowList class="mx-auto" :item="song" :singles="true"/>
      </div>
    </div>
    <div class="col-12 my-2 text-center">
      <h3> TOP 100 SINGLES/ ALBUMS</h3>
      <div class="my-2 mx-auto text-left ">
        <SongOrAlbumRowList class="mx-auto" :item="song" :rating="true"/>
      </div>
    </div>
    <div class="col-12 my-2 text-center">
      <h3> SONGS & ALBUMS (3/4 page)</h3>
      <div class="my-2 mx-auto text-left ">
        <SongOrAlbumRowList class="mx-auto" :item="song" />
      </div>
    </div>
    <div class="col-12 my-2 text-center">
      <h3> Charts Singles and Remixes</h3>
      <div class="my-2 mx-auto text-left ">
        <ChartTopSellingList class="mx-auto" :listData="[song,song]"  :type="'singles'"/>
      </div>
    </div>
    <div class="col-12 my-2 text-center">
      <h3> Country Card</h3>
      <div class="my-2 mx-auto text-left ">
        <ChartCountryCard class="mx-auto" :colorCode="1" style="width: 170px;" :type="'singles'"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChartTopSellingList from "@/components/Common/Elements/ChartTopSellingList"
import ChartCountryCard from "@/components/Common/UiComponenets/Music/ChartCountryCard.vue";
import SongOrAlbumRowList from "@/components/Common/UiComponenets/Music/SongOrAlbumRowList";
import PageOrArtistCard from "@/components/Common/UiComponenets/PageOrArtistCard";
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue";
import SongOrAlbumCardWithSquareImage from "@/components/DesignGuide/Music/Components/Grid.vue";
import {getAlbumDetailByID, getPage, getSongDetailByID} from "../../../apis/APIs";

export default {
  name: "AllComponentsUsed",
  components: {
    ChartTopSellingList,
    ChartCountryCard,
    SongOrAlbumRowList,
    payStreamSwitch,
    SongOrAlbumCardWithSquareImage,
    PageOrArtistCard
  },
  data() {
    return {
      song: null,
      album: null,
      page: null,
    }
  },
  mounted() {
    this.getSong()
    this.getAlbum()
    this.getPage()
  },
  methods: {
    async getSong() {
      try {
        const {data} = await getSongDetailByID(38);
        if (data.success === 1) {
          this.song = data.information
        }
      } catch (e) {
        console.error('error in fetching song')
      }
    },
    async getAlbum() {
      try {
        const {data} = await getAlbumDetailByID(71);
        if (data.success === 1) {
          this.album = data.information
        }
      } catch (e) {
        console.error('error in fetching song')
      }
    },
    async getPage() {
      try {
        const {data} = await getPage(53);
        if (data.success === 1) {
          this.page = data.information.data
        }
      } catch (e) {
        console.error('error in fetching song')
      }
    }
  }
}
</script>

<style scoped>

</style>
