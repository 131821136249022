<template>
  <div class="post">
    <div class="post-user">
      <img src="" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
