<template>
  <div class="wrapRelatedEvents">
    <div class="titleDetalsRightPart">
      <h3>
        {{ title_section }}
      </h3>
      <div class="showAll">
        Show All
        <span class="for_hover_gray_btn">
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.01562 1L6.1515 5.173L1.01562 9.713"
              stroke="#47505B"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
    <div
      v-for="item in dataRelatedEvents"
      :key="item.id"
      class="contentRelatedEvents"
    >
      <div class="content_info">
        <div class="divImg"><img src="" alt="event img" /></div>
        <div class="infoPart">
          <div class="when">
            {{ item.when }}
            <span v-if="title_section === 'Related Events'"
              ><svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ item.current }}</span
            >
          </div>
          <h5>{{ item.name }}</h5>
          <h6>{{ item.where }}</h6>
          <p>{{ item.time }}</p>
          <article class="wr_btns_and_price">
            <div v-if="title_section === 'Related Events'" class="btnDiv">
              <button>Interested</button>
              <span></span>
              <button>Going</button>
            </div>
            <div v-if="title_section != 'Related Events'" class="price_info">
              Sold: 240 / 280 • Gross: $ 1,024
            </div>
          </article>
        </div>
      </div>
      <section
        v-if="title_section != 'Related Events'"
        class="wr_dashboard_btn"
      >
        <button class="for_hover_blue_btn">DASHBOARD</button>
        <div>
          <button class="for_hover_empty_border_blue">EDIT</button>
          <span class="three_dots_menustyle for_hover_gray_btn"
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99967 8.66634C8.36786 8.66634 8.66634 8.36786 8.66634 7.99967C8.66634 7.63148 8.36786 7.33301 7.99967 7.33301C7.63148 7.33301 7.33301 7.63148 7.33301 7.99967C7.33301 8.36786 7.63148 8.66634 7.99967 8.66634Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99967 4.00033C8.36786 4.00033 8.66634 3.70185 8.66634 3.33366C8.66634 2.96547 8.36786 2.66699 7.99967 2.66699C7.63148 2.66699 7.33301 2.96547 7.33301 3.33366C7.33301 3.70185 7.63148 4.00033 7.99967 4.00033Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataRelatedEvents: [
        {
          id: 1,
          img: "",
          when: "JULY 12",
          name: "Four Tet & Guests",
          where: "Royal Albert Hall, Manchester",
          time: "8:00pm til 4:00am",
          current: "000",
        },
        {
          id: 2,
          img: "",
          when: "JULY 12",
          name: "Four Tet & Guests",
          where: "Royal Albert Hall, Manchester",
          time: "8:00pm til 4:00am",
          current: "000",
        },
        {
          id: 3,
          img: "",
          when: "JULY 12",
          name: "Four Tet & Guests",
          where: "Royal Albert Hall, Manchester",
          time: "8:00pm til 4:00am",
          current: "000",
        },
      ],
    };
  },
  computed: {
    title_section() {
      return this.$route.path.includes("/event-manager/page/dashboard")
        ? "Upcoming Events"
        : "Related Events";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapRelatedEvents {
  .contentRelatedEvents {
    margin: 0 0 20px 0;
    width: 100%;
    .content_info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .wr_dashboard_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        border: 1.5px solid #D1433A;
        border-radius: 3px;
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        font-family: HelveticaNeueBold;
        height: 30px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        background: #D1433A;
        color: #fff;
      }
      div {
        display: flex;
        button{
          background: #fff;
          color: #D1433A;
          margin-right: 10px;
        }
      }
    }
  }
  .divImg {
    width: 110px;
    height: 110px;
    background: #c4c4c4;
    border-radius: 3px;
  }
  .when {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    color: #D1433A;
    span {
      color: #fff;
      display: flex;
      background: #ae4ea9;
      border-radius: 4px;
      width: 50px;
      height: 20px;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
      }
    }
  }
  h5 {
    color: #071526;
    font-size: 16px;
    font-family: HelveticaNeueBold;
    margin-bottom: 2px;
  }
  h6 {
    font-family: HelveticaNeueMedium;
    color: #47505b;
    font-size: 13px;
    margin-bottom: 2px;
  }
  p {
    font-family: HelveticaNeueMedium;
    color: #8b949f;
    font-size: 13px;
    margin-bottom: 2px;
  }
  .wr_btns_and_price {
    font-family: HelveticaNeueMedium;
    width: 200px;
    height: 30px;
    font-size: 11px;
    border-radius: 4px;
    .btnDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #D1433A;
      box-sizing: border-box;
      border-radius: 4px;
      height: 100%;
      span {
        height: 100%;
        border-left: 1px solid #D1433A;
      }
      button {
        width: 50%;
        border: none;
        background: transparent;
        color: #D1433A;
        height: 100%;
        &:hover {
          background: #D1433A1A;
        }
      }
    }
    .price_info {
      background: #e6eaf0;
      border-radius: 2px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
