<template>
  <div class="Signup_pop_overlay">
    <div
      class="modal fade login-type show modal_box_outer"
      id="sign_or_login "
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px;"
      aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="close-button"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click="closeRegisterModal"
          >
            <div class="svg-icon">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                class="svg-close"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6111 1.8703L1.87048 11.6109"
                  class="c-line-1"
                  stroke-width="1.3"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M1.87012 1.8703L11.6107 11.6109"
                  class="c-line-1"
                  stroke-width="1.3"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <img
              :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body need_signup">
            <div class="register-back-arrow">
              <a v-on:click="goBack">
                <div class="svg-icon">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                      stroke="#C4CCD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      class="c-line-1"
                      d="M15 10L10 15L15 20"
                      stroke="#C4CCD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      class="c-line-1"
                      d="M20 15H10"
                      stroke="#C4CCD6"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
            <div class="body-title d-block fW_500 m_b_22">
              <!-- <span>You need to</span> Sign Up <span> or</span> Log In <br> <span> for this action</span> -->
              You need to <strong>Sign Up</strong> for this action
            </div>
            <div class="input-wrap">
              <div class="svg-icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66732 2.66602H13.334C14.0673 2.66602 14.6673 3.26602 14.6673 3.99935V11.9993C14.6673 12.7327 14.0673 13.3327 13.334 13.3327H2.66732C1.93398 13.3327 1.33398 12.7327 1.33398 11.9993V3.99935C1.33398 3.26602 1.93398 2.66602 2.66732 2.66602Z"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M14.6673 4L8.00065 8.66667L1.33398 4"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                placeholder="E-mail"
                :value="email"
                readonly
                @change="onChange"
                name="signup_form_email"
              />
            </div>
            <p class="error_msg text-center" v-if="loading">Please Wait...</p>
            <p class="error_msg error_msg_red" v-if="message">{{ message }}</p>

            <label class="error_msg error_msg_red" v-if="_handleErrorMessage('email')"
            >{{ _handleErrorMessage("email") }}</label
            >
            <div class="custom-hr"></div>

            <div class="action-block need_signup_action">
              <!-- <div class="btn btn-facebook"> -->
              <button
                class="btn btn-primary col-12 need_signup Font_semibold"
                v-on:click="$parent.closeModal"
                :disabled="is_valid_btn"
                :style="[is_valid_btn ? { cursor: 'not-allowed' } : { cursor: 'pointer' }]"
              >
                {{ loading ? "Please Wait..." : "SIGN UP" }}
              </button>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as Helper from "../../../apis/Helper.js";
import { SIGNUP_ERRORS } from "../../../store/types";

export default {
  data() {
    return {
      message: ""
    };
  },
  computed: {
    ...mapGetters({
      form_data: "signup_email_form_map_to_props"
    }),
    email() {
      const { email = "" } = this.form_data || {};

      return email;
    },
    loading() {
      const { loading = false } = this.form_data || {};

      return loading;
    },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    },
    is_valid_btn() {
      return !this.email;
    }
  },
  methods: {
    ...mapActions([
      "check_user_exists"
    ]),
    ...mapMutations([
      "updateSignupFormData",
      "updateSignupUIConstraints"
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
            this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    onChange(e) {
      const { name, value } = e.target || {};

      this.updateSignupFormData({
        [name]: value
      });
    },
    nextStep() {

      const body = {
        email: this.email
      };

      console.log("test data ===> store", this.$store.state, body, Object.keys(body), Helper);

      this.$parent.newModal("register-step-1");
      return;
      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateSignupUIConstraints({
              [SIGNUP_ERRORS]: []
            });
            try {
              this.message = "";
              await this.check_user_exists();
              this.$parent.newModal("register-step-1");
            } catch (e) {
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else this.updateSignupUIConstraints({
            [SIGNUP_ERRORS]: response && response.length ? response : []
          });
        })
        .catch(err => console.log(err));
    },
    nextStep1: function() {
      this.$parent.newModal("register-step-1");
    },
    goBack: function() {
      this.$parent.previousStep();
    },  
    closeRegisterModal: function() {
      this.updateSignupFormData({});
      this.$parent.closeModal();
    }
  }
};
</script>
