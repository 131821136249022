<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 15.3346C6 15.3346 12 11.3346 12 6.66797C12 5.07667 11.3679 3.55055 10.2426 2.42533C9.11742 1.30011 7.5913 0.667969 6 0.667969C4.4087 0.667969 2.88258 1.30011 1.75736 2.42533C0.632141 3.55055 0 5.07667 0 6.66797C0 11.3346 6 15.3346 6 15.3346ZM8 6.66797C8 7.77254 7.10457 8.66797 6 8.66797C4.89543 8.66797 4 7.77254 4 6.66797C4 5.5634 4.89543 4.66797 6 4.66797C7.10457 4.66797 8 5.5634 8 6.66797Z" fill="#47505B"/>
                </svg>

                Location
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="updateLocation">
                  <div class="artist-setting-panel">
                    <div class="row">
                      <div class="col-6">
                        <b-form-group
                            label-for="category"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Country
                          </template>
                          <b-form-select
                              id="category"
                              v-model="$v.location.country.$model"
                              :state="validateState('country')"
                              :options="countries"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              size="sm"
                              :value="null"
                          >
                          </b-form-select>
                          <b-form-invalid-feedback
                              id="category"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <b-form-group
                            label-for="category"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            City
                          </template>
                          <b-form-select
                              id="city"
                              v-model="$v.location.city.$model"
                              :state="validateState('city')"
                              :options="cities"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              :disabled="citiesOFSelectedCountry.loading"
                              size="sm"
                              :value="null"
                          >
                          </b-form-select>
                          <b-form-invalid-feedback
                              id="city"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <b-form-group
                            label-for="category"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Work Location
                          </template>
                          <b-form-select
                              id="work_location"
                              v-model="$v.location.work_location.$model"
                              :options="workArea"
                              :state="validateState('work_location')"
                              class="mb-2 mr-sm-2 mb-sm-0 remove-border"
                              size="sm"
                              :value="null"
                          >
                          </b-form-select>
                          <b-form-invalid-feedback
                              id="work_location"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <button class="prime_button" type="submit">Save Changes</button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import DeletePageModal from '../DeletePageModal'
import {required} from "vuelidate/lib/validators";
import {getCitiesOfCountry, updateLocationOFPage} from "../../../../apis/APIs";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue';

export default {
  data() {
    return {
      deletePage: false,
      location: {
        country: null,
        city: null,
        work_location: null
      },
      citiesOFSelectedCountry: {
        loading: false,
        list: []
      },
      workArea: [
        {
          text: 'Worldwide',
          value: 'Worldwide'
        },
        {
          text: 'Anywhere in the UK',
          value: 'Anywhere in the UK'
        },
        {
          text: 'East',
          value: 'East'
        },
        {
          text: 'International',
          value: 'International'
        },
        {
          text: 'Midlands',
          value: 'Midlands'
        },
        {
          text: 'NE England',
          value: 'NE England'
        },
        {
          text: 'Northern Ireland',
          value: 'Northern Ireland'
        },
        {
          text: 'NW England',
          value: 'NW England'
        },
        {
          text: 'Remote',
          value: 'Remote'
        },
        {
          text: 'Republic of Ireland',
          value: 'Republic of Ireland'
        },
        {
          text: 'Scotland',
          value: 'Scotland'
        },
        {
          text: 'SW England',
          value: 'SW England'
        },
        {
          text: 'Wales',
          value: 'Wales'
        }
      ]
    }
  },
  components: {
    EyeIcon
    // MusicSubHeader,
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.map(country => {
        return {
          value: country.id,
          text: country.name,
        }
      })
    },
    cities() {
      if (this.citiesOFSelectedCountry.list.length > 0) {
        return this.citiesOFSelectedCountry.list.map(city => {
          return {
            value: city.id,
            text: city.name,
          }
        })
      }
      return []

    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    pageType() {
      return this.current_page.type
    },

  },
  validations: {
    location: {
      country: {
        required
      },
      city: {
        required
      },
      work_location: {
        required
      },
    }
  },
  watch: {
    'location.country': async function (newVal, oldVal) {
      this.location.city = null
      this.location.work_location = null
      if (newVal === null) {
        this.citiesOFSelectedCountry.list = []
      } else {
        await this.FetchCitiesOfSelectedCountry(newVal)
      }
    },
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.location[name];
      return $dirty ? !$error : null;
    },
    async FetchCitiesOfSelectedCountry(id) {
      try {
        this.citiesOFSelectedCountry.loading = true
        const {data} = await getCitiesOfCountry(id)
        const {success, information} = data
        if (success === 1) {
          this.citiesOFSelectedCountry.list = information
        }
        this.citiesOFSelectedCountry.loading = false
      } catch (e) {
        this.citiesOFSelectedCountry.loading = false
        console.error(e)
      }
    },
    async updateLocation() {
      this.$v.location.$touch();
      if (this.$v.location.$anyError) {
        return;
      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formData = new FormData();
          for (const field in this.location) {
            if (!!(this.location[field])) {
              formData.append(field, this.location[field]);
            }

          }
          const {data} = await updateLocationOFPage(this.current_page.id, formData);
          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const page = data.information || {};
            this.$store.commit('SET_PAGE_DATA', page)
            this.notificationToast(true, 'Page', message, 5000, 'success')
          } else {
            await this.notificationToast(true,'Page', message, 5000, 'error')
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.notificationToast(true, 'Page', e.message, 5000, 'error')
        }

      }
    },
    async getPageDataFromStore() {
      this.location.country = (this.current_page.country) ? this.current_page.country.id : null
      if (this.location.country) {
        await this.FetchCitiesOfSelectedCountry(this.location.country)
      }
      this.location.work_location = this.current_page.work_location
      this.location.city = (this.current_page.city) ? this.current_page.city.id : null
    },
  },
  mounted() {
    this.$store.dispatch('fetchCountries')
    this.getPageDataFromStore()
  }
}
</script>
<style>
.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}
</style>
<style lang="scss">
.custom-select {
  &.is-valid {
    border-color: unset!important;
  }
}
.was-validated {
  .custom-select:valid {
    border-color: blue!important;
  }
}
</style>
<style lang="scss" scoped>
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
}
</style>
