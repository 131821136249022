<template>
  <div class="widget-item">
    <div class="cover-image">
      <div class="img-wrapper">
        <img
          :src="item.artwork_url"
          alt=""
          @error="onProfileImageError($event, getFirstAlphabetOfUserName({username : getArtist(item)}))"
          style="width: inherit"
        />
      </div>
      <div class="status" v-if="item.verified">
        <svg
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.94219 5.26891L1.81219 6.81691L2.62619 4.30991L0.492188 2.76091H3.12819L3.94219 0.253906L4.76019 2.76091H7.39219L5.26219 4.30991L6.07619 6.81691L3.94219 5.26891Z"
            fill="white"
          ></path>
        </svg>
      </div>
    </div>
    <div class="item-desc">
      <div class="name cursor-pointer" @click="redirectWidgetsToArtistPage($event, item.id, 'artist')">{{ getArtist(item) }}</div>
      <div class="info">
        <div>
          <div class="svg-icon">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.9">
                <path
                  d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {{ item.followers_count }}
        </div>
        <div v-if="eventsPage">
          <div class="svg-icon">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.17188 3.17455V0"
                stroke="#8B949F"
                stroke-width="1.2"
              />
              <path
                d="M8.16406 3.17455V0"
                stroke="#8B949F"
                stroke-width="1.2"
              />
              <path
                d="M2.44696 1.5498H9.81971C10.2028 1.55213 10.5696 1.71611 10.8405 2.00636C11.1114 2.2966 11.2645 2.6896 11.2667 3.10006V9.44924C11.2645 9.85971 11.1114 10.2527 10.8405 10.5429C10.5696 10.8332 10.2028 10.9972 9.81971 10.9995H2.44696C2.06387 10.9972 1.69708 10.8332 1.42619 10.5429C1.1553 10.2527 1.00217 9.85971 1 9.44924V3.10006C1.01401 2.69366 1.17093 2.30798 1.43931 2.02041C1.7077 1.73285 2.06767 1.56481 2.44696 1.5498V1.5498Z"
                stroke="#8B949F"
                stroke-width="1.2"
              />
              <path
                d="M2.44531 5.09375H9.88691"
                stroke="#8B949F"
                stroke-width="1.2"
              />
            </svg>
          </div>
          {{ item.total_tracks }}
        </div>
        <div v-else>
          <div class="svg-icon">
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.9">
                <path
                  d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885"
                  stroke="#8B949F"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
          {{ item.total_tracks }}
        </div>
        
      </div>
    </div>
    <div
      v-if="spinner"
      class="text-center d-flex align-items-center justify-content-center ml-auto pr-2"
    >
      <b-spinner class="spinner-color" small label="Small Spinner"></b-spinner>
    </div>
    <div
      v-else
      class="action"
      :class="{ active: follow === 'approved' }"
      @click="followUnfollow(item)"
    >
      <div class="svg-icon">
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 8.00987C3.757 8.00987 6 10.2552 6 13.015C6 13.5676 6.448 14.0161 7 14.0161C7.552 14.0161 8 13.5676 8 13.015C8 9.15105 4.86 6.00781 1 6.00781C0.448 6.00781 0 6.45627 0 7.00884C0 7.56141 0.448 8.00987 1 8.00987Z"
            fill="#D1433A"
            class="c-fill-1"
          ></path>
          <path
            d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
            fill="#D1433A"
            class="c-fill-1"
          ></path>
          <path
            d="M1 14.0157C1.55228 14.0157 2 13.5676 2 13.0147C2 12.4619 1.55228 12.0137 1 12.0137C0.447715 12.0137 0 12.4619 0 13.0147C0 13.5676 0.447715 14.0157 1 14.0157Z"
            fill="#D1433A"
            class="c-fill-1"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoToFollowBarTile",
  data() {
    return {
      listing_type: "grid",
      follow: "",
      spinner: false,
    };
  },
  components: {},
  computed: {},
  props: {
    pageFrom: String,
    title: String,
    item: {
      type: Object,
      required: true,
    },
    eventsPage: Boolean,
  },
  mounted() {
    if (this.item) {
      this.follow = this.item.follow_action;
    }
  },
  methods: {
    async followUnfollow(object) {
      this.spinner = true;
      const res = await this.followOrUnfollowPage(object, this.follow);
      if (res.success === 1) {
        this.follow = this.follow === "approved" ? null : "approved";
      }
      this.spinner = false;
    },
  },
};
</script>

<style scoped>
.widget-follow .widget-item .action.active {
  background: #D1433A;
}
.spinner-color {
  color: #D1433A !important;
}
</style>
