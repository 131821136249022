<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="confirm-restore-password-security-code"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog confirm_email">
        <div class="modal-content">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeLoginModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <!-- <img src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/logo.png" alt="">  -->
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body">
            <div class="body-title Font_semibold">
              <a class="back-arrow" v-on:click="goBack" v-if="!restorePassword">
                <div class="svg-icon">
                  <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        class="c-line-1"
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M15 10L10 15L15 20"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M20 15H10"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
              Enter Security Code
            </div>
            <div class="modal-title-sm grey-text">
              Please check your email for a message with a code <br/>
              Code sent to <span v-if="emailFromParams">{{ emailFromParams }}</span>
              <span v-else>{{ email }}</span>
            </div>
            <p class="error_msg text-center error_msg_red" v-if="message">{{ message }}</p>
            <div class="input-row">
              <vue-fake-input
                  :length="4"
                  :fontSize="32"
                  v-model="code"
                  inputColor="#D1433A"
                  :allowPaste="false"
                  class="pl-2 ml-5"
              />
            </div>
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('code')">{{ _handleErrorMessage("code") }}</label>
            <div class="input-wrap m-t-10">
              <div class="svg-icon">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M12.6667 7.33398H3.33333C2.59695 7.33398 2 7.93094 2 8.66732V13.334C2 14.0704 2.59695 14.6673 3.33333 14.6673H12.6667C13.403 14.6673 14 14.0704 14 13.334V8.66732C14 7.93094 13.403 7.33398 12.6667 7.33398Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                  <path
                      d="M4.66602 7.33398V4.66732C4.66602 3.78326 5.01721 2.93542 5.64233 2.3103C6.26745 1.68517 7.11529 1.33398 7.99935 1.33398C8.8834 1.33398 9.73125 1.68517 10.3564 2.3103C10.9815 2.93542 11.3327 3.78326 11.3327 4.66732V7.33398"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                  type="password"
                  placeholder="Password"
                  v-model="password"
              />
            </div>
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('password')">{{ _handleErrorMessage("password") }}</label>
            <div class="input-wrap m-t-10">
              <div class="svg-icon">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M12.6667 7.33398H3.33333C2.59695 7.33398 2 7.93094 2 8.66732V13.334C2 14.0704 2.59695 14.6673 3.33333 14.6673H12.6667C13.403 14.6673 14 14.0704 14 13.334V8.66732C14 7.93094 13.403 7.33398 12.6667 7.33398Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                  <path
                      d="M4.66602 7.33398V4.66732C4.66602 3.78326 5.01721 2.93542 5.64233 2.3103C6.26745 1.68517 7.11529 1.33398 7.99935 1.33398C8.8834 1.33398 9.73125 1.68517 10.3564 2.3103C10.9815 2.93542 11.3327 3.78326 11.3327 4.66732V7.33398"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                  type="password"
                  placeholder="Confirm Password"
                  v-model="confirm_password"/>
            </div>
            <label class="error_msg error_msg_red"
                   v-if="_handleErrorMessage('confirm_password')">{{ _handleErrorMessage("confirm_password") }}</label>
            <button
                id="verify_email_address"
                class="btn btn-login m-t-20 non-active Font_semibold"
                v-on:click="resetPassword"
            >
              {{ loading ? "Please Wait..." : "CONFIRM CODE" }}
            </button>
            <div class="custom-hr"></div>
            <div class="modal-title-sm grey-text">
              You can retrieve your password with the code sent to your e-mail or click on the link from your e-mail to
              change your password
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {restorePassword, reset_password_api} from '../../../apis/APIs';
import * as SMStorage from "../../../apis/SMStorage";
import * as Helper from '../../../apis/Helper.js';
import VueFakeInput from 'vue-fake-input';
import {SYSTEM_DATA_IS_AUTHENTICATED, SYSTEM_DATA_IS_WELCOME_PAGE, USER_DATA} from "../../../store/types";

export default {
  data() {
    return ({
      email: "",
      emailFromParams:"",
      code: "",
      loading: false,
      message: "",
      restorePassword: false,
      password: "",
      confirm_password: "",
      errors: []
    });
  },
  components: {VueFakeInput},
  computed: {
    ...mapGetters({
      form_data: "login_form_map_to_props",
      check_email_data: "signup_email_form_map_to_props",
    })
  },

  mounted() {
    const { restorePassword, code, email } = this.$route.query;
    this.restorePassword = restorePassword === 'true'
    if (email){
      this.emailFromParams=email
    }
    if (code){
      this.code = code;
    }
    this.init();
  },
  methods: {
    ...mapMutations([
      "updateSystemDataUIConstraints"
    ]),
    ...mapActions([
      "set_user_data"
    ]),
    init() {
      const {email = ""} = this.form_data || {};

      if (email) return this.email = email;

      this.email = this.check_email_data && this.check_email_data.email ? this.check_email_data.email : "";
    },
    isError(key) {
      console.log("this.errors ===> ", this.errors);
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      console.log("message ===> ", message)
      return message;
    },
    closeLoginModal: function () {
      this.$parent.closeModal();
    },
    goBack: function () {
      this.$parent.newModal("login");
    },
    resetPassword: async function () {
      try {
        const {code, loading, password, confirm_password, email} = this;

        if (loading) return;

        const body = {
          code,
          password,
          confirm_password,
        }

        Helper.validate(Object.keys(body), body)
            .then(async ({status, response}) => {
              if (status) {
                this.errors = []
                try {
                  this.message = "";
                  var formdata = new FormData();
                  formdata.append('token', code);
                  formdata.append('password', password);
                  formdata.append('password_confirmation', confirm_password)
                  // const query = `?email=${email}&code=${one}${two}${three}${four}${five}${six}&password=${password}`;
                  this.loading = true;
                  const res = await reset_password_api(formdata);
                  const {success, message, information, token_type, access_token} = res.data || {};
                  this.loading = false;

                  if (success === 1) {
                    this.$store.commit("SET_ACCESS_TOKEN", `${access_token}`);
                    localStorage.setItem('token', `Bearer ${access_token}`)
                    this.updateSystemDataUIConstraints({
                      [SYSTEM_DATA_IS_AUTHENTICATED]: true,
                      [SYSTEM_DATA_IS_WELCOME_PAGE]: true
                    });
                    await this.set_user_data(information);
                    this.$parent.closeModal();
                    await this.$router.replace("/home");
                  } else {
                    this.message = message;
                  }
                } catch (e) {
                  this.loading = false;
                  this.message = e && e.message ? e.message : "Please try again.";
                }
              } else this.errors = response && response.length ? response : [];
            }).catch(err => console.log(err));

        // this.loading = true;

        // const res = await restorePassword(body);

        // this.loading = false;
        // const { success, message, information } = res.data || {};

        // this.message = "";
        // if(success === 1){
        //     try{
        //         await SMStorage.storeSMLoginData(information);
        //         const userData = await SMStorage.getSMLoginData();
        //         console.log("user data ===>", information);
        //         this.$store.commit("updateUserUIConstraints", ({
        //             [USER_DATA]: userData && userData.response ? userData.response : userData
        //         }));
        //     } catch(error) {

        //     }

        //     this.$parent.newModal("verification-status");
        // } else {
        //   this.message = message;
        // }

      } catch (error) {
        this.loading = false;
        this.message = error && error.message ? error.message : "Please try again.";
      }
    }
  }
};
</script>
