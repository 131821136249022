<template>
  <div class="date-time-picker" @click="show=true">
    <div class="output-box text-center">
      <span v-if="displayText">Week of <span class="week-date">{{ displayText }}</span></span>
      <span v-else>Select Week</span>
      <date-picker v-model="value" type="week" class="chart-week-picker" @blur="blur" :show-week-number="false"
                   :open="show" :confirm="true" @confirm="selectValue"></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '/public/css/vue2-date-picker.scss';
import moment from "moment";

export default {
  name: "DateTimePicker",
  data() {
    return {
      displayText: null,
      value: null,
      show: false,
      output: {
        year: null,
        startOfWeek: null,
        endOfWeek: null,
        dateSelected: null,
      }
    }
  },
  watch: {
    value(newVal) {
      this.$emit('valueUpdate', newVal)
    }
  },
  components: {
    DatePicker
  },
  props: {},
  methods: {
    selectValue(date) {
     this.parseValue(date)
      this.show = false
    },
    parseValue(date) {
      if (date) {
        this.output.year = moment(date).year()
        this.output.startOfWeek = moment(date).startOf('week').format('DD-MM-YYYY')
        this.output.endOfWeek = moment(date).endOf('week').format('DD-MM-YYYY')
        this.output.dateSelected = moment(date).format()
        this.displayText = moment(date).startOf('week').format('MMMM Do,YYYY')
        this.$emit('selectedValue', this.output)
      }
      return this.output
    },
    blur() {
      console.log('blur')
    }
  }
}
</script>

<style scoped lang="scss">
.date-time-picker {
  .output-box {
    font-family: HelveticaNeueBold, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    position: relative;
    margin: auto;
    background-color: #f0f2f5;
    height: 50px;
    padding: 14px 14px 14px 14px !important;
    border: 1px solid #D1433A;
    box-sizing: border-box;
    border-radius: 4px;
    width: 250px;

    .week-date {
      color: #D1433A !important;
    }
  }  
}

@media screen and (min-width: 2560px) {
  .date-time-picker .output-box {
    font-size: var(--sm-font-size);
  }
}
</style>
