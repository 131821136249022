<template>
  <div class="row" style="background-color: white">
    <div class="col-sm-9" style="max-width: 80.45% !important;
    flex: 80.45% !important;">
      <div class="row pt-4 px-3 setting-form metadata-input-form">
        <div class="col-sm-12 pl-1">
          <h5 class="song-heading">{{ mainTitle }}</h5>
        </div>
        <div v-if="(type==='song' || type=== 'podcast')" class="row pl-3 pr-0">
          <div class="col-sm-4 pl-1 pr-3">
            <div class="form-group">
              <label class="label-style">Metadata Language</label>
              <b-form-select
                  class="form-control"
                  id="meta_language"
                  v-model="$v.medataDataSong[METADATA_LANGUAGE].$model"
                  :options="languages.list"
                  value-field="id"
                  :state="validateState([METADATA_LANGUAGE])"
                  :disabled="languages.loading"
                  placeholder="Select Language of Lyrics"
              >
              </b-form-select>
              <b-form-invalid-feedback
                  id="meta_language"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-8 pl-1 pr-1">
            <div class="form-group">
              <label class="label-style">Primary {{(actionType === 'createPodcast') ? 'Authors' : 'Artists'}}</label>
              <b-form-input name="primary_artists" id="primary_artists" :state="validateState([PRIMARY_ARTIST])"
                            class="form-control" v-model="$v.medataDataSong[PRIMARY_ARTIST].$model"></b-form-input>
              <b-form-invalid-feedback
                  id="primary_artists"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-4 pl-1 pr-3">
            <div class="form-group">
              <label class="label-style">Composers</label>
              <b-form-input name="composers" id="composers" class="form-control" :state="validateState([COMPOSER])"
                            v-model="$v.medataDataSong[COMPOSER].$model"></b-form-input>
              <b-form-invalid-feedback
                  id="composers"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-8 pl-1 pr-1">
            <div class="form-group">
              <label class="label-style">Producers</label>
              <b-form-input name="producers" id="producers" class="form-control" :state="validateState([PRODUCER])"
                            v-model="$v.medataDataSong[PRODUCER].$model"></b-form-input>
              <b-form-invalid-feedback
                  id="producers"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-4 pl-1 pr-3">
            <div class="form-group">
              <label class="label-style">Record Label</label>
              <b-form-input name="record_label" id="record_label" class="form-control"
                            :state="validateState([RECORD_LABEL])"
                            v-model="$v.medataDataSong[RECORD_LABEL].$model"></b-form-input>
              <b-form-invalid-feedback
                  id="record_label"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-4 pl-1 pr-3">
            <div class="form-group">
              <label class="label-style">Publisher</label>
              <b-form-input name="publishers" id="publishers" class="form-control" :state="validateState([PUBLISHER])"
                            v-model="$v.medataDataSong[PUBLISHER].$model"></b-form-input>
              <b-form-invalid-feedback
                  id="publishers"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-4 pl-3 pr-1">
            <div class="form-group">
              <label class="label-style">ISRC</label>
              <b-form-input name="isrc" id="isrc" class="form-control" :state="validateState([ISRC])"
                            v-model="$v.medataDataSong[ISRC].$model"></b-form-input>
              <b-form-invalid-feedback
                id="isrc"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-4 pl-1 pr-3">
            <div class="form-group">
              <label class="label-style">Release Date</label>
              <b-form-datepicker id="released_at" v-model="$v.medataDataSong[RELEASED_AT].$model"
                                 :state="validateState([RELEASED_AT])" placeholder="DD/MM//YYYY"
                                 :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                 class="date-style"
                                 locale="en"></b-form-datepicker>
              <b-form-invalid-feedback
                  id="released_at"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="col-sm-4 pl-1 pr-3">
            <div class="form-group">
              <label class="label-style">Explicit Content</label>
              <b-form-select
                class="form-control"
                id="exp_content"
                v-model="$v.medataDataSong[EXPLICIT_CONTENT].$model"
                :state="validateState([EXPLICIT_CONTENT])"
                :options="explicit_content_options">
              </b-form-select>
              <b-form-invalid-feedback
                id="exp_content"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-4 pl-3 pr-1">
            <div class="form-group">
              <label class="label-style d-flex">(C) Line <span class="ml-auto">0/20</span></label>
              <b-form-input
                name="c_line"
                id="c_line"
                class="form-control"
                :state="validateState([COPYRIGHT])"
                v-model="$v.medataDataSong[COPYRIGHT].$model"
                placeholder="eg. 2020 XYZ Record Label Ltd."
              ></b-form-input>
              <b-form-invalid-feedback
                  id="c_line"
              >This is a required field.
              </b-form-invalid-feedback>
            </div>
          </div>

          <div class="col-sm-12 pl-1">
            <h3 class="metadata-checbox-h">Licence</h3>
          </div>
          <div class="col-sm-4 pl-1">
            <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
              <div class="widget-item px-0">
                <div class="custom-checkbox">
                  <b-form-checkbox class="download-custom-control"
                      name="checkbox-1"
                      value="all_rights_reserved"
                      v-model="$v.medataDataSong[ALL_RIGHTS_RESERVED].$model"
                  >
                    All rights reserved
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4 px-0">
            <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
              <div class="widget-item px-0">
                <div class="custom-control custom-checkbox">
                  <b-form-checkbox
                    class="download-custom-control"
                      name="checkbox-1"
                      value="creative_commons"
                      v-model="$v.medataDataSong[CREATION_COMMON].$model"
                  >
                    Creative Commons
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row mb-3 pr-0" v-for="(song,index) in songs" :key="index" style="padding-left: 20px;">
          <div class="col-sm-11 pl-1 ">
            <h1 class="metadeta-dropdown-h">#{{index+1}}. {{song.title}}</h1>
          </div>
          <div class="col-sm-1">
            <b-button
                class="bg-transparent border-0"
                :class="song.visible ? null : 'collapsed'"
                :aria-expanded="song.visible ? 'true' : 'false'"
                :aria-controls="'collapse-'+index"
                @click="song.visible = !song.visible"
            >

            <span class=" metadeta-dropdown-icon" v-if="song.visible">
              <svg width="16" height="9" viewBox="0 0 16 9" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.7" d="M1 8L7.70481 1L15 8"
                      stroke="#C4CCD6" stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>

              </span>
              <span class=" metadeta-dropdown-icon" v-else>
                <svg width="16" height="9" viewBox="0 0 16 9" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 1L8.29519 8L1 0.999999" stroke="#D1433A" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </b-button>
          </div>
          <b-collapse :id="'collapse-'+index" v-model="song.visible" class=" mt-2">
            <div class="row">
              <div class="col-sm-4 pl-1">
                <div class="form-group">
                  <label class="label-style">Metadata Language</label>
                  <b-form-select
                      :id="'meta_language'+index"
                      class="form-control"
                      v-model="$v.songs.$each[index][METADATA_LANGUAGE].$model"
                      :options="languages.list"
                      :state="validateStateSongs(index,[METADATA_LANGUAGE])"
                      value-field="id"
                      :disabled="languages.loading"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                      :id="'meta_language'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-8 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Primary {{(actionType === 'createPodcast') ? 'Authors' : 'Artists'}}</label>
                  <b-form-input
                      :id="'primary_artists'+index"
                      v-model="$v.songs.$each[index][PRIMARY_ARTIST].$model"
                      :state="validateStateSongs(index,[PRIMARY_ARTIST])"
                      class="form-control"
                      placeholder=""
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :id="'primary_artists'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Producers</label>
                  <b-form-input
                      :id="'producers'+index"
                      v-model="$v.songs.$each[index][PRODUCER].$model"
                      :state="validateStateSongs(index,[PRODUCER])"
                      class="form-control"
                      placeholder=""
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :id="'producers'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-8 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Composers</label>
                  <b-form-input
                    :id="'composers'+index"
                    v-model="$v.songs.$each[index][COMPOSER].$model"
                    :state="validateStateSongs(index,[COMPOSER])"
                    class="form-control"
                    placeholder=""
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                    :id="'composers'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Record Label</label>
                  <b-form-input
                      :id="'record_label'+index"
                      v-model="$v.songs.$each[index][RECORD_LABEL].$model"
                      :state="validateStateSongs(index,[RECORD_LABEL])"
                      class="form-control"
                      placeholder=""
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :id="'record_label'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Publisher</label>
                  <b-form-input
                      :id="'publishers'+index"
                      v-model="$v.songs.$each[index][PUBLISHER].$model"
                      :state="validateStateSongs(index,[PUBLISHER])"
                      class="form-control"
                      placeholder=""
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :id="'publishers'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">ISRC</label>
                  <b-form-input
                      :id="'isrc'+index"
                      v-model="$v.songs.$each[index][ISRC].$model"
                      :state="validateStateSongs(index,[ISRC])"
                      class="form-control"
                      placeholder=""
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :id="'isrc'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Release Date</label>
                  <b-form-datepicker
                    :id="'released_at'+index"
                    v-model="$v.songs.$each[index][RELEASED_AT].$model"
                    placeholder="MM/DD/YYYY"
                    :state="validateStateSongs(index,[RELEASED_AT])"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    class="date-style"
                    locale="en"></b-form-datepicker>
                  <b-form-invalid-feedback
                    id="released_at"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style">Explicit Content</label>
                  <b-form-select
                    :id="'exp_content'+index"
                    class="form-control"
                    v-model="$v.songs.$each[index][EXPLICIT_CONTENT].$model"
                    :options="explicit_content_options"
                    :state="validateStateSongs(index,[EXPLICIT_CONTENT])"
                    value-field="id"

                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                    :id="'exp_content'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-4 pl-1 pr-3">
                <div class="form-group">
                  <label class="label-style d-flex">(C) Line <span class="ml-auto">0/20</span></label>
                  <b-form-input
                      :id="'c_line'+index"
                      v-model="$v.songs.$each[index][COPYRIGHT].$model"
                      :state="validateStateSongs(index,[COPYRIGHT])"
                      class="form-control"
                      placeholder="eg. 2020 XYZ Record Label Ltd."
                  >
                  </b-form-input>
                  <b-form-invalid-feedback
                      :id="'c_line'+index"
                  >This is a required field.
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-sm-12 pl-1">
                <h3 class="metadata-checbox-h">Licence</h3>
              </div>
              <div class="col-sm-4 pl-1">
                <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                  <div class="widget-item px-0">
                    <div class="custom-control custom-checkbox" style="padding-left: 15px;">
                      <b-form-checkbox
                          name="checkbox-1"
                          value="all_rights_reserved"
                          class="download-custom-control"
                          v-model="song[ALL_RIGHTS_RESERVED]"
                      >
                        All rights reserved
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 px-0">
                <div class="event-filter-widget with-app pg-metadata-song-checkbox-card">
                  <div class="widget-item px-0">
                    <div class="custom-control custom-checkbox">
                      <b-form-checkbox
                          name="checkbox-1"
                          value="creative_commons"
                          class="download-custom-control"
                          v-model="song[CREATION_COMMON]"
                      >
                        Creative Commons
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
          <div class="px-0" style="margin-top: 7px;">
            <hr class="mt-0 metadeta-border-bottom">
          </div>

        </div>

        <div style="border: 1px solid #E6EAF0; margin-bottom: 20px;"></div>

        <div data-v-58c6911e="" data-v-66e59ad3="" class="col-sm-12 pl-1 pb-4">
          <div data-v-58c6911e="" data-v-66e59ad3="" class=" cancel-btn-display">
            <button @click="previousStep" data-v-58c6911e="" data-v-66e59ad3="" class="upload-file-cancel-btn">CANCEL
            </button>
          </div>
          <div
              data-v-58c6911e=""
              data-v-66e59ad3=""
              style="float: right;">
            <div
                data-v-58c6911e=""
                data-v-66e59ad3=""
                class=" save-btn-display">
              <button
                  data-v-58c6911e=""
                  data-v-66e59ad3=""
                  class="upload-file-save-btn">
                SAVE TO DRAFT
              </button>
            </div>
            <div
                data-v-58c6911e=""
                data-v-66e59ad3=""
                class="upload-btn-display">
              <button
                  @click="saveDataInStore"
                  data-v-58c6911e=""
                  data-v-66e59ad3=""
                  class="upload-file-upload-btn">
                <span data-v-58c6911e="" data-v-66e59ad3="">NEXT STEP</span>
                <svg
                    data-v-58c6911e=""
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="basic-info-upload-btn">
                  <path
                      data-v-58c6911e=""
                      d="M0.773189 13.5C0.848551 13.5 0.923913 13.4589 0.961595 13.4178L6.91522 7.21067C7.02826 7.08735 7.02826 6.92292 6.91522 6.7996L0.961596 0.59249C0.848552 0.469169 0.697828 0.469169 0.584784 0.59249C0.47174 0.71581 0.47174 0.880237 0.584784 1.00356L6.31232 7.00514L0.584783 13.0067C0.471739 13.1301 0.471739 13.2945 0.584783 13.4178C0.622464 13.5 0.697826 13.5 0.773189 13.5Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.5"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 upload-file-p-padding"  style="max-width: 19.55%">
      <div class="row" style="padding-top: 28px;">
        <div class="col-sm-12">
          <h5 class="song-heading-1">Metadata</h5>
        </div>
        <div class="col-sm-12">
          <div>
            <p class="song-p">
              Metadata ensures your audio is ready for distribution and release. If your metadata isn’t in order, your music simply isn’t ready to share
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {
  ALL_RIGHTS_RESERVED,
  COMPOSER,
  COPYRIGHT,
  CREATE_ISRC,
  CREATION_COMMON,
  EXPLICIT_CONTENT,
  FEATURE_ARTIST,
  ISRC,
  METADATA_LANGUAGE,
  PRIMARY_ARTIST,
  PRODUCER,
  PUBLISHER,
  RE_MIXER,
  RECORD_LABEL,
  RELEASED_AT, TITLE
} from "../../../../store/types";
import {get_languages_api} from "../../../../apis/APIs";
import {required, requiredIf} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      ALL_RIGHTS_RESERVED,
      COMPOSER, COPYRIGHT, CREATE_ISRC, CREATION_COMMON, EXPLICIT_CONTENT,
      FEATURE_ARTIST, ISRC,
      METADATA_LANGUAGE,
      PRIMARY_ARTIST,
      PRODUCER, PUBLISHER,
      RE_MIXER,
      RECORD_LABEL, RELEASED_AT,
      medataDataSong: {
        [METADATA_LANGUAGE]: null,
        [PRIMARY_ARTIST]: null,
        [FEATURE_ARTIST]: '',
        [COMPOSER]: null,
        [PRODUCER]: null,
        [RE_MIXER]: '',
        [RECORD_LABEL]: null,
        [PUBLISHER]: null,
        [RELEASED_AT]: null,
        [ISRC]: null,
        [COPYRIGHT]: null,
        [EXPLICIT_CONTENT]: null,
        [CREATE_ISRC]: false,
        [ALL_RIGHTS_RESERVED]: false,
        [CREATION_COMMON]: false
      },
      languages: {
        loading: false,
        list: [
          {id: null, text: 'Select language of lyrics'}
        ]
      },
      explicit_content: null,
      explicit_content_options: [
        {id: null, value: null, text: 'Select explicit content'},
        {id: 1, value: 'no', text: 'No'},
        {id: 2, value: 'yes', text: 'Yes'},
      ],
      create_isrc: false,
      license: false,

      songs: []
    }
  },
  props: {
    type: {
      type: String,
      default: 'song'
    },
    actionType: {
      type: String,
      default: 'createSongAlbum'
    }
  },
  computed: {
    songListInAlbums() {
      return this.$store.getters.getAlbumSongsUploadedIn
    },
    mainTitle(){
      if(this.actionType === 'createSongAlbum'){
        return 'Metadata'
      }else if(this.actionType === 'createPodcast'){
        return 'Metadata'
      }else{
        return 'Metadata'
      }
    }
  },
  watch:{
    songListInAlbums(val){
      if(val){
        this.setValueOfSongs()
      }
    }
  },
  validations: {
    medataDataSong: {
      [METADATA_LANGUAGE]: {
        required
      },
      [PRIMARY_ARTIST]: {
        required
      },
      [FEATURE_ARTIST]: {
      },
      [COMPOSER]: {
        required
      },
      [PRODUCER]: {
        required
      },
      [RE_MIXER]: {
      },
      [RECORD_LABEL]: {
        required
      },
      [PUBLISHER]: {
        required
      },
      [RELEASED_AT]: {
        required
      },
      [ISRC]: {},
      [COPYRIGHT]: {
        required
      },
      [EXPLICIT_CONTENT]: {
        required
      },
      [CREATE_ISRC]: {
      },
      [ALL_RIGHTS_RESERVED]: {
        required
      },
      [CREATION_COMMON]: {
        required
      }
    },
    songs: {
      $each: {
        [METADATA_LANGUAGE]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [PRIMARY_ARTIST]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [FEATURE_ARTIST]: {
        },
        [COMPOSER]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [PRODUCER]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [RE_MIXER]: {
        },
        [RECORD_LABEL]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [PUBLISHER]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [RELEASED_AT]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [ISRC]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [COPYRIGHT]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        },
        [EXPLICIT_CONTENT]: {
          required:requiredIf(function () {
            return this.type==='album'
          })
        }
      }
    }
  },
  beforeMount() {
    this.fetchLanguagesData()
  },
  mounted() {
    this.setValueOfSongs()
  },
  methods: {
    validateStateSongs(index,name) {
      const {$dirty, $error} =  this.$v.songs.$each[index][name];
      return $dirty ? !$error : null;
    },
    setValueOfSongs(){
      this.songs = this.songListInAlbums
    },
    async fetchLanguagesData() {
      try {
        this.languages.loading = true;
        const res = await get_languages_api();
        const {success, message, data} = res.data || {};
        if (success === 1) {
          const list = data.map(ele => ({id: ele.id, text: ele.native_name}));
          this.languages.list = [...this.languages.list, ...list]
        }
        this.languages.loading = false;
      } catch (error) {
        console.error(error)
        this.languages.loading = false;
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.medataDataSong[name];
      return $dirty ? !$error : null;
    },
    nextStep() {
      this.$emit('nextStep')
    },
    previousStep() {
      this.$emit('previousStep')
    },
    async saveDataInStore() {
      if(this.type ==='song' || this.type ==='podcast'){
        this.$v.medataDataSong.$touch();
        if (this.$v.medataDataSong.$anyError) {
          return;
        } else {
          this.$store.commit('SET_SONG_STEP_3_DATA', this.medataDataSong)
          this.nextStep()
        }
      }
      else {
        this.$v.songs.$touch();
        if (this.$v.songs.$anyError) {
          return;
        } else {
          for (const song in this.songs) {
            this.songs[song][FEATURE_ARTIST] = ''
            this.songs[song][RE_MIXER] = ''
            this.songs[song][CREATE_ISRC] = false
          }
          await this.$store.commit('SET_ALBUM_STEP_3_DATA', this.songs)
          this.nextStep()
        }
      }
    }
  }
}
</script>
<style scoped>
.song-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #071526;
  opacity: 0.9;
  margin-bottom: 16px;
}

.song-p {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #47505B;
}

.upload-file-p-padding {
  padding: 0 19px 0 21px;
  background-color: #f9fafb;
}

.pg-metadata-song-checkbox-card {
  border: none !important;
}

.metadata-input-form .form-group {
  margin-bottom: 15px !important;
}

.metadata-checbox-h {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #47505B;
  opacity: 0.9;
}

.metadeta-dropdown-h {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.metadeta-border-bottom {
  border: none;
  opacity: 1;
  background: #E6EAF0;
}

.metadeta-dropdown-icon:hover {
  cursor: pointer;
}

.metadeta-dropdown-icon:hover svg path {
  stroke: #D1433A;
  cursor: pointer;
  margin: 0 auto;
}

.pg-metadata-song-checkbox-card .widget-item {
  padding: 5px 20px 17px;
}
.upload-file-save-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  border: 1px solid #D1433A !important;
  background-color: #ffffff;
  color: #D1433A;
  padding: 8px 12px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.upload-file-upload-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background-color: #D1433A;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.cancel-btn-display {
  display: inline-block;
  width: 130px;
}
.save-btn-display {
  display: inline-block;
  width: 150px;
  padding-right: 10px;
}
.upload-btn-display {
  display: inline-block;
  width: 130px;
}

.pg-song-artist-checkbox-card{
  border: none !important;
}


.pg-song-artist-checkbox-card .custom-control {
  width: 100px;
  margin-left: 21px;
  display: inline-block;
}

.pg-song-artist-checkbox-card .widget-item{
  display: inline-block !important;
  padding: 0;
}
.pg-song-artist-checkbox-card .pg-item-title{
  display: inline-block;
  position: relative;
  top: -3px;
  width: auto;
  color: #071526;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;

}
.upload-btn-margin{
  margin-left: 16px;
}
.upload-file-p-padding{
  padding: 0 19px 0 21px;
  background-color: #F9FAFB;
}

.upload-file-p-position{
  position: absolute;
  bottom: 0;
}
.Choose-File-Format-bottom-p{
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #8B949F;
}
.upload-file-cancel-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background: #e6eaf0;
  color: #47505b;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}
.basic-info-upload-btn {
  display: inline-block;
  margin-left: 12px;
  position: relative;
  top: -1px;
}
.song-heading-1{
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #071526;
  margin-bottom: 13px;
}
.label-style {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #47505B;
  opacity: 0.9;
}

</style>
<style lang="scss">
.download-custom-control{
  label {
    font-family: unset!important;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #071526;
    opacity: 0.9;
  }
}
.label-style {
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #47505B;
  }
}
.date-style {
  .btn {
    .bi-calendar {
      display: none!important;
    }
  }
}
</style>
<style>
.setting-form select.form-control {
  border: 1px solid #E6EAF0!important;
}
</style>
