<template>
  <div class="item d-flex align-items-center" @click="selectFriendToCreateGroup(friend)">
    <div class="img-wrapper">
      <div class="img-cover">
        <img :src="friend.artwork_url" alt=""/>
      </div>
    </div>
    <div class="item-title">{{friend.name}}</div>
    <div v-if="iconCheck" class="check-icon ml-auto">
      <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
              fill="#D1433A"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateModalGroupFriendBar",
  props: {
    friend: {
      type: Object,
      required: true
    },
    iconCheck: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectFriendToCreateGroup(friend) {
      this.$emit('selectFriend', friend)
    }
  }
};
</script>

<style scoped lang="scss">
.item {
  .img-wrapper {
    .img-cover {
      img {
        width: inherit;
      }
    }
  }

  .check-icon {
    display: unset!important;
  }
}
</style>
