<template>
  <div class="container-fluid m-t-50">
    <!-- <h6>Music Market Filter</h6> -->

    <div class="row filter-options open">
      <div class="col">
        <div class="filter-inner">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex flex-wrap align-items-center">
              <div class="dropdown bootstrap-select show-tick filters-select f-s">
                <select class="filters-select f-s selectpicker" title="Trending" multiple="" data-max-options="1"
                        dropupauto="false" tabindex="-98">
                  <option value="0">Latest</option>
                  <option value="1">Feautured</option>
                  <option value="2">Trending</option>
                  <option value="3">Top Selling</option>
                  <option value="4">High Rating</option>
                  <option value="5">Low Rating</option>
                </select>
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false"
                        title="Trending">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">Trending</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu" x-placement="bottom-start"
                     style="max-height: 126px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; transform: translate3d(0px, 30px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true"
                       style="max-height: 108px; overflow-y: auto; min-height: 100px;">
                    <ul class="dropdown-menu inner show" role="presentation"
                        style="margin-top: 0px; margin-bottom: 0px;">
                      <li><a role="option" class="dropdown-item" id="bs-select-1-0" tabindex="0" aria-selected="false"
                             aria-setsize="6" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Latest</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-1-1" tabindex="0" aria-selected="false"
                             aria-setsize="6" aria-posinset="2"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Feautured</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-1-2" tabindex="0" aria-selected="false"
                             aria-setsize="6" aria-posinset="3"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Trending</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-1-3" tabindex="0" aria-selected="false"
                             aria-setsize="6" aria-posinset="4"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Top Selling</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-1-4" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">High Rating</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-1-5" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Low Rating</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select class="filters-select f-s selectpicker" multiple="" data-max-options="1" title="Songs"
                      tabindex="-98">
                <option value="1">Songs</option>
                <option value="2">Albums</option>
                <option value="3">Remixes</option>
              </select>
              <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                      role="combobox" aria-owns="bs-select-2" aria-haspopup="listbox" aria-expanded="false"
                      title="Songs">
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">Songs</div>
                  </div>
                </div>
              </button>
              <ul class="dropdown-menu inner" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                <li><a role="option" class="dropdown-item" id="bs-select-2-0" tabindex="0" aria-selected="false"
                       aria-setsize="3" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                    class="text">Songs</span></a></li>
                <li><a role="option" class="dropdown-item" id="bs-select-2-1" tabindex="0" aria-selected="false"
                       aria-setsize="3" aria-posinset="2"><span class=" bs-ok-default check-mark"></span><span
                    class="text">Albums</span></a></li>
                <li><a role="option" class="dropdown-item" id="bs-select-2-2" tabindex="0" aria-selected="false"
                       aria-setsize="3" aria-posinset="3"><span class=" bs-ok-default check-mark"></span><span
                    class="text">Remixes</span></a></li>
              </ul>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select class="filters-select f-s selectpicker" title="Release period" multiple="" data-max-options="1"
                      tabindex="-98">
                <option value="1">Today</option>
                <option value="2">last 7 days</option>
                <option value="3">last 14 days</option>
                <option value="4">last 30 days</option>
                <option value="5">last 90 days</option>
              </select>
              <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                      role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false"
                      title="Release period">
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">Release period</div>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="max-height: 126px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; transform: translate3d(0px, 30px, 0px); top: 0px; left: 0px; will-change: transform;">
                <div class="inner show" role="listbox" id="bs-select-3" tabindex="-1" aria-multiselectable="true"
                     style="max-height: 108px; overflow-y: auto; min-height: 100px;">
                  <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                    <li><a role="option" class="dropdown-item" id="bs-select-3-0" tabindex="0" aria-selected="false"
                           aria-setsize="5" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Today</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-3-1" tabindex="0" aria-selected="false"
                           aria-setsize="5" aria-posinset="2"><span class=" bs-ok-default check-mark"></span><span
                        class="text">last 7 days</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-3-2" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 14 days</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-3-3" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 30 days</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-3-4" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 90 days</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div :class="['filters-select f-s for-active custom-border', openPriceRange ? 'open' : '']">
              <div class="price-range js-price-range" @click="openPriceRange = !openPriceRange">
                <div class="">Price Range / 150</div>
                <div class="svg-icon">
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1" opacity="0.7" d="M10.7695 1L6.09052 5.75638L0.999512 1"
                          stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <div class="price-dropdown">
                <div class="value-row">
                  <div class="min"><input type="text" value="1"></div>
                  <div class="max"><input type="text" value="50"></div>
                </div>
                <div id="price-range" class="noUi-target noUi-ltr noUi-horizontal">
                  <div class="noUi-base">
                    <div class="noUi-connects">
                      <div class="noUi-connect" style="transform: translate(0%, 0px) scale(1, 1);"></div>
                    </div>
                    <div class="noUi-origin" style="transform: translate(-1000%, 0px); z-index: 5;">
                      <div class="noUi-handle noUi-handle-lower" data-handle="0" tabindex="0" role="slider"
                           aria-orientation="horizontal" aria-valuemin="0.0" aria-valuemax="150.0" aria-valuenow="0.0"
                           aria-valuetext="0.00">
                        <div class="noUi-touch-area"></div>
                      </div>
                    </div>
                    <div class="noUi-origin" style="transform: translate(0%, 0px); z-index: 4;">
                      <div class="noUi-handle noUi-handle-upper" data-handle="1" tabindex="0" role="slider"
                           aria-orientation="horizontal" aria-valuemin="0.0" aria-valuemax="150.0" aria-valuenow="150.0"
                           aria-valuetext="150.00">
                        <div class="noUi-touch-area"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s" @click="toggleClass">
              <select class="filters-select f-s selectpicker" title="Global" multiple="" data-max-options="1"
                      tabindex="-98">
                <option value="1">Global</option>
                <option value="2">Australia</option>
                <option value="3">Brazil</option>
                <option value="4">Canada</option>
                <option value="5">France</option>
                <option value="6">Germany</option>
                <option value="7">Greece</option>
                <option value="8">Italy</option>
                <option value="9">Japan</option>
                <option value="10">Malaysia</option>
                <option value="11">Netherlands</option>
                <option value="12">Russian Federation</option>
                <option value="13">South Africa</option>
                <option value="14">Spain</option>
                <option value="15">Sweden</option>
                <option value="16">Thailand</option>
                <option value="17">United Kingdom</option>
                <option value="18">United States</option>
              </select>
              <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                      role="combobox" aria-owns="bs-select-4" aria-haspopup="listbox" aria-expanded="false"
                      title="Global">
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">Global</div>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="max-height: 126px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                <div class="inner show" role="listbox" id="bs-select-4" tabindex="-1" aria-multiselectable="true"
                     style="max-height: 108px; overflow-y: auto; min-height: 100px;">
                  <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                    <li><a role="option" class="dropdown-item" id="bs-select-4-0" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Global</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-1" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="2"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Australia</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-2" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="3"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Brazil</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-3" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="4"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Canada</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-4" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="5"><span class=" bs-ok-default check-mark"></span><span
                        class="text">France</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-5" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Germany</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-6" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="7"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Greece</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-7" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="8"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Italy</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-8" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="9"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Japan</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-9" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="10"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Malaysia</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-10" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="11"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Netherlands</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-11" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="12"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Russian Federation</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-12" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="13"><span class=" bs-ok-default check-mark"></span><span
                        class="text">South Africa</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-13" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="14"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Spain</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-14" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="15"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Sweden</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-15" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="16"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Thailand</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-16" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">United Kingdom</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-4-17" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">United States</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">

export default {
  data() {
    return {
      openPriceRange: false
    }
  },
  components: {},
  computed: {},
  methods: {
    toggleClass: function (e) {
      //    e.target.classList.toggle('open');
    }
  }
}
</script>
