var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container_xxl friendsWrapper"},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"row d-flex mb-40 align-items-stretch"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"friends-wrap bg-transparent width770"},[_c('nav',[_c('div',{staticClass:"nav pages-tbs",staticStyle:{"height":"unset"},attrs:{"id":"nav-tab","role":"tablist"}},[_c('a',{staticClass:"nav-item nav-link active",attrs:{"id":"nav-explore-tab","data-toggle":"tab","href":"#nav-explore","role":"tab","aria-controls":"nav-explore","aria-selected":"true"},on:{"click":function($event){return _vm.showFilter('chooseFilters', true)}}},[_vm._v("EXPLORE")]),_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"nav-friend-tab","data-toggle":"tab","href":"#nav-friend","role":"tab","aria-controls":"nav-friend","aria-selected":"false"},on:{"click":function($event){return _vm.showFilter('chooseFilters', false)}}},[_c('span',[_vm._v("FRIEND REQUESTS")]),(
                  _vm.$store.state.friends
                    .total_pending_friend_request_count > 0
                )?_c('span',{staticClass:"event_counting"},[_vm._v(_vm._s(_vm.$store.state.friends.total_pending_friend_request_count))]):_vm._e()]),_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"nav-sent-tab","data-toggle":"tab","href":"#nav-sent","role":"tab","aria-controls":"nav-sent","aria-selected":"false"},on:{"click":function($event){return _vm.showFilter('chooseFilters', false)}}},[_c('span',[_vm._v("SENT REQUESTS")]),(_vm.$store.state.friends.total_sent_request_count > 0)?_c('span',{staticClass:"event_counting"},[_vm._v(_vm._s(_vm.$store.state.friends.total_sent_request_count))]):_vm._e()]),_c('a',{staticClass:"nav-item nav-link",attrs:{"id":"nav-birthday-tab","data-toggle":"tab","href":"#nav-birthday","role":"tab","aria-controls":"nav-birthday","aria-selected":"false"},on:{"click":function($event){return _vm.showFilter('calenderFilters', false)}}},[_c('span',[_vm._v("BIRTHDAYS")]),(_vm.$store.state.friends.total_birthdays_count > 0)?_c('span',{staticClass:"event_counting"},[_vm._v(_vm._s(_vm.$store.state.friends.total_birthdays_count))]):_vm._e()])])]),_c('div',{staticClass:"tab-content bg-transparent",attrs:{"id":"nav-tabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"nav-explore","role":"tabpanel","aria-labelledby":"nav-explore-tab"}},[(
                _vm.$store.state.friends.friends_suggestion.length ||
                _vm.$store.state.friends.explore_friend_list.length ||
                _vm.$store.state.friends.filtered_friends_list.length
              )?_c('friend-suggestion'):_c('friend-card',{attrs:{"cardTitle":"You don't have any friends yet.","cardSubTitle":"Search city, School, Works places to find friend and connections.","cardIconType":"FriendsIcon"}})],1),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"nav-friend","role":"tabpanel","aria-labelledby":"nav-friend-tab"}},[(_vm.$store.state.friends.pending_friend_requests.length)?_c('friend-requests'):_c('friend-card',{attrs:{"cardTitle":"You don't have any friend requests yet.","cardSubTitle":"Search city, School, Works places to find friend and connections.","cardIconType":"FriendsIcon"}})],1),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"nav-sent","role":"tabpanel","aria-labelledby":"nav-sent-tab"}},[(_vm.$store.state.friends.sent_friend_requests.length)?_c('sent-friend-request'):_c('friend-card',{attrs:{"cardTitle":"You don't have any sent requests yet.","cardSubTitle":"Search city, School, Works places to find friend and connections.","cardIconType":"FriendsIcon"}})],1),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"nav-birthday","role":"tabpanel","aria-labelledby":"nav-birthday-tab"}},[(_vm.$store.state.friends.month_birthdays.length)?_c('friend-birthdays'):_c('friend-card',{attrs:{"cardTitle":"You don't have any birthday's from  friends yet.","cardSubTitle":"Search city, School, Works places to find friend and connections.","cardIconType":"FriendsIcon"}})],1)])])]),_c('friend-filters',{attrs:{"filter":_vm.showFilters,"buttonShow":_vm.showApplyButton}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h1',{staticClass:"pages-heading-title"},[_vm._v("Friends")])])])])
}]

export { render, staticRenderFns }