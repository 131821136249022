<template>
  <div class="container-fluid sale-items">
    <!-- <div class="row">
       <div class="col-sm-12">
          <h4 class="title-h4">Title</h4>
       </div>
    </div> -->
    <div class="row position-relative">
      <div class="col-sm-3 js-container-hover hover" v-for="(event) in eventList" :key="event.id">
        <div class="card slider">
          <div class="card-cover js-card-hover hover">
            <div class="cover square  "><img
                src=""
                alt=""></div>
            <div class="cover-hover">
              <div class="cover-mood js-cover-mood">
                <div class="mood" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  #Techno
                </div>
                <div class="dropdown-menu dropdown-menu-right dropdown-moods">
                  <div class="moods">
                    #Techno
                  </div>
                  <div class="moods">
                    #Techno
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-description events">
            <div class="pur-tag">
              <div class="svg-icon">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.56176 8.064C8.51188 8.064 10.0928 6.48267 10.0928 4.532C10.0928 2.58133 8.51188 1 6.56176 1C4.61164 1 3.03076 2.58133 3.03076 4.532C3.03076 6.48267 4.61164 8.064 6.56176 8.064Z"
                      stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path
                      d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                      stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              000
            </div>
            <div class="item-date">JULY 12</div>
            <div class="item-title">Event Name presents
              Nina Kraviz, Four Tet
            </div>

            <div :class="['custom-select-wrapper in-card', (event.openMenu) ? 'open' : '']">
              <!-- <div class="interested-select interested"> -->
              <!-- <div class="interested-select goind"> -->
              <!-- <div :class="['interested-select', (event.selectedOption == 'Going')? 'goind':'', (event.selectedOption == 'Interested')? 'interested':'', (event.selectedOption == 'Not Interested')? 'not-interested':'']" v-on:click="openOption(index)">

              </div> -->
              <div class="inter-going-btn">
                <button class="Interested_btn btn_act">Interested</button>
                <button class="Interested_btn_going">Going</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  data() {
    return {
      eventList: [{
        id: 1,
        name: 'qwe',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 2,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 3,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 4,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 5,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 6,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }]
    }
  },
  components: {},
  computed: {},
  methods: {
    openOption: function (index) {
      this.eventList[index].openMenu = true;
    },
    updateOption: function (index, value) {
      this.eventList[index].selectedOption = value;
      this.eventList[index].openMenu = false;
    }
  }
}
</script>
