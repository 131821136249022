<template>
    <div class="center_centerbar_wraper_home">
      <div class="row">
        <div class="col-sm-12">
          <h4 class="title-h4 selling_title" style="margin-top: 0px; margin-bottom: 20px;">Similar Artists {{(getSimilarArtistsTotal) ? getSimilarArtistsTotal : 0}}</h4>
        </div>
      </div>

      <div class="row position-relative">
        <div class="col-sm-3 js-container-hover" v-for="(similar_artists, index) in getSimilarArtists" :key="index">
          <div  class="card slider round artist">
            <div  class="card-cover js-card-hover">
              <div class="cover square round ">
                <img :src="(similar_artists.artwork_url) ? similar_artists.artwork_url : ''" alt="artist_image">
              </div>
            </div>
            <div class="card-description position-relative pb-10 round">
              <div class="title text-center text-truncate"> {{(similar_artists.title) ? similar_artists.title : 'Name'}} </div>
              <div class="name text-center text-truncate">
                {{ (similar_artists.country) ? similar_artists.country.name : '' }}
              </div>
              <div class="followers following-btn" v-if="similar_artists.follow_action === 'approved'" @click="followOrUnfollowPage(similar_artists.id, followStatus(similar_artists.follow_action)?0:1, $event)">
                <div class="text" style="margin-left: 2px !important; ">
                  <div class="svg-icon">
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.3346 1L5.0013 8.33333L1.66797 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  Following
                </div>
              </div>
              <div class="followers" style="justify-content: unset;" v-if="similar_artists.follow_action !== 'approved'" @click="followOrUnfollowPage(similar_artists.id, followStatus(similar_artists.follow_action)?0:1, $event)">
                <div class="text" style="margin-left: 8px;">
                  <div  class="svg-icon">
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z" fill="#D1433A" class="c-line-1 c-fill-1">
                      </path>
                      <path d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z" fill="#D1433A" class="c-line-1 c-fill-1">
                      </path>
                      <path d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z" fill="#D1433A" class="c-line-1 c-fill-1">
                      </path>
                    </svg>
                  </div>
                  Follow <span style="margin-left: 32px;">{{similar_artists.followers_count}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="show_more_btn" v-if="getSimilarArtistsTotal > getSimilarArtists.length">
          <div class="show-more musicbar_more">
            <a href="javascript:;" @click="showMore">
              <span class="show_more_bg">
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round">
                </path>
                <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round">
                </path>
              </svg>
              SHOW MORE ARTISTS
            </span>
            </a>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import {changeFollowStatus, followOrUnFollowPage} from "../../../../apis/APIs";
import {mapGetters} from "vuex";

export default {
  date() {
    return {}
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    getSimilarArtists() {
      return this.$store.getters.getPageSimilarArtistsList
    },
    getSimilarArtistsTotal() {
      return this.$store.getters.getPageSimilarArtistTotal
    }
  },
  mounted(){

  },
  methods:{
    showMore(){
      let payload = {
        showMore : true,
        page_id: this.$route.params.id,
      }
      this.$store.dispatch('getPageSimilarArtists', payload);
    },
    async setArtistFollowStatus(similar_artists,id, action, follow_status){
      try {
        await this.$store.dispatch('loading', true)
        let obj = {
          id : id,
          object_type : 'user',
          action : action
        }
        const {data} = await changeFollowStatus(obj)
        await this.$store.dispatch('loading', false)
        if(data.success){
          await this.makeToast('success', 'Artist', `Successfully ` + follow_status)
          similar_artists.follow_status = action;
        }
        else{
          await this.makeToast('error', 'Page', data.message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.makeToast('error', 'Page', e.message)
      }
    },
    async followOrUnfollowPage(page_id,status,event) {
      if(event) event.stopPropagation()
      try {
        await this.$store.dispatch('loading', true)
        const formdata = new FormData();
        formdata.append('user_id', this.user.user_data.id);
        formdata.append('page_id', page_id);
        formdata.append('type', 'Page');
        formdata.append('action', status);
        const {data} = await followOrUnFollowPage(formdata)
        await this.$store.dispatch('loading', false)
        if(data.success){
          const payload = {
            showMore : false,
            page_id : this.$route.params.id,
          }
          await this.$store.dispatch('getPageSimilarArtists', payload)
          await this.makeToast('success', 'Page', `successfully ${(status === 1) ? 'Follow' : 'UnFollow'}`)
        }
        else{
          await this.makeToast('error', 'Page', data.message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.makeToast('error', 'Page', e.message)
      }
    },
    followStatus(follow_action){
      if(follow_action){
        return follow_action
      }
      return !!(follow_action)
    },
  }
}
</script>
