<template>
  <div v-if="post" class="card border-0"  style="background-color: #f5f6f8">
    <div class="card-img-top poll-container mt-1" v-if="post_type === 'coloured'">
      <div :class="postContainPredefinedClass"
           :style="{ background: ((!postHasPredefinedClass)?postContainPredefinedClass+' !important':'') }"
           class="news-feed-pic-detials-posts">

        <h1>{{
            (post && post.short_content) ? post.short_content : ''
          }}</h1>
      </div>
    </div>
    <div class="card-img-top poll-container px-2 py-2" v-if="post_type === 'poll'">
      <VoteItem v-for="(eachOp, index) in poll_options"
                :key="'poll_option_'+index"
                :option="eachOp" :post="post"
      />
    </div>


    <!-- Shared Post is Image or Video -->
    <div class="card-img-top">
      <div v-if="post.category === 'video'">
        <div v-for="(video, index) in post.artwork_url" :key="index" class="for_video">
          <video style="border-radiua: 40px" class="p-1" :src="video.original_url" controls />
        </div>
      </div>
      <img v-else-if="postImage" class="card-img-top float-left p-1" style="border-radius: 10px" :src="postImage" alt="Card image cap" />
    </div>
    <div class="card-img-top" v-if="post.category==='share_profile'">
      <div class="card-text"><a :href="post.post_type_description">{{post.post_type_description}}</a></div>
      <img class="card-img-top float-left p-1" style="border-radius: 10px" :src="post.shareable_item.cover_photo_url" alt="Card image cap" />
    </div>
    <div class="card-body" v-show="anyContentAvailableInPost">
      <div class="card-text">
        <div class="row" v-if="!sameUser">
          <div class="col-2 p-0">
             <span class="news-feed-pic">
               <UserPicture :size="45" :picture="(post && post.user && post.user.artwork_url) ? post.user.artwork_url : ''" @click="redirectToProfile(post.user.username)"/>
               <span class="online-signal" style="right: 18px!important; bottom: 0;"></span>
             </span>
          </div>
          <div class="col-9 pl-0">
            <div class="pt-2">
              <span class="username"  @click="redirectToProfile(post.user.username)">{{ (post && post.user_name) ? post.user_name : 'User Name' }} </span>
            </div>
            <div>
              <span>{{ time }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-text my-2" v-if="isPostIsActivity" @click="openSharedPost">
        <div class="feeling-post" >
          <p class="feelings-text mx-auto">
            <span v-html="getFullContent"></span>
            <span style="margin-left: 20px;" v-if="getEmoji(post.post_type_description)">
              <img width="60" :src="getEmoji(post.post_type_description)"/>
            </span>
          </p>
        </div>
      </div>
      <div class="my-2" v-else-if="post_type === 'article'" @click="openSharedPost">
        <div class="card-title article-title">
          {{
            (post.title) ? post.title : ""
          }}
        </div>
        <div class="card-text" v-html="shorterContent(getFullContent)"/>
      </div>
      <p class="card-text my-2" @click="openSharedPost" v-else v-html="getFullContent"></p>
    </div>
  </div>
</template>

<script>
import UserPicture from '../components/UserPicture';
import VoteItem from './VoteItem';
import moment from "moment-timezone";

export default {
  name: "sharedPost",
  props: {
    post: {
      type: Object,
      default: () => {}
    },
    sameUser: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserPicture,
    VoteItem
  },
  computed: {
    postImage() {
      if (this.post && this.post.artwork_url.length > 0 && this.post.artwork_url ) {
        return this.post.artwork_url[0].artwork_url
      }
      return false
    },
    activities() {
      return this.$store.getters.getPostsActivities
    },
    isPostIsActivity() {
      return !!(this.activities.some(e => e.title === this.post_type))
    },
    post_type() {
      const {post_type} = this.post || {};
      return post_type;
    },
    time() {
      const {created_at} = this.post || {};
      return moment(created_at).fromNow();
    },
    poll_options() {
      const {options} = this.post;
      return options && options.length ? options : [];
    },
    anyContentAvailableInPost() {
      let available = false
      if (this.post && (this.post.full_content || this.post.title || (this.post.post_type_description && this.isPostIsActivity))) {
        available = true
      }
      return available
    },
    getFullContent() {
      let content = ''
      // regular expression which remove a html elements <a> tag from full content
        if( !(this.post.full_content === "null" ||  !this.post.full_content) ) {
          content = this.post.full_content.replace(/<a[^>]*>.*?<\/a>/gi, '')
        }
        else {
          content = ''
        }
      return content;
    },
    postContainPredefinedClass() {
      if (this.post.post_type === 'coloured') {
        return this.post.post_type_description
      }
      return ' '
    },
    postHasPredefinedClass() {
      if (this.post.post_type === 'coloured') {
        return !(this.post.post_type_description.includes('#'))
      }
      return false
    },
  },
  methods: {
    getEmoji(feeling) {
      const activityIndex = this.activities.findIndex(e => e.title === 'Feeling')
      if (activityIndex !== null) {
        if (this.activities[activityIndex].emoticons) {
          const feelingIndex = this.activities[activityIndex].emoticons.findIndex(ele => ele.title === feeling)
          if (feelingIndex !== -1) {
            return (this.activities[activityIndex].emoticons[feelingIndex]) ? this.activities[activityIndex].emoticons[feelingIndex].artwork_url : false
          }

        }
      }
      return false
    },
    shorterContent(value) {
      return value.slice(0, 150)
    },
    openSharedPost() {
      this.$emit('openSharedPost')
    },
    redirectToProfile(username) {
      this.$router.push('/home/page/user-profile/' + username)
    },
  }
}
</script>

<style scoped lang="scss">
.username {
  color: #071526;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.feeling-post {
  .feelings-text {
    left: calc(50% - 130px / 2 - 535px);
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #D1433A;
  }

}

.poll-container {
  margin-top: -10px;
}

.card-image {
  max-height: 185px !important;
}

.online-signal {
  right: 3px !important;
}

.article-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #47505B !important;
}
</style>
