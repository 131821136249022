<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade login-type show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup">
            <div class="choose-billing-method-header">
              <h1>Choose Billing Method to Add</h1>
              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="svg-color" d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="svg-color" d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">
              <div class="billing-content">
                <div class="row ">
                  <div class="col-12">
                    <div class="credit-card" v-on:click="openCreditCard('debit/credit-card')">
                      <a href="javascript:;">
                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M14.1784 1.39453H2.7239C2.02099 1.39453 1.45117 1.95418 1.45117 2.64453V10.1445C1.45117 10.8349 2.02099 11.3945 2.7239 11.3945H14.1784C14.8814 11.3945 15.4512 10.8349 15.4512 10.1445V2.64453C15.4512 1.95418 14.8814 1.39453 14.1784 1.39453Z"
                              stroke="#6540CE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M1.45117 5.14453H15.4512" stroke="#6540CE" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                        <span>Credit Card</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 p-r ">
                    <div class="credit-card mb-0" v-on:click="openPayPal()">
                      <a href="javascript:;">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M11.9196 1.20647C11.1547 0.36203 9.77197 0 8.00311 0H2.86926C2.50736 0 2.19983 0.254926 2.14317 0.60058L0.00554922 13.7315C-0.0369472 13.9904 0.170052 14.2249 0.441024 14.2249H3.61044L4.40645 9.33488L4.38177 9.48801C4.43843 9.14236 4.74368 8.88743 5.10513 8.88743H6.61124C9.57 8.88743 11.8867 7.72345 12.5635 4.35631C12.5836 4.25673 12.601 4.1598 12.616 4.06509C12.5306 4.02127 12.5306 4.02127 12.616 4.06509C12.8175 2.82056 12.6146 1.97346 11.9196 1.20647Z"
                              fill="#003087"></path>
                          <path
                              d="M11.9196 1.20647C11.1547 0.36203 9.77197 0 8.00311 0H2.86926C2.50736 0 2.19983 0.254926 2.14317 0.60058L0.00554922 13.7315C-0.0369472 13.9904 0.170052 14.2249 0.441024 14.2249H3.61044L4.40645 9.33488L4.38177 9.48801C4.43843 9.14236 4.74368 8.88743 5.10513 8.88743H6.61124C9.57 8.88743 11.8867 7.72345 12.5635 4.35631C12.5836 4.25673 12.601 4.1598 12.616 4.06509C12.5306 4.02127 12.5306 4.02127 12.616 4.06509C12.8175 2.82056 12.6146 1.97346 11.9196 1.20647Z"
                              fill="#003087"></path>
                          <path
                              d="M5.26186 4.08195C5.29567 3.87394 5.43367 3.70355 5.6192 3.61724C5.70373 3.5783 5.79786 3.55661 5.89656 3.55661H9.92139C10.398 3.55661 10.8426 3.58671 11.2488 3.65C11.3654 3.66812 11.4782 3.68894 11.5879 3.71284C11.6976 3.73628 11.804 3.76285 11.9073 3.79206C11.9589 3.80665 12.0097 3.82173 12.0595 3.83765C12.2592 3.90227 12.4451 3.9775 12.616 4.06558C12.8175 2.8206 12.6146 1.97395 11.9196 1.20696C11.1542 0.362518 9.77197 0.000488281 8.00311 0.000488281H2.86881C2.50736 0.000488281 2.19983 0.255414 2.14317 0.601068L0.00554953 13.7315C-0.0369469 13.9909 0.170052 14.225 0.440567 14.225H3.61044L4.40645 9.33492L5.26186 4.08195Z"
                              fill="#002F86"></path>
                          <path
                              d="M12.616 4.06494C12.6005 4.1601 12.5836 4.25658 12.5635 4.35616C11.8867 7.72286 9.56997 8.88728 6.61122 8.88728H5.10465C4.7432 8.88728 4.43796 9.14221 4.38175 9.48786L3.61042 14.2243L3.39154 15.568C3.35452 15.7946 3.53548 16 3.77218 16H6.44397C6.76018 16 7.02933 15.7769 7.07868 15.4746L7.10474 15.3427L7.6083 12.2518L7.64076 12.0809C7.69011 11.7787 7.95926 11.5556 8.27547 11.5556H8.6753C11.2635 11.5556 13.2901 10.5372 13.8823 7.59185C14.1295 6.36104 14.0015 5.33337 13.3476 4.61153C13.1493 4.39289 12.903 4.21232 12.616 4.06494"
                              fill="#009CDE"></path>
                          <path
                              d="M11.9074 3.7916C11.8041 3.76239 11.6976 3.73584 11.588 3.71238C11.4783 3.68891 11.365 3.66812 11.2489 3.64998C10.8422 3.58625 10.3981 3.55615 9.921 3.55615H5.89663C5.79747 3.55615 5.70334 3.57786 5.61926 3.61723C5.43328 3.70353 5.29574 3.87348 5.26192 4.08194L4.40651 9.33491L4.38184 9.48804C4.43804 9.14239 4.74328 8.88746 5.10473 8.88746H6.6113C9.57006 8.88746 11.8868 7.72348 12.5635 4.35634C12.5837 4.25676 12.6006 4.16027 12.6161 4.06512C12.4447 3.97749 12.2592 3.90181 12.0595 3.83763C12.0097 3.8217 11.959 3.80619 11.9074 3.7916Z"
                              fill="#012069"></path>
                        </svg>
                        <span>PayPal</span>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6 p-l ">
                    <div class="credit-card mb-0" v-on:click="openCreditCard('stripe')">
                      <a href="javascript:;">
                        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M4.7811 4.76483C4.7811 4.07912 5.38583 3.81539 6.3874 3.81539C7.82362 3.81539 9.63779 4.21978 11.074 4.94066V0.808791C9.50551 0.228571 7.95591 0 6.3874 0C2.55118 0 0 1.86374 0 4.97582C0 9.82857 7.1811 9.05494 7.1811 11.1473C7.1811 11.956 6.4252 12.2198 5.36693 12.2198C3.79843 12.2198 1.79528 11.622 0.207874 10.8132V14.9978C1.96535 15.7011 3.74173 16 5.36693 16C9.29764 16 12 14.189 12 11.0418C11.9811 5.8022 4.7811 6.73407 4.7811 4.76483Z"
                                fill="#6772E5"></path>
                        </svg>
                        <span>Stripe</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  methods: {
    closeModal: function () {
      this.$parent.closeWindow();
    },
    openCreditCard: function (type) {
      this.$parent.change(type);
      this.$emit('change', {type})
    },
    openPayPal: function () {
      this.$parent.paypal();
      this.$emit('paypal', {x: 'v1', y: 'v2', z: 'v3'})

    }
  }
}
</script>
