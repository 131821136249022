<template>
  <div class="container_xxl">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h1 class="pages-heading-title">PAGES</h1>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex mb-40 align-items-stretch">
          <div class="col-8">
            <div class="friends-wrap bg-transparent">
              <nav>
                <div class="nav pages-tbs" id="nav-tab" role="tablist">
                  <a
                    v-on:click="showRightBar = 'discover_liked'"
                    class="nav-item nav-link active"
                    id="nav-explore-tab"
                    data-toggle="tab"
                    href="#nav-explore"
                    role="tab"
                    aria-controls="nav-explore"
                    aria-selected="true"
                    >DISCOVER PAGES</a>
                  <a
                    v-on:click="showRightBar = 'discover_liked'"
                    class="nav-item nav-link"
                    id="nav-friend-tab"
                    data-toggle="tab"
                    href="#nav-friend"
                    role="tab"
                    aria-controls="nav-friend"
                    aria-selected="false"
                    >LIKED PAGES
                  </a>
                  <a
                    v-on:click="showRightBar = 'my_pages'"
                    class="nav-item nav-link"
                    id="nav-sent-tab"
                    data-toggle="tab"
                    href="#nav-sent"
                    role="tab"
                    aria-controls="nav-sent"
                    aria-selected="false"
                    >MY PAGES</a
                  >
                </div>
              </nav>
              <div
                class="tab-content bg-transparent p-t-30"
                id="nav-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="nav-explore"
                  role="tabpanel"
                  aria-labelledby="nav-explore-tab"
                >
                  <div class="row">
                    <div class="col-sm-12"></div>
                  </div>
                  <div class="row position-relative">
                    <div
                      class="col-sm-3 js-container-hover"
                      v-for="n in 12"
                      :key="n"
                    >
                      <div class="card slider round artist">
                        <div class="card-cover js-card-hover">
                          <div class="cover square round">
                            <img :src="getImage({})" alt="" />
                          </div>
                        </div>
                        <div
                          class="card-description position-relative pb-10 round"
                        >
                          <div class="circle-blue">
                            <div class="svg-icon">
                              <svg
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 2.81104L2.75 4.52698L5.66101 1"
                                  stroke="white"
                                  stroke-width="1.3"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div class="title text-center">Artist Name</div>
                          <div class="name text-center">#Singer, Producer</div>
                          <div class="cover-rating">
                            <div class="svg-icon">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="svg-star"
                              >
                                <path
                                  d="M5.90646 8.64551C5.90288 8.64279 5.89791 8.6428 5.89434 8.64553L2.84276 10.981C2.83503 10.9869 2.82426 10.9794 2.82713 10.9701L3.99444 7.19061C3.99567 7.18661 3.99428 7.18226 3.99096 7.17972L0.923852 4.83399C0.916256 4.82818 0.920364 4.81605 0.929927 4.81605H4.7135C4.71789 4.81605 4.72176 4.81319 4.72306 4.809L5.88996 1.03083C5.89286 1.02144 5.90615 1.02143 5.90907 1.03081L7.08406 4.80902C7.08536 4.8132 7.08923 4.81605 7.09361 4.81605H10.8709C10.8804 4.81605 10.8845 4.82818 10.8769 4.83399L7.82342 7.17091C7.8201 7.17346 7.81871 7.1778 7.81994 7.18181L8.98731 10.9615C8.99018 10.9708 8.97945 10.9783 8.97171 10.9724L5.90646 8.64551Z"
                                  fill="white"
                                  stroke="white"
                                  stroke-width="1.3"
                                ></path>
                              </svg>
                            </div>
                            <div class="rating">5.0</div>
                          </div>
                          <div class="followers">
                            <div class="text">
                              <div class="svg-icon">
                                <svg
                                  width="14"
                                  height="15"
                                  viewBox="0 0 14 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    class="c-line-1 c-fill-1"
                                    d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                                    fill="#D1433A"
                                  ></path>
                                  <path
                                    class="c-line-1 c-fill-1"
                                    d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                                    fill="#D1433A"
                                  ></path>
                                  <path
                                    class="c-line-1 c-fill-1"
                                    d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                                    fill="#D1433A"
                                  ></path>
                                </svg>
                              </div>
                              Follow 873.7k
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="show-more musicbar_more">
                    <a href="javascript:;">
                      <span class="show_more_bg">
                        <svg
                          width="9"
                          height="8"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.24219 0.689941V7.01994"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M1 3.85498H7.48702"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        SHOW 10 MORE PAGES
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-friend"
                  role="tabpanel"
                  aria-labelledby="nav-friend-tab"
                >
                  <div class="row">
                    <div class="col-sm-12"></div>
                  </div>
                  <div class="row position-relative">
                    <div
                      class="col-sm-3 js-container-hover"
                      v-for="n in 12"
                      :key="n"
                    >
                      <div class="card slider round artist">
                        <div class="card-cover js-card-hover">
                          <div class="cover square round">
                            <img :src="getImage({})" alt="" />
                          </div>
                        </div>
                        <div
                          class="card-description position-relative pb-10 round"
                        >
                          <div class="circle-blue">
                            <div class="svg-icon">
                              <svg
                                width="7"
                                height="6"
                                viewBox="0 0 7 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 2.81104L2.75 4.52698L5.66101 1"
                                  stroke="white"
                                  stroke-width="1.3"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div class="title text-center">Artist Name</div>
                          <div class="name text-center">#Singer, Producer</div>
                          <div class="cover-rating">
                            <div class="svg-icon">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="svg-star"
                              >
                                <path
                                  d="M5.90646 8.64551C5.90288 8.64279 5.89791 8.6428 5.89434 8.64553L2.84276 10.981C2.83503 10.9869 2.82426 10.9794 2.82713 10.9701L3.99444 7.19061C3.99567 7.18661 3.99428 7.18226 3.99096 7.17972L0.923852 4.83399C0.916256 4.82818 0.920364 4.81605 0.929927 4.81605H4.7135C4.71789 4.81605 4.72176 4.81319 4.72306 4.809L5.88996 1.03083C5.89286 1.02144 5.90615 1.02143 5.90907 1.03081L7.08406 4.80902C7.08536 4.8132 7.08923 4.81605 7.09361 4.81605H10.8709C10.8804 4.81605 10.8845 4.82818 10.8769 4.83399L7.82342 7.17091C7.8201 7.17346 7.81871 7.1778 7.81994 7.18181L8.98731 10.9615C8.99018 10.9708 8.97945 10.9783 8.97171 10.9724L5.90646 8.64551Z"
                                  fill="white"
                                  stroke="white"
                                  stroke-width="1.3"
                                ></path>
                              </svg>
                            </div>
                            <div class="rating">5.0</div>
                          </div>
                          <div class="followers following-btn">
                            <div class="text">
                              <div class="svg-icon">
                                <svg
                                  width="14"
                                  height="10"
                                  viewBox="0 0 14 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.3346 1L5.0013 8.33333L1.66797 5"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              Following
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="show-more musicbar_more">
                    <a href="javascript:;">
                      <span class="show_more_bg">
                        <svg
                          width="9"
                          height="8"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.24219 0.689941V7.01994"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M1 3.85498H7.48702"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        SHOW 10 MORE PAGES
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-sent"
                  role="tabpanel"
                  aria-labelledby="nav-sent-tab"
                >
                  <div class="row">
                    <div class="col-sm-12"></div>
                  </div>
                  <div class="row position-relative">
                    <div class="col">
                      <div class="my-pages-section">
                        <div class="my-pages-section-left">
                          <div class="my-pages-section-img-wrapper">
                            <div
                              class="my-pages-section-img-wrapper-image-cover"
                            >
                              <img
                                src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/jobs1.png"
                                alt=""
                              />
                            </div>
                            <div class="my-pages-check-ico">
                              <div class="svg-icon">
                                <svg
                                  width="10"
                                  height="8"
                                  viewBox="0 0 10 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 1L3.5 6.5L1 4"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="my-pages-section-right">
                          <div class="my-pages-section-title">
                            The Walt Disnep
                          </div>
                          <div class="my-pages-section-details">
                            <div class="my-pages-section-user">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 11.0967V10.0967C10 9.56625 9.78929 9.05754 9.41421 8.68247C9.03914 8.30739 8.53043 8.09668 8 8.09668H4C3.46957 8.09668 2.96086 8.30739 2.58579 8.68247C2.21071 9.05754 2 9.56625 2 10.0967V11.0967"
                                  stroke="#47505B"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M6 6.09668C7.10457 6.09668 8 5.20125 8 4.09668C8 2.99211 7.10457 2.09668 6 2.09668C4.89543 2.09668 4 2.99211 4 4.09668C4 5.20125 4.89543 6.09668 6 6.09668Z"
                                  stroke="#47505B"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                              <p>
                                324k
                                <color>(+12k/week)</color>
                              </p>
                            </div>
                            <div class="my-pages-section-user">
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M1.11719 6.06543C1.11719 6.06543 3.11719 2.06543 6.61719 2.06543C10.1172 2.06543 12.1172 6.06543 12.1172 6.06543C12.1172 6.06543 10.1172 10.0654 6.61719 10.0654C3.11719 10.0654 1.11719 6.06543 1.11719 6.06543Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M6.61719 7.56543C7.44561 7.56543 8.11719 6.89386 8.11719 6.06543C8.11719 5.237 7.44561 4.56543 6.61719 4.56543C5.78876 4.56543 5.11719 5.237 5.11719 6.06543C5.11719 6.89386 5.78876 7.56543 6.61719 7.56543Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.617188 0.0654297)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                525k
                                <color>(+20k/week)</color>
                              </p>
                            </div>
                            <div class="my-pages-section-user">
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M4.39453 11H2.89453C2.62931 11 2.37496 10.8946 2.18742 10.7071C1.99989 10.5196 1.89453 10.2652 1.89453 10V6.5C1.89453 6.23478 1.99989 5.98043 2.18742 5.79289C2.37496 5.60536 2.62931 5.5 2.89453 5.5H4.39453M7.89453 4.5V2.5C7.89453 2.10218 7.7365 1.72064 7.45519 1.43934C7.17389 1.15804 6.79236 1 6.39453 1L4.39453 5.5V11H10.0345C10.2757 11.0027 10.5097 10.9182 10.6935 10.762C10.8772 10.6058 10.9984 10.3885 11.0345 10.15L11.7245 5.65C11.7463 5.50668 11.7366 5.36034 11.6962 5.22113C11.6558 5.08191 11.5856 4.95315 11.4905 4.84376C11.3953 4.73437 11.2776 4.64697 11.1453 4.58761C11.0131 4.52825 10.8695 4.49836 10.7245 4.5H7.89453Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.894531)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                30k
                                <color>(+3k/week)</color>
                              </p>
                            </div>
                          </div>
                          <div class="my-pages-buttons-section">
                            <div class="my-pages-buttons-left-section">
                              <button class="my-pages-buttons-left-msg">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.75 4.79168C8.75143 5.34162 8.62294 5.88413 8.375 6.37501C8.08101 6.96324 7.62906 7.458 7.06977 7.80388C6.51048 8.14976 5.86593 8.33309 5.20833 8.33334C4.65839 8.33478 4.11588 8.20629 3.625 7.95834L1.25 8.75001L2.04167 6.37501C1.79372 5.88413 1.66523 5.34162 1.66667 4.79168C1.66692 4.13408 1.85025 3.48953 2.19613 2.93024C2.54201 2.37095 3.03677 1.919 3.625 1.62501C4.11588 1.37707 4.65839 1.24858 5.20833 1.25001H5.41667C6.28514 1.29793 7.10543 1.6645 7.72047 2.27954C8.33552 2.89458 8.70209 3.71487 8.75 4.58334V4.79168Z"
                                    fill="#D1433A"
                                  ></path>
                                </svg>
                                10 Messages
                              </button>
                              <button class="my-pages-buttons-left-notifi">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 3.33301C7.5 2.66997 7.23661 2.03408 6.76777 1.56524C6.29893 1.0964 5.66304 0.833008 5 0.833008C4.33696 0.833008 3.70107 1.0964 3.23223 1.56524C2.76339 2.03408 2.5 2.66997 2.5 3.33301C2.5 6.24967 1.25 7.08301 1.25 7.08301H8.75C8.75 7.08301 7.5 6.24967 7.5 3.33301Z"
                                    fill="#D1433A"
                                  ></path>
                                  <path
                                    d="M5.72292 8.75C5.64966 8.87628 5.54452 8.9811 5.41801 9.05397C5.2915 9.12684 5.14807 9.16519 5.00208 9.16519C4.85609 9.16519 4.71266 9.12684 4.58616 9.05397C4.45965 8.9811 4.3545 8.87628 4.28125 8.75"
                                    stroke="#D1433A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                13 Notifications
                              </button>
                            </div>
                            <div class="my-pages-buttons-right-section">
                              <button class="create-posts">CREATE POST</button>
                              <button class="create-promote">PROMOTE</button>
                              <button class="my-pages-buttons-more">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.9987 8.66634C8.36689 8.66634 8.66536 8.36786 8.66536 7.99967C8.66536 7.63148 8.36689 7.33301 7.9987 7.33301C7.63051 7.33301 7.33203 7.63148 7.33203 7.99967C7.33203 8.36786 7.63051 8.66634 7.9987 8.66634Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M7.9987 4.00033C8.36689 4.00033 8.66536 3.70185 8.66536 3.33366C8.66536 2.96547 8.36689 2.66699 7.9987 2.66699C7.63051 2.66699 7.33203 2.96547 7.33203 3.33366C7.33203 3.70185 7.63051 4.00033 7.9987 4.00033Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="my-pages-section">
                        <div class="my-pages-section-left">
                          <div class="my-pages-section-img-wrapper">
                            <div
                              class="my-pages-section-img-wrapper-image-cover"
                            >
                              <img
                                src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/jobs1.png"
                                alt=""
                              />
                            </div>
                            <div class="my-pages-check-ico">
                              <div class="svg-icon">
                                <svg
                                  width="10"
                                  height="8"
                                  viewBox="0 0 10 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 1L3.5 6.5L1 4"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="my-pages-section-right">
                          <div class="my-pages-section-title">
                            The Walt Disnep
                          </div>
                          <div class="my-pages-section-details">
                            <div class="my-pages-section-user">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 11.0967V10.0967C10 9.56625 9.78929 9.05754 9.41421 8.68247C9.03914 8.30739 8.53043 8.09668 8 8.09668H4C3.46957 8.09668 2.96086 8.30739 2.58579 8.68247C2.21071 9.05754 2 9.56625 2 10.0967V11.0967"
                                  stroke="#47505B"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M6 6.09668C7.10457 6.09668 8 5.20125 8 4.09668C8 2.99211 7.10457 2.09668 6 2.09668C4.89543 2.09668 4 2.99211 4 4.09668C4 5.20125 4.89543 6.09668 6 6.09668Z"
                                  stroke="#47505B"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                              <p>
                                324k
                                <color>(+12k/week)</color>
                              </p>
                            </div>
                            <div class="my-pages-section-user">
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M1.11719 6.06543C1.11719 6.06543 3.11719 2.06543 6.61719 2.06543C10.1172 2.06543 12.1172 6.06543 12.1172 6.06543C12.1172 6.06543 10.1172 10.0654 6.61719 10.0654C3.11719 10.0654 1.11719 6.06543 1.11719 6.06543Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M6.61719 7.56543C7.44561 7.56543 8.11719 6.89386 8.11719 6.06543C8.11719 5.237 7.44561 4.56543 6.61719 4.56543C5.78876 4.56543 5.11719 5.237 5.11719 6.06543C5.11719 6.89386 5.78876 7.56543 6.61719 7.56543Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.617188 0.0654297)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                525k
                                <color>(+20k/week)</color>
                              </p>
                            </div>
                            <div class="my-pages-section-user">
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M4.39453 11H2.89453C2.62931 11 2.37496 10.8946 2.18742 10.7071C1.99989 10.5196 1.89453 10.2652 1.89453 10V6.5C1.89453 6.23478 1.99989 5.98043 2.18742 5.79289C2.37496 5.60536 2.62931 5.5 2.89453 5.5H4.39453M7.89453 4.5V2.5C7.89453 2.10218 7.7365 1.72064 7.45519 1.43934C7.17389 1.15804 6.79236 1 6.39453 1L4.39453 5.5V11H10.0345C10.2757 11.0027 10.5097 10.9182 10.6935 10.762C10.8772 10.6058 10.9984 10.3885 11.0345 10.15L11.7245 5.65C11.7463 5.50668 11.7366 5.36034 11.6962 5.22113C11.6558 5.08191 11.5856 4.95315 11.4905 4.84376C11.3953 4.73437 11.2776 4.64697 11.1453 4.58761C11.0131 4.52825 10.8695 4.49836 10.7245 4.5H7.89453Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.894531)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                30k
                                <color>(+3k/week)</color>
                              </p>
                            </div>
                          </div>
                          <div class="my-pages-buttons-section">
                            <div class="my-pages-buttons-left-section">
                              <button class="my-pages-buttons-left-msg">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.75 4.79168C8.75143 5.34162 8.62294 5.88413 8.375 6.37501C8.08101 6.96324 7.62906 7.458 7.06977 7.80388C6.51048 8.14976 5.86593 8.33309 5.20833 8.33334C4.65839 8.33478 4.11588 8.20629 3.625 7.95834L1.25 8.75001L2.04167 6.37501C1.79372 5.88413 1.66523 5.34162 1.66667 4.79168C1.66692 4.13408 1.85025 3.48953 2.19613 2.93024C2.54201 2.37095 3.03677 1.919 3.625 1.62501C4.11588 1.37707 4.65839 1.24858 5.20833 1.25001H5.41667C6.28514 1.29793 7.10543 1.6645 7.72047 2.27954C8.33552 2.89458 8.70209 3.71487 8.75 4.58334V4.79168Z"
                                    fill="#D1433A"
                                  ></path>
                                </svg>
                                10 Messages
                              </button>
                              <button class="my-pages-buttons-left-notifi">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 3.33301C7.5 2.66997 7.23661 2.03408 6.76777 1.56524C6.29893 1.0964 5.66304 0.833008 5 0.833008C4.33696 0.833008 3.70107 1.0964 3.23223 1.56524C2.76339 2.03408 2.5 2.66997 2.5 3.33301C2.5 6.24967 1.25 7.08301 1.25 7.08301H8.75C8.75 7.08301 7.5 6.24967 7.5 3.33301Z"
                                    fill="#D1433A"
                                  ></path>
                                  <path
                                    d="M5.72292 8.75C5.64966 8.87628 5.54452 8.9811 5.41801 9.05397C5.2915 9.12684 5.14807 9.16519 5.00208 9.16519C4.85609 9.16519 4.71266 9.12684 4.58616 9.05397C4.45965 8.9811 4.3545 8.87628 4.28125 8.75"
                                    stroke="#D1433A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                13 Notifications
                              </button>
                            </div>
                            <div class="my-pages-buttons-right-section">
                              <button class="create-posts">CREATE POST</button>
                              <button class="create-promote">PROMOTE</button>
                              <button class="my-pages-buttons-more">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.9987 8.66634C8.36689 8.66634 8.66536 8.36786 8.66536 7.99967C8.66536 7.63148 8.36689 7.33301 7.9987 7.33301C7.63051 7.33301 7.33203 7.63148 7.33203 7.99967C7.33203 8.36786 7.63051 8.66634 7.9987 8.66634Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M7.9987 4.00033C8.36689 4.00033 8.66536 3.70185 8.66536 3.33366C8.66536 2.96547 8.36689 2.66699 7.9987 2.66699C7.63051 2.66699 7.33203 2.96547 7.33203 3.33366C7.33203 3.70185 7.63051 4.00033 7.9987 4.00033Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="my-pages-section">
                        <div class="my-pages-section-left">
                          <div class="my-pages-section-img-wrapper">
                            <div
                              class="my-pages-section-img-wrapper-image-cover"
                            >
                              <img
                                src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/jobs1.png"
                                alt=""
                              />
                            </div>
                            <div class="my-pages-check-ico">
                              <div class="svg-icon">
                                <svg
                                  width="10"
                                  height="8"
                                  viewBox="0 0 10 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 1L3.5 6.5L1 4"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="my-pages-section-right">
                          <div class="my-pages-section-title">
                            The Walt Disnep
                          </div>
                          <div class="my-pages-section-details">
                            <div class="my-pages-section-user">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 11.0967V10.0967C10 9.56625 9.78929 9.05754 9.41421 8.68247C9.03914 8.30739 8.53043 8.09668 8 8.09668H4C3.46957 8.09668 2.96086 8.30739 2.58579 8.68247C2.21071 9.05754 2 9.56625 2 10.0967V11.0967"
                                  stroke="#47505B"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M6 6.09668C7.10457 6.09668 8 5.20125 8 4.09668C8 2.99211 7.10457 2.09668 6 2.09668C4.89543 2.09668 4 2.99211 4 4.09668C4 5.20125 4.89543 6.09668 6 6.09668Z"
                                  stroke="#47505B"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                              <p>
                                324k
                                <color>(+12k/week)</color>
                              </p>
                            </div>
                            <div class="my-pages-section-user">
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M1.11719 6.06543C1.11719 6.06543 3.11719 2.06543 6.61719 2.06543C10.1172 2.06543 12.1172 6.06543 12.1172 6.06543C12.1172 6.06543 10.1172 10.0654 6.61719 10.0654C3.11719 10.0654 1.11719 6.06543 1.11719 6.06543Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M6.61719 7.56543C7.44561 7.56543 8.11719 6.89386 8.11719 6.06543C8.11719 5.237 7.44561 4.56543 6.61719 4.56543C5.78876 4.56543 5.11719 5.237 5.11719 6.06543C5.11719 6.89386 5.78876 7.56543 6.61719 7.56543Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.617188 0.0654297)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                525k
                                <color>(+20k/week)</color>
                              </p>
                            </div>
                            <div class="my-pages-section-user">
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0)">
                                  <path
                                    d="M4.39453 11H2.89453C2.62931 11 2.37496 10.8946 2.18742 10.7071C1.99989 10.5196 1.89453 10.2652 1.89453 10V6.5C1.89453 6.23478 1.99989 5.98043 2.18742 5.79289C2.37496 5.60536 2.62931 5.5 2.89453 5.5H4.39453M7.89453 4.5V2.5C7.89453 2.10218 7.7365 1.72064 7.45519 1.43934C7.17389 1.15804 6.79236 1 6.39453 1L4.39453 5.5V11H10.0345C10.2757 11.0027 10.5097 10.9182 10.6935 10.762C10.8772 10.6058 10.9984 10.3885 11.0345 10.15L11.7245 5.65C11.7463 5.50668 11.7366 5.36034 11.6962 5.22113C11.6558 5.08191 11.5856 4.95315 11.4905 4.84376C11.3953 4.73437 11.2776 4.64697 11.1453 4.58761C11.0131 4.52825 10.8695 4.49836 10.7245 4.5H7.89453Z"
                                    stroke="#47505B"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect
                                      width="12"
                                      height="12"
                                      fill="white"
                                      transform="translate(0.894531)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                              <p>
                                30k
                                <color>(+3k/week)</color>
                              </p>
                            </div>
                          </div>
                          <div class="my-pages-buttons-section">
                            <div class="my-pages-buttons-left-section">
                              <button class="my-pages-buttons-left-msg">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.75 4.79168C8.75143 5.34162 8.62294 5.88413 8.375 6.37501C8.08101 6.96324 7.62906 7.458 7.06977 7.80388C6.51048 8.14976 5.86593 8.33309 5.20833 8.33334C4.65839 8.33478 4.11588 8.20629 3.625 7.95834L1.25 8.75001L2.04167 6.37501C1.79372 5.88413 1.66523 5.34162 1.66667 4.79168C1.66692 4.13408 1.85025 3.48953 2.19613 2.93024C2.54201 2.37095 3.03677 1.919 3.625 1.62501C4.11588 1.37707 4.65839 1.24858 5.20833 1.25001H5.41667C6.28514 1.29793 7.10543 1.6645 7.72047 2.27954C8.33552 2.89458 8.70209 3.71487 8.75 4.58334V4.79168Z"
                                    fill="#D1433A"
                                  ></path>
                                </svg>
                                10 Messages
                              </button>
                              <button class="my-pages-buttons-left-notifi">
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 3.33301C7.5 2.66997 7.23661 2.03408 6.76777 1.56524C6.29893 1.0964 5.66304 0.833008 5 0.833008C4.33696 0.833008 3.70107 1.0964 3.23223 1.56524C2.76339 2.03408 2.5 2.66997 2.5 3.33301C2.5 6.24967 1.25 7.08301 1.25 7.08301H8.75C8.75 7.08301 7.5 6.24967 7.5 3.33301Z"
                                    fill="#D1433A"
                                  ></path>
                                  <path
                                    d="M5.72292 8.75C5.64966 8.87628 5.54452 8.9811 5.41801 9.05397C5.2915 9.12684 5.14807 9.16519 5.00208 9.16519C4.85609 9.16519 4.71266 9.12684 4.58616 9.05397C4.45965 8.9811 4.3545 8.87628 4.28125 8.75"
                                    stroke="#D1433A"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                                13 Notifications
                              </button>
                            </div>
                            <div class="my-pages-buttons-right-section">
                              <button class="create-posts">CREATE POST</button>
                              <button class="create-promote">PROMOTE</button>
                              <button class="my-pages-buttons-more">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.9987 8.66634C8.36689 8.66634 8.66536 8.36786 8.66536 7.99967C8.66536 7.63148 8.36689 7.33301 7.9987 7.33301C7.63051 7.33301 7.33203 7.63148 7.33203 7.99967C7.33203 8.36786 7.63051 8.66634 7.9987 8.66634Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M7.9987 4.00033C8.36689 4.00033 8.66536 3.70185 8.66536 3.33366C8.66536 2.96547 8.36689 2.66699 7.9987 2.66699C7.63051 2.66699 7.33203 2.96547 7.33203 3.33366C7.33203 3.70185 7.63051 4.00033 7.9987 4.00033Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
                                    fill="#47505B"
                                    stroke="#47505B"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="show-more musicbar_more">
                        <a href="javascript:;">
                          <span class="show_more_bg">
                            <svg
                              width="9"
                              height="8"
                              viewBox="0 0 9 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.24219 0.689941V7.01994"
                                stroke="#071526"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M1 3.85498H7.48702"
                                stroke="#071526"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                            SHOW 10 MORE PAGES
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 m-b-50" v-if="showRightBar == 'discover_liked'">
            <div class="content-sidebar">
              <div class="content-sidebar">
                <div class="post-widget">
                  <div class="post-btn btn with-icon">
                    <div class="svg-icon">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          class="c-line-1"
                          d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          class="c-line-1"
                          d="M7 4.66699V9.33366"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          class="c-line-1"
                          d="M4.66699 7H9.33366"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    CREATE NEW PAGE
                  </div>
                </div>
                <div class="event-filter-widget with-app">
                  <div class="widget-title">
                    Filters
                    <div class="clear-history">Clear all history</div>
                  </div>
                  <div class="divider"></div>
                  <div class="widget-item">
                    <div class="item-title">Category</div>
                    <div class="choose-wrapper">
                      <div class="item-choose more-filters-all-js active">
                        All Categories
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Artists
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Booking Agency
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Distributor
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Management
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Marketing
                        <div class="point"></div>
                      </div>
                      <div
                        class="more-filters"
                        data-toggle="collapse"
                        data-target="#filters1"
                        aria-expanded="false"
                        aria-controls="filters1"
                      >
                        more(6)
                      </div>
                      <div class="collapse multi-collapse" id="filters1">
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          DJ
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Instrumentalist
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Podcaster
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Rapper
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Record Producer
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Singer
                          <div class="point"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="widget-item">
                    <div class="item-title">Genres</div>
                    <div class="input-genre-search">
                      <div class="svg-icon">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M7.33333 12.8464C10.2789 12.8464 12.6667 10.4585 12.6667 7.51302C12.6667 4.5675 10.2789 2.17969 7.33333 2.17969C4.38781 2.17969 2 4.5675 2 7.51302C2 10.4585 4.38781 12.8464 7.33333 12.8464Z"
                            stroke="#8B949F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            class="c-line-1"
                            d="M14 14.1793L11.1 11.2793"
                            stroke="#8B949F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <input type="text" />
                    </div>
                    <div class="choose-wrapper">
                      <div class="item-choose more-filters-all-js active">
                        All Genres
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Afro
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Alternative
                        <div class="point"></div>
                      </div>
                      <div
                        class="item-choose more-filters-js"
                        @click="$event.target.classList.toggle('active')"
                      >
                        Blues
                        <div class="point"></div>
                      </div>
                      <div
                        class="more-filters"
                        data-toggle="collapse"
                        data-target="#filters1"
                        aria-expanded="false"
                        aria-controls="filters1"
                      >
                        more(6)
                      </div>
                      <div class="collapse multi-collapse" id="filters1">
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Caribbean
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Classical
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Electronic
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Jazz
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          Hip-Hop/ Rap
                          <div class="point"></div>
                        </div>
                        <div
                          class="item-choose more-filters-js"
                          @click="$event.target.classList.toggle('active')"
                        >
                          House
                          <div class="point"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="widget-item">
                    <div class="item-title with-checkbox">Location</div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="c1"
                        name="all"
                      />
                      <label class="custom-control-label check" for="c1"
                        >All</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="c2"
                        name="c2"
                      />
                      <label class="custom-control-label check" for="c2"
                        >UK, London</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="c3"
                        name="c3"
                      />
                      <label class="custom-control-label check" for="c3"
                        >AU, Vienna</label
                      >
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="c4"
                        name="c4"
                      />
                      <label class="custom-control-label check" for="c4"
                        >RU, Moscow</label
                      >
                    </div>
                    <div
                      class="more-filters more-filters-js"
                      data-toggle="collapse"
                      data-target="#filters3"
                      aria-expanded="false"
                      aria-controls="filters3"
                    >
                      more(8)
                    </div>
                    <div class="collapse multi-collapse" id="filters3">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c5"
                          name="c5"
                        />
                        <label class="custom-control-label check" for="c5"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c6"
                          name="c6"
                        />
                        <label class="custom-control-label check" for="c6"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c7"
                          name="c7"
                        />
                        <label class="custom-control-label check" for="c7"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c7"
                          name="c7"
                        />
                        <label class="custom-control-label check" for="c7"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c8"
                          name="c8"
                        />
                        <label class="custom-control-label check" for="c8"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c8"
                          name="c8"
                        />
                        <label class="custom-control-label check" for="c8"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c9"
                          name="c9"
                        />
                        <label class="custom-control-label check" for="c9"
                          >RU, Moscow</label
                        >
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="c10"
                          name="c10"
                        />
                        <label class="custom-control-label check" for="c10"
                          >RU, Moscow</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="widget-item">
                    <div class="item-title">Rating</div>
                    <div class="rating-row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="rat1"
                          name="rat"
                        />
                        <label class="custom-control-label check" for="rat1"
                          >1</label
                        >
                      </div>
                      <div class="ratingss-block">
                        <div class="starss-block">
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="rating"></div>
                      </div>
                    </div>
                    <div class="rating-row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="rat2"
                          name="rat"
                        />
                        <label class="custom-control-label check" for="rat2"
                          >2</label
                        >
                      </div>
                      <div class="ratingss-block">
                        <div class="starss-block">
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="rating"></div>
                      </div>
                    </div>
                    <div class="rating-row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="rat3"
                          name="rat"
                        />
                        <label class="custom-control-label check" for="rat3"
                          >3</label
                        >
                      </div>
                      <div class="ratingss-block">
                        <div class="starss-block">
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="rating"></div>
                      </div>
                    </div>
                    <div class="rating-row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="rat4"
                          name="rat"
                        />
                        <label class="custom-control-label check" for="rat4"
                          >4</label
                        >
                      </div>
                      <div class="ratingss-block">
                        <div class="starss-block">
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="rating"></div>
                      </div>
                    </div>
                    <div class="rating-row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="rat5"
                          name="rat"
                        />
                        <label class="custom-control-label check" for="rat5"
                          >5</label
                        >
                      </div>
                      <div class="ratingss-block">
                        <div class="starss-block">
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                          <div class="svg-icon">
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.90646 7.64551C5.90288 7.64279 5.89791 7.6428 5.89434 7.64553L2.84276 9.98096C2.83503 9.98688 2.82426 9.97938 2.82713 9.97007L3.99444 6.19061C3.99567 6.18661 3.99428 6.18226 3.99096 6.17972L0.923852 3.83399C0.916256 3.82818 0.920364 3.81605 0.929927 3.81605H4.7135C4.71789 3.81605 4.72176 3.81319 4.72306 3.809L5.88996 0.0308291C5.89286 0.0214401 5.90615 0.0214271 5.90907 0.0308105L7.08406 3.80902C7.08536 3.8132 7.08923 3.81605 7.09361 3.81605H10.8709C10.8804 3.81605 10.8845 3.82818 10.8769 3.83399L7.82342 6.17091C7.8201 6.17346 7.81871 6.1778 7.81994 6.18181L8.98731 9.96146C8.99018 9.97075 8.97945 9.97826 8.97171 9.97238L5.90646 7.64551Z"
                                fill="#D1433A"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div class="rating"></div>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="widget-item">
                    <div class="item-title with-checkbox">Sort</div>
                    <ul class="sort-cehkbox">
                      <li>
                        <div class="sort-cehkbox-main">
                          <input
                            type="radio"
                            id="Entry"
                            name="radio-group"
                            checked=""
                          />
                          <label for="Entry"><span>Most Viewed</span></label>
                        </div>
                      </li>
                      <li>
                        <div class="sort-cehkbox-main">
                          <input
                            type="radio"
                            id="Entry1"
                            name="radio-group"
                            checked=""
                          />
                          <label for="Entry1"><span>Latest</span></label>
                        </div>
                      </li>
                      <li>
                        <div class="sort-cehkbox-main">
                          <input
                            type="radio"
                            id="high"
                            name="radio-group"
                            checked=""
                          />
                          <label for="high"><span>High Rating</span></label>
                        </div>
                      </li>
                      <li>
                        <div class="sort-cehkbox-main">
                          <input
                            type="radio"
                            id="Low"
                            name="radio-group"
                            checked=""
                          />
                          <label for="Low"><span>Low Rating</span></label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 m-b-50" v-if="showRightBar == 'my_pages'">
            <div class="row">
              <div class="col-12">
                <div class="post-widget">
                  <div class="post-btn btn with-icon">
                    <div class="svg-icon">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M7 4.66699V9.33366"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M4.66699 7H9.33366"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                    CREATE NEW PAGE
                  </div>
                </div>
                <div class="my-page-overview">
                  <div class="my-page-overview-heading">Overview</div>
                  <div class="my-page-overview-detail">
                    <div class="my-page-overview-pages-over">
                      Total Pages: <span>24</span>
                    </div>
                    <div class="my-page-overview-pages-over">
                      Total Followers: <span>923k</span>
                    </div>
                    <div class="my-page-msg-notifiaction">
                      <div class="my-page-overview-pages-messages">
                        Messages: <span>312</span>
                      </div>
                      <div class="my-page-overview-pages-notifi">
                        Notifications: <span>240</span>
                      </div>
                    </div>
                    <div class="my-page-overview-pages-over">
                      Most Popular Page:
                      <h1>NHOAH THE ARTIST</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="my-page-overview">
                  <div class="my-page-overview-heading">Now Promoting</div>
                  <div class="now-parmoting-contain">
                    <div class="promoting-container-section">
                      <div class="promoting-container-section-image">
                        <img
                          :src="'https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg'"
                        />
                      </div>
                      <div class="promoting-container-section-detail">
                        <h1>NHOAH The Artist</h1>
                        <p>$50 / $100</p>
                      </div>
                      <button
                        class="
                          promoting-container-section-detail-right-arrow
                          btn
                        "
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9L5 5L1 1"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="promoting-container-section">
                      <div class="promoting-container-section-image">
                        <img
                          src="https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg"
                          alt=""
                        />
                      </div>
                      <div class="promoting-container-section-detail">
                        <h1>The Walt Disnep</h1>
                        <p>
                          <color>$99 / $100</color>
                        </p>
                      </div>
                      <button
                        class="
                          promoting-container-section-detail-right-arrow
                          btn
                        "
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9L5 5L1 1"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="promoting-container-section">
                      <div class="promoting-container-section-image">
                        <img
                          src="https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg"
                        />
                      </div>
                      <div class="promoting-container-section-detail">
                        <h1>NHOAH The Artist</h1>
                        <p>$50 / $100</p>
                      </div>
                      <button
                        class="
                          promoting-container-section-detail-right-arrow
                          btn
                        "
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9L5 5L1 1"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      showRightBar: "discover_liked",
    };
  },
  components: {},
  methods: {},
};
</script>
