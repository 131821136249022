<template>
  <div>
    <input type="text" v-model="text" @keyup.enter="send">
  </div>
</template>

<script>
const options = { defaultProtocol: "https" };


export default {
  name: "LinkPrivewPage",
  data() {
    return {
      text: ''
    }
  },
  methods: {
    send() {
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  overflow: auto;
  border-radius: 7px 7px 7px 7px;
  background-color: #fff;
  width: 15rem;
  cursor: pointer;

  .card-block {
    border-radius: 0 0 7px 7px;
    background-color: #fff;

    .card-text {
      width: 80%;
      margin: 0 auto;
      text-align: justify;

      .card-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #071526;
        margin: 5px 0 5px 0;
      }

      .card-description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #47505B;
      }
    }

    .card-btn {
      margin: 1em 0 1em 0;
      position: relative;
      text-align: center;
    }
  }
}
</style>
