<template>
  <div class="widget-follow" v-if="getSimilarArtists && getSimilarArtists.length > 0">
    <div class="widget-title">
      {{title}}
      <div class="link-more near_arrow without-slider in-title pointerClass"><a v-on:click="changeTab">show more
        <span class="sl-arrow">
          <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
      </a>
      </div>
    </div>
    <div>
      <div class="widget-item max-width" v-for="(similar_artist, index) in getSimilarArtists.slice(0,3)" :key="index">
        <div v-if="similar_artist" class="cover-image">
          <div class="img-wrapper">
            <img :src="(similar_artist.artwork_url) ? similar_artist.artwork_url : ''" alt="artist_image" style="width: inherit;">
          </div>
          <div class="status">
            <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.94219 5.26891L1.81219 6.81691L2.62619 4.30991L0.492188 2.76091H3.12819L3.94219 0.253906L4.76019 2.76091H7.39219L5.26219 4.30991L6.07619 6.81691L3.94219 5.26891Z"
                fill="white"></path>
            </svg>
          </div>
        </div>
        <div v-if="similar_artist" class="item-desc">
          <div class="name" @click="goArtistPage(similar_artist.id, similar_artist.type)">{{(similar_artist.title) ? similar_artist.title : 'Name'}}</div>
          <div class="info">
            <div>
              <div class="svg-icon">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.9">
                    <path
                      d="M1.01197 11.0008C1.01197 11.0008 0.573983 8.51883 4.99598 8.42383C4.99598 8.42383 7.99595 8.42383 8.12995 8.42383C12.23 8.42383 12.276 11.0008 12.276 11.0008"
                      stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                      stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </div>
              {{(similar_artist.members) ? similar_artist.members: '0'}}
            </div>
            <div>
              <div class="svg-icon">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.9">
                    <path
                      d="M2.39866 10.9991C3.18621 10.9991 3.82466 10.3732 3.82466 9.60113C3.82466 8.82903 3.18621 8.20312 2.39866 8.20312C1.6111 8.20312 0.972656 8.82903 0.972656 9.60113C0.972656 10.3732 1.6111 10.9991 2.39866 10.9991Z"
                      stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path
                      d="M8.58616 9.09678C9.37371 9.09678 10.0122 8.47088 10.0122 7.69878C10.0122 6.92669 9.37371 6.30078 8.58616 6.30078C7.7986 6.30078 7.16016 6.92669 7.16016 7.69878C7.16016 8.47088 7.7986 9.09678 8.58616 9.09678Z"
                      stroke="#8B949F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M3.82422 9.60184V2.79185L9.98224 0.714844V7.66885" stroke="#8B949F" stroke-width="1.3"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </g>
                </svg>
              </div>
              {{ (similar_artist.total_tracks) ? similar_artist.total_tracks : '0' }}
            </div>
          </div>
        </div>
        <div  class="action" :class="{'active':similar_artist.follow_action === 'approved'}" @click="followOrUnfollowPage(similar_artist.id, followStatus(similar_artist.follow_action)?0:1, $event)">
          <div class="svg-icon">
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-fill-1"
                    d="M1 8.00987C3.757 8.00987 6 10.2552 6 13.015C6 13.5676 6.448 14.0161 7 14.0161C7.552 14.0161 8 13.5676 8 13.015C8 9.15105 4.86 6.00781 1 6.00781C0.448 6.00781 0 6.45627 0 7.00884C0 7.56141 0.448 8.00987 1 8.00987Z"
                    fill="#D1433A"></path>
              <path class="c-fill-1"
                    d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                    fill="#D1433A"></path>
              <path class="c-fill-1"
                    d="M1 14.0157C1.55228 14.0157 2 13.5676 2 13.0147C2 12.4619 1.55228 12.0137 1 12.0137C0.447715 12.0137 0 12.4619 0 13.0147C0 13.5676 0.447715 14.0157 1 14.0157Z"
                    fill="#D1433A"></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {changeFollowStatus, followOrUnFollowPage} from "../../../../apis/APIs";
import {mapGetters} from "vuex";

export default {
  props: {

    title:{
      type:String,
      default:"Similar artists"
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    getSimilarArtists() {
      return this.$store.getters.getPageSimilarArtistsList
    },
    getTotalCountOfSimilarArtist() {
      return this.$store.getters.getPageSimilarArtistTotal
    }
  },
  components: {},
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab('similar-artists');
    },
    changeRoute(value){
      this.$router.push(value)
    },
    async setArtistFollowStatus(similar_artists,id, action, follow_status){
      try {
        await this.$store.dispatch('loading', true)
        let obj = {
          id : id,
          object_type : 'user',
          action : action
        }
        const {data} = await changeFollowStatus(obj)
        await this.$store.dispatch('loading', false)
        if(data.success){
          await this.makeToast('success', 'Artist', `Successfully ` + follow_status)
          similar_artists.follow_status = action;
        }
        else{
          await this.makeToast('error', 'Page', data.message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.makeToast('error', 'Page', e.message)
      }
    },
    async followOrUnfollowPage(page_id,status,event) {
      if(event) event.stopPropagation()
      try {
        await this.$store.dispatch('loading', true)
        const formdata = new FormData();
        formdata.append('user_id', this.user.user_data.id);
        formdata.append('page_id', page_id);
        formdata.append('type', 'Page');
        formdata.append('action', status);
        const {data} = await followOrUnFollowPage(formdata)
        await this.$store.dispatch('loading', false)
        if(data.success){
          const payload = {
            showMore : false,
            page_id : this.$route.params.id,
          }
          await this.$store.dispatch('getPageSimilarArtists', payload)
          await this.makeToast('success', 'Page', `successfully ${(status === 1) ? 'Follow' : 'UnFollow'}`)
        }
        else{
          await this.makeToast('error', 'Page', data.message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false)
        await this.makeToast('error', 'Page', e.message)
      }
    },
    goArtistPage(id, type){
      let pageType;
      if (type === 'clubroom') {
        pageType = 'club'
      } else if (type === 'partyroom') {
        pageType = 'party'
      } else if (type === 'artist') {
        pageType = 'artist'
      } else if (type === 'company') {
        pageType = 'company'
      }
      this.$router.push(`/page-manager/page/${pageType}/${id}`)
    },
    followStatus(follow_action){
      if(follow_action){
        return follow_action
      }
      return !!(follow_action)
    }
  }
}
</script>
