<template>
  <div class="container">
    <div class="card genres-list annotation type-list" style="width: 1170px">
      <div class="row no-gutters">
        <div class="col ">
          <div class="type">Type</div>
          <div class="title">Title</div>
          <div class="album">Album</div>
          <div class="time">Time</div>
          <div class="date js-genre-date">
            <div class="text">Date</div>
            <div class="svg-icon calendar">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg-calendar">
                <path class="c-line-1 c-fill-1"
                      d="M3.52899 1C3.41273 1 3.31851 1.09423 3.31851 1.21047V2.50292C2.95725 2.59752 2.68709 2.92569 2.68709 3.31522C2.68709 3.77768 3.06651 4.15711 3.52899 4.15711C3.99146 4.15711 4.37088 3.77768 4.37088 3.31522C4.37088 2.92569 4.10071 2.59752 3.73946 2.50292V1.21047C3.73946 1.09423 3.64523 1 3.52899 1ZM8.15942 1C8.04316 1 7.94895 1.09423 7.94895 1.21047V2.50292C7.58769 2.59752 7.31752 2.92569 7.31752 3.31522C7.31752 3.77768 7.69695 4.15711 8.15942 4.15711C8.6219 4.15711 9.00132 3.77768 9.00132 3.31522C9.00132 2.92569 8.73114 2.59752 8.36989 2.50292V1.21047C8.36989 1.09423 8.27567 1 8.15942 1ZM2.05567 1.63142C1.47464 1.63142 1 2.10606 1 2.68708V9.20521C1 9.78622 1.47464 10.2609 2.05567 10.2609H9.62616C10.2072 10.2609 10.6818 9.78622 10.6818 9.20521V2.68708C10.6818 2.10606 10.2072 1.63142 9.62616 1.63142H8.99802C8.88683 1.62984 8.78427 1.73069 8.78427 1.8419C8.78427 1.9531 8.88683 2.05394 8.99802 2.05237H9.62616C9.98085 2.05237 10.2609 2.33238 10.2609 2.68708V4.57806H1.42095V2.68708C1.42095 2.33238 1.70095 2.05237 2.05567 2.05237H2.68379C2.79499 2.05395 2.89756 1.9531 2.89756 1.8419C2.89756 1.73069 2.79499 1.62985 2.68379 1.63142H2.05567ZM4.36759 1.63142C4.25134 1.63142 4.15711 1.72565 4.15711 1.8419C4.15711 1.95814 4.25134 2.05237 4.36759 2.05237H7.31423C7.43047 2.05237 7.5247 1.95814 7.5247 1.8419C7.5247 1.72565 7.43047 1.63142 7.31423 1.63142H4.36759ZM3.52899 2.89427C3.76396 2.89427 3.94993 3.08023 3.94993 3.31522C3.94993 3.55019 3.76396 3.73617 3.52899 3.73617C3.29401 3.73617 3.10804 3.55019 3.10804 3.31522C3.10804 3.08023 3.29401 2.89427 3.52899 2.89427ZM8.15942 2.89427C8.39439 2.89427 8.58037 3.08023 8.58037 3.31522C8.58037 3.55019 8.39439 3.73617 8.15942 3.73617C7.92445 3.73617 7.73847 3.55019 7.73847 3.31522C7.73847 3.08023 7.92445 2.89427 8.15942 2.89427ZM1.42095 4.99901H10.2609V9.20521C10.2609 9.55991 9.98085 9.83992 9.62616 9.83992H2.05567C1.70095 9.83992 1.42095 9.55991 1.42095 9.20521V4.99901Z"
                      fill="#47505B" stroke="#47505B" stroke-width="0.4"></path>
              </svg>
            </div>
            <div class="svg-icon">
              <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg-arrow-down">
                <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="popularity">Popularity</div>
        </div>
      </div>
    </div>
    <div :class="['card genres-list type-list',(showMore) ? 'open':'']" style="width: 1170px">
      <div class="row no-gutters">
        <div class="col line-1">
          <div class="cover "><img :src="require('@/assets/img/svg/'+imageName+'.png')" alt="Generic placeholder image">
          </div>
          <div class="play-button js-play-button new-play-button"
               @click="$event.currentTarget.classList.toggle('active')">
            <div class="svg-icon">
              <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="play-type-1">
                <path class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"></path>
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
                <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
              </svg>
            </div>
          </div>
          <div class="type-music">
            <div class="svg-icon">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="14.25" stroke="#47505B" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.4439 12.4618L20.6872 11.4132C21.3897 11.2613 22.0042 10.8392 22.3981 10.2382C22.7921 9.63711 22.9339 8.90525 22.7929 8.20056C22.652 7.49586 22.2395 6.87486 21.6447 6.4716C21.0498 6.06835 20.3203 5.91518 19.6135 6.04516L15.8264 6.80258C15.1936 6.92927 14.6244 7.27125 14.2153 7.77034C13.8063 8.26943 13.5829 8.89481 13.5829 9.54008V17.0547C12.8734 16.6451 12.0485 16.481 11.2363 16.5879C10.424 16.6948 9.66965 17.0668 9.09029 17.6461C8.51093 18.2254 8.1389 18.9796 8.03189 19.7919C7.92488 20.6042 8.08888 21.4291 8.49844 22.1387C8.90801 22.8482 9.54026 23.4028 10.2972 23.7165C11.054 24.0301 11.8933 24.0853 12.6847 23.8733C13.4761 23.6614 14.1755 23.1943 14.6744 22.5444C15.1732 21.8945 15.4437 21.0982 15.4439 20.2789V12.4628V12.4618ZM19.9791 7.86985L16.192 8.62727C15.9812 8.66942 15.7914 8.78329 15.655 8.94953C15.5187 9.11578 15.4441 9.32412 15.4439 9.53915V10.5646L20.3225 9.58847C20.5469 9.5393 20.743 9.40399 20.8687 9.21166C20.9944 9.01933 21.0395 8.78535 20.9943 8.56009C20.9492 8.33482 20.8175 8.13628 20.6274 8.00719C20.4374 7.8781 20.2042 7.82878 19.9782 7.86985H19.9791ZM13.5829 20.2779C13.5829 19.7844 13.3869 19.311 13.0379 18.962C12.6889 18.613 12.2155 18.417 11.722 18.417C11.2284 18.417 10.7551 18.613 10.4061 18.962C10.0571 19.311 9.86099 19.7844 9.86099 20.2779C9.86099 20.7715 10.0571 21.2449 10.4061 21.5939C10.7551 21.9429 11.2284 22.1389 11.722 22.1389C12.2155 22.1389 12.6889 21.9429 13.0379 21.5939C13.3869 21.2449 13.5829 20.7715 13.5829 20.2779Z"
                      fill="#47505B"/>
              </svg>

            </div>
          </div>
          <div class="waveform ">
            <div class="seek-wrapper">
              <div class="svg-icon">
                <svg width="180" height="25" viewBox="0 0 180 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                  <rect x="30.0664" y="17.647" width="2.2549" height="7.35294" fill="#0075FF"/>
                  <rect x="3.00781" y="17.647" width="2.25491" height="7.35294" fill="#0075FF"/>
                  <rect x="33.0703" y="9.55859" width="2.2549" height="15.4412" fill="#0075FF"/>
                  <rect x="27.0586" y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                  <rect x="6.01172" y="14.7061" width="2.25489" height="10.2941" fill="#0075FF"/>
                  <rect x="18.0391" y="16.1763" width="2.2549" height="8.82353" fill="#0075FF"/>
                  <rect x="9.01953" y="11.0293" width="2.2549" height="13.9706" fill="#0075FF"/>
                  <rect x="21.0469" y="18.3823" width="2.2549" height="6.61765" fill="#0075FF"/>
                  <rect x="12.0273" y="16.9116" width="2.2549" height="8.08823" fill="#0075FF"/>
                  <rect x="24.0508" y="13.2354" width="2.2549" height="11.7647" fill="#0075FF"/>
                  <rect x="15.0312" y="12.5" width="2.25489" height="12.5" fill="#0075FF"/>
                  <rect x="36.0781" y="22.0586" width="2.25491" height="2.94118" fill="#0075FF"/>
                  <rect x="66.1445" width="2.25489" height="25" fill="#0075FF"/>
                  <rect x="39.0859" y="21.3237" width="2.2549" height="3.67647" fill="#0075FF"/>
                  <rect x="69.1484" y="9.55859" width="2.25489" height="15.4412" fill="#0075FF"/>
                  <rect x="63.1367" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                  <rect x="42.0938" y="7.35303" width="2.2549" height="17.6471" fill="#0075FF"/>
                  <rect x="54.1172" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                  <rect x="45.0977" y="15.4414" width="2.25491" height="9.55882" fill="#0075FF"/>
                  <rect x="57.125" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                  <rect x="48.1055" y="20.5884" width="2.2549" height="4.41176" fill="#0075FF"/>
                  <rect x="60.1328" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                  <rect x="51.1094" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                  <rect x="72.1562" width="2.25489" height="25" fill="#0075FF"/>
                  <rect x="102.223" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="75.1641" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                  <rect x="105.23" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                  <rect x="99.2148" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                  <rect x="78.1719" y="8.08838" width="2.25487" height="16.9118" fill="#0075FF"/>
                  <rect x="90.1953" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                  <rect x="81.1758" y="11.0293" width="2.25489" height="13.9706" fill="#0075FF"/>
                  <rect x="93.2031" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                  <rect x="84.1836" y="10.2939" width="2.25491" height="14.7059" fill="#0075FF"/>
                  <rect x="96.2109" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                  <rect x="87.1875" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                  <rect x="108.234" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="138.301" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="111.242" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                  <rect x="141.309" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                  <rect x="135.293" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                  <rect x="114.25" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                  <rect x="126.273" y="8.08838" width="2.25491" height="16.9118" fill="#C4CCD6"/>
                  <rect x="117.254" y="11.0293" width="2.25491" height="13.9706" fill="#C4CCD6"/>
                  <rect x="129.281" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                  <rect x="120.262" y="10.2939" width="2.25491" height="14.7059" fill="#C4CCD6"/>
                  <rect x="132.289" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                  <rect x="123.27" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                  <rect x="144.312" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="174.379" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="147.32" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                  <rect x="177.387" y="9.55859" width="2.25491" height="15.4412" fill="#C4CCD6"/>
                  <rect x="171.371" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                  <rect x="150.328" y="8.08838" width="2.25487" height="16.9118" fill="#C4CCD6"/>
                  <rect x="162.352" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                  <rect x="153.332" y="11.0293" width="2.25489" height="13.9706" fill="#C4CCD6"/>
                  <rect x="165.359" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                  <rect x="156.34" y="10.2939" width="2.25489" height="14.7059" fill="#C4CCD6"/>
                  <rect x="168.367" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                  <rect x="159.348" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                  <rect y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                  <rect x="30.0664" y="17.647" width="2.2549" height="7.35294" fill="#0075FF"/>
                  <rect x="3.00781" y="17.647" width="2.25491" height="7.35294" fill="#0075FF"/>
                  <rect x="33.0703" y="9.55859" width="2.2549" height="15.4412" fill="#0075FF"/>
                  <rect x="27.0586" y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                  <rect x="6.01172" y="14.7061" width="2.25489" height="10.2941" fill="#0075FF"/>
                  <rect x="18.0391" y="16.1763" width="2.2549" height="8.82353" fill="#0075FF"/>
                  <rect x="9.01953" y="11.0293" width="2.2549" height="13.9706" fill="#0075FF"/>
                  <rect x="21.0469" y="18.3823" width="2.2549" height="6.61765" fill="#0075FF"/>
                  <rect x="12.0273" y="16.9116" width="2.2549" height="8.08823" fill="#0075FF"/>
                  <rect x="24.0508" y="13.2354" width="2.2549" height="11.7647" fill="#0075FF"/>
                  <rect x="15.0312" y="12.5" width="2.25489" height="12.5" fill="#0075FF"/>
                  <rect x="36.0781" y="22.0586" width="2.25491" height="2.94118" fill="#0075FF"/>
                  <rect x="66.1445" width="2.25489" height="25" fill="#0075FF"/>
                  <rect x="39.0859" y="21.3237" width="2.2549" height="3.67647" fill="#0075FF"/>
                  <rect x="69.1484" y="9.55859" width="2.25489" height="15.4412" fill="#0075FF"/>
                  <rect x="63.1367" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                  <rect x="42.0938" y="7.35303" width="2.2549" height="17.6471" fill="#0075FF"/>
                  <rect x="54.1172" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                  <rect x="45.0977" y="15.4414" width="2.25491" height="9.55882" fill="#0075FF"/>
                  <rect x="57.125" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                  <rect x="48.1055" y="20.5884" width="2.2549" height="4.41176" fill="#0075FF"/>
                  <rect x="60.1328" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                  <rect x="51.1094" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                  <rect x="72.1562" width="2.25489" height="25" fill="#0075FF"/>
                  <rect x="102.223" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="75.1641" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                  <rect x="105.23" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                  <rect x="99.2148" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                  <rect x="78.1719" y="8.08838" width="2.25487" height="16.9118" fill="#0075FF"/>
                  <rect x="90.1953" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                  <rect x="81.1758" y="11.0293" width="2.25489" height="13.9706" fill="#0075FF"/>
                  <rect x="93.2031" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                  <rect x="84.1836" y="10.2939" width="2.25491" height="14.7059" fill="#0075FF"/>
                  <rect x="96.2109" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                  <rect x="87.1875" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                  <rect x="108.234" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="138.301" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="111.242" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                  <rect x="141.309" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                  <rect x="135.293" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                  <rect x="114.25" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                  <rect x="126.273" y="8.08838" width="2.25491" height="16.9118" fill="#C4CCD6"/>
                  <rect x="117.254" y="11.0293" width="2.25491" height="13.9706" fill="#C4CCD6"/>
                  <rect x="129.281" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                  <rect x="120.262" y="10.2939" width="2.25491" height="14.7059" fill="#C4CCD6"/>
                  <rect x="132.289" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                  <rect x="123.27" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                  <rect x="144.312" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="174.379" width="2.25491" height="25" fill="#C4CCD6"/>
                  <rect x="147.32" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                  <rect x="177.387" y="9.55859" width="2.25491" height="15.4412" fill="#C4CCD6"/>
                  <rect x="171.371" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                  <rect x="150.328" y="8.08838" width="2.25487" height="16.9118" fill="#C4CCD6"/>
                  <rect x="162.352" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                  <rect x="153.332" y="11.0293" width="2.25489" height="13.9706" fill="#C4CCD6"/>
                  <rect x="165.359" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                  <rect x="156.34" y="10.2939" width="2.25489" height="14.7059" fill="#C4CCD6"/>
                  <rect x="168.367" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                  <rect x="159.348" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                </svg>

              </div>
            </div>
          </div>
          <div class="description">
            <div class="artist"><a href="#">Artist Name</a></div>
            <div class="track"><a href="#">Song Name</a></div>
          </div>
          <div class="album">
            <div class="album">NA</div>
          </div>
          <div class="time">
            0:00
          </div>
          <div class="date">
            2 days ago
          </div>
          <div class="popularity" v-if="listType == 'type1'">
            <div class="empty">
              <div class="column" v-for="n in 10" :key="n"></div>
            </div>
            <div class="full">
              <div class="column" v-for="n in 10" :key="n"></div>
            </div>
            <div class="count">
              000K
            </div>
          </div>
          <div class="popularity" v-if="listType == 'type2'">
            <div class="btn btn-play-count new-pos">
              <div class="svg-icon">
                <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="small-play-svg">
                  <path class="c-fill-1"
                        d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                        fill="#8B949F"></path>
                </svg>
              </div>
              <span>32.2k</span>
            </div>
          </div>
          <div class="other d-flex ">
            <div class="actions d-flex align-items-center">
              <div class="wishlist js-wishlist">
                <div class="svg-icon " style="visibility:visible" v-if=" (listType == 'type1')">

                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                        stroke="#D1433A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>


                </div>
                <div class="svg-icon" style="visibility:visible" v-if=" (listType == 'type2')">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07935 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                        stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                </div>
              </div>
              <div class="btn btn-icon-border text shop-cart"
                   :style="{visibility: (listType == 'type1') ? 'visible' : 'hidden'}">
                <div class="svg-icon">
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-shop">
                    <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="white"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="c-line-1"
                          d="M9.97559 3.76123V5.04023C9.90323 5.5065 9.653 5.9266 9.27745 6.21227C8.9019 6.49794 8.43026 6.62696 7.96159 6.57223C7.49292 6.62696 7.02127 6.49794 6.64572 6.21227C6.27017 5.9266 6.01994 5.5065 5.94759 5.04023V3.76123"
                          stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div>$00</div>
              </div>
              <div class="more1 ">
                <div class="dropdown vertical more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false" data-display="static">
                  <div class="svg-icon more-position">
                    <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.0429687 2.52051C0.0429686 3.38345 0.742524 4.08301 1.60547 4.08301C2.46841 4.08301 3.16797 3.38345 3.16797 2.52051C3.16797 1.65756 2.46841 0.958008 1.60547 0.958008C0.742524 0.958008 0.0429687 1.65756 0.0429687 2.52051Z"
                          fill="#47505B"/>
                      <path
                          d="M0.0429687 8.5625C0.0429686 9.42544 0.742524 10.125 1.60547 10.125C2.46841 10.125 3.16797 9.42544 3.16797 8.5625C3.16797 7.69956 2.46841 7 1.60547 7C0.742524 7 0.0429687 7.69955 0.0429687 8.5625Z"
                          fill="#47505B"/>
                      <path
                          d="M0.0429687 14.6035C0.0429686 15.4665 0.742524 16.166 1.60547 16.166C2.46841 16.166 3.16797 15.4665 3.16797 14.6035C3.16797 13.7406 2.46841 13.041 1.60547 13.041C0.742524 13.041 0.0429687 13.7406 0.0429687 14.6035Z"
                          fill="#47505B"/>
                    </svg>


                  </div>
                  <ListOption/>
                </div>
              </div>
              <div class="extend js-extend" v-on:click="showMore = !showMore">
                <div class="svg-icon">
                  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-arrow-down">
                    <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters line-2 recent-review d-flex flex-column">
        <div class="head-block">
          <div class="cover-image">
            <div class="img-wrapper"><img
                src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/sidebar-ava.png"
                alt=""></div>
          </div>
          <div class="name-block">
            <div class="name">NHOAH</div>
            <div class="desc">
              <div class="rating">
                <div class="svg-icon full">
                  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="c-line-1 c-fill-1">
                    <path class="c-line-1 c-fill-1"
                          d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                          fill="white" stroke="white" stroke-width="1.3"></path>
                  </svg>
                </div>
                <div class="svg-icon full">
                  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="c-line-1 c-fill-1">
                    <path class="c-line-1 c-fill-1"
                          d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                          fill="white" stroke="white" stroke-width="1.3"></path>
                  </svg>
                </div>
                <div class="svg-icon full">
                  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="c-line-1 c-fill-1">
                    <path class="c-line-1 c-fill-1"
                          d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                          fill="white" stroke="white" stroke-width="1.3"></path>
                  </svg>
                </div>
                <div class="svg-icon full">
                  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="c-line-1 c-fill-1">
                    <path class="c-line-1 c-fill-1"
                          d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                          fill="white" stroke="white" stroke-width="1.3"></path>
                  </svg>
                </div>
                <div class="svg-icon full">
                  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="c-line-1 c-fill-1">
                    <path class="c-line-1 c-fill-1"
                          d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                          fill="white" stroke="white" stroke-width="1.3"></path>
                  </svg>
                </div>
                <span>5.0</span>
              </div>
              <div class="date-block">
                15 April at 18:33
              </div>
            </div>
          </div>
        </div>
        <div class="content-block">
          <div class="text">
            Diverted as strictly exertion addition no disposal by stanhill. This call wife do so sigh no
            gate felt. To they four in love. Settling you has separate supplied bed. You and abode spite
            order get.
          </div>
        </div>
        <div class="actions-block">
          <div class="action-btn list-review-btn ">
            <div class="svg-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M4.25523 15H2.05486C1.25472 15 1.05469 14.7 1.05469 14V8.6C1.05469 7.9 1.25472 7.5 2.15487 7.5H4.35524M9.65614 6.4V3.2C9.66479 2.91713 9.61711 2.63536 9.51587 2.37108C9.41463 2.1068 9.26184 1.86528 9.0664 1.66056C8.87096 1.45585 8.63676 1.29203 8.37743 1.17863C8.1181 1.06523 7.8388 1.00451 7.55578 1V1L4.35524 7.5V15H12.2566C13.4568 15 13.9569 13.5 14.0569 12.8L15.0571 7.8C15.0968 7.65554 15.1075 7.50467 15.0888 7.35604C15.07 7.20741 15.0221 7.06394 14.9478 6.93387C14.8734 6.80379 14.7741 6.68968 14.6556 6.59806C14.537 6.50645 14.4015 6.43914 14.2569 6.4H9.65614Z"
                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            Like
            <span>8</span>
          </div>
          <div class="action-btn list-review-btn ">
            <div class="svg-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M4.66634 1.49657H2.66634C2.31272 1.49657 1.97358 1.6365 1.72353 1.88559C1.47348 2.13469 1.33301 2.47252 1.33301 2.82479V7.47358C1.33301 7.82584 1.47348 8.16368 1.72353 8.41277C1.97358 8.66186 2.31272 8.8018 2.66634 8.8018H4.66634M9.33301 10.13V12.7865C9.33301 13.3149 9.12229 13.8216 8.74722 14.1953C8.37215 14.5689 7.86344 14.7788 7.33301 14.7788L4.66634 8.8018V1.49657H12.1863C12.5079 1.49295 12.8199 1.60521 13.0649 1.81268C13.31 2.02015 13.4715 2.30884 13.5197 2.62556L14.4397 8.60257C14.4687 8.79293 14.4558 8.9873 14.4019 9.17221C14.348 9.35712 14.2544 9.52814 14.1276 9.67344C14.0008 9.81873 13.8437 9.93482 13.6674 10.0137C13.4911 10.0925 13.2996 10.1322 13.1063 10.13H9.33301Z"
                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            Dislike
            <span>5</span>
          </div>
          <div class="action-btn list-review-btn ">
            <div class="svg-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M13 9.12999C13 9.48226 12.8595 9.8201 12.6095 10.0692C12.3594 10.3183 12.0203 10.4582 11.6667 10.4582H3.66667L1 13.1147V2.48887C1 2.1366 1.14048 1.79876 1.39052 1.54967C1.64057 1.30058 1.97971 1.16064 2.33333 1.16064H11.6667C12.0203 1.16064 12.3594 1.30058 12.6095 1.54967C12.8595 1.79876 13 2.1366 13 2.48887V9.12999Z"
                      stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            Comments
            <span>0</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";


export default {
  data() {
    return {
      showSubMenu: 'none',
      showMore: false
    }
  },
  props: {
    iconName: String,
    listType: String,
    imageName: String

  },
  components: {
    ListOption
  },
  computed: {},
  methods: {}
}
</script>
