<template>
  <div class="main-music-tab-content m-t-20">
    <div class="row mt-2">
      <div v-if="totalCreatedPlaylists > 0" class="collection-heading history_heading mt-0 ml-3">
        {{(totalCreatedPlaylists > 0) ? totalCreatedPlaylists : '' }} Created Playlists
      </div>
      <div class="col" v-for="(playlist, index) in createdPlaylists" :key="index">
        <div>
          <Grid :item="playlist" :type="'playlist'" v-if="!loading && createdPlaylists.length > 0"/>
        </div>
        <div v-if="loading" class="text-center mb-3 d-flex justify-content-center">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </div>
    </div>
    <div class="show-more musicbar_more-frd mt-0" v-if="!loading && totalCreatedPlaylists > createdPlaylists.length">
      <a href="javascript:;">
        <span class="show_more_bg">
           <svg width="9" height="8" viewBox="0 0 9 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
              <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round"></path>
           </svg>
            SEE ALL CREATED PLAYLISTS
        </span>
      </a>
    </div>
    <div class="row mt-4">
      <div v-if="totalFavoritedPlaylists > 0" class="collection-heading history_heading mt-0 ml-3" >
        {{(totalFavoritedPlaylists > 0) ? totalFavoritedPlaylists : '' }} Favorited Playlists
      </div>
      <div class="col" v-for="(playlist, index) in favoritePlaylists" :key="index">
        <div>
          <Grid :item="playlist" :type="'playlist'" v-if="!loading && favoritePlaylists.length > 0"/>
        </div>
        <div v-if="loading" class="text-center mb-3 d-flex justify-content-center">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </div>
    </div>
    <div class="show-more musicbar_more-frd mt-0" v-if="!loading && totalFavoritedPlaylists > favoritePlaylists.length">
      <a href="javascript:;">
        <span class="show_more_bg">
           <svg width="9" height="8" viewBox="0 0 9 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
              <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round"></path>
           </svg>
            SEE ALL FAVORITED PLAYLISTS
        </span>
      </a>
    </div>

    <div v-if="totalCreatedPlaylists <= 0 && totalFavoritedPlaylists <= 0" class="placeholder_nav_music">
      <MyMusicEmptyCard
        @redirectFunction="redirectFunction"
        :title="'You haven’t favorited any Music, Playlists, Podcasts or Artists yet'"
        :text="'Go to Explore music and start favoriting music, Podcasts and Artists that you like '"
        :icon="'heart'"
        :buttonText="'EXPLORE MUSIC'"
      />
    </div>

  </div>
</template>

<script>
import Grid from "@/components/DesignGuide/Music/Components/Grid.vue";
import { getMyPlaylists, getUserPlaylists } from "@/apis/APIs";
import MyMusicEmptyCard from "@/components/Common/UiComponenets/MyMusicEmptyCard.vue";
export default {
  name: "Playlist",
  data(){
    return{
      loading:true,
      createdPlaylists : [],
      favoritePlaylists : [],
      totalCreatedPlaylists : null,
      totalFavoritedPlaylists : null,
      type: '',
    }
  },
  components:{
    Grid,
    MyMusicEmptyCard
  },
  mounted(){
    this.getUserPlaylists()
  },
  methods:{
    async getUserPlaylists(){
      try {
        this.loading = true
        let data = await getUserPlaylists(this.type,0, 4);
        this.createdPlaylists =  data.data.information.created.data;
        this.totalCreatedPlaylists =  data.data.information.created.total;
        this.favoritePlaylists = data.data.information.followed.data;
        this.totalFavoritedPlaylists = data.data.information.followed.total;
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
    },
    redirectFunction(){
      this.$router.push('/music/page/overview')
    },
  }
}
</script>

<style scoped>

</style>
