<template>
  <div class="container1">
    <div :class="['card weekly-top w-570',(song.isHovering == 1) ? 'open':'']"
         v-for="(song, index) in listData" :key="index">
      <div class="row no-gutters">
        <div class="pg-display px-0 line-1">
          <div class=" pg-cover "><img width="60" :src="getImage(song)" alt="Generic placeholder image"></div>
          <div class="play-button js-play-button new-play-button"
               @click="$event.currentTarget.classList.toggle('active')">
            <div class="svg-icon">
              <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="play-type-1">
                <path class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"></path>
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
                <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
              </svg>
            </div>
          </div>
          <div class="  pg-position-up">
            <div class="current-week">1</div>
          </div>
          <div class=" pg-description">
            <div class="first-line pg-first-line"><a href="#">{{ getArtist(song.page) }}</a></div>
            <div class="second-line pg-second-line"><a href="#">{{ song.title }}</a></div>
          </div>
          <div class="other d-flex pg-d-flex ">
            <div class="time pg-time">
              0:00
            </div>
            <div class="popularity pg-popularity">
              <div class="empty">
                <div class="column" v-for="n in 10" :key="n"></div>
              </div>
              <div class="full">
                <div class="column" v-for="n in 10" :key="n"></div>
              </div>
              <div class="count">
                100K
              </div>
            </div>
            <div class="actions d-flex align-items-center pg-action">
              <div class="wishlist js-wishlist pg-wishlist active" v-if="selectedFavourite[song.id] && selectedFavourite.length > 0"
                   v-on:click="setFavourite(song.id, song.favorite, song.mp3 ? 'song' : 'album')">
                <div class="svg-icon" v-on:click="setFavouriteArrayValues(song.id, false)">

                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1"
                          d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                          stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                </div>
              </div>
              <div class="wishlist js-wishlist pg-wishlist" v-else-if="!selectedFavourite[song.id] && selectedFavourite.length > 0"
                   v-on:click="setFavourite(song.id, song.favorite, song.mp3 ? 'song' : 'album')">
                <div class="svg-icon" v-on:click="setFavouriteArrayValues(song.id, true)">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1"
                          d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                          stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                </div>
              </div>
              <div class="more dropright pg-more-dropright">
                <div class="dropdown vertical more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false" data-display="static">
                  <div class="svg-icon more-position">
                    <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 3C1.79667 3 2.08668 2.91203 2.33335 2.7472C2.58003 2.58238 2.77229 2.34811 2.88582 2.07403C2.99935 1.79994 3.02906 1.49834 2.97118 1.20736C2.9133 0.916393 2.77044 0.649119 2.56066 0.43934C2.35088 0.229561 2.08361 0.0866999 1.79263 0.0288221C1.50166 -0.0290557 1.20006 0.000649374 0.925974 0.114181C0.651885 0.227712 0.417617 0.419971 0.252795 0.666644C0.087973 0.913318 -6.36721e-07 1.20333 -6.49689e-07 1.5C-6.67078e-07 1.89782 0.158035 2.27936 0.439339 2.56066C0.720644 2.84196 1.10217 3 1.5 3V3Z" fill="#47505B"/>
                      <path d="M1.5 9C1.79667 9 2.08668 8.91203 2.33335 8.7472C2.58003 8.58238 2.77229 8.34811 2.88582 8.07403C2.99935 7.79994 3.02906 7.49834 2.97118 7.20736C2.9133 6.91639 2.77044 6.64912 2.56066 6.43934C2.35088 6.22956 2.08361 6.0867 1.79263 6.02882C1.50166 5.97094 1.20006 6.00065 0.925974 6.11418C0.651885 6.22771 0.417617 6.41997 0.252795 6.66664C0.087973 6.91332 -6.36721e-07 7.20333 -6.49689e-07 7.5C-6.67078e-07 7.89782 0.158035 8.27936 0.439339 8.56066C0.720644 8.84196 1.10217 9 1.5 9V9Z" fill="#47505B"/>
                      <path d="M1.5 15C1.79667 15 2.08668 14.912 2.33335 14.7472C2.58003 14.5824 2.77229 14.3481 2.88582 14.074C2.99935 13.7999 3.02906 13.4983 2.97118 13.2074C2.9133 12.9164 2.77044 12.6491 2.56066 12.4393C2.35088 12.2296 2.08361 12.0867 1.79263 12.0288C1.50166 11.9709 1.20006 12.0006 0.925974 12.1142C0.651885 12.2277 0.417617 12.42 0.252795 12.6666C0.087973 12.9133 -6.36721e-07 13.2033 -6.49689e-07 13.5C-6.67078e-07 13.8978 0.158035 14.2794 0.439339 14.5607C0.720644 14.842 1.10217 15 1.5 15V15Z" fill="#47505B"/>
                    </svg>

                  </div>
                  <ListOption/>
                </div>
              </div>
              <div class="extend js-extend pg-extend">
                <div class="svg-icon" v-on:click="openDetailView(index)">
                  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-arrow-down">
                    <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters line-2">
        <div class="col">
          <div class="btn-group-social custom-cl">
            <button class="btn btn-information text">
              <div class="svg-icon">

                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                        stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information text">
              <div class="svg-icon">

                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                        stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information text">
              <div class="svg-icon">

                <svg class="mix-soc" width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1" d="M1 9.16667L1 3.33306L15 3.33306L10.9989 1" stroke="#071526"
                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path class="c-line-1" d="M15 8L15 12.9548L1 12.9548L5.81381 15" stroke="#071526" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information ">
              <div class="svg-icon">

                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M1 12.4358C1 12.4358 2.34885 6.17675 10.4363 5.97632C10.5603 4.31967 10.5603 2.65665 10.4363 1L18 7.83691L10.4363 15V9.74725C10.4363 9.74725 6.00177 8.4783 1 12.4358Z"
                        stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </button>
          </div>
        </div>
        <div class="col">
          <div class="btn-group-social right-side justify-content-end">
            <button class="btn btn-icon-border shop text mrs">
              <div class="svg-icon">
                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="shop-cart-v2">
                  <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="#D1433A"
                        stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="c-line-1"
                        d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                        stroke="#D1433A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <div class="price">$00</div>
            </button>
            <div class="btn btn-play-count ">
              <div class="svg-icon">
                <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="small-play-svg">
                  <path class="c-fill-1"
                        d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                        fill="#8B949F"></path>
                </svg>
              </div>
              <span>32.8k</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";
import Vue from "vue";
import {set_favourite_on_songs} from "../../../apis/APIs";

export default {
  data() {
    return {
      showSubMenu: 'none',
      showMore: false,
      selectedFavourite: []
    }
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    }
  },

  components: {
    ListOption
  },
  computed: {},
  mounted() {
    this.getFavouriteLists();
  },
  methods: {
    openDetailView: function (index) {
      if (this.listData[index]['isHovering'] == '0')
        this.listData[index]['isHovering'] = false;
      this.listData[index]['isHovering'] = !this.listData[index]['isHovering'];
    },
    getFavouriteLists: function() {
      for(let i = 0; i < this.listData.length; i++) {
        Vue.set(this.selectedFavourite, this.listData[i].id, this.listData[i].favorite);
      }
    },
    setFavouriteArrayValues: function (id, value) {
      Vue.set(this.selectedFavourite, id, value);
    },
    async setFavourite(id, favourite, type) {
      const formdata = new FormData();
      if (favourite) {
        if (id && type) {
          if (type == 'song') {
            formdata.append('id', id);
            formdata.append('object_type', 'song');
            formdata.append('action', 0);
          } else if (type == 'artist') {
            formdata.append('id', id);
            formdata.append('object_type', 'artist');
            formdata.append('action', 0);
          }
        }
      } else {
        if (id && type) {
          if (type == 'song') {
            formdata.append('id', id);
            formdata.append('object_type', 'song');
            formdata.append('action', 1);
          } else if (type == 'album') {
            formdata.append('id', id);
            formdata.append('object_type', 'album');
            formdata.append('action', 1);
          }
        }
      }
      console.log("Song Form Data", formdata);
      try {
        const res = await set_favourite_on_songs(formdata);
        const {success, message} = res.data || {}
      } catch (e) {
        //
      }
    }
  }
}
</script>
