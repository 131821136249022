<template>
  <div class="container_xxl">
    <div class="row">
      <div class="col">
        <h1 class="Page_title">News Feed</h1>
      </div>
    </div>
    <div class="feed_wrapper">
      <div class="center_centerbar_wraper_home" v-if="!getTrendingViewCheck">
        <div class="row">
          <div class="col">
            <div class="artist-main-premium-btns">
              <div class="btns-premium">
                <div class="news-feed-radio">
                  <input
                    type="radio"
                    id="radio-one-main"
                    name="switch-one-main"
                    value="yes"
                    checked="checked"
                  />
                  <label for="radio-one-main">Main</label>
                  <input
                    type="radio"
                    id="radio-two-main"
                    name="switch-one-main"
                    value="no"
                  />
                  <label for="radio-two-main">Premium</label>
                </div>
                <div class="artist-music-profile-select">
                  <div class="main-poset-select-box">
                    <ul>
                      <li
                        class="cursor-pointer"
                        v-on:click="optionOptions = true"
                      >
                        {{ filterName }}
                      </li>
                    </ul>
                    <span>
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L6.09612 5.67901L1 10.77"
                          stroke="#D1433A"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1>Music dicovere</h1>
      </div>
    </div>
  </div>
</template>


<script type="application/javascript">
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      message: "",
      addPhoto: false,
      register_error: false,
      picture: "",
      optionOptions: false,
      trending_limit : 10,
      filterName: 'All'
    };
  },
  watch: {
    user() {
      this.getUserPicture();
    },
  },
  components: {
},
  computed: {
    ...mapGetters({
      form_data: "home_map_to_props",
      user: "user_map_to_props",
    }),
    data() {
      const { data = [] } = this.form_data || {};

      return data && data.length ? data : [];
    },
    loading() {
      const { loading = false } = this.form_data || {};
      console.log(loading, " hello there testing")
      return loading ? data : false;
    },
    post_list() {
      return this.$store.getters.getPosts;
    },
    is_more_posts() {
      const allPosts = this.$store.getters.getTotalPosts; // all posts which are available in DB
      const PostsLenght = this.post_list.length; // postlist array
      return PostsLenght >= this.$store.getters.getPostsLimit || PostsLenght < allPosts;
    },
    previous_route() {
      return this.$store.getters.getArticlePreviousRoute;
    },
    getTrendingViewCheck() {
      return this.$store.getters.getTrendingViewCheck;
    },
    active_tab: {
      set(value) {
        this.$store.commit("SET_POST_ACTIVE_TAB", value);
      },
      get() {
        return this.$store.getters.getPostActiveTab;
      },
    },
  },
  mounted() {
    this.$store.commit('SET_POST_TYPE', '')
    if (this.previous_route === "home.favorites") {
      // this.$refs.liked_posts.click();
      // this.active_tab = "liked_posts";
      // this.$store.commit("SET_PREVIOUS_ROUTE", "");
      // this.showPosts("liked_posts");

      // saved posts
      this.$refs.saved_posts.click();
      this.$store.commit("SET_PREVIOUS_ROUTE", "");
    } else {
      this.active_tab = "discover_posts";
      this.showPosts("discover_posts");
    }

    this.$store.dispatch("getActivities");
    this.getGenres();
    this.$store.dispatch("fetchCountries");
    if (this.$route.query.Post == "yes" && this.$route.query.Type == "photo") {
      this.addPhoto = true;
    }
    if (this.user) {
      this.getUserPicture();
    }
    this.$store.dispatch("getTrendingList");
  },
  methods: {
    ...mapActions(["get_post_data"]),
    ...mapMutations(["updateSignupFormData", "updateSignupUIConstraints"]),
    closeRegisterModal: function () {
      this.$parent.closeModal();
    },
    goBack: function () {
      this.$parent.newModal("register-step-1");
    },
    updateGender: function (row) {
      this.gender = row.value;
      this.genderName = row.name;

      this.updateSignupFormData({
        [SIGNUP_FORM_GENDER]: row.value,
      });
    },
    updateAge: function (row) {
      this.UserAge = row;
    },
    getUserPicture() {
      const { user_data } = this.user;
      const { artwork_url } = user_data || "";
      this.picture = artwork_url;
    },
    showMore(tab_name) {
      if (tab_name === "discover_posts") {
        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        this.$store.dispatch("getPost", true);
      } else if (tab_name === "my_posts") {
        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        this.$store.dispatch("getUserPosts", {showMore: true, loader: false});
      } else if (tab_name === "liked_posts") {
        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        this.$store.dispatch("getUserLikedPost", true);
      }

      // show more post
    },
    setActiveTab(tab_name) {
      this.active_tab = tab_name;
      this.showPosts(tab_name);
    },
    showPosts(tab_name, type) {
      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.commit("GET_POSTS", []);
      if (tab_name === "discover_posts")
        this.$store.dispatch("getPost", false);
      else if (tab_name === "liked_posts")
        this.$store.dispatch("getUserLikedPost", false);
      else if (tab_name === "my_posts") {
        this.$store.commit("SET_OTHER_USER_ID", this.user.id);
        this.$store.dispatch("getUserPosts", {showMore: false, loader: false});
      }
      else if (tab_name === "save_posts") {
        this.$store.dispatch("getSavedPosts", false);
      }
    },
    showMoreTrending() {
      this.trending_limit += 10
      this.$store.dispatch(getTrendingList, 0, this.trending_limit)
    },
    async getGenres() {
      if (
        !this.$store.getters.getGenres &&
        !this.$store.getters.getGenres.length > 0
      ) {
        await this.$store.dispatch("get_geners");
      }
    },
    closeModal: function () {
      this.optionOptions = false;
    },
    filterPosts(value) {
      this.filterName = (value) ? value.charAt(0).toUpperCase() + value.slice(1) : 'All'
      this.showPosts(this.active_tab);
    },
  },
};
</script>

<style>
.edit-share-section {
  border-radius: 4px !important;
  margin-bottom: 4px;
}
</style>
