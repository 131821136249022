<template>
    <div class="pol-detail">
        <label id="base-select-container" @click="selectReason(option.option)" class="audience-container">
            <p class="w-100 m-0 pt-1 sponsors-title">
                {{ option.option }}
            </p>
            <div class="pt-1 radio-btn">
                <input class="radio" type="radio" name="audience-radio" :value="option.option"
                    :id="'radio-btn-' + option.id" :key="option.id" aria-label="..." />
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: "BaseSelect",
    props: {
        option: {
            type: Object,
            required: true
        }
    },
    methods: {
        async onPress(e) {
            if (e) e.stopPropagation();
        },
        selectReason(reason) {
            this.$emit("selectReason", reason);
        }
    },
    computed: {},
    mounted() {
    }
};
</script>

<style lang="scss" scoped>
.audience-container {
    /* Rectangle 4729 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0% 6%;
    height: 50px;
    width: 100%;
    /* Light grey (New) */
    border: 1px solid #E6EAF0;
    border-radius: 4px;

    .hover-icon {
        display: none;
    }

    &:hover {
        transition: ease-in-out 0.2s;
        border: 1px solid #D1433A;

        .icon {
            stroke: none;

            path {
                fill: #D1433A;
            }

            circle {
                stroke: #D1433A;
            }
        }


    }

    .sponsors-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #071526;
    }

    .radio-btn {
        .radio {
            width: 16px;
            height: 16px;
            border: 5px solid red;
            border-radius: 50%;

            &:checked {
                //width: 30px;
                //height: 30px;
                border: 1px solid #D1433A;
                border-radius: 100%;
                background: #fff;
            }
        }
    }
}

</style>