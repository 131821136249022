<template>
  <div class="wr_NFTsCards">
    <h5>{{ currentItem }} {{ title }}</h5>
    <!--  -->
    <!--  -->
    <div class="wr_cards">
      <div
        class="wrapNFTsCard"
        v-for="(song, index) in listData.slice(0, currentShowNFTsCard)"
        :key="song.id"
        :style="(index + 1) % 4 === 0 ? null : { marginRight: '30px' }"
      >
        <div
          class="imgAlbum"
          :style="{
            backgroundImage: `url(${require('@/assets/img/' + song.img)})`,
          }"
        >
          <div class="bg_opacity"></div>
          <div class="content">
            <div class="heart">
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1991 2.00171C12.8748 1.68413 12.4898 1.43221 12.0661 1.26034C11.6423 1.08846 11.1881 1 10.7295 1C10.2708 1 9.8166 1.08846 9.39285 1.26034C8.9691 1.43221 8.58408 1.68413 8.25977 2.00171L7.58631 2.66109L6.91286 2.00171C6.25341 1.38177 5.37204 1.03964 4.45788 1.04873C3.54372 1.05783 2.66963 1.41743 2.02319 2.05036C1.37675 2.68329 1.00947 3.53911 1.00018 4.43416C0.990891 5.32921 1.34033 6.19216 1.9735 6.83783L2.64696 7.49721L7.58631 12.3333L12.5257 7.49721L13.1991 6.83783C13.5235 6.5203 13.7808 6.14333 13.9563 5.72843C14.1319 5.31354 14.2222 4.86885 14.2222 4.41977C14.2222 3.97068 14.1319 3.52599 13.9563 3.1111C13.7808 2.69621 13.5235 2.31923 13.1991 2.00171V2.00171Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="bag">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="#D1433A"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.20675 15C1.58001 15 1.10774 14.4301 1.22416 13.8142L3.49265 1.81425C3.58193 1.34196 3.99459 1 4.47525 1H15.5236C16.0042 1 16.4168 1.34191 16.5061 1.81416L18.7757 13.8142C18.8922 14.43 18.4199 15 17.7931 15H2.20675Z"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5391 4.46875V6.07553C12.446 6.6613 12.1243 7.18905 11.6415 7.54793C11.1587 7.90682 10.5523 8.0689 9.94981 8.00014C9.34728 8.0689 8.74092 7.90682 8.25811 7.54793C7.77529 7.18905 7.45359 6.6613 7.36057 6.07553V4.46875"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="menu">
              <svg
                width="15"
                height="3"
                viewBox="0 0 15 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.22095 2.99854C3.01823 2.99854 3.66456 2.35222 3.66456 1.55494C3.66456 0.757653 3.01823 0.111328 2.22095 0.111328C1.42367 0.111328 0.777344 0.757653 0.777344 1.55494C0.777344 2.35222 1.42367 2.99854 2.22095 2.99854Z"
                  fill="white"
                />
                <path
                  d="M7.79517 2.99854C8.59245 2.99854 9.23878 2.35222 9.23878 1.55494C9.23878 0.757653 8.59245 0.111328 7.79517 0.111328C6.99789 0.111328 6.35156 0.757653 6.35156 1.55494C6.35156 2.35222 6.99789 2.99854 7.79517 2.99854Z"
                  fill="white"
                />
                <path
                  d="M13.3694 2.99854C14.1667 2.99854 14.813 2.35222 14.813 1.55494C14.813 0.757653 14.1667 0.111328 13.3694 0.111328C12.5721 0.111328 11.9258 0.757653 11.9258 1.55494C11.9258 2.35222 12.5721 2.99854 13.3694 2.99854Z"
                  fill="white"
                />
              </svg>
              <div class="menu_modal">
                <div>Buy</div>
                <div>Favorite</div>
                <div>
                  Rate
                  <span>
                    5.0
                    <svg
                      width="6"
                      height="11"
                      viewBox="0 0 6 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5.3 5.231L1 9.833"
                        stroke="#47505B"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
                <div>Repost</div>
                <div>
                  Share
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5.3 5.231L1 9.833"
                      stroke="#47505B"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wr_main_info">
          <div class="wr_info">
            <h6>{{ song.artistName }}</h6>
            <p class="singlName">{{ song.singlName }}</p>
            <div class="wr_price_and_time">
              <div class="price">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.97269 8.98462L0.289062 6.81075L3.97044 12.0004L7.65594 6.81075L3.97044 8.98462H3.97269ZM4.02744 0L0.345313 6.11138L4.02744 8.289L7.71106 6.11362L4.02744 0Z"
                    fill="#47505B"
                  />
                </svg>
                {{ song.price }}
              </div>
              <div class="hours">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM7 12.6C3.9123 12.6 1.4 10.0877 1.4 7C1.4 3.9123 3.9123 1.4 7 1.4C10.0877 1.4 12.6 3.9123 12.6 7C12.6 10.0877 10.0877 12.6 7 12.6Z"
                    fill="#8B949F"
                  />
                  <path
                    d="M7.70078 3.5H6.30078V7.2898L8.60588 9.5949L9.59568 8.6051L7.70078 6.7102V3.5Z"
                    fill="#8B949F"
                  />
                </svg>
                {{ song.hours }}
              </div>
            </div>
          </div>
          <div class="wr_buy_heart">
            <p>Buy now</p>
            <div>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.1991 2.00171C12.8748 1.68413 12.4898 1.43221 12.0661 1.26034C11.6423 1.08846 11.1881 1 10.7295 1C10.2708 1 9.8166 1.08846 9.39285 1.26034C8.9691 1.43221 8.58408 1.68413 8.25977 2.00171L7.58631 2.66109L6.91286 2.00171C6.25341 1.38177 5.37204 1.03964 4.45788 1.04873C3.54372 1.05783 2.66963 1.41743 2.02319 2.05036C1.37675 2.68329 1.00947 3.53911 1.00018 4.43416C0.990891 5.32921 1.34033 6.19216 1.9735 6.83783L2.64696 7.49721L7.58631 12.3333L12.5257 7.49721L13.1991 6.83783C13.5235 6.5203 13.7808 6.14333 13.9563 5.72843C14.1319 5.31354 14.2222 4.86885 14.2222 4.41977C14.2222 3.97068 14.1319 3.52599 13.9563 3.1111C13.7808 2.69621 13.5235 2.31923 13.1991 2.00171Z"
                  fill="white"
                  stroke="#8B949F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ song.heart }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <button
      @click="showCurrentCards(listData, currentShowNFTsCard)"
      v-if="listData.length > currentShowNFTsCard"
    >
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.24219 0.689453V7.01945"
          stroke="#071526"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 3.85449H7.48702"
          stroke="#071526"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      SEE ALL NFT’s IN COLLECTION
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.33333 1.5C2.11232 1.5 1.90036 1.5878 1.74408 1.74408C1.5878 1.90036 1.5 2.11232 1.5 2.33333V11.6667C1.5 11.8877 1.5878 12.0996 1.74408 12.2559C1.90036 12.4122 2.11232 12.5 2.33333 12.5H11.6667C11.8877 12.5 12.0996 12.4122 12.2559 12.2559C12.4122 12.0996 12.5 11.8877 12.5 11.6667V8.33333C12.5 8.05719 12.7239 7.83333 13 7.83333C13.2761 7.83333 13.5 8.05719 13.5 8.33333V11.6667C13.5 12.1529 13.3068 12.6192 12.963 12.963C12.6192 13.3068 12.1529 13.5 11.6667 13.5H2.33333C1.8471 13.5 1.38079 13.3068 1.03697 12.963C0.693154 12.6192 0.5 12.1529 0.5 11.6667V2.33333C0.5 1.8471 0.693154 1.38079 1.03697 1.03697C1.38079 0.693154 1.8471 0.5 2.33333 0.5H5.66667C5.94281 0.5 6.16667 0.723858 6.16667 1C6.16667 1.27614 5.94281 1.5 5.66667 1.5H2.33333ZM9.20473 0.808658C9.28212 0.621821 9.46444 0.5 9.66667 0.5H12.9999H13C13.0678 0.5 13.1324 0.513493 13.1914 0.537939C13.2504 0.562339 13.3056 0.598508 13.3536 0.646447C13.4015 0.694385 13.4377 0.749636 13.4621 0.808609C13.4842 0.862233 13.4967 0.918935 13.4994 0.976015C13.4998 0.984005 13.5 0.992002 13.5 1V1.00049V4.33333C13.5 4.53556 13.3782 4.71788 13.1913 4.79527C13.0045 4.87266 12.7894 4.82989 12.6464 4.68689L11.3333 3.37377L8.35355 6.35355C8.15829 6.54882 7.84171 6.54882 7.64645 6.35355C7.45118 6.15829 7.45118 5.84171 7.64645 5.64645L10.6262 2.66667L9.31311 1.35355C9.17011 1.21055 9.12734 0.995495 9.20473 0.808658Z"
          fill="#D1433A"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    listData: Array,
    currentItem: Number,
    title: String,
  },
  data() {
    return {
      showModal: false,
      currentShowNFTsCard: 4,
    };
  },
  methods: {
    showModalMenu() {
      this.showModal = !this.showModal;
    },
    showCurrentCards(array, current) {
      if (array.length > current) {
        this.currentShowNFTsCard = current + 4;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_NFTsCards {
  .wr_cards {
    display: flex;
    flex-wrap: wrap;
  }
  .wrapNFTsCard {
    &:hover {
      box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
      & > .imgAlbum {
        .content {
          display: flex;
        }
        .bg_opacity {
          display: block;
        }
      }
      & > .wr_main_info {
        .wr_buy_heart {
          p {
            color: #D1433A;
          }
        }
      }
    }
    cursor: pointer;
    margin: 0 0px 20px 0;
    background: #ffffff;
    border-radius: 4px;
    .imgAlbum {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 170px;
      height: 170px;
      .content {
        width: 100%;
        display: none;
        justify-content: space-between;
        padding: 0 33px;
        align-items: center;
      }
      .bg_opacity {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(7, 21, 38, 0.3588) 0%,
          rgba(7, 21, 38, 0.69) 100%
        );
        border-radius: 4px 4px 0px 0px;
      }
      .menu {
        position: relative;
        &:hover {
          .menu_modal {
            display: block;
          }
        }
        .menu_modal {
          display: none;
          padding: 10px 0;
          position: absolute;
          width: 170px;
          height: 170px;
          background: #ffffff;
          box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
          border-radius: 4px;
          z-index: 1;
          left: 5px;
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 17px 0 19px;
            height: 30px;
            width: 100%;
            font-family: HelveticaNeueMedium;
            font-size: 13px;
            line-height: 16px;
            color: #071526;
            span {
              display: flex;
              align-items: center;
              svg {
                margin-left: 10px;
              }
            }
            &:hover {
              background: #D1433A1A;
              color: #D1433A;
            }
          }
        }
      }
      .bag {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #D1433A;
        border-radius: 4px;
        &:hover {
          svg {
            fill: #ffffff;
            :last-child {
              stroke: #D1433A;
            }
          }
        }
      }
    }
    .wr_info {
      padding: 0 10px 20px;
      box-shadow: 0px 4px 8px rgba(71, 80, 91, 0.1);
      h6 {
        font-family: HelveticaNeueBold;
        font-size: 12px;
        line-height: 15px;
        color: #071526;
        margin: 8px 0 6px 0;
      }
      .singlName {
        font-family: HelveticaNeueNormal;
        font-size: 12px;
        line-height: 14px;
        color: #47505b;
        letter-spacing: 1px;
      }
      .wr_price_and_time {
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .price {
          background: #D1433A1A;
          border-radius: 2px;
          width: 60px;
          height: 20px;
          padding: 0 7px;
          font-family: HelveticaNeueBold;
          font-weight: normal;
        }
        .hours {
          font-family: HelveticaNeueNormal;
          font-size: 10px;
          line-height: 125%;
          color: #47505b;
          svg {
            margin-right: 7px;
          }
        }
      }
    }
    .wr_buy_heart {
      display: flex;
      padding: 13px 10px;
      justify-content: space-between;
      align-items: center;
      div {
        font-family: HelveticaNeueMedium;
        font-weight: normal;
        display: flex;
        align-items: center;
        color: #8b949f;
        font-size: 11px;
        svg {
          margin-right: 10px;
        }
      }
      p {
        color: #ffffff;
        margin: 0;
      }
    }
  }
  .NFTsCards {
    display: flex;
    flex-wrap: wrap;
  }
  h5 {
    font-family: HelveticaNeueBold;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #071526;
    margin: 42px 0 16px;
  }
  button {
    margin-top: 20px;
    width: 100%;
    display: flex;
    outline: none;
    background: #e6eaf0;
    border-radius: 4px;
    border: none;
    height: 30px;
    justify-content: center;
    align-items: center;
    font-family: HelveticaNeueBold;
    font-size: 10px;
    line-height: 12px;
    color: #071526;
    svg {
      margin: 0 10px;
    }
  }
}
</style>
