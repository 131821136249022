import { Player } from "@twilio/live-player-sdk";

const { connect } = require('twilio-video');

export default {
  data() {
    return {
      twilio_room: null
    }
  },
  watch: {
    twilio_room(value) {
      this.twilioRoom.on('participantConnected', participant => {
        console.log(`Participant connected: ${participant.identity}`);
        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            return document.getElementById('remote-media-div').appendChild(track.attach());
          }
        });
      });

      this.twilioRoom.on('participantDisconnected', participant => {
        console.log(`Participant disconnected: ${participant.identity}`);
      });
    }
  },
  computed: {
    getTwilioAccessToken () {
      return this.$store.getters.getTwilioAccessToken
    },
    getTwilioGrantAccessRoomToken () {
      return this.$store.getters.getGrantAccessToken
    },
    getTwilioRoom () {
      return this.$store.getters.getTwilioRoom
    },
    getTwilioRoomName () {
      return this.$store.getters.getTwilioRoom.unique_name
    },
    getStreamPlayerId: {
      set(value) {
        this.$store.commit('SET_TWILIO_STREAM_PLAYER_ID', value)
      },
      get() {
        return this.$store.getters.getTwilioStreamPlayerId
      }
    },
    twilioPlayer: {
      set(value) {
        this.$store.commit('SET_TWILIO_PLAYER_DATA', value)
      },
      get() {
        return (this.$store.getters.getTwilioPlayer) ? this.$store.getters.getTwilioPlayer : null
      }
    },
    twilioRoom: {
      set(value) {
        this.$store.commit('SET_TWILIO_ROOM_CONNECT_DATA', value)
        this.twilio_room = value
      },
      get() {
        return (this.$store.getters.getTwilioRoomConnectData) ? this.$store.getters.getTwilioRoomConnectData : ''
      }
    },
    grantRoomToken() {
      return this.$store.getters.getGrantAccessToken
    },
    getTwilioPlaybackGrantId() {
      return this.$store.getters.getTwilioPlaybackGrantId
    },
    getGrantAccessToken(){
      return this.$store.getters.getTwilioGrantAccessToken
    }
  },
  methods: {
    checkIfBrowserIsSupported () {
      if (Player.isSupported) {
        console.log('Your browser is supported for Twilio');
      } else {
        console.log('Your browser is not supported for Twilio');
      }
    },
    async callTwilioFunctionsToAccessRoom(room_sid) {
      const {status, room} = await this.$store.dispatch('getTwilioAudioRoom', room_sid)
    },
    async createTwilioGrantAccessRoom() {
      const payload = {
        roomName: this.getTwilioRoomName,
        user_id: this.userId
      }
      const {status} = await this.$store.dispatch('createTwilioGrantAccessRoom', payload)
    },
    async createTwilioStreamPlayer() {
      const {status, stream_id } = await this.$store.dispatch('createTwilioStreamPlayer')
      return stream_id
    },
    async createTwilioMediaProcessor() {
      const payload = {
        roomName: this.getTwilioRoomName,
        sid: this.getStreamPlayerId,
        user_id: this.userId
      }
      const {status, data} = await this.$store.dispatch('createTwilioMediaProcessor', payload)
      return data
    },
    async getTwilioPlaybackGrant() {
      const {status, information} = await this.$store.dispatch('getTwilioPlaybackGrant', this.getStreamPlayerId)
    },
    async getTwilioGrantAccessToken() {
      const {status, data} = await this.$store.dispatch('getTwilioGrantAccessToken', this.getTwilioPlaybackGrantId)
    },
    async connectToTwilioRoom () {
      await connect(this.grantRoomToken, {
        audio: true,
        video: { width: 1070, height: 400 }
      }).then(room => {
        this.twilioRoom = room
      })
    },
    async connectToTwilioPlayer (access_token) {
      const {
        host,
        protocol,
      } = window.location;
      const player = await Player.connect(access_token, {
        playerWasmAssetsPath: `${protocol}//${host}/livePlayer`,
        video: { width: 1070, height: 400 }
      });
      console.log('Checking Player ==>', player)
      this.twilioPlayer = player
      console.log('Checking Player ==>', this.twilioPlayer)
      player.on("stateChanged", (state) => {
        console.log('Checking Player Records ==>', player)
        if (state === "ready") {
          player.play();
        }
      });
      return player
    },
    async endStream () {
      if (this.twilioRoom) {
        await this.twilioRoom.disconnect()
        this.twilioRoom = null
        await this.vueNotification('Club Room', 'Streaming ended successfully!', 'success')
        await this.endMediaProcessor()
        await this.endPlayerStream()
      }
    },
    async leaveStream() {
      if (this.twilioPlayer) {
        this.twilioPlayer.disconnect()
        this.twilioPlayer = null
      }
    },
    muteAndUnmuteOwnMice (check) {
      this.player.isMuted = check;
    },
    async endMediaProcessor() {
      await this.$store.dispatch('endTwilioMediaProcessor', this.getStreamPlayerId)
    },
    async endPlayerStream() {
      await this.$store.dispatch('endTwilioPlayerStream', this.getStreamPlayerId)
    },
    async startStreaming (channel, room_sid) {
      console.log('Room Sid ==>', room_sid);
      await this.callTwilioFunctionsToAccessRoom(room_sid)
      await this.createTwilioGrantAccessRoom()
      await this.connectToTwilioRoom()
      const stream_id = await this.createTwilioStreamPlayer()
      await this.createTwilioMediaProcessor()
      await this.setStreamSidAndStreamingStatusOfTwilioRoomInGetStreamRoom(channel, stream_id, true)
      await this.vueNotification('Club Room', 'Streaming started successfully!', 'success')
    },
    async watchStreaming (player_stream_id) {
      this.getStreamPlayerId = player_stream_id
      if (this.getStreamPlayerId) {
        await this.getTwilioPlaybackGrant()
        await this.getTwilioGrantAccessToken()
        return await this.connectToTwilioPlayer(this.getGrantAccessToken)
      }
    },
    async joinAsSpeaker(room_sid) {
      await this.callTwilioFunctionsToAccessRoom(room_sid)
      await this.createTwilioGrantAccessRoom()
      await this.connectToTwilioRoom()
    }
  }
}
