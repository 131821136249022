<template>
  <div class="people-list">
    <div class="people-heading">
      People
    </div>
    <div class="people-body" v-if="people_list.length > 0">
      <div class="main-loop-div" v-for="(people, index) in people_list" :key="index">
        <div class="people-alphabet">
          {{ people.group }}
        </div>
        <div v-for="(people_detail, index1) in people.children" :key="index1" class="people-tile">
          <div class="user-picture">
            <div class="img-wrapper">
              <div class="img-cover">
                <img :src="(people_detail && people_detail.image) ? people_detail.image : ''" alt="" />
              </div>
              <div v-if="people_detail && people_detail.online" class="online">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" fill="#1CEC9E" stroke="white" stroke-width="2"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="user-name cursor-pointer" @click="createSingleChat($event, people_detail)">
            {{ (people_detail && people_detail.name) ? people_detail.name : 'User Name' }}
          </div>
          <div class="option-dropdown cursor-pointer">
            <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z" fill="#8B949F"/>
              <path d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z" fill="#8B949F"/>
              <path d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z" fill="#8B949F"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeopleListComponent.vue",
  props: {
    search: {
      type:String,
      default: ''
    }
  },
  watch: {
    async search () {
      if (this.search.length > 0) {
        this.searchPeople()
      } else {
        await this.getAllUsers()
      }
    }
  },
  data() {
    return {
      people_list: []
    }
  },
  async mounted(){
    await this.getAllUsers()
  },
  computed:{
    getStreamClient() {
      return this.$store.getters.getGetStreamChatUser;
    },
    streamUser() {
      return this.$store.getters.user_map_to_props.user_data;
    },
  },
  methods:{
    async getAllUsers(){
      const Ids = [];
      Ids.push(String(this.streamUser.id));
      const response = await this.getStreamClient.queryUsers(
        { role: "user",
          id: { $nin: Ids } },
        { name: 1 },
        { presence: true }
      );
      let data = response.users.reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0].toUpperCase();
        // if there is no property in accumulator with this letter create it
        if(!r[group]) r[group] = {group, children: [e]}
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {})

      // since data at this point is an object, to get array of values
      // we use Object.values method
      const result = Object.values(data)
      this.people_list = result
    },
    searchPeople(){
      let searchWord = this.search
      const people = this.people_list
      const result = people.map((e) => {
        return {
          group: (e.children.filter(o => o.name.toLowerCase().includes(searchWord.toLowerCase())).length > 0) ? e.group : '',
          children: e.children.filter(o => o.name.toLowerCase().includes(searchWord.toLowerCase()))
        }
      })
      // people.filter(o => o.children.filter(e => e.name.toLowerCase().includes(searchWord.toLowerCase())))
      this.people_list = result
    },
    async createSingleChat(event, friend) {
      event.stopPropagation()
      if (friend) {
        const { active_channel, id, find_channel } = await this.checkIfChannelExist(friend);
        await this.openFullViewChat(event, 'single', friend, (find_channel === true) ? active_channel : {});
      }
    }
  }
};
</script>

<style scoped lang="scss">
.people-list{

  .people-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #071526;
    opacity: 0.9;
    margin-bottom: 16px;
  }

  .people-body {
    height: 520px;
    overflow-y: auto;

    .main-loop-div {
      margin-top: 26px;

      &:first-child {
        margin-top: unset;
      }

      .people-alphabet {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #D1433A;
        margin-bottom: 17px;
        text-transform: uppercase;
      }

      .people-tile {
        width: inherit;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .user-picture {
          display: flex;

          .img-wrapper {
            margin: auto;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: end;

            .img-cover {
              height: inherit;
              width: inherit;

              img {
                width: inherit;
                border-radius: 50%;
              }
            }

            .online {
              width: 12px;
              height: 12px;
              margin-left: -10px;
            }
          }

        }
        .user-name {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #47505B;
          margin-left: 11px;
        }
        .option-dropdown {
          margin-left: auto;
          display: flex;
          margin-right: 13px;

          svg {
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.people-alphabet:not(:first-child) {
  margin-top: 24px;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4ccd6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4ccd6;
}
</style>
