<template>
  <div>
    <section v-if="seeEmpty">
      <div class="wr_bank_detail">
        <article>
          <span
            ><svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.421656"
                y="30.7342"
                width="31.2024"
                height="0.843311"
                rx="0.421656"
                stroke="#95B3D7"
                stroke-width="0.843311"
              />
              <mask id="path-2-inside-1_572_155967" fill="white">
                <rect
                  x="1.6875"
                  y="25.2539"
                  width="28.6725"
                  height="3.37323"
                  rx="1"
                />
              </mask>
              <rect
                x="1.6875"
                y="25.2539"
                width="28.6725"
                height="3.37323"
                rx="1"
                stroke="#95B3D7"
                stroke-width="3.37323"
                mask="url(#path-2-inside-1_572_155967)"
              />
              <rect
                x="6.75"
                y="11.7598"
                width="1.68662"
                height="10.1197"
                rx="0.843308"
                stroke="#95B3D7"
                stroke-width="3"
              />
              <rect
                x="23.6094"
                y="11.7598"
                width="1.68662"
                height="10.1197"
                rx="0.843309"
                stroke="#95B3D7"
                stroke-width="3"
              />
              <rect
                x="15.1797"
                y="11.7598"
                width="1.68662"
                height="10.1197"
                rx="0.843309"
                stroke="#95B3D7"
                stroke-width="3"
              />
              <path
                d="M15.7626 0.0547896C15.9321 -0.0182636 16.1153 -0.018263 16.2848 0.0547901L29.794 5.87733C30.6834 6.26065 30.4703 7.96083 29.5329 7.96083H2.51453C1.57713 7.96083 1.36406 6.26065 2.25342 5.87733L15.7626 0.0547896Z"
                fill="#95B3D7"
              />
            </svg>
          </span>
          <div>
            <h5>Add Your Bank Details</h5>
            <p>
              Please add your bank details so as to sell tickets directly on the
              platform.
            </p>
          </div>
        </article>
        <button class="blue_btn for_hover_blue_btn">Add Bank Details</button>
      </div>
      <EmptyDataCard
        :item="emptyCartData"
        @buttonClick="createEventsModal = true"
      />
    </section>
    <div class="wr_EME_tickets">
      <section class="w-770">
        <div class="wr_capacity">
          <div>Event Capacity: <span>Unlimited</span></div>
          <button class="three_dots_menustyle for_hover_gray_btn">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3335 2.00053C11.5086 1.82543 11.7165 1.68654 11.9452 1.59178C12.174 1.49702 12.4192 1.44824 12.6668 1.44824C12.9145 1.44824 13.1597 1.49702 13.3884 1.59178C13.6172 1.68654 13.8251 1.82543 14.0002 2.00053C14.1753 2.17562 14.3142 2.38349 14.4089 2.61227C14.5037 2.84104 14.5524 3.08624 14.5524 3.33386C14.5524 3.58148 14.5037 3.82668 14.4089 4.05545C14.3142 4.28423 14.1753 4.4921 14.0002 4.66719L5.00016 13.6672L1.3335 14.6672L2.3335 11.0005L11.3335 2.00053Z"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            EDIT
          </button>
        </div>
        <EMEventTicketCard
          v-for="item in dashboardData"
          :key="item.id"
          :ticket="item"
          :tab="'TICKET'"
        />
      </section>
      <section class="w-370">
        <div class="create-event-btn">
          <button>
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
                fill="#D1433A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025V5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025V5.50025Z"
                fill="#D1433A"
              />
            </svg>
            CREATE NEW TICKET
          </button>
        </div>
        <div class="wr_tickets_detail_info">
          <ul>
            <li>
              <h6>TICKETS SOLD</h6>
              <span>10</span>
            </li>
            <li>
              <h6>INCOME</h6>
              <span>$140</span>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import EMEventTicketCard from "./../EMElement/EMEventTicketCard.vue";
import EmptyDataCard from "@/components/Common/UiComponenets/EmptyDataCard.vue";
export default {
  components: {
    EMEventTicketCard,
    EmptyDataCard,
  },
  data() {
    return {
      seeEmpty: false,
      createEventsModal: false,
      emptyCartData: {
        title: "Let’s create tickets",
        description:
          "You can create paid, free or donation tickets with unique timeframes.",
        icon: require(`@/assets/img/label.png`),
        buttonText: "CREATE NEW TICKET",
      },
      dashboardData: [
        {
          id: 1,
          tiketType: "Early Bird Tickets",
          status: "On Sale",
          date: "Ends Jul 24, 2020 at 7:00 PM",
          price: 0,
          quantity: 0,
        },
        {
          id: 2,
          tiketType: "General Admission",
          status: "Sold Out",
          date: "Ends Jul 24, 2020 at 7:00 PM",
          price: 14,
          quantity: 10,
        },
        {
          id: 3,
          tiketType: "Final Release Tickets",
          status: "Waiting",
          date: "Ends Jul 24, 2020 at 7:00 PM",
          price: 15,
          quantity: 5,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wr_bank_detail {
  margin: 30px 0;
  background: #ffffff;
  border: 1px solid #D1433A;
  border-radius: 4px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  article {
    display: flex;
    align-items: center;
    color: #071526;
    span {
      margin-right: 25px;
    }
    div {
      h5 {
        font-size: 15px;
        font-family: HelveticaNeueBold;
        font-weight: 700;
        margin: 0;
      }
      p {
        font-family: HelveticaNeueNormal;
        letter-spacing: 0.7px;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
.wr_EME_tickets {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .wr_capacity {
    padding: 0 20px;
    height: 50px;
    background: #f9fafb;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 15px;
    font-family: HelveticaNeueBold;
    color: #47505b;
    div {
      span {
        color: #000000;
      }
    }
    button {
      font-family: HelveticaNeueBold;
      width: 150px;
      border: none;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #47505b;
      svg {
        margin-right: 10px;
      }
    }
  }
  .wr_tickets_detail_info {
    background: #fff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    padding: 20px 20px 10px;
    ul {
      padding: 0;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      list-style-type: none;
      font-weight: 700;
      font-family: HelveticaNeueBold;
      margin: 0;
      li {
        margin-right: 50px;
      }
      h6 {
        display: flex;
        align-items: center;
        font-size: 11px;
        line-height: 13px;
        color: #8b949f;
        svg {
          margin-left: 7px;
        }
      }
      span {
        font-size: 36px;
        line-height: 34px;
        color: #47505b;
        opacity: 0.9;
      }
    }
  }
}
</style>