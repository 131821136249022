import Vue from "vue";
import { get_geners_list, getArticleCategories, getCountries, getPostsTagsList, getCollaboratorsRoles } from "../apis/APIs";
import * as Utils from "../components/utils/Utils";
import {
    ERROR,
    SUCCESS,
    SYSTEM_DATA_IS_AUTHENTICATED,
    SYSTEM_DATA_IS_WELCOME_PAGE,
    SYSTEM_DATA_KEY,
    SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS,
    LOADING,
    REFRESH_TOKEN,
    GENRES,
    ARTICLE_POST_CATEGORIES,
    LIST,
    LAST_FETCH,
    POST_TAGS_LIST,
    COUNTRIES,
    COUNTRIES_LOADING,
    COLLABORATOR_ROLES, TOAST, TOAST_SHOW, TOAST_TITLE, TOAST_TEXT, TOAST_DELAY, TOAST_TYPE
} from "./types";
import moment from "moment-timezone";

const INITIAL_STATE = {
    [SYSTEM_DATA_KEY]: {
        [SYSTEM_DATA_IS_AUTHENTICATED]: false,
        [SYSTEM_DATA_IS_WELCOME_PAGE]: false,
        [SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS]: ""
    },
    [LOADING]:false,
    [ARTICLE_POST_CATEGORIES]: {
        [LIST]:[],
        [LAST_FETCH]:null
    },
    [POST_TAGS_LIST]: {
        [LIST]:[],
        [LAST_FETCH]:null
    },
    [GENRES]: {
        [LIST]:[],
        [LAST_FETCH]:null
    },
    [COUNTRIES]: [],
    [COUNTRIES_LOADING]: false,
    [COLLABORATOR_ROLES]: {
        [LIST]:[],
        [LAST_FETCH]:null,
        [LOADING]:false
    },
    [TOAST]: {
        [TOAST_SHOW]: false,
        [TOAST_TITLE]: '',
        [TOAST_TEXT]: '',
        [TOAST_DELAY]: 3000,
        [TOAST_TYPE]: ''
    }
};

export const state = {...INITIAL_STATE};

export const actions = {
    async get_geners({state, commit, dispatch}, body) {
        return new Promise(async (resolve, reject) => {
            try {
                if(await dispatch('tagsLastFetchedPassedSixHours')) {
                    const res = await get_geners_list();
                    const { success, message, information } = res.data || {};
                    if (success === 1) {
                        commit('SET_GENRES', information)
                        commit('SET_TAGS_LAST_FETCH', moment().format())
                        resolve({
                            status: SUCCESS,
                            message: message,
                            information
                        });
                    } else {
                        reject({
                            status: ERROR,
                            message: message ? message : "Please try again."
                        });
                    }
                }
            } catch (error) {
                // dispatch('loading', false)
                Utils.log("EditProfile ===> error", error);
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async loading({state,commit},payload){
        await commit("CHANGE_LOADING_STATE", payload);
    },
    async getArticleCategories({state, commit,dispatch}) {
        try {
            if(await dispatch('categoriesLastFetchedPassedSixHours'))
            {
                const {data} = await getArticleCategories();
                const {information} = data
                commit('SET_ARTICLE_CATEGORIES', information)
                commit('SET_ARTICLE_CATEGORIES_LAST_FETCH', moment().format())
            }


        } catch (e) {
            console.error(e)
        }

    },
    async categoriesLastFetchedPassedSixHours({state}){
        return !(state[ARTICLE_POST_CATEGORIES][LAST_FETCH] && (moment().diff(moment(state[ARTICLE_POST_CATEGORIES][LAST_FETCH]),'hours')< 6 ))
    },
    async getPostTags({state, commit,dispatch}) {
        try {
            if(await dispatch('tagsLastFetchedPassedSixHours')) {
                const { data } = await getPostsTagsList();
                const { information } = data
                commit('SET_TAGS_LIST', information)
                commit('SET_GENRE_CATEGORIES_LAST_FETCH', moment().format())
            }

        } catch (e) {
            console.error(e)
        }

    },
    async tagsLastFetchedPassedSixHours({state}){
        return !(state[POST_TAGS_LIST][LAST_FETCH] && (moment().diff(moment(state[POST_TAGS_LIST][LAST_FETCH]),'hours')< 6 ))
    },
    async genresLastFetchedPassedSixHours({state}){
        return !(state[GENRES][LAST_FETCH] && (moment().diff(moment(state[GENRES][LAST_FETCH]),'hours')< 6 ))
    },
    async fetchCountries({ state, commit, dispatch }) {
        return new Promise(async (resolve, reject) => {
            try {
                state[COUNTRIES_LOADING]=true;
                const { data } = await getCountries();
                const { success, information } = data;
                if (success === 1) {
                    commit("SET_COUNTRIES_LIST", information);
                }
                state[COUNTRIES_LOADING]=false;
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                state[COUNTRIES_LOADING]=false;
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async fetchCollaboratorsRoles({ state, commit, dispatch }) {
        return new Promise(async (resolve, reject) => {
            try {
                if(await dispatch('collaboratorsRolesFetchPassedSixHours')) {
                    commit('SET_CATEGORY_LOADING_STATE', { type: COLLABORATOR_ROLES, state: true })
                    const { data } = await getCollaboratorsRoles();
                    const { success, information } = data;
                    if (success === 1) {
                        commit("SET_COLLABORATORS_ROLES_LIST", information);
                        commit('SET_CATEGORY_LAST_FETCH', { type: COLLABORATOR_ROLES, time: moment().format() })
                    }
                    commit('SET_CATEGORY_LOADING_STATE', { type: COLLABORATOR_ROLES, state: false })
                    resolve({
                        status: SUCCESS,
                        message: "",
                        data
                    });
                }
            } catch (error) {
                commit('SET_CATEGORY_LOADING_STATE', {type:COLLABORATOR_ROLES, state:false })
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        });
    },
    async collaboratorsRolesFetchPassedSixHours({state}){
        return !(state[COLLABORATOR_ROLES][LAST_FETCH] && (moment().diff(moment(state[COLLABORATOR_ROLES][LAST_FETCH]),'hours')< 6 ))
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    SET_COUNTRIES_LIST(state, payload) {
        try {
            state[COUNTRIES] = payload;
        } catch (e) {
            console.error(e.message, "unable to set countries");
        }
    },
    SET_COLLABORATORS_ROLES_LIST(state, payload) {
        try {
            state[COLLABORATOR_ROLES][LIST] = payload;
        } catch (e) {
            console.error(e.message, "unable to set countries");
        }
    },
    SET_CATEGORY_LAST_FETCH(state, payload={
        type:GENRES,
        time: moment().format(),
    }) {
        try {
            console.log('categories',payload)
            state[payload['type']][LAST_FETCH] = payload['time']
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_CATEGORY_LOADING_STATE(state, payload={
        type:GENRES,
        state:false
    }) {
        try {
            state[payload['type']][LOADING] = payload['state']
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    updateSystemDataUIConstraints(state, obj = {}) {
        try {

            let _state = Utils.parseState(state);

            const formData = _state[SYSTEM_DATA_KEY];
            const data = Object.assign(formData, obj);
            mutations.updateSystemDataState(state, data);
        } catch (error) {
            Utils.log("Update UI Constraints ===> error ", error);
        }
    },
    updateSystemDataState(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            let formData = _state[SYSTEM_DATA_KEY];

            state[SYSTEM_DATA_KEY] = Object.assign(formData, obj);
        } catch (error) {
            Utils.log("Update Login State ===> error ", error);
        }
    },
    resetSystemDataState() {
        for (let f in state) {
            Vue.set(state, f, INITIAL_STATE[f]);
        }
    },
    completeGetStartedPage(state, payload) {
        try {
            state[SYSTEM_DATA_IS_WELCOME_PAGE] = payload
        } catch (e) {
            Utils.log("Update Login State ===> error ", e);
        }
    },
    CHANGE_LOADING_STATE(state,payload){
        state[LOADING] = payload;
    },
    SET_GENRES(state, payload) {
        try {
            state[GENRES][LIST] = payload

        } catch (e) {
            console.error(e.message,'Unable to set genres');
        }
    },
    SET_GENRE_CATEGORIES_LAST_FETCH(state, payload) {
          try {
              state[GENRES][LAST_FETCH] = payload
          } catch (e) {
              console.error(e.message, 'unable to set post access option')
          }
      },
    SET_ARTICLE_CATEGORIES_LAST_FETCH(state, payload) {
        try {
            state[ARTICLE_POST_CATEGORIES][LAST_FETCH] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_TAGS_LAST_FETCH(state, payload) {
        try {
            state[POST_TAGS_LIST][LAST_FETCH] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_ARTICLE_CATEGORIES(state, payload) {
        try {
            state[ARTICLE_POST_CATEGORIES][LIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_TAGS_LIST(state, payload) {
        try {
            state[POST_TAGS_LIST][LIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_TOAST_OPTIONS(state, payload) {
        try {
            state[TOAST][TOAST_SHOW] = payload.show
            state[TOAST][TOAST_TITLE] = payload.title
            state[TOAST][TOAST_TEXT] = payload.text
            state[TOAST][TOAST_DELAY] = payload.delay
            state[TOAST][TOAST_TYPE] = payload.type
        } catch (e) {
            console.error(e, 'Unable to set message');
        }
    },
    RESET_TOAST_OPTIONS(state) {
        try {
            state[TOAST][TOAST_SHOW] = false
            state[TOAST][TOAST_TITLE] = ''
            state[TOAST][TOAST_TEXT] = ''
            state[TOAST][TOAST_DELAY] = 3000
            state[TOAST][TOAST_TYPE] = ''
        } catch (e) {
            console.error(e);
        }
    }
};

const getters = {
    system_map_to_props(state) {
        let _state = Utils.parseState(state);

        const system_key = _state[SYSTEM_DATA_KEY];

        const is_authenticated = system_key && system_key[SYSTEM_DATA_IS_AUTHENTICATED] ? system_key[SYSTEM_DATA_IS_AUTHENTICATED] : false;
        const is_welcome_page = system_key && system_key[SYSTEM_DATA_IS_WELCOME_PAGE] ? system_key[SYSTEM_DATA_IS_WELCOME_PAGE] : false;

        return {
            is_authenticated,
            is_welcome_page
        }
    },
    main_container_full_class_map_to_props(state) {
        let _state = Utils.parseState(state);

        const system_key = _state[SYSTEM_DATA_KEY];

        const main_container_full_classes = system_key && system_key[SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS] ? system_key[SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS] : "";

        return {
            main_container_full_classes
        }
    },
    getLoadingState(state){
        let _state = Utils.parseState(state);
        return _state[LOADING];
    },
    getGenres(state){
        let _state = Utils.parseState(state);
        return _state[GENRES][LIST];
    },
    getArticleCategories(state) {
        let _state = Utils.parseState(state);
        return _state[ARTICLE_POST_CATEGORIES][LIST];
    },
    getPostTagsList(state) {
        let _state = Utils.parseState(state);
        return _state[POST_TAGS_LIST][LIST];
    },
    getCountries(state) {
        let _state = Utils.parseState(state);
        return _state[COUNTRIES];
    },
    getCollaboratorsRoles(state) {
        let _state = Utils.parseState(state);
        return _state[COLLABORATOR_ROLES][LIST];
    },
    getToastOptions(state) {
        return state[TOAST]
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
