<template>
  <div
    class="wr_EMMonitoring"
    :class="{
      insights_page: $route.path.includes('/event-manager/page/insights'),
    }"
  >
    <div>
      <h3>Event Monitoring Dashboard</h3>
      <p>Hi, welcome back! Here’s a summary of your events</p>
    </div>
    <div>
      <ul>
        <li>
          <h5>START DATE</h5>
          <span>10 Oct 2022</span>
        </li>
        <span class="for_border"></span>
        <li>
          <h5>END DATE</h5>
          <span>24 Oct 2022</span>
        </li>
        <span class="for_border"></span>
        <li class="wr_select">
          <h5 v-if="!$route.path.includes('/event-manager/page/insights')">
            EVENT CATEGORY
          </h5>
          <div class="select" @click.stop="selectOpen = !selectOpen">
            {{ selectedValue.eventType }}
            <svg
              :class="{ selectOpen: selectOpen }"
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.78223 1L4.53423 4.3L1.00023 1"
                stroke="#D1433A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <ul v-if="selectOpen" v-click-outside="closePopup">
              <li
                @click="handleEventType(item.id)"
                v-for="item in selectEventData"
                :key="item.id"
                class="for_hover_empty_border_blue"
              >
                {{ item.eventType }}
                <svg
                  v-if="item.active"
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                    fill="#D1433A"
                  />
                </svg>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectOpen: false,
      selectedValue: { id: 1, eventType: "All Events", active: true },
      selectEventData: [
        { id: 1, eventType: "All Events", active: true },
        { id: 2, eventType: "Gigs", active: false },
        { id: 3, eventType: "Clubs", active: false },
        { id: 4, eventType: "Festivals", active: false },
        { id: 5, eventType: "Livestreaming", active: false },
      ],
    };
  },
  methods: {
    handleEventType(id) {
      this.selectEventData.forEach((el) => {
        if (el.id === id) {
          el.active = true;
          this.selectedValue = el;
        } else {
          el.active = false;
        }
      });
    },
    closePopup() {
      this.selectOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_EMMonitoring {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: HelveticaNeueBold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .for_border {
    height: 50px;
    display: block;
    border-left: 1px solid #d9d9d9;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    li {
      padding: 0 10px;
    }
  }
  h3 {
    color: #071526;
    font-size: 18px;
    margin-bottom: 12px;
  }
  p {
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.2px;
    font-weight: 400;
    font-size: 14px;
    color: #8b949f;
    margin: 0;
  }
  h5 {
    font-size: 11px;
    line-height: 13px;
    color: #8b949f;
    margin: 0;
    margin-bottom: 17px;
  }
  span {
    font-size: 11px;
    line-height: 13px;
    color: #47505b;
  }
  .wr_select {
    padding: 0 0 0 19px;
    h5 {
      margin-bottom: 8px;
    }
    .selectOpen {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
    .select {
      cursor: pointer;
      width: 170px;
      height: 30px;
      align-items: center;
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 20px;
      border: 1px solid #D1433A;
      border-radius: 4px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
      color: #D1433A;
      svg {
        transition: all 0.3s;
      }
      ul {
        position: absolute;
        display: block;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.15);
        border-radius: 4px;
        width: 100.5%;
        left: 0;
        top: 30px;
        z-index: 2;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueNormal;
          letter-spacing: 0.7px;
          color: #071526;
          padding: 7px 12px 7px 20px;
          &:hover {
            color: #D1433A;
          }
        }
      }
    }
  }
}
.insights_page {
  .wr_select {
    .select {
      width: 340px !important;
      height: 40px !important;
      ul {
        top: 40px;
      }
    }
  }
  h3 {
    margin-bottom: 5px;
  }
  h5 {
    margin-bottom: 10px !important;
  }
  .for_border {
    height: 40px !important;
  }
}
</style>