<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 0C4.0374 0 0 4.0374 0 9C0 13.9626 4.0374 18 9 18C13.9626 18 18 13.9626 18 9C18 4.0374 13.9626 0 9 0ZM14.175 9.9H8.1V3.6H9.9V8.1H14.175V9.9Z" fill="#47505B"/>
                </svg>

                Contact
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="updateContactInfo()">
                  <div class="artist-setting-panel">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            E-mail
                          </template>
                          <b-form-input
                              id="artist-email"
                              v-model="$v.contact.email.$model"
                              :state="validateState('email')"
                              placeholder="E-mail"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="artist-email"
                          >Invalid email
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Phone
                          </template>
                          <b-form-input
                              id="artist-phone"
                              v-model="$v.contact.phone.$model"
                              :state="validateState('phone')"
                              placeholder="Phone"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="artist-phone"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Website
                          </template>
                          <b-form-input
                              id="artist-website"
                              v-model="$v.contact.website.$model"
                              :state="validateState('website')"
                              placeholder="Website"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="artist-website"
                          >Invalid website
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Address
                          </template>
                          <b-form-input
                              id="artist-address"
                              v-model="$v.contact.address.$model"
                              :state="validateState('address')"
                              placeholder="Address"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="artist-address"
                          >Invalid address
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="address-map">
                          <img :src="require(`@/assets/img/map.png`)">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="artist-content-setting-heading m-t-20">SOCIAL LINKS</div>
                        <div class="acc-sep"></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Facebook Profile URL
                          </template>
                          <b-input-group class="mt-3">
                            <template #prepend>
                              <b-input-group-text>
                                <div class="svg-icon">

                                  <svg width="9" height="16" viewBox="0 0 9 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path class="c-line-1 c-fill-1"
                                          d="M2.46 16H5.39V8.7H7.84L8.21 5.86H5.39V4.04C5.39 3.22 5.62 2.66 6.8 2.66H8.31V0.11C8.05 0.0799999 7.16 0 6.12 0C3.94 0 2.46 1.33 2.46 3.76V5.86H0V8.7H2.46V16Z"
                                          fill="#47505B"/>
                                  </svg>

                                </div>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                id="artist-facebook"
                                v-model="$v.contact.facebook.$model"
                                :state="validateState('facebook')"
                            >
                            </b-form-input>
                          </b-input-group>

                          <b-form-invalid-feedback
                              id="artist-facebook"
                          >Invalid URL.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Twitter Profile URL
                          </template>
                          <b-input-group class="mt-3">
                            <template #prepend>
                              <b-input-group-text>
                                <div class="svg-icon">
                                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 1.54287C15.4116 1.80359 14.7795 1.97975 14.115 2.05938C14.8007 1.64912 15.3136 1.00342 15.5582 0.242768C14.914 0.625391 14.209 0.894716 13.4738 1.03903C12.9794 0.511148 12.3245 0.161258 11.6109 0.0436835C10.8973 -0.0738904 10.1648 0.0474306 9.52717 0.388811C8.88955 0.730191 8.38247 1.27253 8.08467 1.93163C7.78686 2.59073 7.71499 3.32971 7.88021 4.03384C6.57496 3.9683 5.29807 3.62904 4.13242 3.03809C2.96677 2.44713 1.93841 1.61767 1.11407 0.603554C0.832203 1.08977 0.670131 1.6535 0.670131 2.25387C0.669817 2.79434 0.802912 3.32653 1.05761 3.80322C1.3123 4.27992 1.68073 4.68638 2.13019 4.98654C1.60893 4.96995 1.09918 4.8291 0.643354 4.57572V4.618C0.643302 5.37603 0.905511 6.11073 1.38549 6.69745C1.86547 7.28416 2.53365 7.68674 3.27667 7.83688C2.79312 7.96775 2.28615 7.98703 1.79406 7.89326C2.0037 8.5455 2.41205 9.11586 2.96194 9.52449C3.51184 9.93312 4.17575 10.1596 4.86074 10.1721C3.69794 11.085 2.26188 11.5801 0.783581 11.5779C0.521717 11.578 0.260073 11.5627 0 11.5321C1.50056 12.4969 3.24731 13.009 5.03127 13.007C11.0702 13.007 14.3715 8.0053 14.3715 3.66741C14.3715 3.52648 14.368 3.38414 14.3617 3.24321C15.0038 2.77882 15.5581 2.20376 15.9986 1.54498L16 1.54287Z"
                                        fill="#47505B"/>
                                  </svg>
                                </div>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                id="artist-twitter"
                                v-model="$v.contact.twitter.$model"
                                :state="validateState('twitter')"
                            >
                            </b-form-input>
                          </b-input-group>

                          <b-form-invalid-feedback
                              id="artist-twitter"
                          >Invalid URL.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            YouTube Profile URL
                          </template>
                          <b-input-group class="mt-3">
                            <template #prepend>
                              <b-input-group-text>
                                <div class="svg-icon">
                                  <svg width="16" height="11" viewBox="0 0 16 11" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.665 1.67441C15.5744 1.35128 15.3979 1.05873 15.1544 0.827861C14.9039 0.589896 14.597 0.419673 14.2625 0.333261C13.0107 0.00330541 7.99534 0.00330548 7.99534 0.00330548C5.9045 -0.0204829 3.81418 0.0841443 1.73618 0.316597C1.40168 0.409393 1.09531 0.583419 0.844298 0.823195C0.597664 1.0605 0.419022 1.35312 0.325701 1.67375C0.101526 2.88141 -0.00739287 4.10765 0.0004114 5.33592C-0.00758752 6.56309 0.101065 7.78892 0.325701 8.9981C0.417022 9.31739 0.594998 9.60868 0.842298 9.84398C1.0896 10.0793 1.39756 10.2493 1.73618 10.3392C3.00467 10.6685 7.99534 10.6685 7.99534 10.6685C10.0888 10.6924 12.1818 10.5877 14.2625 10.3552C14.597 10.2688 14.9039 10.0986 15.1544 9.86065C15.401 9.62534 15.5763 9.33272 15.6643 9.01409C15.8943 7.80689 16.0062 6.58015 15.9983 5.35125C16.0156 4.11715 15.9039 2.88463 15.665 1.67375V1.67441ZM6.40088 7.61828V3.05423L10.5737 5.33659L6.40088 7.61828Z"
                                        fill="#47505B"/>
                                  </svg>
                                </div>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                id="artist-youtube"
                                v-model="$v.contact.youtube.$model"
                                :state="validateState('youtube')"
                            >
                            </b-form-input>
                          </b-input-group>

                          <b-form-invalid-feedback
                              id="artist-youtube"
                          >Invalid URL.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Instagram Profile URL
                          </template>
                          <b-input-group class="mt-3">
                            <template #prepend>
                              <b-input-group-text>
                                <div class="svg-icon">
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.998 5.3322C6.52901 5.3322 5.3302 6.53101 5.3302 8C5.3302 9.46899 6.52901 10.6678 7.998 10.6678C9.46699 10.6678 10.6658 9.46899 10.6658 8C10.6658 6.53101 9.46699 5.3322 7.998 5.3322ZM15.9994 8C15.9994 6.89525 16.0094 5.80051 15.9474 4.69777C15.8853 3.4169 15.5931 2.28014 14.6565 1.3435C13.7179 0.404868 12.5831 0.114672 11.3022 0.0526304C10.1975 -0.0094115 9.10275 0.000595291 8 0.000595291C6.89525 0.000595291 5.80051 -0.0094115 4.69777 0.0526304C3.4169 0.114672 2.28014 0.40687 1.3435 1.3435C0.404868 2.28214 0.114672 3.4169 0.0526304 4.69777C-0.0094115 5.80252 0.000595291 6.89725 0.000595291 8C0.000595291 9.10275 -0.0094115 10.1995 0.0526304 11.3022C0.114672 12.5831 0.40687 13.7199 1.3435 14.6565C2.28214 15.5951 3.4169 15.8853 4.69777 15.9474C5.80252 16.0094 6.89725 15.9994 8 15.9994C9.10475 15.9994 10.1995 16.0094 11.3022 15.9474C12.5831 15.8853 13.7199 15.5931 14.6565 14.6565C15.5951 13.7179 15.8853 12.5831 15.9474 11.3022C16.0114 10.1995 15.9994 9.10475 15.9994 8ZM7.998 12.1048C5.72646 12.1048 3.89323 10.2715 3.89323 8C3.89323 5.72847 5.72646 3.89523 7.998 3.89523C10.2695 3.89523 12.1028 5.72847 12.1028 8C12.1028 10.2715 10.2695 12.1048 7.998 12.1048ZM12.2709 4.68576C11.7405 4.68576 11.3122 4.25747 11.3122 3.72711C11.3122 3.19675 11.7405 2.76847 12.2709 2.76847C12.8012 2.76847 13.2295 3.19675 13.2295 3.72711C13.2297 3.85305 13.205 3.97778 13.1569 4.09416C13.1088 4.21054 13.0382 4.31628 12.9491 4.40533C12.8601 4.49438 12.7543 4.56499 12.6379 4.61311C12.5216 4.66123 12.3968 4.68592 12.2709 4.68576Z"
                                        fill="#47505B"/>
                                  </svg>
                                </div>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                id="artist-instagram"
                                v-model="$v.contact.instagram.$model"
                                :state="validateState('instagram')"
                            >
                            </b-form-input>
                          </b-input-group>

                          <b-form-invalid-feedback
                              id="artist-instagram"
                          >Invalid URL.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            LinkedIn Profile URL
                          </template>
                          <b-input-group class="mt-3">
                            <template #prepend>
                              <b-input-group-text>
                                <div class="svg-icon">
                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1.97862e-07 1.33673C1.97862e-07 0.982207 0.140834 0.642205 0.391519 0.39152C0.642204 0.140835 0.982205 1.78205e-06 1.33673 1.78205e-06H14.6618C14.8375 -0.000285165 15.0115 0.0340839 15.1739 0.101141C15.3363 0.168198 15.4839 0.266627 15.6082 0.390792C15.7325 0.514956 15.8311 0.662419 15.8983 0.824736C15.9655 0.987054 16.0001 1.16104 16 1.33673V14.6618C16.0002 14.8375 15.9657 15.0116 15.8986 15.174C15.8315 15.3364 15.733 15.4839 15.6087 15.6082C15.4845 15.7325 15.337 15.8311 15.1746 15.8983C15.0123 15.9655 14.8383 16.0001 14.6625 16H1.33673C1.16112 16 0.987243 15.9654 0.825016 15.8982C0.662789 15.831 0.515397 15.7324 0.391261 15.6082C0.267125 15.484 0.168678 15.3366 0.101544 15.1743C0.0344096 15.012 -9.53422e-05 14.8381 1.97862e-07 14.6625V1.33673ZM6.33309 6.10037H8.49964V7.18836C8.81236 6.56291 9.61236 6 10.8145 6C13.1193 6 13.6655 7.24582 13.6655 9.53164V13.7658H11.3331V10.0524C11.3331 8.75055 11.0204 8.016 10.2262 8.016C9.12436 8.016 8.66618 8.808 8.66618 10.0524V13.7658H6.33309V6.10037ZM2.33309 13.6662H4.66618V6H2.33309V13.6655V13.6662ZM5 3.49964C5.0044 3.6994 4.96885 3.89803 4.89545 4.08387C4.82204 4.2697 4.71226 4.43901 4.57253 4.58184C4.43281 4.72468 4.26596 4.83816 4.08179 4.91564C3.89761 4.99312 3.69981 5.03303 3.5 5.03303C3.30019 5.03303 3.10239 4.99312 2.91821 4.91564C2.73404 4.83816 2.56719 4.72468 2.42747 4.58184C2.28774 4.43901 2.17796 4.2697 2.10455 4.08387C2.03115 3.89803 1.9956 3.6994 2 3.49964C2.00863 3.10753 2.17046 2.7344 2.45083 2.46014C2.73119 2.18588 3.1078 2.03231 3.5 2.03231C3.8922 2.03231 4.26881 2.18588 4.54917 2.46014C4.82954 2.7344 4.99137 3.10753 5 3.49964Z"
                                          fill="#47505B"/>
                                  </svg>
                                </div>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                id="artist-linked_in"
                                v-model="$v.contact.linked_in.$model"
                                :state="validateState('linked_in')"
                            >
                            </b-form-input>
                          </b-input-group>

                          <b-form-invalid-feedback
                              id="artist-linked_in"
                          >Invalid URL.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <b-form-group
                            label-for="artist-email"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            SoundCloud Profile URL
                          </template>
                          <b-input-group class="mt-3">
                            <template #prepend>
                              <b-input-group-text>
                                <div class="svg-icon">
                                  <svg width="24" height="10" viewBox="0 0 24 10" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.15731 9.46645C1.20631 9.46645 1.24731 9.42745 1.25531 9.37345L1.52531 7.35145L1.25531 5.28245C1.24831 5.22845 1.20531 5.18945 1.15631 5.18945C1.10631 5.18945 1.06231 5.22945 1.05631 5.28245L0.820312 7.35145L1.05631 9.37245C1.06231 9.42745 1.10631 9.46645 1.15631 9.46645H1.15731Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M0.272 8.69647C0.32 8.69647 0.36 8.66047 0.367 8.60647L0.576 7.35047L0.367 6.07047C0.36 6.01747 0.32 5.98047 0.272 5.98047C0.221 5.98047 0.182 6.01747 0.174 6.07047L0 7.35047L0.174 8.60647C0.182 8.65947 0.221 8.69647 0.272 8.69647Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M2.22081 4.8972C2.2188 4.8666 2.20534 4.83787 2.1831 4.81674C2.16086 4.79561 2.13148 4.78364 2.10081 4.7832C2.07017 4.78342 2.04078 4.79535 2.01865 4.81655C1.99652 4.83774 1.98334 4.8666 1.98181 4.8972L1.75781 7.3512L1.98181 9.7152C1.98384 9.74562 1.99738 9.77413 2.01967 9.79493C2.04196 9.81573 2.07133 9.82727 2.10181 9.8272C2.13239 9.82702 2.16177 9.81526 2.18403 9.7943C2.20629 9.77333 2.21979 9.74472 2.22181 9.7142L2.47581 7.3512L2.22181 4.8972H2.22081Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M3.05222 9.92226C3.08779 9.92162 3.12185 9.90774 3.14774 9.88333C3.17363 9.85892 3.18949 9.82574 3.19222 9.79026L3.43322 7.35126L3.19322 4.82927C3.19047 4.79362 3.17447 4.7603 3.14837 4.73586C3.12227 4.71143 3.08797 4.69766 3.05222 4.69727C3.01627 4.69722 2.98168 4.71101 2.95562 4.73577C2.92956 4.76053 2.91402 4.79436 2.91222 4.83027L2.69922 7.35126L2.91122 9.79026C2.91302 9.82634 2.9287 9.86033 2.95498 9.88511C2.98126 9.9099 3.0161 9.92357 3.05222 9.92326V9.92226Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M4.01234 9.96138C4.05363 9.96146 4.09339 9.94577 4.1235 9.91752C4.15361 9.88927 4.17179 9.85059 4.17434 9.80938L4.40034 7.35038L4.17434 5.01038C4.17155 4.96934 4.15326 4.93091 4.12317 4.90287C4.09309 4.87483 4.05347 4.85928 4.01234 4.85938C3.97126 4.85932 3.93173 4.87508 3.90194 4.90338C3.87216 4.93167 3.8544 4.97034 3.85234 5.01138L3.65234 7.35138L3.85234 9.80938C3.8544 9.85041 3.87216 9.88908 3.90194 9.91738C3.93173 9.94568 3.97126 9.96143 4.01234 9.96138Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M5.36947 7.35105L5.15747 3.54605C5.15469 3.49952 5.13435 3.45579 5.10057 3.42368C5.06678 3.39157 5.02208 3.37347 4.97547 3.37305C4.92879 3.37323 4.88395 3.39125 4.85012 3.42341C4.81628 3.45557 4.79602 3.49944 4.79347 3.54605L4.60547 7.35105L4.79347 9.80905C4.79819 9.85403 4.8194 9.89567 4.85301 9.92593C4.88662 9.95619 4.93024 9.97294 4.97547 9.97294C5.0207 9.97294 5.06432 9.95619 5.09793 9.92593C5.13154 9.89567 5.15275 9.85403 5.15747 9.80905L5.36947 7.35105Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M5.95222 9.98704C6.004 9.9871 6.05381 9.96716 6.09126 9.93139C6.1287 9.89562 6.1509 9.84678 6.15322 9.79504V9.79704L6.35222 7.35304L6.15322 2.67704C6.14962 2.62579 6.12672 2.57782 6.08914 2.54279C6.05156 2.50776 6.00209 2.48828 5.95072 2.48828C5.89934 2.48828 5.84988 2.50776 5.8123 2.54279C5.77472 2.57782 5.75182 2.62579 5.74822 2.67704L5.57422 7.35304L5.74922 9.79604C5.75205 9.84777 5.77474 9.89641 5.81257 9.93181C5.85039 9.96721 5.90042 9.98664 5.95222 9.98604V9.98704Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M6.93187 2.07619C6.87452 2.07588 6.81926 2.09777 6.77769 2.1373C6.73612 2.17682 6.71146 2.23089 6.70887 2.28819L6.54688 7.35319L6.70887 9.77119C6.71146 9.8284 6.73616 9.88237 6.77776 9.92173C6.81936 9.96109 6.87461 9.98277 6.93187 9.98219C6.98921 9.98302 7.04461 9.96143 7.08626 9.92202C7.12792 9.88261 7.15253 9.82849 7.15487 9.77119L7.33987 7.35319L7.15487 2.28819C7.15279 2.23072 7.12828 2.17635 7.0866 2.13673C7.04492 2.0971 6.98938 2.07537 6.93187 2.07619Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M7.91844 9.98777C7.98142 9.98886 8.0423 9.96514 8.08794 9.92174C8.13359 9.87833 8.16035 9.81873 8.16244 9.75577V9.75777L8.33244 7.35377L8.16244 2.11877C8.16035 2.056 8.13373 1.99654 8.0883 1.95316C8.04287 1.90979 7.98224 1.88595 7.91944 1.88677C7.85648 1.88543 7.79556 1.90909 7.75001 1.95258C7.70447 1.99606 7.67801 2.05582 7.67644 2.11877L7.52344 7.35377L7.67644 9.75777C7.67844 9.88677 7.78644 9.98777 7.91944 9.98777H7.91844Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M8.91762 9.98403C8.98573 9.98485 9.05145 9.95891 9.10063 9.91178C9.14981 9.86466 9.17853 9.80012 9.18062 9.73203V9.73403L9.33762 7.35303L9.18062 2.25003C9.17802 2.1823 9.14907 2.11827 9.09995 2.07157C9.05082 2.02487 8.9854 1.9992 8.91762 2.00003C8.84968 1.99894 8.784 2.02449 8.73466 2.07121C8.68532 2.11794 8.65623 2.18213 8.65363 2.25003L8.51562 7.35303L8.65462 9.73303C8.65762 9.87303 8.77363 9.98303 8.91762 9.98303V9.98403Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M10.3497 7.35306L10.2077 2.43606C10.2051 2.36285 10.1739 2.29358 10.1208 2.2431C10.0677 2.19263 9.99697 2.16497 9.92372 2.16606C9.85013 2.16471 9.77897 2.19239 9.72563 2.2431C9.6723 2.29382 9.64107 2.3635 9.63872 2.43706L9.51172 7.35306L9.63872 9.71906C9.64133 9.79245 9.67266 9.86188 9.72597 9.91238C9.77928 9.96288 9.8503 9.99042 9.92372 9.98906C9.99747 9.99014 10.0687 9.96207 10.1218 9.91095C10.175 9.85984 10.2059 9.7898 10.2077 9.71606V9.71906L10.3497 7.35306Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M10.9356 9.99486C11.1006 9.99486 11.2366 9.86486 11.2396 9.70486L11.3686 7.35586L11.2396 1.50586C11.237 1.42725 11.2037 1.35279 11.1469 1.29841C11.0901 1.24402 11.0143 1.21403 10.9356 1.21486C10.857 1.21428 10.7811 1.24432 10.7242 1.29863C10.6673 1.35293 10.6337 1.42725 10.6306 1.50586L10.5156 7.35386L10.6306 9.70586C10.6336 9.86386 10.7706 9.99486 10.9356 9.99486Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M11.9444 0.664105C11.8602 0.662739 11.7787 0.694429 11.7176 0.752381C11.6565 0.810332 11.6205 0.88993 11.6174 0.974105L11.4844 7.3561L11.6184 9.6711C11.6215 9.75475 11.6573 9.83384 11.718 9.89141C11.7788 9.94899 11.8597 9.98047 11.9434 9.9791C12.0274 9.98019 12.1085 9.94818 12.1691 9.88999C12.2297 9.8318 12.265 9.7521 12.2674 9.6681V9.6711L12.4134 7.3561L12.2674 0.975105C12.265 0.891283 12.2299 0.811735 12.1695 0.753574C12.109 0.695412 12.0282 0.663286 11.9444 0.664105Z"
                                        fill="#47505B"/>
                                    <path
                                        d="M12.8669 9.996L21.0479 10C22.6789 10 24.0009 8.732 24.0009 7.167C24.0009 5.603 22.6789 4.335 21.0479 4.335C20.6439 4.335 20.2579 4.414 19.9059 4.555C19.6729 2.003 17.4409 0 14.7189 0C14.0539 0 13.4049 0.126 12.8319 0.339C12.6089 0.422 12.5489 0.507 12.5469 0.672V9.661C12.55 9.74631 12.5843 9.82752 12.6433 9.88925C12.7022 9.95098 12.7818 9.98896 12.8669 9.996Z"
                                        fill="#47505B"/>
                                  </svg>

                                </div>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                id="artist-soundcloud"
                                v-model="$v.contact.soundcloud.$model"
                                :state="validateState('soundcloud')"
                            >
                            </b-form-input>
                          </b-input-group>

                          <b-form-invalid-feedback
                              id="artist-soundcloud"
                          >Invalid URL.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                      <div class="col-12">
                        <div class="">
                          <div class="d-flex justify-content-end m-t-20">
                            <button class="prime_button" type="submit">Save Changes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import {  url,email } from "vuelidate/lib/validators";
import {updateContactsOFPage} from "../../../../apis/APIs";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue';

export default {
  data() {
    return {
      deletePage: false,
      contact: {
        website: null,
        email: null,
        phone: null,
        address: null,
        latitude: null,
        longitude: null,
        facebook: null,
        instagram: null,
        soundcloud: null,
        twitter: null,
        youtube: null,
        linked_in: null,
      }
    }
  },
  validations: {
    contact: {
      website: {
        url
      },
      email: {
        email
      },
      phone: {},
      address: {},
      latitude: {},
      longitude: {},
      facebook: {
        url
      },
      instagram: {
        url
      },
      soundcloud: {
        url
      },
      twitter: {
        url
      },
      youtube: {
        url
      },
      linked_in: {
        url
      }
    }
  },
  components: {
    // MusicSubHeader,
    EyeIcon
  },
  computed: {
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.contact[name];
      return $dirty ? !$error : null;
    },
    async updateContactInfo() {
      this.$v.contact.$touch();
      if (this.$v.contact.$anyError) {
        return;
      } else {
        try {
          await this.$store.dispatch('loading', true)
          const formData = new FormData();
          for (const field in this.contact) {
            if (Array.isArray(this.contact[field])) {
              if (this.contact[field].length > 0) {
                for (let i = 0; i < this.contact[field].length; i++) {
                  formData.append(field + '[' + i + ']', this.contact[field][i]);
                }
              }
            } else {
              if ((this.contact[field] !== null)) {
                formData.append(field, this.contact[field]);
              }
            }
          }

          const {data} = await updateContactsOFPage(this.current_page.id, formData);


          const {success, message} = data || {}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const page = data.information || {};
            this.$store.commit('SET_PAGE_DATA', page)
            await this.makeToast('success', 'Page!', message)
          } else {
            await this.makeToast('error', 'Page!', message)
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.makeToast('error', 'Page!', e.message)
        }

      }
    },
    setPageData(){
      let {email,address,phone,website,facebook,instagram,youtube,linked_in,twitter,soundcloud} = this.current_page
      this.contact.email= email
      this.contact.phone= phone
      this.contact.website= website
      this.contact.address= address
      this.contact.facebook= facebook
      this.contact.instagram= instagram
      this.contact.youtube= youtube
      this.contact.linked_in= linked_in
      this.contact.twitter= twitter
      this.contact.soundcloud= soundcloud
    }
  },
  mounted() {
    this.setPageData()
  },
  watch:{
    current_page(val){
      if(val)
        this.setPageData()

    }
  }
}
</script>
<style>
.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}
</style>
<style scoped lang="scss">
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
}
</style>
