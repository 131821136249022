import moment from "moment-timezone";
export default {
  methods: {
    monthDayFormat(data) {
      return new Date(data).toLocaleString("default", {
        month: "short",
        day: "numeric",
      });
    },
    monthOnly(data) {
      return new Date(data).toLocaleString("default", {
        month: "short",
      });
    },
    dayOnly(data) {
      return new Date(data).toLocaleString("default", {
        day: "numeric",
      });
    },
    dateFormatFromNow(date) {
      return moment(date).fromNow();
    },
  },
};
