<template>
  <div class="photo-layout">
    <div class="row">
      <media-photos-albums-listing @getMediaPhotosAlbumsList="getMediaPhotosAlbums" :listData="mediaPhotosAlbumsList" @toggleAllPhotos="toggleAllPhotos" />
    </div>
    <div v-if="showAll" class="row">
      <div class="col-md-artist-photo-full">
        <div class="all-add-photo" style="margin: 14px 14px 20px 20px;">
          <h1>All Photos</h1>
          <button v-on:click="openPhotoModel" v-if="current_page_user === 'admin'">Add Photos</button>
        </div>
      </div>
      <media-photos-listing @getMediaPhotosLists="getMediaPhotos" :listData="mediaPhotosList" :totalMediaPhotos="mediaPhotosCount"/>
      <div class="col-12">
        <div v-if="mediaPhotosList.length >= 8 && mediaPhotosList.length != mediaPhotosCount" class="artist-show-more" v-on:click="setLimit">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V11.0003" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M11 6L0.999665 6" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
          SHOW MORE
        </div>
      </div>
    </div>
    <AddPhotosModal v-if="showModal" @closePhotoModel="closePhotoModel"/>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script  type="application/javascript">
import mediaPhotosListing from "@/components/PageManager/Pages/ArtistMoreSection/media/photos/mediaPhotosListing.vue";
import mediaPhotosAlbumsListing from "@/components/PageManager/Pages/ArtistMoreSection/media/photos/mediaPhotosAlbumsListing.vue";
import {
  getAllMediaPhotosAlbumsOfPages,
  getAllMediaPhotosOfPages,
} from "../../../../../../apis/APIs";
import AddPhotosModal from "../../../../../Home/Profile/AddPhotosModal";

export default {
  name: "mediaPhotos",
  props:{
    current_page_user:String,
    user_id:{
      type:Number,
      Default:null,
    }
  },
  data() {
    return {
      mediaPhotosList: [],
      mediaPhotosAlbumsList: [],
      offSetLimit: 0,
      limit: 8,
      loading: false,
      dataLoaded: false,
      mediaPhotosCount: '',
      addPhoto: false,
      check: true,
      showModal: false,
      showAll: true
    }
  },
  components: {
    mediaPhotosListing,
    mediaPhotosAlbumsListing,
    AddPhotosModal
  },
  mounted() {
    this.getMediaPhotos();
    this.getMediaPhotosAlbums();
  },
  methods: {
    async getMediaPhotos() {
      try {
        this.loading = true
        let data = await getAllMediaPhotosOfPages(this.offSetLimit, this.limit, this.$route.params.id);
        this.mediaPhotosList =  data.data.information.data;
        this.mediaPhotosCount = data.data.information.total;
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    async getMediaPhotosAlbums() {
      try {
        this.loading = true
        let data = await getAllMediaPhotosAlbumsOfPages(this.$route.params.id);
        this.mediaPhotosAlbumsList =  (Array.isArray(data.data.information))?data.data.information:[];
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    setLimit () {
      this.limit = this.mediaPhotosList.length + 8;
      this.getMediaPhotos();
    },
    createPhotoPost () {
      this.$refs.addPhoto.toggleAddPhotoModal();
    },
    updateMediaTabAlbumsAndPhotos () {
      this.getMediaPhotos();
      this.getMediaPhotosAlbums();
    },
    openPhotoModel(){
      this.showModal = true
    },
    closePhotoModel(){
      this.showModal = false
    },
    toggleAllPhotos(){
      this.showAll = !this.showAll
    },
  }
}
</script>

<style scoped>

</style>
