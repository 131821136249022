<template>
  <div class="wr_Memberships">
    <h4 class="titleSection">{{ currentItem }} {{ title }}</h4>
    <div class="title_grid">
      <div class="w_for_art_title">USER</div>
      <div class="w_for_art_type">TYPE OF PLAN</div>
      <div class="w_for_art_date">
        START DATE
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.52899 1C3.41273 1 3.31851 1.09423 3.31851 1.21047V2.50292C2.95725 2.59752 2.68709 2.92569 2.68709 3.31522C2.68709 3.77768 3.06651 4.15711 3.52899 4.15711C3.99146 4.15711 4.37088 3.77768 4.37088 3.31522C4.37088 2.92569 4.10071 2.59752 3.73946 2.50292V1.21047C3.73946 1.09423 3.64523 1 3.52899 1ZM8.15942 1C8.04316 1 7.94895 1.09423 7.94895 1.21047V2.50292C7.58769 2.59752 7.31752 2.92569 7.31752 3.31522C7.31752 3.77768 7.69695 4.15711 8.15942 4.15711C8.6219 4.15711 9.00132 3.77768 9.00132 3.31522C9.00132 2.92569 8.73114 2.59752 8.36989 2.50292V1.21047C8.36989 1.09423 8.27567 1 8.15942 1ZM2.05567 1.63142C1.47464 1.63142 1 2.10606 1 2.68708V9.20521C1 9.78622 1.47464 10.2609 2.05567 10.2609H9.62616C10.2072 10.2609 10.6818 9.78622 10.6818 9.20521V2.68708C10.6818 2.10606 10.2072 1.63142 9.62616 1.63142H8.99802C8.88683 1.62984 8.78427 1.73069 8.78427 1.8419C8.78427 1.9531 8.88683 2.05394 8.99802 2.05237H9.62616C9.98085 2.05237 10.2609 2.33238 10.2609 2.68708V4.57806H1.42095V2.68708C1.42095 2.33238 1.70095 2.05237 2.05567 2.05237H2.68379C2.79499 2.05395 2.89756 1.9531 2.89756 1.8419C2.89756 1.73069 2.79499 1.62985 2.68379 1.63142H2.05567ZM4.36759 1.63142C4.25134 1.63142 4.15711 1.72565 4.15711 1.8419C4.15711 1.95814 4.25134 2.05237 4.36759 2.05237H7.31423C7.43047 2.05237 7.5247 1.95814 7.5247 1.8419C7.5247 1.72565 7.43047 1.63142 7.31423 1.63142H4.36759ZM3.52899 2.89427C3.76396 2.89427 3.94993 3.08023 3.94993 3.31522C3.94993 3.55019 3.76396 3.73617 3.52899 3.73617C3.29401 3.73617 3.10804 3.55019 3.10804 3.31522C3.10804 3.08023 3.29401 2.89427 3.52899 2.89427ZM8.15942 2.89427C8.39439 2.89427 8.58037 3.08023 8.58037 3.31522C8.58037 3.55019 8.39439 3.73617 8.15942 3.73617C7.92445 3.73617 7.73847 3.55019 7.73847 3.31522C7.73847 3.08023 7.92445 2.89427 8.15942 2.89427ZM1.42095 4.99901H10.2609V9.20521C10.2609 9.55991 9.98085 9.83992 9.62616 9.83992H2.05567C1.70095 9.83992 1.42095 9.55991 1.42095 9.20521V4.99901Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="0.4"
          />
        </svg>
        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 4.41772L1 1.41772L1.41772 1L4 3.58228L6.58228 1L7 1.37975L4 4.41772Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="0.4"
          />
        </svg>
      </div>
      <div class="w_for_art_price">PRICE</div>
      <div class="w_for_art_menu"></div>
    </div>
    <div
      v-for="artist in listData.slice(0, showMembershipsListCurrent)"
      :key="artist.id"
      class="artist_item"
    >
      <div class="art_img_section w_for_art_title">
        <div class="imgdiv">
          <img :src="require(`@/assets/img/${artist.img}`)" alt="" />
          <div></div>
        </div>
        {{ artist.artistName }}
      </div>
      <div class="art_type w_for_art_type">{{ artist.type }}</div>
      <div class="art_date w_for_art_date">{{ artist.date }}</div>
      <div class="art_price w_for_art_price">
        <svg
          width="7"
          height="13"
          viewBox="0 0 7 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.43 8.166H0.325C0.394333 9.076 0.684667 9.791 1.196 10.311C1.70733 10.8223 2.39633 11.1213 3.263 11.208V12.274H3.978V11.208C4.394 11.1733 4.77533 11.0867 5.122 10.948C5.46867 10.8093 5.76767 10.623 6.019 10.389C6.27033 10.1463 6.46533 9.86033 6.604 9.531C6.75133 9.20167 6.825 8.82467 6.825 8.4C6.825 8.00133 6.747 7.659 6.591 7.373C6.44367 7.087 6.26167 6.84867 6.045 6.658C5.82833 6.46733 5.603 6.32 5.369 6.216C5.135 6.10333 4.93133 6.02533 4.758 5.982L3.978 5.774V2.576C4.77533 2.72333 5.26067 3.226 5.434 4.084H6.539C6.36567 3.32133 6.071 2.72333 5.655 2.29C5.239 1.85667 4.68 1.59667 3.978 1.51V0.638999H3.263V1.497C2.89033 1.523 2.53933 1.60533 2.21 1.744C1.88933 1.88267 1.60767 2.07333 1.365 2.316C1.131 2.55 0.944667 2.83167 0.806 3.161C0.667333 3.48167 0.598 3.837 0.598 4.227C0.598 4.60833 0.65 4.93333 0.754 5.202C0.858 5.47067 1.01833 5.70033 1.235 5.891C1.46033 6.073 1.73767 6.23333 2.067 6.372C2.405 6.502 2.80367 6.62333 3.263 6.736V10.181C2.743 10.0943 2.30967 9.882 1.963 9.544C1.625 9.206 1.44733 8.74667 1.43 8.166ZM3.978 10.181V6.918C4.212 6.98733 4.433 7.06533 4.641 7.152C4.849 7.23 5.02667 7.32967 5.174 7.451C5.32133 7.57233 5.43833 7.71967 5.525 7.893C5.61167 8.05767 5.655 8.26567 5.655 8.517C5.655 8.79433 5.61167 9.037 5.525 9.245C5.447 9.44433 5.33 9.61333 5.174 9.752C5.02667 9.882 4.849 9.98167 4.641 10.051C4.44167 10.1203 4.22067 10.1637 3.978 10.181ZM3.263 2.537V5.579C3.04633 5.51833 2.847 5.449 2.665 5.371C2.483 5.293 2.32267 5.202 2.184 5.098C2.054 4.98533 1.95 4.851 1.872 4.695C1.80267 4.53033 1.768 4.33533 1.768 4.11C1.768 3.616 1.911 3.24333 2.197 2.992C2.49167 2.732 2.847 2.58033 3.263 2.537Z"
            fill="#47505B"
            fill-opacity="0.4"
          />
        </svg>
        {{ artist.price }}
      </div>
      <div class="art_menu w_for_art_menu">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.9987 8.66634C8.36689 8.66634 8.66536 8.36786 8.66536 7.99967C8.66536 7.63148 8.36689 7.33301 7.9987 7.33301C7.63051 7.33301 7.33203 7.63148 7.33203 7.99967C7.33203 8.36786 7.63051 8.66634 7.9987 8.66634Z"
            stroke="#D1433A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9987 4.00033C8.36689 4.00033 8.66536 3.70185 8.66536 3.33366C8.66536 2.96547 8.36689 2.66699 7.9987 2.66699C7.63051 2.66699 7.33203 2.96547 7.33203 3.33366C7.33203 3.70185 7.63051 4.00033 7.9987 4.00033Z"
            stroke="#D1433A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.9987 13.3333C8.36689 13.3333 8.66536 13.0349 8.66536 12.6667C8.66536 12.2985 8.36689 12 7.9987 12C7.63051 12 7.33203 12.2985 7.33203 12.6667C7.33203 13.0349 7.63051 13.3333 7.9987 13.3333Z"
            stroke="#D1433A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="menu_modal_wrap">
          <div class="menu_modal">
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 6L6 10"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L10 10"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
              Cancel Membership
            </div>
            <span></span>
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.66406 14.6667V10"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.66406 10.0007C2.66406 10.0007 3.33073 9.33398 5.33073 9.33398C7.33073 9.33398 8.66406 10.6673 10.6641 10.6673C12.6641 10.6673 13.3307 10.0007 13.3307 10.0007V2.00065C13.3307 2.00065 12.6641 2.66732 10.6641 2.66732C8.66406 2.66732 7.33073 1.33398 5.33073 1.33398C3.33073 1.33398 2.66406 2.00065 2.66406 2.00065V10.0007Z"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
              Report user
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="showCurrentCards(listData, showMembershipsListCurrent)"
      v-if="listData.length > showMembershipsListCurrent"
    >
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.24219 0.689453V7.01945"
          stroke="#071526"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 3.85449H7.48702"
          stroke="#071526"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      SEE ALL MEMBERSHIPS IN COLLECTION
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.33333 1.5C2.11232 1.5 1.90036 1.5878 1.74408 1.74408C1.5878 1.90036 1.5 2.11232 1.5 2.33333V11.6667C1.5 11.8877 1.5878 12.0996 1.74408 12.2559C1.90036 12.4122 2.11232 12.5 2.33333 12.5H11.6667C11.8877 12.5 12.0996 12.4122 12.2559 12.2559C12.4122 12.0996 12.5 11.8877 12.5 11.6667V8.33333C12.5 8.05719 12.7239 7.83333 13 7.83333C13.2761 7.83333 13.5 8.05719 13.5 8.33333V11.6667C13.5 12.1529 13.3068 12.6192 12.963 12.963C12.6192 13.3068 12.1529 13.5 11.6667 13.5H2.33333C1.8471 13.5 1.38079 13.3068 1.03697 12.963C0.693154 12.6192 0.5 12.1529 0.5 11.6667V2.33333C0.5 1.8471 0.693154 1.38079 1.03697 1.03697C1.38079 0.693154 1.8471 0.5 2.33333 0.5H5.66667C5.94281 0.5 6.16667 0.723858 6.16667 1C6.16667 1.27614 5.94281 1.5 5.66667 1.5H2.33333ZM9.20473 0.808658C9.28212 0.621821 9.46444 0.5 9.66667 0.5H12.9999H13C13.0678 0.5 13.1324 0.513493 13.1914 0.537939C13.2504 0.562339 13.3056 0.598508 13.3536 0.646447C13.4015 0.694385 13.4377 0.749636 13.4621 0.808609C13.4842 0.862233 13.4967 0.918935 13.4994 0.976015C13.4998 0.984005 13.5 0.992002 13.5 1V1.00049V4.33333C13.5 4.53556 13.3782 4.71788 13.1913 4.79527C13.0045 4.87266 12.7894 4.82989 12.6464 4.68689L11.3333 3.37377L8.35355 6.35355C8.15829 6.54882 7.84171 6.54882 7.64645 6.35355C7.45118 6.15829 7.45118 5.84171 7.64645 5.64645L10.6262 2.66667L9.31311 1.35355C9.17011 1.21055 9.12734 0.995495 9.20473 0.808658Z"
          fill="#D1433A"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    listData: Array,
    title: String,
    currentItem: Number,
  },
  data() {
    return {
      showMembershipsListCurrent: 5,
    };
  },
  methods: {
    showCurrentCards(array, current) {
      if (array.length > current) {
        this.showMembershipsListCurrent = current + 5;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wr_Memberships {
  margin-bottom: 15px;
  .titleSection {
    margin: 42px 0 16px;
    font-family: HelveticaNeueBold;
    color: #071526;
    text-transform: capitalize;
    font-size: 18px;
  }
  .title_grid {
    width: 100%;
    padding: 0 20px;
    margin: 45px 0 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: HelveticaNeueBold;
    font-size: 11px;
    color: #47505b;
    .w_for_art_title {
      padding-left: 55px;
    }
    div {
      display: flex;
      align-items: center;
      line-height: 13px;
      // justify-content: center;
    }
    svg {
      margin-left: 8px;
    }
  }
  button {
    margin-top: 20px;
    width: 100%;
    display: flex;
    outline: none;
    background: #e6eaf0;
    border-radius: 4px;
    border: none;
    height: 30px;
    justify-content: center;
    align-items: center;
    font-family: HelveticaNeueBold;
    font-size: 10px;
    line-height: 12px;
    color: #071526;
    svg {
      margin: 0 10px;
    }
  }
  .artist_item {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 10px 20px;
    .art_img_section {
      display: flex;
      align-items: center;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #D1433A;
      .imgdiv {
        margin-right: 11px;
        min-width: 40px;
        width: 40px;
        height: 40px;
        position: relative;
        border-radius: 50%;
        img {
          width: 100%;
          border-radius: 50%;
        }
        div {
          position: absolute;
          border-radius: 50%;
          width: 7px;
          height: 7px;
          background: #1cec9e;
          border: 1px solid #ffffff;
          right: 3px;
          bottom: 1px;
        }
      }
    }
    .art_menu {
      position: relative;
      cursor: pointer;
      height: 30px;
      border: 1.5px solid #D1433A;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        .menu_modal_wrap {
          display: block;
        }
      }
      .menu_modal_wrap {
        padding: 50px 20px 20px;
        display: none;
        position: absolute;
        top: -25px;
        right: -26px;
        z-index: 1;
        .menu_modal {
          width: 240px;
          padding: 10px 0;
          background: #ffffff;
          border-radius: 4px;
          box-shadow: 0 4px 10px rgba(74, 75, 77, 0.5);
          span {
            display: block;
            width: 100%;
            margin: 10px 0;
            border: 1px solid #e6eaf0;
          }
          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 8px 21px;
            font-family: HelveticaNeueMedium;
            font-size: 13px;
            color: #000000;
            &:hover {
              background: #D1433A1A;
            }
            svg {
              margin-right: 15px;
            }
          }
        }
      }
    }
    .art_price {
      display: flex;
      align-items: center;
      padding: 7px 10px;
      border: 1px solid #c4ccd6;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: HelveticaNeueNormal;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: 13px;
      line-height: 16px;
      color: #47505b;
      svg {
        margin-right: 11px;
      }
    }
    .art_type {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      border: 1px solid #e6eaf0;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: HelveticaNeueNormal;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: 11px;
      color: #071526;
    }
    .art_date {
      font-family: HelveticaNeueNormal;
      font-weight: normal;
      letter-spacing: 1px;
      font-size: 13px;
      line-height: 16px;
      color: #8b949f;
    }
  }
  .w_for_art_title {
    // justify-content: center;
    width: 100%;
    max-width: 186px;
    margin-right: 10px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .w_for_art_type {
    width: 100px;
  }
  .w_for_art_date {
    width: 104px;
  }
  .w_for_art_price {
    width: 90px;
  }
  .w_for_art_menu {
    width: 30px;
  }
}
</style>
