<template>
  <div class="wr_EMevent_Card">
    <div class="img_div"><img src="" alt="" /></div>
    <section>
      <article class="wr_info">
        <div>
          <h3>Playground Presents Nina Kraviz</h3>
          <ul>
            <li>Night Club Volf • Vienna, Austria</li>
            <li>Friday 16th October (8:00pm-4:00am)</li>
          </ul>
        </div>
        <div class="date"><span>JUL</span> <span>12</span></div>
      </article>
      <article class="btns_and_price">
        <div class="price">Sold: 240 / 280 • Gross: $ 1,024</div>
        <div class="wr_btn">
          <button @click="goEventDashboardPage(6)" class="for_hover_blue_btn">
            DASHBOARD
          </button>
          <button class="for_hover_empty_border_blue edit">EDIT</button>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    goEventDashboardPage(id) {
      this.$router.push({
        name: "event.detail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_EMevent_Card {
  width: 100%;
  height: 170px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 0px 4px 4px 0px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  .img_div {
    width: 130px;
    height: 130px;
    border-radius: 4px;
    background: #c3c3c3;
    img {
      width: 100%;
      border-radius: 4px;
    }
  }
  section {
    width: calc(100% - 160px);
    .wr_info {
      display: flex;
      justify-content: space-between;
      h3 {
        font-family: HelveticaNeueBold;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #071526;
        margin-bottom: 14px;
      }
      ul {
        font-family: HelveticaNeueNormal;
        letter-spacing: 0.7px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #8b949f;
        list-style-type: none;
        padding: 0;
        li {
          margin-bottom: 14px;
        }
      }
      .date {
        background: #ffffff;
        border: 1px solid #c4ccd6;
        border-radius: 4px;
        height: 50px;
        width: 50px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-family: HelveticaNeueBold;
        }
        :nth-child(1) {
          background: #95b3d7;
          color: #ffffff;
          font-size: 12px;
          height: 20px;
        }
        :nth-child(2) {
          height: 30px;
          font-size: 18px;
        }
      }
    }
    .btns_and_price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .price {
        background: #e6eaf0;
        border-radius: 2px;
        height: 30px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 11px;
        color: #47505b;
        font-family: HelveticaNeueMedium;
      }
      .wr_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          border: 1.5px solid #D1433A;
          border-radius: 3px;
          font-weight: 700;
          font-size: 11px;
          line-height: 13px;
          font-family: HelveticaNeueBold;
          height: 30px;
          padding: 0 30px;
          display: flex;
          align-items: center;
          background: #D1433A;
          color: #fff;
        }
        .edit {
          margin-left: 10px;
          background: #fff;
          color: #D1433A;
        }
      }
    }
  }
}
</style>