<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Published</h3>
        <h6>Published (switcher is on) anyone can see this button in your Engage. Switch off if you want to hide your
          button</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" id="switch22" v-model="book_now_settings.visibility">
        <label for="switch22" class="switch_sm">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title">
        <h4>Companies can book you for events</h4>
        <p>We’ll show form with fields and event place for companies or event managers to book you</p>
      </div>


    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13.6 4H2.4C1.6268 4 1 4.63959 1 5.42857V12.5714C1 13.3604 1.6268 14 2.4 14H13.6C14.3732 14 15 13.3604 15 12.5714V5.42857C15 4.63959 14.3732 4 13.6 4Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M11 14V2.44444C11 2.06135 10.842 1.69395 10.5607 1.42307C10.2794 1.15218 9.89782 1 9.5 1H6.5C6.10218 1 5.72064 1.15218 5.43934 1.42307C5.15804 1.69395 5 2.06135 5 2.44444V14"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        Book Now
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      book_now_settings:{
        visibility: false,
        action_button_link: null
      }
    }
  },
  props: {
    bookNowSettings: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    'book_now_settings.visibility'() {
      this.$emit('getBookNowData', this.book_now_settings)
    },
    bookNowSettings() {
      this.setBookNowSettingsData()
    }
  },
  mounted() {
    this.setBookNowSettingsData()
  },
  methods: {
    setBookNowSettingsData () {
      this.book_now_settings = this.bookNowSettings
    }
  }
}
</script>
