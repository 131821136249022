import {
  getAllForums,
  createNewThread,
  getForumThreads,
  getStatisticsForums,
  getMyThread,
  getThread,
  postReplyOnThread,
  AddViewOnThread,
  ReportReply,
  getMyReplies,
  EditReply,
  EditThread,
  deleteReply,
  deleteThread,
  getSearchForumResult,
  getForumsName,
  getAllUserId,
  getAllOnlineUsers,
} from "../../apis/APIs";
import { ERROR, SUCCESS } from "../types";
import createFormData from "../../mixins/common";
const INITIAL_STATE = {
  navMainPath: null,
  subPath_2: "",
  subPath_3: "",
  editorData: "",
  editorTitle: "",
  deleteType: "",
  active_tab: '',
  active_tab_search: "",
  searchKeyword: "",
  searchParams: {
    keyword: "",
    search_for: "",
    forum: "",
  },
  searchResult: { data: null },
  forumNameList: "",
  //
  allForums: null,
  mainForumId: null,
  typeForumThreads: null,
  statisticsForums: null,
  mainForumThreads: { data: null },
  //
  myThreads: { data: null },
  thread_id: null,
  threadData: null,
  //
  reply_id: null,
  reply_data: null,
  //
  myReplies: { data: null },
  all_online_users_id: [],
  all_online_users: [],
};
const state = { ...INITIAL_STATE };
const getters = {
  GET_NAV_MAIN_PATH(state) {
    return state.navMainPath;
  },
  GET_SUB_PATH_3(state) {
    return state.subPath_3;
  },
  GET_SUB_PATH_2(state) {
    return state.subPath_2;
  },
  GET_DELETE_TYPE(state) {
    return state.deleteType;
  },
  GET_EDITOR_DATA(state) {
    return state.editorData;
  },
  GET_EDITOR_TITLE_DATA(state) {
    return state.editorTitle;
  },
  GET_ALL_FORUMS(state) {
    return state.allForums;
  },
  GET_MAIN_FORUM_ID(state) {
    return state.mainForumId;
  },
  GET_MAIN_FORUM_THREADS(state) {
    return state.mainForumThreads;
  },
  GET_TYPE_FORUM_THREADS(state) {
    return state.typeForumThreads;
  },
  GET_STATISTICS_FORUM(state) {
    return state.statisticsForums;
  },
  //
  GET_MY_THREADS(state) {
    return state.myThreads;
  },
  GET_THREAD_ID(state) {
    return state.thread_id;
  },
  GET_THREAD_DATA(state) {
    return state.threadData;
  },
  //
  GET_REPLY_ID(state) {
    return state.reply_id;
  },
  GET_REPLY_DATA(state) {
    return state.reply_data;
  },
  //
  GET_MY_REPLISE(state) {
    return state.myReplies;
  },
  GET_SEARCH_KEYWORD(state) {
    return state.searchKeyword;
  },
  GET_FORUM_NAME_LIST(state) {
    return state.forumNameList;
  },
  GET_ACTIVE_TAB_SEARCH(state) {
    return state.active_tab_search;
  },
  GET_SEARCH_RESULT(state) {
    return state.searchResult;
  },
  GET_ALL_ONLINE_USERS(state) {
    return state.all_online_users;
  },
  GET_ACTIVE_TAB(state) {
    return state.active_tab
  }
};
const mutations = {
  changeNavMainPath(state, data) {
    state.navMainPath = data;
  },
  changeSubPath3(state, data) {
    state.subPath_3 = data;
  },
  changeSubPath2(state, data) {
    state.subPath_2 = data;
  },
  changeEditorData(state, data) {
    state.editorData = data;
  },
  changeEditorTitle(state, data) {
    state.editorTitle = data;
  },
  changeAllForums(state, data) {
    state.allForums = data;
  },
  changeMainForumId(state, data) {
    state.mainForumId = data;
  },
  changeMainForumThreads(state, data) {
    state.mainForumThreads = data;
  },
  changeTypeForumThreads(state, data) {
    state.typeForumThreads = data;
  },
  changeStatisticsForums(state, data) {
    state.statisticsForums = data;
  },
  //
  changeMyThreads(state, data) {
    state.myThreads = data;
  },
  changeThreadId(state, data) {
    state.thread_id = data;
  },
  changeThreadData(state, data) {
    state.threadData = data;
  },
  //
  changeReplyId(state, data) {
    state.reply_id = data;
  },
  changeReplyData(state, data) {
    state.reply_data = data;
  },
  //
  changeMyReplies(stste, data) {
    stste.myReplies = data;
  },
  changeDeleteType(state, data) {
    state.deleteType = data;
  },
  changeSearchKeyword(state, data) {
    state.searchKeyword = data;
  },
  changeSearchParams(state, data) {
    state.searchParams = data;
  },
  changeForumNameList(state, data) {
    state.forumNameList = [{ id: "All Forums", name: "All Forums" }, ...data];
  },
  changeSearchResult(state, data) {
    state.searchResult = data;
  },
  changeActiveTabSearch(state, data) {
    state.active_tab_search = data;
  },
  set_all_online_users_id(state, data) {
    state.all_online_users_id = data;
   },
  set_all_online_users(state, data) {
    state.all_online_users = {...state.all_online_users,...data};
  },
  setActiveTab(state, data) {
    state.active_tab = data
  }
};
const actions = {
  async getAllForums({ commit }) {
    let { data } = await getAllForums();
    commit("changeAllForums", data.information);
  },
  async createNewThread({}, body) {
    await createNewThread(body);
  },
  async postReplyOnThread({}, body) {
    await postReplyOnThread(body);
  },
  async AddViewOnThread({}, thread_id) {
    await AddViewOnThread(thread_id);
  },
  async ReportReply({ commit }, thread_id) {
    let { data } = await ReportReply(thread_id);
    commit("changeReplyData", data);
  },
  async getForumThreads({ commit }, params) {
    let { data } = await getForumThreads(params.forum_id, params.offset);
    commit("changeMainForumThreads", data.information);
  },
  async getStatisticsForums({ commit }) {
    let { data } = await getStatisticsForums();
    commit("changeStatisticsForums", data.information);
  },
  //
  async getMyThread({ commit }, params) {
    let { data } = await getMyThread(params.offset);
    commit("changeMyThreads", data.information);
  },
  async getThread({ commit }, params) {
    let { data } = await getThread(params.thread_id, params.offset);
    commit("changeThreadData", data.information);
  },
  async getMyReplies({ commit }, params) {
    let { data } = await getMyReplies(params.offset);
    commit("changeMyReplies", data.information);
  },
  async editReply({}, params) {
    let { data } = await EditReply(params.reply_id, {
      content: params.content,
    });
  },
  async deleteReply({}, reply_id) {
    let { data } = await deleteReply(reply_id);
  },
  async editThread({}, params) {
    let { data } = await EditThread(params.thread_id, {
      title: params.title,
      content: params.content,
    });
  },
  async deleteThread({}, thread_id) {
    let { data } = await deleteThread(thread_id);
  },
  async getSearchForumResult({ state, commit }, offset) {
    let { data } = await getSearchForumResult(
      state.searchParams,
      offset.offset
    );
    commit("changeSearchResult", data.information);
  },
  async getForumsName({ commit }) {
    let { data } = await getForumsName();
    commit("changeForumNameList", data.information);
  },

  async getAllOnlineUsers({state, commit, dispatch }, { user_ids, offset = 0}) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        if (user_ids) {
          const formData = createFormData(user_ids);
          const { data } = await getAllOnlineUsers(formData, offset)
          const {information, message, success} = data || {}
          dispatch('loading', false)
          resolve({
            status: success,
            message: message,
            data: information
          });
        }
      } catch (e) {
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: e.message ? e.message : "Please try again."
        });
      }
      // const { data } = await getAllOnlineUsers({user_ids:state.all_online_users_id},offset);
      // commit("set_all_online_users", data.information);
    })
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
