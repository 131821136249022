<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0.666016V15.3327"
                stroke="#47505B"
                stroke-width="1.5"
              />
              <path
                d="M11.3333 3.33398H6.33333C5.71449 3.33398 5.121 3.57982 4.68342 4.0174C4.24583 4.45499 4 5.04848 4 5.66732C4 6.28616 4.24583 6.87965 4.68342 7.31723C5.121 7.75482 5.71449 8.00065 6.33333 8.00065H9.66667C10.2855 8.00065 10.879 8.24648 11.3166 8.68407C11.7542 9.12165 12 9.71515 12 10.334C12 10.9528 11.7542 11.5463 11.3166 11.9839C10.879 12.4215 10.2855 12.6673 9.66667 12.6673H4"
                stroke="#47505B"
                stroke-width="1.5"
              />
            </svg>
          </div>
          <p class="ml-3 mb-0">Bank Account</p>
        </div>
        <div class="bank-account-detail">
          <ul class="nav bank-account-tab" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                >BANK DETAILS</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                >Withdrawals history</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="bank-acc-inner">
                <div class="row" v-if="message || success_message">
                  <div class="col-12">
                    <div v-if="message" class="alert alert-danger" role="alert">
                      {{ message }}
                    </div>
                    <div
                      v-if="success_message"
                      class="alert alert-success"
                      role="alert"
                    >
                      {{ success_message }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 custom-col-4">
                    <div class="bank-detail-text">
                      Fill out your banking details. You can withdraw your funds
                      from AudioGround straight to your Bank Account.
                    </div>
                  </div>
                </div>



                <div class="bankeDetailContain">
                  <div class="width201" >

                    <div class="attention-area">
                      <div class="attention-icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.0002 36.6668C29.2049 36.6668 36.6668 29.2049 36.6668 20.0002C36.6668 10.7954 29.2049 3.3335 20.0002 3.3335C10.7954 3.3335 3.3335 10.7954 3.3335 20.0002C3.3335 29.2049 10.7954 36.6668 20.0002 36.6668Z"
                            stroke="#47505B"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20 13.3335V20.0002"
                            stroke="#47505B"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20 26.6665H20.0167"
                            stroke="#47505B"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <h1>Attention!</h1>
                      <p>
                        Please check your bank details carefully and make sure
                        you fill all fields right.
                      </p>
                    </div>

                  </div>
                  <div class="width500">


                    <!-- <div class="bank-account-field">
                       <label>Bank Name</label>
                       <input type="text" placeholder="Bank with Money LTD.">
                    </div> -->
                    <div class="bank-account-field">
                      <label>Bank Name</label>
                      <input
                        type="text"
                        placeholder="Ivan Fedorov"
                        v-model="name"
                      />
                      <label
                        class="error_msg error_msg_red"
                        v-if="_handleErrorMessage('name')"
                        >{{ _handleErrorMessage("name") }}</label
                      >
                    </div>
                 


                    <div class="two-account-field">
                      <div class="bank-account-field">
                        <label>Account Name</label>
                        <input
                          type="text"
                          placeholder="000000001"
                          v-model="personal_id_number"
                        />
                        <label
                          class="error_msg error_msg_red"
                          v-if="_handleErrorMessage('account_number')"
                          >{{ _handleErrorMessage("account_number") }}</label
                        >
                      </div>
                      <!-- <div  class="fromto-select language-select ">
                         <label>Country</label>
                         <Select v-model="selectedCountry">
                           <Option v-for="(eachCountry, index) in country"  :value="eachCountry.code" :key="index">{{eachCountry.name}}</Option>
                         </Select>
                      </div> -->
                    </div>

                    <div class="two-account-field mb-13">
                      <div class="bank-account-field ac-no">
                        <label>Account Number</label>
                        <input
                          type="text"
                          placeholder="1112 3318 2229 0001"
                          v-model="routing_number"
                        />
                        <label
                          class="error_msg error_msg_red"
                          v-if="_handleErrorMessage('routing_number')"
                          >{{ _handleErrorMessage("routing_number") }}</label
                        >
                      </div>
                      <div class="bank-account-field iban">
                        <label>IBAN</label>
                        <input
                          type="text"
                          placeholder="1112 3318 2229 0001"
                          v-model="account_number"
                        />
                        <label
                          class="error_msg error_msg_red"
                          v-if="_handleErrorMessage('account_number')"
                          >{{ _handleErrorMessage("account_number") }}</label
                        >
                      </div>
                    </div>




                  </div>
                </div>




                <div class="row">
                  <div class="col text-right">
                    <button
                      type="submit"
                      class="btn save-btn"
                      @click="submitForm"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="withdrawal-history">
                <div class="session-table">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Payment</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(eachWithdraw, index) in withdrawals"
                        :key="index"
                      >
                        <td>{{ eachWithdraw.created_date }}</td>
                        <td>{{ eachWithdraw.payment_method }}</td>
                        <td class="t-amount">
                          ${{ parseFloat(eachWithdraw.amount).toFixed(2) }}
                        </td>
                      </tr>
                      <tr>
                        <td>14 Oct 2020, 04:30 AM</td>
                        <td>
                          <div class="withrawl-bank">
                            Withdrawal to Bank Account
                          </div>
                          <div class="comp">
                            <span>
                              <svg
                                width="10"
                                height="8"
                                viewBox="0 0 10 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 1L3.5 6.5L1 4"
                                  stroke="#15CA86"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </span>
                            Completed
                          </div>
                        </td>
                        <td class="t-amount">$129</td>
                      </tr>
                      <tr>
                        <td>12 Oct 2020, 02:00 AM</td>
                        <td>
                          <div class="withrawl-bank">
                            Withdrawal to Bank Account
                          </div>
                          <div class="comp pending">
                            <span>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                  stroke="#8B949F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M6 3V6L8 7"
                                  stroke="#8B949F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </span>
                            Pending
                          </div>
                        </td>
                        <td class="t-amount">$240</td>
                      </tr>
                      <tr>
                        <td>12 Oct 2020, 02:00 AM</td>
                        <td>
                          <div class="withrawl-bank">
                            Withdrawal to Bank Account
                          </div>
                          <div class="comp cancel">
                            <span>
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3L3 9"
                                  stroke="#EE3F3F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M3 3L9 9"
                                  stroke="#EE3F3F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </span>
                            Cancelled
                            <div class="info-icon">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                  stroke="#C4CCD6"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6 8V6"
                                  stroke="#C4CCD6"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6 4H6.005"
                                  stroke="#C4CCD6"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="info-popup-wrap">
                            <div class="info-popup">
                              <div class="info-popup-title">Cancelled</div>
                              <div class="info-popup-text">
                                Bank details is incorrect. Please change it in
                                <a href="">Settings</a>.
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="t-amount">$240</td>
                      </tr>
                      <tr>
                        <td>14 Oct 2020, 04:30 AM</td>
                        <td>
                          <div class="withrawl-bank">
                            Withdrawal to Bank Account
                          </div>
                          <div class="comp">
                            <span>
                              <svg
                                width="10"
                                height="8"
                                viewBox="0 0 10 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 1L3.5 6.5L1 4"
                                  stroke="#15CA86"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </span>
                            Completed
                          </div>
                        </td>
                        <td class="t-amount">$129</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div class="page_number">
                  <ul class="pagination">
                    <li
                      :class="['page-item', page == n ? 'active' : '']"
                      v-on:click="changePage(n)"
                      v-for="(n, index) in total_page"
                      :key="index"
                    >
                      <a href="#" class="page-link">{{ n }}</a>
                    </li>
                  </ul>
                </div> -->
                <div class="pagination-row mt_30">
                  <div class="pagination-info">
                    <div class="title">
                      Displaying (<span class="visible-pages">1-20</span> of
                      <span class="total-pages">263</span>)
                    </div>
                  </div>
                  <div class="pagination-action">
                    <ul class="pagination">
                      <li class="page-item disabled">
                        <span class="page-link prev">
                          <div class="svg-icon">
                            <svg
                              width="6"
                              height="11"
                              viewBox="0 0 6 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                class="c-line-1"
                                d="M5.30078 9.83203L1.00078 5.60103L5.30078 0.999031"
                                stroke="#8B949F"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          Previous</span
                        >
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item active">
                        <span class="page-link">
                          2
                          <span class="sr-only">(current)</span>
                        </span>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">4</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">5</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">6</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link next" href="#"
                          >Next
                          <div class="svg-icon">
                            <svg
                              width="6"
                              height="11"
                              viewBox="0 0 6 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                class="c-line-1"
                                d="M1 9.83203L5.3 5.60103L0.999999 0.999031"
                                stroke="#8B949F"
                                stroke-width="1.3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg></div
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=""></div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import { countries } from "../../../components/utils/countries";
import * as Helper from "../../../apis/Helper.js";
import {
  updateConnectedAccount,
  getBankDetails,
  getCountry,
  getAllTransfers,
} from "../../../apis/APIs";
import { EDIT_PROFILE_ERRORS, SUCCESS, ERROR } from "../../../store/types";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      name: "",
      routing_number: "",
      account_number: "",
      personal_id_number: "",
      //  country:'US',
      message: "",
      success_message: "",
      loading: false,
      errors: [],
      country: [],
      selectedCountry: "",
      withdrawals: [],
      page: 1,
      total_page: 1,
    };
  },
  directives: { mask },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
  },
  created() {
    this.fetchBankDetails();
    this.getCountry();
    this.getAllTransfers();
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    ...mapMutations(["updateEditProfileFormData", "updateCardUIConstraints"]),
    async fetchBankDetails() {
      let user_id = this.user.user_data.user_id;
      try {
        let formdata = new FormData();
        formdata.append("user_id", user_id);
        formdata.append("function_type", "get_bank_details");
        const res = await getBankDetails(formdata);
        console.log(res, "hhh");
        if (res.success) {
          this.name = res.data[0]["account_holder_name"];
          this.routing_number = res.data[0]["routing_number"];
          this.account_number = res.data[0]["account_number"];
          this.personal_id_number = res.data[0]["personal_id_number"];
          // this.country = res.data[0]['country_code'];
          this.selectedCountry = "US";
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    changePage: function (n) {
      this.page = n;
      this.getAllTransfers();
    },
    async getAllTransfers() {
      let user_id = this.user.user_data.user_id;
      try {
        let formdata = new FormData();
        formdata.append("user_id", user_id);
        formdata.append("function_type", "get_all_transactions");
        formdata.append("page", this.page);
        const res = await getAllTransfers(formdata);
        console.log(res, "trans");
        if (res.success) {
          this.withdrawals = res.data;
          let count = res.count;
          this.total_page = count % 10 > 0 ? parseInt(count / 10) + 1 : 1;
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    async getCountry() {
      try {
        const res = await getCountry();
        if (res.success) {
          this.country = res.data;
          console.log(this.country);
        }
      } catch (error) {
        this.list_loading = false;
      }
    },

    closeModal: function () {
      this.$parent.closeWindow();
    },
    goBack: function () {
      this.$parent.backF();
    },
    async submitForm() {
      console.log(this);
      const { user_data } = this.user || {};
      const { user_id } = user_data || {};

      this.errors = [];

      if (!this.name) {
        this.errors.push({
          fieldName: "name",
          message: "Required",
        });
      }

      if (!this.routing_number) {
        this.errors.push({
          fieldName: "routing_number",
          message: "Required",
        });
      }

      if (!this.account_number) {
        this.errors.push({
          fieldName: "account_number",
          message: "Required",
        });
      }
      if (!this.personal_id_number) {
        this.errors.push({
          fieldName: "personal_id_number",
          message: "Required",
        });
      }
      try {
        var formdata = new FormData();

        formdata.append("customer_id", user_id);
        formdata.append("function_type", "add_connected_account");
        if (this.name) formdata.append("account_holder_name", this.name);
        if (this.routing_number)
          formdata.append("routing_number", this.routing_number);
        if (this.account_number)
          formdata.append("account_number", this.account_number);
        if (this.personal_id_number)
          formdata.append("personal_id_number", this.personal_id_number);

        this.message = "";
        this.loading = true;
        // alert('321');
        const { success, message } = await updateConnectedAccount(formdata);
        console.log(success, message, "error");

        if (success === 1) {
          this.success_message = message;
          // this.fetchUserData();
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);
      return message;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
              status: true,
              message:
                this.errors[
                  this.errors.findIndex((ele) => ele.fieldName === key)
                ].message,
            }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
  },
};
</script>
