<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Hidden</h3>
        <h6>Published (switcher is on) anyone can see this button in your Engage. Switch off if you want to hide your
          button</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input v-model="other_settings.visibility" type="checkbox" id="switch32"/>
        <label class="switch_sm" for="switch32">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4>Where would you like this button to send people?</h4>
        <p>When customer click your button, they will be directed to a place where they can take an action or find more
          information.</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">
          <div class="col-12">
            <div class="engage_field mb_17px">
              <label class="engage_fields_label">Action Button Text</label>
              <input v-model="other_settings.button" type="text" class="form_control" placeholder="Your Text">
            </div>
          </div>
          <div class="col-12">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">Action Button Link</label>
              <input v-model="other_settings.action_link_url" type="text" class="form_control">
              <small class="text_muted">Website link must start with http:// or https://</small>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/link.svg" alt="link"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M6.66699 8.66697C6.95329 9.04972 7.31856 9.36642 7.73803 9.5956C8.15749 9.82477 8.62133 9.96105 9.09809 9.99519C9.57485 10.0293 10.0534 9.96055 10.5012 9.79349C10.9491 9.62643 11.3557 9.36502 11.6937 9.02697L13.6937 7.02697C14.3009 6.3983 14.6368 5.55629 14.6292 4.6823C14.6216 3.80831 14.2711 2.97227 13.6531 2.35424C13.035 1.73621 12.199 1.38565 11.325 1.37806C10.451 1.37046 9.609 1.70644 8.98033 2.31364L7.83366 3.45364"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M9.33347 7.33283C9.04716 6.95008 8.68189 6.63338 8.26243 6.40421C7.84297 6.17503 7.37913 6.03875 6.90237 6.00461C6.4256 5.97047 5.94707 6.03925 5.49924 6.20631C5.0514 6.37337 4.64472 6.63479 4.3068 6.97283L2.3068 8.97283C1.69961 9.60151 1.36363 10.4435 1.37122 11.3175C1.37881 12.1915 1.72938 13.0275 2.3474 13.6456C2.96543 14.2636 3.80147 14.6142 4.67546 14.6217C5.54945 14.6293 6.39146 14.2934 7.02013 13.6862L8.16013 12.5462"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ (other_settings && other_settings.button) ? other_settings.button : 'Your Text' }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      other_settings: {
        visibility: false,
        button: null,
        action_link_url: null
      }
    }
  },
  props: {
    otherSettings: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    'other_settings.visibility'() {
      this.$emit('getOtherData', this.other_settings)
    },
    'other_settings.button'() {
      this.$emit('getOtherData', this.other_settings)
    },
    'other_settings.action_link_url'() {
      this.$emit('getOtherData', this.other_settings)
    },
    otherSettings() {
      this.setOtherSettingsData()
    }
  },
  mounted() {
    this.setOtherSettingsData()
  },
  methods: {
    setOtherSettingsData() {
      this.other_settings = this.otherSettings
    }
  }
}
</script>
