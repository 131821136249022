<template lang="">
    <button type="button" @click="handlePress">
        <svg width="18" v-if="!loading" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 4.5L4.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.5 4.5L13.5 13.5" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p v-if="loading">Loading...</p>
    </button>

</template>
<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import * as Utils from '../../../../components/utils/Utils';
import * as Helper from '../../../../apis/Helper.js';
import {user_education_api, user_work_api} from '../../../../apis/APIs';
import {SUCCESS, ERROR} from '../../../../store/types';
import moment from 'moment-timezone';

export default {
  data() {
    return ({
      loading: false
    })
  },
  props: ["id", "deleteItem"],
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    })
  },
  mounted() {

  },
  methods: {
    handlePress() {
      this.loading = true;
      this.deleteItem(this.id, () => {
        this.loading = false;
      });
    }
  }
}
</script>
<style lang="">

</style>
