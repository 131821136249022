<template>
  <div class="wr_ads_right">
    <article class="wr_indicator">
      <ul>
        <li>
          <h6>VIEW</h6>
          <span>100k</span>
        </li>
        <li>
          <h6>CLICKS</h6>
          <span>3k</span>
        </li>
        <li>
          <h6>CTR</h6>
          <span>0.3</span>
        </li>
      </ul>
    </article>
    <article class="wr_chart_area">
      <section class="chart_tab_sec">
        <ul>
          <li
            v-for="item in chartTabsData"
            :key="item.id"
            :class="{ activeTab: item.active }"
          >
            {{ item.tab }}
          </li>
        </ul>
        <div class="showMore">
          <span>SHOW MORE</span
          ><span class="menu three_dots_menustyle for_hover_gray_btn"
            ><svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 1.75H12.25V5.25"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.25 12.25H1.75V8.75"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.2503 1.75L8.16699 5.83333"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.75 12.25L5.83333 8.16663"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </section>
      <div class="chart_area">
        <apexchart
          height="170"
          type="area"
          :options="optionsAdsCartArea"
          :series="seriesAdsCartArea"
        ></apexchart>
      </div>
      <section class="chart_params">
        <ul>
          <li
            v-for="item in adsData.slice(0, moreCahrtLine)"
            :key="item.id"
            @click="handleChartActiveLine(item.id)"
            :class="{ activeChartLine: item.activeChart }"
          >
            <div>
              <span :style="{ background: item.bg }" class="cl_chart"></span
              >{{ item.name }}
            </div>
            <span class="check_chart"
              ><span v-if="item.activeChart"></span
            ></span>
          </li>
        </ul>
        <span @click="handleMoreLess" class="more"
          >{{ moreCahrtLine === 4 ? "MORE" : "LESS" }}
          {{
            moreCahrtLine === 4
              ? "(" + (adsData.length - moreCahrtLine) + ")"
              : ""
          }}</span
        >
      </section>
    </article>
    <article class="wr_budget">
      <h5>Budget</h5>
      <div>
        <ul>
          <li>Remaining: <span>$100</span></li>
        </ul>
        <button class="btn_empty_border_blue for_hover_empty_border_blue">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_572_173520)">
              <path
                d="M14.0003 3.55237H2.00033C1.26395 3.55237 0.666992 4.14932 0.666992 4.8857V12.8857C0.666992 13.6221 1.26395 14.219 2.00033 14.219H14.0003C14.7367 14.219 15.3337 13.6221 15.3337 12.8857V4.8857C15.3337 4.14932 14.7367 3.55237 14.0003 3.55237Z"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0.666992 7.55237H15.3337"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          TOP UP
        </button>
      </div>
      <ul>
        <li>Spent Today: <span>$32</span></li>
        <li>Spent Yesterday: <span>$40</span></li>
      </ul>
    </article>
  </div>
</template>

<script>
export default {
  props: ["adsData"],
  data() {
    return {
      optionsAdsCartArea: {
        chart: {
          id: "vuechart-example",
          foreColor: "#8B949F",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          gradient: {
            opacityFrom: 0,
            opacityTo: 0,
          },
        },
        markers: {
          size: 3,
          radius: 3,
          strokeWidth: 0,
          //   hover: {
          //     size: 5,
          //     sizeOffset: 0,
          //   },
        },
        legend: {
          show: false,
        },
        stroke: {
          width: 1,
          curve: "straight",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          //   position: "top",
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "9px",
            },
          },
          categories: [
            "29/02",
            "30/02",
            "31/02",
            "01/03",
            "02/03",
            "03/03",
            "04/03",
            "05/03",
          ],
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              fontSize: "9px",
            },
          },
        },
      },
      seriesAdsCartArea: [
        {
          name: "This Week",
          color: "#D1433A",
          data: [10, 20, 5, 30, 30, 40, 50, 40],
        },
        {
          name: "Last Week",
          color: "#FDB390",
          data: [30, 30, 40, 40, 50, 20, 30, 51],
        },
      ],
      chartTabsData: [
        { id: 1, tab: "Clicks", active: true },
        { id: 2, tab: "Visits", active: false },
        { id: 3, tab: "CTR", active: false },
      ],
      moreCahrtLine: 4,
    };
  },
  methods: {
    handleChartActiveLine(id) {
      this.adsData.forEach((el) => {
        if (el.id === id) {
          el.activeChart = !el.activeChart;
        }
      });
    },
    handleMoreLess() {
      if (this.moreCahrtLine === this.adsData.length) {
        this.moreCahrtLine = 4;
      } else {
        this.moreCahrtLine = this.adsData.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_ads_right {
  .wr_indicator {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px 20px 10px;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style-type: none;
      padding: 0;
      margin: 0;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      h6 {
        font-size: 11px;
        line-height: 13px;
        color: #8b949f;
      }
      span {
        font-size: 36px;
        line-height: 44px;
        color: #47505b;
        opacity: 0.9;
      }
    }
  }
  .wr_budget {
    margin-top: 10px;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    h5 {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #071526;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      ul {
        margin: 0;
        li {
          background: #f3f3f3;
          width: 180px;
          margin: 0;
        }
      }
      button {
        width: 120px;
        svg {
          margin-right: 7px;
        }
      }
    }
    ul {
      padding: 0;
      margin: 10px 0 0 0;
      list-style-type: none;
      li {
        background: #f9fafb;
        height: 30px;
        padding: 0 10px;
        border-radius: 4px;
        margin-top: 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #8b949f;
        font-family: HelveticaNeueNormal;
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;
        span {
          margin-left: 5px;
          color: #47505b;
          font-family: HelveticaNeueBold;
        }
      }
    }
  }
  .wr_chart_area {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    margin-top: 10px;
  }
  .chart_area {
    border: 1px solid #e6eaf0;
    pointer-events: none;
  }
  .chart_tab_sec {
    font-weight: 700;
    font-family: HelveticaNeueBold;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .showMore {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
    .menu {
      cursor: pointer;
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      list-style-type: none;
      font-size: 18px;
      line-height: 22px;
      color: #c4ccd6;
      li {
        cursor: pointer;
        margin-right: 10px;
        border-bottom: 1px dashed #e6eaf0;
      }
      .activeTab {
        color: #071526;
        border: none;
      }
    }
  }
  .chart_params {
    ul {
      padding: 0;
      margin: 17px 0 0 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #8b949f;
      font-family: HelveticaNeueMedium;
      li {
        cursor: pointer;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        div {
          display: flex;
          align-items: center;
          span {
            display: block;
            margin-right: 7px;
          }
        }
        span {
          width: 16px;
          height: 16px;
        }
        .check_chart {
          border: 1px solid #e6eaf0;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 8px;
            height: 5px;
            display: block;
            border-bottom: 1px solid #D1433A;
            border-left: 1px solid #D1433A;
            transform: rotate(-50deg);
            margin-bottom: 4px;
          }
        }
      }
      .activeChartLine {
        color: #071526 !important;
        .check_chart {
          border: 1px solid #D1433A !important;
        }
      }
    }
    .more {
      cursor: pointer;
      font-family: HelveticaNeueBold;
      color: #D1433A;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
</style>