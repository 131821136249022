<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <div class="list">
              <div class="card sigles-card active">
                <div class="row no-gutters">
                  <div class="card-content">
                    <div class="cover">
                      <img :src="getImage({})" alt="...">
                      <div class="play-button js-play-button new-play-button">
                        <div class="svg-icon">
                          <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                               class="play-type-1">
                            <path class="c-fill-1 c-line-1"
                                  d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                                  stroke="white" stroke-width="1.3"></path>
                          </svg>
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="c-line-1 c-fill-1"
                                  d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                                  fill="white"></path>
                            <path class="c-line-1 c-fill-1"
                                  d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                                  stroke="white" stroke-width="1.3"></path>
                          </svg>
                          <svg width="10" height="14" viewBox="0 0 10 14" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                                  stroke-linecap="round"></path>
                            <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                                  stroke-linecap="round"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="description">
                      <div class="card-body">
                        <div class="track-info">
                          <div class="author js-marquee">
                            <div><a href="#" style="left: 0px;">
                              Artist Name
                            </a>
                            </div>
                          </div>
                          <div class="title js-marquee">
                            <div><a href="#" style="left: 0px;">
                              Track Name
                            </a>
                            </div>
                          </div>
                        </div>
                        <div class="track-price">
                          <div class="text">
                            Sold for
                          </div>
                          <div class="price">€2.66</div>
                        </div>
                        <div class="more dropright show single-dropd this-drop-down">
                          <div class="dropdown  more-dropdown " @click="getHeight" data-toggle="dropdown"
                               aria-haspopup="true" aria-expanded="false" data-display="static">
                            <div class="svg-icon more-position">
                              <svg width="3" height="15" viewBox="0 0 3 15" fill="none"
                                   xmlns="http://www.w3.org/2000/svg" class="svg-more">
                                <path
                                    d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                                    class="c-fill-1"></path>
                                <path
                                    d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                                    class="c-fill-1"></path>
                                <path
                                    d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                                    class="c-fill-1"></path>
                              </svg>

                            </div>
                          </div>
                          <ListOption/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";

export default {
  data() {
    return {
      showMMore: false,
      optionWidth: 0,

    }
  },
  components: {
    ListOption
  },
  computed: {},
  methods: {
    getHeight: function (e) {
      if (this.optionWidth == 0)
        this.optionWidth = e.screenX;
    }
  }
}
</script>
