<template>
  <div class="w-100">
    <MusicSubHeader/>
    <div class="container_xxl">
      <div class="row mb_30px mt_10">
        <div class="col">
          <div class=" page-filter d-flex d-flex flex-row align-items-center">
            <div class="back-link">
              <a href="javascript:;" class="d-flex flex-row align-items-center">
                <div class="svg-icon">
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1" d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152" stroke="#071526"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                PROFILE
              </a>
            </div>
            <div class="filter-title ff_lato">
              BOOK ARTIST
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-8">
          <div class="widget_form">
            <div class="widget_form_title">
              Event Details
            </div>
            <div class="row">
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Event Name</label>
                  <input type="email" class="form_control" value="London Warehouse Events">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Event Website</label>
                  <input type="email" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Estimated time of Performance<span
                      class="color_red">*</span></label>
                  <input type="email" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Ticket Price<span class="color_red">*</span></label>
                  <input type="email" class="form_control">
                </div>
              </div>
            </div>
          </div>
          <div class="widget_form">
            <div class="widget_form_title">
              Venue / Club / Location
            </div>
            <div class="row">
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Company Name<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-2">
                <div class="widget_field">
                  <label class="engage_fields_label">Capacity (pax)</label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">Website</label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-12">
                <div class="divider_line mb_17px mt_8px"></div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Address Line 1<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Address Line 2<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">City<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">Postal Code<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>

              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">Country<span class="color_red">*</span></label>
                  <div class="select_box">
                    <select class="form_control">
                      <option value="0">United Kingdom</option>
                      <option value="1">Australia</option>
                      <option value="2">Canada</option>
                      <option value="3">Singapore</option>
                    </select>
                  </div>

                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">State</label>
                  <input type="text" class="form_control">
                </div>
              </div>


            </div>
          </div>
          <div class="widget_form">
            <div class="widget_form_title">
              Invoice Address / Contract Partner
            </div>
            <div class="row">
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">First Name<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Last Name<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Email<span class="color_red">*</span></label>
                  <input type="email" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Cell Phone<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Direct Phone Number</label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Direct Fax Number</label>
                  <input type="text" class="form_control">
                </div>
              </div>

              <div class="col-12">
                <div class="mb_17px"></div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Company Name<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Website</label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Address Line 1<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Address Line 2<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">City<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">Postal Code<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>

              <div class="col-4">
                <div class="widget_field">
                  <label class="engage_fields_label">Country<span class="color_red">*</span></label>
                  <div class="select_box">
                    <select class="form_control">
                      <option value="0">United Kingdom</option>
                      <option value="1">Australia</option>
                      <option value="2">Canada</option>
                      <option value="3">Singapore</option>
                    </select>
                  </div>

                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">State</label>
                  <input type="text" class="form_control">
                </div>
              </div>


            </div>
          </div>
          <div class="widget_form">
            <div class="widget_form_title">
              Extra Information
            </div>
            <div class="row">
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Sponsors<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Other Confirmed Acts<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Other Requested Acts<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Nearest International Airport (Name)<span
                      class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-6">
                <div class="widget_field">
                  <label class="engage_fields_label">Distance (Airport - Hotel)<span class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Refferences (Bookings, Events, Websites)<span
                      class="color_red">*</span></label>
                  <input type="text" class="form_control">
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Extra Comments<span class="color_red">*</span></label>
                  <textarea class="form_control"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="widget_form sticky_top_30">
            <div class="widget_form_title">
              Offer
            </div>
            <div class="row">
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Preffered Dates</label>
                  <div class="calander_box">
                    <input type="text" class="form_control" placeholder="DD/MM/YYYY – DD/MM/YYYY">
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="widget_field">
                  <div class="select_iconed">
                    <div class="select_iconed_selectbox">
                      <!-- <img src="img/svg/globe.svg" alt="image"> -->
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 12H22" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path
                            d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span>Club</span>
                      <div class="drop_icon">
                        <!-- <img src="img/svg/dropdown.svg" alt="image"> -->
                        <svg width="12" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </div>
                    <ul class="select_iconed_options" style="display: none;">
                      <li>
                        <img src="img/svg/globe.svg" alt="image">
                        <span>Club 1</span>
                      </li>
                      <li>
                        <img src="img/svg/globe.svg" alt="image">
                        <span>Club 2</span>
                      </li>
                      <li>
                        <img src="img/svg/globe.svg" alt="image">
                        <span>Club 3</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Financial Offer</label>
                  <div class="prepend">
                    <input type="text" class="form_control">
                    <div class="prepend_box">
                      $
                    </div>
                    <small class="text_muted d-block">Your “landed” offer. Excluding tax.</small>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <div class="checkbox_prepend">
                    <div class="checkbox_list">
                      <input type="checkbox" id="Hotel_Included">
                      <label for="Hotel_Included"><span><img src="/assets/img/check_blue.svg" alt="check"></span>Hotel
                        Included</label>
                    </div>
                    <div class="checkbox_list">
                      <input type="checkbox" id="Transport_Included">
                      <label for="Transport_Included"><span><img src="/assets/img/check_blue.svg" alt="check"></span>Transport
                        Included</label>
                    </div>
                    <div class="checkbox_list">
                      <input type="checkbox" id="Rider_Included">
                      <label for="Rider_Included"><span><img src="/assets/img/check_blue.svg" alt="check"></span>Rider
                        Included</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <label class="engage_fields_label">Comment</label>
                  <textarea class="form_control"></textarea>
                </div>
              </div>
              <div class="col-12">
                <div class="widget_field">
                  <button class="prime_button w-100">Send Request</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
//    import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'

export default {
  data() {
    return {
      deletePage: false

    }
  },

  components: {
    MusicSubHeader
  },
  computed: {},
  methods: {
    closeModal: function () {
      this.deletePage = false;

    }

  }
}
</script>
