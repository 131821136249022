<template>
  <div class="Signup_pop_overlay">
    <div class="wr_my_wallet_modals">
      <div class="wr_title">
        <div>
          <div class="title">Payment Verification</div>
        </div>
        <div
          class="ic_prev_close"
          @click="$parent.openCloseModals({modal:'verificationPayment'})"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="wr_description">
        <p>Thank you!</p>
        <p>
          We’ve received your bank receipt image. Please allow for
          <b>1-2 business days</b> to approve your transaction.
        </p>
        <p>
          We will notify you via <b>AudioGround</b> and e-mail when your
          transaction is successfull.
        </p>
      </div>
      <button class="btn_ok">OK</button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  margin: 0 !important;
}
.wr_description {
  font-family: HelveticaNeueNormal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #071526;
  // opacity: 0.9;
  border-bottom: 1px solid #e6eaf0;
  padding: 14px 0 0px 0;
  margin-bottom: 20px;
}
.btn_ok {
  background: #D1433A;
  border-radius: 4px;
  width: 210px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HelveticaNeueBold;
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  border: none;
  outline: none;
  margin: 0 auto;
  &:hover {
    background: #DA6961 !important;
  }
}
</style> 