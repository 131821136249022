<template>
  <div class="container-fluid sale-items">
    <!-- <div class="row">
       <div class="col-sm-12">
          <h4 class="title-h4">Title</h4>
       </div>
    </div> -->
    <div class="row position-relative">
      <div class="col-sm-2 js-container-hover hover" v-for="(event, index) in eventList" :key="event.id">
        <div class="card slider">
          <div class="card-cover js-card-hover hover">
            <div class="cover square  "><img
                src=""
                alt=""></div>
            <div class="cover-hover">
              <div class="cover-mood js-cover-mood">
                <div class="mood" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  #Electronic
                </div>
                <div class="dropdown-menu dropdown-menu-right dropdown-moods">
                  <div class="moods">
                    #Electronic
                  </div>
                  <div class="moods">
                    #Ambient
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-description events">
            <div class="pur-tag">
              <div class="svg-icon">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.56176 8.064C8.51188 8.064 10.0928 6.48267 10.0928 4.532C10.0928 2.58133 8.51188 1 6.56176 1C4.61164 1 3.03076 2.58133 3.03076 4.532C3.03076 6.48267 4.61164 8.064 6.56176 8.064Z"
                      stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path
                      d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                      stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              000
            </div>
            <div class="item-date">JULY 12</div>
            <div class="item-title">Event Name</div>
            <div class="item-subtitle">Venue Name</div>
            <div :class="['custom-select-wrapper in-card', (event.openMenu) ? 'open' : '']">
              <!-- <div class="interested-select interested"> -->
              <!-- <div class="interested-select goind"> -->
              <div
                  :class="['interested-select', (event.selectedOption == 'Going')? 'goind':'', (event.selectedOption == 'Interested')? 'interested':'', (event.selectedOption == 'Not Interested')? 'not-interested':'']"
                  v-on:click="openOption(index)">
                <div class="svg-icon star">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1"
                          d="M6 1L7.545 4.13L11 4.635L8.5 7.07L9.09 10.51L6 8.885L2.91 10.51L3.5 7.07L1 4.635L4.455 4.13L6 1Z"
                          fill="#D1433A"></path>
                  </svg>
                </div>
                <div class="svg-icon check">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
                        fill="#15CA86"></path>
                    <path d="M8.66634 4L4.99967 7.66667L3.33301 6" stroke="white" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                  </svg>
                </div>
                <span>{{ event.selectedOption }}</span>
                <div class="svg-icon arrow">
                  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1" opacity="0.7" d="M10.7695 1L6.09052 5.75638L0.999512 1"
                          stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <div class="inner-interested-select ">
                <div class="custom-control custom-radio" v-on:click="updateOption(index, 'Interested')"><input
                    type="radio" checked="checked" name="interesting" id="Interested2" value="Interested"
                    class="custom-control-input"> <label class="custom-control-label"
                                                         for="Interested2">Interested</label></div>
                <div class="custom-control custom-radio" v-on:click="updateOption(index, 'Going')"><input type="radio"
                                                                                                          name="interesting"
                                                                                                          id="Going2"
                                                                                                          value="Going"
                                                                                                          class="custom-control-input">
                  <label class="custom-control-label" for="Going2">Going</label></div>
                <div class="custom-control custom-radio" v-on:click="updateOption(index, 'Not Interested')"><input
                    type="radio" name="interesting" id="NotInterested2" value="Not Interested"
                    class="custom-control-input"> <label class="custom-control-label" for="NotInterested2">Not
                  Interested</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  data() {
    return {
      eventList: [{
        id: 1,
        name: 'qwe',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 2,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 3,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 4,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 5,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }, {
        id: 6,
        name: 'asd',
        selectedOption: 'Interested',
        openMenu: false
      }]
    }
  },
  components: {},
  computed: {},
  methods: {
    openOption: function (index) {
      this.eventList[index].openMenu = true;
    },
    updateOption: function (index, value) {
      this.eventList[index].selectedOption = value;
      this.eventList[index].openMenu = false;
    }
  }
}
</script>
