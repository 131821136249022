<template>
  <div class="wr_EM_ED_Ticket" :style="{ borderLeft: ticketBorderColor }">
    <ul>
      <li class="wr_ticket_info">
        <h4>{{ ticket.tiketType }}</h4>
        <span
          >{{ ticket.status }} <span class="dot"></span> {{ ticket.date }}</span
        >
      </li>
      <li class="count" :style="{ background: ticketCountBgColor }">
        {{ ticket.quantity }} / 10
      </li>
      <li class="price">${{ ticket.price }}</li>
      <li v-if="tab === 'DASHBOARD'" class="priceCount">
        ${{ ticket.quantity * ticket.price }}
      </li>
      <li v-if="tab === 'TICKET'">
        <div class="wr_menu for_hover_empty_border_blue btn_empty_border_blue">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
              fill="#D1433A"
              stroke="#D1433A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
              fill="#D1433A"
              stroke="#D1433A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
              fill="#D1433A"
              stroke="#D1433A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <ul>
            <li>
              <span
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3335 2.00053C11.5086 1.82543 11.7165 1.68654 11.9452 1.59178C12.174 1.49702 12.4192 1.44824 12.6668 1.44824C12.9145 1.44824 13.1597 1.49702 13.3884 1.59178C13.6172 1.68654 13.8251 1.82543 14.0002 2.00053C14.1753 2.17562 14.3142 2.38349 14.4089 2.61227C14.5037 2.84104 14.5524 3.08624 14.5524 3.33386C14.5524 3.58148 14.5037 3.82668 14.4089 4.05545C14.3142 4.28423 14.1753 4.4921 14.0002 4.66719L5.00016 13.6672L1.3335 14.6672L2.3335 11.0005L11.3335 2.00053Z"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  /></svg
              ></span>
              Edit
            </li>
            <li>
              <span
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_572_97894)">
                    <path
                      d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.3335 9.99967H2.66683C2.31321 9.99967 1.97407 9.8592 1.72402 9.60915C1.47397 9.3591 1.3335 9.01996 1.3335 8.66634V2.66634C1.3335 2.31272 1.47397 1.97358 1.72402 1.72353C1.97407 1.47348 2.31321 1.33301 2.66683 1.33301H8.66683C9.02045 1.33301 9.35959 1.47348 9.60964 1.72353C9.85969 1.97358 10.0002 2.31272 10.0002 2.66634V3.33301"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </g></svg
              ></span>
              Copy
            </li>
            <li @click="openCloseRemoveModal('open')" class="delete">
              <span
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /></svg
              ></span>
              Delete
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <RemoveModal
      v-if="showRemoveModals === 'open'"
      :title="'Delete Ticket'"
      :description="'this Ticket'"
      :id="5"
      @openCloseRemoveModal="openCloseRemoveModal"
      @delete_this="delete_This_Event"
    />
  </div>
</template>

<script>
import RemoveModal from "@/components/Common/Elements/RemoveModal.vue";

export default {
  props: ["ticket", "tab"],
  components: { RemoveModal },
  data() {
    return {
      showRemoveModals: "close",
    };
  },
  methods: {
    openCloseRemoveModal(payload) {
      this.showRemoveModals = payload;
    },
    delete_This_Event() {
      console.log(15156156156);
    },
  },
  computed: {
    ticketBorderColor() {
      let color;
      if (this.ticket.status === "On Sale") {
        color = "5px solid #2ED297";
      } else if (this.ticket.status === "Sold Out") {
        color = "5px solid #FF0039";
      } else if (this.ticket.status === "Waiting") {
        color = "5px solid #FDB390";
      }
      return color;
    },
    ticketCountBgColor() {
      let color;
      if (this.ticket.status === "On Sale") {
        color = "#15ca8640";
      } else if (this.ticket.status === "Sold Out") {
        color = "#ee3f3f40";
      } else if (this.ticket.status === "Waiting") {
        color = "#FDB390";
      }
      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_EM_ED_Ticket {
  margin-top: 10px;
  padding: 0 20px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  height: 70px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wr_ticket_info {
      width: 300px;
      h4 {
        font-family: HelveticaNeueMedium;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #071526;
      }
      span {
        font-family: HelveticaNeueNormal;
        letter-spacing: 0.7px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #47505b;
        opacity: 0.7;
        display: flex;
        align-items: center;
        .dot {
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: #c4ccd6;
        }
      }
    }
    .count {
      width: 60px;
    }
    .price {
      width: 50px;
      background: #f3f3f3;
      border: 1px solid #e6eaf0;
    }
    .priceCount {
      width: 80px;
      background: #D1433A1A;
    }
    .count,
    .price,
    .priceCount {
      font-family: HelveticaNeueBold;
      border-radius: 2px;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #071526;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .wr_menu {
      cursor: pointer;
      width: 30px;
      position: relative;
      &:hover {
        ul {
          display: flex;
        }
      }
      ul {
        display: none;
        width: 140px;
        height: fit-content;
        box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
        border-radius: 4px;
        position: absolute;
        flex-direction: column;
        padding: 7px 0;
        margin: 0;
        list-style-type: none;
        background: #ffffff;
        z-index: 1;
        top: 29px;
        right: 0;
        li {
          width: 100%;
          font-family: HelveticaNeueMedium;
          padding: 7px 20px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #000000;
          cursor: pointer;
          &:hover {
            background: #D1433A1A !important;
          }
          span {
            display: block;
            margin-right: 16px;
          }
        }
        .delete {
          border-top: 1px solid #e6eaf0;
          padding: 10px 20px;
        }
      }
    }
  }
}
</style>