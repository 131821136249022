<template>
  <div class="Signup_pop_overlay">
    <div class="wr_my_wallet_modals">
      <div class="wr_title">
        <div>
          <div class="title">PayPal Payment ($70)</div>
        </div>
        <div
          class="ic_prev_close"
          @click="$parent.openCloseModals({ modal: 'payPalStripeAliPay' })"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="wr_payPalStripeAliPay_content">
        <p>You’re redirecting to {{ paymentType }} web-site... 5s</p>
        <Spiner />
      </div>
    </div>
  </div>
</template>

<script>
import Spiner from "./Spiner.vue";
export default {
  props: {
    paymentType: String,
  },
  components: {
    Spiner,
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 0 !important;
}
.wr_payPalStripeAliPay_content {
  padding: 20px 0 0 0;
  p {
    margin: 0px;
    font-family: HelveticaNeueBold;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #071526;
    opacity: 0.9;
  }
}
</style> 