<template>
  <div class="row position-relative">
    <div v-for="(song, index) in listData.slice(0, 6)" :key="index" class="col-sm-2  py-2" >
      <SongOrAlbumCardWithSquareImage :item="song"  :buy="!isStreaming"/>
    </div>
  </div>
</template>
<script type="application/javascript">
import SongOrAlbumCardWithSquareImage from "@/components/DesignGuide/Music/Components/Grid.vue";


export default {
  data() {
    return {
      showMMore: false,
      // optionWidth:0,
      optionHeight: 0,
      showOption: false,
      lastWidth: 0,
      optionWidth: 0,
      isHovering: false,


    }
  },
  components: {
    SongOrAlbumCardWithSquareImage
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    isStreaming: {
      type: Boolean,
      default: false
    },
  },
  computed: {},
  afterMount: {},
  methods: {
    addClassToParent: function (event) {
      event.target.parentElement.addClassToParent('mmmm');
    },
    isHoveringFun: function (val, index) {
      this.listData[index].isHovering = val;
    },
    hide: function () {
      this.showOption = false;
    },
    getHeight: function (e) {
      e.stopPropagation();
      //  if(this.optionWidth != 0){
      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }

      let height = 108;
      this.optionWidth = parseInt(e.screenX) - this.$refs.busstop.getBoundingClientRect().left;
      this.optionHeight = parseInt(height);
    },
    getArtistName(artists) {
      let name = "";
      for (const artist of artists) {
        name = name + artist.name + ", "
      }
      return name
    }

  }

}
</script>
