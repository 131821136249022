<template>
  <div class="evetnsContainer">
    <div class="title">
      <h2 class="mb-0">{{ eventsType }}</h2>
      <a @click="goTo">SEE MORE</a>
    </div>
    <div class="events-container music_slider_landing carousel-icon">
      <carousel
        v-if="events"
        :items="4"
        :autoplay="false"
        :nav="false"
        :margin="30"
      >
        <EventsCard
          v-for="(event, index) in events.data"
          :key="index"
          :event="event"
        />
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import { mapActions, mapGetters } from "vuex";
import { login } from "../../../apis/APIs";
import EventsCard from "./EventsCard.vue";
export default {
  components: {
    carousel,
    EventsCard,
  },
  props: {
    eventsType: {
      type: String,
      default: () => "",
    },
    events: {
      type: Object,
      default: () => {},
    },
    eventsPath: String,
  },
  methods: {
    ...mapActions(["getEventsByType"]),
    async goTo() {
      let type =
        this.eventsPath === "this-week"
          ? "weekly"
          : this.eventsPath.split("-")[0].toLowerCase();
      await this.$store.dispatch("loading", true);
      await this.getEventsByType({
        type: type,
        offset: 0,
        limit: 12,
        sort_by: "trending",
        event_type: this.$route.meta.event_Type_id,
      });
      await this.$store.dispatch("loading", false);
      this.$router.push({
        name: "events.findEvents",
        params: { eventsName: this.eventsPath, eventsType: this.eventsType },
        query: {
          events: this.events,
          event_type_id: this.$route.meta.event_Type_id,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["GET_ALL_EVENTS_BY_TYPE"]),
  },
};
</script>

<style lang="scss" scoped>
.evetnsContainer {
  margin-top: 32px;
}
.title {
  font-family: HelveticaNeueBold;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h2 {
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
  a {
    cursor: pointer;
    color: #071526 !important;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    margin-right: 66px;
  }
}
</style>
