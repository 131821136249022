<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div
          class="forum-user-items m-t-20"
          v-for="replie in REPLISE.data"
          :key="replie.id"
        >
          <div>
            <div class="item-head for-title">
              <div>
                <div class="item-inner-title">
                  <a>{{ replie.commentable.title }}</a>
                </div>
                <div class="threads">
                  <span
                    ><svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.6667 0.75H1.33341C0.685915 0.75 0.166748 1.26917 0.166748 1.91667V10.0833C0.166748 10.7308 0.685915 11.25 1.33341 11.25H10.6667C11.3142 11.25 11.8334 10.7308 11.8334 10.0833V1.91667C11.8334 1.26917 11.3142 0.75 10.6667 0.75ZM1.91675 3.08333H4.83342V6.58333H1.91675V3.08333ZM10.0834 8.91667H1.91675V7.75H10.0834V8.91667ZM10.0834 6.58333H6.00008V5.41667H10.0834V6.58333ZM10.0834 4.25H6.00008V3.08333H10.0834V4.25Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ replie.commentable.forum.name }}</span
                  >

                  <span
                    ><svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.00008 0.166748C2.78358 0.166748 0.166748 2.78358 0.166748 6.00008C0.166748 9.21658 2.78358 11.8334 6.00008 11.8334C9.21658 11.8334 11.8334 9.21658 11.8334 6.00008C11.8334 2.78358 9.21658 0.166748 6.00008 0.166748ZM7.921 8.74583L5.41675 6.24158V2.50008H6.58342V5.75858L8.74583 7.921L7.921 8.74583Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ dateFormat(replie.commentable.updated_at) }}</span
                  >
                  <span
                    ><svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 10.5183L2.84926e-07 4C3.81491e-07 1.79086 1.79087 -4.46209e-06 4.00001 0L8.00001 8.07935e-06C10.2091 1.25414e-05 12 1.79087 12 4.00001V6.15384C12 8.36298 10.2091 10.1538 8 10.1538H3.23077L1.58124 11.3321C0.919369 11.8048 -3.55535e-08 11.3317 0 10.5183ZM2.23082 3.69224C2.23082 3.13995 2.67853 2.69224 3.23082 2.69224L8.76928 2.69223C9.32156 2.69223 9.76928 3.13995 9.76928 3.69223C9.76928 4.24452 9.32156 4.69223 8.76928 4.69223H3.23082C2.67853 4.69224 2.23082 4.24452 2.23082 3.69224ZM3.23082 5.46163C2.67853 5.46163 2.23082 5.90934 2.23082 6.46163C2.23082 7.01391 2.67853 7.46163 3.23082 7.46163H6.92313C7.47541 7.46163 7.92313 7.01391 7.92313 6.46163C7.92313 5.90934 7.47541 5.46163 6.92313 5.46163H3.23082Z"
                        fill="#8B949F"
                      />
                    </svg>

                    {{ replie.commentable.replies_count }} replies</span
                  >
                  <span
                    ><svg
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.1875 5C9.1875 5.58016 8.95703 6.13656 8.5468 6.5468C8.13656 6.95703 7.58016 7.1875 7 7.1875C6.41984 7.1875 5.86344 6.95703 5.4532 6.5468C5.04297 6.13656 4.8125 5.58016 4.8125 5C4.8125 4.41984 5.04297 3.86344 5.4532 3.4532C5.86344 3.04297 6.41984 2.8125 7 2.8125C7.58016 2.8125 8.13656 3.04297 8.5468 3.4532C8.95703 3.86344 9.1875 4.41984 9.1875 5Z"
                        fill="#8B949F"
                      />
                      <path
                        d="M0 5C0 5 2.625 0.1875 7 0.1875C11.375 0.1875 14 5 14 5C14 5 11.375 9.8125 7 9.8125C2.625 9.8125 0 5 0 5ZM7 8.0625C7.81223 8.0625 8.59118 7.73984 9.16551 7.16551C9.73984 6.59118 10.0625 5.81223 10.0625 5C10.0625 4.18777 9.73984 3.40882 9.16551 2.83449C8.59118 2.26016 7.81223 1.9375 7 1.9375C6.18777 1.9375 5.40882 2.26016 4.83449 2.83449C4.26016 3.40882 3.9375 4.18777 3.9375 5C3.9375 5.81223 4.26016 6.59118 4.83449 7.16551C5.40882 7.73984 6.18777 8.0625 7 8.0625Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ replie.commentable.views }} views</span
                  >
                </div>
              </div>
              <div class="item-inner-subtitle with-name">
                <div>
                  by <a>{{ replie.commentable.author_details.full_name }}</a>
                </div>
              </div>
            </div>
            <div class="forum-user-item with-title">
              <div class="left-item">
                <div class="img-wrapper cursor-pointer">
                  <img
                    @click="redirectWidgetsToProfilePage($event, user_data.username)"
                    :src="replie.user_data.artwork_url" alt="" />
                </div>
                <div
                  @click="redirectWidgetsToProfilePage($event, user_data.username)"
                  class="item-title cursor-pointer">{{ replie.user_data.full_name }}</div>
                <div class="item-subtitle">Member</div>
              </div>
              <div class="right-item">
                <div class="item-title">
                  <div class="item-date">
                    <div class="svg-icon">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 0C2.6916 0 0 2.6916 0 6C0 9.3084 2.6916 12 6 12C9.3084 12 12 9.3084 12 6C12 2.6916 9.3084 0 6 0ZM6 10.8C3.3534 10.8 1.2 8.6466 1.2 6C1.2 3.3534 3.3534 1.2 6 1.2C8.6466 1.2 10.8 3.3534 10.8 6C10.8 8.6466 8.6466 10.8 6 10.8Z"
                          fill="#8B949F"
                        />
                        <path
                          d="M6.5999 3H5.3999V6.2484L7.3757 8.2242L8.2241 7.3758L6.5999 5.7516V3Z"
                          fill="#8B949F"
                        />
                      </svg>
                    </div>
                    {{ dateFormat(replie.updated_at) }}
                  </div>
                  <div
                    v-if="type !== 'search_replies'"
                    class="d-flex row-flex wr_edit_delete"
                  >
                    <a
                      class="text-decoration-none"
                      @click="
                        goTo({
                          active_tab: 'new_thread',
                          type: 'edit_reply',
                          id: replie.id,
                          content: replie.content,
                        })
                      "
                    >
                      <div class="item-reports edit">
                        <div class="svg-icon">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              class="c-line-1"
                              d="M8.5 1.50015C8.63132 1.36883 8.78722 1.26466 8.9588 1.19359C9.13038 1.12252 9.31428 1.08594 9.5 1.08594C9.68572 1.08594 9.86962 1.12252 10.0412 1.19359C10.2128 1.26466 10.3687 1.36883 10.5 1.50015C10.6313 1.63147 10.7355 1.78737 10.8066 1.95895C10.8776 2.13054 10.9142 2.31443 10.9142 2.50015C10.9142 2.68587 10.8776 2.86977 10.8066 3.04135C10.7355 3.21293 10.6313 3.36883 10.5 3.50015L3.75 10.2502L1 11.0002L1.75 8.25015L8.5 1.50015Z"
                              stroke="#D1433A"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        Edit
                      </div>
                    </a>
                    <div
                      @click="
                        deleteReply({
                          showType: 'showDelete',
                          id: replie.id,
                          type: 'reply',
                        })
                      "
                      class="item-remove delete"
                    >
                      <div class="svg-icon">
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M9.66992 3.60352L3.66992 9.60352"
                            stroke="#FF0039"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            class="c-line-1"
                            d="M3.66992 3.60352L9.66992 9.60352"
                            stroke="#FF0039"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      Remove
                    </div>
                  </div>
                </div>
                <div class="item-text">
                  {{ replie.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :count="REPLISE.total"
          :actionName="
            type == 'search_replies' ? 'getSearchForumResult' : 'getMyReplies'
          "
        />
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapGetters, mapActions, mapMutations } from "vuex";
import Pagination from "@/components/Common/Elements/Pagination.vue";
import forum from "@/mixins/forum.js";
export default {
  mixins: [forum],
  data() {
    return {};
  },
  components: {
    Pagination,
  },
  props: {
    type: String,
    REPLISE: Object,
  },
  methods: {
    ...mapMutations([
      "changeTypeForumThreads",
      "changeReplyId",
      "changeEditorData",
      "changeDeleteType",
    ]),
    ...mapActions(["getMyReplies", "getSearchForumResult"]),
    goTo: function (params) {
      this.changeTypeForumThreads(params.type);
      this.changeReplyId(params.id);
      this.$parent.changePage(params.active_tab);
      this.changeEditorData(params.content);
    },
    deleteReply(params) {
      this.changeDeleteType(params.type);
      this.changeReplyId(params.id);
      this.$parent.showModals(params.showType);
    },
  },
  computed: {
    ...mapGetters(["GET_SEARCH_KEYWORD"]),
  },
};
</script>
<style lang="scss" scoped>
.item-subtitle,
.item-text,
.wr_edit_delete,
.item-date {
  font-family: HelveticaNeueNormal;
}
.item-title {
  font-family: HelveticaNeueBold;
  color: #071526;
  opacity: 0.9;
}
.item-head {
  justify-content: space-between;
  align-items: end;
  padding-right: 20px;
}
.item-inner-subtitle {
  font-family: HelveticaNeueNormal;
  text-align: end;
  font-size: 12px;
  color: #8b949f;
  line-height: 14px !important;
  div {
    font-family: HelveticaNeueBold;
    font-size: 11px;
    a {
      color: #47505b !important;
    }
  }
}
.edit,
.delete {
  padding: 5px 7px 5px 8px;
  border-radius: 2px;
  transition: all 0.3s;
  margin-left: 10px;
  &:hover {
    background: #D1433A1A;
    transition: all 0.3s;
  }
}
.threads {
  font-family: HelveticaNeueNormal;
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  span {
    margin-right: 20px;
    display: flex !important;
    align-items: center;
    svg {
      margin-right: 7px;
    }
  }
}
</style>
