<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body m-auto ">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/img/g-settings.png`)">
                Page Setting
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="artist-general-setting">
                <div class="engage_settings_header">
                  <div>
                    <h3>Messaging</h3>
                    <h6>Do you want to receive messages? (switcher on) or no (switcher off)</h6>
                  </div>
                  <div class="switch_sm_wrapper">
                    <input type="checkbox" id="switch2" v-model="messaging">
                    <label class="switch_sm" for="switch2">
                      <div class="switch_toggler"></div>
                    </label>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Visitor Posts</h3>
                    <h6>Which users are allowed to post on your timeline? </h6>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <div :class="['custom-dropdown', (activeDropDown === 'visitor-post')? 'open':'']"
                         v-on:click="openDropDown('visitor-post')">
                      <div class="custom-dropdown-head d-flex flex-row align-items-center ht40drpdown" data-value="1">
                        <div class="svg-icon">
                          <svg style="width:16px !important; height:16px !important;" v-if="visitorPost === 'public'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                              fill="#47505B"/>
                            <path
                              d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                              fill="#47505B"/>
                            <path
                              d="M7.9999 14.4C8.3839 14.4 9.4479 12.48 9.5999 8.79999H6.3999C6.5519 12.48 7.6159 14.4 7.9999 14.4Z"
                              fill="#47505B"/>
                            <path
                              d="M7.9999 1.59998C7.6159 1.59998 6.5519 3.51998 6.3999 7.19998H9.5999C9.4479 3.51998 8.3839 1.59998 7.9999 1.59998Z"
                              fill="#47505B"/>
                            <path
                              d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                              fill="#47505B"/>
                            <path
                              d="M8 -2.15078e-05C10.048 -2.15078e-05 11.056 3.59198 11.2 7.19998H16C15.801 5.21979 14.8711 3.38487 13.3918 2.05352C11.9125 0.722178 9.99013 -0.0099948 8 -2.15078e-05Z"
                              fill="#47505B"/>
                          </svg>
                          <svg  style="width:16px !important; height:16px !important;" v-if="visitorPost === 'friends'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                              fill="#47505B"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.216 12C5.06776 11.6878 4.99382 11.3455 5 11C5 9.645 5.68 8.25 6.936 7.28C6.30909 7.08684 5.65595 6.99237 5 7C1 7 0 10 0 11C0 12 1 12 1 12H5.216Z"
                                  fill="#47505B"/>
                            <path
                              d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                              fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="visitorPost === 'only_me'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.6667 7.33331H3.33333C2.59695 7.33331 2 7.93027 2 8.66665V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V8.66665C14 7.93027 13.403 7.33331 12.6667 7.33331Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                            <path
                              d="M4.6665 7.33331V4.66665C4.6665 3.78259 5.01769 2.93475 5.64281 2.30962C6.26794 1.6845 7.11578 1.33331 7.99984 1.33331C8.88389 1.33331 9.73174 1.6845 10.3569 2.30962C10.982 2.93475 11.3332 3.78259 11.3332 4.66665V7.33331"
                              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                        <span v-if="visitorPost === 'public'">Public</span>
                        <span v-if="visitorPost === 'friends'">Friends</span>
                        <span v-if="visitorPost === 'only_me'">Only me</span>
                        <div class="for-drop d-flex ml-auto">
                          <div class="svg-icon">
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="custom-dropdown-menu flex-column" style="width: inherit; z-index: 1;">
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(visitorPost === 'public')?'active':'']"
                          data-value="0" v-on:click="updateDropDown('public', 'visitor-post')">
                          <div class="svg-icon">
                            <svg  width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg"
                                    d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M7.9999 14.4C8.3839 14.4 9.4479 12.48 9.5999 8.79999H6.3999C6.5519 12.48 7.6159 14.4 7.9999 14.4Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M7.9999 1.59998C7.6159 1.59998 6.5519 3.51998 6.3999 7.19998H9.5999C9.4479 3.51998 8.3839 1.59998 7.9999 1.59998Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M8 -2.15078e-05C10.048 -2.15078e-05 11.056 3.59198 11.2 7.19998H16C15.801 5.21979 14.8711 3.38487 13.3918 2.05352C11.9125 0.722178 9.99013 -0.0099948 8 -2.15078e-05Z"
                                    fill="#47505B"/>
                            </svg>
                          </div>
                          Public
                          <div class="for-check ml-auto" v-if="visitorPost === 'public'">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(visitorPost === 'friends')?'active':'']"
                          data-value="1" v-on:click="updateDropDown('friends', 'visitor-post')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg"
                                    d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                                    fill="#47505B"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" class="post-drop-svg"
                                    d="M5.216 12C5.06776 11.6878 4.99382 11.3455 5 11C5 9.645 5.68 8.25 6.936 7.28C6.30909 7.08684 5.65595 6.99237 5 7C1 7 0 10 0 11C0 12 1 12 1 12H5.216Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                                    fill="#47505B"/>
                            </svg>


                          </div>
                          Friends
                          <div class="for-check  ml-auto" v-if="visitorPost === 'friends'">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(visitorPost === 'only_me')?'active':'']"
                          data-value="2" v-on:click="updateDropDown('only_me', 'visitor-post')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg post-drop-svg-1"
                                    d="M12.6667 7.33331H3.33333C2.59695 7.33331 2 7.93027 2 8.66665V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V8.66665C14 7.93027 13.403 7.33331 12.6667 7.33331Z"
                                    fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                              <path class="post-drop-svg post-drop-svg-1"
                                    d="M4.6665 7.33331V4.66665C4.6665 3.78259 5.01769 2.93475 5.64281 2.30962C6.26794 1.6845 7.11578 1.33331 7.99984 1.33331C8.88389 1.33331 9.73174 1.6845 10.3569 2.30962C10.982 2.93475 11.3332 3.78259 11.3332 4.66665V7.33331"
                                    stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                          </div>
                          Only Me
                          <div class="for-check ml-auto" v-if="visitorPost === 'only_me'">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Age Restriction</h3>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <div :class="['custom-dropdown', (activeDropDown === 'age-restriction')? 'open':'']" v-on:click="openDropDown('age-restriction')">
                      <div class="custom-dropdown-head d-flex flex-row align-items-center" data-value="1">
                        <div class="svg-icon">
                          <svg style="width:16px !important; height:16px !important;" v-if="ageRestriction === 'over_18_only'" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                              fill="#47505B"/>
                            <path
                              d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                              fill="#47505B"/>
                            <path
                              d="M7.9999 14.4C8.3839 14.4 9.4479 12.48 9.5999 8.79999H6.3999C6.5519 12.48 7.6159 14.4 7.9999 14.4Z"
                              fill="#47505B"/>
                            <path
                              d="M7.9999 1.59998C7.6159 1.59998 6.5519 3.51998 6.3999 7.19998H9.5999C9.4479 3.51998 8.3839 1.59998 7.9999 1.59998Z"
                              fill="#47505B"/>
                            <path
                              d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                              fill="#47505B"/>
                            <path
                              d="M8 -2.15078e-05C10.048 -2.15078e-05 11.056 3.59198 11.2 7.19998H16C15.801 5.21979 14.8711 3.38487 13.3918 2.05352C11.9125 0.722178 9.99013 -0.0099948 8 -2.15078e-05Z"
                              fill="#47505B"/>
                          </svg>
                          <svg style="width:16px !important; height:16px !important;" v-if="ageRestriction === 'everyone'" width="16" height="12" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                              fill="#47505B"/>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M5.216 12C5.06776 11.6878 4.99382 11.3455 5 11C5 9.645 5.68 8.25 6.936 7.28C6.30909 7.08684 5.65595 6.99237 5 7C1 7 0 10 0 11C0 12 1 12 1 12H5.216Z"
                                  fill="#47505B"/>
                            <path
                              d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                              fill="#47505B"/>
                          </svg>
                        </div>
                        {{ ageRestriction === 'over_18_only' ? 'Over 18 only':'Everyone' }}
                        <div class="for-drop d-flex ml-auto">
                          <div class="svg-icon">
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="custom-dropdown-menu flex-column show" style="width: inherit; z-index: 1;">
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(ageRestriction === 'everyone')?'active':'']"
                          data-value="1" v-on:click="updateDropDown('everyone', 'age-restriction')">
                          <div class="svg-icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg"
                                    d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                                    fill="#47505B"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" class="post-drop-svg"
                                    d="M5.216 12C5.06776 11.6878 4.99382 11.3455 5 11C5 9.645 5.68 8.25 6.936 7.28C6.30909 7.08684 5.65595 6.99237 5 7C1 7 0 10 0 11C0 12 1 12 1 12H5.216Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                                    fill="#47505B"/>
                            </svg>


                          </div>
                          Everyone
                          <div class="for-check ml-auto" v-if="ageRestriction === 'everyone'">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          :class="['custom-dropdown-item d-flex flex-row align-items-center',(ageRestriction === 'over_18_only')?'active':'']"
                          data-value="2" v-on:click="updateDropDown('over_18_only', 'age-restriction')">
                          <div class="svg-icon">
                            <svg  width="16" height="16" viewBox="0 0 16 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                              <path class="post-drop-svg"
                                    d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M7.9999 14.4C8.3839 14.4 9.4479 12.48 9.5999 8.79999H6.3999C6.5519 12.48 7.6159 14.4 7.9999 14.4Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M7.9999 1.59998C7.6159 1.59998 6.5519 3.51998 6.3999 7.19998H9.5999C9.4479 3.51998 8.3839 1.59998 7.9999 1.59998Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                                    fill="#47505B"/>
                              <path class="post-drop-svg"
                                    d="M8 -2.15078e-05C10.048 -2.15078e-05 11.056 3.59198 11.2 7.19998H16C15.801 5.21979 14.8711 3.38487 13.3918 2.05352C11.9125 0.722178 9.99013 -0.0099948 8 -2.15078e-05Z"
                                    fill="#47505B"/>
                            </svg>
                          </div>
                          Over 18 Only
                          <div class="for-check ml-auto" v-if="ageRestriction === 'over_18_only'">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Delete Page</h3>
                    <h6>You can permanently delete your page from Playground</h6>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <button v-on:click="deletePage = !deletePage" @closeModal="closeModal">Delete Page</button>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end ">
                <button class="prime_button" type="button" @click="pageBasicSettings">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePageModal :page_id="page_id" v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import DeletePageModal from './DeletePageModal'

export default {
  data() {
    return {
      messaging: false,
      deletePage: false,
      activeDropDown: '',
      last_dropdown: '',
      visitorPost: 'friends',
      ageRestriction: 'over_18_only',
      page_id: null,
      message_key : null,
    }
  },
  components: {
    // MusicSubHeader,
    DeletePageModal
  },
  computed: {
    currentPage(){
      return this.$store.getters.getCurrentPage
    }
  },
  watch:{
    currentPage(val){
      if(val){
          this.initData()
      }
    }
  },
  async mounted(){
     await  this.initData()
  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    openDropDown: function (active_dropdown) {
      if (this.last_dropdown !== active_dropdown) {
        this.last_dropdown = active_dropdown;

        this.activeDropDown = active_dropdown;

      } else {
        this.activeDropDown = '';
        this.last_dropdown = '';
      }
    },
    updateDropDown: function (selected, changedFor) {
      if (changedFor === 'visitor-post') {
        this.visitorPost = selected;
      }
      if (changedFor === 'age-restriction') {
        this.ageRestriction = selected;
      }
    },
    async initData(){
      let pageData =  this.currentPage;
      if(pageData) {
        if(pageData.messaging === 1){
          this.messaging = true
        }else{
          this.messaging = false
        }
        this.visitorPost = pageData.visitor_posts,
        this.ageRestriction = pageData.age_restrictions;
        this.page_id = pageData.id;
      }
    },
    pageBasicSettings(){
      if(this.messaging === true){
        this.message_key = 1
      }else{
        this.message_key = 0
      }
      let payload = {
        messaging : this.message_key,
        visitor_posts : this.visitorPost,
        age_restrictions : this.ageRestriction,
        page_id : this.page_id
      }
      this.$store.dispatch('pageBasicSettings', payload)
    }
  }
}
</script>
<style>
.artist-drop .custom-dropdown.open {
  position: relative;
}
.artist-drop .custom-dropdown.open .custom-dropdown-menu {
  display: flex;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item{
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #333333 !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item.active{
  background-color: rgba(65, 108, 255, .1) !important;
  color: #D1433A !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item .for-drop .ml-auto{
  display: none;
}
.artist-drop .custom-dropdown .custom-dropdown-menu {
  top : 29px !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item .svg-icon {
  margin-right: 8px !important;
  margin-left: 1px !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item.active .post-drop-svg {
  fill: #D1433A !important;
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item.active .svg-icon .c-line-1 {
  stroke: #D1433A
}
.artist-drop .custom-dropdown .custom-dropdown-menu .custom-dropdown-item:hover {
  background-color: rgba(65, 108, 255, .05)
}
.artist-drop .custom-dropdown .custom-dropdown-head .svg-icon {
  margin-right: 8px !important;
  margin-left: 8px !important;
}
</style>
<style scoped lang="scss">
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
}
</style>
