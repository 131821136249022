import {
  CURRENT_PAGE, ERROR,
  GET_STREAM_ACCESS_TOKEN, POST_LIMIT, POST_OFFSET, SUCCESS,
  TWILIO_ACCESS_TOKEN,
  TWILIO_GRANT_ACCESS_ROOM_TOKEN, TWILIO_GRANT_ACCESS_TOKEN,
  TWILIO_MEDIA_PROCESSOR_ID,
  TWILIO_PLAYBACK_GRANT_ID, TWILIO_PLAYER,
  TWILIO_PLAYER_STREAM_ID,
  TWILIO_ROOM, TWILIO_ROOM_CONNECT
} from "./types";
import {
  createTwilioAudioRoom,
  createTwilioMediaProcessor,
  createTwilioPlayerStream,
  endTwilioMediaProcessor,
  endTwilioPlayerStream,
  getGetStreamAccessToken,
  getPage,
  getTwilioAccessToken,
  getTwilioAudioRoom,
  getTwilioRoom,
  twilioGrantAccessRoom,
  twilioGrantAccessToken,
  twilioPlaybackGrant
} from "../apis/APIs";
import createFormData from "../mixins/common";

const INITIAL_STATE = {
  [TWILIO_ACCESS_TOKEN]: "",
  [TWILIO_ROOM]: {},
  [TWILIO_GRANT_ACCESS_ROOM_TOKEN] : "",
  [TWILIO_PLAYER_STREAM_ID]: "",
  [TWILIO_MEDIA_PROCESSOR_ID]: "",
  [TWILIO_PLAYBACK_GRANT_ID]: "",
  [TWILIO_GRANT_ACCESS_TOKEN]: "",
  [TWILIO_PLAYER]: null,
  [TWILIO_ROOM_CONNECT]: null
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async getTwilioAccessToken({ state, commit, getters }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await getTwilioAccessToken();
        const { information, message, success } = data || ""
        commit('SET_TWILIO_ACCESS_TOKEN', information)
        resolve({
          status: success,
          message: message,
          data: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async createTwilioAudioRoom({ state, commit, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = createFormData(payload)
        const { data } = await createTwilioAudioRoom(formData);
        const { information, message, success } = data || ""
        commit('SET_TWILIO_ROOM', information)
        resolve({
          status: success,
          message: message,
          data: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getTwilioAudioRoom({ state, commit, getters }, sid) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await getTwilioRoom(sid);
        const { information, message, success } = data || ""
        commit('SET_TWILIO_ROOM', information)
        resolve({
          status: success,
          message: message,
          room: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async createTwilioGrantAccessRoom({ state, commit, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = createFormData(payload)
        const { data } = await twilioGrantAccessRoom(formData);
        const { information, message, success } = data || ""
        commit('SET_TWILIO_ROOM_GRANT_ACCESS_TOKEN', information)
        resolve({
          status: success,
          message: message,
          room_token: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async createTwilioStreamPlayer({ state, commit, getters }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await createTwilioPlayerStream();
        const { information, message, success } = data || ""
        commit('SET_TWILIO_STREAM_PLAYER_ID', information)
        resolve({
          status: success,
          message: message,
          stream_id: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async createTwilioMediaProcessor({ state, commit, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = createFormData(payload)
        const { data } = await createTwilioMediaProcessor(formData);
        const { information, message, success } = data || ""
        commit('SET_TWILIO_MEDIA_PROCESSOR_ID', information)
        resolve({
          status: success,
          message: message,
          data: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getTwilioPlaybackGrant({ state, commit, getters }, player_stream_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await twilioPlaybackGrant(player_stream_id);
        const { information, message, success } = data || ""
        commit('SET_TWILIO_PLAYBACK_GRANT', information)
        resolve({
          status: success,
          message: message,
          information: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getTwilioGrantAccessToken({ state, commit, getters }, playback_grant_access_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await twilioGrantAccessToken(playback_grant_access_id);
        const { information, message, success } = data || ""
        commit('SET_TWILIO_GRANT_ACCESS_TOKEN', information)
        resolve({
          status: success,
          message: message,
          data: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async endTwilioMediaProcessor({ state, commit, getters }, player_stream_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await endTwilioMediaProcessor(player_stream_id);
        const { information, message, success } = data || ""
        // commit('SET_TWILIO_GRANT_ACCESS_TOKEN', information)
        resolve({
          status: success,
          message: message,
          data: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async endTwilioPlayerStream({ state, commit, getters }, player_stream_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await endTwilioPlayerStream(player_stream_id);
        const { information, message, success } = data || ""
        // commit('SET_TWILIO_GRANT_ACCESS_TOKEN', information)
        resolve({
          status: success,
          message: message,
          data: information
        });
      } catch (error) {
        console.error(error);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
}

export const mutations = {
  SET_TWILIO_ACCESS_TOKEN (state, payload) {
    state[TWILIO_ACCESS_TOKEN] = payload;
  },
  SET_TWILIO_ROOM (state, payload) {
    state[TWILIO_ROOM] = payload;
  },
  SET_TWILIO_ROOM_GRANT_ACCESS_TOKEN (state, payload) {
    state[TWILIO_GRANT_ACCESS_ROOM_TOKEN] = payload;
  },
  SET_TWILIO_STREAM_PLAYER_ID (state, payload) {
    state[TWILIO_PLAYER_STREAM_ID] = payload;
  },
  SET_TWILIO_MEDIA_PROCESSOR_ID (state, payload) {
    state[TWILIO_MEDIA_PROCESSOR_ID] = payload;
  },
  SET_TWILIO_PLAYBACK_GRANT (state, payload) {
    state[TWILIO_PLAYBACK_GRANT_ID] = payload;
  },
  SET_TWILIO_GRANT_ACCESS_TOKEN (state, payload) {
    state[TWILIO_GRANT_ACCESS_TOKEN] = payload;
  },
  SET_TWILIO_PLAYER_DATA (state, payload) {
    state[TWILIO_PLAYER] = payload;
  },
  SET_TWILIO_ROOM_CONNECT_DATA (state, payload) {
    state[TWILIO_ROOM_CONNECT] = payload;
  },
}

const getters = {
  getTwilioAccessToken(state) {
    return state[TWILIO_ACCESS_TOKEN];
  },
  getTwilioRoom(state) {
    return state[TWILIO_ROOM];
  },
  getGrantAccessToken(state) {
    return state[TWILIO_GRANT_ACCESS_ROOM_TOKEN];
  },
  getTwilioStreamPlayerId(state) {
    return state[TWILIO_PLAYER_STREAM_ID]
  },
  getTwilioMediaProcessorId(state) {
    return state[TWILIO_MEDIA_PROCESSOR_ID]
  },
  getTwilioPlaybackGrantId(state) {
    return state[TWILIO_PLAYBACK_GRANT_ID]
  },
  getTwilioGrantAccessToken(state) {
    return state[TWILIO_GRANT_ACCESS_TOKEN]
  },
  getTwilioPlayer(state) {
    return state[TWILIO_PLAYER]
  },
  getTwilioRoomConnectData(state) {
    return state[TWILIO_ROOM_CONNECT]
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
};


