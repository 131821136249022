import * as Utils from '../components/utils/Utils';
import {
    SM_LOGIN_DATA,
    STATUS,
    SUCCESS,
    ERROR,
    RESPONSE,
    SM_LOGIN_REMEMBER_ME,
    SM_LOGIN_DATA_EXPIRES_IN
} from '../store/types';

/** Store SM Login data */
export const storeSMLoginData = async (data) => {
    try {
        await localStorage.setItem(SM_LOGIN_DATA, JSON.stringify(data));

        return new Promise(resolve => resolve({
            [STATUS]: SUCCESS
        }));
    } catch (e) {
        // error
        Utils.log("Store SM Login Data ===> error ", e);

        return new Promise((resolve, rejects) => rejects({
            [STATUS]: ERROR
        }));
    }
}

/** Get SM Login data */
export const getSMLoginData = async (key) => {
    try {
        const data = await localStorage.getItem(SM_LOGIN_DATA);
        const res = data && data.length ? JSON.parse(data) : {};

        return new Promise(resolve => resolve({
            [STATUS]: SUCCESS,
            [RESPONSE]: res && key && res[key] ? res[key] : res
        }));
    } catch (e) {
        // error
        Utils.log("Get SM Login Data ===> error ", e);

        return new Promise((resolve, rejects) => rejects({
            [STATUS]: ERROR,
            [RESPONSE]: e
        }));
    }
}

/** Clear SM Login data */
export const clearSMLoginData = async () => {
    try {
        await localStorage.removeItem(SM_LOGIN_DATA);
        return new Promise(resolve => resolve({
            [STATUS]: SUCCESS
        }));
    } catch (e) {
        Utils.log("Remove SM Login Data ===> error ", e);

        return new Promise((resolve, rejects) => rejects({
            [STATUS]: ERROR
        }));
    }
}
