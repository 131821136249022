<template>
  <div class="wr_EME_Videos">
    <div v-for="item in 18" :key="item">
      <div v-if="item === 1" class="upload">
        <div v-if="!video">
          <div class="square">
            <input type="file"  @change="handleVideo" />
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 6.25V23.75"
                stroke="#D1433A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.25 15H23.75"
                stroke="#D1433A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p>Upload New</p>
        </div>
        <div v-else>
          <img :src="video" alt="video" />
          <input type="file"  @change="handleVideo" />
        </div>
      </div>
      <div
        v-else
        class="Videos"
        :style="{ margin: item % 6 === 0 ? '0 0 30px' : null }"
      >
        <div class="img_div"></div>
        <h6>Video #{{ item }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Artwork",
  data() {
    return {
      video: null,
      videoRolick: null,
    };
  },
  methods: {
    handleVideo(e) {
      if (e.target.files[0].size < 1000000) {
        this.videoRolick = e.target.files[0];
        this.video = URL.createObjectURL(this.videoRolick);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wr_EME_Videos {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  .upload {
    position: relative;
    width: 170px;
    height: 150px;
    border-radius: 4px;
    border: 1px solid #D1433A;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // background: #e5e5e5;
    margin: 0 30px 30px 0;
    .square {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 4px;
      background: #D1433A1A;
      border: none;
      margin: 0 auto;
    }
    p {
      margin: 16px 0 0 0;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
  .Videos {
    width: 170px;
    margin: 0 30px 30px 0;
    .img_div {
      background: #c3c3c3;
      width: 100%;
      height: 120px;
      cursor: pointer;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    h6 {
      font-family: HelveticaNeueNormal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #D1433A;
      margin: 17px 0 0 0;
    }
  }
}
</style>
