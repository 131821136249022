<template>
  <div>
    <div class="container-fluid">
        <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContainer"
}
</script>

<style scoped>

</style>
