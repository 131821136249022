<template>
  <div class="detail-container">
    <div class="detail-container-top">
      <div class="cover">
        <img :src="GET_SINGLE_EVENT_DETAILS.artwork_url" alt="" />
      </div>
      <div class="detail-container-top-info">
        <div class="date">
          <div class="month">
            {{ monthOnly(GET_SINGLE_EVENT_DETAILS.started_at) }}
            <span>{{ dayOnly(GET_SINGLE_EVENT_DETAILS.started_at) }}</span>
          </div>
          <div class="time">
            <h3>{{ GET_SINGLE_EVENT_DETAILS.title }}</h3>
            <span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.992 0C3.576 0 0 3.584 0 8C0 12.416 3.576 16 7.992 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.992 0ZM11.2 11.2C11.126 11.2742 11.0381 11.333 10.9413 11.3731C10.8445 11.4133 10.7408 11.434 10.636 11.434C10.5312 11.434 10.4275 11.4133 10.3307 11.3731C10.2339 11.333 10.146 11.2742 10.072 11.2L7.44 8.568C7.36441 8.49391 7.30427 8.40555 7.26308 8.30805C7.22188 8.21055 7.20044 8.10585 7.2 8V4.8C7.2 4.36 7.56 4 8 4C8.44 4 8.8 4.36 8.8 4.8V7.672L11.2 10.072C11.512 10.384 11.512 10.888 11.2 11.2Z"
                  fill="white"
                />
              </svg>
              {{
                GET_SINGLE_EVENT_DETAILS.start_time +
                " - " +
                monthDayFormat(GET_SINGLE_EVENT_DETAILS.end_at) +
                " " +
                GET_SINGLE_EVENT_DETAILS.end_time
              }}
            </span>
          </div>
        </div>
        <div class="visitors">
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
              stroke="white"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
              stroke="white"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>{{
            GET_SINGLE_EVENT_DETAILS.going + GET_SINGLE_EVENT_DETAILS.interested
          }}</span>
        </div>
      </div>
    </div>
    <div class="detail-container-desc">
      <event-info />
      <div class="options">
        <div class="top">
          <div class="buttons">
            <button
              class="int"
              :class="{
                'active-btn':
                  GET_SINGLE_EVENT_DETAILS.user_interest === 'interested',
              }"
              @click="
                handleInterest({
                  event_id: GET_SINGLE_EVENT_DETAILS.id,
                  interest: 'interested',
                })
              "
            >
              Interested
            </button>
            <button
              class="going"
              :class="{
                'active-btn':
                  GET_SINGLE_EVENT_DETAILS.user_interest === 'going',
              }"
              @click="
                handleInterest({
                  event_id: GET_SINGLE_EVENT_DETAILS.id,
                  interest: 'going',
                })
              "
            >
              Going
            </button>
          </div>
          <div class="settings">
            <button class="share">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.619C1 11.619 2.26951 5.80698 9.88122 5.62087C9.99792 4.08255 9.99792 2.53831 9.88122 1L17 7.34856L9.88122 14V9.12244C9.88122 9.12244 5.70755 7.94414 1 11.619Z"
                  fill="#D1433A"
                  stroke="#D1433A"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button class="setting">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z"
                  fill="#D1433A"
                  stroke="#D1433A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.25 9.75C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25C13.8358 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.8358 9.75 14.25 9.75Z"
                  fill="#D1433A"
                  stroke="#D1433A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75Z"
                  fill="#D1433A"
                  stroke="#D1433A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="buy-ticket">
          <router-link
            :to="{
              name: 'buy.Ticket',
              params: { id: GET_SINGLE_EVENT_DETAILS.id },
            }"
          >
            <button>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.3759L1.24704 11.623L3.11533 13.4912L3.36238 13.7383L3.60944 13.4912C4.22635 12.8744 5.21598 12.8744 5.83285 13.4912C6.44976 14.1082 6.44976 15.0978 5.83285 15.7147L5.58579 15.9617L5.83285 16.2088L7.70114 18.0771L7.94818 18.3241L8.19523 18.0771L18.0771 8.19522L18.3241 7.94818L18.0771 7.70113L16.2088 5.83284L15.9618 5.58579L15.7147 5.83284C15.0978 6.44973 14.1082 6.44975 13.4913 5.83284C12.8744 5.21595 12.8744 4.22632 13.4913 3.60942L13.7383 3.36238L13.4913 3.11533L11.623 1.24705L11.3759 1L11.1289 1.24705L1.24704 11.1289L1 11.3759ZM1.98819 11.3759L8.28786 5.07626L9.02899 5.8174L9.5231 5.3233L8.78195 4.58217L11.3759 1.98819L12.7733 3.38554C12.1258 4.27258 12.1974 5.52718 12.9972 6.32693C13.7969 7.12666 15.0477 7.19432 15.9347 6.54696L17.3359 7.94818L14.7419 10.5422L14.0008 9.80103L13.5067 10.2951L14.2479 11.0362L7.94817 17.3359L6.54696 15.9347C7.19432 15.0477 7.12668 13.7969 6.32694 12.9972C5.52718 12.1974 4.27257 12.1258 3.38554 12.7733L1.98819 11.3759ZM10.0326 6.82102L10.5113 7.29967L11.0054 6.80558L10.5267 6.32693L10.0326 6.82102ZM12.0244 8.81283L12.5031 9.2915L12.9972 8.79739L12.5185 8.31874L12.0244 8.81283Z"
                  fill="#D1433A"
                  stroke="#D1433A"
                  stroke-width="0.4"
                />
              </svg>
              <p class="mb-0">Buy Tickets</p>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="detail-container-nav">
      <div
        class="nav-item"
        :class="{ 'active-nav': item.title === activeNav }"
        v-for="item in nav"
        :key="item.id"
        @click="handleActiveNav(item.title)"
      >
        <p>{{ item.title }}</p>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventInfo from "./EventInfo.vue";
import dateFormats from "@/mixins/dateFormats.js";
export default {
  mixins: [dateFormats],
  components: {
    EventInfo,
  },
  props: {
    activeNav: {
      type: String,
      default: () => "about",
    },
  },
  data() {
    return {
      activeBtn: "",
      nav: [
        {
          id: 1,
          title: "about",
        },
        {
          id: 2,
          title: "posts",
        },
        {
          id: 3,
          title: "media",
        },
        {
          id: 4,
          title: "members",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["getSingleEventDetails", "InterestedGoing"]),
    handleBtn(value) {
      this.activeBtn = value;
    },
    handleActiveNav(nav) {
      this.$emit("setActivNav", nav);
    },
    async handleInterest(params) {
      this.InterestedGoing(params);
      await this.getSingleEventDetails(this.$route.params.id);
    },
  },
  computed: {
    ...mapGetters(["GET_SINGLE_EVENT_DETAILS"]),
  },
};
</script>

<style lang="scss" scoped>
.active-nav {
  p {
    color: #47505b !important;
  }
  span {
    opacity: 1 !important;
  }
}
.active-btn {
  background: #D1433A !important;
  color: #fff !important;
  &:hover {
    background: #DA6961 !important;
  }
}
.detail-container {
  width: 770px;
  max-width: 100%;
  background: #ffffff;
  border-radius: 0 0 4px 4px;
  &-top {
    position: relative;
    .cover {
      position: relative;
      height: 370px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(7, 21, 38, 0) 74.51%,
          #071526 100%
        );
      }
    }
    &-info {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 20px;
      color: #fff;
      .date {
        display: flex;
        .month {
          width: 50px;
          height: 50px;
          border-radius: 4px;
          border: 2px solid #ffffff;
          font-size: 12px;
          line-height: 15px;
          font-family: HelveticaNeueBold;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          span {
            display: block;
            font-size: 18px;
            line-height: 22px;
          }
        }
        .time {
          color: #ffffff;
          margin-left: 29px;
          font-family: HelveticaNeueBold;
          h3 {
            font-size: 24px;
            line-height: 26px;
          }
          span {
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 22px;
            svg {
              margin-right: 14px;
            }
          }
        }
      }
      .visitors {
        background: #ae4ea9;
        border-radius: 4px;
        width: 90px;
        max-width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-right: 16px;
          margin-left: 5px;
        }
      }
    }
  }
  &-desc {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 30px 20px;
    border: 1px solid #e6eaf0;
    box-shadow: 0px 4px 5px rgba(219, 219, 219, 0.1);

    border-bottom: 1px solid #e6eaf0;
    .options {
      margin-top: 0;
      .top {
        display: flex;
        justify-content: space-between;
        .buttons {
          display: flex;
          button {
            border: 1px solid #D1433A;
            width: 120px;
            height: 40px;
            max-width: 100%;
            background: #fff;
            color: #D1433A;
            font-size: 11px;
            line-height: 13px;
            font-family: HelveticaNeueBold;
            &:hover {
              background-color: #D1433A1A;
            }
          }
          .int {
            border-radius: 4px 0 0 4px;
          }
          .going {
            border-radius: 0 4px 4px 0;
          }
        }
        .settings {
          display: flex;
          align-items: center;
          margin-left: 10px;
          button {
            background: unset;
            border: 1.5px solid #D1433A;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            &:hover {
              background-color: #D1433A1A;
            }
            svg {
              margin: 0 auto;
            }
          }
          .setting {
            margin-left: 10px;
          }
        }
      }
      .buy-ticket {
        margin-top: 10px;
        &:hover {
          background-color: #D1433A1A;
        }
        button {
          border-radius: 4px;
          border: 1px solid #D1433A;
          width: 340px;
          height: 40px;
          background: unset;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: #D1433A;
            font-size: 13px;
            line-height: 16px;
            font-family: HelveticaNeueBold;
            margin-left: 8px;
          }
        }
      }
    }
  }
  &-nav {
    display: flex;
    padding: 20px 20px 0 20px;
    .nav-item {
      cursor: pointer;
      p {
        color: #c4ccd6;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        margin-bottom: 19px;
      }
      span {
        display: block;
        width: 100%;
        height: 3px;
        background: #D1433A;
        opacity: 0;
        transition: all 0.3s;
      }
    }
    .nav-item + .nav-item {
      margin-left: 42px;
    }
  }
}
</style>
