<template>
  <div class="related-widget widget-info new_widget">
    <div class="widget-item">
      <div class="item">
        <div class="items-main">
          <div class="item-title">FRIENDS</div>
          <div
            class="
              item-subtitle
              d-flex
              align-items-center
              justify-content-center
            "
          >
            {{ friends_count }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="items-main">
          <div class="item-title">FOLLOWERS</div>
          <div
            class="
              item-subtitle
              d-flex
              align-items-center
              justify-content-center
            "
          >
            {{ followers_count }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="items-main">
          <div class="item-title">FOLLOWING</div>
          <div
            class="
              item-subtitle
              d-flex
              align-items-center
              justify-content-center
            "
          >
            {{ following_count }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    followersCount: {
      type: String,
    },
    followingCount: {
      type: String,
    },
    friendsCount: {
      type: String,
    },
  },
  computed: {
    friends_count() {
      return this.friendsCount && this.friendsCount.length > 1
        ? this.friendsCount
        : 0 + this.friendsCount;
    },
    following_count() {
      return this.followingCount && this.followingCount.length > 1
        ? this.followingCount
        : 0 + this.followingCount;
    },
    followers_count() {
      return this.followersCount && this.followersCount.length > 1
        ? this.followersCount
        : 0 + this.followersCount;
    },
  },
};
</script>
<style scoped lang="scss">
.related-widget {
  .widget-item {
    .item {
      .items-main {
        // padding: 8px 20px 8px;
        .item-title {
          margin-bottom: unset !important;
        }
        .item-subtitle {
          font-size: 30px !important;
          margin-top: 3px;
          margin-top: 0;
          line-height: 37px !important;
        }
      }
    }
  }
  &.new_widget {
    padding: 9px !important;
  }
}
</style>
