<template>
  <div class="wr_eventsInvites">
    <h1 class="title">INVITES</h1>
    <div class="wr_contener">
      <div class="invites_count">
        You have
        {{
          GET_MY_INVITES_EVENT_LIST.total ? GET_MY_INVITES_EVENT_LIST.total : 0
        }}
        {{ GET_MY_INVITES_EVENT_LIST.total > 1 ? "invites" : "invite" }}
      </div>
      <EventListCart
        v-for="item in GET_MY_INVITES_EVENT_LIST.data"
        :key="item.id"
        :event="item"
        @getFavoritesData="getAllFavoritesData_going_and_interested"
      />
    </div>
    <div
      v-if="GET_MY_INVITES_EVENT_LIST.total > limit"
      @click="showMoreInvites"
      class="show_more_btn"
    >
      <div class="show-more musicbar_more">
        <a
          ><span class="show_more_bg"
            ><svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW 5 MORE INVITES
          </span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import EventListCart from "../components/EventListCart.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    EventListCart,
  },
  data() {
    return {
      limit: 5,
    };
  },
  methods: {
    ...mapActions(["myInvitesEvents"]),
    async getAllFavoritesData_going_and_interested() {
      await this.$store.dispatch("loading", true);
      await this.myInvitesEvents();
      await this.$store.dispatch("loading", false);
    },
    async showMoreInvites() {
      this.limit += 5;
      await this.$store.dispatch("loading", true);
      await this.myInvitesEvents(this.limit);
      await this.$store.dispatch("loading", false);
    },
  },
  computed: {
    ...mapGetters(["GET_MY_INVITES_EVENT_LIST"]),
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.myInvitesEvents(this.limit);
    await this.$store.dispatch("loading", false);
  },
};
</script>

<style lang="scss" scoped>
.wr_eventsInvites {
  margin-bottom: 90px;
  .title {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin: 35px 0 13px 0;
  }
  .invites_count {
    margin-bottom: 30px;
    background: #ffffff;
    height: 70px;
    display: flex;
    align-items: center;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    padding: 0 29px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #47505b;
    font-family: HelveticaNeueBold;
  }
  .wr_contener {
  }
}
</style>