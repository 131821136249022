import { Add_To_Queue_Songs_Array, Play_All_Songs_Array } from "../types";

const INITIAL_STATE = {
  [Add_To_Queue_Songs_Array]: [],
  [Play_All_Songs_Array]: [],
}
export const state = {...INITIAL_STATE};
export const actions = {
  async addSongsToQueue({commit, getters}){
    await commit('Set_Add_Songs_To_Queue', getters.getAddToQueue)
    commit('SET_LIST_OPTION_STATUS')
  },
  async removeFromQueue({commit, getters}){
    await commit('Set_Remove_From_Queue', getters.getAddToQueue)
    commit('Remove_From_Music_Player', getters.getAddToQueue);
    commit('SET_LIST_OPTION_STATUS')
  }
}
export const mutations ={
  Set_Add_Songs_To_Queue(state, payload){
    // try {
    //   if(state[Add_To_Queue_Songs_Array].length > 0){
    //     function unique(arrayName)
    //     {
    //       const newArray = [];
    //       label: for(let i=0; i < arrayName.length; i++)
    //       {
    //         for(let j=0; j < newArray.length; j++)
    //         {
    //           if(newArray[j].id === arrayName[i].id)
    //             continue label;
    //         }
    //         newArray[newArray.length] = arrayName[i];
    //       }
    //       return newArray;
    //     }
    //     let oldData = state[Add_To_Queue_Songs_Array];
    //     let newData = [...oldData, ...payload]
  //       state[Add_To_Queue_Songs_Array] = unique(newData);
    //   }else{
    //     state[Add_To_Queue_Songs_Array] = payload;
    //   }

    // }catch (e){
    //   console.error(e.message, 'unable to set add songs to queue')
    // }

    try {
      if (state[Add_To_Queue_Songs_Array].length > 0) {
        function isUnique(item, index, self) {
          return self.findIndex((i) => i.id === item.id) === index;
        }
    
        let oldData = state[Add_To_Queue_Songs_Array];
        let newData = [...oldData, ...payload];
    
        // Find common items between newData and oldData
        let commonItems = newData.filter((item) =>
          oldData.some((oldItem) => oldItem.id === item.id)
        );
    
        if (commonItems.length === newData.length) {
          Swal.fire({
            title: 'Add Queue',
            text: 'This song has already been added to the queue!',
            icon: 'warning'
          });
        } else {
          // Filter out duplicate items in newData
          let uniqueData = newData.filter(isUnique);
          state[Add_To_Queue_Songs_Array] = uniqueData;
        }
      } else {
        state[Add_To_Queue_Songs_Array] = payload;
      }
    } catch (e) {
      console.error(e.message, 'Unable to set add songs to the queue');
    }
    
  },
  Set_Play_All_Songs_Array(state, payload){
    try {
      state[Play_All_Songs_Array] = [];
      state[Play_All_Songs_Array] = payload;
    }catch (e){
      console.error(e.message, 'unable to set play all songs')
    }
  },
  Empty_Play_All_Songs_Array(state){
    try {
      state[Play_All_Songs_Array] = [];
    }catch (e){
      console.error(e.message, 'unable to empty play all songs')
    }
  },
  Set_Remove_From_Queue(state, payload){
    try {
      let song = state[Add_To_Queue_Songs_Array].filter(song =>song.id === payload[0].id)[0]
      if(song){
        let index = state[Add_To_Queue_Songs_Array].indexOf(song)
        state[Add_To_Queue_Songs_Array].splice(index, 1);
        state[Add_To_Queue_Songs_Array] = state[Add_To_Queue_Songs_Array];
      }
    }catch (e){
      console.error(e.message, 'unable to remove songs from queue')
    }
  },
  Set_Songs_List_Empty(state){
    state[Add_To_Queue_Songs_Array] = [];
  },
  Set_Play_All_Songs_List_Empty(state){
    state[Play_All_Songs_Array] = [];
  }
}
const getters = {
  getSessionSongs(state) {
    return state[Add_To_Queue_Songs_Array];
  },
  getPlayAllSongs(state){
    return state[Play_All_Songs_Array];
  }
}
export default {
  state,
  actions,
  mutations,
  getters
};
