<template>
    <div class="pol-detail">
        <label id="base-select-container" @click="selectAudience(option.option)" class="audience-container">
            <div class="d-flex justify-content-center align-items-center" style="width: 30px; height: 30px">
                <div class="icon w-20">
                    <slot name="icon"></slot>
                </div>
                <div class="w-20">
                    <slot name="icon-with-no-hover"></slot>
                </div>
            </div>
            <p class="w-100 m-0 pl-4 pt-1 sponsors-title">
                {{ option.option }}
            </p>
            <div class="pt-1 radio-btn">
                <input class="radio" type="radio" name="audience-radio" :value="option.option"  :checked="option.option.toUpperCase() === selectedRadio"
                    :id="'radio-btn-' + option.id" :key="option.id" aria-label="..." />
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: "BaseSelect",
    props: {
        option: {
            type: Object,
            required: true
        },
      selectedRadio: {
        type: String,
        default: ""
      },
    },
    methods: {
        async onPress(e) {
            if (e) e.stopPropagation();
        },
        selectAudience(audience) {
            // console.log("Emitting Value Aud--> "+audience);
            this.$emit("selectAudience", audience);
        }
    },
    computed: {},
    mounted() {
    }
};
</script>

<style lang="scss" scoped>
.audience-container {
    /* Rectangle 4729 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0% 6%;
    height: 50px;
    width: 100%;
    /* Light grey (New) */
    border: 1px solid #E6EAF0;
    border-radius: 4px;

    .hover-icon {
        display: none;
    }

    &:hover {
        transition: ease-in-out 0.2s;
        border: 1px solid #D1433A;

        .icon {
            stroke: none;

            path {
                fill: #D1433A;
            }

            circle {
                stroke: #D1433A;
            }
        }


    }

    .sponsors-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        /* identical to box height */

        /* Dark (New) */
        color: #071526;
    }

    .radio-btn {
        .radio {
            width: 16px;
            height: 16px;
            border: 5px solid red !important;
            border-radius: 50%;
            &:checked {
                //width: 30px;
                //height: 30px;
                border: 1px solid #D1433A !important;
                border-radius: 100%;
                background: #fff;
            }
        }
    }
}

input[type="radio"] {
  accent-color: #D1433A;  /* Change the default checked color */
}

/* You can also customize the background and border when checked */
input[type="radio"]:checked {
  background-color: #D1433A; /* Custom color when checked */
  border-color: #D1433A;     /* Border color when checked */
}

//input[type="radio"] {
//  width: 16px;
//  height: 16px;
//  background-color: #D1433A1A;
//  &:checked {
//    background-color: #D1433A;
//  }
//}
</style>