<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <Header />
        <router-view />
        <MusicFooter v-if="ShowMusicBar" />
      </div>
    </div>

    <div class="dock-wrapper"></div>
  </div>
</template>
<script>
import Header from "@/components/Common/Header";
import MusicFooter from "@/components/Common/MusicFooter";

export default {
  data() {
    return {};
  },

  components: {
    Header,
    MusicFooter,
  },
  props: {
    ShowMusicFooter: Boolean,
    ShowLeftNav: Boolean,
  },
  computed: {
    ShowMusicBar() {
      if (!this.$route.meta.ShowMusicFooter) {
        return false;
      }

      return true;
    },
  },
  methods: {
    checkUrl: function (comp) {},
  },
};
</script>
