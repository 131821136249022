import { render, staticRenderFns } from "./SingleChat.vue?vue&type=template&id=64f53028&scoped=true"
import script from "./SingleChat.vue?vue&type=script&lang=js"
export * from "./SingleChat.vue?vue&type=script&lang=js"
import style0 from "./SingleChat.vue?vue&type=style&index=0&id=64f53028&prod&scoped=true&lang=scss"
import style1 from "./SingleChat.vue?vue&type=style&index=1&id=64f53028&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f53028",
  null
  
)

export default component.exports