<template>
<div>
    <div class="page-card">
      <div class="page-card-body">
        <div class="row">
          <div class="col-md-12">
        <div class="page-form-n">
          <label>Name your Page<sup class="sup-color">*</sup></label>
          <input type="text">
        </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-12">
        <div class="page-form-n">
          <label>Page Address</label>
          <div class="page-address">
            <span class="email-ico">  www.audioground.com/  </span>
            <input type="text" placeholder="youraddress">
          </div>
          <span class="page-text">Can only contain alphanumeric characters (A–Z, 0–9) and periods ('.')</span>
        </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="page-form-n">
              <label>Category<sup class="sup-color">*</sup></label>
                <div class="category-select-n">
                  <select name="edit_profile_form_gender" class="page-control"><option value="">Select Gender</option><option value="M">Male</option><option value="F">Female</option><option value="O">Other</option></select>
                </div>
              </div>
          </div>
          <div class="col-md-6">
            <div class="page-form-n">
              <label>SubCategory<sup class="sup-color">*</sup></label>
              <div class="category-select-n">
              <select name="edit_profile_form_relationship_status" class="page-control"><option value="">Select Relationship</option><option value="Single">Single</option><option value="Married">Married</option></select>
              </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
        <div class="page-form-n">
          <label>About</label>
          <textarea></textarea>
        </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-12">
        <div class="page-form-n">
          <label>Avatar Image</label>
          <div class="page-avatar-section">
            <div class="page-avatar-icon">
              <img src="/img/birthday-dp.8f36c8c9.png">
            </div>
            <div class="avtatr-upload">
              <p>No Avatar Image</p>
              <div class="avatar-upload-section">
                <input type="file" id="myfile" name="myfile">
                Upload Now
                <span class="line"></span>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        <div class="page-form-n">
          <label>Cover Image</label>
          <div class="upload-artist-cover">
            <img :src="require(`@/assets/img/upload.png`)"/>
            <input type="file" id="myfile" name="myfile">
          </div>
        </div>
        <div class="page-btn">
          <button>PUBLISH</button></div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: "NewPageForm"
}
</script>

<style scoped>
.page-card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}
.page-card-body{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.page-text{
  font-size: 12px;
  font-weight: 500;
  color: #8B949F;
  margin-top: 8px;
  display: inline-block;
  width: 100%;
  font-family: HelveticaNeueMedium, sans-serif;
}
.page-address{
  display: flex;
}
.page-btn button{
  background-color: #D1433A;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  transition: 0.3s all;
  border: none;
  width: 300px;
  height: 40px;
  font-family: HelveticaNeueMedium, sans-serif;
}

.category-select-n::after {
  content: '';
  position: absolute;

  width: 10px;
  height: 6px;
  top: 43px;
  right: 25px;
}

.page-control{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: rgba(7, 21, 38, 0.6);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  cursor: pointer;
  font-family: 'HelveticaNeueMedium';
}

.page-form-n{
  margin-bottom: 17px;
  color: #47505B;
}
.page-form-n label{
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  color: #47505B;
  font-family: HelveticaNeueMedium, sans-serif;
}
.sup-color {
  color: #f00;
}
.page-form-n input{
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E6EAF0;
  padding-left: 10px;
  font-size: 13px;
  color: #071526;
  font-weight: 500;
  height: 30px;
  font-family: HelveticaNeueMedium, sans-serif;
}
.page-form-n textarea{
  width: 100%;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  resize: none;
  height: 50px;
  font-size: 13px;
  font-weight: 500;
  color: #8B949F;
  padding: 5px 0 0 10px;
}

.page-avatar-section{
  display: flex;
  align-items: center;
}

.page-avatar-icon img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}





</style>
