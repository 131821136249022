<template>
  <div class="Signup_pop_overlay">
    <div class="wr_my_wallet_modals">
      <div class="wr_title">
        <div>
          <div
            class="ic_prev_close"
            @click="$parent.openCloseModals({modal:'paymentMethods'})"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 1L2 6L7 11" stroke="#47505B" stroke-width="1.5" />
            </svg>
          </div>
          <div class="title">Payment ($70)</div>
        </div>
        <div class="ic_prev_close" @click="$parent.openCloseModals({modal:'cart'})">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="wr_cart_content">
        <div class="name-payment">
          <div>
            <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1804 1.39453H2.72585C2.02294 1.39453 1.45312 1.95418 1.45312 2.64453V10.1445C1.45312 10.8349 2.02294 11.3945 2.72585 11.3945H14.1804C14.8833 11.3945 15.4531 10.8349 15.4531 10.1445V2.64453C15.4531 1.95418 14.8833 1.39453 14.1804 1.39453Z"
                stroke="#6540CE"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.45312 5.14453H15.4531"
                stroke="#6540CE"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Credit Card
          </div>
        </div>
        <div v-if="!rememberCart">
          <div>
            <div class="div_input card_number">
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1804 1.39453H2.72585C2.02294 1.39453 1.45312 1.95418 1.45312 2.64453V10.1445C1.45312 10.8349 2.02294 11.3945 2.72585 11.3945H14.1804C14.8833 11.3945 15.4531 10.8349 15.4531 10.1445V2.64453C15.4531 1.95418 14.8833 1.39453 14.1804 1.39453Z"
                  stroke="#8B949F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.45312 5.14453H15.4531"
                  stroke="#8B949F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <input type="number" placeholder="Card Number" />
            </div>
            <div class="d-flex">
              <div class="div_input card_verify">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4444 2.5H2.55556C1.69645 2.5 1 3.17157 1 4V14.5C1 15.3284 1.69645 16 2.55556 16H13.4444C14.3036 16 15 15.3284 15 14.5V4C15 3.17157 14.3036 2.5 13.4444 2.5Z"
                    stroke="#8B949F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.1094 1V4"
                    stroke="#8B949F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.89062 1V4"
                    stroke="#8B949F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 7H15"
                    stroke="#8B949F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input type="number" placeholder="MM/YY" />
              </div>
              <div class="div_input card_cvv">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4444 7.75H2.55556C1.69645 7.75 1 8.42157 1 9.25V14.5C1 15.3284 1.69645 16 2.55556 16H13.4444C14.3036 16 15 15.3284 15 14.5V9.25C15 8.42157 14.3036 7.75 13.4444 7.75Z"
                    stroke="#8B949F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.10938 7.75V4.75C4.10938 3.75544 4.5191 2.80161 5.2484 2.09835C5.97771 1.39509 6.96687 1 7.99826 1C9.02966 1 10.0188 1.39509 10.7481 2.09835C11.4774 2.80161 11.8872 3.75544 11.8872 4.75V7.75"
                    stroke="#8B949F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input type="number" placeholder="CVV" />
              </div>
            </div>
          </div>
          <div class="custom-checkbox">
            <div @click="ischeked = !ischeked">
              <span v-if="ischeked"></span>
            </div>
            <p @click="ischeked = !ischeked">Remember Me</p>
          </div>
        </div>
        <div v-if="rememberCart" class="wr_saved_card">
          <div class="saved_card d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1804 1.39453H2.72585C2.02294 1.39453 1.45312 1.95418 1.45312 2.64453V10.1445C1.45312 10.8349 2.02294 11.3945 2.72585 11.3945H14.1804C14.8833 11.3945 15.4531 10.8349 15.4531 10.1445V2.64453C15.4531 1.95418 14.8833 1.39453 14.1804 1.39453Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.45312 5.14453H15.4531"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              4440 **** 1192
            </div>
            <div class="d-flex align-items-center">
              08 / 24
              <div class="delete_seved_card">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L5 15"
                    stroke="#FF0039"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 5L15 15"
                    stroke="#FF0039"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <button @click="rememberCart = !rememberCart">
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.9987 9.16683C7.29988 9.16683 9.16537 7.30135 9.16537 5.00016C9.16537 2.69898 7.29988 0.833496 4.9987 0.833496C2.69751 0.833496 0.832031 2.69898 0.832031 5.00016C0.832031 7.30135 2.69751 9.16683 4.9987 9.16683Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 3.3335V6.66683"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.33203 5H6.66537"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            ADD NEW CARD
          </button>
        </div>
      </div>
      <div class="wr_btn">
        <button @click="$parent.openCloseModals({modal:'cart'})" class="cancel">
          CANCEL
        </button>
        <button class="confirm">Pay $70</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ischeked: false,
      rememberCart: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.wr_cart_content {
  margin: 20px 0;
  .custom-checkbox {
    display: flex;
    background: #f3f3f3;
    border-radius: 2px;
    align-items: center;
    height: 40px;
    padding: 0 0 0 20px;
    margin-top: 10px;
    div {
      width: 20px;
      height: 20px;
      border: 1px solid #e6eaf0;
      border-radius: 2px;
      background: #ffffff;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 15px;
        height: 6px;
        display: block;
        border-left: 2px solid #D1433A;
        border-bottom: 2px solid #D1433A;
        transform: rotate(-45deg);
        margin-bottom: 3px;
      }
    }
    p {
      margin: 0;
      color: rgba(7, 21, 38, 0.6);
      font-family: HelveticaNeueMedium;
      font-weight: 500;
      font-size: 13px;
    }
  }
  .name-payment {
    padding: 0px 0 20px;
    border-bottom: 1px solid #e6eaf0;
    margin-bottom: 20px;
    div {
      height: 40px;
      background: #f3f3f3;
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 0 0 0 20px;
      color: #47505b;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 12px;
      svg {
        margin-right: 18px;
      }
      .delete_seved_card {
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 17px;
        cursor: pointer;
      }
    }
  }
  .div_input {
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    padding: 0 0 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    input {
      height: 100%;
      border-radius: 4px;
      border: none;
      color: rgba(7, 21, 38, 0.6);
      font-size: 13px;
      font-family: HelveticaNeueMedium;
      font-weight: 500;
      width: 75%;
    }
    svg {
      margin-right: 18px;
    }
  }
  .card_verify {
    margin-right: 10px;
  }
}
.wr_saved_card {
  .saved_card {
    padding: 0 20px;
    height: 40px;
    background: #D1433A1A;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  button {
    margin: 0 auto;
    width: 230px;
    height: 30px;
    display: flex;
    color: #ffffff;
    background: #D1433A;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    font-family: HelveticaNeueBold;
    font-weight: 700;
    font-size: 12px;
    border: none;
    &:hover {
      background: #DA6961 !important;
    }
    svg {
      margin-right: 10px;
    }
  }
  .saved_card {
    svg {
      margin-right: 17px;
    }
  }
  .delete_seved_card {
    margin-left: 20px;
    cursor: pointer;
    svg {
      margin: 0;
    }
  }
}
</style>              