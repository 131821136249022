<template>
<span>Dashboard</span>
</template>

<script>
export default {
  name: "index.vue"
};
</script>

<style scoped>

</style>
