<template>
  <div class="row" :class="[type === 'full' ? 'full-width' : 'width']">
    <div class="col-12 d-flex align-items-end">
      <div class="time" style="border-radius: 4px">
        {{ convertSecondsIntoMinuets(time) }}
      </div>
      <div class="wave-width">
        <div class="waveform-holder cursor-pointer">
          <div class="wavess" :id="'wave' + latest_list_id + fullPageCheck" />
        </div>
        <div style="display: none">{{ waveSurfer }}</div>
        <div v-if="songReady < 100" class="rel">
          <div class="abs">
            <div class="spinner-border" role="status">
              <span class="visually-hidden" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="time"
        :class="{ 'margin-left': type === 'full' }"
        style="border-radius: 4px"
      >
        {{ convertSecondsIntoMinuets(duration) }}
      </div>
    </div>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import moment from "moment";
export default {
  name: "MusicPlayer",
  props: {
    songLoaded: Boolean,
    isSongPlaying: Boolean,
    music: String,
    latest_list_id: Number,
    duration: Number,
    type: String,
    fullPageCheck: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      wavesurfer: false,
      songReady: 0,
      time: 0,
      song_duration: 0,
    };
  },
  computed: {
    waveSurfer() {
      if (this.wavesurfer) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.time = this.wavesurfer.getCurrentTime();
      } else {
      }
      return 0;
    },
    currentPlayer() {
      return this.$store.getters.getCurrentPlayer;
    },
    songStatus() {
      return this.$store.getters.getSongsStatus;
    },
  },
  watch: {
    music() {
      this.createSurfer(this.music);
    },
    currentPlayer: function (newVal, oldVal) {
      if (newVal === "footerPlayer") {
        this.stopPlayer();
      }
    },
  },
  mounted() {
    if (this.music) {
      this.createSurfer(this.music);
    }
  },
  destroyed() {
    this.destroySong();
  },
  methods: {
    convertSecondsIntoMinuets(duration) {
      return moment.utc(duration * 1000).format("mm:ss");
    },
    setsongReady(val) {
      this.songReady = val;
    },
    createSurfer(song_url) {
      let self = this;
      setTimeout(function () {
        self.wavesurfer = WaveSurfer.create({
          container: "#wave" + self.latest_list_id + self.fullPageCheck,
          barWidth: 2,
          barHeight: 1,
          barGap: 2,
          waveColor: "#E6EAF0",
          cursorColor: "transparent",
          progressColor: "#0075FF",
          height: 90,
          top: 4,
          left: 50,
          showTime: true,
          mediaControls: true,
          backend: "WebAudio",
          fillParent: true,
          responsive: true,
          maxCanvasWidth: 15000,
          normalize: true,
          partialRender: true,
          mediaType: "audio",
        });
        self.wavesurfer.load(song_url);
        self.wavesurfer.on("finish", () => {
          self.wavesurfer.pause();
          self.$emit("update:isSongPlaying", self.wavesurfer.isPlaying());
          self.$emit("updateIcon", false);
        });
        self.wavesurfer.on("ready", () => {
          self.setsongReady(100);
        });
        self.wavesurfer.on("audioprocess", () => {
          self.song_duration = self.wavesurfer.getDuration();
          if (self.wavesurfer.isPlaying()) {
            self.time = self.wavesurfer.getCurrentTime();
          }
        });
      }, 3000);
    },
    destroySong() {
      this.wavesurfer.destroy();
    },
    async playPauseSong() {
      if (this.wavesurfer.isPlaying()) {
        this.$emit("update:isSongPlaying", this.wavesurfer.isPlaying());
      }
      await this.wavesurfer.playPause();
    },
    playSong() {
      this.wavesurfer.play();
    },
    pauseSong() {
      this.wavesurfer.pause();
    },
    stopPlayer() {
      this.wavesurfer.pause();
      this.$emit("updateIcon", false);
    },
    getCurrentTime() {
      if (this.wavesurfer) {
        this.time = this.wavesurfer.getCurrentTime();
      }
    },
  },
};
</script>

<style scoped>
.time {
  position: unset !important;
  margin-right: unset !important;
  z-index: 5;
}
.wave-width {
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  margin-left: -38px;
  margin-right: -22px;
}
.margin-left {
  margin-left: -1rem;
  z-index: 2;
}
.waveform-holder {
  height: 45px;
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer !important;
}
.rel {
  position: relative;
}
.abs {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #D1433A;
}
.full-width {
  width: 115%;
}
.width {
  width: 105%;
}
</style>
