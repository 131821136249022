<template>
  <div class="new-event">
    <!-- <div class="new-event-nav">
      <div class="go-back" @click="$router.push({ name: 'Events' })">
        <svg
          width="7"
          height="14"
          viewBox="0 0 7 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.85938 12.7285L0.130375 7.00052L5.85937 1.27152"
            stroke="#071526"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>MY EVENTS</p>
      </div>
      <div class="new-event-title">
        <p>ADD NEW EVENT</p>
      </div>
    </div> -->
    <div class="new-event-container">
      <div class="left">
        <div class="left-title">
          <label for="name"
            ><p>Name your Event</p>
            <input type="text" v-model="eventName" />
          </label>
          <label for="location"
            ><p>Location</p>
            <GoogleMapAndLocation @getLocation="getLocation" />
          </label>
        </div>
        <div class="left-date flex">
          <div class="flex">
            <div class="start-date">
              <h4>Start Date</h4>
              <div class="date-picker-container" @click="show = !show">
                <p class="mb-0" v-if="startDate">{{ isMoment(startDate) }}</p>
                <p class="mb-0" v-else>DD/MM/YYYY</p>
                <date-picker
                  v-model="startDate"
                  type="date"
                  :show-week-number="false"
                  :open="show"
                  :confirm="true"
                  confirm-text="DONE"
                  @confirm="selectValue"
                ></date-picker>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.60156 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M11.3984 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.10156 6.90039H13.9016"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
            <div class="start-time ml-10">
              <h4>Start Time</h4>
              <div
                class="date-picker-container w-140"
                @click="showSTime = !showSTime"
              >
                <p class="mb-0" v-if="startTime">{{ startTime }}</p>
                <p class="mb-0" v-else>10:00 PM</p>
                <date-picker
                  v-model="startTime"
                  type="time"
                  format="hh:mm a"
                  value-type="format"
                  :open="showSTime"
                  :confirm="true"
                  confirm-text="DONE"
                  @confirm="showSTime = false"
                ></date-picker>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5 5L9 1"
                    stroke="#47505B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="start-date">
              <h4>End Date</h4>
              <div
                class="date-picker-container"
                @click="showEDate = !showEDate"
              >
                <p class="mb-0" v-if="endDate">{{ isMoment(endDate) }}</p>
                <p class="mb-0" v-else>DD/MM/YYYY</p>
                <date-picker
                  v-model="endDate"
                  type="date"
                  :show-week-number="false"
                  :open="showEDate"
                  :confirm="true"
                  confirm-text="DONE"
                  @confirm="showEDate = false"
                ></date-picker>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.60156 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M11.3984 4.3V0"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                  <path
                    d="M3.10156 6.90039H13.9016"
                    stroke="#8B949F"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </div>
            <div class="start-time ml-10">
              <h4>End Time</h4>
              <div
                class="date-picker-container w-140"
                @click="showETime = !showETime"
              >
                <p class="mb-0" v-if="endTime">{{ endTime }}</p>
                <p class="mb-0" v-else>10:00 PM</p>
                <date-picker
                  v-model="endTime"
                  type="time"
                  format="hh:mm a"
                  value-type="format"
                  :open="showETime"
                  :confirm="true"
                  :show-second="false"
                  confirm-text="DONE"
                  @confirm="showETime = false"
                ></date-picker>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5 5L9 1"
                    stroke="#47505B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="left-select flex">
          <div class="left-select-genre">
            <h4>Genre</h4>
            <div class="left-select-co" @click="genresPopup = !genresPopup">
              <p v-if="selectedGenres.length < 1">Choose genre</p>
              <div v-else class="genres-select-wraper">
                <span v-for="item in selectedGenres" :key="item.id"
                  >{{ item.name }}
                  <svg
                    @click.stop="removeGenre(item.id)"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 2.5L2.5 7.5"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 2.5L7.5 7.5"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>

              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#47505B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div v-if="genresPopup" class="genres-popup">
                <perfect-scrollbar>
                  <div
                    class="item"
                    v-for="genre in genres"
                    :key="genre.id"
                    @click.stop="handleGenres(genre)"
                  >
                    {{ genre.name }}
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
          <div class="left-select-venue">
            <h4>Venue Type</h4>
            <div class="left-select-co" @click="venuePopup = !venuePopup">
              <p v-if="!selectedvenue">Choose Venue</p>
              <p v-else>{{ selectedvenue.title }}</p>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#47505B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                v-if="venuePopup"
                class="genres-popup"
                @click.stop="venuePopup = false"
              >
                <perfect-scrollbar>
                  <div
                    class="item"
                    v-for="venue in venueTypes"
                    :key="venue.id"
                    @click="handleVenue(venue)"
                  >
                    {{ venue.title }}
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
        <div class="left-editor">
          <h4>About Events</h4>
          <!-- <ckeditor
            class="newEvent-editor"
            v-model="editorData"
            :config="editorConfig"
          ></ckeditor> -->
          <quill-editor ref="myTextEditor" v-model="editorData" :config="customToolbar">
          </quill-editor>
        </div>
        <div class="left-actions">
          <button class="create" @click="newEvent">create</button>
          <button class="cancel" @click="cancelEventPopup = true">
            cancel
          </button>
        </div>
      </div>
      <div class="right">
        <div class="right-not">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M12.7734 22.1133C18.2963 22.1133 22.7734 17.6361 22.7734 12.1133C22.7734 6.59043 18.2963 2.11328 12.7734 2.11328C7.25059 2.11328 2.77344 6.59043 2.77344 12.1133C2.77344 17.6361 7.25059 22.1133 12.7734 22.1133Z"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.7734 16.1133V12.1133"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.7734 8.11328H12.7834"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div>
            <h3>Tickets & Pricing</h3>
            <p>
              You can add your tickets & pricing information after creating the
              event using “Settings Tool”
            </p>
          </div>
        </div>
        <div class="right-cover">
          <h4>Cover</h4>
          <div class="upload">
            <input type="file" ref="cover" @change="handleCover" />
            <svg
              v-if="!cover"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 18.3333V22.7777C25 23.3671 24.7659 23.9323 24.3491 24.349C23.9324 24.7658 23.3671 24.9999 22.7778 24.9999H7.22222C6.63285 24.9999 6.06762 24.7658 5.65087 24.349C5.23413 23.9323 5 23.3671 5 22.7777V18.3333"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.5564 10.5556L15.0009 5L9.44531 10.5556"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 5V18.3333"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <img v-else :src="cover" alt="cover" />
          </div>
        </div>
        <div class="right-privacy">
          <h4>Privacy</h4>
          <div
            class="privacy"
            :class="{
              'custom-border': privacyPopup,
              'privacy-shadow': privacyPopup,
            }"
            @click.stop="privacyPopup = !privacyPopup"
          >
            <div class="privacy-left">
              <img
                :src="require(`@/assets/img/events/${activePrivacy.img}`)"
                alt=""
              />
            </div>
            <div class="privacy-middle">
              <h3>{{ activePrivacy.text.title }}</h3>
              <p>{{ activePrivacy.text.description }}</p>
            </div>
            <div class="privacy-right" :class="{ 'arrow-down': privacyPopup }">
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5 5L9 1"
                  stroke="#47505B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="privacy-popup" v-if="privacyPopup">
              <div
                class="item"
                :class="{ 'active-privacy': item.id === activePrivacy.id }"
                v-for="item in privacy"
                :key="item.id"
                @click.stop="handlePrivacy(item)"
              >
                <div class="privacy-left">
                  <img
                    :src="require(`@/assets/img/events/${item.img}`)"
                    alt=""
                  />
                </div>
                <div class="privacy-middle">
                  <h3>{{ item.text.title }}</h3>
                  <p>{{ item.text.description }}</p>
                </div>
                <div class="item-radio">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <!-- <h4>Event Type</h4>
          <div class="left-select-co mb-17" @click="eventPopup = !eventPopup">
            <p v-if="!selectedEvent">Choose Category</p>
            <p v-else>{{ selectedEvent.title }}</p>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5 5L9 1"
                stroke="#47505B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div
              v-if="eventPopup"
              class="genres-popup"
              @click.stop="eventPopup = false"
            >
              <perfect-scrollbar>
                <div
                  class="item"
                  v-for="event in eventTypes"
                  :key="event.id"
                  @click="handleEvent(event)"
                >
                  {{ event.title }}
                </div>
              </perfect-scrollbar>
            </div>
          </div> -->
          <h4>Tags</h4>
          <div class="tags">
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              placeholder="Tags"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="cancelEventPopup" class="cancel-popup-wrapper">
      <cancel-popup @cancelEvent="cancelEventPopup = false" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "/public/css/vue2-date-picker.scss";
import { mapActions } from "vuex";
// import CKEditor from "ckeditor4-vue";
import VueTagsInput from "@johmun/vue-tags-input";
import GoogleMapAndLocation from "@/components/Common/Google Location/GoogleMapAndLocation";
import mixin from "../../../mixins/sweetAlert";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css';

// import CancelPopup from "../components/CreateEvents/CancelPopup.vue";
export default {
  components: {
    DatePicker,
    // ckeditor: CKEditor.component,
    VueTagsInput,
    GoogleMapAndLocation,
    quillEditor
    // CancelPopup,
  },
  mixins: [mixin],
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      eventName: "",
      startDate: null,
      startTime: null,
      endTime: null,
      endDate: null,
      show: false,
      showSTime: false,
      showETime: false,
      showEDate: false,
      genres: [],
      selectedGenres: [],
      genresPopup: false,
      venuePopup: false,
      venueTypes: [],
      selectedvenue: null,
      // editorConfig: {},
      editorData: null,
      cover: null,
      privacy: [
        {
          id: 1,
          img: "public.svg",
          text: {
            title: "Public Event",
            description: "Anyone can see the event, its users and posts.",
          },
        },
        {
          id: 2,
          img: "closed.svg",
          text: {
            title: "Closed Event",
            description: "Only event users can see posts",
          },
        },
        {
          id: 3,
          img: "secret.svg",
          text: {
            title: "Secrent Event",
            description: "Only invited and event users can find the event",
          },
        },
      ],
      activePrivacy: {},
      privacyPopup: false,
      // eventPopup: false,
      eventTypes: [],
      selectedEvent: null,
      tag: "",
      tags: [],
      imgCover: null,
      gMapData: {},
      cancelEventPopup: false,
    };
  },
  created() {
    this.getGenres();
    this.getVenue();
    this.getETypes();
    this.activePrivacy = this.privacy[0];
  },
  computed: {
    validation() {
      let bool = false;
      const {
        eventName,
        startDate,
        startTime,
        endTime,
        endDate,
        selectedvenue,
        selectedGenres,
        imgCover,
        activePrivacy,
        selectedEvent,
        tags,
        gMapData,
      } = this;
      if (
        eventName &&
        startDate &&
        startTime &&
        endTime &&
        endDate &&
        selectedvenue &&
        selectedGenres.length > 0 &&
        imgCover &&
        activePrivacy.text.title &&
        selectedEvent &&
        tags.length > 0 &&
        gMapData.city &&
        gMapData.country &&
        gMapData.latitude &&
        gMapData.location &&
        gMapData.longitude
      ) {
        bool = true;
      }

      return bool;
    },
  },
  methods: {
    ...mapActions([
      "get_geners",
      "getVenueTypes",
      "getEventTypes",
      "createEvent",
    ]),
    async getGenres() {
      try {
        const { status, information } = await this.get_geners();
        this.genres =
          information && information.length
            ? information.map((genre) => {
                return {
                  id: genre.id,
                  name: genre.name,
                };
              })
            : [];
      } catch (error) {
        console.error(error);
      }
    },
    async getVenue() {
      try {
        const { status, information } = await this.getVenueTypes();
        this.venueTypes = information;
      } catch (error) {
        console.error(error);
      }
    },
    async getETypes() {
      try {
        const { status, information } = await this.getEventTypes();
        this.eventTypes = information;
      } catch (error) {
        console.error(error);
      }
    },
    async newEvent() {
      let formData = new FormData();
      formData.append("file", this.imgCover);
      let privacy = this.activePrivacy.text.title
        .split(" ")
        .join("_")
        .toLowerCase();
      let tagsList = this.tags.map((elem) => elem.text);
      let genresList = this.selectedGenres.map((elem) => elem.id);
      const obj = {
        name: this.eventName,
        ...this.gMapData,
        start_date: this.startDate,
        start_time: this.startTime ? this.startTime.split(/(\s+)/)[0] : "",
        end_date: this.endDate,
        end_time: this.endTime ? this.endTime.split(/(\s+)/)[0] : "",
        genre: genresList,
        venue_type: this.selectedvenue ? this.selectedvenue.id : "",
        about_event: this.editorData,
        artwork: formData,
        privacy: privacy,
        event_type: this.selectedEvent ? this.selectedEvent.id : "",
        tags_list: tagsList,
        page_id: "22",
      };
      try {
        if (this.validation) await this.createEvent(obj);
      } catch (error) {
        console.error(error);
      }
    },
    getLocation(data) {
      this.gMapData = data;
    },
    selectValue() {
      this.show = false;
    },
    isMoment(date) {
      return moment(date).startOf("day").format("MMM Do,YYYY");
    },
    handleGenres(genre) {
      if (this.selectedGenres.some((elem) => elem.id === genre.id)) {
        this.genresPopup = false;
        return;
      } else {
        this.selectedGenres.push(genre);
      }
      this.genresPopup = false;
    },
    handleVenue(venue) {
      this.selectedvenue = venue;
      this.venuePopup = false;
    },
    handleCover(e) {
      const file = e.target.files[0];
      this.imgCover = this.$refs.cover.files[0];

      this.cover = URL.createObjectURL(file);
    },
    handlePrivacy(data) {
      this.activePrivacy = data;
    },
    handleEvent(event) {
      this.selectedEvent = event;
    },
    removeGenre(id) {
      this.selectedGenres = this.selectedGenres.filter(
        (elem) => elem.id !== id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.genres-select-wraper {
  display: flex;
  flex-wrap: wrap;
}
.mb-17 {
  margin-bottom: 17px;
}
svg {
  transition: all 0.3s;
}
.active-privacy {
  opacity: 1 !important;
  .item-radio {
    span {
      opacity: 1 !important;
    }
  }
}
.privacy-shadow {
  box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
}
.custom-border {
  border-radius: 4px 4px 0 0 !important;
}
.arrow-down {
  transform: rotate(-180deg) !important;
}
.newEvent-editor {
  border-radius: 4px !important;
  border: 1px solid #e6eaf0 !important;
}
.w-140 {
  width: 140px !important;
}
.date-picker-container {
  border-radius: 4px;
  border: 1px solid #e6eaf0;
  width: 190px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  p {
    color: #47505b;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueNormal;
  }
}

.flex {
  display: flex;
}
.new-event {
  margin-top: 130px;
  // position: relative;
  .cancel-popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
  }
  label {
    margin-bottom: 0 !important;
  }
  h4 {
    color: #47505b;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
  }
  p {
    margin-bottom: 0;
  }
  &-nav {
    width: 100%;
    height: 70px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 37px;
    .go-back {
      display: flex;
      align-items: center;
      border-right: solid 1px #e6eaf0;
      height: 100%;
      padding-right: 40px;
      cursor: pointer;
      p {
        color: #071526;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        margin-left: 13px;
        text-transform: uppercase;
      }
    }
    .new-event-title {
      padding-left: 30px;
      color: #47505b;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueBold;
    }
  }
  &-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .left {
      background-color: #fff;
      width: 770px;
      max-width: 100%;
      padding: 30px;
      &-title {
        display: flex;
        justify-content: space-between;
        p {
          color: #47505b;
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueBold;
          margin-bottom: 7px;
        }
        input {
          width: 340px;
          max-width: 100%;
          border: 1px solid #e6eaf0;
          border-radius: 4px;
          height: 30px;
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueBold;
          padding-left: 10px;
        }
        input::placeholder {
          color: rgba(7, 21, 38, 0.6);
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueMedium;
        }
      }
      &-date {
        justify-content: space-between;
        margin-top: 27px;
      }
      &-select {
        justify-content: space-between;
        margin-top: 27px;
        &-co {
          border: 1px solid #e6eaf0;
          border-radius: 4px;
          width: 340px;
          max-width: 100%;
          min-height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 13px 0 10px;
          cursor: pointer;
          position: relative;
          span,
          p {
            color: rgba(7, 21, 38, 0.6);
            font-size: 13px;
            line-height: 16px;
            font-family: HelveticaNeueMedium;
          }
          span {
            background: #e6eaf0;
            border-radius: 2px;
            min-width: 80px;
            height: 20px;
            padding: 0 7px 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 10px;
          }
          .genres-popup {
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
            border-radius: 4px;
            width: 100%;
            position: absolute;
            z-index: 999;
            padding: 10px 0;
            left: 0;
            top: 0;
            .ps {
              height: 200px;
            }
            .item {
              color: #071526;
              font-size: 13px;
              line-height: 16px;
              font-family: HelveticaNeueNormal;
              padding: 7px 20px;
              transition: all 0.3s;
              &:hover {
                background: #D1433A1A;
                color: #D1433A;
              }
            }
          }
        }
      }
      &-editor {
        margin-top: 27px;
      }
      &-actions {
        display: flex;
        margin-top: 30px;
        button {
          height: 40px;
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueBold;
          border-radius: 4px;
          text-transform: uppercase;
        }
        .create {
          background: #D1433A;
          width: 200px;
          max-width: 100%;
          color: #fff;
          border: none;
          margin-right: 20px;
        }
        .cancel {
          width: 120px;
          max-width: 100%;
          border: 1.3px solid #8b949f;
          background: #ffffff;
          color: #8b949f;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .right {
      width: 370px;
      &-not {
        background: #e6eaf0;
        border-radius: 4px;
        display: flex;
        padding: 17px 30px 14px;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
        h3 {
          color: #071526;
          font-size: 13px;
          line-height: 16px;
          font-family: HelveticaNeueBold;
          margin-bottom: 3px;
        }
        p {
          color: rgba(7, 21, 38, 0.6);
          font-size: 11px !important;
          line-height: 20px;
          font-family: HelveticaNeueNormal;
        }
      }
      &-cover {
        background: #ffffff;
        border-radius: 4px;
        margin-top: 30px;
        padding: 30px;
        .upload {
          position: relative;
          width: 100%;
          height: 150px;
          background: #f7f7f7;
          border-radius: 2px;
          cursor: pointer;
          input {
            width: 100%;
            height: 100%;
            opacity: 0;
          }
          svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
          }
        }
      }
      &-privacy {
        background: #ffffff;
        border-radius: 4px;
        min-height: 270px;
        width: 100%;
        padding: 30px;
        margin-top: 30px;
        .ps {
          height: 100px !important;
        }
        .privacy {
          border: 1px solid #e6eaf0;
          border-radius: 4px;
          width: 100%;
          height: 50px;
          padding: 0 14px 0 12px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          margin-bottom: 17px;
          &-left {
            margin-right: 16px;
          }
          &-middle {
            h3 {
              color: #071526;
              font-size: 13px;
              line-height: 16px;
              font-family: HelveticaNeueBold;
              margin-bottom: 6px;
            }
            p {
              color: rgba(7, 21, 38, 0.6);
              font-size: 11px;
              line-height: 13px;
              font-family: HelveticaNeueNormal;
            }
          }
          &-right {
            margin-left: auto;
          }
          &-popup {
            position: absolute;
            z-index: 999;
            top: 50px;
            left: -1px;
            border-radius: 0 0 4px 4px;
            border: 1px solid #e6eaf0;
            border-top: none;
            background: #fff;
            width: calc(100% + 2px);
            box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
            .item + .item {
              border-top: 1px solid #e6eaf0;
            }
            .item {
              display: flex;
              align-items: center;
              cursor: pointer;
              padding: 0 14px 0 12px;
              height: 71px;
              opacity: 0.6;
              .privacy-left {
                margin-right: 16px;
              }
              h3 {
                color: #071526;
                font-size: 13px;
                line-height: 16px;
                font-family: HelveticaNeueBold;
                margin-bottom: 6px;
              }
              p {
                color: rgba(7, 21, 38, 0.6);
                font-size: 11px;
                line-height: 13px;
                font-family: HelveticaNeueNormal;
              }
              &-radio {
                border: 1px solid #8b949f;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  display: block;
                  width: 10px;
                  height: 10px;
                  background: #D1433A;
                  opacity: 0;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

// date picker custom styles

<style lang="scss">
.new-event-container {
  // date picker
  .mx-calendar-header-label {
    color: #D1433A;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    font-family: HelveticaNeueBold;
  }
  .mx-btn-icon-left,
  .mx-btn-icon-right {
    border-radius: 4px !important;
  }
  .mx-table-date td,
  .mx-table-date th {
    border-radius: 50%;
  }
  .vs__actions {
    padding: 4px 0 0 3px !important;
  }
  .mx-datepicker {
    opacity: 0;
  }
  // tags
  .ti-input {
    border-radius: 4px;
    border: 1px solid #e6eaf0 !important;
    min-height: 30px;
    padding: 0 !important;
    padding-left: 10px !important;
  }
  .ti-tags {
    align-items: center;
  }
  .ti-tag {
    background: #e6eaf0 !important;
    border-radius: 2px !important;
    color: rgba(7, 21, 38, 0.6) !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-family: HelveticaNeueMedium !important;
    height: 20px;
    padding: 0 10px !important;
  }
  .ti-new-tag-input-wrapper {
    color: rgba(7, 21, 38, 0.6) !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-family: HelveticaNeueMedium !important;
    padding: 0 !important;
  }
}

// gmap
.left-title {
  .pac-target-input {
    width: 340px;
    max-width: 100%;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    height: 30px;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
    padding-left: 10px;
    &::placeholder {
      color: rgba(7, 21, 38, 0.6);
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
    }
  }
}
</style>
