<template>
  <div :class="['card genres-list pg-genres-list-1 type-list', (dropDown) ? 'open':'']" style="width: 1170px">
    <div class="row no-gutters">
      <div class="col-12 line-1">
        <div class="cover "><img width="60px" :src="song.artwork_url" alt="Generic placeholder image"></div>
        <div class="play-button js-play-button new-play-button" @click=" playPause($event, [song], index)">
          <div class="svg-icon">
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="play-type-1">
              <path class="c-fill-1 c-line-1"
                    d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    fill="white"></path>
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                    stroke-linecap="round"></path>
              <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                    stroke-linecap="round"></path>
            </svg>
          </div>
        </div>
        <div class="type-music">
          <div class="svg-icon">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="14.25" stroke="#47505B" stroke-width="1.5"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.4439 12.4618L20.6872 11.4132C21.3897 11.2613 22.0042 10.8392 22.3981 10.2382C22.7921 9.63711 22.9339 8.90525 22.7929 8.20056C22.652 7.49586 22.2395 6.87486 21.6447 6.4716C21.0498 6.06835 20.3203 5.91518 19.6135 6.04516L15.8264 6.80258C15.1936 6.92927 14.6244 7.27125 14.2153 7.77034C13.8063 8.26943 13.5829 8.89481 13.5829 9.54008V17.0547C12.8734 16.6451 12.0485 16.481 11.2363 16.5879C10.424 16.6948 9.66965 17.0668 9.09029 17.6461C8.51093 18.2254 8.1389 18.9796 8.03189 19.7919C7.92488 20.6042 8.08888 21.4291 8.49844 22.1387C8.90801 22.8482 9.54026 23.4028 10.2972 23.7165C11.054 24.0301 11.8933 24.0853 12.6847 23.8733C13.4761 23.6614 14.1755 23.1943 14.6744 22.5444C15.1732 21.8945 15.4437 21.0982 15.4439 20.2789V12.4628V12.4618ZM19.9791 7.86985L16.192 8.62727C15.9812 8.66942 15.7914 8.78329 15.655 8.94953C15.5187 9.11578 15.4441 9.32412 15.4439 9.53915V10.5646L20.3225 9.58847C20.5469 9.5393 20.743 9.40399 20.8687 9.21166C20.9944 9.01933 21.0395 8.78535 20.9943 8.56009C20.9492 8.33482 20.8175 8.13628 20.6274 8.00719C20.4374 7.8781 20.2042 7.82878 19.9782 7.86985H19.9791ZM13.5829 20.2779C13.5829 19.7844 13.3869 19.311 13.0379 18.962C12.6889 18.613 12.2155 18.417 11.722 18.417C11.2284 18.417 10.7551 18.613 10.4061 18.962C10.0571 19.311 9.86099 19.7844 9.86099 20.2779C9.86099 20.7715 10.0571 21.2449 10.4061 21.5939C10.7551 21.9429 11.2284 22.1389 11.722 22.1389C12.2155 22.1389 12.6889 21.9429 13.0379 21.5939C13.3869 21.2449 13.5829 20.7715 13.5829 20.2779Z"
                    fill="#47505B"/>
            </svg>
          </div>
        </div>
        <div class="waveform ">
          <div class="seek-wrapper">
            <div class="svg-icon">
              <svg width="180" height="25" viewBox="0 0 180 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                <rect x="30.0654" y="17.647" width="2.2549" height="7.35294" fill="#0075FF"/>
                <rect x="3.00635" y="17.647" width="2.25491" height="7.35294" fill="#0075FF"/>
                <rect x="33.0718" y="9.55859" width="2.2549" height="15.4412" fill="#0075FF"/>
                <rect x="27.0586" y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                <rect x="6.01318" y="14.7061" width="2.25489" height="10.2941" fill="#0075FF"/>
                <rect x="18.0391" y="16.1763" width="2.2549" height="8.82353" fill="#0075FF"/>
                <rect x="9.01953" y="11.0293" width="2.2549" height="13.9706" fill="#0075FF"/>
                <rect x="21.0459" y="18.3823" width="2.2549" height="6.61765" fill="#0075FF"/>
                <rect x="12.0259" y="16.9116" width="2.2549" height="8.08823" fill="#0075FF"/>
                <rect x="24.0522" y="13.2354" width="2.2549" height="11.7647" fill="#0075FF"/>
                <rect x="15.0327" y="12.5" width="2.25489" height="12.5" fill="#0075FF"/>
                <rect x="36.0786" y="22.0586" width="2.25491" height="2.94118" fill="#0075FF"/>
                <rect x="66.1436" width="2.25489" height="25" fill="#0075FF"/>
                <rect x="39.085" y="21.3237" width="2.2549" height="3.67647" fill="#0075FF"/>
                <rect x="69.1504" y="9.55859" width="2.25489" height="15.4412" fill="#0075FF"/>
                <rect x="63.1372" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                <rect x="42.0918" y="7.35303" width="2.2549" height="17.6471" fill="#0075FF"/>
                <rect x="54.1177" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                <rect x="45.0981" y="15.4414" width="2.25491" height="9.55882" fill="#0075FF"/>
                <rect x="57.124" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                <rect x="48.1045" y="20.5884" width="2.2549" height="4.41176" fill="#0075FF"/>
                <rect x="60.1309" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                <rect x="51.1113" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                <rect x="72.1567" width="2.25489" height="25" fill="#0075FF"/>
                <rect x="102.223" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="75.1631" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                <rect x="105.229" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                <rect x="99.2153" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                <rect x="78.1699" y="8.08838" width="2.25487" height="16.9118" fill="#0075FF"/>
                <rect x="90.1958" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                <rect x="81.1763" y="11.0293" width="2.25489" height="13.9706" fill="#0075FF"/>
                <rect x="93.2026" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                <rect x="84.1831" y="10.2939" width="2.25491" height="14.7059" fill="#0075FF"/>
                <rect x="96.209" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                <rect x="87.1895" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                <rect x="108.235" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="138.301" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="111.242" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                <rect x="141.307" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                <rect x="135.294" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                <rect x="114.249" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                <rect x="126.274" y="8.08838" width="2.25491" height="16.9118" fill="#C4CCD6"/>
                <rect x="117.255" y="11.0293" width="2.25491" height="13.9706" fill="#C4CCD6"/>
                <rect x="129.281" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                <rect x="120.262" y="10.2939" width="2.25491" height="14.7059" fill="#C4CCD6"/>
                <rect x="132.288" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                <rect x="123.268" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                <rect x="144.313" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="174.379" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="147.32" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                <rect x="177.386" y="9.55859" width="2.25491" height="15.4412" fill="#C4CCD6"/>
                <rect x="171.373" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                <rect x="150.327" y="8.08838" width="2.25487" height="16.9118" fill="#C4CCD6"/>
                <rect x="162.353" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                <rect x="153.333" y="11.0293" width="2.25489" height="13.9706" fill="#C4CCD6"/>
                <rect x="165.359" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                <rect x="156.34" y="10.2939" width="2.25489" height="14.7059" fill="#C4CCD6"/>
                <rect x="168.366" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                <rect x="159.346" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                <rect y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                <rect x="30.0654" y="17.647" width="2.2549" height="7.35294" fill="#0075FF"/>
                <rect x="3.00635" y="17.647" width="2.25491" height="7.35294" fill="#0075FF"/>
                <rect x="33.0718" y="9.55859" width="2.2549" height="15.4412" fill="#0075FF"/>
                <rect x="27.0586" y="19.853" width="2.25491" height="5.14706" fill="#0075FF"/>
                <rect x="6.01318" y="14.7061" width="2.25489" height="10.2941" fill="#0075FF"/>
                <rect x="18.0391" y="16.1763" width="2.2549" height="8.82353" fill="#0075FF"/>
                <rect x="9.01953" y="11.0293" width="2.2549" height="13.9706" fill="#0075FF"/>
                <rect x="21.0459" y="18.3823" width="2.2549" height="6.61765" fill="#0075FF"/>
                <rect x="12.0259" y="16.9116" width="2.2549" height="8.08823" fill="#0075FF"/>
                <rect x="24.0522" y="13.2354" width="2.2549" height="11.7647" fill="#0075FF"/>
                <rect x="15.0327" y="12.5" width="2.25489" height="12.5" fill="#0075FF"/>
                <rect x="36.0786" y="22.0586" width="2.25491" height="2.94118" fill="#0075FF"/>
                <rect x="66.1436" width="2.25489" height="25" fill="#0075FF"/>
                <rect x="39.085" y="21.3237" width="2.2549" height="3.67647" fill="#0075FF"/>
                <rect x="69.1504" y="9.55859" width="2.25489" height="15.4412" fill="#0075FF"/>
                <rect x="63.1372" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                <rect x="42.0918" y="7.35303" width="2.2549" height="17.6471" fill="#0075FF"/>
                <rect x="54.1177" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                <rect x="45.0981" y="15.4414" width="2.25491" height="9.55882" fill="#0075FF"/>
                <rect x="57.124" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                <rect x="48.1045" y="20.5884" width="2.2549" height="4.41176" fill="#0075FF"/>
                <rect x="60.1309" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                <rect x="51.1113" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                <rect x="72.1567" width="2.25489" height="25" fill="#0075FF"/>
                <rect x="102.223" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="75.1631" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                <rect x="105.229" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                <rect x="99.2153" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"/>
                <rect x="78.1699" y="8.08838" width="2.25487" height="16.9118" fill="#0075FF"/>
                <rect x="90.1958" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"/>
                <rect x="81.1763" y="11.0293" width="2.25489" height="13.9706" fill="#0075FF"/>
                <rect x="93.2026" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"/>
                <rect x="84.1831" y="10.2939" width="2.25491" height="14.7059" fill="#0075FF"/>
                <rect x="96.209" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"/>
                <rect x="87.1895" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"/>
                <rect x="108.235" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="138.301" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="111.242" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                <rect x="141.307" y="9.55859" width="2.25489" height="15.4412" fill="#C4CCD6"/>
                <rect x="135.294" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                <rect x="114.249" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                <rect x="126.274" y="8.08838" width="2.25491" height="16.9118" fill="#C4CCD6"/>
                <rect x="117.255" y="11.0293" width="2.25491" height="13.9706" fill="#C4CCD6"/>
                <rect x="129.281" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                <rect x="120.262" y="10.2939" width="2.25491" height="14.7059" fill="#C4CCD6"/>
                <rect x="132.288" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                <rect x="123.268" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                <rect x="144.313" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="174.379" width="2.25491" height="25" fill="#C4CCD6"/>
                <rect x="147.32" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
                <rect x="177.386" y="9.55859" width="2.25491" height="15.4412" fill="#C4CCD6"/>
                <rect x="171.373" y="4.41162" width="2.25491" height="20.5882" fill="#C4CCD6"/>
                <rect x="150.327" y="8.08838" width="2.25487" height="16.9118" fill="#C4CCD6"/>
                <rect x="162.353" y="8.08838" width="2.25489" height="16.9118" fill="#C4CCD6"/>
                <rect x="153.333" y="11.0293" width="2.25489" height="13.9706" fill="#C4CCD6"/>
                <rect x="165.359" y="18.3823" width="2.25491" height="6.61765" fill="#C4CCD6"/>
                <rect x="156.34" y="10.2939" width="2.25489" height="14.7059" fill="#C4CCD6"/>
                <rect x="168.366" y="13.2354" width="2.25489" height="11.7647" fill="#C4CCD6"/>
                <rect x="159.346" y="4.41162" width="2.25489" height="20.5882" fill="#C4CCD6"/>
              </svg>

            </div>
          </div>
        </div>
        <div class="description">
          <div class="artist"><a
              href="#">{{ song.user_firstname ? song.user_firstname + ' ' + song.user_lastname : '' }}</a></div>
          <div class="track"><a href="#">{{ song.title ? song.title : '' }}</a></div>
        </div>
        <div class="album">
          <div class="album">NA</div>
        </div>
        <div class="time">
          0:00
        </div>
        <div class="date">
          2 days ago
        </div>
        <div class="popularity">
          <div class="empty">
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
          </div>
          <div class="full">
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
            <div class="column"></div>
          </div>
          <div class="count">
            000K
          </div>
        </div>
        <div class="other d-flex ">
          <div class="actions d-flex align-items-center">
            <div class="wishlist js-wishlist">
              <div class="svg-icon ">

                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="heart-acti"
                        d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                        stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </div>
            <div class="btn btn-icon-border text shop-cart" :style="{'visibility': isStreaming? 'hidden':'visible'}">
              <div class="svg-icon">
                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="svg-shop">
                  <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="white"
                        stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="c-line-1"
                        d="M9.97559 3.76123V5.04023C9.90323 5.5065 9.653 5.9266 9.27745 6.21227C8.9019 6.49794 8.43026 6.62696 7.96159 6.57223C7.49292 6.62696 7.02127 6.49794 6.64572 6.21227C6.27017 5.9266 6.01994 5.5065 5.94759 5.04023V3.76123"
                        stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <div>$00</div>
            </div>
            <div class="more1">
              <div class="dropdown vertical more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false" data-display="static">
                <div class="svg-icon more-position">
                  <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="more-svg-ic"
                          d="M0.0410156 2.52051C0.0410155 3.38345 0.740571 4.08301 1.60352 4.08301C2.46646 4.08301 3.16602 3.38345 3.16602 2.52051C3.16602 1.65756 2.46646 0.958008 1.60352 0.958008C0.740571 0.958008 0.0410156 1.65756 0.0410156 2.52051Z"
                          fill="#47505B"/>
                    <path class="more-svg-ic"
                          d="M0.0410156 8.56226C0.0410155 9.4252 0.740571 10.1248 1.60352 10.1248C2.46646 10.1248 3.16602 9.4252 3.16602 8.56226C3.16602 7.69931 2.46646 6.99976 1.60352 6.99976C0.740571 6.99976 0.0410156 7.69931 0.0410156 8.56226Z"
                          fill="#47505B"/>
                    <path class="more-svg-ic"
                          d="M0.0410156 14.6038C0.0410155 15.4667 0.740571 16.1663 1.60352 16.1663C2.46646 16.1663 3.16602 15.4667 3.16602 14.6038C3.16602 13.7408 2.46646 13.0413 1.60352 13.0413C0.740571 13.0413 0.0410156 13.7408 0.0410156 14.6038Z"
                          fill="#47505B"/>
                  </svg>
                </div>
              </div>
            </div>
            <div class="extend js-extend" v-on:click="showMore()">
              <div class="svg-icon">
                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="svg-arrow-down">
                  <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters line-2 d-flex align-items-center justify-content-end">
      <div class="">
        <div class="btn-group-social left-button" style="    float: left;
    display: inline-flex;
    left: 30.6%;
    position: relative;">
          <button class="btn btn-information text">
            <div class="svg-icon">

              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1"
                      d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                      stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <span>000k</span>
          </button>
          <button class="btn btn-information text">
            <div class="svg-icon">
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1"
                      d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                      stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <span>000k</span>
          </button>
          <button class="btn btn-information text">
            <div class="svg-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1" d="M1 9.16667L1 3.33306L15 3.33306L10.9989 1" stroke="#071526"
                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path class="c-line-1" d="M15 8L15 12.9548L1 12.9548L5.81381 15" stroke="#071526" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
            <span>000k</span>
          </button>
          <button class="btn btn-information">
            <div class="svg-icon">
              <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1"
                      d="M1 12.4358C1 12.4358 2.34885 6.17675 10.4363 5.97632C10.5603 4.31967 10.5603 2.65665 10.4363 1L18 7.83691L10.4363 15V9.74725C10.4363 9.74725 6.00177 8.4783 1 12.4358Z"
                      stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>
          </button>
        </div>
        <div class="btn-group-social justify-content-end btn-group-social-2 pg-listening-card"     style="float: right;">
          <span class="btn btn-icon-border text m-r-22" v-if="!isStreaming" style="float: left">
            <div class="svg-icon">
              <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="shop-cart-v2">
                <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="#D1433A"
                      stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                <path class="c-line-1"
                      d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                      stroke="#D1433A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            <div>${{ song.price }}</div>
          </span>
          <span class="btn btn-play-count new-pos pg-btn-play-count">
            <div class="svg-icon">
              <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="small-play-svg">
                <path class="c-fill-1"
                      d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                      fill="#8B949F"></path>
              </svg>
            </div>
            <span>32.2k</span>
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "songOrAlbumHorizontalCard",
  data() {
    return {
      dropDown:false,
      song: {
        access: null,
        administrator: null,
        album: {id: 39, title: "Rare by Selena Gomez", primary_artist: "6", feature_artist: "5"},
        all_rights_reserved: null,
        allow_comments: 1,
        allow_download: false,
        allow_high_quality_download: false,
        approved: 1,
        artists: null,
        artwork: null,
        artwork_url: "http://auddiodev-api.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-api.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        basic_user: null,
        cleared_for_not_sale_countries: null,
        cleared_for_sale_countries: null,
        collectors: 0,
        comment_count: 0,
        composer: null,
        copyright: null,
        create_isrc: null,
        created_at: "2021-11-16T07:48:08.000000Z",
        creative_common: null,
        description: null,
        display_comment: null,
        display_embed_code: null,
        display_stats: null,
        download_album_pricing: null,
        download_count: 0,
        download_track_pricing: null,
        duration: 123,
        enable_app_playback: null,
        enable_comment: null,
        enable_direct_download: null,
        enable_offline_listening: null,
        explicit: 0,
        explicit_content: null,
        favorite: true,
        feature_artist: null,
        flac: 0,
        frontend_url: "null",
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        isrc: null,
        last_listen: "2022-01-31T12:28:32.000000Z",
        let_fan_play_album: 0,
        let_fan_play_track: 0,
        library: false,
        license_id: null,
        loves: 10,
        metadata_language: null,
        moderator: null,
        mood: [],
        mp3: 1,
        path: "http://auddiodev-backend.theplayground.co.uk/audio/B0A6GOPyneIaS2WJXG2B1K5J7CDXyHfH",
        pending: 0,
        plays: 139,
        preorder_info: null,
        preview: 0,
        price: null,
        pricing_type: null,
        primary_artist: null,
        privacy: null,
        producer: null,
        publisher: null,
        reacted: null,
        reaction_count: 0,
        reactions: [],
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        stream_url: null,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        type: "song",
        updated_at: "2022-01-31T12:28:32.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0
      }
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isStreaming: {
      type: Boolean,
      default: false
    },
  },
  methods:{
    showMore(){
      this.dropDown = !this.dropDown
    }
  }
}
</script>

<style scoped>
.pg-genres-list-1 .cover{
  width: 8.56% !important;
}
.pg-genres-list-1 .type-music{
  width: 4.3% !important;
}
.pg-genres-list-1 .waveform {
  width: 18.6% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.pg-genres-list-1 .description{
  width: 11.32% !important;
  padding-left: 0px ;
}
.pg-genres-list-1 .album{
  width: 14.7% !important;
  margin-right: 0px !important;
}
.pg-genres-list-1 .time{
  width: 8.56% !important;
}
.pg-genres-list-1 .date{
  width: 8.56% !important;
}
.pg-genres-list-1 .popularity{
  width: 6.6% !important;
}
.pg-listening-card{
  right: 22px;
  bottom: 15px;
}
.pg-btn-play-count{
  position: relative;
  right: -4px;
  top: 3px;
  float: left;
}

</style>
