<template>
  <div class="w-370">
    <div class="right_part">
      <div
        class="create-event-btn"
        v-if="!clearAddNewEventBtn.includes($route.path)"
      >
        <button @click="createEventsModal = true">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
              fill="#D1433A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025V5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025V5.50025Z"
              fill="#D1433A"
            />
          </svg>

          ADD New EVENT
        </button>
      </div>
      <div
        class="create-event-btn"
        v-if="$route.path.includes('/event-manager/page/ads')"
      >
        <button>
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
              fill="#D1433A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025V5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025V5.50025Z"
              fill="#D1433A"
            />
          </svg>
          NEW ADVERTISING
        </button>
      </div>
    </div>
    <div v-if="createEventsModal" class="create-events-wrapper">
      <event-post-container @closeEventModal="createEventsModal = false" />
    </div>
    <section v-if="$route.path.includes('/event-manager/page/dashboard')">
      <DashboardRightSideBar />
    </section>
    <section v-if="seeSearch.includes($route.path)">
      <OrdTickEvRightSideBar />
    </section>
    <section v-if="$route.path.includes('/event-manager/page/ads')">
      <AdsRightSideBar :adsData="adsData" />
    </section>
  </div>
</template>

<script>
import EventPostContainer from "../../components/CreateEvents/EventPostContainer.vue";

import AdsRightSideBar from "./AdsRightSideBar.vue";
import OrdTickEvRightSideBar from "./OrdTickEvRightSideBar.vue";
import DashboardRightSideBar from "./DashboardRightSideBar.vue";
export default {
  components: {
    EventPostContainer,
    AdsRightSideBar,
    OrdTickEvRightSideBar,
    DashboardRightSideBar,
  },
  props: ["adsData"],
  data() {
    return {
      clearAddNewEventBtn: [
        "/event-manager/page/tickets",
        "/event-manager/page/orders",
        "/event-manager/page/ads",
        '/event-manager/page/orders/detail'
      ],
      seeSearch: [
        "/event-manager/page/events",
        "/event-manager/page/tickets",
        "/event-manager/page/orders",
        "/event-manager/page/orders/detail",
      ],
      createEventsModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>