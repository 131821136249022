import moment from "moment-timezone";
import { followOrUnFollowPage, set_favourite_on_songs } from "../apis/APIs";
import createFormData from "./common";

export default {
  data() {
    return {
      msg: "Hello World",
      song_status: false,
      songs_push_status: 0,
      songs_array: [],
    };
  },
  computed: {
    userId() {
      return this.$store.getters.user_map_to_props.user_data
        ? this.$store.getters.user_map_to_props.user_data.id
        : "";
    },
    currentUserName() {
      return this.$store.getters.user_map_to_props.user_data.username;
    },
    pageUserName() {
      return this.$store.getters.getCurrentPage.user.username;
    },
    getSongStatus() {
      return this.$store.getters.getSongsStatus;
    },
    getCurrentPlayId() {
      return this.$store.getters.getCurrentPlayId;
    },
    getCurrentPage() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    getArtist(artist) {
      return artist && artist.title ? artist.title : "Unknown";
    },
    PlayIconStatus(
      item,
      currentPlayer,
      currentPlayId,
      songStatus,
      currentPlayTypeID
    ) {
      if (item) {
        if (item.type === "song" && currentPlayTypeID === null) {
          return currentPlayer === "footerPlayer" &&
            currentPlayId === item.id &&
            songStatus === true
            ? "active"
            : "";
        } else if (item.type === "album" && item.id === currentPlayTypeID) {
          if (item.album_songs.length > 0) {
            let albumSongs = item.album_songs;
            let track = albumSongs.filter(
              (track) => track.id === currentPlayId
            )[0];
            if (track && songStatus === true) {
              return "active";
            } else {
              return "";
            }
          }
        }
      }
    },
    playPause(e, songs, player, playTypeId, activity) {
      e.stopPropagation();
      this.$store.commit("SET_CURRENT_PLAY_ACTIVITY_ID", activity);

      // e.currentTarget.classList.toggle('active');
      // this.song_status = !this.song_status;
      if (this.getCurrentPlayId === songs[0].id) {
        this.song_status = this.getSongStatus === true ? false : true;
      } else {
        this.song_status = true;
        // if(this.getSongStatus === false || this.getSongStatus === null){
        //     this.song_status = true;
        // }else if(this.getSongStatus === true || this.getSongStatus === null){
        //     this.song_status = false;
        // }
      }
      this.songs_array = songs;
      this.$store.commit("Empty_Play_All_Songs_Array");
      this.$store.commit("Set_Continue_Listening_Song", {
        data: this.songs_array,
        status: this.song_status,
      });
      this.$store.commit("Set_Song_status", this.song_status);
      this.$store.commit("SET_CURRENT_PLAYER", player);
      this.$store.commit("SET_CURRENT_PLAY_TYPE_ID", playTypeId);
    },
    getImage(song) {
      return song.artwork_url
        ? song.artwork_url
        : require(`@/assets/img/photo-1.png`);
    },
    getAlbum(song) {
      return song.album ? song.album.title : "NA";
    },
    getTimeInMin(song) {
      if (song.duration) {
        return moment.utc(song.duration * 1000).format("mm:ss");
      }
      return "0:00";
    },
    convertDateIntoHumanReadableForm(date) {
      return moment(date).fromNow();
    },
    convertAddFiveHourDateIntoHumanReadableForm(date) {
      return moment(date)
        .add(5, "hours")
        .fromNow();
    },
    convertDateIntoHHMMFormat(date) {
      return moment(date).format("HH:mm");
    },
    convertDateIntoAMPMformat(date) {
      return moment(date).format("hh:mm A");
    },
    convertDateSeenformat(date) {
      return moment(date).format("MMMM D, YYYY hh:mm A");
    },
    convertDateIntoDashesDate(date) {
      return moment(date).format("mm.dd.yyyy");
    },
    convertTimeZoneDate(zone) {
      return moment()
        .tz(zone)
        .format("hh:mm a z");
    },
    getMonthFromDate(date) {
      return moment(date).format("MM");
    },
    getDayFromDate(date) {
      return moment(date).format("D");
    },
    getPublishData(song) {
      if (song.created_at) {
        let date = moment(song.created_at).format();
        let today = moment();
        let min = today.diff(date, "minutes");
        return moment.duration(min, "minutes").humanize();
      }
      return "Unknown";
    },
    getPrice(song) {
      if (song.type === "album") {
        return song.download_album_pricing
          ? Number(song.download_album_pricing).toFixed(2)
          : "0.00";
      }
      return song.download_track_pricing
        ? Number(song.download_track_pricing).toFixed(2)
        : "0.00";
    },
    getCount(song, field) {
      if (song[field]) {
        return Math.trunc(song[field] / 1000) > 0
          ? Math.trunc(song[field] / 1000) + " k"
          : song[field];
      }
      return 0;
    },
    redirectToChannel(
      type,
      channelAltName,
      title,
      country,
      itemType = "singles"
    ) {
      let from = this.$route.name.replace("music.", "");
      if (type === "album" || type === "song" || type === "all") {
        this.$router.push({
          path: `/music/page/song-album-channel/${channelAltName}`,
          query: { fromPage: from, title: title },
        });
      } else if (type === "artist" || type === "page" || type === "label") {
        this.$router.push({
          path: `/music/page/artist-channel/${channelAltName}`,
          query: { fromPage: from, title: title },
        });
      } else if (type === "country") {
        this.$router.push({
          path: `/music/page/chart-top-selling/${channelAltName}`,
          query: {
            fromPage: from,
            title: title,
            country: country,
            type: itemType,
          },
        });
      } else if (type === "podcast") {
        this.$router.push({
          path: `/music/page/artist-channel/${channelAltName}`,
          query: { fromPage: from, title: title },
        });
      } else {
        this.$router.push("/home/page/pages");
        window.scrollTo(0, 0);
      }
    },
    redirectToFullViewPage: function(e, type, id) {
      e.stopPropagation();
      this.$router.push("/music/page/" + type + "/" + id);
      window.scrollTo(0, 0);
    },
    redirectWidgetsToFullViewPage: function(e, type, id) {
      e.stopPropagation();
      this.$router.push("/");
      this.$router.push("/music/page/" + type + "/" + id);
    },
    redirectWidgetsToProfilePage: function(e, user_name) {
      e.stopPropagation();
      this.$router.push("/home/page/user-profile/" + user_name);
    },
    redirectWidgetsToArtistPage: function(e, page_id, pageType = "") {
      e.stopPropagation();
      this.$router.push(`/page-manager/page/${pageType}/` + page_id);
      if (pageType === "artist") {
        this.$router.push(`/page-manager/page/${pageType}/` + page_id);
      }
    },
    redirectToIndividualComponent: function(verb, id, notification_id) {
      this.notificationAsRead(notification_id);
      if (
        verb === "repost_post" ||
        verb === "share_post" ||
        verb === "post_comment" ||
        verb === "reaction" ||
        verb === "reply" ||
        verb === "post_created"
      ) {
        this.$router.push("/home/page/post-detail/" + id);
      } else if (
        verb === "page_follow" ||
        verb === "page_created" ||
        verb === "page_post_created"
      ) {
        this.$router.push("/page-manager/page/" + id);
      }
    },
    redirectToCreatNewArticlePage: function() {
      this.$store.commit("SET_PREVIOUS_ROUTE", this.$route.name);
      this.$router.push("/home/page/create-new-article");
    },
    convertSecondsIntoMinuets(duration) {
      return moment.utc(duration * 1000).format("mm:ss");
    },
    async setFavourite(e, song, type, favorite) {
      e.stopPropagation();
      let song_id = null;
      let objectType = null;
      if (type && type === "review") {
        song_id = song.reviewable_id;
        objectType = song.reviewable.type;
      } else {
        song_id = song.id;
        objectType = song.type;
      }
      let action = "";
      if (favorite !== null) {
        action = favorite;
      } else {
        action = song.favorite;
      }
      let payload = {
        id: song_id,
        object_type: objectType,
        action: action === true ? 0 : 1,
      };
      const formData = createFormData(payload);
      try {
        const { data } = await set_favourite_on_songs(formData);
        await this.$store.dispatch("loading", false);
        const { success, message } = data;
        if (data.success === 1) {
          return { success, message };
        }
      } catch (e) {
        console.error(e.message);
      }
    },
    async followOrUnfollowPage(page, action) {
      try {
        let payload = {
          user_id: this.userId,
          page_id: page.id,
          type: page.type === "artist" ? "Page" : "Page",
          action: action === "approved" ? 0 : 1,
        };
        const formData = createFormData(payload);
        const { data } = await followOrUnFollowPage(formData);
        const { success, message } = data || {};
        if (data.success === 1) {
          return { success, message };
        }
      } catch (e) {
        console.error(e.message);
      }
    },
    redirectToFavorite(type) {
      this.$store.commit("SET_PREVIOUS_ROUTE", this.$route.name);
      if (type === "Posts") {
        this.$router.push(`/home/page/news-feed`);
      } else if (type === "Pages") {
        this.$router.push(`/home/page/pages`);
      } else if (type === "Audio") {
        this.$router.push(`/music/page/Favorites`);
      }
    },
    redirectToProfilePage(username = this.currentUserName) {
      if (this.getCurrentPage && this.getCurrentPage.type) {
        this.$router.push(
          `/page-manager/page/${this.getCurrentPage.type}/` +
            this.$route.params.id
        );
      } else {
        this.$router.push("/home/page/user-profile/" + username);
      }
    },
    redirectToNewsFeedPage() {
      this.$router.replace("/home/page/news-feed");
    },
    redirectToInbox($event, type) {
      this.$store.commit("SET_ACTIVE_FULL_CHAT_TYPE", type);
      this.$router.replace("/chat-manager/chat");
    },
    fourdigitsnumber(num) {
      const yourNumber = num;
      const defaultFill = "0000";

      const formattedNumber = (defaultFill + yourNumber).substr(
        String(yourNumber).length
      );
      return formattedNumber;
    },
  },
};
