<template>
  <div class="user-profile-pic" >
    <div class="profile-image-section">
      <input
        type="file"
        ref="profileInput"
        accept="image/*"
        @change="previewProfile"
        class="d-none"
        id="profilePicUpload"
      />
      <img :src="profileImage"  @click="openProfileModel()"/>
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <!--      <span v-if="edit" class="user-profile-edit-icon" :style="(profileImagePresent) ? '' : 'right: 52%' ">-->
      <!--        <template>-->
      <!--          <div class="h5 mb-0">-->
      <!--            <b-icon class="cursor-pointer mt-1" icon="pencil-square" style="color: #D1433A;" @click="openProfileModel()"></b-icon>-->
      <!--            <b-icon class="cursor-pointer" variant="danger" icon="trash" v-if="profileImagePresent" @click="deleteImage('profile')"></b-icon>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" dropup text="Drop-Up" no-caret>-->
      <!--          <template #button-content>-->
      <!--          </template>-->
      <!--          <b-dropdown-item @click="openProfileModel()">Upload Picture</b-dropdown-item>-->
      <!--          <b-dropdown-divider v-if="profileImagePresent"></b-dropdown-divider>-->
      <!--          <b-dropdown-item v-if="profileImagePresent"-->
      <!--                           @click="deleteImage('profile')">Delete</b-dropdown-item>-->
      <!--        </b-dropdown>-->
      <!--      </span>-->
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <span>

        <svg
        class="pg-position"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#D1433A"
          />
          <path
            d="M14 7L8.5 12.5L6 10"
            stroke="white"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <!-- <span v-if="edit" class="user-profile-model-image">
        <div class="circle" @click="openProfileModel()">
          <img :src="require(`@/assets/icons/profile_model_camera_icon.svg`)" />
        </div>
      </span> -->
    </div>
    <full-page-loader :show="loading" style="z-index: +10" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  DeleteUploadedProfileAndCover,
  uploadProfileAndCover,
} from "../../../../apis/APIs";

export default {
  name: "ProfilePic",
  data() {
    return {
      profilePreview: null,
      image: null,
      loading: false,
    };
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    saveChanges: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    profileImage() {
      if (this.profilePreview) {
        return this.profilePreview;
      }
      if (this.user && this.user.artwork_url) {
        return this.user.artwork_url;
      }
      return require(`@/assets/img/person-11.png`);
    },
    profileImagePresent() {
      if (this.user && this.user.profile_photo) {
        return !!this.user.profile_photo;
      }
      return false;
    },
  },
  methods: {
    previewProfile: function(event) {
      let input = event.target;
      if (input.files) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.profilePreview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    openProfileModel() {
      this.$emit("coverModelOpenProfile");
    },
    async deleteImage(type = "profile") {
      try {
        const result = await this.confirmationToast(
          "warning",
          `Are you sure you want to delete ${
            type !== "profile" ? "Cover " : "Profile"
          } photo!`,
          "You won't be able to revert this!",
          "Yes, delete it!",
          "#3085d6",
          "#d33"
        );
        if (result.isConfirmed) {
          await this.$store.dispatch("loading", true);
          const form = new FormData();
          type !== "profile"
            ? form.append("type", "cover_photo")
            : form.append("type", "profile_photo");
          const { data } = await DeleteUploadedProfileAndCover(form);
          const { success, message, information } = data || {};
          if (success === 1) {
            this.profilePreview = null;
            this.image = null;
            this.modelShow = false;
            await this.$store.dispatch("set_user_data", information);
            await this.$store.dispatch("loading", false);
            this.notificationToast(true, `${type !== "profile" ? "Cover " : "Profile"} Deleted`, `${
              type !== "profile" ? "Cover " : "Profile "
            } Deleted successfully`, 5000, 'success')
            await this.$store.dispatch("loading", false);
          } else {
            this.notificationToast(true, `${type !== "profile" ? "Cover " : "Profile"} Deleted`, message, 5000, 'error')
            await this.$store.dispatch("loading", false);
          }
        }
      } catch (error) {
        await this.$store.dispatch("loading", false);
        console.error(error);
        await this.notificationToast(true, "error", error.message, 5000, 'error');
        this.profilePreview = null;
        this.image = null;
      }
    },
  },
  watch: {
    profilePreview(val) {
      this.$emit("previewEnabled", !!val);
    },
    preview(val) {
      if (!val) {
        this.profilePreview = null;
        this.image = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.user-profile-edit-icon {
  position: absolute;
  bottom: 3px;
  right: 48%;
  height: 20px;
  z-index: +4;
  transform: translate(-50%, 0px);
}
</style>
