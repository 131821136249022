import { render, staticRenderFns } from "./MessageSearchComponent.vue?vue&type=template&id=edf4f120&scoped=true"
import script from "./MessageSearchComponent.vue?vue&type=script&lang=js"
export * from "./MessageSearchComponent.vue?vue&type=script&lang=js"
import style0 from "./MessageSearchComponent.vue?vue&type=style&index=0&id=edf4f120&prod&scoped=true&lang=scss"
import style1 from "./MessageSearchComponent.vue?vue&type=style&index=1&id=edf4f120&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edf4f120",
  null
  
)

export default component.exports