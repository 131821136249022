<template>
  <div class="w-100">
    <MusicSubHeader/>
    <div class="container_xxl">
      <div class="artist_body  mb-5">
        <div class="panel_wrapper">

          <div class="panel_header">
            <div class="panel_title">
              <img :src="require(`@/assets/img/svg/sponsor_gray.svg`)" alt="icon">Subscriptions
            </div>
          </div>
          <div class="panel_body_tabs">
            <ul class="nav   nav_tabs_iconed nav-tab-bg" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#Packages">
                  <div class="svg_icon_tabs">

                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="pkg_ic"
                            d="M14.4 0H1.6C0.712 0 0 0.712 0 1.6V10.4C0 11.288 0.712 12 1.6 12H4.8V16L8 14.4L11.2 16V12H14.4C15.288 12 16 11.288 16 10.4V1.6C16 0.712 15.288 0 14.4 0ZM14.4 10.4H1.6V8.8H14.4V10.4ZM14.4 6.4H1.6V1.6H14.4V6.4Z"
                            fill="#47505B"/>
                    </svg>

                  </div>
                  Plans</a>
              </li>
              <li class="nav-item">
                <a class="nav-link " data-toggle="tab" href="#Sponsors">
                  <div class="svg_icon_tabs">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path
                            d="M10.666 14V12.6667C10.666 11.9594 10.3851 11.2811 9.88497 10.781C9.38487 10.281 8.70659 10 7.99935 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14"
                            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                            d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                            stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M11.334 7.33333L12.6673 8.66667L15.334 6" stroke="#47505B" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="16" height="16" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  Subscribers
                  <span class="event_counting">6</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#Requests">
                  <div class="svg_icon_tabs">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path
                            d="M10.666 14V12.6667C10.666 11.9594 10.3851 11.2811 9.88497 10.781C9.38487 10.281 8.70659 10 7.99935 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14"
                            stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path
                            d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
                            stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M13.334 5.33203V9.33203" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M15.334 7.33203H11.334" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="16" height="16" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  Requests</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div id="Packages" class="tab-pane active">
                <div class="no_sub_plan">
                  <div class="no_sub_plan_icon">
                                      <span>
                                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M47.5 10H12.5C9.73858 10 7.5 12.2386 7.5 15V50C7.5 52.7614 9.73858 55 12.5 55H47.5C50.2614 55 52.5 52.7614 52.5 50V15C52.5 12.2386 50.2614 10 47.5 10Z"
                                            stroke="#47505B" stroke-width="3" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path d="M40 5V15" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                        <path d="M20 5V15" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                        <path d="M7.5 25H52.5" stroke="#47505B" stroke-width="3" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                        </svg>
                                      </span>
                  </div>
                  <div class="no_sub_plan_text">
                    <h1>You don’t have any Subscription Plans set up yet</h1>
                    <p>Create your first Subscription plan now!</p>
                  </div>
                  <div class="no_sub_plan_btn">
                    <button>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M6.99908 0C7.42142 0 7.76379 0.34237 7.76379 0.764705V13.2353C7.76379 13.6576 7.42142 14 6.99908 14C6.57675 14 6.23438 13.6576 6.23438 13.2353V0.764706C6.23438 0.34237 6.57675 0 6.99908 0Z"
                              fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0 7.00006C0 6.57772 0.34237 6.23535 0.764705 6.23535H13.2353C13.6576 6.23535 14 6.57772 14 7.00006C14 7.42239 13.6576 7.76476 13.2353 7.76476H0.764706C0.34237 7.76476 0 7.42239 0 7.00006Z"
                              fill="white"/>
                      </svg>
                      ADD SUBSCRIPTION
                    </button>
                  </div>
                </div>
                <div class="packages_main p-t-20">
                  <div class="Subscrition-headings">
                    <h1>Set up pricing and offer for each plan</h1>
                    <p>You can add up to 3 plans</p>
                  </div>
                  <div class="sponsor_plan m-b-30">
                    <div class="sponsor_plan_left">
                      <div class="sponsor_plan_top">
                        <h5>Light Plan </h5>
                        <h6>$ 5,99 / month</h6>
                      </div>
                      <div class="sponsor_plan_bottom">Free 2 albums and 2 tracks; 2 free livestreaming shows; 10% merch
                        discount; Advance listen of new music.
                      </div>
                    </div>
                    <div class="sponsor_plan_right">
                      <button class="preview_engage">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M8.5 1.50015C8.63132 1.36883 8.78722 1.26466 8.9588 1.19359C9.13038 1.12252 9.31428 1.08594 9.5 1.08594C9.68572 1.08594 9.86962 1.12252 10.0412 1.19359C10.2128 1.26466 10.3687 1.36883 10.5 1.50015C10.6313 1.63147 10.7355 1.78737 10.8066 1.95895C10.8776 2.13054 10.9142 2.31443 10.9142 2.50015C10.9142 2.68587 10.8776 2.86977 10.8066 3.04135C10.7355 3.21293 10.6313 3.36883 10.5 3.50015L3.75 10.2502L1 11.0002L1.75 8.25015L8.5 1.50015Z"
                              stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span>Edit</span>
                      </button>
                      <button class="remove_engage ml_10px">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 3L3 9" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          <path d="M3 3L9 9" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="divider_line mb_27px"></div>
                  <div class="engage_fields_group">
                    <div class="row">
                      <div class="col">
                        <div class="plans-div">
                          <div class="plan-left">
                            <label>Plan Title</label>
                            <input type="text"/>
                            <span>We’ll show this title for user as Plan name</span>
                          </div>
                          <div class="plan-right">
                            <label>Price</label>
                            <input type="text"/>
                            <span class="per-month">$ per month</span>
                            <p>You’ll get 8,99 (10% system fee)</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="plan-des">
                          <label>Plan Description</label>
                          <textarea placeholder="Type description... "></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="subs_label">
                          <label># of Track Downloads/ Month</label>
                          <input type="text"/>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="subs_label">
                          <label>Type</label>
                          <select>
                            <option>Type1</option>
                            <option>Type2</option>
                            <option>Type3</option>
                            <option>Type4</option>
                          </select>
                          <span>
            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L5.827 6.135L1.287 1" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round"/>
            </svg>
          </span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="subs_label">
                          <label># of Albums/ Month</label>
                          <input type="text"/>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="subs_label">
                          <label>Type</label>
                          <select>
                            <option>Type1</option>
                            <option>Type2</option>
                            <option>Type3</option>
                            <option>Type4</option>
                          </select>
                          <span>
            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L5.827 6.135L1.287 1" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round"/>
            </svg>
          </span>
                        </div>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-6">
                        <div class="subs_label">
                          <label># of Sound Kits/ Month</label>
                          <input type="text"/>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="subs_label">
                          <label>Type</label>
                          <select>
                            <option>Type1</option>
                            <option>Type2</option>
                            <option>Type3</option>
                            <option>Type4</option>
                          </select>
                          <span>
            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L5.827 6.135L1.287 1" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round"/>
            </svg>
          </span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="subs_label">
                          <label># of Merch Discounts/ Month</label>
                          <input type="text"/>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="subs_label">
                          <label>Merch Discount (%)</label>
                          <select>
                            <option>10</option>
                            <option>09</option>
                            <option>08</option>
                            <option>07</option>
                          </select>
                          <span>
            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L5.827 6.135L1.287 1" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                  stroke-linejoin="round"/>
            </svg>
          </span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="subs_label">
                          <label>Additional Perks (list one perk - one line)</label>
                          <textarea></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="remove-save-plan">
                          <button class="remove-btn">REMOVE</button>
                          <button class="saveplan-btn">SAVE PLAN</button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="ass-sub-plan">
                          <button class="add-sub-plan-btn">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="add-sub-svg"
                                    d="M7.0013 12.8337C10.223 12.8337 12.8346 10.222 12.8346 7.00033C12.8346 3.77866 10.223 1.16699 7.0013 1.16699C3.77964 1.16699 1.16797 3.77866 1.16797 7.00033C1.16797 10.222 3.77964 12.8337 7.0013 12.8337Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path class="add-sub-svg" d="M7 4.66699V9.33366" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                              <path class="add-sub-svg" d="M4.66797 7H9.33464" stroke="#D1433A" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            ADD SUBSCRIPTION PLAN
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>


              <div id="Sponsors" class="tab-pane ">
                <div class="sponsor_member_listing">
                  <div class="search-filter-added">
                    <input type="text" placeholder="Search by name"/>
                    <span class="svg-serch">
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path d="M12.5 12.501L14.5 14.501" stroke="#8B949F" stroke-width="1.3"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                      </span>
                  </div>
                  <ul>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">

                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">
                            <img :src="require(`@/assets/img/svg/map.svg`)" alt="icon">
                            Lives in Milan, Italy
                          </div>
                        </div>
                      </div>
                      <div class="ml-5">
                        <button type="button" class="button_sponsor button_sponsor_light">Light</button>
                      </div>
                      <div class="d-flex ml-auto">
                        <button type="button" class="outline_primary_button_md_iconed w120">
                          <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.07812 6.58594H9.5521" stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M4.07812 4.53516H11.6041" stroke="#D1433A" stroke-width="1.3"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path
                                d="M2.52897 0.769531H12.471C12.8761 0.7947 13.2573 0.970028 13.5401 1.26125C13.8228 1.55248 13.9868 1.93867 14 2.34435V8.9805C13.9773 9.36416 13.8038 9.72329 13.5174 9.97956C13.231 10.2358 12.8548 10.3685 12.471 10.3485H4.51738L1 12.2621V2.34435C1.01319 1.93867 1.17718 1.55248 1.45993 1.26125C1.74267 0.970028 2.12385 0.7947 2.52897 0.769531V0.769531Z"
                                stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          </svg>
                          MESSAGE
                        </button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown"
                                  aria-expanded="false">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor" style="">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">
                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">
                            <img :src="require(`@/assets/img/svg/img.png`)" alt="icon">
                            Chaz John Ross is a mutual friend
                          </div>
                        </div>
                      </div>
                      <div class="ml-5">
                        <button type="button" class="button_sponsor button_sponsor_medium">Medium</button>
                      </div>
                      <div class="d-flex ml-auto">
                        <button type="button" class="outline_primary_button_md_iconed w120">
                          <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.07812 6.58594H9.5521" stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M4.07812 4.53516H11.6041" stroke="#D1433A" stroke-width="1.3"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path
                                d="M2.52897 0.769531H12.471C12.8761 0.7947 13.2573 0.970028 13.5401 1.26125C13.8228 1.55248 13.9868 1.93867 14 2.34435V8.9805C13.9773 9.36416 13.8038 9.72329 13.5174 9.97956C13.231 10.2358 12.8548 10.3685 12.471 10.3485H4.51738L1 12.2621V2.34435C1.01319 1.93867 1.17718 1.55248 1.45993 1.26125C1.74267 0.970028 2.12385 0.7947 2.52897 0.769531V0.769531Z"
                                stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          </svg>
                          MESSAGE
                        </button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown"
                                  aria-expanded="false">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor" style="">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">
                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">
                            <img :src="require(`@/assets/img/svg/users.svg`)" alt="icon">
                            2 mutual friends
                          </div>
                        </div>
                      </div>
                      <div class="ml-5">
                        <button type="button" class="button_sponsor button_sponsor_full">Full</button>
                      </div>
                      <div class="d-flex ml-auto">
                        <button type="button" class="outline_primary_button_md_iconed w120">
                          <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.07812 6.58594H9.5521" stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M4.07812 4.53516H11.6041" stroke="#D1433A" stroke-width="1.3"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path
                                d="M2.52897 0.769531H12.471C12.8761 0.7947 13.2573 0.970028 13.5401 1.26125C13.8228 1.55248 13.9868 1.93867 14 2.34435V8.9805C13.9773 9.36416 13.8038 9.72329 13.5174 9.97956C13.231 10.2358 12.8548 10.3685 12.471 10.3485H4.51738L1 12.2621V2.34435C1.01319 1.93867 1.17718 1.55248 1.45993 1.26125C1.74267 0.970028 2.12385 0.7947 2.52897 0.769531V0.769531Z"
                                stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          </svg>
                          MESSAGE
                        </button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown"
                                  aria-expanded="false">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor" style="">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">
                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">
                            <img :src="require(`@/assets/img/svg/map.svg`)" alt="icon">


                            Lives in Milan, Italy
                          </div>
                        </div>
                      </div>
                      <div class="ml-5">
                        <button type="button" class="button_sponsor button_sponsor_light">Light</button>
                      </div>
                      <div class="d-flex ml-auto">
                        <button type="button" class="outline_primary_button_md_iconed w120">
                          <svg width="15" height="13" viewBox="0 0 15 13" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.07812 6.58594H9.5521" stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path d="M4.07812 4.53516H11.6041" stroke="#D1433A" stroke-width="1.3"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path
                                d="M2.52897 0.769531H12.471C12.8761 0.7947 13.2573 0.970028 13.5401 1.26125C13.8228 1.55248 13.9868 1.93867 14 2.34435V8.9805C13.9773 9.36416 13.8038 9.72329 13.5174 9.97956C13.231 10.2358 12.8548 10.3685 12.471 10.3485H4.51738L1 12.2621V2.34435C1.01319 1.93867 1.17718 1.55248 1.45993 1.26125C1.74267 0.970028 2.12385 0.7947 2.52897 0.769531V0.769531Z"
                                stroke="#D1433A" stroke-width="1.3" stroke-linecap="round"
                                stroke-linejoin="round"></path>
                          </svg>
                          MESSAGE
                        </button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown"
                                  aria-expanded="false">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor" style="">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="d-flex justify-content-end">
                    <button class="prime_button w200" type="button">Save Changes</button>
                  </div>
                </div>

              </div>
              <div id="Requests" class="tab-pane fade">
                <div class="sponsor_member_listing">
                  <div class="search-filter-added">
                    <input type="text" placeholder="Search by name"/>
                    <span class="svg-serch">
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                                            stroke="#8B949F" stroke-width="1.3" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                        <path d="M12.5 12.501L14.5 14.501" stroke="#8B949F" stroke-width="1.3"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                      </span>
                  </div>
                  <ul>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">

                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">

                            <img :src="require(`@/assets/img/svg/map.svg`)" alt="icon">
                            Lives in Milan, Italy
                          </div>
                        </div>
                      </div>

                      <div class="d-flex ml-auto">
                        <button class="button_accept">Accept</button>
                        <button class="button_decline ml-2">Decline</button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown"
                                  aria-expanded="false">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor" style="">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">
                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">

                            <img class="round_12" :src="require(`@/assets/img/svg/img.png`)" alt="icon">
                            Chaz John Ross is a mutual friend
                          </div>
                        </div>
                      </div>

                      <div class="d-flex ml-auto">
                        <button class="button_accept">Accept</button>
                        <button class="button_decline ml-2">Decline</button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">
                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">

                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">

                            <img :src="require(`@/assets/img/svg/users.svg`)" alt="icon">
                            2 mutual friends
                          </div>
                        </div>
                      </div>

                      <div class="d-flex ml-auto">
                        <button class="button_accept">Accept</button>
                        <button class="button_decline ml-2">Decline</button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30 " data-toggle="dropdown">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="">
                      <div class="member_info">
                        <div class="member_info_avatar">

                          <img :src="require(`@/assets/img/svg/Avatarpic.png`)" alt="icon">
                          <span class="member_status hint_green"></span>
                        </div>
                        <div class="flex-column d-flex justify-content-center">
                          <a href="javascript:;">User Name </a>
                          <div class="member_info_location">
                            <img :src="require(`@/assets/img/svg/map.svg`)" alt="icon">
                            Lives in Milan, Italy
                          </div>
                        </div>
                      </div>

                      <div class="d-flex ml-auto">
                        <button class="button_accept">Accept</button>
                        <button class="button_decline ml-2">Decline</button>
                        <div class="dropdown ml-2">
                          <button type="button" class="outline_primary_button_md_iconed w30" data-toggle="dropdown">
                            <svg class="m-0" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M8.00065 8.66536C8.36884 8.66536 8.66732 8.36689 8.66732 7.9987C8.66732 7.63051 8.36884 7.33203 8.00065 7.33203C7.63246 7.33203 7.33398 7.63051 7.33398 7.9987C7.33398 8.36689 7.63246 8.66536 8.00065 8.66536Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 4.0013C8.36884 4.0013 8.66732 3.70283 8.66732 3.33464C8.66732 2.96645 8.36884 2.66797 8.00065 2.66797C7.63246 2.66797 7.33398 2.96645 7.33398 3.33464C7.33398 3.70283 7.63246 4.0013 8.00065 4.0013Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              <path
                                  d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            </svg>
                          </button>
                          <div class="dropdown-menu dropdown-menu-right dropdown_sponsor">
                            <button class="dropdown-item" type="button">
                              <img :src="require(`@/assets/img/svg/cross.svg`)" alt="icon">
                              Cancel Sponsorship
                            </button>
                            <div class="border_line"></div>
                            <button class="dropdown-item" type="button">

                              <img :src="require(`@/assets/img/svg/flag.svg`)" alt="icon">
                              Report user
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="d-flex justify-content-end">
                    <button class="prime_button w200" type="button">Save Changes</button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'

export default {
  data() {
    return {}
  },
  components: {
    MusicSubHeader
  },
  computed: {},
  methods: {}
}
</script>
