<template>
  <div class="Signup_pop_overlay">
    <div class="wr_my_wallet_modals">
      <div class="wr_title">
        <div>
          <div
            class="ic_prev_close"
            @click="$parent.openCloseModals({modal:'paymentMethods'})"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 1L2 6L7 11" stroke="#47505B" stroke-width="1.5" />
            </svg>
          </div>
          <div class="title">Payment ($70)</div>
        </div>
        <div
          class="ic_prev_close"
          @click="$parent.openCloseModals({modal:'bankTransfer'})"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="wr_bank_Transfer">
        <div class="wr_bank_info">
          <div class="pay_method">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.210827"
                y="15.3676"
                width="15.6012"
                height="0.421654"
                rx="0.210827"
                stroke="#FDB390"
                stroke-width="0.421654"
              />
              <rect
                x="1.2654"
                y="13.0486"
                width="13.4929"
                height="0.843308"
                rx="0.421654"
                stroke="#FDB390"
                stroke-width="0.843308"
              />
              <rect
                x="3.375"
                y="5.88037"
                width="0.843308"
                height="5.05985"
                rx="0.421654"
                stroke="#FDB390"
              />
              <rect
                x="11.8047"
                y="5.88037"
                width="0.843308"
                height="5.05985"
                rx="0.421654"
                stroke="#FDB390"
              />
              <rect
                x="7.58984"
                y="5.88037"
                width="0.843308"
                height="5.05985"
                rx="0.421654"
                stroke="#FDB390"
              />
              <path
                d="M7.88131 0.0273948C7.96606 -0.00913178 8.05767 -0.00913152 8.14242 0.0273951L14.897 2.93867C15.3417 3.13033 15.2352 3.98041 14.7665 3.98041H1.25727C0.788563 3.98041 0.682028 3.13033 1.12671 2.93867L7.88131 0.0273948Z"
                fill="#FDB390"
              />
            </svg>
            Bank Transfer
          </div>
          <div class="bank_info">
            <h5>Bank Name Goes Here</h5>
            <div class="d-flex">
              <div class="colum_1">
                <div class="for_m_b">
                  <span>Account Number / IBAN</span>
                  <h6>5697 8243 7249 9078</h6>
                </div>
                <div>
                  <span>Account Name</span>
                  <h6>Adam Smith</h6>
                </div>
              </div>
              <div class="colum_2">
                <div class="for_m_b">
                  <span>Country</span>
                  <h6>United States</h6>
                </div>
                <div>
                  <span>Routing Code</span>
                  <h6>USNATUIOUXXX</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wr_des">
          <span>Important Note!</span>
          <p>
            In order to confirm the bank transfer, you will need to upload or
            take a screenshot of your transfer within 1 day from your payment
            date
          </p>
        </div>
        <div class="wr_up_file_div">
          <span>Upload Bank Receipt Image</span>
          <div class="up_file_div">
            <input type="file" />
            <div>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3333 1H2.66667C1.74619 1 1 1.74619 1 2.66667V14.3333C1 15.2538 1.74619 16 2.66667 16H14.3333C15.2538 16 16 15.2538 16 14.3333V2.66667C16 1.74619 15.2538 1 14.3333 1Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.58203 6.8335C6.27239 6.8335 6.83203 6.27385 6.83203 5.5835C6.83203 4.89314 6.27239 4.3335 5.58203 4.3335C4.89168 4.3335 4.33203 4.89314 4.33203 5.5835C4.33203 6.27385 4.89168 6.8335 5.58203 6.8335Z"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0013 11.0002L11.8346 6.8335L2.66797 16.0002"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /></svg
              >Bank Receipt Image
            </div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3346 5.33333L8.0013 2L4.66797 5.33333"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 2V10"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="wr_btn">
        <button @click="$parent.openCloseModals({modal:'bankTransfer'})" class="cancel">
          CANCEL
        </button>
        <button class="confirm">SEND</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wr_bank_Transfer {
  margin: 20px 0;
  .wr_bank_info {
    .pay_method {
      background: #f3f3f3;
      border-radius: 2px 2px 0 0;
      display: flex;
      align-items: center;
      padding: 0 0 0 20px;
      height: 40px;
      svg {
        margin-right: 14px;
      }
    }
    .bank_info {
      padding: 20px;
      background: #D1433A;
      border-radius: 0 0 2px 2px;
      color: #fff;
      margin-bottom: 27px;
      h5 {
        font-family: HelveticaNeueBold;
        margin: 0 0 17px 0;
        opacity: 0.9;
        font-weight: 700;
        font-size: 16px;
      }
      h6 {
        margin: 0;
        font-family: HelveticaNeueBold;
        font-weight: 700;
        font-size: 13px;
      }
      span {
        font-family: HelveticaNeueNormal;
        opacity: 0.4;
        font-size: 12px;
        margin-bottom: 3px;
        display: block;
      }
      .colum_1 {
        margin-right: 30px;
      }
      .for_m_b {
        margin-bottom: 13px;
      }
    }
  }
  .wr_des {
    margin-bottom: 20px;
    span {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #47505b;
      margin-bottom: 5px;
    }
    p {
      font-family: HelveticaNeueNormal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #47505b;
      margin: 0;
    }
  }
  .wr_up_file_div {
    span {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #47505b;
      margin: 0 0 7px 0;
      display: block;
    }
    .up_file_div {
      position: relative;
      display: flex;
      padding: 0 20px;
      border: 1px solid #D1433A;
      border-radius: 4px;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      background: #D1433A1A;
      div {
        display: flex;
        align-items: center;
        font-family: HelveticaNeueMedium;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #47505b;
        svg {
          margin-right: 18px;
        }
      }
      input {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }
    }
  }
}
</style> 