import { render, staticRenderFns } from "./PageStats.vue?vue&type=template&id=769c9862&scoped=true"
import script from "./PageStats.vue?vue&type=script&lang=js"
export * from "./PageStats.vue?vue&type=script&lang=js"
import style0 from "./PageStats.vue?vue&type=style&index=0&id=769c9862&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "769c9862",
  null
  
)

export default component.exports