<template>
  <div class="Signup_pop_overlay share-model">
    <div
      class="modal fade show d-block"
      id="login"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style=" padding-right: 15px;"
      aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content" style="width: 572px;">
          <div class="post-option-container px-0">
            <div class="modal-header py-2">
              <span class="modal-title">
                Upload Photos
              </span>
              <div
                @click="closeModel"
                role="button"
                class="close-modal-button d-flex align-items-center justify-content-center"
              >
                <img
                  :src="
                    require(`@/assets/icons/icons/home/news-feed/close-icon.svg`)
                  "
                  alt="close"
                />
              </div>
            </div>
            <div class="">
              <nav>
                <div
                  class="nav nav-tabs custom_tab"
                  id="nav-tab"
                  role="tablist"
                  style="padding: 19px 20px 0 20px;"
                >
                  <a
                    @click="changeActiveTab('photos')"
                    :class="[
                      'nav-item nav-link headers-style',
                      active_tab === 'share' ? 'active' : '',
                    ]"
                    id="nav-home-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-home"
                    :aria-selected="active_tab === 'photos'"
                  >
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.08203 8.3335C7.77239 8.3335 8.33203 7.77385 8.33203 7.0835C8.33203 6.39314 7.77239 5.8335 7.08203 5.8335C6.39168 5.8335 5.83203 6.39314 5.83203 7.0835C5.83203 7.77385 6.39168 8.3335 7.08203 8.3335Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.5013 12.5002L13.3346 8.3335L4.16797 17.5002"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span v-if="active_tab !== 'photos'">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.08203 8.3335C7.77239 8.3335 8.33203 7.77385 8.33203 7.0835C8.33203 6.39314 7.77239 5.8335 7.08203 5.8335C6.39168 5.8335 5.83203 6.39314 5.83203 7.0835C5.83203 7.77385 6.39168 8.3335 7.08203 8.3335Z"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.5013 12.5002L13.3346 8.3335L4.16797 17.5002"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span class="ml-3">Upload Photos</span></a
                  >
                  <a
                    @click="changeActiveTab('albums')"
                    :class="[
                      'nav-item nav-link headers-style',
                      active_tab === 'socials' ? 'active' : '',
                    ]"
                    id="nav-contact-tab1"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-contact"
                    :aria-selected="active_tab === 'albums'"
                  >
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 3.83333C16.5 3.09695 15.7538 2.5 14.8333 2.5H3.16667C2.24619 2.5 1.5 3.09695 1.5 3.83333V13.1667"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.8333 5.5H5.16667C4.24619 5.5 3.5 6.09695 3.5 6.83333V16.1667C3.5 16.903 4.24619 17.5 5.16667 17.5H16.8333C17.7538 17.5 18.5 16.903 18.5 16.1667V6.83333C18.5 6.09695 17.7538 5.5 16.8333 5.5Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.08203 11.3335C8.77239 11.3335 9.33203 10.7739 9.33203 10.0835C9.33203 9.39314 8.77239 8.8335 8.08203 8.8335C7.39168 8.8335 6.83203 9.39314 6.83203 10.0835C6.83203 10.7739 7.39168 11.3335 8.08203 11.3335Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.5013 15.5002L14.3346 11.3335L8.16797 17.5002"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span v-if="active_tab !== 'albums'">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 3.83333C16.5 3.09695 15.7538 2.5 14.8333 2.5H3.16667C2.24619 2.5 1.5 3.09695 1.5 3.83333V13.1667"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.8333 5.5H5.16667C4.24619 5.5 3.5 6.09695 3.5 6.83333V16.1667C3.5 16.903 4.24619 17.5 5.16667 17.5H16.8333C17.7538 17.5 18.5 16.903 18.5 16.1667V6.83333C18.5 6.09695 17.7538 5.5 16.8333 5.5Z"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.08203 11.3335C8.77239 11.3335 9.33203 10.7739 9.33203 10.0835C9.33203 9.39314 8.77239 8.8335 8.08203 8.8335C7.39168 8.8335 6.83203 9.39314 6.83203 10.0835C6.83203 10.7739 7.39168 11.3335 8.08203 11.3335Z"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.5013 15.5002L14.3346 11.3335L8.16797 17.5002"
                          stroke="#D1433A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span class="ml-3">Create Album</span>
                  </a>
                </div>
              </nav>
            </div>
            <div
              class="tab-content"
              id="nav-tabContent"
              style="padding: 0 20px 0 20px;"
            >
              <div
                :class="[
                  'tab-pane fade',
                  active_tab === 'photos' ? 'active show' : '',
                ]"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div
                  class="uploaded-image col-sm-12 pt-5 pb-5 upload-file-border"
                  v-if="preview_list.length > 0"
                >
                  <img :src="preview_list[0]" />
                </div>
                <div
                  @click="$refs.file.click()"
                  @drop="dragFile($event)"
                  @dragover.prevent
                  @dragleave.prevent
                  class="col-sm-12 pt-5 pb-5 upload-file-border"
                  v-else
                >
                  <div>
                    <p class="product-song-p">
                      Browse for file or drag and drop it here
                    </p>
                  </div>
                  <div class="upload-file-icon">
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="49"
                        height="49"
                        rx="3.5"
                        fill="#D1433A"
                        fill-opacity="0.1"
                        stroke="#D1433A"
                      />
                      <path
                        d="M25 16.25V33.75"
                        stroke="#D1433A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.25 25H33.75"
                        stroke="#D1433A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    multiple="false"
                    @change="uploadImages"
                    ref="file"
                    style="display: none;"
                  />
                </div>
                <div class="pt-3">
                  <div
                    class="event-filter-widget with-app pg-song-artist-checkbox-card"
                  >
                    <div class="widget-item">
                      <div class="pg-item-title  with-checkbox">Privacy:</div>
                      <div
                        class="custom-control custom-checkbox"
                        @click="setPrivacyValue('public')"
                      >
                        <input
                          type="checkbox"
                          id="public"
                          value="public"
                          name="privacy"
                          class="custom-control-input"
                          :checked="privacy === 'public'"
                        />
                        <label for="public" class="custom-control-label check"
                          >Public</label
                        >
                      </div>
                      <div
                        class="custom-control custom-checkbox"
                        @click="setPrivacyValue('private')"
                      >
                        <input
                          type="checkbox"
                          id="private"
                          value="private"
                          name="privacy"
                          class="custom-control-input"
                          :checked="privacy === 'private'"
                        />
                        <label for="private" class="custom-control-label check"
                          >Private</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="[
                  'tab-pane fade',
                  active_tab === 'albums' ? 'active show' : '',
                ]"
                id="socials"
                role="tabpanel"
                aria-labelledby="nav-contact-tab1"
              >
                <div class="row">
                  <div class="col-12 d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control mt-4"
                      placeholder="Album Name"
                      v-model="albumTitle"
                    />
                  </div>
                  <div class="col-12">
                    <div class="upload-photos-section">
                      <ul>
                        <li
                          v-for="(eachOp, index) in preview_list"
                          :key="'image' + index"
                        >
                          <div class="uploaded-image">
                            <img :src="eachOp" />
                          </div>
                          <span class="cross-icon" @click="removeFile(eachOp)">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.5 3.5L3.5 10.5"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M3.5 3.5L10.5 10.5"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                        </li>
                        <li class="upload-photo-section-li">
                          <div class="upload-photo-icons">
                            <img
                              :src="require('@/assets/img/add-p.png')"
                              class="sampleImg"
                            />
                            <input
                              type="file"
                              multiple="multiple"
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                              name="fileToUpload"
                              @change="uploadImages"
                              id="fileToUpload"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 pl-0 pr-0">
                <span style="color: red;">{{ imageError }}</span>
              </div>
              <div class="row">
                <div class="col">
                  <div class="post-btn" @click="addPhoto">
                    <button>POST</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddPhotosModal",
  data() {
    return {
      active_tab: "",
      error_message: "",
      loading: false,
      privacy: "public",
      file: null,
      names: [],
      preview_list: [],
      images: [],
      imageError: "",
      // active: false,
      // inActiveTimeout: null
    };
  },
  props: {
    pageId: Number,
    default: null
  },
  watch: {
    active_tab(value) {
      if (value === "albums") {
        this.$store.commit("SET_CATEGORY", "image");
        this.$store.commit("SET_TYPE", "album");
        this.$store.commit("SET_TYPE_DESCRIPTION", "share a album");
        this.$store.commit("SET_ACCESS", "public");
      } else if (value === "photos") {
        this.$store.commit("SET_CATEGORY", "image");
        this.$store.commit("SET_TYPE", "timeline_photo");
        this.$store.commit("SET_TYPE_DESCRIPTION", "share a photo");
      }
    },
  },
  computed: {
    selectedImages: {
      set(newValue) {
        this.$store.commit("SET_PICTURES", newValue);
      },
      get() {
        return this.$store.getters.getPictures;
      },
    },
    albumTitle: {
      get() {
        return this.$store.getters.getAlbumTitle;
      },
      set(newValue) {
        this.$store.commit("SET_ALBUM", newValue);
      },
    },
  },
  mounted() {},
  methods: {
    closeModel() {
      this.$emit("closePhotoModel");
    },
    changeActiveTab(tab) {
      this.active_tab = tab;
    },
    changeSubActiveTab(tab) {
      this.sub_active_tab = tab;
    },
    dragFile(e) {
      e.preventDefault();
      var input = e.dataTransfer.files;
      var index = 0;
      if (input && input[0].size < 1000000) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.images.push(input[index]);
          reader.readAsDataURL(input[index]);
        this.selectedImages = this.images;
        this.imageError = "";
      } else {
        this.imageError = "Image size should be less than 1 MB";
      }
    },
    // setActive() {
    //   this.active = true;
    // },
    // setInactive() {
    //   this.active = false;
    // },
    setPrivacyValue(value) {
      this.privacy = value;
      this.$store.commit("SET_ACCESS", this.privacy);
    },
    uploadImages(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files && input.files[index].size < 1000000) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.images.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
        this.selectedImages = this.images;
        this.imageError = "";
      } else {
        this.imageError = "Image size should be less than 1 MB";
      }
    },
    removeFile(file) {
      const index = this.preview_list.indexOf(file);
      if (index > -1){
        this.preview_list.splice(index, 1);
        this.images.splice(index, 1)
      }
    },
    async addPhoto() {
      this.$emit("closePhotoModel");
      if(this.pageId){
        await this.$store.dispatch("post", {page_id: this.pageId});
      }
      else{
        await this.$store.dispatch("post", {});
      }
      this.$store.commit("SET_CATEGORY", "");
      this.$store.commit("SET_TYPE", "");
      this.$store.commit("SET_TYPE_DESCRIPTION", "");
      this.$store.commit("SET_ACCESS", "");
      this.$store.commit("SET_ALBUM", "");
    },
  },
};
</script>

<style scoped lang="scss">
.share-model {
  .modal {
    .modal-dialog {
      .modal-content {
        .post-option-container {
          .modal-header {
            border-bottom: 1px solid #e6eaf0;
            margin-bottom: 0.5rem;

            .close-modal-button {
              width: 30px;
              height: 30px;
              border-radius: 4px;
              &:hover {
                background: #e6eaf0;
                border-radius: 2px;
              }
            }

            .modal-title {
              font-style: normal !important;
              font-weight: bold !important;
              font-size: 20px !important;
              line-height: 24px !important;
              text-transform: capitalize;
              color: #071526 !important;
              display: flex;
              justify-content: center;
              width: 100%;
              margin-bottom: 0.5rem;
            }
          }

          .custom_tab {
            border: none !important;
            border-radius: 0px !important;

            .nav-item {
              width: 255px;
              height: 40px;
              &.active {
                background: #D1433A;
                border-radius: 2px 0px 0px 2px;
              }
            }
          }

          .tiles {
            .tile-height {
              &.active-title {
                background: #D1433A1A;
              }

              height: 120px;
              border: 1px solid #e6eaf0;
              cursor: pointer;

              .tile-top-margin {
                margin-top: 0.7rem;
              }
            }
          }

          .post-artist-setcion {
            justify-content: center !important;
          }

          .tab-content {
            .embed-tabs {
              &.active {
                background: #D1433A !important;
                color: #fff !important;
                border: none !important;
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
              &:first-child {
                border-radius: 4px 0 0 4px !important;
              }
              &:nth-child(2) {
                border-radius: 0 4px 4px 0 !important;
              }
              background: #ffffff;
              border: 1px solid #e6eaf0 !important;
              color: #47505b !important;
            }

            .pg-position-share-link {
              input {
                background: inherit;
              }
            }

            .colored-list {
              .colored-box {
                height: 20px !important;
                width: 20px !important;
              }
            }

            .remove-border {
              width: 100%;
              border: none !important;
              height: 28px;
            }

            .song-bar {
              height: 50px;
              background: #fafafc;
              margin-left: 0.1rem;
              margin-right: 0.1rem;
              border-radius: 4px;

              .song-artwork {
                width: 30px;
                height: 30px;
                border-radius: 2px;
              }

              .song-title {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #47505b;
              }

              .song-artist {
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 20px;
                color: #8b949f;
              }
            }

            .link-padding {
              margin-left: 0.1rem;
              margin-right: 0.1rem;
              border-radius: 4px;
              height: 31px;
            }

            .add-button {
              font-size: 13px;
              text-transform: uppercase;
              font-weight: bold;
              height: 29px;
              width: 60px;
              background: #D1433A;
              color: #fff;
              border: 1px solid #D1433A;
              border-radius: 0 4px 4px 0;
              transition: 0.2s all ease;
              font-family: "Helvetica Neue", sans-serif;
              display: flex;
              align-items: center;
              justify-content: center;
              letter-spacing: 0.05em;
            }

            .social-icon {
              &.active {
                background: #D1433A;
                svg {
                  path {
                    fill: #fff;
                  }
                }
              }
              width: 40px;
              height: 30px;
              background: rgba(113, 129, 149, 0.07);
              margin: 0 10px;
              border-radius: 4px;
            }

            .link-label {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #8b949f;
              letter-spacing: 0.03em;
            }

            .embed-label {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #47505b;
              letter-spacing: 0.03em;
            }

            .select-height {
              height: 30px;
              padding-top: 0;
              padding-bottom: 0;
            }

            .write-message-text {
              color: #47505b;
            }

            .write-message {
              width: 90%;
              height: 41px;
            }
          }
        }
      }
    }
  }
}

.modal-title {
  font-family: HelveticaNeueBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.upload-file-border {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(71, 80, 91, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  width: 530px;
  height: 200px;
}

.event-filter-widget {
  background-color: #fff;
  border: none;
  border-radius: none;
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid #e6eaf0;
  .widget-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    .with-checkbox {
      margin-right: 21px;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #071526;
    }
    .custom-control-label {
      padding: 5px 10px 5px;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #47505b;
      opacity: 0.9;
      &:before {
        width: 30px !important;
        height: 30px !important;
      }

      &:after {
        width: 30px !important;
        height: 30px !important;
      }
    }
  }
}
.share-model {
  .upload-photos-section ul {
    border-bottom: none;
  }
}
.share-model {
  .headers-style {
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom: none;
  }
}
</style>
