<template>
  <div class="w-100">
    <div class="row">
      <div class="col-12">
        <PageElement1 :song="song" :songName="song.title" :type="$route.params.type"
                      :backgroundType="($route.params.type === 'song' || $route.params.type === 'Music Podcast') ? 'type-1' : 'type-2'"
                      :listType="($route.params.type === 'song')? 'streaming' : 'selling'"
                      :track="(tracks && tracks.length > 0) ? tracks[0] : {}"
                      :showPlus="false" :imageName="'ddll'" ref="songTile"
                      @goto="goTo"/>
      </div>
    </div>
    <div class="row">
      <div class="feed_wrapper mt_20 p-0">
        <div class="col-8">
          <div class="event-main">
            <AlbumTrackDetails :song="song" />
          </div>
          <div class="event-main">
            <div class="detail-tab detail-tab-artist event_tab">
              <nav>
                <div id="nav-tab1" role="tablist" class="nav top-tab nav-tabs1">
                  <a v-if="$route.params.type === 'album'" id="nav-past-tab" data-toggle="tab" href="#tracks" role="tab"
                     aria-controls="nav-profile"
                     aria-selected="false" class="nav-item nav-link active">Tracks
                    <span class="event_counting">{{ (total_tracks) ? total_tracks : 0 }}</span></a>
                  <a id="nav-past-tab" data-toggle="tab" href="#comments" role="tab" aria-controls="nav-profile"
                     aria-selected="false"
                     :class="[($route.params.type === 'song' || $route.params.type === 'Music Podcast') ? 'active' : '']"
                     class="nav-item nav-link">Comment
                    <span class="event_counting">{{ (comment_count) ? comment_count : 0 }}</span></a>
                  <a id="nav-past-tab" data-toggle="tab" href="#reviews" role="tab" aria-controls="nav-profile"
                     aria-selected="false" class="nav-item nav-link">Reviews
                    <span class="event_counting">{{ review_count }}</span></a>
                </div>
              </nav>
              <span class="">
                <SpecificGenres />
              </span>
            </div>
          </div>
          <div id="myTabContent" class="tab-content">
            <div v-if="$route.params.type === 'album'" id="tracks" role="tabpanel" aria-labelledby="nav-past-tab"
                 class="tab-pane fade active show">
              <div class="row">
                <div class="col-12">
                  <favoriteSongsListing :listData="tracks" :userProfile="'artist_profile'" />
                </div>
              </div>
            </div>
            <div id="comments" role="tabpanel" aria-labelledby="nav-past-tab" class="tab-pane fade show"
                 :class="[($route.params.type === 'song' || $route.params.type === 'Music Podcast') ? 'active' : '']">
              <div class="row">
                <div class="col-12">
                  <div class="post-field mb_10">
                    <CommentInput :item="song"
                                  :design_check="true" @comments_count="commentsCount" @addComment="addCommentInList"
                                  style="width: 100%" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <CommentListing :item="song"
                                  ref="commentList" />
                </div>

              </div>
              <div v-if="comment_count === 0" class="row">
                <div class="col-12">
                  <div class="no-content-here">
                    <div class="no-detail-content">
                      <div class="category-content-icon">
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M48.25 23.7084C48.2589 27.1181 47.4623 30.4816 45.925 33.5251C44.1023 37.1721 41.3002 40.2396 37.8326 42.384C34.365 44.5285 30.3688 45.6652 26.2917 45.6667C22.882 45.6756 19.5185 44.879 16.475 43.3417L1.75 48.2501L6.65833 33.5251C5.12107 30.4816 4.32444 27.1181 4.33333 23.7084C4.33491 19.6313 5.47158 15.6351 7.61602 12.1675C9.76047 8.69988 12.828 5.89779 16.475 4.07507C19.5185 2.53781 22.882 1.74118 26.2917 1.75007H27.5833C32.9679 2.04714 38.0537 4.31987 41.8669 8.13313C45.6802 11.9464 47.9529 17.0322 48.25 22.4167V23.7084Z"
                            stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div class="no-detail-content-heading">No Comments to show</div>
                      <div class="no-detail-content-subheading">Be the first person to write a comment</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="reviews" role="tabpanel" aria-labelledby="nav-past-tab" class="tab-pane fade">
              <div class="row">
                <div class="col-12">
                  <div class="rate-user">
                    <div class="total-rating">
                      {{ (song.rating && song.rating.avg) ? song.rating.avg : "NA" }}
                    </div>
                    <div class="rating-detail">
                      <div class="latest-rating">
                        <h1>RATING</h1>
                        <p v-if="song.rating && song.rating.avg">
                          {{ (song.rating && song.rating.avg) ? song.rating.avg : "NA" }} from
                          {{ (song.rating && song.rating.total) ? song.rating.total : "0" }} reviews</p>
                      </div>
                      <div class="latest-rating last-rate">
                        <h1>LAST REVIEW</h1>
                        <p> {{ (lastReviewData && lastReviewData.updated_at) ? lastReviewData.updated_at: '' | formatDate }} / {{ (lastReviewData && lastReviewData.rating) ? lastReviewData.rating : "NA" }}
                          from
                          {{ (lastReviewData && lastReviewData.reviewable_user && lastReviewData.reviewable_user.name) ? lastReviewData.reviewable_user.name : ""
                          }}</p>
                      </div>
                    </div>
                    <div class="star-rate">
                      <ul>
                        <li v-for="(rating, index) in ratings" :key="index">
                          <div class="star-count">
                            <star-rating :read-only="true"
                                         v-model="rating.rating"
                                         v-bind:increment="1"
                                         v-bind:max-rating="index+1"
                                         :inline="true"
                                         inactive-color="#47505B"
                                         active-color="#0075FF"
                                         v-bind:star-size="10" />
                          </div>
                          <div class="position-relative-status">
                            <vue-slider class="slider" v-model="rating.total" />
                          </div>
                          <div class="total-star-count">{{ rating.total }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ReviewInput :item="song" @addReview="addReviewInList" />
                </div>
              </div>
              <div class="row" v-if="this.featuredReview">
                <div class="col-12">
                  <div class="featured-review-section">
                    <h1>FEATURED REVIEW</h1>
                    <div class="featured-review-main">
                      <div class="featured-user-detail">
                        <div class="featured-user-detail-img">
                          <span><img :src="userPicture"></span>
                          <div class="featured-user-details">
                            <h1>{{ userName }}</h1>
                            <p>Journalist</p>
                          </div>
                        </div>
                        <div class="user-start-review">
                          <div class="user-rate">
                            <star-rating :read-only="true"
                                         v-model="getRating"
                                         v-bind:increment="0.5"
                                         v-bind:max-rating="5"
                                         :inline="true"
                                         inactive-color="#47505B"
                                         active-color="#0075FF"
                                         v-bind:star-size="15" />
                            <span>{{ (getRating > 0) ? getRating + ".0" : "" }}</span>
                          </div>
                          <div class="review-date">{{ featuredReview.updated_at | formatDate }}</div>
                        </div>
                      </div>
                      <div class="review-details d-flex">
                        <span class="mr-1">
                          <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1.33377 11.5564H4.6671L6.88932 7.11198V0.445312H0.222656V7.11198H3.55599L1.33377 11.5564ZM10.2227 11.5564H13.556L15.7782 7.11198V0.445312H9.11155V7.11198H12.4449L10.2227 11.5564Z"
                              fill="#D1433A" />
                          </svg>
                        </span>
                        <p>
                          {{ featuredReview.comment }}
                        </p>
                      </div>
                      <div class="user-profile-detail-sep-comment"></div>
                      <div class="like-reply">
                        <ul>
                          <li>
                            <a href="javascript:;">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M4.66536 14.6673H2.66536C2.31174 14.6673 1.9726 14.5268 1.72256 14.2768C1.47251 14.0267 1.33203 13.6876 1.33203 13.334V8.66732C1.33203 8.3137 1.47251 7.97456 1.72256 7.72451C1.9726 7.47446 2.31174 7.33398 2.66536 7.33398H4.66536M9.33203 6.00065V3.33398C9.33203 2.80355 9.12132 2.29484 8.74625 1.91977C8.37117 1.5447 7.86246 1.33398 7.33203 1.33398L4.66536 7.33398V14.6673H12.1854C12.5069 14.671 12.819 14.5583 13.064 14.35C13.309 14.1417 13.4705 13.8519 13.5187 13.534L14.4387 7.53398C14.4677 7.34289 14.4548 7.14777 14.4009 6.96215C14.347 6.77653 14.2534 6.60485 14.1266 6.45899C13.9998 6.31314 13.8428 6.19661 13.6664 6.11746C13.4901 6.03832 13.2986 5.99846 13.1054 6.00065H9.33203Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                              </svg>
                              Like
                              <span>24</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:;">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M11.3327 1.33268L13.3327 1.33268C13.6863 1.33268 14.0254 1.47316 14.2755 1.72321C14.5255 1.97325 14.666 2.31239 14.666 2.66602L14.666 7.33268C14.666 7.6863 14.5255 8.02544 14.2755 8.27549C14.0254 8.52554 13.6863 8.66602 13.3327 8.66602L11.3327 8.66602M6.66602 9.99935L6.66602 12.666C6.66602 13.1964 6.87673 13.7052 7.2518 14.0802C7.62687 14.4553 8.13558 14.666 8.66602 14.666L11.3327 8.66602L11.3327 1.33268L3.81268 1.33268C3.49113 1.32905 3.1791 1.44174 2.93408 1.65001C2.68906 1.85828 2.52756 2.14808 2.47935 2.46601L1.55935 8.46601C1.53034 8.65711 1.54323 8.85223 1.59712 9.03785C1.65101 9.22347 1.74461 9.39515 1.87144 9.541C1.99827 9.68686 2.1553 9.80339 2.33163 9.88254C2.50797 9.96168 2.69941 10.0015 2.89268 9.99935L6.66602 9.99935Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                              Dislike
                              <span>12</span>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:;">
                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M13 9C13 9.35362 12.8595 9.69276 12.6095 9.94281C12.3594 10.1929 12.0203 10.3333 11.6667 10.3333H3.66667L1 13V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V9Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                              Comment
                              <span>12</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="featured-review-section">
                    <h1>REVIEWS FROM USERS</h1>
                    <div>
                      <ReviewListing :item="song" ref="reviewList"
                        @reviews_count="reviewsCount"
                        @featured_review="featureReview"
                        @last_review="setLastReview"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="review_count === 0">
                <div class="col-12">
                  <div class="no-content-here">
                    <div class="no-detail-content">
                      <div class="category-content-icon">
                        <svg width="56" height="53" viewBox="0 0 56 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M27.9036 1.98828L35.8861 18.1599L53.737 20.7691L40.8203 33.3499L43.8686 51.1233L27.9036 42.7274L11.9386 51.1233L14.987 33.3499L2.07031 20.7691L19.9211 18.1599L27.9036 1.98828Z"
                            stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div class="no-detail-content-heading">No Reviews to show</div>
                      <div class="no-detail-content-subheading">Be the first person to write a review</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="content-sidebar">
            <RelatedAlbums @change_active_tab="change_active_tab"
                           :title="($route.params.type === 'song') ? 'Related Songs' : ($route.params.type === 'Music Podcast') ? 'Related Podcast' : 'Related Albums'"
                           :typeName="'Song Name'"
                           :comment_type="$route.params.type" />
            <RelatedAlbums @change_active_tab="change_active_tab" :title="'In Playlist'" :typeName="'Playlist Name'" />
            <Fans @change_active_tab="change_active_tab" />
            <!--            <Repost @change_active_tab="change_active_tab" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageElement1 from "../../Common/UiList/PageElement1";
import AlbumTrackDetails from "../../PageManager/Pages/MusicDetailPage/AlbumTrackDetails";
import SpecificGenres from "../../Common/UiList/SpecificGenres";
import RelatedAlbums from "../../PageManager/Pages/Widgets/RelatedAlbums";
import Fans from "../../PageManager/Pages/Widgets/Fans";
import Repost from "../../PageManager/Pages/Widgets/Repost";
import CommentInput from "../../Home/NewsFeed/PostItem/CommentInput";
import CommentListing from "../../Home/NewsFeed/PostItem/CommentListing";
import { getPodcastDetailByID, getSongAndAlbumDetailByID } from "../../../apis/APIs";
import favoriteSongsListing from "../../Home/Profile/favorites/music/favoriteSongsListing";
import ReviewInput from "../../DesignGuide/Music/Components/ReviewInput";
import ReviewListing from "../componenets/ReviewListing";
import StarRating from "vue-star-rating";
import moment from "moment";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import "/public/css/vue-star-rating.scss";

export default {
  name: "IndividualFullViewPage",
  data() {
    return {
      active_sub_tab: "comments",
      comment_count: 0,
      review_count: 0,
      song: {},
      tracks: [],
      total_tracks: "",
      featuredReview: {},
      ratings: [
        {
          rating: 1,
          total: 0
        },
        {
          rating: 2,
          total: 0
        },
        {
          rating: 3,
          total: 0
        },
        {
          rating: 4,
          total: 0
        },
        {
          rating: 5,
          total: 0
        }

      ],
      lastReviewData: {},
      rat: 5
    };
  },
  filters: {
    formatDate(value) {
      return moment(value).calendar();
    }
  },
  watch: {
    getRatingArray() {
      this.getRatingObject();
    },
    currentPageId() {
      this.getPageData()
    }
  },
  computed: {
    userName() {
      return (this.featuredReview && this.featuredReview.reviewable_user && this.featuredReview.reviewable_user.name) ? this.featuredReview.reviewable_user.name : "";
    },
    getRating() {
      return (this.featuredReview && this.featuredReview.rating) ? parseInt(this.featuredReview.rating) : 0;
    },
    userPicture() {
      return (this.featuredReview && this.featuredReview.reviewable_user && this.featuredReview.reviewable_user.artwork_url) ? this.featuredReview.reviewable_user.artwork_url : "require(`@/assets/img/svg/mask-group.svg`)";
    },
    getRatingArray() {
      return (this.song && this.song.rating_group && this.song.rating_group.length > 0) ? this.song.rating_group : [];
    },
    currentPageId() {
      return this.$route.params.id
    }
  },
  components: {
    ReviewListing,
    PageElement1,
    AlbumTrackDetails,
    SpecificGenres,
    RelatedAlbums,
    ReviewInput,
    StarRating,
    VueSlider,
    Fans,
    // Repost,
    CommentInput,
    CommentListing,
    favoriteSongsListing
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    change_active_tab: function(tab) {
      this.active_sub_tab = tab;
      window.scrollTo(0, 0);
    },
    commentsCount(comments_count) {
      if (comments_count > this.comment_count) {
        this.comment_count = comments_count;
        this.$refs.songTile.setCommentCounts(comments_count);
      }
    },
    reviewsCount(total) {
      if (total > this.review_count) {
        this.review_count = total;
      }
    },
    featureReview(featured_review) {
      this.featuredReview = featured_review;
    },
    addReviewInList(review) {
      this.$refs.reviewList.addReviewInList(review);
      this.setLastReview(review);
      // if (this.featuredReview) {
      //   if (Object.keys(this.featuredReview).length !== 0) {
      //     this.featuredReview = {};
      //     this.featuredReview.unshift(review);
      //   }
      // }
    },
    addCommentInList(comment) {
      this.$refs.commentList.addCommentInList(comment);
    },
    async getPageData() {
      this.song = {};
      this.tracks = [];
      this.total_tracks = ''
      try {
        await this.$store.dispatch("loading", true);
        if (this.$route.params.type === "Music Podcast") {
          const { data } = await getPodcastDetailByID(this.$route.params.id);
          const { success, information } = data || {};
          if (success === 1) {
            this.song = information;
          }
        } else {
          const { data } = await getSongAndAlbumDetailByID(this.$route.params.id, this.$route.params.type);
          const { success, information } = data || {};
          if (success === 1) {
            if (this.$route.params.type === "album") {
              this.song = information.data;
              this.tracks = information.songs.data;
              this.total_tracks = information.songs.total;
            } else {
              this.song = information;
            }
          }
        }
        this.comment_count = this.song.comment_count;
        this.review_count = (this.song && this.song.rating && this.song.rating.total) ? this.song.rating.total : 0;
        await this.$store.dispatch("loading", false);
      } catch (e) {
        await this.$store.dispatch("loading", false);
        console.error(e.message);
      }
    },
    getRatingObject() {
      for (let ratingIndex in this.ratings) {
        const index = this.getRatingArray.findIndex(e => parseInt(e.rating) === this.ratings[ratingIndex].rating);
        if (index !== -1) {
          this.ratings[ratingIndex].total = this.getRatingArray[index].total;
        } else {
          this.ratings[ratingIndex].rating = 0;
        }
      }

    },
    setLastReview(review) {
      this.lastReviewData = review;
    },
    goTo() {
      window.scrollTo(0, 500);
    }
  }
};
</script>


<style scoped lang="scss">
.rate-user {
  .star-rate {
    .position-relative-status {
      height: 16px !important;
    }
  }
}
</style>
<style lang="scss">
.rate-user {
  .star-rate {
    .position-relative-status {
      .vue-slider {
        .vue-slider-dot {
          display: none;
        }
      }
    }
  }
}
</style>
