<template >
    <!-- Create Poll -->
    <div>
    <div class="post-filed-options-c" v-if="!selectedTab" :style="is_edit_post ? 'border-bottom:' + 'none' : ''">
        <div class="upload-photos-section mt-3">
            <div class="row">
            <div class="col">
                <div class="upload-photos-title">
                    <div class="back-photo-icon" @click="goBack()">
                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9L1 5L5 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                    <div class="bac-text-tite">Create Poll</div>
                </div>
            </div>
            </div>
            <div class="row">
            <div class="col">
                <div class="uploaded-files">
                    <ul class="add-options">
                        <li v-for="(eachOp, index) in polls" :key="'poll'+index">
                            <div class="add-option">
                                <input type="text" v-model="polls[index]"  placeholder="Add an option">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <div class="row">
            <div class="col">
                <div class="add-upload-file" @click="addPoll">
                    <img :src="require('@/assets/img/add-p.png')">
                </div>
            </div>
            </div>
            <div class="row" v-if="!is_edit_post">
            <div class="col">
                <div class="Sponsors-section ">
                    <div class="action-block ">
                        <div class="switch-button">
                        <label class="switch "> <input type="checkbox" checked="checked" class="default"> <span
                                class="slider round"></span> </label>
                        </div>
                        For All Users
                    </div>
                    <div class="select-audience-button" v-if="!selectedTab" @click="selectedTab = 'selectAudience'">

                      <span v-if="!audience"> Choose Audience</span>
                      <span v-if="audience"> {{audience.toUpperCase().replace(/_/g, ' ')}}</span>
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="sponser-type w-100" v-if="selectedTab === 'selectAudience'">
      <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
    </div>
    </div>
    <!-- Exit -->

</template>
<script>
import SelectAudience from './components/selectAudience';
import {mapGetters, mapMutations} from 'vuex';
import {CREATE_POST_FORM_POLLS} from '../../../store/types';
import audience from "../../Events/components/Detail/Audience.vue";

export default {
  data() {
    return ({
      polls: [],
      selectedTab:"",
      audience: null,
    })
  },
  props:{
    is_edit_post: {
      type: Boolean
    },
    post: {
      type: Object
    }
  },
  watch: {
    selectedTab(value) {
      if (value) this.hideTopBarAndShowPostBtn();
    }
  },
  components:{
    SelectAudience
  },
  mounted() {
    this.init();
    this.setDetails();
  },
  computed: {
    ...mapGetters({
      form_data: "create_poll_forms_map_to_props"
    }),
    shared_with() {
      const {shared_with} = this.form_data;

      return shared_with ? shared_with : "";
    },
    stored_polls() {
      const {polls} = this.form_data;

      return polls && polls.length ? polls : [];
    },
  },
  methods: {
    ...mapMutations([
      "updateCreatePostFormData"
    ]),
    init() {
      const {stored_polls} = this;
      if (stored_polls && stored_polls.length) {
        this.polls = stored_polls;
      }else if(this.is_edit_post){
        this.polls = this.post && this.post.options.map((item)=>{ return item.option});
      }
    },
    goBack() {
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
      this.$parent.goBack();
    },
    addPoll() {
      this.polls.push("");
    },
    savePollOptionInStore(){
      this.$store.commit('SET_POLL_OPTION',this.polls)
    },
    setDetails(){
      this.$store.commit('SET_TYPE','poll')
      this.$store.commit('SET_TYPE_DESCRIPTION','created a poll')
      this.$store.commit('SET_POLL_EXPIRY','2021-12-12')
    },
    onChangeSharedWith(e) {
      const {name, value} = e.target || {};

      this.updateCreatePostFormData({
        [name]: value
      });
    },
    hideTopBarAndShowPostBtn() {
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      createPostOptionsContainer.style.display = "none";
    },
    goBackToPostMain(audience) {
      this.selectedTab = null;
      this.audience = audience.toLowerCase().replace(/ /g, '_');
      const createPostOptionsContainer = document.getElementById("create-post-option-container");
      if (createPostOptionsContainer.style.display === "none") createPostOptionsContainer.style.display = "block";
    }
  }
}
</script>
<style lang="scss" scoped>
.select-audience-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  width: 250px;
  height: 40px;
  background: #F3F3F3;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
  padding: 7px 10px 7px 10px;
}
</style>
