import moment from "moment-timezone"
import {
    ACCESS,
    ALBUM, ANNOUNCEMENT,
    ARTICLE_POST_CATEGORIES,
    CATEGORY,
    CONTENT, CURRENT_PAGE,
    ERROR, LAST_FETCH,
    LINKS, LIST,
    MENTION_LIST, OTHER_USER_ID, PAGE_ACTIVE_TAB, PAGE_POSTS,
    PICTURES,
    POLL_EXPIRY,
    POLL_OPTION,
    POST, REPOST_COUNT,
    POST_ACTIVE_TAB,
    POST_ACTIVITIES,
    POST_COLOR,
    POST_COLOR_TEXT,
    POST_ERROR,
    POST_LIMIT,
    POST_LIST,
    POST_OFFSET,
    ALL_HASH_TAGS,
    TOTAL_TAGS_COUNT,
    POST_FOLLOWER_TAG,
    POST_TOTAL, POST_TYPE,
    POST_TAG,
    POST_VIDEOS,
    SHORT_CONTENT,
    SINGLE_POST,
    SUCCESS,
    TAGS_LIST,
    TYPE,
    TYPE_DESCRIPTION,
    ARTICLE,
    EDIT,
    MEDIA_OFFSET,
    MEDIA_LIMIT,
    MEDIA_ALBUM,
    MEDIA_LIST,
    MEDIA_TOTAL,
    CITY,
    COUNTRY,
    TRENDING_VIEW_CHECK, POST_TYPE_ID, MENTION_PAGES, VIDEOS
} from "../types";
import * as Utils from "../../components/utils/Utils";
import {
    addPost,
    deletePosts,
    editPost,
    getActivities,
    getIndividualPost,
    getPosts,
    getTrendingList,
    getUserLikedPosts,
    getUserPosts,
    onOfCommenting,
    reactPost,
    deleteComments,
    reportPosts,
    getSelectedPhotos,
    sharePostViaEmail,
    deleteMedia,
    getUserPostsArticleSection,
    getSavedPosts,
    savePost,
    unsavePost
} from "../../apis/APIs";
import Vue from "vue";
import createFormData  from "../../mixins/common";
import {removeExtraHashes}  from  "../../mixins/common";
import Integer from "vuelidate/lib/validators/integer";
const INITIAL_STATE = {
    [POST_ACTIVE_TAB]: 'discover_posts',
    [POST]: {
        [POST_COLOR]: null,
        [POST_COLOR_TEXT]: null,
        [ACCESS]: 'public',
        [CONTENT]: null,
        [SHORT_CONTENT]: null,
        [CATEGORY]: 'default',
        [ANNOUNCEMENT]: null,
        [TYPE]: null,
        [TYPE_DESCRIPTION]: null,
        [POST_TYPE_ID]: null,
        [ALBUM]: null,
        [POLL_OPTION]: [],
        [POLL_EXPIRY]: null,
        [TAGS_LIST]: [],
        [MENTION_LIST]: [],
        [MENTION_PAGES]: [],
        [LINKS]: [],
        [PICTURES]: [],
        [POST_VIDEOS]: [],
        [REPOST_COUNT]: 0,
    },
    [EDIT]: Boolean,
    [ARTICLE] : null,
    [POST_ERROR]: null,
    [POST_TYPE]: '',
    [ALL_HASH_TAGS]: [],
    [TOTAL_TAGS_COUNT]: undefined,
    [POST_FOLLOWER_TAG]: [],
    [POST_TAG]: '',
    [POST_LIST]: [],
    [SINGLE_POST]: {},
    [POST_LIMIT]: 8,
    [POST_OFFSET]: 0,
    [POST_TOTAL]: 0,
    [POST_ACTIVITIES]: [],
    [OTHER_USER_ID]: null,
    [MEDIA_LIMIT]: 8,
    [MEDIA_OFFSET]: 0,
    [MEDIA_ALBUM]: [],
    [CITY]: '',
    [COUNTRY]: '',
    [TRENDING_VIEW_CHECK]: Boolean,
}
export const state = {...INITIAL_STATE};

export const actions = {
    async post({state, commit, dispatch}, {global_type = '', page_id = null, announcement = null, post_id = null, deletMedia= []}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                if (announcement) {
                    commit('SET_POST_ANNOUNCEMENT', announcement)
                }
                const postFormData = state[POST];
                const formData = new FormData();
                for (const field in postFormData) {
                    if (Array.isArray(postFormData[field])) {
                        if (postFormData[field].length > 0) {
                            for (let i = 0; i < postFormData[field].length; i++) {
                                formData.append(field + '[' + i + ']', postFormData[field][i]);
                            }
                        }
                    } else {
                        if (!!(postFormData[field])) {
                            formData.append(field, postFormData[field]);
                        }
                    }
                }
                if (state[POST][CONTENT]) {
                    let taglist = []
                    let html = state[POST][CONTENT]
                    let div = document.createElement("div")
                    div.innerHTML = html;
                    taglist = div.innerText
                    if (taglist.includes('#')) {
                        taglist = taglist.split(' ')
                        const startsWithHash = taglist.filter((e) => e.startsWith("#"));
                        const filteredData = removeExtraHashes(startsWithHash)
                        for (let i = 0; i < filteredData.length; i++) {
                                formData.append('tag_list' + "[" + i + "]", filteredData[i]);
                        }
                    }
                }
                if (state[POST][SHORT_CONTENT]) {
                    let taglist = []
                    let html = state[POST][SHORT_CONTENT]
                    let div = document.createElement("div")
                    div.innerHTML = html;
                    taglist = div.innerText
                    if (taglist.includes('#')) {
                        taglist = taglist.split(' ')
                        const startsWithHash = taglist.filter((e) => e.startsWith("#"));
                        const filteredData = removeExtraHashes(startsWithHash)
                        for (let i = 0; i < filteredData.length; i++) {
                                formData.append('tag_list' + "[" + i + "]", filteredData[i]);
                        }
                    }
                }
                if (global_type && page_id) {
                    formData.append('global_type', global_type);
                    formData.append('page_id', page_id);
                } else if(post_id) {
                    formData.append('post_id', post_id);
                    // formData.append('category', 'share_profile');
                }
                if(deletMedia.length > 0){
                    for (let i = 0; i < deletMedia.length; i++) {
                        formData.append('deleteMedia[' + i + ']', deletMedia[i]);
                    }
                }
                const {data} = await addPost(formData);
                const {information} = data
                // load latest tags with updated counts
                if(information.tags.length > 0)
                    dispatch('getTrendingList');
                if(post_id === null){
                    commit('ADD_POST_IN_POSTS', information)
                } else if(post_id !== null){
                    commit('ADD_UPDATE_POST_IN_POSTS', information)
                }
                if (announcement) {
                    commit('ADD_ANNOUNCEMENT_POST_OF_PAGE_IN_POSTS', information)
                } else {
                    commit('ADD_POST_OF_PAGE_IN_POSTS', information)
                }
                commit('SET_CONTENT', null)
                commit('SET_PAGE_ACTIVE_TAB', 'posts')
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false);
              
                if (error.data && error.data.errors) {
                  const errorMessage = error.data.errors[0].message;
              
                  if (errorMessage === 'The artwork field is required.') {
                    state[POST_ERROR] = 'Please Select some Pictures';
                  } else if (errorMessage === 'The video field is required.') {
                    state[POST_ERROR] = 'Please Select some Video';
                  }
                } else {
                  state[POST_ERROR] = '';
                }
              
                reject({
                  status: ERROR,
                  message: error && error.message ? error.message : "Please try again.",
                });
              }
        })
    },
    async addArticle({state, commit, dispatch},  { Article, global_type = '', page_id = null , post_id = null, deleteMedia = []}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const articleFormData = Article
                const formData = new FormData();
                formData.append('title', articleFormData.title);
                let payload = {links: [] ,length: [],content: articleFormData.full_content}
                commit('SET_CONTENT', payload)
                formData.append('full_content', state[POST][CONTENT]);
                formData.append('short_content', articleFormData.short_content);
                formData.append('category', articleFormData.category);
                formData.append('access', state[POST][ACCESS].toLowerCase());
                formData.append('post_type', "article");
                formData.append('post_type_description', "post and article");
                if(deleteMedia.length > 0){
                    formData.append('deleteMedia[' + 0 + ']', deleteMedia);
                }
                if (articleFormData.artwork.length >= 0) {
                    for (let i = 0; i < articleFormData.artwork.length; i++) {
                        formData.append('artwork[' + i + ']', articleFormData.artwork[i]);
                    }
                }
                if (articleFormData.tag_list.length > 0) {
                    const filteredData = removeExtraHashes(articleFormData.tag_list)
                    for (let i = 0; i < filteredData.length; i++) {
                        formData.append('tag_list[' + i + ']', filteredData[i]);
                    }
                }
                if (articleFormData.article_category.length > 0) {
                    for (let i = 0; i < articleFormData.article_category.length; i++) {
                        formData.append('article_category[' + i + ']', articleFormData.article_category[i]);
                    }
                }
                if (global_type && page_id) {
                    formData.append('global_type', global_type);
                    formData.append('page_id', page_id);
                } else if(post_id){
                    formData.append('post_id', post_id);
                }
                const {data} = await addPost(formData);
                const {information} = data
                if(post_id !== null){
                    commit('ADD_UPDATE_POST_IN_POSTS', information)
                } else{
                    commit('ADD_POST_IN_POSTS', information)
                }
                commit('SET_CONTENT', null)
                dispatch('loading', false)
                commit('CLEAR_POST')
                resolve({data});
            } catch (error) {
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async getPost({state, commit, dispatch}, showMore = false) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                    const {data} = await getPosts(state[POST_TYPE],state[POST_TAG],state[CITY],state[COUNTRY],state[POST_FOLLOWER_TAG], state[POST_OFFSET], state[POST_LIMIT]);
                    const {information} = data
                    if (showMore) {
                        commit('JOIN_POSTS_WITH_OTHER_POSTS', information.data)

                    } else {
                        commit('GET_POSTS', information.data)
                    }
                    commit('SET_TOTAL_POSTS', information.total)
                    dispatch('loading', false)
                    resolve({
                        status: SUCCESS,
                        message: "",
                        data
                    });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getSelectedPhoto({state, commit, dispatch}, showMore = false) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                    const {data} = await getSelectedPhotos(state[MEDIA_ALBUM],state[MEDIA_OFFSET], state[MEDIA_LIMIT]);
                    const {information} = data
                    if (showMore) {
                        commit('JOIN_MEDIA_WITH_OHTER_MEDIA', information.data)

                    } else {
                        commit('GET_MEDIA', information.data)
                    }
                    commit('SET_TOTAL_MEDIA', information.total)
                    dispatch('loading', false)
                    resolve({
                        status: SUCCESS,
                        message: "",
                        data
                    });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getUserLikedPost({state, commit, dispatch}, showMore = false) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const {data} = await getUserLikedPosts(state[POST_TYPE], state[POST_OFFSET], state[POST_LIMIT]);
                const {information} = data
                if (showMore) {
                    commit('JOIN_POSTS_WITH_OTHER_POSTS', information.data)

                } else {
                    commit('GET_POSTS', information.data)
                }
                commit('SET_TOTAL_POSTS', information.total)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getUserPosts({state, commit, dispatch}, {showMore = false, loader = false, search = ''}) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!loader) {
                    dispatch('loading', true)
                }
                if(search.length <= 0){
                    const {data} = await getUserPosts(state[POST_TYPE],  state[POST_OFFSET], state[POST_LIMIT],(state[POST_LIMIT])?state[OTHER_USER_ID]:null, state[POST_TAG]);
                    const {information} = data
                    if (showMore) {
                        commit('JOIN_POSTS_WITH_OTHER_POSTS', information.data)

                    } else {
                        commit('GET_POSTS', information.data)
                    }
                    commit('SET_TOTAL_POSTS', information.total)
                    dispatch('loading', false)
                    resolve({
                        status: SUCCESS,
                        message: "",
                        data
                    });
                }
                else{
                    const {data} = await getUserPostsArticleSection(state[POST_TYPE],  state[POST_OFFSET], state[POST_LIMIT],(state[POST_LIMIT])?state[OTHER_USER_ID]:null, search, state[POST_TAG]);
                    const {information} = data
                    if (showMore) {
                        commit('JOIN_POSTS_WITH_OTHER_POSTS', information.data)

                    } else {
                        commit('GET_POSTS', information.data)
                    }
                    commit('SET_TOTAL_POSTS', information.total)
                    dispatch('loading', false)
                    resolve({
                        status: SUCCESS,
                        message: "",
                        data
                    });
                }

            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async getIndividualPost({state, commit, dispatch}, post_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const {data} = await getIndividualPost(post_id);
                const {information} = data
                commit('GET_SINGLE_POST', information)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                });
            } catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },
    async clearData({state, commit, dispatch}) {
        return new Promise(async (resolve, reject) => {
            try {
                commit('ClEAR_DATA')
            } catch (e) {

            }
        });
    },
    async getActivities({state, commit}) {
        try {
            const {data} = await getActivities();
            const {information} = data
            commit('SET_ACTIVITIES', information)

        } catch (e) {
            console.error(e)
        }

    },
    async replacePostWithNew({state, commit}, {oldId, newPost}) {
        let posts = state[POST_LIST]
        if (posts.length > 0) {
            const replacedPostIndex = posts.findIndex(e => e.id === oldId)
            if (replacedPostIndex !== null) {
                Vue.set(posts, replacedPostIndex, newPost);
                commit('REPLACE_POST_IN_LIST', posts)
            }
        }
    },
    async removePost({state, commit}, postId) {
        let posts = state[POST_LIST]
        if (posts.length > 0) {
            const replacedPostIndex = posts.findIndex(e => e.id === postId)
            if (replacedPostIndex !== null) {
                posts.splice(replacedPostIndex, 1);
                // Vue.set(posts, replacedPostIndex, newPost);
                commit('REPLACE_POST_IN_LIST', posts)
            }
        }
    },
    async postReact({state, commit, dispatch,getters}, {index, reactionAbleType = 'Post', reactionType = 'like', postPage = '', announcement_check= false}) {
        let post;
        let temp;

        if ((postPage === 'clubroom' || postPage === 'partyroom') && announcement_check) {
            const _post = getters.getCurrentPageAnnouncements
            post = _post[index]
            const _temp = getters.getCurrentPageAnnouncements
            temp = _temp[index]
        } else if (postPage === 'artist' || postPage === 'company' || postPage === 'clubroom' || postPage === 'partyroom') {
            const _post = getters.getCurrentPagePosts
            post = _post[index]
            const _temp = getters.getCurrentPagePosts
            temp = _temp[index]
        } else if (postPage === 'single_post') {
            post = state[SINGLE_POST]
            temp = state[SINGLE_POST]
        }else {
            post = state[POST_LIST][index]
            temp = state[POST_LIST][index]
        }

        try {
            dispatch('loading', true)
            let obj = {
                reaction_able_id: post.id,
                reaction_able_type: reactionAbleType,
                reaction_type: reactionType
            }

            if (post.reacted) {
                await Vue.set(post, 'reacted', null)
                const index = post.reactions.findIndex(e => e.type = reactionType)
                if (index !== -1) {
                    post.reactions[index].count = --post.reactions[index].count
                }
            } else {
                await Vue.set(post, 'reacted', obj)
                const index = post.reactions.findIndex(e => e.type = reactionType)
                if (index !== -1) {
                    post.reactions[index].count = ++post.reactions[index].count
                } else {
                    post.reactions.push({
                        type: reactionType,
                        count: 1
                    })
                }
            }

            if ((postPage === 'clubroom' || postPage === 'partyroom') && announcement_check) {
                commit('SET_CURRENT_PAGE_ANNOUNCEMENT_POST', {payload: post,  index:index})
            }
            else if (postPage === 'artist' || postPage === 'company' || postPage === 'clubroom' || postPage === 'partyroom') {
                commit('GET_PAGE_SINGLE_POST', { payload:post, index:index })
            } else if (postPage === 'single_post') {
              state[SINGLE_POST] = post
            } else {
                state[POST_LIST][index] = post
            }

            dispatch('loading', false)
            let {success} = await dispatch('reactOrUnReact', obj)

            if (!(Boolean(success)) && postPage === 'artist') {
                Vue.set(post, 'reacted', (post.reacted) ? null : obj)
                commit('GET_PAGE_SINGLE_POST', { payload:post, index:index })
            } else if (!(Boolean(success))) {
                Vue.set(post, 'reacted', (post.reacted) ? null : obj)
                state[POST_LIST][index] = temp
            }
        } catch (e) {
            console.error(e)
            Vue.set(post, 'reacted', (post.reacted) ? null : obj)
        }
    },
    async reportPost({state, commit, dispatch}, { report_type, post_id , title , message = ''} ) {
        return new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append('reportable_type', report_type);
                formData.append('reportable_id', post_id);
                formData.append('title', title);
                if(message !== ''){
                    formData.append('message', message);
                }
                dispatch('loading', true)
                const {data} = await reportPosts(formData);
                dispatch('loading', false)
                resolve({data});
            } catch (error) {
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async reactOrUnReact({state, commit}, reactObject) {
        return new Promise(async (resolve, reject) => {
            try {
                const formData = new FormData();
                for (const field in reactObject) {
                    if (!!(reactObject[field])) {
                        formData.append(field, reactObject[field]);
                    }
                }
                const {data} = await reactPost(formData);
                resolve(data);
            } catch (e) {
                reject(e)
            }
        });
    },
    async sharePost({state, commit, dispatch}, {post_type_id, category, post_type,post_type_description, global_type, page_id, post_id }) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const formData = new FormData();
                formData.append('category', category);
                formData.append('post_type_id', post_type_id);
                formData.append('post_type', post_type);
                formData.append('post_type_description', post_type_description);

                if (global_type) {
                    formData.append('global_type', global_type)
                }
                if (page_id) {
                    formData.append('page_id', page_id)
                }
                if (post_id !== '') {
                    formData.append('post_id', post_id)
                }
                if (state[POST][CONTENT]) {
                    formData.append('full_content', state[POST][CONTENT]);
                }
                formData.append('access', state[POST][ACCESS].toLowerCase());
                for (let i = 0; i < state[POST][TAGS_LIST].length; i++) {
                    formData.append('tag_list[' + i + ']', state[POST][TAGS_LIST][i]);
                }
                for (let i = 0; i < state[POST][MENTION_LIST].length; i++) {
                    formData.append('mentioned_list[' + i + ']', state[POST][MENTION_LIST][i]);
                }
                for (let i = 0; i < state[POST][MENTION_PAGES].length; i++) {
                    formData.append('mentioned_pages[' + i + ']', state[POST][MENTION_PAGES][i]);
                }
                const {data} = await addPost(formData);
                const {information} = data
                if(post_id === ''){
                    commit('ADD_POST_IN_POSTS', information)
                }
                if(post_id !==  ''){
                    commit('ADD_UPDATE_POST_IN_POSTS', information)
                }
                commit('SET_CONTENT', null)
                dispatch('loading', false)
                resolve({data});
            } catch (error) {
                console.log(error, 'error')
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async deletePost({state, commit, dispatch}, post_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const {data} = await deletePosts(post_id);
                const {success} = data
                if (Number(success)) {
                    dispatch('removePost', post_id)
                }
                commit('SET_CONTENT', null)
                dispatch('loading', false)
                resolve({data});
            } catch (error) {
                console.log(error, 'error')
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async deleteComment({state, commit, dispatch}, comment_id) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const {data} = await deleteComments(comment_id);
                dispatch('loading', false)
                resolve({data});
            } catch (error) {
                console.log(error, 'error')
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async unshiftPinPost ({state, commit}, post) {
        let posts = state[POST_LIST]
        if (posts.length > 0) {
           state[POST_LIST].unshift(post)
        }
    },
    async editPost({state, commit, dispatch}, { payload, post_id, check }) {
        return new Promise(async (resolve, reject) => {
            try {
                if (check) {
                    const {data} = await editPost(payload, post_id);
                    const {success, message, information} = data
                    if (success === 1) {
                        const obj =  {
                            oldId: post_id,
                            newPost: information
                        }
                        dispatch('replacePostWithNew', obj)
                    }
                    resolve({
                        status: success,
                        message: message
                    });
                }
            } catch (error) {
                console.log(error, 'error')
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async onOfCommenting({state, commit, dispatch}, { payload, post_id }) {
        return new Promise(async (resolve, reject) => {
            try {
                const {data} = await onOfCommenting(payload, post_id);
                const {success, message, information} = data
                if (success === 1) {
                    const obj =  {
                        oldId: post_id,
                        newPost: information
                    }
                    dispatch('replacePostWithNew', obj)
                    commit('GET_SINGLE_POST', information)
                }
                resolve({
                    status: success,
                    message: message,
                });
            } catch (error) {
                console.log(error, 'error')
                dispatch('loading', false)
                reject(error);
            }
        })
    },
    async sharePostViaEmail({state, commit, dispatch}, payload ) {
        return new Promise(async (resolve, reject) => {
            try {
                const formData = createFormData(payload)
                dispatch('loading', true)
                const {data} = await sharePostViaEmail(formData);
                dispatch('loading', false)
                resolve({data});
            } catch (error) {
                dispatch('loading', false)
                reject(error);
            }
        })
    },

    //-------------------    SAVED POSTS   -------------------//
    async saveNewPost({state, commit, dispatch, getters}, {id}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await savePost(id);
                const {success, message} = data;
                commit('UPDATE_POST_LIST', { id, status: 1 })
                resolve({
                    status: SUCCESS,
                    message: message
                });
            }
            catch (error) {
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async unsavePost({state, commit, dispatch}, {id, activeTab = ''}) {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await unsavePost(id);
                const {success, message} = data;
                
                if(activeTab === 'save_posts') {
                    const savedPosts = data.information.map(obj => {
                        return {
                            ...obj,
                            savedPost: 1
                        }
                    })
                    commit('GET_POSTS', savedPosts)
                }
                else {
                    commit('UPDATE_POST_LIST', { id, status: 0 })
                }
                resolve({
                    status: SUCCESS,
                    message: message
                });
            }
            catch (error) {
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async getSavedPosts({state, commit, dispatch}, showMore = false){
        // get all posts
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                const { data } = await getSavedPosts();
                const savedPosts = data.information.map(obj => {
                    return {
                        ...obj,
                        savedPost: 1
                    }
                })
                if (showMore) {
                    commit('JOIN_POSTS_WITH_OTHER_POSTS', savedPosts)
                }
                else {
                    commit('GET_POSTS', savedPosts)
                }
                commit('SET_TOTAL_POSTS', data.count)
                dispatch('loading', false)
                resolve({
                    status: SUCCESS,
                    message: "",
                    data
                });
            }
            catch (error) {
                dispatch('loading', false)
                reject({
                    status: ERROR,
                    message: error.message ? error.message : "Please try again."
                });
            }
        })
    },

    //-------------------  GET ALL TRENDING TAGS   -------------------//
    async getTrendingList({ state, commit, dispatch }, { offset = 0, limit = 10 } = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                dispatch('loading', true)
                let {data} = await getTrendingList(offset, limit);
                // update state
                state[ALL_HASH_TAGS] = data.information.data;
                state[TOTAL_TAGS_COUNT] = data.information.total
                dispatch('loading', false)
            } catch (error) {
                dispatch('loading', false)
                // reject({
                //     status: ERROR,
                //     message: error.message ? error.message : "Please try again."
                // });
                console.log(error)
            }
        })
      },
}
export const mutations = {
    SET_REPOST_COUNT(state, payload) {
        try {
            console.log('payload',payload)
            state[POST][REPOST_COUNT] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post count')
        }
    },
    SET_ACCESS(state, payload) {
        try {
            state[POST][ACCESS] = payload.toLowerCase()
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_CATEGORY(state, payload) {
        try {
            state[POST][CATEGORY] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post category')
        }
    },
    SET_CONTENT(state, payload) {
        try {
            if (payload && payload.content) {
                let counterLink = 0
                let words = payload.content.split(' ')
                var vari = 0
                for(const key in words){
                    if(words[key].startsWith("@") || words[key].startsWith("#")){
                        if(words[key].startsWith("@")){
                            words[key] = payload.links[counterLink]
                            for(let i=1; i<=payload.lengthName[counterLink] ; i++){
                                words[vari + i] = ' '
                            }
                            counterLink= counterLink + 1
                        }else {
                            while (words[key].charAt(0) === '#'){
                                words[key] = words[key].substring(1)
                            }
                            words[key] = '#' + words[key]
                            words[key] =`<a class="link-text" href="#"'>${words[key]} </a>`
                        }
                    }
                    vari = vari +1
                }
                state[POST][CONTENT] =words.join(" ")
            }
        } catch (e) {
            console.error(e.message, 'unable to set Post CONTENT')
        }
    },
    SET_SHORT_CONTENT(state, payload) {
        try {
            if (payload && payload.content) {
                let counterLink = 0
                let words = payload.content.split(" ")
                var vari = 0
                for(const key in words){
                    if(words[key].startsWith("@") || words[key].startsWith("#")){
                        if(words[key].startsWith("@")){
                            words[key] = payload.links[counterLink]
                            for(let i=1; i<=payload.lengthName[counterLink] ; i++){
                                words[vari + i] = ' '
                            }
                            counterLink= counterLink + 1
                        }else {
                            while (words[key].charAt(0) === '#'){
                                words[key] = words[key].substring(1)
                            }
                            words[key] = '#' + words[key]
                            words[key] =`<a class="link-text" href="#"'>${words[key]} </a>`
                        }
                    }
                    vari = vari + 1
                }
                state[POST][SHORT_CONTENT] = words.join(" ")
            }
        } catch (e) {
            console.error(e.message, 'unable to set Post CONTENT')
        }
    },
    SET_TYPE(state, payload) {
        try {
            state[POST][TYPE] = payload

        } catch (e) {
            console.error(e.message, 'unable to set Post CONTENT')
        }
    },
    SET_TYPE_DESCRIPTION(state, payload) {
        try {
            state[POST][TYPE_DESCRIPTION] = payload

        } catch (e) {
            console.error(e.message, 'unable to set Post CONTENT')
        }
    },
    SET_POST_TYPE_ID(state, payload) {
        try {
            state[POST][POST_TYPE_ID] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post CONTENT')
        }
    },
    SET_POST_ACTIVE_TAB(state, payload) {
        try {
            state[POST_ACTIVE_TAB] = payload
        } catch (e) {
            console.error(e.message, 'unable to SET Check for Post in new Tab')
        }
    },
    SET_TAGS(state, payload) {
        try {
            state[POST][TAGS_LIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post TAGS')
        }
    },
    SET_MENTION_LIST(state, payload) {
        try {
            state[POST][MENTION_LIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post TAGS')
        }
    },
    SET_MENTION_PAGES(state, payload) {
        try {
            state[POST][MENTION_PAGES] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post TAGS')
        }
    },
    SET_MENTION_LIST_WITH_IDS(state, payload) {
        try {
            state[POST][MENTION_LIST].push(payload)
        } catch (e) {
            console.error(e.message, 'unable to set Post MENTIONED')
        }
    },
    SET_MENTION_PAGES_WITH_IDS(state, payload) {
        try {
            state[POST][MENTION_PAGES].push(payload)
        } catch (e) {
            console.error(e.message, 'unable to set Post MENTIONED')
        }
    },
    SET_LINKS(state, payload) {
        try {
            state[POST][LINKS] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    SET_ALBUM(state, payload) {
        try {
            state[POST][ALBUM] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    SET_PICTURES(state, payload) {
        try {
            let _state = Utils.parseState(state);
            let post = _state[POST];
            post[PICTURES] = payload
            state[POST] = Object.assign(state[POST], post)
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    SET_VIDEOS(state, payload) {
        try {
            let _state = Utils.parseState(state);
            let post = _state[POST];
            post[POST_VIDEOS] = payload
            state[POST] = Object.assign(state[POST], post)
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    SET_POLL_OPTION(state, payload) {
        try {
            state[POST][POLL_OPTION] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    SET_POLL_EXPIRY(state, payload) {
        try {
            state[POST][POLL_EXPIRY] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    JOIN_POSTS_WITH_OTHER_POSTS(state, payload) {
        let posts = state[POST_LIST]
        state[POST_LIST] = [...posts, ...payload]
    },
    JOIN_MEDIA_WITH_OHTER_MEDIA(state, payload) {
        let media = state[MEDIA_LIST]
        state[MEDIA_LIST] = [...media, ...payload]
    },
    ADD_POST_IN_POSTS(state, payload) {
        try {
            state[POST_LIST].unshift(payload)
        } catch (e) {
            console.error(e.message, 'unable to Add posts')
        }
    },
    ADD_UPDATE_POST_IN_POSTS(state, payload) {
        try {
            state[POST_LIST] = [
                ...state[POST_LIST].map(item =>
                    item.id !== payload.id ? item : {...item, ...payload}
                )
            ]
        } catch (e) {
            console.error(e.message, 'unable to Add posts')
        }
    },
    GET_POSTS(state, payload) {
        try {
            state[POST_LIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to store posts')
        }

    },
    // Update Post List as a reference without after save unsave
    UPDATE_POST_LIST(state, payload) {
        const targetPost = state[POST_LIST];
        const i = targetPost.findIndex(post => post.id === payload.id);
        targetPost[i].savedPost = payload.status;
    },
    GET_MEDIA(state, payload) {
        try {
            state[MEDIA_LIST] = payload
        } catch (e) {
            console.error(e.message, 'unable to media list')
        }

    },
    GET_SINGLE_POST(state, payload) {
        try {
            let single_post = Object.assign({}, state[SINGLE_POST])
            single_post = payload
            state[SINGLE_POST] = single_post
        } catch (e) {
            console.error(e.message, 'unable to store posts')
        }

    },
    SET_TOTAL_POSTS(state, payload) {
        try {
            state[POST_TOTAL] = payload
        } catch (e) {
            console.error(e.message, 'unable to store posts')
        }

    },
    SET_TOTAL_MEDIA(state, payload) {
        try {
            state[MEDIA_TOTAL] = payload
        } catch (e) {
            console.error(e.message, 'unable to store posts')
        }

    },
    SET_POST_ERROR(state, payload) {
        try {
            state[POST_ERROR] = payload
        } catch (e) {
            console.error(e.message, 'unable to set Post LINKS')
        }
    },
    ClEAR_DATA(state) {
        state[POST][CATEGORY] = 'default'
        state[POST][CONTENT] = null
        state[POST][TYPE] = null
        state[POST][TYPE_DESCRIPTION] = null
        state[POST][POST_TYPE_ID] = null
        state[POST][TAGS_LIST] = []
        state[POST][MENTION_LIST] = []
        state[POST][MENTION_PAGES] = []
        state[POST][LINKS] = []
        state[POST][PICTURES] = []
        state[POST][POST_VIDEOS] = []
        state[POST][POLL_OPTION] = []
        state[POST][POLL_EXPIRY] = null
    },
    SET_POST_OFFSET(state, payload) {
        try {
            state[POST_OFFSET] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_POST_LIMIT(state, payload) {
        try {
            state[POST_LIMIT] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_ACTIVITIES(state, payload) {
        try {
            state[POST_ACTIVITIES] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    REPLACE_POST_IN_LIST(state, posts) {
        try {
            state[POST_LIST] = posts
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_OTHER_USER_ID(state, ID) {
        try {
            state[OTHER_USER_ID] = ID
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    EXTRACT_MENTIONS_AND_TAGS_FROM_CONTENT(state){
        try {
            let words = state[POST][CONTENT].split(" ")
            for(const key in words){
                if(words[key].startsWith("@")||words[key].startsWith("#")){
                    words[key] =`<a class="link-text" href="/home/page/user-profile/${words.substring(1)}"'>${words[key]} </a>`
                }
            }
            state[POST][CONTENT] =words.join(" ")
        } catch (e) {
            console.error(e.message, 'unable to set post access option')
        }
    },
    SET_POST_TYPE(state, type) {
        try {
            state[POST_TYPE] = type
        } catch (e) {
            console.error(e.message, 'unable to set post type')
        }
    },
    SET_TAG_TYPE(state, tag) {
        try {
            state[POST_TAG] = tag
        } catch (e) {
            console.error(e.message, 'unable to set post type')
        }
    },
    SET_POST_FOLLOWER_TAG(state, tag) {
        try {
            state[POST_FOLLOWER_TAG] = tag
        } catch (e) {
            console.error(e.message, 'unable to set post type')
        }
    },
    SET_POST_COUNTRY(state, country) {
        try {
            state[COUNTRY] = country
        } catch (e) {
            console.error(e.message, 'unable to set post type')
        }
    },
    SET_POST_CITY(state, city) {
        try {
            state[CITY] = city
        } catch (e) {
            console.error(e.message, 'unable to set post type')
        }
    },
    SET_POST_ANNOUNCEMENT(state, payload) {
        try {
            state[POST][ANNOUNCEMENT] = payload
        } catch (e) {
            console.error(e.message, 'unable to set post announcement')
        }
    },
    SET_ARTICLE(state, payload) {
        try {
            state[ARTICLE] = payload
        } catch (e) {
            console.error(e.message, 'unable to set article post')
        }
    },
    
    CLEAR_POST(state) {
        try {
            state[POST][CONTENT] = null
        } catch (e) {
            console.error(e.message, 'unable to clear post')
        }
    },
    SET_EDIT(state, payload) {
        try {
            state[EDIT] = payload
        } catch (e) {
            console.error(e.message, 'unable to set article post')
        }
    },
    RESET_EDIT(state) {
        try {
            state[EDIT] = false
        } catch (e) {
            console.error(e.message, 'unable to set article post')
        }
    },
    SET_MEDIA_OFFSET(state, payload){
        try {
            state[MEDIA_OFFSET] = payload
        } catch (e) {
            console.error(e.message, 'unable to set media offset')
        }
    },
    SET_MEDIA_ALBUM(state, payload){
        try {
            state[MEDIA_ALBUM] = payload
        } catch (e) {
            console.error(e.message, 'unable to set media album')
        }
    },
    SET_MEDIA_LIMIT(state, payload){
        try {
            state[MEDIA_LIMIT] = payload
        } catch (e) {
            console.error(e.message, 'unable to set media limit')
        }
    },
    SET_TRENDING_VIEW_CHECK(state, payload) {
        try {
            state[TRENDING_VIEW_CHECK] = payload
        } catch (e) {
            console.error(e.message, 'unable to store trending check')
        }

    },

}

const getters = {
    getRepostCount(state) {
        let _state = Utils.parseState(state);
        return _state[POST][REPOST_COUNT];
    },
    getAccess(state) {
        let _state = Utils.parseState(state);
        return _state[POST][ACCESS];
    },
    getCategory(state) {
        let _state = Utils.parseState(state);
        return _state[POST][CATEGORY];
    },
    getContent(state) {
        let _state = Utils.parseState(state);
        return _state[POST][CONTENT];
    },
    getShortContent(state) {
        let _state = Utils.parseState(state);
        return _state[POST][SHORT_CONTENT];
    },
    getType(state) {
        let _state = Utils.parseState(state);
        return _state[POST][TYPE];
    },
    getTypeDescription(state) {
        let _state = Utils.parseState(state);
        return _state[POST][TYPE_DESCRIPTION];
    },
    getPostTypeId(state) {
        let _state = Utils.parseState(state);
        return _state[POST][POST_TYPE_ID];
    },
    getTags(state) {
        let _state = Utils.parseState(state);
        return _state[POST][TAGS_LIST];
    },
    getLinks(state) {
        let _state = Utils.parseState(state);
        return _state[POST][LINKS];
    },
    getAlbumTitle(state) {
        let _state = Utils.parseState(state);
        return _state[POST][ALBUM];
    },
    getPictures(state) {
        let _state = Utils.parseState(state);
        return _state[POST][PICTURES];
    },
    getVideos(state) {
        let _state = Utils.parseState(state);
        return _state[POST][POST_VIDEOS];
    },
    getPollOptions(state) {
        let _state = Utils.parseState(state);
        return _state[POST][POLL_OPTION];
    },
    getPollExpiry(state) {
        let _state = Utils.parseState(state);
        return _state[POST][POLL_EXPIRY];
    },
    getPostError(state) {
        let _state = Utils.parseState(state);
        return _state[POST_ERROR];
    },
    getPosts(state) {
        let _state = Utils.parseState(state);
        return _state[POST_LIST];
    },
    getTagPosts(state) {
        let _state = Utils.parseState(state);
        return _state[POST_TAG];
    },
    getSinglePost(state) {
        let _state = Utils.parseState(state);
        return _state[SINGLE_POST];
    },
    getTotalPosts(state) {
        let _state = Utils.parseState(state);
        return _state[POST_TOTAL];
    },
    getPostOffset(state) {
        let _state = Utils.parseState(state);
        return _state[POST_OFFSET];
    },
    getPostsLimit(state) {
        let _state = Utils.parseState(state);
        return _state[POST_LIMIT];
    },
    getMediaOffset(state) {
        let _state = Utils.parseState(state);
        return _state[MEDIA_OFFSET];
    },
    getMediaLimit(state) {
        let _state = Utils.parseState(state);
        return _state[MEDIA_LIMIT];
    },
    getPostsActivities(state) {
        let _state = Utils.parseState(state);
        return _state[POST_ACTIVITIES];
    },

    getOtherUserID(state) {
        let _state = Utils.parseState(state);
        return _state[OTHER_USER_ID];
    },
    getPostActiveTab(state) {
        let _state = Utils.parseState(state);
        return _state[POST_ACTIVE_TAB];
    },
    getArticlePost(state) {
        let _state = Utils.parseState(state);
        return _state[ARTICLE];
    },
    getEditPost(state) {
        let _state = Utils.parseState(state);
        return _state[EDIT];
    },
    getTrendingViewCheck(state) {
        let _state = Utils.parseState(state);
        return _state[TRENDING_VIEW_CHECK];
    },
    getPostType(state) {
        let _state = Utils.parseState(state);
        return _state[POST_TYPE];
    },
    getMentionedList(state) {
        let _state = Utils.parseState(state);
        return _state[POST][MENTION_LIST];
    },
    getMentionedPages(state) {
        let _state = Utils.parseState(state);
        return _state[POST][MENTION_PAGES];
    },

    // ------ Hash tags
    getTrendingList(state) {
        let _state = Utils.parseState(state);
        return _state[ALL_HASH_TAGS];
    },
    getHashTagCount(state) {
        let _state = Utils.parseState(state);
        return _state[TOTAL_TAGS_COUNT];
    }
}
export default {
    state,
    actions,
    mutations,
    getters
};
