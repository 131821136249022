<template>
  <div class="statistics mt-0">
    <div class="stat-header">
      <h1>Statistics</h1>
      <div class="who-see">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.5 5.5H2.5C1.94772 5.5 1.5 5.94772 1.5 6.5V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V6.5C10.5 5.94772 10.0523 5.5 9.5 5.5Z"
              stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M3.5 5.5V3.5C3.5 2.83696 3.76339 2.20107 4.23223 1.73223C4.70107 1.26339 5.33696 1 6 1C6.66304 1 7.29893 1.26339 7.76777 1.73223C8.23661 2.20107 8.5 2.83696 8.5 3.5V5.5"
              stroke="#8B949F" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p>ONLY YOU SEE THIS BLOCK</p>
      </div>
    </div>
    <div class="view-sections">
      <div class="view-today">
        <p>Views Today</p>
        <h1>140</h1>
      </div>
      <div class="search-appearance">
        <p>SEARCH APPEREANCES</p>
        <h1>230</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Statistics"
}
</script>

<style scoped>

</style>
