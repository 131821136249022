import {
  ACTIVITY_ID,
  Album_Play_Status, CURRENT_PLAY_ID, CURRENT_PLAY_TYPE_ID, CURRENT_PLAYER
}
  from "./types";
import * as Utils from "../components/utils/Utils";
import Vue from "vue";
const INITIAL_STATE = {
  [Album_Play_Status]: null,
  [CURRENT_PLAYER]: null,
  [CURRENT_PLAY_TYPE_ID] : null,
  [CURRENT_PLAY_ID]: null,
  [ACTIVITY_ID]: null
}
export const state = { ...INITIAL_STATE };
export const actions = {
}
export const mutations = {
  Set_Song_status(state, payload) {
    try {
      Vue.set(state, Album_Play_Status, payload);
    } catch (e) {
      console.error(e.message, "unable to set song status");
    }
  },
  SET_CURRENT_PLAYER(state, payload) {
    try {
      Vue.set(state, CURRENT_PLAYER, payload);
    } catch(e) {
      console.error(e.message, "unable to set current player");
    }
  },
  SET_CURRENT_PLAY_ID(state, payload) {
    try {
      Vue.set(state, CURRENT_PLAY_ID, payload);
    } catch(e) {
      console.error(e.message, "unable to set current play id");
    }
  },
  SET_CURRENT_PLAY_TYPE_ID(state, payload){
    try {
      Vue.set(state, CURRENT_PLAY_TYPE_ID, payload);
    } catch(e) {
      console.error(e.message, "unable to set current play type id");
    }
  },
  SET_CURRENT_PLAY_ACTIVITY_ID(state, payload){
    try {
      Vue.set(state,ACTIVITY_ID , payload);
    } catch(e) {
      console.error(e.message, "unable to set current play activity id");
    }
  }
}
const getters = {
  getSongsStatus(state) {
    return state[Album_Play_Status];
  },
  getCurrentPlayer(state) {
    return state[CURRENT_PLAYER];
  },
  getCurrentPlayId(state) {
    return state[CURRENT_PLAY_ID];
  },
  getCurrentPlayTypeId(state){
    return state[CURRENT_PLAY_TYPE_ID];
  },
  getCurrentPlayActivityId(state) {
    return state[ACTIVITY_ID]
  }
}
export default {
state,
actions,
mutations,
getters
};
