<template>
  <div class="collaborators">
    <div class="row" style="background-color: white;
width: 870px;">
      <div class="col-sm-9" style="max-width: 80.45% !important;
flex: 80.45% !important;">
        <div class="row pl-3">
          <div class="col-sm-12 pt-4 pl-1">
            <h5 class="song-heading">Collaborators</h5>
          </div>

          <div v-for="(collaborator, index) in collaborators" :key="index"
            class="row setting-form pr-0 position-relative">
            <div class="col-sm-4 px-2">
              <div class="form-group form-input-multiselect v-bootstrap-select">
                <label>Collaborator</label>
                <v-select v-if="index === 0" :disabled="true"
                          class="remove-action"
                          :class="{ 'is-invalid': $v.collaborators.$each[index][COLLABORATOR_ID].$dirty && !validateStateCollaborator(index, [COLLABORATOR_ID]) }"
                          v-model="$v.collaborators.$each[index][COLLABORATOR_ID].$model"
                          :id="index" label="text" track-by="id" :options="[{id: myPageId, value: myPageId, text: 'You'}]"
                          :loading="!!(userLoading)" :state="validateStateCollaborator(index, [COLLABORATOR_ID])"
                          placeholder="Search" :reduce="(option) => option.id"
                ><template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.text }}
                  </div>
                </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template></v-select>
                <v-select v-else :class="{ 'is-invalid': $v.collaborators.$each[index][COLLABORATOR_ID].$dirty && !validateStateCollaborator(index, [COLLABORATOR_ID]) }" v-model="$v.collaborators.$each[index][COLLABORATOR_ID].$model"
                  :id="index" label="text" track-by="id" :options="mapPages"
                  :loading="!!(userLoading)" :state="validateStateCollaborator(index, [COLLABORATOR_ID])"
                  placeholder="Search" :reduce="(option) => option.id"
                ><template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.text }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.text }}
                        </div>
                      </template></v-select>
                <div class="invalid-feedback d-block" v-if="$v.collaborators.$each[index][COLLABORATOR_ID].$dirty">
                  <span v-if="!validateStateCollaborator(index, [COLLABORATOR_ID])">
                    This is a required field.
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-4 pl-2">
              <div class="form-group">
                <label>Role</label>
                <b-form-select :id="'COLLABORATOR_ROLE_ID' + index" class="form-control"
                  v-model="$v.collaborators.$each[index][COLLABORATOR_ROLE_ID].$model" :options="collaboratorRoles"
                  :state="validateStateCollaborator(index, [COLLABORATOR_ROLE_ID])" value-field="value"
                  :readonly="index === 0">
                </b-form-select>
                <b-form-invalid-feedback :id="'COLLABORATOR_ROLE_ID' + index">This is a required field.
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="col-sm-4 pl-2">
              <div class="form-group">
                <label>Profit share</label>
                <b-form-input :id="'PROFIT' + index" v-model="$v.collaborators.$each[index][PROFIT].$model"
                  :state="validateStateCollaborator(index, [PROFIT])" class="form-control change-background" type="text" placeholder=""
                  :readonly="index === 0" @change="updateProfit">
                </b-form-input>
                <b-form-invalid-feedback :id="'PROFIT' + index">This is a required field.
                </b-form-invalid-feedback>
                <!-- <div class="invalid-feedback d-block" v-if="isProfitGreaterThen100">-->
                <!-- <span>-->
                <!-- This Sum of profit is not Greater then 100 {{isProfitGreaterThen100}}-->
                <!-- </span>-->
                <!-- </div>-->
              </div>
            </div>
            <div class="col-sm-12" style="padding-left: 8px">
              <hr class="m-0 collaborate-b">
            </div>
            <b-icon v-if="index !== 0" @click="removeCollaborator(index)" class="remove-collaborator-icon cursor-pointer"
              font-scale="1.5" icon="person-x-fill" variant="danger"></b-icon>
          </div>
          <div class="row">
            <div class="col-sm-12 pr-0" style="text-align: center;">
              <div v-if="this.collaborators && this.collaborators.length < 5 && this.collaborators[0].profit !== 0" class="add-collaborator-btn" @click="addCollaboratorInCollaborators()">
                <button class="upload-file-upload-btn" type="submit">
                  <svg class="song-btn-icon-display upload" width="14" height="14" viewBox="0 0 14 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.99908 0C7.42142 0 7.76379 0.34237 7.76379 0.764705V13.2353C7.76379 13.6576 7.42142 14 6.99908 14V14C6.57675 14 6.23438 13.6576 6.23438 13.2353V0.764706C6.23438 0.34237 6.57675 0 6.99908 0V0Z"
                      fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0 6.99908C0 6.57675 0.34237 6.23438 0.764705 6.23438H13.2353C13.6576 6.23438 14 6.57675 14 6.99908V6.99908C14 7.42142 13.6576 7.76379 13.2353 7.76379H0.764706C0.34237 7.76379 0 7.42142 0 6.99908V6.99908Z"
                      fill="white" />
                  </svg>
                  <span class="add-button-lp">ADD COLLABORATOR</span>
                </button>
              </div>
            </div>
            <div class="col-sm-12 pr-0" style="padding-left: 8px">
              <hr class="m-0 collaborate-b">
            </div>
            <div class="col-sm-12">
              <div class="row pl-2 pr-0" style="padding-top: 20px !important">
                <div class="col-sm-12 collaborator-border-box">
                  <div class="col-sm-2 collaborate-icon">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle r="25" transform="matrix(1 0 0 -1 25 25)" fill="#D1433A" />
                      <path
                        d="M25 12.5C31.9036 12.5 37.5 18.0964 37.5 25C37.5 31.9036 31.9036 37.5 25 37.5C18.0964 37.5 12.5 31.9036 12.5 25C12.5 18.0964 18.0964 12.5 25 12.5Z"
                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M25 30V25" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                      <path d="M25 20H25.0125" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>

                  </div>
                  <div class="col-sm-10 px-0 pt-2 colaborator-h">
                    <div class="col-12 pl-0">
                      <h3 class="collaboate-p">Collaborators must meet the below requirements:</h3>
                    </div>
                    <div class="col-12 px-0 collaborators-li-child">
                      <ul class="row mx-0 mb-2 pl-3">
                        <li class="collaborate-li-p" style="width:252px;">You must be the primary collaborator</li>
                        <li class="collaborate-li-p" style="width: 284px">Your collaborator must be registered to AUDDIO</li>
                        <li class="collaborate-li-p" style="width:252px;">Needs to have their Paypal email set up</li>
                        <li class="collaborate-li-p" style="width: 284px">A maximum of 5 collaborators allowed per file</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-58c6911e="" data-v-66e59ad3="" class="col-sm-12 pb-4 pr-0"
              style="padding-left: 8px; padding-top: 30px;">
              <div data-v-58c6911e="" data-v-66e59ad3="" class=" cancel-btn-display">
                <button @click="previousStep" data-v-58c6911e="" data-v-66e59ad3="" class="upload-file-cancel-btn">
                  CANCEL
                </button>
              </div>
              <div data-v-58c6911e="" data-v-66e59ad3="" style="float: right;">
                <div data-v-58c6911e="" data-v-66e59ad3="" class=" save-btn-display">
                  <button data-v-58c6911e="" data-v-66e59ad3="" class="upload-file-save-btn">
                    SAVE TO DRAFT
                  </button>
                </div>
                <div data-v-58c6911e="" data-v-66e59ad3="" class="upload-btn-display">
                  <button @click="saveDataInStore" data-v-58c6911e="" data-v-66e59ad3="" class="upload-file-upload-btn">
                    <span data-v-58c6911e="" data-v-66e59ad3="">NEXT STEP</span>
                    <svg data-v-58c6911e="" width="8" height="14" viewBox="0 0 8 14" fill="none"
                      xmlns="http://www.w3.org/2000/svg" class="basic-info-upload-btn">
                      <path data-v-58c6911e=""
                        d="M0.773189 13.5C0.848551 13.5 0.923913 13.4589 0.961595 13.4178L6.91522 7.21067C7.02826 7.08735 7.02826 6.92292 6.91522 6.7996L0.961596 0.59249C0.848552 0.469169 0.697828 0.469169 0.584784 0.59249C0.47174 0.71581 0.47174 0.880237 0.584784 1.00356L6.31232 7.00514L0.584783 13.0067C0.471739 13.1301 0.471739 13.2945 0.584783 13.4178C0.622464 13.5 0.697826 13.5 0.773189 13.5Z"
                        fill="white" stroke="white" stroke-width="0.5"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 upload-file-p-padding" style="max-width: 19.55%">
        <div class="row" style="padding-top: 28px;">
          <div class="col-sm-12">
            <h5 class="song-heading-1">Collaborators</h5>
          </div>
          <div class="col-sm-12">
            <div>
              <p class="song-p">
                Set up your collaborators and the percentage split so that payments are made automatically without any
                hassle.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { COLLABORATOR_ID, COLLABORATOR_ROLE_ID, PROFIT } from "../../../../store/types";
import { required } from "vuelidate/lib/validators";
import { searchArtists } from "../../../../apis/APIs";
import { mapGetters } from "vuex";
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "Collaborators",
  components:{
    vSelect
  },
  data() {
    return {
      COLLABORATOR_ID,
      COLLABORATOR_ROLE_ID,
      PROFIT,
      collaborators: [],
      newCollaborator: {
        [COLLABORATOR_ID]: null,
        [COLLABORATOR_ROLE_ID]: null,
        [PROFIT]: 0
      },
      users: [],
      userLoading: false,
      pagesData: {},
    };
  },
  mounted() {
    this.$store.dispatch('fetchCollaboratorsRoles');
    this.fetchPages();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    collaboratorRoles() {
      return this.$store.getters.getCollaboratorsRoles.map(role => {
        return {
          value: role.id,
          text: role.title
        }
      })
    },
    // isProfitGreaterThen100() {
    //   return (this.$v.collaborators.$model.reduce((partialSum, a) => partialSum + a[PROFIT], 0) > 100);
    // },
    myPageId() {
      return Number(this.$route.params.id);
    },
    mapPages() {
      return this.pagesData && this.pagesData.map(page => {
        return {
          value: page.id,
          text: page.title,
          id: page.id
        }
      })
    },
  },
  props: {
    type: {
      type: String,
      default: 'song'
    },
    actionType: {
      type: String,
      default: 'createSongAlbum'
    },
  },
  watch: {
    "collaborators": {
      immediate: true,
      handler(value) {
        if (this.collaborators && this.collaborators.length < 1) {
          let collaborator = Object.assign({}, this.newCollaborator)
          collaborator[PROFIT] = 100
          collaborator[COLLABORATOR_ROLE_ID] = 1
          collaborator[COLLABORATOR_ID] = this.myPageId
          this.collaborators.push(collaborator);
        }
      }
    }
  },
  validations: {
    collaborators: {
      $each: {
        [COLLABORATOR_ID]: {
          required
        },
        [COLLABORATOR_ROLE_ID]: {
          required
        },
        [PROFIT]: {
          required
        }
      }
    }
  },
  methods: {
    validateStateCollaborator(index, name) {
      const { $dirty, $error } = this.$v.collaborators.$each[index][name];
      return $dirty ? !$error : null;
    },
    mapUserObjectInCollaborators(collaborators) {
      if (collaborators.length > 0) {
        return collaborators.map(collaborator => {
          collaborator[COLLABORATOR_ID] = (typeof collaborator[COLLABORATOR_ID] === 'object') ? collaborator[COLLABORATOR_ID].id : collaborator[COLLABORATOR_ID]
          return collaborator
        })
      }
      return []
    },
    addCollaboratorInCollaborators() {
      if(this.collaborators && this.collaborators.length < 5 && this.collaborators[0].profit !== 0) {
        const collaborator = Object.assign({}, this.newCollaborator)
        this.collaborators.push(collaborator)
      }
    },
    async saveDataInStore() {
      this.$v.collaborators.$touch();
      if (this.isProfitGreaterThen100 && this.$v.collaborators.$anyError) {
        return;
      } else {
        if (this.type === 'song'){
          await this.$store.commit('SET_SONG_COLLABORATORS_DATA', this.collaborators)
        }else{
          await this.$store.commit('SET_ALBUM_COLLABORATORS_DATA', this.mapUserObjectInCollaborators(this.collaborators))
        }
        this.nextStep()
      }
    },
    async find(type = "Artist", pageTitle) {
      try {
        this.userLoading = true
        await searchArtists(type, pageTitle)
        this.userLoading = false
      } catch (e) {
        this.userLoading = false
        console.error(e)
      }
    },
    fetchPages(type = "Artist", offset = 0, limit = 20) {
      let token = localStorage.getItem('token');
      try {
        axios.get(
          `https://auddiodev-api.theplayground.co.uk/api/get/page/all/${type}?offset=${offset}&limit=${limit}`,{
            headers : {
              Authorization: token,
             }
          }
        ).then(response => {
          this.pagesData = response.data.information.data;
        }).catch(error => {
            console.log("Error Message ===> ", error.message);
        })
      } catch (e) {
        console.log(e.message);
      }
    },

    previousStep() {
      this.$emit('previousStep')
    },
    nextStep() {
      this.$emit('nextStep')
    },
    removeCollaborator(index) {
      this.collaborators[0].profit = parseInt(this.collaborators[0].profit) + parseInt(this.collaborators[this.collaborators.length - 1].profit)
      this.collaborators.splice(index, 1)
    },
    updateProfit() {
      if (this.collaborators.length > 1) {
        if(this.collaborators[0].profit > this.collaborators[this.collaborators.length - 1].profit) {
          this.collaborators[0].profit = this.collaborators[0].profit - this.collaborators[this.collaborators.length - 1].profit
        } else {
          this.collaborators[this.collaborators.length - 1].profit = this.collaborators[0].profit
          this.collaborators[0].profit = 0
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.collaborators {
  .remove-collaborator-icon {
    width: auto;
    position: absolute;
    right: 0px;
  }
}

.add-collaborator-btn {
  display: inline-block;
  width: 230px;
  padding-top: 39px;
  padding-bottom: 59px;
}

.save-btn-display {
  display: inline-block;
  width: 150px;
  padding-right: 10px;
}

.upload-btn-display {
  display: inline-block;
  width: 130px;
}

.upload-file-cancel-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background: #e6eaf0;
  color: #47505b;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}

.upload-file-upload-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  background-color: #D1433A;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
}

.upload-file-save-btn {
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
  border: 1px solid #D1433A !important;
  background-color: #ffffff;
  color: #D1433A;
  padding: 8px 12px;
  font-size: 13px;
  border: none;
  font-family: "HelveticaNeueMedium";
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.05em;
}

.song-btn-icon-display {
  &.upload {
    left: -10px;
    top: -1px !important;
  }

  display: inline-block;
  position: relative;
  top: -2px;
}

.add-button-lp {
  letter-spacing: 0.07em;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.collaboate-p {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.9;
  margin-bottom: 16px;
  color: #071526;
}

.collaborate-b {
  margin-bottom: 16px !important;
  color: #E6EAF0;
}

.collaborators-li-child ul li::marker {
  color: #97AEFF;
}

.upload-file-p-padding {
  padding: 0 19px 0 21px;
  background-color: #f9fafb;
}

.cancel-btn-display {
  display: inline-block;
  width: 130px;
}

.basic-info-upload-btn {
  display: inline-block;
  margin-left: 12px;
  position: relative;
  top: -1px;
}

.song-heading {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.03em;
  margin-bottom: 20px;
}

.song-heading-1 {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.03em;
  margin-bottom: 13px;
  color: #071526;

}

.song-p {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  color: #47505B;
}

.collaborate-li-p {
  margin-bottom: 16px;
  padding-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #47505B;
}

.collaborate-icon {
  width: 11%;
  padding: 0px;
  max-width: 11%;
  margin: 0px;
  float: left;
  display: inline-block;
}

.colaborator-h {
  width: 89.5%;
  padding: 0px;
  max-width: 89%;
  margin: 0;
  float: left;
  display: inline-block;
}

.collaborator-border-box {
  padding: 20px 0 0 20px !important;
  background: #ECF0FF;
  border: 1px solid #D1433A1A;
  border-radius: 4px;
}

.setting-form .collaborator-form-group {
  margin-bottom: 20px !important;
}
</style>
<style lang="scss">
.v-bootstrap-select {
  .custom-select {
    height: 30px;
    border: 1px solid #E6EAF0;
    font-size: 14px;
    margin-bottom: 0px !important;
  }
}
.remove-action {
  .vs__dropdown-toggle {
    border: 1px solid #E6EAF0!important;
    background: #FFFFFF!important;
    .vs__actions {
      display: none;
    }
    .vs__selected-options {
      background: #FFFFFF!important;

      input {
        background: #FFFFFF!important;
      }
    }
  }
}
.change-background {
  background: #FFFFFF!important;
}
</style>
