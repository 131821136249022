<template>
  <div class="singles carousel-icon">
    <carousel :items="3" :autoplay="false" :nav="false" class="all_singles_overview" :margin="30"
    :responsive="{2560: {items: 2}}"
    >
      <div v-for="n in songsColumns" :key="n">
        <div class="list">
          <div>
            <single v-for="row in rows" :key="n+row" :item="getItemByRowAndColumn(listData, row, n)" :stream="isStreaming" type="record" />
          </div>
        </div>

      </div>
    </carousel>
  </div>
</template>
<script type="application/javascript">
import single from "@/components/DesignGuide/Music/Components/Single";
import ClickOutside from "vue-click-outside";
import carousel from "vue-owl-carousel";

export default {
  data() {
    return {
      showMMore: false,
      showOption: false,
      lastWidth: 0,
      song_status: false,
      song_push_status: 0,
      songs_array: [],
      rows: [0, 1, 2]

    };
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    componentType: {
      type: String,
      default: "buy"
    }
  },
  components: {
    single,
    carousel
  },
  computed: {
    songsColumns() {
      if (Array.isArray(this.listData) && this.listData.length > 0) {
        if (Number(this.listData.length) % 3 === 0) {
          return Number(this.listData.length / 3);
        }
        return Math.floor(Number(this.listData.length / 3) + 1);

      }
      return 0;
    },
    isStreaming(){
      return this.$store.getters.isMusicModeIsStreaming
    }
  },
  mounted: function() {
    this.checkConsole();
  },

  methods: {
    getImgUrl(filename) {
      if (filename) {
        var xhr = new XMLHttpRequest();
        xhr.open("HEAD", filename, false);
        xhr.send();
        if (xhr.status == "404") {
          return require(`@/assets/img/placeholders.png`);
        } else {
          return filename;
        }

      } else {
        return require(`@/assets/img/placeholders.png`);
      }
    },
    hasValue: function(index, i) {
      let newIndex = index + i - 1;
      if (this.listData[newIndex]) {
        return true;
      } else return false;
    },
    checkConsole: function() {
    },
    hide: function() {
      this.showOption = false;
      this.$parent.showList(false);
    },
    getHeight: function(e, ei) {
      //  if(this.optionWidth != 0){
      e.stopPropagation();
      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }
      console.log(e.screenX, e.screenY, ei);
      let height = (ei % 3) > 0 ? ((ei % 3) == 1) ? 35 : 100 : 160;
      this.$parent.updateDimension(e.screenX, e.screenY);
      this.$parent.showList(this.showOption);
      //  }
    },
    getItemByRowAndColumn(data, row, column) {
      let index = ((3 * column) - 3) + row;
      if(data[index])
      {
        return data[index]
      }
      return null;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
.card-padding {
  padding-left: 30px !important;
  padding-right: 0px !important;
}

.owl-item:nth-child(1) .card-padding {
  padding-left: 7px !important;
  padding-right: 15px !important;
}

.owl-item:nth-child(2) .card-padding {
  padding-left: 15px !important;
  padding-right: 7px !important;

}

.owl-item:nth-child(3) .card-padding {
  padding-left: 22.5px !important;
}

@import '/src/assets/style/music/UI-componsnts/singles.scss';

.owl-item {
  margin-right: 3rem;
}
</style>
