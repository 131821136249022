<template>
  <div class="container1">
    <songOrAlbumHorizontalCardHeader/>
    <songOrAlbumHorizontalCard :item="item"  v-for="(item, index) in listData" :key="index"/>
  </div>
</template>
<script type="application/javascript">
import songOrAlbumHorizontalCard from "@/components/Common/UiComponenets/Music/songOrAlbumHorizontalCard"
import songOrAlbumHorizontalCardHeader from "@/components/Common/UiComponenets/Music/songOrAlbumHorizontalCardHeader"

export default {
  data() {
    return {
      showSubMenu: 'none',
    }
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    isStreaming: {
      type: Boolean,
      default: false
    },
  },
  components: {
    songOrAlbumHorizontalCardHeader,
    songOrAlbumHorizontalCard
  },
  computed: {},
  methods: {


    showMore: function (index) {
      if (this.listData[index].isHovering == '0')
        this.listData[index].isHovering = 0;

      this.listData[index].isHovering = !this.listData[index].isHovering;
    }
  }
}
</script>
