<template>
  <div class="wrapReposts">
    <div class="titleDetalsRightPart">
      <h3>Reposts</h3>
      <div class="showAll">
        Show All
        <span>
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.01562 1L6.1515 5.173L1.01562 9.713"
              stroke="#47505B"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="imgReposts">
      <div v-for="item in dataReposts" :key="item.id">
        <img src="" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataReposts: [
        {
          id: "1",
          img: "",
        },
        {
          id: "2",
          img: "",
        },
        {
          id: "3",
          img: "",
        },
        {
          id: "4",
          img: "",
        },
        {
          id: "5",
          img: "",
        },
        {
          id: "6",
          img: "",
        },
        {
          id: "7",
          img: "",
        },

        {
          id: "8",
          img: "",
        },
        {
          id: "9",
          img: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapReposts {
  .imgReposts {
    display: flex;    
    div {
      width: 40px;
      height: 40px;
      background: #c3c3c3;
      border: 3px solid #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -9px;
      img{
        border-radius: 50%;
      }
    }
  }
}
</style>
