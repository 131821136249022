<template>
  <div style="padding-left: 0">
    <div class="media-tabs friends_tab">
      <ul id="myTab" role="tablist" class="nav nav-tabs5">
        <li class="nav-item">
          <a id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true" style="color: #47505B;" class="nav-link" @click="getArtistPageFollowers">
            {{artistFollowerListLength}} MEMBERS
          </a>
        </li>
      </ul>
      <div class="filter-div">
        <div class="music_dropdown_select" @click="selectFilter = !selectFilter">
          <span>Sort by Date Joined</span>
          <div class="drop_icon">
            <img :src="require(`@/assets/img/svg/dropdown_arrow_blue.svg`)" alt="image" />
          </div>
        </div>
        <ul class="music_select_options" v-show="selectFilter">
          <li>
            <span>Sort by Date Joined</span>
          </li>
          <li>
            <span>Sort by Date Joined</span>
          </li>
          <li>
            <span>Sort by Date Joined</span>
          </li>
        </ul>
      </div>
    </div>
    <div id="myTabContent" class="tab-content">
      <div id="Followers" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade mt_20 show active">
        <ArtistFollower :checkForButton="false" :currentActiveFollowerTab="currentActiveFollowerTab" :friendsSearchKey="friendsSearchKey"/>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ArtistFollower from "@/components/PageManager/Pages/ArtistMoreSection/ArtistFollowers.vue";

export default {
  data(){
    return{
      currentActiveFollowerTab:'followers',
      friendsSearchKey:'',
      selectFilter: false
    }
  },
  components: {
    ArtistFollower
  },
  computed: {
    artistFollowerListLength(){
      return this.$store.getters.getArtistFollowerList.length
    },
  },
  methods:{
    searchArtistFollowerValue(value){
      this.friendsSearchKey = value;
    },
    getArtistPageFollowers(){
      this.friendsSearchKey = '';
      this.currentActiveFollowerTab = 'followers'
      let payload = {
        showMore : false,
        page_id: this.$route.params.id,
        artistFollowersSearchKey: null,
        search_type: this.currentActiveFollowerTab,
        name: null,
      }
      this.$store.dispatch('getArtistPageFollowers', payload)
    },

  }
}
</script>
<style>
.center_centerbar_wraper_home {
  flex: 1;
  min-width: 770px !important;
}
</style>
<style scoped lang="scss">
.filter-div {
  width: 210px;
  .music_dropdown_select {
    width: 210px;
  }
  .music_select_options {
    width: 210px;
  }
}
</style>
