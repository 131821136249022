<template>
  <div class="date-day-bar" v-if="lengthOfChannelMessages > 0">
    <div class="border-line"></div>
    <div class="display-date-day">
      <div>
        <span>{{ date.date | filterDateToDayOrDate }}</span>
      </div>
    </div>
    <div class="border-line"></div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TimeDayDateBarComponent",
  props: {
    lengthOfChannelMessages: {
      type: Number,
      required: true
    },
    date: {
      type: Object,
      required: true
    }
  },
  filters: {
    filterDateToDayOrDate(value) {
      if (value === moment().format("DD-MM-YYYY")) {
        return "Today";
      } else if (value === moment().subtract(1, "days").format("DD-MM-YYYY")) {
        return "Yesterday";
      } else if (moment(value, "DD-MM-YYYY").day() >= 2 && moment(value, "DD-MM-YYYY").day() < 1) {
        if (moment(value, "DD-MM-YYYY").day() === 2) {
          return "Monday";
        } else if (moment(value, "DD-MM-YYYY").day() === 3) {
          return "Tuesday";
        } else if (moment(value, "DD-MM-YYYY").day() === 4) {
          return "Wednesday";
        } else if (moment(value, "DD-MM-YYYY").day() === 5) {
          return "Thursday";
        } else if (moment(value, "DD-MM-YYYY").day() === 6) {
          return "Friday";
        } else if (moment(value, "DD-MM-YYYY").day() === 7) {
          return "Saturday";
        }
      } else {
        return value;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.date-day-bar {
  height: 90px;
  display: flex;
  align-items: center;

  .border-line {
    height: 1px;
    background: #E6EAF0;
    width: 520px;
  }

  .display-date-day {
    width: 130px;
    height: 30px;
    display: flex;
    justify-content: center;

    div {
      height: inherit;
      width: 90px;
      border: 1px solid #E6EAF0;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #47505B;
      }
    }
  }
}
</style>
