<template>
  <div
    class="post-section-comment"
    :style="backgroundColor"
    :class="{ active: active_check }"
  >
    <!--    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path-->
    <!--          d="M17 11.4934C16.9688 11.9892 16.7645 12.451 16.4316 12.7785C16.0987 13.1059 15.6642 13.2725 15.2225 13.242H4.57235L1 16V2.75212C1.03125 2.25636 1.23553 1.79451 1.56841 1.46705C1.90128 1.1396 2.33582 0.973021 2.77748 1.00357H15.2158C15.6575 0.973021 16.092 1.1396 16.4249 1.46705C16.7578 1.79451 16.9621 2.25636 16.9933 2.75212L17 11.4934Z"-->
    <!--          stroke="#47505B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" :style="stroke"-->
    <!--          class="artist-timeline-action-svg"></path>-->
    <!--    </svg>-->
    <svg width="16" height="14.3" viewBox="0 0 16 14.3">
      <defs>
        <clipPath id="clip-comment">
          <rect width="16" height="14.3" />
        </clipPath>
      </defs>
      <g id="comment" clip-path="url(#clip-comment)">
        <path
          class="post-section-comment-svg"
          id="Path_2"
          data-name="Path 2"
          d="M14.274,11.439l-.052,0H3.572a.75.75,0,0,0-.457.155L.75,13.411V1.771A1.212,1.212,0,0,1,1.093,1,.822.822,0,0,1,1.726.752l.052,0H14.216l.052,0A.822.822,0,0,1,14.9,1h0a1.212,1.212,0,0,1,.343.77l.007,8.65a1.212,1.212,0,0,1-.343.77A.822.822,0,0,1,14.274,11.439Z"
          fill="none"
          stroke="#47505b"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          :style="stroke"
        />
      </g>
    </svg>
    {{ comments_count }}
  </div>
</template>

<script>
export default {
  name: "PostComment",
  props: {
    post: {
      type: Object,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    stroke: {
      type: String,
      default: "",
    },
    comments_count: {
      type: Number,
      default: 0,
    },
    active_check: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
