<template>
  <div class="wr_card">
    <article>
      <h3>{{ event.name }}</h3>
      <span class="price">${{ event.price }}</span>
    </article>
    <article>
      <h4>{{ event.by }}</h4>
      <span class="date">{{ event.date }}</span>
    </article>
  </div>
</template>

<script>
export default {
  props: ["event"],
};
</script>

<style lang="scss" scoped>
.wr_card {
  margin-top: 10px;
  background: #f5f7f9;
  border-radius: 4px;
  padding: 7px 10px;
  article {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    line-height: 16px;
    font-size: 11px;
    line-height: 13px;
  }
  h3 {
    color: #47505b;
    font-size: 13px;
    color: #47505b;
    margin-bottom: 7px;
  }
  h4 {
    font-size: 11px;
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.7px;
    font-weight: 400;
    color: #D1433A;
    margin: 0;
  }
  .price {
    color: #15ca86;
  }
  .date {
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.7px;
    font-weight: 400;
    color: #8b949f;
  }
}
</style>