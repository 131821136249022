<template>
  <div class="wr_Popular_Events_card">
    <div class="img_div"><img src="" alt="img event" /></div>
    <div style="color: #071526">Event Name featuring Ellen</div>
    <div>183 guests</div>
    <div>March 12, 2022</div>
    <div style="cursor:pointer">
      <svg
        width="3"
        height="15"
        viewBox="0 0 3 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 3C1.79667 3 2.08668 2.91203 2.33335 2.7472C2.58003 2.58238 2.77229 2.34811 2.88582 2.07403C2.99935 1.79994 3.02906 1.49834 2.97118 1.20736C2.9133 0.916393 2.77044 0.649119 2.56066 0.43934C2.35088 0.229561 2.08361 0.0866999 1.79263 0.0288221C1.50166 -0.0290557 1.20006 0.000649374 0.925974 0.114181C0.651885 0.227712 0.417617 0.419971 0.252795 0.666644C0.087973 0.913318 -6.36721e-07 1.20333 -6.49689e-07 1.5C-6.67078e-07 1.89782 0.158035 2.27936 0.439339 2.56066C0.720644 2.84196 1.10217 3 1.5 3V3Z"
          fill="#47505B"
        />
        <path
          d="M1.5 9C1.79667 9 2.08668 8.91203 2.33335 8.7472C2.58003 8.58238 2.77229 8.34811 2.88582 8.07403C2.99935 7.79994 3.02906 7.49834 2.97118 7.20736C2.9133 6.91639 2.77044 6.64912 2.56066 6.43934C2.35088 6.22956 2.08361 6.0867 1.79263 6.02882C1.50166 5.97094 1.20006 6.00065 0.925974 6.11418C0.651885 6.22771 0.417617 6.41997 0.252795 6.66664C0.087973 6.91332 -6.36721e-07 7.20333 -6.49689e-07 7.5C-6.67078e-07 7.89782 0.158035 8.27936 0.439339 8.56066C0.720644 8.84196 1.10217 9 1.5 9V9Z"
          fill="#47505B"
        />
        <path
          d="M1.5 15C1.79667 15 2.08668 14.912 2.33335 14.7472C2.58003 14.5824 2.77229 14.3481 2.88582 14.074C2.99935 13.7999 3.02906 13.4983 2.97118 13.2074C2.9133 12.9164 2.77044 12.6491 2.56066 12.4393C2.35088 12.2296 2.08361 12.0867 1.79263 12.0288C1.50166 11.9709 1.20006 12.0006 0.925974 12.1142C0.651885 12.2277 0.417617 12.42 0.252795 12.6666C0.087973 12.9133 -6.36721e-07 13.2033 -6.49689e-07 13.5C-6.67078e-07 13.8978 0.158035 14.2794 0.439339 14.5607C0.720644 14.842 1.10217 15 1.5 15V15Z"
          fill="#47505B"
        />
      </svg>
    </div>
    <div style="cursor:pointer">
      <svg
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.83301 1L5.60201 5.3L1.00001 1"
          stroke="#47505B"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wr_Popular_Events_card {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  font-family: HelveticaNeueMedium;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #47505b;
  .img_div {
    width: 60px;
    height: 60px;
    border-radius: 4px 0 0 4px;
    background: #c3c3c3;
  }
}
</style>