<template>
  <div class="buy-item item">
    <div class="item-title">
      {{ paramsItems.ticket.event.title }}
      <span
        >{{ countTicket }}/ ${{ paramsItems.ticket.price * countTicket }}</span
      >
    </div>
    <div class="buy-item-title">
      <div>ITEM</div>
      <div>COUNT</div>
      <div>PRICE</div>
    </div>
    <div class="buy-item-body">
      <div class="img-text-wrap">
        <div class="img-wrap">
          <img :src="paramsItems.ticket.event.artwork_url" alt="" />
        </div>
        <div class="text-wrap">
          <div class="title">
            {{
              paramsItems.ticket.name.length > 42
                ? paramsItems.ticket.name.slice(0, 42) + "..."
                : paramsItems.ticket.name
            }}
          </div>
          <div class="subtitle">
            {{
              paramsItems.ticket.event.location.length > 42
                ? paramsItems.ticket.event.location.slice(0, 42) + "..."
                : paramsItems.ticket.event.location
            }}
          </div>
        </div>
      </div>
      <div class="counter">
        <div @click="reduceTicketCount" class="minus">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33398 8H12.6673"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <input type="number" class="count" name="" v-model="countTicket" />
        <div @click="addTicketCount" class="plus">
          <div class="svg-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33398 8H12.6673"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 3.33301L8 12.6663"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="price-remove">
        <div class="price">${{ paramsItems.ticket.price * countTicket }}</div>
        <div @click="openCloseRemoveModal('open')" class="btn-remove">
          <div class="svg-icon">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L1 11"
                class="c-line"
                stroke="#8B949F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1L11 11"
                class="c-line"
                stroke="#8B949F"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="seeAvailable" class="available">
      The available count is - {{ paramsItems.ticket.available_tickets }}
    </div>
    <RemoveModal
      v-if="showRemoveModals === 'open'"
      :title="'Delete Cart'"
      :description="'this cart'"
      :id="paramsItems.id"
      @openCloseRemoveModal="openCloseRemoveModal"
      @delete_this="delete_this"
    />
  </div>
</template>

<script>
import RemoveModal from "@/components/Common/Elements/RemoveModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    RemoveModal,
  },
  data() {
    return {
      showRemoveModals: "close",
      countTicket: this.paramsItems.quantity,
      seeAvailable: false,
    };
  },
  props: {
    paramsItems: Object,
  },

  methods: {
    ...mapActions(["removeCart", "myCartItems", "addToCart"]),
    openCloseRemoveModal(do_it) {
      this.showRemoveModals = do_it;
    },
    async addTicketCount() {
      console.log("this.countTicket", this.countTicket);
      if (this.countTicket + 1 <= this.paramsItems.ticket.available_tickets) {
        this.countTicket += 1;
        this.seeAvailable = false;
      } else {
        this.seeAvailable = true;
      }
    },
    async reduceTicketCount() {
      if (this.countTicket - 1 >= 0) {
        this.countTicket -= 1;
        this.seeAvailable = false;
      }
    },
    async delete_this(id) {
      await this.$store.dispatch("loading", true);
      await this.removeCart(id);
      await this.myCartItems();
      this.$parent.forParamsCartSummary();
      await this.$store.dispatch("loading", false);
    },
  },
  watch: {
    async countTicket(next, prev) {
      if (next <= this.paramsItems.ticket.available_tickets) {
        this.seeAvailable = false;
        let payload = {
          cartItems: [
            {
              ticket_id: this.paramsItems.ticket.id,
              quantity: this.countTicket,
              total_amount: this.paramsItems.ticket.price * this.countTicket,
            },
          ],
        };
        await this.addToCart(payload);
        await this.myCartItems();
        this.$parent.forParamsCartSummary();
      } else {
        this.seeAvailable = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-wrap {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.available {
  color: red;
}
</style>
