<template>
  <div class="w-1170">
    <h1 class="EM_Pages_title">MANAGE EVENTS</h1>
    <EmptyDataCard
      v-if="seeEmpty"
      :item="emptyCartData"
      @buttonClick="createEventsModal = true"
    />
    <div v-if="createEventsModal" class="create-events-wrapper">
      <event-post-container @closeEventModal="createEventsModal = false" />
    </div>
    <div v-if="!seeEmpty" class="event_manager_contener">
      <div>
        <div class="w-770">
          <EMEventCard v-for="item in 6" :key="item" />
        </div>
        <div data-v-0e9df30a="" class="show_more_btn">
          <div data-v-0e9df30a="" class="show-more musicbar_more">
            <a data-v-0e9df30a=""
              ><span data-v-0e9df30a="" class="show_more_bg"
                ><svg
                  data-v-0e9df30a=""
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    data-v-0e9df30a=""
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    data-v-0e9df30a=""
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW 10 MORE EVENTS
              </span></a
            >
          </div>
        </div>
      </div>
      <EMRightSidebar />
    </div>
  </div>
</template>

<script>
import EMRightSidebar from "../EMElement/EMRightSidebar.vue";
import EMEventCard from "../EMElement/EMEventCard.vue";
import EmptyDataCard from "@/components/Common/UiComponenets/EmptyDataCard.vue";
import EventPostContainer from "../../components/CreateEvents/EventPostContainer.vue";
export default {
  components: {
    EMRightSidebar,
    EmptyDataCard,
    EventPostContainer,
    EMEventCard,
  },
  data() {
    return {
      seeEmpty: false,
      createEventsModal: false,
      emptyCartData: {
        title: "You have no events yet",
        description:
          "Please add new event with «Add New Event» button and go live with it!",
        icon: require(`@/assets/img/calendar_bg_blue.png`),
        buttonText: "ADD NEW EVENT",
      },
    };
  },
};
</script>

<style lang="scss" scoped>

</style>