<template>
  <div class="p-0 page-element-1">
    <div class="row">
      <div class="col">
        <div
          :class="[
            'music-head d-flex justify-content-start bg-' + backgroundType,
          ]"
        >
          <div class="left-block">
            <div class="cover">
              <img
                :src="
                  song && song.artwork_url
                    ? song.artwork_url
                    : require('@/assets/img/svg/' + imageName + '.png')
                "
                alt=""
                style="width: inherit; height: inherit"
              />
            </div>
          </div>
          <div class="right-block w-100 d-flex flex-column">
            <div class="d-flex justify-content-star align-items-center">
              <div
                class="play-button js-add-active play"
                :class="[play ? 'active' : '']"
              >
                <div class="svg-icon" @click="playPause">
                  <svg
                    width="13"
                    height="16"
                    viewBox="0 0 13 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="play-type-1"
                  >
                    <path
                      class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white"
                      stroke-width="1.3"
                    ></path>
                  </svg>
                  <svg
                    width="10"
                    height="14"
                    viewBox="0 0 10 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      d="M1 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      class="c-line-1 c-fill-1"
                      d="M9 1V13"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-between w-100">
                <div>
                  <div class="title">{{ getArtistName }}</div>
                  <div class="subtitle">{{ song ? song.title : "title" }}</div>
                </div>
                <div>
                  <div class="days">
                    {{ convertDateIntoHumanReadableForm(song.updated_at) }}
                  </div>
                  <div class="in-line-both">
                    <div
                      class="tag"
                      v-for="(genre, index) in getGenres.slice(0, 3)"
                      :key="index"
                    >
                      #<span v-html="genre.name"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center m-t-30">
              <MusicPlayer
                :duration="songOrAlbumDuration"
                v-if="songOrAlbumAudioUrl !== ''"
                ref="songPlayerRef"
                :song-loaded="songLoaded"
                :is-song-playing.sync="isSongPlaying"
                :music="songOrAlbumAudioUrl"
                :latest_list_id="songOrAlbumId"
                :type="'full'"
                @updateIcon="updateIcon"
              />
            </div>
            <div
              class="
                d-flex
                w-100
                justify-content-between
                align-items-center
                mt-auto
              "
            >
              <div class="d-flex flex-row">
                <button class="btn btn-information text dualClass">
                  <div class="svg-icon">
                    <b-spinner
                      v-if="spinner"
                      class="spinner-color"
                      small
                      label="Small Spinner"
                    ></b-spinner>
                    <svg
                      :class="[favorite === true ? 'active' : '']"
                      v-else
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      @click="favouriteUnfavourite($event, song, favorite)"
                    >
                      <path
                        class="c-line-1"
                        d="M13.9167 2.06063C13.5733 1.72437 13.1657 1.45764 12.717 1.27565C12.2683 1.09367 11.7874 1 11.3018 1C10.8161 1 10.3352 1.09367 9.88655 1.27565C9.43787 1.45764 9.0302 1.72437 8.68682 2.06063L7.97375 2.7588L7.26067 2.06063C6.56244 1.40423 5.62922 1.04197 4.66128 1.0516C3.69335 1.06123 2.76784 1.44198 2.08337 2.11214C1.39891 2.7823 1.01003 3.68847 1.00019 4.63617C0.990355 5.58387 1.36035 6.49759 2.03076 7.18123L2.74384 7.8794L7.97375 13L13.2037 7.8794L13.9167 7.18123C14.2602 6.84503 14.5326 6.44588 14.7185 6.00658C14.9043 5.56728 15 5.09643 15 4.62093C15 4.14543 14.9043 3.67458 14.7185 3.23528C14.5326 2.79598 14.2602 2.39683 13.9167 2.06063V2.06063Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <span>{{ favoriteCounts + getCount(song, "loves") }}</span>
                </button>
                <button
                  class="btn btn-information text dualClass"
                  @click="goTo"
                >
                  <div class="svg-icon">
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="c-line-1"
                        d="M14 9.39473C13.9746 9.79134 13.8086 10.1608 13.5382 10.4228C13.2677 10.6847 12.9146 10.818 12.5558 10.7936H3.90253L1 13V2.4017C1.02539 2.00509 1.19137 1.63561 1.46183 1.37364C1.73229 1.11168 2.08535 0.978417 2.4442 1.00285H12.5504C12.9092 0.978417 13.2623 1.11168 13.5327 1.37364C13.8032 1.63561 13.9692 2.00509 13.9946 2.4017L14 9.39473Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <span>{{ commentCounts }}</span>
                </button>
                <button
                  class="btn btn-information text dualClass"
                  @click="repost($event)"
                >
                  <div class="svg-icon">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_14697_204280)">
                        <path
                          d="M11.333 0.667969L13.9997 3.33464L11.333 6.0013"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2 7.33203V5.9987C2 5.29145 2.28095 4.61318 2.78105 4.11308C3.28115 3.61298 3.95942 3.33203 4.66667 3.33203H14"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.66667 15.3333L2 12.6667L4.66667 10"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 8.66797V10.0013C14 10.7085 13.719 11.3868 13.219 11.8869C12.7189 12.387 12.0406 12.668 11.3333 12.668H2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_14697_204280">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span>{{ repostCounts }}</span>
                </button>
                <button
                  class="btn btn-information dualClass"
                  @click="share($event)"
                >
                  <div class="svg-icon">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.9995 10.3359V13.4469C14.9995 13.8595 14.8357 14.2551 14.5439 14.5469C14.2522 14.8386 13.8566 15.0025 13.444 15.0025H2.5555C2.14296 15.0025 1.74731 14.8386 1.4556 14.5469C1.16388 14.2551 1 13.8595 1 13.4469V10.3359"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.8889 4.88876L8.00009 1L4.11133 4.88876"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 1V10.333"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </button>
                <PostShare
                  :post="song"
                  ref="share"
                  :share_media_check="true"
                  :fullViewPageCheck="true"
                  @repostCounts="IncrementRepostCounts"
                />
                <button
                  class="btn btn-information dualClass dropdown more-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-display="static"
                  @click="
                    openListOption(
                      $event,
                      song.type === 'song' ? [song] : song.album_songs,
                      'default_option_list',
                      song
                    )
                  "
                >
                  <div class="svg-icon more-position">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.00006 1C8.42239 1 8.76476 1.34237 8.76476 1.76471V14.2353C8.76476 14.6576 8.42239 15 8.00006 15C7.57772 15 7.23535 14.6576 7.23535 14.2353V1.76471C7.23535 1.34237 7.57772 1 8.00006 1Z"
                        fill="white"
                        stroke="white"
                        stroke-width="0.3"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 7.99908C1 7.57675 1.34237 7.23438 1.76471 7.23438H14.2353C14.6576 7.23438 15 7.57675 15 7.99908C15 8.42142 14.6576 8.76379 14.2353 8.76379H1.76471C1.34237 8.76379 1 8.42142 1 7.99908Z"
                        fill="white"
                        stroke="white"
                        stroke-width="0.3"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <ListOption />
                </button>
              </div>
              <div class="d-flex flex-row justify-content-end">
                <button
                  class="btn btn-icon-border shop text"
                  v-if="listType == 'selling'"
                >
                  <div class="svg-icon">
                    <svg
                      width="16"
                      height="13"
                      viewBox="0 0 16 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="shop-cart-v2"
                    >
                      <path
                        class="c-line-1"
                        d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        class="c-line-1"
                        d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                        stroke="#D1433A"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <div class="price">${{ getPrice(song) }}</div>
                </button>
                <div
                  class="add-queue-btn mr_30 music_wave"
                  v-if="listType == 'streaming'"
                >
                  <div class="svg-icon">
                    <svg
                      width="19"
                      height="13"
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0654 6.13184H18.0654"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M10.0654 2.33203H17.9654"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M10.0654 9.93213H18.0654"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M1.70458 11.375C1.65029 11.3818 1.59536 11.3763 1.54345 11.359C1.49154 11.3417 1.44417 11.313 1.40478 11.275C1.29689 11.212 1.2048 11.1252 1.13549 11.0212C1.06618 10.9173 1.02132 10.7989 1.00463 10.675V1.67502C0.987431 1.54354 1.01848 1.41028 1.09203 1.29995C1.16559 1.18962 1.2768 1.10971 1.40478 1.07502C1.51484 1.02557 1.63398 1 1.75463 1C1.87529 1 1.99467 1.02557 2.10473 1.07502L9.00463 5.57502C9.15331 5.68929 9.25545 5.8536 9.29223 6.03749C9.32901 6.22137 9.29787 6.41234 9.20458 6.57502L9.00463 6.77502L2.10473 11.275C1.96377 11.2601 1.82192 11.2955 1.70458 11.375"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                  <div class="price cursor-pointer" @click="addSongToQueue">
                    Add to queue
                  </div>
                </div>
                <div class="btn btn-play-count">
                  <div class="svg-icon">
                    <svg
                      width="7"
                      height="8"
                      viewBox="0 0 7 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="small-play-svg"
                    >
                      <path
                        class="c-fill-1"
                        d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                        fill="#8B949F"
                      ></path>
                    </svg>
                  </div>
                  <span>{{ getPlaysCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "./ListOption";
import MusicPlayer from "../../PageManager/Pages/Components/LatestAlbums/MusicPlayer";
import PostShare from "../../Home/NewsFeed/PostItem/PostShare";
import moment from "moment";

export default {
  data() {
    return {
      play: false,
      favorite: false,
      favoriteCounts: 0,
      spinner: false,
      wavesurfer: null,
      songLoaded: true,
      isSongPlaying: false,
      comment_count: "",
      repost_counts: 0,
      songPlayStatus: false,
    };
  },
  components: {
    ListOption,
    MusicPlayer,
    PostShare,
  },
  props: {
    songName: String,
    backgroundType: String,
    listType: String,
    showPlus: Boolean,
    imageName: String,
    type: {
      type: String,
      default: "",
    },
    song: {
      type: Object,
      required: true,
    },
    track: {
      type: Object,
    },
  },
  computed: {
    getArtistName() {
      return this.song && this.song.artists && this.song.artists.length > 0
        ? this.song.artists[0].title
        : "Artist Name";
    },
    getGenres() {
      return this.song && this.song.genre && this.song.genre.length > 0
        ? this.song.genre
        : [];
    },
    getPlaysCount() {
      const total = this.$store.getters.getSongCount;
      if (total) return total > 1000 ? total.slice(0) + "K" : total;
      else
        return this.song && this.song.plays && this.song.plays > 1000
          ? this.song.plays.slice(0) + "K"
          : this.song.plays;
    },
    commentCounts: {
      set(value) {
        this.comment_count = value;
      },
      get() {
        if (this.comment_count > this.song.comment_count) {
          return this.comment_count;
        } else {
          return this.song.comment_count;
        }
      },
    },
    songOrAlbumId() {
      if (this.type === "album") {
        return this.track && this.track.id ? this.track.id : "";
      } else {
        return this.song && this.song.id ? this.song.id : "";
      }
    },
    songOrAlbumDuration() {
      if (this.type === "album") {
        return this.track && this.track.duration ? this.track.duration : "";
      } else {
        return this.song && this.song.duration ? this.song.duration : "";
      }
    },
    songOrAlbumAudioUrl() {
      if (this.type === "album") {
        return this.track && this.track.audio_url ? this.track.audio_url : "";
      } else {
        return this.song && this.song.audio_url ? this.song.audio_url : "";
      }
    },
    getPageId() {
      return this.song && this.song.page && this.song.page.id
        ? this.song.page.id
        : "";
    },
    repostCounts: {
      set(value) {
        return (this.repost_counts = value);
      },
      get() {
        if (this.repost_counts > this.song.repost_count) {
          return this.repost_counts;
        } else {
          return this.song.repost_count;
        }
      },
    },
  },
  watch: {
    song() {
      this.favorite = this.song.favorite;
      this.repost_counts = this.song.repost_count;
    },
  },
  mounted() {
    if (this.song && this.song.favorite) {
      this.favorite = this.song.favorite;
    }
  },
  methods: {
    setCommentCounts(comment_counts) {
      if (comment_counts > this.comment_count) {
        this.comment_count = comment_counts;
      }
    },
    convertDateIntoHumanReadableForm(date) {
      return moment(date).fromNow();
    },
    repost(event) {
      this.$refs.share.repost(event, null, this.getPageId);
    },
    share(event) {
      this.$refs.share.openShareModel(event);
    },
    async favouriteUnfavourite(e, object, favorite) {
      this.spinner = true;
      const res = await this.setFavourite(e, object, "", favorite);
      if (res.success === 1) {
        this.favorite = this.favorite !== true;
        this.favorite === true ? this.favoriteCounts++ : this.favoriteCounts--;
      }
      this.spinner = false;
    },
    async playPause() {
      this.play = !this.play;
      this.$store.commit("SET_CURRENT_PLAYER", "waveSurferPlayer");
      await this.$refs.songPlayerRef.playPauseSong();
      this.isSongPlaying = this.$refs.songPlayerRef.wavesurfer.isPlaying();
      // Add song play count increment
      if (this.isSongPlaying && !this.songPlayStatus) {
        let payload = {
          type: "song",
          id: this.song.id,
        };
        this.$store.dispatch("trackPlayed", payload);
        this.$store.commit(
          "SET_SONG_PLAY_COUNT",
          parseInt((this.song.plays += 1))
        );
        this.songPlayStatus = true;
      }
    },
    updateIcon(val) {
      this.play = val;
    },
    async addSongToQueue() {
      if (this.song) {
        await this.$store.commit("Set_Add_Songs_To_Queue", [this.song]);
      }
    },
    goTo() {
      this.$emit("goto");
    },
    IncrementRepostCounts() {
      this.repost_counts = this.repost_counts + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-element-1 {
  .music-head {
    border-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

    .left-block {
      .cover {
        img {
          border-bottom-left-radius: 4px;
        }
      }
    }

    .right-block {
      .btn.btn-information {
        background-color: rgba(7, 21, 38, 0.3) !important;

        .svg-icon {
          svg.active {
            fill: #fff !important;
          }

          path {
            stroke: #fff !important;
          }
        }

        span {
          color: #fff !important;
          font-family: HelveticaNeueNormal, sans-serif;
          letter-spacing: 0.5px;
        }
      }

      .play-button {
        .svg-icon {
          svg {
            width: 30px !important;
            height: 30px !important;
          }
        }
      }
    }

    .in-line-both {
      .tag {
        height: auto !important;
        justify-content: end;
        margin-left: auto;
        padding: 0 10px !important;

        &:last-child {
          margin-right: unset !important;
        }
      }
    }
  }
}
</style>
