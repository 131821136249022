<template>
  <div class="content discover ">
    <div class="container">
      <MusicMarketFilter/>
      <GenresMoodsSelling/>
      <SpecificGenres/>
      <RemixSelling/>
      <ChartFilter/>
      <ReviewsFilter/>
      <PageFilter/>
      <AllFeeds/>

      <div class="container-fluid m-t-50">
        <h6>ADD TO PLAYLIST_CREATE PLAYLIST_(Modal) </h6>
        <button v-on:click="playList1Show = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          ADD TO PLAYLISTS
        </button>
      </div>
      <div class="container-fluid m-t-50">
        <h6>ADD TO PLAYLIST_CREATE PLAYLIST_(Modal) V2</h6>
        <button v-on:click="playList2Show = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          ADD TO PLAYLISTS
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>SHARE - EMBED</h6>
        <button v-on:click="showShareEmbed = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Share & Embed
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Play List</h6>
        <button v-on:click="showPlayList = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Play List
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Accept & Invite</h6>
        <button v-on:click="showAcceptInvite = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Accept & Invite
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Job Decline</h6>
        <button v-on:click="showJobDecline = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Job Decline
        </button>
      </div>


      <div class="container-fluid m-t-50">
        <h6>Hire</h6>
        <button v-on:click="showHire = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Hire
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Drafts</h6>
        <button v-on:click="showDrafts = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Drafts
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Job Invite Interview</h6>
        <button v-on:click="showJobInvute = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Job Invite Interview
        </button>
      </div>


      <div class="container-fluid m-t-50">
        <h6>Propose Offer</h6>
        <button v-on:click="showProposeOffer = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Propose Offer
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Request Refund</h6>
        <button v-on:click="showRequestrefund = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Request Refund
        </button>
      </div>

      <div class="container-fluid m-t-50">
        <h6>Ask Suppport Help</h6>
        <button v-on:click="showSupportHelp = true" type="button" class="btn btn-primary" data-backdrop="false"
                data-toggle="modal" data-target="#add_modal_playlist">
          Ask Suppport Help
        </button>
      </div>

      <CreatePlayList1 v-if="playList1Show" @closeModal="closeModal"/>
      <CreatePlayList2 v-if="playList2Show" @closeModal="closeModal"/>
      <ShareEmbedd v-if="showShareEmbed" @closeModal="closeModal"/>
      <MyPlayList v-if="showPlayList" @closeModal="closeModal"/>
      <AcceptInvite v-if="showAcceptInvite" @closeModal="closeModal"/>
      <JobDecline v-if="showJobDecline" @closeModal="closeModal"/>
      <Hire v-if="showHire" @closeModal="closeModal"/>
      <Drafts v-if="showDrafts" @closeModal="closeModal"/>
      <JobInviteInterView v-if="showJobInvute" @closeModal="closeModal"/>
      <ProposeOffer v-if="showProposeOffer" @closeModal="closeModal"/>
      <RequestRefund v-if="showRequestrefund" @closeModal="closeModal"/>
      <AskSupportHelp v-if="showSupportHelp" @closeModal="closeModal"/>

    </div>
  </div>
</template>
<script type="application/javascript">
import MusicMarketFilter from './MusicMarketFilter'
import GenresMoodsSelling from './GenresMoodsSelling'
import SpecificGenres from './SpecificGenres'
import RemixSelling from './RemixSelling'
import ChartFilter from './ChartFilter'
import ReviewsFilter from './ReviewsFilter'
import PageFilter from './PageFilter'
import AllFeeds from './AllFeeds'
import CreatePlayList1 from './CreatePlayList1'
import CreatePlayList2 from './CreatePlayList2'
import ShareEmbedd from './ShareEmbedd'
import MyPlayList from './MyPlayList'
import AcceptInvite from './AcceptInvite'
import JobDecline from './JobDecline'
import Hire from './Hire'
import Drafts from './Drafts'
import JobInviteInterView from './JobInviteInterview'
import ProposeOffer from './ProposeOffer'
import RequestRefund from './RequestRefund'
import AskSupportHelp from './AskSupportHelp'

export default {
  data() {
    return {
      playList1Show: false,
      playList2Show: false,
      showShareEmbed: false,
      showPlayList: false,
      showAcceptInvite: false,
      showJobDecline: false,
      showHire: false,
      showDrafts: false,
      showJobInvute: false,
      showProposeOffer: false,
      showRequestrefund: false,
      showSupportHelp: false
    }
  },
  components: {
    MusicMarketFilter,
    GenresMoodsSelling,
    SpecificGenres,
    RemixSelling,
    ChartFilter,
    ReviewsFilter,
    PageFilter,
    AllFeeds,
    CreatePlayList1,
    CreatePlayList2,
    ShareEmbedd,
    MyPlayList,
    AcceptInvite,
    JobDecline,
    Hire,
    Drafts,
    JobInviteInterView,
    ProposeOffer,
    RequestRefund,
    AskSupportHelp
  },
  computed: {},
  methods: {
    closeModal: function () {
      this.playList1Show = false;
      this.playList2Show = false;
      this.showShareEmbed = false;
      this.showPlayList = false;
      this.showAcceptInvite = false;
      this.showJobDecline = false;
      this.showHire = false;
      this.showJobInvute = false;
      this.showDrafts = false;
      this.showProposeOffer = false;
      this.showRequestrefund = false;
      this.showSupportHelp = false;
    }
  }
}
</script>
