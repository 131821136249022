import Vue from "vue";
import * as Utils from "../components/utils/Utils";
import {login, get_ip_address} from "../apis/APIs";
import * as SMStorage from "../apis/SMStorage";
import {
    EMPTY,
    ERROR,
    LOGIN_ERRORS,
    LOGIN_FORM,
    LOGIN_FORM_EMAIL,
    LOGIN_FORM_PASSWORD,
    USER_DATA,
    LOGIN_KEY,
    LOGIN_REQEUST_LOADING,
    LOGIN_REQEUST_SESSION_LOADING,
    LOGIN_REQUEST_SESSION_STATUS,
    LOGIN_REQUEST_STATUS,
    MESSAGE,
    STATUS,
    SUCCESS,
    SERVER_VERIFICATION_ERROR,
    SYSTEM_DATA_IS_AUTHENTICATED,
    SYSTEM_DATA_IS_WELCOME_PAGE,
} from "./types";
import localIpV4Address from 'local-ipv4-address';

const INITIAL_STATE = {
    [LOGIN_KEY]: {
        [LOGIN_FORM]: {
            [LOGIN_FORM_EMAIL]: "",
            [LOGIN_FORM_PASSWORD]: ""
        },
        [LOGIN_REQEUST_LOADING]: false,
        [LOGIN_REQUEST_STATUS]: {
            [STATUS]: EMPTY,
            [MESSAGE]: ""
        },
        [LOGIN_REQEUST_SESSION_LOADING]: false,
        [LOGIN_REQUEST_SESSION_STATUS]: {
            [STATUS]: EMPTY,
            [MESSAGE]: ""
        },
        [LOGIN_ERRORS]: []
    }
};

export const state = {...INITIAL_STATE};

export const actions = {
    async login_user({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                const login_key = state[LOGIN_KEY];
                const form_data = login_key && login_key[LOGIN_FORM] ? login_key[LOGIN_FORM] : {};

                const email = form_data && form_data[LOGIN_FORM_EMAIL] ? form_data[LOGIN_FORM_EMAIL] : "";
                const password = form_data && form_data[LOGIN_FORM_PASSWORD] ? form_data[LOGIN_FORM_PASSWORD] : "";

                commit("updateLoginUIConstraints", {
                    [LOGIN_REQEUST_LOADING]: true
                });

                const res_ip = await get_ip_address();
                const {ip} = res_ip.data;

                if (!ip) {
                    commit("updateLoginUIConstraints", {
                        [LOGIN_REQEUST_LOADING]: false
                    });

                    reject({
                        status: ERROR,
                        message: "IP address is not found, please try again."
                    });
                }

                let formdata = new FormData();
                formdata.append("email", email);
                formdata.append("password", password);
                // formdata.append("user_browser", Utils.getBrowser());
                // formdata.append("user_os", navigator.platform);
                // formdata.append("user_ip", ip);
                const resp = await login(formdata);
                const {data} = resp;

                const {success, message, information, token, refreshToken, expiresIn} = data || {};
                if (Number(success) === 1) {
                    const {email_verified} = information;

                    try {
                        if (email_verified === 1) {
                            await SMStorage.storeSMLoginData(information);
                            const userData = await SMStorage.getSMLoginData();
                            commit("updateUserUIConstraints", ({
                                [USER_DATA]: userData && userData.response ? userData.response : userData
                            }));
                            commit("SET_ACCESS_TOKEN", token);
                            commit("SET_REFRESH_TOKEN", refreshToken);
                            commit("SET_ACCESS_TOKEN_EXPIRES_AT", expiresIn);
                            commit("SET_REFRESH_TOKEN_EXPIRES_AT");
                            localStorage.setItem('token', `Bearer ${token}`)
                            const {user_get_started_page} = information || {}
                            commit("updateSystemDataUIConstraints", ({
                                [SYSTEM_DATA_IS_AUTHENTICATED]: true,
                                [SYSTEM_DATA_IS_WELCOME_PAGE]: user_get_started_page === "0" ? true : false
                            }));
                        } else {
                            commit("updateUserUIConstraints", ({
                                [USER_DATA]: information
                            }));
                        }
                    } catch (error) {
                        console.log("SM Login Storage ===> Error " + error);
                        commit("updateLoginUIConstraints", {
                            [LOGIN_REQEUST_LOADING]: false
                        });
                        reject({
                            status: ERROR,
                            message: error && error.message ? error.message : "Please try again."
                        });
                        return;
                    }

                    commit("updateLoginUIConstraints", {
                        [LOGIN_REQEUST_LOADING]: false
                    });

                    resolve({
                        status: email_verified === "0" ? SERVER_VERIFICATION_ERROR : SUCCESS,
                        message: ""
                    });
                } else if (Number(success)) {
                    resolve({
                        status: SERVER_VERIFICATION_ERROR,
                        message: "Please verify your Email."
                    });
                    return;
                } else {
                    console.log('API success error', resp)
                    commit("updateLoginUIConstraints", {
                        [LOGIN_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: Utils.getErrorFromApi(resp)
                    });
                }
            } catch (error) {
                console.log("Login ===> error", error);
                commit("updateLoginUIConstraints", {
                    [LOGIN_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: Utils.getErrorFromApi((error.response) ? error.response.data : error.data)
                });
            } finally {
                commit("updateLoginUIConstraints", {
                    [LOGIN_REQEUST_LOADING]: false
                });
            }
        })
    },
    async logout({state, commit}, articleSlug, prevArticle) {
        return new Promise(async (resolve, reject) => {
            try {
                await commit("CLEAR_TOKEN_STORE");
                await SMStorage.clearSMLoginData();
                commit("updateUserUIConstraints", ({
                    [USER_DATA]: undefined
                }));

                commit("updateSystemDataUIConstraints", ({
                    [SYSTEM_DATA_IS_AUTHENTICATED]: false
                }));
                resolve();
            } catch (error) {
                resolve();
                Utils.log("Login ===> error", error);
            }
        })
    },
    async update_user_data({state, commit}, data) {
        return new Promise(async (resolve, reject) => {
            try {
                await SMStorage.storeSMLoginData(data);
                const userData = await SMStorage.getSMLoginData();
                commit("updateUserUIConstraints", ({
                    [USER_DATA]: userData && userData.response ? userData.response : userData
                }));

                const {user_get_started_page} = data || {}
                commit("updateSystemDataUIConstraints", ({
                    [SYSTEM_DATA_IS_AUTHENTICATED]: true,
                    [SYSTEM_DATA_IS_WELCOME_PAGE]: user_get_started_page === "0" ? true : false
                }));

                resolve({
                    status: SUCCESS,
                    message: ""
                });
            } catch (error) {
                Utils.log("Update user data ===> error", error);
                console.log(error + ' error')
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    updateLoginFormData(state, obj) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[LOGIN_KEY];
            const data = Object.assign(formData[LOGIN_FORM], obj);

            _state.test = "data"

            mutations.updateLoginState(state, Object.assign(formData, {
                [LOGIN_FORM]: data
            }));
        } catch (error) {
            Utils.log("Update Form Data ===> error ", error);
        }
    },
    updateLoginUIConstraints(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[LOGIN_KEY];
            const data = Object.assign(formData, obj);

            mutations.updateLoginState(state, data);
        } catch (error) {
            Utils.log("Update UI Constraints ===> error ", error);
        }
    },
    updateLoginState(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            let formData = _state[LOGIN_KEY];

            state[LOGIN_KEY] = Object.assign(formData, obj);
        } catch (error) {
            Utils.log("Update Login State ===> error ", error);
        }
    },
    resetLoginState() {
        for (let f in state) {
            Vue.set(state, f, INITIAL_STATE[f]);
        }
    },
    setLoginLoader (state, payload) {
        try {
            state[LOGIN_REQEUST_LOADING] = payload
        } catch (e) {
            console.log('Unable to set login loader')
        }
    }
};

const getters = {
    login_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const login_key = _state[LOGIN_KEY];
        const form_data = login_key && login_key[LOGIN_FORM] ? login_key[LOGIN_FORM] : {};

        const email = form_data && form_data[LOGIN_FORM_EMAIL] ? form_data[LOGIN_FORM_EMAIL] : "";
        const password = form_data && form_data[LOGIN_FORM_PASSWORD] ? form_data[LOGIN_FORM_PASSWORD] : "";

        const errors = login_key && login_key[LOGIN_ERRORS] ? login_key[LOGIN_ERRORS] : [];
        const loading = login_key && login_key[LOGIN_REQEUST_LOADING] ? login_key[LOGIN_REQEUST_LOADING] : false;

        return {
            email,
            password,

            errors,
            loading
        }
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
