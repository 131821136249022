<template>
  <div class="Signup_pop_overlay" @click="reportModal('showReport')">
    <div class="wrap_Report_modal">
      <div class="modal_Report" @click.stop="() => null">
        <div v-if="!showContent2" class="content1">
          <h4 class="name">Report</h4>
          <div class="text">
            Are you sure you want to <span>report this?</span>
          </div>
          <div class="btn">
            <button class="cancel" @click.stop="reportModal('showReport')">
              CANCEL
            </button>
            <button @click.stop="confirm" class="confirm">CONFIRM</button>
          </div>
        </div>
        <div v-if="showContent2" class="content2">
          <div v-if="GET_REPLY_DATA.success === 1">
            <svg
              width="55"
              height="56"
              viewBox="0 0 55 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.0125 12.455L27.5875 6.5325C27.815 4.1525 30.0375 2.5375 32.2825 3.1175C35.66 3.9925 38.0325 7.13 38.0325 10.7275V18.315C38.0325 20.0025 38.0325 20.8475 38.3975 21.465C38.605 21.8175 38.89 22.115 39.23 22.3275C39.83 22.705 40.6475 22.705 42.28 22.705H43.27C47.5275 22.705 49.655 22.705 50.965 23.68C51.9475 24.4125 52.64 25.485 52.915 26.7C53.28 28.325 52.4625 30.3575 50.825 34.4175L50.01 36.4425C49.5372 37.6153 49.3438 38.882 49.445 40.1425C50.025 47.3275 44.3275 53.38 37.3625 52.9825L11.3025 51.485C8.45496 51.3225 7.03246 51.24 5.74746 50.1025C4.45996 48.965 4.21746 47.7925 3.73496 45.45C2.70804 40.4665 2.75491 35.3214 3.87246 30.3575C4.57996 27.2375 7.55996 25.5575 10.6375 25.9575C18.7975 27.0075 26.195 20.91 27.0125 12.4575V12.455Z"
                stroke="#D1433A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.5002 26.75L15.1577 27.8925C12.9404 35.2837 13.0601 43.1793 15.5002 50.5"
                stroke="#D1433A"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Thanks
          </div>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="56"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.884 2.532C12.538 1.878 11.462 1.878 11.116 2.532L2.11596 19.532C2.03494 19.6844 1.99478 19.8551 1.99938 20.0276C2.00398 20.2001 2.05319 20.3685 2.14221 20.5164C2.23124 20.6642 2.35704 20.7864 2.50736 20.8712C2.65768 20.956 2.82739 21.0003 2.99996 21H21C21.1724 21.0004 21.342 20.956 21.4922 20.8713C21.6424 20.7866 21.7681 20.6644 21.8571 20.5167C21.946 20.3689 21.9951 20.2006 21.9997 20.0282C22.0042 19.8559 21.964 19.6852 21.883 19.533L12.884 2.532ZM13 18H11V16H13V18ZM11 14V9H13L13.001 14H11Z"
                fill="#EB3333"
              />
            </svg>
            Error!
          </div>
          <div class="text">
            {{
              GET_REPLY_DATA.success === 1
                ? "Your report has been submitted"
                : GET_REPLY_DATA.message
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showContent2: false,
    };
  },
  methods: {
    ...mapActions(["ReportReply"]),
    async confirm() {
      await this.ReportReply(this.GET_REPLY_ID);
      this.showContent2 = true;
    },
    reportModal(showType) {
      this.$parent.showModals(showType);
    },
  },
  computed: {
    ...mapGetters(["GET_REPLY_ID", "GET_REPLY_DATA"]),
  },
};
</script>

<style lang="scss" scoped>
.wrap_Report_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .modal_Report {
    width: 370px;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    box-shadow: 0px 4px 20px rgba(71, 80, 91, 0.25);
    border-radius: 4px;
    .content1 {
      padding: 22px 20px 30px;
      h4 {
        font-family: HelveticaNeueBold;
        color: #071526;
        font-size: 18px;
      }
      .text {
        color: #47505b;
        padding: 25px 0 23px;
        font-size: 15px;
        border-bottom: 1px solid #e6eaf0;
        border-top: 1px solid #e6eaf0;
        margin: 17px 0 20px;
        font-family: HelveticaNeueNormal;
        span {
          font-family: HelveticaNeueMedium;
        }
      }
      .btn {
        display: flex;
        justify-content: space-between;
        padding: 0;
        button {
          width: 160px;
          height: 40px;
          font-family: HelveticaNeueBold;
          border-radius: 4px;
          display: flex;
          align-items: center;
          font-size: 13px;
          justify-content: center;
          padding: 0;
        }
        .cancel {
          background: #ffffff;
          border: 1.3px solid #8b949f;
          color: #8b949f;
        }
        .confirm {
          background: #D1433A;
          border: 1.3px solid #D1433A;
          color: #fff;
        }
      }
    }
  }
  .content2 {
    padding: 35px 20px 20px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #071526;
      font-size: 18px;
      font-family: HelveticaNeueBold;
      svg {
        margin-bottom: 17px;
      }
    }
    .text {
      margin-top: 17px;
      font-size: 15px;
      color: #47505b;
      padding: 24px 0;
      border-bottom: 1px solid #e6eaf0;
      border-top: 1px solid #e6eaf0;
      font-family: HelveticaNeueNormal;
    }
  }
}
</style>
