<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Hidden</h3>
        <h6>To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input v-model="contact_us_settings.visibility" type="checkbox" id="switch31"/>
        <label class="switch_sm" for="switch31">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4>How do you want user to contact you?</h4>
        <p>Choose how you would like to be contacted</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">

          <div class="col-5">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">Button</label>
              <div class="select_box">
                <select v-model="contact_us_settings.button" class="form_control">
                  <option value="0">Send E-mail</option>
                  <option value="1">E-mail 1</option>
                  <option value="2">E-mail 2</option>
                  <option value="3">E-mail 3</option>
                </select>
              </div>

            </div>
          </div>
          <div class="col-7">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">E-mail</label>
              <input v-model="contact_us_settings.email" class="form_control" type="email" placeholder="E-mail">
            </div>
          </div>

        </div>

      </div>


    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/message-circle.svg" alt="message"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Contact Us
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contact_us_settings: {
        visibility: false,
        button:null,
        email: null
      }
    }
  },
  props: {
    contactUsSettings: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    'contact_us_settings.visibility'() {
      this.$emit('getContactUsData', this.contact_us_settings)
    },
    'contact_us_settings.button'() {
      this.$emit('getContactUsData', this.contact_us_settings)
    },
    'contact_us_settings.email'() {
      this.$emit('getContactUsData', this.contact_us_settings)
    },
    contactUsSettings() {
      this.setContactUsSettingsData()
    }
  },
  mounted() {
    this.setContactUsSettingsData()
  },
  methods: {
    setContactUsSettingsData() {
      this.contact_us_settings = this.contactUsSettings
    }
  }
}
</script>
