<template>
  <div class="row">
    <div class="col-12">
      <div class="review_request-people">
        <h1>
          Friend Requests
          <span
            v-if="$store.state.friends.total_pending_friend_request_count !== 0"
            >({{
              $store.state.friends.total_pending_friend_request_count
            }})</span
          >
        </h1>
        <a href="javascript:;">Review Your Friend Requests</a>
      </div>
    </div>
    <div class="wr_content">
      <div
        v-for="(pending_friend_request, index) in $store.state.friends
          .pending_friend_requests"
        :key="index"
        :class="{ mr_r_0: (index + 1) % 4 === 0 }"
      >
        <div class="birthday-card">
          <div class="birthday-card-body px-2">
            <div class="col-md-12 px-2 cursor-pointer" @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    pending_friend_request  && pending_friend_request.username
                      ? pending_friend_request.username
                      : ''
                  )
                ">
              <b-img
                :src="pending_friend_request.artwork_url"
                rounded="circle"
                alt="User Image"
                @error="onProfileImageError($event, getFirstAlphabetOfUserName(pending_friend_request))"
                style="
                  width: 130px !important;
                  height: 130px !important;
                  margin: 0 auto;
                  display: flex;
                "
              ></b-img>
            </div>
            <div class="col-md-12 pt-3 px-0 text-center">
              <h6
                class="birthday-h-font cursor-pointer"
                @click="
                  redirectWidgetsToProfilePage(
                    $event,
                    pending_friend_request.username
                  )
                "
              >
                {{ pending_friend_request.name }}
              </h6>
            </div>
            <div class="col-md-12">
              <h6 style="display: flex" class="birthday-p1-font">
                <span class="location_map">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="pending_friend_request.mututal_friends !== 0"
                  >
                    <path
                      d="M5.25 10.5C5.25 10.5 4.5 10.5 4.5 9.75C4.5 9 5.25 6.75 8.25 6.75C11.25 6.75 12 9 12 9.75C12 10.5 11.25 10.5 11.25 10.5H5.25ZM8.25 6C8.84674 6 9.41903 5.76295 9.84099 5.34099C10.2629 4.91903 10.5 4.34674 10.5 3.75C10.5 3.15326 10.2629 2.58097 9.84099 2.15901C9.41903 1.73705 8.84674 1.5 8.25 1.5C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75C6 4.34674 6.23705 4.91903 6.65901 5.34099C7.08097 5.76295 7.65326 6 8.25 6Z"
                      fill="#47505B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.912 10.4998C3.80082 10.2657 3.74537 10.009 3.75 9.74983C3.75 8.73358 4.26 7.68733 5.202 6.95983C4.73182 6.81496 4.24196 6.74411 3.75 6.74983C0.75 6.74983 0 8.99983 0 9.74983C0 10.4998 0.75 10.4998 0.75 10.4998H3.912Z"
                      fill="#47505B"
                    />
                    <path
                      d="M3.375 6C3.87228 6 4.34919 5.80246 4.70083 5.45083C5.05246 5.09919 5.25 4.62228 5.25 4.125C5.25 3.62772 5.05246 3.15081 4.70083 2.79917C4.34919 2.44754 3.87228 2.25 3.375 2.25C2.87772 2.25 2.40081 2.44754 2.04917 2.79917C1.69754 3.15081 1.5 3.62772 1.5 4.125C1.5 4.62228 1.69754 5.09919 2.04917 5.45083C2.40081 5.80246 2.87772 6 3.375 6Z"
                      fill="#47505B"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="pending_friend_request.mututal_friends === 0"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                      fill="#47505B"
                    />
                  </svg>
                </span>
                <span
                  style="padding-left: 8px; font-size: 11px !important"
                  v-if="pending_friend_request.mututal_friends !== 0"
                >
                  {{ pending_friend_request.mututal_friends }} mutual
                  friends</span
                >
                <span
                  class="mr-0"
                  v-if="
                    pending_friend_request.mututal_friends === 0 &&
                    pending_friend_request.current_city
                  "
                  >{{ pending_friend_request.current_city }} </span
                ><span
                  class="ml-0"
                  v-if="
                    pending_friend_request.country.length &&
                    pending_friend_request.mututal_friends === 0
                  "
                  >,{{ pending_friend_request.country[0].name }}</span
                >
              </h6>
            </div>
            <div
              v-if="pending_friend_request.friend_status.status === 'pending'"
            >
              <div class="col-md-12 px-2 pt-3">
                <button
                  class="friend-request-btn w-100"
                  type="button"
                  @click="
                    responseFriendRequest(
                      pending_friend_request.friend_status,
                      1
                    )
                  "
                >
                  <span>Accept</span>
                </button>
              </div>
              <div class="col-md-12 px-2 pt-2">
                <button
                  class="remove-btn w-100"
                  type="button"
                  @click="
                    responseFriendRequest(
                      pending_friend_request.friend_status,
                      0
                    )
                  "
                >
                  <span>Decline</span>
                </button>
              </div>
            </div>
            <div
              v-if="
                pending_friend_request.friend_status.status === 'confirmed' ||
                pending_friend_request.friend_status.status === 'rejected'
              "
            >
              <div class="col-md-12 px-2 pt-3">
                <button
                  class="friend-request-btn w-100"
                  type="button"
                  v-if="
                    pending_friend_request.friend_status.status === 'confirmed'
                  "
                >
                  <span>Request Accepted</span>
                </button>
              </div>
              <div class="col-md-12 px-2 pt-2">
                <button
                  class="remove-btn w-100"
                  type="button"
                  v-if="
                    pending_friend_request.friend_status.status === 'rejected'
                  "
                >
                  <span>Request Declined</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="show-more musicbar_more-frd"
      v-if="
        $store.state.friends.total_pending_friend_request_count >
        $store.state.friends.pending_friend_requests.length
      "
    >
      <a href="javascript:;" @click="showMore">
        <span class="show_more_bg">
          <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.24219 0.689941V7.01994"
              stroke="#071526"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M1 3.85498H7.48702"
              stroke="#071526"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          SHOW 10 MORE FRIEND REQUESTS
        </span>
      </a>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {};
  },
  methods: {
    responseFriendRequest(payload, action) {
      this.$store.dispatch("responseFriendRequest", { payload, action });
    },
    showMore() {
      this.$store.dispatch("getPendingFriendRequests");
    },
  },
};
</script>
<style lang="scss" scoped>
.wr_content {
  display: flex;
  flex-wrap: wrap;
  .mr_r_0 {
    div {
      margin-right: 0px;
    }
  }
  .frind_list_box {
    padding: 20px;
    width: 170px;
    flex-direction: column;
  }
  .friend-image-wrapper {
    width: 130px;
    height: 130px;
    img {
      width: 100%;
    }
  }
  .friend_profile_content {
    margin: 16px 0;
    text-align: center;
  }
  .friend_profile_name {
    color: #071526;
    opacity: 0.9;
    font-size: 14px;
  }
}
.birthday-table td,
th {
  border: none !important;
  text-align: center;
  font-size: 14px;
}
.birthday-table td {
  padding: 11.7px 0px;
}
.birthday-card-btn {
  color: #ffffff;
  background: #D1433A;
  padding: 15px 0px 15px 0px;
  border-radius: 4px !important;
  border: none;
  font-weight: normal;
  font-family: "HelveticaNeueBold";
  border-radius: 4px;
  font-size: 13px;
  letter-spacing: 0.05em;
}
.friend-request-btn {
  color: #D1433A;
  background: #D1433A1A;
  margin: 0;
  border: 1px solid #D1433A;
  padding: 5px 5px 5px 5px;
  border-radius: 2px;
  border: none;
  font-weight: 500;
  font-family: "HelveticaNeuemedium";
}
.friend-request-btn:hover {
  background-color: #D1433A;
  color: #ffffff;
}
.day-color {
  background: #D1433A;
  border-radius: 100px;
  color: white;
}
.c-month-center {
  color: #D1433A;
  font-size: 16px;
  font-weight: 700;
  font-family: "HelveticaNeueMedium";
}
.calender-heading {
  font-size: 17px;
  font-family: "HelveticaNeueMedium";
  font-weight: 700;
}
.birthday-heading {
  font-family: "HelveticaNeueMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #47505b;
}
.birthday-card {
  width: 170px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-right: 30px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}
.birthday-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.birthday-btn {
  color: #D1433A;
  background: #fff;
  margin: 0;
  border: 1.3px solid #D1433A;
  border-radius: 4px;
  padding: 3px 5px 3px 5px;
}
.birthday-h-font {
  font-family: "HelveticaNeueMedium";
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.03em;
  margin-bottom: 4px;
  text-align: center;
}
.birthday-p-font {
  font-family: "HelveticaNeueMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  text-align: center;
}
.birthday-p-font {
  font-family: "HelveticaNeueMedium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 10px;
  text-align: center;
  color: #47505b;
}
.birthday-a-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #e6eaf0;
  background-clip: border-box;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
}
.icon-center {
  margin: 0 auto;
  display: flex;
}
.icon {
  margin: 3px 8px 0 0 !important;
}

.musicbar_more-frd .show_more_bg {
  display: flex;
  align-items: center;
  font-family: "HelveticaNeueMedium";
}
.birthday-p1-font {
  font-family: "HelveticaNeueNormal";
  letter-spacing: 1px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  margin-bottom: 0px;
  text-align: center;
  color: #8b949f;
  justify-content: center;
  letter-spacing: 0.5px;

}
.birthday-heading-1 {
  font-family: "HelveticaNeueBold";
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #47505b;
}
.send-request-btn {
  color: #D1433A;
  background: #fff;
  margin: 0;
  border: 1px solid #D1433A;
  padding: 3px 5px 3px 5px;
  border-radius: 2px;
  font-weight: 500;
  font-family: "HelveticaNeuemedium";
}
.send-request-btn:hover {
  background-color: #D1433A;
  color: #ffffff;
}
.remove-btn {
  background-color: #e6eaf0;
  color: #47505b;
  width: 100px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  border: none;
}
.remove-btn:hover {
  background-color: #c4ccd6;
  color: #47505b;
}
.float {
  float: right;
}
</style>
