<template>
  <div class="wr_online_cards" :class="{ mr_r_0: style_for_mr }">
    <div class="img_online_people">
      <img
        @click="redirectWidgetsToProfilePage($event, user_info.username)"
        :src="user_info && user_info.artwork_url ? user_info.artwork_url : ''"
        alt=""
      />
    </div>
    <div
      class="name_user"
      @click="redirectWidgetsToProfilePage($event, user_info.username)"
    >
      {{ user_info && user_info.full_name ? user_info.full_name : "User Name" }}
    </div>
    <div class="mutual_friends_counts">
      <span v-if="user_info && user_info.mututal_friends">
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.25 9.5C5.25 9.5 4.5 9.5 4.5 8.75C4.5 8 5.25 5.75 8.25 5.75C11.25 5.75 12 8 12 8.75C12 9.5 11.25 9.5 11.25 9.5H5.25ZM8.25 5C8.84674 5 9.41903 4.76295 9.84099 4.34099C10.2629 3.91903 10.5 3.34674 10.5 2.75C10.5 2.15326 10.2629 1.58097 9.84099 1.15901C9.41903 0.737053 8.84674 0.5 8.25 0.5C7.65326 0.5 7.08097 0.737053 6.65901 1.15901C6.23705 1.58097 6 2.15326 6 2.75C6 3.34674 6.23705 3.91903 6.65901 4.34099C7.08097 4.76295 7.65326 5 8.25 5Z"
            fill="#47505B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.912 9.49983C3.80082 9.26569 3.74537 9.00899 3.75 8.74983C3.75 7.73358 4.26 6.68733 5.202 5.95983C4.73182 5.81496 4.24196 5.74411 3.75 5.74983C0.75 5.74983 0 7.99983 0 8.74983C0 9.49983 0.75 9.49983 0.75 9.49983H3.912Z"
            fill="#47505B"
          />
          <path
            d="M3.375 5C3.87228 5 4.34919 4.80246 4.70083 4.45083C5.05246 4.09919 5.25 3.62228 5.25 3.125C5.25 2.62772 5.05246 2.15081 4.70083 1.79917C4.34919 1.44754 3.87228 1.25 3.375 1.25C2.87772 1.25 2.40081 1.44754 2.04917 1.79917C1.69754 2.15081 1.5 2.62772 1.5 3.125C1.5 3.62228 1.69754 4.09919 2.04917 4.45083C2.40081 4.80246 2.87772 5 3.375 5Z"
            fill="#47505B"
          />
        </svg>
        {{
          user_info && user_info.mututal_friends ? user_info.mututal_friends : 0
        }}
        mutual friends
      </span>
      <span v-else>
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 11.5C5 11.5 9.5 8.5 9.5 5C9.5 3.80653 9.02589 2.66193 8.18198 1.81802C7.33807 0.974106 6.19347 0.5 5 0.5C3.80653 0.5 2.66193 0.974106 1.81802 1.81802C0.974106 2.66193 0.5 3.80653 0.5 5C0.5 8.5 5 11.5 5 11.5ZM6.5 5C6.5 5.82843 5.82843 6.5 5 6.5C4.17157 6.5 3.5 5.82843 3.5 5C3.5 4.17157 4.17157 3.5 5 3.5C5.82843 3.5 6.5 4.17157 6.5 5Z"
            fill="#47505B"
          />
        </svg>
        Lives in
        {{
          user_info && user_info.current_city
            ? user_info.current_city + ", "
            : " "
        }}{{
          user_info && user_info.country && user_info.country.length > 0
            ? user_info.country[0].name
            : " "
        }}
      </span>
    </div>
    <button
      @click="
        user_info.friend_status &&
        (user_info.friend_status.status === 'pending' ||
          user_info.friend_status.status === 'confirmed')
          ? null
          : addFriendApi(user_info)
      "
      class="btn_online_people"
      :class="{
        'request-send':
          user_info &&
          user_info.friend_status &&
          user_info.friend_status.status === 'pending',
      }"
    >
      {{
        user_info &&
        user_info.friend_status &&
        user_info.friend_status.status === "pending"
          ? "Request sent"
          : user_info &&
            user_info.friend_status &&
            user_info.friend_status.status === "confirmed"
          ? "Friends"
          : "Add Friend"
      }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    user_info: {
      type: Object,
      required: true,
    },
    userPage: {
      type: Number,
      default: 0,
    },
    style_for_mr: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["getAllOnlineUsers"]),
    async addFriendApi(payload) {
      await this.$store.dispatch("addFriendApi", payload);
      await this.getAllOnlineUsers(this.userPage);
    },
  },
  // created() {
  //   console.log("this.user_info", this.user_info);
  // },
};
</script>

<style lang="scss" scoped>
.wr_online_cards {
  margin-bottom: 10px;
  margin-right: 30px;
  width: 170px;
  padding: 20px 19px 20px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 2px;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 8px 20px rgba(71, 80, 91, 0.15);
    transition: all 0.3s;
  }
  .img_online_people {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #e6eaf0;
    margin-bottom: 16px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .name_user {
    font-family: HelveticaNeueBold;
    color: #071526;
    opacity: 0.9;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .mutual_friends_counts {
    margin: 7px 0 16px;
    font-family: HelveticaNeueNormal;
    color: #47505b;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.5px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      margin-right: 8px;
    }
  }

  .btn_online_people {
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    font-family: HelveticaNeueMedium;
    background: #D1433A1A;
    border-radius: 2px;
    color: #D1433A;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    &.request-send {
      border: 1px solid #D1433A !important;
      border-radius: 2px;
      background: #fff !important;
      color: #D1433A !important;
    }

    &:hover {
      color: #ffffff;
      background: #D1433A;
      transition: all 0.3s;
    }
  }
}

.friend_botton {
  color: #ffffff;
  background: #D1433A;
}

.mr_r_0 {
  margin-right: 0;
}
</style>
