<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8 15.3337C8 15.3337 14 11.3337 14 6.66699C14 5.07569 13.3679 3.54957 12.2426 2.42435C11.1174 1.29913 9.5913 0.666992 8 0.666992C6.4087 0.666992 4.88258 1.29913 3.75736 2.42435C2.63214 3.54957 2 5.07569 2 6.66699C2 11.3337 8 15.3337 8 15.3337ZM10 6.66699C10 7.77156 9.10457 8.66699 8 8.66699C6.89543 8.66699 6 7.77156 6 6.66699C6 5.56242 6.89543 4.66699 8 4.66699C9.10457 4.66699 10 5.56242 10 6.66699Z"
                fill="#47505B"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Location</p>
          <button class="btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <form class="setting-form setting-form1">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label for="Select">Country</label>
                  <div class="set-custom-select" style="width: 280px;">
                    <b-form-select id="category" v-model="country" :options="countries"  value-field="value"
                      class="mb-2 mr-sm-2 mb-sm-0 no-select-caret" size="sm" :value="null">
                    </b-form-select>
                         <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('country')">{{ _handleErrorMessage("country") }}</label>
                    <div class="svg-icon">
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="form-group">
                  <label for="Select">City</label>
                  <div class="set-custom-select" style="width: 410px;">
                    <b-form-select v-model="current_city" :options="cities" value-field="text"
                      text-field="text"
                      class="mb-2 mb-sm-0 no-select-caret" style="margin-left: 3px" :disabled="citiesOFSelectedCountry.loading" size="sm"
                      :value="null">
                    </b-form-select>
                        <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('current_city')">{{ _handleErrorMessage("current_city") }}</label>
                    <div class="svg-icon">
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <div class="form-group">
                  <label for="Select">Hometown</label>
                  <div class="set-custom-select" style="width: 280px;">
                    <input type="text" class="form-control light-grey-color" id="hometown" v-model="hometown"
                      value="null" />
                      <label class="error_msg error_msg_red"
                         v-if="_handleErrorMessage('hometown')">{{ _handleErrorMessage("hometown") }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="button" @click="submit" class="btn save-btn">
                  {{ loading ? "Please Wait..." : "Save Changes" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class=""></div>
    </div>
    <full-page-loader :show="loading === true" />
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getCitiesOfCountry } from "../../../apis/APIs";
import * as Helper from '../../../apis/Helper.js';
import {EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR} from '../../../store/types';
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";

export default {
  components: {
    EyeIcon
  },
  data() {
    return {
      country: null,
      current_city: null,
      hometown: null,
      citiesOFSelectedCountry: {
        loading: false,
        list: []
      },
      loading: "",
      message: "",
      success_message: "",
    };
  },
  mounted() {
    this.init();
    this.$store.dispatch('fetchCountries')
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    },
    countries() {
      return this.$store.getters.getCountries.map(country => {
        return {
          value: country.id,
          text: country.name,
        }
      })
    },
    cities() {
      if (this.citiesOFSelectedCountry.list.length > 0) {
        return this.citiesOFSelectedCountry.list.map(city => {
          return {
            value: city.id,
            text: city.name,
          }
        })
      }
      return []
    },
  },
  watch: {
    'country': async function (newVal) {
      this.current_city = this.current_city;
      if (newVal === null) {
        this.citiesOFSelectedCountry.list = []
      } else {
        await this.FetchCitiesOfSelectedCountry(newVal)
      }
    },
  },
  methods: {
    ...mapActions(["user_location", "fetchUserData"]),
    ...mapMutations(["updateEditProfileUIConstraints"]),
   async init() {
     await this.$store.dispatch("loading", true);
      const {user_data} =  this.user || {};
      const {
        country = "",
        current_city = "",
        hometown = "",
      } = user_data || {};

      this.country = country[0].id;
      this.current_city = current_city;
      this.hometown = hometown;
     await this.$store.dispatch("loading", false);
    },
    async FetchCitiesOfSelectedCountry(id) {
      try {
        await this.$store.dispatch("loading", true);
        this.citiesOFSelectedCountry.loading = true
        const { data } = await getCitiesOfCountry(id)
        const { success, information } = data
        if (success === 1) {
          this.citiesOFSelectedCountry.list = information
        }
        this.citiesOFSelectedCountry.loading = false
        await this.$store.dispatch("loading", false);

      } catch (e) {
        await this.$store.dispatch("loading", false);
        this.citiesOFSelectedCountry.loading = false
        console.error(e)
      }
    },
    isError(key) {
    if (this.errors && this.errors.length) {
      return this.errors.findIndex(ele => ele.fieldName === key) > -1
        ? {
          status: true,
          message:
            this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
        }
        : { status: false, message: "" };
    } else return { status: false, message: "" };
  },
  _handleErrorMessage(key) {
    const { message = "" } = this.isError(key);

    return message;
  },
  submit(e) {
    if (e) e.preventDefault();
    const { loading, hometown, current_city, country } = this;

    if (loading) return;

    let body = {
      hometown,
      current_city,
      country,
    };

    Helper.validate(Object.keys(body), body)
      .then(async ({ status, response }) => {

        if (status) {
          this.updateEditProfileUIConstraints({
            [EDIT_PROFILE_ERRORS]: []
          });
          try {
            this.message = "";
            this.loading = true;
            const { status, message } = await this.user_location(body);
            this.loading = false;
            switch (status) {
              case SUCCESS:
                this.success_message = message;
                await this.fetchUserData();
                setTimeout(() => {
                  this.success_message = ""
                }, 5000);
                break;
              case ERROR:
                this.message = message;
                break;
            }
          } catch (e) {
            this.message = e && e.message ? e.message : "Please try again.";
          }
        } else this.updateEditProfileUIConstraints({
          [EDIT_PROFILE_ERRORS]: response && response.length ? response : []
        });
      })
      .catch(err => console.log(err));
  }
  },
};
</script>

<style>
select.custom-select.no-select-caret {
  background-image: none !important;
}
</style>