<template>
  <div class="main-container main-container_full" :class="custom_css_class">
    <LeftNav v-if="currentUrl != 'book-artist'"/>
    <router-view></router-view>
  </div>
</template>
<script>
import LeftNav from "@/components/Group/LeftNav";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      "form_data": "main_container_full_class_map_to_props"
    }),
    custom_css_class() {
      const {main_container_full_classes = ""} = this.form_data;

      return main_container_full_classes
    }
  },
  props: {
    ShowMusicFooter: Boolean,
    ShowLeftNav: Boolean,
  },
  beforeMount() {
    this.showCon();
  },
  components: {
    LeftNav,
  },
  methods: {
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
  },
};
</script>
