<template>
  <div class="ag-container">
    <MusicSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="'/assets/img/sponsership.svg'" alt="icon">Sponsorship
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="sponsor_plan">
                <div class="sponsor_plan_left">
                  <div class="sponsor_plan_top">
                    <h5>Light</h5>
                    <h6>$ 5,99 / month</h6>
                  </div>
                  <div class="sponsor_plan_bottom">
                    Free 2 albums and 2 tracks; 2 free livestreaming shows; 10% merch discount; Advance listen of new
                    music.
                  </div>
                </div>
                <div class="sponsor_plan_right">
                  <button class="preview_engage">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M8.5 1.50015C8.63132 1.36883 8.78722 1.26466 8.9588 1.19359C9.13038 1.12252 9.31428 1.08594 9.5 1.08594C9.68572 1.08594 9.86962 1.12252 10.0412 1.19359C10.2128 1.26466 10.3687 1.36883 10.5 1.50015C10.6313 1.63147 10.7355 1.78737 10.8066 1.95895C10.8776 2.13054 10.9142 2.31443 10.9142 2.50015C10.9142 2.68587 10.8776 2.86977 10.8066 3.04135C10.7355 3.21293 10.6313 3.36883 10.5 3.50015L3.75 10.2502L1 11.0002L1.75 8.25015L8.5 1.50015Z"
                          stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    <span>Edit</span>
                  </button>
                  <button class="remove_engage ml_10px">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3L3 9" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                      <path d="M3 3L9 9" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="divider_line mb_17px"></div>
              <div class="engage_fields_group mb_27px">
                <div class="row">
                  <div class="col-4">
                    <div class="engage_field">
                      <label class="engage_fields_label">Price</label>
                      <div class="prepend">
                        <input type="text" class="form_control">
                        <div class="prepend_box">
                          $ per month
                        </div>
                      </div>
                      <small class="text_muted">You’ll get 8,99 (10% system fee)</small>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="engage_field">
                      <label class="engage_fields_label">Title</label>
                      <input type="text" class="form_control" value="Medium">
                      <small class="text_muted">We’ll show this title for user as Plan name</small>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="engage_field">
                      <label class="engage_fields_label">Free Albums</label>
                      <input type="text" class="form_control" value="2">
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="engage_field">
                      <label class="engage_fields_label">Free Tracks</label>
                      <input type="text" class="form_control" value="2">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="engage_field">
                      <label class="engage_fields_label">Free Livestreaming Shows</label>
                      <input type="text" class="form_control" value="2">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="engage_field">
                      <label class="engage_fields_label">Merch Discount (%)</label>
                      <input type="text" class="form_control" value="10">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="engage_field">
                      <label class="engage_fields_label">Additional Perks (list one perk - one line)</label>
                      <textarea name="" id="" class="form_control" cols="30" rows="10"
                                placeholder="Additional perks (one perk - one line)"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="save_plan_buttons">
                      <button class="outline_danger_button_sm w130" type="button">Remove</button>
                      <button class="primary_button_sm w200" type="button">Save Plan</button>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="divider_line mb_20px"></div>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <button class="outline_primary_button_sm_iconed w210" type="button">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.99935 12.8337C10.221 12.8337 12.8327 10.222 12.8327 7.00033C12.8327 3.77866 10.221 1.16699 6.99935 1.16699C3.77769 1.16699 1.16602 3.77866 1.16602 7.00033C1.16602 10.222 3.77769 12.8337 6.99935 12.8337Z"
                            stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M7 4.66699V9.33366" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        <path d="M4.66602 7H9.33268" stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                      </svg>
                      ADD SPONSOR PLAN
                    </button>
                  </div>
                  <div class="col-12">
                    <div class="divider_line mt_20px"></div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button class="prime_button" type="button">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'

export default {
  data() {
    return {}
  },
  components: {
    MusicSubHeader
  },
  computed: {},
  methods: {}
}
</script>
