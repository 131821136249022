import Vue from "vue";
import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import GoogleAuth from "./configs/google_oAuth.js";
import AppConfig from "./configs/AppConfig";
import VueSocialSharing from "vue-social-sharing";
import VCalendar from "v-calendar";
import VueSessionStorage from "vue-sessionstorage";
import Notifications from 'vue-notification'
import VueClipboard from 'vue-clipboard2'
import VueEllipseProgress from 'vue-ellipse-progress';
import VueApexCharts from 'vue-apexcharts'
import shareIt from 'vue-share-it';
import VueSocialauth from 'vue-social-auth'

Vue.use(VueApexCharts)
Vue.use(VueEllipseProgress);
Vue.use(shareIt);
Vue.use(VueClipboard)
Vue.use(Notifications);
Vue.use(VueSessionStorage);
Vue.config.productionTip = false;
Vue.use(VCalendar, {
  componentPrefix: "vc"
});
Vue.use(VueSocialauth, {

  providers: {
    google: {
      clientId: '96249110523-tc4tn9i1etrqdrbjim8lbgninpnpkjep.apps.googleusercontent.com',
      redirectUri: 'https://playground-api.septemsystems.com/auth/google/callback' // Your client app URL
    }
  }
})

Vue.component('apexchart', VueApexCharts)
// Google Location
import * as VueGoogleMaps from "vue2-google-maps"
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: AppConfig.google_location_api,
    libraries: "places"
  }
});

//plugins
import "./plugins/vue-bootstrap";
import "./plugins/listOption";
import "./plugins/playlistModal";
import "./plugins/sweetAlert2";
import "./plugins/perfectScrollBar";
import "./plugins/loader";
import "./plugins/cropper";
import "./plugins/PostShare";

//mixins
import songMixins from "./mixins/songs.js";
import profile from "./mixins/profile.js";
import sweetAlertMixin from "./mixins/sweetAlert.js";
import system from "./mixins/system.js";
import getStream from "./mixins/getStream.js"
import launchRoom from "./mixins/launchRoom.js"
import forum from "./mixins/forum.js";
import twilio from "./mixins/twilio.js"

Vue.config.productionTip = false;
Vue.use(Vuelidate);

Vue.use(VueMoment, {
  moment
});

const gauthOption = {
  clientId:
    "163919161563-j77kmt1mirspfmdeigparpq2gpus3egs.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account"
};
Vue.use(GoogleAuth, gauthOption);

Vue.use(VueSocialSharing);
Vue.mixin(songMixins);
Vue.mixin(profile);
Vue.mixin(sweetAlertMixin);
Vue.mixin(system);
Vue.mixin(getStream);
Vue.mixin(launchRoom);
Vue.mixin(forum);
Vue.mixin(twilio);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  }
}).$mount("#app");

// var isWin = navigator.platform == "Win32" || navigator.platform == "Windows";
// var isMac =
//   navigator.platform == "Mac68K" ||
//   navigator.platform == "MacPPC" ||
//   navigator.platform == "Macintosh";
// if (isWin) {
//   document.body.classList.add("win");


// }
// if (isMac) {
//   document.body.classList.add("mac");
// }
// var newStyle = document.createElement('style');
// newStyle.appendChild(document.createTextNode("@font-face {font-family: " + HelveticaNeueBold + ";src: url('" + ".." / fonts / HelveticaNeueBold.ttf + "');}"));

// document.head.appendChild(newStyle);



