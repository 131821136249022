import {
  userEvents,
} from "../../apis/APIs";
import {
  ERROR,
  SUCCESS,
} from "../types";
import Vue from "vue";
import createFormData from "../../mixins/common";


const INITIAL_STATE = {
  allEventsPast:[],
  allEventsIncoming:[],
  totalPastEvents: 0,
  totalIncomingEvents: 0,
  eventValue: '',
  eventOffset: 0,
};

export const state = {...INITIAL_STATE};

export const actions = {
  async getUserEvents({state, commit, dispatch}, payload ) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        commit('SET_EVENTS_VALUE', payload);
        if(state.eventValue === 'past'){
          const DATA = await userEvents(state.eventValue, state.totalPastEvents,8);
          for (let i=0; i < DATA.data.information.data.length; i++){
            state.allEventsPast.push(DATA.data.information.data[i])
          }
          state.totalPastEvents = DATA.data.information.total
        }
        else {
          const DATA = await userEvents(state.eventValue, state.totalIncomingEvents,8);
          for (let i=0; i < DATA.data.information.data.length; i++){
            state.allEventsIncoming.push(DATA.data.information.data[i])
          }
          state.totalIncomingEvents = DATA.data.information.total
        }
        dispatch('loading', false);
        resolve({
          status: SUCCESS,
          message: "",
        });
      } catch (error) {
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },

};

export const mutations = {
  SET_All_INCOMING_EVENTS(state, payload) {
    try {
      state.allEventsIncoming = payload
    } catch (e) {
      console.error(e.message, 'unable to set incoming events')
    }
  },
  SET_All_PAST_EVENTS(state, payload) {
    try {
      state.allEventsPast = payload
    } catch (e) {
      console.error(e.message, 'unable to set past events')
    }
  },
  SET_TOTAL_PAST_EVENT(state, payload){
    try {
      state.totalPastEvents = payload
    } catch (e) {
      console.error(e.message, 'unable to set past events')
    }
  },
  SET_TOTAL_INCOMING_EVENT(state, payload){
    try {
      state.totalIncomingEvents = payload
    } catch (e) {
      console.error(e.message, 'unable to set incoming events')
    }
  },
  SET_EVENTS_VALUE(state, payload) {
    try {
      state.eventValue = payload
    } catch (e) {
      console.error(e.message, 'unable to set past events')
    }
  },
}

export const getters = {
  getPastEvents(state){
    return state.allEventsPast
  },
  getUpcomingEvents(state){
    return state.allEventsIncoming
  },
  getUpcomingTotal(state){
    return state.totalIncomingEvents
  },
  getPastTotal(state){
    return state.totalPastEvents
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};