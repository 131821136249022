<template>
  <header>
    <div class="logo-search-wrap">
      <div class="logo">
        <img
            class="for-logo-text header-logo audio_logo"
            :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
        />
      </div>
      <!-- <div class="header-search">
        <form
            class="form-inline search-form"
            method="get"
            action="https://devmusic.theplayground.co.uk/search"
            _lpchecked="1"
        >
          <div class="input-group place active">
            <input
                :style="{visibility: showMore ? 'visible' : 'hidden'}"

                type="text"
                class="form-control"
                name="query"
                placeholder="Search"
                aria-label="Search for products"
                aria-describedby="store-search"
            />
            <div class="svg-icon" id="events-search">
              <svg @click="showMore = true"
                   width="17"
                   height="17"
                   viewBox="0 0 17 17"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M7.66667 14.3333C11.3486 14.3333 14.3333 11.3486 14.3333 7.66667C14.3333 3.98477 11.3486 1 7.66667 1C3.98477 1 1 3.98477 1 7.66667C1 11.3486 3.98477 14.3333 7.66667 14.3333Z"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M16 16L12.375 12.375"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
        </form>
      </div> -->
    </div>
    <div class="action-block" v-if="!is_authenticated">
      <div
          class="btn btn-login"
          v-on:click="openModal('sign-up-email', 'login')"
      >
        <span>Log In</span>
      </div>

      <!-- <div
          class="btn btn-register Signup_btn_blue"
          v-on:click="openModal('sign-up-email', 'register')"
      >
        <span>Sign Up</span>
      </div> -->
    </div>
    <div class="action-block" v-else>
      <router-link to="/home">
        <div
            class="btn btn-register Signup_btn_blue"
        >
          <span>Dashboard</span>
        </div>
      </router-link>
    </div>
    <SignupEmail
        v-if="currentModal == 'sign-up-email'"
        @closeModal="closeModal"
        @nextStep="nextStep"
    />
    <LoginModule
        v-if="currentModal == 'login'"
        @closeModal="closeModal"
        @nextStep="nextStep"
        @previousStep="previousStep"
        @newModal="newModal"
    />
    <RestorePassword
        v-if="currentModal == 'restore-password'"
        @closeModal="closeModal"
        @nextStep="nextStep"
        @previousStep="previousStep"
        @newModal="newModal"
    />
    <ConfirmationModal
        v-if="currentModal == 'confirmation-modal'"
        @closeModal="closeModal"
    />
    <NeedSignUp
        v-if="currentModal == 'need-signup'"
        @closeModal="closeModal"
        @newModal="newModal"
    />
    <RegisterStep1
        v-if="currentModal == 'register-step-1'"
        @closeModal="closeModal"
        @newModal="newModal"
        :ref="clearData"
    />
    <RegisterStep2
        v-if="currentModal == 'register-step-2'"
        @closeModal="closeModal"
        @newModal="newModal"
        @clearFormData="formDataClear"
    />
    <VerifyEmail
        v-if="currentModal == 'verify-email'"
        @closeModal="closeModal"
        @newModal="newModal"
    />
    <RegisterCompleteModal
        v-if="currentModal == 'verification-status'"
        @closeModal="closeModal"
        @newModal="newModal"
    />
    <ResetPasswordSecurityCode
        v-if="currentModal == 'reset-password-security-code'"
        @closeModal="closeModal"
        @newModal="newModal"
    />
  </header>
</template>
<script type="application/javascript">
import SignupEmail from "@/components/Landing/modals/SignupEmail.vue";
import LoginModule from "@/components/Landing/modals/LoginModal.vue";
import RestorePassword from "@/components/Landing/modals/RestorePassword.vue";
import ConfirmationModal from "@/components/Landing/modals/ConfirmationModal.vue";
import NeedSignUp from "@/components/Landing/modals/NeedSignUp.vue";
import RegisterStep1 from "@/components/Landing/modals/RegisterStep1.vue";
import RegisterStep2 from "@/components/Landing/modals/RegisterStep2.vue";
import VerifyEmail from "@/components/Landing/modals/VerifyEmail.vue";
import RegisterCompleteModal from "@/components/Landing/modals/RegisterCompleteModal.vue";
import ResetPasswordSecurityCode from "@/components/Landing/modals/ResetPasswordSecurityCode.vue";

import {mapMutations, mapGetters} from 'vuex';

export default {
  data() {
    return {
      currentModal: "",
      modalFor: "",
      step: 1,
      showMore: false,
      clearData: false
    };
  },
  components: {
    SignupEmail,
    LoginModule,
    RestorePassword,
    ConfirmationModal,
    NeedSignUp,
    RegisterStep1,
    RegisterStep2,
    VerifyEmail,
    RegisterCompleteModal,
    ResetPasswordSecurityCode
  },
  computed: {
    ...mapGetters({
      form_data: "system_map_to_props"
    }),
    is_authenticated() {
      return this.form_data && this.form_data.is_authenticated ? this.form_data.is_authenticated : false
    }
  },
  mounted() {
    if (this.$route.params.key) {
      this.$store.commit("SET_AFFILIATION_TOKEN_VALUE", this.$route.params.key)
    }

  },
  methods: {
    ...mapMutations([
      "resetSignupState",
      "resetLoginState"
    ]),
    formDataClear(value) {
      this.clearData = value
      if (this.clearData) {
        this.$refs.clearData.clearFormData();
      }
    },
    nextStep: function () {
      if (this.modalFor == "login") {
        if (this.step == 1) {
          this.step = 2;
          this.currentModal = "login";
        }
      } else {
        this.currentModal = "need-signup";
      }
    },
    previousStep: function () {
      // if (this.modalFor == "login") {
      //   if (this.step == 2) {
      //     this.step = 1;
      //     this.currentModal = "sign-up-email";
      //   }
      // } else {
      //   alert("1");
      // }

      this.currentModal = "sign-up-email";
    },
    closeModal: function (modalType) {
      this.resetSignupState();
      this.resetLoginState();
      if (modalType == "sign-up-email") {
        this.currentModal = "";
        this.step = 1;
      } else {
        this.currentModal = "";
        this.step = 1;
      }
    },
    openModal: function (modalType, modalFor) {
      this.modalFor = modalFor;
      if (modalType == "sign-up-email") {
        this.currentModal = "sign-up-email";
      }
    },
    newModal: function (modalType) {
      this.currentModal = modalType;
    }
  }
};
</script>
