export default function createFormData(data) {
  const formData = new FormData();
  for (const field in data) {
    if (Array.isArray(data[field])) {
      if (data[field].length > 0) {
        for (let i = 0; i < data[field].length; i++) {
          console.log('Checking Data Type ==>', typeof data[field][i])
          if (typeof data[field][i] === 'object' && !(data[field][i] instanceof File) && !(data[field][i] instanceof Blob)) {
            formData.append(
              field + "[" + i + "]",
              JSON.stringify(data[field][i])
            );
          } else {
            formData.append(field + "[" + i + "]", data[field][i]);
          }
        }
      }
    } else {
      if (!!data[field]) {
        formData.append(field, data[field]);
      } else if (data[field] === 0) {
        formData.append(field, data[field]);
      }
    }
  }
  return formData;
}
export function createRawData(data) {
  for (const field in data) {
    if (Array.isArray(data[field])) {
      if (!data[field].length > 0) {
        delete data[field];
      } else {
        for (let i = 0; i < data[field].length; i++) {
          if (
            typeof data[field][i] === "object" &&
            !(data[field][i] instanceof File) &&
            !(data[field][i] instanceof Blob)
          ) {
            data[field][i] = createRawData(data[field][i]);
          }
        }
      }
    } else {
      if (data[field] === null) {
        delete data[field];
      }
    }
  }
  return data;
}
export function dateFormat(date) {
  return new Date(date).getDate() === new Date().getDate()
   ? "Today"
   : new Date().getDate() - new Date(date).getDate() === 1
   ? "Yesterday"
   : date.slice(0, 10).split("-").reverse().join(".");
}
export  function removeExtraHashes(data){
  const filteredData =  data.map((e)=>{
    const var0 =  e.replace(/#/g, ' ')
    const var1 =  var0.trimStart()
    return var1.replace(/ /g, '#')
  })
  return filteredData
}
