<template>
  <div>
    <div class="photo-list">
      <media-videos-listing @getMediaVideosList="getMediaVideos" :listData="mediaVideosList" :totalMediaVideos="mediaVideosCount"/>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script  type="application/javascript">
import mediaVideosListing from "@/components/Home/Profile/media/videos/mediaVideosListing.vue";
import { getAllMediaVideosOfUsers } from "../../../../../apis/APIs";

export default {
  name: "mediaVideos",
  props:{
    user_id:{
      type:Number,
      Default:null,
    }
  },
  data() {
    return {
      mediaVideosList: [],
      offSetLimit: 0,
      limit: 8,
      loading: false,
      dataLoaded: false,
      mediaVideosCount: ''
    }
  },
  components: {
    mediaVideosListing
  },
  mounted() {
    this.getMediaVideos();
  },
  methods: {
    async getMediaVideos() {
      try {
        this.loading = true
        let data = await getAllMediaVideosOfUsers(this.offSetLimit, this.limit,(this.user_id)?this.user_id:null);
        this.mediaVideosList =  data.data.information.data;
        this.mediaVideosCount = data.data.information.total;
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    setLimit (value) {
      this.limit = value;
    }
  }
}
</script>

<style scoped>

</style>
