<template>
  <div class="wr_adsCard" @click="goSingleAdsPage(165)">
    <ul>
      <li>{{ ads.name }}</li>
      <li
        :class="{
          ads_active: ads.status === 'Active',
          ads_pause: ads.status === 'Paused',
          ads_pend_mode: ads.status === 'Pending' || ads.status === 'Moderate',
        }"
      >
        <svg
          v-if="ads.status === 'Active'"
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.916992 0.278809L9.08366 5.52881L0.916992 10.7788V0.278809Z"
            fill="#15CA86"
          /></svg
        ><svg
          v-if="ads.status === 'Paused'"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83333 2.33337H3.5V11.6667H5.83333V2.33337Z"
            fill="#47505B"
          />
          <path
            d="M10.5003 2.33337H8.16699V11.6667H10.5003V2.33337Z"
            fill="#47505B"
          /></svg
        ><svg
          v-if="ads.status === 'Pending' || ads.status === 'Moderate'"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.8327 5.99984C11.8327 9.2215 9.22101 11.8332 5.99935 11.8332C2.77769 11.8332 0.166016 9.2215 0.166016 5.99984C0.166016 2.77818 2.77769 0.166504 5.99935 0.166504C9.22101 0.166504 11.8327 2.77818 11.8327 5.99984ZM6.49951 2.5C6.49951 2.22386 6.27565 2 5.99951 2C5.72337 2 5.49951 2.22386 5.49951 2.5V6C5.49951 6.18939 5.60651 6.36252 5.77591 6.44721L8.10924 7.61388C8.35623 7.73737 8.65656 7.63726 8.78006 7.39027C8.90355 7.14328 8.80344 6.84295 8.55645 6.71945L6.49951 5.69098V2.5Z"
            fill="#8B949F"
          />
        </svg>

        {{ ads.status }}
      </li>
      <li>{{ ads.daily }}</li>
      <li>{{ ads.total }}</li>
      <li :class="{ ads_moderate: ads.status === 'Moderate' }">
        {{ ads.status === "Moderate" ? "_" : ads.spent }}
      </li>
      <li :class="{ ads_moderate: ads.status === 'Moderate' }">
        {{ ads.status === "Moderate" ? "_" : ads.view }}
      </li>
      <li :class="{ ads_moderate: ads.status === 'Moderate' }">
        {{ ads.status === "Moderate" ? "_" : ads.quantity }}
      </li>
      <li :class="{ ads_moderate: ads.status === 'Moderate' }">
        {{ ads.status === "Moderate" ? "_" : ads.CTR }}
      </li>
      <li :class="{ ads_moderate: ads.status === 'Moderate' }">
        {{ ads.status === "Moderate" ? "_" : ads.eCPC }}
      </li>
      <span class="three_dots_menustyle for_hover_gray_btn">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99967 9.08516C8.36786 9.08516 8.66634 8.78669 8.66634 8.4185C8.66634 8.05031 8.36786 7.75183 7.99967 7.75183C7.63148 7.75183 7.33301 8.05031 7.33301 8.4185C7.33301 8.78669 7.63148 9.08516 7.99967 9.08516Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99967 4.41842C8.36786 4.41842 8.66634 4.11994 8.66634 3.75175C8.66634 3.38356 8.36786 3.08508 7.99967 3.08508C7.63148 3.08508 7.33301 3.38356 7.33301 3.75175C7.33301 4.11994 7.63148 4.41842 7.99967 4.41842Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99967 13.7518C8.36786 13.7518 8.66634 13.4533 8.66634 13.0851C8.66634 12.7169 8.36786 12.4185 7.99967 12.4185C7.63148 12.4185 7.33301 12.7169 7.33301 13.0851C7.33301 13.4533 7.63148 13.7518 7.99967 13.7518Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["ads"],
  methods: {
    goSingleAdsPage(id) {
      this.$router.push({ name: "ads.detail", params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_adsCard {
  margin-top: 10px;
  background: #ffffff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  ul {
    width: 100%;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #071526;
    font-family: HelveticaNeueMedium;
    :nth-child(1) {
      font-family: HelveticaNeueBold;
    }
    :nth-child(3),
    :nth-child(4) {
      font-family: HelveticaNeueNormal;
    }
    li {
      display: flex;
      align-items: center;
      svg {
        margin-right: 9px;
      }
    }
    li:nth-child(1) {
      width: 88px;
    }
    li:nth-child(2) {
      width: 85px;
    }
    li:nth-child(3) {
      width: 53px;
    }
    li:nth-child(4) {
      width: 53px;
    }
    li:nth-child(5) {
      width: 30px;
    }
    li:nth-child(6) {
      width: 30px;
    }
    li:nth-child(7) {
      width: 43px;
    }
    li:nth-child(8) {
      width: 36px;
    }
    li:nth-child(9) {
      width: 44px;
    }
  }
  .ads_active {
    color: #15ca86;
  }
  .ads_pause {
    color: #47505b;
  }
  .ads_pend_mode {
    color: #8b949f;
  }
  .ads_moderate {
    margin-bottom: 10px;
  }
}
</style> 