<template>
  <div class="about-work">
    <div class="row">
      <div class="col">
        <div class="about-work-text">
          <h1>ABOUT</h1>
          <div v-if="(current_page && current_page.content && current_page.content.length > 300) && showLess">
            <p>{{ current_page.content.slice(0, 300) }}...</p>
            <a href="javascript:;" @click="checkAbout">READ MORE</a>
          </div>
          <div v-else-if="current_page && current_page.content && current_page.content.length > 300 && readMore">
            <p v-if="current_page && current_page.content ">{{ current_page.content }}</p>
            <a href="javascript:;" @click="checkAbout">SHOW LESS</a>
          </div>
          <div
            v-else-if="current_page && current_page.content && current_page.content.length < 300 && current_page.content">
            <p v-if="current_page && current_page.content ">{{ current_page.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="about_tags">
          <ul v-if="(current_page && current_page.genres && current_page.genres.length > 0)">
            <li v-for="item in current_page.genres" :key="item.name">
              <a href="javascript:;">{{ item.name }}</a>
            </li>
            <!--            <li><a href="javascript:;">rock</a></li>-->
            <!--            <li><a href="javascript:;">alternative</a></li>-->
            <!--            <li><a href="javascript:;">grunge</a></li>-->
            <!--            <li><a href="javascript:;">guitar</a></li>-->
            <!--            <li><a href="javascript:;">+2</a></li>-->
          </ul>
        </div>
      </div>
    </div>
    <hr class="about_hr_line">
    <div class="row">
      <div class="col-md-6">
        <div class="about_listing">
          <div class="about-more-details">
            <span class="d-flex" style="width:30px; height:30px;">
             <svg class="m-auto" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                 d="M8.9527 2.82715C7.40254 2.82715 6.1459 4.2943 6.1459 6.10412C6.1459 7.35929 6.75034 8.4494 7.63792 8.99983L6.60867 9.47684L3.86968 10.7472C3.60508 10.8795 3.47302 11.1038 3.47302 11.4214V14.4379C3.49479 14.815 3.72108 15.1666 4.08796 15.1725H13.8323C14.2511 15.1361 14.463 14.7987 14.4671 14.4379V11.4214C14.4671 11.1038 14.3351 10.8795 14.0705 10.7472L11.4307 9.47684L10.3336 8.95709C11.1848 8.39428 11.7595 7.32773 11.7595 6.10414C11.7595 4.2943 10.5028 2.82715 8.9527 2.82715V2.82715ZM4.42535 3.97951C3.75825 4.00484 3.22956 4.2935 2.82789 4.75345C2.38359 5.30722 2.16699 5.9647 2.1628 6.61876C2.19009 7.58593 2.62284 8.50153 3.39324 8.98045L0.317445 10.4098C0.10575 10.4892 0 10.6744 0 10.9654V13.3864C0.01653 13.7075 0.18324 13.9773 0.495825 13.982H2.54009V11.4214C2.57391 10.7368 2.89539 10.1834 3.47302 9.9134L5.51673 8.94115C5.67549 8.84852 5.82769 8.72263 5.97322 8.56387C5.13058 7.26373 5.01357 5.7065 5.55663 4.33685C5.20342 4.12069 4.80648 3.98177 4.42535 3.97951V3.97951ZM13.5541 3.97951C13.1179 3.98864 12.7147 4.14929 12.3835 4.37617C12.9118 5.75822 12.7661 7.31641 11.9863 8.52397C12.1583 8.72242 12.3374 8.87462 12.5226 8.98048L14.4871 9.9134C15.0855 10.2416 15.3941 10.7999 15.4001 11.4214V13.982H17.5036C17.8495 13.9521 17.997 13.6762 18 13.3864V10.9655C18 10.7008 17.8943 10.5157 17.6826 10.4098L14.6461 8.96051C15.4336 8.37989 15.829 7.50971 15.8366 6.61876C15.8157 5.91358 15.6003 5.2588 15.1715 4.75345C14.7235 4.26746 14.1679 3.98465 13.5541 3.97951V3.97951Z"
                 fill="#8B949F" />
             </svg>
            </span>
            <p v-if="currentPageCategory">{{ currentPageCategory }} group</p>
          </div>
          <div class="about-more-details">
            <span>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" fill="white" />
                <path
                  d="M8.74413 14.463L14.5832 8.20694C14.7191 8.06128 14.9491 8.05833 15.0887 8.20046L21.2497 14.4714C21.314 14.5369 21.35 14.625 21.35 14.7167V21C21.35 21.1933 21.1933 21.35 21 21.35H17.9744C17.7811 21.35 17.6244 21.1933 17.6244 21V18.3846C17.6244 17.4733 16.8856 16.7346 15.9744 16.7346H14.3846C13.4733 16.7346 12.7346 17.4733 12.7346 18.3846V21C12.7346 21.1933 12.5779 21.35 12.3846 21.35H9C8.8067 21.35 8.65 21.1933 8.65 21V14.7019C8.65 14.6132 8.68364 14.5279 8.74413 14.463Z"
                  fill="#8B949F" stroke="#8B949F" stroke-width="1.3" />
              </svg>
            </span>
            <p>{{ currentLocation }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about_listing">
          <div class="about-more-details">
            <span>
             <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect width="30" height="30" fill="white" />
               <path d="M11.8 15.7998H7C7.19899 17.78 8.12892 19.6149 9.60819 20.9463C11.0875 22.2776 13.0099 23.0098 15 22.9998C12.952 22.9998 11.944 19.4078 11.8 15.7998Z"
                     fill="#8B949F" />
               <path d="M7 14.2001H11.8C11.928 10.5921 12.936 7.0001 15 7.0001C13.0099 6.99013 11.0875 7.7223 9.60819 9.05365C8.12892 10.385 7.19899 12.2199 7 14.2001Z"
                     fill="#8B949F" />
               <path d="M14.9984 21.3998C15.3824 21.3998 16.4464 19.4798 16.5984 15.7998H13.3984C13.5504 19.4798 14.6144 21.3998 14.9984 21.3998Z"
                     fill="#8B949F" />
               <path d="M14.9984 8.59961C14.6144 8.59961 13.5504 10.5196 13.3984 14.1996H16.5984C16.4464 10.5196 15.3824 8.59961 14.9984 8.59961Z"
                     fill="#8B949F" />
               <path d="M15 22.9998C16.9901 23.0098 18.9125 22.2776 20.3918 20.9463C21.8711 19.6149 22.801 17.78 23 15.7998H18.2C18.056 19.4078 17.048 22.9998 15 22.9998Z"
                     fill="#8B949F" />
               <path d="M15 7.0001C17.048 7.0001 18.056 10.5921 18.2 14.2001H23C22.801 12.2199 21.8711 10.385 20.3918 9.05365C18.9125 7.7223 16.9901 6.99013 15 7.0001Z"
                     fill="#8B949F" />
             </svg>
            </span>
            <p v-if="current_page && current_page.website">
              <b>
                {{ current_page.website.slice(0, 25) }}...
              </b>
            </p>
          </div>
          <div class="about-more-details">
            <span>
             <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect width="30" height="30" fill="white" />
               <path d="M22.875 8H7.875C6.84375 8 6.00937 8.84375 6.00937 9.875L6 21.125C6 22.1562 6.84375 23 7.875 23H22.875C23.9062 23 24.75 22.1562 24.75 21.125V9.875C24.75 8.84375 23.9062 8 22.875 8ZM22.5 11.9844L15.8719 16.1281C15.5719 16.3156 15.1781 16.3156 14.8781 16.1281L8.25 11.9844C8.15599 11.9316 8.07367 11.8603 8.00802 11.7748C7.94237 11.6893 7.89475 11.5914 7.86804 11.4869C7.84133 11.3825 7.8361 11.2737 7.85265 11.1672C7.8692 11.0606 7.90719 10.9586 7.96433 10.8672C8.02146 10.7757 8.09655 10.6969 8.18506 10.6353C8.27356 10.5738 8.37363 10.5308 8.47921 10.509C8.5848 10.4873 8.6937 10.4872 8.79933 10.5087C8.90496 10.5302 9.00512 10.573 9.09375 10.6344L15.375 14.5625L21.6563 10.6344C21.7449 10.573 21.845 10.5302 21.9507 10.5087C22.0563 10.4872 22.1652 10.4873 22.2708 10.509C22.3764 10.5308 22.4764 10.5738 22.5649 10.6353C22.6534 10.6969 22.7285 10.7757 22.7857 10.8672C22.8428 10.9586 22.8808 11.0606 22.8974 11.1672C22.9139 11.2737 22.9087 11.3825 22.882 11.4869C22.8553 11.5914 22.8076 11.6893 22.742 11.7748C22.6763 11.8603 22.594 11.9316 22.5 11.9844Z"
                     fill="#8B949F" />
             </svg>
            </span>
            <p>{{ currentPageEmail }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AboutMainTab",
  filters: {
    moment: function(date) {
      return moment(date).format("Do MMMM YYYY");
    }
  },
  data() {
    return {
      readMore: false,
      showLess: true
    };
  },
  methods: {
    moment: function() {
      return moment();
    },
    checkAbout() {
      if (this.readMore) {
        this.showLess = true;
        this.readMore = false;
      } else if (this.showLess) {
        this.readMore = true;
        this.showLess = false;
      }
    }
  },
  computed: {
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    currentPageCity() {
      return (this.current_page && this.current_page.city) ? this.current_page.city : "";
    },
    currentPageCountry() {
      return (this.current_page && this.current_page.country && this.current_page.country.name) ? this.current_page.country.name : "";
    },
    currentPageCategory() {
      return (this.current_page && (this.current_page.page_category && this.current_page.page_category.name)) ? this.current_page.page_category.name : "";
    },
    currentLocation() {
      return (this.current_page && this.current_page.address) ? 'Lives in ' + this.current_page.address : 'No Location'
    },
    currentPageEmail() {
      return (this.current_page && this.current_page.email) ? this.current_page.email : 'No email address'
    }
  }
};
</script>

<style scoped>

</style>
