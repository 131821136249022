<template>
  <div
    class="music-head d-flex justify-content-start bg-type-1"
    style="background: white !important; padding: 0px !important"
  >
    <div class="left-block" style="margin: 8px">
      <div class="cover" style="width: 160px; height: 160px; opacity: 1">
        <img
          :src="song.artwork_url"
          alt="song_image"
          height="160"
          width="160"
        />
      </div>
    </div>
    <div
      class="right-block w-100 d-flex flex-column"
      style="padding: 8px !important"
    >
      <div class="d-flex justify-content-star align-items-center">
        <div
          class="play-button js-add-active"
          @click="playPauseSong(index + '_' + song.id, song)"
          style="
            position: relative;
            top: 0px;
            left: -2px;
            min-width: 40px;
            width: 40px;
            height: 40px;
            margin-bottom: 0px;
          "
        >
          <div class="svg-icon" v-if="icon === true">
            <svg
              width="13"
              height="16"
              viewBox="0 0 13 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="play-type-1 abccc"
            >
              <path
                class="c-fill-1 c-line-1"
                d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                stroke="white"
                stroke-width="1.3"
              ></path>
              <path
                style="fill: #D1433A"
                class="c-line-1 c-fill-1"
                d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                stroke="white"
                stroke-width="1.3"
              ></path>
            </svg>
            <!--            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--              <path class="c-line-1 c-fill-1" d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891" fill="white"></path>-->
            <!--              <path class="c-line-1 c-fill-1" d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891" stroke="white" stroke-width="1.3">-->
            <!--              </path>-->
            <!--            </svg>-->
            <svg
              width="10"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="c-line-1 c-fill-1"
                d="M1 1V13"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
              <path
                class="c-line-1 c-fill-1"
                d="M9 1V13"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </svg>
          </div>
          <div class="svg-icon" v-if="icon === false">
            <svg
              width="10"
              height="14"
              viewBox="0 0 10 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class=""
            >
              <path
                d="M1 1V13"
                stroke="white"
                stroke-width="2"
                class="c-line-1 c-fill-1"
                stroke-linecap="round"
              ></path>
              <path
                d="M9 1V13"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                class="c-line-1 c-fill-1"
              ></path>
            </svg>
          </div>
        </div>
        <div
          class="d-flex flex-row justify-content-between w-100 cursor-pointer"
          @click="redirectToFullViewPage($event, song.type, song.id)"
        >
          <div>
            <div
              class=""
              style="
                color: #8b949f;
                background-color: white;
                font-size: 13px;
                font-weight: 500;
              "
            >
              {{ song.primary_artist ? song.primary_artist : "Artist Name" }}
            </div>
            <div
              class=""
              style="
                color: #071526;
                background-color: white;
                font-size: 16px;
                font-weight: 500;
              "
            >
              {{
                song.title.length > 50
                  ? song.title.slice(0, 50) + "..."
                  : song.title
              }}
            </div>
          </div>
          <div>
            <div class="days" style="color: #47505b">
              {{ convertDateIntoHumanReadableForm(song.created_at) }}
            </div>
            <div
              class="tag"
              style="padding: 4px 16px 4px 16px; border-radius: 4px"
              v-if="genre.length > 0"
            >
              #<span v-html="genre[0].name"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 justify-content-between align-items-center mt-auto">
        <music-player
          :key="index + '_' + song.id"
          :duration="song.duration"
          v-if="song.audio_url !== ''"
          :ref="'songPlayerRef' + index + '_' + song.id"
          :song-loaded="songLoaded"
          :is-song-playing.sync="isSongPlaying"
          :music="song.audio_url"
          :latest_list_id="song.id"
          @updateIcon="updateIcon"
        />
      </div>
      <div
        class="d-flex w-100 justify-content-between align-items-center mt-auto"
      >
        <div class="d-flex flex-row">
          <button
            :class="[
              'btn btn-information text love-button',
              song.favorite === true ? 'active-background active' : '',
            ]"
            @click="favouriteUnFavourite($event, song)"
          >
            <div class="svg-icon">
              <b-spinner
                v-if="spinner && favorite_id === song.id"
                small
                label="Small Spinner"
                class="spinner-color"
              ></b-spinner>
              <svg
                v-else
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="svg-icon-text"
                ></path>
              </svg>
            </div>
            <span>{{ getCount(song, "loves") }}</span>
          </button>
          <button
            class="btn btn-information text comment-button"
            @click="redirectToFullViewPage($event, song.type, song.id)"
          >
            <div class="svg-icon">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="svg-icon-text"
                ></path>
              </svg>
            </div>
            <span>{{ getCount(song, "comment_count") }}</span>
          </button>
          <button
            class="btn btn-information text repost-button"
            @click="
              repost(
                $event,
                song && song.page && song.page.id ? song.id : '',
                index
              )
            "
          >
            <div class="svg-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_15137_101379)">
                  <path
                    d="M11.333 0.666016L13.9997 3.33268L11.333 5.99935"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 7.33398V6.00065C2 5.29341 2.28095 4.61513 2.78105 4.11503C3.28115 3.61494 3.95942 3.33398 4.66667 3.33398H14"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.66667 15.3333L2 12.6667L4.66667 10"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 8.66602V9.99935C14 10.7066 13.719 11.3849 13.219 11.885C12.7189 12.3851 12.0406 12.666 11.3333 12.666H2"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_15137_101379">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span>{{ getCount(song, "repost_count") }}</span>
          </button>
          <button
            class="btn btn-information share-button"
            @click="share($event, index)"
          >
            <div class="svg-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9995 10.334V13.445C14.9995 13.8575 14.8357 14.2532 14.5439 14.5449C14.2522 14.8366 13.8566 15.0005 13.444 15.0005H2.5555C2.14296 15.0005 1.74731 14.8366 1.4556 14.5449C1.16388 14.2532 1 13.8575 1 13.445V10.334"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.8889 4.88876L8.00009 1L4.11133 4.88876"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 1V10.333"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
          <PostShare
            :post="song"
            ref="share"
            :share_media_check="true"
            :fullViewPageCheck="true"
          />
          <button
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-display="static"
            class="btn btn-information dropdown more-dropdown"
          >
            <div class="svg-icon-plus more-position">
              <svg
                version="1.1"
                id="Capa_1"
                width="16.562px"
                height="16.562px"
                viewBox="0 0 93.562 93.562"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M87.952,41.17l-36.386,0.11V5.61c0-3.108-2.502-5.61-5.61-5.61c-3.107,0-5.61,2.502-5.61,5.61l0.11,35.561H5.61
                     		c-3.108,0-5.61,2.502-5.61,5.61c0,3.107,2.502,5.609,5.61,5.609h34.791v35.562c0,3.106,2.502,5.61,5.61,5.61
                     		c3.108,0,5.61-2.504,5.61-5.61V52.391h36.331c3.108,0,5.61-2.504,5.61-5.61C93.562,43.672,91.032,41.17,87.952,41.17z"
                    class="c-line-1-fill"
                  ></path>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <button
            class="btn btn-icon-border text"
            style="margin-right: 16px"
            v-if="!isMusicModeIsStreaming"
          >
            <div class="svg-icon">
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="shop-cart-v2"
              >
                <path
                  class="c-line-1"
                  d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z"
                  stroke="#D1433A"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  class="c-line-1"
                  d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                  stroke="#D1433A"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <div class="price">${{ getPrice(song) }}</div>
          </button>
          <div class="btn btn-play-count" style="margin-right: 16px">
            <div class="svg-icon">
              <svg
                width="7"
                height="8"
                viewBox="0 0 7 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="small-play-svg"
              >
                <path
                  d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                  fill="#8B949F"
                ></path>
              </svg>
            </div>
            <span style="color: #47505b">{{ getCount(song, "plays") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { set_favourite_on_songs } from "../../../../../apis/APIs";
import MusicPlayer from "@/components/PageManager/Pages/Components/LatestAlbums/MusicPlayer.vue";
import { Song_Play_Index } from "../../../../../store/types";
import PostShare from "@/components/Home/NewsFeed/PostItem/PostShare";

export default {
  props: ["song", "index", "genre", "song_type"],
  data() {
    return {
      wavesurfer: null,
      songLoaded: true,
      isSongPlaying: false,
      icon: true,
      spinner: false,
      favorite_id: null,
    };
  },
  computed: {
    getPlaySongIndex() {
      return this.$store.getters.getSongPlayIndex;
    },
    getAlbumIndex() {
      return this.$store.getters.getAlbumIndex;
    },
    isMusicModeIsStreaming() {
      return this.$store.getters.isMusicModeIsStreaming;
    },
  },
  components: {
    MusicPlayer,
    PostShare,
  },
  mounted() {},
  methods: {
    convertSecondsIntoMinuets(duration) {
      return moment.utc(duration * 1000).format("mm:ss");
    },
    convertDateIntoHumanReadableForm(date) {
      return moment(date).fromNow();
    },
    repost(event, id, index) {
      this.$refs.share.repost(event, null, id);
    },
    share(event, index) {
      this.$refs.share.openShareModel(event);
    },
    async playPauseSong(Index, song) {
      await this.$store.commit("SET_CURRENT_PLAYER", "waveSurferPlayer");
      this.$emit("songPlayStop", Index);
      if (this.icon === false) {
        let payload = {
          type: "song",
          id: song.id,
        };
        await this.$store.dispatch("trackPlayed", payload);
        song.plays += 1;
      }
    },
    async playPauseParent(Index, type) {
      this.icon = !this.icon;
      await this.$refs["songPlayerRef" + Index].playPauseSong();
      this.isSongPlaying =
        this.$refs["songPlayerRef" + Index].wavesurfer.isPlaying();
    },
    async pauseParent(Index) {
      this.icon = true;
      await this.$refs["songPlayerRef" + Index].pauseSong();
    },
    updateIcon(val) {
      this.icon = true;
    },
    async favouriteUnFavourite(e, object) {
      this.favorite_id = object.id;
      this.spinner = true;
      const res = await this.setFavourite(e, object);
      if (res.success === 1) {
        object.favorite = !object.favorite;
        object.favorite === true ? object.loves++ : object.loves--;
      }
      this.favorite_id = null;
      this.spinner = false;
    },
  },
};
</script>
<style scoped lang="scss">
//.active-background {
//  background-color: rgba(65, 108, 255, .1) !important;
//}
.music-head .right-block .play-button:hover .svg-icon svg:nth-child(1),
.song-detail .left-part .text .more-text.collapsed span.less {
  display: block !important;
}
.music-head .right-block .play-button:hover .svg-icon svg:nth-child(2),
.song-detail .left-part .text .more-text.collapsed span.less {
  display: none !important;
}
.play-button:hover .abccc path:nth-child(1) {
  stroke: #D1433A !important;
}
.play-button:hover .abccc path:nth-child(2) {
  fill: #fff !important;
}
.btn {
  &.btn-information {
    background: #fff !important;
  }
  &.text {
    &.love-button {
      &:hover {
        background: rgba(247, 35, 129, 0.1) !important;

        svg {
          path {
            stroke: #f72381 !important;
          }
        }
      }
      &.active {
        background: rgba(247, 35, 129, 0.1) !important;

        svg {
          path {
            stroke: #f72381 !important;
          }
        }
      }
    }
    &.share-button {
      &:hover {
        background: #D1433A1A !important;
        svg {
          path {
            stroke: #D1433A !important;
          }
        }
      }
    }
    &.comment-button {
      &:hover {
        background: #D1433A1A !important;
        svg {
          path {
            stroke: #D1433A !important;
          }
        }
      }
    }
    &.repost-button {
      &:hover {
        background: rgba(46, 191, 136, 0.1) !important;
        svg {
          path {
            stroke: #2ebf88 !important;
          }
        }
      }
      &.active {
        background: rgba(46, 191, 136, 0.1) !important;
        svg {
          path {
            stroke: #2ebf88 !important;
          }
        }
      }
    }
  }
  &.share-button {
    &:hover {
      background: #D1433A1A !important;
      svg {
        path {
          stroke: #D1433A !important;
        }
      }
    }
  }
}
</style>
