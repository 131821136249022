<template>
  <div>
    <div class="text-center" v-if="latestLists.length < 1">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>

    <div class="row mb_20px" v-for="(latest_list,index) in latestLists.data" :key="index">
      <div class="col" v-if="latest_list.type === 'song'">
        <SongTrackComponent :ref="'songPlayerRef2'+index+'_'+latest_list.id" @songPlayStop="songPlayStop(index+'_'+latest_list.id)" :song_type="latest_list.type"  :song="latest_list" :index="index" :genre= "latest_list.genre"/>
      </div>
      <div class="col" v-if="latest_list.type === 'album'">
        <div class="feed-song-list d-flex justify-content-start flex-wrap mt-auto" style="padding:0px !important;">
          <div v-for="(album_song, index) in latest_list.album_songs.slice(0,show_albums_song_list_number)" :key="index"
               :class="['card weekly-top song-lists w-100 active' , (check[latest_list.id] === index) ? 'open border-style' : 'border-style-2']"
          >
            <div :class="['row no-gutters', (check[latest_list.id] === index) ? 'display-none' : '']">
              <div class="col line-1 hover-blue-bg">
                <div class="cover justify-content-center" style="width:20px">
                  {{ index + 1 }}
                </div>
                <div class="description">
                  <div class="first-line m-b-0"><a href="#"> <span></span> <span
                    class="type1"> {{ (album_song.title) ? album_song.title : 'Song Name' }} </span> </a></div>
                </div>
                <div class="other d-flex ">
                  <div class="time">
                    {{ convertSecondsIntoMinuets(album_song.duration) }}
                  </div>
                  <div class="btn btn-play-count" style="margin-right: 16px;">
                    <div class="svg-icon">
                      <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg" class="small-play-svg">
                        <path  d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z" fill="#8B949F"></path>
                      </svg>
                    </div>
                    <span style="color: #47505B">{{ getCount(album_song, 'plays') }}</span>
                  </div>
                  <div class="actions d-flex align-items-center">
                    <div :class="['wishlist js-wishlist', (album_song.favorite === true) ? 'active' : '']"
                         @click="favouriteUnFavourite($event,album_song)">
                      <div class="svg-icon">
                        <b-spinner v-if="spinner && favorite_id === album_song.id" small label="Small Spinner" class="spinner-color"></b-spinner>
                        <svg v-else width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                            stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"
                            class="c-line-1 c-fill-1"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="more dropright" @click="openListOption($event, latest_list.album_songs, 'default_option_list', latest_list)">
                      <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"
                           class="dropdown vertical more-dropdown">
                        <div class="svg-icon more-position">
                          <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"
                               class="svg-more-hor">
                            <path
                              d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                              fill="white" class="c-fill-1"></path>
                            <path
                              d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                              fill="white" class="c-fill-1"></path>
                            <path
                              d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                              fill="white" class="c-fill-1"></path>
                          </svg>
                          <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                               class="svg-more">
                            <path
                              d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                              class="c-fill-1"></path>
                            <path
                              d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                              class="c-fill-1"></path>
                            <path
                              d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                              class="c-fill-1"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="extend js-extend" @click="showComments(latest_list.id,index)">
                      <div class="svg-icon">
                        <svg width="11" height="6" viewBox="0 0 11 6" fill="none"
                             xmlns="http://www.w3.org/2000/svg" class="svg-arrow-down">
                          <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B"
                                stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters line-2">
              <SongTrackComponent :ref="'songPlayerRef2'+index+'_'+album_song.id" @songPlayStop="songPlayStop(index+'_'+album_song.id)" :song_type="'album'" :song="album_song" :index="index" :genre="latest_list.genre"/>
            </div>
          </div>
          <div class="show-more-song-list" @click="showMoreSongs" v-if="latest_list.album_songs.length > show_albums_song_list_number"><span>+</span>SHOW MORE TRACKS</div>
        </div>
      </div>
    </div>
  </div>

</template>
<script type="application/javascript">
import SongTrackComponent from '@/components/PageManager/Pages/Components/LatestAlbums/SongTrackComponent.vue';
import moment from "moment";
import { set_favourite_on_songs } from "../../../../apis/APIs";
import Vue from "vue";
export default {
  data() {
    return {
      wavesurfer: null,
      songLoaded: true,
      isSongPlaying: false,
      oldIndex: null,
      showMore: true,
      check: [],
      first_song:'',
      show_albums_song_list_number:3,
      spinner : false,
      favorite_id:null
    }
  },
  async mounted(){
    await this.showAlbumExpended()
  },
  components: {
    SongTrackComponent
  },
  computed:{
    latestLists(){
      return this.$store.getters.getPageLatestSongList;
    },
    getPlaySongIndex() {
      return this.$store.getters.getSongPlayIndex;
    },
    getAlbumIndex(){
      return this.$store.getters.getAlbumIndex;
    },
    getPlayType(){
      return this.$store.getters.getPlayType;
    },
  },
  watch:{
    'latestLists.data':function(newVal,oldVal){
      if(newVal.length>0)
      {
        this.showAlbumExpended()
      }
    }
  },
  methods: {
    async favouriteUnFavourite(e,object) {
      this.favorite_id = object.id;
      this.spinner = true
      const res =  await this.setFavourite(e,object)
      if (res.success === 1) {
        object.favorite = !object.favorite;
        (object.favorite === true) ? object.loves++ : object.loves--
      }
      this.favorite_id = null;
      this.spinner = false
    },
    songPlayStop(Index){
      if (this.getPlaySongIndex && this.getPlaySongIndex !== Index) {
        this.$refs['songPlayerRef2' + this.getPlaySongIndex][0].pauseParent(this.getPlaySongIndex);
      }
      this.$store.commit('SET_PLAY_SONG_INDEX', Index)
      this.$refs['songPlayerRef2' + Index][0].playPauseParent(Index);
    },
    showAlbumExpended(){
      if(this.latestLists.data){
        for(const item  of this.latestLists.data){
          if(item.type === 'album'){
            if(item.album_songs.length>0)
            {
              this.showComments(item.id,0)
            }
          }
        }
      }
    },
    convertSecondsIntoMinuets(duration) {
      return moment.utc(duration * 1000).format('mm:ss');
    },
    showComments (patentId,id,value = true) {
      Vue.set(this.check,patentId, id);
    },
    convertDateIntoHumanReadableForm(date) {
      return moment(date).fromNow();
    },
    showMoreSongs(){
      this.show_albums_song_list_number = this.show_albums_song_list_number + 3;
    },
  }
}
</script>
<style >
.music-head .right-block .play-button.active:before {
  width: 0px !important;
}
.active-background {
  background-color: rgba(65, 108, 255, .1) !important;
}
.display-none{
  display: none !important;
}
.border-style{
  border: none !important;
  margin-bottom: 0px !important;
}
.border-style-2{
  margin:8px !important;
}
</style>
