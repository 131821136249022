<template>
  <div class="app-container main-container_full" :class="custom_css_class">
    <LeftNav v-if="ShowLeftNav" category="manager" type="job" class="left-bar-placement"/>
    <div style="height: 60px;">&nbsp;</div>
  </div>
</template>
<script>
import LeftNav from "@/components/DesignGuide/Layout/LeftNav.vue";
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      currentUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      "form_data": "main_container_full_class_map_to_props"
    }),
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    currentUserIsAdmin(){
      return !!(this.currentPage && this.currentPage.current_user && this.currentPage.current_user==='admin')
    },
    custom_css_class() {
      const {main_container_full_classes = ""} = this.form_data;

      return main_container_full_classes
    },
    ShowLeftNav () {
      if(!this.$route.meta.showLeftNav)
      {
        return false
      }
      if(this.currentUrl === 'book-artist' || this.currentUrl === 'related-album-list'){
        return false
      }
      return true
    }
  },
  props: {
    ShowMusicFooter: Boolean,
  },
  beforeMount() {
    this.showCon();
  },
  components: {
    LeftNav,
  },
  methods: {
    showCon: function () {
      this.currentUrl = this.$route.name;
    },
  },
};
</script>
