<template>
  <div class="w-100">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/icons/verification-square.svg`)" alt="icon">Verification
              </div>
            </div>
            <div class="artist-setting-panel">
              <nav>
                <div class="nav nav-tabs custom_tab" id="nav-tab" role="tablist">
                  <a @click="changeActiveTab('verify_account')"
                     :class="['nav-item nav-link headers-style', (active_tab === 'verify_account') ? 'active':'']"
                     id="nav-home-tab"
                     data-toggle="tab" href="#verify_account" role="tab" aria-controls="nav-home"
                     :aria-selected="(active_tab === 'verify_account')?true:false">1. Verify Account</a>
                  <a v-on:click="changeActiveTab('information')"
                     :class="['nav-item nav-link headers-style', (active_tab === 'information') ? 'active':'']"
                     style="border-top-right-radius: 4px; border-bottom-right-radius: 4px"
                     id="nav-contact-tab" data-toggle="tab" href="#information" role="tab"
                     aria-controls="nav-contact" :aria-selected="(active_tab === 'information')?true:false">2.
                    Information
                  </a>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div :class="['tab-pane fade', (active_tab === 'verify_account') ? 'active show':'']"
                     id="verify_account"
                     role="tabpanel" aria-labelledby="nav-home-tab">
                  <div class="row mt-4 mb-4">
                    <div class="col text-center">
                      <span>
                        Please verify your information below claiming an artist. Your email and full legal name will be used for contact purpose
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <b-form @submit.prevent="nextStep">
                        <b-form-group
                            id="email"
                            label="E-mail address"
                            label-for="email">

                          <b-form-input
                              id="email"
                              v-model="$v.verify_account_form.email.$model"
                              :state="validateVerifyState('email')"
                              size="sm"
                              type="email"
                              placeholder="Your e-mail">
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="email"
                              v-if="!$v.verify_account_form.email.$model"
                          >This is a required field.
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback
                            id="email"
                            v-else
                          >This is not a valid email.
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="full_name"
                            label="Full Name"
                            label-for="full_name">

                          <b-form-input
                              id="full_name"
                              v-model="$v.verify_account_form.full_name.$model"
                              :state="validateVerifyState('full_name')"
                              type="text"
                              size="sm"
                              placeholder="Your full legal name">
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="full_name"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <div class="row">
                          <div class="col mt_30">
                            <div class="next_step_btn">
                              <button type="submit" style="width: 32%!important; float: right!important;">
                                {{ verify_data ? "please wait..." : "NEXT STEP" }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </b-form>
                    </div>
                  </div>
                </div>
                <div :class="['tab-pane fade', (active_tab === 'information') ? 'active show':'']" id="information"
                     role="tabpanel" aria-labelledby="nav-contact-tab">
                  <div class="row mt-4 mb-4">
                    <div class="col font-weight-bolder">
                      <span>
                        Main Information
                      </span>
                    </div>
                  </div>
                  <b-form @submit.prevent="onSubmit">
                    <div class="row">
                      <div class="col">

                        <div class="row">
                          <div class="col-9">
                            <b-form-group
                                id="search"
                                label="Enter your artist or band name"
                                label-for="search">
                              <div style="border: 1px solid #E6EAF0;">
                                <v-select class="v-select-main-class" :filterable="false"
                                          :options="searchedArtistsList"
                                          v-model="$v.information_account_form.artist_name.$model"
                                          :state="validateInformationState('artist_name')"
                                          :reduce="(option) => option.title"
                                          :dropdown-should-open="dropdownShouldOpen"
                                          placeholder="Search"
                                          @search="searchArtist">
                                  <template #search="{attributes, events}">
                                    <input
                                        class="vs__search"
                                        v-model="$v.information_account_form.artist_name.$model"
                                        :state="validateInformationState('artist_name')"
                                        v-bind="attributes"
                                        v-on="events"
                                    />
                                  </template>
                                  <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                      <img class="search-image" :src="option.artwork_url"/>
                                      {{ option.title }}
                                    </div>
                                  </template>
                                  <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                      <img class="search-image" :src="option.artwork_url"/>
                                      {{ option.title }}
                                    </div>
                                  </template>
                                </v-select>
                              </div>
                              <div class="invalid-feedback d-block"
                                   v-if="$v.information_account_form.artist_name.$dirty">
                                <span v-if="!$v.information_account_form.artist_name.required">
                                  This is a required field.
                                </span>
                              </div>
                            </b-form-group>
                          </div>
                          <div class="col-3">
                            <b-button size="sm" class="btn-outline-primary" @click="searchButton()"
                                      style="width: 100%; margin-top: 1.7rem">
                              Search
                            </b-button>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-9 ">
                            <b-form-group
                                id="number"
                                label="Phone Number"
                                label-for="number">
                              <b-form-input
                                  id="number"
                                  v-model="$v.information_account_form.phone_no.$model"
                                  :state="validateInformationState('phone_no')"
                                  size="sm"
                                  type="text"
                                  placeholder="Number">
                              </b-form-input>
                              <b-form-invalid-feedback
                                  id="number"
                              >Only numbers can be enter.
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                          <div class="col-3">
                            <b-form-group
                                id="ext"
                                label="Ext:"
                                label-for="ext">

                              <b-form-input
                                  id="ext"
                                  v-model="$v.information_account_form.ext.$model"
                                  :state="validateInformationState('ext')"
                                  size="sm"
                                  type="text">
                              </b-form-input>
                              <b-form-invalid-feedback
                                  id="ext"
                              >This is a required field.
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </div>
                        </div>

                        <b-form-group
                            id="artist"
                            label="Affiliation to Artist"
                            label-for="artist">
<!--                          v-model="$v.information_account_form.affiliation_to_artist.$model"-->
<!--                          :state="validateInformationState('affiliation_to_artist')"-->
                          <b-form-select
                              id="artist"

                              size="sm"
                              placeholder="Select option">
                          </b-form-select>
                          <b-form-invalid-feedback
                              id="artist"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="description"
                            label="Explain your relationship to the artist and provide a band link:"
                            label-for="description">

                          <b-form-textarea
                              id="description"
                              v-model="information_account_form.description"
                              size="sm">
                          </b-form-textarea>
                          <b-form-invalid-feedback
                              id="description"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row mt-4 mb-2">
                      <div class="col font-weight-bolder">
                      <span>
                        Speed up the process! Verify your artist's accounts below
                      </span>
                      </div>
                    </div>
                    <div class="row mt-4 mb-2">
                      <div class="col">
                      <span class="account-label">
                        Facebook Profile URL
                      </span>
                      </div>
                    </div>
                    <div class="row pl-3 pr-3">
                      <div class="col">
                        <div class="row accounts-main-row">
                          <div class="col-1 d-flex align-items-center justify-content-center accounts-icon-div">
                            <svg width="9" height="16" viewBox="0 0 9 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M2.46 16H5.39V8.7H7.84L8.21 5.86H5.39V4.04C5.39 3.22 5.62 2.66 6.8 2.66H8.31V0.11C8.05 0.0799999 7.16 0 6.12 0C3.94 0 2.46 1.33 2.46 3.76V5.86H0V8.7H2.46V16Z"
                                  fill="#47505B"/>
                            </svg>
                          </div>
                          <div class="col-8 mt-1 mb-1">
                            <span>Facebook</span>
                            <p style="margin-bottom: 0">Click to verify and connect your Facebook profile to request</p>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <b-button size="sm" class="simple-button" style="">Connect</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 mb-2">
                      <div class="col">
                      <span class="account-label">
                        Twitter Profile URL
                      </span>
                      </div>
                    </div>
                    <div class="row pl-3 pr-3">
                      <div class="col">
                        <div class="row accounts-main-row">
                          <div class="col-1 d-flex align-items-center justify-content-center accounts-icon-div">
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M16 1.54287C15.4116 1.80359 14.7795 1.97975 14.115 2.05938C14.8007 1.64912 15.3136 1.00342 15.5582 0.242768C14.914 0.625391 14.209 0.894716 13.4738 1.03903C12.9794 0.511148 12.3245 0.161258 11.6109 0.0436835C10.8973 -0.0738904 10.1648 0.0474306 9.52717 0.388811C8.88955 0.730191 8.38247 1.27253 8.08467 1.93163C7.78686 2.59073 7.71499 3.32971 7.88021 4.03384C6.57496 3.9683 5.29807 3.62904 4.13242 3.03809C2.96677 2.44713 1.93841 1.61767 1.11407 0.603554C0.832203 1.08977 0.670131 1.6535 0.670131 2.25387C0.669817 2.79434 0.802912 3.32653 1.05761 3.80322C1.3123 4.27992 1.68073 4.68638 2.13019 4.98654C1.60893 4.96995 1.09918 4.8291 0.643354 4.57572V4.618C0.643302 5.37603 0.905511 6.11073 1.38549 6.69745C1.86547 7.28416 2.53365 7.68674 3.27667 7.83688C2.79312 7.96775 2.28615 7.98703 1.79406 7.89326C2.0037 8.5455 2.41205 9.11586 2.96194 9.52449C3.51184 9.93312 4.17575 10.1596 4.86074 10.1721C3.69794 11.085 2.26188 11.5801 0.783581 11.5779C0.521717 11.578 0.260073 11.5627 0 11.5321C1.50056 12.4969 3.24731 13.009 5.03127 13.007C11.0702 13.007 14.3715 8.0053 14.3715 3.66741C14.3715 3.52648 14.368 3.38414 14.3617 3.24321C15.0038 2.77882 15.5581 2.20376 15.9986 1.54498L16 1.54287Z"
                                  fill="#47505B"/>
                            </svg>
                          </div>
                          <div class="col-8 mt-1 mb-1">
                            <span>Twitter</span>
                            <p style="margin-bottom: 0">Click to verify and connect your Twitter profile to request</p>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <b-button size="sm" class="simple-button" style="">Connect</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 mb-2">
                      <div class="col">
                      <span class="account-label">
                        Passport
                      </span>
                      </div>
                    </div>
                    <div class="row pl-3 pr-3">
                      <div class="col">
                        <div class="row accounts-main-row">
                          <div class="col-1 d-flex align-items-center justify-content-center accounts-icon-div">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.16797 1.33398H4.0013C3.64768 1.33398 3.30854 1.47446 3.05849 1.72451C2.80844 1.97456 2.66797 2.3137 2.66797 2.66732V13.334C2.66797 13.6876 2.80844 14.0267 3.05849 14.2768C3.30854 14.5268 3.64768 14.6673 4.0013 14.6673H12.0013C12.3549 14.6673 12.6941 14.5268 12.9441 14.2768C13.1942 14.0267 13.3346 13.6876 13.3346 13.334V6.50065H8.66797C8.39183 6.50065 8.16797 6.27679 8.16797 6.00065V1.33398ZM12.8346 5.50065L9.16797 1.83398V5.50065H12.8346Z"
                                    fill="#47505B"/>
                            </svg>
                          </div>
                          <div class="col-8 mt-1 mb-1">
                            <span>Upload Passport</span>
                            <p style="margin-bottom: 0">Scan and upload passport to get verify even faster</p>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <b-button size="sm" class="simple-button" style="">Connect</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 pl-3 pr-3">
                      <div class="col">
                        <div class="row">
                          <div class="col-1 d-flex align-items-center justify-content-center">
                            <b-form-group>
                              <b-form-checkbox
                                  id="agreement"
                                  :checked="information_account_form.agreement === 1"
                                  :state="validateInformationState('agreement')"
                                  name="agreement"
                                  size="lg"
                                  @change="setAgreement"
                              >
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                          <div class="col-11 mt-1 mb-1">
                            <span>Agreement</span>
                            <p style="margin-bottom: 0;  color: #8B949F">I have read and agree to the Artist Profile
                              Terms and have the
                              legal right to claim this artist content</p>
                            <div class="invalid-feedback d-block"
                                 v-if="$v.information_account_form.agreement.$dirty">
                              <span v-if="!$v.information_account_form.agreement.required">
                                This is a required field.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col mt_30">
                        <div class="next_step_btn">
                          <button type="submit" class="d-flex align-items-center justify-content-center"
                                  style="width: 32%!important; float: right!important;">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.43351 11.249L3.78223 8.04899L5.259 7.61464L7.33757 9.43631L10.4204 6.09659L12.8489 5.38232L7.43351 11.249Z"
                                    fill="white"/>
                            </svg>
                            <span class="ml-2">{{ verify_data ? "please wait..." : "SEND REQUEST" }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import {required, numeric, email, alpha} from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  data() {
    return {
      active_tab: 'verify_account',
      search: false,
      search_data: '',
      verify_account_form: {
        email: '',
        full_name: ''
      },
      information_account_form: {
        artist_name: '',
        phone_no: '',
        ext: '',
        affiliation_to_artist: '',
        description: '',
        agreement: null
      },
      verify_data: false
    }
  },
  validations: {
    verify_account_form: {
      email: {
        required,
        email
      },
      full_name: {
        required
      }
    },
    information_account_form: {
      agreement: {
        required
      },
      artist_name: {
        required
      },
      phone_no: {
        required,
        numeric
      },
      ext: {
        required,
      }
    }
  },
  watch: {
    getVerificationFormData() {
      this.setVerifyFormData()
    }
  },
  components: {
    // MusicSubHeader,
    vSelect
  },
  computed: {
    getVerificationFormData() {
      return this.$store.getters.getCurrentPageVerificationFormData
    },
    searchedArtistsList() {
      return this.$store.getters.getSearchedArtists
    }
  },
  mounted() {
    this.setVerifyFormData();
    this.$store.dispatch('getPageVerification', this.$route.params.id);
  },
  methods: {
    validateVerifyState(name) {
      const {$dirty, $error} = this.$v.verify_account_form[name];
      return $dirty ? !$error : null;
    },
    validateInformationState(name) {
      const {$dirty, $error} = this.$v.information_account_form[name];
      return $dirty ? !$error : null;
    },
    changeActiveTab(tab) {
      this.active_tab = tab
    },
    setVerifyFormData() {
      this.verify_account_form.email = this.getVerificationFormData.email
      this.verify_account_form.full_name = this.getVerificationFormData.full_name
      this.information_account_form.artist_name = this.getVerificationFormData.artist_name
      this.information_account_form.phone_no = this.getVerificationFormData.phone_no
      this.information_account_form.ext = this.getVerificationFormData.ext
      this.information_account_form.affiliation_to_artist = this.getVerificationFormData.affiliation_to_artist
      this.information_account_form.description = this.getVerificationFormData.description
      this.information_account_form.agreement = this.getVerificationFormData.agreement
    },
    setAgreement() {
      this.information_account_form.agreement = (this.information_account_form.agreement == 1) ? '' : 1
    },
    nextStep() {
      this.$v.verify_account_form.$touch();
      if (this.$v.verify_account_form.$anyError) {
        return;
      } else {
        this.verify_data = true
        this.$store.commit('SET_CURRENT_PAGE_VERIFICATION_VERIFY', this.verify_account_form)
        this.verify_data = false
        this.changeActiveTab('information')
      }
    },
    async onSubmit() {
      this.$v.information_account_form.$touch();
      if (this.$v.information_account_form.$anyError) {
        return;
      } else {
        try {
          this.$store.commit('SET_CURRENT_PAGE_VERIFICATION_INFORMATION', this.information_account_form)
          const {status, message} = await this.$store.dispatch('postPageVerification', this.$route.params.id)
          if (status === 1) {
            await this.makeToast('success', 'Page Verification', `Page verified successfully!`)
          } else {
            await this.makeToast('error', 'Page Verification', message)
          }
        } catch (e) {
          await this.makeToast('error', 'Page Verification', e.message)
        }
      }
    },
    async searchArtist(search) {
      await this.$store.dispatch('searchArtists', {type: 'artist', name: search})
    },
    dropdownShouldOpen(VueSelect) {
      if (this.search) {
        return VueSelect.open
      }
      return VueSelect.search.length !== 0 && VueSelect.open
    },
    searchButton() {
      this.search = true;
      $('.vs__search').click()
    }
  }
}
</script>

<style>
.headers-style {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.btn-outline-primary {
  color: #D1433A !important;
  border-color: #D1433A !important;
  background: inherit !important;
  width: 100%;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background: #D1433A !important;
}

.simple-button {
  width: 100%;
  background: #E6EAF0 !important;
  color: #47505B !important;
  font-weight: bold !important;
  border-color: #E6EAF0 !important;
}

.accounts-main-row {
  border: 1px solid #E6EAF0;
  border-radius: 4px
}

.accounts-main-row p {
  color: #8B949F;
}

.accounts-icon-div {
  background: #F7F8FA;
  border-radius: 4px 0px 0px 4px;
}

.account-label {
  color: #47505B;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.search-image {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.v-select-main-class {
  width: 100%;
  border: none !important;
  /*margin-top: 20px !important;*/
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
</style>
<style scoped lang="scss">
.panel_wrapper {
  margin-top: 59px;
}
.artist_body {
  display: flex;
  justify-content: center;
  margin: auto;
}
</style>
