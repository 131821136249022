import moment from "moment-timezone";

export default {
    data() {
        return {
            msg: 'Hello World'
        }
    },
    methods: {
        removeMentionsOnBackSpace(e){
            if (e.keyCode === 46|| e.keyCode === 8) {
                if(!(this.content.endsWith(" "))){
                    let words = this.content.split(" ");
                    if(words[words.length-1] && words[words.length-1].startsWith("@")){
                        e.preventDefault()
                        words.pop();
                    }
                    this.content =words.join( " ")
                }
            }
        }
    }
}
