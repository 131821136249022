<template>
  <div class="wr_search_section">
    <article class="search">
      <h2 class="search_titlse">{{ searchTitle }}</h2>
      <div>
        <input
          type="text"
          :placeholder="
            $route.path.includes('/event-manager/page/orders')
              ? 'Search order by event, name or date'
              : 'Search event by name, venue or location'
          "
        />
      </div>
    </article>
    <div v-if="$route.path.includes('/event-manager/page/events')">
      <article class="wr_status">
        <h3 class="search_titlse">Event Status</h3>
        <div
          class="item"
          v-for="item in eventStatus"
          :key="item.id"
          @click="filtrEventsStatus(item)"
        >
          <label>
            <input type="checkbox" checked="checked" v-model="item.active" />
            <span class="checkmark">
              <svg
                v-show="item.active"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  fill="#D1433A"
                />
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {{ item.value }}
          </label>
        </div>
      </article>
      <article class="wr_artist">
        <h3 class="search_titlse">Artists</h3>
        <div
          class="item"
          v-for="item in eventArtists"
          :key="item.id"
          @click="filtrEventsArtist(item)"
        >
          <label>
            <input type="checkbox" checked="checked" v-model="item.active" />
            <span class="checkmark">
              <svg
                v-show="item.active"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  fill="#D1433A"
                />
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {{ item.value }}
          </label>
        </div>
        <div class="more">MORE (18)</div>
      </article>
    </div>
    <article v-if="$route.path.includes('/event-manager/page/tickets')">
      <article class="wr_status">
        <h3 class="search_titlse">Tickets to Show</h3>
        <div
          class="item"
          v-for="item in ticketsShow"
          :key="item.id"
          @click="filtrTicketsShow(item)"
        >
          <label>
            <input type="checkbox" checked="checked" v-model="item.active" />
            <span class="checkmark">
              <svg
                v-show="item.active"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  fill="#D1433A"
                />
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {{ item.value }}
          </label>
        </div>
      </article>
    </article>
    <article
      v-if="
        $route.path.includes('/event-manager/page/tickets') ||
        $route.path.includes('event-manager/page/events')
      "
      class="wr_sort"
    >
      <h3 class="search_titlse">Sort</h3>
      <div class="filter-sort-list">
        <div class="item" v-for="item in eventsSortList" :key="item.id">
          <label :for="item.value">
            <input
              type="radio"
              :value="item.value"
              :id="item.value"
              v-model="eventsSortValue"
            />
            <span
              class="radio"
              :class="{ active: eventsSortValue === item.value }"
            ></span>
            <p class="mb-0">{{ item.value }}</p>
          </label>
        </div>
      </div>
    </article>
    <div v-if="$route.path.includes('/event-manager/page/orders')">
      <article class="wr_status">
        <h3 class="search_titlse">Order Status</h3>
        <div
          class="item"
          v-for="item in ordersStatus"
          :key="item.id"
          @click="filtrOrdersStatus(item)"
        >
          <label>
            <input type="checkbox" checked="checked" v-model="item.active" />
            <span class="checkmark">
              <svg
                v-show="item.active"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  fill="#D1433A"
                />
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {{ item.value }}
          </label>
        </div>
      </article>
      <article
        v-if="!$route.path.includes('/event-manager/page/orders/detail')"
        class="wr_artist"
      >
        <h3 class="search_titlse">Event</h3>
        <div
          class="item"
          v-for="item in oredrsArtists"
          :key="item.id"
          @click="filtrOrdersArtist(item)"
        >
          <label>
            <input type="checkbox" checked="checked" v-model="item.active" />
            <span class="checkmark">
              <svg
                v-show="item.active"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  fill="#D1433A"
                />
                <path
                  d="M0.898438 2.82227L2.82349 4.74707L6.73938 0.830078"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {{ item.value }}
          </label>
        </div>
        <div class="more">MORE (18)</div>
      </article>
      <article class="wr_sort">
        <h3 class="search_titlse">Sort</h3>
        <div class="filter-sort-list">
          <div class="item" v-for="item in ordersSortList" :key="item.id">
            <label :for="item.value">
              <input
                type="radio"
                :value="item.value"
                :id="item.value"
                v-model="ordersSortValue"
              />
              <span
                class="radio"
                :class="{ active: ordersSortValue === item.value }"
              ></span>
              <p class="mb-0">{{ item.value }}</p>
            </label>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      eventStatus: [
        {
          id: 0,
          value: "All Statuses",
          active: false,
        },
        {
          id: 1,
          value: "Published",
          active: false,
        },
        {
          id: 2,
          value: "Draft",
          active: false,
        },
        {
          id: 3,
          value: "Past",
          active: false,
        },
      ],
      eventArtists: [
        {
          id: 0,
          value: "All Artists",
          active: false,
        },
        {
          id: 1,
          value: "ZZ Top",
          active: false,
        },
        {
          id: 2,
          value: "Nirvana",
          active: false,
        },
        {
          id: 3,
          value: "Metallica",
          active: false,
        },
      ],
      ticketsShow: [
        {
          id: 0,
          value: "All Tickets",
          active: false,
        },
        {
          id: 1,
          value: "Future Events",
          active: false,
        },
        {
          id: 2,
          value: "Past Events",
          active: false,
        },
        {
          id: 3,
          value: "Only Events with Ticket Sales",
          active: false,
        },
      ],
      eventsSortValue: null,
      eventsSortList: [
        {
          id: 0,
          value: "Start Date",
        },
        {
          id: 1,
          value: "Gross",
        },
        {
          id: 2,
          value: "Tickets Sold",
        },
      ],
      ordersSortValue: null,
      ordersSortList: [
        {
          id: 0,
          value: "Order Date",
        },
        {
          id: 1,
          value: "Amount",
        },
        {
          id: 2,
          value: "Status",
        },
      ],
      ordersStatus: [
        {
          id: 0,
          value: "All Statuses",
          active: false,
        },
        {
          id: 1,
          value: "Confirmed",
          active: false,
        },
        {
          id: 2,
          value: "Cancelled",
          active: false,
        },
        {
          id: 3,
          value: "Pending",
          active: false,
        },
      ],
      oredrsArtists: [
        {
          id: 0,
          value: "All Events",
          active: false,
        },
        {
          id: 1,
          value: "Playground Presents Nina Kraviz",
          active: false,
        },
        {
          id: 2,
          value: "Playground Presents Nina Kraviz",
          active: false,
        },
        {
          id: 3,
          value: "Playground Presents Nina Kraviz",
          active: false,
        },
      ],
    };
  },
  methods: {
    filtrEventsArtist(item) {
      console.log(item);
    },
    filtrEventsStatus(item) {
      console.log(item);
    },
    filtrTicketsShow(item) {
      console.log(item);
    },
    filtrOrdersArtist(item) {
      console.log(item);
    },
    filtrOrdersStatus(item) {
      console.log(item);
    },
  },
  computed: {
    searchTitle() {
      let searchTitle;
      if (this.$route.path.includes("event-manager/page/events")) {
        searchTitle = "Search Events";
      } else if (this.$route.path.includes("/event-manager/page/tickets")) {
        searchTitle = "Search Tickets";
      } else if (this.$route.path.includes("/event-manager/page/orders")) {
        searchTitle = "Search Order";
      }
      return searchTitle;
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_search_section {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  .search_titlse {
    font-family: HelveticaNeueBold;
    font-weight: 700;
    line-height: 22px;
  }
  h2 {
    color: #071526;
    font-size: 18px;
  }
  h3 {
    color: #47505b;
    font-size: 17px;
  }
  .search {
    div {
      border: 1.3px solid #e6eaf0;
      border-radius: 4px;
      height: 40px;
      input {
        width: 100%;
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 0 10px;
        height: 100%;
        font-weight: 400;
        font-size: 13px;
        font-family: HelveticaNeueNormal;
        letter-spacing: 0.7px;
        color: #47505b;
      }
    }
  }
  .wr_status,
  .wr_artist,
  .search {
    border-bottom: 1px solid #e6eaf0;
    padding: 20px;
  }
  .wr_status,
  .wr_artist {
    label {
      padding: 0;
      margin: 0;
      line-height: 0px;
      color: #47505b;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 13px;
      color: #47505b;
      font-family: HelveticaNeueNormal;
      letter-spacing: 0.7px;
      input {
        margin-right: 10px !important;
      }
    }
    .item {
      width: 100%;
      padding: 7px 0px;
      color: #071526;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      font-family: HelveticaNeueMedium;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #D1433A;
        opacity: 0;
      }
    }
    .more {
      margin: 16px 0 0 29px;
      color: #D1433A;
      font-weight: bold;
      font-size: 13px;
      font-family: HelveticaNeueBold;
      cursor: pointer;
    }
  }
  .wr_sort {
    padding: 20px;
    .filter-sort-list {
      margin-top: 13px;
      label {
        display: flex;
        align-items: center;
        input {
          display: none;
        }
        p {
          color: #47505b;
          font-family: HelveticaNeueNormal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          margin-left: 15px;
        }
        .radio {
          width: 14px;
          height: 14px;
          border: 1.5px solid #c4ccd6;
          border-radius: 8px;
          display: block;
        }
        .active {
          border: 4px solid #D1433A !important;
        }
      }
    }
  }
}
</style>