<template>
  <div class="latest-reviews">
    <div class="row">
      <div class="col-4 " v-for="(n, index) in 3" :key="index">
        <div class="card-item review-tile" >
          <div class="cover-wrapper"><img
            src="http://auddiodev-api.theplayground.co.uk/storage/377/conversions/1633463147-lg.jpg" alt="">
          </div>
          <div class="content-wrapper d-flex flex-column">
            <div class="d-flex flex-row">
              <div class="card-type">Feature</div>
              <div class="d-flex flex-row ml-auto align-items-center  action-block">
                <div class="svg-icon save-wish">
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1"
                          d="M2.02752 17.1556L7.11115 11.4196L12.1948 17.1556C12.2773 17.2391 12.3845 17.2952 12.5015 17.3161C12.6184 17.3369 12.7392 17.3215 12.8466 17.2719C12.9541 17.2224 13.0429 17.1412 13.1006 17.0398C13.1582 16.9384 13.1817 16.8219 13.1679 16.7068V2.48941C13.1842 2.26759 13.1096 2.04853 12.9605 1.88026C12.8114 1.71199 12.5999 1.60825 12.3724 1.5918H1.84986C1.6224 1.60825 1.41092 1.71199 1.26181 1.88026C1.11269 2.04853 1.0381 2.26759 1.05441 2.48941V16.7068C1.04055 16.8219 1.0641 16.9384 1.12173 17.0398C1.17937 17.1412 1.26818 17.2224 1.37565 17.2719C1.48313 17.3215 1.60384 17.3369 1.72079 17.3161C1.83774 17.2952 1.94503 17.2391 2.02752 17.1556V17.1556Z"
                          stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="btn-more">
                  <div class="dropdown vertical more-dropdown " @click="getHeight($event, index)" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false" data-display="static">
                    <div class="svg-icon more-position">
                      <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more-hor">
                        <path class="c-fill-1"
                              d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                              fill="white"></path>
                        <path class="c-fill-1"
                              d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                              fill="white"></path>
                        <path class="c-fill-1"
                              d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                              fill="white"></path>
                      </svg>
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more">
                        <path
                          d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                          class="c-fill-1"></path>
                        <path
                          d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                          class="c-fill-1"></path>
                        <path
                          d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                          class="c-fill-1"></path>
                      </svg>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="d-flex flex-row mt-auto">
              <div class="cover-image-sm"><img
                src="http://auddiodev-api.theplayground.co.uk/storage/377/conversions/1633463147-lg.jpg"
                alt=""></div>
              <div class="d-flex flex-column mt-auto">
                <div>
                  <div class="l-release">Latest Release</div>
                </div>
                <div class="d-flex flex-row">
                  <div>
                    <div class="play-button js-play-button new-play-button play"
                         @click="$event.target.classList.toggle('active')">
                      <div class="svg-icon">
                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                             class="play-type-1">
                          <path class="c-fill-1 c-line-1"
                                d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                                stroke="white" stroke-width="1.3"></path>
                        </svg>
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="c-line-1 c-fill-1"
                                d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                                fill="white"></path>
                          <path class="c-line-1 c-fill-1"
                                d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                                stroke="white" stroke-width="1.3"></path>
                        </svg>
                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                                stroke-linecap="round"></path>
                          <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                                stroke-linecap="round"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="mt-auto">
                    <div class="title">Starboy by The Week more text</div>
                    <div class="subtitle">14 songs, 1 hr 24 min</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import carousel from "vue-owl-carousel";

export default {
  name:'LatestRelease',
  data() {
    return {
      showMMore: false,
      optionHeight: 0,
      showOption: false,
      lastWidth: 0,
      optionWidth: 0,
      isHovering: false,
      songList: [{
        id: 1,
        name: 'qwe',
        isHovering: false
      }, {
        id: 2,
        name: 'qwe',
        isHovering: false
      }, {
        id: 3,
        name: 'qwe',
        isHovering: false
      }, {
        id: 4,
        name: 'qwe',
        isHovering: false
      }, {
        id: 5,
        name: 'qwe',
        isHovering: false
      }, {
        id: 6,
        name: 'qwe',
        isHovering: false
      }, {
        id: 7,
        name: 'qwe',
        isHovering: false
      }, {
        id: 8,
        name: 'qwe',
        isHovering: false
      }]

    }
  },
  components: {
    //  carousel
  },
  computed: {},
  afterMount: {},
  props: {
    gridTitle: String,
    redirectLink: String
  },
  methods: {
    redirect: function (url) {
      // this.$router.push(url).catch(err => { return err });
      // let title = this.gridTitle != 'Featured' ? 'Top Album of the week': 'Featured Eectronic Songs';
      this.$router.push({name: 'music.'+this.redirectLink})

    },
    addClassToParent: function (event) {
      event.target.parentElement.addClassToParent('mmmm');
    },
    isHoveringFun: function (val, index) {
      this.songList[index].isHovering = val;
    },
    hide: function () {
      this.showOption = false;
    },
    getHeight: function (e, ei) {
      e.stopPropagation();

      console.log(e.screenX, e.screenY, 'Dimension', this.$refs.busstop.getBoundingClientRect().left)

      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }
      let height = 32;
      // this.optionWidth = parseInt(e.screenX) - (this.$refs.busstop.getBoundingClientRect().left - (e.screenX *0.25));
      this.optionWidth = parseInt(e.screenX) - (this.$refs.busstop.getBoundingClientRect().left);
      this.optionHeight = parseInt(height);
    }

  },


}
</script>
<style scoped lang="scss">
.latest-reviews{
  .review-tile{
    height: 230px;
    .cover-wrapper{
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

