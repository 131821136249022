<template>
  <div class="Signup_pop_overlay">
    <div class="wr_upload_modal">
      <article>
        <div class="item-title">{{ title }}</div>
        <span
          @click="closeModal('close')"
          class="three_dots_menustyle for_hover_gray_btn"
          ><svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.19189L11 10.8073"
              stroke="#47505B"
              stroke-width="1.5"
            />
            <path
              d="M10.8076 1L1.19223 11"
              stroke="#47505B"
              stroke-width="1.5"
            />
          </svg>
        </span>
      </article>
      <h6>Upload Your File</h6>
      <article class="upload">
        <input type="file" @change="uploadFile" ref="uploadFile" />
        <div>
          <span>{{ fileName }}</span>
          <button class="blue_btn for_hover_blue_btn">Upload FILE</button>
        </div>
      </article>
      <div class="text">
        {{ description }}
      </div>
      <div class="wr_btn">
        <button
          @click="closeModal('close')"
          class="gray_btn for_hover_gray_btn"
        >
          CANCEL
        </button>
        <button @click="uploadThis()" class="blue_btn for_hover_blue_btn">
          SEND
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "description"],
  data() {
    return {
      fileName: "Drop file here or",
      uploadBeingfile: "",
    };
  },
  methods: {
    closeModal(do_it) {
      this.$emit("openCloseUploadModal", do_it);
    },
    uploadThis() {
      this.$emit("upload_this", 56);
    },
    uploadFile() {
      console.log("this.$refs.file.files[0]", this.$refs.uploadFile.files[0]);
      this.fileName = this.$refs.uploadFile.files[0].name;
      this.uploadBeingfile = this.$refs.uploadFile.files[0];
      const formData = new FormData();
      formData.append("file", this.uploadBeingfile);
    //   const headers = { "Content-Type": "multipart/form-data" };
    },
  },
};
</script>

<style lang='scss' scoped>
.Signup_pop_overlay {
  .wr_upload_modal {
    background: #ffffff;
    width: 370px;
    padding: 22px 20px 29px;
    border-radius: 2px;
    article {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 17px;
    }
    .item-title {
      font-family: HelveticaNeueBold;
      color: #071526;
      font-weight: 700;
      font-size: 18px;
    }
    .text {
      font-family: HelveticaNeueNormal;
      letter-spacing: 0.3px;
      font-weight: 400;
      margin: 25px 0 19px;
      font-size: 11px;
    }
    .wr_btn {
      display: flex;
      justify-content: space-between;
      button {
        width: 130px;
      }
    }
    h6 {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 13px;
      color: #47505b;
    }
    .upload {
      padding: 0;
      position: relative;
      width: 100%;
      height: 120px;
      background: #D1433A1A;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          cursor: pointer;
          width: 100px;
        }
        span {
          font-family: HelveticaNeueBold;
          margin-right: 7px;
          font-weight: 700;
          font-size: 11px;
          line-height: 13px;
          color: #D1433A;
        }
      }
    }
  }
}
</style>