import * as Utils from "../components/utils/Utils";
import {
    SUCCESS,
    ERROR,
    RIGHT_BAR, AFFILIATION_TOKEN
} from "./types";

const INITIAL_STATE = {
    [RIGHT_BAR]: false,
    [AFFILIATION_TOKEN]: null,
};

export const state = {...INITIAL_STATE};

export const actions = {
    async toggleRightBar({state, commit}) {
        return new Promise(async (resolve, reject) => {
            try {
                commit("TOGGLE_RIGHT_BAR_VALUE")
                resolve({
                    status: SUCCESS,
                    message: 'Right bar toggle success fully'
                });
            } catch (e) {
                reject({
                    status: ERROR,
                    message: Utils.getErrorFromApi(e)
                });
            }

        })

    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    TOGGLE_RIGHT_BAR_VALUE(state) {
        state[RIGHT_BAR] = !state[RIGHT_BAR];
    },
    SET_AFFILIATION_TOKEN_VALUE(state, value) {
        state[AFFILIATION_TOKEN] = value;
    },
};

const getters = {
    getRightBarValue(state) {
        let _state = Utils.parseState(state);
        return _state[RIGHT_BAR];
    },
    getAffiliationTokenValue(state) {
        let _state = Utils.parseState(state);
        return _state[AFFILIATION_TOKEN];
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
