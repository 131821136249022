<template>
  <div class="container_xxl">
    <div class="container-fluid ">
      <div class="row">
        <div class="col ">
          <h1 class="Page_title">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="content discover ">

      <div class="row">
        <div class="col">
          <div class=" page-filter d-flex d-flex flex-row align-items-center p-r-20">
            <div class="back-link">
              <a @click="redirect()" class=" d-flex flex-row align-items-center">
                <div class="svg-icon">
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1" d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152" stroke="#071526"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                {{ pageFrom }}
              </a>
            </div>
            <div class="filter-title">
              {{ pageTitle }}
            </div>
            <div class="filter-update-text">
              {{ thirdTxt }}
            </div>
            <div class="d-flex flex-wrap align-items-end ml-auto">


              <div class="text with-icon d-flex justify-content-between justify-content-end align-items-center">
                Sort
                <div class="svg-icon">
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-filter-new">
                    <rect width="16" height="1.87013" fill="#8B949F"></rect>
                    <rect y="5.61035" width="13.1765" height="1.87013" fill="#8B949F"></rect>
                    <rect y="11.2207" width="9.41176" height="1.87013" fill="#8B949F"></rect>
                  </svg>
                </div>
              </div>
              <div class="dropdown bootstrap-select show-tick filters-select for-overview">

                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false"
                        title="Featured">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">{{ filterApplied }}</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu ">
                  <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true">
                    <ul class="dropdown-menu inner show" role="presentation">
                      <li @click="updateFilter('Latest')"><a role="option" class="dropdown-item"
                                                             :class="[filterApplied == 'Latest'?'active selected':'']"
                                                             id="bs-select-9-0" tabindex="0" aria-selected="false"
                                                             aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Latest</span></a></li>
                      <li @click="updateFilter('Featured')"><a role="option" class="dropdown-item"
                                                               :class="[filterApplied == 'Featured'?'active selected':'']"
                                                               id="bs-select-9-0" tabindex="0" aria-selected="false"
                                                               aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Featured</span></a></li>
                      <li @click="updateFilter('Treanding')"><a role="option" class="dropdown-item"
                                                                :class="[filterApplied == 'Treanding'?'active selected':'']"
                                                                id="bs-select-9-0" tabindex="0" aria-selected="false"
                                                                aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Trending</span></a></li>
                      <li @click="updateFilter('Popular')"><a role="option" class="dropdown-item"
                                                              :class="[filterApplied == 'Popular'?'active selected':'']"
                                                              id="bs-select-9-0" tabindex="0" aria-selected="false"
                                                              aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Popular</span></a></li>
                      <li @click="updateFilter('High Rating')"><a role="option" class="dropdown-item"
                                                                  :class="[filterApplied == 'High Rating'?'active selected':'']"
                                                                  id="bs-select-9-0" tabindex="0" aria-selected="false"
                                                                  aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">High Rating</span></a></li>
                      <li @click="updateFilter('Low Rating')"><a role="option" class="dropdown-item"
                                                                 :class="[filterApplied == 'Low Rating'?'active selected':'']"
                                                                 id="bs-select-9-0" tabindex="0" aria-selected="false"
                                                                 aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Low Rating</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="js-container">
        <div class=" sale-items m-t-10">
          <div class="row position-relative" v-if="listing_type == 'grid'">


            <div class="col-sm-2 js-container-hover" v-for="(row, index) in allData" :key="index">
              <PageOrArtistCard :item="row"/>
            </div>
          </div>

        </div>
      </div>
      <div class="row" v-if="totalRows > allData.length">
        <div class="col-12">
          <div class="show-more musicbar_more mt-0">
            <a href="javascript:;" @click="fetchMoreData">
                     <span class="show_more_bg">
                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                                 stroke-linecap="round" stroke-linejoin="round"></path>
                           <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                                 stroke-linejoin="round"></path>
                        </svg>
                        SHOW MORE
                     </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>
<script type="application/javascript">
import {getPageData} from '../../../apis/APIs';
import PageOrArtistCard from "@/components/Common/UiComponenets/PageOrArtistCard.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      listing_type: 'grid',
      allData: [],
      loading: false,
      dataLoaded: false,
      limit: 6,
      offset: 0,
      totalRows: 0,
      filterApplied: 'LatestAlbums',
      user_id: ''
    }
  },

  components: {
    PageOrArtistCard
  },
  props: {
    title: String,
    pageFrom: String,
    pageTitle: String,
    redirectUrl: String,
    activeHeader: String,
    thirdTxt: String,
    pageFilter: {
      type: String,
      default: ''
    },
    header: Boolean
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
  channelSlug(){
    const {name} =this.$route
    if(name && name.includes('music.')){
      return name.replace('music.','')
    }
    return name
  }
  },
  mounted: function () {

    let user_id = this.user.user_data.user_id;
    this.pageChannels();

  },

  methods: {
    ...mapActions([
      "fetchUserData"
    ]),
    async pageChannels() {
      let user_id = this.user.user_data.user_id;
      try {
        this.loading = true
        let {data} = await getPageData((this.channelSlug) ? this.channelSlug : null, this.limit, this.offset, this.filterApplied);
        this.allData = data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchMoreData() {
      let user_id = this.user.user_data.user_id;
      try {
        this.offset = parseInt(this.offset) + this.limit;
        this.loading = true
        let {data} = await getPageData((this.channelSlug) ? this.channelSlug : null, this.limit, this.offset, this.filterApplied);
        this.allData = [...this.allData, ...data.channels.center.objects.data]
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    showForm: function (type) {
      this.listing_type = type;
    },
    redirect: function (url) {
      this.$router.push({name: this.redirectUrl})
    },
    async updateFilter(currentFilter) {
      this.filterApplied = currentFilter;
      let user_id = this.user.user_data.user_id;
      try {
        this.loading = true;
        this.offset = 0;
        let {data} = await getPageData((this.channelSlug) ? this.channelSlug : null);
        this.allData = data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  }
}
</script>
