<template>

  <div class="row">
    <div class="col">
      <listArtistCardSmall v-for="(eachData, index) in listData" :key="index" :item="eachData" type="record" />
    </div>
  </div>

</template>
<script type="application/javascript">
import listArtistCardSmall from "@/components/DesignGuide/Music/Components/ListArtistRecordCardSmall";
export default {
  data() {
    return {
      showSubMenu: 'none',
      showMore: false,
    }
  },

  components: {
    listArtistCardSmall
  },
  props: {
    width: String,
    hasTitleHeader: {
      type: Boolean,
      default: true
    },
    listData: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  mounted: function () {
  },
  methods: {
    getImgUrl(filename) {
      if (filename) {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', filename, false);
        xhr.send();
        if (xhr.status == "404") {
          return require(`@/assets/img/placeholders.png`);
        } else {
          return filename;
        }

      } else {
        return require(`@/assets/img/placeholders.png`);
      }
    },

  }
}
</script>

