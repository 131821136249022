<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show modal_box_outer"
        id="sign_or_login "
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeRegisterModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <!-- <img src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/logo.png" alt=""> -->
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body">
            <div class="body-title d-block Font_semibold">
              <!-- <span>You need to</span> Sign Up <span> or</span> Log In <br> <span> for this action</span> -->
              Log In
            </div>
            <div class="input-wrap">
              <div class="svg-icon">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M2.66732 2.66602H13.334C14.0673 2.66602 14.6673 3.26602 14.6673 3.99935V11.9993C14.6673 12.7327 14.0673 13.3327 13.334 13.3327H2.66732C1.93398 13.3327 1.33398 12.7327 1.33398 11.9993V3.99935C1.33398 3.26602 1.93398 2.66602 2.66732 2.66602Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                  <path
                      d="M14.6673 4L8.00065 8.66667L1.33398 4"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <input
                  type="text"
                  placeholder="E-mail"
                  :value="email"
                  @change="onChange"
                  name="signup_form_email"
                  v-on:keyup="checkEnter"
              />
            </div>
            <p class="error_msg text-center" v-if="loading">Please Wait...</p>
            <p class="error_msg error_msg_red" v-if="message">{{ message }}</p>

            <label class="error_msg error_msg_red" v-if="_handleErrorMessage('email')"
            >{{ _handleErrorMessage("email") }}</label
            >
            <div class="custom-hr"></div>
            <div class="modal-title-sm fW_500">or Continue With</div>
            <p class="error_msg error_msg_red text-center" v-if="error_message">{{ error_message }}</p>
            <div class="action-block Socail_btns">
              <SMFacebook/>
              <SMGoogle/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as Helper from '../../../apis/Helper.js';
import {SIGNUP_ERRORS, SUCCESS, SERVER_PRESENT} from '../../../store/types';
import SMFacebook from "@/components/Landing/auth/SMFacebook.vue";
import SMGoogle from "@/components/Landing/auth/SMGoogle.vue";

export default {
  data() {
    return ({
      message: "",
      error_message: ""
    })
  },
  components: {
    SMFacebook,
    SMGoogle
  },
  computed: {
    ...mapGetters({
      form_data: "signup_email_form_map_to_props"
    }),
    email() {
      const {email = ""} = this.form_data || {};

      return email;
    },
    loading() {
      const {loading = false} = this.form_data || {};

      return loading;
    },
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions([
      "check_user_exists"
    ]),
    ...mapMutations([
      "updateSignupFormData",
      "updateSignupUIConstraints"
    ]),
    setErrorMessage(message) {
      this.error_message = message;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      return message;
    },
    onChange(e) {
      const {name, value} = e.target || {};

      this.updateSignupFormData({
        [name]: value
      });
    },
    checkEnter(event) {

      if (event.keyCode === 13) {
        const body = {
          email: this.email
        };

        Helper.validate(Object.keys(body), body)
            .then(async ({status, response}) => {
              if (status) {
                this.updateSignupUIConstraints({
                  [SIGNUP_ERRORS]: []
                });
                try {
                  this.message = "";
                  const {status, message} = await this.check_user_exists();

                  switch (status) {
                    case SUCCESS:
                      this.$parent.newModal("need-signup");
                      break;
                    case SERVER_PRESENT:
                      this.$parent.newModal("login");
                    default:
                      this.message = message;
                  }
                } catch (e) {
                  this.message = e && e.message ? e.message : "Please try again.";
                }
              } else this.updateSignupUIConstraints({
                [SIGNUP_ERRORS]: response && response.length ? response : []
              });
            })
            .catch(err => console.log(err));
      }
    },
    checkEnter1: function (event) {
      let email = this.email;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (event.keyCode == 13) {
        if (re.test(String(email))) {
          this.$parent.nextStep();
        } else {
          this.has_error = true;
        }
      } else if (email == "") {
        this.has_error = false;
      }
    },
    closeRegisterModal: function () {
      this.updateSignupFormData({});
      this.$parent.closeModal("sign-up-email");
    }
  }
};
</script>
