<template>
  <div class="ag-container">
    <MusicSubHeader v-if="current_page && (current_page.type === 'clubroom' || current_page.type === 'partyroom')" :current_page_user="(current_page && current_page.current_user) ? current_page.current_user : null"/>
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper" :class="{'panel_wrapper_top' : current_page && (current_page.type === 'artist' || current_page.type === 'company')}">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/icons/artist-admins.svg`)" alt="icon">Admins
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="d-flex">
                <div class="d-flex mb-30 main-search-div col-10 p-0">
                  <div class="col-1 d-flex align-items-center justify-content-center">
                    <span><img style="margin: 0!important;" :src="require(`@/assets/icons/search-icon.svg`)"></span>
                  </div>
                  <div class="col-8 d-flex align-items-center">
                    <v-select class="v-select-main-class v-select-without-border" label="name"
                        :filterable="false"
                        :options="searchData"
                        v-model="selected_user"
                        :reduce="(option) => option.id"
                        placeholder="Search for admins or moderators"
                        @search="onSearch">
                      <template slot="no-options">
                        Search for admins or moderators
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <img class="search-image" style="border-radius: 50%" :src='option.artwork_url'/>
                          {{ option.name }} {{ option.last_name}}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option" >
                        <div class="selected d-center">
                          <img class="search-image" :src='option.artwork_url'/>
                          {{ option.name }} {{ option.last_name}}
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-3 user-roles-main-div">
                    <v-select class="v-select-admin-class user-roles" label="name"
                              :filterable="false"
                              :options="usersTypesList"
                              v-model="selected_role"
                              :reduce="(option) => option.id">
                      <template slot="no-options">
                        Search for admins or moderators
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.name }}
                          <svg v-if="option.id === selected_role" class="ml-auto" width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z" fill="#D1433A"/>
                          </svg>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ (option.name) ? option.name  : 'Admin'}}
                        </div>
                      </template>
                    </v-select>
<!--                    <b-form-select class="user-roles" v-model="selected_role" :options="usersTypesList" value-field="id" text-field="name"></b-form-select>-->
                  </div>
                </div>
                <div class="col-2">
                  <button class="add-button" type="button" :disabled="!selected_role && !selected_user" @click="addUserInList">ADD</button>
                </div>
              </div>
              <div class="admin-detail" v-if="getAdmins && getAdmins.length > 0">
                <h1>ADMINS ({{ (getAdmins && getAdmins.length > 0) ? getAdmins.length : 0 }})</h1>
                <ul class="main-list" v-if="getAdmins && getAdmins.length > 0">
                  <li class="sub-list" v-for="(admin,index) in getAdmins" :key="index">
                    <div class="artist-admin-detail">
                      <div class="ar-admin-name-img">
                        <div class="artist-admin-image">
                          <img :src="(admin && admin.artwork_url) ?  admin.artwork_url : ''">
                          <span class="artist-admin-online"></span>
                        </div>
                        <div class="artist-admin-name">
                          {{ (admin && admin.name && admin.last_name) ? admin.name + ' ' + admin.last_name : '' }}
                        </div>
                      </div>
                      <div v-if="current_page && current_page.user_id !== admin.id" class="remove-artist-admin align-items-center">
                        <button v-if="admin && admin.page_invitation_status && admin.page_invitation_status.toUpperCase() === 'PENDING'" class="status-artist-button mr-2">
                          {{ admin.page_invitation_status.toUpperCase() }}
                        </button>
                        <button class="remove-artist-button" @click="addMoveRemoveUser('remove','Admin',admin.id)">
                          <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="remove-artist-button-active" d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="remove-artist-button-active" d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          REMOVE
                        </button>
                        <div>
                          <b-dropdown id="dropdown-right" right text="Right align" size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                              <button class="artist-admin-more">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                              </button>
                            </template>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Admin', admin.id)">Move to Moderators</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Editor', admin.id)">Move to Editors</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Advertiser', admin.id)">Move to Advertisers</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="admin-detail" v-if="getEditors && getEditors.length > 0">
                <h1>EDITORS ({{ (getEditors && getEditors.length > 0) ? getEditors.length : 0 }})</h1>
                <ul class="main-list" v-if="getEditors && getEditors.length > 0">
                  <li class="sub-list" v-for="(editor,index) in getEditors" :key="index">
                    <div class="artist-admin-detail">
                      <div class="ar-admin-name-img">
                        <div class="artist-admin-image">
                          <img :src="(editor && editor.artwork_url) ?  editor.artwork_url : ''">
                          <span class="artist-admin-online"></span>
                        </div>
                        <div class="artist-admin-name">
                          {{ (editor && editor.name && editor.last_name) ? editor.name + ' ' + editor.last_name : '' }}
                        </div>
                      </div>
                      <div class="remove-artist-admin align-items-center">
                        <button v-if="editor && editor.page_invitation_status && editor.page_invitation_status.toUpperCase() === 'PENDING'" class="status-artist-button mr-2">
                          {{ editor.page_invitation_status.toUpperCase()}}
                        </button>
                        <button class="remove-artist-button" @click="addMoveRemoveUser('remove','Editor',editor.id)">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="remove-artist-button-active" d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="remove-artist-button-active" d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          REMOVE
                        </button>
                        <div>
                          <b-dropdown id="dropdown-right" right text="Right align" size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                              <button class="artist-admin-more">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                              </button>
                            </template>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Admin', editor.id)">Move to Administrators</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Moderator', editor.id)">Move to Moderators</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Advertiser', editor.id)">Move to Advertiser</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="admin-detail" v-if="getModerators && getModerators.length > 0">
                <h1>MODERATORS ({{ (getModerators && getModerators.length > 0) ? getModerators.length : 0 }})</h1>
                <ul class="main-list" v-if="getModerators && getModerators.length > 0">
                  <li class="sub-list" v-for="(moderator,index) in getModerators" :key="index">
                    <div class="artist-admin-detail">
                      <div class="ar-admin-name-img">
                        <div class="artist-admin-image">
                          <img :src="(moderator && moderator.artwork_url) ?  moderator.artwork_url : ''">
                          <span class="artist-admin-online"></span>
                        </div>
                        <div class="artist-admin-name">
                          {{ (moderator && moderator.name && moderator.last_name) ? moderator.name + ' ' + moderator.last_name : '' }}
                        </div>
                      </div>
                      <div class="remove-artist-admin align-items-center">
                        <button v-if="moderator && moderator.page_invitation_status && moderator.page_invitation_status.toUpperCase() === 'PENDING'" class="status-artist-button mr-2">
                          {{ moderator.page_invitation_status.toUpperCase() }}
                        </button>
                        <button class="remove-artist-button" @click="addMoveRemoveUser('remove','Moderator',moderator.id)">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="remove-artist-button-active" d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="remove-artist-button-active" d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          REMOVE
                        </button>
                        <div>
                          <b-dropdown id="dropdown-right" right text="Right align" size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                              <button class="artist-admin-more">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                              </button>
                            </template>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Moderator', moderator.id)">Move to Administrators</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Editor', moderator.id)">Move to Editor</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Advertiser', moderator.id)">Move to Advertiser</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="admin-detail" v-if="getAdvertiser && getAdvertiser.length > 0">
                <h1>ADVERTISER ({{ (getAdvertiser && getAdvertiser.length > 0) ? getAdvertiser.length : 0 }})</h1>
                <ul class="main-list" v-if="getAdvertiser && getAdvertiser.length > 0">
                  <li class="sub-list" v-for="(advertiser,index) in getAdvertiser" :key="index">
                    <div class="artist-admin-detail">
                      <div class="ar-admin-name-img">
                        <div class="artist-admin-image">
                          <img :src="(advertiser && advertiser.artwork_url) ?  advertiser.artwork_url : ''">
                          <span class="artist-admin-online"></span>
                        </div>
                        <div class="artist-admin-name">
                          {{ (advertiser && advertiser.name && advertiser.last_name) ? advertiser.name + ' ' + advertiser.last_name : '' }}
                        </div>
                      </div>
                      <div class="remove-artist-admin align-items-center">
                        <button v-if="advertiser && advertiser.page_invitation_status && advertiser.page_invitation_status.toUpperCase() === 'PENDING'" class="status-artist-button mr-2">
                          {{ advertiser.page_invitation_status.toUpperCase() }}
                        </button>
                        <button class="remove-artist-button" @click="addMoveRemoveUser('remove','Advertiser',advertiser.id)">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="remove-artist-button-active" d="M12 4L4 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="remove-artist-button-active" d="M4 4L12 12" stroke="#EE3F3F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          REMOVE
                        </button>
                        <div>
                          <b-dropdown id="dropdown-right" right text="Right align" size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                              <button class="artist-admin-more">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path class="artist-admin-more-active"
                                        d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                                        stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                </svg>
                              </button>
                            </template>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Admin', advertiser.id)">Move to Administrators</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Moderator', advertiser.id)">Move to Moderators</b-dropdown-item>
                            <b-dropdown-item @click="addMoveRemoveUser('move', 'Editor', advertiser.id)">Move to Editors</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="d-flex justify-content-end mt_30">
                <button class="prime_button " type="button" @click="saveData">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import '/public/css/vue-select-style.scss';
import {addOrUpdatePageUser} from "../../../apis/APIs";
import createFormData from "../../../mixins/common";
import appConfig from "../../../configs/AppConfig";

export default {
  data() {
    return {
      selected_user: '',
      selected_role: 5,
      selected_role_name: ''
    }
  },
  components: {
    MusicSubHeader,
    vSelect
  },
  computed: {
    getAdmins() {
      return this.$store.getters.getCurrentPageAdmins
    },
    getModerators() {
      return this.$store.getters.getCurrentPageModerators
    },
    getEditors() {
      return this.$store.getters.getCurrentPageEditors
    },
    getAdvertiser() {
      return this.$store.getters.getCurrentPageAdvertiser
    },
    searchData() {
      return this.$store.getters.getSearchedFriendsData
    },
    usersTypesList() {
      return this.$store.getters.getPageRoles
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  mounted() {
    this.$store.dispatch('getPageRoles')
    this.$store.dispatch('getAdminAndModerators', this.$route.params.id)
  },
  methods: {
    onSearch(search, loading) {
      loading(true);
      this.$store.dispatch('searchFriendsForAdminSection', search)
      loading(false);
    },
    async addMoveRemoveUser(function_type, user_type = '', id = '') {
      let body = {};
      let role = '';
      if (function_type === 'add') {
        role = this.getRoleName(this.selected_role)
        body.role_id = this.selected_role
        body.action = 1
      } else if (function_type === 'remove') {
        if (user_type === 'Admin') {
          body.role_id = 5
          role = this.getRoleName(5)
        } else if (user_type === 'Moderator') {
          body.role_id = 6
          role = this.getRoleName(6)
        } else if (user_type === 'Editor') {
          body.role_id = 7
          role = this.getRoleName(7)
        } else if (user_type === 'Advertiser') {
          body.role_id = 8
          role = this.getRoleName(8)
        }
        body.action = 0
      } else if (function_type === 'move') {
        if (user_type === 'Admin') {
          body.role_id = 5
          role = this.getRoleName(5)
        } else if (user_type === 'Moderator') {
          body.role_id = 6
          role = this.getRoleName(6)
        } else if (user_type === 'Editor') {
          body.role_id = 7
          role = this.getRoleName(7)
        } else if (user_type === 'Advertiser') {
          body.role_id = 8
          role = this.getRoleName(8)
        }
        body.action = 1
      }
      try {
        body.page_id = this.$route.params.id
        body.member_id = (id) ? id : this.selected_user
        body.url = window.location.origin + '/page-manager/page/notification/' + this.$route.params.id +'/'+ role
        await this.$store.dispatch('loading', true);
        const formData = createFormData(body);
        const { data } = await addOrUpdatePageUser(formData);
        const { success, message } = data || ''
        await this.$store.dispatch('loading', false);
        if (success === 1 ) {
          this.resetData();
          await this.$store.dispatch('getAdminAndModerators', this.$route.params.id)
          await this.makeToast('success', 'Admins Page', `Successfully ${message}`)
        } else {
          await this.makeToast('error', 'Admins Page', message)
        }
      } catch (e) {
        await this.$store.dispatch('loading', false);
        console.error(e.message)
        await this.makeToast('error', 'Admins Page', 'Please try again!')
      }
    },
    resetData() {
      this.selected_user = ''
      this.selected_role = 5
    },
    getRoleName (value){
      const index = this.usersTypesList.findIndex(e => e.id === value)
      if (index !== -1) {
        return this.usersTypesList[index].name
      }
    },
    addUserInList () {
      if (this.selected_role && this.selected_user && this.searchData && this.searchData.length > 0) {
        const filter = this.searchData.filter(e=> e.id === this.selected_user)
        if (this.selected_role === 5) {
          const admins = this.getAdmins
          this.$store.commit('SET_CURRENT_PAGE_ADMINS', (admins && admins.length > 0) ? [...admins, ...filter] : filter)
        } else if (this.selected_role === 6) {
          const moderators = this.getModerators
          this.$store.commit('SET_CURRENT_PAGE_MODERATORS', (moderators && moderators.length > 0) ? [...moderators, ...filter] : filter)
        } else if (this.selected_role === 7) {
          const editors = this.getEditors
          this.$store.commit('SET_CURRENT_PAGE_EDITORS', (editors && editors.length > 0) ? [...editors, ...filter] : filter)
        } else if (this.selected_role === 8) {
          const advertisers = this.getAdvertiser
          this.$store.commit('SET_CURRENT_PAGE_ADVERTISER', (advertisers && advertisers.length > 0) ? [...advertisers, ...filter] : filter)
        }
      }
    },
    saveData() {
      this.addMoveRemoveUser('add')
    }
  }
}
</script>

<style scoped>

.main-search-div {
  border: 1px solid #C4CCD6;
  height: 2.5rem;
  border-radius: 4px;
}

.search-image {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}
.v-select-main-class {
  width: 100%;
  border: none!important;
  /*margin-top: 20px !important;*/
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  border-radius: 50%;
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}


.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
.user-roles-main-div {
  border-left: 1px solid #C4CCD6;
  padding: 0;
}
.user-roles {
  border: none;
}

.add-button {
  width: 6.5rem;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  height: 40px;
  background: #D1433A;
  color: #fff;
  border: 1px solid #D1433A;
  border-radius: 4px;
  transition: 0.2s all ease;
  font-family: 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
}

.admin-detail {
  margin-bottom: 20px;
  border: 1px solid #E6EAF0;
  border-radius: 4px;
}

.admin-detail h1 {
  font-size: 13px;
  font-weight: 700;
  padding: 17px 30px;
  border-bottom: 1px solid #E6EAF0;
  color: #47505B;
  margin-bottom: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

.admin-detail .main-list {
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}

.admin-detail .main-list .sub-list {
  list-style: none;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #E6EAF0;
}

.admin-detail .main-list .sub-list:last-child {
  border-bottom: none;
}

.status-artist-button {
  width: 110px;
  height: 30px;
  border: 1.5px solid #D1433A;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 700;
  color: #D1433A;
  background-color: #fff;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Helvetica Neue', sans-serif;
}

.status-artist-button:hover {
  background: #D1433A;
  color: #fff;
}
.active {
  background: #D1433A1A!important;
}
</style>
<style lang="scss" scoped>
.panel_wrapper_top {
  margin-top: 59px;
}

.ag-container {
  width: 100%;
  padding: 0;
}
</style>

