<template>
  <div class="widget-follow" v-if="this.work_list.length > 0">
    <div class="widget-title">
      About
      <div class="link-more near_arrow without-slider in-title pointerClass">
        <a v-on:click="changeTab">show more
          <span class="sl-arrow">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo=" />
          </span>
        </a>
      </div>
    </div>
    <div class="about-more">
      <div class="about-more-details">
        <span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.8455 5.54768C2.64934 5.54768 1.66602 6.51022 1.66602 7.71434V15.3333C1.66602 16.5375 2.64934 17.5 3.8455 17.5H16.1532C17.3494 17.5 18.3327 16.5375 18.3327 15.3333V7.71434C18.3327 6.51022 17.3494 5.54768 16.1532 5.54768H13.7172V4.66665C13.7172 4.08943 13.4856 3.53748 13.076 3.13178C12.6667 2.72636 12.1132 2.5 11.5377 2.5H8.46082C7.88533 2.5 7.33184 2.72636 6.92251 3.13178C6.51291 3.53748 6.28133 4.08943 6.28133 4.66665V5.54768H3.8455ZM12.4352 4.66665C12.4352 4.43561 12.3426 4.21239 12.1751 4.04655C12.0074 3.88044 11.7783 3.78571 11.5377 3.78571H8.46082C8.22025 3.78571 7.99112 3.88044 7.82341 4.04655C7.65597 4.21239 7.56338 4.43561 7.56338 4.66665V5.54768H12.4352V4.66665ZM6.2819 6.8335V16.2144H7.56395V6.8335H6.2819ZM13.7178 16.2144V6.8335H12.4357V16.2144H13.7178Z"
              fill="#8B949F" />
          </svg>
        </span>
        <!-- <p v-if="userData.work_title && userData.work_place">
          <span>Director at</span>&nbsp;
          {{ userData.work_title }} &nbsp;at&nbsp;{{ userData.work_place }}
        </p> -->
        <p>
          <span>{{ this.work_list && this.work_list[0].title }} at</span>{{ this.work_list && this.work_list[0].company_name }}
        </p>
      </div>
      <div
        v-if="!otherUser || checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_location_info"
        class="about-more-details">
        <span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.6734 2.14744C9.28461 1.51939 10.3101 1.50663 10.9377 2.11928L17.8742 8.8905C18.1678 9.17717 18.3327 9.56497 18.3327 9.9692V16.8046C18.3327 17.6496 17.6268 18.3346 16.7561 18.3346H13.0601C12.1894 18.3346 11.4835 17.6496 11.4835 16.8046V13.711C11.4835 13.5903 11.3827 13.4924 11.2583 13.4924H9.1331C9.00871 13.4924 8.90787 13.5903 8.90787 13.711V16.8046C8.90787 17.6496 8.20202 18.3346 7.3313 18.3346H3.24259C2.37187 18.3346 1.66602 17.6496 1.66602 16.8046V9.95615C1.66602 9.56549 1.82 9.18963 2.0964 8.90561L8.6734 2.14744Z"
              fill="#8B949F" />
          </svg>
        </span>
        <p v-if="userData.country && userData.current_city">
          <span>Lives in</span> &nbsp;{{ userData.current_city }}, &nbsp;{{
              userData.country && userData.country.length > 0
                ? userData.country[0].name
                : ""
          }}
        </p>
      </div>
      <div class="about-more-details">
        <span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M10.0007 18.3346C10.0007 18.3346 16.6673 13.7892 16.6673 8.48615C16.6673 6.67786 15.9649 4.94362 14.7147 3.66497C13.4645 2.38631 11.7688 1.66797 10.0007 1.66797C8.23254 1.66797 6.53685 2.38631 5.28661 3.66497C4.03636 4.94362 3.33398 6.67786 3.33398 8.48615C3.33398 13.7892 10.0007 18.3346 10.0007 18.3346ZM12.2234 8.4866C12.2234 9.74179 11.2285 10.7593 10.0012 10.7593C8.77389 10.7593 7.77897 9.74179 7.77897 8.4866C7.77897 7.2314 8.77389 6.21387 10.0012 6.21387C11.2285 6.21387 12.2234 7.2314 12.2234 8.4866Z"
              fill="#8B949F" />
          </svg>
        </span>
        <p v-if="userData.hometown && userData.country">
          <span>From</span>&nbsp;{{ userData.hometown }}, &nbsp;{{
              userData.country && userData.country.length > 0
                ? userData.country[0].name
                : ""
          }}
        </p>
      </div>
      <div class="about-more-details">
        <span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.66595 10.8346L1.66602 10.8346C1.8733 12.8973 2.84196 14.8087 4.38285 16.1955C5.92374 17.5823 7.92622 18.3449 9.99925 18.3345C7.86594 18.3345 6.81595 14.5929 6.66595 10.8346Z"
              fill="#8B949F" />
            <path
              d="M1.66602 9.16798L6.66595 9.16798C6.79929 5.40969 7.84927 1.66807 9.99925 1.66807C7.92621 1.65768 5.92374 2.42036 4.38285 3.80716C2.84196 5.19396 1.8733 7.10531 1.66602 9.16798Z"
              fill="#8B949F" />
            <path
              d="M9.9977 16.6679C10.3977 16.6679 11.506 14.6679 11.6643 10.8346L8.33106 10.8346C8.48939 14.6679 9.59771 16.6679 9.9977 16.6679Z"
              fill="#8B949F" />
            <path
              d="M9.9977 3.33382C9.59771 3.33382 8.48939 5.3338 8.33106 9.16708L11.6643 9.16708C11.506 5.3338 10.3977 3.33382 9.9977 3.33382Z"
              fill="#8B949F" />
            <path
              d="M9.99925 18.3345C12.0723 18.3449 14.0749 17.5823 15.6157 16.1955C17.1566 14.8087 18.1253 12.8973 18.3326 10.8346L13.3326 10.8346C13.1826 14.5929 12.1326 18.3345 9.99925 18.3345Z"
              fill="#8B949F" />
            <path
              d="M9.99925 1.66807C12.1326 1.66807 13.1826 5.40969 13.3326 9.16798L18.3326 9.16798C18.1253 7.10531 17.1566 5.19396 15.6157 3.80716C14.0749 2.42035 12.0723 1.65768 9.99925 1.66807Z"
              fill="#8B949F" />
          </svg>
        </span>
        <p v-if="userData.website_url">
          {{ userData.website_url.slice(0, 35) }}...
        </p>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { get_user_work_api } from "../../../apis/APIs";
export default {
  data() {
    return {
      work_list_loading: false
    }
  },
  props: {
    userData: {
      type: Object,
      require: true,
    },
    work_list: {
      type: Array,
      require: true
    }
  },
  computed:{
    otherUser() {
      return !(
        this.user &&
        this.user.userData &&
        this.user.userData.username === this.$route.params.username
      );
    },
  },
  components: {},
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab("about");
    }
  },
};
</script>
