<template>
  <div class="wr_ticket_params" :class="{ bg_gray: bg_gray }">
    <div class="ticket_type">{{ ticket.ticketName }}</div>
    <div class="sold"><span>{{ticket.quantity}}</span> / {{ticket.sold}}</div>
    <div class="price">${{ ticket.price }}</div>
    <div class="total">${{ticket.total}}</div>
    <div class="status">{{ ticket.status }}</div>
    <div class="wr_actions">
      <span @click="changePlayPause" class="for_hover_gray_btn">
        <svg
          v-if="!playOrPause"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3.33301 2L12.6663 8L3.33301 14V2Z" fill="#47505B" />
        </svg>

        <svg
          v-if="playOrPause"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66667 2.66699H4V13.3337H6.66667V2.66699Z"
            fill="#47505B"
          />
          <path
            d="M11.9997 2.66699H9.33301V13.3337H11.9997V2.66699Z"
            fill="#47505B"
          />
        </svg>
      </span>
      <span @click="editTicket" class="for_hover_gray_btn"
        ><svg
          v-if="!edit"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.91699 1.74985C10.0702 1.59664 10.2521 1.47511 10.4523 1.39219C10.6524 1.30928 10.867 1.2666 11.0837 1.2666C11.3003 1.2666 11.5149 1.30928 11.7151 1.39219C11.9152 1.47511 12.0971 1.59664 12.2503 1.74985C12.4035 1.90306 12.5251 2.08494 12.608 2.28512C12.6909 2.4853 12.7336 2.69985 12.7336 2.91652C12.7336 3.13319 12.6909 3.34774 12.608 3.54791C12.5251 3.74809 12.4035 3.92998 12.2503 4.08318L4.37533 11.9582L1.16699 12.8332L2.04199 9.62485L9.91699 1.74985Z"
            fill="#47505B"
          />
        </svg>
        <svg
          v-if="edit"
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="14pt"
          height="9pt"
          viewBox="0 0 1400.000000 900.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
            fill="#47505B"
            stroke="none"
          >
            <path
              d="M3395 8988 c-22 -5 -71 -16 -110 -25 -174 -40 -337 -133 -476 -272
                    -101 -101 -172 -205 -221 -322 -31 -75 -45 -125 -79 -277 -12 -57 -12 -7127 0
                    -7184 35 -157 48 -202 81 -279 135 -316 424 -545 775 -612 86 -17 7184 -17
                    7270 0 351 67 640 296 775 612 33 77 46 122 81 279 5 26 9 1222 9 3088 l0
                    3045 -21 87 c-59 239 -234 468 -773 1010 -572 577 -834 781 -1078 841 l-87 21
                    -3053 -1 c-1840 -1 -3069 -5 -3093 -11z m5827 -366 c81 -39 148 -110 180 -190
                    l23 -57 0 -1275 c0 -1230 -1 -1277 -19 -1325 -27 -72 -71 -131 -124 -170 -98
                    -71 107 -66 -2477 -63 l-2330 3 -50 27 c-62 32 -139 113 -168 176 l-22 47 0
                    1300 0 1300 27 57 c48 103 152 182 262 198 28 4 1082 7 2341 6 l2290 -1 67
                    -33z m1038 -4153 c66 -31 132 -95 168 -164 l27 -50 0 -1830 0 -1830 -25 -50
                    c-52 -105 -154 -178 -272 -196 -84 -12 -6232 -12 -6316 0 -118 18 -220 91
                    -272 196 l-25 50 0 1830 0 1830 24 45 c46 88 104 141 189 176 l57 24 3195 -3
                    3195 -2 55 -26z"
            />
            <path
              d="M7707 8298 c-16 -12 -17 -92 -15 -1208 l3 -1195 515 0 515 0 3 1199
                    c2 953 0 1201 -10 1208 -7 4 -234 8 -504 8 -377 0 -494 -3 -507 -12z"
            />
          </g>
        </svg>
      </span>
      <span class="for_hover_gray_btn"
        ><svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4L4 12"
            stroke="#47505B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4 4L12 12"
            stroke="#47505B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bg_gray", "ticket"],
  data() {
    return {
      edit: false,
      playOrPause: false,
    };
  },
  methods: {
    editTicket() {
      this.edit = !this.edit;
    },
    changePlayPause() {
      this.playOrPause = !this.playOrPause;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg_gray {
  background: #f5f7f9;
}
.wr_ticket_params {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  .wr_actions {
    width: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e6eaf0;
      border-radius: 2px;
    }
  }
  .sold span,
  .status,
  .ticket_type {
    color: #071526;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    font-family: HelveticaNeueBold;
  }
  .ticket_type {
    width: 155px;
  }
  .status {
    width: 55px;
  }
  .sold,
  .price,
  .total {
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #47505b;
  }
}
</style>