<template>
  <div class="container_xxl">
    <div class="container-fluid ">
      <div class="row">
        <div class="col ">
          <h1 class="Page_title">{{ header ? "EXPLORE" : "" }}</h1>
        </div>
      </div>
    </div>
    <div class="content discover ">
      <div class="row">
        <div class="col">
          <div
            class=" page-filter d-flex d-flex flex-row align-items-center p-r-20"
          >
            <div class="back-link">
              <a
                @click="goBack()"
                class=" d-flex flex-row align-items-center cursor-pointer"
              >
                <div class="svg-icon">
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                      stroke="#071526"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                {{ pageFrom }}
              </a>
            </div>
            <div class="filter-title">
              {{ pageTitle }}
            </div>
            <div
              class="filter-update-text"
              v-if="getChannelName === 'popular-labels'"
            >
              UPDATED DAILY
            </div>
            <div class="filter-update-text" v-if="titleDescription">
              {{ titleDescription }}
            </div>
            <div class="d-flex flex-wrap align-items-center ml-auto">
              <div
                class="text with-icon d-flex justify-content-between justify-content-end align-items-center"
              >
                Sort
                <div class="svg-icon">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="svg-filter-new"
                  >
                    <rect width="16" height="1.87013" fill="#8B949F"></rect>
                    <rect
                      y="5.61035"
                      width="13.1765"
                      height="1.87013"
                      fill="#8B949F"
                    ></rect>
                    <rect
                      y="11.2207"
                      width="9.41176"
                      height="1.87013"
                      fill="#8B949F"
                    ></rect>
                  </svg>
                </div>
              </div>
              <div
                class="dropdown bootstrap-select show-tick filters-select for-overview"
              >
                <button
                  type="button"
                  class="btn dropdown-toggle btn-light bs-placeholder"
                  data-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="Featured"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">
                        {{ filterApplied }}
                      </div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu ">
                  <div
                    class="inner show"
                    role="listbox"
                    id="bs-select-1"
                    tabindex="-1"
                    aria-multiselectable="true"
                  >
                    <ul class="dropdown-menu inner show" role="presentation">
                      <li
                        v-for="(category, index) in sortedByCategories"
                        :key="index"
                      >
                        <a
                          @click="
                            selectOrUnSelectValue(
                              category.value,
                              'filterApplied'
                            )
                          "
                          :class="{
                            'active selected': isSelectedValue(
                              category.value,
                              'filterApplied'
                            ),
                          }"
                          role="option"
                          class="dropdown-item"
                          id="bs-select-5-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class=" bs-ok-default check-mark"></span
                          ><span class="text">{{ category.name }}</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="js-container">
        <div class=" sale-items m-t-10 mb-4">
          <div class="position-relative">
            <!-- Popular Lables card design -->
            <div class="row" v-if="getChannelName === 'popular-labels'">
              <PopularLabels :allData="allData" />
            </div>

            <!-- All other designs -->
            <div class="row" v-else>
              <div
                class="col-sm-2 js-container-hover"
                v-for="(row, index) in allData"
                :key="index"
              >
                <PageOrArtistCard
                  :item="row"
                  :type="row.reviewable ? 'review' : 'song'"
                />
              </div>
            </div>

            <div class="col-12 mt-2 text-center" v-if="loading">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="totalRows > allData.length">
        <div class="col-12">
          <div class="show-more musicbar_more mt-0">
            <a
              href="javascript:;"
              @click="GetChannelData(getChannelName, true)"
            >
              <span class="show_more_bg">
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW MORE
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { getPageData } from "../../../../apis/APIs";
import PageOrArtistCard from "@/components/Common/UiComponenets/PageOrArtistCard.vue";
import { mapActions, mapGetters } from "vuex";
import PopularLabels from "../../../Common/UiComponenets/Music/PopularLabels.vue";

export default {
  name: "ArtistOrPageChannelWithCards",
  data() {
    return {
      allData: [],
      loading: false,
      dataLoaded: false,
      limit: 6,
      offset: 0,
      totalRows: 0,
      filterApplied: "trending",
      sortedByCategories: [
        { value: "trending", name: "Trending" },
        { value: "latest", name: "Latest" },
        { value: "featured", name: "Featured" },
        { value: "low_rating", name: "Low Rating" },
        { value: "high_rating", name: "High Rating" },
        { value: "top_selling", name: "Top Selling" },
      ],
    };
  },

  components: {
    PageOrArtistCard,
    PopularLabels
},
  props: {
    title: String,
    redirectUrl: String,
    activeHeader: String,
    thirdTxt: String,
    pageFilter: {
      type: String,
      default: "",
    },
    header: Boolean,
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    pageFrom() {
      const { fromPage } = this.$route.query;
      if (fromPage) {
        return fromPage;
      }
      return "Back";
    },
    pageTitle() {
      const { title } = this.$route.query;
      if (title) {
        return title;
      } else if (this.getChannelName) {
        return this.ChannelTitle;
      }
      return "Title";
    },
    titleDescription() {
      const { description } = this.$route.query;
      return description ? description : null;
    },
    getChannelName() {
      const { channelName } = this.$route.params;
      return channelName ? channelName : null;
    },
    ChannelTitle() {
      const { channelName } = this.$route.params;
      if (channelName) {
        return channelName.replaceAll("-", " ");
      }
      return "Title";
    },
    selectedSortByFilter() {
      if (this.filterApplied) {
        const index = this.sortedByCategories.findIndex(
          (e) => e.value === this.filterApplied
        );
        return this.sortedByCategories[index].name;
      }
      return "Sort by";
    },
  },
  mounted: function() {
    if (localStorage.getItem("componentType")) {
      this.componentType = localStorage.getItem("componentType");
    }
    console.log(this.getChannelName, " hello channel name here ...");
    if (this.getChannelName) {
      this.GetChannelData(this.getChannelName);
    }
  },
  watch: {
    filterApplied: {
      handler(val) {
        this.GetChannelData(this.getChannelName, false, true);
      },
    },
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    isSelectedValue(value, type) {
      return this.$data[type] === value;
    },
    selectOrUnSelectValue(value, type) {
      if (this.$data[type]) {
        this.$data[type] = this.$data[type] === value ? null : value;
      } else {
        this.$data[type] = value;
      }
    },
    async GetChannelData(channelName, showMore = false, reset = false) {
      try {
        this.loading = true;
        if (reset) {
          this.offset = 0;
          this.totalRows = 0;
          this.allData = [];
        }
        if (showMore) {
          this.offset = parseInt(this.offset) + this.limit;
        }
        let { data } = await getPageData(
          channelName,
          this.limit,
          this.offset,
          this.filterApplied
        );
        this.allData = showMore
          ? [...this.allData, ...data.channels.center.objects.data]
          : data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    redirect: function(url) {
      this.$router.push({ name: url });
    },

    async updateFilter(currentFilter) {
      this.filterApplied = currentFilter;
      try {
        this.loading = true;
        this.offset = 0;
        let data = await getPageData(
          this.$route.name ? this.$route.name : null,
          this.limit,
          this.offset,
          this.filterApplied
        );
        this.allData = data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
