<template lang="">
    <li @click="submit">
        <span>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.762 3.28092C15.3695 2.85749 14.9036 2.52159 14.3909 2.29243C13.8781 2.06326 13.3285 1.94531 12.7735 1.94531C12.2184 1.94531 11.6688 2.06326 11.1561 2.29243C10.6433 2.52159 10.1774 2.85749 9.78493 3.28092L8.96999 4.1601L8.15505 3.28092C7.35707 2.45434 6.29053 1.99816 5.18432 2.01029C4.07811 2.02242 3.02039 2.50188 2.23814 3.34579C1.45589 4.1897 1.01146 5.33079 1.00022 6.5242C0.988977 7.7176 1.41182 8.8682 2.17801 9.72908L2.99295 10.6083L8.96999 17.0564L14.947 10.6083L15.762 9.72908C16.1545 9.30572 16.4658 8.80308 16.6782 8.24989C16.8907 7.6967 17 7.10378 17 6.505C17 5.90622 16.8907 5.31331 16.6782 4.76011C16.4658 4.20692 16.1545 3.70429 15.762 3.28092V3.28092Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        <p>{{
    temp_reaction_count
  }}</p>
      </li>

</template>
<script>
import {
  post_comment_api,
  post_comment_form_data_api,
  create_post_comment_api,
  create_post_like_api
} from '../../../../apis/APIs';
import {mapGetters} from 'vuex';
import moment from 'moment';
import {VueChatEmoji} from 'vue-chat-emoji'
import CommentItem from './CommentItem'

require("vue-chat-emoji/dist/vue-chat-emoji.min.css");

export default {
  data() {
    return ({
      like_loading: false,
      temp_reaction_count: 0,
      temp_user_reaction: "test",

      message: "",
      success_message: ""
    })
  },
  props: ["item", "transparent"],
  watch: {
    reaction_count(value) {
      this.temp_reaction_count = value;
    },
    user_reaction(value) {
      this.temp_user_reaction = value;
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const {post_id = ""} = this.item || {};

      return post_id;
    },
    user_reaction() {
      const {post_reaction = null} = this.item || {};

      return post_reaction;
    },
    reaction_count() {
      const {reaction_count = ""} = this.item || {};

      return reaction_count;
    }
  },
  methods: {
    init() {
      this.temp_reaction_count = this.reaction_count;
      this.temp_user_reaction = this.user_reaction;
    },
    async submit() {

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      try {
        var formdata = new FormData();
        formdata.append("page_action", this.temp_user_reaction === "like" ? "3" : "1");
        formdata.append("post_id", this.id);
        formdata.append("user_id", user_id);
        formdata.append("reaction", "like");
        formdata.append("time", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        formdata.append("points_earned", "0");

        this.like_loading = true;
        this.message = "";

        const {success, message} = await create_post_like_api(formdata);
        this.like_loading = false;

        if (success === 1) {
          this.success_message = message;

          this.temp_reaction_count = this.temp_user_reaction === "like" ? parseInt(this.temp_reaction_count) - 1 : parseInt(this.temp_reaction_count) + 1;
          this.temp_user_reaction = this.temp_user_reaction === "like" ? null : "like";

          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

      } catch (error) {
        console.log("error ===> ", error);
        this.like_loading = false;
      }
    }
  }
}
</script>
<style lang="">

</style>
