<template>
  <div class="about-work work-tab">
    <div class="row">
      <div class="col-12">
        <div class="total-projects">
          <h1>Job history</h1>
          <span>6</span>
        </div>
      </div>
      <div class="col-12">
        <div class="job-history-detail">
          <div class="job-history-detail-description">
            <div class="job-heading">Need to mix a song from the new album of a popular group in United
              States
            </div>
            <div class="job-feedback">No Feedback Given</div>
            <div class="job-salary-detail">
              <div class="job-price">2,400 USD</div>
              <span class="dot"></span>
              <div class="job-price">Fixed Price</div>
              <span class="dot"></span>
              <div class="job-price">June 2020 – July 2020</div>
            </div>
          </div>
        </div>
        <div class="job-history-detail">
          <div class="job-history-detail-description">
            <div class="job-heading">Need interns-virtual assistance for Music Label-Agency</div>
            <div class="job-feedback-star">
              <ul>
                <li><img :src="require(`@/assets/img/star-rate.png`)"/></li>
                <li><img :src="require(`@/assets/img/star-rate.png`)"/></li>
                <li><img :src="require(`@/assets/img/star-rate.png`)"/></li>
                <li><img :src="require(`@/assets/img/star-rate.png`)"/></li>
                <li><img :src="require(`@/assets/img/star-rate.png`)"/></li>
              </ul>
              <span>4,59</span>
            </div>
            <div class="job-salary-detail">
              <div class="job-price">30 USD / hr</div>
              <span class="dot"></span>
              <div class="job-price">Hourly</div>
              <span class="dot"></span>
              <div class="job-price">September 2019 – June 2020</div>
            </div>
          </div>
        </div>
        <div class="job-history-detail">
          <div class="job-history-detail-description">
            <div class="job-heading">Creating music covers- need music arrangement, mixing and mastering
            </div>
            <div class="job-feedback">No Feedback Given</div>
            <div class="job-salary-detail">
              <div class="job-price">2,400 USD</div>
              <span class="dot"></span>
              <div class="job-price">Fixed Price</div>
              <span class="dot"></span>
              <div class="job-price">June 2020 – July 2020</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show-all-list"><a href="javascript:;">SHOW 3 MORE</a></div>
  </div>
</template>

<script>
export default {
  name: "JobHistory"
}
</script>

<style scoped lang="scss">

</style>
