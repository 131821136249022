<template>
  <div class="container_xxl_video">
    <div class="row">
      <div class="col-8">
        <single-post-video :item="single_post" />
      </div>
      <div class="col-4">
        <single-post-for-image :item="single_post" :index="0" :comment_check="true"/>
      </div>
    </div>
  </div>
</template>


<script type="application/javascript">
import {mapActions, mapGetters, mapMutations} from "vuex";
import * as Helper from "../../apis/Helper.js";
import * as Utils from "../utils/Utils";
import {post_api} from '../../apis/APIs';
import PostDetail from './NewsFeed/PostItem/PostDetail';
import Social from './NewsFeed/PostItem/Social';
import PostLike from './NewsFeed/PostItem/PostLike';
import PostLikeTransparent from './NewsFeed/PostItem/PostLikeTransparent';
import AppConfig from '../../configs/AppConfig';
import CommentList from './NewsFeed/PostItem/PostIVCommentList';
import {SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS} from '../../store/types';
import SinglePostVideo from "./NewsFeed/Posts/SinglePostVideo";
import SinglePostForImage from "./NewsFeed/Posts/SinglePostForImage";

export default {
  data() {
    return {
      message: "",
      register_error: false,
      post_detail: {},
      post_detail_loading: false,
      comment_count: 0,
      item: {
        post_id: 7
      },

      write_comment: false,
      is_drop_down_open: false,

      edit_post: "",
      is_edit_post: false,
      edit_post_loading: false,

      is_edit_poll_vote_local: false,

      selected_poll_option: undefined,
      change_vote_count: 0,

      selected_image_index: 0

    };
  },
  mounted() {
    const {ref} = this.$route.query;

    this.init();

    this.updateSystemDataUIConstraints({
      [SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS]: 'custom_comainer'
    });
    this.$root.$emit('music_footer', false);
  },
  beforeDestroy() {
    this.updateSystemDataUIConstraints({
      [SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS]: ''
    })
  },
  destroyed() {
    this.$root.$emit('music_footer', true);
  },
  components: {
    SinglePostVideo,
    SinglePostForImage,
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    single_post() {
      return this.$store.getters.getSinglePost
    },
  },
  methods: {
    ...mapMutations([
      "updateSignupFormData",
      "updateSignupUIConstraints",
      "updateSystemDataUIConstraints"
    ]),
    async init() {
      try {
        const {id} = this.$route.params;

        const {user_data} = this.user || {};
        const {user_id} = user_data || {};

        this.post_detail_loading = true;

        const query = `?action=5&user_id=${user_id}&post_id=${id}`;
        const {status, message, data} = await post_api(query);
        this.post_detail_loading = false;

        this.post_detail = data && data.post_id ? data : {};

        if (this.post_detail && this.post_detail.post_id) {
          this.comment_count = this.post_detail.comments;
        }

      } catch (error) {
        this.post_detail_loading = false;
      }
    },
    increment_comment_count(value) {
      this.comment_count = value;
    },
    goBack() {
      const {id} = this.$route.params;

      if (this.selected_image_index > 0) return this.selected_image_index -= 1;

      this.$router.replace(`/home/page/post-detail/${id}`);
    },
    nextImage() {
      this.selected_image_index += parseInt(this.selected_image_index) + 1;
    }
  },
};
</script>
