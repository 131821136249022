<template>
  <div class="">
    <about-section :userData="user_data" :user_experience="this.experience_data"/>
    <Experience
      v-if="checkIfPageOwnerIsYourFriend(user_data.id) || !user_data.who_can_see_your_work_info"
      :edit-able="false" :typeCheck="true" @getExperience="setExperience"/>
    <Education
      v-if="checkIfPageOwnerIsYourFriend(user_data.id) || !user_data.who_can_see_your_education_info"
      :edit-able="false" :typeCheck="true"/>
    <social-links :userData="user_data"/>
  </div>
</template>

<script>
import aboutSection from "@/components/Home/Profile/about/aboutSection.vue";
import socialLinks from "@/components/Home/Profile/about/socialLinks.vue";
import Experience from "@/components/PageManager/Pages/Components/TabComponents/Experience.vue";
import Education from "@/components/PageManager/Pages/Components/TabComponents/Education.vue";
import {mapActions} from "vuex";

export default {
  name: "aboutMainPage",
  data() {
    return {
      experience_data: {}
    }
  },
  props:{
    user_data:{
      type:Object,
      default:null,
      required:true
    }
  },
  components: {
    aboutSection,
    socialLinks,
    Experience,
    Education
  },
  computed:{
    followersCount(){
       return (this.user_data && this.user_data.follower_count) ? String(this.user_data.follower_count) : '0'
    },
    followingCount(){
       return (this.user_data && this.user_data.following_count) ? String(this.user_data.following_count) : '0'
    }
  },
  methods: {
    ...mapActions([
      "fetchUserData"
    ]),
    change_active_tab: function (tab) {
      //   alert(tab);
      this.active_sub_tab = tab;
      window.scrollTo(0, 0);
    },
    setExperience(experience_data) {
      this.experience_data = experience_data
    }
  }
}
</script>

<style scoped>

</style>
