<template>
  <div class="container-fluid m-t-50">
    <h6>REVIEWS</h6>
    <div class="row filter-options open">
      <div class="col">
        <div class="filter-inner">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex flex-wrap align-items-center">
              <div class="text sort-title">
                Sort
              </div>
              <div class="svg-icon m-r-20">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="svg-filter-new">
                  <rect width="16" height="1.87013" fill="#8B949F"></rect>
                  <rect y="5.61035" width="13.1765" height="1.87013" fill="#8B949F"></rect>
                  <rect y="11.2207" width="9.41176" height="1.87013" fill="#8B949F"></rect>
                </svg>
              </div>
              <div class="dropdown bootstrap-select show-tick filters-select f-s">
                <select class="filters-select f-s selectpicker" title="Latest Reviews" multiple="" data-max-options="1"
                        dropupauto="false" tabindex="-98">
                  <option value="0">Latest Reviews</option>
                  <option value="1">Oldest Reviews</option>
                  <option value="2">Highest Rating</option>
                  <option value="3">Lowest Rating</option>
                </select>
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-20" aria-haspopup="listbox" aria-expanded="false"
                        title="Latest Reviews">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">Latest Reviews</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu" x-placement="bottom-start"
                     style="max-height: 174px; overflow: hidden; min-height: 118px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                  <div class="inner show" role="listbox" id="bs-select-20" tabindex="-1" aria-multiselectable="true"
                       style="max-height: 156px; overflow-y: auto; min-height: 100px;">
                    <ul class="dropdown-menu inner show" role="presentation"
                        style="margin-top: 0px; margin-bottom: 0px;">
                      <li><a role="option" class="dropdown-item" id="bs-select-20-0" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Latest Reviews</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-20-1" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Oldest Reviews</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-20-2" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Highest Rating</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-20-3" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Lowest Rating</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select class="filters-select f-s selectpicker" multiple="" data-max-options="1" title="All Formats"
                      tabindex="-98">
                <option value="1">Albums</option>
                <option value="2">Singles</option>
                <option value="3">CD’s</option>
                <option value="4">Cassettes</option>
                <option value="5">Vinyl</option>
                <option value="6">Sample Packs</option>
              </select>
              <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                      role="combobox" aria-owns="bs-select-21" aria-haspopup="listbox" aria-expanded="false"
                      title="All Formats">
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">All Formats</div>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="max-height: 175px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                <div class="inner show" role="listbox" id="bs-select-21" tabindex="-1" aria-multiselectable="true"
                     style="max-height: 157px; overflow-y: auto; min-height: 100px;">
                  <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                    <li><a role="option" class="dropdown-item" id="bs-select-21-0" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Albums</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-21-1" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Singles</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-21-2" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                        class="text">CD’s</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-21-3" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Cassettes</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-21-4" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Vinyl</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-21-5" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Sample Packs</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select class="filters-select f-s selectpicker" title="Release period" multiple="" data-max-options="1"
                      tabindex="-98">
                <option value="1">Today</option>
                <option value="2">last 7 days</option>
                <option value="3">last 14 days</option>
                <option value="4">last 30 days</option>
                <option value="5">last 90 days</option>
              </select>
              <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                      role="combobox" aria-owns="bs-select-22" aria-haspopup="listbox" aria-expanded="false"
                      title="Release period">
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">Release period</div>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="max-height: 175px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                <div class="inner show" role="listbox" id="bs-select-22" tabindex="-1" aria-multiselectable="true"
                     style="max-height: 157px; overflow-y: auto; min-height: 100px;">
                  <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                    <li><a role="option" class="dropdown-item" id="bs-select-22-0" tabindex="0" aria-selected="false"
                           aria-setsize="5" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Today</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-22-1" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 7 days</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-22-2" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 14 days</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-22-3" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 30 days</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-22-4" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">last 90 days</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="for-calendar-input f-s">
              <div class="flatpickr-wrapper">
                <input readonly="readonly" placeholder="Week of Release"
                       class="filters-select js-datepicker-week form-control datepicker-week custom-caljs flatpickr-input"
                       type="text">
                <div class="flatpickr-calendar rangeMode animate static arrowTop" tabindex="-1">
                  <div class="flatpickr-months">
                              <span class="flatpickr-prev-month">
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                      width="29" height="29" viewBox="0 0 29 29">
                                    <defs>
                                       <path id="vb2fa"
                                             d="M1087.5 7294c8.008 0 14.5 6.492 14.5 14.5s-6.492 14.5-14.5 14.5-14.5-6.492-14.5-14.5 6.492-14.5 14.5-14.5z"></path>
                                       <path id="vb2fc" d="M1088.5 7304.5l-3.603 3.833 3.603 3.757"></path>
                                       <clipPath id="vb2fb">
                                          <use fill="#fff" xlink:href="#vb2fa"></use>
                                       </clipPath>
                                    </defs>
                                    <g id="svg-prevMonth">
                                       <g transform="translate(-1073 -7294)">
                                          <use class="ccls-1" fill="#fff" fill-opacity="0" stroke="#e1e4e8"
                                               stroke-miterlimit="50" stroke-width="2"
                                               clip-path="url(&quot;#vb2fb&quot;)" xlink:href="#vb2fa"></use>
                                       </g>
                                       <g transform="translate(-1073 -7294)">
                                          <use class="ccls-2" fill="#fff" fill-opacity="0" stroke="#616375"
                                               stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="50"
                                               stroke-width="1.5" xlink:href="#vb2fc"></use>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                    <div class="flatpickr-month">
                      <div class="flatpickr-current-month">
                        <span class="cur-month">March </span>
                        <div class="numInputWrapper"><input class="numInput cur-year" type="number" tabindex="-1"
                                                            aria-label="Year"><span class="arrowUp"></span><span
                            class="arrowDown"></span></div>
                      </div>
                    </div>
                    <span class="flatpickr-next-month">
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                      width="29" height="29" viewBox="0 0 29 29">
                                    <defs>
                                       <path id="vlooa"
                                             d="M1274.5 7294c8.008 0 14.5 6.492 14.5 14.5s-6.492 14.5-14.5 14.5-14.5-6.492-14.5-14.5 6.492-14.5 14.5-14.5z"></path>
                                       <path id="vlooc" d="M1273.5 7312.09l3.603-3.833-3.603-3.757"></path>
                                       <clipPath id="vloob">
                                          <use fill="#fff" xlink:href="#vlooa"></use>
                                       </clipPath>
                                    </defs>
                                    <g id="svg-nextArrow">
                                       <g transform="translate(-1260 -7294)">
                                          <use class="ccls-1" fill="#fff" fill-opacity="0" stroke="#e1e4e8"
                                               stroke-miterlimit="50" stroke-width="2"
                                               clip-path="url(&quot;#vloob&quot;)" xlink:href="#vlooa"></use>
                                       </g>
                                       <g transform="translate(-1260 -7294)">
                                          <use class="ccls-2" fill="#fff" fill-opacity="0" stroke="#616375"
                                               stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="50"
                                               stroke-width="1.5" xlink:href="#vlooc"></use>
                                       </g>
                                    </g>
                                 </svg>
                              </span>
                  </div>
                  <div class="flatpickr-innerContainer">
                    <div class="flatpickr-rContainer">
                      <div class="flatpickr-weekdays">
                        <div class="flatpickr-weekdaycontainer">
                                       <span class="flatpickr-weekday">
                                       Mon</span><span class="flatpickr-weekday">Tue</span><span
                            class="flatpickr-weekday">Wed</span><span class="flatpickr-weekday">Thu</span><span
                            class="flatpickr-weekday">Fri</span><span class="flatpickr-weekday">Sat</span><span
                            class="flatpickr-weekday">Sun
                                       </span>
                        </div>
                      </div>
                      <div class="flatpickr-days" tabindex="-1">
                        <div class="dayContainer"><span class="flatpickr-day " aria-label="March 1, 2021" tabindex="-1">1</span><span
                            class="flatpickr-day " aria-label="March 2, 2021" tabindex="-1">2</span><span
                            class="flatpickr-day " aria-label="March 3, 2021" tabindex="-1">3</span><span
                            class="flatpickr-day " aria-label="March 4, 2021" tabindex="-1">4</span><span
                            class="flatpickr-day today" aria-label="March 5, 2021" aria-current="date"
                            tabindex="-1">5</span><span class="flatpickr-day " aria-label="March 6, 2021" tabindex="-1">6</span><span
                            class="flatpickr-day " aria-label="March 7, 2021" tabindex="-1">7</span><span
                            class="flatpickr-day " aria-label="March 8, 2021" tabindex="-1">8</span><span
                            class="flatpickr-day " aria-label="March 9, 2021" tabindex="-1">9</span><span
                            class="flatpickr-day " aria-label="March 10, 2021" tabindex="-1">10</span><span
                            class="flatpickr-day " aria-label="March 11, 2021" tabindex="-1">11</span><span
                            class="flatpickr-day " aria-label="March 12, 2021" tabindex="-1">12</span><span
                            class="flatpickr-day " aria-label="March 13, 2021" tabindex="-1">13</span><span
                            class="flatpickr-day " aria-label="March 14, 2021" tabindex="-1">14</span><span
                            class="flatpickr-day " aria-label="March 15, 2021" tabindex="-1">15</span><span
                            class="flatpickr-day " aria-label="March 16, 2021" tabindex="-1">16</span><span
                            class="flatpickr-day " aria-label="March 17, 2021" tabindex="-1">17</span><span
                            class="flatpickr-day " aria-label="March 18, 2021" tabindex="-1">18</span><span
                            class="flatpickr-day " aria-label="March 19, 2021" tabindex="-1">19</span><span
                            class="flatpickr-day " aria-label="March 20, 2021" tabindex="-1">20</span><span
                            class="flatpickr-day " aria-label="March 21, 2021" tabindex="-1">21</span><span
                            class="flatpickr-day " aria-label="March 22, 2021" tabindex="-1">22</span><span
                            class="flatpickr-day " aria-label="March 23, 2021" tabindex="-1">23</span><span
                            class="flatpickr-day " aria-label="March 24, 2021" tabindex="-1">24</span><span
                            class="flatpickr-day " aria-label="March 25, 2021" tabindex="-1">25</span><span
                            class="flatpickr-day " aria-label="March 26, 2021" tabindex="-1">26</span><span
                            class="flatpickr-day " aria-label="March 27, 2021" tabindex="-1">27</span><span
                            class="flatpickr-day " aria-label="March 28, 2021" tabindex="-1">28</span><span
                            class="flatpickr-day " aria-label="March 29, 2021" tabindex="-1">29</span><span
                            class="flatpickr-day " aria-label="March 30, 2021" tabindex="-1">30</span><span
                            class="flatpickr-day " aria-label="March 31, 2021" tabindex="-1">31</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 1, 2021" tabindex="-1">1</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 2, 2021" tabindex="-1">2</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 3, 2021" tabindex="-1">3</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 4, 2021" tabindex="-1">4</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 5, 2021" tabindex="-1">5</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 6, 2021" tabindex="-1">6</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 7, 2021" tabindex="-1">7</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 8, 2021" tabindex="-1">8</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 9, 2021" tabindex="-1">9</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 10, 2021" tabindex="-1">10</span><span
                            class="flatpickr-day nextMonthDay" aria-label="April 11, 2021" tabindex="-1">11</span></div>
                      </div>
                    </div>
                  </div>
                  <div class="flatpickr-checks">
                    <div class="btn btn-calendar js-submit-date dropdown-item" title="toggle" data-toggle="">Done</div>
                  </div>
                </div>
              </div>
              <div class="svg-icon">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1 c-fill-1" opacity="0.7" d="M10.7695 1L6.09052 5.75638L0.999512 1"
                        stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <div class="svg-icon"></div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select class="filters-select f-s selectpicker" title="Global" multiple="" data-max-options="1"
                      tabindex="-98">
                <option value="1">Global</option>
                <option value="2">Australia</option>
                <option value="3">Brazil</option>
                <option value="4">Canada</option>
                <option value="5">France</option>
                <option value="6">Germany</option>
                <option value="7">Greece</option>
                <option value="8">Italy</option>
                <option value="9">Japan</option>
                <option value="10">Malaysia</option>
                <option value="11">Netherlands</option>
                <option value="12">Russian Federation</option>
                <option value="13">South Africa</option>
                <option value="14">Spain</option>
                <option value="15">Sweden</option>
                <option value="16">Thailand</option>
                <option value="17">United Kingdom</option>
                <option value="18">United States</option>
              </select>
              <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                      role="combobox" aria-owns="bs-select-23" aria-haspopup="listbox" aria-expanded="false"
                      title="Global">
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">Global</div>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu" x-placement="bottom-start"
                   style="max-height: 175px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
                <div class="inner show" role="listbox" id="bs-select-23" tabindex="-1" aria-multiselectable="true"
                     style="max-height: 157px; overflow-y: auto; min-height: 100px;">
                  <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                    <li><a role="option" class="dropdown-item" id="bs-select-23-0" tabindex="0" aria-selected="false"
                           aria-setsize="18" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Global</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-1" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Australia</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-2" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Brazil</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-3" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Canada</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-4" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">France</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-5" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Germany</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-6" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Greece</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-7" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Italy</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-8" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Japan</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-9" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Malaysia</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-10" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Netherlands</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-11" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Russian Federation</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-12" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">South Africa</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-13" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                        class="text">Spain</span></a></li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-14" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Sweden</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-15" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Thailand</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-16" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">United Kingdom</span></a>
                    </li>
                    <li><a role="option" class="dropdown-item" id="bs-select-23-17" tabindex="0"
                           aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">United States</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
//     import flatPickr from 'vue-flatpickr-component';
//     import 'flatpickr/dist/flatpickr.css';
//     import 'flatpickr/dist/themes/material_blue.css';
//   // localization is optional
//   import {Hindi} from 'flatpickr/dist/l10n/hi.js';
export default {
  data() {
    return {
      date: '2020-10-16',
      // Get more form https://flatpickr.js.org/options/

    }
  },
  components: {},
  computed: {},
  methods: {
    doSomethingOnChange: function () {

    }
  }
}
</script>
