<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div class="forums-item m-t-30 my-th">
          <div
            class="item-content for_height_item-content"
            v-for="thread in THREADS.data"
            :key="thread.id"
          >
            <div class="forums_name_in_my_thread">
              <div>
                <a
                  @click="
                    goTo({
                      active_tab: 'home',
                    })
                  "
                  >Forum:<span style="cursor: pointer">{{
                    thread.forum.name
                  }}</span></a
                >
              </div>
              <span>{{ dateFormat(thread.forum.updated_at) }}</span>
            </div>
            <div class="item-content-row">
              <div class="item-desc" style="margin: unset">
                <div
                  @click="
                    goTo({
                      active_tab: 'thread_reply',
                      thread_id: thread.id,
                      title: thread.title,
                    })
                  "
                  class="item-inner-title"
                >
                  <a>{{
                    thread.title.length > 35
                      ? thread.title.slice(0, 35) + "..."
                      : thread.title
                  }}</a>
                </div>
                <div class="item-inner-subtitle with-name">
                  by <a
                  @click="redirectWidgetsToProfilePage($event, thread.author_details.username)"
                >{{ thread.author_details.full_name }}</a
                  ><i>.</i>{{ dateFormat(thread.created_at) }}
                </div>
              </div>
              <div class="ml-auto">
                <div class="my_threads_replies_view">
                  <div>
                    <span class="res_replies"
                      >Replies<span>{{ thread.replies_count }}</span></span
                    >
                  </div>
                  <div>
                    <span class="res_view"
                      >Views <span>{{ thread.views }}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            :count="THREADS.total"
            :actionName="
              type == 'search_threads' ? 'getSearchForumResult' : 'getMyThread'
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Pagination from "@/components/Common/Elements/Pagination.vue";
import forum from "@/mixins/forum.js";
export default {
  mixins: [forum],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
    };
  },
  components: {
    Pagination,
  },
  props: {
    type: String,
    THREADS: Object,
  },
  methods: {
    ...mapMutations([
      "changeThreadId",
      "changeSubPath2",
      "changeSubPath3",
      "changeTypeForumThreads",
    ]),
    ...mapActions(["getMyThread", "getThread", "getSearchForumResult"]),
    async goTo(params) {
      this.changeThreadId(params.thread_id);
      if (params.active_tab === "thread_reply") {
        await this.getThread({ thread_id: params.thread_id, offset: 0 });
        this.changeSubPath3(params.title);
        this.changeSubPath2(this.GET_THREAD_DATA.forum.name);
      }
      this.type == "search_threads"
        ? this.$parent.$parent.changePage(params.active_tab)
        : this.$parent.changePage(params.active_tab);
    },
  },
  computed: {
    ...mapGetters(["GET_THREAD_DATA", "GET_SEARCH_KEYWORD"]),
  },
};
</script>

<style lang="scss" scoped>
.for_height_item-content {
  height: 70px;
}
.item-inner-subtitle {
  display: flex;
  i {
    margin: -3px 5px 0 !important;
  }
}
.replies {
  margin-left: 22px;
}
.threads {
  display: flex;
  justify-content: flex-end;
}
.views {
  margin-left: 20px;
}
.item-content-row {
  padding: 18px 19px;
}
.forums_name_in_my_thread {
  display: flex;
  justify-content: space-between;
  a {
    font-family: HelveticaNeueBold;
    color: #D1433A !important;
    span {
      color: #47505b;
      margin-left: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  span {
    color: #8b949f;
    font-family: HelveticaNeueNormal;
  }
}
</style>
