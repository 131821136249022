<template>
  <div class="buy-stream-toggle-switch mr-3">
    <b-button-group size="sm" class="button-group">
      <b-button
        :variant="isSelling ? 'primary' : 'outline-primary'"
        @click="setBuyStreamState(true)"
        class="buy-switch"
        :class="{ active: !isSelling }"
      >
        Buy
      </b-button>
      <b-button
        :variant="!isSelling ? 'primary' : 'outline-primary'"
        @click="setBuyStreamState(false)"
        class="stream-switch m-0"
        :class="{ active: isSelling }"
      >
        Stream
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  name: "payStreamToggleSwitch",
  watch: {
    buy(val) {
      this.$emit("buyState", val);
    },
  },
  data() {
    return {
      buy: true,
    };
  },
  methods: {
    setBuyStreamState(val) {
      this.$store.commit("SET_MUSIC_MODE", !val);
    },
  },
  computed: {
    isSelling() {
      return !this.$store.getters.isMusicModeIsStreaming;
    },
  },
};
</script>

<style scoped lang="scss">
@import "/src/assets/style/music/UI-componsnts/buyStreamToggleSwitch.scss";
</style>
