<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div id="privacy" class="right-setting-bar settings-content active">
      <div class="set-block d-flex flex-column">
        <div class="setting-header">
          <div class="setting-header-inner d-flex align-items-center">
            <div class="svg-icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.66699 12.6667V2.55556L15.0003 1V11.1111" stroke="#47505B" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.33333 15C4.622 15 5.66667 13.9554 5.66667 12.6667C5.66667 11.378 4.622 10.3334 3.33333 10.3334C2.04467 10.3334 1 11.378 1 12.6667C1 13.9554 2.04467 15 3.33333 15Z"
                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M12.6663 13.4445C13.955 13.4445 14.9997 12.3998 14.9997 11.1112C14.9997 9.8225 13.955 8.77783 12.6663 8.77783C11.3777 8.77783 10.333 9.8225 10.333 11.1112C10.333 12.3998 11.3777 13.4445 12.6663 13.4445Z"
                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
            <p class="ml-3 mb-0">Audio Settings</p>
            <button type="button" class=" btn-view ml-auto" @click="redirectToProfilePage(username)">
              <span class="pr-2">
              <EyeIcon />
              </span>
              View Profile
            </button>
          </div>
          <div class="set-block-content d-flex flex-column privacy">

            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="#" role="tabpanel" aria-labelledby="activity-tab">
                <div v-if="message" class="alert alert-danger mx-5" role="alert">
                  {{ message }}
                </div>
                <div v-if="success_message" class="alert alert-success mx-5" role="alert">
                  {{ success_message }}
                </div>
                <div class="tab-item-wrapper">
                  <div class="tab-item d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <div class="item-title">Enable HD music streaming with cd-quality or better sound when possible.
                      </div>

                    </div>
                    <div class="switch-button">
                      <label class="switch ">
                        <input type="checkbox" v-model="enable_hd_music" class="default">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="tab-item-wrapper">
                  <div class="tab-item d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <div class="item-title">When I Log in, automatically restore my last songs.</div>
                    </div>
                    <div class="switch-button">
                      <label class="switch ">
                        <input type="checkbox" v-model="automatically_music" class="default">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="tab-item-wrapper">
                  <div class="tab-item d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <div class="item-title">Always remember my Shuffle setting.</div>
                    </div>
                    <div class="switch-button">
                      <label class="switch ">
                        <input type="checkbox" v-model="always_remember" class="default">
                        <span class="slider round">
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="tab-item-wrapper">
                  <div class="tab-item d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <div class="item-title">When I Play or Pause a song, fade the song in or out.</div>
                    </div>
                    <div class="switch-button">
                      <label class="switch ">
                        <input type="checkbox" v-model="play_or_pause" class="default">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="tab-item-wrapper">
                  <div class="tab-item d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <div class="item-title">Disable player keyboard shortcuts.</div>
                    </div>
                    <div class="switch-button">
                      <label class="switch ">
                        <input type="checkbox" v-model="disable_player" class="default">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="save-btn" @click="submit">
              {{
                loading ? "Please Wait..." : "Save Changes"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import { your_profile_privacy_api, your_profile_activity_api, audio_settings_api } from '../../../apis/APIs';
import { EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR } from '../../../store/types';
import * as SMStorage from "../../../apis/SMStorage";
import EyeIcon from '../../../assets/svgs/EyeIcon.vue';

export default {
  data() {
    return {
      enable_hd_music: false,
      automatically_music: false,
      user_id: false,
      always_remember: false,
      play_or_pause: false,
      disable_player: false,
      id: "",
      message: "",
      success_message: "",
      loading: false,
      fetch_data_loading: false
    }
  },
  components: {
    EyeIcon
  },
  computed: {
    user() {
      return this.$store.getters.user_map_to_props.user_data
    }
  },
  watch: {
    user() {
      console.log('Checking Watch');
      this.initData()
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async submit(e) {
      if (e) e.preventDefault();

      const { id } = this.user || {};

      let { enable_hd_music, automatically_music, always_remember, play_or_pause, disable_player, loading } = this;

      if (loading) return;

      try {
        this.loading = true;

        // let query = `?enable_hd_music=${Number(enable_hd_music)}&automatically_music=${Number(automatically_music)}&user_id=${id}&always_remember=${Number(always_remember)}&play_or_pause=${Number(play_or_pause)}&disble_player=${Number(disble_player)}`;

        // if(id){
        //    query = query+`&action=2&id=${id}`;
        // } else {
        //    query = query+`&action=1`;
        // }

        var formdata = new FormData();
        formdata.append("hd_streaming", Number(enable_hd_music));
        formdata.append("restore_queue", Number(automatically_music));
        formdata.append("persist_shuffle", Number(always_remember));
        formdata.append("play_pause_fade", Number(play_or_pause));
        formdata.append("disablePlayerShortcuts", Number(disable_player));

        const res = await audio_settings_api(formdata);

        const { success, message, data } = res.data || {};
        // const {id, hd_streaming, restore_queue, persist_shuffle, play_pause_fade, disablePlayerShortcuts} = data
        // const payload = { hd_streaming : hd_streaming, restore_queue : restore_queue, persist_shuffle : persist_shuffle, play_pause_fade : play_pause_fade, disablePlayerShortcuts : disablePlayerShortcuts }
        if (success === 1) {
          this.success_message = message;
          await SMStorage.storeSMLoginData(data);
          this.$store.commit("updateUserUIConstraints", ({
            [USER_DATA]: data
          }));
          this.initData();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

        this.loading = false;
      } catch (error) {
        console.log("error ===> ", error)
        this.loading = false;
      }
    },
    initData() {
      const { id, hd_streaming, restore_queue, persist_shuffle, play_pause_fade, disablePlayerShortcuts } = this.user || {};
      this.fetch_data_loading = true;
      this.enable_hd_music = hd_streaming == 1 ? true : false;
      this.automatically_music = restore_queue == 1 ? true : false;
      this.always_remember = persist_shuffle == 1 ? true : false;
      this.play_or_pause = play_pause_fade == 1 ? true : false;
      this.disable_player = disablePlayerShortcuts == 1 ? true : false;
      this.id = id;
    }
  }
}
</script>
