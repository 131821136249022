<template>
  <div class="w-100">
    <div class="container_xxl ff px-0">
      <div class="wrapper">
        <div class="row">
          <div class="col">
            <div class="overview-mains mt-0">
              <h1 class="Page_title gen-mood bo-botom-0">charts</h1>
              <div class="caleder-chart">
                <DateTimePicker @selectedValue="weekSelected"/>
              </div>
              <div class="switch-field chart-buy-stream">
                <payStreamSwitch/>
              </div>
            </div>
          </div>
        </div>

        <div class=" discover content">
          <div class=" gallery-card new-style charts">
            <div class="row">
              <div class="col-12">
                <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index">
                  <component v-bind:is="myComponentList[singleChannel.alt_name].component"
                             :cname="singleChannel.description" :myInd="index" :gridTitle="singleChannel.description"
                             :header="true" :redirectLink="'continue-listening-to'"
                             :listData="singleChannel.objects"
                             v-if="myComponentList[singleChannel.alt_name].component == 'TopCharts'"/>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <div class="new-releae-geners chart-gens">
                  <h1>Genres</h1>
                  <div class="gen_mooods ">
                    <ul>
                      <li @click="selectGenre($event,eachOp.id)" v-for="(eachOp, index) in genres" :key="index"><a
                          :class="{'active':isSelectedGenre(eachOp.id)}" href="javascript:;"><span
                          v-html="eachOp.name"></span></a></li>
                    </ul>
                    <div @click="clearGenreFilter" class="clear-history" v-if="selectedGenres.length>0">Clear all
                      history
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row options">
            <div class="col-md-12">
              <div class="btn btn-play-all" @click="playAll">
                <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="svg-play filled">
                  <path class="c-line-1"
                        d="M1.93569 11.9999C1.81909 11.998 1.70435 11.9704 1.59969 11.9189C1.48036 11.8487 1.37983 11.7506 1.30668 11.6331C1.23354 11.5155 1.18996 11.382 1.17969 11.2439V1.66692C1.18566 1.53314 1.22298 1.40262 1.28864 1.2859C1.3543 1.16917 1.44645 1.06951 1.55769 0.994924C1.67446 0.928694 1.80782 0.89744 1.94186 0.904887C2.0759 0.912333 2.20497 0.958167 2.31369 1.03692L9.66469 5.82492C9.76802 5.89435 9.85268 5.98813 9.91123 6.09799C9.96977 6.20786 10.0004 6.33044 10.0004 6.45492C10.0004 6.57941 9.96977 6.70199 9.91123 6.81185C9.85268 6.92172 9.76802 7.0155 9.66469 7.08492L2.31369 11.8739C2.20318 11.9529 2.07147 11.9968 1.93569 11.9999"
                        stroke="white" stroke-width="1.3"></path>
                </svg>
                PLAY ALL
              </div>
            </div>


          </div>

          <div class="  m-t-20 with-slider js-container-hover">
            <div class="row m-b-25">
              <div class="col-md-4">
                <h4 class="title-h4 ">Top 100 Played Songs</h4>
              </div>
              <div class="col-md-8">

                <div class="d-flex flex-wrap justify-content-end align-items-center h-100">
                  <div class="text with-icon d-flex justify-content-between justify-content-end align-items-center">
                    Sort
                    <div class="svg-icon">
                      <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.964844" width="9.51351" height="2.16216" rx="1.08108" fill="#D1433A"/>
                        <rect x="0.964844" y="5.18945" width="16" height="2.16216" rx="1.08108" fill="#D1433A"/>
                        <rect x="0.964844" y="10.3779" width="12.973" height="2.16216" rx="1.08108" fill="#D1433A"/>
                      </svg>

                    </div>
                  </div>
                  <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                    <select class="filters-select selectpicker for-overview" title="All Genres" multiple=""
                            data-max-options="1" dropupauto="false" tabindex="-98">
                      <option value="0">Classical</option>
                      <option value="1">Dance/ Electronic</option>
                      <option value="2">Hip-Hop/ Rap</option>
                      <option value="3">Indie/ Rock</option>
                      <option value="4">Pop</option>
                    </select>
                    <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                            role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false"
                            title="All Genres">
                      <div class="filter-option">
                        <div class="filter-option-inner">
                          <div class="filter-option-inner-inner">{{ selectedFilter|upperCaseFirstLetter }}</div>
                        </div>
                      </div>
                    </button>
                    <div class="dropdown-menu ">
                      <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true">
                        <ul class="dropdown-menu inner show" role="presentation">
                          <li><a :class="{'active selected ':selectedFilter === 'singles'}"
                                 @click="selectFilter('singles')" role="option" class="dropdown-item" id="bs-select-9-0"
                                 tabindex="0"
                                 aria-selected="false" aria-setsize="6" aria-posinset="1"><span
                              class=" bs-ok-default check-mark"></span><span class="text">Singles</span></a></li>
                          <li><a :class="{'active selected ':selectedFilter === 'albums'}" @click="selectFilter('albums')"
                                 role="option" class="dropdown-item" id="bs-select-9-1" tabindex="0"
                                 aria-selected="false" aria-setsize="6" aria-posinset="1"><span
                              class=" bs-ok-default check-mark"></span><span class="text">Albums</span></a></li>
                          <li><a :class="{'active selected ':selectedFilter === 'remixes'}" @click="selectFilter('remixes')"
                                 role="option" class="dropdown-item" id="bs-select-9-2" tabindex="0"
                                 aria-selected="false" aria-setsize="6" aria-posinset="1"><span
                              class=" bs-ok-default check-mark"></span><span class="text">Remixes</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index">
                  <component v-bind:is="myComponentList[singleChannel.alt_name].component"
                             :cname="singleChannel.description" :myInd="index" :gridTitle="singleChannel.description"
                             :header="true" :redirectLink="'continue-listening-to'"
                             :listData="singleChannel.objects.data"
                             :stream="isStreaming"
                             v-if="myComponentList[singleChannel.alt_name].component !== 'TopCharts'"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style"/>
    <full-page-loader :show="loading"/>
  </div>
</template>
<script type="application/javascript">
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue";
import DateTimePicker from "@/components/Common/UiComponenets/DateTimePicker";
import 'vue2-datepicker/index.css';
import '/public/css/vue2-date-picker.scss';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import TopCharts from "@/components/Common/Elements/TopCharts.vue";
import ChartTopSellingList from "@/components/Common/Elements/ChartTopSellingList"
import {mapActions} from 'vuex';
import TopNewSinglesAlbums from "@/components/Common/Elements/TopNewSinglesAlbums.vue";
import {getChannelData} from '../../../apis/APIs';

export default {
  data() {
    return {
      showMd: false,
      openWeekPicker: false,
      week: null,
      showAd: false,
      date: new Date(),
      selectedGenres: [],
      selectedFilter: 'singles',
      loading: false,
      allChannels: [],
      dataLoaded: false,
      top_100_played_songs_array:[],
      myComponentList: {
        'top-100-by-country': {'component': 'TopCharts', 'link': 'continue-listening-to', 'subComponent': ''},
        'top-100-played-songs': {
          'component': 'ChartTopSellingList',
          'link': 'continue-listening-to',
          'subComponent': ''
        },
      }
    }
  },
  watch: {
    selectedGenres() {
      this.pageChannels()
    },
    selectedFilter() {
      this.pageChannels()
    },
  },
  components: {
    payStreamSwitch,
    TopCharts,
    DateTimePicker,
    //  SongsPopularity,
    ChartTopSellingList,
    TopNewSinglesAlbums
  },
  filters: {
    upperCaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  computed: {
    isStreaming()
    {
      return this.$store.getters.isMusicModeIsStreaming
    },
    genres() {
      return (this.$store.getters.getGenres && this.$store.getters.getGenres.length > 0) ? this.$store.getters.getGenres : [];
    }
  },
  mounted() {
    this.pageChannels();
    this.getGeners();
  },
  methods: {
    async pageChannels(startDate = null, endDate = null) {
      try {
        console.log(startDate, endDate)
        this.allChannels = [];
        await this.$store.dispatch('loading', true)
        let data = await getChannelData('charts', this.selectedGenres, null, null,null,this.selectedFilter, startDate, endDate);
        this.allChannels = data.data.channels;
        this.top_100_played_songs_array = (data.data.channels.center[1] && data.data.channels.center[1].alt_name === 'top-100-played-songs') ? data.data.channels.center[1].objects.data : [] ;
        await this.$store.dispatch('loading', false)
      } catch (error) {
        await this.$store.dispatch('loading', false)
      }
    },
    playAll(){
      if(this.top_100_played_songs_array.length > 0){
        this.$store.commit('Set_Play_All_Songs_Array', this.top_100_played_songs_array);
        this.$store.commit('SET_CURRENT_PLAYER', 'footerPlayer');
        this.$store.commit('Set_Song_status', true)
      }else{
        console.log("No 100 Top Played Songs Yet");
      }
    },
    ...mapActions([
      "get_geners"
    ]),
    async getGeners() {
      try {
        if (!this.$store.getters.getGenres && !this.$store.getters.getGenres.length > 0) {
          await this.$store.dispatch('get_geners')
        }
      } catch (error) {
        console.error(error)
      }
    },
    clearGenreFilter() {
      this.selectedGenres = []
    },
    selectGenre(e, value) {
      e.stopPropagation()
      const index = this.selectedGenres.findIndex(e => e === value);
      if (index !== -1) {
        this.selectedGenres.splice(index, 1)
      } else {
        this.selectedGenres.push(value);
      }
    },
    isSelectedGenre(id) {
      const index = this.selectedGenres.findIndex(e => e === id)
      return (index !== -1)
    },
    selectFilter(filter) {
      this.selectedFilter = filter
    },
    weekSelected(val) {
      const {startOfWeek, endOfWeek} = val
      this.pageChannels(startOfWeek, endOfWeek)
      console.log(val)
    },
    redirect: function (url) {
      this.$router.push({name: url, params: {title: 'Hot new singles', pageFrom: 'overview'}})

    },
  }
}
</script>
<style>
.gallery-card.charts {
  padding-top: unset;
}
.gallery-card.charts > .row {
  position: unset;
}
.notification-style {
  margin-top: 5rem;
}
.top-active-btn::before {
  content: '';
  position: absolute;
  background-image: url(../../../../src/assets/img/check-white.png);
  width: 12px;
  height: 9px;
  top: 9px;
  left: 16%;
}

.list {
  width: 100% !important;
}

.gen_mooods ul li :nth-child(12){
  margin-right: 0px;
}

</style>
