<template>
  <div class="sale-items">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="title-h4 alb-rev">{{ gridTitle }} </h4>
      </div>
    </div>
    <div class="music_slider_landing selling_now2" ref="busstop">
      <carousel :items="3" :autoplay="false" :nav="false" class="featured-box">
        <div class="row">
          <div class="col-4" v-for="(eachData, index) in listData" :key="index">
            <div class="podcast-featured h-100">
              <PodcastFeatured :item="eachData" :backgroundType="'type-1'" :stream="isStreaming" />
            </div>
          </div>
        </div>
      </carousel>
      <div class="link-more near_arrow more_btn_landing cursor-pointer">
        <a @click="redirect()">See more</a>
      </div>
    </div>
  </div>


</template>
<script type="application/javascript">
import ClickOutside from 'vue-click-outside'
import carousel from "vue-owl-carousel";
import PodcastFeatured from "@/components/DesignGuide/Music/Components/PodcastFeatured.vue";

export default {
  data() {
    return {
      showMMore: false,
      showOption: false,
      lastWidth: 0

    }
  },
  mounted() {
    //  this.popupItem = this.$el
  },
  components: {
    //    ListOption,
    PodcastFeatured,
    carousel,
  },
  props: {
    gridTitle: String,
    redirectLink: String,
    listData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isStreaming(){
      return this.$store.getters.isMusicModeIsStreaming
    }
  },
  methods: {
    redirect: function () {
      this.$router.push({name: 'music.'+this.redirectLink})
    },
    hide: function () {
      this.showOption = false;
      this.$parent.showList(false);
    },
    getHeight: function (e, ei) {
      //  if(this.optionWidth != 0){
      e.stopPropagation();
      this.showOption = !this.showOption;
      if (this.lastWidth != e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }

      console.log(this.showOption, 'this.showOption');

      let height = (ei % 3) > 0 ? ((ei % 3) == 1) ? 35 : 100 : 160;
      this.$parent.updateDimension(e.screenX, height);
      this.$parent.showList(this.showOption);
      //  }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
<style>
/* .all_singles .owl-item {
width: 398px !important;
} */
.box_margins {
  margin-left: 0.8rem!important;
}
</style>
