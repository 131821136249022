<template>
  <div class="info">
    <div class="info-item">
      <h3>{{ GET_SINGLE_EVENT_DETAILS.going }}</h3>
      <p class="mb-0">GOING</p>
    </div>
    <div class="info-item">
      <h3>{{ GET_SINGLE_EVENT_DETAILS.interested }}</h3>
      <p class="mb-0">INTERESTED</p>
    </div>
    <div class="info-item">
      <h3>{{ GET_SINGLE_EVENT_DETAILS.total_invites }}</h3>
      <p class="mb-0">INVITED</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["GET_SINGLE_EVENT_DETAILS"]),
  },
};
</script>

<style lang="scss" scoped>
.info {
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 370px;
  height: 90px;
  max-width: 100%;
  &-item {
    padding: 9px 10px;
    font-family: HelveticaNeueBold;
    border-radius: 4px;
    transition: all 0.3s;
    width: 90px;
    max-width: 100%;
    text-align: center;
    h3 {
      color: rgba($color: #47505b, $alpha: 0.9);
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 13px;
    }
    p {
      color: #8b949f;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
    }
    &:hover {
      background: #D1433A1A;
      h3 {
        color: #D1433A;
      }
      p {
        color: rgba(65, 108, 255, 0.8);
      }
    }
  }
}
</style>
