<template>
  <div>
    <div class="row mt_10">
      <div class="col-md-6 " v-for="(my_friends, index) in getFriendsList" :key="index">
        <div class="frind_list_box">
          <div class="friend_profile_image">
            <div class="friend-image-wrapper cursor-pointer"
                 @click="redirectWidgetsToProfilePage($event,my_friends.artwork_url && my_friends.username ? my_friends.username : '')"
            >
              <img :src="my_friends.artwork_url" alt="user_image" @error="onProfileImageError($event, getFirstAlphabetOfUserName(my_friends))">
            </div>
          </div>
          <div class="friend_profile_content">
            <h4
              @click="redirectWidgetsToProfilePage($event,my_friends.name && my_friends.username ? my_friends.username : '')"
              class="friend_profile_name cursor-pointer">{{ my_friends.name}}</h4>
            <p class="friend_profile_location" v-if="my_friends && my_friends.country.length > 0">
                <span class="location_map">
                   <svg width="10" height="12" viewBox="0 0 10 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                          fill="#47505B"/>
                   </svg>
                </span>
              <span class="mr-0"> {{my_friends.country ? my_friends.country[0].name : '' }}</span>
            </p>
          </div>
          <div class="dropdown show">
            <div class="friends_more_icon" :id="'dropdownMenuLink_'+my_friends.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="toggleOpenMore">
              <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.44361 2.88743C2.24089 2.88743 2.88721 2.2411 2.88721 1.44382C2.88721 0.646539 2.24089 0.000214015 1.44361 0.000213875C0.646325 0.000213736 -1.13007e-07 0.646539 -2.52408e-07 1.44382C-3.9181e-07 2.2411 0.646325 2.88743 1.44361 2.88743Z"
                  fill="#47505B"/>
                <path
                  d="M7.01783 2.88722C7.81511 2.88722 8.46143 2.24089 8.46143 1.44361C8.46143 0.646326 7.81511 3.9181e-07 7.01783 2.52408e-07C6.22054 1.13007e-07 5.57422 0.646325 5.57422 1.44361C5.57422 2.24089 6.22054 2.88722 7.01783 2.88722Z"
                  fill="#47505B"/>
                <path
                  d="M12.592 2.88722C13.3893 2.88722 14.0357 2.24089 14.0357 1.44361C14.0357 0.646326 13.3893 3.9181e-07 12.592 2.52408e-07C11.7948 1.13007e-07 11.1484 0.646325 11.1484 1.44361C11.1484 2.24089 11.7948 2.88722 12.592 2.88722Z"
                  fill="#47505B"/>
              </svg>
            </div>
            <div class="friends_more_dropdown dropdown-menu" v-show="openMore" :id="my_friends.id"
                  :aria-labelledby="'dropdownMenuLink_'+my_friends.id">
              <form  class="friends_more_dropdown_listing">
                <div class="form-check dropdown-item">
                  <input type="radio" class="form-check-input" :id="'close_friend_'+my_friends.id" name="optradio1" :checked="my_friends.friend_status.close_friends === 1" @click="updateFriendStatus(close_friends=1, acquaintance=0, my_friends)">
                  <label class="form-check-label" :for="'close_friend_'+my_friends.id">
                    <span>Close Friends</span>
                  </label>
                </div>
                <div class="form-check dropdown-item">
                  <input type="radio" class="form-check-input" :id="'acquaintances_'+my_friends.id" name="optradio1" :checked="my_friends.friend_status.acquaintance === 1" @click="updateFriendStatus(close_friends=0, acquaintance=1, my_friends)">
                  <label class="form-check-label" :for="'acquaintances_'+my_friends.id">
                    <span>Acquaintances</span>
                  </label>
                </div>
                <div class="form-check dropdown-item">
                  <input type="radio" class="form-check-input" :id="'unfollow_'+my_friends.id" name="optradio2" :checked="my_friends.follow_status === 0" value="option2" @click="changeFollowStatus(my_friends.id, 'user',0, my_friends)">
                  <label class="form-check-label" :for="'unfollow_'+my_friends.id">
                    <span>Unfollow</span>
                  </label>
                </div>
                <div class="form-check dropdown-item">
                  <input type="radio" class="form-check-input" :id="'unfriend_'+my_friends.id" :name="'unfriend_'+my_friends.id" v-model="selected">
                  <label class="form-check-label"  :for="'unfriend_'+my_friends.id" @click="unFriend(my_friends)">
                    <span>Unfriend</span>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    -->
    <div class="col-md-12" v-if="getFriendsTotal > getFriendsList.length">
      <div class="show_more_btn">
        <div class="show-more musicbar_more"><a href="javascript:;" @click="showMoreFriends">
        <span class="show_more_bg">
          <svg
            width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
              stroke-linejoin="round"
            >
            </path>
            <path d="M1 3.85498H7.48702" stroke="#071526"
              stroke-width="1.3" stroke-linecap="round"
              stroke-linejoin="round"
            >
            </path>
          </svg>
       SHOW MORE FRIENDS
     </span></a></div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  props:['friendsSearchKey','currentActiveFriendTab'],
  data() {
    return {
      user_id:null,
      openMore: true,
      showMore: true,
      selected: false,
    }
  },
  computed:{
    getFriendsList(){
      return this.$store.getters['getMyFriends']
    },
    getFriendsTotal(){
      return this.$store.getters['getFriendsTotal']
    }
  },
  methods:{
    toggleOpenMore(){
      this.openMore = true
    },
    showMoreFriends(){
      let payload = {
        showMore : this.showMore,
        friendsSearchKey : this.friendsSearchKey,
        search_type: this.currentActiveFriendTab
      }
      this.$store.dispatch('getMyFriends', payload);
    },
    async changeFollowStatus(id,object_type,action, my_follower){
      let payload = {
        id : id,
        object_type : object_type,
        action : action,
        user : my_follower
      }
      await this.$store.dispatch('changeFollowStatus', payload)
      this.openMore = false;
    },
    async unFriend(payload){
      await this.$store.dispatch('removeSentRequest', payload)
      this.openMore = false;
      this.selected = false;
    },
    async updateFriendStatus(close_friends, acquaintance, my_friends){
      let payload = {
        close_friends : close_friends,
        acquaintance : acquaintance,
        user: my_friends
      }
      await this.$store.dispatch('updateFriendStatus', payload)
      this.openMore = false;
    },
  }
}
</script>
<style scoped>
.dropdown{
  position: absolute !important;
  right: 30px !important;
}
.friends_more_dropdown_listing .form-check .form-check-label::before {
  top: 8.5px !important;
}
.friends_more_dropdown_listing .form-check input:checked+label::after {
  top: 10.5px !important;
}
</style>