<template lang="">
    <div class="btn btn-facebook" @click="logInWithFacebook">
        <div class="svg-icon">
            <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M20 10C20 4.47658 15.5234 0 10 0C4.47658 0 0 4.47658 0 10C0 14.9922 3.65625 19.1289 8.4375 19.8789V12.8906H5.89842V10H8.4375V7.79688C8.4375 5.291 9.92971 3.90625 12.2148 3.90625C13.3086 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1914C11.9492 6.5625 11.5625 7.334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8789C16.3438 19.1289 20 14.9922 20 10Z"
                fill="white"
            ></path>
            <path
                d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.334 11.9492 6.5625 13.1914 6.5625H14.4531V4.10156C14.4531 4.10156 13.3086 3.90625 12.2149 3.90625C9.92973 3.90625 8.43752 5.291 8.43752 7.79687V10H5.89844V12.8906H8.43752V19.8789C8.94727 19.959 9.46877 20 10 20C10.5313 20 11.0528 19.959 11.5625 19.8789V12.8906H13.8926Z"
                fill="#D1433A"
            ></path>
            </svg>
        </div>
        {{
    loading ? "Please Wait..." : "Facebook"
  }}
         </div>

</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {getFacebookData, facebook_login, get_ip_address} from '../../../apis/APIs';
import * as Utils from "../../../components/utils/Utils";

export default {
  data() {
    return ({
      facebook_sdk_loading: false,
      loading: false
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "update_user_data"
    ]),
    async init() {
      try {
        this.facebook_sdk_loading = true;
        await this.loadFbSdk();
        this.facebook_sdk_loading = false;
      } catch (error) {
        this.facebook_sdk_loading = false;
      }
    },
    async logInWithFacebook() {
      const that = this;
      window.FB.login(function (response) {
        if (response.authResponse) {
          const {accessToken} = response.authResponse || {};
          console.log("You are logged in &amp; cookie set!", response.authResponse, accessToken);
          that.getUserData(accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }, {
        scope: 'email',
        return_scopes: true
      });
      return false;
    },
    async getUserData(access_token) {
      const url = `https://graph.facebook.com/me?fields=name,email,first_name,last_name,birthday,gender,picture&access_token=${access_token}`;

      this.loading = true;
      const res = await getFacebookData(url);
      const {email, first_name, last_name, name, gender, birthday, picture, id: facebook_id} = res.data || {};
      const res_ip = await get_ip_address();
      const {ip} = res_ip.data;

      if (!email) {
        this.$parent.setErrorMessage("Email address not found.");
        this.loading = false;
        return;
      }

      const query = `?user_browser=${Utils.getBrowser()}&user_os=${navigator.platform}&user_ip=${ip}&firstname=${first_name}&lastname=${last_name}&email=${email}&user_type=1&facebook_id=${facebook_id}&username=${first_name}${facebook_id}`;
      const user_data_res = await facebook_login(query);

      const {success, data} = user_data_res || {};

      if (success === "1") {
        await this.update_user_data(data);
        this.$router.push("/home");
      }

      this.loading = false;

      console.log("data ===> ", user_data, query);
    },
    async loadFbSdk() {
      return new Promise(resolve => {
        window.fbAsyncInit = function () { // eslint-disable-line func-names
          FB.init({
            appId: "272797644453089",
            cookie: true,
            version: "v13.0",
            xfbml: false
          });
          FB.AppEvents.logPageView();
          resolve('SDK Loaded!');
        };
        (function (d, s, id) { // eslint-disable-line func-names
          console.log("connect with facebook sdk");
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          const js = d.createElement(s);
          js.id = id;
          js.src = '//connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      });
    }
  }
}
</script>
<style lang="">

</style>
