<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Hidden</h3>
        <h6>To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="get_quote_settings.visibility" id="switch26"/>
        <label class="switch_sm" for="switch26">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4>Companies can get quote from you</h4>
        <p>We’ll show form with fields and financial offer value for companies</p>
      </div>
    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/file.svg" alt="file"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M8.66602 1.33301H3.99935C3.64573 1.33301 3.30659 1.47348 3.05654 1.72353C2.80649 1.97358 2.66602 2.31272 2.66602 2.66634V13.333C2.66602 13.6866 2.80649 14.0258 3.05654 14.2758C3.30659 14.5259 3.64573 14.6663 3.99935 14.6663H11.9993C12.353 14.6663 12.6921 14.5259 12.9422 14.2758C13.1922 14.0258 13.3327 13.6866 13.3327 13.333V5.99967L8.66602 1.33301Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.66602 1.33301V5.99967H13.3327" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        Get Quote
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      get_quote_settings: {
        visibility: false
      }
    }
  },
  props: {
    getQuoteSettings: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    'get_quote_settings.visibility'() {
      this.$emit('getQuoteData', this.get_quote_settings)
    },
    getQuoteSettings() {
      this.setGetQuotesSettingsData()
    }
  },
  mounted() {
    this.setGetQuotesSettingsData()
  },
  methods: {
    setGetQuotesSettingsData() {
      this.get_quote_settings = this.getQuoteSettings
    }
  }
}
</script>
