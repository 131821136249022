<template>
  <div>
    <h1 class="EM_Pages_title">SELL TICKETS</h1>
    <div class="w-1170 event_manager_contener wr_tickets">
      <div>
        <SellTicketsCard
          v-for="item in eventsData"
          :key="item.id"
          :event="item"
        />
      </div>
      <EMRightSidebar />
    </div>
  </div>
</template>

<script>
import EMRightSidebar from "../EMElement/EMRightSidebar.vue";
import SellTicketsCard from "../EMElement/SellTicketsCard.vue";
export default {
  components: {
    EMRightSidebar,
    SellTicketsCard,
  },
  data() {
    return {
      eventsData: [
        {
          id: 1,
          eventName: "Playground Presents Nina Kraviz",
          location: "Night Club Volf • Vienna, Austria",
          tickets: [
            {
              id: 1,
              ticketName: "Advance Discount Ticket",
              quantity: 240,
              sold: 280,
              price: 25,
              total: '6,650',
              status: "Hidden",
            },
            {
              id: 2,
              ticketName: "General Concessions",
              quantity: 280,
              sold: 280,
              price: 25,
              total: '5,750',
              status: "Sold Out",
            },
            {
              id: 3,
              ticketName: "Advance Discount Ticket",
              quantity: 100,
              sold: 300,
              price: 25,
              total: '4,250',
              status: "Active",
            },
          ],
        },
        {
          id: 2,
          eventName: "Playground Presents Nina Kraviz",
          location: "Night Club Volf • Vienna, Austria",
          tickets: [
            {
              id: 1,
              ticketName: "Advance Discount Ticket",
              quantity: 240,
              sold: 280,
              price: 25,
              total: '9,350',
              status: "Sold Out",
            },
            {
              id: 2,
              ticketName: "General Concessions",
              quantity: 280,
              sold: 280,
              price: 25,
              total: '5,750',
              status: "Active",
            },
            {
              id: 3,
              ticketName: "Advance Discount Ticket",
              quantity: 100,
              sold: 300,
              price: 25,
              total: '8,750',
              status: "Hidden",
            },
          ],
        },
        {
          id: 3,
          eventName: "Playground Presents Nina Kraviz",
          location: "Night Club Volf • Vienna, Austria",
          tickets: [],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>