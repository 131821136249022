<template>
  <div class="about-work">
    <div class="row">
      <div class="col">
        <div class="about-work-text">
          <h1>ABOUT</h1>
          <div v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_basic_info">
            <div v-if="(userData.bio && userData.bio.length > 300) && showLess">
              <p>{{ userData.bio.slice(0,300)}}...</p>
              <a href="javascript:;" @click="checkAbout">READ MORE</a>
            </div>
            <div v-else-if="userData.bio && userData.bio.length > 300 && readMore">
              <p v-if="userData.bio ">{{ userData.bio }}</p>
              <a href="javascript:;" @click="checkAbout">SHOW LESS</a>
            </div>
            <div v-else-if="userData.bio && userData.bio.length < 300 && userData.bio">
              <p v-if="userData.bio ">{{ userData.bio }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_basic_info"
      class="row">
      <div class="col">
        <div
          class="about_tags">
          <ul>
            <li v-for="item in userData.genres" :key="item.name">
              <a href="javascript:;">{{ item.name }}</a>
            </li>
<!--            <li><a href="javascript:;">rock</a></li>-->
<!--            <li><a href="javascript:;">alternative</a></li>-->
<!--            <li><a href="javascript:;">grunge</a></li>-->
<!--            <li><a href="javascript:;">guitar</a></li>-->
<!--            <li><a href="javascript:;">+2</a></li>-->
          </ul>
        </div>
      </div>
    </div>
    <hr v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_basic_info" class="about_hr_line">
    <div class="row" :class="{'mt-4' : (!checkIfPageOwnerIsYourFriend(userData.id) || userData.who_can_see_your_basic_info)}">
      <div class="col-md-6">
        <div class="about_listing">
          <div class="about-more-details">
            <span>
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 15H12V1.60714C12 0.719866 11.3281 0 10.5 0H5.5C4.67188 0 4 0.719866 4 1.60714V15ZM6 2.14286H10V3.21429H6V2.14286ZM16 4.82143V13.3929C16 14.2801 15.3281 15 14.5 15H13V3.21429H14.5C15.3281 3.21429 16 3.93415 16 4.82143ZM3 15H1.5C0.671875 15 0 14.2801 0 13.3929V4.82143C0 3.93415 0.671875 3.21429 1.5 3.21429H3V15Z"
                    fill="#8B949F"></path>
              </svg>
            </span>
            <p v-if="user_experience && user_experience.title && user_experience.company_name">{{ user_experience.title }}&nbsp;at&nbsp;{{ user_experience.company_name }}</p>
          </div>
          <div class="about-more-details">
            <span>
              <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.00173 6.42198C0.983736 5.71866 1.10635 5.01884 1.3623 4.36349C1.61824 3.70815 2.00241 3.11048 2.4923 2.60551C2.98219 2.10054 3.56792 1.69844 4.21521 1.42275C4.8625 1.14707 5.5583 1.00333 6.26185 1H6.3428C7.04438 0.999364 7.73917 1.13708 8.38746 1.40527C9.03575 1.67346 9.62481 2.06684 10.1209 2.56293C10.617 3.05902 11.0104 3.64807 11.2786 4.29637C11.5467 4.94466 11.6845 5.6395 11.6838 6.34108V6.42198C11.6567 7.91492 11.0468 9.33809 9.98442 10.3873L6.3428 15L2.62023 10.3064C1.5682 8.76879 1.00173 7.87863 1.00173 6.42198Z"
                    fill="#8B949F"></path>
                <path
                    d="M1.00173 6.42198C0.983736 5.71866 1.10635 5.01884 1.3623 4.36349C1.61824 3.70815 2.00241 3.11048 2.4923 2.60551C2.98219 2.10054 3.56792 1.69844 4.21521 1.42275C4.8625 1.14707 5.5583 1.00333 6.26185 1H6.3428C7.04438 0.999364 7.73917 1.13708 8.38746 1.40527C9.03575 1.67346 9.62481 2.06684 10.1209 2.56293C10.617 3.05902 11.0104 3.64807 11.2786 4.29637C11.5467 4.94466 11.6845 5.6395 11.6838 6.34108V6.42198C11.6567 7.91492 11.0468 9.33809 9.98442 10.3873L6.3428 15L2.62023 10.3064C1.5682 8.76879 1.00173 7.87863 1.00173 6.42198"
                    stroke="#8B949F" stroke-width="1.5"></path>
                <path
                    d="M6.34174 8.12136C7.36969 8.12136 8.20301 7.32428 8.20301 6.34102C8.20301 5.35776 7.36969 4.56067 6.34174 4.56067C5.31379 4.56067 4.48047 5.35776 4.48047 6.34102C4.48047 7.32428 5.31379 8.12136 6.34174 8.12136Z"
                    stroke="white" stroke-width="1.5" stroke-miterlimit="10"></path>
              </svg>
            </span>
            <p v-if="userData.hometown && (userData.country && userData.country.length > 0 && userData.country[0].name)">From&nbsp;{{
                userData.hometown
              }},&nbsp;{{ (userData.country && userData.country.length > 0 && userData.country[0].name) ? userData.country[0].name : '' }}</p>
          </div>
          <div class="about-more-details">
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.54 0.6875H11.3759C11.1708 0.6875 10.9741 0.768987 10.829 0.914035C10.684 1.05908 10.6025 1.25581 10.6025 1.46094C10.6025 1.66607 10.684 1.86279 10.829 2.00784C10.9741 2.15289 11.1708 2.23437 11.3759 2.23437H12.6728L10.5497 4.35746C8.15244 2.4991 4.67865 2.66996 2.47998 4.86969C0.0949805 7.25504 0.0949805 11.1356 2.48139 13.5195C3.62943 14.6641 5.18445 15.3069 6.80561 15.3069C8.42676 15.3069 9.98178 14.6641 11.1298 13.5195C13.3296 11.3195 13.5004 7.84707 11.6421 5.44977L13.7665 3.32809V4.625C13.7665 4.83013 13.848 5.02685 13.9931 5.1719C14.1381 5.31695 14.3349 5.39844 14.54 5.39844C14.7451 5.39844 14.9418 5.31695 15.0869 5.1719C15.2319 5.02685 15.3134 4.83013 15.3134 4.625V1.46094C15.3134 1.35937 15.2934 1.25879 15.2545 1.16496C15.2157 1.07112 15.1587 0.985855 15.0869 0.914035C15.0151 0.842214 14.9298 0.785243 14.836 0.746374C14.7421 0.707506 14.6416 0.6875 14.54 0.6875ZM10.0372 12.4269C9.17949 13.2828 8.01729 13.7635 6.80561 13.7635C5.59392 13.7635 4.43173 13.2828 3.57404 12.4269C1.79197 10.6452 1.79197 7.74547 3.57404 5.96375C5.35611 4.18203 8.25545 4.18168 10.0372 5.96375C11.8189 7.74582 11.8192 10.6452 10.0372 12.4269Z"
                    fill="#8B949F"/>
              </svg>
            </span>
            <p v-if="userData.gender">
              <b v-if="userData.gender=='M'">Male</b>
              <b v-else-if="userData.gender=='F'">Female</b>
              <b v-else-if="userData.gender=='O'">Other</b>
            </p>
          </div>
          <div
            v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_relationship"
            class="about-more-details mb-0">
            <span>
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.6212 2.86018L15.6213 2.86027C15.9802 3.21162 16.264 3.62789 16.4574 4.08488C16.6507 4.54185 16.75 5.03105 16.75 5.5247C16.75 6.01835 16.6507 6.50755 16.4574 6.96451C16.264 7.42151 15.9802 7.83778 15.6213 8.18913L15.6212 8.18917L14.7299 9.06188L8.71718 14.9489L2.70449 9.06188L1.81884 8.19475C1.12183 7.48127 0.740018 6.53242 0.750199 5.55153C0.760404 4.56824 1.16378 3.6248 1.87892 2.92461C2.59456 2.22393 3.56546 1.82313 4.58406 1.813C5.60022 1.80289 6.57714 2.18243 7.30682 2.86579L8.19248 3.73294C8.48404 4.0184 8.95032 4.0184 9.24188 3.73294L10.1332 2.86022L10.1333 2.86018C10.4922 2.50874 10.9191 2.22914 11.3901 2.03811C11.8611 1.84706 12.3665 1.74854 12.8772 1.74854C13.388 1.74854 13.8933 1.84706 14.3644 2.03811C14.8354 2.22914 15.2623 2.50874 15.6212 2.86018Z"
                    fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <p v-if="userData.relationship_status">
              <b>{{ userData.relationship_status }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about_listing">
          <div
            class="about-more-details">
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.8 8.79999H0C0.198991 10.7802 1.12892 12.6151 2.60819 13.9464C4.08747 15.2778 6.00987 16.01 8 16C5.952 16 4.944 12.408 4.8 8.79999Z"
                    fill="#8B949F"></path>
                <path
                    d="M0 7.20022H4.8C4.928 3.59222 5.936 0.000222633 8 0.000222633C6.00987 -0.00975066 4.08747 0.722422 2.60819 2.05377C1.12892 3.38512 0.198991 5.22004 0 7.20022Z"
                    fill="#8B949F"></path>
                <path
                    d="M8.00039 14.4C8.38439 14.4 9.44839 12.48 9.60039 8.79999H6.40039C6.55239 12.48 7.61639 14.4 8.00039 14.4Z"
                    fill="#8B949F"></path>
                <path
                    d="M8.00039 1.60001C7.61639 1.60001 6.55239 3.52001 6.40039 7.20001H9.60039C9.44839 3.52001 8.38439 1.60001 8.00039 1.60001Z"
                    fill="#8B949F"></path>
                <path
                    d="M8 16C9.99013 16.01 11.9125 15.2778 13.3918 13.9464C14.8711 12.6151 15.801 10.7802 16 8.79999H11.2C11.056 12.408 10.048 16 8 16Z"
                    fill="#8B949F"></path>
                <path
                    d="M8 9.00975e-06C10.048 9.00975e-06 11.056 3.59201 11.2 7.20001H16C15.801 5.21982 14.8711 3.3849 13.3918 2.05356C11.9125 0.722209 9.99013 -0.00996428 8 9.00975e-06Z"
                    fill="#8B949F"></path>
              </svg>
            </span>
            <p v-if="userData.website_url">
              <b>
                {{ userData.website_url.slice(0, 25) }}...
              </b>
            </p>
          </div>
          <div
            v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_location_info"
            class="about-more-details">
            <span>
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.74413 7.46304L6.58316 1.20694C6.71911 1.06128 6.94906 1.05833 7.0887 1.20046L13.2497 7.47144C13.314 7.53689 13.35 7.62498 13.35 7.71673V14C13.35 14.1933 13.1933 14.35 13 14.35H9.97436C9.78106 14.35 9.62436 14.1933 9.62436 14V11.3846C9.62436 10.4733 8.88563 9.73462 7.97436 9.73462H6.38462C5.47335 9.73462 4.73462 10.4733 4.73462 11.3846V14C4.73462 14.1933 4.57792 14.35 4.38461 14.35H1C0.8067 14.35 0.65 14.1933 0.65 14V7.70186C0.65 7.6132 0.683641 7.52785 0.74413 7.46304Z"
                    fill="#8B949F" stroke="#8B949F" stroke-width="1.3"></path>
              </svg>
            </span>
            <p v-if="userData.current_city && userData.country[0].name">Lives in &nbsp;{{
                userData.current_city
              }},&nbsp;{{ (userData.country && userData.country.length > 0) ? userData.country[0].name : '' }}</p>
          </div>
          <div
            v-if="checkIfPageOwnerIsYourFriend(userData.id) || !userData.who_can_see_your_birthdate"
            class="about-more-details">
            <span>
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1" fill="white">
                  <rect y="11.5881" width="17.6476" height="4.41189" rx="1"/>
                </mask>
                <rect y="11.5881" width="17.6476" height="4.41189" rx="1" fill="#8B949F" stroke="#8B949F"
                      stroke-width="3" mask="url(#path-1-inside-1)"/>
                <mask id="path-2-inside-2" fill="white">
                  <rect x="2.64844" y="8.05946" width="12.3533" height="4.41189" rx="1"/>
                </mask>
                <rect x="2.64844" y="8.05946" width="12.3533" height="4.41189" rx="1" fill="#8B949F" stroke="#8B949F"
                      stroke-width="3" mask="url(#path-2-inside-2)"/>
                <rect x="4.63075" y="2.98492" width="0.441189" height="3.9707" rx="0.220595" fill="#8B949F"
                      stroke="#8B949F" stroke-width="0.441189"/>
                <rect x="7.27919" y="2.98492" width="0.441189" height="3.9707" rx="0.220595" fill="#8B949F"
                      stroke="#8B949F" stroke-width="0.441189"/>
                <rect x="12.5761" y="2.98492" width="0.441189" height="3.9707" rx="0.220595" fill="#8B949F"
                      stroke="#8B949F" stroke-width="0.441189"/>
                <rect x="4.41016" y="1" width="0.882378" height="0.882378" rx="0.441189" fill="#8B949F" stroke="#8B949F"
                      stroke-width="0.5"/>
                <rect x="7.05859" y="1" width="0.882378" height="0.882378" rx="0.441189" fill="#8B949F" stroke="#8B949F"
                      stroke-width="0.5"/>
                <rect x="9.92763" y="2.98492" width="0.441189" height="3.9707" rx="0.220595" fill="#8B949F"
                      stroke="#8B949F" stroke-width="0.441189"/>
                <rect x="9.70703" y="1" width="0.882378" height="0.882378" rx="0.441189" fill="#8B949F" stroke="#8B949F"
                      stroke-width="0.5"/>
                <rect x="12.3555" y="1" width="0.882378" height="0.882378" rx="0.441189" fill="#8B949F" stroke="#8B949F"
                      stroke-width="0.5"/>
              </svg>
            </span>
            <p v-if="userData.birth">
              <b>{{ userData.birth | moment }}</b>
            </p>
          </div>
          <div class="about-more-details mb-0">
            <span>
              <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.875 0H1.875C0.84375 0 0.00937499 0.84375 0.00937499 1.875L0 13.125C0 14.1562 0.84375 15 1.875 15H16.875C17.9062 15 18.75 14.1562 18.75 13.125V1.875C18.75 0.84375 17.9062 0 16.875 0ZM16.5 3.98438L9.87187 8.12813C9.57187 8.31563 9.17813 8.31563 8.87813 8.12813L2.25 3.98438C2.15599 3.9316 2.07367 3.86031 2.00802 3.7748C1.94237 3.68929 1.89475 3.59135 1.86804 3.48691C1.84133 3.38247 1.8361 3.27369 1.85265 3.16716C1.8692 3.06064 1.90719 2.95858 1.96433 2.86716C2.02146 2.77574 2.09655 2.69686 2.18506 2.63531C2.27356 2.57375 2.37363 2.53079 2.47921 2.50903C2.5848 2.48727 2.6937 2.48716 2.79933 2.5087C2.90496 2.53025 3.00512 2.573 3.09375 2.63437L9.375 6.5625L15.6563 2.63437C15.7449 2.573 15.845 2.53025 15.9507 2.5087C16.0563 2.48716 16.1652 2.48727 16.2708 2.50903C16.3764 2.53079 16.4764 2.57375 16.5649 2.63531C16.6534 2.69686 16.7285 2.77574 16.7857 2.86716C16.8428 2.95858 16.8808 3.06064 16.8974 3.16716C16.9139 3.27369 16.9087 3.38247 16.882 3.48691C16.8553 3.59135 16.8076 3.68929 16.742 3.7748C16.6763 3.86031 16.594 3.9316 16.5 3.98438Z"
                    fill="#8B949F"/>
              </svg>
            </span>
            <p v-if="userData.email">
              <b>{{ userData.email.slice(0, 20) }}...</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "aboutSection",
  props: {
    userData: {
      type: Object,
      require: true
    },
    user_experience: {
      type: Object,
      require: true
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('Do MMMM YYYY');
    }
  },
  data () {
    return {
      readMore: false,
      showLess: true
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    checkAbout() {
      if(this.readMore) {
        this.showLess = true;
        this.readMore = false;
      }
      else if (this.showLess) {
        this.readMore = true
        this.showLess = false
      }
    }
  }
}
</script>

<style scoped>

</style>
