<template>
    <div class="join-modal">
        <div class="modal fade show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            style="display: block; padding-right: 15px;" aria-modal="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="join-modal-heading">
                        <h1>Join Speakers?</h1>
                        <div class="create-video-" v-on:click="closeModal">
                            <a href="javascript:;">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="join-modal-text">Are you sure you want to join speakers? Your microphone will be on and
                        users will hear you
                    </div>
                    <div class="join-modal-btn-container">
                        <button class="cancel-btn" v-on:click="closeModal">CANCEL</button>
                        <button class="join-btn">JOIN SPEAKERS</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="application/javascript">
export default {
    name: 'JoinModal',
    data() {
        return {
            currentTab: 'upload-photo',
            genres2: false,
            subGenres: false,
            type: ''
        }
    },
    components: {},
    methods: {
        closeModal: function () {
            this.$emit('closeModal');
        },
    },
}
</script>
<style scoped lang="scss">
.join-modal {
    background-color: #00000045;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal {
        .modal-dialog {
            max-width: 370px;
            box-shadow: none;
            margin: 200px auto;

            .modal-content {
                position: relative;
                border-radius: 5px;
                width: 370px;
                height: 210px;
                border: none;

                .join-modal-heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #E6EAF0;
                    padding: 20px;
                    font-family: HelveticaNeueMedium, sans-serif;

                    h1 {
                        font-size: 18px;
                        font-weight: 600;
                        font-family: HelveticaNeueMedium, sans-serif;
                        color: #071526;
                        letter-spacing: 0.5px;
                    }
                }

                .join-modal-text {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    padding: 15px 20px 25px 20px;
                }

                .join-modal-btn-container {
                    display: flex;
                    padding: 0 20px 20px 20px;
                    flex-direction: row;

                    .cancel-btn {
                        background: white;
                        padding: 12px;
                        width: 100px;
                        height: 40px;
                        border: 1.3px solid #8B949F;
                        border-radius: 4px;
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 16px;
                        color: #8B949F;
                    }

                    .join-btn {
                        margin-left: 10px;
                        width: 220px;
                        height: 40px;
                        padding: 12px;
                        background: #15CA86;
                        border-radius: 4px;
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 16px;
                        color: #fff;
                        border: none;
                    }
                }
            }
        }
    }
}
</style>
