<template>
<div>
<!--  <ChatMusic />-->
  <div>
    <button @click="callNotification()">Show Modal</button>
    <div class="large-12 medium-12 small-12 cell">
      <label>File
        <input type="file" id="file" ref="file" @change="handleFileUpload"/>
      </label>
      <br>
      <progress max="100" :value.prop="uploadPercentage"></progress>
      <br>
      <button v-on:click="uploadSong()">Submit</button>
    </div>
  </div>
</div>
</template>

<script>
import ChatMusic from "./Chat/ChatMusic";
import createFormData from "../mixins/common";
import { uploadSongTemp } from "../apis/APIs";
import AppConfig from "../configs/AppConfig";
import axios from "axios";

export default {
  name: "TestChatMusic",
  data() {
    return {
      file: '',
      uploadPercentage: 0,
      tempSongUpload: {
        privacy: 'public',
        type: 'song',
        file: null,
        songs:[],
        names:[]
      }
    }
  },
  components:{
    // ChatMusic
  },
  methods: {
    callNotification() {
      this.notificationToast(true, 'Page', 'Page location info updated', 5000, 'success')
    },
    handleFileUpload(e){
      if(this.tempSongUpload.type==='song' || this.tempSongUpload.type==='podcast'){
        this.tempSongUpload.names.push(e.target.files[0].name)
        this.tempSongUpload.file = e.target.files[0]
      }else{
        for(const song of e.target.files){
          this.tempSongUpload.names.push(song.name)
          this.tempSongUpload.songs.push(song)  ;
        }
      }
      console.log('Checking results ==>', this.tempSongUpload);
    },
    async uploadSong() {
      const formData = createFormData(this.tempSongUpload);
      axios.post( `${AppConfig.base_url}/page/music/upload-temp-song`,
        formData,
        {
          headers: {
            Authorization:
              localStorage.getItem("token") !== null
                ? localStorage.getItem("token")
                : "",
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
            console.log('Checking Percentage ==>', this.uploadPercentage);
          }.bind(this)
        }
      ).then(function(){
        console.log('SUCCESS!!');
      })
        .catch(function(){
          console.log('FAILURE!!');
        });
    }
  }
};
</script>

<style scoped lang="scss">
</style>
