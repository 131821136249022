<template>
  <div class="profile_content"  v-if="item && item.user">
    <span class="news-feed-pic" @mouseleave="mouseUp">
      <img
        @click="redirectWidgetsToProfilePage($event, item && item.user && item.user.username ? item.user.username : '')"
        :style="imageSize" :src="picture" @mouseover="mouseOver" @error="onProfileImageError($event, getFirstAlphabetOfUserName(item.user))">
      <UserProfileCard v-if="item && (userId !== item.user_id)"  v-show="active" :item="item"></UserProfileCard>
    </span>
  </div>
</template>

<script>
import UserProfileCard from '../../../Common/UiComponenets/Profile/UserProfileCard.vue';
export default {
  name: "UserPicture",
  data() {
    return{
      active: false,
      showMore: false
    }
  },
  methods: {
    mouseOver: function(){
        this.active = !this.active;
    },
    mouseUp: function(){
        this.active = false;
    },
    getMyFollowings: function() {
      let payload = {
        showMore : this.showMore,
        friendsSearchKey: null,
      }
       this.$store.dispatch('getMyFollowings',payload);
    }
  },
  props:{
    picture:{
      type:String,
      required:true
    },
    size:{
      type:Number,
      default:null,
    },
    item:{
      type: Object,
    }
  },
  components:{
    UserProfileCard
  },
  computed:{
    imageSize(){
      if(this.size){
        return {
          width:this.size+'px',
          height:this.size+'px',
        }
      }
      return {}
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id
    },

  }
}
</script>

<style scoped>

</style>
