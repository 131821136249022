<template>
  <div class="modal fade modal-add-playlist show" id="add_modal_create_playlist" tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" style="display: block; padding-right: 15px;" aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="title d-flex justify-content-center align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M9.28786 14.3091C10.5598 14.3091 11.5909 13.278 11.5909 12.0061C11.5909 10.7342 10.5598 9.70312 9.28786 9.70312C8.01595 9.70312 6.98486 10.7342 6.98486 12.0061C6.98486 13.278 8.01595 14.3091 9.28786 14.3091Z"
                  stroke="#D1433A" stroke-width="1.5" stroke-linejoin="round"></path>
              <path d="M6.672 6.47852H0" stroke="#D1433A" stroke-width="1.5" stroke-linejoin="round"></path>
              <path d="M11.6309 12.3V2.748L14.9998 1" stroke="#D1433A" stroke-width="1.5"
                    stroke-linejoin="round"></path>
              <path d="M6.74817 1.87598H0.0761719" stroke="#D1433A" stroke-width="1.5" stroke-linejoin="round"></path>
            </svg>
          </div>
          <div class="short-border">
            ADD TO PLAYLISTS
          </div>
        </div>
        <div class="create-wrapper collapse show" id="create-play" style="">
          <div class="d-flex flex-row justify-content-between align-items-end m-lr-30">
            <div class="flex-grow-1 m-r-20 playlist-title">
              <label for="basic-url">Playlist Title</label>
              <input type="text" id="basic-url" aria-describedby="basic-addon3"></div>
            <div>
              <div class="dropdown bootstrap-select show-tick filters-select">
                <select class="filters-select selectpicker" title="Genres" multiple="" data-max-options="1"
                        tabindex="-98">
                  <option value="1">Afrobeats</option>
                  <option value="2">Alternative</option>
                  <option value="3">Blues</option>
                  <option value="4">Caribbean</option>
                  <option value="5">Dance &amp; EDM</option>
                  <option value="6">Easy Listening</option>
                  <option value="7">Electronic</option>
                  <option value="8">Folk</option>
                  <option value="9">Drum &amp; Bass</option>
                  <option value="10">Hip-Hop/ Rap</option>
                  <option value="11">House</option>
                  <option value="12">Indie</option>
                  <option value="13">Jazz</option>
                  <option value="14">Pop</option>
                  <option value="15">R &amp; B/ Soul</option>
                  <option value="16">Rock</option>
                  <option value="17">Techno</option>
                  <option value="18">World</option>
                  <option value="19">Classical</option>
                </select>
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-30" aria-haspopup="listbox" aria-expanded="false"
                        title="Genres">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">Genres</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu" x-placement="bottom-start"
                     style="max-height: 253px; overflow: hidden; min-height: 118px; min-width: 182px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 14px, 0px);"
                     x-out-of-boundaries="">
                  <div class="inner show" role="listbox" id="bs-select-30" tabindex="-1" aria-multiselectable="true"
                       style="max-height: 235px; overflow-y: auto; min-height: 100px;">
                    <ul class="dropdown-menu inner show" role="presentation"
                        style="margin-top: 0px; margin-bottom: 0px;">
                      <li><a role="option" class="dropdown-item" id="bs-select-30-0" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Afrobeats</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-1" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Alternative</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-2" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Blues</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-3" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Caribbean</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-4" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Dance &amp; EDM</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-5" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Easy Listening</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-6" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Electronic</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-7" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Folk</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-8" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Drum &amp; Bass</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-9" tabindex="0" aria-selected="false"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Hip-Hop/ Rap</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-10" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">House</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-11" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Indie</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-12" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Jazz</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-13" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Pop</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-14" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">R &amp; B/ Soul</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-15" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Rock</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-16" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Techno</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-17" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">World</span></a>
                      </li>
                      <li><a role="option" class="dropdown-item" id="bs-select-30-18" tabindex="0"
                             aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Classical</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="privacy-block d-flex flex-row align-items-center m-lr-30">
            <div class="privacy-title">Privacy:</div>
            <div class="custom-control custom-radio d-flex align-items-center"><input type="radio" id="customRadio1"
                                                                                      name="customRadio"
                                                                                      class="custom-control-input"
                                                                                      checked=""> <label
                class="custom-control-label" for="customRadio1">Public</label></div>
            <div class="custom-control custom-radio d-flex align-items-center"><input type="radio" id="customRadio2"
                                                                                      name="customRadio"
                                                                                      class="custom-control-input">
              <label class="custom-control-label" for="customRadio2">Private</label></div>
            <div class="btn btn-create ml-auto">Create</div>
          </div>
          <div class="search-playlist"><input type="text" placeholder="Search tracks and albums"></div>
          <div class="song-list albums-style">
            <div class="list-item d-flex flex-row ">
              <div class="cover">
                <div class="svg-icon">
                  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                      <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <div class="item-title">Four Tet</div>
                <div class="item-subtitle">Sleeping shadows</div>
              </div>
              <div class="add-playlist-wrapper ml-auto align-self-center">
                <div class="lock">
                  <div class="svg-icon">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                            fill="white" stroke="white" stroke-width="0.5"></path>
                    </svg>
                  </div>
                </div>
                <div class="add-playlist-btn">
                  Add to playlist
                </div>
              </div>
            </div>
            <div class="list-item d-flex flex-row ">
              <div class="cover">
                <div class="svg-icon">
                  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                      <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <div class="item-title">Four Tet</div>
                <div class="item-subtitle">Sleeping shadows</div>
              </div>
              <div class="add-playlist-wrapper ml-auto align-self-center">
                <div class="lock">
                  <div class="svg-icon">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                            fill="white" stroke="white" stroke-width="0.5"></path>
                    </svg>
                  </div>
                </div>
                <div class="add-playlist-btn">
                  Add to playlist
                </div>
              </div>
            </div>
            <div class="list-item d-flex flex-row active lock-active">
              <div class="cover">
                <div class="svg-icon">
                  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                      <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <div class="item-title">Four Tet</div>
                <div class="item-subtitle">Sleeping shadows</div>
              </div>
              <div class="add-playlist-wrapper ml-auto align-self-center">
                <div class="lock">
                  <div class="svg-icon">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                            fill="white" stroke="white" stroke-width="0.5"></path>
                    </svg>
                  </div>
                </div>
                <div class="add-playlist-btn">
                  Added
                </div>
              </div>
            </div>
            <div class="list-item d-flex flex-row lock-active">
              <div class="cover">
                <div class="svg-icon">
                  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                      <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <div class="item-title">Four Tet</div>
                <div class="item-subtitle">Sleeping shadows</div>
              </div>
              <div class="add-playlist-wrapper ml-auto align-self-center">
                <div class="lock">
                  <div class="svg-icon">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                            fill="white" stroke="white" stroke-width="0.5"></path>
                    </svg>
                  </div>
                </div>
                <div class="add-playlist-btn">
                  Add to playlist
                </div>
              </div>
            </div>
            <div class="list-item d-flex flex-row lock-active">
              <div class="cover">
                <div class="svg-icon">
                  <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                      <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                      <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                            stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <div class="item-title">Four Tet</div>
                <div class="item-subtitle">Sleeping shadows</div>
              </div>
              <div class="add-playlist-wrapper ml-auto align-self-center">
                <div class="lock">
                  <div class="svg-icon">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.5 5.66667C1.77614 5.66667 2 5.44281 2 5.16667V2C2 1.44771 2.44772 1 3 1H9C9.55229 1 10 1.44772 10 2V5.16667C10 5.44281 10.2239 5.66667 10.5 5.66667C10.7761 5.66667 11 5.89052 11 6.16667V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V6.16667C1 5.89052 1.22386 5.66667 1.5 5.66667ZM4 1.93333C3.44772 1.93333 3 2.38105 3 2.93333V4.66667C3 5.21895 3.44772 5.66667 4 5.66667H8C8.55228 5.66667 9 5.21895 9 4.66667V2.93333C9 2.38105 8.55228 1.93333 8 1.93333H4ZM7 9.46667C7 8.91438 6.55228 8.46667 6 8.46667C5.44772 8.46667 5 8.91438 5 9.46667V12.1333C5 12.6856 5.44772 13.1333 6 13.1333C6.55228 13.1333 7 12.6856 7 12.1333V9.46667Z"
                            fill="white" stroke="white" stroke-width="0.5"></path>
                    </svg>
                  </div>
                </div>
                <div class="add-playlist-btn">
                  Add to playlist
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
