<template>
  <div class="main-music-tab-content m-t-20">
    <div class="row">
      <div class="col">
        <div class="placeholder_nav_music" >
          <MyMusicEmptyCard
            @redirectFunction="redirectFunction"
            :title="'You haven’t favorited any Music, Playlists, Podcasts or Artists yet'"
            :text="'Go to Explore music and start favoriting music, Podcasts and Artists that you like '"
            :icon="'heart'"
            :buttonText="'EXPLORE MUSIC'"
          />
        </div>
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script>
import MyMusicEmptyCard from "@/components/Common/UiComponenets/MyMusicEmptyCard.vue";
export default {
  name: "NFT's",
  components:{
    MyMusicEmptyCard
  },
  data(){
    return{
      loading:false
    }
  },
  methods:{
    redirectFunction(){
      this.$router.push('/music/page/overview')
    },
  }
}
</script>