<template>
  <div class="container_xxl pl115 pagesMain">
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h1 class="pages-heading-title">{{($route.params.type === 'clubrooms') ? 'CLUBROOMS' : ($route.params.type === 'partyrooms') ? 'PARTYROOMS' : 'PAGES'}}</h1>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex mb-40 align-items-stretch">
          <div class="col-8">
            <div class="friends-wrap bg-transparent">
              <nav>
                <div class="nav pages-tbs" id="nav-tab" role="tablist">
                  <a
                    :class="[active_tab === 'discover_liked' ? 'active' : '']"
                    v-on:click="showRightBar = 'discover_liked'"
                    class="nav-item nav-link"
                    id="nav-explore-tab"
                    data-toggle="tab"
                    href="#nav-explore"
                    role="tab"
                    aria-controls="nav-explore"
                    aria-selected="true"
                    >DISCOVER {{$route.params.type.toUpperCase()}}</a
                  >
                  <a
                    :class="[active_tab === 'pages_liked' ? 'active' : '']"
                    ref="liked_pages"
                    v-on:click="showRightBar = 'discover_liked'"
                    class="nav-item nav-link"
                    id="nav-friend-tab"
                    data-toggle="tab"
                    href="#nav-friend"
                    role="tab"
                    aria-controls="nav-friend"
                    aria-selected="false"
                    >LIKED {{$route.params.type.toUpperCase()}}
                  </a>
                  <a
                    :class="[active_tab === 'my_pages' ? 'active' : '']"
                    v-on:click="showRightBar = 'discover_liked'"
                    class="nav-item nav-link"
                    id="nav-sent-tab"
                    data-toggle="tab"
                    href="#nav-sent"
                    role="tab"
                    aria-controls="nav-sent"
                    aria-selected="false"
                    >MY {{$route.params.type.toUpperCase()}}</a
                  >
                </div>
              </nav>
              <div
                class="tab-content bg-transparent p-t-30"
                id="nav-tabContent"
              >
                <div
                  class="tab-pane fade show active"
                  id="nav-explore"
                  role="tabpanel"
                  aria-labelledby="nav-explore-tab"
                >
                  <div v-if="explorePages.pages.length > 0" class="row position-relative">
                    <div
                      class="col-sm-3 js-container-hover"
                      v-for="(page, index) in explorePages.pages"
                      :key="index"
                    >
                      <PageOrArtistCard
                        :item="page"
                        @refreshPagesList="refreshPagesOfSection"
                      />
                    </div>

                  </div>


                  <div v-else>
                    <div class="text-center" v-if="explorePages.loading || favouritePages.loading || myPages.loading">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                    <div v-else class="no-content-here no-event-here">
                      <div class="no-detail-content">
                        <div class="category-content-icon">
                          <svg v-if="$route.params.type === 'pages'" width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 50V3" stroke="#47505B" stroke-width="3" stroke-linejoin="round"/>
                            <path d="M2.5 29.9117V3.25512C2.77471 3.08618 3.18213 2.86779 3.74468 2.64277C5.11094 2.09627 7.41935 1.5 11.0004 1.5C14.4216 1.5 17.2915 2.63191 20.4438 3.89283L20.5334 3.92866C23.607 5.15824 26.9615 6.50022 31.0013 6.50022C34.9206 6.50022 37.6124 5.84644 39.3713 5.14288C39.4154 5.12524 39.4589 5.10757 39.5018 5.08989V31.7464C39.2271 31.9154 38.8196 32.1338 38.2571 32.3588C36.8908 32.9053 34.5824 33.5015 31.0013 33.5015C27.5801 33.5015 24.7103 32.3696 21.558 31.1087L21.4684 31.0729C18.3948 29.8433 15.0403 28.5013 11.0004 28.5013C7.0812 28.5013 4.3894 29.1551 2.63051 29.8587C2.58641 29.8763 2.5429 29.894 2.5 29.9117Z" stroke="#47505B" stroke-width="3" stroke-linejoin="round"/>
                          </svg>
                          <img v-else :src="require(`@/assets/icons/club_icon_explore_pages.svg`)" style="height:60px; width: 60px"/>
                        </div>
                        <div class="no-detail-content-heading">
                          You haven't discovered any {{($route.params.type === 'clubrooms') ? 'ClubPages ' : ($route.params.type === 'partyrooms') ? 'PartyPages ' : 'Page ' }} yet.
                        </div>
                        <div class="no-detail-content-subheading">
                          Create {{($route.params.type === 'clubrooms') ? 'ClubPages ' : ($route.params.type === 'partyrooms') ? 'PartyPages ' : 'Page ' }} and join the discussion using Audio.
                        </div>
                        <div class="add-new-product-buttons">

                          <button @click="createNewPage">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1604_17059)">
                                <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.3181 11.6816 1.33333 7.99967 1.33333C4.31778 1.33333 1.33301 4.3181 1.33301 8C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 5.33333V10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.33301 8H10.6663" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_1604_17059">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                            CREATE {{($route.params.type === 'clubrooms') ? ' CLUB PAGE' : ($route.params.type === 'partyrooms') ? ' PARTY PAGE' : ' PAGE' }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="show-more musicbar_more">
                    <a
                      @click="showMore('getAllPages')"
                      class="pointerClass"
                      v-if="
                        explorePages.pages.length < explorePages.total &&
                        !explorePages.loading
                      "
                    >
                      <span class="show_more_bg">
                        <svg
                          width="9"
                          height="8"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.24219 0.689941V7.01994"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M1 3.85498H7.48702"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        SHOW
                        {{ explorePages.total - explorePages.pages.length }}
                        MORE {{($route.params.type === 'clubrooms') ? 'CLUBS' : ($route.params.type === 'partyrooms') ? 'PARTYS' : 'PAGES'}}
                      </span>
                    </a>
                    <div class="text-center" v-if="explorePages.loading && explorePages.pages.length > 0">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-friend"
                  role="tabpanel"
                  aria-labelledby="nav-friend-tab"
                >
                  <div v-if="favouritePages.pages.length > 0" class="row position-relative">
                    <div
                      class="col-sm-3 js-container-hover"
                      v-for="(page, index) in favouritePages.pages"
                      :key="index"
                    >
                      <PageOrArtistCard
                        :item="page"
                        @refreshPagesList="refreshPagesOfSection"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="text-center" v-if="favouritePages.loading || explorePages.loading || myPages.loading">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                    <div v-else class="no-content-here no-event-here">
                      <div class="no-detail-content">
                        <div class="category-content-icon">
                          <img :src="require(`@/assets/icons/explore-pages-heart.svg`)" style="height:50px; width: 57px"/>
                        </div>
                        <div class="no-detail-content-heading">
                          You haven't liked any {{($route.params.type === 'clubrooms') ? 'ClubPages ' : ($route.params.type === 'partyrooms') ? 'PartyPages ' : 'Page ' }} yet.
                        </div>
                        <div class="no-detail-content-subheading">
                          Create {{($route.params.type === 'clubrooms') ? 'ClubPages ' : ($route.params.type === 'partyrooms') ? 'PartyPages ' : 'Page ' }} and join the discussion using Audio.
                        </div>
                        <div class="add-new-product-buttons">
                          <button @click="createNewPage">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1604_17059)">
                                <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.3181 11.6816 1.33333 7.99967 1.33333C4.31778 1.33333 1.33301 4.3181 1.33301 8C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 5.33333V10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.33301 8H10.6663" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_1604_17059">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                            CREATE {{($route.params.type === 'clubrooms') ? ' CLUB PAGE' : ($route.params.type === 'partyrooms') ? ' PARTY PAGE' : ' PAGE' }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="show-more musicbar_more">
                    <a
                      @click="showMore('getLikedPages')"
                      class="pointerClass"
                      v-if="
                        favouritePages.pages.length < favouritePages.total &&
                        !favouritePages.loading
                      "
                    >
                      <span class="show_more_bg">
                        <svg
                          width="9"
                          height="8"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.24219 0.689941V7.01994"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M1 3.85498H7.48702"
                            stroke="#071526"
                            stroke-width="1.3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        SHOW
                        {{ favouritePages.total - favouritePages.pages.length }}
                        MORE PAGES
                      </span>
                    </a>
                    <div class="text-center" v-if="favouritePages.loading && favouritePages.pages.length > 0">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-sent"
                  role="tabpanel"
                  aria-labelledby="nav-sent-tab"
                >
                  <div v-if="myPages.pages.length > 0" class="row position-relative">
                    <div
                      class="col-sm-3 js-container-hover"
                      v-for="(page, index) in myPages.pages"
                      :key="index"
                    >
                      <PageOrArtistCard
                        :item="page"
                        :section="'my_pages'"
                        @refreshPagesList="refreshPagesOfSection"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="text-center" v-if="myPages.loading || explorePages.loading || favouritePages.loading">
                      <b-spinner
                        variant="primary"
                        label="Spinning"
                      ></b-spinner>
                    </div>
                    <div v-else class="no-content-here no-event-here">
                      <div class="no-detail-content">
                        <div class="category-content-icon">
                          <svg v-if="$route.params.type === 'pages'" width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 52V34.4991" stroke="#47505B" stroke-width="3" stroke-linejoin="round"/>
                            <path d="M2 34.5016C2 34.5016 4.50012 32.0015 12.0005 32.0015C19.5009 32.0015 24.5011 37.0017 32.0015 37.0017C39.5018 37.0017 42.0019 34.5016 42.0019 34.5016V4.50012C42.0019 4.50012 39.5018 7.00024 32.0015 7.00024C24.5011 7.00024 19.5009 2 12.0005 2C4.50012 2 2 4.50012 2 4.50012V34.5016Z" stroke="#47505B" stroke-width="3" stroke-linejoin="round"/>
                          </svg>
                          <img v-else :src="require(`@/assets/icons/club_icon_explore_pages.svg`)" style="height:60px; width: 60px"/>
                        </div>
                        <div class="no-detail-content-heading">
                          You haven't created any {{($route.params.type === 'clubrooms') ? 'ClubPages ' : ($route.params.type === 'partyrooms') ? 'PartyPages ' : 'Page ' }} yet.
                        </div>
                        <div class="no-detail-content-subheading">
                          Create {{($route.params.type === 'clubrooms') ? 'ClubPages ' : ($route.params.type === 'partyrooms') ? 'PartyPages ' : 'Page ' }} and join the discussion using Audio.
                        </div>
                        <div class="add-new-product-buttons">
                          <button @click="createNewPage">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1604_17059)">
                                <path d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.3181 11.6816 1.33333 7.99967 1.33333C4.31778 1.33333 1.33301 4.3181 1.33301 8C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8 5.33333V10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5.33301 8H10.6663" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_1604_17059">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                            CREATE {{($route.params.type === 'clubrooms') ? ' CLUB  PAGE' : ($route.params.type === 'partyrooms') ? ' PARTY PAGE' : ' PAGE' }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="show-more musicbar_more">
                    <a
                      @click="showMore('getMyPages')"
                      class="pointerClass"
                      v-if="
                          myPages.pages.length < myPages.total &&
                          !myPages.loading
                        "
                    >
                        <span class="show_more_bg">
                          <svg
                            width="9"
                            height="8"
                            viewBox="0 0 9 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.24219 0.689941V7.01994"
                              stroke="#071526"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M1 3.85498H7.48702"
                              stroke="#071526"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          SHOW {{ myPages.total - myPages.pages.length }} MORE
                          PAGES
                        </span>
                    </a>
                    <div class="text-center" v-if="myPages.loading && myPages.pages.length > 0">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-4 m-b-50"
            v-if="
              showRightBar == 'discover_liked'
            "
          >
            <ExplorePagesFilters />
          </div>
          <div
            class="col-md-4 m-b-50"
            v-if="showRightBar == 'my_pages' "
          >
            <div class="row">
              <div class="col-12">
                <div class="post-widget">
                  <div class="post-btn btn with-icon" @click="createNewPage">
                    <div class="svg-icon">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M7 4.66699V9.33366"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M4.66699 7H9.33366"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                    CREATE NEW PAGE
                  </div>
                </div>
                <div class="my-page-overview">
                  <div class="my-page-overview-heading">Overview</div>
                  <div class="my-page-overview-detail">
                    <div class="my-page-overview-pages-over">
                      Total Pages: <span>{{ myPages.total }}</span>
                    </div>
                    <div class="my-page-overview-pages-over">
                      Total Followers: <span>923k</span>
                    </div>
                    <div class="my-page-msg-notifiaction">
                      <div class="my-page-overview-pages-messages">
                        Messages: <span>312</span>
                      </div>
                      <div class="my-page-overview-pages-notifi">
                        Notifications: <span>240</span>
                      </div>
                    </div>
                    <div class="my-page-overview-pages-over">
                      Most Popular Page:
                      <h1>NHOAH THE ARTIST</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="my-page-overview">
                  <div class="my-page-overview-heading">Now Promoting</div>
                  <div class="now-parmoting-contain">
                    <div class="promoting-container-section">
                      <div class="promoting-container-section-image">
                        <img
                          :src="'https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg'"
                        />
                      </div>
                      <div class="promoting-container-section-detail">
                        <h1>NHOAH The Artist</h1>
                        <p>$50 / $100</p>
                      </div>
                      <button
                        class="
                          promoting-container-section-detail-right-arrow
                          btn
                        "
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9L5 5L1 1"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="promoting-container-section">
                      <div class="promoting-container-section-image">
                        <img
                          src="https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg"
                          alt=""
                        />
                      </div>
                      <div class="promoting-container-section-detail">
                        <h1>The Walt Disnep</h1>
                        <p>
                          <span>$99 / $100</span>
                        </p>
                      </div>
                      <button
                        class="
                          promoting-container-section-detail-right-arrow
                          btn
                        "
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9L5 5L1 1"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="promoting-container-section">
                      <div class="promoting-container-section-image">
                        <img
                          src="https://auddiodev-api.theplayground.co.uk/common/default/default-profile.jpg"
                        />
                      </div>
                      <div class="promoting-container-section-detail">
                        <h1>NHOAH The Artist</h1>
                        <p>$50 / $100</p>
                      </div>
                      <button
                        class="
                          promoting-container-section-detail-right-arrow
                          btn
                        "
                      >
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9L5 5L1 1"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <full-page-loader :show="loading" class="position-on-top" />
  </div>
</template>
<script type="application/javascript">
import PageOrArtistCard from "@/components/Common/UiComponenets/PageOrArtistCard.vue";
// import MyPageCard from "@/components/Common/UiComponenets/MyPageCard.vue";
import ExplorePagesFilters from "./Components/ExplorePagesFilters";
import { LIKED_PAGES, LOADING } from "../../../store/types";

export default {
  data() {
    return {
      showRightBar: "discover_liked",
      route: "",
      active_tab: ''
    };
  },
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
    pageType() {
      return this.$store.getters.getPagesType;
    },
    favouritePages() {
      return this.$store.getters.getFavouritePages;
    },
    explorePages() {
      return this.$store.getters.getExplorePages;
    },
    myPages() {
      return this.$store.getters.getUserPages;
    },
    previous_route() {
      return this.$store.getters.getArticlePreviousRoute;
    },
    getRoute: {
      set() {
        this.route = this.$route.params.type;
      },
      get() {
        return this.route;
      },
    },
  },
  async mounted() {
    if (this.previous_route === "home.favorites") {
      this.$refs.liked_pages.click();
      this.showRightBar = "discover_liked";
      this.active_tab = "pages_liked";
      this.$store.commit("SET_PREVIOUS_ROUTE", "");
    } else {
      this.active_tab = "discover_liked";
    }
    if (this.$route.params.type) {
      await this.fetchPagesData();
    } else {
      this.$store.commit("CLEAR_PAGES_DATA");
    }
  },
  components: {
    PageOrArtistCard,
    // MyPageCard,
    ExplorePagesFilters,
  },
  methods: {
    createNewPage: async function () {
      const pathname = `/page-manager/page/create-new-page?type=${this.pageType}`;
      await this.$router.push(pathname);
    },
    async fetchPagesData(showMore = false) {
      await this.$store.commit("SET_PAGES_TYPE", (this.$route.params.type === 'pages') ? 'artist' : this.$route.params.type.slice(0,-1));
      if (this.$route.params.type === 'pages') {
        await this.$store.commit("SET_PAGE_ACTIVE_TAB", "music");
      } else {
        await this.$store.commit("SET_PAGE_ACTIVE_TAB", "post");
      }
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("getPageCategories");
      await this.$store.commit("CLEAR_PAGES_DATA");
      await this.$store.dispatch("getAllPages", showMore);
      await this.$store.dispatch("getLikedPages", showMore);
      await this.$store.dispatch("getMyPages", showMore);
      await this.$store.dispatch("loading", false);
    },
    showMore(type = "getAllPages") {
      this.$store.dispatch(type, {
        type: this.pageType,
        showMore: true,
      });
    },
    refreshPagesOfSection(section) {
      this.$store.commit("SET_OFFSET_OF_SPECIFIC_PAGES", LIKED_PAGES);
      this.$store.dispatch(section, {
        type: this.pageType,
        showMore: false,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.position-on-top {
  z-index: +9999999999999;
}
.add-new-product-buttons {
  button {
    height: 40px;
    width: 230px;
  }
 }
</style>
