<template>
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.00173 6.42198C0.983736 5.71866 1.10635 5.01884 1.3623 4.36349C1.61824 3.70815 2.00241 3.11048 2.4923 2.60551C2.98219 2.10054 3.56792 1.69844 4.21521 1.42275C4.8625 1.14707 5.5583 1.00333 6.26185 1H6.3428C7.04438 0.999364 7.73917 1.13708 8.38746 1.40527C9.03575 1.67346 9.62481 2.06684 10.1209 2.56293C10.617 3.05902 11.0104 3.64807 11.2786 4.29637C11.5467 4.94466 11.6845 5.6395 11.6838 6.34108V6.42198C11.6567 7.91492 11.0468 9.33809 9.98442 10.3873L6.3428 15L2.62023 10.3064C1.5682 8.76879 1.00173 7.87863 1.00173 6.42198Z"
            fill="#8B949F" />
        <path
            d="M1.00173 6.42198C0.983736 5.71866 1.10635 5.01884 1.3623 4.36349C1.61824 3.70815 2.00241 3.11048 2.4923 2.60551C2.98219 2.10054 3.56792 1.69844 4.21521 1.42275C4.8625 1.14707 5.5583 1.00333 6.26185 1H6.3428C7.04438 0.999364 7.73917 1.13708 8.38746 1.40527C9.03575 1.67346 9.62481 2.06684 10.1209 2.56293C10.617 3.05902 11.0104 3.64807 11.2786 4.29637C11.5467 4.94466 11.6845 5.6395 11.6838 6.34108V6.42198C11.6567 7.91492 11.0468 9.33809 9.98442 10.3873L6.3428 15L2.62023 10.3064C1.5682 8.76879 1.00173 7.87863 1.00173 6.42198"
            stroke="#8B949F" stroke-width="1.5" />
        <path
            d="M6.34174 8.12124C7.36969 8.12124 8.20301 7.32415 8.20301 6.34089C8.20301 5.35764 7.36969 4.56055 6.34174 4.56055C5.31379 4.56055 4.48047 5.35764 4.48047 6.34089C4.48047 7.32415 5.31379 8.12124 6.34174 8.12124Z"
            stroke="white" stroke-width="1.5" stroke-miterlimit="10" />
    </svg>
</template>

<script>
export default {
    name: "LocationIcon",
};
</script>