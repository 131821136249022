<template>
  <div class="posting">
    <div class="posting-logo">
      <img src="@/assets/img//events/posting-logo.png" alt="" />
    </div>
    <div class="posting-field">
      <input type="text" placeholder="Post Something" />
      <div class="actions">
        <button>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.8571 10.5714L14.1429 7.85714L9 13L6 10L1 15.1429V18.8571C1.01377 19.4211 1.24396 19.9582 1.64287 20.3571C2.04179 20.756 2.57887 20.9862 3.14286 21H18.8571C19.4211 20.9862 19.9582 20.756 20.3571 20.3571C20.756 19.9582 20.9862 19.4211 21 18.8571V3.14286C20.9862 2.57887 20.756 2.04179 20.3571 1.64287C19.9582 1.24396 19.4211 1.01377 18.8571 1H3.14286C2.57887 1.01377 2.04179 1.24396 1.64287 1.64287C1.24396 2.04179 1.01377 2.57887 1 3.14286V9.57143"
              stroke="#8B949F"
              stroke-width="2"
              stroke-miterlimit="10"
            />
          </svg>
        </button>
        <button>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.2097 14.8896C19.5735 16.394 18.5785 17.7197 17.3116 18.7507C16.0448 19.7818 14.5446 20.4868 12.9424 20.8041C11.3401 21.1215 9.68449 21.0415 8.12027 20.5711C6.55606 20.1008 5.13087 19.2545 3.96931 18.1061C2.80775 16.9577 1.94518 15.5423 1.45702 13.9836C0.96886 12.4248 0.86997 10.7702 1.16899 9.16443C1.46802 7.55863 2.15585 6.05054 3.17236 4.77199C4.18887 3.49344 5.50311 2.48337 7.00017 1.83008"
              stroke="#8B949F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.9996 10.9996C20.9996 9.68644 20.741 8.38613 20.2384 7.17292C19.7359 5.95972 18.9993 4.85737 18.0708 3.92882C17.1422 3.00027 16.0399 2.2637 14.8267 1.76117C13.6135 1.25865 12.3132 1 11 1V10.9996H20.9996Z"
              stroke="#8B949F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button>
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
              stroke="#8B949F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.07812 13.2432C7.51566 13.8393 8.08481 14.3265 8.74132 14.6669C9.39783 15.0073 10.124 15.1916 10.8634 15.2056C11.6027 15.1916 12.3289 15.0073 12.9854 14.6669C13.6419 14.3265 14.2111 13.8393 14.6486 13.2432"
              stroke="#8B949F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
              fill="#8B949F"
            />
            <path
              d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
              fill="#8B949F"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="posting-send">
      <button>
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_348_18395)">
            <path
              d="M23.3336 12.7282L11.6664 12.7282"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.3332 12.7277L9.01425 19.622L11.6659 12.7277L9.01425 5.8334L23.3332 12.7277Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_348_18395">
              <rect
                width="18"
                height="18"
                fill="white"
                transform="translate(12.7266) rotate(45)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.posting {
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  padding: 10px 20px 10px 14px;
  display: flex;
  align-items: center;
  &-logo {
    width: 40px;
    max-width: 100%;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }
  &-field {
    width: 620px;
    max-width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f7f7;
    border-radius: 8px;
    padding-left: 19px;
    padding-right: 16px;
    margin-right: 10px;
    input {
      width: 100%;
      border: none;
      background: unset;
      font-size: 15px;
      line-height: 18px;
      color: rgba(7, 21, 38, 0.6);
      font-family: HelveticaNeueNormal;
    }
    .actions {
      display: flex;
      button {
        width: 30px;
        height: 30px;
        border: none;
        background: unset;
      }
      button + button {
        margin-left: 10px;
      }
    }
  }
  &-send {
    button {
      width: 40px;
      height: 40px;
      background: #D1433A;
      border-radius: 20px;
      border: none;
    }
  }
}
</style>
