<template>
  <div class="container_xxl">
    <div class="container-fluid ">
      <div class="row">
        <div class="col ">
          <h1 class="Page_title">{{ header ? 'EXPLORE' : '' }}</h1>
        </div>
      </div>
    </div>
    <div class="content discover ">

      <div class="row">
        <div class="col">
          <div class=" page-filter d-flex d-flex flex-row align-items-center p-r-20">
            <div class="back-link">
              <a @click="goBack()" class=" d-flex flex-row align-items-center cursor-pointer">
                <div class="svg-icon">
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1" d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152" stroke="#071526"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                {{ 'CHARTS' }}
              </a>
            </div>
            <div class="filter-title">
              {{ 'CHARTS' }}
            </div>
            <div class="d-flex flex-wrap align-items-end ml-auto">


              <div class="text with-icon d-flex justify-content-between justify-content-end align-items-center">
                Format
                <div class="svg-icon">
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-filter-new">
                    <rect width="16" height="1.87013" fill="#8B949F"></rect>
                    <rect y="5.61035" width="13.1765" height="1.87013" fill="#8B949F"></rect>
                    <rect y="11.2207" width="9.41176" height="1.87013" fill="#8B949F"></rect>
                  </svg>
                </div>
              </div>
              <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                <select class="filters-select selectpicker for-overview" title="All Genres" multiple=""
                        data-max-options="1" dropupauto="false" tabindex="-98">
                  <option value="0">Classical</option>
                  <option value="1">Dance/ Electronic</option>
                  <option value="2">Hip-Hop/ Rap</option>
                  <option value="3">Indie/ Rock</option>
                  <option value="4">Pop</option>
                </select>
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false"
                        title="All Genres">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">{{ selectedTypeFilter}}</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu ">
                  <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true">
                    <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                      <li v-for="(category,index) in typeCategory" :key="index"><a @click="selectOrUnSelectValue(category.value,'type')" :class="{'active selected':isSelectedValue(category.value,'type')}" role="option" class="dropdown-item" id="bs-select-6-0" tabindex="0" aria-selected="false"
                                                                                   aria-setsize="3" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                          class="text">{{ category.name }}</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="js-container">
        <div class=" sale-items m-t-10">
          <div class="row position-relative" v-if="listing_type == 'grid'">

            <div class="col-sm-2 js-container-hover" >
              <ChartCountryCard :small="true" :colorCode="1" :type="type"/>
            </div>
            <div class="col-sm-2 js-container-hover" v-for="(country,index) in pageCountries" :key="index">
              <ChartCountryCard :small="true" :colorCode="Number(index)+2" :country="country" :type="type"/>
            </div>
          </div>

        </div>
      </div>
      <div class="row" v-if="totalRows > allData.length">
        <div class="col-12">
          <div class="show-more musicbar_more mt-0">
            <a href="javascript:;" @click="GetChannelData(getChannelName,true)">
                     <span class="show_more_bg">
                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                                 stroke-linecap="round" stroke-linejoin="round"></path>
                           <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                                 stroke-linejoin="round"></path>
                        </svg>
                        SHOW MORE
                     </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>
<script type="application/javascript">
import {get_countries_list, getPageData} from '../../../../apis/APIs';
import ChartCountryCard from "@/components/DesignGuide/Music/Components/GridChart.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
  name:'ArtistOrPageChannelWithCards',
  data() {
    return {
      listing_type: 'grid',
      allData: [],
      loading: false,
      dataLoaded: false,
      limit: 6,
      offset: 0,
      totalRows: 0,
      type:'singles',
      selectedFilter: 'singles',
      filterApplied: 'Latest',
      user_id: '',
      typeCategory:[
        {value:'singles',name:'Singles'},
        {value:'albums',name:'Albums'},
        {value:'remixes',name:'Remixes'},
      ],
    }
  },

  components: {
    ChartCountryCard
  },
  filters: {
    upperCaseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  props: {
    title: String,
    redirectUrl: String,
    activeHeader: String,
    thirdTxt: String,
    pageFilter: {
      type: String,
      default: ''
    },
    header: Boolean
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    selectedTypeFilter(){
      if(this.type){
        const index  = this.typeCategory.findIndex(e=>e.value === this.type)
        return this.typeCategory[index].name

      }
      return 'Type'
    },
    pageCountries(){
     let selectedCountriesIDs = [0,232,233,14,31,39,75,82,85,107,109,132,156,182,204,213,207,219]
      let selectedCountries = []
      for(const id of selectedCountriesIDs){
        let index = this.allCountries.findIndex(e=>e.id === id)
        if(index !== -1){
          selectedCountries.push(this.allCountries[index])
        }
      }
      return selectedCountries
    },
    allCountries(){
      return this.$store.getters.getCountries
    },
    pageFrom(){
      const {fromPage} = this.$route.query;
      if(fromPage){
        return fromPage
      }
      return 'Back'
    },
    getChannelName() {
      const {channelName} = this.$route.params;
      return (channelName)?channelName:null
    },
    ChannelTitle(){
      const {channelName} = this.$route.params;
      if(channelName){
        return channelName.replaceAll('-',' ')
      }
      return 'Title'
    }
  },
  mounted: function () {
    if (localStorage.getItem('componentType')) {
      this.componentType = localStorage.getItem('componentType');
    }
    this.$store.dispatch('fetchCountries')
  },
  methods: {
    ...mapActions([
      "fetchUserData"
    ]),
    async GetChannelData(channelName,showMore=false) {
      try {
        this.loading = true
        if(showMore)
        {
          this.offset = parseInt(this.offset) + this.limit;
        }
        let {data} = await getPageData(channelName, this.limit, this.offset, this.filterApplied);
        this.allData = (showMore)? [...this.allData, ...data.channels.center.objects.data]:data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    selectOrUnSelectValue(value,type){
      if(this.$data[type])
      {
        this.$data[type] = (this.$data[type] === value)?null:value
      }
      else{
        this.$data[type] = value
      }
    },
    isSelectedValue(value,type){
      return (this.$data[type] === value)
    },
    async updateFilter(currentFilter) {
      this.filterApplied = currentFilter;
      let user_id = this.user.user_data.user_id;
      try {
        this.loading = true;
        this.offset = 0;
        let data = await getPageData((this.$route.name) ? this.$route.name : null, this.limit, this.offset, this.filterApplied);
        this.allData = data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  }
}
</script>
