<template>
  <div class="container-fluid m-t-50">
    <div class="row filter-options open">
      <div class="col px-0">
        <div class="filter-inner">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex flex-wrap align-items-center">
              <div class="text sort-title">Sort</div>
              <div class="svg-icon m-r-30">
                <svg
                  class="svg-filter-new"
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.666016 1.58203C0.666016 0.891675 1.22566 0.332031 1.91602 0.332031H9.41602C10.1064 0.332031 10.666 0.891675 10.666 1.58203C10.666 2.27239 10.1064 2.83203 9.41602 2.83203H1.91602C1.22566 2.83203 0.666016 2.27239 0.666016 1.58203Z"
                    fill="#D1433A"
                  />
                  <path
                    d="M0.666016 6.58203C0.666016 5.89167 1.22566 5.33203 1.91602 5.33203H16.0827C16.773 5.33203 17.3327 5.89167 17.3327 6.58203C17.3327 7.27239 16.773 7.83203 16.0827 7.83203H1.91602C1.22566 7.83203 0.666016 7.27239 0.666016 6.58203Z"
                    fill="#D1433A"
                  />
                  <path
                    d="M0.666016 11.582C0.666016 10.8917 1.22566 10.332 1.91602 10.332H12.7493C13.4397 10.332 13.9993 10.8917 13.9993 11.582C13.9993 12.2724 13.4397 12.832 12.7493 12.832H1.91602C1.22566 12.832 0.666016 12.2724 0.666016 11.582Z"
                    fill="#D1433A"
                  />
                </svg>

                <!-- <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                     >
                  <rect width="16" height="1.87013" fill="#8B949F"></rect>
                  <rect y="5.61035" width="13.1765" height="1.87013" fill="#8B949F"></rect>
                  <rect y="11.2207" width="9.41176" height="1.87013" fill="#8B949F"></rect>
                </svg> -->
              </div>
              <div
                class="dropdown bootstrap-select show-tick filters-select f-s"
              >
                <select
                  class="filters-select f-s selectpicker"
                  title="Trending"
                  multiple=""
                  data-max-options="1"
                  dropupauto="false"
                  tabindex="-98"
                >
                  <option value="0">Latest</option>
                  <option value="1">Feautured</option>
                  <option value="2">Trending</option>
                  <option value="3">Top Selling</option>
                  <option value="4">High Rating</option>
                  <option value="5">Low Rating</option>
                </select>
                <button
                  type="button"
                  class="btn dropdown-toggle btn-light bs-placeholder"
                  data-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-5"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="Trending"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">
                        {{ selectedSortByFilter }}
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  class="dropdown-menu"
                  style="
                    max-height: 224px;
                    overflow: hidden;
                    min-height: 118px;
                    min-width: 182px;
                    position: absolute;
                    will-change: transform;
                    top: 0px;
                    left: 0px;
                    transform: translate3d(0px, 30px, 0px);
                  "
                  x-placement="bottom-start"
                >
                  <div
                    class="inner show"
                    role="listbox"
                    id="bs-select-5"
                    tabindex="-1"
                    aria-multiselectable="true"
                    style="
                      max-height: 206px;
                      overflow-y: auto;
                      min-height: 100px;
                    "
                  >
                    <ul
                      class="dropdown-menu inner show"
                      role="presentation"
                      style="margin-top: 0px; margin-bottom: 0px"
                    >
                      <li
                        v-for="(category, index) in sortedByCategories"
                        :key="index"
                      >
                        <a
                          @click="
                            selectOrUnSelectValue(category.value, 'sortBy')
                          "
                          :class="{
                            'active selected': isSelectedValue(
                              category.value,
                              'sortBy'
                            ),
                          }"
                          role="option"
                          class="dropdown-item"
                          id="bs-select-5-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">{{ category.name }}</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select
                class="filters-select f-s selectpicker"
                multiple=""
                data-max-options="1"
                title="Songs"
                tabindex="-98"
              >
                <option value="1">Songs</option>
                <option value="2">Albums</option>
                <option value="3">Remixes</option>
              </select>
              <button
                type="button"
                class="btn dropdown-toggle btn-light bs-placeholder"
                data-toggle="dropdown"
                role="combobox"
                aria-owns="bs-select-6"
                aria-haspopup="listbox"
                aria-expanded="false"
                title="Songs"
              >
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">
                      {{ selectedTypeFilter }}
                    </div>
                  </div>
                </div>
              </button>
              <div
                class="dropdown-menu"
                style="
                  max-height: 224px;
                  overflow: hidden;
                  min-height: 0px;
                  position: absolute;
                  will-change: transform;
                  min-width: 182px;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(0px, 30px, 0px);
                "
                x-placement="bottom-start"
              >
                <div
                  class="inner show"
                  role="listbox"
                  id="bs-select-6"
                  tabindex="-1"
                  aria-multiselectable="true"
                  style="max-height: 206px; overflow-y: auto; min-height: 0px"
                >
                  <ul
                    class="dropdown-menu inner show"
                    role="presentation"
                    style="margin-top: 0px; margin-bottom: 0px"
                  >
                    <li
                      v-for="(category, index) in podcastCheck === true
                        ? typeCategory.slice(3, 5)
                        : typeCategory.slice(0, 3)"
                      :key="index"
                    >
                      <a
                        @click="selectOrUnSelectValue(category.value, 'type')"
                        :class="{
                          'active selected': isSelectedValue(
                            category.value,
                            'type'
                          ),
                        }"
                        role="option"
                        class="dropdown-item"
                        id="bs-select-6-0"
                        tabindex="0"
                        aria-selected="false"
                        aria-setsize="3"
                        aria-posinset="1"
                        ><span class="bs-ok-default check-mark"></span
                        ><span class="text">{{ category.name }}</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select
                class="filters-select f-s selectpicker"
                title="Release period"
                multiple=""
                data-max-options="1"
                tabindex="-98"
              >
                <option value="1">Today</option>
                <option value="2">last 7 days</option>
                <option value="3">last 14 days</option>
                <option value="4">last 30 days</option>
                <option value="5">last 90 days</option>
              </select>
              <button
                type="button"
                class="btn dropdown-toggle btn-light bs-placeholder"
                data-toggle="dropdown"
                role="combobox"
                aria-owns="bs-select-7"
                aria-haspopup="listbox"
                aria-expanded="true"
                title="Release period"
              >
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">
                      {{ selectedTimePeriodFilter }}
                    </div>
                  </div>
                </div>
              </button>
              <div
                class="dropdown-menu"
                x-placement="top-start"
                style="
                  max-height: 224px;
                  overflow: hidden;
                  min-height: 118px;
                  min-width: 182px;
                  position: absolute;
                  will-change: transform;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(0px, -141px, 0px);
                "
              >
                <div
                  class="inner show"
                  role="listbox"
                  id="bs-select-7"
                  tabindex="-1"
                  aria-multiselectable="true"
                  style="max-height: 206px; overflow-y: auto; min-height: 100px"
                >
                  <ul
                    class="dropdown-menu inner show"
                    role="presentation"
                    style="margin-top: 0px; margin-bottom: 0px"
                  >
                    <li
                      v-for="(category, index) in releasePeriodCategory"
                      :key="index"
                    >
                      <a
                        @click="
                          selectOrUnSelectValue(category.value, 'timePeriod')
                        "
                        :class="{
                          'active selected': isSelectedValue(
                            category.value,
                            'timePeriod'
                          ),
                        }"
                        role="option"
                        class="dropdown-item"
                        id="bs-select-7-0"
                        tabindex="0"
                        aria-selected="false"
                        aria-setsize="5"
                        aria-posinset="1"
                        ><span class="bs-ok-default check-mark"></span
                        ><span class="text">{{ category.name }}</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              :class="[
                'filters-select f-s for-active custom-border',
                openPriceRange ? 'open' : '',
              ]"
            >
              <div
                class="price-range js-price-range"
                @click="openPriceRange = !openPriceRange"
              >
                <div>{{ selectedMinMaxRange }}</div>
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1 c-fill-1"
                      opacity="0.7"
                      d="M10.7695 1L6.09052 5.75638L0.999512 1"
                      stroke="#47505B"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="price-dropdown">
                <div class="value-row">
                  <div class="min">
                    <input type="text" v-model="this.price.start" />
                  </div>
                  <div class="max">
                    <input type="text" v-model="this.price.end" />
                  </div>
                </div>
                <div
                  id="price-range"
                  class="price-range-slider"
                  style="padding-top: 15px"
                >
                  <vue-slider
                    class="slider"
                    v-model="value"
                    :contained="true"
                    v-bind="options"
                  />
                </div>
              </div>
            </div>
            <div class="dropdown bootstrap-select show-tick filters-select f-s">
              <select
                class="filters-select f-s selectpicker"
                title="Global"
                multiple=""
                data-max-options="1"
                tabindex="-98"
              >
                <option value="1">Global</option>
                <option value="2">Australia</option>
                <option value="3">Brazil</option>
                <option value="4">Canada</option>
                <option value="5">France</option>
                <option value="6">Germany</option>
                <option value="7">Greece</option>
                <option value="8">Italy</option>
                <option value="9">Japan</option>
                <option value="10">Malaysia</option>
                <option value="11">Netherlands</option>
                <option value="12">Russian Federation</option>
                <option value="13">South Africa</option>
                <option value="14">Spain</option>
                <option value="15">Sweden</option>
                <option value="16">Thailand</option>
                <option value="17">United Kingdom</option>
                <option value="18">United States</option>
              </select>
              <button
                type="button"
                class="btn dropdown-toggle btn-light bs-placeholder"
                data-toggle="dropdown"
                role="combobox"
                aria-owns="bs-select-8"
                aria-haspopup="listbox"
                aria-expanded="false"
                title="Global"
              >
                <div class="filter-option">
                  <div class="filter-option-inner">
                    <div class="filter-option-inner-inner">
                      {{ selectedCountry }}
                    </div>
                  </div>
                </div>
              </button>
              <div
                class="dropdown-menu overview-genre-drop-down"
                x-placement="bottom-start"
                style="
                  min-height: 118px;
                  min-width: 182px;
                  position: absolute;
                  will-change: transform;
                  top: 0px;
                  left: 0px;
                  transform: translate3d(0px, 30px, 0px);
                "
              >
                <perfect-scrollbar>
                  <div
                    class="inner show"
                    role="listbox"
                    id="bs-select-8"
                    tabindex="-1"
                    aria-multiselectable="true"
                  >
                    <ul
                      class="dropdown-menu inner show"
                      role="presentation"
                      style="margin-top: 0px; margin-bottom: 0px"
                    >
                      <li>
                        <a
                          @click="selectOrUnSelectValue(null, 'country')"
                          :class="{
                            'active selected': isSelectedValue(null, 'country'),
                          }"
                          role="option"
                          class="dropdown-item"
                          id="bs-select-8-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="18"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">Global</span></a
                        >
                      </li>
                      <li v-for="(country, index) in allCountries" :key="index">
                        <a
                          @click="selectOrUnSelectValue(country.id, 'country')"
                          :class="{
                            'active selected': isSelectedValue(
                              country.id,
                              'country'
                            ),
                          }"
                          role="option"
                          class="dropdown-item"
                          id="bs-select-8-1"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="18"
                          aria-posinset="2"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">{{ country.name }}</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import VueSlider from "vue-slider-component";
export default {
  data() {
    return {
      openPriceRange: false,
      sortedByCategories: [
        { value: "trending", name: "Trending" },
        { value: "latest", name: "Latest" },
        { value: "featured", name: "Featured" },
        { value: "low_rating", name: "Low Rating" },
        { value: "high_rating", name: "High Rating" },
        { value: "top_selling", name: "Top Selling" },
      ],
      typeCategory: [
        { value: "songs", name: "Singles" },
        { value: "albums", name: "Albums" },
        { value: "remixes", name: "Remixes" },
        { value: "music_podcasts", name: "Music Podcasts" },
        { value: "talk_podcasts", name: "Talk Podcasts" },
      ],
      releasePeriodCategory: [
        { value: "0", name: "Today" },
        { value: "7", name: "last 7 days" },
        { value: "14", name: "last 14 days" },
        { value: "30", name: "last 30 days" },
        { value: "90", name: "last 90 days" },
      ],
      options: {
        dotSize: 14,
        width: "150px",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        dataLabel: "label",
        dataValue: "value",
        min: 0,
        max: 50,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "active",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      value: [0, 50],
      sortBy: null,
      type: this.podcastCheck ? "music_podcasts" : "songs",
      timePeriod: null,
      price: {
        start: null,
        end: null,
      },
      country: null,
    };
  },
  props: {
    podcastCheck: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueSlider,
  },
  watch: {
    sortBy: {
      immediate: true,
      handler(val) {
        this.$emit("setType", val, "sort_by");
      },
    },
    type: {
      immediate: true,
      handler(val) {
        this.$emit("setType", val, "type");
      },
    },
    timePeriod: {
      immediate: true,
      handler(val) {
        this.$emit("setType", val, "time_period");
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.price.start = val[0];
        this.price.end = val[1];
      },
    },
    price: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit("setType", val, "price");
      },
    },
    country: {
      immediate: true,
      handler(val) {
        this.$emit("setType", val, "country");
      },
    },
  },
  computed: {
    selectedSortByFilter() {
      if (this.sortBy) {
        const index = this.sortedByCategories.findIndex(
          (e) => e.value === this.sortBy
        );
        return this.sortedByCategories[index].name;
      }
      return "Sort by";
    },
    selectedTypeFilter() {
      if (this.type) {
        const index = this.typeCategory.findIndex((e) => e.value === this.type);
        return this.typeCategory[index].name;
      }
      return "Type";
    },
    selectedMinMaxRange() {
      if (this.price.start && this.price.end) {
        return this.price.start + " / " + this.price.end;
      }
      return "Price Range";
    },
    selectedTimePeriodFilter() {
      if (this.timePeriod) {
        const index = this.releasePeriodCategory.findIndex(
          (e) => e.value === this.timePeriod
        );
        return this.releasePeriodCategory[index].name;
      }
      return "Release period";
    },
    selectedCountry() {
      if (this.country) {
        const index = this.allCountries.findIndex((e) => e.id === this.country);
        return this.allCountries[index].name;
      }
      return "Global";
    },
    allCountries() {
      return this.$store.getters.getCountries;
    },
  },
  methods: {
    selectOrUnSelectValue(value, type) {
      if (this.$data[type]) {
        this.$data[type] = this.$data[type] === value ? null : value;
      } else {
        this.$data[type] = value;
      }
    },
    isSelectedValue(value, type) {
      return this.$data[type] === value;
    },
    toggleClass: function (e) {
      //    e.target.classList.toggle('open');
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1780px) {
  .filters-select {
    width: 160px !important;
  }
}
@media screen and (max-width: 1683px) {
  .filters-select {
    width: 150px !important;
  }
}
.price-range-slider {
  padding-top: 15px;
}
</style>
