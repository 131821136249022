import AppConfig from "../configs/AppConfig";
import * as Utils from "../components/utils/Utils";
import * as UrlParamsHandler from "../components/utils/UrlParamsHandler";
import Helper from "./Helper";
import $axios from "../configs/axios";
import axios from "axios";

const unInterceptedAxiosInstance = axios.create();

const RESOURCE_URL = AppConfig.base_url;

function setToken() {
  try {
    if (localStorage.getItem("token") !== null) {
      $axios.defaults.headers.common.Authorization = localStorage.getItem(
        "token"
      );
    }
  } catch (e) {
    console.log("No Token Provided");
  }
}

const setHeaders = (value = {}) =>
  new Headers({
    Authorization:
      localStorage.getItem("token") !== null
        ? localStorage.getItem("token")
        : "",
    "Content-Type": "application/x-www-form-urlencoded",
    ...value,
  });

const setHeadersForMultipartData = (value = {}) =>
  new Headers({
    ...value,
  });

const UN_INTERCEPTED_POST = async (baseUrl, endpoint, body, headers) => {
  return await axios.post(`${baseUrl}${endpoint}`, body);
};

const GET_OLD = (baseUrl, endpoint, headers) =>
  axios(`${baseUrl}${endpoint}`, {
    method: "GET",
  }).then(Utils.verifyResponse);

const POST = async (baseUrl, endpoint, body, headers) => {
  return await $axios.post(`${baseUrl}${endpoint}`, body);
};

const PUT = (baseUrl, endpoint, body, headers) =>
  $axios(`${baseUrl}${endpoint}`, {
    method: "PUT",
    headers: setHeaders(headers),
    data: JSON.stringify(body),
  }).then(Utils.verifyResponse);

const DELETE = (baseUrl, endpoint, body, headers) =>
  $axios(`${baseUrl}${endpoint}`, {
    method: "DELETE",
    headers: setHeaders(headers),
    data: JSON.stringify(body),
  }).then(Utils.verifyResponse);

const GET = async (baseUrl, endpoint, headers) => {
  return await $axios.get(`${baseUrl}${endpoint}`).catch((error) => {
    console.error(error);
  });
};

export const signup = (body) =>
  unInterceptedAxiosInstance
    .post(`${RESOURCE_URL}/auth/signup`, body)
    .then((response) => response);
// .catch(Utils.handleError);

export const login = (body) =>
  unInterceptedAxiosInstance
    .post(`${RESOURCE_URL}/auth/user/login`, body)
    .then((response) => response);

export const checkEmail = ({ email }) =>
  unInterceptedAxiosInstance
    .get(`${RESOURCE_URL}/auth/validateEmail/${email}`)
    .then((response) => response);

export const verifyEmail = (body) =>
  POST(RESOURCE_URL, `/email-verify`, body).then((response) => response);

export const deleteMedia = (id) =>
  POST(RESOURCE_URL, `/media/delete/${id}`).then((response) => response);

export const getGetStreamAccessToken = (user_id) =>
  GET(RESOURCE_URL, `/getstream/access-token/${user_id}`).then(
    (response) => response
  );
export const getGetStreamMessagingAccessToken = (user_id) =>
  GET(RESOURCE_URL, `/getstream-chat/access-token/${user_id}`).then(
    (response) => response
  );

// all online users
export const getAllOnlineUsers = (body, offset) =>
  POST(RESOURCE_URL, `/all/online/users?offset=${offset}&limit=10`, body)
    .then((response) => response)
    .catch(Utils.handleError);
// POSTS

export const addPost = (body) =>
  POST(RESOURCE_URL, `/posts/add`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const deletePosts = (id) =>
  GET(RESOURCE_URL, `/posts/delete/${id}`).then((response) => response);

export const getPosts = (
  type,
  tag,
  city,
  country,
  followerTag,
  offset,
  limit
) => {
  let followerTagList = "";
  let count = followerTag.length;
  let length = 0;
  for (const index in followerTag) {
    length++;
    followerTagList =
      followerTagList +
      `follower_tag[` +
      index +
      `]=${followerTag[index]}` +
      `${count === length ? "" : "&"}`;
  }
  return GET(
    RESOURCE_URL,
    `/news-feed/all?type=${type}&tag=${tag}&city=${city}&country=${country}&offset=${offset}&limit=${limit}&${
      followerTag && followerTag.length > 0 ? followerTagList : ""
    }`
  ).then((response) => response);
};

export const getUserLikedPosts = (type, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/get/liked/posts?type=${type}&offset=${offset}&limit=${limit}`
  ).then((response) => response);

export const getSavedPosts = () =>
  GET(
    RESOURCE_URL,
    `/posts/getSavedPosts`
  ).then((response) => response);

export const getUserPosts = (type, offset, limit, userId = null, tag) =>
  GET(
    RESOURCE_URL,
    `/my-posts?type=${type}&offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }&tags=${tag}`
  ).then((response) => response);

export const getUserPostsArticleSection = (
  type,
  offset,
  limit,
  userId = null,
  search,
  tag
) =>
  GET(
    RESOURCE_URL,
    `/my-posts?type=${type}&offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }&search=${search}&tags=${tag}`
  ).then((response) => response);

export const getIndividualPost = (post_id) =>
  GET(RESOURCE_URL, `/news-feed/post/${post_id}`).then((response) => response);

export const getPostsTagsList = () =>
  GET(RESOURCE_URL, `/post/tags/all`).then((response) => response);

export const getMentionsList = (query, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/search/user?q=${query}&limit=${limit}&offset=${offset}`
  ).then((response) => response);

export const getMentionsData = (query) =>
  GET(RESOURCE_URL, `/search/all/user-page?q=${query}`).then(
    (response) => response
  );

// POSTS

// PAGES

export const addPage = (body) =>
  POST(RESOURCE_URL, `/create/page`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const updatePageBasicInfo = (id, body) =>
  POST(RESOURCE_URL, `/page/about/update/basic-info/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const updateClubPageInfo = (id, body) =>
  POST(RESOURCE_URL, `/page/update/club-information/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const updatePageAboutAndSkills = (id, body) =>
  POST(RESOURCE_URL, `/page/about/update/skills/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const AddPageProjects = (id, body) =>
  POST(RESOURCE_URL, `/page/about/update/projects/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const updateContactsOFPage = (id, body) =>
  POST(RESOURCE_URL, `/page/about/update/contact/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const updateLocationOFPage = (id, body) =>
  POST(RESOURCE_URL, `/page/about/update/location/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const updatePageProjects = (id, body) =>
  POST(RESOURCE_URL, `/page/about/edit/update/project/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const deletePageProjects = (id) =>
  POST(RESOURCE_URL, `/page/about/delete/project/${id}`).then(
    (response) => response
  );

export const getPagesByType = (
  type,
  offset,
  limit,
  category,
  subcategory,
  location,
  genre,
  sort
) =>
  GET(
    RESOURCE_URL,
    `/get/page/all/${type}?offset=${offset}&limit=${limit}&category=${category}&subcategory=${subcategory}&city=${
      location && location.city ? location.city : ""
    }&country=${
      location && location.country ? location.country : ""
    }&genre=${genre}&sort=${sort}`
  ).then((response) => response);

export const getFavouritePagesByType = (
  type,
  offset,
  limit,
  category,
  subcategory,
  location,
  genre,
  sort
) =>
  GET(
    RESOURCE_URL,
    `/get/favorite/page/${type}?offset=${offset}&limit=${limit}&category=${category}&subcategory=${subcategory}&city=${
      location && location.city ? location.city : ""
    }&country=${
      location && location.country ? location.country : ""
    }&genre=${genre}&sort=${sort}`
  ).then((response) => response);

export const getFavoritePageListing = () =>
  GET(RESOURCE_URL, `/get/my/favorite/list`).then((response) => response);

export const getMyPagesByType = (type, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/get/my-pages/${type}?offset=${offset}&limit=${limit}`
  ).then((response) => response);

export const getAllMessagesPages = () =>
  GET(RESOURCE_URL, `/get/my/messages/page/list`).then((response) => response);

export const getPage = (page_id, offset, limit, pagePostTypeFilter) =>
  GET(
    RESOURCE_URL,
    `/get/page/${page_id}?offset=${offset}&limit=${limit}&type=${pagePostTypeFilter}`
  ).then((response) => response);

export const getCurrentPageMemberRequests = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/members/get/invites/${page_id}?offset=${offset}&limit=${limit}`
  ).then((response) => response);

export const getAnnouncement = (page_id, offset, limit, type) =>
  GET(
    RESOURCE_URL,
    `/page/announcement/${page_id}?offset=${offset}&limit=${limit}&type=${type}`
  ).then((response) => response);

export const getClubMembers = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/get/all/members/${page_id}?offset=${offset}&limit=${limit}`
  ).then((response) => response);

export const getAdminAndModerators = (page_id) =>
  GET(RESOURCE_URL, `/page/member/get/admin/${page_id}`).then(
    (response) => response
  );
export const getPartners = (page_id) =>
  GET(RESOURCE_URL, `/page/partner/list/${page_id}`).then(
    (response) => response
  );

export const getPartnerInvites = (page_id) =>
  GET(RESOURCE_URL, `/page/partner/invite/get/${page_id}`).then(
    (response) => response
  );

export const addOrUpdatePageUser = (body) =>
  POST(RESOURCE_URL, `/page/member/added`, body).then((response) => response);

export const addOrUpdatePartner = (body) =>
  POST(RESOURCE_URL, `/page/partner/invite/send`, body).then(
    (response) => response
  );

export const actionPartnerInvitation = (page_id, body) =>
  POST(RESOURCE_URL, `/page/partner/invite/action/${page_id}`, body).then(
    (response) => response
  );

export const currentPageNotificationSettings = (body, page_id) =>
  POST(RESOURCE_URL, `/page/notification/settings/add/${page_id}`, body).then(
    (response) => response
  );

export const pageMemberApprovalAndRejection = (body) =>
  POST(RESOURCE_URL, `/page/member/request-action`, body).then(
    (response) => response
  );

export const getPageVerification = (page_id) =>
  GET(RESOURCE_URL, `/page/verification/get/${page_id}`).then(
    (response) => response
  );

export const postPageVerification = (page_id, body) =>
  POST(RESOURCE_URL, `/page/verification/update/${page_id}`, body).then(
    (response) => response
  );

export const getPageCategories = () =>
  GET(RESOURCE_URL, `/page/get/category`).then((response) => response);

export const followOrUnFollowPage = (body) =>
  POST(RESOURCE_URL, `/add/page/follow`, body).then((response) => response);

export const addExperienceOnArtistPage = (pageId, body) =>
  POST(RESOURCE_URL, `/page/about/update/experience/${pageId}`, body).then(
    (response) => response
  );

export const updateExperienceOnArtistPage = (experienceId, body) =>
  POST(
    RESOURCE_URL,
    `/page/about/edit/update/experience/${experienceId}`,
    body
  ).then((response) => response);

export const deleteExperienceOnArtistPage = (experienceId, body) =>
  POST(
    RESOURCE_URL,
    `/page/about/delete/experience/${experienceId}`,
    body
  ).then((response) => response);

export const addEducationOnArtistPage = (pageId, body) =>
  POST(RESOURCE_URL, `/page/about/update/education/${pageId}`, body).then(
    (response) => response
  );

export const updateEducationOnArtistPage = (educationId, body) =>
  POST(
    RESOURCE_URL,
    `/page/about/edit/update/education/${educationId}`,
    body
  ).then((response) => response);

export const deleteEducationOnArtistPage = (educationId, body) =>
  POST(RESOURCE_URL, `/page/about/delete/education/${educationId}`, body).then(
    (response) => response
  );

export const setAvailabilityAndRatePage = (pageId, body) =>
  POST(
    RESOURCE_URL,
    `/page/about/update/availability-rate/${pageId}`,
    body
  ).then((response) => response);

export const uploadArtistCoverAndProfilePicture = (body, id) =>
  POST(RESOURCE_URL, `/page/settings/upload-image/${id}`, body).then(
    (response) => response
  );

export const sendMemberInvitationToFriends = (body) =>
  POST(RESOURCE_URL, `/page/club/send/invites`, body).then(
    (response) => response
  );

export const getPageSongAndAlbumByType = (
  type,
  pageID,
  offset,
  limit,
  filter_by,
  search
) =>
  GET(
    RESOURCE_URL,
    `/page/music/get/${type}/${pageID}?offset=${offset}&limit=${limit}${
      filter_by ? "&filter_by=" + filter_by : ""
    }${search ? "&search=" + search : ""}`
  ).then((response) => response);

export const getAutoFillDataByType = (page_id, type) =>
  GET(RESOURCE_URL, `/get/autofill/title/${type}?page_id=${page_id}`).then(
    (response) => response
  );

// export const getPage = (offset,limit) =>
//     GET(RESOURCE_URL, `/news-feed/all?offset=${offset}&limit=${limit}`).then((response) => response)
//
// export const getUserPage= (offset,limit,userId=null) =>
//     GET(RESOURCE_URL, `/my-posts?offset=${offset}&limit=${limit}${(userId)?`&user_id=${userId}`:``}`).then((response) => response)
//
// export const getIndividualPage = (post_id) =>
//     GET(RESOURCE_URL, `/news-feed/post/${post_id}`).then((response) => response)
//
// export const getPageTagsList = () =>
//     GET(RESOURCE_URL, `/post/tags/all`).then((response) => response)
//
// export const getMentionsListPage = (query,offset,limit) =>
//     GET(RESOURCE_URL, `/search/user?q=${query}&limit=${limit}&offset=${offset}`).then((response) => response)

// PAGES

// MARKET
export const uploadSongTemp = (body, progress) =>
  POST(RESOURCE_URL, `/page/music/upload-temp-song`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: function(progressEvent) {
      console.log(
        "Checking Progress =>",
        parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
      );
    }.bind(this),
  }).then((response) => response);

export const uploadPodcastTemp = (body) =>
  POST(RESOURCE_URL, `/page/music/podcast/upload-temp-podcast`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const songUploadDetail = (body) =>
  POST(RESOURCE_URL, `/page/music/upload-song-details`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const podcastUploadDetail = (body) =>
  POST(RESOURCE_URL, `/page/music/podcast/upload-podcast-details`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const getSongAndAlbumDetailByID = (songID, type) =>
  GET(RESOURCE_URL, `/page/music/get/${type}/${songID}`).then(
    (response) => response
  );

export const getSongAndAlbumDetailByIDOpenRoute = (songID, type) =>
  GET(RESOURCE_URL, `/get/data/${type}/${songID}`).then((response) => response);

export const getPodcastDetailByID = (podcastID) =>
  GET(RESOURCE_URL, `/page/music/podcast/get/${podcastID}`).then(
    (response) => response
  );

export const getRelatedSongsAndAlbumsByTheirID = (songID, type) =>
  GET(RESOURCE_URL, `/get/page/${type}/${songID}`).then((response) => response);

export const getSongDetailByID = (songID) =>
  GET(RESOURCE_URL, `/page/music/get/song/${songID}`).then(
    (response) => response
  );

export const getAlbumDetailByID = (albumID) =>
  GET(RESOURCE_URL, `/page/music/get/album/${albumID}`).then(
    (response) => response
  );

export const albumUploadDetail = (body) =>
  POST(RESOURCE_URL, `/page/music/upload-album`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);
// MARKET

export const getActivities = () =>
  GET(RESOURCE_URL, `/post/activities`).then((response) => response);

export const getArticleCategories = () =>
  GET(RESOURCE_URL, `/post/article/category/all`).then((response) => response);

export const uploadProfileAndCover = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/upload-image`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const DeleteUploadedProfileAndCover = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/remove-image`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((response) => response);

export const resendCode = (body) =>
  POST(RESOURCE_URL, `/resend-verification-code`, body).then(
    (response) => response
  );

export const refreshToken = (body) =>
  POST(RESOURCE_URL, `/auth/user/refresh-token`, body).then(
    (response) => response
  );

export const getOtherUserData = (id) =>
  GET(RESOURCE_URL, `/auth/user/profile/${id}`).then((response) => response);

export const restorePassword = ({ email, code }) =>
  GET(
    RESOURCE_URL,
    `/registration_confirmed.php?code=${code}&email=${email}`
  ).then((response) => response);

export const getFacebookData = (url) =>
  GET(`${url}`, "").then((response) => response.json());

export const basic_info = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/profile`, body).then(
    (response) => response
  );

export const trackPlayed = (body) =>
  POST(RESOURCE_URL, `/stream/on-track-played/${body.id}`, body).then(
    (response) => response
  );

export const change_password_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/password`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const get_ip_address = () =>
  GET_OLD(`https://api.ipify.org?format=json`, "")
    .then((response) => response.json())
    .catch(Utils.handleError);

export const get_user_session_list = (query) =>
  GET(RESOURCE_URL, `/auth/user/sessions?page=${query}`).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const get_user = () =>
  GET(RESOURCE_URL, `/auth/user/get-user`).then((response) => response);

export const get_geners_list = () =>
  GET(RESOURCE_URL, `/genres/all`).then((response) => response);

export const get_countries_list = () =>
  GET(RESOURCE_URL, `/country/all`).then((response) => response);

export const get_podcast_categories = () =>
  GET(RESOURCE_URL, `/podcast-categories`).then((response) => response);

export const get_artists_list = (query) =>
  GET(RESOURCE_URL, `/artists/all${query}`).then((response) => response);

export const facebook_login = (query) =>
  POST(RESOURCE_URL, `/social_login.php${query}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const update_profile_photo = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/upload-image`, body).then(
    (response) => response
  );

export const delete_session = (query) =>
  POST(RESOURCE_URL, `/auth/user/rovoke-session/${query}`).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const update_information = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/profile`, body).then(
    (response) => response
  );

export const forgotPassword = (body) =>
  POST(RESOURCE_URL, `/send-password`, body).then((response) => response);
// .catch(Utils.handleError);

export const reset_password_api = (body) =>
  POST(RESOURCE_URL, `/forgot-password`, body).then((response) => response);
// .catch(Utils.handleError);

export const profile_completed = async () =>
  await GET(RESOURCE_URL, `/auth/user/profile-complete`).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const update_geners_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/genres`, body).then(
    (response) => response
  );

export const update_subgeners_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/subgenres`, body).then(
    (response) => response
  );

export const update_artists_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/artist`, body).then(
    (response) => response
  );

export const udpate_account_settings_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/update-username`, body).then(
    (response) => response
  );

export const availability_and_rate_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/availability`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const location_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/location`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const get_company_info_api = (body) =>
  GET(RESOURCE_URL, `/auth/user/get/company-info`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const companyInfo = (body) =>
  POST(RESOURCE_URL, `/auth/user/add/company-info`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const social_link_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/user-social-links`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const your_profile_privacy_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/profile/privacy`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const your_profile_activity_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/privacy`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const update_social_notification_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/social/notifications`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const update_music_notification_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/music/notifications`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const user_verification_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/verifications`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const get_languages_api = () =>
  GET(RESOURCE_URL, `/language/all`).then((response) => response);
// .catch(Utils.handleError);

export const get_regions_api = () =>
  GET(RESOURCE_URL, `/region/all`).then((response) => response);
// .catch(Utils.handleError);

export const update_language_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/language-and-region`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const degree_tool_skill_api = () =>
  GET(RESOURCE_URL, `/degree_tool_skill`).then((response) => response);
// .catch(Utils.handleError);

export const add_update_about_and_skills_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/update-user-about-info`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const get_about_and_skills_api = (query) =>
  GET(RESOURCE_URL, `/about_and_skills.php${query}?t=${Date.now()}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const user_education_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/education`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const edit_user_education_api = (body, query) =>
  POST(RESOURCE_URL, `/auth/user/settings/education/${query}`, body).then(
    (response) => response
  );

export const delete_user_education_api = (query) =>
  POST(RESOURCE_URL, `/auth/user/settings/education/${query}/delete`).then(
    (response) => response
  );

export const get_user_education_api = (offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/settings/education?offset=${offset}&limit=${limit}`
  ).then((response) => response);
// .catch(Utils.handleError);

export const get_user_work_api = (offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/settings/experience?offset=${offset}&limit=${limit}`
  ).then((response) => response);
// .catch(Utils.handleError);

export const set_favourite_on_songs = (body) =>
  POST(RESOURCE_URL, `/auth/user/add-favorite`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const user_work_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/experience`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const edit_user_work_api = (body, query) =>
  POST(RESOURCE_URL, `/auth/user/settings/experience/${query}`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const delete_user_work_api = (query) =>
  POST(RESOURCE_URL, `/auth/user/settings/experience/${query}/delete`).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const block_user_listing_api = (query) =>
  POST(RESOURCE_URL, `/user_block.php${query}&t=${Date.now()}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const audio_settings_api = (body) =>
  POST(RESOURCE_URL, `/auth/user/settings/user-audio-settings`, body).then(
    (response) => response
  );
// .catch(Utils.handleError);

export const addCard = (query) =>
  POST(RESOURCE_URL, `/payments.php`, query)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const updateConnectedAccount = (query) =>
  POST(RESOURCE_URL, `/payments.php`, query)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getBankDetails = (query) =>
  POST(RESOURCE_URL, `/payments.php`, query)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getCountry = () =>
  POST(RESOURCE_URL, `/country.php`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const addTransfer = (query) =>
  POST(RESOURCE_URL, `/payments.php`, query)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getAllTransfers = (query) =>
  POST(RESOURCE_URL, `/payments.php`, query)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getAllCardDetails = (query) =>
  POST(RESOURCE_URL, `/payments.php`, query)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getUserAffilatedLink = () =>
  GET(RESOURCE_URL, `/auth/user/affiliate`).then((response) => response);
// .catch(Utils.handleError);

export const getReferedUsers = (query, page) =>
  GET(
    RESOURCE_URL,
    `/auth/user/affiliate/users?term=${query}&page=${page}`
  ).then((response) => response);
// .catch(Utils.handleError);

export const deleteUserAccount = () =>
  GET(RESOURCE_URL, `/auth/user/delete`).then((response) => response);
// .catch(Utils.handleError);

export const search_affliate_users_api = (query) =>
  POST(RESOURCE_URL, `/search.php${query}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const create_post_api = (query) =>
  POST(RESOURCE_URL, `/post.php${query}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const get_article_categories = () =>
  GET(RESOURCE_URL, `/blog_category.php?t=${Date.now()}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const create_new_article_api = (body) =>
  POST(RESOURCE_URL, `/post_article.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const create_post_poll_api = (query) =>
  POST(RESOURCE_URL, `/post_poll.php${query}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const create_album_post_api = (body) =>
  POST(RESOURCE_URL, `/post_media.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const post_api = (query) =>
  POST(RESOURCE_URL, `/post.php${query}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const pin_post = (post_id, type, action, post_tab='') =>
  POST(
    RESOURCE_URL,
    `/auth/user/post/activity?post_id=${post_id}&type=${type}&action=${action}&post_tab=${post_tab}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const savePost = (postId) => 
  POST(
    RESOURCE_URL,
    `/posts/savePost/${postId}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const unsavePost = (postId) => 
  POST(
    RESOURCE_URL,
    `/posts/deleteSavedPost/${postId}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const post_comment_api = (query) =>
  POST(RESOURCE_URL, `/posts_comments.php${query}`)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const post_comment_form_data_api = (body) =>
  POST(RESOURCE_URL, `/posts_comments.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getMoods = (body) =>
  GET(RESOURCE_URL, `/moods/all`).then((response) => response);

export const continueListeningTo = () =>
  GET(
    RESOURCE_URL,
    `/fetch_music.php?type=1&data_limit=15&start_point=0&user_id=1`
  )
    .then((response) => response.json())
    .catch(Utils.handleError);

export const getChannelData = (
  pageFor,
  genres,
  subGenres,
  moods,
  talks,
  sort_by,
  type,
  time_period,
  start_price,
  end_price,
  country,
  startDate,
  endDate
) => {
  let genreList = "";
  for (const index in genres) {
    genreList = genreList + `genre[` + index + `]=${genres[index]}&`;
  }
  let subGenreList = "";
  for (const index in subGenres) {
    subGenreList =
      subGenreList + `subgenre[` + index + `]=${subGenres[index]}&`;
  }
  let moodList = "";
  for (const index in moods) {
    moodList = moodList + `mood[` + index + `]=${genres[index]}&`;
  }
  let talkList = "";
  for (const index in talks) {
    talkList = talkList + `talk[` + index + `]=${talks[index]}&`;
  }
  return GET(
    RESOURCE_URL,
    `/music/channel/${pageFor}?offset=0&limit=18&${
      genres && genres.length > 0 ? genreList : ""
    }${moods && moods.length > 0 ? moodList : ""}${
      talks && talks.length > 0 ? talkList : ""
    }${subGenres && subGenres.length > 0 ? subGenreList : ""}${
      startDate && endDate
        ? "start_date=" + startDate + "&end_date=" + endDate
        : ""
    }${type ? "&type=" + type : ""}${sort_by ? "&sort_by=" + sort_by : ""}${
      time_period ? "&time_period=" + time_period : ""
    }${start_price ? "&start_price=" + start_price : ""}${
      end_price ? "&end_price=" + end_price : ""
    }${country ? "&country=" + country : ""}`
  )
    .then((response) => response)
    .catch(Utils.handleError);
};

export const getTrendingList = (
  offset,
  limit,
  country = "",
  follow_artist = 0,
  follow_event = 0
) =>
  GET(
    RESOURCE_URL,
    `/tags/trending?offset=${offset}&limit=${limit}&country=${country}&follow_artist=${follow_artist}&follow_event=${follow_event}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getFavoriteSongsOfUsers = (query) =>
  GET(RESOURCE_URL, `/auth/user/favorite/${query}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getMusicListOfPage = (query) =>
  GET(RESOURCE_URL, `/page/music/get/data/${query}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaPhotosOfUsers = (offset, limit, userId = null) =>
  GET(
    RESOURCE_URL,
    `/auth/user/all-photos?offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaPhotosOfPages = (offset, limit, page_id) =>
  GET(
    RESOURCE_URL,
    `/page/media/all-photos?offset=${offset}&limit=${limit}&page_id=${page_id}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getSelectedPhotos = (album, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/album-photos/${album}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getPageSelectedPhotos = (album, offset, limit, id) =>
  GET(
    RESOURCE_URL,
    `/page/media/album-photos/${album}?page_id=${id}&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaVideosOfUsers = (offset, limit, userId = null) =>
  GET(
    RESOURCE_URL,
    `/auth/user/all-videos?offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaVideosOfPosts = (
  offset,
  limit,
  userId = null,
  page_id
) =>
  GET(
    RESOURCE_URL,
    `/page/media/all-videos?offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }&page_id=${page_id}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaPhotosAlbumsOfUsers = (userId = null) =>
  GET(
    RESOURCE_URL,
    `/auth/user/default-albums${userId ? `?user_id=${userId}` : ``}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaPhotosAlbumsOfPages = (page_id) =>
  GET(RESOURCE_URL, `/page/media/default-albums?page_id=${page_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaAlbumsOfUsers = (offset, limit, userId = null) =>
  GET(
    RESOURCE_URL,
    `/auth/user/all-albums?offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getAllMediaAlbumsOfPages = (
  offset,
  limit,
  userId = null,
  page_id
) =>
  GET(
    RESOURCE_URL,
    `/page/media/all-albums?offset=${offset}&limit=${limit}${
      userId ? `&user_id=${userId}` : ``
    }&page_id=${page_id}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const editComment = (body) =>
  POST(RESOURCE_URL, `/page_post_comments.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const create_post_comment_api = (body) =>
  POST(RESOURCE_URL, `/page_post_comments.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const reactPost = (body) =>
  POST(RESOURCE_URL, `/reaction/react`, body).then((response) => response);

export const getCommentsOfPost = (body) =>
  POST(RESOURCE_URL, `/comments/get`, body).then((response) => response);

export const getReviewsOfSongsAlbumsPodcast = (
  reviewable_type,
  reviewable_id
) =>
  GET(
    RESOURCE_URL,
    `/page/music/review/get?reviewable_type=${reviewable_type}&reviewable_id=${reviewable_id}`
  ).then((response) => response);

export const getFansOfSongsAlbumsPodcast = (song_id) =>
  GET(RESOURCE_URL, `/page/get/song/fans/${song_id}`).then(
    (response) => response
  );

export const addCommentsOfPost = (body) =>
  POST(RESOURCE_URL, `/comments/add`, body).then((response) => response);

export const addReviewOnSongAlbumPodcast = (body) =>
  POST(RESOURCE_URL, `/page/music/review/create`, body).then(
    (response) => response
  );

export const addReplyOnComment = (body) =>
  POST(RESOURCE_URL, `/comments/add/reply`, body).then((response) => response);

export const getReplyOfComment = (body, offset = 0, limit = 3) =>
  POST(
    RESOURCE_URL,
    `/comments/replies/get?offset=${offset}&limit=${limit}`,
    body
  ).then((response) => response);

export const page_post_comment_api = (body) =>
  POST(RESOURCE_URL, `/page_post_comments.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const create_post_comment_like_api = (body) =>
  POST(RESOURCE_URL, `/page_post_comment_reaction.php`, body)
    .then((response) => response.json())
    .catch(Utils.handleError);

export const poll_vote_api = (body) =>
  POST(RESOURCE_URL, `/posts/add/vote`, body).then((response) => response);

export const getPageData = (
  pageFilter,
  limit,
  offset,
  filterApplied,
  country,
  type
) =>
  GET(
    RESOURCE_URL,
    `/channel/${pageFilter}?limit=${limit}&offset=${offset}&sort_by=${filterApplied}${
      country ? "&country=" + country : ""
    }${type ? "&type=" + type : ""}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getPageUserRoles = () =>
  GET(RESOURCE_URL, `/page/member/roles`).then((response) => response);

export const getPagePartnerRoles = () =>
  GET(RESOURCE_URL, `/page/partner/get/role`).then((response) => response);

export const searchPartners = (page_id, search) =>
  POST(RESOURCE_URL, `/page/partner/search/${page_id}?title=${search}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const searchArtists = (type, name) =>
  GET(RESOURCE_URL, `/get/page/search/${type}?search=${name}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const addToFavorite = (user_id, ids, type, favorite) =>
  GET(
    RESOURCE_URL,
    `/favorite.php?user_id=` +
      user_id +
      `&ids=` +
      ids +
      `&type=` +
      type +
      `&favorite=` +
      favorite
  )
    .then((response) => response.json())
    .catch(Utils.handleError);

export const addLove = (user_id, ids, type, love) =>
  GET(
    RESOURCE_URL,
    `/favorite.php?user_id=` +
      user_id +
      `&ids=` +
      ids +
      `&type=` +
      type +
      `&love=` +
      love
  )
    .then((response) => response.json())
    .catch(Utils.handleError);

//get friends suggestion:
export const getFriendsSuggestion = (offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/suggestions?offset=${offset}&limit=${limit}`
  ).then((response) => response);

export const exploreFriends = (offset, limit, search, city, work, school) =>
  GET(RESOURCE_URL, `/auth/user/country?offset=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch(Utils.handleError);
//get friends request:
export const getPendingFriendRequests = (offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/friend/requests/pending?offset=${offset}&limit=${limit}`
  ).then((response) => response);

//get sent requests:
export const getSentRequests = (offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/friend/requests/send?offset=${offset}&limit=${limit}`
  ).then((response) => response);

// add friend :
export const addFriendApi = (body) =>
  POST(RESOURCE_URL, `/auth/user/send/friend-request`, body).then(
    (response) => response
  );

// accept friend request :
export const responseFriendRequest = (body) =>
  POST(RESOURCE_URL, `/auth/user/response/friend-request`, body).then(
    (response) => response
  );

// remove suggested friend :
export const removeSuggestedFriend = (body) =>
  POST(RESOURCE_URL, `/auth/user/suggestions/remove`, body).then(
    (response) => response
  );

//Remove Sent Request:
export const removeSentRequest = (body) =>
  POST(RESOURCE_URL, `/auth/user/friend/delete`, body).then(
    (response) => response
  );

// Birthdays Request:
export const getBirthdays = (day, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/birthdays?day=${day}&offset=${offset}&limit=${limit}`
  ).then((response) => response);
//Search Friends:
export const searchFriends = (searchKey) =>
  GET(RESOURCE_URL, `/auth/user/country?search=${searchKey}`)
    .then((response) => response)
    .catch(Utils.handleError);
//Get  Cities:
export const getCities = () =>
  GET(RESOURCE_URL, `/auth/user/city`)
    .then((response) => response)
    .catch(Utils.handleError);
//Get  Work Place:
export const getWorkPlace = () =>
  GET(RESOURCE_URL, `/auth/user/work-place`)
    .then((response) => response)
    .catch(Utils.handleError);
//Get  Schools:
export const getSchools = () =>
  GET(RESOURCE_URL, `/auth/user/school`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getfilteredUsers = (offset, limit, search, work, city, school) =>
  GET(
    RESOURCE_URL,
    `/auth/user/country?offset=${offset}&limit=${limit}&search=${search}&city=${city}&work=${work}&school=${school}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getMonthBirthdays = (date) =>
  GET(RESOURCE_URL, `/auth/user/birthdays?date=${date}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getMyFriends = (offset, limit) =>
  GET(RESOURCE_URL, `/auth/user/my-friends?offset=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getArtistPageFollowers = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/get/followers/${page_id}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getArtistPageFollowings = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/get/followings/${page_id}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getArtistPageMutualFollowings = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/get/mutuals/${page_id}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const searchArtistFollowers = (
  page_id,
  offset,
  limit,
  search_type,
  name
) =>
  GET(
    RESOURCE_URL,
    `/page/get/search/${page_id}?offset=${offset}&limit=${limit}&search_type=${search_type}&name=${name}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const getMyFollowers = (offset, limit) =>
  GET(RESOURCE_URL, `/auth/user/my-followers?offset=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const getMyFollowings = (offset, limit) =>
  GET(RESOURCE_URL, `/auth/user/my-followings?offset=${offset}&limit=${limit}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const changeFollowStatus = (body) =>
  POST(RESOURCE_URL, `/auth/user/add-favorite`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const updateFriendStatus = (body) =>
  POST(RESOURCE_URL, `/auth/user/update/friends-status`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const searchProfileFriends = (offset, limit, search_type, name) =>
  GET(
    RESOURCE_URL,
    `/auth/user/search-friend?offset=${offset}&limit=${limit}&search_type=${search_type}&name=${name}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

export const pageChangeUserFollowStatus = (body) =>
  POST(RESOURCE_URL, `/add/page/follow`, body).then((response) => response);

// Page Basic Settings:
export const pageBasicSettings = (body, page_id) =>
  POST(RESOURCE_URL, `/page/settings/basic/${page_id}`, body).then(
    (response) => response
  );
export const clubPageGeneralSettings = (body, page_id) =>
  POST(RESOURCE_URL, `/page/update/general-settings/${page_id}`, body).then(
    (response) => response
  );

// Delete Page:
export const pageDelete = (body, page_id) =>
  POST(RESOURCE_URL, `/page/delete/${page_id}`, body).then(
    (response) => response
  );
// Page Work Settings:
export const pageWorkSetting = (body, page_id) =>
  POST(RESOURCE_URL, `/page/settings/work/${page_id}`, body).then(
    (response) => response
  );
//system
export const getCountries = () =>
  GET(RESOURCE_URL, `/get/country`).then((response) => response);

export const getUserRoles = () =>
  GET(RESOURCE_URL, `/roles`).then((response) => response);

export const getCitiesOfCountry = (id) =>
  GET(RESOURCE_URL, `/get/country/cities?country_id=${id}`).then(
    (response) => response
  );
//system

//Page Similar Artists:
export const getPageSimilarArtists = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/get/page/similar-artist/${page_id}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

//Page Similar Clubs:
export const getSimilarClubs = (page_id, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/get/similar-clubs/${page_id}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);
//get page events:
export const getPageEvents = (page_id, offset, limit, type, search) =>
  GET(
    RESOURCE_URL,
    `/event/get/page/${page_id}?offset=${offset}&limit=${limit}&type=${type}&search=${search}`
  )
    .then((response) => response)
    .catch(Utils.handleError);
//get music filtered data:
export const getMusicFilteredData = (type, page_id, filter_by, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/page/music/get/${type}/${page_id}?filter_by=${filter_by}&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

//get continuelistening songs filter:
export const getContinueListeningSongs = (filter, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/channel/continue-listening-to?filter=${filter}&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);
// create playlist:
export const createPlaylist = (body) =>
  POST(RESOURCE_URL, `/music/playlist/create`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// get my playlist & search playlist:
export const getMyPlaylists = (term, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/music/playlist/get/my?term=${term}&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);
//user playlist
export const getUserPlaylists = (type, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/music/playlist/all?type=${type}&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// get songs and albums and search:
export const getSongsAndAlbums = (term, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/search/songAlbum?term=${term}&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// Add Songs to Playlist :
export const addSongToPlaylist = (body) =>
  POST(RESOURCE_URL, `/music/playlist/addSong`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Events
// Get All User list for send invites
export const getAllUserListForSendInvites = () =>
  GET(RESOURCE_URL, `/all/users/list`)
    .then((response) => response)
    .catch(Utils.handleError);

// get getCountries List
export const getCountriesList = () =>
  GET(RESOURCE_URL, `/get/country`)
    .then((response) => response)
    .catch(Utils.handleError);

//get geners
export const getGeners = () =>
  GET(RESOURCE_URL, `/genres/all`)
    .then((response) => response)
    .catch(Utils.handleError);

// add event searech contry and city
export const addSearchHistoryCityContry = (body) =>
  POST(RESOURCE_URL, `/add-search-history`, body)
    .then((response) => response)
    .catch(Utils.handleError);

//single event details
export const getSingleEventDetails = (event_id) =>
  GET(RESOURCE_URL, `/event/get/details/${event_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// buy tickets
export const buyTickets = (event_id) =>
  GET(RESOURCE_URL, `/event/get/tickets/${event_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// get event searech contry and city
export const getSearchHistoryCityContry = () =>
  GET(RESOURCE_URL, `/get-search-history`)
    .then((response) => response)
    .catch(Utils.handleError);

// My Favorites Events
export const myFavoritesEvents = (params) =>
  GET(
    RESOURCE_URL,
    `/event/get/favorite/${params.value}?${
      params.start_date ? "&start_date=" + params.start_date : ""
    }${params.end_date ? "&end_date=" + params.end_date : ""}${
      params.start_price ? "&start_price=" + params.start_price : ""
    }${params.end_price ? "&end_price=" + params.end_price : ""}${
      params.sort_by ? "&sort_by=" + params.sort_by : ""
    }${params.venue_type ? "&venue_type=" + params.venue_type : ""}${
      params.Genre
        ? UrlParamsHandler.getArrayOfParamsAsStr(params.Genre, "genre[]")
        : ""
    }${params.country ? "&country=" + params.country : ""}${
      params.city ? "&city=" + params.city : ""
    }&offset=0&limit=${params.limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// My Invites Events
export const myInvitesEvents = (limit) =>
  GET(RESOURCE_URL, `/event/get/invites?offset=0&limit=${limit}`)
    .then((response) => response)
    .catch(Utils.handleError);

// My Events Decline Invite
export const declineInvite = (invite_id) =>
  POST(RESOURCE_URL, `/event/invite/action/${invite_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// My Events My Tickets
export const myTickets = (params) =>
  GET(
    RESOURCE_URL,
    `/event/get/my-tickets/${params.value}?${
      params.start_date ? "&start_date=" + params.start_date : ""
    }${params.end_date ? "&end_date=" + params.end_date : ""}${
      params.sort_by ? "&sort_by=" + params.sort_by : ""
    }${params.country ? "&country=" + params.country : ""}${
      params.city ? "&city=" + params.city : ""
    }&offset=0&limit=${params.limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

//My Events Get My Order Single
export const getSingleOrder = (params) =>
  GET(
    RESOURCE_URL,
    `/event/order/get?order_id=${params.order_id}&ticket_id=${params.ticket_id}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

//My Events Get similar event
export const getSimilarEvent = (params) =>
  GET(
    RESOURCE_URL,
    `/similar/get/event?${
      params.Genre
        ? UrlParamsHandler.getArrayOfParamsAsStr(params.Genre, "genre[]")
        : ""
    }${params.country ? "&country=" + params.country : ""}${
      params.city ? "&city=" + params.city : ""
    }&offset=0&limit=6`
  )
    .then((response) => response)
    .catch(Utils.handleError);
// Add to Cart
export const addToCart = (body) =>
  POST(RESOURCE_URL, `/event/cart/add-to-cart`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Add contact info
export const addContactInfo = (body) =>
  POST(RESOURCE_URL, `/event/contact/add-info`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Promo Verification
export const promoVerification = (event_id, body) =>
  POST(RESOURCE_URL, `/event/verify/promo/${event_id}`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Place Order
export const placeOrder = (body) =>
  POST(RESOURCE_URL, `/event/order/place`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Get My Order Single
export const getMyOrderSingle = () =>
  GET(RESOURCE_URL, `/event/order/get`)
    .then((response) => response)
    .catch(Utils.handleError);

// Send Ticket Via Email
export const sendTicketViaEmail = (ticket_id, body) =>
  POST(RESOURCE_URL, `/event/order/send/ticket-via-email/${ticket_id}`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Remove cart item
export const removeCart = (cart_id) =>
  POST(RESOURCE_URL, `/event/cart/remove/item/${cart_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// Remove contact info
export const removeContactInfo = (contact_id) =>
  POST(RESOURCE_URL, `/event/contact/remove/item/${contact_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// My cart items
export const myCartItems = () =>
  GET(RESOURCE_URL, `/event/cart/my-cart`)
    .then((response) => response)
    .catch(Utils.handleError);

// Get my contact info
export const GetMyContactInfo = () =>
  GET(RESOURCE_URL, `/event/contact/my-contact-info`)
    .then((response) => response)
    .catch(Utils.handleError);

// get Venue Types
export const getVenueTypes = () =>
  GET(RESOURCE_URL, `/event/get/venue-type`)
    .then((response) => response)
    .catch(Utils.handleError);

// get Event Types
export const getEventTypes = () =>
  GET(RESOURCE_URL, `/event/get/event-type`)
    .then((response) => response)
    .catch(Utils.handleError);

// Get events by type
export const getEventsByType = (params) =>
  GET(
    RESOURCE_URL,
    `/get/explore/events/${params.type}?offset=${params.offset}&limit=${
      params.limit
    }&sort_by=${params.sort_by}${
      params.event_type ? `&event_type=${params.event_type}` : ''
    }`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// explor all Events
export const explorEvents = (params) => {
  if (params) {
    return GET(
      RESOURCE_URL,
      `/explore/events?${
        params.event_type ? "&event_type=" + params.event_type : ""
      }${params.start_date ? "&start_date=" + params.start_date : ""}${
        params.end_date ? "&end_date=" + params.end_date : ""
      }${params.start_price ? "&start_price=" + params.start_price : ""}${
        params.end_price ? "&end_price=" + params.end_price : ""
      }${params.sort_by ? "&sort_by=" + params.sort_by : ""}${
        params.venue_type ? "&venue_type=" + params.venue_type : ""
      }${
        params.Genre
          ? UrlParamsHandler.getArrayOfParamsAsStr(params.Genre, "genre[]")
          : ""
      }${params.search ? "&search=" + params.search : ""}${
        params.country ? "&country=" + params.country : ""
      }${params.city ? "&city=" + params.city : ""}${
        params.date ? "&date=" + params.date : ""
      }`
    )
      .then((response) => response)
      .catch(Utils.handleError);
  } else {
    return GET(RESOURCE_URL, `/explore/events`)
      .then((response) => response)
      .catch(Utils.handleError);
  }
};

// My Events Past and InComing
export const userEvents = (value, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/my-events/${value}?&offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);
// Api to Join Page
export const requestToJoinClubPage = (body) =>
  POST(RESOURCE_URL, `/page/join`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => response)
    .catch(Utils.handleError);
//

// create new event

export const createNewEvent = (body) =>
  POST(RESOURCE_URL, `/create/event`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => response)
    .catch(Utils.handleError);

// Interested/Going
export const InterestedGoing = (body) =>
  POST(RESOURCE_URL, `/event/add/user/interest`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Music Favorites:
export const getMusicFavorites = (type, offset, limit) =>
  GET(
    RESOURCE_URL,
    `/auth/user/favorite/${type}?offset=${offset}&limit=${limit}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

//Overall Search Api
export const getOverallSearchResult = (search) =>
  GET(RESOURCE_URL, `/search/suggest?${search}`).then((response) => response);

//Listened Song Activity
export const getListenedSongsForPost = (offset, limit) =>
  GET(
    RESOURCE_URL,
    `/get/recent/activity?offset=${offset}&limit=${limit}`
  ).then((response) => response);

// Article page

// filter Article
export const filterArticle = ({ type, sort_by, created = 0, saved = 0 }) =>
  GET(
    RESOURCE_URL,
    `/my-posts?type=${type}&sort_by=${sort_by}&offset=0&limit=10&created=${created}&saved=${saved}`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// Forum page
// All Forums
export const getAllForums = () =>
  GET(RESOURCE_URL, `/forums/get/all`)
    .then((response) => response)
    .catch(Utils.handleError);

//get statistics
export const getStatisticsForums = () =>
  GET(RESOURCE_URL, `/forums/statistics`)
    .then((response) => response)
    .catch(Utils.handleError);

// create new Thread
export const createNewThread = (body) =>
  POST(RESOURCE_URL, `/forums/create/thread`, body)
    .then((response) => response)
    .catch(Utils.handleError);

//  post Reply On Thread
export const postReplyOnThread = (body) =>
  POST(RESOURCE_URL, `/forums/thread/post-reply`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Add View On Thread
export const AddViewOnThread = (thread_id) =>
  POST(RESOURCE_URL, `/forums/add/thread/view/${thread_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// Report Reply
export const ReportReply = (reply_id) =>
  POST(RESOURCE_URL, `/forums/report/reply/${reply_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

//Edit  Reply
export const EditReply = (reply_id, body) =>
  POST(RESOURCE_URL, `/forums/update/reply/${reply_id}`, body)
    .then((response) => response)
    .catch(Utils.handleError);

//delete  Reply
export const deleteReply = (reply_id) =>
  POST(RESOURCE_URL, `/forums/delete/reply/${reply_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

//Edit  Thread
export const EditThread = (thread_id, body) =>
  POST(RESOURCE_URL, `/forums/update/thread/${thread_id}`, body)
    .then((response) => response)
    .catch(Utils.handleError);

//delete Thread
export const deleteThread = (thread_id) =>
  POST(RESOURCE_URL, `/forums/delete/thread/${thread_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// Get Forum Threads
export const getForumThreads = (forum_id, offset) =>
  GET(
    RESOURCE_URL,
    `/forums/get/forum-threads/${forum_id}?offset=${offset}&limit=10`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// Get My Forum Threads
export const getMyThread = (offset) =>
  GET(RESOURCE_URL, `/forums/my-threads?offset=${offset}&limit=10`)
    .then((response) => response)
    .catch(Utils.handleError);

// Get Thread
export const getThread = (thread_id, offset) =>
  GET(RESOURCE_URL, `/forums/get/thread/${thread_id}?offset=${offset}&limit=10`)
    .then((response) => response)
    .catch(Utils.handleError);

// get My Replies
export const getMyReplies = (offset) =>
  GET(RESOURCE_URL, `/forums/my-replies?offset=${offset}&limit=10`)
    .then((response) => response)
    .catch(Utils.handleError);

// Search forum
export const getSearchForumResult = (searchParams, offset) =>
  GET(
    RESOURCE_URL,
    `/forums/search?keyword=${searchParams.keyword}&search_for=${searchParams.search_for}&forum=${searchParams.forum}&offset=${offset}&limit=10`
  )
    .then((response) => response)
    .catch(Utils.handleError);

// get forum Name
export const getForumsName = () =>
  GET(RESOURCE_URL, `/forums/get/name`)
    .then((response) => response)
    .catch(Utils.handleError);

// Accept and Decline Member Request
export const acceptAndDeclineMemberRequest = (body) =>
  POST(RESOURCE_URL, `/page/join/action`, body).then((response) => response);
// Edit Post
export const editPost = (body, post_id) =>
  POST(RESOURCE_URL, `/posts/edit/${post_id}`, body).then(
    (response) => response
  );

export const onOfCommenting = (body, post_id) =>
  POST(RESOURCE_URL, `/post/action/comment/${post_id}`, body).then(
    (response) => response
  );

export const getCollaboratorsRoles = () =>
  GET(RESOURCE_URL, `/page/collaborator/get/role`).then((response) => response);

export const deleteComments = (id) =>
  GET(RESOURCE_URL, `/comments/delete/${id}`).then((response) => response);

export const reportPosts = (body) =>
  POST(RESOURCE_URL, `/report/post`, body).then((response) => response);

// Twilio Api's

export const getTwilioAccessToken = () =>
  GET(RESOURCE_URL, `/twilio/access_token`)
    .then((response) => response)
    .catch(Utils.handleError);

export const createTwilioAudioRoom = (body) =>
  POST(RESOURCE_URL, `/twilio/create/audio-room`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const getTwilioRoom = (s_id) =>
  GET(RESOURCE_URL, `/twilio/get/room/${s_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const twilioGrantAccessRoom = (body) =>
  POST(RESOURCE_URL, `/twilio/get/grant-access-room`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const createTwilioPlayerStream = () =>
  GET(RESOURCE_URL, `/twilio/create/player-stream`)
    .then((response) => response)
    .catch(Utils.handleError);

export const endTwilioPlayerStream = (player_stream_id) =>
  GET(RESOURCE_URL, `/twilio/end/player-stream/${player_stream_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const createTwilioMediaProcessor = (body) =>
  POST(RESOURCE_URL, `/twilio/create/media-processor`, body)
    .then((response) => response)
    .catch(Utils.handleError);

export const endTwilioMediaProcessor = (player_stream_id) =>
  GET(RESOURCE_URL, `/twilio/end/media-processor/${player_stream_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const twilioPlaybackGrant = (player_stream_id) =>
  GET(RESOURCE_URL, `/twilio/playback-grants/${player_stream_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const twilioGrantAccessToken = (playback_grant_access_id) =>
  GET(RESOURCE_URL, `/twilio/playback-grants-token/${playback_grant_access_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const sharePostViaEmail = (body) =>
  POST(RESOURCE_URL, `/share/post/email`, body).then((response) => response);

// action page Invite :
export const responsePageInvite = (body) =>
  POST(RESOURCE_URL, `/page/join/action`, body).then((response) => response);

// friends list without page members :
export const getFriendsListWithOutHavingMembersOfThatPage = (page_id) =>
  GET(RESOURCE_URL, `/get/remaining/invite/members/list/${page_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

// Pages Tools List
export const getToolsOfCurrentPage = () =>
  GET(RESOURCE_URL, "/page/get/tools")
    .then((response) => response)
    .catch(Utils.handleError);

// Post Current Page Engage Setting
export const saveCurrentPageEngageSettings = (body) =>
  POST(RESOURCE_URL, `/page/settings/add/engage-button`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Get Current Page Engage Settings
export const getCurrentPageEngageSettings = (page_id) =>
  GET(RESOURCE_URL, `/page/settings/get/engage-button/${page_id}`)
    .then((response) => response)
    .catch(Utils.handleError);

export const uploadTemporaryArtworkUrl = (body) =>
  POST(RESOURCE_URL, `/upload/temp/artwork`, body)
    .then((response) => response)
    .catch(Utils.handleError);

// Who can send friend request

export const whoCanSendFriendRequest = (body) =>
  POST(RESOURCE_URL, `/auth/user/update/friends-request/setting`, body)
    .then((response) => response)
    .catch(Utils.handleError);
