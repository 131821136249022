<template>
  <div class="header-container">
    <h2 class="heading">{{ ((getActiveChannel && getActiveChannel.title) ? getActiveChannel.title : '') | shortTheLengthOfString }}</h2>
    <span>{{
        getActiveChatUser.timezone
          ? convertTimeZoneDate(getActiveChatUser.timezone)
          : convertDateIntoAMPMformat(getActiveChatUser.last_active)
      }}</span>
    <span class="star-icon">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 1.5L11.3175 6.195L16.5 6.9525L12.75 10.605L13.635 15.765L9 13.3275L4.365 15.765L5.25 10.605L1.5 6.9525L6.6825 6.195L9 1.5Z"
                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </span>
  </div>
</template>
<script>
import JoinModal from "./JoinModal";

export default {
  name: "Header",
  data() {
    return {};
  },
  filters: {
    shortTheLengthOfString: function(string) {
      return (string && string.length > 22) ? string.slice(0, 22) + "..." : string;
    },
    shortTheLengthOfAbout: function(string) {
      return (string && string.length > 50) ? string.slice(0, 50) + "..." : string;
    }
  },
  computed: {
    getActiveChannel() {
      return this.$store.getters.getActiveGroup;
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    },
    getActiveChatUser() {
      return this.$store.getters.getActiveFullChatUser;
    }
  }
};
</script>
<style scoped lang="scss">
.header-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E6EAF0;

  .heading {
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    padding: 16px 0px 15px 28px;
    color: black;
  }

  span {
    margin-left: 9px;
  }

  .star-icon {
    margin-bottom: 5px;
  }
}
</style>
