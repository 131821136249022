<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div class="forum-breadcrumbs">
          <a href="" class="ic_mr"
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 5.99992L8 1.33325L14 5.99992V13.3333C14 13.6869 13.8595 14.026 13.6095 14.2761C13.3594 14.5261 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5261 2.39052 14.2761C2.14048 14.026 2 13.6869 2 13.3333V5.99992Z"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 14.6667V8H10V14.6667"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <i>></i>
          <a :class="[(GET_NAV_MAIN_PATH === 'Main Section') ? 'link' : 'not-link']" @click="changePage($event, GET_NAV_MAIN_PATH)">{{ GET_NAV_MAIN_PATH }}</a>
          <i v-if="seeNavHome.includes(active_tab)">></i>
          <span>{{
            seeNavHome.includes(active_tab)
              ? GET_SUB_PATH_2.length > 25
                ? GET_SUB_PATH_2.slice(0, 25) + "..."
                : GET_SUB_PATH_2
              : null
          }}</span>
          <i v-if="GET_SUB_PATH_3">></i>
          <span>{{
            seeNavHome.includes(active_tab)
              ? GET_SUB_PATH_3.length > 25
                ? GET_SUB_PATH_3.slice(0, 25) + "..."
                : GET_SUB_PATH_3
              : null
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["GET_NAV_MAIN_PATH", "GET_SUB_PATH_2", "GET_SUB_PATH_3"]),
  },
  props: {
    active_tab: String,
  },
  data() {
    return {
      seeNavHome: ["forum_thread", "new_thread", "thread_reply"],
    };
  },
  methods: {
    ...mapActions([
      "getMyThread",
      "getMyReplies",
      "getForumsName"
    ]),
    ...mapMutations(["changeNavMainPath"]),
    async changePage(event, page) {
      event.stopPropagation();
      let active_tab
      if (page === "My Threads") {
        active_tab = 'my_thread'
      } else if (page === "My Replies") {
        active_tab = 'my_replies'
      } else if (page === "Search") {
        active_tab = 'search'
      } else if (page === 'Main Section') {
        active_tab = 'home'
      }
      this.$store.commit('changeSubPath3', '')
      this.$store.commit('setActiveTab', active_tab)
    },
  }
};
</script>

<style lang="scss" scoped>
.ic_mr {
  margin-bottom: 4px;
}
.link {
  color: #D1433A;
  cursor: pointer;

  &:hover {
    color: #D1433A;
  }
}

.not-link {
  color: #47505B;
  cursor: pointer;

  &:hover {
    color: #47505B;
  }
}
</style>
