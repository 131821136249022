<template>
  <div class="sale-items">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="title-h4 selling_title mt-0">{{ gridTitle }}</h4>
      </div>
    </div>
    <div class="music_slider_landing selling_now artist-page-carousel" ref="busstop" >
      <carousel :items="4" :autoplay="false" :margin="30" :nav="false" :responsive="{2560: { items: 3 }}">
        <div class="" v-for="(song, index) in filterData.slice(0,8)" :key="index">
            <Grid :item="song" :type="'review'"/>
        </div>
      </carousel>
      <ListOption :optionWidth="optionWidth" :optionHeight="optionHeight" :showOption="showOption"/>
      <div class="link-more near_arrow more_btn_landing cursor-pointer">
        <a @click="redirectToChannel(channelType,altName,pageTitle)">See more</a>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";
import Grid from "@/components/DesignGuide/Music/Components/Grid";
// import ReviewCardWithSquareImage from "@/components/Common/UiComponenets/Music/ReviewCardWithSquareImage.vue";
import carousel from "vue-owl-carousel";
import ClickOutside from "vue-click-outside";
// import AlbumReviewCard from "../UiComponenets/Music/AlbumReviewCard";
export default {
  data() {
    return {
      showMMore: false,
      optionHeight: 0,
      showOption: false,
      lastWidth: 0,
      optionWidth: 0,
      isHovering: false,
    }
  },
  components: {
    // ReviewCardWithSquareImage,
    ListOption,
    carousel,
    Grid
    // AlbumReviewCard
  },
  computed: {
    filterData(){
      return this.listData.filter(function(e) {
        return e.reviewable != null;
      });
    }
  },
  afterMount: {},
  props: {
    gridTitle: String,
    redirectLink: String,
    redirectUrl: String,
    pageTitle: String,
    altName: String,
    channelType:{
      type:String,
      default:null
    },
    listData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    redirect: function (url) {
      // this.$router.push(url).catch(err => { return err });
      // let title = this.gridTitle != 'Featured' ? 'Top Album of the week': 'Featured Eectronic Songs';
      this.$router.push({name: 'music.'+this.redirectLink})
    },
    addClassToParent: function (event) {
      event.target.parentElement.addClassToParent('mmmm');
    },
    isHoveringFun: function (val, index) {
      if (this.listData[index].isHovering === '0')
        this.listData[index].isHovering = 0;
      this.listData[index].isHovering = val;
    },
    hide: function () {
      this.showOption = false;
    },
    getHeight: function (e) {
      e.stopPropagation();
      //  if(this.optionWidth != 0){
      this.showOption = !this.showOption;
      if (this.lastWidth !== e.screenX) {
        this.showOption = true;
        this.lastWidth = e.screenX;
      }
      let height = 108;
      // this.optionWidth = parseInt(e.screenX) - (this.$refs.busstop.getBoundingClientRect().left);
      this.optionWidth = parseInt(e.screenX) - this.$refs.busstop.getBoundingClientRect().left;
      // this.optionWidth = parseInt(e.screenX) - (this.$refs.busstop.getBoundingClientRect().left - (e.screenX *0.25));
      this.optionHeight = parseInt(height);
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
