<template lang="">
    <div class="comment_tab_detail">
        <div class="coment_user_img">
            <img v-if="user_picture" :src="user_picture">
            <img v-if="!user_picture" :src="require(`@/assets/img/user-placeholder.png`)">
            <span class="online_sys"></span>
        </div>
        <div class="comment_user_details">
            <div class="cmt_user_r">
                <div class="cmt_det_reply">
                    <h1>{{
    name
  }}</h1>
                      <p>replying to</p>
                  </div>
                  <div class="cmt_hr_link">
                      <p>{{
    time
  }}</p>
                      <span>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                                  stroke="#8B949F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M1 7H13" stroke="#8B949F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              <path
                                  d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                                  stroke="#8B949F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                      </span>
                      <!-- <a href="javascript:;">@SarahHogan</a> -->
                  </div>
                  <div class="more_ic" @click="toggle_drop_down()">
                      <span>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path class="more_ic_svg"
                                  d="M9.16634 9.99935C9.16634 10.4596 9.53944 10.8327 9.99967 10.8327C10.4599 10.8327 10.833 10.4596 10.833 9.99935C10.833 9.53911 10.4599 9.16602 9.99967 9.16602C9.53944 9.16602 9.16634 9.53911 9.16634 9.99935Z"
                                  fill="#8B949F" stroke="#8B949F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              <path class="more_ic_svg"
                                  d="M15.0003 9.99935C15.0003 10.4596 15.3734 10.8327 15.8337 10.8327C16.2939 10.8327 16.667 10.4596 16.667 9.99935C16.667 9.53911 16.2939 9.16602 15.8337 9.16602C15.3734 9.16602 15.0003 9.53911 15.0003 9.99935Z"
                                  fill="#8B949F" stroke="#8B949F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                              <path class="more_ic_svg"
                                  d="M3.33333 9.99935C3.33333 10.4596 3.70643 10.8327 4.16667 10.8327C4.6269 10.8327 5 10.4596 5 9.99935C5 9.53911 4.6269 9.16602 4.16667 9.16602C3.70643 9.16602 3.33333 9.53911 3.33333 9.99935Z"
                                  fill="#8B949F" stroke="#8B949F" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                      </span>
                  </div>
                  <div class="comment-post-dot-dropdown">
                      <div class="my-post-contanier" v-if="is_drop_down_open">
                          <div class="my-post-contaniermain">

                              <div class="regular-links">
                                  <ul>
                                      <li @click="toggle_edit">
                                          <a href="javascript:;">
                                              <span>
                                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path class="regular-link-svg"
                                                          d="M8.5 4H4.22222C3.89807 4 3.58719 4.12877 3.35798 4.35798C3.12877 4.58719 3 4.89807 3 5.22222V13.7778C3 14.1019 3.12877 14.4128 3.35798 14.642C3.58719 14.8712 3.89807 15 4.22222 15H12.7778C13.1019 15 13.4128 14.8712 13.642 14.642C13.8712 14.4128 14 14.1019 14 13.7778V9.5"
                                                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                                          stroke-linejoin="round"></path>
                                                      <path class="regular-link-svg"
                                                          d="M12.9485 3.35199C13.1738 3.12661 13.4795 3 13.7982 3C14.117 3 14.4226 3.12661 14.648 3.35199C14.8734 3.57737 15 3.88304 15 4.20177C15 4.5205 14.8734 4.82617 14.648 5.05155L9.26608 10.4335L7 11L7.56652 8.73392L12.9485 3.35199Z"
                                                          stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                                          stroke-linejoin="round"></path>
                                                  </svg>
                                              </span>
                                              Edit Reply
                                          </a>
                                      </li>

                                      <li @click="deleteSubmit">
                                          <a href="javascript:;">
                                              <span>
                                                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path class="regular-link-svg1"
                                                          d="M9.94 2.26H10.08C10.003 2.26 9.94 2.197 9.94 2.12V2.26H4.62V2.12C4.62 2.197 4.557 2.26 4.48 2.26H4.62V3.52H3.36V2.12C3.36 1.50225 3.86225 1 4.48 1H10.08C10.6978 1 11.2 1.50225 11.2 2.12V3.52H9.94V2.26ZM1.12 3.52H13.44C13.7498 3.52 14 3.77025 14 4.08V4.64C14 4.717 13.937 4.78 13.86 4.78H12.803L12.3707 13.9325C12.3427 14.5293 11.8493 15 11.2525 15H3.3075C2.709 15 2.21725 14.531 2.18925 13.9325L1.757 4.78H0.7C0.623 4.78 0.559999 4.717 0.559999 4.64V4.08C0.559999 3.77025 0.81025 3.52 1.12 3.52ZM3.44225 13.74H11.1177L11.5412 4.78H3.01875L3.44225 13.74Z"
                                                          fill="#47505B"></path>
                                                      <path class="regular-link-svg1"
                                                          d="M9.94 2.26H10.08C10.003 2.26 9.94 2.197 9.94 2.12V2.26ZM9.94 2.26H4.62V2.12C4.62 2.197 4.557 2.26 4.48 2.26H4.62V3.52H3.36V2.12C3.36 1.50225 3.86225 1 4.48 1H10.08C10.6977 1 11.2 1.50225 11.2 2.12V3.52H9.94V2.26ZM1.12 3.52H13.44C13.7498 3.52 14 3.77025 14 4.08V4.64C14 4.717 13.937 4.78 13.86 4.78H12.803L12.3707 13.9325C12.3427 14.5293 11.8492 15 11.2525 15H3.3075C2.709 15 2.21725 14.531 2.18925 13.9325L1.757 4.78H0.7C0.623 4.78 0.56 4.717 0.56 4.64V4.08C0.56 3.77025 0.81025 3.52 1.12 3.52ZM3.44225 13.74H11.1177L11.5412 4.78H3.01875L3.44225 13.74Z"
                                                          stroke="#47505B" stroke-width="0.1"></path>
                                                  </svg>
                                              </span>
                                              Delete Reply
                                          </a>
                                      </li>

                                      <span class="seprator-line" style="visibility: hidden;"></span>
                                  </ul>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="video_comment" v-if="!is_edit">{{
    item.text
  }}</div>
              <input v-if="is_edit" v-model="comment" placeholder="Comment" v-on:keyup="checkEnter"/>
              <p v-if="is_edit" @click="toggle_edit">Cancel</p>
              <div class="comment_icons">
                  <CommentLike :item="item"/>
                  <div class="artist-timeline-action" @click="toggleReplySection">

                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                              stroke="#47505B" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"
                              class="artist-timeline-action-svg" />
                      </svg>

                      {{
    reply_count
  }}
                  </div>
              </div>
          </div>
      </div>

</template>
<script>
import {post_comment_api, post_comment_form_data_api, editComment} from '../../../../apis/APIs';
import {mapGetters} from 'vuex';
import moment from 'moment';
import CommentLike from './CommentLike';
import * as Utils from '../../../utils/Utils';

export default {
  data() {
    return ({
      is_edit: false,
      comment: "",
      comment_loading: false,
      write_reply: false,

      is_drop_down_open: false,

      message: "",
      success_message: ""
    })
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const {node_id = ""} = this.item || {};

      return node_id;
    },
    name() {
      const {user_firstname, user_lastname} = this.item || {};

      return `${user_firstname} ${user_lastname}`
    },
    user_picture() {
      const {user_picture} = this.item || {};

      return user_picture;
    },
    time() {
      const {time} = this.item || {};

      return time ? Utils.parseDate(new Date(this.item.time)) : "";
    }
  },
  components: {
    CommentLike
  },
  props: ["item", "reply_count", "post_id"],
  methods: {
    toggleReplySection() {
      this.$parent.openReplySection();
    },
    toggle_drop_down() {
      this.is_drop_down_open = !this.is_drop_down_open;
    },
    init() {
      const {text} = this.item;

      this.comment = text;
    },
    toggle_edit() {
      this.is_edit = !this.is_edit
    },
    refresh_reply_list() {
      this.$parent.init_reply_list()
    },
    async submit() {

      const {comment_loading, comment, item} = this;
      const {comment_id} = item || {};

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      if (comment_loading || (!comment)) return;

      try {

        var formdata = new FormData();
        formdata.append("page_action", "2");
        formdata.append("comment_id", comment_id);
        formdata.append("text", comment);
        formdata.append("time", moment(new Date()).format("YYYY-MM-dd hh:mm:ss"))

        this.comment_loading = true;
        this.message = "";

        const {success, message} = await editComment(formdata);
        this.comment_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.refresh_reply_list();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

      } catch (error) {
        this.comment_loading = false;
      }
    },
    checkEnter(event) {

      if (event.keyCode === 13) {
        this.submit();
      }

    },
    reset_data() {
      this.is_edit = false;
      this.comment_loading = false;
    },
    async deleteSubmit() {

      const {comment_loading, comment, item} = this;
      const {comment_id} = item || {};

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};

      try {

        var formdata = new FormData();
        formdata.append("page_action", "3");
        formdata.append("post_id", this.post_id);
        formdata.append("comment_id", comment_id);
        formdata.append("node_id", this.id);
        formdata.append("node_type", "comment");
        formdata.append("user_id", user_id);
        formdata.append("time", moment(new Date()).format("YYYY-MM-dd hh:mm:ss"))

        const {success, message} = await editComment(formdata);

        if (success === 1) {
          this.reset_data();
          this.refresh_reply_list();
          setTimeout(() => this.success_message = "", 5000);
        } else {
        }

      } catch (error) {
      }
    }
  }
}
</script>
<style lang="">

</style>
