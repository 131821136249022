<template>
  <div class="for_forums_width">
    <EmptyCardNoButton
      v-if="!(listOfOnlineUserDetails)"
      :description="'Check back later to interact with members'"
      :title="'There are no members online now '"
      :iconType="'friends'"
    />
    <div v-else>
      <div class="wr_count_members">
        Online: <span>members</span
        ><span class="count">{{ (listOfOnlineUserDetails && listOfOnlineUserDetails.length > 0) ? listOfOnlineUserDetails.length : 0 }}</span>
      </div>
      <div class="wr_online_people">
        <OnlinePeopleCards
          v-for="(item, index) in listOfOnlineUserDetails"
          :key="index"
          :user_info="item"
          :userPage="userPage"
          :style_for_mr="(index + 1) % 4 ? false : true"
        />
      </div>
      <div
        class="see_more_members"
        @click="seeMoreOnlineUsers"
        v-if="(listOfOnlineUserDetails) && (listOfOnlineUserDetails.length > countOnlineUsers)"
      >
        <svg
          width="9"
          height="8"
          viewBox="0 0 9 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.24316 0.689941V7.01994"
            stroke="#071526"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 3.85498H7.48702"
            stroke="#071526"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        SHOW 10 MORE ONLINE MEMBERS
      </div>
    </div>
  </div>
</template>

<script>
import OnlinePeopleCards from "./OnlinePeopleCards.vue";
import getStream from "@/mixins/getStream.js";
import EmptyCardNoButton from "../../Common/Elements/EmptyCardNoButton.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [getStream],
  components: {
    OnlinePeopleCards,
    EmptyCardNoButton,
  },
  props: {
    listOfOnlineUserDetails: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      userPage:0,
      countOnlineUsers: 10,
    };
  },
  methods: {
    ...mapActions(['getAllOnlineUsers']),
    seeMoreOnlineUsers() {
      this.countOnlineUsers += 10;
      this.userPage= this.countOnlineUsers
      this.$parent.getOnlineUsersDetails(this.countOnlineUsers)
    },
  },
  computed: {
    ...mapGetters(["GET_ALL_ONLINE_USERS"]),
  },

};
</script>

<style lang="scss" scoped>
.wr_online_people {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.see_more_members {
  cursor: pointer;
  font-family: HelveticaNeueBold;
  color: #071526;
  background: #e6eaf0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 9px 0;
  align-items: center;
  svg {
    margin-right: 7px;
  }
}
.wr_count_members {
  font-family: HelveticaNeueMedium;
  background: #ffffff;
  display: flex;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  align-items: center;
  color: #8b949f;
  font-size: 14px;
  margin: 30px 0;
  padding: 17px 20px;
  span {
    margin-left: 10px;
    color: #071526;
  }
  .count {
    padding: 2px 5px;
    background: #D1433A;
    border-radius: 4px;
    color: #ffffff;
    font-family: HelveticaNeueBold;
    font-size: 12px;
  }
}
</style>
