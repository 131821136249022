<template>
  <div class="container-fluid1">
    <div class="card genres-list annotation type-list width-810 ">
      <div class="row no-gutters">
        <div class="col ">
          <div class="type">Type</div>
          <div class="title">Title</div>
          <div class="album song-price-album">Album</div>
          <div class="time">Time</div>
          <div class="date js-genre-date">
            <div class="text">Date</div>
            <div class="svg-icon calendar">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg-calendar">
                <path class="c-line-1 c-fill-1"
                      d="M3.52899 1C3.41273 1 3.31851 1.09423 3.31851 1.21047V2.50292C2.95725 2.59752 2.68709 2.92569 2.68709 3.31522C2.68709 3.77768 3.06651 4.15711 3.52899 4.15711C3.99146 4.15711 4.37088 3.77768 4.37088 3.31522C4.37088 2.92569 4.10071 2.59752 3.73946 2.50292V1.21047C3.73946 1.09423 3.64523 1 3.52899 1ZM8.15942 1C8.04316 1 7.94895 1.09423 7.94895 1.21047V2.50292C7.58769 2.59752 7.31752 2.92569 7.31752 3.31522C7.31752 3.77768 7.69695 4.15711 8.15942 4.15711C8.6219 4.15711 9.00132 3.77768 9.00132 3.31522C9.00132 2.92569 8.73114 2.59752 8.36989 2.50292V1.21047C8.36989 1.09423 8.27567 1 8.15942 1ZM2.05567 1.63142C1.47464 1.63142 1 2.10606 1 2.68708V9.20521C1 9.78622 1.47464 10.2609 2.05567 10.2609H9.62616C10.2072 10.2609 10.6818 9.78622 10.6818 9.20521V2.68708C10.6818 2.10606 10.2072 1.63142 9.62616 1.63142H8.99802C8.88683 1.62984 8.78427 1.73069 8.78427 1.8419C8.78427 1.9531 8.88683 2.05394 8.99802 2.05237H9.62616C9.98085 2.05237 10.2609 2.33238 10.2609 2.68708V4.57806H1.42095V2.68708C1.42095 2.33238 1.70095 2.05237 2.05567 2.05237H2.68379C2.79499 2.05395 2.89756 1.9531 2.89756 1.8419C2.89756 1.73069 2.79499 1.62985 2.68379 1.63142H2.05567ZM4.36759 1.63142C4.25134 1.63142 4.15711 1.72565 4.15711 1.8419C4.15711 1.95814 4.25134 2.05237 4.36759 2.05237H7.31423C7.43047 2.05237 7.5247 1.95814 7.5247 1.8419C7.5247 1.72565 7.43047 1.63142 7.31423 1.63142H4.36759ZM3.52899 2.89427C3.76396 2.89427 3.94993 3.08023 3.94993 3.31522C3.94993 3.55019 3.76396 3.73617 3.52899 3.73617C3.29401 3.73617 3.10804 3.55019 3.10804 3.31522C3.10804 3.08023 3.29401 2.89427 3.52899 2.89427ZM8.15942 2.89427C8.39439 2.89427 8.58037 3.08023 8.58037 3.31522C8.58037 3.55019 8.39439 3.73617 8.15942 3.73617C7.92445 3.73617 7.73847 3.55019 7.73847 3.31522C7.73847 3.08023 7.92445 2.89427 8.15942 2.89427ZM1.42095 4.99901H10.2609V9.20521C10.2609 9.55991 9.98085 9.83992 9.62616 9.83992H2.05567C1.70095 9.83992 1.42095 9.55991 1.42095 9.20521V4.99901Z"
                      fill="#47505B" stroke="#47505B" stroke-width="0.4"></path>
              </svg>
            </div>
            <div class="svg-icon">
              <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg-arrow-down">
                <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="popularity">Popularity</div>
        </div>
      </div>
    </div>
    <div class="card genres-list type-list width-810" v-for="n in loopCount" :key="n"
         @click="$event.currentTarget.classList.toggle('open')">
      <div class="row no-gutters">
        <div class="col line-1">
          <div class="cover "><img
              src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/genres-artist.png"
              alt="Generic placeholder image"></div>
          <div class="play-button js-play-button new-play-button"
               @click="$event.currentTarget.classList.toggle('active')">
            <div class="svg-icon">
              <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="play-type-1">
                <path class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"></path>
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
                <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
              </svg>
            </div>
          </div>
          <div class="type-music">
            <div class="svg-icon"></div>
          </div>
          <div class="waveform ">
            <div class="seek-wrapper">
              <div class="svg-icon">
                <svg width="181" height="32" viewBox="0 0 181 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                    <path d="M0.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M3.5 8.125V23.875" stroke="#D1433A"></path>
                    <path d="M6.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M9.5 9V23" stroke="#D1433A"></path>
                    <path d="M12.5 2.875V29.125" stroke="#D1433A"></path>
                    <path d="M15.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M18.5 8.125V23.875" stroke="#D1433A"></path>
                    <path d="M21.5 8.125V23.875" stroke="#D1433A"></path>
                    <path d="M24.5 13.375V18.625" stroke="#D1433A"></path>
                    <path d="M27.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M30.5 13.375V18.625" stroke="#D1433A"></path>
                    <path d="M33.5 13.375V18.625" stroke="#D1433A"></path>
                    <path d="M36.5 2.875V29.125" stroke="#D1433A"></path>
                    <path d="M39.5 11.625V20.375" stroke="#D1433A"></path>
                    <path d="M42.5 2.875V29.125" stroke="#D1433A"></path>
                    <path d="M45.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M48.5 2.875V29.125" stroke="#D1433A"></path>
                    <path d="M51.5 2.875V29.125" stroke="#D1433A"></path>
                    <path d="M54.5 8.125V23.875" stroke="#D1433A"></path>
                    <path d="M57.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M60.5 8.125V23.875" stroke="#D1433A"></path>
                    <path d="M63.5 5.5V26.5" stroke="#D1433A"></path>
                    <path d="M66.5 2.875V29.125" stroke="#D1433A"></path>
                    <path d="M69.5 8.125V23.875" stroke="#D1433A"></path>
                    <path d="M72.5 11.625V20.375" stroke="#D1433A"></path>
                    <path d="M75.5 12.5V19.5" stroke="#AFB5BE"></path>
                    <path d="M78.5 12.5V19.5" stroke="#AFB5BE"></path>
                    <path d="M81.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M84.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M87.5 9.875V22.125" stroke="#AFB5BE"></path>
                    <path d="M90.5 12.5V19.5" stroke="#AFB5BE"></path>
                    <path d="M93.5 8.125V23.875" stroke="#AFB5BE"></path>
                    <path d="M96.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M99.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M102.5 8.125V23.875" stroke="#AFB5BE"></path>
                    <path d="M105.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M108.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M111.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M114.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M117.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M120.5 13.375V18.625" stroke="#AFB5BE"></path>
                    <path d="M123.5 9.875V22.125" stroke="#AFB5BE"></path>
                    <path d="M126.5 8.125V23.875" stroke="#AFB5BE"></path>
                    <path d="M129.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M132.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M135.5 8.125V23.875" stroke="#AFB5BE"></path>
                    <path d="M138.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M141.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M144.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M147.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M150.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M153.5 8.125V23.875" stroke="#AFB5BE"></path>
                    <path d="M156.5 5.5V26.5" stroke="#AFB5BE"></path>
                    <path d="M159.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M162.5 9V23" stroke="#AFB5BE"></path>
                    <path d="M165.5 9V23" stroke="#AFB5BE"></path>
                    <path d="M168.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M171.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M174.5 11.625V20.375" stroke="#AFB5BE"></path>
                    <path d="M177.5 2.875V29.125" stroke="#AFB5BE"></path>
                    <path d="M180.5 8.125V23.875" stroke="#AFB5BE"></path>
                  </g>
                  <path d="M72 0V32" stroke="#D1433A" stroke-width="3"></path>
                  <defs>
                    <clipPath id="clip0">
                      <rect x="0.5" y="2.875" width="180" height="26.25" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div class="description">
            <div class="artist"><a href="#">Artist Name</a></div>
            <div class="track"><a href="#">Song Name</a></div>
          </div>
          <div class="album"><a href="#">Album Name</a></div>
          <div class="time">
            0:00
          </div>
          <div class="date">
            2 days ago
          </div>
          <div class="popularity">
            <div class="empty">
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
            </div>
            <div class="full">
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
            </div>
            <div class="count">
              000K
            </div>
          </div>
          <div class="other d-flex ">
            <div class="actions d-flex align-items-center">
              <div class="wishlist js-wishlist">
                <div class="svg-icon ">
                  <svg class="svg-mark-soc" width="12" height="16" viewBox="0 0 12 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1 c-fill-1"
                          d="M1.80601 14.8492L6 9.74505L10.194 14.8492C10.262 14.9234 10.3506 14.9734 10.447 14.9919C10.5435 15.0105 10.6431 14.9968 10.7318 14.9527C10.8204 14.9086 10.8937 14.8363 10.9413 14.7461C10.9888 14.6559 11.0082 14.5522 10.9968 14.4498V1.79872C11.0103 1.60135 10.9487 1.40642 10.8257 1.25669C10.7027 1.10695 10.5282 1.01464 10.3406 1H1.65944C1.47179 1.01464 1.29732 1.10695 1.1743 1.25669C1.05127 1.40642 0.989741 1.60135 1.00319 1.79872V14.4498C0.991763 14.5522 1.01119 14.6559 1.05874 14.7461C1.10628 14.8363 1.17955 14.9086 1.26822 14.9527C1.35689 14.9968 1.45648 15.0105 1.55296 14.9919C1.64944 14.9734 1.73795 14.9234 1.80601 14.8492Z"
                          stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <div class="btn btn-icon-border text shop-cart">
                <div class="svg-icon">
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-shop">
                    <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="white"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="c-line-1"
                          d="M9.97559 3.76123V5.04023C9.90323 5.5065 9.653 5.9266 9.27745 6.21227C8.9019 6.49794 8.43026 6.62696 7.96159 6.57223C7.49292 6.62696 7.02127 6.49794 6.64572 6.21227C6.27017 5.9266 6.01994 5.5065 5.94759 5.04023V3.76123"
                          stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div>$00</div>
              </div>
              <div class="more dropright">
                <div class="dropdown vertical more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false" data-display="static">
                  <div class="svg-icon more-position">
                    <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="svg-more-hor">
                      <path class="c-fill-1"
                            d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                            fill="white"></path>
                      <path class="c-fill-1"
                            d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                            fill="white"></path>
                      <path class="c-fill-1"
                            d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                            fill="white"></path>
                    </svg>
                    <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="svg-more">
                      <path
                          d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                          class="c-fill-1"></path>
                      <path
                          d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                          class="c-fill-1"></path>
                      <path
                          d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                          class="c-fill-1"></path>
                    </svg>
                  </div>
                  <ListOption/>
                </div>
              </div>
              <div class="extend js-extend">
                <div class="svg-icon">
                  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-arrow-down">
                    <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters line-2 d-flex align-items-center justify-content-end">
        <div class="">
          <div class="btn-group-social left-button">
            <button class="btn btn-information text">
              <div class="svg-icon">
                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M1.72541 12.8707L5.5 8.49576L9.27459 12.8707C9.33584 12.9344 9.4155 12.9772 9.50233 12.9931C9.58917 13.009 9.6788 12.9972 9.7586 12.9594C9.8384 12.9216 9.90434 12.8597 9.94714 12.7824C9.98993 12.705 10.0074 12.6162 9.99713 12.5284V1.68462C10.0092 1.51544 9.95385 1.34836 9.84313 1.22002C9.73241 1.09168 9.57539 1.01255 9.4065 1H1.5935C1.42461 1.01255 1.26759 1.09168 1.15687 1.22002C1.04615 1.34836 0.990767 1.51544 1.00287 1.68462V12.5284C0.992586 12.6162 1.01007 12.705 1.05286 12.7824C1.09566 12.8597 1.1616 12.9216 1.2414 12.9594C1.3212 12.9972 1.41083 13.009 1.49767 12.9931C1.5845 12.9772 1.66416 12.9344 1.72541 12.8707Z"
                        stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information text">
              <div class="svg-icon">
                <svg class="svg-message-soc" width="15" height="14" viewBox="0 0 15 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M14 9.39473C13.9746 9.79134 13.8086 10.1608 13.5382 10.4228C13.2677 10.6847 12.9146 10.818 12.5558 10.7936H3.90253L1 13V2.4017C1.02539 2.00509 1.19137 1.63561 1.46183 1.37364C1.73229 1.11168 2.08535 0.978417 2.4442 1.00285H12.5504C12.9092 0.978417 13.2623 1.11168 13.5327 1.37364C13.8032 1.63561 13.9692 2.00509 13.9946 2.4017L14 9.39473Z"
                        stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information text">
              <div class="svg-icon">
                <svg class="mix-soc" width="14" height="14" viewBox="0 0 14 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg" clip-path="mix-soc">
                  <path class="c-line-1" d="M1 8L1 2.99977L13 2.99977L9.57045 1" stroke="#071526" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="c-line-1" d="M13 7L13 11.247L1 11.247L5.12612 13" stroke="#071526" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information">
              <div class="svg-icon">
                <svg class="share-soc" width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path class="c-line-1"
                        d="M2 11.7561C2.35568 10.0409 3.21256 8.52102 4.42532 7.45413C5.63808 6.38724 7.13208 5.83902 8.65398 5.90244V2L14.6341 7.7561L8.65398 14V10C8.65398 10 5.53756 8.34146 2 11.7561Z"
                        stroke="#071526" stroke-width="1.5" stroke-linecap="round"></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div class="">
          <div class="btn-group-social justify-content-end btn-group-social-2">
            <div class="btn btn-icon-border text m-r-22">
              <div class="svg-icon">
                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="shop-cart-v2">
                  <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="#D1433A"
                        stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="c-line-1"
                        d="M9.97559 3.76099V5.03999C9.90323 5.50626 9.653 5.92635 9.27745 6.21203C8.9019 6.4977 8.43026 6.62672 7.96159 6.57199C7.49292 6.62672 7.02127 6.4977 6.64572 6.21203C6.27017 5.92635 6.01994 5.50626 5.94759 5.03999V3.76099"
                        stroke="#D1433A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <div>$00</div>
            </div>
            <div class="btn btn-play-count ">
              <div class="svg-icon">
                <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="small-play-svg">
                  <path class="c-fill-1"
                        d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                        fill="#8B949F"></path>
                </svg>
              </div>
              <span>32.2k</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from "@/components/Common/Elements/ListOptions.vue";

export default {
  data() {
    return {}
  },
  components: {
    ListOption
  },
  props: {
    loopCount: Number
  },
  methods: {}
}
</script>
