<template>
  <div class="ml-auto">
    <div class="news-feed">
      <div class="boosted-postes">
        <div class="boosted-post-main">
          <div class="boosted-post-main-image">
            <img src="@/assets/img/boost1.png" /><span class="boosted-tag"
              >Boosted</span
            ><span class="Chill-tag">#Chill... </span>
          </div>
          <div class="boosted-post-details">
            <div class="heading-detail">
              <h1>Event Name ft. Nina Kraviz, SNTS</h1>
              <button>
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                    stroke="white"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                000
              </button>
            </div>
            <div class="icons-flex-detail">
              <div class="bosted-date">
                <span
                  ><svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0833 2.33337H2.91667C2.27233 2.33337 1.75 2.85571 1.75 3.50004V11.6667C1.75 12.311 2.27233 12.8334 2.91667 12.8334H11.0833C11.7277 12.8334 12.25 12.311 12.25 11.6667V3.50004C12.25 2.85571 11.7277 2.33337 11.0833 2.33337Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M9.33301 1.16663V3.49996"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M4.66699 1.16663V3.49996"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M1.75 5.83337H12.25"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path></svg
                ></span>
                16th October
              </div>
              <div class="boosted-location">
                <span
                  ><svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M12.25 5.83337C12.25 9.91671 7 13.4167 7 13.4167C7 13.4167 1.75 9.91671 1.75 5.83337C1.75 4.44099 2.30312 3.10563 3.28769 2.12106C4.27226 1.1365 5.60761 0.583374 7 0.583374C8.39239 0.583374 9.72774 1.1365 10.7123 2.12106C11.6969 3.10563 12.25 4.44099 12.25 5.83337Z"
                        stroke="#8B949F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M7 7.58337C7.9665 7.58337 8.75 6.79987 8.75 5.83337C8.75 4.86688 7.9665 4.08337 7 4.08337C6.0335 4.08337 5.25 4.86688 5.25 5.83337C5.25 6.79987 6.0335 7.58337 7 7.58337Z"
                        stroke="#8B949F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="14" height="14" fill="white"></rect>
                      </clipPath>
                    </defs></svg
                ></span>
                Manchester
              </div>
              <div class="boosted-time">
                <span
                  ><svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00033 12.8333C10.222 12.8333 12.8337 10.2216 12.8337 6.99996C12.8337 3.7783 10.222 1.16663 7.00033 1.16663C3.77866 1.16663 1.16699 3.7783 1.16699 6.99996C1.16699 10.2216 3.77866 12.8333 7.00033 12.8333Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M7 3.5V7L9.33333 8.16667"
                      stroke="#8B949F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path></svg
                ></span>
                8pm- 4am
              </div>
            </div>
          </div>
          <div class="boosted-post-btns">
            <div class="boosted-intersted-btn">
              <!-- <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAAeCAYAAADO4udXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUISURBVHgB7VtRUttIEO2xzf7inGDNCWJOEJkLLJwA79/WBgr5BDgnsAlha/8wJ1jnAlg5QcwJMCeI8rsYTd4bjRxjTMXYSCapflXCkno0PaN+09OjaYwAOwe2bUWOcFoVhWJ5xMZI7/LUtEzjwHZxsZmUpRV1TSwKxZIIQlszY6GTuiaxvtixbEf/mpEoFCsC5KqCXNcCYllRKJ4R5FRJFIocoMRS5AIlliIXKLEUuUCJpcgFSixFLvgpiRUc2CD429blFwb79zP3cS3EcsTAIUvCiAzw52iRssGh7fCQghG8tefoY1uWhCnLf4v28SWiImsAiHHsv8pGkjOMlfo6vgCbkgQ2yb9/LxWFE8t7qsD48+iDiWQFoI4jMLUmidzAmEdiJQaRPqLeNr0GitS9rkHJSvPyzNzgfpiVBfGGyUa6TzpblzUSwqdfYYvi3N1n3Va60Zm5cLoPbRPP78/KqAvXNeilZx6gLQ2Wd5v9BuWn2pj1454M9UjBYF9KiVTxfubq5j6gjGUfbX63SH2FEIuNxs9rGLaGFxpk92EUThcjGGaIy6vo1PTkqTAgDgiDPYQuiUDy4O4x4pO+lKUPQwU0FgjSS0ryNcvkgPHauDcSGnGMaQc7Ea6uJG0fyvRBMIO6ByDIMEnrrqNMD22+wZsbkXDQ2YW8jSOcyKALz9YhG7lzcdscXej8A+0McfkK9XZ2Du0mMwGczLfJGIkQn4Q4r0mRAKnQ3g7aX50mPOE2l+8QfogwSWEhYhUTY5UlwovfxcvblenUHJOOahhzV25XmjZikLIVvTcfkyTtOGKUTV6DISNL+XtzQa/kDAgi4OWdUA4DttiGnbf296xN9k4arA/nVXqeZCyu7st0tEY4juX/tB98gSj/FbI9QzJUZEhdeDambp670U69ZQlZDwcQdJJoTW7aQraP8x69weDUfEoq8qekRiwM8LTZADmejg0npKInrUhj0foK8Vgw6AgNbPgG1u4JaXgacpXsCjtlhN9wPp5fzLvzKo1KLzEtS8rwRmkwFk+15TX/mIpco/ykLAg0Gvxjht77NTFthpCTSH2IWw8Uj9M+YwD1s3qy2kq3smlJ4NRrO3AANA5dfYWCA2cnbV874G8F3pg2S9zg2QPxFyZ7YatCkstNLzPA1NAvMGXHvRh6isEHY3hgFL6yY9ly3s0Jp0gKAvGZrOyk/IZs09PAk13Aw2whSN9Gne9AkCZI9HAll3i9Jdmd1cuYz7XLPBhwa0m6JLkyz2Vu5bMn1ZMHfqGfG7hCe3Bz3r3n1MmsRky/wV+25hMZI8QxIa9JjtJYQkybAz8lzeJK+KyfGvy08BnTdgcEqtOTcSEQwXvJhhs0sZs+vyuvOb2UM95K7uk9p15fcsg4kTJe7KT61pbNm5GL58vOJkV/x+LSP+JIxYjdwghnrJErsRIrn6iXJGAcZRm/wPi8RvD9xQfNe/OyZ7lidaMXqzXmGDGBzdKLcRUJmQvcEfBOZCAISHfCZ+HFTlz8CD3uuiwNw5gy08vPIHdpzOLbFPspF4/KG5HvU+M6QHINzszW0rNJkYl+iBvezN7zgW3umPVIJNkkYF8ALDvPq/HeY7LH6nlM71Pb9FLhOKUZpIrnhmaQKnKDEkuRC5RYilygxFLkAiWWIheQWPGiS2WF4kfwn4/iEvfNsF/Vyb76KhTLwv8XdNt/+E6zLE2abqKeS7EKmD3SZZbGN6RIofonytKAAAAAAElFTkSuQmCC"
              /> -->
              <div class="inter-going-btn m-0">
                <button class="Interested_btn btn_act">Interested</button>
                <button class="Interested_btn_going">Going</button>
              </div>
            </div>
            <div class="buy-ticket-btn">
              <button>
                <span
                  ><svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.913105 0.336895L0.913107 0.686266L0.913122 3.32841L0.913109 3.6778L1.26251 3.6778C2.13494 3.67781 2.8347 4.37759 2.8347 5.24999C2.83468 6.12244 2.13493 6.82219 1.26249 6.82219L0.913108 6.82218L0.913122 7.17156L0.913123 9.81372L0.913109 10.1631L1.2625 10.1631L15.2375 10.1631L15.5869 10.1631L15.5869 9.81374L15.5869 7.17157L15.5869 6.8222L15.2375 6.8222C14.3651 6.8222 13.6653 6.12246 13.6653 5.25001C13.6653 4.3776 14.3651 3.67781 15.2375 3.67782L15.5869 3.67782L15.5869 3.32844L15.5869 0.68629L15.5869 0.3369L15.2375 0.336905L1.26249 0.336882L0.913105 0.336895ZM1.61186 1.03565L10.5209 1.03565L10.5209 2.08377L11.2197 2.08378L11.2197 1.03564L14.8881 1.03565L14.8881 3.01181C13.8031 3.18122 12.9666 4.11898 12.9666 5.24999C12.9666 6.38099 13.8032 7.31327 14.8881 7.48273L14.8881 9.46436L11.2197 9.46435L11.2197 8.41623L10.5209 8.41621L10.521 9.46434L1.61186 9.46433L1.61186 7.48272C2.69683 7.31326 3.53344 6.38099 3.53344 5.24999C3.53343 4.11898 2.6969 3.18122 1.61186 3.01181L1.61186 1.03565ZM10.521 3.50312L10.5209 4.18003L11.2197 4.18004L11.2197 3.50311L10.521 3.50312ZM10.521 6.31996L10.5209 6.99688L11.2197 6.99687L11.2197 6.31996L10.521 6.31996Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.4"
                    ></path></svg
                ></span>
                Buy Tickets
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <who-to-follow
          pageTitle="Suggested Promoters"
          :header="false"
          :redirectLink="'continue-listening-to'"
          :listData="talkChannels"
          :eventsPage="true"
        />
      </div>
    </div>
    <full-page-loader :show="loading" />
  </div>
</template>
<script>
import WhoToFollow from "@/components/Common/Elements/WhoToFollow.vue";
import { mapGetters } from "vuex";
import { getChannelData } from "../../../apis/APIs";

export default {
  data() {
    return {
      dataLoaded: false,
      loading: false,
      talkChannels: [],
    };
  },
  components: {
    WhoToFollow,
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
  },
  mounted() {
  },
  methods: {
    async getTalkChannels() {
      try {
        this.loading = true;
        let { data } = await getChannelData("talk_podcast");
        this.talkChannels = data.channels.rightbar[0].objects.data;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.news-feed {
  width: 370px;
  max-width: 100%;
}
</style>
