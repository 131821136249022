<template>
  <div class="related-widget">
    <div class="widget-title">
      Events
      <div class="link-more near_arrow without-slider in-title pointerClass"><a v-on:click="changeTab">See All
        <span class="sl-arrow"> <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
      </a>
      </div>
    </div>
    <div v-if="getPageEvents.length < 1" class="update-info" :class="{'pb-23' : !currentUserIsAdmin}">
      <div class="update-info-text">No Events Yet</div>
      <div class="update-info-btn">
        <button data-toggle="modal"
                data-target="#create-event-modal"
                v-if="currentUserIsAdmin" @click="$store.commit('SET_EVENT_MODAL', true)">
          <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.4999 0C4.7714 0 4.99149 0.220095 4.99149 0.491596V8.5084C4.99149 8.7799 4.7714 9 4.4999 9C4.2284 9 4.0083 8.7799 4.0083 8.5084V0.491596C4.0083 0.220095 4.2284 0 4.4999 0Z"
                  fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 4.50039C0 4.22888 0.220095 4.00879 0.491596 4.00879H8.5084C8.7799 4.00879 9 4.22888 9 4.50039C9 4.77189 8.7799 4.99198 8.5084 4.99198H0.491596C0.220095 4.99198 0 4.77189 0 4.50039Z"
                  fill="white" />
          </svg>
          ADD NEW EVENT
        </button>
      </div>
    </div>
    <div v-else class="artist-tour-dates">
      <ul >
        <li v-for="(event,index) in getPageEvents.slice(0,3)" :key="index">
          <div class="artist-calendar">
            <div class="a-month">{{getMonthFromDate(event.started_at)}}</div>
            <div class="a-month-date">{{getDayFromDate(event.started_at)}}</div>
          </div>
          <div class="artist-tour-dates-events">
            <h1>{{event.title}}</h1>
            <p>{{event.city}}, {{event.country}}</p>
          </div>
          <div class="artist-intersted-btn">
            <span><img :src="require(`@/assets/icons/artist-star.svg`)"></span>
            <span class="intersted-text-art">Interested</span>
            <span><img :src="require(`@/assets/icons/interested-down-btn.svg`)"></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed:{
    getPageEvents() {
      return this.$store.getters.getCurrentPageUpcomingEvents;
    },
    currentUserIsAdmin(){
      return !!(this.current_page && this.current_page.current_user && this.current_page.current_user==='admin')
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
  },
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab('events');
    }
  }
}
</script>
