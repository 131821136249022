<template>
  <div class="row" style="margin-left: 0.1rem !important; padding-right: 15px;">
    <div class="col-md-artist-photo-full">
      <div class="all-add-photo">
        <h1>{{ this.title }}</h1>
        <span @click="close">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 10.1914L20 19.8068"
              stroke="#47505B"
              stroke-width="1.5"
            />
            <path
              d="M19.8066 10L10.1913 20"
              stroke="#47505B"
              stroke-width="1.5"
            />
          </svg>
        </span>
      </div>
    </div>
    <div
      v-for="(albums, index) in singleAlbumData"
      :key="index"
      class="col-md-artist-photo"
    >
      <div class="photo-block">
        <img :src="albums.artwork_url" @error="onPostImageError" />
      </div>
    </div>
    <div v-if="singleAlbumData.length >= 8 && singleAlbumData.length != singleAlbumData" class="artist-show-more" @click="showMoreAlbum">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V11.0003" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M11 6L0.999665 6" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
          SHOW MORE
        </div>
  </div>
</template>

<script type="application/javascript">
import Vue from "vue";
import VueImg from "v-img";
const vueImgConfig = {
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
}
Vue.use(VueImg, vueImgConfig);
export default {
  name: "SingleAlbum",
  props: {
    header: Boolean,
    singleAlbumData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
    },
    slug:{
      type: String
    },
    totalMediaPhotos: {},
  },
  methods: {
    getMediaPhotosLists() {
      this.$emit("getMediaPhotos");
    },
    close(){
      this.$emit('closeSingleAlbum')
    },
    openAddPhotoModal(){
      this.photoModal = true;
    },
    async showMoreAlbum(){
      this.$emit('showMore');
    }
  },
};
</script>

<style scoped></style>
