<template>
  <div class="pol-detail">
    <div class=" custom-radio" @click="onPress($event)">
      <input type="radio" v-bind="$attrs"
      :id="'radio-group'+option.id"
      @click="addVote(option.id)"
        :name="'radio-group' +post.id"
        :value="option.option">
      <label :for="'radio-group'+option.id">{{
      option.option
      }}</label>
    </div>
    <div class="poll-no">{{
    option.votes
    }}
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex';
import {poll_vote_api} from '../../../../apis/APIs.js';

export default {
inheritAttrs: false,
  props: {
    option: {
      type: Object,
      required: true
    },
    post: {
      type: Object,
      required: true
    },
  },
  mounted() {
    this.checkSelectedOption();
  },
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    // isChecked() {
    //   return this.post.poll_reaction && this.post.poll_reaction.option_id === this.option.id
    //   // return !(this.post.poll_reaction && this.post.poll_reaction.option_id===this.option.id)
    // },
  },
  methods: {
    checkSelectedOption(){
      let rb = document.getElementById('radio-group' + this.option.id);
      rb.checked = this.post.poll_reaction && this.post.poll_reaction.option_id === this.option.id
    },

    async addVote(optionID) {
      try {
        const {user_data} = this.user
        const formData = new FormData();
        formData.append('user_id', user_data.id);
        formData.append('option_id', optionID);
        formData.append('post_id', this.post.id);
        const {data} = await poll_vote_api(formData)
        if(data.success === 1){
          const newPost = data.information
          const oldId =this.post.id
          await this.$store.dispatch('replacePostWithNew', { oldId, newPost})
        }
      } catch (e) {
        console.error(e)
      }
    },
    async onPress(e) {
      if (e) e.stopPropagation();
    },
    async submit(e) {
      if (e) e.stopPropagation();


      try {


      } catch (error) {
      }
    },
  }
}
</script>
<style lang="">

</style>
