var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"for_forums_width"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"forums-item m-t-30 my-th"},[_vm._l((_vm.THREADS.data),function(thread){return _c('div',{key:thread.id,staticClass:"item-content for_height_item-content"},[_c('div',{staticClass:"forums_name_in_my_thread"},[_c('div',[_c('a',{on:{"click":function($event){return _vm.goTo({
                    active_tab: 'home',
                  })}}},[_vm._v("Forum:"),_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(thread.forum.name))])])]),_c('span',[_vm._v(_vm._s(_vm.dateFormat(thread.forum.updated_at)))])]),_c('div',{staticClass:"item-content-row"},[_c('div',{staticClass:"item-desc",staticStyle:{"margin":"unset"}},[_c('div',{staticClass:"item-inner-title",on:{"click":function($event){return _vm.goTo({
                    active_tab: 'thread_reply',
                    thread_id: thread.id,
                    title: thread.title,
                  })}}},[_c('a',[_vm._v(_vm._s(thread.title.length > 35 ? thread.title.slice(0, 35) + "..." : thread.title))])]),_c('div',{staticClass:"item-inner-subtitle with-name"},[_vm._v(" by "),_c('a',{on:{"click":function($event){return _vm.redirectWidgetsToProfilePage($event, thread.author_details.username)}}},[_vm._v(_vm._s(thread.author_details.full_name))]),_c('i',[_vm._v(".")]),_vm._v(_vm._s(_vm.dateFormat(thread.created_at))+" ")])]),_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"my_threads_replies_view"},[_c('div',[_c('span',{staticClass:"res_replies"},[_vm._v("Replies"),_c('span',[_vm._v(_vm._s(thread.replies_count))])])]),_c('div',[_c('span',{staticClass:"res_view"},[_vm._v("Views "),_c('span',[_vm._v(_vm._s(thread.views))])])])])])])])}),_c('Pagination',{attrs:{"count":_vm.THREADS.total,"actionName":_vm.type == 'search_threads' ? 'getSearchForumResult' : 'getMyThread'}})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }