import { getOtherUserData } from "../apis/APIs";
import moment from "moment";

export default {
  data() {
    return {
      setPageTypeFunction:'setPagesType',
      brokenImage: require(`@/assets/broken/broken_post.png`)
    };
  },
  computed:{
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id
    },
    userDOB() {
      return this.$store.getters.user_map_to_props.user_data.birth
    },
    getMyFriends() {
      return this.$store.getters.getMyFriends
    },
    currentPageUserId() {
      return this.$store.getters.getCurrentPage.user.id
    }
  },
  methods: {
    openListOption(event, Songs, list_type, object, left = false, favorite = null, counts= null, right_bar_check = false) {
      event.stopPropagation();
      this.$store.commit("SET_LIST_OPTION", { value: false, Songs: null });
      let width = left ? (right_bar_check) ? Number(event.clientX) - 100 : Number(event.clientX) - 170 : event.clientX;
      let height = event.pageY;
      this.$store.commit("SET_HEIGHT_AND_WIDTH_LIST_OPTION", {
        height,
        width,
        left
      });
      this.$store.commit("SET_LIST_OPTION", {
        value: true,
        Songs: Songs,
        list_type: list_type,
        right_bar_check: right_bar_check
      });
      this.$store.commit("Set_Add_To_Playlist_Songs_Array", object);
      this.$store.commit('SET_CURRENT_SONG_ALBUM_FAVORITE', favorite);
      this.$store.commit('SET_CURRENT_SONG_ALBUM_FAVORITE_COUNTS', counts);
    },
    async openChatBox(event, type, friend,last_message) {
      event.stopPropagation();

      if(type === 'new') {
        this.$store.commit('SET_ACTIVE_CHANNEL', {})
      }
      this.$store.commit("SET_ACTIVE_CHAT_USER", friend);

      if(type==="single_online" && friend)
      {
        await this.setActiveChannel(friend)
      }
      if(type==="group")
      {
        this.$store.commit("SET_ACTIVE_GROUP", last_message.data)
      }

      if(last_message && last_message.state && last_message.state.messageSets.length > 0)
      {
        this.$store.commit("SET_ACTIVE_CHAT", last_message.state.read);
        this.$store.commit("SET_ACTIVE_CHANNEL", last_message.state.messageSets[0]);
        await this.markReadMessages(last_message)
        this.watchingChannelForStartTyping(last_message)
        this.watchingChannelForStopTyping(last_message)
      }
      const payload = {
        check: true,
        type: type
      }
      this.$store.commit("SET_SINGLE_CHAT_OPTION", payload);
    },
    async openFullViewChat(event, type, friend,thread) {

      if(event)
      {
        event.stopPropagation();
      }
      this.$store.commit("SET_ACTIVE_FULL_CHAT_USER", friend);
      this.$store.commit("SET_ACTIVE_FULL_CHAT_TYPE", type);

      await this.clearChannelDataInFullChatView()

      if(type==="group" || type === 'clubroom' || type === 'partyroom')
      {
        this.$store.commit("SET_ACTIVE_GROUP", thread.data)
      }

      if(thread && thread.state && thread.state.messageSets.length > 0)
      {
        this.$store.commit("SET_ACTIVE_FULL_CHAT", thread.state.read);
        this.$store.commit("SET_FULL_CHAT_ACTIVE_CHANNEL", thread.state.messageSets[0]);

        await this.markReadMessages(thread)
        const group= await this.getGroupChannel(thread);
        const members=await group.queryMembers({});
        const allAttachments = thread.state.messageSets[0].messages.filter(x => x.attachments.length > 0 ).map(x => x);
        const allImages=allAttachments.filter(x => x.attachments.length > 0 && (x.attachments[0].type === 'image/jpeg' || x.attachments[0].type ==='image/jpg' || x.attachments[0].type ==='image/png' || x.attachments[0].type === 'image/webp' ) ).map(x => x)
        const allGifs=allAttachments.filter(x => x.attachments.length > 0 && (x.attachments[0].type === 'image/gif' || x.attachments[0].type === 'image/webp') ).map(x => x )
        const allFiles=allAttachments.filter(x => x.attachments.length > 0 && (x.attachments[0].type === 'application/pdf' || x.attachments[0].type==='.docx' || x.attachments[0].type==='.xlsx' || x.attachments[0].type==='.doc' || x.attachments[0].type==='.xls' || x.attachments[0].type === '.txt' || x.attachments[0].type === '.pptx'|| x.attachments[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || x.attachments[0].type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || x.attachments[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) ).map(x => x)
        const allAudios=allAttachments.filter(x => x.attachments.length > 0 && (x.attachments[0].type === 'audio/mpeg' || x.attachments[0].type === 'audio/mp3' ) ).map(x => x)
        const allVoice = allAttachments.filter(x => x.attachments.length > 0 && (x.attachments[0].audio_type === 'voice' ) ).map(x => x)
        const allLinks = allAttachments.filter(x => x.attachments.length > 0 && x.attachments[0].hasOwnProperty('title_link')).map(x => x)
        this.$store.commit("SET_ACTIVE_ALL_IMAGES", allImages);
        this.$store.commit("SET_ACTIVE_ALL_FILES", allFiles);
        this.$store.commit("SET_ACTIVE_ALL_AUDIOS", allAudios);
        this.$store.commit("SET_ACTIVE_ALL_GIFS", allGifs);
        this.$store.commit('SET_ACTIVE_ALL_VOICES', allVoice)
        this.$store.commit('SET_ACTIVE_ALL_LINKS', allLinks)
        this.$store.commit("SET_ACTIVE_ALL_GROUP_MEMBERS", members);
      }
      // console.log('Checking Friend ==>', friend)
      // if(friend)
      // {
      //   const activeUser= await this.getOtherUser(friend.id)
      //   console.log('Checking Active User ==>', activeUser)
      //   this.$store.commit("SET_ACTIVE_FULL_CHAT_USER_BACKEND", activeUser);
      // }
    },
    async clearChannelDataInFullChatView () {
      this.$store.commit("SET_ACTIVE_FULL_CHAT", {});
      this.$store.commit("SET_FULL_CHAT_ACTIVE_CHANNEL", {});
      this.$store.commit("SET_ACTIVE_ALL_IMAGES", []);
      this.$store.commit("SET_ACTIVE_ALL_FILES", []);
      this.$store.commit("SET_ACTIVE_ALL_AUDIOS", []);
      this.$store.commit("SET_ACTIVE_ALL_GIFS", []);
      this.$store.commit("SET_ACTIVE_ALL_GROUP_MEMBERS", []);
    },
    async getOtherUser(id) {

      if (id) {
        try {
          const res = await getOtherUserData(id);
          const data = (res && res.data) ? res.data : {}
          return (data && data.information) ? data.information : {};
        } catch (e) {
          console.error("error fetching other user", e);
          return null;
        }
      }
      return false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openPlaylistModal(event, value, check) {
      this.$store.commit("SET_PLAY_LIST_MODAL", { value: value, check: check });
    },
    setPagesType(type) {

      this.$store.commit('SET_PAGES_TYPE', type)
    },
    redirectToSettingPage: function (event) {
      event.stopPropagation();
      this.$router.push('/home/settings')
    },
    calculateUserAgeFromDateOfBirth() {
      return moment().diff(this.userDOB, 'years')
    },
    checkIfUserIsAbove18() {
      return this.calculateUserAgeFromDateOfBirth() >= 18
    },
    checkIfPageOwnerIsYourFriend(user_id = this.currentPageUserId) {
      return this.getMyFriends.some(e => e.id === user_id)
    },
    checkIfYouAreTheOwnerOfThePage() {
      return this.currentPageUserId === this.userId
    },
    notificationToast(show,title, text, delay = 3000, type) {
      const payload = {
        show: show,
        title: title,
        text: text,
        delay: delay,
        type: type
      }
      this.$store.commit('SET_TOAST_OPTIONS', payload)
    },
    onPostImageError(event) {
      event.target.src = this.brokenImage;
    },
    onProfileImageError(event,alphabet) {
      event.target.src =  require(`@/assets/img/initials/${alphabet}.png`);
    },
    getFirstAlphabetOfUserName(user){
      return user.username ? user.username.charAt(0).toLowerCase() : 'p';
    }
  }
};
