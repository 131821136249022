import Vue from "vue";
import * as Utils from "../components/utils/Utils";
import {SYSTEM_DATA_IS_WELCOME_PAGE, USER_DATA, USER_KEY} from "./types";
import * as SMStorage from '../apis/SMStorage';
import {checkEmail, get_user} from "../apis/APIs";

const INITIAL_STATE = {
    [USER_KEY]: {
        [USER_DATA]: undefined,
    }
};

export const state = {...INITIAL_STATE};

export const actions = {
    async fetchUserData({state, commit, getters}) {
        let userData = await SMStorage.getSMLoginData();
        userData = userData && userData.response ? userData.response : userData;
        const email = userData && userData.email ? userData.email : "";
        try {
            const body = {email};
            const res = await get_user();
            let {success, message, information} = res.data || {};
            if (success === 1) {
                await SMStorage.storeSMLoginData(information);
                commit("updateUserUIConstraints", ({
                    [USER_DATA]: information
                }));
            }
        } catch (error) {
            console.error(error)
        }
    }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    updateUserUIConstraints(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[USER_KEY];
            const data = Object.assign(formData, obj);

            mutations.updateUserState(state, data);
        } catch (error) {
            Utils.log("Update UI Constraints ===> error ", error);
        }
    },
    updateUserState(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            let formData = _state[USER_KEY];

            state[USER_KEY] = Object.assign(formData, obj);
        } catch (error) {
            Utils.log("Update Login State ===> error ", error);
        }
    },
    resetUserState() {
        for (let f in state) {
            Vue.set(state, f, INITIAL_STATE[f]);
        }
    }
};

const getters = {
    user_map_to_props(state) {
        let _state = Utils.parseState(state);

        const user_key = _state[USER_KEY];

        const user_data = user_key && user_key[USER_DATA] ? user_key[USER_DATA] : [];

        return {
            user_data
        }
    },
    get_user_country(state) {
        let _state = Utils.parseState(state);
        return (_state[USER_KEY][USER_DATA] && _state[USER_KEY][USER_DATA].country.length > 0) ? _state[USER_KEY][USER_DATA].country : ''
    },
    get_user_country_code(state) {
        let _state = Utils.parseState(state);
        return (_state[USER_KEY][USER_DATA] && _state[USER_KEY][USER_DATA].country.length > 0) ? _state[USER_KEY][USER_DATA].country[0].tld.substring(1).toUpperCase() : ''
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
