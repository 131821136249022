<template>
  <div class="w-100">
    <MusicSubHeader/>
    <div class="container_xxl">
      <div class="row">
        <div class="col">
          <div class="event_booking_demo">

          </div>
        </div>
      </div>
      <div class="feed_wrapper ">
        <div class="center_centerbar_wraper_home">
          <div class="event-booking">
            <div class="even-booking-name">52 Event Bookings</div>
            <div class="right-select">
              <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                <select multiple="multiple" title="Featured" data-max-options="1" dropupauto="false" tabindex="-98"
                        class="filters-select selectpicker for-overview">
                  <option value="0">Latest</option>
                  <option value="1">Featured</option>
                  <option value="2">Trending</option>
                  <option value="3">Popular</option>
                  <option value="4">High Rating</option>
                  <option value="5">Low Rating</option>
                </select>
                <button type="button" data-toggle="dropdown" role="combobox" aria-owns="bs-select-1"
                        aria-haspopup="listbox" aria-expanded="false" title="Featured"
                        class="btn dropdown-toggle btn-light bs-placeholder">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">Any Status</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu ">
                  <div role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true" class="inner show">
                    <ul role="presentation" class="dropdown-menu inner show">
                      <li><a role="option" id="bs-select-9-0" tabindex="0" aria-selected="false" aria-setsize="6"
                             aria-posinset="1" class="dropdown-item"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Negotiating</span></a></li>
                      <li><a role="option" id="bs-select-9-0" tabindex="0" aria-selected="false" aria-setsize="6"
                             aria-posinset="1" class="dropdown-item"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Confirmed</span></a></li>
                      <li><a role="option" id="bs-select-9-0" tabindex="0" aria-selected="false" aria-setsize="6"
                             aria-posinset="1" class="dropdown-item"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Declined</span></a></li>
                      <li><a role="option" id="bs-select-9-0" tabindex="0" aria-selected="false" aria-setsize="6"
                             aria-posinset="1" class="dropdown-item"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Cancelled</span></a></li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="items-header">
            <div class="iteam-header-text w-235">ITEM</div>
            <div class="iteam-header-text w-116">OFFER</div>
            <div class="iteam-header-text w-157">DATES</div>
            <div class="iteam-header-text w-200px">STATUS</div>
            <div class="iteam-header-text w-10"></div>
          </div>
          <div class="items-main-content-details">
            <div class="items-main-content-details-list ">
              <div class="item-name-details w-235">
                <div class="item-name-detail-img">
                  <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAYAAADhu0ooAAAFRklEQVRogeWbf0xVZRjHP/dKXFIwISvUzUXzQghaUA3aMOt/1xZOzb9ICX8VLAbpVlmNyj9Sqo01V1szHYoIaI7NUdAaEkwcPzY1zfwBrQQaiQy4MIh76Y/n3LgQXM65930N7LvdP+65z3me7/c+57zve573ObaeXhcaEAqkAM8CscDjwHJgERBh2PQDvcDvwE3gElAPNAIjqgnZFApdBGwA1gNrgPkB+hkE6oAKoBy4o4KcCqGJQB6wCbg/aEYTMQSUAoVIxgNGMEIfBT5GMmgPhoQJeJAM7wbaA3EQCMFQ4C3gMnKp6haJEWODEfNtg4MlWM2oEygBnrIaSDFakVvlmtkTrGTjJaCJ/14kQBLCJd3sCWaF7gLKgIUBkNKFhcAJhNuMMCN0L/A5MC8IUrowD+G2dybDmYTuBApUMNKMAoTrtPAnNB0oUkpHL4qQqW5KTDfqrkBu9gc0kdKFPmSwvD75h6ky6kBWI3NNJIwPUI7JP0wlNA9I1s1II5KA/MkHJ1+6MciaMtAF+WzBILIGb/MemJzRj5j7IkE07PM94JtRJ3CF2TlfBgIPEA/8AhMzuod7RySItj3eL96MRgCdwAJdUX++cplDh76itvYHOjs6eDg6mufXvkDGli0kJqzWFdYFLAH6vUIzgK91RSsuPkLO69MvXPYXfkpm5jZd4V8BDnsv3Zd1Ramu/tavSIA383L55lSFLgqbQS7dUKQuo3y0HXWPEv1QJB6Px5R95x+3cTjCVNMYBKLsQCqappSa6u9MiwSorDytg8Z8IMWOlCS14Pz5Rkv2zU1NmpiQage0DXnu0VFL9qNua/YWEGcHHtPlPXHVKkv28fErNTEhztbT6/oVqaIrx4BrgOXLHjFtf6PtNyIjo3RQabcDkTo8A4QvCOfAJ5+Zsn2/4ENdIgEi7IzvhWjB1q1ZZOe84dfm1W3bycnJ1UkjwtbT6xrTGcGLqqozfPnFQc6ercXjdmOz2UhLW0PW9p2sW/ei7vAjtp5eVx+aszoLcDsEWRVpFdrV1UlLSzN/dncT6nDguC+U4b9GGBke5sHFi0lKSmbp0mU6KfSHAB1oGnXb29soPLCfY0ePMDbm/w7ZuGkzefm7cTpjdVC5ZUc2YZXjVEU5yU8mcrT48IwiAU6UlpDyTBLHjx/TQeeqHbig2mtl5WkyMzMCOnfXjixKS0sUM+KqrafX9RxQq8pjR+ctEuODv/yaWy8SE6Ns0bbWDpxDHmWUID/X/5xpFtmv7VDiB9HWaEcaI+pUeOzq6qSq6owKVzQ01HOz7YYKV3XAsLfCoOTxvqamWoWbf/B9TY0KNxUwXgUsRxojgkJD/Y/BupiAluagn0+HEG2EGAfuIHsWgQ2VBpyxsYSFqSuFrHA6g3VRhtG+41vATgAuArZgvc8SjCFFhUswsYD9E/IP3Csow6c36X+7ydSGbDTNdezDRyRMvePtABqYu3ukrUhlc9j34FQbwcNIs1LfXSClGn3ARiaJhOmbNa4DmYBbIynVcCOc/9W/AP67UsqBbB2MNCEbY3EwFWbqMzoIvKeUjh68i3CdFmY6xwqAHGQHebbBg3D7YCZDs72ARUgb6WwaoPoQTqaavqx0d54EngZaAiClGq0Il5NmT7DaVHwNmaPeQcHTTgAYMmKnYqFXFwLrnh5BVk8rkfXk3bh3x4xYCUZsy29RBNMm3o5Mzk8g/Q86Mjxk+F5txGrza+0HKl8HiUIGh3QgjeBeB6lHKgNlQI8KciqF+iIUuY9SgTjjswTZuQs3bAaQh+JupLZ8AVljn0PDCz5/A7hncp9ilFv5AAAAAElFTkSuQmCC">
                </div>
                <div class="iteam-name-detail-text ">
                  <h1>Venue or Club name</h1>
                  <p>Afanasii Petrov</p>
                </div>
              </div>

              <div class="item-main-detail-offer w-116">
                <p>$ 240</p>
                <span>Hotel, Transport</span>
              </div>

              <div class="item-main-detail-date w-157">
                <p>20.12.2020</p>
                <span>3 days</span>
              </div>

              <div class="item-main-detail-status w-200px">
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6654 1.5L4.2487 7.91667L1.33203 5" stroke="#2ED297" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="Confirmed">Confirmed</span>
              </div>

              <div class="item-main-detail-more w-10">
                <div class="more-main">
                  <div class="dropdown ">
                     <span data-toggle="dropdown" aria-expanded="false">
                       <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path class="next-dots-btn"
                             d="M7.9987 9.08529C8.36689 9.08529 8.66536 8.78681 8.66536 8.41862C8.66536 8.05043 8.36689 7.75195 7.9987 7.75195C7.63051 7.75195 7.33203 8.05043 7.33203 8.41862C7.33203 8.78681 7.63051 9.08529 7.9987 9.08529Z"
                             fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"/>
                       <path class="next-dots-btn"
                             d="M7.9987 4.41829C8.36689 4.41829 8.66536 4.11982 8.66536 3.75163C8.66536 3.38344 8.36689 3.08496 7.9987 3.08496C7.63051 3.08496 7.33203 3.38344 7.33203 3.75163C7.33203 4.11982 7.63051 4.41829 7.9987 4.41829Z"
                             fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"/>
                       <path class="next-dots-btn"
                             d="M7.9987 13.7518C8.36689 13.7518 8.66536 13.4533 8.66536 13.0851C8.66536 12.7169 8.36689 12.4185 7.9987 12.4185C7.63051 12.4185 7.33203 12.7169 7.33203 13.0851C7.33203 13.4533 7.63051 13.7518 7.9987 13.7518Z"
                             fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"/>
                       </svg>
                     </span>

                    <div class="dropdown-menu dropdown-menu-right dropdown_sponsor wvwntd" style="">
                      <button class="dropdown-item" type="button">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path
                                d="M8.19792 15.1024C11.8798 15.1024 14.8646 12.1176 14.8646 8.43571C14.8646 4.75381 11.8798 1.76904 8.19792 1.76904C4.51602 1.76904 1.53125 4.75381 1.53125 8.43571C1.53125 12.1176 4.51602 15.1024 8.19792 15.1024Z"
                                stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.19922 11.1022V8.43555" stroke="#071526" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.19922 5.76904H8.20589" stroke="#071526" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="16" height="16" fill="white" transform="translate(0.199219 0.435547)"/>
                            </clipPath>
                          </defs>
                        </svg>

                        Open Details
                      </button>
                      <button class="dropdown-item" type="button">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M13.3346 14.3047V12.9714C13.3346 12.2641 13.0537 11.5858 12.5536 11.0857C12.0535 10.5856 11.3752 10.3047 10.668 10.3047H5.33464C4.62739 10.3047 3.94911 10.5856 3.44902 11.0857C2.94892 11.5858 2.66797 12.2641 2.66797 12.9714V14.3047"
                              stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path
                              d="M7.9987 7.63802C9.47146 7.63802 10.6654 6.44411 10.6654 4.97135C10.6654 3.49859 9.47146 2.30469 7.9987 2.30469C6.52594 2.30469 5.33203 3.49859 5.33203 4.97135C5.33203 6.44411 6.52594 7.63802 7.9987 7.63802Z"
                              stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        Seller Page
                      </button>
                      <button class="dropdown-item decline-offer" type="button">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 4.30469L4 12.3047" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                          <path d="M4 4.30469L12 12.3047" stroke="#FF0039" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>

                        Decline Offer
                      </button>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div class="items-main-content-details-list ">
              <div class="item-name-details w-235">
                <div class="item-name-detail-img">
                  <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAYAAADhu0ooAAAFRklEQVRogeWbf0xVZRjHP/dKXFIwISvUzUXzQghaUA3aMOt/1xZOzb9ICX8VLAbpVlmNyj9Sqo01V1szHYoIaI7NUdAaEkwcPzY1zfwBrQQaiQy4MIh76Y/n3LgQXM65930N7LvdP+65z3me7/c+57zve573ObaeXhcaEAqkAM8CscDjwHJgERBh2PQDvcDvwE3gElAPNAIjqgnZFApdBGwA1gNrgPkB+hkE6oAKoBy4o4KcCqGJQB6wCbg/aEYTMQSUAoVIxgNGMEIfBT5GMmgPhoQJeJAM7wbaA3EQCMFQ4C3gMnKp6haJEWODEfNtg4MlWM2oEygBnrIaSDFakVvlmtkTrGTjJaCJ/14kQBLCJd3sCWaF7gLKgIUBkNKFhcAJhNuMMCN0L/A5MC8IUrowD+G2dybDmYTuBApUMNKMAoTrtPAnNB0oUkpHL4qQqW5KTDfqrkBu9gc0kdKFPmSwvD75h6ky6kBWI3NNJIwPUI7JP0wlNA9I1s1II5KA/MkHJ1+6MciaMtAF+WzBILIGb/MemJzRj5j7IkE07PM94JtRJ3CF2TlfBgIPEA/8AhMzuod7RySItj3eL96MRgCdwAJdUX++cplDh76itvYHOjs6eDg6mufXvkDGli0kJqzWFdYFLAH6vUIzgK91RSsuPkLO69MvXPYXfkpm5jZd4V8BDnsv3Zd1Ramu/tavSIA383L55lSFLgqbQS7dUKQuo3y0HXWPEv1QJB6Px5R95x+3cTjCVNMYBKLsQCqappSa6u9MiwSorDytg8Z8IMWOlCS14Pz5Rkv2zU1NmpiQage0DXnu0VFL9qNua/YWEGcHHtPlPXHVKkv28fErNTEhztbT6/oVqaIrx4BrgOXLHjFtf6PtNyIjo3RQabcDkTo8A4QvCOfAJ5+Zsn2/4ENdIgEi7IzvhWjB1q1ZZOe84dfm1W3bycnJ1UkjwtbT6xrTGcGLqqozfPnFQc6ercXjdmOz2UhLW0PW9p2sW/ei7vAjtp5eVx+aszoLcDsEWRVpFdrV1UlLSzN/dncT6nDguC+U4b9GGBke5sHFi0lKSmbp0mU6KfSHAB1oGnXb29soPLCfY0ePMDbm/w7ZuGkzefm7cTpjdVC5ZUc2YZXjVEU5yU8mcrT48IwiAU6UlpDyTBLHjx/TQeeqHbig2mtl5WkyMzMCOnfXjixKS0sUM+KqrafX9RxQq8pjR+ctEuODv/yaWy8SE6Ns0bbWDpxDHmWUID/X/5xpFtmv7VDiB9HWaEcaI+pUeOzq6qSq6owKVzQ01HOz7YYKV3XAsLfCoOTxvqamWoWbf/B9TY0KNxUwXgUsRxojgkJD/Y/BupiAluagn0+HEG2EGAfuIHsWgQ2VBpyxsYSFqSuFrHA6g3VRhtG+41vATgAuArZgvc8SjCFFhUswsYD9E/IP3Csow6c36X+7ydSGbDTNdezDRyRMvePtABqYu3ukrUhlc9j34FQbwcNIs1LfXSClGn3ARiaJhOmbNa4DmYBbIynVcCOc/9W/AP67UsqBbB2MNCEbY3EwFWbqMzoIvKeUjh68i3CdFmY6xwqAHGQHebbBg3D7YCZDs72ARUgb6WwaoPoQTqaavqx0d54EngZaAiClGq0Il5NmT7DaVHwNmaPeQcHTTgAYMmKnYqFXFwLrnh5BVk8rkfXk3bh3x4xYCUZsy29RBNMm3o5Mzk8g/Q86Mjxk+F5txGrza+0HKl8HiUIGh3QgjeBeB6lHKgNlQI8KciqF+iIUuY9SgTjjswTZuQs3bAaQh+JupLZ8AVljn0PDCz5/A7hncp9ilFv5AAAAAElFTkSuQmCC">
                </div>
                <div class="iteam-name-detail-text ">
                  <h1>Venue or Club name</h1>
                  <p>Afanasii Petrov</p>
                </div>
              </div>

              <div class="item-main-detail-offer w-116">
                <p>$ 240</p>
                <span>Hotel, Transport</span>
              </div>

              <div class="item-main-detail-date w-157">
                <p>20.12.2020</p>
                <span>3 days</span>
              </div>

              <div class="item-main-detail-status w-200px">
                <p class="pos-to">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7422 3.69775L3.74219 10.6978" stroke="#FF0039" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.74219 3.69775L10.7422 10.6978" stroke="#FF0039" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </p>

                <span class="offer-decline">Offer Declined</span>
              </div>

              <div class="item-main-detail-more w-10">
                <div class="more-main">
                     <span>
                       <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path
                           d="M7.9987 9.08529C8.36689 9.08529 8.66536 8.78681 8.66536 8.41862C8.66536 8.05043 8.36689 7.75195 7.9987 7.75195C7.63051 7.75195 7.33203 8.05043 7.33203 8.41862C7.33203 8.78681 7.63051 9.08529 7.9987 9.08529Z"
                           fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round"/>
                       <path
                           d="M7.9987 4.41829C8.36689 4.41829 8.66536 4.11982 8.66536 3.75163C8.66536 3.38344 8.36689 3.08496 7.9987 3.08496C7.63051 3.08496 7.33203 3.38344 7.33203 3.75163C7.33203 4.11982 7.63051 4.41829 7.9987 4.41829Z"
                           fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round"/>
                       <path
                           d="M7.9987 13.7518C8.36689 13.7518 8.66536 13.4533 8.66536 13.0851C8.66536 12.7169 8.36689 12.4185 7.9987 12.4185C7.63051 12.4185 7.33203 12.7169 7.33203 13.0851C7.33203 13.4533 7.63051 13.7518 7.9987 13.7518Z"
                           fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round"/>
                       </svg>
                     </span>

                </div>
              </div>

            </div>
            <div class="items-main-content-details-list ">
              <div class="item-name-details w-235">
                <div class="item-name-detail-img">
                  <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAYAAADhu0ooAAAFRklEQVRogeWbf0xVZRjHP/dKXFIwISvUzUXzQghaUA3aMOt/1xZOzb9ICX8VLAbpVlmNyj9Sqo01V1szHYoIaI7NUdAaEkwcPzY1zfwBrQQaiQy4MIh76Y/n3LgQXM65930N7LvdP+65z3me7/c+57zve573ObaeXhcaEAqkAM8CscDjwHJgERBh2PQDvcDvwE3gElAPNAIjqgnZFApdBGwA1gNrgPkB+hkE6oAKoBy4o4KcCqGJQB6wCbg/aEYTMQSUAoVIxgNGMEIfBT5GMmgPhoQJeJAM7wbaA3EQCMFQ4C3gMnKp6haJEWODEfNtg4MlWM2oEygBnrIaSDFakVvlmtkTrGTjJaCJ/14kQBLCJd3sCWaF7gLKgIUBkNKFhcAJhNuMMCN0L/A5MC8IUrowD+G2dybDmYTuBApUMNKMAoTrtPAnNB0oUkpHL4qQqW5KTDfqrkBu9gc0kdKFPmSwvD75h6ky6kBWI3NNJIwPUI7JP0wlNA9I1s1II5KA/MkHJ1+6MciaMtAF+WzBILIGb/MemJzRj5j7IkE07PM94JtRJ3CF2TlfBgIPEA/8AhMzuod7RySItj3eL96MRgCdwAJdUX++cplDh76itvYHOjs6eDg6mufXvkDGli0kJqzWFdYFLAH6vUIzgK91RSsuPkLO69MvXPYXfkpm5jZd4V8BDnsv3Zd1Ramu/tavSIA383L55lSFLgqbQS7dUKQuo3y0HXWPEv1QJB6Px5R95x+3cTjCVNMYBKLsQCqappSa6u9MiwSorDytg8Z8IMWOlCS14Pz5Rkv2zU1NmpiQage0DXnu0VFL9qNua/YWEGcHHtPlPXHVKkv28fErNTEhztbT6/oVqaIrx4BrgOXLHjFtf6PtNyIjo3RQabcDkTo8A4QvCOfAJ5+Zsn2/4ENdIgEi7IzvhWjB1q1ZZOe84dfm1W3bycnJ1UkjwtbT6xrTGcGLqqozfPnFQc6ercXjdmOz2UhLW0PW9p2sW/ei7vAjtp5eVx+aszoLcDsEWRVpFdrV1UlLSzN/dncT6nDguC+U4b9GGBke5sHFi0lKSmbp0mU6KfSHAB1oGnXb29soPLCfY0ePMDbm/w7ZuGkzefm7cTpjdVC5ZUc2YZXjVEU5yU8mcrT48IwiAU6UlpDyTBLHjx/TQeeqHbig2mtl5WkyMzMCOnfXjixKS0sUM+KqrafX9RxQq8pjR+ctEuODv/yaWy8SE6Ns0bbWDpxDHmWUID/X/5xpFtmv7VDiB9HWaEcaI+pUeOzq6qSq6owKVzQ01HOz7YYKV3XAsLfCoOTxvqamWoWbf/B9TY0KNxUwXgUsRxojgkJD/Y/BupiAluagn0+HEG2EGAfuIHsWgQ2VBpyxsYSFqSuFrHA6g3VRhtG+41vATgAuArZgvc8SjCFFhUswsYD9E/IP3Csow6c36X+7ydSGbDTNdezDRyRMvePtABqYu3ukrUhlc9j34FQbwcNIs1LfXSClGn3ARiaJhOmbNa4DmYBbIynVcCOc/9W/AP67UsqBbB2MNCEbY3EwFWbqMzoIvKeUjh68i3CdFmY6xwqAHGQHebbBg3D7YCZDs72ARUgb6WwaoPoQTqaavqx0d54EngZaAiClGq0Il5NmT7DaVHwNmaPeQcHTTgAYMmKnYqFXFwLrnh5BVk8rkfXk3bh3x4xYCUZsy29RBNMm3o5Mzk8g/Q86Mjxk+F5txGrza+0HKl8HiUIGh3QgjeBeB6lHKgNlQI8KciqF+iIUuY9SgTjjswTZuQs3bAaQh+JupLZ8AVljn0PDCz5/A7hncp9ilFv5AAAAAElFTkSuQmCC">
                </div>
                <div class="iteam-name-detail-text ">
                  <h1>Venue or Club name</h1>
                  <p>Afanasii Petrov</p>
                </div>
              </div>

              <div class="item-main-detail-offer w-116">
                <p>$ 240</p>
                <span>Hotel, Transport</span>
              </div>

              <div class="item-main-detail-date w-157">
                <p>20.12.2020</p>
                <span>3 days</span>
              </div>

              <div class="item-main-detail-status w-200px">
                <p class="pos-to">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M11.8346 5.99984C11.8346 9.2215 9.22296 11.8332 6.0013 11.8332C2.77964 11.8332 0.167969 9.2215 0.167969 5.99984C0.167969 2.77818 2.77964 0.166504 6.0013 0.166504C9.22296 0.166504 11.8346 2.77818 11.8346 5.99984ZM6.50146 2.5C6.50146 2.22386 6.27761 2 6.00146 2C5.72532 2 5.50146 2.22386 5.50146 2.5V6C5.50146 6.18939 5.60847 6.36252 5.77786 6.44721L8.11119 7.61388C8.35818 7.73737 8.65852 7.63726 8.78201 7.39027C8.90551 7.14328 8.8054 6.84295 8.55841 6.71945L6.50146 5.69098V2.5Z"
                          fill="#8B949F"/>
                  </svg>

                </p>

                <span class="nego">Negotiating</span>
              </div>

              <div class="item-main-detail-more w-10">
                <div class="more-main">
                     <span>
                       <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path
                           d="M7.9987 9.08529C8.36689 9.08529 8.66536 8.78681 8.66536 8.41862C8.66536 8.05043 8.36689 7.75195 7.9987 7.75195C7.63051 7.75195 7.33203 8.05043 7.33203 8.41862C7.33203 8.78681 7.63051 9.08529 7.9987 9.08529Z"
                           fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round"/>
                       <path
                           d="M7.9987 4.41829C8.36689 4.41829 8.66536 4.11982 8.66536 3.75163C8.66536 3.38344 8.36689 3.08496 7.9987 3.08496C7.63051 3.08496 7.33203 3.38344 7.33203 3.75163C7.33203 4.11982 7.63051 4.41829 7.9987 4.41829Z"
                           fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round"/>
                       <path
                           d="M7.9987 13.7518C8.36689 13.7518 8.66536 13.4533 8.66536 13.0851C8.66536 12.7169 8.36689 12.4185 7.9987 12.4185C7.63051 12.4185 7.33203 12.7169 7.33203 13.0851C7.33203 13.4533 7.63051 13.7518 7.9987 13.7518Z"
                           fill="#47505B" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round"/>
                       </svg>
                     </span>

                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="center_rightbar_wraper">
          <div class="booking-cal">
            <div class="book-cal-heading">Bookings Calendar</div>
            <div class="cal-sec">
              calendar-section
            </div>
          </div>
          <div class="related-widget widget-info new_widget">
            <div class="widget-item">
              <div class="item">
                <div class="items-main">
                  <div class="item-title">CONFIRMED</div>
                  <div class="item-subtitle">2</div>
                </div>
              </div>
              <div class="item">
                <div class="items-main">
                  <div class="item-title">PENDING</div>
                  <div class="item-subtitle">2</div>
                </div>
              </div>
              <div class="item">
                <div class="items-main">
                  <div class="item-title">CANCELLED</div>
                  <div class="item-subtitle">2</div>
                </div>
              </div>
            </div>
          </div>
          <div class="total-section">
            <div class="title-block">Total</div>
            <div class="total-ul">
              <ul>
                <li>
                  <div class="total-li-section">
                    <p>Confirmed Amount: <span>$ 1 043</span></p>
                  </div>
                </li>
                <li>
                  <div class="total-li-section">
                    <p>Pending Amount: <span>$ 320</span></p>
                  </div>
                </li>
                <li>
                  <div class="total-li-section">
                    <p>Cancelled Amount: <span>$ 240</span></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="recent-orders-sec">
            <div class="recent-order-heading">
              <h1>Negotiations</h1>
              <div class="event-see-all">
                SEE ALL
                <span>
                                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 1L6.135 5.173L1 9.713" stroke="#47505B" stroke-width="1.3"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                  </svg>
                                </span>
              </div>
            </div>
            <div class="event-orders-list">
              <ul>
                <li>
                  <div class="event-orders-details">
                    <h1>Club name</h1>
                    <p>Afanasii Petrov</p>
                  </div>
                  <div class="event-order-right">
                    <h1>34,20$</h1>
                    <p>29 May 2020, 04:30 PM</p>
                  </div>
                </li>
                <li>
                  <div class="event-orders-details">
                    <h1>Club name</h1>
                    <p>Afanasii Petrov</p>
                  </div>
                  <div class="event-order-right">
                    <h1>34,20$</h1>
                    <p>29 May 2020</p>
                  </div>
                </li>
                <li>
                  <div class="event-orders-details">
                    <h1>Club name</h1>
                    <p>Afanasii Petrov</p>
                  </div>
                  <div class="event-order-right">
                    <h1>34,20$</h1>
                    <p>29 May 2020</p>
                  </div>
                </li>
                <li>
                  <div class="event-orders-details">
                    <h1>Club name</h1>
                    <p>Afanasii Petrov</p>
                  </div>
                  <div class="event-order-right">
                    <h1>34,20$</h1>
                    <p>29 May 2020</p>
                  </div>
                </li>
                <li>
                  <div class="event-orders-details">
                    <h1>Club name</h1>
                    <p>Afanasii Petrov</p>
                  </div>
                  <div class="event-order-right">
                    <h1>34,20$</h1>
                    <p>29 May 2020</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
// import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'

export default {
  data() {
    return {}
  },
  components: {
    MusicSubHeader
  },
  computed: {},
  methods: {}
}
</script>
