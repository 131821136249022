<template>
  <div class="engage_settings">
    <div class="engage_settings_header">
      <div>
        <h3>Button Visibility: Hidden</h3>
        <h6>To publish, turn the switcher on.</h6>
      </div>
      <div class="switch_sm_wrapper">
        <input type="checkbox" v-model="listen_now_settings.visibility" id="switch27"/>
        <label class="switch_sm" for="switch27">
          <div class="switch_toggler"></div>
        </label>
      </div>
    </div>
    <div class="engage_settings_body">
      <div class="engage_set_title engage_set_title_bold">
        <h4>What do you want user to listen?</h4>
        <p>Choose from list below</p>
      </div>

      <div class="engage_fields_group">
        <div class="row">

          <div class="col-5">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">What to Listen</label>
              <div class="select_box">
                <select v-model="listen_now_settings.what_to_listen" class="form_control">
                  <option value="0">Album</option>
                  <option value="1">Album 1</option>
                  <option value="2">Album 2</option>
                  <option value="3">Album 3</option>
                </select>
              </div>

            </div>
          </div>
          <div class="col-7">
            <div class="engage_field mb-0">
              <label class="engage_fields_label">Album</label>
              <div class="select_box">
                <select v-model="listen_now_settings.album" class="form_control">
                  <option value="0">Choose album from list</option>
                  <option value="1">Album 1</option>
                  <option value="2">Album 2</option>
                  <option value="3">Album 3</option>
                </select>
              </div>

            </div>
          </div>

        </div>

      </div>


    </div>
    <div class="engage_settings_footer">
      <h4>Your Button preview:</h4>
      <button type="button">
        <!-- <img src="img/svg/music.svg" alt="music"/> -->
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 12V3.33333L14 2V10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path
              d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M12 12.667C13.1046 12.667 14 11.7716 14 10.667C14 9.56242 13.1046 8.66699 12 8.66699C10.8954 8.66699 10 9.56242 10 10.667C10 11.7716 10.8954 12.667 12 12.667Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Listen Now
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listen_now_settings: {
        visibility: false,
        what_to_listen: null,
        album: null
      }
    }
  },
  props: {
    listenNowSettings: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    'listen_now_settings.visibility'() {
      this.$emit('getListenNowData', this.listen_now_settings)
    },
    'listen_now_settings.what_to_listen'() {
      this.$emit('getListenNowData', this.listen_now_settings)
    },
    'listen_now_settings.album'() {
      this.$emit('getListenNowData', this.listen_now_settings)
    },
    listenNowSettings() {
      this.setListenNowSettingData()
    }
  },
  mounted() {
    this.setListenNowSettingData()
  },
  methods: {
    setListenNowSettingData () {
      this.listen_now_settings = this.listenNowSettings
    }
  }
}
</script>
