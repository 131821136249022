<template>
  <div class=" col-12" style="padding-left: 0">
    <div class="media-tabs friends_tab">
      <ul id="myTab" role="tablist" class="nav nav-tabs5">
        <li class="nav-item">
          <a id="home-tab" data-toggle="tab" href="#Upcoming" role="tab" aria-controls="home" aria-selected="true" class="nav-link active" @click="getUpcomingEvents">
            UPCOMING
          </a>
        </li>
        <li class="nav-item">
          <a id="profile-tab" data-toggle="tab" href="#Past" role="tab" aria-controls="profile" aria-selected="false" class="nav-link" @click="getPastEvents">
            PAST
          </a>
        </li>
      </ul>
      <SearchEvent :currentActiveEventTab="currentActiveEventTab" />
    </div>
    <div id="myTabContent" class="tab-content">
      <div id="Upcoming" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade mt_20 show active">
        <EventsCard
          v-for="(event, index) in getCurrentPageUpcomingEvents"
          :key="index"
          :event="event"
        />
      </div>
      <div id="Past" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade mt_20">
        <EventsCard
          v-for="(event, index) in getCurrentPagePastEvents"
          :key="index"
          :event="event"
        />
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import EventsCard from "@/components/Events/components/EventsCard";
import SearchEvent from "@/components/ClubManager/Club/SearchEvent.vue";

export default {
  data(){
    return{
      currentActiveEventTab:'upcoming',
      eventsSearchKey:''
    }
  },
  components: {
    EventsCard,
    SearchEvent
  },
  computed:{
    getCurrentPageUpcomingEvents() {
      return this.$store.getters.getCurrentPageUpcomingEvents;
    },
    getCurrentPagePastEvents() {
      return this.$store.getters.getCurrentPagePastEvents
    },
  },
  methods:{
    searchEvent(value){
      this.eventsSearchKey = value;
    },
    getUpcomingEvents(){
      this.eventsSearchKey = '';
      this.currentActiveEventTab = 'upcoming'
      // let payload = {
      //   showMore : false,
      //   page_id: this.$route.params.id,
      //   artistFollowersSearchKey: null,
      //   search_type: this.currentActiveFollowerTab,
      //   name: null,
      // }
      // this.$store.dispatch('getArtistPageFollowers', payload)
    },
    getPastEvents(){
      this.eventsSearchKey = '';
      this.currentActiveEventTab = 'past'
      // let payload = {
      //   showMore : false,
      //   page_id: this.$route.params.id,
      //   artistFollowersSearchKey: null,
      //   search_type: this.currentActiveFollowerTab,
      //   name: null,
      // }
      // this.$store.dispatch('getArtistPageFollowings', payload)
    }
  }
}
</script>

<style>
/* .center_centerbar_wraper_home {
  flex: 1;
  min-width: 770px !important;
} */
</style>
