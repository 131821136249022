var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.key_this_component,staticClass:"overview-content",class:{ dontScroll: _vm.createEventsModal }},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col px-0"},[_c('div',{staticClass:"overview-mains"},[_c('h1',{staticClass:"Page_title"},[_vm._v(_vm._s(_vm.title_event_type))]),_c('Search',{on:{"changeFilterKey":_vm.changeFilterKey,"explorEvents":_vm.explorEvents}})],1),_c('div',{staticClass:"overview-genres"},[_c('Genres',{on:{"explorEvents":_vm.explorEvents}})],1),_c('div',{staticClass:"overview-Venue"},[_c('VenueContainer',{on:{"explorEvents":_vm.explorEvents}})],1),_c('div',{staticClass:"overview-featured"},[(
              _vm.GET_ALL_EVENTS.featured &&
              _vm.GET_ALL_EVENTS.featured.data.length > 0
            )?_c('Featured',{key:_vm.keyFeatured,attrs:{"events":_vm.GET_ALL_EVENTS.featured,"eventsType":`${
              _vm.title_event_type === 'EXPLORE EVENTS'
                ? 'Featured'
                : 'Featured' + ' ' + _vm.title_event_type
            }`,"eventsPath":"featured-events"}}):_vm._e()],1),_c('div',{staticClass:"overview-events"},[_c('div',{staticClass:"overview-events-left"},[(
                _vm.GET_ALL_EVENTS.popular &&
                _vm.GET_ALL_EVENTS.popular.data.length > 0
              )?_c('EventsContainer',{key:_vm.keyPopular,staticClass:"mt-0",attrs:{"events":_vm.GET_ALL_EVENTS.popular,"eventsType":`${
                _vm.title_event_type === 'EXPLORE EVENTS'
                  ? 'Popular Events'
                  : 'Popular' + ' ' + _vm.title_event_type
              }`,"eventsPath":"popular-events"}}):_vm._e(),(
                _vm.GET_ALL_EVENTS.thisweek &&
                _vm.GET_ALL_EVENTS.thisweek.data.length > 0
              )?_c('EventsContainer',{key:_vm.keyThisweek,attrs:{"events":_vm.GET_ALL_EVENTS.thisweek,"eventsType":`${
                _vm.title_event_type === 'EXPLORE EVENTS'
                  ? 'This Week'
                  : 'This Week' + ' ' + _vm.title_event_type
              }`,"eventsPath":"this-week"}}):_vm._e(),(
                _vm.GET_ALL_EVENTS.online &&
                _vm.GET_ALL_EVENTS.online.data.length > 0 &&
                _vm.GET_EVENTS_SEARCH_FOR.filterStatus !== 'offline'
              )?_c('EventsContainer',{key:_vm.keyOnline,attrs:{"events":_vm.GET_ALL_EVENTS.online,"eventsType":`${
                _vm.title_event_type === 'EXPLORE EVENTS'
                  ? 'Online Events'
                  : 'Online' + ' ' + _vm.title_event_type
              }`,"eventsPath":"online-events"}}):_vm._e(),(
                _vm.GET_ALL_EVENTS.festivals &&
                _vm.GET_ALL_EVENTS.festivals.data.length > 0
              )?_c('EventsContainer',{key:_vm.keyFestivals,attrs:{"events":_vm.GET_ALL_EVENTS.festivals,"eventsType":`${
                _vm.title_event_type === 'EXPLORE EVENTS'
                  ? 'Festival Events'
                  : 'Festival' + ' ' + _vm.title_event_type
              }`,"eventsPath":"festival-events"}}):_vm._e(),(
                _vm.GET_ALL_EVENTS.latest && _vm.GET_ALL_EVENTS.latest.data.length > 0
              )?_c('EventsContainer',{key:_vm.keyLatest,attrs:{"events":_vm.GET_ALL_EVENTS.latest,"eventsType":`${
                _vm.title_event_type === 'EXPLORE EVENTS'
                  ? 'Latest Events'
                  : 'Latest' + ' ' + _vm.title_event_type
              }`,"eventsPath":"Latest-events"}}):_vm._e()],1),_c('div',{staticClass:"overview-events-filter"},[_c('div',{staticClass:"create-event-btn"},[_c('button',{on:{"click":function($event){_vm.createEventsModal = true}}},[_c('svg',{attrs:{"width":"11","height":"11","viewBox":"0 0 11 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z","fill":"#D1433A"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025V5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025V5.50025Z","fill":"#D1433A"}})]),_vm._v(" ADD New EVENT ")])]),_c('FilterEvents',{key:_vm.filtrKey,on:{"changeFilterKey":_vm.changeFilterKey,"explorEvents":_vm.explorEvents}}),_c('news-feed')],1)])])])]),(_vm.createEventsModal)?_c('div',{staticClass:"create-events-wrapper"},[_c('event-post-container',{on:{"closeEventModal":function($event){_vm.createEventsModal = false}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }