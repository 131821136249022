<template>
    <div>
        <div class="user-card" v-if="(this.item && this.item.user && this.item.user.id) !== this.userId">
            <div style="" class="user-card-detail">
                <div
                  @click="redirectWidgetsToProfilePage($event, item.user.username ? item.user.username : '')"
                >
                    <img :src="this.item && this.item.user && this.item.user.artwork_url" alt="dsds" class="user-picture"/>
                </div>
                <div class="user-details">
                    <span class="user-detail__username"
                          @click="redirectWidgetsToProfilePage($event, item.user.username ? item.user.username : '')"
                    >
                        {{this.item && this.item.user && this.item.user.full_name}}
                    </span>
                    <span class="user-detail_role">
                        Artist
                    </span>
                    <span class="user-detail_location">{{(this.item && this.item.user && this.item.user.current_city) ? this.item.user.current_city + ',' : ' '}} {{(this.item && this.item.user && this.item.user.country.length) ? this.item.user.country[0].name : ''}} </span>
                </div>
                <div style="width: 100%;" v-if="!follow">
                    <span class="follow-image-container" @click="changeFollowStatus('user',1)" >
                        <img class="follow-image" :src="require('../../../../assets/icons/icons/home/news-feed/unfollow-icon.svg')" alt="follow-image"/>
                    </span>
                </div>
                <div style="width: 100%;" v-if="follow">
                    <span class="follow-image-container" @click="changeFollowStatus('user',0)" >
                        <img class="follow-image" :src="require('../../../../assets/icons/icons/home/news-feed/follow-icon.svg')" alt="follow-image"/>
                    </span>
                </div>
            </div>
            <div class="user-post-container">
                <div class="user-total-posts">
                    <span class="user-post-count">{{this.item && this.item.user && this.item.user.posts_count}}</span>
                    <p class="user-post-text">Posts</p>
                </div>
                <div class="border-line"></div>
                 <div class="user-total-posts">
                    <span class="user-post-count">{{this.item && this.item.user && this.item.user.follower_count}}</span>
                    <p class="user-post-text">Followers</p>
                </div>
                <div class="border-line"></div>
                 <div class="user-total-posts">
                    <span class="user-post-count">{{this.item && this.item.user && this.item.user.following_count}}</span>
                    <p class="user-post-text">Following</p>
                </div>
            </div>
            <div class="user-card-btn-container">
                <button class="user-card-engage-btn">Engage</button>
                <button class="user-card-follow-btn" v-if="follow" @click="changeFollowStatus('user',0)">
                   Unfollow
                </button>
                 <button class="user-card-follow-btn" v-if="!follow" @click="changeFollowStatus('user',1)">
                   Follow
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserProfileCard",
        data(){
            return{
                follow: Boolean,
            }
        },
        mounted() {
            if(this.item.user.follow_status === 1) {
                this.follow = true
            } else {
                this.follow = false
            }
        },
        computed:{
            userId(){
                return this.$store.getters.user_map_to_props.user_data.id;
            }
        },
        props:{
            item: {
                type: Object
            }
        },
        methods:{
            async changeFollowStatus(object_type,action){
            if(this.item){
                 let payload = {
                     id : this.item.user.id,
                     object_type : object_type,
                     action : action,
                 }
            const {data} = await this.$store.dispatch('changeFollowStatus', payload)
                if(data.success === 1) {
                    if(action === 0) {
                    this.follow = false
                    this.item.user.follower_count = data.data
                    } else {
                    this.follow = true
                    this.item.user.follower_count = data.data
                    }
                }
            }
            },
        }
    }
</script>

<style lang="scss" scoped>
.user-card{
    width: 370px;
    height: 220px;
    border-radius: 6px;
    padding: 0px 19px 0px 19px;
    position: absolute;
    top: 0px;
    border: 1px solid #E6EAF0;
    background-color: #fff !important;
    box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
    cursor: default;
    z-index: 1;

    .user-card-detail{
        height: 45%;
        display: flex;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #E6EAF0;
        .user-picture{
            width: 55px;
            height: 55px;
            .user-detail__avatar-circle{
                position: relative;
                font-size: 51px;
                line-height: 43px;
                left:-4px;
            }
        }
        .follow-image-container{
            display: flex;
            justify-content: end;
        }
        .follow-image{
            border-radius: 0px !important;
            height: 30px;
            width: 30px;
        }
    }
    .user-details{
        margin-left: 7px;
        display: flex;
        flex-direction: column;
        width: 70%;
        .user-detail__username{
            font-weight: 700;
            line-height: 16px;
            font-size: 13px;
        }
        .user-detail_role{
            font-weight: 500;
            line-height: 15px;
            font-size: 12px;
            margin-top: 5px;
        }
        .user-detail_location{
            font-size: 10px;
            line-height: 12px;
            font-weight: 400;
            margin-top: 7px;
        }
    }
}
.user-post-container{
    display: flex;
    width: 100%;
    color: black;
    justify-content: space-between;
    padding: 10px 16px;
    padding-left: 28px;
    .user-total-posts{
        padding-block: 7px;
        .user-post-count{
            display: flex;
            justify-content: center;
            color: #47505B;
            font-weight: 700;
            font-size: 13px;
        }
        .user-post-text{
            color: #8B949F;
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 0;
        }
    }
    .border-line{
        border-right: 2px solid #E6EAF0;
    }
}
.user-card-btn-container{
    display: flex;
    width: 100%;
    .user-card-engage-btn{
        border-radius: 6px;
        height: 30px;
        width: 160px;
        border: 1px solid #E6EAF0;
        background-color: #fff;
        padding: 0px;
    }
    .user-card-follow-btn{
        border-radius: 6px;
        height: 30px;
        width: 160px;
        border: 1px solid #E6EAF0;
        background-color: #D1433A;
        color: white;
        margin-left: 10px;
        padding: 0px;
    }
}

</style>
