<template>
  <div class="w-770">
    <div class="wr_resent_orders">
      <article>
        <h3>{{ title }}</h3>

        <router-link
          to="/event-manager/page/orders/detail"
          v-if="!$route.path.includes('/event-manager/page/orders')"
        >
          SEE ALL<span class="for_hover_gray_btn"
            ><svg
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L6.135 5.173L1 9.713"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </router-link>
      </article>
      <div class="title_colume">
        <ul>
          <li class="w_74"><span>Date & Time</span></li>
          <li class="w_128"><span>Customer</span></li>
          <li class="w_142"><span>Event</span></li>
          <li class="w_76"><span>Status</span></li>
          <li class="for_margin"><span>Amount & Fee</span></li>
        </ul>
        <ResentOredrsCards
          v-for="(item, index) in ResentOredrsCardsData"
          :key="item.id"
          :event="item"
          :index="index"
        />
      </div>
      <button
        v-if="!$route.path.includes('/event-manager/page/orders')"
        class="btn_empty_border_blue for_hover_empty_border_blue"
      >
        EXPORT ATTENDANCE LIST<svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 14C5.28333 14 5.521 13.904 5.713 13.712C5.90433 13.5207 6 13.2833 6 13C6 12.7167 5.90433 12.479 5.713 12.287C5.521 12.0957 5.28333 12 5 12C4.71667 12 4.479 12.0957 4.287 12.287C4.09567 12.479 4 12.7167 4 13C4 13.2833 4.09567 13.5207 4.287 13.712C4.479 13.904 4.71667 14 5 14ZM5 10C5.28333 10 5.521 9.904 5.713 9.712C5.90433 9.52067 6 9.28333 6 9C6 8.71667 5.90433 8.479 5.713 8.287C5.521 8.09567 5.28333 8 5 8C4.71667 8 4.479 8.09567 4.287 8.287C4.09567 8.479 4 8.71667 4 9C4 9.28333 4.09567 9.52067 4.287 9.712C4.479 9.904 4.71667 10 5 10ZM5 6C5.28333 6 5.521 5.904 5.713 5.712C5.90433 5.52067 6 5.28333 6 5C6 4.71667 5.90433 4.479 5.713 4.287C5.521 4.09567 5.28333 4 5 4C4.71667 4 4.479 4.09567 4.287 4.287C4.09567 4.479 4 4.71667 4 5C4 5.28333 4.09567 5.52067 4.287 5.712C4.479 5.904 4.71667 6 5 6ZM8 14H14V12H8V14ZM8 10H14V8H8V10ZM8 6H14V4H8V6ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16ZM2 2V16V2Z"
            fill="#D1433A"
          />
        </svg>
      </button>
    </div>
    <Pagination
      v-if="$route.path.includes('/event-manager/page/orders')"
      :count="15"
      :id="'100'"
      :keyId="'forum_id'"
      :actionName="'getForumThreads'"
    />
  </div>
</template>

<script>
import ResentOredrsCards from "../EMElement/ResentOredrsCards.vue";
import Pagination from "@/components/Common/Elements/Pagination.vue";
export default {
  components: {
    ResentOredrsCards,
    Pagination,
  },
  props: ["title"],
  data() {
    return {
      ResentOredrsCardsData: [
        {
          data: "14/10/2020",
          id: "1",
          time: "04:30 PM",
          email: "afanasiipetrov@mail.ru",
          costumer: "Afanasii Petrov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Confirmed",
          price: "$32,20",
          discount: "$1,24",
        },
        {
          data: "14/10/2020",
          id: "2",
          time: "04:30 PM",
          email: "ivanpikelov@gmail.com",
          costumer: "Ivan Pikekov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Cancelled",
          price: "$32,20",
          discount: "$1,24",
        },
        {
          data: "14/10/2020",
          id: "3",
          time: "04:30 PM",
          email: "afanasiipetrov@mail.ru",
          costumer: "Afanasii Petrov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Pending",
          price: "$32,20",
          discount: "$1,24",
        },
        {
          data: "14/10/2020",
          id: "4",
          time: "04:30 PM",
          email: "afanasiipetrov@mail.ru",
          costumer: "Afanasii Petrov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Confirmed",
          price: "$32,20",
          discount: "$1,24",
        },
        {
          data: "14/10/2020",
          id: "5",
          time: "04:30 PM",
          email: "afanasiipetrov@mail.ru",
          costumer: "Afanasii Petrov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Pending",
          price: "$32,20",
          discount: "$1,24",
        },
        {
          data: "14/10/2020",
          id: "6",
          time: "04:30 PM",
          email: "afanasiipetrov@mail.ru",
          costumer: "Afanasii Petrov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Pending",
          price: "$32,20",
          discount: "$1,24",
        },
        {
          data: "14/10/2020",
          id: "7",
          time: "04:30 PM",
          email: "afanasiipetrov@mail.ru",
          costumer: "Afanasii Petrov",
          eventName: "Playground Presents...",
          created_data: "24/11/2020",
          status: "Pending",
          price: "$32,20",
          discount: "$1,24",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wr_resent_orders {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  height: fit-content;
  article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #071526;
    }
    a {
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      text-transform: uppercase;
      color: #000000;
      align-items: center;
      cursor: pointer;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e4e6eb;
        border-radius: 4px;
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }
    }
  }
  .title_colume {
    margin-top: 32px;
    ul {
      padding: 0px 80px 0 20px;
      list-style-type: none;
      color: #8b949f;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        span {
          border-bottom: 1px dashed #e6eaf0;
        }
      }
    }
  }
  .for_margin {
    margin-left: -23px;
  }
  button {
    margin: 20px 0 0 auto;
    width: 220px;
    height: 40px;
    svg {
      margin-left: 10px;
    }
  }
}
</style>
<style>
.w_74 {
  width: 74px;
}
.w_128 {
  width: 128px;
}
.w_142 {
  width: 142px;
}
.w_76 {
  width: 76px;
}
</style>