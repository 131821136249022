<template>
  <div class="related-widget">
    <div class="widget-title">
      {{ title }}
      <div class="link-more near_arrow without-slider in-title pointerClass"><a v-on:click="changeTab">show all
        <span class="sl-arrow"> <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
      </a>
      </div>
    </div>
    <div v-if="typeName === 'Playlist Name'">
      <div v-for="(song, index) in songs.slice(0,3)" :key="index">
        <RelatedAlbumTile :song="song"/>
      </div>
    </div>
    <div v-else-if="typeName === 'Song Name'">
      <div v-for="(song, index) in albums.slice(0,3)" :key="index">
        <RelatedAlbumTile :song="song"/>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import RelatedAlbumTile from "../../../Common/UiComponenets/Music/RelatedAlbumTile"
import { getRelatedSongsAndAlbumsByTheirID } from "../../../../apis/APIs";
export default {
  data() {
    return {
      albums: []
    }
  },
  components: {
    RelatedAlbumTile
  },
  props: {
    title: String,
    typeName: String,
    comment_type : {
      type: String,
      default: ''
    }
  },
  computed:{
    songs(){
      return (this.$store.getters.getPlaylists && this.$store.getters.getPlaylists.length > 0 && this.$store.getters.getPlaylists[0].playlist_songs.length > 0) ? this.$store.getters.getPlaylists[0].playlist_songs : []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    changeTab: function () {
      this.$parent.change_active_tab('all-likes');
    },
    getData() {
      if(this.typeName === 'Song Name'){
        this.getRelatedAlbumData()
      } else if(this.typeName === 'Playlist Name') {
        let payload = {
          term : '',
          offset : 0,
          limit : 3
        }
        this.$store.dispatch('myPlaylists',payload)
      }
    },
    async getRelatedAlbumData(){
      try{
         await this.$store.dispatch('loading', true);
         const { data } = await getRelatedSongsAndAlbumsByTheirID(this.$route.params.id, (this.comment_type === 'album') ? 'related-albums' : 'related-songs');
         const { information, success} = data;
         if(success === 1) {
           this.albums = information.data || []
         }
        await this.$store.dispatch('loading', false);
      } catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>
