<template>
  <div class="wr_eventsMyTickets">
    <h1 class="title">My Tickets</h1>
    <div class="wr_contener">
      <div class="contener">
        <div class="ticket-info-tabs">
          <div>
            <div
              @click="
                changeTabsAccordingType({
                  tab_name: 'upcoming',
                  tab_Type: 'active_tabs',
                })
              "
              class="single-tab"
              :class="{ active_tabs: active_tabs.tab_name === 'upcoming' }"
            >
              UPCOMING<span
                :class="{
                  zeroEvent: GET_MY_TICKETS_UPCOMING_LIST.total === 0,
                }"
                >{{ GET_MY_TICKETS_UPCOMING_LIST.total }}</span
              >
            </div>
            <div
              @click="
                changeTabsAccordingType({
                  tab_name: 'past',
                  tab_Type: 'active_tabs',
                })
              "
              class="single-tab"
              :class="{ active_tabs: active_tabs.tab_name === 'past' }"
            >
              PAST<span
                :class="{
                  zeroEvent: GET_MY_TICKETS_PAST_LIST.total === 0,
                }"
                >{{ GET_MY_TICKETS_PAST_LIST.total }}</span
              >
            </div>
          </div>
          <div class="wr_event_form">
            <span
              @click="
                changeTabsAccordingType({
                  tab_name: 'grid',
                  tab_Type: 'activ_form',
                })
              "
              class="form_grid"
              :class="{ activ_form: activ_form.tab_name === 'grid' }"
              ><svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 5.5V0.5H13.5V5.5H8.5Z"
                  stroke="#D1433A"
                  stroke-linecap="square"
                />
                <path
                  d="M0.5 5.5V0.5H5.5V5.5H0.5Z"
                  stroke="#D1433A"
                  stroke-linecap="square"
                />
                <path
                  d="M8.5 13.5V8.5H13.5V13.5H8.5Z"
                  stroke="#D1433A"
                  stroke-linecap="square"
                />
                <path
                  d="M0.5 13.5V8.5H5.5V13.5H0.5Z"
                  stroke="#D1433A"
                  stroke-linecap="square"
                />
              </svg>
            </span>
            <span
              @click="
                changeTabsAccordingType({
                  tab_name: 'list',
                  tab_Type: 'activ_form',
                })
              "
              class="form_list"
              :class="{ activ_form: activ_form.tab_name === 'list' }"
              ><svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 4.5V0.5H13.5V4.5H0.5Z"
                  stroke="#D1433A"
                  stroke-linecap="square"
                />
                <path
                  d="M0.5 13.5V9.5H13.5V13.5H0.5Z"
                  stroke="#D1433A"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
        <div
          v-if="activ_form.tab_name === 'grid'"
          class="wr_MyTickets_contener"
        >
          <EventsCard
            v-for="(item, index) in active_tabs.tab_name === 'upcoming'
              ? GET_MY_TICKETS_UPCOMING_LIST.data
              : GET_MY_TICKETS_PAST_LIST.data"
            :key="item.ticket.id + Math.random() * new Date().getMilliseconds()"
            :event="item.ticket.event"
            :ticket_id="item.ticket.id"
            :order_id="item.order_id"
            :no_margin_right="!((index + 1) % 4) ? true : false"
            @getFavoritesData="getAllFavoritesData_past_and_upcoming"
            :mRight="'30px'"
          />
          <EmptyDataCard
            v-if="
              (active_tabs.tab_name === 'upcoming' &&
                GET_MY_TICKETS_UPCOMING_LIST.data.length === 0) ||
              (active_tabs.tab_name === 'past' &&
                GET_MY_TICKETS_PAST_LIST.data.length === 0)
            "
            :item="emptyCartData"
            @buttonClick="goToOverviewPage"
          />
        </div>
        <div v-if="activ_form.tab_name === 'list'">
          <EventListCart
            v-for="item in active_tabs.tab_name === 'upcoming'
              ? GET_MY_TICKETS_UPCOMING_LIST.data
              : GET_MY_TICKETS_PAST_LIST.data"
            :key="item.ticket.id + Math.random() * new Date().getMilliseconds()"
            :event="item.ticket.event"
            :ticket_id="item.ticket.id"
            :order_id="item.order_id"
            :name="item.name"
            @getFavoritesData="getAllFavoritesData_past_and_upcoming"
          />
          <EmptyDataCard
            v-if="
              (active_tabs.tab_name === 'upcoming' &&
                GET_MY_TICKETS_UPCOMING_LIST.data.length === 0) ||
              (active_tabs.tab_name === 'past' &&
                GET_MY_TICKETS_PAST_LIST.data.length === 0)
            "
            :item="emptyCartData"
            @buttonClick="goToOverviewPage"
          />
        </div>
        <div
          @click="showMoreEvent"
          v-if="
            (active_tabs.tab_name === 'upcoming' &&
              GET_MY_TICKETS_UPCOMING_LIST.total > currentUpcoming) ||
            (active_tabs.tab_name === 'past' &&
              GET_MY_TICKETS_PAST_LIST.total > currentPast)
          "
          class="show_more_btn"
        >
          <div class="show-more musicbar_more">
            <a>
              <span class="show_more_bg"
                ><svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW 10 MORE EVENTS
              </span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div class="create-event-btn">
          <button @click="createEventsModal = true">
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
                fill="#D1433A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025V5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025V5.50025Z"
                fill="#D1433A"
              />
            </svg>

            ADD New EVENT
          </button>
        </div>
        <FilterEvents
          :key="filtrKey"
          @getAllFavoritesData_past_and_upcoming="
            getAllFavoritesData_past_and_upcoming
          "
        />
      </div>
    </div>
    <div v-if="createEventsModal" class="create-events-wrapper">
      <event-post-container @closeEventModal="createEventsModal = false" />
    </div>
  </div>
</template>

<script>
import EventsCard from "@/components/Events/components/EventsCard.vue";
import FilterEvents from "../components/Filter.vue";
import EventListCart from "../components/EventListCart.vue";
import EmptyDataCard from "@/components/Common/UiComponenets/EmptyDataCard.vue";
import EventPostContainer from "../components/CreateEvents/EventPostContainer.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    EventsCard,
    FilterEvents,
    EventListCart,
    EmptyDataCard,
    EventPostContainer,
  },
  data() {
    return {
      filtrKey: 0,
      createEventsModal: false,
      activ_form: { tab_name: "grid" },
      active_tabs: { tab_name: "upcoming" },
      currentUpcoming: 8,
      currentPast: 8,
      emptyCartData: {
        title: "Not Upcoming or Past to Events yet",
        description:
          "You aren’t going to any events yet. Let’s find some events! ",
        icon: require(`@/assets/img/calendar.png`),
        buttonText: "FIND EVENTS",
      },
    };
  },
  methods: {
    ...mapActions(["myTickets"]),
    async changeTabsAccordingType(params) {
      this[params.tab_Type].tab_name = params.tab_name;
    },
    async getDataMyTickets(payload) {
      await this.myTickets(payload);
    },
    async showMoreEvent() {
      await this.$store.dispatch("loading", true);
      await this.getDataMyTickets({
        value: this.active_tabs.tab_name,
        limit:
          this.active_tabs.tab_name === "upcoming"
            ? (this.currentUpcoming += 8)
            : (this.currentPast += 8),
        ...this.GET_EVENTS_SEARCH_FOR,
      });
      await this.$store.dispatch("loading", false);
    },
    goToOverviewPage() {
      this.$router.push("/events/page/overview");
    },
    changeFilterKey() {
      this.filtrKey += 1;
    },
    async getAllFavoritesData_past_and_upcoming(payload) {
      await this.$store.dispatch("loading", true);
      await this.getDataMyTickets({
        value: "upcoming",
        limit: this.currentUpcoming,
        ...payload,
      });
      await this.getDataMyTickets({
        value: "past",
        limit: this.currentPast,
        ...payload,
      });
      await this.$store.dispatch("loading", false);
    },
  },
  computed: {
    ...mapGetters([
      "GET_MY_TICKETS_PAST_LIST",
      "GET_MY_TICKETS_UPCOMING_LIST",
      "GET_EVENTS_SEARCH_FOR",
    ]),
  },
  async created() {
    await this.getAllFavoritesData_past_and_upcoming();
  },
};
</script>

<style lang="scss" scoped>
.wr_eventsMyTickets {
  margin-bottom: 90px;
  .title {
    font-family: DrukWide;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin: 35px 0 13px 0;
  }
  .wr_contener {
    display: flex;
    justify-content: space-between;
    .contener {
      width: 770px;
    }
    .ticket-info-tabs {
      margin-bottom: 27px;
      padding: 0 20px;
      background: #fff;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        height: 100%;
        padding-top: 5px;
        div {
          cursor: pointer;
          margin-right: 42px;
          font-family: HelveticaNeueBold;
          height: 100%;
          display: flex;
          color: #c4ccd6;
          align-items: center;
          border-bottom: 3px solid #fff;
          span {
            margin-left: 6px;
            min-width: 26px;
            background: #D1433A;
            border-radius: 4px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .active_tabs {
          color: #47505b;
          border-color: #D1433A;
        }
      }
      .wr_event_form {
        padding: 0;
        width: 82;
        height: 30px;
        display: flex;
        border: 1px solid #D1433A;
        border-radius: 3px;
        cursor: pointer;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          border-right: 1px;
          height: 100%;
          border-color: #D1433A;
        }
        .form_list {
          border-left: 1px;
        }
        .activ_form {
          background: #D1433A;
          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
  .wr_MyTickets_contener {
    display: flex;
    flex-wrap: wrap;
  }
  .zeroEvent {
    background: #c4ccd6 !important;
  }
}
</style>
