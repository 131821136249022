<template>
  <div class="wr_schedule">
    <div class="wr_btns">
      <button
        @click="cange_schedule_Or_Start_Now('schedule')"
        class="btn_schedul"
        :class="{ active_btn: schedule_Or_Start_Now === 'schedule' }"
      >
        SCHEDULE
      </button>
      <button
        @click="cange_schedule_Or_Start_Now('start_now')"
        class="btn_start_now"
        :class="{ active_btn: schedule_Or_Start_Now === 'start_now' }"
      >
        START NOW
      </button>
    </div>
    <div class="wr_content_schedul">
      <div class="schedul" v-if="schedule_Or_Start_Now === 'schedule'">
        <div class="left-date flex">
          <div>
            <div>
              <div class="start-date">
                <h4>Start Date</h4>
                <div
                  class="date-picker-container"
                  @click="showDatePicker('showS')"
                >
                  <p class="mb-0" v-if="info.startDate">
                    {{ isMoment(info.startDate) }}
                  </p>
                  <p class="mb-0" v-else>DD/MM/YYYY</p>
                  <date-picker
                    v-model="info.startDate"
                    type="date"
                    :show-week-number="false"
                    :open="show"
                    :confirm="true"
                    confirm-text="DONE"
                    @confirm="selectValue"
                  ></date-picker>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.60156 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M11.3984 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.10156 6.90039H13.9016"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
              <div class="start-time">
                <h4>Start Time</h4>
                <div
                  class="date-picker-container"
                  @click="showSTime = !showSTime"
                >
                  <p class="mb-0" v-if="info.startTime">{{ info.startTime }}</p>
                  <p class="mb-0" v-else>10:00 PM</p>
                  <date-picker
                    v-model="info.startTime"
                    type="time"
                    format="hh:mm a"
                    value-type="format"
                    :open="showSTime"
                    :confirm="true"
                    confirm-text="DONE"
                    @confirm="showSTime = false"
                  ></date-picker>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <!-- <p v-if="startTimeReq && startDateReq" class="required">
              This field is required.
            </p> -->
          </div>
          <div>
            <div>
              <div class="start-date ml-10 ">
                <h4>End Date</h4>
                <div
                  class="date-picker-container"
                  @click="showDatePicker('showE')"
                >
                  <p class="mb-0" v-if="info.endDate">
                    {{ isMoment(info.endDate) }}
                  </p>
                  <p class="mb-0" v-else>DD/MM/YYYY</p>
                  <date-picker
                    v-model="info.endDate"
                    type="date"
                    :show-week-number="false"
                    :open="showEDate"
                    :confirm="true"
                    confirm-text="DONE"
                    @confirm="showEDate = false"
                  ></date-picker>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.60156 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M11.3984 4.3V0"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.09998 2.09961H13.8C14.356 2.10276 14.8883 2.32487 15.2815 2.71802C15.6746 3.11116 15.8969 3.64348 15.9 4.19946V12.7996C15.8969 13.3555 15.6746 13.8879 15.2815 14.281C14.8883 14.6742 14.356 14.8963 13.8 14.8994H3.09998C2.54399 14.8963 2.01168 14.6742 1.61853 14.281C1.22538 13.8879 1.00315 13.3555 1 12.7996V4.19946C1.02033 3.64899 1.24807 3.12657 1.63757 2.73706C2.02708 2.34755 2.5495 2.11994 3.09998 2.09961V2.09961Z"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                    <path
                      d="M3.10156 6.90039H13.9016"
                      stroke="#8B949F"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
              <div class="start-time ml-10">
                <h4>End Time</h4>
                <div
                  class="date-picker-container"
                  @click="showETime = !showETime"
                >
                  <p class="mb-0" v-if="info.endTime">{{ info.endTime }}</p>
                  <p class="mb-0" v-else>10:00 PM</p>
                  <date-picker
                    v-model="info.endTime"
                    type="time"
                    format="hh:mm a"
                    value-type="format"
                    :open="showETime"
                    :confirm="true"
                    :show-second="false"
                    confirm-text="DONE"
                    @confirm="showETime = false"
                  ></date-picker>
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L9 1"
                      stroke="#47505B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <!-- <p v-if="endTimeReq && endDateReq" class="required">
              This field is required.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="start_now" v-if="schedule_Or_Start_Now === 'start_now'"></div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      schedule_Or_Start_Now: "start_now",
      show: false,
      showSTime: false,
      showETime: false,
      showEDate: false,
      info: {
        startDate: null,
        startTime: null,
        endTime: null,
        endDate: null,
      },
    };
  },
  methods: {
    cange_schedule_Or_Start_Now(type) {
      this.schedule_Or_Start_Now = type;
    },
    selectValue() {
      this.show = false;
    },
    showDatePicker(payload) {
      if (payload === "showS") {
        this.show = !this.show;
      } else if (payload === "showE") {
        this.showEDate = !this.showEDate;
      }
      setTimeout(() => {
        document
          .querySelectorAll(".cell")
          .forEach((el) => (el.style.borderRadius = "50%"));
      });
    },
    isMoment(date) {
      return moment(date).startOf("day").format("MMM Do,YYYY");
    },
    setScheduleInfo()
    {
      let success=0
      let schedule=0;
      const payload={
        is_room_schedule:schedule,
        room_schedule_start_date:null,
        room_schedule_start_time:null,
        room_schedule_end_date:null,
        room_schedule_end_time:null,
      };
      if(this.schedule_Or_Start_Now==='start_now')
      {
        success=1
      }
      else if(this.schedule_Or_Start_Now==='schedule')
      {
        if(this.info && this.info.startDate){
          success=1,
          schedule=1,
          payload.is_room_schedule=schedule,
          payload.room_schedule_start_date=this.info.startDate? this.info.startDate : null,
          payload.room_schedule_start_time=this.info.startTime? this.info.startTime : null,
          payload.room_schedule_end_date=this.info.endDate? this.info.endDate : null,
          payload.room_schedule_end_time=this.info.endTime? this.info.endTime : null
        }
      }
      if(success)
      {
        this.$store.commit("SET_LAUNCH_ROOM_SCHEDULE", payload);
      }
      return success
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_schedule {
  .wr_btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
      width: 260px;
      height: 40px;
      color: #47505b;
      background: #e6eaf0;
      border-radius: 4px;
      font-size: 12px;
      font-family: HelveticaNeueBold;
    }
    .active_btn {
      background: #D1433A;
      color: #ffffff;
    }
  }
  .wr_content_schedul {
    height: 373px;
  }
  .flex {
    display: flex;
  }
  .schedul {
    padding-top: 27px;
    .date-picker-container {
      border-radius: 4px;
      border: 1px solid #e6eaf0;
      width: 260px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      position: relative;
      cursor: pointer;
      p {
        color: #47505b;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueNormal;
      }
    }
    .start-date,
    .start-time {
        margin-bottom: 17px;
      h4 {
        font-family: HelveticaNeueBold;
        font-size: 13px;
      }
    }
  }
}
</style>