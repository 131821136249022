<template>
  <div class="w-100">
    <MusicSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="'/assets/img/jobs-svg.svg'" alt="icon">Jobs
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="search-events-panel">
                <h1>Search Your Events</h1>
              </div>
              <div class="event-search-panel">
                <div class="event-search-input">
                  <input type="text" placeholder="Search event by name, venue or location">
                  <span class="s-icon"><img :src="'/assets/img/search-icon.png'"></span>
                </div>
                <div class="event-setting-artist-btns">
                  <div class="event-management">
                    <button>JOB MANAGEMENT</button>
                  </div>
                  <div class="add-new-event">
                    <button>
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
                              fill="white"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025Z"
                              fill="white"></path>
                      </svg>

                      ADD NEW JOB
                    </button>
                  </div>
                </div>
              </div>
              <div class="all-event-details">
                <ul>
                  <li>
                    <div class="artist-job-related-detail">
                      <h1>Account managers needed for rapidly growing company</h1>
                      <div class="artist-job-icons">
                        <div class="artist-part-time">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6 3V6L8 7" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          Part time
                        </div>
                        <div class="artist-publish-icons">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8 1V3" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M4 1V3" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M1.5 5H10.5" stroke="#47505B" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                          Published: 7 Hours ago
                        </div>
                      </div>
                      <div class="proposel-hire">
                        <p><span>73 (66 NEW)</span> Proposals</p>
                        <p><span>2</span> Messaged</p>
                        <p><span>1</span> Hired</p>
                      </div>

                    </div>
                    <div class="artist-setting-rescue">
                      <div class="art-btn">
                        <button class="reuse-btn-artist">REUSE POSTING</button>
                      </div>
                      <div class="more-artist-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="artist-job-related-detail">
                      <h1>Account managers needed for rapidly growing company</h1>
                      <div class="artist-job-icons">
                        <div class="artist-part-time">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6 3V6L8 7" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          Part time
                        </div>
                        <div class="artist-publish-icons">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8 1V3" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M4 1V3" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M1.5 5H10.5" stroke="#47505B" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                          Published: 7 Hours ago
                        </div>
                      </div>
                      <div class="proposel-hire">
                        <p><span>73 (66 NEW)</span> Proposals</p>
                        <p><span>2</span> Messaged</p>
                        <p><span>1</span> Hired</p>
                      </div>

                    </div>
                    <div class="artist-setting-rescue">
                      <div class="art-btn">
                        <button class="reuse-btn-artist">REUSE POSTING</button>
                      </div>
                      <div class="more-artist-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="artist-job-related-detail">
                      <h1>Account managers needed for rapidly growing company</h1>
                      <div class="artist-job-icons">
                        <div class="artist-part-time">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6 3V6L8 7" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                          Part time
                        </div>
                        <div class="artist-publish-icons">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                                stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8 1V3" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M4 1V3" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M1.5 5H10.5" stroke="#47505B" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                          Published: 7 Hours ago
                        </div>
                      </div>
                      <div class="proposel-hire">
                        <p><span>73 (66 NEW)</span> Proposals</p>
                        <p><span>2</span> Messaged</p>
                        <p><span>1</span> Hired</p>
                      </div>

                    </div>
                    <div class="artist-setting-rescue">
                      <div class="art-btn">
                        <button class="reuse-btn-artist">REUSE POSTING</button>
                      </div>
                      <div class="more-artist-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M8.00065 8.66683C8.36884 8.66683 8.66732 8.36835 8.66732 8.00016C8.66732 7.63197 8.36884 7.3335 8.00065 7.3335C7.63246 7.3335 7.33398 7.63197 7.33398 8.00016C7.33398 8.36835 7.63246 8.66683 8.00065 8.66683Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.00065 3.99984C8.36884 3.99984 8.66732 3.70136 8.66732 3.33317C8.66732 2.96498 8.36884 2.6665 8.00065 2.6665C7.63246 2.6665 7.33398 2.96498 7.33398 3.33317C7.33398 3.70136 7.63246 3.99984 8.00065 3.99984Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                          <path
                              d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
                              fill="#47505B" stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                        </svg>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="show-more-artist-event"><a href="javascript:;"><img :src="'/assets/img/plus-i.png'"> SHOW 3
                  MORE JOB POSTS</a></div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
//  import MusicSubHeader from '../MusicSubHeader'
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'

export default {
  data() {
    return {}
  },
  components: {
    MusicSubHeader
  },
  computed: {},
  methods: {}
}
</script>
