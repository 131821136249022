<template>
  <div class="modal d-block custom-modal modalpayment">
    <div class="modals Signup_pop_overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modal_title_and_send_type.title }}</h5>
          <button
            @click="closeModal({ ticket_id: '' })"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <div class="svg-icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L4.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.5 4.5L13.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
        <div>
          <div class="wr_event_detalis">
            <label>Event</label>
            <div class="wr_detalis">
              <div class="detalis">
                <div class="div_img">
                  <img :src="single_cart.event.artwork_url" alt="" />
                </div>
                <div>
                  <h5>
                    {{
                      single_cart.name.length > 42
                        ? single_cart.name.slice(0, 42) + "..."
                        : single_cart.name
                    }}
                  </h5>
                  <span
                    >{{
                      single_cart.event.location.length > 42
                        ? single_cart.event.location.slice(0, 42) + "..."
                        : single_cart.event.location
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="send">
            <label>{{ modal_title_and_send_type.send_type }}</label>
            <div>
              <input type="text" v-model="email_or_phone" placeholder="Email" />
            </div>
          </div>
        </div>
        <div class="btns">
          <button class="cancel" @click="closeModal({ ticket_id: '' })">
            Cancel
          </button>
          <button class="send" @click="send_user_ticket_info">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["single_cart", "modal_title_and_send_type"],
  data() {
    return {
      email_or_phone: "",
    };
  },
  methods: {
    ...mapActions(["sendTicketViaEmail"]),
    closeModal(params) {
      this.$emit("openCloseSendEmailOrPhoneModals", params);
    },
    async send_user_ticket_info() {
      await this.$store.dispatch("loading", true);
      await this.sendTicketViaEmail({
        ticket_id: this.single_cart.id,
        email: this.email_or_phone,
      });
      await this.$store.dispatch("loading", false);
      this.$emit("openCloseSendEmailOrPhoneModals",{ ticket_id: '' });
    },
  },
  computed: {
    ...mapGetters(["GET_MY_ORDER_SINGLE"]),
  },
  created() {
    this.email_or_phone =
      this.modal_title_and_send_type.title === "Send Ticket via E-mail"
        ? this.GET_MY_ORDER_SINGLE.email
        : this.modal_title_and_send_type.title === "Send QR via SMS"
        ? this.GET_MY_ORDER_SINGLE.contact_info.phone
        : "";
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  border: none !important;
  button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

label {
  color: #47505b;
  font-weight: 700;
  font-size: 13px;
  font-family: HelveticaNeueBold;
}
input {
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  width: 100%;
  padding: 0 10px;
  height: 30px;
}
.wr_detalis {
  border: 1px solid #e6eaf0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 17px;
  .detalis {
    display: flex;
    align-items: center;
    .div_img {
      width: 50px;
      height: 50px;
      border-radius: 2px;
      margin-right: 20px;
      background: #c3c3c3;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2px;
      }
    }
    h5 {
      color: #071526;
      font-weight: 700;
      font-size: 13px;
      font-family: HelveticaNeueBold;
      margin: 0;
    }
    span {
      font-family: HelveticaNeueNormal;
      color: #47505b;
      opacity: 0.7;
      font-weight: 400;
      font-size: 12px;
    }
  }
}
.btns {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  button {
    width: 130px;
    height: 30px;
    border-radius: 4px;
    color: #47505b;
    background: #e6eaf0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #D1433A1A;
    }
  }
  .send {
    background: #D1433A;
    color: #ffffff;
    &:hover {
      background-color: #DA6961;
    }
  }
}
</style>