<template>
  <div
    :class="[
      'dropdown-menu absolute-position more-dropdown',
      showOption ? 'show' : '',
    ]"
    aria-labelledby="moreDropdown"
    :style="{ top: optionHeight + 'px', left: optionWidth + 'px' }"
  >
    <a class="dropdown-item item-selling js-save-wishlist" href="#">
      <span>Buy</span>
      <div class="more-price">$2.66</div>
    </a>

    <a
      class="dropdown-item item-selling js-save-wishlist"
      href="#"
      @click="addToFavoriteToggle"
    >
      <span>Favorites</span>
    </a>
    <div
      class="dropdown-item js-rate with-submenu"
      @mouseenter="showSubMenu = 'block'"
      @mouseleave="showSubMenu = 'none'"
    >
      <span>Rate</span>
      <div class="more-rate margin-right-this">5.0</div>
      <div class="svg-icon arrow-icon d-flex ml-auto">
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="c-line-1"
            d="M1 1L5.3 5.231L1 9.833"
            stroke="#47505B"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
      <div
        class="submenu"
        :style="{ display: showSubMenu }"
        @mouseover="showSubMenu = 'block'"
        @mouseout="showSubMenu = 'none'"
      >
        <div class="submenu-item">
          <div class="text">Click to Rate</div>
          <div
            class="submenu-stars with-js"
            @click="$event.target.classList.toggle('selected')"
          >
            <div class="star svg-icon" data-value="1">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="2">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="3">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="4">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon" data-value="5">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="submenu-item">
          <div class="text">Average Rating</div>
          <div class="submenu-stars">
            <div class="star svg-icon active">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon active">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon active">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
            <div class="star svg-icon">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="c-line-1 c-fill-1"
              >
                <path
                  class="c-line-1 c-fill-1"
                  d="M8.45224 9.53623L5.03193 11.863L6.3397 8.09924L2.9043 5.76377H7.14347L8.45124 2L9.76806 5.76377H14.0002L10.5799 8.09056L11.8876 11.8543L8.45224 9.53623Z"
                  fill="white"
                  stroke="white"
                  stroke-width="1.3"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="submenu-item rate">9 Ratings</div>
        <div class="submenu-item sub-see-all d-flex ml-auto">
          See all {{ music_type }}
          <div class="svg-icon">
            <svg
              width="6"
              height="11"
              viewBox="0 0 6 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="c-line-1"
                d="M1 1L5.3 5.231L1 9.833"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <a class="dropdown-item js-repost-album" href="#">
      <span>Repost</span>
    </a>
    <a class="dropdown-item js-share-album" href="#">
      <span>Share</span>
      <span class="sh_tab">
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5.3 5.231L1 9.833"
            stroke="#47505B"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="c-line-1"
          ></path>
        </svg>
      </span>
    </a>
  </div>
</template>
<script type="application/javascript">
import { addToFavorite } from "../../../apis/APIs";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showSubMenu: "none",
      loading: false,
      user_id: null,
    };
  },
  mounted: function () {
    this.user_id = this.user.user_data.user_id;
    // console.log(user_id);
  },
  props: {
    optionWidth: Number,
    optionHeight: Number,
    showOption: Boolean,
    music_type: {
      type: String,
      default: "song",
    },
    music_type_id: {
      type: Number,
      default: 1,
    },
    favorite: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
  },

  methods: {
    async addToFavoriteToggle() {
      try {
        this.loading = true;
        let data = await addToFavorite(
          this.user_id,
          this.music_type_id,
          this.music_type,
          this.favorite
        );
        this.loading = false;
        console.log(data, "GEt data ");
      } catch (error) {
        this.loading = false;
      }
    },
    ...mapActions(["fetchUserData"]),
    //  addToFavoriteToggle:function(){
    //     alert('21');
    //  }
  },
};
</script>
