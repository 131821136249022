
<template>
  <div  class="d-flex" :key="index">
    <a class="FB_reactions ml-auto" :class="'FB_reactions-'+index" data-reactions-type='horizontal' :data-unique-id="index" :data-emoji-class="reaction" :message-id="messageId" :active-user="getActiveChatUserId" :user-id="userId">
    </a>
  </div>
</template>

<script>
import '/public/css/facebook-reaction.css';
import "/src/plugins/facebook-reactions";
export default {
  name: "ReactionComponent",
  props: {
    index: {
      type: Number,
      required: true
    },
    messageId: {
      type: String,
      required: true
    },
    reaction: {
      type: String
    }
  },
  computed: {
    getActiveChatUserId() {
      return this.$store.getters.getActiveChatUser.id;
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id
    }
  },
  mounted() {
    $('.FB_reactions-'+this.index).facebookReactions(this.messageId);
  }
};
</script>

<style scoped>

</style>
