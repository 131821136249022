<template>
  <div class=" boards m-t-25 ">
    <div class="row">
      <div class="col-md-12">
        <h4 class="title-head top-sign-bottom">{{ gridTitle }}</h4>
        <div class="plus-link cursor-pointer">
          <a @click="redirectToChannel(channelType,altName,pageTitle)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3.33333 0.5H16.6667C18.2315 0.5 19.5 1.76853 19.5 3.33333V16.6667C19.5 18.2315 18.2315 19.5 16.6667 19.5H3.33333C1.76853 19.5 0.5 18.2315 0.5 16.6667V3.33333C0.5 1.76853 1.76853 0.5 3.33333 0.5Z"
                  stroke="black"/>
              <path d="M10.956 9.716V6.93H9.458V9.716H6.672V11.214H9.458V14H10.956V11.214H13.742V9.716H10.956Z"
                    fill="black"/>
            </svg>
          </a>
        </div>
        <div class="lists">
          <SongOrAlbumRowList v-for="(item,index) in listData" :item="item" :key="index" :singles="true" :stream="isStreaming" />
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import SongOrAlbumRowList from "@/components/DesignGuide/Music/Components/SongOrAlbumRowList.vue";

export default {
  data() {
    return {}
  },
  components: {
    SongOrAlbumRowList
  },
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    gridTitle: {
      type: String,
      default: () => ""
    },
    channelType:{
      type:String,
      default:null
    },
    altName: String,
    pageTitle: String,
  },
  computed: {
  isStreaming(){
    return this.$store.getters.isMusicModeIsStreaming
  }
  },
  mounted() {
  },
  methods: {}
}
</script>
<style>
.switch-field input:checked + label::before {
  content: '';
  position: absolute;
  background-image: url(../../../../src/assets/img/check-white.png);
  width: 12px;
  height: 9px;
  top: 9px;
  left: 8%;
}

.all_singles .owl-item {
  width: 393px !important;
}
</style>
