<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div class="forum-text-editor-wrapper m-t-30">
          <div v-if="
            GET_TYPE_FORUM_THREADS === 'forum' ||
            GET_TYPE_FORUM_THREADS === 'edit_thread'
          " class="form-group">
            <label for="Tittle">Title</label>
            <input type="text" class="form-control" id="Tittle" aria-describedby="Tittle" v-model="title" />
          </div>

          <div class="form-group">
            <label for="Content">Content</label>
            <!-- <ckeditor class="forum-editor" v-model="editorData" :config="editorConfig"></ckeditor> -->
            <quill-editor ref="myTextEditor" v-model="editorData" :config="customToolbar">
            </quill-editor>
          </div>
          <div v-if="GET_TYPE_FORUM_THREADS === 'forum'" @click="createThread" class="btn btn-post">
            Post Thread
          </div>
          <div v-if="GET_TYPE_FORUM_THREADS === 'edit_thread'" @click="editMyThread" class="btn btn-post">
            Edit Thread
          </div>
          <div v-if="GET_TYPE_FORUM_THREADS === 'thread_reply'" @click="createPostReply" class="btn btn-post">
            Post Reply
          </div>
          <div v-if="GET_TYPE_FORUM_THREADS === 'edit_reply'" @click="editMyReply" class="btn btn-post">
            Edit Reply
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import CKEditor from "ckeditor4-vue";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css';
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    // ckeditor: CKEditor.component,
    quillEditor
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"]
      ],
      title: this.$store.getters.GET_EDITOR_TITLE_DATA,
      editorData: this.$store.getters.GET_EDITOR_DATA,
      // editorConfig: {},
    };
  },
  methods: {
    ...mapMutations(["changeEditorData", 'changeEditorTitle']),
    ...mapActions(["createNewThread", "postReplyOnThread", "editReply", 'editThread', 'getStatisticsForums']),
    async createThread() {
      if (this.title && this.editorData) {
        let body = {
          title: this.title,
          content: this.editorData.slice(3, this.editorData.length - 5),
          type: this.GET_TYPE_FORUM_THREADS,
          forum_id: this.GET_MAIN_FORUM_ID,
        };
        await this.createNewThread(body);
        await this.getStatisticsForums()
        this.$parent.changePage("forum_thread");
      }
    },
    async createPostReply() {
      if (this.editorData) {
        let body = {
          thread_id: this.GET_THREAD_ID,
          content: this.editorData.slice(3, this.editorData.length - 5),
        };
        await this.postReplyOnThread(body);
        await this.getStatisticsForums();
        this.$parent.changePage("thread_reply");
      }
    },
    async editMyReply() {
      if (this.editorData) {
        let body = {
          reply_id: this.GET_REPLY_ID,
          content: this.editorData.slice(3, this.editorData.length - 5),
        };
        await this.editReply(body);
        this.$parent.changePage("my_replies");
      }
    },
    async editMyThread() {
      if (this.title && this.editorData) {
        let body = {
          thread_id: this.GET_THREAD_ID,
          title: this.title,
          content: this.editorData.slice(3, this.editorData.length - 5),
        };
        await this.editThread(body)
        this.$parent.changePage("my_thread");
      }
    },
  },
  computed: {
    ...mapGetters([
      "GET_MAIN_FORUM_ID",
      "GET_TYPE_FORUM_THREADS",
      "GET_THREAD_ID",
      "GET_REPLY_ID",
    ]),
  },
  destroyed() {
    this.changeEditorData("");
    this.changeEditorTitle('')
  },
};
</script>
<style lang="scss">
.forum-editor {
  .cke_toolbar:nth-child(1) {
    display: none;
  }

  .cke_toolbar:nth-child(2) {
    display: none;
  }

  .cke_toolbar:nth-child(3) {
    display: none;
  }

  .cke_toolbar:nth-child(4) {
    display: none;
  }

  .cke_toolbar:nth-child(5) {
    display: none;
  }

  .cke_toolbar:nth-child(6) {
    display: none;
  }

  .cke_toolbar:nth-child(9) {
    display: none;
  }

  .cke_toolbar:nth-child(10) {
    display: none;
  }

  .cke_toolbar:nth-child(11) {
    display: none;
  }

  .cke_toolbar:nth-child(8) {
    display: block !important;

    .cke_toolgroup :nth-child(3) {
      display: none;
    }

    .cke_toolgroup :nth-child(5) {
      display: none;
    }
  }
}
</style>
