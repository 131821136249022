<template>
  <div class="sale-items lg-grid-view">
    <div class="row" v-if="header">
      <div class="col-sm-12">
        <h4 class="title-h4 Font_semibold">{{ gridTitle }}</h4>
      </div>
    </div>
    <div class="row position-relative">
      <div v-for="(song, index) in filterData" :key="index" class="col-sm-2 lg-col">
        <SongOrAlbumCardWithSquareImage
          :item="song"
          :type="song.reviewable ? 'review' : 'song'"
          :buy="!isStreaming"
          :stream="isStreaming"
        />
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import SongOrAlbumCardWithSquareImage from "@/components/DesignGuide/Music/Components/Grid.vue";
export default {
  data() {
    return {
      optionWidth: 0,
      isHovering: false,
      audio: null,
    };
  },
  components: {
    SongOrAlbumCardWithSquareImage,
  },
  props: {
    gridTitle: String,
    header: Boolean,
    slider: {
      type: Boolean,
      default: true,
    },
    hasHeart: {
      type: Boolean,
      default: false,
    },
    isStreaming: {
      type: Boolean,
      default: false,
    },
    listData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filterData() {
      return this.listData.filter(function(e) {
        if (e.reviewable === null) {
          return e.reviewable != null;
        } else {
          return e;
        }
      });
    },
  },
  mounted: function() {},
  methods: {
    isHoveringFun: function(val, index) {
      if (this.listData[index].isHovering == "0") {
        this.listData[index].isHovering = 0;
      }
      this.listData[index].isHovering = val;
    },
    getHeight: function(e) {
      if (this.optionWidth == 0) this.optionWidth = e.screenX;
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 2560px) {
  .lg-grid-view {
    .lg-col {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
</style>