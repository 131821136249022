<template>
  <div class="widget-item max-width">
    <div class="cover-image">
      <div class="img-wrapper"><img
        :src="(item && item.user && item.user.artwork_url) ? item.user.artwork_url : ''  " alt="">
      </div>
      <div class="status">
        <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.94219 5.26891L1.81219 6.81691L2.62619 4.30991L0.492188 2.76091H3.12819L3.94219 0.253906L4.76019 2.76091H7.39219L5.26219 4.30991L6.07619 6.81691L3.94219 5.26891Z"
            fill="white"></path>
        </svg>
      </div>
    </div>
    <div class="item-desc">
      <div class="name">{{ (item && item.user && item.user.name) ? item.user.name : ''  }} {{ (item && item.user && item.user.last_name) ? item.user.last_name : ''  }}</div>
      <div class="info">
        <p>Liked this track {{ (item && item.updated_at) ? item.updated_at : '' | formatDate}}</p>
      </div>
    </div>
    <div v-if="spinner" class="text-center ml-auto pr-2">
      <b-spinner class="spinner-color" small label="Small Spinner"></b-spinner>
    </div>
    <div v-else class="action" :class="[(follow ===  true) ? 'active' : '']" @click="changeFollowStatus(item.user_id, 'user', (follow ===  true) ? 0 : 1, item.user)">
      <div class="svg-icon">
        <div class="svg-icon">
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="c-fill_add-user"
                  d="M11.6304 13.6049V12.3512C11.6304 11.6862 11.3663 11.0484 10.896 10.5782C10.4258 10.1079 9.78799 9.84375 9.12297 9.84375H4.10805C3.44303 9.84375 2.80525 10.1079 2.335 10.5782C1.86476 11.0484 1.60059 11.6862 1.60059 12.3512V13.6049"
                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path class="c-fill_add-user"
                  d="M6.61488 7.33524C7.99972 7.33524 9.12235 6.21261 9.12235 4.82778C9.12235 3.44294 7.99972 2.32031 6.61488 2.32031C5.23005 2.32031 4.10742 3.44294 4.10742 4.82778C4.10742 6.21261 5.23005 7.33524 6.61488 7.33524Z"
                  stroke="#D1433A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path class="c-fill_add-user" d="M12.8613 1.60156V5.63632" stroke="#D1433A" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"></path>
            <path class="c-fill_add-user" d="M10.9131 3.61719H14.9478" stroke="#D1433A" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import createFormData from "../../../../mixins/common";
import { followOrUnFollowPage } from "../../../../apis/APIs";
export default {
  name: "FansTile",
  data(){
    return{
      follow: '',
      spinner: false
    }
  },
  props:{
    item:{
      type: Object,
    }
  },
  filters:{
    formatDate(value){
      return moment(value).calendar()
    }
  },
  mounted() {
    if(this.item) {
      this.follow = !!(this.item && this.item.user && this.item.user.follow_status === 1)
    }
  },
  methods:{
    async changeFollowStatus(id,object_type,action, my_followings){
      this.spinner = true
      let payload = {
        id : id,
        object_type : object_type,
        action : action,
        user : my_followings
      }
      const { data } = await this.$store.dispatch('changeFollowStatus', payload)
      const {success} = data
      if(success === 1) {
        if(action === 0) {
          this.follow = false
        } else {
          this.follow = true
        }
      }
      this.spinner = false
    }
  }
};
</script>

<style scoped lang="scss">
.widget-follow {
  .widget-item {
    .spinner-color {
      color: #D1433A;
    }
    .cover-image {
      .img-wrapper{
        img {
          width: inherit;
        }
      }
    }
    .action {
      &.active {
        fill: #D1433A;
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>
