<template>
  <div class="app-container">
    <LeftNav v-if="!isNav" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftNav from "@/components/Events/LeftNav";
export default {
  components: {
    LeftNav,
  },
  data() {
    return {
      arr: ["events.findEvents", "events.new-events"],
    };
  },
  computed: {
    isNav() {
      return this.arr.includes(this.$route.name) ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  padding-right: 21% !important;
  padding-left: 18% !important;
  padding-bottom: 100px;
}

.right-nav-space {
  padding-right: 280px;
}


</style>
