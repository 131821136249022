s<template>
  <div class="content-sidebar">
    <div class="related-widget widget-info new_widget">
      <div class="widget-item">
        <div class="item">
          <div class="items-main">
            <div class="item-title d-flex align-items-center">FOLLOWERS</div>
            <div class="item-subtitle d-flex align-items-center">{{followers | addZeroIfLengthIsLess}}</div>
          </div>
        </div>
        <div class="item">
          <div class="items-main">
            <div class="item-title d-flex align-items-center">PLAYS</div>
            <div class="item-subtitle d-flex align-items-center">{{ plays | addZeroIfLengthIsLess }}</div>
          </div>
        </div>
        <div class="item">
          <div class="items-main">
            <div class="item-title d-flex align-items-center">TRACKS</div>
            <div class="item-subtitle d-flex align-items-center">{{ tracks | addZeroIfLengthIsLess }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageStats",
  filters: {
    addZeroIfLengthIsLess (data) {
      return (data  > 9) ? data : '0' + data
    }
  },
  computed:{
    followers() {
      return this.$store.getters.getCurrentPageFollowersCount;
    },
    plays() {
      return this.$store.getters.getCurrentPagePlaysCount;
    },
    tracks() {
      return this.$store.getters.getCurrentPageTracksCount;
    }
  },
  methods:{
    getFollowers (followers) {
      if (followers > 999) {
        return (followers/1000)+'k';
      }
      else {
        return followers;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.items-main {
  padding: 9px 0 3px 10px;
}
</style>
