<template>
  <div class="content discover ">

    <!-- MUSIC UI Components_GRID ROWS -->

    <div class="container">
      <Grid1/>
      <Grid2/>
      <div class="col-12">
        <Grid3 :title="'Test'"/>
      </div>
      <Grid4/>
      <SongTitleList v-if="false"/>
      <Grid5/>
      <Grid6/>
      <Grid7/>
      <Grid9/>
      <Grid10/>
      <Grid11/>
      <Grid12_6 v-if="false"/>

      <!-- <Grid13/> -->
    </div>

    <!-- MUSIC UI Components_MY MUSIC (FAVORITES)  -->
    <div class="container">
      <div class="trackes-heading col-12">
        Songs, Albums
        <p>(GRID VIEW- FAVORITED)</p>
      </div>
      <br/>
      <div class="col-12">
        <h6>SONGS, ALBUMS, SAMPLE PACKS, PODCASTS_(Streaming)</h6>
      </div>
      <GridSelling6 :listData="listData" :hasHeart="true" :isStreaming="true"/>

      <br/>
      <div class="col-12">
        <h6>SONGS, ALBUMS, SAMPLE PACKS_(Selling)_WISHLIST)</h6>
      </div>
      <GridSelling6 :listData="listData" :hasHeart="true" :isStreaming="false"/>

      <br/>
      <!-- <div class="col-12">
         <h6>PLAYLISTS_(My Music)</h6>
      </div> -->

      <PlayList v-if="false"/>

      <div class="col-12">
        <h6>Artists</h6>
      </div>
      <div class="row col-8">
        <div class="col-sm-3 js-container-hover" v-for="n in 4" :key="n">
          <Artist/>
        </div>
      </div>


      <div class="row col-12">
        <div class="col-sm-2 js-container-hover" v-for="n in 6" :key="n">
          <Artist/>
        </div>
      </div>

      <div class="col-12">
        <h6>Following</h6>
      </div>
      <div class="row col-12">
        <div class="col-sm-2 js-container-hover" v-for="n in 6" :key="n">
          <Artist/>
        </div>
      </div>

      <div class="compnant-seprator"></div>

      <div class="col-12 marign-top-40">
        <h6>SINGLES_(HOT NEW SONGS)</h6>
      </div>
      <div>
        <Track/>
      </div>

      <div class="col-12 marign-top-40">
        <h6>SINGLES/ ALBUMS (TOP NEW)</h6>
      </div>
      <div class=" col-12">
        <NewAlbumSingles :listData="listData"/>
      </div>

      <div class="col-12 marign-top-40">
        <h6>SINGLES/ ALBUMS (TOP NEW)1</h6>
      </div>
      <div class="row col-8">
        <SongsPopularity :listData="listData" :width="'670 px'"/>
      </div>

      <div class="w-810 pl-15 pr-15">
        <h6>SINGLES/ ALBUMS (TOP NEW)2</h6>
        <div class=" marign-top-40">
          <SongsAlbum34Selling :listData="listData" :loopCount="1"/>
        </div>
      </div>

      <div class="w-810 pl-15 pr-15">
        <div class="">
          <div class="trackes-heading m-t-50 mb-2">
            <h6>
              POD CAST
              <p>(3/4 page)SELLING </p>
            </h6>
          </div>
        </div>
        <div class="row">
          <PodcastSelling/>
        </div>
      </div>

      <div class="w-810 pl-15 pr-15">
        <div class="">
          <div class="trackes-heading m-t-50 mb-2">
            <h6>
              Sample
              <p>(3/4 page)SELLING </p>
            </h6>
          </div>
        </div>
        <SongsAlbum34Selling :loopCount="1"/>
      </div>

      <div class="w-810 pl-15 pr-15">
        <div class="">
          <div class="trackes-heading m-t-50 mb-2">
            <h6>SONGS & ALBUMS_ARTIST PROFILE PAGE(3/4 page) SELLING
            </h6>
          </div>
        </div>
        <SongsAlbum34Selling :loopCount="1"/>
      </div>


      <div class="compnant-seprator"></div>
      <div class=" ">
        <div class="trackes-heading col-12 m-t-50">
          <h6>STREAMING </h6>
        </div>
      </div>


      <div class="row col-8">
        <SongsPopularity :width="'670 px'"/>
      </div>

      <div class="w-810 pl-15 pr-15">
        <div class="trackes-heading m-t-50">
          <h6>
            Songs
            <p>(3/4 Pages) Streamings01 </p>
          </h6>
        </div>
        <SongsStreaming :title="true" :loopCount="1" :authorName="'Artist Name'" :filename="'Song Name'"
                        :dynamicTytle="'Album'"/>
      </div>


      <div class="w-810 pl-15 pr-15">
        <div class="trackes-heading m-t-50">
          <h6>
            Podcast
            <p>(3/4 Pages) Streaming</p>
          </h6>
        </div>
        <SongsStreaming :title="true" :loopCount="1" :authorName="'Author Name'" :filename="'Podcast Name'"
                        :dynamicTytle="'Episode'"/>
      </div>

      <div class="w-810 pl-15 pr-15">
        <div class="">
          <div class="trackes-heading m-t-50">
            <h6>
              Sample
              <p>(3/4 Pages) Streaming</p>
            </h6>
          </div>
          <SongsStreaming :title="true" :loopCount="1" :authorName="'Artist Name'" :filename="'Sample Name'"
                          :dynamicTytle="'Pack'"/>
        </div>
      </div>
      <div class="w-810 pl-15 pr-15">
        <div class="">
          <div class="trackes-heading m-t-50">
            <h6>
              Song_Artist PAge
              <p>(3/4 Pages) Streaming</p>
            </h6>
          </div>
          <SongsStreaming :title="false" :loopCount="1" :authorName="'Artist Name'" :filename="'Song Name'"
                          :dynamicTytle="'Album'"/>
        </div>
      </div>

      <div class="contianer-streaming-main">
        <div class=" m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                Songs + Remixes_
                <p>Full page (Selling)</p>
              </h6>
            </div>
          </div>
          <Song10/>
        </div>
        <div class=" m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                Songs + Remixes_
                <p>Full page (Streaming)</p>
              </h6>
            </div>
          </div>
          <SongRemixStreaming/>
        </div>

        <div class=" m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                Album_
                <p>Full page (Selling)</p>
              </h6>
            </div>
          </div>
          <AlbumFullPageSelling/>
        </div>

        <div class=" m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                Album_
                <p>Full page (Streaming)</p>
              </h6>
            </div>
          </div>
          <AlbumFullPageStreaming/>
        </div>

        <div class=" ">
          <div class="trackes-heading col-12 m-t-50">
            <h6>SAMPLE PAGE </h6>
          </div>
        </div>

        <div class=" m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                SAMPLES_
                <p>Full page (Selling)</p>
              </h6>
            </div>
          </div>
          <!-- <SamplePage :imageName="'sampleFullSelling'" :iconName="'Loop'" :showType="'selling'"/> -->
          <SampleTest :imageName="'sampleFullSelling'" :iconName="'Loop'" :showType="'selling'"/>
        </div>
        <div class="container-fluid m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                SAMPLES_
                <p>Full page (Streaming)</p>
              </h6>
            </div>
          </div>
          <SamplePage :imageName="'sampleFullPageStreaming'" :iconName="'Loop'" :showType="'streaming'"/>
          <!-- <SampleTest :imageName="'sampleFullPageStreaming'" :iconName="'Loop'" :showType="'streaming'"/> -->
        </div>


        <div class="container-fluid m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                PODCAST_
                <p>Full page (Selling)</p>
              </h6>
            </div>
          </div>
          <!-- <SamplePage :imageName="'podcastfullpagesellimng'" :iconName="'mike'" :showType="'selling'"/> -->
          <SampleTest :imageName="'podcastfullpagesellimng'" :iconName="'mike'" :showType="'selling'"/>
        </div>

        <div class="container-fluid m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                PODCAST_
                <p>Full page (Streaming)</p>
              </h6>
            </div>
          </div>
          <SamplePage :imageName="'podcaststreaming'" :iconName="'mike'" :showType="'streaming'"/>
        </div>

        <div class="container-fluid m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                PLAYLIST_
                <p>Full page (Selling)</p>
              </h6>
            </div>
          </div>
          <SampleTest :imageName="'22'" :iconName="'mm'" :showType="'selling'"/>
        </div>

        <div class="container-fluid m-t-50">
          <div class="col-12">
            <div class="trackes-heading">
              <h6>
                PLAYLIST_
                <p>Full page (Streaming)</p>
              </h6>
            </div>
          </div>
          <SamplePage :imageName="'bbot'" :iconName="'mm'" :showType="'streaming'"/>
        </div>
      </div>


    </div>
    <!-- Wave Forms -->

    <div class="container" v-if="false">

      <div class="col-12">
        <div class="trackes-heading">
          <h2>Songs</h2>
        </div>
      </div>

      <div class="container" v-if="false">
        <div class="">
          <div class="trackes-heading m-t-20">
            <h6>
              POD CAST_
              <p> (3/4 page)SELLING </p>
            </h6>
          </div>
          <Song20/>
        </div>
      </div>
      <div class="container" v-if="false">
        <div class="col-12">
          <div class="trackes-heading m-t-50">
            <h6>SONG + PODCAST (3/4 page)STREAMING </h6>
          </div>
        </div>
        <SongPodcastStreaming/>
      </div>
      <div class="container" v-if="false">
        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>On Feed</h6>
          </div>
        </div>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <div class="trackes-heading ">
            <h6>Waveform_SONG_3/4 page_(Selling)_Reposted by User</h6>
          </div>
          <Song12/>
        </div>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <Song16/>
        </div>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <div class="trackes-heading ">
            <h6>
              <p>Waveform_SONG_3/4 page_(Streaming)_Reposted by User</p>
            </h6>
          </div>
          <WaveformSong/>
        </div>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>ALBUM </h6>
          </div>
        </div>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              ALBUM
              <p>_selling</p>
            </h6>
          </div>
        </div>
        <Song18/>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              ALBUM
              <p>_Streaming</p>
            </h6>
          </div>
        </div>
        <AlbumStreaming/>
      </div>
      <div class="mx-800 m-t-20" v-if="false">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              ALBUM
              <p>_Selling Reposted By user</p>
            </h6>
          </div>
        </div>
        <AlbumSellingReposted/>
      </div>
      <div class="mx-800 m-t-20">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              ALBUM
              <p>_Streamin Reposted By user</p>
            </h6>
          </div>
        </div>
        <AlbumStreamingReposted/>
      </div>
      <div class="mx-800 m-t-20">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>PLaylist Streaming</h6>
          </div>
        </div>
        <PlaylistStreamSelling :reposted="false"/>
      </div>
      <div class="mx-800 m-t-20">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              PLAYLIST
              <p>Streaming (Resposted by User)</p>
            </h6>
          </div>
        </div>
        <PlaylistStreamSelling :reposted="true"/>
      </div>

    </div>

    <!-- Full Page Files -->


    <div class="container-fluid" v-if="false">
      <div class="container-mains">
        <div class="col-12">
          <div class="trackes-heading text-center">
            <div class="songs">Songs</div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>SONG 4/4_page_
              <p> Related Song/Related Podcasts/Library(SELLING)</p>
            </h6>
          </div>
        </div>
        <RelatedSongLibrary :showSongList="false" :listType="'selling'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>SONG 4/4_page_
              <p> Related Song/Related Podcasts/Library(STREAMING)</p>
            </h6>
          </div>
        </div>
        <RelatedSongLibrary :showSongList="false" :listType="'streaming'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>full page waveform album(In Albums)(SELLING)
            </h6>
          </div>
        </div>
        <RelatedSongLibrary :showSongList="true" :listType="'selling'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>full page waveform album(In Albums)(STREAMING)
            </h6>
          </div>
        </div>
        <RelatedSongLibrary :showSongList="true" :listType="'streaming'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>full page waveform playlist(In Playlist)(SELLING)
            </h6>
          </div>
        </div>
        <RelatedSongLibrary :showSongList="true" :listType="'selling'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>full page waveform playlist(In Library)(Streaming)
            </h6>
          </div>
        </div>
        <RelatedSongLibrary :showSongList="true" :listType="'streaming'"/>
      </div>
    </div>

    <!-- Reviews -->

    <div class="container-fluid" v-if="false">
      <div class="container-mains">
        <div class="col-12">
          <div class="trackes-heading">
            <h2>List view Reviews </h2>
          </div>
        </div>
      </div>
      <div class="container-fluid m-t-50">
        <ReviewMusic :imageName="'demo_image_5'" :iconName="'music_with_circle'" listType="type1"/>
      </div>
      <div class="container-fluid m-t-50">
        <ReviewMusic :imageName="'demo_image_3'" :iconName="'music_with_circle'" listType="type2"/>
      </div>
      <div class="container-fluid m-t-50">
        <ReviewMusic :imageName="'demo_image_4'" :iconName="'double_circle'" listType="type1"/>
      </div>
      <div class="container-fluid m-t-50">
        <ReviewMusic :imageName="'demo_image_3'" :iconName="'music_in_folder'" listType="type2"/>
      </div>
      <div class="container-fluid m-t-50">
        <ReviewMusic :imageName="'demo_image_1'" :iconName="'mike_in_circle'" listType="type1"/>
      </div>
      <div class="container-fluid m-t-50">
        <ReviewMusic :imageName="'demo_image_1'" :iconName="'mike_in_circle'" listType="type2"/>
      </div>
    </div>


    <!-- Charts -->

    <div class="container-fluid" v-if="false">
      <div class="container">
        <div class="col-12">
          <div class="trackes-heading">
            <h2>CHARTS </h2>
          </div>
        </div>
      </div>
      <div class="container m-t-50">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              <p>CHARTS_SINGLES, REMIXES & ALBUMS_Full page (Selling) 3</p>
            </h6>
          </div>
        </div>
        <Song23 :imageName="'demo_image_4'" :iconName="'music_with_circle'" :listType="'type1'"/>
      </div>

      <div class="container m-t-50">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              <p>CHARTS_SINGLES, REMIXES & ALBUMS_Full page (Streaming)</p>
            </h6>
          </div>
        </div>
        <Song23 :imageName="'demo_image_3'" :iconName="'music_with_circle'" :listType="'type2'"/>
      </div>


      <div class="container m-t-50">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              <p>CHARTS_SAMPLES, SAMPLES PACKS, BEATS_Full page (Selling)</p>
            </h6>
          </div>
        </div>
        <ChartSample :imageName="'podcastfullpagesellimng'" :iconName="'music_with_circle'" :listType="'type1'"
                     :header="true"/>
      </div>
      <div class="container m-t-50">
        <div class="col-12">
          <div class="trackes-heading">
            <h6>
              <p>CHARTS_SAMPLES, SAMPLES PACKS, BEATS_Full page (Streaming)</p>
            </h6>
          </div>
        </div>
        <ChartSample :imageName="'twoperson'" :iconName="'music_with_circle'" :listType="'type2'" :header="false"/>
      </div>


    </div>

    <!-- Header Elements -->

    <div class="container-fluid">
      <div class="container-mains">
        <div class="col-12">
          <div class="trackes-heading">
            <h2>Songs</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>waveform song full page on song(Selling)
              <!-- <p> Related Song/Related Podcasts/Library(SELLING)</p> -->
            </h6>
          </div>
        </div>
        <PageElement1 :songName="'Song Name is XYZ'" :backgroundType="'type-1'" :listType="'selling'" :showPlus="true"
                      :imageName="'ddll'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>waveform song full page on song(Streaming)
              <!-- <p> Related Song/Related Podcasts/Library(SELLING)</p> -->
            </h6>
          </div>
        </div>
        <PageElement1 :songName="'Song Name is XYZ'" :backgroundType="'type-1'" :listType="'streaming'"
                      :showPlus="false" :imageName="'ddll'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>Waveform_ALBUM_Full page_ON ALBUM (Selling)
              <!-- <p> Related Song/Related Podcasts/Library(SELLING)</p> -->
            </h6>
          </div>
        </div>
        <PageElement1 :songName="'Album Name is XYZ'" :backgroundType="'type-2'" :showPlus="true" :listType="'selling'"
                      :imageName="'albumfull'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>Waveform_ALBUM_Full page_ON ALBUM_(Streaming)
              <!-- <p> Related Song/Related Podcasts/Library(SELLING)</p> -->
            </h6>
          </div>
        </div>

        <PageElement1 :songName="'Album Name is XYZ'" :backgroundType="'type-2'" :showPlus="true"
                      :listType="'streaming'" :imageName="'albumfull'"/>

        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>Waveform_Playlist (STREAMING ONLY)
              <!-- <p> Related Song/Related Podcasts/Library(SELLING)</p> -->
            </h6>
          </div>
        </div>
        <PageElement1 :songName="'Playlist Name is XYZ'" :backgroundType="'type-2'" :showPlus="true"
                      :listType="'streaming'" :imageName="'playliststreaming'"/>


        <div class="col-12">
          <div class="trackes-heading m-t-20">
            <h6>Waveform_PODCAST_ MUSIC_(streaming)
              <!-- <p> Related Song/Related Podcasts/Library(SELLING)</p> -->
            </h6>
          </div>
        </div>
        <PageElement1 :songName="'Podcast Title'" :backgroundType="'type-2'" :showPlus="true" :listType="'streaming'"
                      :imageName="'pod_stream'"/>


      </div>
    </div>


  </div>


</template>
<script type="application/javascript">
import GridSelling6 from "@/components/Common/Elements/GridSelling6"
import PlayList from "@/components/Common/Elements/PlayList"
import Artist from "@/components/Common/Elements/Artist"
import Track from "@/components/Common/Elements/Track"
import NewAlbumSingles from "@/components/Common/Elements/NewAlbumSingles"
import SongsPopularity from "@/components/Common/Elements/SongsPopularity"
import PodcastSelling from "@/components/Common/Elements/PodcastSelling"
import SongsAlbum34Selling from "@/components/Common/Elements/Songs_Album_3_4_Selling.vue";
import SongsStreaming from "@/components/Common/Elements/SongStreaming.vue";
import Song10 from "@/components/Common/UiList/Song10"
import SongRemixStreaming from "@/components/Common/UiList/SongRemixStreaming"
import AlbumFullPageSelling from "@/components/Common/UiList/AlbumFullPageSelling"
import AlbumFullPageStreaming from "@/components/Common/UiList/AlbumFullPageStreaming"
import SampleTest from "@/components/Common/UiList/SampleTest"
import SamplePage from "@/components/Common/UiList/SamplePage"

import Song20 from "@/components/Common/UiList/Song20"
import SongPodcastStreaming from "@/components/Common/UiList/SongPodcastStreaming"
import Song12 from "@/components/Common/UiList/Song12"
import Song16 from "@/components/Common/UiList/Song16"
import WaveformSong from "@/components/Common/UiList/WaveformSong"
import Song18 from "@/components/Common/UiList/Song18"
import AlbumStreaming from "@/components/Common/UiList/AlbumStreaming"
import AlbumSellingReposted from "@/components/Common/UiList/AlbumSellingReposted"
import AlbumStreamingReposted from "@/components/Common/UiList/AlbumStreamingReposted"
import PlaylistStreamSelling from "@/components/Common/UiList/PlaylistStreamSelling"
import RelatedSongLibrary from "@/components/Common/UiList/RelatedSongLIbrary"
import ReviewMusic from "@/components/Common/UiList/ReviewMusic"
import Song23 from "@/components/Common/UiList/Song23"
import ChartSample from "@/components/Common/UiList/ChartSample"

import Grid1 from "@/components/Common/UiList/Grid1"
import Grid2 from "@/components/Common/UiList/Grid2"
import Grid3 from "@/components/Common/UiList/Grid3"
import Grid4 from "@/components/Common/UiList/Grid4"
import SongTitleList from "@/components/Common/UiList/SongTitleList"
import Grid5 from "@/components/Common/UiList/Grid5"
import Grid6 from "@/components/Common/UiList/Grid6"
import Grid7 from "@/components/Common/UiList/Grid7"
import Grid9 from "@/components/Common/UiList/Grid9"
import Grid10 from "@/components/Common/UiList/Grid10"
import Grid11 from "@/components/Common/UiList/Grid11"
import Grid12_6 from "@/components/Common/UiList/Grid12_6"
import PageElement1 from "@/components/Common/UiList/PageElement1"
// import Grid13 from "@/components/Common/UiList/Grid13"


export default {
  data() {
    return {
      engageWithArtistD: false,
      engageWithArtist: false,
      gettingStarted: false,
      listData: [{
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }, {
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      }]
    }
  },
  components: {
    GridSelling6,
    PlayList,
    Artist,
    Track,
    NewAlbumSingles,
    SongsPopularity,
    // SongAlbumSelling,
    PodcastSelling,
    SongsAlbum34Selling,
    SongsStreaming,
    Song10,
    SongRemixStreaming,
    AlbumFullPageSelling,
    AlbumFullPageStreaming,
    SampleTest,
    SamplePage,
    Song20,
    SongPodcastStreaming,
    Song12,
    Song16,
    WaveformSong,
    Song18,
    AlbumStreaming,
    AlbumSellingReposted,
    AlbumStreamingReposted,
    PlaylistStreamSelling,
    RelatedSongLibrary,
    ReviewMusic,
    ChartSample,
    Song23,
    Grid1,
    Grid2,
    Grid3,
    Grid4,
    Grid5,
    Grid6,
    Grid7,
    Grid9,
    Grid10,
    Grid11,
    // Grid13,
    Grid12_6,
    SongTitleList,
    PageElement1


  },
  methods: {
    redirect: function (url) {
      this.$router.push(url);
    }
  }
}
</script>
