<template>
  <div>
    <div class="row " v-if="reviews_list_loading ">
      <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>

    <div>
      <ReviewTile :id="eachOp.id" v-for="(eachOp) in reviews" :key="eachOp.id" :item="eachOp"/>
<!--      <CommentSection :id="eachOp.id" v-for="(eachOp) in reviews_list" :key="eachOp.id" :item="eachOp"-->
<!--                      :name="userName"-->
<!--                      :comment_type="type"-->
<!--                      @reactOrUnReact="reactOrUnReactOnComment(eachOp)"/>-->

    </div>
  </div>
</template>

<script>
import ReviewTile from "../../DesignGuide/Music/Components/ReviewTile"
import { getReviewsOfSongsAlbumsPodcast } from "../../../apis/APIs";
export default {
  name: "ReviewListing",
  data(){
    return{
      reviewsItem: {},
      reviewsKey: 0,
      reviewsList: [],
      featuredReview: {},
      reviews_count: null,
      review: null,
      addReviewLoading: false,
      reviews_list_loading: false,
      reviews_list: [],
    }
  },
  components: {
    ReviewTile,
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  computed:{
    id() {
      const {id = ""} = this.item || {};

      return id;
    },
    type() {
      const {type = ""} = this.item || {};
      return type.charAt(0).toUpperCase()+type.slice(1)
    },
    reviews() {
      return this.reviewsList.length > 0 ? this.reviewsList : []
    }
  },
  watch:{
    type(){
      if(this.id) {
        this.init()
      }
    }
  },
  mounted() {
  },
  methods:{
    async init() {
      try {
        this.reviews_list_loading = true;
        const {data} = await getReviewsOfSongsAlbumsPodcast((this.type && this.type === 'Music Podcast') ? 'Podcast' : this.type, this.id);
        this.reviewsList = (data.information) ? data.information.data.data : []
        this.featuredReview = (data.information) ? data.information.featured : {}
        this.reviews_count = (data.information) ? data.information.data.total : null
        this.reviews_list_loading = false;
        this.$emit('featured_review', this.featuredReview)
        this.$emit('last_review', this.reviewsList[0])
      } catch (error) {
        console.error(error)
        this.reviews_list_loading = false;
      }
    },
    // This method is called from parent.
    addReviewInList(review){
      // First check the same user id
      const items = JSON.parse(JSON.stringify(this.reviewsList))
      const index = items.findIndex(item => item.id === review.id )
      if(index > -1 ) {
        items[index] = review
        this.reviewsList = items
      }
      else {
        this.reviewsList.unshift(review)
        const total_reviews = this.reviews_count + 1
        this.$emit('reviews_count', (total_reviews))
      }
    }
  }
};
</script>

<style scoped>

</style>
