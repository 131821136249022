<template>
  <div class="photo-layout">
    <div class="">
      <media-photos-albums-listing @getMediaPhotosAlbumsList="getMediaPhotosAlbums" @toggleAllPhotos="toggleAllPhotos" :listData="mediaPhotosAlbumsList" />
    </div>
    <div v-if="showAll" class="">
      <div class="col-md-artist-photo-full">
        <div class="all-add-photo">
          <h1>All Photos</h1>
          <button v-if="edit" v-on:click="openPhotoModel">Add Photos</button>
        </div>
      </div>
      <media-photos-listing @getMediaPhotosLists="getMediaPhotos" :listData="mediaPhotosList" :totalMediaPhotos="mediaPhotosCount"/>
      <div class="col-12">
        <div v-if="mediaPhotosList.length >= 8 && mediaPhotosList.length != mediaPhotosCount" class="artist-show-more" v-on:click="setLimit">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1V11.0003" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
            <path d="M11 6L0.999665 6" stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
          SHOW MORE
        </div>
      </div>
    </div>
    <AddPhotosModal v-if="showModal" @closePhotoModel="closePhotoModel"/>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script  type="application/javascript">
import mediaPhotosListing from "@/components/Home/Profile/media/photos/mediaPhotosListing.vue";
import mediaPhotosAlbumsListing from "@/components/Home/Profile/media/photos/mediaPhotosAlbumsListing.vue";
import AddPhotosModal from "../../AddPhotosModal.vue";
import {getAllMediaPhotosAlbumsOfUsers, getAllMediaPhotosOfUsers} from "../../../../../apis/APIs";

export default {
  name: "mediaPhotos",
  props:{
    user_id:{
      type:Number,
      Default:null,
    },
    edit:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      showAll : true,
      mediaPhotosList: [],
      mediaPhotosAlbumsList: [],
      offSetLimit: 0,
      limit: 8,
      loading: false,
      dataLoaded: false,
      mediaPhotosCount: '',
      addPhoto: false,
      check: true,
      showModal: false
    }
  },
  components: {
    mediaPhotosListing,
    mediaPhotosAlbumsListing,
    AddPhotosModal,
  },
  mounted() {
    if (this.mediaPhotosList && this.mediaPhotosList.length < 1) {
      this.getMediaPhotos();
    }
    if (this.mediaPhotosAlbumsList && this.mediaPhotosAlbumsList.length < 1) {
      this.getMediaPhotosAlbums();
    }
  },
  methods: {
    async getMediaPhotos() {
      try {
        this.loading = true
        let data = await getAllMediaPhotosOfUsers(this.offSetLimit, this.limit, (this.user_id)?this.user_id:null);
        this.mediaPhotosList =  data.data.information.data;
        this.mediaPhotosCount = data.data.information.total;
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }

    },
    openPhotoModel(){
      this.showModal = true
    },
    closePhotoModel(){
      this.showModal = false
    },
    toggleAllPhotos(){
      this.showAll = !this.showAll
    },
    async getMediaPhotosAlbums() {
      try {
        this.loading = true
        let data = await getAllMediaPhotosAlbumsOfUsers((this.user_id)?this.user_id:null);
        this.mediaPhotosAlbumsList =  (Array.isArray(data.data.information))?data.data.information:[];
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    setLimit () {
      this.limit = this.mediaPhotosList.length + 8;
      this.getMediaPhotos();
    },
    createPhotoPost () {
      this.$refs.addPhoto.toggleCreatePostModal();
    },
    updateMediaTabAlbumsAndPhotos () {
      this.getMediaPhotos();
      this.getMediaPhotosAlbums();
    }
  }
}
</script>

<style scoped>

</style>
