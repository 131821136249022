<template>
  <div class="w-100">
    <GroupSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/img/events.svg`)" alt="icon">Events
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="search-events-panel">
                <h1>Search Your Events</h1>
              </div>
              <div class="event-search-panel">
                <div class="event-search-input">
                  <input type="text" placeholder="Search event by name, venue or location">
                  <span class="s-icon"><img :src="require(`@/assets/img/search-icon.png`)"></span>
                </div>
                <div class="event-setting-artist-btns">
                  <div class="event-management">
                    <button>EVENT MANAGEMENT</button>
                  </div>
                  <div class="add-new-event">
                    <button>
                      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
                              fill="white"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025Z"
                              fill="white"></path>
                      </svg>
                      ADD NEW EVENT
                    </button>
                  </div>
                </div>
              </div>
              <div class="all-event-sections">
                <nav>
                  <div class="nav artist-event-setting top-tab nav-tabs1" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#all-event" role="tab"
                       aria-controls="nav-home" aria-selected="true">All Events</a>
                    <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#live-event" role="tab"
                       aria-controls="nav-profile" aria-selected="false">Live Events</a>
                    <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#virtual-event" role="tab"
                       aria-controls="nav-contact" aria-selected="false">Virtual Events</a>
                  </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade active show" id="all-event" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="all-event-details">
                      <ul>
                        <li v-for="n in 3" :key="n">
                          <div class="artist-event-list">
                            <div class="artist-image-detail">
                              <img :src="require(`@/assets/img/artist-image.png`)">
                            </div>
                            <div class="artist-image-detail-description">
                              <h1>Playground Presents Nina Kraviz</h1>
                              <p><span>Night Club Volf </span>• Vienna, Austria</p>
                              <p>Friday 16th October (8:00pm-4:00am)</p>
                              <div class="sold-area-detail">Sold: 240 / 280 • Gross: $ 1,024</div>
                            </div>
                          </div>
                          <div class="artist-detail-btns-cal">
                            <div class="artist-calendar">
                              <div class="a-month">JUL</div>
                              <div class="a-month-date">12</div>
                            </div>
                            <div class="artist-das-btns">
                              <button class="dashboard-btn">DASHBOARD</button>
                              <button class="aedit-btn">EDIT</button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="show-more-artist-event"><a href="javascript:;"><img
                        :src="require(`@/assets/img/plus-i.png`)"> SHOW 3 MORE EVENTS</a></div>
                  </div>
                  <div class="tab-pane fade" id="live-event" role="tabpanel" aria-labelledby="nav-profile-tab">Live
                    Events
                  </div>
                  <div class="tab-pane fade" id="virtual-event" role="tabpanel" aria-labelledby="nav-contact-tab">
                    Virtual Events
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import GroupSubHeader from '@/components/Group/Pages/GroupSubHeader'

export default {
  data() {
    return {}
  },
  components: {
    GroupSubHeader
  },
  computed: {},
  methods: {}
}
</script>
