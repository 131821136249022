import { filterArticle } from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";
import { ERROR, SUCCESS } from "../types";
const INITIAL_STATE = {
  filteredArticles: null,
};
const state = { ...INITIAL_STATE };
const getters = {
  GET_FILTERED_ARTICLES(state) {
    return state.filteredArticles;
  },
};
const mutations = {
  changeFilteredArticles(state, data) {
    state.filteredArticles = data.information;
  },
};
const actions = {
  async filterArticle({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) =>{
      try {
        dispatch('loading', true)
        let { data } = await filterArticle(payload);
        commit("changeFilteredArticles", data);
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
        dispatch('loading', false)
      }
      catch(error){
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }

    })
},
};
export default {
  state,
  actions,
  mutations,
  getters,
};
