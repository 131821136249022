<template>
  <div>
    <div class="right-cover">
      <h4>Cover Image</h4>
      <div class="upload" :class="{required:imgCoverReq}">
        <div v-if="!cover" class="square">
          <input type="file" ref="cover" @change="handleCover" />
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.25V23.75"
              stroke="#D1433A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.25 15H23.75"
              stroke="#D1433A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-else>
          <img :src="cover" alt="cover" />
          <input type="file" ref="cover" @change="handleCover" />
        </div>
      </div>
    </div>
    <span class="image-error" style="color: red">{{ imageError }}</span>
    <div class="imgMustBe">
      <div class="i">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 20V15"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 10H15.0125"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="imgInfo">
        <h4>Your image must be:</h4>
        <div>
          <div v-for="item in imgformat" :key="item">
            <span></span>
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Artwork",
  data() {
    return {
      imgCoverReq: false,
      cover: null,
      imgformat: [
        "Dimensions: 770px x 370px",
        "PNG or JPG format",
        "Maximum size: 1mb",
        "RGB format",
      ],
      info: {
        imgCover: null,
      },
      imageError: "",
    };
  },
  methods: {
    handleCover(e) {
      if (e.target.files[0].size < 1000000) {
        this.info.imgCover = e.target.files[0];
        this.cover = URL.createObjectURL(this.info.imgCover);
        this.imgCoverReq = false;
        this.imageError = "";
      } else {
        this.imageError = "Image size must be less than 1mb";
      }
    },
    handleArtWork() {
      if (!this.info.imgCover) {
        this.imgCoverReq = true;
      }

      this.$emit("setNewDataEvent", this.info);
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
 border: 1px dashed #FF0039 !important;
}
.right-cover {
  h4 {
    font-size: 18px;
    margin: 32px 0 18px 0;
    color: #071526 !important;
  }
}

.upload {
  position: relative;
  width: 100%;
  height: 218px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: 1px dashed #c4ccd6;
  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #D1433A;
    background: #D1433A1A;
  }
  input {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}
.imgMustBe {
  margin: 30px 0 0 0;
  padding: 20px 34px 20px 20px;
  background: #D1433A1A;
  border: 1px solid #D1433A1A;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .i {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #D1433A;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgInfo {
    width: calc(100% - 70px) !important;
    color: #47505b !important;
    h4 {
      font-family: HelveticaNeueMedium;
      font-weight: 500;
      color: #071526;
      font-size: 13px !important;
      opacity: 0.9;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    span {
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50px;
      background: #D1433A;
      opacity: 0.5;
      margin-right: 14px;
    }
    p {
      font-family: HelveticaNeueNormal;
      letter-spacing: 0.5px;
      font-size: 12px;
      color: #47505b;
      margin: 0;
    }
  }
  .image-error {
    color: red;
    size: 13px;
    font-weight: 400;
  }
}
</style>
