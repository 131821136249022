<template>
  <div class="w-1170 wr_event_detail_dashboard">
    <section class="wr_nav">
      <router-link class="back-link" to="/event-manager/page/dashboard">
        <div class="svg-icon">
          <svg
            width="7"
            height="14"
            viewBox="0 0 7 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.86328 12.7285L0.134281 7.00052L5.86328 1.27152"
              stroke="#071526"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="text-link">DASHBOARD</div>
      </router-link>
      <div class="title_event">EVENT SETTINGS “Manila Style X Music Fest”</div>
    </section>
    <section class="wr_tab_section">
      <ul>
        <li
          @click="changeActiveTab(item.id)"
          v-for="item in dataTabs"
          :key="item.id"
          :class="{ EM_ED_active_tab: item.active }"
        >
          {{ item.tabName }}
        </li>
      </ul>
      <div class="wr_btns">
        <button>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_572_86255)">
              <path
                d="M0.666992 8.00033C0.666992 8.00033 3.33366 2.66699 8.00033 2.66699C12.667 2.66699 15.3337 8.00033 15.3337 8.00033C15.3337 8.00033 12.667 13.3337 8.00033 13.3337C3.33366 13.3337 0.666992 8.00033 0.666992 8.00033Z"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                stroke="#D1433A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          Event Preview
        </button>
        <div class="wr_menue three_dots_menustyle for_hover_gray_btn">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99967 8.66634C8.36786 8.66634 8.66634 8.36786 8.66634 7.99967C8.66634 7.63148 8.36786 7.33301 7.99967 7.33301C7.63148 7.33301 7.33301 7.63148 7.33301 7.99967C7.33301 8.36786 7.63148 8.66634 7.99967 8.66634Z"
              fill="#47505B"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.99967 4.00033C8.36786 4.00033 8.66634 3.70185 8.66634 3.33366C8.66634 2.96547 8.36786 2.66699 7.99967 2.66699C7.63148 2.66699 7.33301 2.96547 7.33301 3.33366C7.33301 3.70185 7.63148 4.00033 7.99967 4.00033Z"
              fill="#47505B"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z"
              fill="#47505B"
              stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <article>
            <article>
              <div @click="openCloseUploadModal('open')">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33317 1.33301H3.99984C3.64622 1.33301 3.30708 1.47348 3.05703 1.72353C2.80698 1.97358 2.6665 2.31272 2.6665 2.66634V13.333C2.6665 13.6866 2.80698 14.0258 3.05703 14.2758C3.30708 14.5259 3.64622 14.6663 3.99984 14.6663H11.9998C12.3535 14.6663 12.6926 14.5259 12.9426 14.2758C13.1927 14.0258 13.3332 13.6866 13.3332 13.333V5.33301L9.33317 1.33301Z"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.3335 1.33301V5.33301H13.3335"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6668 8.66699H5.3335"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6668 11.333H5.3335"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66683 6H6.00016H5.3335"
                    stroke="#071526"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Upload Contract
              </div>
              <div @click="openCloseRemoveModal('open')">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="#47505B"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /></svg
                >Delete Event
              </div>
            </article>
            <span></span>
          </article>
        </div>
      </div>
    </section>
    <section v-if="EM_ED_active_tab === 'DASHBOARD'"><EMEDashboard /></section>
    <section v-if="EM_ED_active_tab === 'BASIC INFO'"><EMEBasicInfo /></section>
    <section v-if="EM_ED_active_tab === 'TICKETS'"><EMETickets /></section>
    <section v-if="EM_ED_active_tab === 'PHOTOS'"><EMEPhotos /></section>
    <section v-if="EM_ED_active_tab === 'VIDEOS'"><EMEVideos /></section>
    <RemoveModal
      v-if="showRemoveModals === 'open'"
      :title="'Delete Event'"
      :description="'«Manila Style x Music Fest»'"
      :id="5"
      @openCloseRemoveModal="openCloseRemoveModal"
      @delete_this="delete_This_Event"
    />
    <UploadFileModal
      v-if="showUploadModals === 'open'"
      :title="'Upload Contract'"
      :description="'The file should be under 5 mb and in .pdf or .docx format'"
      @openCloseUploadModal="openCloseUploadModal"
      @upload_this="upload_This_File"
    />
  </div>
</template>

<script>
import EMEDashboard from "./../EMEventPages/EME_Dashboard.vue";
import EMEBasicInfo from "./../EMEventPages/EME_BasicInfo.vue";
import EMETickets from "./../EMEventPages/EME_Tickets.vue";
import EMEPhotos from "./../EMEventPages/EME_Photos.vue";
import EMEVideos from "./../EMEventPages/EME_Videos.vue";
import RemoveModal from "@/components/Common/Elements/RemoveModal.vue";
import UploadFileModal from "@/components/Common/Elements/UploadFileModal.vue";

export default {
  components: {
    EMEDashboard,
    EMEBasicInfo,
    EMETickets,
    EMEPhotos,
    EMEVideos,
    RemoveModal,
    UploadFileModal,
  },
  data() {
    return {
      EM_ED_active_tab: "DASHBOARD",
      dataTabs: [
        {
          id: 1,
          tabName: "DASHBOARD",
          active: true,
        },
        {
          id: 2,
          tabName: "BASIC INFO",
          active: false,
        },
        {
          id: 3,
          tabName: "TICKETS",
          active: false,
        },
        {
          id: 4,
          tabName: "PHOTOS",
          active: false,
        },
        {
          id: 5,
          tabName: "VIDEOS",
          active: false,
        },
      ],
      showRemoveModals: "close",
      showUploadModals: "close",
    };
  },
  methods: {
    openCloseRemoveModal(payload) {
      this.showRemoveModals = payload;
    },
    delete_This_Event() {
      console.log(15156156156);
    },
    openCloseUploadModal(payload) {
      this.showUploadModals = payload;
    },
    upload_This_File(payload) {
      console.log(payload);
    },
    changeActiveTab(id) {
      this.dataTabs.forEach((el) => {
        if (el.id === id) {
          el.active = true;
          this.EM_ED_active_tab = el.tabName;
        } else {
          el.active = false;
        }
      });
    },
    
  },
};
</script>

<style lang="scss" scoped>
.wr_event_detail_dashboard {
  .wr_tab_section {
    margin-top: 29px;
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 31px;
    .wr_btns {
      display: flex;
      button {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #D1433A;
        font-family: HelveticaNeueMedium;
        border: none;
        align-items: center;
        margin-right: 10px;
        width: 150px;
        height: 30px;
        display: flex;
        justify-content: center;
        background: #ecf0ff;
        border-radius: 2px;
        svg {
          margin-right: 7px;
        }
      }
    }
    ul {
      width: 487px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style-type: none;
      font-family: HelveticaNeueBold;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #c4ccd6;
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        border-bottom: 3px solid transparent;
      }
      .EM_ED_active_tab {
        color: #47505b;
        border-bottom: 3px solid #D1433A;
      }
    }
  }
  .wr_menue {
    position: relative;
    &:hover {
      article {
        display: block;
      }
    }
    article {
      display: none;
      z-index: 1;
      width: 210px;
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
      border-radius: 4px;
      top: 15px;
      right: 0px;
      div {
        z-index: 2;
        display: flex;
        align-items: center;
        height: 50px;
        font-family: HelveticaNeueBold;
        padding-left: 27px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #071526;
        svg {
          margin-right: 14px;
        }
        &:hover {
          background: #D1433A1A !important;
        }
      }
      span {
        position: absolute;
        width: 14px;
        height: 20px;
        top: 15px;
        right: 8px;
        background: #ffffff;
        rotate: 21deg;
        transform: skewY(50deg);
      }
      :nth-child(2) {
        border-top: 1px solid #e6eaf0;
      }
    }
  }
}
</style>