import axios from "axios";
import appConfig from "../configs/AppConfig"

export default axios.create({
    baseURL: appConfig.base_url,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});
