<template>
  <div class="Signup_pop_overlay">
    <div>
      <div class="wr_report_modal" v-if="!reasonModal">
       <ReportModalHeader @closeModal="closeModal" />
        <div class="text-container">
            <div class="text">
              Are you sure you want to
              <b>report this?</b>
            </div>
        </div>
        <div class="wr_btn">
          <div @click="closeModal()" class="cancel">CANCEL</div>
          <div class="confirm" @click="openReasonModal">CONFIRM</div>
        </div>
      </div>
    </div>
    <div>
        <ReportReason v-if="reasonModal" @closeModal="closeModal" :post="post" />
    </div>
  </div>
</template>
<script type="application/javascript">
import ReportModalHeader from './ReportModalHeader.vue';
import ReportReason from "./ReportReason.vue";
export default {
  name: "ConfirmReportModal",
  data() {
    return {
        reasonModal: false
    };
  },
  components: {
    ReportModalHeader,
    ReportReason
  },
  props:{
    post:{
      type: Object
    }
  },
  methods: {
    openReasonModal(){
        this.reasonModal = true;
    },
    closeModal() {
      this.$emit("closeReportModal");
    },
    async deleteThis() {
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.Signup_pop_overlay {
  font-family: HelveticaNeueNormal;
  .wr_report_modal {
    background: #ffffff;
    width: 570px;
    height: 249px;
    padding: 22px 20px 29px;
    border-radius: 4px;
    .text-container{
        border-bottom: 1px solid #e6eaf0;
        margin-bottom: 20px;
        .text {
          text-align: center;
          font-weight: 400;
          margin: 35px 0;
          font-size: 15px;
          line-height: 20px;
          color: #47505B;
          b {
            font-family: HelveticaNeueBold;
          }
        }
    }
    .wr_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .confirm,
      .cancel {
        width: 160px;
        height: 40px;
        font-weight: 700;
        font-size: 13px;
        color: #ffffff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .cancel {
        color: #8b949f;
        border: 1.3px solid #8b949f;
      }
      .confirm {
        background: #D1433A;
      }
    }
  }
}
</style>
