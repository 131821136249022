<template>
  <div class="row">
    <div class="col">
<!--      <div class="collection-heading history_heading mt-0">Listening History</div>-->
<!--      <SongsStreaming :title="true" :dynamicTitle="'song'" :listData="listeningSongs"/>-->
      <div>
        <favorite-songs-listing :listData="listeningSongs" :title="'Listening History'"/>
      </div>
      <div class="show_more_btn" v-show="nextBatchCount">
        <div class="show-more musicbar_more"><a @click="fetchMoreData()" href="javascript:;"><span class="show_more_bg"><svg
            width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path
            d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
            stroke-linejoin="round"></path> <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"></path></svg>
                           SHOW {{ nextBatchCount }} MORE SONGS
                        </span></a></div>
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script>
// import SongsStreaming from "@/components/Common/Elements/SongStreaming.vue";
import favoriteSongsListing from "@/components/Home/Profile/favorites/music/favoriteSongsListing.vue";
import {getPageData} from "../../../../apis/APIs";

export default {
  name: "listeningHistory",
  data() {
    return {
      listeningSongs: [],
      totalRows: 0,
      loading: false,
      limit: 6,
      offset: 0,
    }
  },
  mounted() {
    this.pageChannels()
  },
  computed: {
    nextBatchCount() {
      if ((this.totalRows - this.listeningSongs.length) > 6) {
        return 6
      } else if ((this.totalRows - this.listeningSongs.length) < 6 && (this.totalRows - this.listeningSongs.length) > 0) {
        return Number(this.totalRows - this.listeningSongs.length)
      } else {
        return false
      }
    }
  },
  components: {
    // SongsStreaming
    favoriteSongsListing
  },
  methods: {
    async pageChannels() {
      try {
        this.loading = true
        let {data} = await getPageData('continue-listening-to', this.limit, this.offset, this.filterApplied);
        this.listeningSongs = data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchMoreData() {
      try {
        this.offset = parseInt(this.offset) + this.limit;
        this.loading = true
        let {data} = await getPageData('continue-listening-to', this.limit, this.offset, this.filterApplied);
        this.listeningSongs = [...this.listeningSongs, ...data.channels.center.objects.data]
        this.totalRows = data.channels.center.objects.total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
