<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade login-type show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup">
            <div class="work-experience-heading">
              <h1>Delete page</h1>
              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="svg-color" d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="svg-color" d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">
              <div class="delete-popup-heading">Are you sure you want to delete this page?</div>
              <div class="delete-popup-input"><input type="text" placeholder="Your Password" v-model="password"></div>
            </div>
            <div class="popup-dlt-btns">
              <button class="cancel-popup" v-on:click="closeModal()">CANCEL</button>
              <button class="deletebtn-popup" @click="pageDelete">DELETE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">

export default {
  props:['page_id'],
  data() {
    return {
      password : ''
    }
  },
  components: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    },
    pageDelete(){
      let payload = {
        password : this.password,
        page_id : this.page_id
      }
      this.$store.dispatch('pageDelete', payload)
    }
  }
}
</script>
