import {SERVER_FORBIDDEN} from "../../store/types";

export const verifyResponse = response => new Promise((resolve, rejects) => response && response.ok ? resolve(response) : rejects(response))

export const handleError = error => {
    console.log('error  ==>' + error.response)
    if (!(error.status)) {
        return error
    }
    if (error && (error.status === 409 && error.message === SERVER_FORBIDDEN)) {

        console.log("Unauthorized.");
    }

    if (error && (error.status === 422 || error.status === 501 || error.status === 401 || error.status === 404 || error.status === 409 || error.status === 401 || error.status === 400 || error.status === 304 || error.status === 409 || error.status === 500))
        return error;
    return error;
}

export const log = (prefix, ...args) => {
    // if (__DEV__) {
    console.log(prefix, args);
    // }
}

export const readURL = (file) => {
    return new Promise((resolve) => {
        if (file) {
            try {
                var reader = new FileReader();

                reader.onload = function (e) {
                    resolve(e.target.result);
                }

                reader.readAsDataURL(file);
            } catch (error) {
                resolve(null);
            }
        } else return resolve(null);
    })
}

export const createBlob = (base64, name) => {
    return new Promise(async (resolve) => {
        if (base64) {
            try {
                var blob = await fetch(base64).then(res => res.blob())

                // blob.lastModifiedDate = new Date();
                // blob.name = "test.mp4";

                resolve(new File([blob], name));
            } catch (error) {
                resolve(null);
            }
        } else return resolve(null);
    })
}

export const parseDate = (date) => {
    const days = (new Date() - new Date(date)) / (24 * 60 * 60 * 1000);
    const hours = (new Date() - new Date(date)) / (60 * 60 * 1000);
    const minutes = (new Date() - new Date(date)) / (60 * 1000);

    if (days > 1) return `${parseInt(days)}d`;
    if (hours > 1) return `${parseInt(hours)}h`;
    if (minutes > 1) return `${parseInt(minutes)}m`;
    if (minutes < 1) return `few moments ago`;
}

export const parseToken = (token) => `Bearer ${token}`;

export const getDate = (sec) => {
    const date = new Date();
    date.setSeconds(sec, 0);

    return date;
}

export const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export const parseState = (state) => JSON.parse(JSON.stringify(state));

export const getMonths = () => [
    {index: 0, vaule: "Jan"},
    {index: 1, vaule: "Feb"},
    {index: 2, vaule: "Mar"},
    {index: 3, vaule: "Apr"},
    {index: 4, vaule: "May"},
    {index: 5, vaule: "Jun"},
    {index: 6, vaule: "Jul"},
    {index: 7, vaule: "Aug"},
    {index: 8, vaule: "Sep"},
    {index: 9, vaule: "Oct"},
    {index: 10, vaule: "Nov"},
    {index: 11, vaule: "Dec"},
].map(ele => ({name: ele.vaule, is_selected: false, ...ele}));

export const getYears = () => {
    let count = new Date().getFullYear();
    let years = [];

    while (count >= 1970) {
        years.push({name: count, value: count, is_selected: false});
        count--;
    }

    return years;
}

export const getMonthDays = (year, month) => {
    let days = [];
    let count = new Date(year, month, 0).getDate();
    console.log("Count", count);
    console.log("days ===> count", days, count, year, month)
    while (count > 0) {
        days.push({name: count, value: count, is_selected: false});

        count--;
    }

    return Array.from(days).reverse();
}

export const getTimezones = () => {
    return ([
        {
            "value": -12,
            "text": "(GMT -12:00) Eniwetok, Kwajalein"
        },
        {
            "value": -11,
            "text": "(GMT -11:00) Midway Island, Samoa"
        },
        {
            "value": -10,
            "text": "(GMT -10:00) Hawaii"
        },
        {
            "value": -9,
            "text": "(GMT -9:00) Alaska"
        },
        {
            "value": -8,
            "text": "(GMT -8:00) Pacific Time (US & Canada)"
        },
        {
            "value": -7,
            "text": "(GMT -7:00) Mountain Time (US & Canada)"
        },
        {
            "value": -6,
            "text": "(GMT -6:00) Central Time (US & Canada), Mexico City"
        },
        {
            "value": -5,
            "text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"
        },
        {
            "value": -4,
            "text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"
        },
        {
            "value": -3.5,
            "text": "(GMT -3:30) Newfoundland"
        },
        {
            "value": -3,
            "text": "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
        },
        {
            "value": -2,
            "text": "(GMT -2:00) Mid-Atlantic"
        },
        {
            "value": -1,
            "text": "(GMT -1:00) Azores, Cape Verde Islands"
        },
        {
            "value": 0,
            "text": "(GMT) Western Europe Time, London, Lisbon, Casablanca"
        },
        {
            "value": 1,
            "text": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"
        },
        {
            "value": 2,
            "text": "(GMT +2:00) Kaliningrad, South Africa"
        },
        {
            "value": 3,
            "text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
        },
        {
            "value": 3.5,
            "text": "(GMT +3:30) Tehran"
        },
        {
            "value": 4,
            "text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
        },
        {
            "value": 4.5,
            "text": "(GMT +4:30) Kabul"
        },
        {
            "value": 5,
            "text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
        },
        {
            "value": 5.5,
            "text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi"
        },
        {
            "value": 5.75,
            "text": "(GMT +5:45) Kathmandu"
        },
        {
            "value": 6,
            "text": "(GMT +6:00) Almaty, Dhaka, Colombo"
        },
        {
            "value": 7,
            "text": "(GMT +7:00) Bangkok, Hanoi, Jakarta"
        },
        {
            "value": 8,
            "text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong"
        },
        {
            "value": 9,
            "text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
        },
        {
            "value": 9.5,
            "text": "(GMT +9:30) Adelaide, Darwin"
        },
        {
            "value": 10,
            "text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok"
        },
        {
            "value": 11,
            "text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
        },
        {
            "value": 12,
            "text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka"
        }
    ]);
}

export const getBrowser = () => {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
        return ('Opera');
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        return ('Chrome');
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
        return ('Safari');
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        return ('Firefox');
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
        return ('IE');
    } else {
        return ('unknown');
    }
}

export const getColors = () => [
    {color: "color1",code:'#28FAED',type:'color'},
    {color: "color2",code:'#40CEED',type:'color'},
    {color: "color3",code:'#2699FB',type:'color'},
    {color: "color4",code:'#CB62F8',type:'color'},
    {color: "color5",code:'#6540CE',type:'color'},
    {color: "color6",code:'#4504FF',type:'color'},
    {color: "color7",code:'linear-gradient(180deg, #42B1AE 0%, #6A3DCB 100%)',type:'color'},
    {color: "color8",code:'linear-gradient(270deg, #0075FF -9.47%, #6A3DCB 98.54%)',type:'color'},
    {color: "color9",code:'#D1433A',type:'color'},
    {color: "color10",code:'#47505B',type:'color'}
]


export const getErrorFromApi = (data) => {
    console.log('get error function' + data)
    let error = ""
    if (data) {
        if (data.message) {
            error = (Array.isArray(data.message)) ? data.message[0] : data.message;
            if (data.errors) {
                if (data.errors.length > 0)
                    error = error + '\n' + data.errors[0].message
                else {
                    error = error + '\n' + data.errors.message[0]
                }
            }
        } else {
            error = "Please try again"
        }
    } else {
        console.log('error' + data)
    }

    return error
}
