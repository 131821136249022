<template>
  <div class="container_xxl">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1 class="Page_title">{{ header ? 'EXPLORE' : '' }}</h1>
        </div>
      </div>
    </div>
    <div class="content discover ">

      <div class="row">
        <div class="col">
          <div class=" page-filter d-flex d-flex flex-row align-items-center p-r-20">
            <div class="back-link">
              <a @click="redirect(redirectUrl)" class=" d-flex flex-row align-items-center">
                <div class="svg-icon">
                  <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="c-line-1" d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152" stroke="#071526"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                {{ pageFrom }}
              </a>
            </div>
            <div class="filter-title">
              {{ pageTitle }}
            </div>
            <div class="filter-update-text">
              {{ thirdTxt }}
            </div>
            <div class="d-flex flex-wrap align-items-end ml-auto">
              <div class="change-view js-change-view">

              </div>

              <div class="text with-icon d-flex justify-content-between justify-content-end align-items-center">
                Format
                <div class="svg-icon">
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="9.51351" height="2.16216" rx="1.08108" fill="#D1433A"/>
                    <rect y="5.18945" width="16" height="2.16216" rx="1.08108" fill="#D1433A"/>
                    <rect y="10.3779" width="12.973" height="2.16216" rx="1.08108" fill="#D1433A"/>
                  </svg>

                </div>
              </div>
              <div class="dropdown bootstrap-select show-tick filters-select for-overview">
                <select class="filters-select selectpicker for-overview" title="Featured" multiple=""
                        data-max-options="1" dropupauto="false" tabindex="-98">

                </select>
                <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                        role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false"
                        title="Featured">
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">Singles</div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu ">
                  <div class="inner show" role="listbox" id="bs-select-1" tabindex="-1" aria-multiselectable="true">
                    <ul class="dropdown-menu inner show" role="presentation">
                      <li><a role="option" class="dropdown-item active selected" id="bs-select-9-0" tabindex="0"
                             aria-selected="false" aria-setsize="6" aria-posinset="1"><span
                          class=" bs-ok-default check-mark"></span><span class="text">Singles</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-9-0" tabindex="0" aria-selected="false"
                             aria-setsize="6" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Albums</span></a></li>
                      <li><a role="option" class="dropdown-item" id="bs-select-9-0" tabindex="0" aria-selected="false"
                             aria-setsize="6" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                          class="text">Remixes</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="js-container">
        <div class="container-fluid sale-items m-t-10">

          <div class="row position-relative" v-if="listing_type == 'grid'">
            <TopCharts :header="false"/>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="show-more musicbar_more mt-0">
            <a href="javascript:;">
                     <span class="show_more_bg">
                        <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3"
                                 stroke-linecap="round" stroke-linejoin="round"></path>
                           <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                                 stroke-linejoin="round"></path>
                        </svg>
                        SHOW MORE
                     </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import TopCharts from "@/components/Common/Elements/TopCharts.vue";

export default {
  data() {
    return {
      listing_type: 'grid'
    }
  },
  components: {
    TopCharts
  },
  props: {
    title: String,
    pageFrom: String,
    pageTitle: String,
    redirectUrl: String,
    activeHeader: String,
    thirdTxt: String,
    header: Boolean
  },
  computed: {},
  methods: {
    showForm: function (type) {
      this.listing_type = type;
    },
    redirect: function (url) {
      //   if(this.title == 'Featured Eectronic Songs')
      //    url = 'genres-moods';
      this.$router.push({name: url})
    },
  }
}
</script>
