<template>
  <div class="container_xxl">
    <div class="row">
      <div class="col">
        <h1 class="Page_title"></h1>
      </div>
    </div>
    <div class="content discover">
      <div class="row">
        <div class="col">
          <div
            class="page-filter d-flex d-flex flex-row align-items-center p-r-20"
          >
            <div class="back-link">
              <a
                @click="redirect('genres-moods')"
                class="d-flex flex-row align-items-center"
              >
                <div class="svg-icon">
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                      stroke="#071526"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                {{ pageFrom }}
              </a>
            </div>
            <div class="filter-title">
              {{ pageTitle }}
            </div>

            <div class="d-flex flex-wrap align-items-end ml-auto">
              <div class="change-view js-change-view">
                <div class="btn-group m-r-10">
                  <div
                    data-type="grid"
                    :class="[
                      'btn btn-svg-icon js-set-view',
                      listing_type == 'grid' ? 'active' : '',
                    ]"
                    v-on:click="showForm('grid')"
                  >
                    <div data-type="grid" class="svg-icon show-grid grid-svd">
                      <!-- <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.75 5.25V0.75H13.25V5.25H8.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square" class="stroke-line-1"></path><path d="M0.75 5.25V0.75H5.25V5.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square" class="stroke-line-1"></path><path d="M8.75 13.25V8.75H13.25V13.25H8.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square" class="stroke-line-1"></path><path d="M0.75 13.25V8.75H5.25V13.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square" class="stroke-line-1"></path>
                      </svg> -->
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 0H0V30H30V0Z"
                          fill="#D1433A"
                          class="c-fill-1"
                        ></path>
                        <path
                          d="M16.5 13.5V8.5H21.5V13.5H16.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M8.5 13.5V8.5H13.5V13.5H8.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M16.5 21.5V16.5H21.5V21.5H16.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M8.5 21.5V16.5H13.5V21.5H8.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div
                    :class="[
                      'btn btn-svg-icon js-set-view',
                      listing_type == 'list' ? 'active' : '',
                    ]"
                    v-on:click="showForm('list')"
                  >
                    <div data-type="list" class="svg-icon show-list grid-svd">
                      <!-- <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.75 4.25V0.75H13.25V4.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square" class="stroke-line-1"></path><path d="M0.75 13.25V9.75H13.25V13.25H0.75Z" stroke="#D1433A" stroke-width="1.5" stroke-linecap="square" class="stroke-line-1"></path>
                      </svg> -->
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 0H0V30H30V0Z"
                          fill="white"
                          class="c-fill-1"
                        ></path>
                        <path
                          d="M8.5 12.5V8.5H21.5V12.5H8.5Z"
                          stroke="#8B949F"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M8.5 21.5V17.5H21.5V21.5H8.5Z"
                          stroke="#8B949F"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="
                  text
                  with-icon
                  d-flex
                  justify-content-between justify-content-end
                  align-items-center
                "
              >
                Sort
                <div class="svg-icon">
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="9.51351"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                    <rect
                      y="5.18945"
                      width="16"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                    <rect
                      y="10.377"
                      width="12.973"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  dropdown
                  bootstrap-select
                  show-tick
                  filters-select
                  for-overview
                "
              >
                <button
                  type="button"
                  class="btn dropdown-toggle btn-light bs-placeholder"
                  data-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="Featured"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">
                        {{ filterApplied }}
                      </div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu">
                  <div
                    class="inner show"
                    role="listbox"
                    id="bs-select-1"
                    tabindex="-1"
                    aria-multiselectable="true"
                  >
                    <ul class="dropdown-menu inner show" role="presentation">
                      <li @click="updateFilter('Latest')">
                        <a
                          role="option"
                          class="dropdown-item"
                          :class="[
                            filterApplied == 'Latest' ? 'active selected' : '',
                          ]"
                          id="bs-select-9-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">Latest</span></a
                        >
                      </li>
                      <li @click="updateFilter('Featured')">
                        <a
                          role="option"
                          class="dropdown-item"
                          :class="[
                            filterApplied == 'Featured'
                              ? 'active selected'
                              : '',
                          ]"
                          id="bs-select-9-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">Featured</span></a
                        >
                      </li>
                      <li @click="updateFilter('Treanding')">
                        <a
                          role="option"
                          class="dropdown-item"
                          :class="[
                            filterApplied == 'Treanding'
                              ? 'active selected'
                              : '',
                          ]"
                          id="bs-select-9-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">Trending</span></a
                        >
                      </li>
                      <li @click="updateFilter('Popular')">
                        <a
                          role="option"
                          class="dropdown-item"
                          :class="[
                            filterApplied == 'Popular' ? 'active selected' : '',
                          ]"
                          id="bs-select-9-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">Popular</span></a
                        >
                      </li>
                      <li @click="updateFilter('High Rating')">
                        <a
                          role="option"
                          class="dropdown-item"
                          :class="[
                            filterApplied == 'High Rating'
                              ? 'active selected'
                              : '',
                          ]"
                          id="bs-select-9-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">High Rating</span></a
                        >
                      </li>
                      <li @click="updateFilter('Low Rating')">
                        <a
                          role="option"
                          class="dropdown-item"
                          :class="[
                            filterApplied == 'Low Rating'
                              ? 'active selected'
                              : '',
                          ]"
                          id="bs-select-9-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">Low Rating</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="js-container">
        <div class="sale-items m-t-10">
          <div class="row">
            <div class="col-sm-12"></div>
          </div>
          <div class="row position-relative" v-if="listing_type == 'grid'">
            <Reviews :header="false" :listData="allData" />
          </div>
          <div class="row position-relative" v-if="listing_type == 'list'">
            <ReviewListView
              :imageName="'demo_image_5'"
              :iconName="'music_with_circle'"
              listType="type1"
              :listData="allData"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="totalRows > allData.length">
        <div class="col-12">
          <div class="show-more musicbar_more mt-0">
            <a href="javascript:;" @click="fetchMoreData">
              <span class="show_more_bg">
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW MORE
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import Reviews from "@/components/Common/Elements/Reviews";
import ReviewListView from "@/components/Common/Elements/ReviewListView";
import { getPageData } from "../../../apis/APIs";
import { mapActions, mapGetters } from "vuex";
// import SingleMusicListView from "@/components/Common/Elements/SingleMusicListView"
export default {
  data() {
    return {
      listing_type: "grid",
      allData: [],
      dataLoaded: false,
      limit: 6,
      offset: 0,
      totalRows: 0,
      componentType: "buy",
      filterApplied: "LatestAlbums",
      user_id: "",
    };
  },
  components: {
    Reviews,
    ReviewListView,
  },
  props: {
    pageFrom: String,
    pageTitle: String,
    redirectUrl: String,
    thirdTxt: String,
    activeHeader: String,
    header: Boolean,
    pageFilter: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
  },
  mounted: function () {
    if (localStorage.getItem("componentType")) {
      this.componentType = localStorage.getItem("componentType");
    }
    let user_id = this.user.user_data.user_id;
    this.pageChannels();
  },
  methods: {
    showForm: function (type) {
      this.listing_type = type;
    },
    redirect: function (url) {
      this.$router.push({ name: this.redirectUrl });
    },
    async pageChannels() {
      let user_id = this.user.user_data.user_id;
      try {
        this.loading = true;
        let data = await getPageData(
          this.pageFilter,
          user_id,
          this.limit,
          this.offset,
          this.filterApplied
        );
        this.allData = data.data.data;
        this.totalRows = data.data.total_count;
        this.dataLoaded = true;
        console.log(this.allData, "Details Api");
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchMoreData() {
      let user_id = this.user.user_data.user_id;
      try {
        this.offset = parseInt(this.offset) + 1;
        this.loading = true;
        let data = await getPageData(
          this.pageFilter,
          user_id,
          this.limit,
          this.offset,
          this.filterApplied
        );
        this.allData = [...this.allData, ...data.data.data];
        this.totalRows = data.data.total_count;
        this.dataLoaded = true;
        console.log(this.allData, "Details Api");
      } catch (error) {
        this.loading = false;
      }
    },
    async updateFilter(currentFilter) {
      this.filterApplied = currentFilter;
      let user_id = this.user.user_data.user_id;
      try {
        this.loading = true;
        this.offset = 0;
        let data = await getPageData(
          this.pageFilter,
          user_id,
          this.limit,
          this.offset,
          this.filterApplied
        );
        this.allData = data.data.data;
        this.totalRows = data.data.total_count;
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
