<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div class="new-thread-wrapper m-t-30">
          <div class="item-title">{{ GET_MAIN_FORUM_THREADS.forum.name }}</div>
          <a
            class="text-decoration-none"
            @click="
              goTo({
                active_tab: 'new_thread',
                type: 'forum',
                title: 'New Thread',
              })
            "
          >
            <div class="btn btn-new-thread">
              <div class="svg-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="c-line-1"
                    d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    class="c-line-1"
                    d="M7 4.66699V9.33366"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    class="c-line-1"
                    d="M4.66699 7H9.33366"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              New Thread
            </div>
          </a>
        </div>
        <div class="for_line"></div>
        <div class="forums-item">
          <div class="item-head">
            <div class="">Threads</div>
            <div class="ml-auto th-title">Replies</div>
            <div class="ls-title">Last Post</div>
          </div>
          <div
            class="item-content for_height_item-content"
            v-for="thread in GET_MAIN_FORUM_THREADS.threads.data"
            :key="thread.id"
          >
            <div class="item-content-row">
              <div class="item-desc" style="margin: unset">
                <div class="item-inner-title">
                  <a
                    @click="
                      goTo({
                        active_tab: 'thread_reply',
                        thread_id: thread.id,
                        title: thread.title,
                      })
                    "
                    >{{ thread.title }}</a
                  >
                </div>
                <div class="item-inner-subtitle with-name">
                  by<a
                  @click="redirectWidgetsToProfilePage($event, thread.author_details.username)"
                >{{ thread.author_details.full_name }}</a
                  ><i>.</i>{{ dateFormat(thread.created_at) }}
                  <i>.</i>
                  <span>{{ thread.views }} views</span>
                </div>
              </div>
              <div class="threads ml-auto">
                <span></span>{{ thread.replies_count }}
              </div>
              <div class="replies">
                {{ dateFormat(thread.updated_at) }}
              </div>
            </div>
          </div>
        </div>
        <Pagination
          :count="GET_MAIN_FORUM_THREADS.forum.threads_count"
          :id="String(GET_MAIN_FORUM_THREADS.forum.id)"
          :keyId="'forum_id'"
          :actionName="'getForumThreads'"
        />
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapGetters, mapMutations, mapActions } from "vuex";
import Pagination from "@/components/Common/Elements/Pagination.vue";
import forum from "@/mixins/forum.js";
export default {
  name: "forumThread",
  mixins: [forum],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      actionName: "getForumThreads",
    };
  },
  components: { Pagination },
  methods: {
    ...mapMutations([
      "changeTypeForumThreads",
      "changeThreadId",
      "changeSubPath3",
    ]),
    ...mapActions(["getForumThreads", "getThread"]),
    async goTo(params) {
      if (params.type) {
        this.changeTypeForumThreads(params.type);
        this.changeSubPath3(params.title);
      } else if (params.thread_id) {
        this.changeThreadId(params.thread_id);
        await this.getThread({ thread_id: params.thread_id, offset: 0 });
        this.changeSubPath3(params.title);
      }
      this.$parent.changePage(params.active_tab);
    },
  },
  computed: {
    ...mapGetters(["GET_MAIN_FORUM_THREADS"]),
  },
  async created() {
    await this.getForumThreads({
      forum_id: this.$store.state.Forums.mainForumId,
      offset: 0,
    });
  },
};
</script>
<style lang="scss" scoped>
.for_height_item-content {
  height: 70px;
}

.item-inner-subtitle {
  display: flex;
  i {
    margin: 0 5px !important;
    position: relative;
    top: -3px;
  }
}
.for_line {
  border-top: 1px solid #e6eaf0;
  width: 100%;
  margin: 20px 0;
}
</style>
