<template>
  <div>
    <h1 class="EM_Pages_title">EVENTS DASHBOARD</h1>
    <div class="w-1170 event_manager_contener wr_dashboard">
      <div class="w-770 wr_content">
        <EMMonitoring/>
        <ChartAreaForDashboard :paramsChart="paramsChart" />
        <ResentOredrs :title="'Recent Orders'" />
      </div>
      <EMRightSidebar />
    </div>
  </div>
</template> 

<script>
import EMRightSidebar from "../EMElement/EMRightSidebar.vue";
import ResentOredrs from "../EMElement/ResentOredrs.vue";
import ChartAreaForDashboard from "../EMElement/ChartAreaForDashboard.vue";
import EMMonitoring from "../EMElement/EM_Monitoring.vue";
export default {
  components: {
    EMRightSidebar,
    ResentOredrs,
    ChartAreaForDashboard,
    EMMonitoring,
  },
  data() {
    return {
      paramsChart: {
        series: [
          {
            name: "This Week",
            color: "#D1433A",
            data: [0, 40, 45, 50, 49, 60, 70, 91],
          },
          // {
          //   name: "Last Week",
          //   color: "#FDB390",
          //   data: [0, 50, 30, 60, 70, 90, 54, 40],
          // },
        ],
        chartInfo: {
          title:'Revenue',
          subTitle:'(including booking fees)',
          grossIncome: "R1,381180",
          grossIncomeToday: "R10,500",
          totalIssuedTickets: "782",
          totalIssuedTicketsToday: "8",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>