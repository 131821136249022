<template>
  <div>
    <div class="post-section-retweet float-left" :style="backgroundColor" v-if="!fullViewPageCheck" @click="repost($event, 'Post')" :key="repostCounts">
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="post-section-retweet-svg" d="M10.333 0.666016L12.9997 3.33268L10.333 5.99935" stroke="#47505B"
              stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
        <path class="post-section-retweet-svg"
              d="M1 7.33398V6.00065C1 5.29341 1.28095 4.61513 1.78105 4.11503C2.28115 3.61494 2.95942 3.33398 3.66667 3.33398H13"
              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
        <path class="post-section-retweet-svg"
            d="M3.66667 15.3333L1 12.6667L3.66667 10" stroke="#47505B" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
        <path class="post-section-retweet-svg"
              d="M13 8.66602V9.99935C13 10.7066 12.719 11.3849 12.219 11.885C11.7189 12.3851 11.0406 12.666 10.3333 12.666H1"
              stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
      </svg>
      {{ repostCounts }}
    </div>
    <div class="post-section-comment" @click="openShareModel($event)" :style="style" v-if="!fullViewPageCheck">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="post-section-comment-svg" d="M14.9995 10.334V13.445C14.9995 13.8575 14.8357 14.2532 14.5439 14.5449C14.2522 14.8366 13.8566 15.0005 13.444 15.0005H2.5555C2.14296 15.0005 1.74731 14.8366 1.4556 14.5449C1.16388 14.2532 1 13.8575 1 13.445V10.334" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
        <path class="post-section-comment-svg" d="M11.8889 4.88876L8.00009 1L4.11133 4.88876" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
        <path class="post-section-comment-svg" d="M8 1V10.333" stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" :style="stroke"/>
      </svg>
      {{ postShareCount }}
    </div>
    <ShareModel :open-model="shareModel" :profile="user_picture" :post="post" :share_media_check="share_media_check" @closeShareModel="shareModel = false" ref="shareModal"/>
  </div>
</template>

<script>
import ShareModel from './ShareModel';
export default {
  name: "Post-share",
  data() {
    return {
      shareModel: false,
      style: '',
      repost_counts: 0
    }
  },
  props: {
    post: {
      type: Object,
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: ''
    },
    margin: {
      type: String,
      default: ''
    },
    fullViewPageCheck: {
      type: Boolean,
    },
    share_media_check:{
      type: Boolean,
      default: false
    }
  },
  components: {
    ShareModel
  },
  watch: {
    post() {
      this.repost_counts = this.post.repost_count
    }
  },
  computed: {
    postShareCount() {
      return (this.post.share_count) ? this.post.share_count : 0
    },
    repostCounts: {
      set(value) {
        return this.repost_counts = value;
      },
      get() {
        if (this.post && this.repost_counts > this.post.repost_count) {
          return this.repost_counts;
        } else {
          return this.post.repost_count;
        }
      }
    },
    user_picture() {
      return (this.$store.getters.user_map_to_props && this.$store.getters.user_map_to_props.user_data && this.$store.getters.user_map_to_props.user_data.artwork_url) ? this.$store.getters.user_map_to_props.user_data.artwork_url : null
    },
  },
  mounted() {
    this.style = this.backgroundColor + ';' + this.margin;
    if(this.post && this.post.repost_count){
      this.repost_counts = this.post.repost_count
    }
  },
  methods: {
    sharePost(event) {
      if (event) event.stopPropagation()
      this.$store.dispatch('sharePost', this.post.id)
    },
    openShareModel(event) {
      if (event) event.stopPropagation()
      this.shareModel = true
    },
    async repost(event, post = null, page_id = null) {
      const res = await this.$refs.shareModal.repostPost(event, post, page_id)
      if (res) {
        this.post.repost_count = this.post.repost_count + 1
        this.repost_counts =  this.post.repost_count
        this.$store.commit('SET_REPOST_COUNT', this.repost_counts)
        // await this.$emit('repostCounts1',this.repostCounts)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
