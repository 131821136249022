<template>
  <div class="modal fade modal-add-to-cart contact_info create-new-ticket job-invite show" id="job-decline"
       tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="display: block; padding-right: 15px;"
       aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="item-title">
          <div class="short-border">
            Decline Jane Cooper’s reply
          </div>
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div class="form-group m-t-0">
            <div class="input-title">Your Message</div>
            <textarea name="aaa" class="custom-textarea" id="" cols="30" rows="10"></textarea>
          </div>
          <div class="action-item">
            <div class="btn back-btn">Cancel</div>
            <div class="btn check-btn bg-red">Decline &amp; Send</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
