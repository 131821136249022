<template>
  <div class="about-social-link">
    <h1>SOCIAL LINKS</h1>

    <div class="social-link-mains ">
      <div class="row">
        <div class="col-md-6 mt-2">
          <div class="about_listing">
            <div class="about-more-details">
            <span>
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 7.54188C0 11.2706 2.70813 14.3713 6.25 15V9.58313H4.375V7.5H6.25V5.83313C6.25 3.95813 7.45813 2.91687 9.16687 2.91687C9.70813 2.91687 10.2919 3 10.8331 3.08313V5H9.875C8.95813 5 8.75 5.45813 8.75 6.04187V7.5H10.75L10.4169 9.58313H8.75V15C12.2919 14.3713 15 11.2712 15 7.54188C15 3.39375 11.625 0 7.5 0C3.375 0 0 3.39375 0 7.54188Z"
                    fill="#8B949F"/>
              </svg>
            </span>
              <p v-if="userData.facebook_url && userData.facebook_url!=='null' ">
                <b>{{ userData.facebook_url.slice(0, 25) }}...</b>
              </p>
            </div>
            <div class="about-more-details">
            <span>
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.6671 2.338C15.5765 2.01482 15.4 1.72223 15.1564 1.49133C14.9059 1.25334 14.5989 1.08309 14.2644 0.996666C13.0124 0.666666 7.99641 0.666667 7.99641 0.666667C5.9053 0.642875 3.8147 0.747516 1.73641 0.98C1.40187 1.07281 1.09545 1.24686 0.844411 1.48667C0.597745 1.724 0.419078 2.01667 0.325745 2.33733C0.10154 3.54516 -0.00739387 4.77156 0.000411455 6C-0.00758854 7.22733 0.101078 8.45333 0.325745 9.66267C0.417078 9.982 0.595078 10.2733 0.842411 10.5087C1.08974 10.744 1.39774 10.914 1.73641 11.004C3.00508 11.3333 7.99641 11.3333 7.99641 11.3333C10.0902 11.3572 12.1835 11.2525 14.2644 11.02C14.5989 10.9336 14.9059 10.7633 15.1564 10.5253C15.4031 10.29 15.5784 9.99733 15.6664 9.67867C15.8965 8.4713 16.0083 7.24439 16.0004 6.01533C16.0177 4.78107 15.906 3.54838 15.6671 2.33733V2.338ZM6.40174 8.28267V3.718L10.5751 6.00067L6.40174 8.28267Z"
                fill="#8B949F"/>
              </svg>

            </span>
              <p v-if="userData.youtube_url && userData.youtube_url!=='null'">
                <b>{{ userData.youtube_url.slice(0, 25) }}...</b>
              </p>
            </div>
            <div class="about-more-details">
            <span>
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.36 0H0.64C0.286 0 0 0.286 0 0.64V15.36C0 15.714 0.286 16 0.64 16H15.36C15.714 16 16 15.714 16 15.36V0.64C16 0.286 15.714 0 15.36 0ZM4.746 13.634H2.372V5.998H4.746V13.634ZM3.56 4.954C3.28785 4.954 3.02182 4.8733 2.79554 4.7221C2.56925 4.57091 2.39289 4.356 2.28874 4.10457C2.1846 3.85314 2.15735 3.57647 2.21044 3.30956C2.26353 3.04264 2.39458 2.79746 2.58702 2.60502C2.77946 2.41258 3.02464 2.28153 3.29156 2.22844C3.55847 2.17535 3.83514 2.2026 4.08657 2.30674C4.338 2.41089 4.55291 2.58725 4.7041 2.81354C4.8553 3.03982 4.936 3.30585 4.936 3.578C4.934 4.338 4.318 4.954 3.56 4.954ZM13.634 13.634H11.262V9.92C11.262 9.034 11.246 7.896 10.028 7.896C8.794 7.896 8.604 8.86 8.604 9.856V13.634H6.234V5.998H8.51V7.042H8.542C8.858 6.442 9.632 5.808 10.788 5.808C13.192 5.808 13.634 7.39 13.634 9.446V13.634Z" fill="#8B949F"/>
                </svg>
            </span>
              <p v-if="userData.linkedin_url  && userData.linkedin_url!=='null'">
                <b>{{ userData.linkedin_url.slice(0, 25) }}...</b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="about_listing">
            <div class="about-more-details">
            <span>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="30" fill="white"/>
              <path
                d="M22.7772 11.0856C22.2553 11.3169 21.6947 11.4731 21.1053 11.5437C21.7134 11.1799 22.1684 10.6072 22.3853 9.93249C21.814 10.2719 21.1887 10.5107 20.5366 10.6387C20.0981 10.1705 19.5172 9.86019 18.8843 9.75591C18.2513 9.65163 17.6016 9.75923 17.0361 10.062C16.4705 10.3648 16.0208 10.8458 15.7567 11.4304C15.4925 12.015 15.4288 12.6705 15.5753 13.295C14.4176 13.2369 13.2851 12.936 12.2512 12.4118C11.2173 11.8877 10.3052 11.152 9.57406 10.2525C9.32406 10.6837 9.18031 11.1837 9.18031 11.7162C9.18003 12.1956 9.29808 12.6676 9.52399 13.0904C9.74989 13.5133 10.0767 13.8738 10.4753 14.14C10.013 14.1253 9.56086 14.0004 9.15656 13.7756V13.8131C9.15652 14.4854 9.38908 15.1371 9.8148 15.6575C10.2405 16.1779 10.8332 16.5349 11.4922 16.6681C11.0633 16.7842 10.6136 16.8013 10.1772 16.7181C10.3631 17.2966 10.7253 17.8025 11.213 18.1649C11.7008 18.5274 12.2896 18.7282 12.8972 18.7394C11.8658 19.549 10.5921 19.9882 9.28094 19.9862C9.04868 19.9863 8.81661 19.9727 8.58594 19.9456C9.91686 20.8013 11.4662 21.2555 13.0484 21.2537C18.4047 21.2537 21.3328 16.8175 21.3328 12.97C21.3328 12.845 21.3297 12.7187 21.3241 12.5937C21.8936 12.1818 22.3853 11.6718 22.7759 11.0875L22.7772 11.0856Z"
                fill="#8B949F"/>
              </svg>
            </span>
              <p v-if="userData.twitter_url && userData.twitter_url!=='null'">
                <b> {{ userData.twitter_url.slice(0, 25) }}...</b>
              </p>
            </div>
            <div class="about-more-details cml-1">
            <span>
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.50094 5.02665C6.13903 5.02665 5.0276 6.13808 5.0276 7.49999C5.0276 8.8619 6.13903 9.97333 7.50094 9.97333C8.86286 9.97333 9.97428 8.8619 9.97428 7.49999C9.97428 6.13808 8.86286 5.02665 7.50094 5.02665ZM14.9191 7.49999C14.9191 6.47577 14.9284 5.46083 14.8709 4.43847C14.8133 3.25097 14.5424 2.19706 13.6741 1.3287C12.8039 0.458486 11.7518 0.189443 10.5643 0.131924C9.5401 0.0744044 8.52516 0.0836818 7.5028 0.0836818C6.47858 0.0836818 5.46364 0.0744044 4.44127 0.131924C3.25377 0.189443 2.19987 0.460342 1.33151 1.3287C0.461294 2.19892 0.192251 3.25097 0.134732 4.43847C0.077212 5.46269 0.0864894 6.47763 0.0864894 7.49999C0.0864894 8.52235 0.077212 9.53915 0.134732 10.5615C0.192251 11.749 0.46315 12.8029 1.33151 13.6713C2.20172 14.5415 3.25377 14.8105 4.44127 14.8681C5.46549 14.9256 6.48043 14.9163 7.5028 14.9163C8.52702 14.9163 9.54196 14.9256 10.5643 14.8681C11.7518 14.8105 12.8057 14.5396 13.6741 13.6713C14.5443 12.8011 14.8133 11.749 14.8709 10.5615C14.9302 9.53915 14.9191 8.52421 14.9191 7.49999ZM7.50094 11.3056C5.39499 11.3056 3.69538 9.60595 3.69538 7.49999C3.69538 5.39403 5.39499 3.69442 7.50094 3.69442C9.6069 3.69442 11.3065 5.39403 11.3065 7.49999C11.3065 9.60595 9.6069 11.3056 7.50094 11.3056ZM11.4624 4.42733C10.9707 4.42733 10.5736 4.03026 10.5736 3.53856C10.5736 3.04687 10.9707 2.6498 11.4624 2.6498C11.9541 2.6498 12.3511 3.04687 12.3511 3.53856C12.3513 3.65532 12.3284 3.77096 12.2838 3.87885C12.2392 3.98675 12.1737 4.08479 12.0912 4.16735C12.0086 4.24991 11.9106 4.31537 11.8027 4.35998C11.6948 4.40459 11.5791 4.42748 11.4624 4.42733Z"
                fill="#8B949F"/>
              </svg>
            </span>
              <p v-if="userData.instagram_url && userData.instagram_url!=='null'">
                <b>{{ userData.instagram_url.slice(0, 25) }}...</b>
              </p>
            </div>
            <div class="about-more-details">
            <span>
              <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.15731 9.46601C1.20631 9.46601 1.24731 9.42701 1.25531 9.37301L1.52531 7.35101L1.25531 5.28201C1.24831 5.22801 1.20531 5.18901 1.15631 5.18901C1.10631 5.18901 1.06231 5.22901 1.05631 5.28201L0.820312 7.35101L1.05631 9.37201C1.06231 9.42701 1.10631 9.46601 1.15631 9.46601H1.15731Z"
                fill="#8B949F"/>
              <path
                d="M0.272 8.697C0.32 8.697 0.36 8.661 0.367 8.607L0.576 7.351L0.367 6.071C0.36 6.018 0.32 5.981 0.272 5.981C0.221 5.981 0.182 6.018 0.174 6.071L0 7.351L0.174 8.607C0.182 8.66 0.221 8.697 0.272 8.697Z"
                fill="#8B949F"/>
              <path
                d="M2.22081 4.89699C2.2188 4.86638 2.20534 4.83765 2.1831 4.81653C2.16086 4.7954 2.13148 4.78343 2.10081 4.78299C2.07017 4.78321 2.04078 4.79514 2.01865 4.81633C1.99652 4.83753 1.98334 4.86639 1.98181 4.89699L1.75781 7.35099L1.98181 9.71499C1.98384 9.74541 1.99738 9.77392 2.01967 9.79472C2.04196 9.81552 2.07133 9.82706 2.10181 9.82699C2.13239 9.8268 2.16177 9.81505 2.18403 9.79408C2.20629 9.77312 2.21979 9.7445 2.22181 9.71399L2.47581 7.35099L2.22181 4.89699H2.22081Z"
                fill="#8B949F"/>
              <path
                d="M3.05222 9.92199C3.08779 9.92135 3.12185 9.90747 3.14774 9.88306C3.17363 9.85865 3.18949 9.82547 3.19222 9.78999L3.43322 7.35099L3.19322 4.82899C3.19047 4.79335 3.17447 4.76002 3.14837 4.73559C3.12227 4.71116 3.08797 4.69739 3.05222 4.69699C3.01627 4.69695 2.98168 4.71073 2.95562 4.73549C2.92956 4.76025 2.91402 4.79409 2.91222 4.82999L2.69922 7.35099L2.91122 9.78999C2.91302 9.82607 2.9287 9.86005 2.95498 9.88484C2.98126 9.90962 3.0161 9.92329 3.05222 9.92299V9.92199Z"
                fill="#8B949F"/>
              <path
                d="M4.01234 9.96201C4.05363 9.96208 4.09339 9.9464 4.1235 9.91815C4.15361 9.88989 4.17179 9.85121 4.17434 9.81L4.40034 7.351L4.17434 5.011C4.17155 4.96997 4.15326 4.93154 4.12317 4.9035C4.09309 4.87546 4.05347 4.85991 4.01234 4.86C3.97126 4.85995 3.93173 4.8757 3.90194 4.904C3.87216 4.9323 3.8544 4.97097 3.85234 5.012L3.65234 7.352L3.85234 9.81C3.8544 9.85104 3.87216 9.88971 3.90194 9.918C3.93173 9.9463 3.97126 9.96206 4.01234 9.96201Z"
                fill="#8B949F"/>
              <path
                d="M5.36947 7.35199L5.15747 3.54699C5.15469 3.50047 5.13435 3.45673 5.10057 3.42462C5.06678 3.39251 5.02208 3.37442 4.97547 3.37399C4.92879 3.37418 4.88395 3.39219 4.85012 3.42435C4.81628 3.45651 4.79602 3.50038 4.79347 3.54699L4.60547 7.35199L4.79347 9.80999C4.79819 9.85497 4.8194 9.89661 4.85301 9.92688C4.88662 9.95714 4.93024 9.97389 4.97547 9.97389C5.0207 9.97389 5.06432 9.95714 5.09793 9.92688C5.13154 9.89661 5.15275 9.85497 5.15747 9.80999L5.36947 7.35199Z"
                fill="#8B949F"/>
              <path
                d="M5.95222 9.987C6.004 9.98705 6.05381 9.96711 6.09126 9.93134C6.1287 9.89557 6.1509 9.84673 6.15322 9.795V9.797L6.35222 7.353L6.15322 2.677C6.14962 2.62575 6.12672 2.57777 6.08914 2.54274C6.05156 2.50771 6.00209 2.48824 5.95072 2.48824C5.89934 2.48824 5.84988 2.50771 5.8123 2.54274C5.77472 2.57777 5.75182 2.62575 5.74822 2.677L5.57422 7.353L5.74922 9.796C5.75205 9.84772 5.77474 9.89636 5.81257 9.93176C5.85039 9.96716 5.90042 9.98659 5.95222 9.986V9.987Z"
                fill="#8B949F"/>
              <path
                d="M6.93187 2.07601C6.87452 2.07569 6.81926 2.09759 6.77769 2.13711C6.73612 2.17663 6.71146 2.23071 6.70887 2.28801L6.54688 7.35301L6.70887 9.77101C6.71146 9.82822 6.73616 9.88219 6.77776 9.92155C6.81936 9.96091 6.87461 9.98259 6.93187 9.98201C6.98921 9.98284 7.04461 9.96125 7.08626 9.92184C7.12792 9.88242 7.15253 9.82831 7.15487 9.77101L7.33987 7.35301L7.15487 2.28801C7.15279 2.23054 7.12828 2.17617 7.0866 2.13654C7.04492 2.09692 6.98938 2.07519 6.93187 2.07601Z"
                fill="#8B949F"/>
              <path
                d="M7.91844 9.987C7.98142 9.98808 8.0423 9.96436 8.08794 9.92096C8.13359 9.87756 8.16035 9.81795 8.16244 9.755V9.757L8.33244 7.353L8.16244 2.118C8.16035 2.05522 8.13373 1.99576 8.0883 1.95239C8.04287 1.90901 7.98224 1.88517 7.91944 1.88599C7.85648 1.88465 7.79556 1.90831 7.75001 1.9518C7.70447 1.99528 7.67801 2.05504 7.67644 2.118L7.52344 7.353L7.67644 9.757C7.67844 9.886 7.78644 9.987 7.91944 9.987H7.91844Z"
                fill="#8B949F"/>
              <path
                d="M8.91762 9.98501C8.98573 9.98583 9.05145 9.95989 9.10063 9.91276C9.14981 9.86564 9.17853 9.80109 9.18062 9.73301V9.73501L9.33762 7.35401L9.18062 2.25101C9.17802 2.18328 9.14907 2.11924 9.09995 2.07254C9.05082 2.02585 8.9854 2.00018 8.91762 2.00101C8.84968 1.99991 8.784 2.02546 8.73466 2.07219C8.68532 2.11892 8.65623 2.1831 8.65363 2.25101L8.51562 7.35401L8.65462 9.73401C8.65762 9.87401 8.77363 9.98401 8.91762 9.98401V9.98501Z"
                fill="#8B949F"/>
              <path
                d="M10.3497 7.35401L10.2077 2.43701C10.2051 2.3638 10.1739 2.29453 10.1208 2.24405C10.0677 2.19357 9.99697 2.16592 9.92372 2.16701C9.85013 2.16566 9.77897 2.19333 9.72563 2.24405C9.6723 2.29477 9.64107 2.36445 9.63872 2.43801L9.51172 7.35401L9.63872 9.72001C9.64133 9.7934 9.67266 9.86282 9.72597 9.91332C9.77928 9.96383 9.8503 9.99137 9.92372 9.99001C9.99747 9.99109 10.0687 9.96302 10.1218 9.9119C10.175 9.86078 10.2059 9.79075 10.2077 9.71701V9.72001L10.3497 7.35401Z"
                fill="#8B949F"/>
              <path
                d="M10.9356 9.994C11.1006 9.994 11.2366 9.864 11.2396 9.704L11.3686 7.355L11.2396 1.50501C11.237 1.4264 11.2037 1.35194 11.1469 1.29755C11.0901 1.24317 11.0143 1.21318 10.9356 1.21401C10.857 1.21343 10.7811 1.24346 10.7242 1.29777C10.6673 1.35208 10.6337 1.4264 10.6306 1.50501L10.5156 7.353L10.6306 9.705C10.6336 9.863 10.7706 9.994 10.9356 9.994Z"
                fill="#8B949F"/>
              <path
                d="M11.9444 0.663998C11.8602 0.662632 11.7787 0.694323 11.7176 0.752274C11.6565 0.810225 11.6205 0.889823 11.6174 0.973998L11.4844 7.356L11.6184 9.671C11.6215 9.75464 11.6573 9.83373 11.718 9.89131C11.7788 9.94888 11.8597 9.98036 11.9434 9.979C12.0274 9.98008 12.1085 9.94807 12.1691 9.88988C12.2297 9.83169 12.265 9.75199 12.2674 9.668V9.671L12.4134 7.356L12.2674 0.974998C12.265 0.891177 12.2299 0.811628 12.1695 0.753467C12.109 0.695306 12.0282 0.663179 11.9444 0.663998Z"
                fill="#8B949F"/>
              <path
                d="M12.8669 9.996L21.0479 10C22.6789 10 24.0009 8.732 24.0009 7.167C24.0009 5.603 22.6789 4.335 21.0479 4.335C20.6439 4.335 20.2579 4.414 19.9059 4.555C19.6729 2.003 17.4409 0 14.7189 0C14.0539 0 13.4049 0.126 12.8319 0.339C12.6089 0.422 12.5489 0.507 12.5469 0.672V9.661C12.55 9.74631 12.5843 9.82752 12.6433 9.88925C12.7022 9.95098 12.7818 9.98896 12.8669 9.996Z"
                fill="#8B949F"/>
              </svg>
            </span>
              <p v-if="userData.soundcloud_url  && userData.soundcloud_url!=='null'">
                <b>{{ userData.soundcloud_url.slice(0, 25) }}...</b>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "socialLinks",
  props: {
    userData: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped>
.cml-1{
  margin-left: 0.55rem !important;
}
</style>
