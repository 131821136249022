<template>
    <div class="btn btn-google Popup_btn_google" @click="AuthProvider('google')">
        <div class="svg-icon">
            <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                fill="white"
            ></path>
            <path
                d="M10 7.34157C10.8137 7.34157 11.3626 7.69305 11.6756 7.98676L12.8985 6.79268C12.1474 6.09453 11.17 5.66602 10 5.66602C8.3052 5.66602 6.8415 6.63861 6.12891 8.05416L7.53002 9.14231C7.8815 8.0975 8.85409 7.34157 10 7.34157Z"
                fill="#EA4335"
            ></path>
            <path
                d="M14.16 10.0949C14.16 9.73861 14.1311 9.47861 14.0685 9.20898H10V10.8171H12.3881C12.34 11.2168 12.08 11.8186 11.5022 12.2231L12.8696 13.2823C13.6881 12.5264 14.16 11.4142 14.16 10.0949Z"
                fill="#4285F4"
            ></path>
            <path
                d="M7.53417 10.8569C7.44268 10.5873 7.38972 10.2984 7.38972 9.99987C7.38972 9.70135 7.44268 9.41246 7.52935 9.14284L6.12824 8.05469C5.83453 8.64209 5.66602 9.30172 5.66602 9.99987C5.66602 10.698 5.83453 11.3576 6.12824 11.9451L7.53417 10.8569Z"
                fill="#FBBC05"
            ></path>
            <path
                d="M9.99911 14.3318C11.1691 14.3318 12.1513 13.9466 12.8687 13.2821L11.5013 12.2229C11.1354 12.4781 10.6443 12.6562 9.99911 12.6562C8.85318 12.6562 7.88059 11.9003 7.53392 10.8555L6.13281 11.9436C6.8454 13.3592 8.30429 14.3318 9.99911 14.3318Z"
                fill="#34A853"
            ></path>
            </svg>
        </div>
        {{
    loading ? "Please Wait..." : "Google"
  }}
     </div>

</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {facebook_login, get_ip_address} from '../../../apis/APIs';
import * as Utils from "../../../components/utils/Utils";

export default {
  data() {
    return ({
      loading: false
    })
  },
  methods: {
    ...mapActions([
      "update_user_data"
    ]),
    loginWithGoogle() {
      this.loading = true;
      this.$gAuth
          .signIn()
          .then(async GoogleUser => {
            // on success do something
            const gUser = GoogleUser.getBasicProfile();

            const res_ip = await get_ip_address();
            const {ip} = res_ip.data;

            const email = gUser.getEmail();
            const first_name = gUser.getGivenName();
            const last_name = gUser.getFamilyName();
            const picture = gUser.getImageUrl();
            const google_id = gUser.getId();

            const query = `?user_browser=${Utils.getBrowser()}&user_os=${navigator.platform}&user_ip=${ip}&firstname=${first_name}&lastname=${last_name}&email=${email}&user_type=2&gmail_id=${google_id}&username=${first_name}${google_id}`;
            const user_data_res = await facebook_login(query);

            const {success, data} = user_data_res || {};

            if (success === "1") {
              await this.update_user_data(data);
              this.$router.push("/home");
            }

            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log('error', error)
          })
    },
    AuthProvider(provider) {

      var self = this

      this.$auth.authenticate(provider).then(response =>{

        console.log('in self login');
        self.SocialLogin(provider,response)

      }).catch(err => {
        console.log('not in self login');
        console.log({err:err})
      })

    },
    SocialLogin(provider,response){

      this.$http.post('/sociallogin/'+provider,response).then(response => {

        console.log(response.data)

      }).catch(err => {
        console.log({err:err})
      })
    },
  }
}
</script>
<style lang="">

</style>
