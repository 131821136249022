<template lang="">
    <div>
        <ReplyItem v-for="(eachOp, index) in comment_list" :key="'reply_item'+index" :item="eachOp"/>
        <!-- <div class="comment-post-html-newsfeed">
            <div class="user-post-filed-image">
                <img :src="require(`@/assets/img/Image-2-man.png`)">
            </div>
            <div class="post-something-field" :style="emoji ? 'z-index: 1' : ''">
                <input type="text" placeholder="Add a Comment" v-model="comment" v-on:keyup="checkEnter">
                <span class="post_emoji" @click="toggleEmoji">
                <input type="file" id="upload-comment-image" @change="updateImage"/>
                <svg width="18" height="18" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.45 15.9C12.5645 15.9 15.9 12.5645 15.9 8.45C15.9 4.33548 12.5645 1 8.45 1C4.33548 1 1 4.33548 1 8.45C1 12.5645 4.33548 15.9 8.45 15.9Z" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M5.52637 10.1211C5.85233 10.5652 6.27635 10.9282 6.76545 11.1818C7.25455 11.4353 7.79554 11.5727 8.34637 11.5831C8.89719 11.5727 9.43819 11.4353 9.92729 11.1818C10.4164 10.9282 10.8404 10.5652 11.1664 10.1211" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M11.3314 7.82095C11.8682 7.82095 12.3034 7.38577 12.3034 6.84895C12.3034 6.31213 11.8682 5.87695 11.3314 5.87695C10.7946 5.87695 10.3594 6.31213 10.3594 6.84895C10.3594 7.38577 10.7946 7.82095 11.3314 7.82095Z" fill="#47505B"></path>
                    <path d="M5.5677 7.82095C6.10452 7.82095 6.5397 7.38577 6.5397 6.84895C6.5397 6.31213 6.10452 5.87695 5.5677 5.87695C5.03088 5.87695 4.5957 6.31213 4.5957 6.84895C4.5957 7.38577 5.03088 7.82095 5.5677 7.82095Z" fill="#47505B"></path>
                </svg>
                </span>
                <span class="image-icon ">
                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.2714 9.61429L12.8286 7.17143L8.2 11.8L5.5 9.1L1 13.7286V17.0714C1.01239 17.579 1.21956 18.0624 1.57859 18.4214C1.93761 18.7804 2.42098 18.9876 2.92857 19H17.0714C17.579 18.9876 18.0624 18.7804 18.4214 18.4214C18.7804 18.0624 18.9876 17.579 19 17.0714V2.92857C18.9876 2.42098 18.7804 1.93761 18.4214 1.57859C18.0624 1.21956 17.579 1.01239 17.0714 1H2.92857C2.42098 1.01239 1.93761 1.21956 1.57859 1.57859C1.21956 1.93761 1.01239 2.42098 1 2.92857V8.71429" stroke="#47505B" stroke-width="1.3" stroke-miterlimit="10"></path>
                    </svg>
                </span>
                <Emoji icon="" @click="selectEmogie" :open="emoji"/>
            </div>
        </div> -->
    </div>

</template>
<script>
import {post_comment_api, post_comment_form_data_api} from '../../../../apis/APIs';
import {mapGetters} from 'vuex';
import moment from 'moment';
import {VueChatEmoji} from 'vue-chat-emoji'
import ReplyItem from './ReplyItem'

require("vue-chat-emoji/dist/vue-chat-emoji.min.css");

export default {
  data() {
    return ({
      comment: "",
      comment_loading: false,
      photo: undefined,
      photo_src: undefined,

      comment_list_loading: false,
      comment_list: [],

      message: "",
      success_message: "",

      emoji: false
    })
  },
  mounted() {
    this.init();
  },
  props: ["item"],
  computed: {
    ...mapGetters({
      "user": "user_map_to_props"
    }),
    id() {
      const {comment_id = ""} = this.item || {};

      return comment_id;
    },
    post_id() {
      const {node_id = ""} = this.item || {};

      return node_id;
    }
  },
  components: {
    ReplyItem
  },
  methods: {
    async init() {
      try {

        const {user_data} = this.user || {};
        const {user_id} = user_data || {};

        this.comment_list_loading = true;
        const query = `?action=5&user_id=${user_id}&node_id=${this.id}&node_type=comment`;
        const {status, message, information} = await post_comment_api(query);

        this.comment_list_loading = false;

        this.comment_list = information && information.length ? information : [];

      } catch (error) {
        console.log("mounted ===>  error", error);
        this.comment_list_loading = false;
      }
    },
    async submit() {

      const {photo, comment_loading, comment} = this;

      const {user_data} = this.user || {};
      const {user_id} = user_data || {};


      if (comment_loading || (!comment && !photo)) return;

      try {

        var formdata = new FormData();
        formdata.append("action", "1");
        formdata.append("node_id", this.id);
        formdata.append("node_type", "post");
        formdata.append("user_id", user_id);
        formdata.append("user_type", "user");
        formdata.append("text", comment);
        if (photo) formdata.append("image", photo);
        formdata.append("voice_note", "");
        formdata.append("time", moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        formdata.append("reaction_like_count", "0");
        formdata.append("reaction_love_count", "0");
        formdata.append("reaction_haha_count", "0");
        formdata.append("reaction_yay_count", "0");
        formdata.append("reaction_wow_count", "0");
        formdata.append("reaction_sad_count", "0");
        formdata.append("reaction_angry_count", "0");
        formdata.append("replies", "0");
        formdata.append("points_earned", "0");

        this.comment_loading = true;
        this.message = "";

        const {success, message} = await post_comment_form_data_api(formdata);
        this.comment_loading = false;

        if (success === 1) {
          this.success_message = message;
          this.reset_data();
          this.init();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

      } catch (error) {
        this.comment_loading = false;
      }
    },
    async updateImage(e) {
      const files = e.target.files;
      const file = files && files.length ? files[0] : null;

      this.photo = file;
      this.photo_src = URL.createObjectURL(file);
    },
    checkEnter(event) {

      if (event.keyCode === 13) {
        this.submit();
      }

    },
    reset_data() {
      this.comment = "";
      this.photo = undefined;
      this.photo_src = undefined;
    },
    toggleEmoji() {
      this.emoji = !this.emoji
    },
    selectEmogie(data) {
      const {comment} = this;
      const {emoji, key} = data || {};

      this.comment = comment ? `${comment} ${emoji}` : emoji;
    },
  }
}
</script>
<style lang="">

</style>
