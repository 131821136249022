<template>
    <div class="main-div">
        <PartyHeader/>
        <MainContent />
        <PartyFooter/>
    </div>
</template>
<script>
import PartyHeader from "./Components/PartyRoomComponents/Header.vue";
import MainContent from "./Components/PartyRoomComponents/MainContent.vue";
import PartyFooter from "./Components/PartyRoomComponents/Footer.vue";
export default {
    name: "PartyRoom",
    data() {
        return {
        
        };
    },
    components: {
        PartyHeader,
        MainContent,
        PartyFooter
    },
};
</script>
<style scoped lang="scss">
.main-div {
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px 4px 0 0;
    width: 1170px;
    height: 780px;
    margin: 19px 5px 10px 5px;
}
</style>