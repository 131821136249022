import {
  Add_To_Queue,
  PREVIOUS_ROUTE,
  COUNTRIES,
  CURRENT_SONG_ALBUM_FAVORITE,
  CURRENT_SONG_ALBUM_FAVORITE_COUNTS,
  ERROR,
  HEIGHT,
  LEFT,
  LIST_OPTION,
  LIST_OPTION_FAVORITE,
  LIST_OPTION_FAVORITE_ID,
  LIST_TYPE,
  MUSIC_MODE,
  OPEN,
  Play_List_Modal_Open,
  Playlist_Modal_Open_Check,
  ROLES,
  ROUTE_STACK,
  SHOW_CROPPER,
  STREAMING_MODE,
  SUCCESS,
  WIDTH,
  CHAT_OPTION,
  CHAT_TYPE,
  CHAT_ATTACHMENT,
  ACTIVE_CHAT_USER,
  ACTIVE_CHANNEL,
  ACTIVE_GROUP,
  ACTIVE_CHAT,
  ACTIVE_FULL_CHAT,
  FULL_CHAT_ACTIVE_CHANNEL,
  ACTIVE_FULL_CHAT_USER,
  ACTIVE_FULL_CHAT_TYPE,
  ACTIVE_ALL_IMAGES,
  ACTIVE_ALL_LINKS,
  ACTIVE_ALL_FILES,
  ACTIVE_ALL_AUDIOS,
  ACTIVE_ALL_VOICES,
  ACTIVE_ALL_GIFS,
  ACTIVE_ALL_GROUP_MEMBERS,
  TYPING,
  IS_TYPING,
  TYPING_USER,
  VIEW_ALL_CHAT_LISTING,
  ACTIVE_CHAT_ID,
  ACTIVE_FULL_CHAT_PREVIOUS_TYPE,
  ACTIVE_FULL_CHAT_USER_BACKEND,
  RIGHT_BAR_CHECK,
  REDIRECT_TO_SETTING_PAGE,
  REDIRECT_TO_SETTING_PAGE_CHECK,
  REDIRECT_TO_SETTING_PAGE_TAB,
  VIEW_ALL_CHAT_LISTING_TAB, LEFT_BAR_LISTING_ACTIVE_TAB, ACTIVE_LAUNCH_ROOM_FROM_ROOM, CREATE_EVENT_MODAL_CHECK
} from "../types";
import { getCountries, getUserRoles } from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";
import Vue from "vue";

const INITIAL_STATE = {
  [ROUTE_STACK]: [],

  [ROLES]: [],
  [SHOW_CROPPER]: true,
  [LIST_OPTION]: {
    [HEIGHT]: null,
    [WIDTH]: null,
    [LEFT]: false,
    [OPEN]: false,
    [LIST_TYPE]: "default_option_list",
    [RIGHT_BAR_CHECK]: false,
    [CURRENT_SONG_ALBUM_FAVORITE]: null,
    [CURRENT_SONG_ALBUM_FAVORITE_COUNTS]: null,
  },
  [CHAT_OPTION]: {
    [OPEN]: false,
    [CHAT_TYPE]: "",
    [TYPING]: {
      [IS_TYPING]: false,
      [TYPING_USER]: "",
    },
  },
  [VIEW_ALL_CHAT_LISTING]: false,
  [VIEW_ALL_CHAT_LISTING_TAB]:"",
  [CHAT_ATTACHMENT]: false,
  [Add_To_Queue]: [],
  [Play_List_Modal_Open]: false,
  [Playlist_Modal_Open_Check]: null,
  [STREAMING_MODE]: true,
  [LIST_OPTION_FAVORITE] : null,
  [LIST_OPTION_FAVORITE_ID] : null,
  [PREVIOUS_ROUTE] : '',
  [ACTIVE_CHAT_USER]:{},
  [ACTIVE_CHANNEL]:{},
  [ACTIVE_GROUP]:{},
  [ACTIVE_CHAT]:{},
  [ACTIVE_FULL_CHAT]:{},
  [FULL_CHAT_ACTIVE_CHANNEL]:{},
  [ACTIVE_FULL_CHAT_USER]:{},
  [ACTIVE_FULL_CHAT_TYPE]:'',
  [ACTIVE_FULL_CHAT_PREVIOUS_TYPE]: '',
  [ACTIVE_ALL_IMAGES]: [],
  [ACTIVE_ALL_FILES]: [],
  [ACTIVE_ALL_AUDIOS]: [],
  [ACTIVE_ALL_LINKS]: [],
  [ACTIVE_ALL_VOICES]: [],
  [ACTIVE_ALL_GIFS]: [],
  [ACTIVE_ALL_GROUP_MEMBERS]: [],
  [ACTIVE_CHAT_ID]: "",
  active_launch_Room: false,
  [ACTIVE_LAUNCH_ROOM_FROM_ROOM]: false,
  [ACTIVE_FULL_CHAT_USER_BACKEND]:{},
  [REDIRECT_TO_SETTING_PAGE]: {
    [REDIRECT_TO_SETTING_PAGE_CHECK]: false,
    [REDIRECT_TO_SETTING_PAGE_TAB]: null
  },
  [LEFT_BAR_LISTING_ACTIVE_TAB]: '',
  [CREATE_EVENT_MODAL_CHECK]: false
};

export const state = { ...INITIAL_STATE };
export const actions = {
  async getRoles({ state, commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch("loading", true);
        const { data } = await getUserRoles();
        commit("SET_ROLES", data.data);
        dispatch("loading", false);
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        dispatch("loading", false);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
};

export const mutations = {
  SET_ACTIVE_LAUNCH_ROOM(state,payload) {
    state[ACTIVE_LAUNCH_ROOM_FROM_ROOM] = payload;
    state.active_launch_Room = !state.active_launch_Room;
  },
  SET_ROLES(state, payload) {
    try {
      state[ROLES] = payload;
    } catch (e) {
      console.error(e.message, "unable to set roles");
    }
  },
  SET_CROPPER(state, payload) {
    try {
      state[SHOW_CROPPER] = payload;
    } catch (e) {
      console.error(e.message, "unable to set cropper");
    }
  },
  SET_HEIGHT_AND_WIDTH_LIST_OPTION(state, { height, width, left = false }) {
    try {
      state[LIST_OPTION][HEIGHT] = height;
      state[LIST_OPTION][WIDTH] = width;
      state[LIST_OPTION][LEFT] = left;
    } catch (e) {
      console.error(e.message, "unable to set cropper");
    }
  },
  SET_LIST_OPTION(state, payload) {
    try {
      state[LIST_OPTION][OPEN] = payload.value;
      state[LIST_OPTION][LIST_TYPE] = payload.list_type;
      state[LIST_OPTION][RIGHT_BAR_CHECK] = payload.right_bar_check;
      if (payload.value === true) {
        state[Add_To_Queue] = payload.Songs;
      }
    } catch (e) {
      console.error(e.message, "unable to open list option");
    }
  },
  SET_SINGLE_CHAT_OPTION(state, payload) {
    try {
      state[CHAT_OPTION][OPEN] = payload.check;
      state[CHAT_OPTION][CHAT_TYPE] = payload.type;
    } catch (e) {
      console.error(e.message, "unable to open single chat option");
    }
  },
  ADD_ROUTE_IN_STACK(state, value) {
    try {
      let _state = Utils.parseState(state);
      _state[ROUTE_STACK].push({
        path: value.fullPath,
        name: value.name,
      });
      Vue.set(state, ROUTE_STACK, _state[ROUTE_STACK]);
    } catch (e) {
      console.error(e.message, "unable to add route");
    }
  },
  SET_LIST_OPTION_STATUS(state) {
    state[LIST_OPTION][OPEN] = false;
  },
  SET_PLAY_LIST_MODAL(state, payload) {
    state[Play_List_Modal_Open] = payload.value;
    state[Playlist_Modal_Open_Check] = payload.check;
  },
  Update_Playlist_Modal_Status(state, payload) {
    state[Playlist_Modal_Open_Check] = payload;
  },
  SET_MUSIC_MODE(state, value) {
    if (value === true || value === false) state[STREAMING_MODE] = value;
  },
  SET_LIST_OPTION_FAVORITE(state, value, id) {
    if (value === true || value === false) {
      state[LIST_OPTION_FAVORITE] = value;
    }
  },
  SET_LIST_OPTION_FAVORITE_ID(state, value) {
    state[LIST_OPTION_FAVORITE_ID] = value;
  },
  SET_EMPTY_LIST_OPTION_FAVORITE(state) {
    state[LIST_OPTION_FAVORITE] = null;
    state[LIST_OPTION_FAVORITE_ID] = null;
  },
  SET_CURRENT_SONG_ALBUM_FAVORITE(state, value) {
    state[LIST_OPTION][CURRENT_SONG_ALBUM_FAVORITE] = value;
  },
  SET_CURRENT_SONG_ALBUM_FAVORITE_COUNTS(state, value) {
    state[LIST_OPTION][CURRENT_SONG_ALBUM_FAVORITE_COUNTS] = value;
  },
  SET_PREVIOUS_ROUTE(state, value) {
    state[PREVIOUS_ROUTE] = value;
  },
  SET_ACTIVE_CHAT_USER(state, value) {
    state[ACTIVE_CHAT_USER] = value;
  },
  SET_ACTIVE_CHANNEL(state, value) {
    state[ACTIVE_CHANNEL] = value;
  },
  SET_ACTIVE_GROUP(state, value) {
    state[ACTIVE_GROUP] = value;
  },
  SET_ACTIVE_CHAT(state, value) {
    state[ACTIVE_CHAT] = value;
  },
  SET_ACTIVE_FULL_CHAT_USER(state, value) {
    state[ACTIVE_FULL_CHAT_USER] = value;
  },
  SET_ACTIVE_FULL_CHAT(state, value) {
    state[ACTIVE_FULL_CHAT] = value;
  },
  SET_ACTIVE_FULL_CHAT_USER_BACKEND(state,value) {
    state[ACTIVE_FULL_CHAT_USER_BACKEND] = value
  },
  SET_FULL_CHAT_ACTIVE_CHANNEL(state, value) {
    state[FULL_CHAT_ACTIVE_CHANNEL] = value;
  },
  SET_ACTIVE_FULL_CHAT_TYPE(state, value) {
    state[ACTIVE_FULL_CHAT_TYPE] = value;
  },
  SET_ACTIVE_FULL_CHAT_PREVIOUS_TYPE(state,value) {
    state[ACTIVE_FULL_CHAT_PREVIOUS_TYPE] = value
  },
  SET_CHAT_ATTACHMENT_MODAL (state, value) {
    state[CHAT_ATTACHMENT] = value
  },
  SET_ACTIVE_ALL_IMAGES(state, value) {
    state[ACTIVE_ALL_IMAGES] = value;
  },
  SET_ACTIVE_ALL_FILES(state, value) {
    state[ACTIVE_ALL_FILES] = value;
  },
  SET_ACTIVE_ALL_AUDIOS(state, value) {
    state[ACTIVE_ALL_AUDIOS] = value;
  },
  SET_ACTIVE_ALL_VOICES(state, value) {
    state[ACTIVE_ALL_VOICES] = value;
  },
  SET_ACTIVE_ALL_GIFS(state, value) {
    state[ACTIVE_ALL_GIFS] = value;
  },
  SET_ACTIVE_ALL_LINKS(state, value) {
    state[ACTIVE_ALL_LINKS] = value;
  },
  SET_ACTIVE_ALL_GROUP_MEMBERS(state, value) {
    state[ACTIVE_ALL_GROUP_MEMBERS] = value;
  },
  SET_TYPING_USER(state, value) {
    state[CHAT_OPTION][TYPING][TYPING_USER] = value;
  },
  SET_IS_TYPING(state, value) {
    state[CHAT_OPTION][TYPING][IS_TYPING] = value;
  },
  SET_VIEW_ALL_CHAT_LISTING(state, value) {
    state[VIEW_ALL_CHAT_LISTING] = value;
  },
  SET_VIEW_ALL_CHAT_LISTING_TAB(state, value) {
    state[VIEW_ALL_CHAT_LISTING_TAB] = value;
  },
  SET_ACTIVE_CHAT_ID(state, value) {
    state[ACTIVE_CHAT_ID] = value;
  },
  SET_REDIRECT_TO_SETTING_PAGE_CHECK(state, value) {
    state[REDIRECT_TO_SETTING_PAGE][REDIRECT_TO_SETTING_PAGE_CHECK] = value
  },
  SET_REDIRECT_TO_SETTING_PAGE_TAB(state, value) {
    state[REDIRECT_TO_SETTING_PAGE][REDIRECT_TO_SETTING_PAGE_TAB] = value
  },
  SET_LEFT_BAR_LISTING_ACTIVE_TAB(state, value) {
    state[LEFT_BAR_LISTING_ACTIVE_TAB] = value
  },
  SET_CREATE_EVENT_MODAL_CHECK (state, value) {
    state[CREATE_EVENT_MODAL_CHECK] = value
  }
};
const getters = {
  get_active_launch_Room(state){
    return state.active_launch_Room
  },
  getActiveLaunchRoomFromRoom(state){
    return state.active_launch_room_from_room
  },
  getAddToQueue(state) {
    let _state = Utils.parseState(state);
    return _state[Add_To_Queue];
  },
  getListOptionType(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][LIST_TYPE];
  },
  getListOptionValue(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][OPEN];
  },
  getListOptionHeight(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][HEIGHT];
  },
  getListOptionWidth(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][WIDTH];
  },
  getListOptionRightBarCheck(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][RIGHT_BAR_CHECK];
  },
  getRoles(state) {
    let _state = Utils.parseState(state);
    return _state[ROLES];
  },
  getCropperState(state) {
    let _state = Utils.parseState(state);
    return _state[SHOW_CROPPER];
  },
  getPlaylistModal(state) {
    let _state = Utils.parseState(state);
    return _state[Play_List_Modal_Open];
  },
  getPlaylistModalCheck(state) {
    let _state = Utils.parseState(state);
    return _state[Playlist_Modal_Open_Check];
  },
  isMusicModeIsStreaming(state) {
    let _state = Utils.parseState(state);
    return !!_state[STREAMING_MODE];
  },
  getPositionOfListOptionOnLeft(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][LEFT];
  },
  getListOptionFavorite(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION_FAVORITE];
  },
  getListOptionFavoriteId(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION_FAVORITE_ID];
  },
  getCurrentSongOrAlbumFavorite(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][CURRENT_SONG_ALBUM_FAVORITE];
  },
  getCurrentSongOrAlbumFavoriteCounts(state) {
    let _state = Utils.parseState(state);
    return _state[LIST_OPTION][CURRENT_SONG_ALBUM_FAVORITE_COUNTS];
  },
  getArticlePreviousRoute(state) {
    let _state = Utils.parseState(state);
    return _state[PREVIOUS_ROUTE];
  },
  getIfChatBoxIsOpen(state) {
    let _state = Utils.parseState(state);
    return _state[CHAT_OPTION][OPEN];
  },
  getChatBoxType(state) {
    let _state = Utils.parseState(state);
    return _state[CHAT_OPTION][CHAT_TYPE];
  },
  getActiveChatUser(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_CHAT_USER];
  },
  getActiveChannel(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_CHANNEL];
  },
  getActiveGroup(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_GROUP];
  },
  getActiveChat(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_CHAT];
  },
  getActiveFullChat(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_FULL_CHAT];
  },
  getFullChatActiveChannel(state) {
    let _state = Utils.parseState(state);
    return _state[FULL_CHAT_ACTIVE_CHANNEL];
  },
  getActiveFullChatUser(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_FULL_CHAT_USER];
  },
  getActiveFullChatUserBackend (state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_FULL_CHAT_USER_BACKEND];
  },
  getActiveFullChatType(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_FULL_CHAT_TYPE];
  },
  getActiveFullChatPreviousType (state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_FULL_CHAT_PREVIOUS_TYPE];
  },
  getChatAttachmentChatModalStatus (state) {
    let _state = Utils.parseState(state);
    return _state[CHAT_ATTACHMENT];
  },
  getActiveAllImages(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_IMAGES];
  },
  getActiveAllFiles(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_FILES];
  },
  getActiveAllAudios(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_AUDIOS];
  },
  getActiveAllLinks(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_LINKS];
  },
  getActiveAllVoices(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_VOICES];
  },
  getActiveAllGifs(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_GIFS];
  },
  getActiveAllGroupMembers(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_ALL_GROUP_MEMBERS];
  },
  getIsTyping(state) {
    let _state = Utils.parseState(state);
    return _state[CHAT_OPTION][TYPING][IS_TYPING];
  },
  getTypingUser(state) {
    let _state = Utils.parseState(state);
    return _state[CHAT_OPTION][TYPING][TYPING_USER];
  },
  getViewAllChatListing(state) {
    let _state = Utils.parseState(state);
    return _state[VIEW_ALL_CHAT_LISTING];
  },
  getViewAllChatListingTab(state) {
    let _state = Utils.parseState(state);
    return _state[VIEW_ALL_CHAT_LISTING_TAB];
  },
  getActiveChatId(state) {
    let _state = Utils.parseState(state);
    return _state[ACTIVE_CHAT_ID];
  },
  getRedirectToSettingPageObject(state) {
    return state[REDIRECT_TO_SETTING_PAGE]
  },
  getLeftBarListingActiveTab(state) {
    return state[LEFT_BAR_LISTING_ACTIVE_TAB]
  },
  getCreateEventModalCheck (state) {
    return state[CREATE_EVENT_MODAL_CHECK]
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
