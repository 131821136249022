<template>
  <div class="container">
    <div class="card genres-list annotation type-list">
      <div class="row no-gutters">
        <div class="col ">
          <div class="type">Type</div>
          <div class="title">Title</div>
          <div class="album">Album</div>
          <div class="time">Time</div>
          <div class="date js-genre-date">
            <div class="text">Date</div>
            <div class="svg-icon calendar">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg-calendar">
                <path class="c-line-1 c-fill-1"
                      d="M3.52899 1C3.41273 1 3.31851 1.09423 3.31851 1.21047V2.50292C2.95725 2.59752 2.68709 2.92569 2.68709 3.31522C2.68709 3.77768 3.06651 4.15711 3.52899 4.15711C3.99146 4.15711 4.37088 3.77768 4.37088 3.31522C4.37088 2.92569 4.10071 2.59752 3.73946 2.50292V1.21047C3.73946 1.09423 3.64523 1 3.52899 1ZM8.15942 1C8.04316 1 7.94895 1.09423 7.94895 1.21047V2.50292C7.58769 2.59752 7.31752 2.92569 7.31752 3.31522C7.31752 3.77768 7.69695 4.15711 8.15942 4.15711C8.6219 4.15711 9.00132 3.77768 9.00132 3.31522C9.00132 2.92569 8.73114 2.59752 8.36989 2.50292V1.21047C8.36989 1.09423 8.27567 1 8.15942 1ZM2.05567 1.63142C1.47464 1.63142 1 2.10606 1 2.68708V9.20521C1 9.78622 1.47464 10.2609 2.05567 10.2609H9.62616C10.2072 10.2609 10.6818 9.78622 10.6818 9.20521V2.68708C10.6818 2.10606 10.2072 1.63142 9.62616 1.63142H8.99802C8.88683 1.62984 8.78427 1.73069 8.78427 1.8419C8.78427 1.9531 8.88683 2.05394 8.99802 2.05237H9.62616C9.98085 2.05237 10.2609 2.33238 10.2609 2.68708V4.57806H1.42095V2.68708C1.42095 2.33238 1.70095 2.05237 2.05567 2.05237H2.68379C2.79499 2.05395 2.89756 1.9531 2.89756 1.8419C2.89756 1.73069 2.79499 1.62985 2.68379 1.63142H2.05567ZM4.36759 1.63142C4.25134 1.63142 4.15711 1.72565 4.15711 1.8419C4.15711 1.95814 4.25134 2.05237 4.36759 2.05237H7.31423C7.43047 2.05237 7.5247 1.95814 7.5247 1.8419C7.5247 1.72565 7.43047 1.63142 7.31423 1.63142H4.36759ZM3.52899 2.89427C3.76396 2.89427 3.94993 3.08023 3.94993 3.31522C3.94993 3.55019 3.76396 3.73617 3.52899 3.73617C3.29401 3.73617 3.10804 3.55019 3.10804 3.31522C3.10804 3.08023 3.29401 2.89427 3.52899 2.89427ZM8.15942 2.89427C8.39439 2.89427 8.58037 3.08023 8.58037 3.31522C8.58037 3.55019 8.39439 3.73617 8.15942 3.73617C7.92445 3.73617 7.73847 3.55019 7.73847 3.31522C7.73847 3.08023 7.92445 2.89427 8.15942 2.89427ZM1.42095 4.99901H10.2609V9.20521C10.2609 9.55991 9.98085 9.83992 9.62616 9.83992H2.05567C1.70095 9.83992 1.42095 9.55991 1.42095 9.20521V4.99901Z"
                      fill="#47505B" stroke="#47505B" stroke-width="0.4"></path>
              </svg>
            </div>
            <div class="svg-icon">
              <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg-arrow-down">
                <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
          </div>
          <div class="popularity">Popularity</div>
        </div>
      </div>
    </div>
    <div :class="['card genres-list type-list', , (showMore) ? 'open':'']">
      <div class="row no-gutters">
        <div class="col line-1">
          <div class="cover "><img
              src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/music-c13.png"
              alt="Generic placeholder image"></div>
          <div class="play-button js-play-button new-play-button"
               @click="$event.currentTarget.classList.toggle('active')">
            <div class="svg-icon">
              <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="play-type-1">
                <path class="c-fill-1 c-line-1"
                      d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      fill="white"></path>
                <path class="c-line-1 c-fill-1"
                      d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                      stroke="white" stroke-width="1.3"></path>
              </svg>
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
                <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                      stroke-linecap="round"></path>
              </svg>
            </div>
          </div>
          <div class="type-music">
            <div class="svg-icon">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="14.25" stroke="#47505B" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.4439 12.4618L20.6872 11.4132C21.3897 11.2613 22.0042 10.8392 22.3981 10.2382C22.7921 9.63711 22.9339 8.90525 22.7929 8.20056C22.652 7.49586 22.2395 6.87486 21.6447 6.4716C21.0498 6.06835 20.3203 5.91518 19.6135 6.04516L15.8264 6.80258C15.1936 6.92927 14.6244 7.27125 14.2153 7.77034C13.8063 8.26943 13.5829 8.89481 13.5829 9.54008V17.0547C12.8734 16.6451 12.0485 16.481 11.2363 16.5879C10.424 16.6948 9.66965 17.0668 9.09029 17.6461C8.51093 18.2254 8.1389 18.9796 8.03189 19.7919C7.92488 20.6042 8.08888 21.4291 8.49844 22.1387C8.90801 22.8482 9.54026 23.4028 10.2972 23.7165C11.054 24.0301 11.8933 24.0853 12.6847 23.8733C13.4761 23.6614 14.1755 23.1943 14.6744 22.5444C15.1732 21.8945 15.4437 21.0982 15.4439 20.2789V12.4628V12.4618ZM19.9791 7.86985L16.192 8.62727C15.9812 8.66942 15.7914 8.78329 15.655 8.94953C15.5187 9.11578 15.4441 9.32412 15.4439 9.53915V10.5646L20.3225 9.58847C20.5469 9.5393 20.743 9.40399 20.8687 9.21166C20.9944 9.01933 21.0395 8.78535 20.9943 8.56009C20.9492 8.33482 20.8175 8.13628 20.6274 8.00719C20.4374 7.8781 20.2042 7.82878 19.9782 7.86985H19.9791ZM13.5829 20.2779C13.5829 19.7844 13.3869 19.311 13.0379 18.962C12.6889 18.613 12.2155 18.417 11.722 18.417C11.2284 18.417 10.7551 18.613 10.4061 18.962C10.0571 19.311 9.86099 19.7844 9.86099 20.2779C9.86099 20.7715 10.0571 21.2449 10.4061 21.5939C10.7551 21.9429 11.2284 22.1389 11.722 22.1389C12.2155 22.1389 12.6889 21.9429 13.0379 21.5939C13.3869 21.2449 13.5829 20.7715 13.5829 20.2779Z"
                      fill="#47505B"/>
              </svg>

            </div>
          </div>
          <div class="waveform ">
            <div class="seek-wrapper">
              <div class="svg-icon">
                <svg width="180" height="25" viewBox="0 0 180 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="19.853" width="2.25491" height="5.14706" fill="#0075FF"></rect>
                  <rect x="30.0654" y="17.647" width="2.2549" height="7.35294" fill="#0075FF"></rect>
                  <rect x="3.00635" y="17.647" width="2.25491" height="7.35294" fill="#0075FF"></rect>
                  <rect x="33.0718" y="9.55859" width="2.2549" height="15.4412" fill="#0075FF"></rect>
                  <rect x="27.0586" y="19.853" width="2.25491" height="5.14706" fill="#0075FF"></rect>
                  <rect x="6.01318" y="14.7061" width="2.25489" height="10.2941" fill="#0075FF"></rect>
                  <rect x="18.0391" y="16.1763" width="2.2549" height="8.82353" fill="#0075FF"></rect>
                  <rect x="9.01953" y="11.0293" width="2.2549" height="13.9706" fill="#0075FF"></rect>
                  <rect x="21.0459" y="18.3823" width="2.2549" height="6.61765" fill="#0075FF"></rect>
                  <rect x="12.0259" y="16.9116" width="2.2549" height="8.08823" fill="#0075FF"></rect>
                  <rect x="24.0522" y="13.2354" width="2.2549" height="11.7647" fill="#0075FF"></rect>
                  <rect x="15.0327" y="12.5" width="2.25489" height="12.5" fill="#0075FF"></rect>
                  <rect x="36.0786" y="22.0586" width="2.25491" height="2.94118" fill="#0075FF"></rect>
                  <rect x="66.1436" width="2.25489" height="25" fill="#0075FF"></rect>
                  <rect x="39.085" y="21.3237" width="2.2549" height="3.67647" fill="#0075FF"></rect>
                  <rect x="69.1504" y="9.55859" width="2.25489" height="15.4412" fill="#0075FF"></rect>
                  <rect x="63.1372" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"></rect>
                  <rect x="42.0918" y="7.35303" width="2.2549" height="17.6471" fill="#0075FF"></rect>
                  <rect x="54.1177" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"></rect>
                  <rect x="45.0981" y="15.4414" width="2.25491" height="9.55882" fill="#0075FF"></rect>
                  <rect x="57.124" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"></rect>
                  <rect x="48.1045" y="20.5884" width="2.2549" height="4.41176" fill="#0075FF"></rect>
                  <rect x="60.1309" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"></rect>
                  <rect x="51.1113" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"></rect>
                  <rect x="72.1567" width="2.25489" height="25" fill="#0075FF"></rect>
                  <rect x="102.223" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="75.1631" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"></rect>
                  <rect x="105.229" y="9.55859" width="2.25489" height="15.4412" fill="#E6EAF0"></rect>
                  <rect x="99.2153" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"></rect>
                  <rect x="78.1699" y="8.08838" width="2.25487" height="16.9118" fill="#0075FF"></rect>
                  <rect x="90.1958" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"></rect>
                  <rect x="81.1763" y="11.0293" width="2.25489" height="13.9706" fill="#0075FF"></rect>
                  <rect x="93.2026" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"></rect>
                  <rect x="84.1831" y="10.2939" width="2.25491" height="14.7059" fill="#0075FF"></rect>
                  <rect x="96.209" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"></rect>
                  <rect x="87.1895" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"></rect>
                  <rect x="108.235" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="138.301" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="111.242" y="4.41162" width="2.25491" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="141.307" y="9.55859" width="2.25489" height="15.4412" fill="#E6EAF0"></rect>
                  <rect x="135.294" y="4.41162" width="2.25491" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="114.249" y="8.08838" width="2.25489" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="126.274" y="8.08838" width="2.25491" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="117.255" y="11.0293" width="2.25491" height="13.9706" fill="#E6EAF0"></rect>
                  <rect x="129.281" y="18.3823" width="2.25491" height="6.61765" fill="#E6EAF0"></rect>
                  <rect x="120.262" y="10.2939" width="2.25491" height="14.7059" fill="#E6EAF0"></rect>
                  <rect x="132.288" y="13.2354" width="2.25489" height="11.7647" fill="#E6EAF0"></rect>
                  <rect x="123.268" y="4.41162" width="2.25489" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="144.313" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="174.379" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="147.32" y="4.41162" width="2.25489" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="177.386" y="9.55859" width="2.25491" height="15.4412" fill="#E6EAF0"></rect>
                  <rect x="171.373" y="4.41162" width="2.25491" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="150.327" y="8.08838" width="2.25487" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="162.353" y="8.08838" width="2.25489" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="153.333" y="11.0293" width="2.25489" height="13.9706" fill="#E6EAF0"></rect>
                  <rect x="165.359" y="18.3823" width="2.25491" height="6.61765" fill="#E6EAF0"></rect>
                  <rect x="156.34" y="10.2939" width="2.25489" height="14.7059" fill="#E6EAF0"></rect>
                  <rect x="168.366" y="13.2354" width="2.25489" height="11.7647" fill="#E6EAF0"></rect>
                  <rect x="159.346" y="4.41162" width="2.25489" height="20.5882" fill="#E6EAF0"></rect>
                  <rect y="19.853" width="2.25491" height="5.14706" fill="#0075FF"></rect>
                  <rect x="30.0654" y="17.647" width="2.2549" height="7.35294" fill="#0075FF"></rect>
                  <rect x="3.00635" y="17.647" width="2.25491" height="7.35294" fill="#0075FF"></rect>
                  <rect x="33.0718" y="9.55859" width="2.2549" height="15.4412" fill="#0075FF"></rect>
                  <rect x="27.0586" y="19.853" width="2.25491" height="5.14706" fill="#0075FF"></rect>
                  <rect x="6.01318" y="14.7061" width="2.25489" height="10.2941" fill="#0075FF"></rect>
                  <rect x="18.0391" y="16.1763" width="2.2549" height="8.82353" fill="#0075FF"></rect>
                  <rect x="9.01953" y="11.0293" width="2.2549" height="13.9706" fill="#0075FF"></rect>
                  <rect x="21.0459" y="18.3823" width="2.2549" height="6.61765" fill="#0075FF"></rect>
                  <rect x="12.0259" y="16.9116" width="2.2549" height="8.08823" fill="#0075FF"></rect>
                  <rect x="24.0522" y="13.2354" width="2.2549" height="11.7647" fill="#0075FF"></rect>
                  <rect x="15.0327" y="12.5" width="2.25489" height="12.5" fill="#0075FF"></rect>
                  <rect x="36.0786" y="22.0586" width="2.25491" height="2.94118" fill="#0075FF"></rect>
                  <rect x="66.1436" width="2.25489" height="25" fill="#0075FF"></rect>
                  <rect x="39.085" y="21.3237" width="2.2549" height="3.67647" fill="#0075FF"></rect>
                  <rect x="69.1504" y="9.55859" width="2.25489" height="15.4412" fill="#0075FF"></rect>
                  <rect x="63.1372" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"></rect>
                  <rect x="42.0918" y="7.35303" width="2.2549" height="17.6471" fill="#0075FF"></rect>
                  <rect x="54.1177" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"></rect>
                  <rect x="45.0981" y="15.4414" width="2.25491" height="9.55882" fill="#0075FF"></rect>
                  <rect x="57.124" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"></rect>
                  <rect x="48.1045" y="20.5884" width="2.2549" height="4.41176" fill="#0075FF"></rect>
                  <rect x="60.1309" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"></rect>
                  <rect x="51.1113" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"></rect>
                  <rect x="72.1567" width="2.25489" height="25" fill="#0075FF"></rect>
                  <rect x="102.223" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="75.1631" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"></rect>
                  <rect x="105.229" y="9.55859" width="2.25489" height="15.4412" fill="#E6EAF0"></rect>
                  <rect x="99.2153" y="4.41162" width="2.25491" height="20.5882" fill="#0075FF"></rect>
                  <rect x="78.1699" y="8.08838" width="2.25487" height="16.9118" fill="#0075FF"></rect>
                  <rect x="90.1958" y="8.08838" width="2.25491" height="16.9118" fill="#0075FF"></rect>
                  <rect x="81.1763" y="11.0293" width="2.25489" height="13.9706" fill="#0075FF"></rect>
                  <rect x="93.2026" y="18.3823" width="2.25491" height="6.61765" fill="#0075FF"></rect>
                  <rect x="84.1831" y="10.2939" width="2.25491" height="14.7059" fill="#0075FF"></rect>
                  <rect x="96.209" y="13.2354" width="2.25489" height="11.7647" fill="#0075FF"></rect>
                  <rect x="87.1895" y="4.41162" width="2.25489" height="20.5882" fill="#0075FF"></rect>
                  <rect x="108.235" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="138.301" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="111.242" y="4.41162" width="2.25491" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="141.307" y="9.55859" width="2.25489" height="15.4412" fill="#E6EAF0"></rect>
                  <rect x="135.294" y="4.41162" width="2.25491" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="114.249" y="8.08838" width="2.25489" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="126.274" y="8.08838" width="2.25491" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="117.255" y="11.0293" width="2.25491" height="13.9706" fill="#E6EAF0"></rect>
                  <rect x="129.281" y="18.3823" width="2.25491" height="6.61765" fill="#E6EAF0"></rect>
                  <rect x="120.262" y="10.2939" width="2.25491" height="14.7059" fill="#E6EAF0"></rect>
                  <rect x="132.288" y="13.2354" width="2.25489" height="11.7647" fill="#E6EAF0"></rect>
                  <rect x="123.268" y="4.41162" width="2.25489" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="144.313" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="174.379" width="2.25491" height="25" fill="#E6EAF0"></rect>
                  <rect x="147.32" y="4.41162" width="2.25489" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="177.386" y="9.55859" width="2.25491" height="15.4412" fill="#E6EAF0"></rect>
                  <rect x="171.373" y="4.41162" width="2.25491" height="20.5882" fill="#E6EAF0"></rect>
                  <rect x="150.327" y="8.08838" width="2.25487" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="162.353" y="8.08838" width="2.25489" height="16.9118" fill="#E6EAF0"></rect>
                  <rect x="153.333" y="11.0293" width="2.25489" height="13.9706" fill="#E6EAF0"></rect>
                  <rect x="165.359" y="18.3823" width="2.25491" height="6.61765" fill="#E6EAF0"></rect>
                  <rect x="156.34" y="10.2939" width="2.25489" height="14.7059" fill="#E6EAF0"></rect>
                  <rect x="168.366" y="13.2354" width="2.25489" height="11.7647" fill="#E6EAF0"></rect>
                  <rect x="159.346" y="4.41162" width="2.25489" height="20.5882" fill="#E6EAF0"></rect>
                </svg>
              </div>
            </div>
          </div>
          <div class="description">
            <div class="artist"><a href="#">Artist Name</a></div>
            <div class="track"><a href="#">Song Name</a></div>
          </div>
          <div class="album">
            <div class="album">NA</div>
          </div>
          <div class="time">
            0:00
          </div>
          <div class="date">
            2 days ago
          </div>
          <div class="popularity">
            <div class="empty">
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
            </div>
            <div class="full">
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
              <div class="column"></div>
            </div>
            <div class="count"> 000K</div>
          </div>
          <div class="other d-flex ">
            <div class="actions d-flex align-items-center">

              <div class="btn btn-icon-border text shop-cart" style="opacity:0;">
                <div class="svg-icon">
                  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-shop">
                    <path class="c-line-1" d="M1 12.144L3.05859 1H12.9414L15.001 12.144H1Z" stroke="white"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path class="c-line-1"
                          d="M9.97559 3.76123V5.04023C9.90323 5.5065 9.653 5.9266 9.27745 6.21227C8.9019 6.49794 8.43026 6.62696 7.96159 6.57223C7.49292 6.62696 7.02127 6.49794 6.64572 6.21227C6.27017 5.9266 6.01994 5.5065 5.94759 5.04023V3.76123"
                          stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div>$00</div>
              </div>
              <div class="wishlist js-wishlist">
                <div class="svg-icon ">
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.7545 2.2374C15.3623 1.8451 14.8966 1.53391 14.3841 1.32159C13.8716 1.10928 13.3223 1 12.7675 1C12.2128 1 11.6635 1.10928 11.1509 1.32159C10.6384 1.53391 10.1728 1.8451 9.78052 2.2374L8.96599 3.05193L8.15145 2.2374C7.35387 1.4716 6.28787 1.04896 5.18222 1.0602C4.07657 1.07144 3.01937 1.51565 2.23752 2.2975C1.45566 3.07936 1.01145 4.13655 1.00022 5.2422C0.988983 6.34785 1.41162 7.41385 2.17742 8.21144L2.99195 9.02597L8.96599 15L14.94 9.02597L15.7545 8.21144C16.1469 7.8192 16.458 7.35352 16.6704 6.84101C16.8827 6.32849 16.992 5.77917 16.992 5.22442C16.992 4.66967 16.8827 4.12035 16.6704 3.60783C16.458 3.09531 16.1469 2.62964 15.7545 2.2374V2.2374Z"
                        stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"
                        class="c-line-1 c-fill-1"></path>
                  </svg>
                </div>
              </div>

              <div class="more">
                <div class="dropdown vertical more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false" data-display="static">
                  <div class="svg-icon more-position">
                    <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="svg-more-hor">
                      <path class="c-fill-1"
                            d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                            fill="white"></path>
                      <path class="c-fill-1"
                            d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                            fill="white"></path>
                      <path class="c-fill-1"
                            d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                            fill="white"></path>
                    </svg>
                    <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="svg-more">
                      <path
                          d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                          class="c-fill-1"></path>
                      <path
                          d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                          class="c-fill-1"></path>
                      <path
                          d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                          class="c-fill-1"></path>
                    </svg>
                  </div>
                  <ListOption/>
                </div>
              </div>
              <div class="extend js-extend" v-on:click="showMore = !showMore">
                <div class="svg-icon">
                  <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-arrow-down">
                    <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters line-2 d-flex align-items-center justify-content-end">
        <div class="">
          <div class="btn-group-social left-button song-remix-page">
            <button class="btn btn-information text">
              <div class="svg-icon">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M14.2317 1.76782L14.2318 1.76791C14.5546 2.09052 14.8107 2.47351 14.9854 2.89499C15.1601 3.31648 15.25 3.76822 15.25 4.22442C15.25 4.68062 15.1601 5.13236 14.9854 5.55384C14.8107 5.97533 14.5546 6.35832 14.2318 6.68093L14.2318 6.68097L13.4168 7.4955L7.97 12.9396L2.52315 7.4955L1.71387 6.68663C1.08683 6.03118 0.74096 5.15668 0.75018 4.24983C0.759424 3.34055 1.12492 2.47106 1.76834 1.82796C2.41177 1.18485 3.28187 0.819404 4.19194 0.810161C5.09959 0.800942 5.9747 1.14677 6.63051 1.77352L7.4398 2.5824C7.73266 2.87511 8.20733 2.87511 8.50019 2.5824L9.31513 1.76787L9.31517 1.76782C9.63795 1.44516 10.0212 1.18919 10.443 1.01454C10.8648 0.839895 11.3169 0.75 11.7735 0.75C12.23 0.75 12.6821 0.839895 13.1039 1.01454C13.5257 1.18919 13.909 1.44516 14.2317 1.76782Z"
                      stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                      class="svg-icon-text"></path>
                </svg>
              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information text">
              <div class="svg-icon">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M16 10.7938C15.9707 11.2566 15.7792 11.6876 15.4671 11.9932C15.155 12.2989 14.7477 12.4543 14.3336 12.4258H4.34908L1 15V2.63532C1.0293 2.1726 1.22081 1.74155 1.53288 1.43592C1.84495 1.13029 2.25233 0.97482 2.66639 1.00333H14.3273C14.7414 0.97482 15.1488 1.13029 15.4609 1.43592C15.7729 1.74155 15.9644 2.1726 15.9937 2.63532L16 10.7938Z"
                      stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                      class="svg-icon-text"></path>
                </svg>
              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information text">
              <div class="svg-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9.16667L1 3.33306L15 3.33306L10.9989 1" stroke="#071526" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" class="svg-icon-text"></path>
                  <path d="M15 8L15 12.9548L1 12.9548L5.81381 15" stroke="#071526" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" class="svg-icon-text"></path>
                </svg>
              </div>
              <span>000k</span>
            </button>
            <button class="btn btn-information">
              <div class="svg-icon">
                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1 12.4358C1 12.4358 2.34885 6.17675 10.4363 5.97632C10.5603 4.31967 10.5603 2.65665 10.4363 1L18 7.83691L10.4363 15V9.74725C10.4363 9.74725 6.00177 8.4783 1 12.4358Z"
                      stroke="#071526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                      class="c-line-1"></path>
                </svg>
              </div>
            </button>
            <div class="more_btn dropright">
              <button class="btn btn-information dropdown  more-dropdown" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false" data-display="static">
                <div class="svg-icon-plus  more-position">

                  <svg version="1.1" id="Capa_1"
                       width="16.562px" height="16.562px" viewBox="0 0 93.562 93.562"
                       style="enable-background:new 0 0 93.562 93.562;"
                       xml:space="preserve">
                       <g>
                         <path class="c-line-1-fill" d="M87.952,41.17l-36.386,0.11V5.61c0-3.108-2.502-5.61-5.61-5.61c-3.107,0-5.61,2.502-5.61,5.61l0.11,35.561H5.61
                           c-3.108,0-5.61,2.502-5.61,5.61c0,3.107,2.502,5.609,5.61,5.609h34.791v35.562c0,3.106,2.502,5.61,5.61,5.61
                           c3.108,0,5.61-2.504,5.61-5.61V52.391h36.331c3.108,0,5.61-2.504,5.61-5.61C93.562,43.672,91.032,41.17,87.952,41.17z"/>
                       </g>

                       </svg>
                </div>
              </button>
              <ListOption/>
            </div>
          </div>
        </div>
        <div class="streaming-mains">
          <div class="btn-group-social right-side justify-content-end">
            <div class="add-queue-btn">
              <div class="svg-icon">
                <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0657 6.13184H18.0657" stroke="#071526" stroke-width="1.5"/>
                  <path d="M10.0657 2.33203H17.9657" stroke="#071526" stroke-width="1.5"/>
                  <path d="M10.0657 9.93213H18.0657" stroke="#071526" stroke-width="1.5"/>
                  <path
                      d="M1.70458 11.375C1.65029 11.3818 1.59536 11.3763 1.54345 11.359C1.49154 11.3417 1.44417 11.313 1.40478 11.275C1.29689 11.212 1.2048 11.1252 1.13549 11.0212C1.06618 10.9173 1.02132 10.7989 1.00463 10.675V1.67502C0.987431 1.54354 1.01848 1.41028 1.09203 1.29995C1.16559 1.18962 1.2768 1.10971 1.40478 1.07502C1.51484 1.02557 1.63398 1 1.75463 1C1.87529 1 1.99467 1.02557 2.10473 1.07502L9.00463 5.57502C9.15331 5.68929 9.25545 5.8536 9.29223 6.03749C9.32901 6.22137 9.29787 6.41234 9.20458 6.57502L9.00463 6.77502L2.10473 11.275C1.96377 11.2601 1.82192 11.2955 1.70458 11.375"
                      stroke="#071526" stroke-width="1.5"/>
                </svg>
              </div>
              <div class="price">Add to queue</div>
            </div>

          </div>
          <div class="btn btn-play-count ">
            <div class="svg-icon">
              <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="small-play-svg">
                <path
                    d="M0.539 7.90376C0.455885 7.90163 0.37427 7.88114 0.3 7.84376C0.215195 7.79414 0.143631 7.72477 0.0913988 7.64154C0.0391664 7.55832 0.00781083 7.46371 0 7.36576L0 0.543765C0.00417511 0.448431 0.0306947 0.355412 0.0774202 0.27221C0.124146 0.189007 0.18977 0.117949 0.269 0.0647649C0.352295 0.0176647 0.447355 -0.00454485 0.542897 0.000772843C0.638438 0.00609054 0.730447 0.0387122 0.808 0.0947649L6.044 3.50576C6.11826 3.55502 6.17917 3.62189 6.22131 3.70041C6.26345 3.77893 6.2855 3.86665 6.2855 3.95577C6.2855 4.04488 6.26345 4.1326 6.22131 4.21112C6.17917 4.28964 6.11826 4.35651 6.044 4.40576L0.808 7.81476C0.729362 7.87101 0.635653 7.90136 0.539 7.90376Z"
                    fill="#8B949F" class="c-fill-1"></path>
              </svg>
            </div>
            <span>32.8k</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from './ListOption'

export default {
  data() {
    return {
      showSubMenu: 'none',
      showMore: false,
    }
  },

  components: {
    ListOption
  },
  computed: {},
  methods: {}
}
</script>
