<template>
  <div>
    <div class="heading d-flex align-items-center">
      <button class="back-btn" @click="backToPreviousState">
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9L1 5L5 1"
            stroke="#D1433A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="title">Search Info</div>
    </div>
    <div class="body">
      <!-- START MESSAGE CARD -->
      <!-- <perfect-scrollbar> -->
      <perfect-scrollbar>
        <div v-if="getSearchMessageList.length > 0">
          <div v-for="(message, index) in getSearchMessageList" :key="index">
            <div class="user-card">
              <div class="cover-image">
                <div class="img-wrapper">
                  <img
                    :src="
                      message.message &&
                      message.message.user &&
                      message.message.user.image
                        ? message.message.user.image
                        : ''
                    "
                    alt=""
                  />
                </div>
                <div
                  v-if="
                    message.message &&
                    message.message.user &&
                    message.message.user.online
                  "
                  class="online-status"
                ></div>
              </div>
              <div class="content-block">
                <div class="title">
                  {{
                    message.message &&
                    message.message.user &&
                    message.message.user.name
                      ? message.message.user.name
                      : "User Name"
                  }}
                </div>
                <div class="subtitle">
                  {{
                    message.message ? message.message.text : "Searched Message"
                  }}
                  <!--            <a href="">project</a>..-->
                </div>
                <div class="last-active">
                  {{
                    convertDateIntoHumanReadableForm(message.message.created_at)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-search-result">
          <div class="show-result">
            <div class="search-icon">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.0329 38.79C47.664 33.8351 49.2904 27.6918 48.5866 21.5893C47.8828 15.4868 44.9007 9.87503 40.2371 5.87675C35.5734 1.87846 29.572 -0.211476 23.4336 0.0250483C17.2951 0.261573 11.4724 2.80712 7.13019 7.15241C2.78801 11.4977 0.246627 17.3223 0.0144948 23.4609C-0.217637 29.5995 1.8766 35.5994 5.87822 40.2602C9.87984 44.921 15.4937 47.899 21.5968 48.5985C27.6998 49.2979 33.8419 47.6672 38.7942 44.0325H38.7904C38.9029 44.1825 39.0229 44.325 39.1579 44.4638L53.5954 58.9013C54.2986 59.6049 55.2525 60.0004 56.2472 60.0008C57.242 60.0011 58.1962 59.6063 58.8998 58.9031C59.6035 58.2 59.999 57.2461 59.9993 56.2513C59.9997 55.2566 59.6048 54.3024 58.9017 53.5988L44.4642 39.1613C44.3301 39.0255 44.186 38.9002 44.0329 38.7863V38.79ZM45.0004 24.375C45.0004 27.0835 44.467 29.7655 43.4305 32.2679C42.3939 34.7702 40.8747 37.0439 38.9595 38.9591C37.0443 40.8743 34.7706 42.3935 32.2683 43.43C29.7659 44.4665 27.084 45 24.3754 45C21.6669 45 18.9849 44.4665 16.4826 43.43C13.9803 42.3935 11.7066 40.8743 9.79136 38.9591C7.87615 37.0439 6.35693 34.7702 5.32042 32.2679C4.28392 29.7655 3.75044 27.0835 3.75044 24.375C3.75044 18.9049 5.92342 13.6589 9.79136 9.79093C13.6593 5.923 18.9053 3.75001 24.3754 3.75001C29.8455 3.75001 35.0916 5.923 38.9595 9.79093C42.8275 13.6589 45.0004 18.9049 45.0004 24.375V24.375Z"
                  fill="#47505B"
                />
              </svg>
            </div>
            <div class="no-result">No results found</div>
            <div class="no-result-subtext">
              Search again using different keywords
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <!-- END MESSAGE CARD -->
      <!-- </perfect-scrollbar> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageSearchComponent",
  computed: {
    getSearchMessageList() {
      return this.$store.getters.getSearchedMessageList;
    },
  },
  methods: {
    backToPreviousState() {
      this.$store.commit("SET_ACTIVE_FULL_CHAT_PREVIOUS_TYPE", "");
    },
  },
};
</script>
<style scoped lang="scss">
.heading {
  width: inherit;
  height: 79px;
  padding: 0 20px 0 20px;

  .back-btn {
    box-sizing: border-box;
    background: #f5f7f9;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    border: none;

    svg {
      margin: auto;
    }
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #071526;
    margin-left: 18px;
  }
}

.body {
  padding: 0 20px 0 20px;
  .ps__thumb-y {
    background-color: #e6eaf0 !important;
    overflow-y: scroll;
  }
  .ps {
    width: 320px;
    height: 770px;

    .ps__rail-y {
      background-color: transparent !important;
      opacity: 1 !important;
      .ps__thumb-y {
        background-color: #e6eaf0 !important;
        width: 7px !important;
        opacity: 1 !important;
      }
      &:hover {
        background-color: transparent !important;
        opacity: 1 !important;
        .ps__thumb-y {
          background-color: #e6eaf0 !important;
          width: 7px !important;
          opacity: 1 !important;
        }
      }
    }
    .ps__rail-x {
      display: none !important;
    }
    .ps__rail-y:hover > .ps__thumb-y {
      background-color: #e6eaf0 !important;
      width: 7px !important;
      opacity: 1 !important;
    }
  }
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: red !important;
    opacity: 1 !important;
  }

  .user-card {
    width: 308px !important;
  }

  .user-card {
    height: 100px;
    width: 310px;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: start;
    padding: 15px 17px 6px 19px !important;
    margin-bottom: 10px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    &:hover {
      border-color: #D1433A;
    }

    .cover-image {
      margin-top: 4px;
      width: 40px;
      height: 40px;
      position: relative;

      .img-wrapper {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 100%;

        img {
          width: 100%;
        }
      }

      .online-status {
        width: 15px;
        height: 15px;
        background: #1cec9e;
        border: 2px solid #ffffff;
        border-radius: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .content-block {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .title {
        font-family: "HelveticaNeueNormal";
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #071526;
        margin-bottom: 4px;
      }

      .subtitle {
        font-family: "HelveticaNeueNormal";
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #47505b;
        margin-bottom: 7px;
        height: 32px;
        overflow: hidden;
        padding-right: 10px;
        // position: relative;
        // &:after {
        //   content: "..";
        //   position: absolute;
        //   right: 0;
        //   bottom: 0;
        // }

        a {
          color: #D1433A;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }

      .last-active {
        font-family: "HelveticaNeueNormal";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8b949f;
        text-align: right;
        position: absolute;
        right: 20px;
        bottom: 7px;
      }
    }
  }

  .no-search-result {
    width: 310px;
    height: 770px;
    background: #f5f7f9;
    border-radius: 5px;
    display: flex;

    .show-result {
      margin: auto;
      width: 212px;

      .search-icon {
        display: flex;

        svg {
          margin: auto;
        }
      }

      .no-result {
        margin-top: 24px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #071526;
      }

      .no-result-subtext {
        margin-top: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #47505b;
      }
    }
  }
}
</style>
<style scoped>
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4ccd6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4ccd6;
}
</style>
