<template>
  <div class="box-sections">
    <div class="widget-follow">
      <div class="widget-title">
        About
        <div class="link-more near_arrow without-slider in-title cursor-pointer">
          <a v-on:click="changeTab">SEE ALL
            <span class="sl-arrow">
              <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo=">
            </span>
          </a>
        </div>
      </div>
      <div class="about-more">
        <div class="about-more-details">
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.8 8.7998H0C0.198991 10.78 1.12892 12.6149 2.60819 13.9463C4.08747 15.2776 6.00987 16.0098 8 15.9998C5.952 15.9998 4.944 12.4078 4.8 8.7998Z"
                fill="#8B949F"></path>
              <path
                d="M0 7.2001H4.8C4.928 3.5921 5.936 0.000100562 8 0.000100562C6.00987 -0.00987273 4.08747 0.7223 2.60819 2.05365C1.12892 3.38499 0.198991 5.21991 0 7.2001Z"
                fill="#8B949F"></path>
              <path
                d="M7.99844 14.3998C8.38244 14.3998 9.44644 12.4798 9.59844 8.7998H6.39844C6.55044 12.4798 7.61444 14.3998 7.99844 14.3998Z"
                fill="#8B949F"></path>
              <path
                d="M7.99844 1.59961C7.61444 1.59961 6.55044 3.51961 6.39844 7.19961H9.59844C9.44644 3.51961 8.38244 1.59961 7.99844 1.59961Z"
                fill="#8B949F"></path>
              <path
                d="M8 15.9998C9.99013 16.0098 11.9125 15.2776 13.3918 13.9463C14.8711 12.6149 15.801 10.78 16 8.7998H11.2C11.056 12.4078 10.048 15.9998 8 15.9998Z"
                fill="#8B949F"></path>
              <path
                d="M8 0.000100562C10.048 0.000100562 11.056 3.5921 11.2 7.2001H16C15.801 5.21991 14.8711 3.38499 13.3918 2.05365C11.9125 0.7223 9.99013 -0.00987273 8 0.000100562Z"
                fill="#8B949F"></path>
            </svg>
          </span>
          <p class="artist-p-about">{{ currentPageWebsiteLink }}</p>
        </div>
        <div class="about-more-details">
          <span>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.74413 7.46304L6.58316 1.20694C6.71911 1.06128 6.94906 1.05833 7.0887 1.20046L13.2497 7.47144C13.314 7.53689 13.35 7.62498 13.35 7.71673V14C13.35 14.1933 13.1933 14.35 13 14.35H9.97436C9.78106 14.35 9.62436 14.1933 9.62436 14V11.3846C9.62436 10.4733 8.88563 9.73462 7.97436 9.73462H6.38462C5.47335 9.73462 4.73462 10.4733 4.73462 11.3846V14C4.73462 14.1933 4.57792 14.35 4.38461 14.35H1C0.8067 14.35 0.65 14.1933 0.65 14V7.70186C0.65 7.6132 0.683641 7.52785 0.74413 7.46304Z"
                fill="#8B949F" stroke="#8B949F" stroke-width="1.3" />
            </svg>
          </span>
          <p class="artist-p-about">Lives in </p>
          <p class="pl-1"><span>{{ currentPageLocation }}</span></p>
        </div>
        <div class="about-more-details">
          <span>
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 1.575C0 0.7056 0.7056 0 1.575 0H6.0489C6.5709 0 7.0569 0.2574 7.3512 0.6885L8.6148 2.5452C8.6354 2.57567 8.66315 2.60063 8.69563 2.61789C8.72811 2.63515 8.76432 2.64418 8.8011 2.6442H16.425C17.2944 2.6442 18 3.3489 18 4.2192V14.625C18 15.0427 17.8341 15.4433 17.5387 15.7387C17.2433 16.0341 16.8427 16.2 16.425 16.2H1.575C1.15728 16.2 0.756677 16.0341 0.461307 15.7387C0.165937 15.4433 0 15.0427 0 14.625V1.575Z"
                    fill="#8B949F" />
            </svg>
          </span>
          <p class="artist-p-about">{{ currentPageCategory + "/ " + currentPageSubCategory }}</p>
        </div>
        <div class="about-more-details">
          <span>
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 12C7 12 6 12 6 11C6 10 7 7 11 7C15 7 16 10 16 11C16 12 15 12 15 12H7ZM11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6Z"
                fill="#8B949F" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.216 12.0004C5.06776 11.6882 4.99382 11.346 5 11.0004C5 9.64543 5.68 8.25043 6.936 7.28043C6.30909 7.08726 5.65595 6.9928 5 7.00043C1 7.00043 0 10.0004 0 11.0004C0 12.0004 1 12.0004 1 12.0004H5.216Z"
                    fill="#8B949F" />
              <path
                d="M4.5 6C5.16304 6 5.79893 5.73661 6.26777 5.26777C6.73661 4.79893 7 4.16304 7 3.5C7 2.83696 6.73661 2.20107 6.26777 1.73223C5.79893 1.26339 5.16304 1 4.5 1C3.83696 1 3.20107 1.26339 2.73223 1.73223C2.26339 2.20107 2 2.83696 2 3.5C2 4.16304 2.26339 4.79893 2.73223 5.26777C3.20107 5.73661 3.83696 6 4.5 6Z"
                fill="#8B949F" />
            </svg>
          </span>
          <p class="artist-p-about bold-word cursor-pointer" data-toggle="modal"
             data-target="#create-group"
             data-backdrop="false">Invite friends <span class="ml-1">to like this page</span></p>

        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    currentPageLocation() {
      return (this.currentPage && this.currentPage.address) ? " " + this.currentPage.address : "";
    },
    currentPageCity() {
      return (this.currentPage && this.currentPage.city) ? this.currentPage.city : "";
    },
    currentPageCountry() {
      return (this.currentPage && this.currentPage.country && this.currentPage.country.name) ? this.currentPage.country.name : "";
    },
    currentPageGenres() {
      return (this.currentPage && this.currentPage.genres && this.currentPage.genres.length > 0) ? this.currentPage.genres : [];
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    currentPageWebsiteLink() {
      return (this.currentPage && this.currentPage.website) ? this.currentPage.website : "";
    },
    currentPageCategory() {
      return (this.currentPage && (this.currentPage.page_category && this.currentPage.page_category.name)) ? this.currentPage.page_category.name : "";
    },
    currentPageSubCategory() {
      return (this.currentPage && (this.currentPage.page_sub_category && this.currentPage.page_sub_category.name)) ? this.currentPage.page_sub_category.name : "";
    },
    currentUserIsAdmin() {
      return !!(this.currentPage && this.currentPage.current_user && this.currentPage.current_user === "admin");
    }
  },
  methods: {
    changeTab: function() {
      this.$parent.change_active_tab("more");
    }
  }
};
</script>
<style>
.bold-word {
  font-weight: bold !important;
}

.link-color {
  color: #0053B5 !important;
}
</style>
