<template>
  <div class="wrapAudience">
    <div class="titleDetalsRightPart">
      <h3>Audience</h3>
      <div class="showAll">
        Show All
        <span>
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.01562 1L6.1515 5.173L1.01562 9.713"
              stroke="#47505B"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
    <div v-for="item in dataAudience" :key="item.id" class="user">
      <div class="userContent">
        <div class="imgDiv"><img src="" alt="" /></div>
        <div class="userInfo">
          {{ item.userName }}
          <p>Is Interested 6 hours ago</p>
        </div>
      </div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="28.5"
          height="28.5"
          rx="3.25"
          stroke="#D1433A"
          stroke-width="1.5"
        />
        <path
          d="M19.0345 21.1555V19.7762C19.0345 19.0446 18.7439 18.3429 18.2265 17.8256C17.7092 17.3082 17.0075 17.0176 16.2759 17.0176H10.7586C10.027 17.0176 9.32533 17.3082 8.80798 17.8256C8.29064 18.3429 8 19.0446 8 19.7762V21.1555"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5164 14.2585C15.04 14.2585 16.2751 13.0234 16.2751 11.4998C16.2751 9.97629 15.04 8.74121 13.5164 8.74121C11.9929 8.74121 10.7578 9.97629 10.7578 11.4998C10.7578 13.0234 11.9929 14.2585 13.5164 14.2585Z"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.3906 11.4736V15.9125"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.25 13.6934H23.6889"
          stroke="#D1433A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataAudience: [
        {
          img: "",
          id: "1",
          userName: "User Name",
          infoHoursAgo: "Is Interested 6 hours ago",
        },
        {
          img: "",
          id: "2",
          userName: "User Name",
          infoHoursAgo: "Is Going 6 hours ago",
        },
        {
          img: "",
          id: "3",
          userName: "User Name",
          infoHoursAgo: "Is Interested 6 hours ago",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapAudience {  
  .user {
    display: flex;
    justify-content: space-between;
    .userContent {
      display: flex;
      .imgDiv {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #c3c3c3;
        margin-right: 10px;
      }
      .userInfo {
        font-size: 13px;
        color: #071526;
        font-family: HelveticaNeueBold;
        p {
          font-family: HelveticaNeueNormal;
          color: #47505b;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
