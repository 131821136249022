<template>
  <div class="Signup_pop_overlay">
    <div
        class="modal fade login-type show"
        id="reg-user-info-step2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        style="display: block; padding-right: 15px;"
        aria-modal="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
              class="close-button"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="closeRegisterModal"
          >
            <div class="svg-icon">
              <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  class="svg-close"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M11.6111 1.8703L1.87048 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
                <path
                    d="M1.87012 1.8703L11.6107 11.6109"
                    class="c-line-1"
                    stroke-width="1.3"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <div class="modal-header">
            <img
                :src="require(`@/assets/icons/desgin-guide/layout/new_logo.svg`)"
            />
          </div>
          <div class="modal-body term_signup_body">
            <div class="body-title Font_semibold m_b_22">
              <a class="back-arrow" v-on:click="goBack">
                <div class="svg-icon">
                  <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        class="c-line-1"
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M15 10L10 15L15 20"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        class="c-line-1"
                        d="M20 15H10"
                        stroke="#C4CCD6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </a>
              2 / 2. User Settings
            </div>
            <p class="error_msg text-center error_msg_red" v-if="message">{{ message }}</p>
            <div class="input-wrap no-icons m-b-17">
              <div class="input-title Font_semibold">Username</div>
              <input
                  type="text"
                  placeholder="Username"
                  @change="onChange"
                  @keypress="handleUserNameChange"
                  autocomplete="off"
                  :value="username"
                  name="signup_form_username"/>
              <label class="error_msg error_msg_red"
                     v-if="_handleErrorMessage('username')">{{ _handleErrorMessage("username") }}</label>
            </div>

            <div class="input-wrap no-icons m-b-17">
              <div class="input-title Font_semibold">Password</div>
              <input
                  type="password"
                  placeholder="Password"
                  :value="password"
                  @change="onChange"
                  name="signup_form_password"/>
              <label class="error_msg error_msg_red"
                     v-if="_handleErrorMessage('password')">{{ _handleErrorMessage("password") }}</label>
            </div>

            <div class="input-wrap no-icons m-b-17">
              <div class="input-title Font_semibold">Repeat Password</div>
              <input
                  type="password"
                  placeholder="Password"
                  :value="confirm_password"
                  @change="onChange"
                  name="signup_form_confirm_password"/>
              <label class="error_msg error_msg_red"
                     v-if="_handleErrorMessage('confirm_password')">{{ _handleErrorMessage("confirm_password") }}</label>
            </div>

            <div class="checkbox-wrap">
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="terms"
                    @change="onChange"
                    :checked="i_agree"
                    name="signup_form_i_aggree_terms_and_privacy_policy"/>
                <label
                    class="custom-control-label check register_checkbox-shadow"
                    for="terms"
                >I agree to
                  <a href="">Terms and Conditions of Use</a></label
                >
              </div>
            </div>
            <label class="invalid-input"
                   v-if="_handleErrorMessage('i_agree')">{{ _handleErrorMessage("i_agree") }}</label>
            <div class="checkbox-wrap">
              <div class="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    class="custom-control-input"
                    id="subscribe"
                    :checked="subscribe_to_playground"
                    @change="onChange"
                    name="signup_form_subscribe_to_audioground_newsletter"/>
                <label
                    class="custom-control-label check register_checkbox-shadow"
                    for="subscribe"
                >Subscribe to AudioGround newsletter</label
                >
              </div>
            </div>
            <!--            <label class="invalid-input" v-if="_handleErrorMessage('subscribe_to_playground')">{{_handleErrorMessage("subscribe_to_playground")}}</label>-->
            <div
                class="btn btn-login m-t-30 Font_semibold term_signup_btn"
                v-on:click="registerUser"
            >
              {{ loading ? "Please Wait..." : "SIGN UP" }}
            </div>
            <span v-if="register_error" class="accept_trm_btn">Please accept terms & condition.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as Helper from '../../../apis/Helper.js';
import {
  SIGNUP_ERRORS,
  SIGNUP_FORM, SIGNUP_FORM_CONFIRM_PASSWORD,
  SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY, SIGNUP_FORM_PASSWORD,
  SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER, SIGNUP_FORM_USERNAME
} from '../../../store/types';
import * as Utils from '../../../components/utils/Utils';

export default {
  props: {
    clearData: {
      Type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: "",
      register_error: false
    };
  },
  watch: {
    clearData: function () {
      if (this.clearData == true) {
        this.clearFormData();
        this.$emit('clearData', true);
      }
    }
  },
  computed: {
    ...mapGetters({
      form_data: "signup_user_settings_form_map_to_props",
      affiliation_token: "getAffiliationTokenValue"
    }),
    username() {
      const {username = ""} = this.form_data || {};

      return username;
    },
    password() {
      const {password = ""} = this.form_data || {};

      return password;
    },
    confirm_password() {
      const {confirm_password = ""} = this.form_data || {};

      return confirm_password;
    },
    loading() {
      const {loading = false} = this.form_data || {};

      return loading;
    },
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    },
    i_agree() {
      const {i_agree = false} = this.form_data || {};

      return i_agree;
    },
    subscribe_to_playground() {
      const {subscribe_to_playground = false} = this.form_data || {};

      return subscribe_to_playground;
    }
  },
  mounted() {
    const {ref} = this.$route.query;
  },
  methods: {
    ...mapActions([
      "register_user"
    ]),
    ...mapMutations([
      "updateSignupFormData",
      "updateSignupUIConstraints"
    ]),
    handleUserNameChange(e) {
      let {key} = e;

      const value = key === "." ? key : key.replace(/\W/gi, "");

      if (value) return true;

      return e.preventDefault();
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      return message;
    },
    onChange(e) {
      const {name, value, checked} = e.target || {};

      switch (name) {
        case SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY:
        case SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER:
          this.updateSignupFormData({
            [name]: checked
          });
          break;
        default:
          this.updateSignupFormData({
            [name]: value
          });
      }
    },
    registerUser() {
      const {loading, username, password, confirm_password, subscribe_to_playground, i_agree} = this;

      if (loading) return;

      const body = {
        username,
        password,
        confirm_password,
        i_agree
      };

      console.log("test data ===> store", this.$store.state, body, Object.keys(body), Helper)

      Helper.validate(Object.keys(body), body)
          .then(async ({status, response}) => {
            if (status) {
              this.updateSignupUIConstraints({
                [SIGNUP_ERRORS]: []
              });
              try {
                this.message = "";
                await this.register_user();
                this.clearFormData();
                this.updateSignupFormData({
                  [SIGNUP_FORM_USERNAME]: '',
                  [SIGNUP_FORM_PASSWORD]: '',
                  [SIGNUP_FORM_CONFIRM_PASSWORD]: ''
                });
                this.$parent.newModal("verify-email");
              } catch (e) {
                this.message = e && e.message ? e.message : "Please try again.";
              }
            } else this.updateSignupUIConstraints({
              [SIGNUP_ERRORS]: response && response.length ? response : []
            });
          })
          .catch(err => console.log(err));
    },
    registerUser1: function () {
      this.username_error = false;
      this.password_error = false;
      this.repeat_password_error = false;
      this.register_error = false;

      let username = this.username.trim();
      let password = this.password.trim();
      let repeat_password = this.repeat_password.trim();

      console.log(
          this.username,
          this.password,
          this.repeat_password,
          this.terms_condition,
          this.newsletter,
          "tet"
      );
      if (!username) {
        this.username_error = true;
        return;
      }
      if (!password) {
        this.password_error = true;
        return;
      }
      if (password != repeat_password) {
        this.repeat_password_error = true;
        return;
      }
      if (!this.terms_condition) {
        this.register_error = true;
        return;
      }
      this.$parent.newModal("verify-email");
    },
    closeRegisterModal: function () {
      this.updateSignupFormData({});
      this.$parent.closeModal();
    },
    goBack: function () {
      this.$parent.newModal("register-step-1");
    },
    updateGender: function (row) {
      this.genderName = row.name;
      this.gender = row.value;

      this.updateSignupFormData({
        [SIGNUP_FORM_GENDER]: row.value
      });
    },
    updateAge: function (row) {
      this.UserAge = row;
    },
    clearFormData() {
      this.$emit('formDataClear', true);
    }
  }
};
</script>

