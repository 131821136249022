<template>
  <div class="w-100">
    <GroupSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <img :src="require(`@/assets/img/g-settings.png`)" alt="icon">General Settings
              </div>
            </div>
            <div class="artist-setting-panel">
              <div class="artist-general-setting">
                <div class="engage_settings_header">
                  <div>
                    <h3>Group Access: Open</h3>
                    <h6>If status set as Open (switcher is on) anyone can access to your group. Switcher off if you want
                      to only invite members</h6>
                  </div>
                  <div class="switch_sm_wrapper">
                    <input type="checkbox" id="switch3">
                    <label class="switch_sm" for="switch3">
                      <div class="switch_toggler"></div>
                    </label>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Visitor Posts</h3>
                    <h6>What users are allowed to post on your timeline? </h6>
                  </div>
                  <div class="switch_sm_wrapper">
                    <input type="checkbox" id="switch2">
                    <label class="switch_sm" for="switch2">
                      <div class="switch_toggler"></div>
                    </label>
                  </div>
                </div>

                <div class="engage_settings_header">
                  <div>
                    <h3>Age Restriction</h3>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <div class="custom-dropdown">
                      <div class="custom-dropdown-head d-flex flex-row align-items-center" data-value="1">
                        <div class="svg-icon">
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="c-line-1"
                                  d="M6 11C8.76143 11 11 8.76142 11 6C11 3.23858 8.76143 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                  stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="c-line-1" d="M1 6H11" stroke="#071526" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="c-line-1"
                                  d="M6 1C7.25064 2.36918 7.96138 4.14602 8 6C7.96138 7.85398 7.25064 9.63082 6 11C4.74936 9.63082 4.03862 7.85398 4 6C4.03862 4.14602 4.74936 2.36918 6 1V1Z"
                                  stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        Anyone
                        <div class="for-drop d-flex ml-auto">
                          <div class="svg-icon">
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="custom-dropdown-menu flex-column">
                        <div class="custom-dropdown-item d-flex flex-row align-items-center" data-value="0">
                          <div class="svg-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1"
                                    d="M10 18.3332C14.6024 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6024 1.6665 10 1.6665C5.39765 1.6665 1.66669 5.39746 1.66669 9.99984C1.66669 14.6022 5.39765 18.3332 10 18.3332Z"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1" d="M1.66669 10H18.3334" stroke="#071526" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M10 1.6665C12.0844 3.94846 13.269 6.90987 13.3334 9.99984C13.269 13.0898 12.0844 16.0512 10 18.3332C7.91562 16.0512 6.73106 13.0898 6.66669 9.99984C6.73106 6.90987 7.91562 3.94846 10 1.6665V1.6665Z"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          Public
                          <div class="for-check ml-auto">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                          <div class="for-drop ml-auto">
                            <div class="svg-icon">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="custom-dropdown-item d-flex flex-row align-items-center" data-value="1">
                          <div class="svg-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1"
                                    d="M14.1666 17.5V15.8333C14.1666 14.9493 13.8155 14.1014 13.1903 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16665C3.28259 12.5 2.43474 12.8512 1.80962 13.4763C1.1845 14.1014 0.833313 14.9493 0.833313 15.8333V17.5"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M7.50002 9.16667C9.34097 9.16667 10.8334 7.67428 10.8334 5.83333C10.8334 3.99238 9.34097 2.5 7.50002 2.5C5.65907 2.5 4.16669 3.99238 4.16669 5.83333C4.16669 7.67428 5.65907 9.16667 7.50002 9.16667Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M19.1667 17.5001V15.8334C19.1661 15.0948 18.9203 14.3774 18.4678 13.7937C18.0153 13.2099 17.3818 12.793 16.6667 12.6084"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M13.3333 2.6084C14.0503 2.79198 14.6858 3.20898 15.1397 3.79366C15.5935 4.37833 15.8398 5.09742 15.8398 5.83757C15.8398 6.57771 15.5935 7.2968 15.1397 7.88147C14.6858 8.46615 14.0503 8.88315 13.3333 9.06673"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          Friends
                          <div class="for-check  ml-auto">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                          <div class="for-drop ml-auto">
                            <div class="svg-icon">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="custom-dropdown-item d-flex flex-row align-items-center" data-value="2">
                          <div class="svg-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1"
                                    d="M15.8333 9.1665H4.16667C3.24619 9.1665 2.5 9.9127 2.5 10.8332V16.6665C2.5 17.587 3.24619 18.3332 4.16667 18.3332H15.8333C16.7538 18.3332 17.5 17.587 17.5 16.6665V10.8332C17.5 9.9127 16.7538 9.1665 15.8333 9.1665Z"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M5.83331 9.1665V5.83317C5.83331 4.7281 6.2723 3.66829 7.0537 2.88689C7.8351 2.10549 8.89491 1.6665 9.99998 1.6665C11.105 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1666 4.7281 14.1666 5.83317V9.1665"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          Only Me
                          <div class="for-check ml-auto">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                          <div class="for-drop ml-auto">
                            <div class="svg-icon">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Country Restriction</h3>
                    <h6>You can choose countries in blocklist and allowlist or all countries</h6>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <div class="custom-dropdown">
                      <div class="custom-dropdown-head d-flex flex-row align-items-center" data-value="1">
                        All Countries
                        <div class="for-drop d-flex ml-auto">
                          <div class="svg-icon">
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="custom-dropdown-menu flex-column">
                        <div class="custom-dropdown-item d-flex flex-row align-items-center" data-value="0">
                          <div class="svg-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1"
                                    d="M10 18.3332C14.6024 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6024 1.6665 10 1.6665C5.39765 1.6665 1.66669 5.39746 1.66669 9.99984C1.66669 14.6022 5.39765 18.3332 10 18.3332Z"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1" d="M1.66669 10H18.3334" stroke="#071526" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M10 1.6665C12.0844 3.94846 13.269 6.90987 13.3334 9.99984C13.269 13.0898 12.0844 16.0512 10 18.3332C7.91562 16.0512 6.73106 13.0898 6.66669 9.99984C6.73106 6.90987 7.91562 3.94846 10 1.6665V1.6665Z"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          Public
                          <div class="for-check ml-auto">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                          <div class="for-drop ml-auto">
                            <div class="svg-icon">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="custom-dropdown-item d-flex flex-row align-items-center" data-value="1">
                          <div class="svg-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1"
                                    d="M14.1666 17.5V15.8333C14.1666 14.9493 13.8155 14.1014 13.1903 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16665C3.28259 12.5 2.43474 12.8512 1.80962 13.4763C1.1845 14.1014 0.833313 14.9493 0.833313 15.8333V17.5"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M7.50002 9.16667C9.34097 9.16667 10.8334 7.67428 10.8334 5.83333C10.8334 3.99238 9.34097 2.5 7.50002 2.5C5.65907 2.5 4.16669 3.99238 4.16669 5.83333C4.16669 7.67428 5.65907 9.16667 7.50002 9.16667Z"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M19.1667 17.5001V15.8334C19.1661 15.0948 18.9203 14.3774 18.4678 13.7937C18.0153 13.2099 17.3818 12.793 16.6667 12.6084"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M13.3333 2.6084C14.0503 2.79198 14.6858 3.20898 15.1397 3.79366C15.5935 4.37833 15.8398 5.09742 15.8398 5.83757C15.8398 6.57771 15.5935 7.2968 15.1397 7.88147C14.6858 8.46615 14.0503 8.88315 13.3333 9.06673"
                                    stroke="#D1433A" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          Friends
                          <div class="for-check  ml-auto">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                          <div class="for-drop ml-auto">
                            <div class="svg-icon">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div class="custom-dropdown-item d-flex flex-row align-items-center" data-value="2">
                          <div class="svg-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path class="c-line-1"
                                    d="M15.8333 9.1665H4.16667C3.24619 9.1665 2.5 9.9127 2.5 10.8332V16.6665C2.5 17.587 3.24619 18.3332 4.16667 18.3332H15.8333C16.7538 18.3332 17.5 17.587 17.5 16.6665V10.8332C17.5 9.9127 16.7538 9.1665 15.8333 9.1665Z"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                              <path class="c-line-1"
                                    d="M5.83331 9.1665V5.83317C5.83331 4.7281 6.2723 3.66829 7.0537 2.88689C7.8351 2.10549 8.89491 1.6665 9.99998 1.6665C11.105 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1666 4.7281 14.1666 5.83317V9.1665"
                                    stroke="#071526" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                          </div>
                          Only Me
                          <div class="for-check ml-auto">
                            <div class="svg-icon">
                              <svg width="9" height="7" viewBox="0 0 9 7" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                                      fill="#D1433A"></path>
                              </svg>
                            </div>
                          </div>
                          <div class="for-drop ml-auto">
                            <div class="svg-icon">
                              <svg width="10" height="6" viewBox="0 0 10 6" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path class="c-line-1" d="M9 1L5.16868 5L1 1" stroke="#8B949F" stroke-width="1.3"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="engage_settings_header">
                  <div>
                    <h3>Delete Page</h3>
                    <h6>You can permanently delete your page from Playground</h6>
                  </div>
                  <div class="switch_sm_wrapper artist-drop">
                    <button v-on:click="deletePage = !deletePage" @closeModal="closeModal">Delete Page</button>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end ">
                <button class="prime_button" type="button">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePageModal v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
import GroupSubHeader from '@/components/Group/Pages/GroupSubHeader'
import DeletePageModal from '@/components/Group/Pages/DeletePageModal'

export default {
  data() {
    return {
      deletePage: false

    }
  },
  components: {
    GroupSubHeader,
    DeletePageModal
  },
  computed: {},
  methods: {
    closeModal: function () {
      this.deletePage = false;

    }

  }
}
</script>
