<template>
  <div class="w-100 empty-card">
    <div class="row">
      <div class="col-12 text-center">
        <div class="empty-card-icon">
          <img :src="item.icon" alt="picture">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <div class="empty-card-title">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <div class="empty-card-description">
          {{ item.description }}
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 text-center">
        <div class="empty-card-button">
          <b-button variant="primary" class="action-button py-2" block @click="action(item)">
            <b-icon icon="plus"></b-icon>
            {{ item.buttonText }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyDataCard",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    action(value) {
      this.$emit('buttonClick', value)
    }
  }
}
</script>

<style scoped lang="scss">
.empty-card {
  background: #FAFAFA;
  /* Light grey (New) */

  border: 1.3px solid #E6EAF0;
  box-sizing: border-box;
  border-radius: 4px;

  .empty-card-icon {
    margin-top: 40px;
    margin-bottom: 34px;

  }

  .empty-card-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 16px;

  }

  .empty-card-description {
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.7px;
    width: 600px;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 37px;
    /* identical to box height, or 125% */

    text-align: center;

    /* #47505B */

    color: #47505B;
  }

  .empty-card-button {
    margin-bottom: 49px;
  }
}
</style>
