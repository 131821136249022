<template>
  <div class="container_xxl articleContainer articleMain">
    <div class="row">
      <div class="col">
        <h1 class="Page_title">MY Articles</h1>
      </div>
    </div>

    <div class="feed_wrapper">

      <div class="center_centerbar_wraper_home">
        <div class="col" style="height: auto">
          <div v-if="active_articles_content === 'MyArticlesItems'">
            <div class="container-fluid">
              <div class="row">
                <div class="feed-filters">
                  <div class="search-wrapper with-icon pg-search-wrapper">
                    <div class="svg-icon">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                        class="svg-search-ico">
                        <path class="c-line-1"
                          d="M12.1 6.54294C12.1 3.48162 9.61518 0.999937 6.55 0.999937C3.48482 0.999937 1 3.48162 1 6.54294C1 9.60425 3.48482 12.0859 6.55 12.0859C9.61518 12.0859 12.1 9.60425 12.1 6.54294Z"
                          stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path class="c-line-1" d="M12.5 12.501L14.5 14.501" stroke="#47505B" stroke-width="1.3"
                          stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                    </div>
                    <input class="search-input" placeholder="Search articles" v-on:keyup.enter="searchMyEvents()"
                      v-model="searchInput" />
                  </div>
                  <div class="dropdown-tabs-wrapper pg-dropdown">
                    <div class="dropdown-tabs" @click="openCloseFiltrArticles">
                      All
                      <div class="svg-icon">
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.7695 1L6.09052 6.09612L0.999512 1" stroke="#D1433A" stroke-width="1.3"
                            stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </div>
                    </div>
                    <!-- dropdown-tabs-menu  abs-btn -->
                    <div v-if="seeDropDownArticls" class="dropDownArticls abs-btn dropdown-tabs-menu"
                      style="display: flex">
                      <div class="tab-content m-t-0">
                        <div class="tab-pane active">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="saved" value="saved"
                              v-model="chekedFilterArticle" />
                            <label class="custom-control-label check" for="saved">Saved Articles</label>
                          </div>
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="created" value="created"
                              v-model="chekedFilterArticle" />
                            <label class="custom-control-label check" for="created">Created Articles</label>
                          </div>
                          <div class="divider"></div>
                          <div class="custom-control custom-radio">
                            <input type="radio" name="points_payment_method2" id="latest" value="latest"
                              class="custom-control-input" v-model="radioFilterArticle" />
                            <label class="custom-control-label" for="latest">Latest</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" name="points_payment_method2" id="most_viewed" value="most_viewed"
                              class="custom-control-input" v-model="radioFilterArticle" />
                            <label class="custom-control-label" for="most_viewed">Most viewed</label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input type="radio" name="points_payment_method2" id="most_Comments" value="most_Comments"
                              class="custom-control-input" v-model="radioFilterArticle" />
                            <label class="custom-control-label" for="most_Comments">Most Comments</label>
                          </div>
                        </div>
                        <div class="btn-done js-dropdown-tabs-btn" @click="doneFiltrArticle">
                          DONE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="myArticles.length > 0">
              <div v-for="(article, index) in myArticles" :key="article.id">
                <MyArticleItem :article="article" :key="'post-detail' + article.id" :index="index" />
              </div>
            </div>

            <div v-else class="item-nothing h-300">
              <div class="svg-icon">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M31.0769 44L32.6923 44L43.9999 32.6923L44 31.0769M31.0769 44L8.46149 44C4.89288 44 1.99995 41.1071 1.99995 37.5385L1.99995 8.46154C1.99995 4.89293 4.89287 2 8.46148 2L37.5385 2C41.1071 2 44 4.89294 44 8.46156L44 31.0769M31.0769 44L31.0769 31.0769L44 31.0769"
                    stroke="#47505B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
              <div class="item-title">No Articles to show</div>
              <div class="item-subtitle">
                You don’t save or create articles yet. Let’s do this!
              </div>
              <a class="text-decoration-none" @click="redirectToCreatNewArticlePage">
                <div class="share-album-btn">
                  <div class="svg-icon">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.154 0.943604L15.8105 4.60011L8.72934 11.6813L3.95068 12.8057L5.06757 7.91937L12.154 0.943604ZM6.43219 8.68088L5.94907 10.7945L7.9704 10.3189L13.6892 4.60011L12.1457 3.05661L6.43219 8.68088Z"
                        fill="white"></path>
                      <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                        d="M0 2.15009H6.7V3.65009H1.5V15.1501H13.4V10.3501H14.9V16.6501H0V2.15009Z" fill="white">
                      </path>
                    </svg>
                  </div>
                  WRITE NEW ARTICLE
                </div>
              </a>
            </div>
          </div>
          <TrendingArticles v-if="active_articles_content === 'TrendingArticles'" />
          <div v-if="myArticles.length > 0" class="show_more_btn">
            <div class="show-more musicbar_more width770">
              <a @click="showMorePosts()" href="javascript:;"><span class="show_more_bg"><svg width="9" height="8"
                    viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.24219 0.689941V7.01994" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                    <path d="M1 3.85498H7.48702" stroke="#071526" stroke-width="1.3" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                  </svg>
                  SHOW MORE
                </span></a>
            </div>
          </div>
        </div>

      </div>

      <div class="content-sidebar">
        <div class="post-widget">
          <a class="text-decoration-none" @click="redirectToCreatNewArticlePage">
            <div class="post-btn btn with-icon" style="width: 350px">
              <div class="svg-icon custom-pos">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.154 0.943604L15.8105 4.60011L8.72934 11.6813L3.95068 12.8057L5.06757 7.91937L12.154 0.943604ZM6.43219 8.68088L5.94907 10.7945L7.9704 10.3189L13.6892 4.60011L12.1457 3.05661L6.43219 8.68088Z"
                    fill="#D1433A"></path>
                  <path class="c-fill-1" fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 2.15009H6.7V3.65009H1.5V15.1501H13.4V10.3501H14.9V16.6501H0V2.15009Z" fill="#D1433A">
                  </path>
                </svg>
              </div>
              Write new article
            </div>
          </a>
        </div>
        <div v-if="active_articles_content === 'MyArticlesItems'" class="content-sidebar">
          <div class="related-widget widget-info new_widget">
            <div class="widget-item">
              <div class="item">
                <div class="items-main">
                  <div class="item-title">FRIENDS</div>
                  <div class="
                            item-subtitle
                            d-flex
                            align-items-center
                            justify-content-center
                          ">
                    {{ (myArticles.length > 0) ? myArticles[0].user.follower_count : 0 }}
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="items-main">
                  <div class="item-title">FOLLOWERS</div>
                  <div class="
                            item-subtitle
                            d-flex
                            align-items-center
                            justify-content-center
                          ">
                    {{ (myArticles.length > 0) ? myArticles[0].user.follower_count : 0 }}
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="items-main">
                  <div class="item-title">FOLLOWING</div>
                  <div class="
                            item-subtitle
                            d-flex
                            align-items-center
                            justify-content-center
                          ">
                    {{ (myArticles.length > 0) ? myArticles[0].user.following_count : 0 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <trending :fromMyArticle=true :listData="trendingData" @showMore="showMoreTrending" />
        <who-to-follow :header="true" :redirectLink="'continue-listening-to'" :listData="talkChannels"
          :pageTitle="'Who To Follow'" />
        <who-to-follow :header="true" :redirectLink="'continue-listening-to'" :listData="explorePages.pages"
          :pageTitle="'Suggested pages'" />
      </div>

    </div>


  </div>
</template>
<script>
import MyArticleItem from "@/components/Home/Article/MyArticleItem.vue";
import TrendingArticles from "@/components/Home/Article/TrendingArticles.vue";
import WhoToFollow from "@/components/Common/Elements/WhoToFollow.vue";
import Trending from "@/components/Common/Widget/Trending.vue";
import { mapActions, mapGetters } from "vuex";
import { getChannelData, getTrendingList } from "../../../apis/APIs";
export default {
  components: {
    MyArticleItem,
    TrendingArticles,
    WhoToFollow,
    Trending
  },
  data() {
    return {
      searchInput: '',
      seeDropDownArticls: false,
      chekedFilterArticle: [],
      radioFilterArticle: "",
      active_articles_content: "MyArticlesItems",
      userData: null,
      trendingData: [],
      talkChannels: [],
      showMore: false,
    };
  },

  async mounted() {
    this.$store.dispatch("loading", true);

    try {
      await Promise.all([
        this.getUserPosts(true),
        this.getPages(),
        this.getTrendingList(),
        this.getTalkChannels()
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.$store.dispatch("loading", false);
    }
  },

  methods: {
    ...mapActions(["filterArticle"]),
    openCloseFiltrArticles() {
      this.seeDropDownArticls = !this.seeDropDownArticls;
    },
    changeContentarticles(active_article) {
      this.active_articles_content = active_article;
    },
    async doneFiltrArticle() {
      let saved = 0;
      let created = 0;
      if (this.chekedFilterArticle.length > 0) {
        if (this.chekedFilterArticle[0] === 'saved' || this.chekedFilterArticle[1] === 'saved') {
          saved = 1
        }
        if (this.chekedFilterArticle[0] === 'created' || this.chekedFilterArticle[1] === 'created') {
          created = 1
        }
      }
      let dataFilter = {
        type: "articles",
        created: created,
        saved: saved,
        sort_by: this.radioFilterArticle,
      };
      await this.$store.dispatch('filterArticle', dataFilter)
      this.$store.commit('GET_POSTS', this.$store.getters.GET_FILTERED_ARTICLES.data)
      this.$store.commit("SET_POST_TYPE", 'all');
      this.openCloseFiltrArticles();
    },
    async getTrendingList(offset = 0, limit = 10) {
      try {
        let { data } = await getTrendingList(offset, limit);
        this.trendingData = data.information.data
      }
      catch (error) {
        // this.loading = false;
      }
    },

    showMoreTrending() {
      this.trending_offset = this.trending_limit
      this.trending_limit += 10
      this.getTrendingList(this.trending_offset, this.trending_limit)
    },

    async getPages() {
      await this.$store.commit("SET_PAGES_TYPE", 'artist');
      await this.$store.dispatch("getPageCategories");
      await this.$store.commit("CLEAR_PAGES_DATA");
      await this.$store.dispatch("getAllPages", this.showMore);
      this.explorePages = await this.$store.getters.getExplorePages;
    },

    async getTalkChannels() {
      try {
        this.loading = true
        let { data } = await getChannelData('talk_podcast');
        this.talkChannels = data.channels.rightbar[0].objects.data;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        // this.loading = false;
      }
    },

    async getUserPosts(loader) {
      this.$store.commit("GET_POSTS", [])
      this.$store.commit("SET_OTHER_USER_ID", (!this.otherUser) ? null : this.userData.id);
      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.commit("SET_POST_TYPE", 'articles');
      await this.$store.dispatch("getUserPosts", { loader: loader });
      await this.$store.commit("SET_POST_TYPE", 'all');
    },

    showMorePosts() {
      if (this.$store.getters.getPosts.total > this.myArticles.length) {
        let offset = this.$store.getters.getPostOffset;
        let limit = this.$store.getters.getPostsLimit;
        offset = offset + limit;
        this.$store.commit("SET_POST_OFFSET", offset);
        this.$store.commit("SET_POST_TYPE", 'articles');
        this.$store.dispatch("getUserPosts", { showMore: true, loader: false });
        this.$store.commit("SET_POST_TYPE", 'all');
        this.myArticles = this.$store.getters.getPosts;
      }
    },

    async searchMyEvents() {
      this.$store.dispatch("loading", true)
      this.$store.commit("GET_POSTS", [])
      this.$store.commit("SET_OTHER_USER_ID", (!this.otherUser) ? null : this.userData.id);
      this.$store.commit("SET_POST_OFFSET", 0);
      this.$store.commit("SET_POST_TYPE", 'articles');
      await this.$store.dispatch("getUserPosts", { loader: true, search: this.searchInput });
      this.$store.commit("SET_POST_TYPE", 'all');
      this.myArticles = this.$store.getters.getPosts;
    }
  },
  computed: {
    ...mapGetters(["GET_FILTERED_ARTICLES",]),
    explorePages() {
      return this.$store.getters.getExplorePages;
    },
    myArticles() {
      return this.$store.getters.getPosts
    },
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
.related-widget {
  &.new_widget {
    padding: 9px !important;
  }
}

.dropDownArticls {
  display: flex;
  position: absolute;
  top: 50px;
  left: 0;
  width: 250px;
  background-color: #fff;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(71, 80, 91, 0.2));
  border: 1px solid #e6eaf0;
  padding: 23px 0px 0;
  overflow: hidden;

  .tab-content {
    width: 100% !important;

    .tab-pane {
      padding-bottom: 6px;
    }

    .divider {
      margin: 2px auto 17px;
      width: 210px;
    }
  }

  .tab-content .custom-control {
    padding-left: 50px;

    input {
      width: 100%;
    }
  }

  .custom-control-label::after {
    top: 6px !important;
    left: -28px !important;
  }

  .tab-content .custom-control .custom-control-label::before {
    width: 16px !important;
    height: 16px !important;
    left: -28px !important;
    top: 6px;
  }

  .tab-content .custom-control.custom-radio .custom-control-input:checked~.custom-control-label::after {
    top: 10px !important;
    left: -24px !important;
  }

  .tab-content .custom-control .custom-control-label {
    padding: 7px 0 !important;
  }

  .custom-radio:hover {
    background-color: unset;
  }
}

.treanding_sections {
  min-height: 530px;

  .treanding_flag {
    height: 59px;

    .trending-heading {
      display: flex;
      align-items: center;

      .trending-title {
        margin-left: 0.6rem;
        width: 81px;
        height: 22px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #071526;
        opacity: 0.9;
        font-family: HelveticaNeueBold, sans-serif;
        white-space: nowrap;
      }
    }
  }

  .trending-list {
    li {
      height: 80px;
      display: flex;
      align-items: center;
      width: 100%;

      .tr_block {
        width: 100%;
      }
    }
  }
}
</style>
