<template>
  <div class="item-title">
    <div class="header-text">
      <h1>Report Post</h1>
    </div>
    <div @click="closeModal()">
      <span>
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 10.1914L20 19.8068"
            stroke="#47505B"
            stroke-width="1.5"
          />
          <path
            d="M19.8066 10L10.1913 20"
            stroke="#47505B"
            stroke-width="1.5"
          />
        </svg>
      </span>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ReportModalHeader",
  data() {
    return {};
  },
  components: {},
  methods: {
    ...mapActions([
      "deleteReply",
      "getMyReplies",
      "deleteThread",
      "getMyThread",
      "getStatisticsForums",
    ]),
    closeModal() {
      this.$emit("closeModal");
    },
    async deleteThis() {
      this.closeModal();
    },
  },
  computed: {
    ...mapGetters(["GET_REPLY_ID", "GET_THREAD_ID", "GET_DELETE_TYPE"]),
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6eaf0;
  padding-bottom: 25px;
  .header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;
    h1 {
      color: #071526;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
