

<template>
<svg
width="16"
height="17"
viewBox="0 0 16 17"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  class="c-line-1"
  d="M7.33333 12.8464C10.2789 12.8464 12.6667 10.4585 12.6667 7.51302C12.6667 4.5675 10.2789 2.17969 7.33333 2.17969C4.38781 2.17969 2 4.5675 2 7.51302C2 10.4585 4.38781 12.8464 7.33333 12.8464Z"
  stroke="#8B949F"
  stroke-width="1.5"
  stroke-linecap="round"
  stroke-linejoin="round"
></path>
<path
  class="c-line-1"
  d="M14 14.1793L11.1 11.2793"
  stroke="#8B949F"
  stroke-width="1.5"
  stroke-linecap="round"
  stroke-linejoin="round"
></path>
</svg>
</template>

<script>
export default {
    name: "SearchIcon",
};
</script>