<template>
  <div class="ag-container music-page">
    <MusicSubHeader/>
    <div class="container_xxl">
      <div class="artist_body mx-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.03516 4.04042V0" stroke="#47505B" stroke-width="1.5"/>
                  <path d="M10.1211 4.04042V0" stroke="#47505B" stroke-width="1.5"/>
                  <path
                      d="M2.84162 1.97266H12.2253C12.7129 1.97562 13.1797 2.18432 13.5245 2.55373C13.8693 2.92315 14.0642 3.42333 14.0669 3.94575V12.0267C14.0642 12.5491 13.8693 13.0493 13.5245 13.4187C13.1797 13.7881 12.7129 13.9968 12.2253 13.9998H2.84162C2.35404 13.9968 1.88721 13.7881 1.54243 13.4187C1.19766 13.0493 1.00276 12.5491 1 12.0267V3.94575C1.01783 3.42851 1.21755 2.93762 1.55913 2.57163C1.90072 2.20563 2.35887 1.99176 2.84162 1.97266V1.97266Z"
                      stroke="#47505B" stroke-width="1.5"/>
                  <path d="M2.83984 6.48438H12.3112" stroke="#47505B" stroke-width="1.5"/>
                </svg>
                Music
              </div>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab container-page">
                <div class="row">
                  <div class="col-12 page-title ">Search Your Tracks</div>
                </div>
                <div class="row my-2">
                  <div class="col-6">
                    <v-select class="v-select-main-class v-select-with-search-icon" label="name"
                              :filterable="false"
                              :options="searchData"
                              v-model="searchItem"
                              placeholder="Search tracks or albums by name"
                    >
                      <template slot="no-options">
                        Search for admins or moderators
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <img class="search-image" :src='option.artwork_url'/>
                          {{ option.name }} {{ option.last_name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <img class="search-image" :src='option.artwork_url'/>
                          {{ option.name }} {{ option.last_name }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-6 pr-1">
                        <b-button variant="outline-primary" block class="mt-0 action-button py-2">SHOP MANAGER
                        </b-button>
                      </div>
                      <div class="col-6 pl-1">
                        <b-button variant="primary" class="action-button py-2" block @click="addMedia">
                          <b-icon icon="plus"></b-icon>
                          UPLOAD MUSIC
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="row sticky_navbar">
                    <div class="col-12">
                      <div class="edit-share-section">
                        <div class="detail-tab">
                          <nav>
                            <div class="nav top-tab nav-tabs1" id="nav-tab" role="tablist">
                              <a :class="['nav-item nav-link', ((active_tab === 'songs') ? 'active' : '')]"
                                 id="nav-song-tab" data-toggle="tab" href="#nav-songs" role="tab"
                                 aria-controls="nav-home" :aria-selected="(active_tab === 'songs') ? true : false"
                                 @click="setActiveTab('songs')">Songs</a>
                              <a :class="['nav-item nav-link', ((active_tab === 'albums') ? 'active' : '')]"
                                 id="nav-album-tab" data-toggle="tab" href="#nav-albums" role="tab"
                                 aria-controls="nav-home" :aria-selected="(active_tab === 'albums') ? true : false"
                                 @click="setActiveTab('albums')">Albums</a>
                              <a :class="['nav-item nav-link', ((active_tab === 'samples') ? 'active' : '')]"
                                 id="nav-samples-tab" data-toggle="tab" href="#nav-samples" role="tab"
                                 aria-controls="nav-home" :aria-selected="(active_tab === 'samples') ? true : false"
                                 @click="setActiveTab('samples')">Samples</a>
                              <a :class="['nav-item nav-link', ((active_tab === 'samples-pack') ? 'active' : '')]"
                                 id="nav-samples-pack-tab" data-toggle="tab" href="#nav-samples-pack" role="tab"
                                 aria-controls="nav-home"
                                 :aria-selected="(active_tab === 'samples-pack') ? true : false"
                                 @click="setActiveTab('samples-pack')">Sample Packs</a>
                              <a :class="['nav-item nav-link', ((active_tab === 'beats') ? 'active' : '')]"
                                 id="nav-beats-tab" data-toggle="tab" href="#nav-beats" role="tab"
                                 aria-controls="nav-home" :aria-selected="(active_tab === 'beats') ? true : false"
                                 @click="setActiveTab('beats')">Beats</a>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- PAGE NAV TAB END -->
                  <div class="tab-content" id="nav-tabContent">
                    <!-- PAGE MUSIC SECTION START -->
                    <div :class="['tab-pane fade', (active_tab === 'songs') ? 'active show' : '']" id="nav-songs"
                         role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="row my-4 px-3">
                        <div class="col-12 text-center" v-if="songs.loading">
                          <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </div>
                        <div class="col-12" v-else-if="songs.songsList.length>0">
                          <SongListingOnPage :listData="songs.songsList"/>
                        </div>
                        <div class="col-12" v-else>
                          <EmptyDataCard :item="emptyCardList.songs" @buttonClick="addMedia"/>
                        </div>
                      </div>
                    </div>
                    <div :class="['tab-pane fade', (active_tab === 'albums') ? 'active show' : '']" id="nav-albums"
                         role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="row my-4 px-3">
                        <div class="col-12 text-center" v-if="albums.loading">
                          <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </div>
                        <div class="col-12" v-else-if="albums.albumsList.length>0">
                          <SongListingOnPage :listData="albums.albumsList"/>
                        </div>
                        <div class="col-12" v-else>
                          <EmptyDataCard :item="emptyCardList.albums" @buttonClick="addMedia"/>
                        </div>
                      </div>
                    </div>
                    <div :class="['tab-pane fade', (active_tab === 'samples') ? 'active show' : '']" id="nav-samples"
                         role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="row my-4 px-3">
                      </div>
                    </div>
                    <div :class="['tab-pane fade', (active_tab === 'samples-pack') ? 'active show' : '']"
                         id="nav-samples-pack" role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="row my-4 px-3">
                      </div>
                    </div>
                    <div :class="['tab-pane fade', (active_tab === 'beats') ? 'active show' : '']" id="nav-beats"
                         role="tabpanel" aria-labelledby="nav-home-tab">
                      <div class="row my-4 px-3">
                      </div>
                    </div>
                    <!-- PAGE MUSIC SECTION END -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import EmptyDataCard from "@/components/Common/UiComponenets/EmptyDataCard.vue";
import SongListingOnPage from "@/components/Common/UiComponenets/SongListingOnPage.vue";
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import '/public/css/vue-select-style.scss';
import {getAutoFillDataByType, getPageSongAndAlbumByType} from "../../../../apis/APIs";

export default {
  data() {
    return {
      setAvailabilityAndRate: {
        availability_status: null,
        availability_time: null,
        rate_per_hour: null,
      },
      errorsList: [],
      searchItem: null,
      active_tab: 'songs',
      emptyCardList: {
        songs: {
          title: 'You aren’t selling any Songs yet',
          description: 'Set up your tracks and sell them directly on the platform ',
          icon: require(`@/assets/icons/common/songs.svg`),
          buttonText: 'UPLOAD MUSIC',
        },
        albums: {
          title: 'You aren’t selling any Albums yet',
          description: 'Set up your tracks and sell them directly on the platform ',
          icon: require(`@/assets/icons/common/songs.svg`),
          buttonText: 'UPLOAD ALBUMS',
        }
      },
      songs: {
        loading: false,
        songsList: [],
      },
      albums: {
        loading: false,
        albumsList: [],
      },
      autoFillData: {
        songs: [],
        albums: [],
        loading: true
      }

    }
  },
  components: {
    MusicSubHeader,
    vSelect,
    EmptyDataCard,
    SongListingOnPage,
  },
  computed: {
    pageType() {
      return this.current_page.type
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    searchData() {
      let data =[]
      for(const list in this.autoFillData)
      {
        if(Array.isArray(this.autoFillData[list])){
          data = [...data,...this.autoFillData[list]]
        }
      }
      return data
    },
  },
  validations: {},
  watch: {
    current_page(val) {
      if (val) {
        this.getPageSongList()
        this.getPageAlbumsList()
        this.fetchAllAutoFills()
      }
    },
    searchItem(val){
      if(val){
        this.getPageSongList(val)
        this.getPageAlbumsList(val)
      }
      else{
        this.getPageSongList()
        this.getPageAlbumsList()
      }

    }
  },
  methods: {
    onSearch(search, loading) {
      loading(true);
      loading(false);
    },
    setActiveTab(tab_name) {
      this.active_tab = tab_name;
    },
    addMedia() {
      this.$router.push('/page-manager/page/add-product/' + this.current_page.id)
    },
    async getPageSongList(searchItem=null) {
      try {
        this.songs.loading = true
        const {data} = await getPageSongAndAlbumByType('songs', this.current_page.id, 0, 5,null,searchItem)
        if (data.success === 1) {
          const {information} = data
          this.songs.songsList = information.songs.data
        } else {
          console.error(data.message)
        }
        this.songs.loading = false
      } catch (e) {
        this.songs.loading = false
        console.error(e)
      }
    },
    async getPageAlbumsList(searchItem) {
      try {
        this.albums.loading = true
        const {data} = await getPageSongAndAlbumByType('albums', this.current_page.id, 0, 5,null,searchItem)
        if (data.success === 1) {
          const {information} = data
          this.albums.albumsList = information.albums.data
        } else {
          console.error(data.message)
        }
        this.albums.loading = false
      } catch (e) {
        this.albums.loading = false
        console.error(e)
      }
    },
    async getAutoFillDataByType(type) {
      try {
        this.autoFillData.loading = true
        const {data} = await getAutoFillDataByType(this.current_page.id, type)
        if (data.success === 1) {
          const {information} = data
          this.autoFillData[type] = information
        } else {
          console.error(data.message)
        }
        this.autoFillData.loading = false
      } catch (e) {
        this.autoFillData.loading = false
        console.error(e)
      }
    },
    async fetchAllAutoFills() {
      let types = ['songs', 'albums']
      for (const type of types) {
        await this.getAutoFillDataByType(type)
      }
    }
  },
  mounted() {
    if (this.current_page) {
      this.getPageSongList()
      this.getPageAlbumsList()
      this.fetchAllAutoFills()
    }
  }
}
</script>
<style scoped lang="scss">
.music-page {
  .container-page {
    padding: 1.5rem 2.125rem;
    .page-title {
      font-family: HelveticaNeueBold;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 21px;
    }
    .action-button {
      font-family: HelveticaNeueBold;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      text-transform: uppercase;
    }
  }
}
</style>
