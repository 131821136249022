<template>
  <div class="col-12 m-t-0">
    <SongOrAlbumRowList
      class="mx-auto"
      :itemType="itemType"
      :listData="listData"
      :rating="showRating"
    />
  </div>
</template>
<script type="application/javascript">
import SongOrAlbumRowList from "@/components/DesignGuide/Music/Components/SongOrAlbumRowList";

export default {
  data() {
    return {};
  },

  components: {
    SongOrAlbumRowList,
  },
  props: {
    width: String,
    hasTitleHeader: {
      type: Boolean,
      default: true,
    },
    listData: {
      type: Array,
      default: () => [],
    },
    showRating: {
      type: Boolean,
      default: true,
    },
    itemType: {
      type: String,
      default: "song",
    },
  },
  computed: {},
  mounted: function () {},
  methods: {},
};
</script>
<style lang="scss">
.card.genres-list .cover {
  width: 11%;
  float: left;
  margin-right: 0px !important;
}
.card.genres-list .description {
  width: 9.59% !important;
  float: left;
  margin: 0px !important;
  padding-left: 9px;
}
.card.genres-list .album {
  width: 15.64% !important;
  float: left;
}
.card.genres-list .time {
  width: 13% !important;
  float: left;
  margin: 0px !important;
}
.card.genres-list .date {
  width: 15% !important;
  float: left;
  margin: 0px !important;
}
.card.genres-list .popularity {
  width: 12% !important;
  float: left;
  margin: 0px !important;
}
.card.genres-list .other {
  float: left;
  margin: 0px !important;
}
.card.genres-list .wishlist {
  width: 33.33%;
}
.card.genres-list.width-810 .more {
  width: 33.33%;
}
.card.genres-list .extend {
  width: 33.33%;
}
.card.genres-list .other {
  width: 18%;
}
.card.genres-list .actions {
  width: 82%;
}
.pg-genres-list .line-1 .position {
  margin-right: 0px !important;
}
.pg-genres-list .line-1 .position {
  width: 3% !important;
  padding-right: 20px !important;
}
.pg-genres-list .line-1 .last-week {
  margin-left: 0px !important;
  padding-left: 16px;
  padding-right: 5px;
}
.pg-genres-list.width-810 .album {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.pg-genres-list .track {
  width: 100% !important;
  margin-right: 0px !important;
}
.pg-genres-list .album {
  width: 11.1% !important;
  padding-left: 13px;
}
.pg-genres-list .line-1 .current-week {
  padding-left: 11px;
}
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.pg-genres-list .line-1 .cover {
  width: 66px !important;
  margin: 0px !important;
}
.w-1170 .line-2 .btn-group-social {
  margin-left: 60px !important;
}
.pg-weekly-top .col {
  justify-content: normal !important;
}
.pg-weekly-top .cover {
  width: 66px !important;
  margin: 0px !important;
}
.pg-weekly-top .position {
  width: 3% !important;
  margin: 0px !important;
  padding-right: 20px !important;
}
.pg-weekly-top .last-weak {
  margin: 0px !important;
  padding-left: 16px;
}
.pg-weekly-top .title {
  width: 6.59% !important;
  margin: 0px !important;
}
.pg-weekly-top .album {
  width: 25.06% !important;
  margin: 0px !important;
}
.pg-weekly-top .date {
  width: 13% !important;
  margin: 0px !important;
}
.pg-weekly-top .time {
  width: 15% !important;
  margin: 0px !important;
}
.pg-weekly-top .popularity {
  width: 23% !important;
  margin: 0px !important;
}
</style>
