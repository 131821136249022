<template>
  <div class="page-filter d-flex d-flex flex-row align-items-center" style="padding-left: 16px !important;">
    <BuyStream />
    <div class="d-flex flex-wrap align-items-end ml-auto" style="margin-right: 16px;">
      <div :class="['dropdown bootstrap-select show-tick filters-select for-overview', (showDropdown === true) ? 'show' :'']" style="width: 250px !important;">
        <button @click="toggleDropdown('no')" type="button" class="btn dropdown-toggle btn-light bs-placeholder"  aria-owns="bs-select-28" aria-haspopup="listbox" aria-expanded="false" title="Top Selling">
          <div class="filter-option">
            <div class="filter-option-inner">
              <div class="filter-option-inner-inner">{{musicTypeName}}</div>
            </div>
          </div>
        </button>
        <div :class="['dropdown-menu',(showDropdown === true) ? 'show' :'']" style="position: absolute; will-change: transform; min-width: 250px; top: 0px; left: 0px; transform: translate3d(0, 10px, 0)!important;" x-placement="top-start">
          <div class="inner show" role="listbox" id="bs-select-28" tabindex="-1" aria-multiselectable="true">
            <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px; min-width: 250px !important;">
              <li class="music-filter-list" @click="changeMusicType('all', 'All')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_all" tabindex="0" aria-selected="false" aria-setsize="6" aria-posinset="1">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'all') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="16" height="16" viewBox="0 0 16 16" :fill="(musicType === 'all') ? '#D1433A': 'none'" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                        <path d="M6.70921 4.41603L6.93186 4.75H7.33325H13.3333C13.488 4.75 13.6363 4.81146 13.7457 4.92085C13.8551 5.03025 13.9166 5.17862 13.9166
                         5.33333V12.6667C13.9166 12.8214 13.8551 12.9697 13.7457 13.0791C13.6363 13.1885 13.488 13.25 13.3333 13.25H2.66659C2.51187 13.25 2.3635 13.1885
                          2.25411 13.0791C2.14471 12.9697 2.08325 12.8214 2.08325 12.6667V3.33333C2.08325 3.17862 2.14471 3.03025 2.25411 2.92085C2.3635 2.81146 2.51188
                          2.75 2.66659 2.75H5.59853L6.70921 4.41603Z" :stroke="(musicType === 'all') ? '#fff': '#071526'" stroke-width="1.5"/>
                      </svg>
                    </span>
                    All
                  </span>
                </a>
              </li>
              <li class="music-filter-list" @click="changeMusicType('songs', 'Tracks')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_tracks" tabindex="0" aria-selected="false" aria-setsize="6" aria-posinset="2">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'songs') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.61683 5.74384L11.2775 4.8117C11.9019 4.67667 12.4481 4.30153 12.7983 3.76726C13.1485 3.23299 13.2746 2.58244
                         13.1493 1.95605C13.024 1.32965 12.6574 0.777654 12.1286 0.419203C11.5998 0.0607518 10.9513 -0.075398 10.3231 0.0401427L6.95677 0.713404C6.39435 0.826017 5.88832
                         1.13 5.52475 1.57363C5.16119 2.01727 4.96255 2.57316 4.96262 3.14674V9.82642C4.33193 9.46229 3.59871 9.31643 2.87667 9.41147C2.15464 9.50651 1.48414 9.83713 0.969147
                         10.3521C0.454159 10.867 0.123463 11.5375 0.0283449 12.2595C-0.0667732 12.9815 0.0790016 13.7147 0.443062 14.3455C0.807123 14.9762 1.36912 15.4692 2.04191 15.748C2.7147
                         16.0268 3.46068 16.0758 4.16416 15.8874C4.86764 15.699 5.48931 15.2838 5.93276 14.7061C6.37621 14.1285 6.61666 13.4206 6.61683 12.6923V5.74467V5.74384ZM10.6481 1.66209L7.28182
                         2.33535C7.09439 2.37282 6.92572 2.47404 6.80448 2.62181C6.68325 2.76958 6.61694 2.95478 6.61683 3.14591V4.05738L10.9533 3.18975C11.1528 3.14604 11.3272 3.02577 11.4388 2.85481C11.5505
                         2.68385 11.5906 2.47587 11.5505 2.27563C11.5104 2.0754 11.3933 1.89892 11.2244 1.78417C11.0554 1.66942 10.8482 1.62558 10.6473 1.66209H10.6481ZM4.96262 12.6915C4.96262 12.2528 4.78834
                         11.832 4.47811 11.5218C4.16789 11.2116 3.74714 11.0373 3.30842 11.0373C2.86969 11.0373 2.44894 11.2116 2.13872 11.5218C1.82849 11.832 1.65421 12.2528 1.65421 12.6915C1.65421 13.1302
                         1.82849 13.551 2.13872 13.8612C2.44894 14.1714 2.86969 14.3457 3.30842 14.3457C3.74714 14.3457 4.16789 14.1714 4.47811 13.8612C4.78834 13.551 4.96262 13.1302 4.96262 12.6915Z"
                              :fill="(musicType === 'songs') ? '#fff': '#071526'"/>
                      </svg>
                    </span>
                    Tracks
                  </span>
                </a>
              </li>
              <li class="music-filter-list" @click="changeMusicType('albums', 'Albums')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_albums" tabindex="0" aria-selected="false">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'albums') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                      <path d="M8.00003 5.86719C6.82373 5.86719 5.8667 6.82422 5.8667 8.00052C5.8667 9.17687 6.82373 10.1339 8.00003 10.1339C9.17638 10.1339 10.1334 9.17683 10.1334
                        8.00052C10.1334 6.82417 9.17638 5.86719 8.00003 5.86719ZM8.00003 9.97094C6.91351 9.97094 6.02961 9.08699 6.02961 8.00052C6.02961 6.91405 6.91351 6.0301 8.00003
                        6.0301C9.0865 6.0301 9.97046 6.91405 9.97046 8.00052C9.97046 9.08699 9.0865 9.97094 8.00003 9.97094Z" :fill="(musicType === 'albums') ? '#fff': '#071526'"
                            :stroke="(musicType === 'albums') ? '#fff': '#071526'"/>
                      <circle cx="8" cy="8" r="7.25" :stroke="(musicType === 'albums') ? '#fff': '#071526'" stroke-width="1.5"/>
                      </svg>
                    </span>
                    Albums
                  </span>
                </a>
              </li>
              <li class="music-filter-list" @click="changeMusicType('playlists', 'Playlists')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_playlists" tabindex="0" aria-selected="false">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'playlists') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                        <path d="M10.7769 15.2198C12.2496 15.2198 13.4435 14.026 13.4435 12.5533C13.4435 11.0806 12.2496 9.88672 10.7769 9.88672C9.30421 9.88672 8.11035 11.0806 8.11035
                         12.5533C8.11035 14.026 9.30421 15.2198 10.7769 15.2198Z" :stroke="(musicType === 'playlists') ? '#fff': '#071526'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.4446 12.5551V1H17" :stroke="(musicType === 'playlists') ? '#fff': '#071526'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.88855 1.88672H1" :stroke="(musicType === 'playlists') ? '#fff': '#071526'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 5.44531H9.88855" :stroke="(musicType === 'playlists') ? '#fff': '#071526'" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.33313 9H1" :stroke="(musicType === 'playlists') ? '#fff': '#071526'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                    Playlists
                  </span>
                </a>
              </li>
              <li class="music-filter-list" @click="changeMusicType('podcasts', 'Podcasts')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_podcasts" tabindex="0" aria-selected="false">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'podcasts') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="16" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.15382 0C7.85316 0 9.23074 1.30244 9.23074 2.90909V8C9.23074 9.60665 7.85316 10.9091 6.15382 10.9091C4.45448 10.9091 3.0769 9.60665
                           3.0769 8V2.90909C3.0769 1.30244 4.45448 0 6.15382 0ZM6.92308 13.7732V14.5455H9.23077V16H3.07692V14.5455H5.38462V13.7732C2.34899 13.4153 0 10.967 0 8V6.54545H1.53846V8C1.53846
                           10.41 3.60484 12.3636 6.15385 12.3636C8.70285 12.3636 10.7692 10.41 10.7692 8V6.54545H12.3077V8C12.3077 10.967 9.9587 13.4153 6.92308 13.7732ZM4.61538 2.90909C4.61538 2.10577
                           5.30417 1.45454 6.15384 1.45454C7.00351 1.45454 7.6923 2.10577 7.6923 2.90909V8C7.6923 8.80332 7.00351 9.45455 6.15384 9.45455C5.30417 9.45455 4.61538 8.80332 4.61538
                           8V2.90909Z" :fill="(musicType === 'podcasts') ? '#fff': '#071526'"/>
                      </svg>
                    </span>
                    Podcasts
                  </span>
                </a>
              </li>
              <li class="music-filter-list" @click="changeMusicType('sample_packs', 'Sample Packs')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_packs" tabindex="0" aria-selected="false">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'sample_packs') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                        <path d="M16.6667 4.30233H13.7C13.56 4.30233 13.44 4.35814 13.3933 4.44651L12.0667 6.78605L10.0933 1.1814C10.06 1.07907 9.92667 1 9.76667 1C9.61333 1 9.48 1.07442 9.44 1.17674L7.13333 7.71163L5.69333 2.87442C5.66 2.77209
                         5.54 2.69767 5.38667 2.68837C5.23333 2.68372 5.1 2.74884 5.05333 2.84651L3.95333 5.13953H1.33333C1.14667 5.13953 1 5.24186 1 5.37209C1 5.50233 1.14667 5.60465 1.33333 5.60465H4.19333C4.33333 5.60465 4.46667 5.53954 4.50667
                          5.44651L5.28667 3.81395L6.77333 8.81395C6.80667 8.92093 6.94 9 7.09333 9H7.1C7.25333 9 7.38667 8.92558 7.42667 8.8186L9.76667 2.2093L11.66 7.6093C11.6933 7.70698 11.8133 7.7814 11.96 7.78605C12.1067 7.7907 12.24 7.73488 12.2933
                           7.64186L13.9267 4.76744H16.6667C16.8533 4.76744 17 4.66512 17 4.53488C17 4.40465 16.8533 4.30233 16.6667 4.30233Z"
                              :fill="(musicType === 'sample_packs') ? '#fff': '#071526'" :stroke="(musicType === 'sample_packs') ? '#fff': '#071526'" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                    Sample Packs
                  </span>
                </a>
              </li>
              <li class="music-filter-list last_music_filter_list" @click="changeMusicType('beats', 'Beats')">
                <a role="option" class="dropdown-item dropdown-hover" id="display_beats" tabindex="0" aria-selected="false">
                  <span class="text">
                    <span class="music-filter-icons" :style="(musicType === 'beats') ? 'background: #D1433A;': 'background: #E6EAF0;'">
                      <svg width="18" height="18" viewBox="0 0 18 18" :fill="(musicType === 'beats') ? '#fff': '#071526'" xmlns="http://www.w3.org/2000/svg" class="music_filter_svg">
                        <path d="M9 3L1.5 6.75L9 10.5L16.5 6.75L9 3Z" :stroke="(musicType === 'beats') ? '#fff': '#071526'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.5 10.5L9 14.25L16.5 10.5" :stroke="(musicType === 'beats') ? '#fff': '#071526'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                    Beats
                  </span>
                </a>
              </li>
              <hr class="music-hr-style">
            </ul>
            <div class="widget-item" style="margin: 20px;">
              <ul class="sort-cehkbox">
                <li>
                  <div class="sort-cehkbox-main">
                    <input type="radio" id="most_played" name="radio-group" v-model="filterBy" value="most_played" :checked="(filterBy === 'most_played') ? true : false">
                    <label for="most_played" class="radio-labels"><span>Most Played</span></label>
                  </div>
                </li>
                <li>
                  <div class="sort-cehkbox-main">
                    <input type="radio" id="latest" name="radio-group" v-model="filterBy" value="latest" :checked="(filterBy === 'latest') ? true : false">
                    <label for="latest" class="radio-labels"><span>Latest</span></label>
                  </div>
                </li>
                <li>
                  <div class="sort-cehkbox-main">
                    <input type="radio" id="high" name="radio-group" v-model="filterBy" value="high_rating" :checked="(filterBy === 'high_rating') ? true : false">
                    <label for="high" class="radio-labels"><span>High Rating</span></label>
                  </div>
                </li>
              </ul>
            </div>
            <div @click="toggleDropdown('yes')" class="done-btn js-dropdown-tabs-btn">
              DONE
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import BuyStream from '@/components/Music/UI-components/payStreamToggleSwitch.vue';
export default {
  data(){
    return{
      showDropdown : false,
      musicType: 'all',
      musicTypeName : 'All',
      filterBy: 'most_played',
      offset: 0,
      limit:10
    }
  },
  components:{
    BuyStream
  },
  methods:{
    toggleDropdown(emit){
      this.showDropdown = !this.showDropdown;
      if(this.musicType === 'albums'){
        this.offset = 0;
        this.limit = 8;
      }else{
        this.offset = 0;
        this.limit = 10;
      }
      const payload = {
        type : this.musicType,
        filter_by : this.filterBy,
        showMore : false,
        page_id : this.$route.params.id,
        offset : this.offset,
        limit : this.limit,
      }
      if(emit==='yes'){
        this.$store.dispatch('getMusicFilteredData',payload)
        this.$emit('activeMusicComponent', payload)
      }
    },
    changeMusicType(musicType, musicTypeName){
      this.musicType = musicType;
      this.musicTypeName = musicTypeName;
    }
  }
}
</script>
<style>
.radio-labels{
  color: #071526 !important;
  padding-top:2px;
}
.dropdown-hover:hover{
  background-color: #fff !important;
}
.music-filter-icons{
  background: #E6EAF0;
  padding: 8px;
  border-radius:4px;
  margin-right: 16px;
}
.music-filter-list{
  margin-top: 12px;
}
.last_music_filter_list{
  margin-bottom: 20px;
}
.music-hr-style{
  margin: 0px 20px 0px 20px;
  border: #E6EAF0 !important;
}
.music_filter_svg{
  display: inherit;
}
.done-btn {
  width: calc(100% + 90px);
  margin-left: -20px;
  background-color: #D1433A;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 12px;
  cursor: pointer;
  transition: all .3s;
  margin-bottom: -10px;
}

.switch-field input:checked + label::before {
  content: '';
  position: absolute;
  background-image: url(../../../../../src/assets/img/check-white.png);
  width: 12px;
  height: 9px;
  top: 9px;
  left: 8%;
}

.all_singles .owl-item {
  width: 393px !important;
}

.sort-cehkbox-main input:checked + label::before {
  background-image: url(../../../../../src/assets/img/check-page.svg);
}
</style>