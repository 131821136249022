<template>
  <div class="card genres-list annotation type-list pg-genres-list-2" style="width: 1170px">
    <div class="row no-gutters">
      <div class="col ">
        <div class="cover"></div>
        <div class="type">Type</div>
        <div class="waveform "></div>
        <div class="title">Title</div>
        <div class="album song-albumb-list">Album</div>
        <div class="time">Time</div>
        <div class="date js-genre-date">
          <div class="text">Date</div>
          <div class="svg-icon calendar">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="svg-calendar">
              <path class="c-line-1 c-fill-1"
                    d="M3.52899 1C3.41273 1 3.31851 1.09423 3.31851 1.21047V2.50292C2.95725 2.59752 2.68709 2.92569 2.68709 3.31522C2.68709 3.77768 3.06651 4.15711 3.52899 4.15711C3.99146 4.15711 4.37088 3.77768 4.37088 3.31522C4.37088 2.92569 4.10071 2.59752 3.73946 2.50292V1.21047C3.73946 1.09423 3.64523 1 3.52899 1ZM8.15942 1C8.04316 1 7.94895 1.09423 7.94895 1.21047V2.50292C7.58769 2.59752 7.31752 2.92569 7.31752 3.31522C7.31752 3.77768 7.69695 4.15711 8.15942 4.15711C8.6219 4.15711 9.00132 3.77768 9.00132 3.31522C9.00132 2.92569 8.73114 2.59752 8.36989 2.50292V1.21047C8.36989 1.09423 8.27567 1 8.15942 1ZM2.05567 1.63142C1.47464 1.63142 1 2.10606 1 2.68708V9.20521C1 9.78622 1.47464 10.2609 2.05567 10.2609H9.62616C10.2072 10.2609 10.6818 9.78622 10.6818 9.20521V2.68708C10.6818 2.10606 10.2072 1.63142 9.62616 1.63142H8.99802C8.88683 1.62984 8.78427 1.73069 8.78427 1.8419C8.78427 1.9531 8.88683 2.05394 8.99802 2.05237H9.62616C9.98085 2.05237 10.2609 2.33238 10.2609 2.68708V4.57806H1.42095V2.68708C1.42095 2.33238 1.70095 2.05237 2.05567 2.05237H2.68379C2.79499 2.05395 2.89756 1.9531 2.89756 1.8419C2.89756 1.73069 2.79499 1.62985 2.68379 1.63142H2.05567ZM4.36759 1.63142C4.25134 1.63142 4.15711 1.72565 4.15711 1.8419C4.15711 1.95814 4.25134 2.05237 4.36759 2.05237H7.31423C7.43047 2.05237 7.5247 1.95814 7.5247 1.8419C7.5247 1.72565 7.43047 1.63142 7.31423 1.63142H4.36759ZM3.52899 2.89427C3.76396 2.89427 3.94993 3.08023 3.94993 3.31522C3.94993 3.55019 3.76396 3.73617 3.52899 3.73617C3.29401 3.73617 3.10804 3.55019 3.10804 3.31522C3.10804 3.08023 3.29401 2.89427 3.52899 2.89427ZM8.15942 2.89427C8.39439 2.89427 8.58037 3.08023 8.58037 3.31522C8.58037 3.55019 8.39439 3.73617 8.15942 3.73617C7.92445 3.73617 7.73847 3.55019 7.73847 3.31522C7.73847 3.08023 7.92445 2.89427 8.15942 2.89427ZM1.42095 4.99901H10.2609V9.20521C10.2609 9.55991 9.98085 9.83992 9.62616 9.83992H2.05567C1.70095 9.83992 1.42095 9.55991 1.42095 9.20521V4.99901Z"
                    fill="#47505B" stroke="#47505B" stroke-width="0.4"></path>
            </svg>
          </div>
          <div class="svg-icon">
            <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="svg-arrow-down">
              <path d="M9.83301 1L5.60201 5.3L1.00001 1" class="c-line-4" stroke="#47505B" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
        <div class="popularity">Popularity</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "songOrAlbumHorizontalCard",
  data() {
    return {

    }
  },
  props: {
  }
}
</script>

<style scoped>
.pg-genres-list-2 .cover{
  width: 8.56% !important;
  margin: 0px !important;
  height: 0px !important;
}
.pg-genres-list-2 .type{
  width: 4.3% !important;
  margin: 0px !important;
}
.pg-genres-list-2 .waveform {
  width: 18.6% !important;
  margin: 0px !important;
  height: 0px !important;
}
.pg-genres-list-2 .title{
  width: 11.32% !important;
  margin: 0px !important;
}
.pg-genres-list-2 .album {
  width: 14.7% !important;
  margin: 0px !important;
}
.pg-genres-list-2 .time {
  width: 8.56% !important;
  margin: 0px !important;
}
.pg-genres-list-2 .date {
  width: 8.56% !important;
  margin: 0px !important;
}
.pg-genres-list-2 .popularity {
  width: 7% !important;
  margin: 0px !important;
}

</style>
