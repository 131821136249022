<template>
  <div class="content-sidebar">
    <div class="content-sidebar">
      <div class="post-widget">
        <div class="post-btn btn with-icon" @click="createNewPage">
          <div class="svg-icon">
        <PlusIcon/>
          </div>
          CREATE NEW PAGE
        </div>
      </div>
      <div class="event-filter-widget with-app">
        <div class="widget-title">
          Filters
          <div class="clear-history" @click="clearAllHistory">
            Clear all history
          </div>
        </div>
        <div class="divider"></div>
        <div class="artist-setting-panel" >
          <nav v-if="pageType==='artist' || pageType==='company'">
            <div class="nav nav-tabs custom_tab" id="nav-tab" role="tablist">
              <a
                @click="changeActiveTab('artist')"
                :class="[
                  'nav-item nav-link headers-style',
                  active_tab === 'artist' ? 'active' : '',
                ]"
                id="nav-home-tab"
                data-toggle="tab"
                href="#artist"
                role="tab"
                aria-controls="nav-home"
                :aria-selected="active_tab === 'artist' ? true : false"
                >ARTIST</a
              >
              <a
                @click="changeActiveTab('company')"
                :class="[
                  'nav-item nav-link headers-style',
                  active_tab === 'company' ? 'active' : '',
                ]"
                style="
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                "
                id="nav-contact-tab"
                data-toggle="tab"
                href="#company"
                role="tab"
                aria-controls="nav-contact"
                :aria-selected="active_tab === 'company' ? true : false"
                >INDUSTRY
              </a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div
              :class="[
                'tab-pane fade',
                active_tab === pageType ? 'active show' : '',
              ]"
              :id=pageType
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div class="widget-item">
                <div class="item-title">Category</div>
                <div class="choose-wrapper">
                  <div
                    class="item-choose more-filters-all-js"
                    :class="[all_categories_selected === true ? 'active' : '']"
                    @click="setPagesFilter('Category', 'all', false)"
                  >
                    All Categories

                  </div>
                  <ul class="setting-detail">
                    <li
                      class="main-category-list"
                      v-for="(subCategory, index) in !more_category
                        ? pageSubCategories
                        : pageSubCategories.slice(0, 5)"
                      :key="index"
                      :class="[
                        getSelectedCategory === subCategory.id ? 'active' : '',
                      ]"
                      @click="
                        setPagesFilter(
                          'Category',
                          getSelectedCategory === subCategory.id
                            ? ''
                            : subCategory.id,
                          !!(
                            subCategory.page_specification &&
                            subCategory.page_specification.length > 0
                          )
                        )
                      "
                    >
                      <a
                        class="nav-link main-link"
                        data-toggle="collapse"
                        :href="['#' + subCategory.name]"
                        role="button"
                        aria-expanded="false"
                        aria-controls="multiCollapseExample1"
                      >
                        <span class="font-semi">{{ subCategory.name }}</span>
                        <span
                          v-if="
                            subCategory.page_specification &&
                            subCategory.page_specification.length > 0
                          "
                          class="svg-icon drop-icon ml-auto sublist-arrow"
                        >
                          <svg
                            width="13"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.2651 1.50488L6.58614 6.26488L1.49514 1.50488"
                              stroke="#D1433A"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </a>
                      <div class="submenu-set">
                        <div
                          class="multi-collapse collapse"
                          :id="[subCategory.name]"
                          style=""
                        >
                          <div class="card card-body drop-subitem">
                            <ul class="subitem-list">
                              <li
                                class="nav-item p-0 sub-list"
                                v-for="(specification, index) in subCategory.page_specification"
                                :key="index"
                                :class="[getSelectedSubCategory === specification.id ? 'active' : '']">
                                <a class="d-flex align-items-center" @click="setPageSubCategory(subCategory.id, specification.id)">
                                  <span> {{ specification.name }}</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
             
                  <div
                    class="more-filters"
                    data-toggle="collapse"
                    data-target="#filters1"
                    aria-expanded="false"
                    aria-controls="filters1"
                    @click="showMoreAndLess('category')"
                  >
                    {{
                      more_category
                        ? "More(" + (pageSubCategories.length - 5) + ")"
                        : "Less"
                    }}
                  </div>
                </div>
              </div>
              <div class="divider"></div>
              <div class="widget-item" v-if="pageType !='company'">
                <div class="item-title">Genres</div>
                <div class="row search-row_border width330">
                  <div class="col-11 p-0">
                    <v-select
                      class="v-select-main-class v-select-without-border"
                      label="name"
                      :filterable="true"
                      v-model="SelectedGenre"
                      :options="genres"
                      :reduce="(option) => option.id"
                      placeholder="Search genres"
                      @input="setPagesFilter('Genre', SelectedGenre)"
                    >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                  <div class="col-1 p-0 d-flex align-items-center">
                    <div class="svg-icon">
                  <SearchIcon/>
                    </div>
                  </div>
                </div>
                <div class="choose-wrapper">
                  <div
                    class="item-choose more-filters-all-js"
                    :class="[all_genres_selected === true ? 'active' : '']"
                    @click="setPagesFilter('Genre', 'all', true)"
                  >
                    All Genres
                    <div class="point"></div>
                  </div>
                  <div
                    v-for="(genre, index) in !more_genre
                      ? genres
                      : genres.slice(0, 3)"
                    :key="index"
                    class="item-choose more-filters-js"
                    :class="[SelectedGenre === genre.id ? 'active' : '']"
                    @click="
                      setPagesFilter('Genre', (SelectedGenre === genre.id) ? '' : genre.id, false)
                    "
                  >
                    {{ genre.name }}
                    <div class="point"></div>
                  </div>
                  <div
                    class="more-filters"
                    data-toggle="collapse"
                    data-target="#filters1"
                    aria-expanded="false"
                    aria-controls="filters1"
                    @click="showMoreAndLess('genres')"
                  >
                    {{
                      more_genre ? "More(" + (genres.length - 3) + ")" : "Less"
                    }}
                  </div>
                </div>
              </div>

              
              <div class="divider"></div>


              <div class="widget-item">
                <div class="item-title with-checkbox">Location</div>
                <div
                  class="custom-control custom-checkbox"
                  v-for="item in locations"
                  :key="item.id"
                  @click="setPagesFilter(item.location)"
                  
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    checked="checked"
                    v-model="item.active"
                    :id="item.id"
                  />
                  <label class="custom-control-label check" :for="item.id"
                  >{{ item.value }}</label
                  >
                </div>
            
                <!-- <div class="collapse multi-collapse p-0" id="filters3">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c5"
                      name="c5"
                    />
                    <label class="custom-control-label check" for="c5"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c6"
                      name="c6"
                    />
                    <label class="custom-control-label check" for="c6"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c7"
                      name="c7"
                    />
                    <label class="custom-control-label check" for="c7"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c7"
                      name="c7"
                    />
                    <label class="custom-control-label check" for="c7"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c8"
                      name="c8"
                    />
                    <label class="custom-control-label check" for="c8"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c8"
                      name="c8"
                    />
                    <label class="custom-control-label check" for="c8"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c9"
                      name="c9"
                    />
                    <label class="custom-control-label check" for="c9"
                      >RU, Moscow</label
                    >
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="c10"
                      name="c10"
                    />
                    <label class="custom-control-label check" for="c10"
                      >RU, Moscow</label
                    >
                  </div>
                </div> -->


                <div class="row search-row_border fixLocationField">
                  <div
                    class="
                      col-1
                      p-0
                      d-flex
                      align-items-center
                      justify-content-end
                    "
                  >
                    <div class="svg-icon">
                   <LocationIcon/>
                    </div>
                  </div>
                  <div class="col-10 p-0">
                    <GoogleMapAndLocation @setLocationFilter="setPagesFilter" @getLocation="getLocation" />
                  </div>
                  <div class="col-1 p-0 d-flex align-items-center">
                    <div class="svg-icon">
                    <SearchIcon/>

                    </div>
                  </div>
                </div>
              </div>
             
              <div class="divider"></div>
              <div class="widget-item">
                <div class="item-title with-checkbox">Sort</div>
                <ul class="sort-cehkbox">
                  <li>
                    <div class="sort-cehkbox-main">
                      <input
                        type="radio"
  id="most_viewed"
  name="radio-group"
  :checked="getSelectedSort === 'most_viewed'"
  @click="setPagesFilter('sort', 'most_viewed')"
                      />
                      <label for="most_viewed"><span>Most Viewed</span></label>
                    </div>
                  </li>
                  <li>
                    <div class="sort-cehkbox-main">
                      <input
  type="radio"
  id="latest"
  name="radio-group"
  :checked="getSelectedSort === 'latest'"
  @click="setPagesFilter('sort', 'latest')"
/>
<label for="latest"><span>Latest</span></label>
                    </div>
                  </li>
                  <li>
                    <div class="sort-cehkbox-main">
                      <input
                        id="high"
                        type="radio"
  name="radio-group"
  :checked="getSelectedSort === 'high'"
  @click="setPagesFilter('sort', 'high')"
                      />
                      <label for="high"><span>High Rating</span></label>
                    </div>
                  </li>
                  <li>
                    <div class="sort-cehkbox-main">
                      <input
                        type="radio"
                        id="low"
                        name="radio-group"
  :checked="getSelectedSort === 'low'"
  @click="setPagesFilter('sort', 'low')"
                      />
                      <label for="low"><span>Low Rating</span></label>
                    </div>
                  </li>
                </ul>
              </div>

              
            </div>

         
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_geners_list } from "../../../../apis/APIs";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "/public/css/vue-select-style.scss";
import GoogleMapAndLocation from "../../../Common/Google Location/GoogleMapAndLocation";
import { mapActions } from "vuex";
import PlusIcon from "../../../../assets/svgs/PlusIcon.vue";
import SearchIcon from "../../../../assets/svgs/SearchIcon.vue";
import LocationIcon from "../../../../assets/svgs/LocationIcon.vue";

export default {
  name: "ExplorePagesFilters",
  data() {
    return {
      all_categories_selected: false,
      all_genres_selected: false,
      genres: [],
      active_tab: "",
      more_category: true,
      more_genre: true,
      locations:[],
      checkLocationId:null
    };
  },
  components: {
    GoogleMapAndLocation,
    vSelect,
    PlusIcon,
    SearchIcon,
    LocationIcon
  },
  watch: {
    pageType() {
      this.active_tab = this.pageType;
      this.fetchPagesData();
    },
  },
  async created() {
    await this.getSearchHistoryCityContry();
    this.locationsData();

  },
  computed: {
    pageType() {
      return this.$store.getters.getPagesType;
    },
    pageSubCategories() {
      const categories = this.$store.getters.getPageCategories;
      const type = (this.pageType ) ? this.pageType.charAt(0).toUpperCase() + this.pageType.slice(1) : null
      const index = categories.findIndex(
        (category) => category.name === type
      );
      if (index !== -1) {
        return categories[index].page_sub_category;
      }
      return [];
    },
    getSelectedCategory() {
      return this.$store.getters.getSelectedCategory;
    },
    getSelectedSubCategory() {
      return this.$store.getters.getSelectedSubCategory;
    },
    getSelectedLocation() {
      return this.$store.getters.getSelectedLocation;
    },
    SelectedGenre: {
      set(value) {
        this.$store.commit("SET_SELECTED_GENRE", value);
      },
      get() {
        return this.$store.getters.getSelectedGenre;
      },
    },
    getSelectedSort() {
      return this.$store.getters.getSelectedSort;
    },
  },
  mounted() {
    this.active_tab = this.pageType;
    this.getGenreList();
  },
  methods: {
    createNewPage: async function () {
      if(this.pageType ==='artist' || this.pageType ==='company')
      {
        this.$store.commit("SET_PAGES_TYPE", "");
      } else  {
        this.$store.commit("SET_PAGES_TYPE", this.pageType);
      }

      const pathname = `/page-manager/page/create-new-page`;
      await this.$router.push(pathname);
    },
    setPagesFilter: async function (type, value, check = false) {
      
      if (type === "Category") {
        if (value === 'all' && !check) {
          if (this.all_categories_selected) {
            this.all_categories_selected = false;
          } else {
            this.all_categories_selected = true;
          }
        } else {
          this.all_categories_selected = false;
        }
        this.$store.commit("SET_SELECTED_CATEGORY", (value === 'all') ? '' : value);
      } else if (type === "Genre") {
        if (value === 'all' && !check) {
          if (this.all_genres_selected) {
            this.all_genres_selected = false;
          } else {
            this.all_genres_selected = true;
          }
        } else {
          this.all_genres_selected = false;
        }
        this.$store.commit("SET_SELECTED_GENRE", (value === 'all') ? '' : value);
      } else if (type === "location") {
      console.log('sdaffdsfsdasdaf ', type ,value);

        this.$store.commit("SET_SELECTED_LOCATION", value);
      } else if (type === "sort") {
        this.$store.commit("SET_SELECTED_SORT", value);
      }
      if (!check) {
        this.fetchPagesData();
      }
    },
    setPageSubCategory: async function (category_id, sub_category_id) {
      this.$store.commit("SET_SELECTED_SUBCATEGORY", sub_category_id);
      this.fetchPagesData();
    },
    async getGenreList() {
      try {
        const res = await get_geners_list();
        const { success, information } = res.data || {};
        if (success === 1) {
          if (information && information.length) {
            this.genres = information.map((ele) => ({
              id: ele.id,
              name: ele.name,
            }));
          }
        }
      } catch (error) {}
    },
    fetchPagesData(showMore = false) {
      if(this.$route.params.type === 'pages') {
        this.$store.commit("SET_PAGE_ACTIVE_TAB", "music");
      }
      this.$store.dispatch("loading", true);
      this.$store.dispatch("getPageCategories");
      this.$store.commit("CLEAR_PAGES_DATA");
      this.$store.dispatch("getAllPages", showMore);
      this.$store.dispatch("getLikedPages", showMore);
      this.$store.dispatch("getMyPages", showMore);
      this.$store.dispatch("loading", false);
    },
    clearAllHistory: async function () {
      this.all_genres_selected = "";
      this.all_categories_selected = "";
      this.more_category = true;
      this.more_genre = true;
      this.$refs.google_location.clearInputFiled();
      await this.$store.commit("SET_SELECTED_FILTERS_EMPTY");
      await this.fetchPagesData();
    },
    changeActiveTab(tab) {
      this.active_tab = tab;
      this.$store.commit("SET_PAGES_TYPE", tab);
      this.clearAllHistory();
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    showMoreAndLess(type) {
      if (type === "category") {
        this.more_category = !this.more_category;
      } else if (type === "genres") {
        this.more_genre = !this.more_genre;
      }
    },
    ...mapActions([
      "getSearchHistoryCityContry",
      "addSearchHistoryCityContry",
    ]),
    locationsData() {
      this.user_location = {
        city: this.$store.getters.user_map_to_props.user_data.current_city,
        country:
        this.$store.getters.user_map_to_props.user_data.country[0].name,
      };
      function location(loc) {
        return loc.city && loc.country
          ? loc.city + "," + loc.country
          : !loc.city && loc.country
            ? loc.country
            : loc.city && !loc.country
              ? loc.city
              : "";
      }
      this.locations = [
        {
          id: 0,
          value: "All",
          active: true,
          location: { country: "", city: "" },
        },
        {
          id: 1,
          value: location(this.user_location),
          active: false,
          location: this.user_location,
        },
        {
          id: 2,
          value: location(this.$store.getters.GET_END_SEARCH_CITY_CONTRY),
          active: false,
          location: this.$store.getters.GET_END_SEARCH_CITY_CONTRY,
        },
      ];
    },
    async getLocation(data) {
      await this.addSearchHistoryCityContry({
        city: data.city,
        country: data.country,
      });
      await this.getSearchHistoryCityContry();
      this.locationsData();
    },
  },
};
</script>

<style scoped>
.clear-history {
  cursor: pointer;
}

.artist-setting-panel {
  padding: 1rem !important;
}

.widget-item {
  padding: 21px 4px 17px !important;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.search-row_border {
  border: 1px solid #e6eaf0;
  margin-left: 0.1rem !important;
  margin-right: 0.1rem !important;
  margin-bottom: 0.5rem !important;
  border-radius: 4px;
}

.setting-detail li {
  padding: 0px;
}

.font-semi {
  margin-left: 1.2rem !important;
}

.setting-detail {
  padding: 0px;
}

.sublist-arrow {
  margin-right: 1rem;
}

.sub-list {
  margin-left: 0.3rem !important;
  margin-right: 0.3rem !important;
  margin-bottom: 0;
}

.sub-list.active {
  background: #D1433A1A;
  border-radius: 2px;
  transition: all 0.3s;
  color: #D1433A;
}

.submenu-set {
  width: 100%;
}

.main-category-list {
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  font-family: HelveticaNeueMedium, sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #071526;
  transition: all 0.3s;
}

.main-category-list.active {
  background: #D1433A1A;
  border-radius: 2px;
  transition: all 0.3s;
  color: #D1433A;
}

.main-category-list.active svg {
  transform: rotate(90deg);
}

.main-category-list .point {
  opacity: 0;
  transition: all 0.3s;
  width: 6px;
  height: 6px;
  background-color: #D1433A;
  border-radius: 100%;
}

.main-category-list.active .point {
  transition: all 0.3s;
  display: flex;
  opacity: 1;
}
</style>

<!--Without Scoped-->
<style>
.v-select .vs__dropdown-menu {
  margin-top: 0.1rem !important;
}
.setting-detail {
  margin-bottom: auto !important;
}
</style>
