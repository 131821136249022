<template>
  <div class="ag-container">
<!--    <MusicSubHeader/>-->
    <div class="container_xxl">
      <div class="artist_body m-auto">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0.8937C0.00164704 0.65734 0.0962102 0.431116 0.263262 0.263896C0.430313 0.0966761 0.656442 0.00188518 0.8928 0H17.1072C17.6004 0 18 0.4005 18 0.8937V15.3063C17.9984 15.5427 17.9038 15.7689 17.7367 15.9361C17.5697 16.1033 17.3436 16.1981 17.1072 16.2H0.8928C0.655932 16.1998 0.428847 16.1055 0.26144 15.9379C0.0940338 15.7703 -1.2011e-07 15.5432 0 15.3063V0.8937ZM3.6 10.8V12.6H14.4V10.8H3.6ZM3.6 3.6V9H9V3.6H3.6ZM10.8 3.6V5.4H14.4V3.6H10.8ZM10.8 7.2V9H14.4V7.2H10.8ZM5.4 5.4H7.2V7.2H5.4V5.4Z" fill="#47505B"/>
                </svg>

                About & Skill
              </div>
              <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
                        <span class="pr-2">
                          <EyeIcon />
                        </span>
                View Profile
              </button>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <b-form @submit.prevent="updateAboutAndSkill">
                  <div class="artist-setting-panel">
                    <div class="row">
                      <div class="col-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Title <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="name-your-page"
                              v-model="$v.aboutAndSkillsForm.title.$model"
                              :state="validateState('title')"
                              placeholder="Your Title, “DJ Mastering” for example.  "
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                              id="name-your-page"
                          >This is a required field.
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row" v-if="selectedSubCategorySpecification.length>0">
                      <div class="col-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Instruments <span class="text-danger">*</span>
                          </template>
                          <Select2 v-model="$v.aboutAndSkillsForm.specification_id.$model"
                                   :key="'specification_id' + errorsList['specification_id']"
                                   :class="{'is-invalid':errorsList['specification_id']}"
                                   placeholder="Select Instrument"
                                   :state="validateState('specification_id')"
                                   :options="selectedSubCategorySpecification"
                                   :settings="{ multiple: true, tags: true }"/>
                          <div class="invalid-feedback d-block" v-if="errorsList['specification_id']">
                            This is a required field.
                          </div>

                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            What genre best describes your sound as an Artist?
                          </template>
                          <Select2 v-model="$v.aboutAndSkillsForm.genre.$model"
                                   id="genre"
                                   placeholder="Choose "
                                   :key="'specification_id' + errorsList['genre']"
                                   :class="{'is-invalid':errorsList['genre']}"
                                   :state="validateState('genre')"
                                   :options="genres"
                                   :settings="{ multiple: true, tags: true }"/>
                          <div class="invalid-feedback d-block" v-if="errorsList['genre']">
                            This is a required field.
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Your Sub-Genres <small class="text-muted">(List 3)</small>
                          </template>
                          <Select2 v-model="$v.aboutAndSkillsForm.sub_genre.$model"
                                   id="sub-genre"
                                   placeholder="Choose subGenres"
                                   :state="validateState('sub_genre')"
                                   :options="selectedGenresSubGenres"
                                   :settings="{ multiple: true, tags: true }"/>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Tools
                          </template>
                          <Select2 v-model="$v.aboutAndSkillsForm.tools.$model" placeholder="Tools"
                                   id="tools"
                                   :options="tools"
                                   :key="'specification_id' + errorsList['tools']"
                                   :class="{'is-invalid':errorsList['tools']}"
                                   :state="validateState('tools')"
                                   :settings="{ multiple: true, tags: true }"/>
                          <div class="invalid-feedback d-block" v-if="errorsList['tools']">
                            This is a required field.
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <b-form-group
                            label-for="name-your-page"
                            class="artist-page-form-field"
                        >
                          <template v-slot:label>
                            Languages
                          </template>
                          <Select2 v-model="$v.aboutAndSkillsForm.languages.$model" placeholder="Choose categories"
                                   id="languages"
                                   :options="languages"
                                   :key="'specification_id' + errorsList['languages']"
                                   :class="{'is-invalid':errorsList['languages']}"
                                   :state="validateState('languages')"
                                   :settings="{ multiple: true, tags: true }"/>
                          <div class="invalid-feedback d-block" v-if="errorsList['languages']">
                            This is a required field.
                          </div>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button class="prime_button" type="submit">Save Changes</button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePageModal v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
import MusicSubHeader from '@/components/PageManager/PMSubHeader.vue'
import DeletePageModal from '../DeletePageModal'
import Select2 from 'v-select2-component';
import {required, minLength, requiredIf} from "vuelidate/lib/validators";
import {
  get_geners_list,
  get_languages_api,
  getToolsOfCurrentPage,
  updatePageAboutAndSkills
} from "../../../../apis/APIs";
import Vue from "vue";
import {state} from "../../../../store/user.module";
import EyeIcon from '../../../../assets/svgs/EyeIcon.vue';

export default {
  data() {
    return {
      deletePage: false,
      skills: [],
      aboutAndSkillsForm: {
        title: null,
        specification_id: [],
        genre: [],
        sub_genre: [],
        tools: [],
        languages: []
      },
      genres: [],
      languages: [],
      categories: [],
      subCategories: [],
      specifications: [],
      profileAndCoverModel: false,
      profileAndCoverModelType: '',
      errorsList:[],
      tools:[]
    }
  },
  components: {
    // MusicSubHeader,
    EyeIcon,
    DeletePageModal,
    Select2
  },
  computed: {
    pageCategories() {
      return this.$store.getters.getPageCategories.map(category => {
        return {
          value: category.id,
          text: category.name,
          subCategories: category.page_sub_category
        }
      })
    },
    selectedCategorySubCategories() {
      if (this.pageCategory !== null) {
        const index = this.pageCategories.findIndex(category => category.value === this.pageCategory)
        if (index !== -1) {
          return this.pageCategories[index].subCategories.map(category => {
            return {
              value: category.id,
              text: category.name,
              specification: category.page_specification
            }
          })
        }
      }
      return []
    },
    selectedSubCategorySpecification() {
      if (this.pageSubCategory !== null) {
        const index = this.selectedCategorySubCategories.findIndex(category => category.value === this.pageSubCategory)
        if (index !== -1) {
          return this.selectedCategorySubCategories[index].specification.map(category => {
            return {
              id: category.id,
              text: category.name,
            }
          })
        }
      }
      return []
    },
    pageType(){
      return this.current_page.type
    },
    createPageIcon() {
      if (this.pageType === 'Clubs') {
        return require(`@/assets/img/svg/create_club_page.svg`)
      } else if (this.pageType === 'Company') {
        return require(`@/assets/img/svg/create_company_page.svg`)
      } else {
        return require(`@/assets/img/user-1.png`)
      }
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    pageSubCategory() {
      return (this.current_page) ? this.current_page.page_subcategory_id : null
    },
    pageCategory() {
      return (this.current_page) ? this.current_page.page_category_id : null
    },
    pageSpecification() {
      return (this.current_page) ? this.current_page.specification_id : null
    },
    selectedGenresSubGenres() {
      if (this.aboutAndSkillsForm.genre.length > 0) {
        let subGenres = []
        for (const genreId of this.aboutAndSkillsForm.genre) {
          const index = this.genres.findIndex(e => e.id === Number(genreId))
          if (index !== -1) {
            subGenres = [...subGenres, ...this.genres[index].subgenre]
          }
        }
        return subGenres.map(genre => {
          return {
            id: genre.id,
            text: genre.name
          }
        })
      }
      return []
    }
  },
  validations: {
    aboutAndSkillsForm: {
      title: {
        required
      },
      specification_id: {
        required: requiredIf(function (nestedModel) {
          return this.selectedSubCategorySpecification.length > 0
        })
      },
      genre: {
        required,
        maxLength: minLength(1)
      },
      sub_genre: {
      },
      tools: {
        required,
      },
      languages: {
        required,
        maxLength: minLength(1)
      }
    }
  },
  watch: {
    current_page(val) {
      this.getGenreList()
      this.fetchLanguagesData()
      this.getPageDataFromStore()
    }
  },
  methods: {
    closeModal: function () {
      this.deletePage = false;
    },
    validateState(name) {
      if(name === 'sub_genre'){
        return null
      }
      const {$dirty, $error} = this.$v.aboutAndSkillsForm[name];
      Vue.set(this.errorsList, name, $error);
      return $dirty ? !$error : null;
    },
    async updateAboutAndSkill() {
      this.$v.aboutAndSkillsForm.$touch();
      if (this.$v.aboutAndSkillsForm.$anyError) {
        return;
      } else {
        try {
          await this.$store.dispatch('loading', true)

          const formData = new FormData();
          for (const field in this.aboutAndSkillsForm) {
            if (Array.isArray(this.aboutAndSkillsForm[field])) {
              if(field === 'sub_genre' && this.aboutAndSkillsForm[field].length <= 0){
                formData.append(field + '[' + 0 + ']', [])
              }
              if (this.aboutAndSkillsForm[field].length > 0) {
                for (let i = 0; i < this.aboutAndSkillsForm[field].length; i++) {
                  formData.append(field + '[' + i + ']', this.aboutAndSkillsForm[field][i]);
                }
              }
            } else {
              if (!!(this.aboutAndSkillsForm[field])) {
                formData.append(field, this.aboutAndSkillsForm[field]);
              }
            }

          }
          const {data} = await updatePageAboutAndSkills(this.current_page.id,formData);
          const {success,message} = data||{}
          await this.$store.dispatch('loading', false)
          if (success === 1) {
            const page = data.information || {};
            this.$store.commit('SET_PAGE_DATA',page)
            await this.makeToast('success', 'Page!', message)
          } else {
            await this.makeToast('error', 'Page!', message)
          }
        } catch (e) {
          await this.$store.dispatch('loading', false)
          await this.makeToast('error', 'Page!', e.message)
        }

      }
    },
    async getGenreList() {
      try {

        const res = await get_geners_list();
        const {success, information} = res.data || {};
        if (success === 1) {
          if (information && information.length) {
            this.genres = information.map(ele => ({id: ele.id, text: ele.name, subgenre: ele.subgenre}));
          }
        }

      } catch (error) {

      }
    },
    async fetchLanguagesData() {
      try {
        this.list_loading = true;
        const res = await get_languages_api();
        const {success, message, data} = res.data || {};
        this.list_loading = false;

        if (success === 1) {
          if (data && data.length) this.languages = data.map(ele => ({id: ele.id, text: ele.native_name}));
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    getPageDataFromStore(){
      this.aboutAndSkillsForm.title = (this.current_page && this.current_page.about_title) ? this.current_page.about_title : ''
      this.aboutAndSkillsForm.languages = this.current_page.languages.map(language=>{
        return language.id
      })
      this.$v.aboutAndSkillsForm.genre.$model = this.current_page.genres.map(genre=>{
        return genre.id
      })
      this.$v.aboutAndSkillsForm.sub_genre.$model = this.current_page.sub_genres.map(sub_genres=>{
        return sub_genres.id
      })
      this.aboutAndSkillsForm.specification_id = this.current_page.page_specification.map(sub_genres=>{
        return sub_genres.id
      })
      this.aboutAndSkillsForm.tools = this.current_page.tools.map((e) => {
        return e.id
      })
    },
    async getPagesTools() {
      try {
        const {data} = await getToolsOfCurrentPage()
        const {success, information} = data || {}
        if (success === 1) {
          this.tools = information.map((e) => {
            return {
              id: e.id,
              text: e.title
            }
          })
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  created() {
    this.$store.dispatch('getPageCategories')
    this.getGenreList()
    this.fetchLanguagesData()
    this.getPagesTools()
    this.getPageDataFromStore()
  },
  mounted() {

  }
}
</script>
<style lang="scss">
.category-select::after {
  background-image: url(../../../../assets/img/artist-select.png);
}

.container-checkbox .week-checkmark::after {
  background-image: url(../../../../assets/img/setting-checkbox.png);
}

.is-invalid{
  .select2{
    .select2-selection{
      border-color: #dc3545 !important;
      padding-right: calc(1.5em + .75rem)!important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(.375em + .1875rem) center;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
  }
}

</style>
<style lang="scss" scoped>
.ag-container {
  width: 100%;
  padding: 0;
}
.panel_wrapper {
  margin-top: 59px;
}
</style>
