<template>
  <div class="modal fade modal-add-to-cart contact_info create-new-ticket job-invite show" id="request-refund"
       tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true"
       style="padding-right: 15px; display: block;">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="item-title">
          <div class="short-border">
            Request Refund
          </div>
        </div>
        <div class="refund-info-notify">
          <div class="notyf-title">Information</div>
          <div class="notyf-subtitle">We’re check your problem and reach you with message</div>
        </div>
        <div class="m-b-17">
          <div class="input-title">Problem</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100">
            <select class="filters-select selectpicker w-100" title="Overdue" multiple="" data-max-options="1"
                    tabindex="-98">
              <option value="1">Overdue</option>
              <option value="2">Overdue</option>
              <option value="3">Overdue</option>
            </select>
            <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox"
                    aria-owns="bs-select-35" aria-haspopup="listbox" aria-expanded="false" title="Overdue">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">Overdue</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu" x-placement="top-start"
                 style="max-height: 227px; overflow: hidden; min-height: 0px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -36px, 0px);"
                 x-out-of-boundaries="">
              <div class="inner show" role="listbox" id="bs-select-35" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 209px; overflow-y: auto; min-height: 0px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-35-0" tabindex="0" aria-selected="false"
                         aria-setsize="3" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Overdue</span></a></li>
                  <li class="selected"><a role="option" class="dropdown-item selected" id="bs-select-35-1" tabindex="0"
                                          aria-selected="true" aria-setsize="3" aria-posinset="2"><span
                      class=" bs-ok-default check-mark"></span><span class="text">Overdue</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-35-2" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Overdue</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="m-b-17">
          <div class="input-title">Invoice</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100">
            <select class="filters-select selectpicker w-100" title="Invoice for 08/03/2020-08/09/2020 - 30:00 hrs..."
                    multiple="" data-max-options="1" tabindex="-98">
              <option value="1">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</option>
              <option value="2">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</option>
              <option value="3">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</option>
            </select>
            <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox"
                    aria-owns="bs-select-36" aria-haspopup="listbox" aria-expanded="false"
                    title="Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu" x-placement="top-start"
                 style="max-height: 157px; overflow: hidden; min-height: 0px; position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -106px, 0px);"
                 x-out-of-boundaries="">
              <div class="inner show" role="listbox" id="bs-select-36" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 139px; overflow-y: auto; min-height: 0px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-36-0" tabindex="0" aria-selected="false"
                         aria-setsize="3" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</span></a></li>
                  <li class="selected"><a role="option" class="dropdown-item selected" id="bs-select-36-1" tabindex="0"
                                          aria-selected="true" aria-setsize="3" aria-posinset="2"><span
                      class=" bs-ok-default check-mark"></span><span class="text">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</span></a>
                  </li>
                  <li><a role="option" class="dropdown-item" id="bs-select-36-2" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Invoice for 08/03/2020-08/09/2020 - 30:00 hrs...</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="m-b-17">
          <div class="input-title">Refund Request</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100">
            <select class="filters-select selectpicker w-100" title="Custom Amount" multiple="" data-max-options="1"
                    tabindex="-98">
              <option value="1">Custom Amount</option>
              <option value="2">Custom Amount</option>
              <option value="3">Custom Amount</option>
            </select>
            <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                    role="combobox" aria-owns="bs-select-37" aria-haspopup="listbox" aria-expanded="false"
                    title="Custom Amount">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">Custom Amount</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu"
                 style="max-height: 102px; overflow: hidden; min-height: 0px; position: absolute; will-change: transform; min-width: 1351px; top: 0px; left: 0px; transform: translate3d(0px, -176px, 0px);"
                 x-placement="top-start" x-out-of-boundaries="">
              <div class="inner show" role="listbox" id="bs-select-37" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 84px; overflow-y: auto; min-height: 0px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-37-0" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Custom Amount</span></a>
                  </li>
                  <li><a role="option" class="dropdown-item" id="bs-select-37-1" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Custom Amount</span></a>
                  </li>
                  <li><a role="option" class="dropdown-item" id="bs-select-37-2" tabindex="0"
                         aria-selected="false"><span class=" bs-ok-default check-mark"></span><span class="text">Custom Amount</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="input-title">Refund Request</div>
          <div class="input-group  for-how-much m-b-0">
            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                   placeholder="How much you want to refund">
            <div class="input-group-append"><span class="input-group-text">$</span></div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-title">Your Message</div>
          <textarea name="aaa" class="custom-textarea" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="action-item">
          <div class="btn back-btn">Cancel</div>
          <div class="btn check-btn">Send</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
