var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container_xxl"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"artist-page-left"},[_c('div',{staticClass:"artist-page-form"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"artist-page-form-field m-t-50"},[_c('label',[_vm._v("Cover Image")]),_c('div',{staticClass:"upload-artist-cover"},[_c('img',{attrs:{"src":require(`@/assets/img/upload.png`)}}),_c('input',{attrs:{"type":"file","id":"myfile","name":"myfile"}})])]),_vm._m(5)])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"create-new-page-section"},[_c('div',{staticClass:"create-new-page-section-detail"},[_c('span',{staticClass:"page-placeholder"},[_c('img',{attrs:{"src":require(`@/assets/img/file-text.png`)}})]),_c('h1',[_vm._v("Creating New Page")]),_c('p',[_vm._v("Set up your artist page or business page ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt_40 mb_40"},[_c('div',{staticClass:"artist-page-heading text-center"},[_vm._v("Pages")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artist-page-form-field"},[_c('label',[_vm._v("Name your Page"),_c('sup',{staticClass:"sup-color"},[_vm._v("*")])]),_c('input',{attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artist-page-form-field"},[_c('label',[_vm._v("Page Address")]),_c('div',{staticClass:"page-address-artist"},[_c('span',{staticClass:"email-ico"},[_vm._v(" www.audioground.com/ ")]),_c('input',{attrs:{"type":"text","placeholder":"youraddress"}})]),_c('span',{staticClass:"sm-text"},[_vm._v("Can only contain alphanumeric characters (A–Z, 0–9) and periods ('.')")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artist-page-form-field category-field"},[_c('div',{staticClass:"categories-field p-r-field"},[_c('label',[_vm._v("Category"),_c('sup',{staticClass:"sup-color"},[_vm._v("*")])]),_c('div',{staticClass:"category-select"},[_c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Choose category")]),_c('option',[_vm._v("Choose-category1")]),_c('option',[_vm._v("Choose-category2")])])])]),_c('div',{staticClass:"categories-field p-l-field"},[_c('label',[_vm._v("SubCategory"),_c('sup',{staticClass:"sup-color"},[_vm._v("*")])]),_c('div',{staticClass:"category-select"},[_c('select',{staticClass:"form-control"},[_c('option',[_vm._v("Choose category")]),_c('option',[_vm._v("Choose-category1")]),_c('option',[_vm._v("Choose-category2")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artist-page-form-field about_textarea"},[_c('label',[_vm._v("About")]),_c('textarea')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publish-btn"},[_c('button',[_vm._v("PUBLISH")])])
}]

export { render, staticRenderFns }