<template>
  <div class="friend-main-div">
    <div
      v-for="(notification, index) in friendRequestNotifications.slice(0, 8)"
      :key="index"
    >
      <div v-for="(activity, index1) in notification.activities" :key="index1">
        <div v-if="notification.verb === 'friend_request_sent' && !notification.is_read && activity.status === 'pending'" class="friend-new d-flex flex-row mb-2">
          <div class="cover-image">
            <div class="img-wrapper">
              <img :src="activity.user.artwork_url" alt="" />
            </div>
          </div>
          <div class="content-block f-flex flex-column justify-content-between">
            <div class="title">
              {{
                activity &&
                activity.user &&
                activity.user.full_name
                  ? activity.user.full_name
                  : activity &&
                  activity.user &&
                  activity.user.name
                    ? activity.user.name
                    : "User Name"
              }}
            </div>
            <div class="subtitle">
              {{
                activity.user.mutual_friends
                  ? activity.user.mutual_friends
                  : "No"
              }}
              mutual friends
            </div>
            <div class="d-flex flex-row justify-content-between friends-btn">
              <div
                class="btn btn-accept"
                @click="
                acceptRejectFriendRequest(
                  activity.request_id,
                  1,
                  notification.id
                )
              "
              >
                Accept
              </div>
              <div
                class="btn btn-delete"
                @click="
                acceptRejectFriendRequest(
                  activity.request_id,
                  0,
                  notification.id
                )
              "
              >
                Decline
              </div>
            </div>
          </div>
        </div>
        <div v-if="(notification.verb === 'friend_request_accepted' && activity.status === 'confirmed') || (notification.verb === 'friend_request_sent' && activity.status === 'confirmed')" class="friend-new d-flex flex-row mb-2">
          <div class="cover-image">
            <div class="img-wrapper">
              <img
                :src="
                activity &&
                activity.user
                  ? activity.user.artwork_url
                  : ''
              "
                alt=""
              />
            </div>
          </div>
          <div
            class="content-block f-flex flex-column justify-content-between"
            style="width: 100%"
          >
            <div class="title">
              {{ (activity && notification.activities.length > 0 && activity.user) ? activity.user.name : "User Name" }}
            </div>
            <div class="subtitle">
              {{ (activity.user.mutual_friends) ? activity.user.mutual_friends : "No"}}
              mutual friends
            </div>
            <div class="d-flex fr-btn" style="width: 100%">
              <button class="ml-auto">Friends</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { responseFriendRequest } from "../../../apis/APIs";

export default {
  name: "FriendRequestNotificationsList",
  props: {
    friendRequestNotifications: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async acceptRejectFriendRequest(request_id, action, notification_id) {
      let payload = {
        request_id: request_id,
        action: action,
      };
      await responseFriendRequest(payload);
      await this.notificationAsRead(notification_id);
      await this.$store.commit("SET_NOTIFICATIONS_TO_EMPTY");
      await this.getNotification();
    },
  },
};
</script>

<style scoped lang="scss">
.friend-main-div {
  .friend-new {
    border-radius: 4px;
    .content-block {
      position: relative;
      .title {
        font-family: "HelveticaNeueBold";
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #071526;
        opacity: 0.8;
      }
      .subtitle {
        font-family: "HelveticaNeueNormal";
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.5px;
        color: #071526;
      }
    }
  }
}
.cover-image {
  .img-wrapper {
    img {
      width: inherit;
    }
  }
}
.background-color {
  background: #dfe4f6;
}
</style>
