<template>
  <div class="for_forums_width">
    <div class="row">
      <div class="col">
        <div class="m-t-30">
          <div class="wrap_thread">
            <div class="wrap_name">
              <div class="wr_thread_info">
                <div class="name_Thread">
                  {{ GET_THREAD_DATA.thread.title }}
                </div>
                <div class="thread_info">
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.6667 0.75H1.33332C0.685823 0.75 0.166656 1.26917 0.166656 1.91667V10.0833C0.166656 10.7308 0.685823 11.25 1.33332 11.25H10.6667C11.3142 11.25 11.8333 10.7308 11.8333 10.0833V1.91667C11.8333 1.26917 11.3142 0.75 10.6667 0.75ZM1.91666 3.08333H4.83332V6.58333H1.91666V3.08333ZM10.0833 8.91667H1.91666V7.75H10.0833V8.91667ZM10.0833 6.58333H5.99999V5.41667H10.0833V6.58333ZM10.0833 4.25H5.99999V3.08333H10.0833V4.25Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ GET_THREAD_DATA.forum.name }}
                  </div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.00002 0.166504C2.78352 0.166504 0.166687 2.78334 0.166687 5.99984C0.166687 9.21634 2.78352 11.8332 6.00002 11.8332C9.21652 11.8332 11.8334 9.21634 11.8334 5.99984C11.8334 2.78334 9.21652 0.166504 6.00002 0.166504ZM7.92094 8.74559L5.41669 6.24134V2.49984H6.58335V5.75834L8.74577 7.92075L7.92094 8.74559Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ dateFormat(GET_THREAD_DATA.thread.created_at) }}
                  </div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 10.5183L2.84926e-07 4C3.81491e-07 1.79086 1.79087 -4.46209e-06 4.00001 0L8.00001 8.07935e-06C10.2091 1.25414e-05 12 1.79087 12 4.00001V6.15384C12 8.36298 10.2091 10.1538 8 10.1538H3.23077L1.58124 11.3321C0.919369 11.8048 -3.55535e-08 11.3317 0 10.5183ZM2.23082 3.69224C2.23082 3.13995 2.67853 2.69224 3.23082 2.69224L8.76928 2.69223C9.32156 2.69223 9.76928 3.13995 9.76928 3.69223C9.76928 4.24452 9.32156 4.69223 8.76928 4.69223H3.23082C2.67853 4.69224 2.23082 4.24452 2.23082 3.69224ZM3.23082 5.46163C2.67853 5.46163 2.23082 5.90934 2.23082 6.46163C2.23082 7.01391 2.67853 7.46163 3.23082 7.46163H6.92313C7.47541 7.46163 7.92313 7.01391 7.92313 6.46163C7.92313 5.90934 7.47541 5.46163 6.92313 5.46163H3.23082Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ GET_THREAD_DATA.replies.total }} replies
                  </div>
                  <div>
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.1875 5C9.1875 5.58016 8.95703 6.13656 8.5468 6.5468C8.13656 6.95703 7.58016 7.1875 7 7.1875C6.41984 7.1875 5.86344 6.95703 5.4532 6.5468C5.04297 6.13656 4.8125 5.58016 4.8125 5C4.8125 4.41984 5.04297 3.86344 5.4532 3.4532C5.86344 3.04297 6.41984 2.8125 7 2.8125C7.58016 2.8125 8.13656 3.04297 8.5468 3.4532C8.95703 3.86344 9.1875 4.41984 9.1875 5Z"
                        fill="#8B949F"
                      />
                      <path
                        d="M0 5C0 5 2.625 0.1875 7 0.1875C11.375 0.1875 14 5 14 5C14 5 11.375 9.8125 7 9.8125C2.625 9.8125 0 5 0 5ZM7 8.0625C7.81223 8.0625 8.59118 7.73984 9.16551 7.16551C9.73984 6.59118 10.0625 5.81223 10.0625 5C10.0625 4.18777 9.73984 3.40882 9.16551 2.83449C8.59118 2.26016 7.81223 1.9375 7 1.9375C6.18777 1.9375 5.40882 2.26016 4.83449 2.83449C4.26016 3.40882 3.9375 4.18777 3.9375 5C3.9375 5.81223 4.26016 6.59118 4.83449 7.16551C5.40882 7.73984 6.18777 8.0625 7 8.0625Z"
                        fill="#8B949F"
                      />
                    </svg>
                    {{ GET_THREAD_DATA.thread.views }} views
                  </div>
                </div>
              </div>
              <div>
                <div
                  class="div_ic Link"
                  @click="showLinkToPostModal('showLinkToPost')"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.19824 8.15425C6.47925 8.52993 6.83777 8.84078 7.24947 9.06571C7.66118 9.29064 8.11644 9.4244 8.58439 9.45792C9.05233 9.49143 9.52201 9.42391 9.96157 9.25994C10.4011 9.09598 10.8003 8.83939 11.132 8.5076L13.095 6.54458C13.6909 5.92753 14.0207 5.10109 14.0133 4.24327C14.0058 3.38544 13.6617 2.56486 13.0551 1.95826C12.4485 1.35166 11.6279 1.00758 10.7701 1.00012C9.91228 0.992669 9.08585 1.32244 8.4688 1.9184L7.34334 3.03732"
                      stroke="#D1433A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.81514 6.84571C8.53413 6.47004 8.17561 6.15919 7.76391 5.93426C7.3522 5.70932 6.89694 5.57556 6.42899 5.54205C5.96104 5.50854 5.49136 5.57605 5.05181 5.74002C4.61225 5.90399 4.2131 6.16057 3.88142 6.49237L1.9184 8.45539C1.32244 9.07243 0.992669 9.89887 1.00012 10.7567C1.00758 11.6145 1.35166 12.4351 1.95826 13.0417C2.56486 13.6483 3.38544 13.9924 4.24327 13.9998C5.10109 14.0073 5.92753 13.6775 6.54458 13.0816L7.6635 11.9626"
                      stroke="#D1433A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="hover_link">
                    Link
                    <div></div>
                  </div>
                </div>
                <div
                  @click="
                    reportModal({
                      showType: 'showReport',
                      id: GET_THREAD_DATA.thread.id,
                    })
                  "
                  class="div_ic Report"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.6665 9.99992C2.6665 9.99992 3.33317 9.33325 5.33317 9.33325C7.33317 9.33325 8.6665 10.6666 10.6665 10.6666C12.6665 10.6666 13.3332 9.99992 13.3332 9.99992V1.99992C13.3332 1.99992 12.6665 2.66659 10.6665 2.66659C8.6665 2.66659 7.33317 1.33325 5.33317 1.33325C3.33317 1.33325 2.6665 1.99992 2.6665 1.99992V9.99992Z"
                      stroke="#D1433A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.6665 14.6667V10"
                      stroke="#D1433A"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="hover_link">
                    Report
                    <div></div>
                  </div>
                </div>
                <div
                  class="post_Reply"
                  @click="
                    goTo({
                      active_tab: 'new_thread',
                      type: 'thread_reply',
                      title: 'Post Reply',
                    })
                  "
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.99984 9.33341L2.6665 6.00008L5.99984 2.66675"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.3332 13.3333V8.66667C13.3332 7.95942 13.0522 7.28115 12.5521 6.78105C12.052 6.28095 11.3737 6 10.6665 6H2.6665"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Post Reply
                </div>
              </div>
            </div>
            <div class="wrap_info_content">
              <div class="wrap_info_author">
                <div class="img_div cursor-pointer">
                  <img
                    @click="redirectWidgetsToProfilePage($event, GET_THREAD_DATA.thread.author_details.username)"
                    :src="GET_THREAD_DATA.thread.author_details.artwork"
                    alt=""
                    @error="onProfileImageError($event, getFirstAlphabetOfUserName({username:GET_THREAD_DATA.thread.author_details.full_name}))"
                  />
                </div>
                <div>

                  <div class="name cursor-pointer"
                       @click="redirectWidgetsToProfilePage($event, GET_THREAD_DATA.thread.author_details.username)"
                  >
                    {{ GET_THREAD_DATA.thread.author_details.full_name }}
                  </div>
                  <div class="member">Author</div>
                </div>
              </div>
              <span class="line_thread"></span>
              <div style="width: 100%">
                <div class="wr_edit_delete">
                  <div class="created_at_post">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 0C2.6916 0 0 2.6916 0 6C0 9.3084 2.6916 12 6 12C9.3084 12 12 9.3084 12 6C12 2.6916 9.3084 0 6 0ZM6 10.8C3.3534 10.8 1.2 8.6466 1.2 6C1.2 3.3534 3.3534 1.2 6 1.2C8.6466 1.2 10.8 3.3534 10.8 6C10.8 8.6466 8.6466 10.8 6 10.8Z"
                        fill="#8B949F"
                      />
                      <path
                        d="M6.60039 3H5.40039V6.2484L7.37619 8.2242L8.22459 7.3758L6.60039 5.7516V3Z"
                        fill="#8B949F"
                      />
                    </svg>

                    {{ dateFormat(GET_THREAD_DATA.thread.created_at) }}
                  </div>
                  <div
                    v-if="
                      (GET_NAV_MAIN_PATH != 'Search' &&
                        userId == GET_THREAD_DATA.thread.author_details.id) ||
                      (left_nav_active_tab && GET_NAV_MAIN_PATH != 'Search')
                    "
                    class="d-flex edit_remove"
                  >
                    <div
                      @click="
                        goTo({
                          active_tab: 'new_thread',
                          type: 'edit_thread',
                          thread_id: GET_THREAD_DATA.thread.id,
                          title: GET_THREAD_DATA.thread.title,
                          content: GET_THREAD_DATA.thread.content,
                        })
                      "
                      class="d-flex edit"
                    >
                      <div class="svg-icon">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M8.5 1.50015C8.63132 1.36883 8.78722 1.26466 8.9588 1.19359C9.13038 1.12252 9.31428 1.08594 9.5 1.08594C9.68572 1.08594 9.86962 1.12252 10.0412 1.19359C10.2128 1.26466 10.3687 1.36883 10.5 1.50015C10.6313 1.63147 10.7355 1.78737 10.8066 1.95895C10.8776 2.13054 10.9142 2.31443 10.9142 2.50015C10.9142 2.68587 10.8776 2.86977 10.8066 3.04135C10.7355 3.21293 10.6313 3.36883 10.5 3.50015L3.75 10.2502L1 11.0002L1.75 8.25015L8.5 1.50015Z"
                            stroke="#D1433A"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      Edit
                    </div>

                    <div
                      @click="
                        deleteThread({
                          showType: 'showDelete',
                          thread_id: GET_THREAD_DATA.thread.id,
                          type: 'thread',
                        })
                      "
                      class="d-flex delete"
                    >
                      <div class="svg-icon">
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="c-line-1"
                            d="M9.66992 3.60352L3.66992 9.60352"
                            stroke="#FF0039"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            class="c-line-1"
                            d="M3.66992 3.60352L9.66992 9.60352"
                            stroke="#FF0039"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      Remove
                    </div>
                  </div>
                </div>
                <div class="content_thread">
                  <p>
                    {{ GET_THREAD_DATA.thread.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="threadReply in GET_THREAD_DATA.replies.data"
            :key="threadReply.id"
            class="wrap_post for_bg_rd"
          >
            <div class="wr_info_post">
              <div class="img_div cursor-pointer">

                <img
                  @click="redirectWidgetsToProfilePage($event, threadReply.user_data.username)"
                  :src="threadReply.user_data.artwork_url" alt="" @error="onProfileImageError($event, getFirstAlphabetOfUserName({username:threadReply.user_data.full_name}))" />
              </div>
              <div>
                <div class="name cursor-pointer"
                     @click="redirectWidgetsToProfilePage($event, threadReply.user_data.username)"
                >{{ threadReply.user_data.full_name }}</div>
                <div class="member">
                  {{
                    threadReply.user_data.id === userId ? "Author" : "Member"
                  }}
                </div>
              </div>
            </div>
            <span class="line_thread"></span>
            <div class="wrap_content_post">
              <div class="wrap_time_link_report">
                <div class="created_at_post">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 0C2.6916 0 0 2.6916 0 6C0 9.3084 2.6916 12 6 12C9.3084 12 12 9.3084 12 6C12 2.6916 9.3084 0 6 0ZM6 10.8C3.3534 10.8 1.2 8.6466 1.2 6C1.2 3.3534 3.3534 1.2 6 1.2C8.6466 1.2 10.8 3.3534 10.8 6C10.8 8.6466 8.6466 10.8 6 10.8Z"
                      fill="#8B949F"
                    />
                    <path
                      d="M6.60039 3H5.40039V6.2484L7.37619 8.2242L8.22459 7.3758L6.60039 5.7516V3Z"
                      fill="#8B949F"
                    />
                  </svg>

                  {{ dateFormat(threadReply.created_at) }}
                </div>
                <div>
                  <div
                    @click="
                      reportModal({
                        showType: 'showReport',
                        id: threadReply.id,
                      })
                    "
                    class="report"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 7.5C2 7.5 2.5 7 4 7C5.5 7 6.5 8 8 8C9.5 8 10 7.5 10 7.5V1.5C10 1.5 9.5 2 8 2C6.5 2 5.5 1 4 1C2.5 1 2 1.5 2 1.5V7.5Z"
                        stroke="#D1433A"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2 11V7.5"
                        stroke="#D1433A"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Report
                  </div>
                </div>
              </div>
              <div class="content_post">
                <p>
                  {{ threadReply.content }}
                </p>
              </div>
            </div>
          </div>
          <Pagination
            :count="GET_THREAD_DATA.replies.total"
            :id="String(GET_THREAD_DATA.thread.id)"
            :keyId="'thread_id'"
            :actionName="'getThread'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import Pagination from "@/components/Common/Elements/Pagination.vue";
import forum from "@/mixins/forum.js";
export default {
  mixins: [forum],
  components: {
    Pagination,
  },
  props: {
    left_nav_active_tab: Boolean,
  },
  methods: {
    ...mapMutations([
      "changeTypeForumThreads",
      "changeSubPath3",
      "changeReplyId",
      "changeEditorTitle",
      "changeEditorData",
      "changeDeleteType",
    ]),
    ...mapActions(["getThread", "AddViewOnThread"]),
    async goTo(params) {
      if (params.type === "thread_reply") {
        this.changeTypeForumThreads(params.type);
        this.changeSubPath3(params.title);
      } else if (params.type === "edit_thread") {
        this.changeTypeForumThreads(params.type);
        this.changeEditorTitle(params.title);
        this.changeEditorData(params.content);
      }
      this.$parent.changePage(params.active_tab);
    },
    reportModal(params) {
      this.changeReplyId(params.id);
      this.$parent.showModals(params.showType);
    },
    deleteThread(params) {
      this.changeDeleteType(params.type);
      this.$parent.showModals(params.showType);
    },
    showLinkToPostModal(showType) {
      this.$parent.showModals(showType);
    },
  },
  computed: {
    ...mapGetters(["GET_THREAD_DATA", "GET_NAV_MAIN_PATH"]),
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    },
  },
  async created() {
    await this.getThread({
      thread_id: this.$store.state.Forums.thread_id,
      offset: 0,
    });
    await this.AddViewOnThread(this.$store.state.Forums.thread_id);
  },
  // destroyed() {
  //   this.changeSubPath3("");
  // },
};
</script>

<style lang="scss" scoped>
.wrap_thread {
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 4px;
}
.for_bg_rd {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 13px 20px 20px;
  margin-bottom: 10px;
}
.wrap_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 18px;
  background: #e6eaf0;
  border-radius: 4px 4px 0px 0px;
  .name_Thread {
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 17px;
    color: #D1433A;
    margin-bottom: 5px;
  }
  div {
    display: flex;
  }
  .post_Reply {
    background: #D1433A;
    border-radius: 2px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "HelveticaNeueMedium";
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s;
    svg {
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
    &:hover {
      background: #DA6961;
      transition: all 0.3s;
    }
  }
  .div_ic {
    margin-right: 10px;
    background: #e6eaf0;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover {
      .hover_link {
        display: flex;
      }
    }
    .hover_link {
      display: none;
      position: absolute;
      background: #1e2b3b;
      border-radius: 2px;
      font-family: "HelveticaNeueMedium";
      letter-spacing: 0.5px;
      font-size: 11px;
      color: #ffffff;
      top: -45px;
      width: 80px;
      height: 30px;
      align-items: center;
      justify-content: center;
      &:before {
        position: absolute;
        content: "";
        left: 50%;
        bottom: -9px;
        transform: translateX(-50%);
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 7.5px 0 7.5px;
        border-color: #1e2b3b transparent transparent transparent;
      }
    }
  }
  .Report {
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      background: #D1433A1A;
      svg {
        path {
          fill: #D1433A;
        }
      }
    }
  }
  .Link {
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      background: #D1433A1A;
    }
  }
}
.wrap_info_content {
  display: flex;
  margin-top: 19px;
  padding: 0 13px 20px 20px;
}
.wrap_info_author {
  min-width: 101px;
}
.img_div {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  background: #e6eaf0;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 50%;
  }
}
.wr_edit_delete {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
}
.created_at_post {
  font-family: HelveticaNeueNormal;
  font-weight: 400;
  font-size: 11px;
  color: #8b949f;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  svg {
    margin-right: 10px;
  }
}
.name {
  color: #071526;
  font-family: HelveticaNeueBold;
  font-weight: 700;
  font-size: 14px;
  opacity: 0.9;
  text-align: center;
}
.member {
  font-family: HelveticaNeueNormal;
  font-weight: 400;
  font-size: 11px;
  color: #47505b;
  text-align: center;
}
.content_thread {
  p {
    color: #47505b;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    word-break: break-word;
    opacity: 0.95;
    font-family: HelveticaNeueNormal;
  }
}
.line_thread {
  border: 1px solid #e6eaf0;
  margin: 0 20px;
}
.wrap_post {
  display: flex;
}
.report {
  color: #D1433A;
  font-family: HelveticaNeueNormal;
  font-weight: 400;
  font-size: 11px;
  cursor: pointer;
  padding: 5px 7px 5px 8px;
  border-radius: 2px;
  &:hover {
    background: #D1433A1A;
  }
  svg {
    margin-right: 8px;
  }
}
.wr_info_post {
  width: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img_div {
    margin: 0;
  }
  .member {
    text-align: center;
  }
}
.wrap_content_post {
  width: 81%;
}
.wrap_time_link_report {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  div {
    display: flex;
    align-items: center;
  }
}
.content_post {
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #47505b;
    word-break: break-word;
    font-family: HelveticaNeueNormal;
  }
}
.edit_remove {
  justify-content: end;
  font-family: HelveticaNeueNormal;
  font-size: 11px;
  letter-spacing: 0.5px;
  .svg-icon {
    margin-top: 0 !important;
    margin: 0 7px 0 0;
  }
  .edit {
    align-items: center;
    padding: 5px 7px 5px 8px;
    color: #D1433A;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: #D1433A1A;
      transition: all 0.3s;
    }
  }
  .delete {
    align-items: center;
    padding: 5px 7px 5px 8px;
    color: #ff0039;
    border-radius: 2px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      background: #D1433A1A;
      transition: all 0.3s;
    }
  }
}
.wr_thread_info {
  display: block !important;
  .thread_info {
    div {
      margin-right: 20px;
      justify-content: center;
      align-items: center;
      font-family: "HelveticaNeueNormal";
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.5px;
      color: #47505b;
      svg {
        margin-right: 7px;
      }
    }
  }
}
</style>
