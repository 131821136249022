<template>
  <div class="w-100">
    <GroupSubHeader/>
    <div class="container_xxl">
      <div class="artist_body">
        <div class="panel_wrapper">
          <div class="panel_inside">
            <div class="panel_header">
              <div class="panel_title">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M15.35 8C15.35 12.0593 12.0593 15.35 8 15.35C3.94071 15.35 0.65 12.0593 0.65 8C0.65 3.94071 3.94071 0.65 8 0.65C12.0593 0.65 15.35 3.94071 15.35 8Z"
                      stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 11.2V8" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                        stroke-linejoin="round"/>
                  <path d="M8 4.7998H8.01" stroke="#47505B" stroke-width="1.3" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
                Group Information
              </div>
            </div>
            <div class="artist_body_setting">
              <div class="artist-setting-tab">
                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="basic-home-tab">
                    <div class="artist-setting-panel">
                      <div class="artist-page-form-field">
                        <label>Page Name<sup class="sup-color">*</sup></label>
                        <input type="text"/>
                      </div>

                      <div class="artist-page-form-field row">
                        <div class="categories-field-setting col-md-4">
                          <label>Category<sup class="sup-color">*</sup></label>
                          <div class="category-select artist-category-select">
                            <select class="form-control">
                              <option>Groups</option>
                            </select>
                          </div>
                        </div>
                        <div class="categories-field-setting col-md-8">
                          <label>SubCategory<sup class="sup-color">*</sup></label>
                          <div class="category-select">
                            <select class="form-control ">
                              <option>Fan Community</option>
                            </select>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="artist-page-form-field">
                            <label>Location</label>
                            <input type="text">
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="artist-page-form-field">
                            <label>Phone</label>
                            <input type="text">
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="artist-page-form-field">
                            <label>Website</label>
                            <input type="text">
                          </div>
                        </div>
                      </div>
                      <div class="artist-page-form-field setting-artist-about">
                        <label>About</label>
                        <textarea></textarea>
                      </div>
                      <div class="d-flex justify-content-end">
                        <button class="prime_button" type="button">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletePageModal v-if="deletePage"/>
  </div>
</template>
<script type="application/javascript">
import GroupSubHeader from '@/components/Group/Pages/GroupSubHeader'
import DeletePageModal from '@/components/Group/Pages/DeletePageModal'

export default {
  data() {
    return {
      deletePage: false
    }
  },
  components: {
    GroupSubHeader,
    DeletePageModal
  },
  computed: {},
  methods: {
    closeModal: function () {
      this.deletePage = false;
    }
  }
}
</script>
