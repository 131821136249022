import {
  ERROR,
  Page_Popular_Tracks_List,
  Page_Latest_Song_List,
  Album_Play_Index,
  Song_Play_Index,
  SUCCESS,
  Play_Type,
  Page_Music_Filtered_List,
  Page_Music_Filtered_List_Total,
  Page_Music_Filtered_List_Offset,
  Page_Music_Filtered_List_Limit,
  Page_Music_Filtered_List_Payload,
  Artist_Mutual_Followings_List,
  Music_Songs_List,
  Album_Status,
  // Album_Play_Status,
  Last_Play_Array_Length,
  Add_To_Queue_Songs_Array,
  Update_Song_Status,
  My_Playlist_List,
  My_Playlist_Total,
  Tracks_And_Albums_List,
  Tracks_And_Albums_List_Total,
  Playlist_Loader,
  Tracks_And_Albums_Loader,
  Add_To_Playlist_Songs_Array,
  FAVORITE_SONGS_LIST,
  FAVORITE_SONGS_TOTAL,
  FAVORITE_SONGS_LOADER,
  FAVORITE_ALBUMS_LIST,
  FAVORITE_ALBUMS_TOTAL,
  FAVORITE_ALBUMS_LOADER,
  PLAY_LIST,
  TERM,
  OFFSET,
  LIMIT,
  FAVORITE_ARTISTS_LIST,
  FAVORITE_ARTISTS_TOTAL,
  FAVORITE_ARTISTS_LOADER,
  FAVORITE_LIMIT,
  FAVORITE_OFFSET,
} from "../types";
import {
  addSongToPlaylist,
  createPlaylist,
  getContinueListeningSongs,
  getFavouritePagesByType,
  getMusicFavorites,
  getMusicFilteredData,
  getMusicListOfPage,
  getMyPlaylists,
  getSongsAndAlbums,
  trackPlayed,
} from "../../apis/APIs";
import * as Utils from "../../components/utils/Utils";
import Vue from "vue";
import mixin from "../../mixins/sweetAlert";

const INITIAL_STATE = {
  [Page_Popular_Tracks_List]: [],
  [Page_Latest_Song_List]: [],
  [Song_Play_Index]: null,
  [Album_Play_Index]: null,
  [Play_Type]: null,
  [Page_Music_Filtered_List]: null,
  [Page_Music_Filtered_List_Total]: 0,
  [Page_Music_Filtered_List_Offset]: 0,
  [Page_Music_Filtered_List_Limit]: 10,
  [Page_Music_Filtered_List_Payload]: null,
  loading: false,
  [Music_Songs_List]: [],
  // [Album_Play_Status]: null,
  [Last_Play_Array_Length]: [],
  [Add_To_Queue_Songs_Array]: [],
  [Update_Song_Status]: false,
  [My_Playlist_List]: [],
  [PLAY_LIST]: {
    [TERM]: null,
    [OFFSET]: 0,
    [TERM]: 0,
  },
  [Tracks_And_Albums_List]: [],
  [Tracks_And_Albums_List_Total]: null,
  [Playlist_Loader]: false,
  [Tracks_And_Albums_Loader]: false,
  [Add_To_Playlist_Songs_Array]: {},
  [FAVORITE_SONGS_LIST]: [],
  [FAVORITE_SONGS_TOTAL]: null,
  [FAVORITE_SONGS_LOADER]: false,
  [FAVORITE_ALBUMS_LIST]: [],
  [FAVORITE_ALBUMS_TOTAL]: null,
  [FAVORITE_ALBUMS_LOADER]: false,
  [FAVORITE_ARTISTS_LIST]: [],
  [FAVORITE_ARTISTS_TOTAL]: null,
  [FAVORITE_ARTISTS_LOADER]: false,
  [FAVORITE_LIMIT]: null,
  [FAVORITE_OFFSET]: null,
};
export const state = { ...INITIAL_STATE };
export const actions = {
  async getMusicData({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch("loading", true);
        commit("setPopularTrackList", { data: [], loading: true });
        const { data } = await getMusicListOfPage(payload.page_id);
        commit("setPopularTrackList", {
          data: data.information.popularsongs,
          loading: false,
        });
        commit("setLatestSongList", data.information.latest);
        dispatch("loading", false);
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        dispatch("loading", false);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async getMusicFilteredData({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch("loading", true);
        commit("Set_Page_Music_Payload", payload);
        state[Page_Music_Filtered_List_Offset] = payload.offset;
        state[Page_Music_Filtered_List_Limit] = payload.limit;
        const { data } = await getMusicFilteredData(
          payload.type,
          payload.page_id,
          payload.filter_by,
          state[Page_Music_Filtered_List_Offset],
          state[Page_Music_Filtered_List_Limit]
        );
        if (payload.showMore === true) {
          commit(
            "Join_With_Other_Music_Filtered_List",
            data.information[payload.type].data
          );
        } else {
          commit(
            "Set_Page_Music_Filtered_List",
            data.information[payload.type].data
          );
        }
        commit(
          "Set_Page_Music_Filtered_List_Total",
          data.information[payload.type].total
        );
        dispatch("loading", false);
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        dispatch("loading", false);
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async getFooterMusicList({ state, commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (state[Music_Songs_List].length > 0) {
        } else {
          const { data } = await getContinueListeningSongs("latest", "0", "10");
          commit("Set_Continue_Listening_Song", {
            data: data.channels.center.objects.data,
            status: null,
          });
          resolve({
            status: SUCCESS,
            message: "",
            data,
          });
        }
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async trackPlayed({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await trackPlayed(payload);
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  // async createPlaylist({state, commit, dispatch}, payload){
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const {data} = await createPlaylist(payload.createPlaylist);
  //       if(data.success === 1){
  //         let obj = {
  //           playlist_id: data.information.id,
  //           song_id: payload.createPlaylist.songsAddToPlaylist
  //         }
  //         const { res } = await addSongToPlaylist(obj);
  //         await mixin.methods.makeToast('success', 'Playlist', data.message);
  //         commit("SET_PLAY_LIST_MODAL", {value:false});
  //       }else{
  //         await mixin.methods.makeToast('error', 'Error', data.message);
  //       }
  //       resolve({
  //         status: SUCCESS,
  //         message: "",
  //         data
  //       });
  //     } catch (error) {
  //       reject({
  //         status: ERROR,
  //         message: error.message ? error.message : "Please try again."
  //       });
  //     }
  //   })
  // },
  async myPlaylists({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        state[Playlist_Loader] = true;
        let resp;
        if (await dispatch("isPayloadChangeOfPlaylist", payload)) {
          // console.log("fetch playlist again", dispatch("isPayloadChangeOfPlaylist", payload));
          const { data } = await getMyPlaylists(
            payload.term,
            payload.offset,
            payload.limit
          );
          if (data.success === 1) {
            commit("Set_Playlist_List", data.information.data);
            commit("Set_Payload_Of_Playlist", payload);
            commit("Set_Playlist_Loader", false);
          }
          resp = data;
        } else {
          commit("Set_Playlist_Loader", false);
        }
        resolve({
          status: SUCCESS,
          message: "",
          resp,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async songsAndAlbums({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const header = localStorage.getItem("token");
        state[Tracks_And_Albums_Loader] = true;
        const { data } = await getSongsAndAlbums(
          payload.term,
          payload.offset,
          payload.limit
        );
        if (data.success === 1) {
          commit("Set_Songs_And_Album_List", data.information.data);
          commit("Set_Songs_And_Album_List_Total", data.information.total);
        }
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
  async musicFavorites({ state, commit, dispatch }) {
    const songPromise = new Promise(async (resolve, reject) => {
      try {
        state[FAVORITE_SONGS_LOADER] = true;
        const { data } = await getMusicFavorites("song", 0, 8);
        if (data.success === 1) {
          commit("Set_Favorite_Songs_List", data.information.data);
          commit("Set_Favorite_Songs_Total", data.information.total);
          commit("Set_Favorite_Songs_Loader", false);
        }
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
    const albumPromise = new Promise(async (resolve, reject) => {
      try {
        state[FAVORITE_ALBUMS_LOADER] = true;
        const { data } = await getMusicFavorites("album", 0, 12);
        if (data.success === 1) {
          commit("Set_Favorite_Albums_List", data.information.data);
          commit("Set_Favorite_Albums_Total", data.information.total);
          commit("Set_Favorite_Albums_Loader", false);
        }
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
    const artistPromise = new Promise(async (resolve, reject) => {
      try {
        state[FAVORITE_ARTISTS_LOADER] = true;
        const { data } = await getFavouritePagesByType(
          "artist",
          0,
          12,
          "",
          "",
          "",
          "",
          "latest"
        );
        if (data.success === 1) {
          commit("Set_Favorite_Artists_List", data.information.data);
          commit("Set_Favorite_Artists_Total", data.information.total);
          commit("Set_Favorite_Artists_Loader", false);
        }
        resolve({
          status: SUCCESS,
          message: "",
          data,
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
    Promise.all([songPromise, albumPromise, artistPromise])
      .then((values) => {
        console.log("val", values);
      })
      .catch((error) => {
        console.error(error.message ? error.message : "Please try again.");
      });
  },
  async isPayloadChangeOfPlaylist({ state }, payload) {
    return (
      state[PLAY_LIST][TERM] != payload.term ||
      state[PLAY_LIST][OFFSET] != payload.offset ||
      state[PLAY_LIST][LIMIT] != payload.limit
    );
  },
  async getFavoriteByType({ state, commit, dispatch }, payload) {
    new Promise(async (resolve, reject) => {
      try {
        state[FAVORITE_OFFSET] = payload.offset;
        state[FAVORITE_LIMIT] = payload.limit;
        if (payload.type === "song" || payload.type === "album") {
          if (payload.type === "song") {
            state[FAVORITE_SONGS_LOADER] = true;
          } else if (payload.type === "album") {
            state[FAVORITE_ALBUMS_LOADER] = true;
          }
          const { data } = await getMusicFavorites(
            payload.type,
            state[FAVORITE_OFFSET],
            state[FAVORITE_LIMIT]
          );
          if (data.success === 1) {
            if (payload.type === "song") {
              commit("Join_With_Favorite_Songs_List", data.information.data);
              commit("Set_Favorite_Songs_Total", data.information.total);
              commit("Set_Favorite_Songs_Loader", false);
            } else if (payload.type === "album") {
              commit("Join_With_Favorite_Albums_List", data.information.data);
              commit("Set_Favorite_Albums_Total", data.information.total);
              commit("Set_Favorite_Albums_Loader", false);
            }
          }
          resolve({
            status: SUCCESS,
            message: "",
            data,
          });
        } else if (payload.type === "artist") {
          state[FAVORITE_ARTISTS_LOADER] = true;
          const { data } = await getFavouritePagesByType(
            payload.type,
            state[FAVORITE_OFFSET],
            state[FAVORITE_LIMIT],
            "",
            "",
            "",
            "",
            "latest"
          );
          if (data.success === 1) {
            commit("Join_With_Favorite_Artists_List", data.information.data);
            commit("Set_Favorite_Artists_Total", data.information.total);
            commit("Set_Favorite_Artists_Loader", false);
          }
          resolve({
            status: SUCCESS,
            message: "",
            data,
          });
        }
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again.",
        });
      }
    });
  },
};
export const mutations = {
  Set_Add_To_Playlist_Songs_Array(state, payload) {
    try {
      state[Add_To_Playlist_Songs_Array] = payload;
    } catch (e) {
      console.error(e.message, "unable to set add to playlist songs array");
    }
  },
  Set_Playlist_List(state, payload) {
    try {
      state[My_Playlist_List] = payload;
    } catch (e) {
      console.error(e.message, "unable to set playlist list");
    }
  },
  Set_Playlist_Loader(state, payload) {
    try {
      state[Playlist_Loader] = false;
    } catch (e) {
      console.error(e.message, "unable to set playlist loader");
    }
  },
  Set_Songs_And_Album_List(state, payload) {
    try {
      state[Tracks_And_Albums_List] = payload;
      state[Tracks_And_Albums_Loader] = false;
    } catch (e) {
      console.error(e.message, "unable to set tracks and albums list");
    }
  },
  Set_Songs_And_Album_List_Total(state, payload) {
    try {
      state[Tracks_And_Albums_List_Total] = payload;
    } catch (e) {
      console.error(e.message, "unable to set playlist list total");
    }
  },
  Set_Continue_Listening_Song(state, payload) {
    try {
      if (payload.status === null) {
        state[Last_Play_Array_Length] = payload.data;
        state[Music_Songs_List] = state[Last_Play_Array_Length];
      } else {
        state[Music_Songs_List] = [
          ...payload.data,
          ...state[Last_Play_Array_Length],
        ];
      }
      // Vue.set(state, Album_Play_Status, payload.status);
    } catch (e) {
      console.error(e.message, "unable to set continue listening songs list");
    }
  },
  // Set_Song_status(state, payload) {
  //   try {
  //     Vue.set(state, Album_Play_Status, payload);
  //   } catch (e) {
  //     console.error(e.message, "unable to set song status");
  //   }
  // },
  Set_Last_Play_Song_List(state, payload) {
    try {
      Vue.set(state, Last_Play_Array_Length, payload);
    } catch (e) {
      console.error(e.message, "unable to set last play array length");
    }
  },
  Set_Page_Music_Payload(state, payload) {
    try {
      state[Page_Music_Filtered_List_Payload] = payload;
    } catch (e) {
      console.error(e.message, "unable to set page filtered list payload");
    }
  },
  Set_Update_song_status(state, payload) {
    try {
      state[Update_Song_Status] = payload;
    } catch (e) {
      console.error(e.message, "unable to update song status payload");
    }
  },
  Set_Page_Music_Filtered_List(state, payload) {
    try {
      state[Page_Music_Filtered_List] = payload;
    } catch (e) {
      console.error(e.message, "unable to set page filtered list");
    }
  },
  Join_With_Other_Music_Filtered_List(state, payload) {
    try {
      let musicFilteredList = state[Page_Music_Filtered_List];
      state[Page_Music_Filtered_List] = [...musicFilteredList, ...payload];
    } catch (e) {
      console.error(e.message, "unable to join with  set page filtered list");
    }
  },
  Set_Page_Music_Filtered_List_Total(state, payload) {
    try {
      state[Page_Music_Filtered_List_Total] = payload;
    } catch (e) {
      console.error(e.message, "unable to set page filtered list total");
    }
  },
  setPopularTrackList(state, payload) {
    try {
      state[Page_Popular_Tracks_List] = payload;
    } catch (e) {
      console.error(e.message, "unable to set page popular tracks");
    }
  },
  setLatestSongList(state, payload) {
    try {
      state[Page_Latest_Song_List] = payload;
    } catch (e) {
      console.error(e.message, "unable to set page latest songs");
    }
  },
  SET_PLAY_SONG_INDEX(state, payload) {
    try {
      state[Song_Play_Index] = payload;
    } catch (e) {
      console.error(e.message, "unable to set song play index");
    }
  },
  SET_ALBUM_PLAY_SONG_INDEX(state, payload) {
    try {
      state[Album_Play_Index] = payload;
    } catch (e) {
      console.error(e.message, "unable to set album play index");
    }
  },
  SET_SONG_TYPE(state, payload) {
    try {
      state[Play_Type] = payload;
    } catch (e) {
      console.error(e.message, "unable to set  play type");
    }
  },
  Remove_From_Music_Player(state, payload) {
    try {
      let song = state[Music_Songs_List].filter(
        (song) => song.id === payload[0].id
      )[0];
      if (song) {
        let index = state[Music_Songs_List].indexOf(song);
        state[Music_Songs_List].splice(index, 1);
        state[Music_Songs_List] = state[Music_Songs_List];
      }
    } catch (e) {
      console.error(e.message, "unable to remove songs from music player list");
    }
  },
  Set_Favorite_Songs_List(state, payload) {
    try {
      state[FAVORITE_SONGS_LIST] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite songs list");
    }
  },
  Join_With_Favorite_Songs_List(state, payload) {
    try {
      let songsList = state[FAVORITE_SONGS_LIST];
      state[FAVORITE_SONGS_LIST] = [...songsList, ...payload];
    } catch (e) {
      console.error(e.message, "unable to join with favorite songs list");
    }
  },
  Set_Favorite_Songs_Total(state, payload) {
    try {
      state[FAVORITE_SONGS_TOTAL] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite songs total");
    }
  },
  Set_Favorite_Songs_Loader(state, payload) {
    try {
      state[FAVORITE_SONGS_LOADER] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite songs total");
    }
  },
  Set_Favorite_Albums_List(state, payload) {
    try {
      state[FAVORITE_ALBUMS_LIST] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite albums list");
    }
  },
  Join_With_Favorite_Albums_List(state, payload) {
    try {
      let albumsList = state[FAVORITE_ALBUMS_LIST];
      state[FAVORITE_ALBUMS_LIST] = [...albumsList, ...payload];
    } catch (e) {
      console.error(e.message, "unable to join with favorite albums list");
    }
  },
  Set_Favorite_Albums_Total(state, payload) {
    try {
      state[FAVORITE_ALBUMS_TOTAL] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite songs total");
    }
  },
  Set_Favorite_Albums_Loader(state, payload) {
    try {
      state[FAVORITE_ALBUMS_LOADER] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite songs total");
    }
  },
  Set_Payload_Of_Playlist(state, payload) {
    try {
      state[PLAY_LIST][TERM] = payload.term;
      state[PLAY_LIST][OFFSET] = payload.offset;
      state[PLAY_LIST][LIMIT] = payload.limit;
    } catch (e) {
      console.error(e.message, "unable to set playlist payload");
    }
  },
  Set_Favorite_Artists_List(state, payload) {
    try {
      state[FAVORITE_ARTISTS_LIST] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite artists list");
    }
  },
  Join_With_Favorite_Artists_List(state, payload) {
    try {
      let artistList = state[FAVORITE_ARTISTS_LIST];
      state[FAVORITE_ARTISTS_LIST] = [...artistList, ...payload];
    } catch (e) {
      console.error(e.message, "unable to join with favorite artists list");
    }
  },
  Set_Favorite_Artists_Total(state, payload) {
    try {
      state[FAVORITE_ARTISTS_TOTAL] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite artists total");
    }
  },
  Set_Favorite_Artists_Loader(state, payload) {
    try {
      state[FAVORITE_ARTISTS_LOADER] = payload;
    } catch (e) {
      console.error(e.message, "unable to set favorite artists loader");
    }
  },
};
const getters = {
  getAddToPlaylistSongsArray(state) {
    return state[Add_To_Playlist_Songs_Array];
  },
  getPlaylistLoader(state) {
    return state[Playlist_Loader];
  },
  getTracksAndAlbumsLoader(state) {
    return state[Tracks_And_Albums_Loader];
  },
  getTracksAndAlbums(state) {
    return state[Tracks_And_Albums_List];
  },
  getPlaylists(state) {
    return state[My_Playlist_List];
  },
  getUpdateSongStatus(state) {
    return state[Update_Song_Status];
  },
  getLastPlaySongsArray(state) {
    return state[Last_Play_Array_Length];
  },
  continueSongsListening(state) {
    return state[Music_Songs_List];
  },
  // getSongsStatus(state) {
  //   return state[Album_Play_Status];
  // },
  getPageMusicFilteredListPayload(state) {
    return state[Page_Music_Filtered_List_Payload];
  },
  getPageMusicFilteredList(state) {
    return state[Page_Music_Filtered_List];
  },
  getPageMusicFilteredListTotal(state) {
    return state[Page_Music_Filtered_List_Total];
  },
  getPagePopularTrackList(state) {
    return state[Page_Popular_Tracks_List];
  },
  getPageLatestSongList(state) {
    return state[Page_Latest_Song_List];
  },
  getSongPlayIndex(state) {
    let _state = Utils.parseState(state);
    return _state[Song_Play_Index];
  },
  getAlbumIndex(state) {
    let _state = Utils.parseState(state);
    return _state[Album_Play_Index];
  },
  getPlayType(state) {
    let _state = Utils.parseState(state);
    return _state[Play_Type];
  },
  getMusicFavoriteSongs(state) {
    return state[FAVORITE_SONGS_LIST];
  },
  getFavoriteSongsTotal(state) {
    return state[FAVORITE_SONGS_TOTAL];
  },
  getFavoriteSongsLoader(state) {
    return state[FAVORITE_SONGS_LOADER];
  },
  getMusicFavoriteAlbums(state) {
    return state[FAVORITE_ALBUMS_LIST];
  },
  getFavoriteAlbumsTotal(state) {
    return state[FAVORITE_ALBUMS_TOTAL];
  },
  getFavoriteAlbumsLoader(state) {
    return state[FAVORITE_ALBUMS_LOADER];
  },
  getFavoriteArtists(state) {
    return state[FAVORITE_ARTISTS_LIST];
  },
  getFavoriteArtistsTotal(state) {
    return state[FAVORITE_ARTISTS_TOTAL];
  },
  getFavoriteArtistsLoader(state) {
    return state[FAVORITE_ARTISTS_LOADER];
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
