import Vue from 'vue'
export default {
    data() {
        return {
        }
    },
    methods: {
        makeToast: async function (variant = 'info',title = '',body = '') {
            return Swal.fire({
                title: title,
                text: body,
                icon: variant,
                confirmButtonText: 'Okay'
            })
        },
        errorToaster : async function (variant = 'info',title = '',body = '') {
            return Swal.fire({
                popup: 'swal2-hide',
                title: title,
                text: body,
                icon: variant,
                showConfirmButton: false,
                showCloseButton: false,
                timer: 3000
            })
        },
        confirmationToast: async function(variant = 'warning', title = '', body= '', text= '', confirmButtonColor= '', cancelButtonColor = '') {
            return Swal.fire( {
                title: title,
                text: body,
                icon: variant,
                showCancelButton: true,
                confirmButtonColor: confirmButtonColor,
                cancelButtonColor: cancelButtonColor,
                confirmButtonText: text
            })
        },
        topCornerToast: async function(title, variant) {
            return Swal.fire({
                position: 'top-end',
                icon: variant,
                title: title,
                heightAuto: false,
                showConfirmButton: false,
                timer: 1500
            })
        },
        bootStrapSmallToaster(title, variant, body) {
            this.$bvToast.toast(body, {
                title: title,
                variant: variant,
                solid: true,
                noAutoHide: true
            })
        },
        vueNotification(title, body, type) {
             Vue.notify({
                group: 'foo',
                title: title,
                text: body,
                type: type
            })
        }
    }
}
