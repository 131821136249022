<template>
  <div class="container sale-items m-t-50">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="title-h4 selling_title">Selling Now</h4>
      </div>
    </div>
    <div class="row position-relative">
      <div v-for="(song, index) in songList" :key="index"
           :class="['col-sm-2 js-container-hover',song.isHovering ? 'hover':'']" @mouseover="isHoveringFun(true, index)"
           @mouseout="isHoveringFun(false, index)">
        <div class="card slider">
          <div :class="['card-cover js-card-hover',song.isHovering ? 'hover':'']">
            <div class="cover square  "><img src="/assets/img/photo-1.png" alt=""></div>
            <div class="cover-hover">
              <div class="head-item">
                <div class=" btn-slider-head">
                  <div class="svg-icon  ">
                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1 c-fill-1"
                            d="M1.64169 14.422L5.58297 9.60534L9.52424 14.422C9.56466 14.4668 9.61329 14.5025 9.66718 14.5269C9.72108 14.5512 9.77911 14.5638 9.83775 14.5638C9.8964 14.5638 9.95443 14.5512 10.0083 14.5269C10.0622 14.5025 10.1109 14.4668 10.1513 14.422C10.2408 14.3276 10.3304 14.1387 10.2408 14.0442V2.14423C10.2622 2.05642 10.2646 1.96467 10.2479 1.87576C10.2311 1.78684 10.1956 1.70303 10.144 1.63051C10.0924 1.55798 10.0261 1.4986 9.9498 1.45675C9.87353 1.41489 9.78932 1.39162 9.70339 1.38867H1.46254C1.2862 1.40616 1.12293 1.49423 1.0063 1.63477C0.889671 1.77531 0.828553 1.95763 0.835518 2.14423V14.1387C0.824892 14.2511 0.856434 14.3635 0.923396 14.4518C0.990358 14.54 1.08742 14.5971 1.19382 14.6109C1.37297 14.6109 1.55211 14.6109 1.64169 14.422Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="square"></path>
                    </svg>
                  </div>
                </div>
                <div class="play btn js-play-card" @click="$event.currentTarget.classList.toggle('active')">
                  <div class="svg-icon ">
                    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="play-type-1">
                      <path class="c-fill-1 c-line-1"
                            d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                            stroke="white" stroke-width="1.3"></path>
                    </svg>
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1 c-fill-1"
                            d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                            fill="white"></path>
                      <path class="c-line-1 c-fill-1"
                            d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                            stroke="white" stroke-width="1.3"></path>
                    </svg>
                    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2"
                            stroke-linecap="round"></path>
                      <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2"
                            stroke-linecap="round"></path>
                    </svg>
                  </div>
                </div>
                <div class="btn-more">
                  <div class="dropdown  more-dropdown " data-toggle="dropdown" aria-haspopup="true"
                       aria-expanded="false" data-display="static">
                    <div class="svg-icon more-position">
                      <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more-hor">
                        <path class="c-fill-1"
                              d="M2.22144 2.99805C3.01872 2.99805 3.66505 2.35173 3.66505 1.55445C3.66505 0.757165 3.01872 0.11084 2.22144 0.11084C1.42416 0.11084 0.777832 0.757165 0.777832 1.55445C0.777832 2.35173 1.42416 2.99805 2.22144 2.99805Z"
                              fill="white"></path>
                        <path class="c-fill-1"
                              d="M7.79615 2.99805C8.59343 2.99805 9.23975 2.35173 9.23975 1.55445C9.23975 0.757165 8.59343 0.11084 7.79615 0.11084C6.99886 0.11084 6.35254 0.757165 6.35254 1.55445C6.35254 2.35173 6.99886 2.99805 7.79615 2.99805Z"
                              fill="white"></path>
                        <path class="c-fill-1"
                              d="M13.3709 2.99805C14.1681 2.99805 14.8145 2.35173 14.8145 1.55445C14.8145 0.757165 14.1681 0.11084 13.3709 0.11084C12.5736 0.11084 11.9272 0.757165 11.9272 1.55445C11.9272 2.35173 12.5736 2.99805 13.3709 2.99805Z"
                              fill="white"></path>
                      </svg>
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more">
                        <path
                            d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                            class="c-fill-1"></path>
                        <path
                            d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                            class="c-fill-1"></path>
                        <path
                            d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                            class="c-fill-1"></path>
                      </svg>
                    </div>
                    <ListOption/>
                  </div>
                </div>
              </div>
              <div class="cover-rating">
                <div class="svg-icon">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="svg-star">
                    <path
                        d="M5.90646 8.64551C5.90288 8.64279 5.89791 8.6428 5.89434 8.64553L2.84276 10.981C2.83503 10.9869 2.82426 10.9794 2.82713 10.9701L3.99444 7.19061C3.99567 7.18661 3.99428 7.18226 3.99096 7.17972L0.923852 4.83399C0.916256 4.82818 0.920364 4.81605 0.929927 4.81605H4.7135C4.71789 4.81605 4.72176 4.81319 4.72306 4.809L5.88996 1.03083C5.89286 1.02144 5.90615 1.02143 5.90907 1.03081L7.08406 4.80902C7.08536 4.8132 7.08923 4.81605 7.09361 4.81605H10.8709C10.8804 4.81605 10.8845 4.82818 10.8769 4.83399L7.82342 7.17091C7.8201 7.17346 7.81871 7.1778 7.81994 7.18181L8.98731 10.9615C8.99018 10.9708 8.97945 10.9783 8.97171 10.9724L5.90646 8.64551Z"
                        fill="white" stroke="white" stroke-width="1.3"></path>
                  </svg>
                </div>
                <div class="rating">5.0</div>
              </div>
            </div>
          </div>
          <div class="card-description  ">
            <div class="title marquee js-marquee">
              <div class="gird-title-name">
                <a href="#">
                  The Long Road
                </a>
              </div>
            </div>
            <div class="description marquee js-marquee">
              <div class="gird-title-subname">
                <a href="#">
                  Nightrage
                </a>
              </div>
            </div>
            <div class="price">
              <div>
                <a href="#" class="sold_for"> Sold for</a>
                <span class="font-14">€2.66</span>
              </div>
            </div>
            <div class="description marquee js-marquee song_description_location">
              <div><a href="#">
                in Germany
              </a>
              </div>
            </div>
            <div class="description marquee js-marquee song_description_time">
              <div><a href="#">
                1 second ago
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import ListOption from './ListOption'

export default {
  data() {
    return {
      optionWidth: 0,
      isHovering: false,
      songList: [{
        id: 1,
        name: 'qwe',
        isHovering: false
      }, {
        id: 2,
        name: 'asd',
        isHovering: false
      }, {
        id: 3,
        name: 'asd',
        isHovering: false
      }, {
        id: 4,
        name: 'asd',
        isHovering: false
      }, {
        id: 5,
        name: 'asd',
        isHovering: false
      }, {
        id: 6,
        name: 'asd',
        isHovering: false
      }]

    }
  },
  components: {
    ListOption

  },
  computed: {},
  methods: {
    isHoveringFun: function (val, index) {
      this.songList[index].isHovering = val;
    },
    getHeight: function (e) {
      if (this.optionWidth == 0)
        this.optionWidth = e.screenX;
    }

  }
}
</script>
