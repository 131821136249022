<template>
  <div class="Signup_pop_overlay share-model" v-if="openModel || is_edit_share_post">
    <div class="modal fade show d-block" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content w-120 h-100">
            <div class="sponser-type w-100" v-if="selectedFilterTab === 'selectAudience'">
              <SelectAudience @goBackToPostMain="goBackToPostMain" :selected-tab="audience"/>
            </div>
            <div v-else class="post-option-container px-0">
              <div class="modal-header py-2">
                <span class="modal-title">
                  Share {{ (post && (post.type === "album" || post.type === "song" || post.type === "Music Podcast")) ? "Audio" : "Post"
                  }}
                </span>
                <div @click="closeModel()" role="button" class="close-modal-button d-flex align-items-center justify-content-center">
                  <img :src="require(`@/assets/icons/icons/home/news-feed/close-icon.svg`)" alt="close">
                </div>
              </div>
              <div class="">
              <nav>
                <div class="nav nav-tabs custom_tab" id="nav-tab" role="tablist" style="padding: 0 20px 0 20px;">
                  <a @click="changeActiveTab('share')"
                    :class="['nav-item nav-link headers-style mr-2', (active_tab === 'share') ? 'active':'']"
                    id="nav-home-tab"
                    data-toggle="tab" href="#share" role="tab" aria-controls="nav-home"
                    :aria-selected="(active_tab === 'share')">SHARE</a>
                  <a @click="changeActiveTab('embed')"
                    v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')"
                    :class="['nav-item nav-link headers-style ml-2', (active_tab === 'embed') ? 'active':'']"
                    style="border-top-right-radius: 4px; border-bottom-right-radius: 4px"
                    id="nav-contact-tab" data-toggle="tab" href="#embed" role="tab"
                    aria-controls="nav-contact" :aria-selected="(active_tab === 'embed')">EMBED
                  </a>
                  <a @click="changeActiveTab('socials')"
                    v-else
                    :class="['nav-item nav-link headers-style ml-2', (active_tab === 'socials') ? 'active':'']"
                    style="border-top-right-radius: 4px; border-bottom-right-radius: 4px"
                    id="nav-contact-tab1" data-toggle="tab" href="#socials" role="tab"
                    aria-controls="nav-contact" :aria-selected="(active_tab === 'socials')">SOCIALS
                  </a>
                </div>
              </nav>
              </div>
              <div class="tab-content" id="nav-tabContent" style="padding: 0 20px 0 20px;">
                <div :class="['tab-pane fade', (active_tab === 'share') ? 'active show':'']"
                    id="share"
                    role="tabpanel" aria-labelledby="nav-home-tab">
                  <div class="row mt-4">
                    <div class="col-12 pl-3 pr-2">
                      <span class="link-label">{{ (post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')) ? 'Track' : 'Post' }} to Share</span>
                    </div>
                  </div>
                  <div class="row song-bar">
                    <div class="col-1 d-flex align-items-center justify-content-center pr-0">
                      <b-img class="song-artwork" size="small"
                            :src="artwork" fluid></b-img>
                    </div>
                    <div class="col-11 m-auto">
                      <p class="song-title" v-html="mainContent === 'null' ? '' : mainContent.length > 100 ? mainContent.slice(0, 100) + '...' : mainContent" style="margin: 0"></p>
                      <span class="song-artist">{{ subContent }}</span>
                    </div>
                  </div>
                  <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mt-3">
                    <div class="col-12 pl-3 pr-2">
                      <span class="link-label">Link</span>
                    </div>
                  </div>
                  <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mt-1 border link-padding">
                    <div class="col-1 d-flex align-items-center justify-content-center pr-0 pl-0  pg-position-link">
                      <b-img size="small" :src="require(`@/assets/icons/icons/home/news-feed/link-icon.svg`)" fluid
                            alt="link icon"></b-img>
                    </div>
                    <div class="col-9 pr-0 pl-0 pg-position-share-link">
                      <b-form-input class="remove-border pl-0" size="small"
                                    placeholder="https://www.auddio.co/" v-model="linkToCopy" disabled></b-form-input>
                    </div>
                    <div class="col-2 pr-0 pl-0 d-flex justify-content-end">
                      <button class="add-button" type="button">COPY</button>
                    </div>
                  </div>
                  <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="border-bottom mt-3 mx-1"></div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="mx-2">
                        <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mb-3 mt-3">
                          <div class="col-12 pl-2 pr-2">
                            <span class="share-post-to-text">Select Socials</span>
                          </div>
                          <div class="col-12">
                            <SocialWidget :post-url="linkToCopy"/>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 pl-2 pr-2">
                            <span class="share-post-to-text">Select Channel</span>
                          </div>
                        </div>
                        <div class="row tiles mb-3 mt-1">
                          <div v-for="(tab,index) in tabs" :key="index" class="col-3 text-center px-2 "
                              @click="selectTab(index)">
                            <div class="border rounded tile-height px-2 py-2 "
                                :class="{'active-title':selectedTab===index}">
                              <div class="mx-2 my-2">
                                <div class="share-post-to-icons d-flex justify-content-center tile-top-margin">
                                  <span v-html="tab.svg"></span>
                                </div>
                              </div>
                              <div class="h-25">
                                <span class="share-post-icons-text">{{ tab.title }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3" v-if="selectedTab">
                          <div class="col-12">
                            <b-tabs content-class="mt-2 border-0" v-model="selectedTab" fill style="padding: 0!important;">
                              <b-tab title-item-class="d-none" class="d-none">

                              </b-tab>
                              <b-tab title-item-class="d-none">
                                <div class="row">
                                  <div class="col-12 px-2">
                                    <label>Select Page</label>
                                    <div class="in_fields">
                                      <v-select class="v-select-main-class" label="name"
                                                :filterable="true"
                                                v-model="selectedPage"
                                                :options="pagesList"
                                                :reduce="(option) => option.id"
                                                placeholder="Search Pages"
                                      >
                                        <template slot="option" slot-scope="option">
                                          <div class="d-center">
                                            {{ option.title }}
                                          </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                          <div class="selected d-center">
                                            {{ option.title }}
                                          </div>
                                        </template>
                                      </v-select>
                                    </div>
                                  </div>
                                </div>
                              </b-tab>
                              <b-tab title-item-class="d-none">
                                <div class="row">
                                  <div class="col-12 px-2">
                                    <label>Select Group</label>
                                    <div class="in_fields">
                                      <v-select class="v-select-main-class"
                                                :filterable="true"
                                                :options="getGroupsList()"
                                                v-model="selectedGroup"
                                                :reduce="(option) => option.id"
                                                placeholder="Select Group">
                                        <template slot="option" slot-scope="option">
                                          <div class="d-center">
                                            {{ option.title }}
                                          </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                          <div class="selected d-center">
                                            {{ option.title }}
                                          </div>
                                        </template>
                                      </v-select>
                                    </div>
                                  </div>
                                </div>
                              </b-tab>
                              <b-tab title-item-class="d-none">
                                <div class="row">
                                  <UsersLaunch ref="userInfo" />
                                </div>
                              </b-tab>
                            </b-tabs>
                          </div>
                        </div>
                      </div>
                      <div class="border-bottom audience-section">
                        <p class="write-message-text"> Write a message</p>
                        <!-- <div class="select-audience-button" v-if="!selectedFilterTab" @click="selectedFilterTab = 'selectAudience'">
                          <span v-if="!audience"> Choose Audience</span>
                          <span v-if="audience"> {{audience}}</span>
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5 5L9 1" stroke="#47505B" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 mb-1">
                    <div class="col-12 px-0">
                      <div class="post-artist-setcion">
                        <div class="user-post-filed-image">
                          <img :src="profile ? profile : require(`@/assets/img/user-placeholder.png`)" />
                        </div>
                        <MentionedAndTagBox @userPageLink="setPageUserLinks">
                          <template v-slot:input>
                            <!--input box-->
                            <div class="post-something-field">
                              <input @keydown="removeMentionsOnBackSpace($event)" type="text" v-model="content"
                                    name="create_post_form_post"
                                    ref="Input"
                                    @click="emoji = false"
                                    placeholder="What is on your mind? #Hashtag...@Mention... Link" autocomplete="off" />
                              <span class="post_emoji" @click="toggleEmoji" v-if="emoji">
                                                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                    stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                  <path
                                                    d="M7.07617 13.2422C7.51371 13.8384 8.08286 14.3256 8.73937 14.6659C9.39588 15.0063 10.122 15.1906 10.8614 15.2046C11.6008 15.1906 12.3269 15.0063 12.9834 14.6659C13.64 14.3256 14.2091 13.8384 14.6466 13.2422"
                                                    stroke="#D1433A" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                  <path
                                                    d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                                                    fill="#D1433A" />
                                                  <path
                                                    d="M7.13185 10.1543C7.85241 10.1543 8.43654 9.57018 8.43654 8.84962C8.43654 8.12906 7.85241 7.54492 7.13185 7.54492C6.41128 7.54492 5.82715 8.12906 5.82715 8.84962C5.82715 9.57018 6.41128 10.1543 7.13185 10.1543Z"
                                                    fill="#D1433A" />
                                                  </svg>
                                              </span>

                              <span class="post_emoji" @click="toggleEmoji" v-if="!emoji">
                                                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                                    stroke="#8B949F" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                  <path
                                                    d="M7.07617 13.2432C7.51371 13.8393 8.08286 14.3265 8.73937 14.6669C9.39588 15.0073 10.122 15.1916 10.8614 15.2056C11.6008 15.1916 12.3269 15.0073 12.9834 14.6669C13.64 14.3265 14.2091 13.8393 14.6466 13.2432"
                                                    stroke="#8B949F" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                  <path
                                                    d="M14.8711 10.1543C15.5917 10.1543 16.1758 9.57018 16.1758 8.84962C16.1758 8.12906 15.5917 7.54492 14.8711 7.54492C14.1505 7.54492 13.5664 8.12906 13.5664 8.84962C13.5664 9.57018 14.1505 10.1543 14.8711 10.1543Z"
                                                    fill="#8B949F" />
                                                  <path
                                                    d="M7.13282 10.1543C7.85339 10.1543 8.43752 9.57018 8.43752 8.84962C8.43752 8.12906 7.85339 7.54492 7.13282 7.54492C6.41226 7.54492 5.82812 8.12906 5.82812 8.84962C5.82812 9.57018 6.41226 10.1543 7.13282 10.1543Z"
                                                    fill="#8B949F" />
                                                  </svg>
                                              </span>

                              <div class="comment-emojis" v-if="emoji" @shown="onShown" @hidden="onHidden" ref="dialog">
                                <VEmojiPicker @select="selectEmoji" :emojiWithBorder="false" :emojiSize="26" :continuousList="true"
                                              :emojisByRow="6"/>
                              </div>
                            </div>
                            <!--input box-->
                          </template>
                        </MentionedAndTagBox>
                          <div class="send-btn" @click="sharePost($event)" v-if="!loading">
                            <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0)">
                                <path d="M24.3336 12.7282L12.6664 12.7282" stroke="white" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z"
                                      stroke="white" stroke-width="1.5" stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="18" height="18" fill="white"
                                        transform="translate(13.7266) rotate(45)"></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div class="send-btn" v-else>
                            <b-icon icon="arrow-clockwise" animation="spin" font-scale="2" variant="light"></b-icon>
                          </div>
                      </div>
                    </div>
                  </div>
                  <p class="text-center" v-if="loading">Please Wait...</p>
                  <p class="text-center error_msg error_msg_red" v-if="error_message">{{
                      error_message
                    }}</p>
                </div>
                <div :class="['tab-pane fade', (active_tab === 'embed') ? 'active show':'']" id="embed"
                    role="tabpanel" aria-labelledby="nav-contact-tab">
                  <nav class="mt-4">
                    <div class="nav nav-tabs custom_tab nav_height" id="nav-tab-embed" role="tablist">
                      <a @click="changeSubActiveTab('Image')"
                        :class="['nav_btn_height nav-item nav-link headers-style embed-tabs d-flex align-items-center justify-content-center mr-2', (sub_active_tab === 'Image') ? 'active':'']"
                        id="nav-image-embed-tab"
                        data-toggle="tab" href="#Image" role="tab" aria-controls="nav-home"
                        :aria-selected="(sub_active_tab === 'Image')">
                        <div>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1 7.7L10.2 5.8L6.6 9.4L4.5 7.3L1 10.9V13.5C1.00964 13.8948 1.17077 14.2707 1.45001 14.55C1.72925 14.8292 2.10521 14.9904 2.5 15H13.5C13.8948 14.9904 14.2707 14.8292 14.55 14.55C14.8292 14.2707 14.9904 13.8948 15 13.5V2.5C14.9904 2.10521 14.8292 1.72925 14.55 1.45001C14.2707 1.17077 13.8948 1.00964 13.5 1H2.5C2.10521 1.00964 1.72925 1.17077 1.45001 1.45001C1.17077 1.72925 1.00964 2.10521 1 2.5V7" stroke="#47505B" stroke-width="1.3" stroke-miterlimit="10"/>
                          </svg>
                        </div>
                        <span class="ml-1">IMAGE</span>
                      </a>
                      <a @click="changeSubActiveTab('Wave')"
                        :class="['nav_btn_height nav-item nav-link headers-style embed-tabs d-flex align-items-center justify-content-center ml-2', (sub_active_tab === 'Wave') ? 'active':'']"
                        id="nav-wave-embed-tab" data-toggle="tab" href="#Wave" role="tab"
                        aria-controls="nav-contact" :aria-selected="(sub_active_tab === 'Wave')">
                        <div>
                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 11.5V4.5H5V11.5H4.5Z" fill="#47505B" stroke="#47505B"/>
                            <path d="M16.5 11.5V4.5H17V11.5H16.5Z" fill="#47505B" stroke="#47505B"/>
                            <path d="M8.5 15.5V0.5H9V15.5H8.5Z" fill="#47505B" stroke="#47505B"/>
                            <path d="M12.5 13.5V2.5H13V13.5H12.5Z" fill="#47505B" stroke="#47505B"/>
                            <path d="M0.5 13.5V2.5H1V13.5H0.5Z" fill="#47505B" stroke="#47505B"/>
                          </svg>
                        </div>
                        <span class="ml-1">WAVE</span>
                      </a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-embed-tabContent">
                    <div :class="['tab-pane fade mt-4', (sub_active_tab === 'Image') ? 'active show':'']"
                        id="Image"
                        role="tabpanel" aria-labelledby="nav-image-embed-tab">
                      <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mt-3">
                        <div class="col-12 pl-3 pr-2">
                          <span class="embed-label">Embed Code</span>
                        </div>
                      </div>
                      <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mt-1 border link-padding">
                        <div class="col-10 pr-0 pg-position-share-link">
                          <b-form-input class="remove-border" size="small" v-model="embed_code"
                                        ref="embedinput" placeholder="<hello_world/>" readonly></b-form-input>
                        </div>
                        <div class="col-2 pr-0 pl-0 d-flex justify-content-end">
                          <button class="add-button" type="button"
                                  v-clipboard:copy="embed_code"
                                  v-clipboard:success="onCopy"
                                  v-clipboard:error="onError">COPY</button>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-2 d-flex align-items-center">
                          <span class="embed-label">Color</span>
                        </div>
                        <div class="col-8">
                          <div class="colored-list">
                            <ul>
                              <li v-for="(eachOp, index) in colors" :key="'color'+index">
                                <div v-if="!eachOp.isHex" class="colored-box" :class="eachOp.color"></div>
                                <div v-if="eachOp.isHex" class="colored-box" :style="{backgroundColor: eachOp.color}"></div>
                              </li>
                              <li>
                                <input class="color-custom-tag" v-model="color"/>
                              </li>
                              <li>
                                <div class="add-color-btn">
                                  <button @click="addColor">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8 0C8.55228 0 9 0.447715 9 1V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V1C7 0.447715 7.44772 0 8 0Z"
                                            fill="#47505B"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 8C0 7.44772 0.447715 7 1 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H1C0.447715 9 0 8.55228 0 8Z"
                                            fill="#47505B"></path>
                                    </svg>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8 d-flex align-items-center ">
                          <b-form-checkbox
                            id="checkbox-1"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted"
                          >
                          </b-form-checkbox>
                          <span class="embed-label"> Auto-play</span>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <ImageAndWaveOnShareModal :song='post' :type="post.type" :id="post.id"/>
                        </div>
                      </div>
                    </div>
                    <div :class="['tab-pane fade mt-4', (sub_active_tab === 'Wave') ? 'active show':'']" id="Wave"
                        role="tabpanel" aria-labelledby="nav-wave-embed-tab">
                      <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mt-3">
                        <div class="col-12 pl-3 pr-2">
                          <span class="embed-label">Embed Code</span>
                        </div>
                      </div>
                      <div v-if="post && (post.type === 'album' || post.type === 'song' || post.type === 'Music Podcast')" class="row mt-1 border link-padding">
                        <div class="col-10 pr-0 pg-position-share-link">
                          <b-form-input class="remove-border" size="small" v-model="embed_code"
                                        placeholder="<hello_world/>" readonly></b-form-input>
                        </div>
                        <div class="col-2 pr-0 pl-0 d-flex justify-content-end">
                          <button class="add-button" type="button"
                                  v-clipboard:copy="embed_code"
                                  v-clipboard:success="onCopy"
                                  v-clipboard:error="onError">COPY</button>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-2 d-flex align-items-center">
                          <span class="embed-label">Color</span>
                        </div>
                        <div class="col-8">
                          <div class="colored-list">
                            <ul>
                              <li v-for="(eachOp, index) in colors" :key="'color'+index">
                                <div v-if="!eachOp.isHex" class="colored-box" :class="eachOp.color"></div>
                                <div v-if="eachOp.isHex" class="colored-box" :style="{backgroundColor: eachOp.color}"></div>
                              </li>
                              <li>
                                <input class="color-custom-tag" v-model="color"/>
                              </li>
                              <li>
                                <div class="add-color-btn">
                                  <button @click="addColor">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8 0C8.55228 0 9 0.447715 9 1V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V1C7 0.447715 7.44772 0 8 0Z"
                                            fill="#47505B"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 8C0 7.44772 0.447715 7 1 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H1C0.447715 9 0 8.55228 0 8Z"
                                            fill="#47505B"></path>
                                    </svg>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8 d-flex align-items-center ">
                          <b-form-checkbox
                            id="checkbox-1"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted"
                          >
                          </b-form-checkbox>
                          <span class="embed-label"> Auto-play</span>
                        </div>
                      </div>
                      <ImageAndWaveOnShareModal :song='post' :type="post.type" :id="post.id" :showWaveForm="(sub_active_tab === 'Wave') ? true : false"/>
                    </div>
                  </div>
                </div>
                <div :class="['tab-pane fade', (active_tab === 'socials') ? 'active show':'']" id="socials"
                    role="tabpanel" aria-labelledby="nav-contact-tab1">
                  <div class="row mt-4">
                    <div class="col-12 pl-3 pr-2" style="margin-bottom: 7px;">
                      <span class="link-label">Post to Share</span>
                    </div>
                  </div>
                  <div class="row song-bar">
                    <div class="col-1 d-flex align-items-center justify-content-center pr-0">
                      <b-img class="song-artwork" size="small"
                            :src="artwork" fluid
                            alt="link icon"></b-img>
                    </div>
                    <div class="col-11 m-auto">
                      <p class="song-title" v-html="mainContent === 'null' ? '' : mainContent.length > 100 ? mainContent.slice(0, 100) + '...' : mainContent"></p>
                      <span class="song-artist">{{ subContent }}</span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 pl-3 pr-2">
                      <span class="link-label">Link</span>
                    </div>
                  </div>
                  <div class="row mt-1 border link-padding">
                    <div class="col-1 d-flex align-items-center justify-content-center pr-0 pl-0">
                      <b-img size="small" :src="require(`@/assets/icons/icons/home/news-feed/link-icon.svg`)" fluid
                            alt="link icon"></b-img>
                    </div>
                    <div class="col-9 pr-0 pl-0">
                      <b-form-input class="remove-border pl-0" size="small"
                                    placeholder="https://www.auddio.co/" v-model="linkToCopy" disabled></b-form-input>
                    </div>
                    <div class="col-2 pr-0 pl-0 d-flex justify-content-end">
                      <button class="add-button" type="button">COPY</button>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12 pl-2 pr-2">
                      <span class="share-post-to-text">Select Socials Network</span>
                    </div>
                    <div class="col-12">
                      <SocialWidget :post-url="linkToCopy"/>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12 pl-3 pr-2">
                      <span class="share-post-to-text">Send Email</span>
                    </div>
                  </div>
                  <div class="row mt-1 border link-padding">
                    <div class="col-1 d-flex align-items-center justify-content-center pr-0 pl-0">
                      <b-img size="small" :src="require(`@/assets/icons/icons/home/news-feed/email-icon.svg`)" fluid
                            alt="link icon"></b-img>
                    </div>
                    <div class="col-11 pr-0 pl-0">
                      <b-form-input class="remove-border email pl-0" :class="status($v.email)" type="email" size="small" placeholder="Enter email address" v-model="$v.email.$model" ></b-form-input>
                    </div>
                  </div>
                    <div class="error" v-if="!$v.email.email">This must be a valid email</div>
                  <div class="row mt-3">
                    <div class="col-12 pl-3 pr-2">
                      <span class="write-message-text"> Write message</span>
                    </div>
                  </div>
                  <div class="row mt-1 mb-3 link-padding">
                    <div class="col-12 pr-0 pl-0 d-flex align-items-center">
                      <b-form-input class="write-message" v-model="message"></b-form-input>
                      <p class="text-center error_msg error_msg_red" v-if="error_message">{{
                      error_message
                    }}</p>
                      <div class="send-btn" v-if="!loading" @click="sharePostViaEmail">
                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0)">
                            <path d="M24.3336 12.7282L12.6664 12.7282" stroke="white" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M24.3332 12.7277L10.0143 19.622L12.6659 12.7277L10.0143 5.8334L24.3332 12.7277Z"
                                  stroke="white" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="18" height="18" fill="white"
                                    transform="translate(13.7266) rotate(45)"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="send-btn" v-else>
                        <b-icon icon="arrow-clockwise" animation="spin" font-scale="2" variant="light"></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getColors } from "../../../utils/Utils";

const users = [
{
  value: "akryum",
  firstName: "Guillaume"
},
{
  value: "posva",
  firstName: "Eduardo"
},
{
  value: "atinux",
  firstName: "Sébastien"
}
];
const issues = [
{
  value: 123,
  label: "Error with foo bar",
  searchText: "foo bar"
},
{
  value: 42,
  label: "Cannot read line",
  searchText: "foo bar line"
},
{
  value: 77,
  label: "I have a feature suggestion",
  searchText: "feature"
}
];

import SelectAudience from "../components/selectAudience";
import MentionedAndTagBox from "@/components/Common/UiList/MentionedAndTagBox";
import ImageAndWaveOnShareModal from '../../../Common/ShareModal/ImageAndWaveOnShareModal'
import {VEmojiPicker} from 'v-emoji-picker';
import moment from "moment";
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import '/public/css/vue-select-style.scss';
import {email, required} from "vuelidate/lib/validators";
import UsersLaunch from "../../../FullViewChat/Components/launchRoom/UsersLaunch";
import SocialWidget from "../../Profile/SocialWidget.vue";
import audience from "../../../Events/components/Detail/Audience.vue";

export default {
name: "shareModel",
data() {
  return {
    page: null,
    group: null,
    content: null,
    loading: false,
    error_message: null,
    selectedFilterTab: "",
    audience: null,
    emoji: false,
    selectedTab: null,
    selectedPage: null,
    tabs: [
      {
        title: "Timeline",
        svg: "     <svg width=\"60\" height=\"60\" viewBox=\"0 0 60 60\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "<circle cx=\"30\" cy=\"30\" r=\"30\" fill=\"#E6EAF0\"/>\n" +
          "<rect x=\"15\" y=\"15\" width=\"30\" height=\"30\" rx=\"4\" fill=\"#E6EAF0\"/>\n" +
          "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.8 22H37.2C37.6737 22.0116 38.1249 22.2049 38.46 22.54C38.7951 22.8751 38.9884 23.3263 39 23.8V36.28C38.9884 36.7537 38.7951 37.2049 38.46 37.54C38.1249 37.8751 37.6737 38.0684 37.2 38.08H22.8C22.3263 38.0684 21.8751 37.8751 21.54 37.54C21.2049 37.2049 21.0116 36.7537 21 36.28V23.8C21.0116 23.3263 21.2049 22.8751 21.54 22.54C21.8751 22.2049 22.3263 22.0116 22.8 22ZM26.1684 26.5945C25.7153 26.6067 25.2842 26.7922 24.9637 27.1126C24.6432 27.4331 24.4578 27.8643 24.4456 28.3173C24.4456 28.6581 24.5466 28.9912 24.7359 29.2745C24.9252 29.5578 25.1943 29.7787 25.5091 29.9091C25.8239 30.0394 26.1703 30.0736 26.5045 30.0071C26.8387 29.9406 27.1456 29.7765 27.3866 29.5356C27.6275 29.2946 27.7917 28.9877 27.8582 28.6535C27.9247 28.3193 27.8905 27.9728 27.7601 27.658C27.6297 27.3432 27.4089 27.0741 27.1255 26.8848C26.8422 26.6955 26.5091 26.5945 26.1684 26.5945ZM24.4456 34.2362H35.9313V32.7362H24.4456V34.2362ZM35.9315 27.3445H30.1887V25.8445H35.9315V27.3445ZM30.1887 30.7898H35.9315V29.2898H30.1887V30.7898Z\" fill=\"#071526\"/>\n" +
          "</svg>"
      },
      {
        title: "Page",
        svg: "             <svg width=\"60\" height=\"60\" viewBox=\"0 0 60 60\" fill=\"none\"\n" +
          "                                 xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "                              <circle cx=\"30\" cy=\"30\" r=\"30\" fill=\"#E6EAF0\"/>\n" +
          "                              <path d=\"M23.875 37V31.75\" stroke=\"#071526\" stroke-width=\"1.5\" stroke-linejoin=\"round\"/>\n" +
          "                              <path\n" +
          "                                  d=\"M23.875 32.75C23.875 32.75 24.6406 32 26.9375 32C29.2344 32 30.7656 33.5 33.0625 33.5C35.3594 33.5 36.125 32.75 36.125 32.75V23.75C36.125 23.75 35.3594 24.5 33.0625 24.5C30.7656 24.5 29.2344 23 26.9375 23C24.6406 23 23.875 23.75 23.875 23.75V32.75Z\"\n" +
          "                                  fill=\"#071526\" stroke=\"#071526\" stroke-width=\"1.5\" stroke-linejoin=\"round\"/>\n" +
          "                            </svg>"
      },
      {
        title: "Group",
        svg: "          <svg width=\"60\" height=\"60\" viewBox=\"0 0 60 60\" fill=\"none\"\n" +
          "                                 xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "                              <circle cx=\"30\" cy=\"30\" r=\"30\" fill=\"#E6EAF0\"/>\n" +
          "                              <path\n" +
          "                                  d=\"M28.875 36.75C28.875 36.75 27.75 36.75 27.75 35.625C27.75 34.5 28.875 31.125 33.375 31.125C37.875 31.125 39 34.5 39 35.625C39 36.75 37.875 36.75 37.875 36.75H28.875ZM33.375 30C34.2701 30 35.1286 29.6444 35.7615 29.0115C36.3944 28.3786 36.75 27.5201 36.75 26.625C36.75 25.7299 36.3944 24.8714 35.7615 24.2385C35.1286 23.6056 34.2701 23.25 33.375 23.25C32.4799 23.25 31.6214 23.6056 30.9885 24.2385C30.3556 24.8714 30 25.7299 30 26.625C30 27.5201 30.3556 28.3786 30.9885 29.0115C31.6214 29.6444 32.4799 30 33.375 30Z\"\n" +
          "                                  fill=\"#071526\"/>\n" +
          "                              <path fill-rule=\"evenodd\" clip-rule=\"evenodd\"\n" +
          "                                    d=\"M26.868 36.75C26.7012 36.3988 26.6181 36.0137 26.625 35.625C26.625 34.1006 27.39 32.5312 28.803 31.44C28.0977 31.2227 27.3629 31.1164 26.625 31.125C22.125 31.125 21 34.5 21 35.625C21 36.75 22.125 36.75 22.125 36.75H26.868Z\"\n" +
          "                                    fill=\"#071526\"/>\n" +
          "                              <path\n" +
          "                                  d=\"M26.0625 30C26.8084 30 27.5238 29.7037 28.0512 29.1762C28.5787 28.6488 28.875 27.9334 28.875 27.1875C28.875 26.4416 28.5787 25.7262 28.0512 25.1988C27.5238 24.6713 26.8084 24.375 26.0625 24.375C25.3166 24.375 24.6012 24.6713 24.0738 25.1988C23.5463 25.7262 23.25 26.4416 23.25 27.1875C23.25 27.9334 23.5463 28.6488 24.0738 29.1762C24.6012 29.7037 25.3166 30 26.0625 30Z\"\n" +
          "                                  fill=\"#071526\"/>\n" +
          "                            </svg>"
      },
      {
        title: "Message",
        svg: "                 <svg width=\"60\" height=\"60\" viewBox=\"0 0 60 60\" fill=\"none\"\n" +
          "                                 xmlns=\"http://www.w3.org/2000/svg\">\n" +
          "                              <circle cx=\"30\" cy=\"30\" r=\"30\" fill=\"#E6EAF0\"/>\n" +
          "                              <path\n" +
          "                                  d=\"M36.5286 23H23.6143C22.7264 23 22.0081 23.7264 22.0081 24.6143L22 37.1896C22 37.908 22.8717 38.2712 23.3802 37.7627L25.2286 35.9143H36.5286C37.4165 35.9143 38.1429 35.1879 38.1429 34.3V24.6143C38.1429 23.7264 37.4165 23 36.5286 23ZM26.0357 28.65H34.1072C34.5511 28.65 34.9143 29.0132 34.9143 29.4572C34.9143 29.9011 34.5511 30.2643 34.1072 30.2643H26.0357C25.5918 30.2643 25.2286 29.9011 25.2286 29.4572C25.2286 29.0132 25.5918 28.65 26.0357 28.65ZM30.8786 32.6857H26.0357C25.5918 32.6857 25.2286 32.3225 25.2286 31.8786C25.2286 31.4347 25.5918 31.0715 26.0357 31.0715H30.8786C31.3225 31.0715 31.6857 31.4347 31.6857 31.8786C31.6857 32.3225 31.3225 32.6857 30.8786 32.6857ZM34.1072 27.8429H26.0357C25.5918 27.8429 25.2286 27.4797 25.2286 27.0357C25.2286 26.5918 25.5918 26.2286 26.0357 26.2286H34.1072C34.5511 26.2286 34.9143 26.5918 34.9143 27.0357C34.9143 27.4797 34.5511 27.8429 34.1072 27.8429Z\"\n" +
          "                                  fill=\"#071526\"/>\n" +
          "                            </svg>"
      }
    ],
    groups: [],
    pages: [],
    text: "",
    items: [],
    active_tab: "share",
    sub_active_tab: 'Image',
    users,
    issues,
    colors: getColors(),
    color: "",
    selected_color: null,
    is_selected_color_hex: false,
    embed_code: '',
    showImage: true,
    message: '',
    email: '',
    selectedGroup: null,
    selectedUser: [],
    pageUserLinks: [],
    pageNameLength:[],
    linkToCopy: 'https://auddiodev.theplayground.co.uk/home/page/post-detail/',
  };
},
components: {
  MentionedAndTagBox,
  VEmojiPicker,
  vSelect,
  ImageAndWaveOnShareModal,
  UsersLaunch,
  SelectAudience,
  SocialWidget
},
validations: {
  email: {
    email,
    required
  }
},
props: {
  openModel: {
    type: Boolean,
    default: false
  },
  is_edit_share_post: {
    type: Boolean,
    default: false
  },
  profile: {
    type: String
  },
  post: {
    type: Object,
  },
  share_media_check: {
    type: Boolean,
    default: false
  }
},
watch: {
  color: function () {
    if (this.color && this.color.length >= 7) return this.color = this.color.slice(0, 7);

    if (!this.color || (this.color && !this.color.includes("#"))) this.color = `#${this.color}`;
  },
  $route() {
    if(this.$route.name !== null && this.$route.name !== 'EmbedComponent' && this.accessToken) {
      this.getPageList()
    }
  },
  selectedFilterTab(value) {
    if (value) this.hideShareModalAndShowAudianceModal();
  },
},
computed: {
  tagsList() {
    return this.$store.getters.getPostTagsList.map(obj => {
      return obj.tag;
    });
  },
  artwork() {
    if (this.post) {
      if (Array.isArray(this.post.artwork_url)) {
        return this.post.artwork_url[0]
      } else if (this.post.user && this.post.user.artwork_url) {
        return this.post.user.artwork_url
      } else if (this.post.artwork_url) {
        return this.post.artwork_url
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  getMyPagesByType() {
    return this.$store.getters.GET_MY_PAGES_TYPE;
  },
  pagesList () {
    return (this.getMyPagesByType.data ? this.getMyPagesByType.data : [])
    // return (this.$store.getters.getFavoritePageListing && this.$store.getters.getFavoritePageListing.length > 0) ? this.$store.getters.getFavoritePageListing : []
  },
  mainContent() {
    if (this.post){
      if(this.post.full_content && this.post.post_type !== 'article' && !this.is_edit_share_post) {
        return this.post.full_content
      } else if(this.is_edit_share_post && this.post && this.post.shareable_item.full_content && this.post.post_type !== 'article' ){
        return this.post.shareable_item.full_content.replace(/<\/?[^>]+>/ig, " ");
      } else if (this.post.title) {
        return this.post.title
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  subContent() {
    if (this.post) {
      if( this.post.artists && this.post.artists.length > 0 && this.post.artists[0].title) {
        return  this.post.artists[0].title
      } else if (this.post.created_at) {
        return moment(this.post.created_at).format('DD.MM.YYYY, h:mm A')
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  accessToken() {
    return this.$store.getters.getAccessToken
  },
  getLastMessagesGroups() {
    return this.$store.getters.getLastMessagesGroups
  }
},
mounted() {
  this.setEmbedCode()
  this.linkToCopy = `https://auddiodev.theplayground.co.uk/home/page/post-detail/${this.post ? this.post.id : ''}`;
  if(this.is_edit_share_post){
    this.content = (this.post && this.post.full_content) ? this.post.full_content : ''
    this.selectedPage = this.post && Number(this.post.page_id)
    this.selectedTab = this.post && this.post.global_type
  }
},
methods: {
  hideShareModalAndShowAudianceModal() {
    console.log('share-modal')
  },
  goBackToPostMain(audience) {
    this.selectedFilterTab = null;
    this.audience = audience;
  },
  status(validation) {
      return {
      error: validation.$error,
      dirty: validation.$dirty
    }
  },
  postURLImage(){
    if (this.post){
      if(this.post.post_type === 'video') {
        return this.post.artwork_url[0].thumb_url.xl;
      } else if (this.post.post_type === 'album' || this.post.post_type === 'cover_photo' || this.post.post_type === 'timeline_photo') {
        return this.post.artwork_url[0].artwork_url;
      } else if((this.post.post_type === 'article' || this.post.post_type === 'Post') && this.post.artwork_url.length > 0) {
        return this.post.artwork_url[0].artwork_url;
      } else if (this.post.artwork_url.length === 0 ){
        return this.post.artwork_url === '';
      }
        else {
        return ''
      }
    }
  },
  closeModel() {
    if(this.is_edit_share_post){
      this.$emit('closeEditShareModal');
    }else{
      this.$emit("closeShareModel");
    }
  },
  selectEmoji(emoji) {
    const {data, key} = emoji || {};
    const {content} = this;
    this.content = content ? `${content} ${data}` : data;
  },
  async getPageList(){
    if (!(this.$store.getters.getFavoritePageListing) && this.$store.getters.getFavoritePageListing.length === 0) {
      await this.$store.dispatch('getFavoritePageListing')
    }
  },
  toggleEmoji() {
    this.emoji = !this.emoji;
  },
  onShown() {
    this.$refs.dialog.focus()
  },
  onHidden() {
    this.$refs.Input.focus()
  },
  selectTab(index) {
    this.selectedTab = index;
  },
  async sharePost(event) {
    if (event) event.stopPropagation();
    if(this.selectedTab === 2 || this.selectedTab === 3) {
      await this.sharePostInMessageOrGroup(this.selectedTab)
    } else {
      try {
        this.loading = true
        if (this.content) {
          this.extractMentionsAndTagsFromContent();
          let payloadContent = {
            content: this.content,
            links: this.pageUserLinks,
            lengthName: this.pageNameLength
          }
          this.$store.commit("SET_CONTENT", payloadContent);
        }
        let type = (this.post.type) ?  this.post.type.charAt(0).toUpperCase() + this.post.type.slice(1) : 'Post';
        type = (this.post.type === 'Music Podcast')  ? 'Podcast' : type
        if (this.post && this.post.post_type === 'Post' && this.post.shareable_item) {
          let payload = {
            post_type_id: (this.post.shareable_item.id) ? this.post.shareable_item.id : this.post.id,
            category: "share",
            post_type: type,
            post_type_description: (this.is_edit_share_post) ? "shared a" + " " + this.post.shareable_item.category : (this.post.category === "default" || this.post.category === "share") ? "shared a" + " " + type : "shared a" + " " + this.post.category,
            global_type:(this.selectedPage) ? 'page':'',
            page_id: (this.selectedPage) ? this.selectedPage : '',
            post_id: (this.is_edit_share_post) ? this.post.id : ''
          };
          await this.$store.dispatch("sharePost", payload);
          this.closeModel()
        } else {
          let payload = {
            post_type_id: (this.post.shared_post && this.post.category!=='share_profile') ? this.post.shared_post.id : this.post.id,
            category: "share",
            post_type: type,
            post_type_description: this.is_edit_share_post ? `shared a ${this.post.shareable_item.category}` :
            (
              this.post.category ? ( (this.post.category === "default" || this.post.category === "share") ? `shared a ${type}` : `shared a ${this.post.category}`) : `shared a ${type}`
            ),
            global_type:(this.selectedPage) ? 'page':'',
            page_id: (this.selectedPage) ? this.selectedPage : '',
            post_id: (this.is_edit_share_post) ? this.post.id : ''
          };
          await this.$store.dispatch("sharePost", payload);
          this.closeModel()
        }
        this.content = ''
        this.selectedPage = ''
        let payloadContent = {
          content: '',
          links: this.pageUserLinks,
          lengthName: this.pageNameLength
        }
        this.$store.commit("SET_CONTENT", payloadContent);
        this.closeModel();
        this.loading = false
      } catch (e) {
        this.loading = false
        this.error_message = e.message;
      }
    }
  },
  async sharePostInMessageOrGroup(tab) {
    try {
      this.loading = true
      const text = this.content
      const post = {
        id: this.post.id,
        post_type: this.post.post_type,
        post_type_description: this.post.post_type_description,
        user: {
          id: this.post.user.id,
          full_name: this.post.user.full_name,
          username: this.post.user.username,
          artwork_url: this.post.user.artwork_url
        },
        artwork_url: this.post.artwork_url,
        global_type: this.post.global_type,
        full_content: this.post.full_content,
        short_content: this.post.short_content
      }
      const groupId = this.selectedGroup
      this.selectedUser = this.$refs.userInfo.getSelectedUsers()
      const userIds = this.selectedUser.map((e) => e)
      if(userIds && userIds.length > 0 && tab === 3) {
        for (const id of userIds) {
          await this.sharePostToUser(text, id.toString(), post)
        }
      } else {
        await this.sharePostInGroupMessage(text, groupId, post)
      }
      this.content = ''
      this.selectedGroup = ''
      this.selectedUser = []
      let payloadContent = {
        content: '',
        links: this.pageUserLinks,
        lengthName: this.pageNameLength
      }
      this.$store.commit("SET_CONTENT", payloadContent);
      this.closeModel();
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  },
  async repostPost(event, post = null, page_id = null) {
    if (event) event.stopPropagation();
    try {
      if (this.content) {
        console.log("Content available...")
        this.extractMentionsAndTagsFromContent();
        let payloadContent = {
          content: this.content,
          links: this.pageUserLinks,
          lengthName: this.pageNameLength
        }
        this.$store.commit("SET_CONTENT", payloadContent);
      }
      let type = (post === "Post") ? 'Post' : this.post.type.charAt(0).toUpperCase() + this.post.type.slice(1);
      type = (this.post.type === 'Music Podcast')  ? 'Podcast' : type
      if (this.post && this.post.shareable_item) {
        console.log("shared items...");
        let payload = {
          post_type_id: (this.post.shareable_item.id && this.post.category!=='share_profile' && this.post.category!=='repost') ? this.post.shareable_item.id : this.post.id,
          category: "repost",
          post_type: type,
          post_type_description: "repost a " +((this.post.category) ? this.post.category + " " : "" )+ type,
          global_type:(this.selectedPage) ? 'page':'',
          page_id: (this.selectedPage) ? this.selectedPage : '',
          post_id: (this.is_edit_share_post) ? this.post.id : ''
        };
        const { data } = await this.$store.dispatch("sharePost", payload);
        const {success} = data || {}
        if (success === 1 ) {
          return true
        }
      } else {
        console.log("Not shared items...")
        let payload = {
          post_type_id: (this.post.shared_post) ? this.post.shared_post.id : this.post.id,
          category: "repost",
          post_type: type,
          post_type_description: "repost a " + type,
          global_type:(this.selectedPage) ? 'page':'',
          page_id: (this.selectedPage) ? this.selectedPage : '',
          post_id: (this.is_edit_share_post) ? this.post.id : ''
        };
        const { data } = await this.$store.dispatch("sharePost", payload);
        const {success} = data || {}
        if (success === 1) {
          return true
        }
      }
      this.closeModel();
    } catch (e) {
      this.error_message = e.message;
    }
  },
  onOpen(key) {
    this.items = key === "@" ? users : issues;
  },
  onApply(item, key, replacedWith) {
    // console.log(item, `has been replaced with ${replacedWith}`);
  },
  extractMentionsAndTagsFromContent() {
    let mentions = [];
    let tags = [];
    let words = this.content.split(" ");
    for (const word of words) {
      if (word.charAt(0).includes("#") || word.charAt(0).includes("@")) {
        (word.charAt(0).includes("#")) ? tags.push(word) : mentions.push(word);
      }
    }
    this.$store.commit("SET_TAGS", tags);
    this.$store.commit("SET_MENTION_LIST", mentions);
  },
  changeActiveTab(tab) {
    this.active_tab = tab;
  },
  changeSubActiveTab(tab) {
    this.sub_active_tab = tab;
  },
  addColor() {
    if (!this.color) return;

    this.colors.push({color: this.color, isHex: true});
    this.color = "";
  },
  setEmbedCode() {
    this.embed_code = `<iframe src="${window.location.origin + '/embed/' + this.$route.params.type + '/' + this.$route.params.id}" height="220" width="555" title="Embed Code"></iframe>`
  },
  onCopy: function (e) {
    this.notificationToast(true, 'Copy', 'Text copied Successfully', 5000, 'success')
  },
  onError: function (e) {
    this.notificationToast(true,'Copy', 'Failed to copied text', 5000, 'error')
  },
  async sharePostViaEmail(){
    try{
      let base_url = window.location.origin;
      let payload = {
        post_id: this.post.id,
        email: this.email,
        link: this.post.category === 'image' ? base_url + '/home/page/news-feed/feed-image/' + this.post.id : base_url + '/home/page/news-feed/post-detail/' + this.post.id,
        message: this.message
      }
      const res = await this.$store.dispatch('sharePostViaEmail',payload)
      const {success, message} = res.data;
      if(success === 1){
        this.notificationToast(true,'Post Share', message , 5000, 'success')
        this.closeModel()
      }
    } catch(e){
      this.error_message = e.message;
    }
  },
  getGroupsList () {
    return this.getLastMessagesGroups.map((e) => {
      return {
        id: e.cid,
        title: e.data.title
      }
    })
  },
  setPageUserLinks(links, length){
    this.pageUserLinks = []
    this.pageNameLength = []
    this.pageUserLinks = [...this.pageUserLinks, ...links]
    this.pageNameLength = [...this.pageNameLength, ...length]
  },
}
};
</script>

<style scoped lang="scss">
.nav_btn_height {
height: 38px;
}
.nav_height {
height: 40px;
}
.share-model {
.modal {
  .modal-dialog {
    .modal-content {
      .post-option-container {
        .modal-header {
          border-bottom: 1px solid #E6EAF0;
          margin-bottom: 0.5rem;

          .close-modal-button {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            &:hover {
              background: #E6EAF0;
              border-radius: 2px;
            }
          }

          .modal-title {
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 20px !important;
            line-height: 24px !important;
            text-transform: capitalize;
            color: #071526 !important;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }

        .custom_tab {
          border: none !important;
          border-radius: 0px !important;

          .nav-item {
            &.active {
              border-bottom: 4px solid #D1433A !important;
              background: transparent !important;
              color: #47505B !important;
            }

            border: none !important;
            border-bottom: 4px solid #C4CCD6 !important;;
            color: #C4CCD6 !important;
          }
        }

        .tiles {
          .tile-height {
            &.active-title {
              background: #D1433A1A;
            }

            height: 120px;
            border: 1px solid #E6EAF0;
            cursor: pointer;

            .tile-top-margin {
              margin-top: 0.7rem;
            }
          }
        }

        .post-artist-setcion {
          justify-content: center !important;
        }

        .tab-content {
          .embed-tabs {
            &.active {
              background: #D1433A!important;
              color: #fff!important;
              border: none!important;
              svg {
                path {
                  stroke: #fff;
                }
              }
            }
            &:first-child {
              border-radius: 4px 0 0 4px!important;
            }
            &:nth-child(2) {
              border-radius: 0 4px 4px 0!important;
            }
            background: #FFFFFF;
            border: 1px solid #E6EAF0!important;
            color: #47505B!important;
          }

          .pg-position-share-link {
            input {
              background: inherit;
            }
          }

          .colored-list {
            .colored-box {
              height: 20px!important;
              width: 20px!important;
            }

          }

          .remove-border {
            width: 100%;
            border: none !important;
            height: 28px;
          }

          .song-bar {
            height: 50px;
            background: #FAFAFC;
            margin-left: 0.1rem;
            margin-right: 0.1rem;
            border-radius: 4px;

            .song-artwork {
              width: 30px;
              height: 30px;
              border-radius: 2px;
            }

            .song-title {
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #47505B;
              word-break: break-all;
            }

            .song-artist {
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              line-height: 20px;
              color: #8B949F;
            }
          }

          .link-padding {
            margin-left: 0.1rem;
            margin-right: 0.1rem;
            border-radius: 4px;
            height: 31px;
          }

          .add-button {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            height: 29px;
            width: 60px;
            background: #D1433A;
            color: #fff;
            border: 1px solid #D1433A;
            border-radius: 0 4px 4px 0;
            transition: 0.2s all ease;
            font-family: 'Helvetica Neue', sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.05em;
          }

          .link-label {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #8B949F;
            letter-spacing: 0.03em;
          }

          .embed-label {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #47505B;
            letter-spacing: 0.03em;
          }

          .select-height {
            height: 30px;
            padding-top: 0;
            padding-bottom: 0;
          }

          .write-message-text {
            color: #47505B;
          }

          .write-message {
            width: 90%;
            height: 41px;
          }
        }
      }
    }
  }
}

.social-icon {
  margin-left: 0.3rem !important;
  margin-right: 0.3rem !important;
}

.share-post-to-text {
  font-family: HelveticaNeueMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.share-post-icons-text {
  font-family: HelveticaNeueMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

}

.write-message-text {
  font-family: HelveticaNeueMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 7px;
}

.audience-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-audience-button {
align-items: center;
background: black;
width: 250px;
height: 40px;
background: #f3f3f3;
border: 1px solid #e6eaf0;
border-radius: 4px;
padding: 7px 10px 7px 10px;
margin-bottom: 10px;
display: flex;
justify-content: space-between;
}

.selected-for-messages {
  background-color: #F5F6F7 !important;
}
}

.modal-title {
font-family: HelveticaNeueBold;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}

.tabs .tab-content {
border: none !important;
}

.link-icon {
background-image: url("/src/assets/icons/icons/home/news-feed/link-icon.svg");
}


.active-title {
border-color: #D1433A !important;

.share-post-to-icons ::v-deep circle {
  fill: #D1433A !important;
}

.share-post-to-icons ::v-deep rect {
  fill: #D1433A !important;
}

.share-post-to-icons ::v-deep path {
  fill: white !important;
  stroke: #fff !important;

}


}

.post-something-field {
input {
  border-radius: 8px!important;
}
}
.error {
color: red;
margin-left: 10px;
}
.tabs .tab-content{
padding: 0!important;
}
.pg-position-link{
position: relative;
left: -4px;
}
.pg-position-share-link{
position: relative;
left: -10px;
}

@media (min-width: 576px) {
.modal-dialog {
  max-width: 572px;
  margin: 1.75rem auto;
}
}
</style>

