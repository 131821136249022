<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade Play-source  show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="work-exp-popup1">
            <div class="work-experience-heading insight-popup">
              <h1>Plays by GEO</h1>

              <div class="create-video-close" @click="closeModal">
                <a href="javascript:;">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                    <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round">
                    </path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="popup-content">
              <ul class="progress_listing progress_listing_popup">
                <li>
                  <div class="progress_text">
                    <h3>Russian Federation</h3>
                    <h4>160k plays / 60%</h4>
                  </div>
                  <div class="progress_widget">
                    <div class="progress_inner" style="width: 60%;"></div>
                  </div>
                </li>
                <li>
                  <div class="progress_text">
                    <h3>United States</h3>
                    <h4>40k plays / 30%</h4>
                  </div>
                  <div class="progress_widget">
                    <div class="progress_inner" style="width: 30%;"></div>
                  </div>
                </li>
                <li>
                  <div class="progress_text">
                    <h3>Australia</h3>
                    <h4>20k plays / 15%</h4>
                  </div>
                  <div class="progress_widget">
                    <div class="progress_inner" style="width: 15%;"></div>
                  </div>
                </li>
                <li>
                  <div class="progress_text">
                    <h3>England</h3>
                    <h4>10k plays / 8%</h4>
                  </div>
                  <div class="progress_widget">
                    <div class="progress_inner" style="width: 8%;"></div>
                  </div>
                </li>
                <li>
                  <div class="progress_text">
                    <h3>German</h3>
                    <h4>5k plays / 4%</h4>
                  </div>
                  <div class="progress_widget">
                    <div class="progress_inner" style="width: 4%;"></div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
