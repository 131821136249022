<template>
  <div class="modal fade modal-add-to-cart contact_info create-new-ticket job-invite show" id="job-support"
       tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true"
       style="padding-right: 15px; display: block;">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="item-title">
          <div class="short-border">
            Ask Support for Help
          </div>
        </div>
        <div class="refund-info-notify">
          <div class="notyf-title">Information</div>
          <div class="notyf-subtitle">Tell us what’s wrong and your thoughts</div>
        </div>
        <div class="m-b-17">
          <div class="input-title">Problem</div>
          <div class="dropdown bootstrap-select show-tick filters-select w-100"><select
              class="filters-select selectpicker w-100" title="Freelancer don’t respond" multiple=""
              data-max-options="1" tabindex="-98">
            <option value="1">Freelancer don’t respond</option>
            <option value="2">Freelancer don’t respond</option>
            <option value="3">Freelancer don’t respond</option>
          </select>
            <button type="button" class="btn dropdown-toggle btn-light bs-placeholder" data-toggle="dropdown"
                    role="combobox" aria-owns="bs-select-38" aria-haspopup="listbox" aria-expanded="false"
                    title="Freelancer don’t respond">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">Freelancer don’t respond</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu ">
              <div class="inner show" role="listbox" id="bs-select-38" tabindex="-1" aria-multiselectable="true">
                <ul class="dropdown-menu inner show" role="presentation"></ul>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-title">Your Message</div>
          <textarea name="aaa" class="custom-textarea" id="" cols="30" rows="10"></textarea></div>
        <div class="action-item">
          <div class="btn back-btn">Cancel</div>
          <div class="btn check-btn">Send</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
