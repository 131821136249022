<template>
  <div class="container-fluid">
    <div class="row d-flex fle">
      <div class="col ">
        <div class="d-flex flex-wrap align-items-center h-100">
          <div class="text with-icon d-flex justify-content-between justify-content-end align-items-center">
            Sort
            <div class="svg-icon">
              <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="9.51351" height="2.16216" rx="1.08108" fill="#D1433A"/>
                <rect y="5.1875" width="16" height="2.16216" rx="1.08108" fill="#D1433A"/>
                <rect y="10.3789" width="12.973" height="2.16216" rx="1.08108" fill="#D1433A"/>
              </svg>
            </div>
          </div>
          <div class="dropdown bootstrap-select show-tick filters-select for-overview">
            <select class="filters-select selectpicker for-overview" title="Latest" multiple="" data-max-options="1"
                    dropupauto="false" tabindex="-98">
              <option value="0">Latest</option>
              <option value="1">Featured</option>
              <option value="2">Trending</option>
              <option value="3">Popular</option>
              <option value="4">High Rating</option>
              <option value="5">Low Rating</option>
            </select>
            <button type="button" class="btn dropdown-toggle btn-light" data-toggle="dropdown" role="combobox"
                    aria-owns="bs-select-9" aria-haspopup="listbox" aria-expanded="false" title="Featured">
              <div class="filter-option">
                <div class="filter-option-inner">
                  <div class="filter-option-inner-inner">Featured</div>
                </div>
              </div>
            </button>
            <div class="dropdown-menu" x-placement="bottom-start"
                 style="max-height: 108px; overflow: hidden; min-height: 118px; position: absolute; will-change: transform; min-width: 170px; top: 0px; left: 0px; transform: translate3d(0px, 30px, 0px);">
              <div class="inner show" role="listbox" id="bs-select-9" tabindex="-1" aria-multiselectable="true"
                   style="max-height: 90px; overflow-y: auto; min-height: 100px;">
                <ul class="dropdown-menu inner show" role="presentation" style="margin-top: 0px; margin-bottom: 0px;">
                  <li><a role="option" class="dropdown-item" id="bs-select-9-0" tabindex="0" aria-selected="false"
                         aria-setsize="6" aria-posinset="1"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Latest</span></a></li>
                  <li class="selected"><a role="option" class="dropdown-item selected" id="bs-select-9-1" tabindex="0"
                                          aria-selected="true" aria-setsize="6" aria-posinset="2"><span
                      class=" bs-ok-default check-mark"></span><span class="text">Featured</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-9-2" tabindex="0" aria-selected="false"
                         aria-setsize="6" aria-posinset="3"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Trending</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-9-3" tabindex="0" aria-selected="false"
                         aria-setsize="6" aria-posinset="4"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Popular</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-9-4" tabindex="0" aria-selected="false"
                         aria-setsize="6" aria-posinset="5"><span class=" bs-ok-default check-mark"></span><span
                      class="text">High Rating</span></a></li>
                  <li><a role="option" class="dropdown-item" id="bs-select-9-5" tabindex="0" aria-selected="false"
                         aria-setsize="6" aria-posinset="6"><span class=" bs-ok-default check-mark"></span><span
                      class="text">Low Rating</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      openPriceRange: false
    }
  },
  components: {},
  computed: {},
  methods: {
    toggleClass: function (e) {
      //    e.target.classList.toggle('open');
    }
  }
}
</script>
