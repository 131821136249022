<template>
  <div
    class="wr_resendOrderCard for_hover_empty_border_blue"
    :style="{ background: index % 2 ? '#F9FAFB' : '#FFFFFF' }"
  >
    <ul>
      <li class="w_74">
        <h4>{{ event.data }}</h4>
        <span>{{ event.time }}</span>
      </li>
      <li class="w_128">
        <h4>{{ event.costumer }}</h4>
        <span>{{ event.email }}</span>
      </li>
      <li class="w_142">
        <h4>{{ event.eventName }}</h4>
        <span>{{ event.created_data }}</span>
      </li>
      <li class="w_76">
        <h4
          :class="[
            event.status === 'Confirmed'
              ? 'green'
              : event.status === 'Cancelled'
              ? 'red'
              : null,
          ]"
        >
          {{ event.status }}
        </h4>
        <span></span>
      </li>
      <li>
        <h4 class="green">{{ event.price }}</h4>
        <span>{{ event.discount }}</span>
      </li>
      <div class="wr_menue three_dots_menustyle for_hover_gray_btn">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99967 8.66634C8.36786 8.66634 8.66634 8.36786 8.66634 7.99967C8.66634 7.63148 8.36786 7.33301 7.99967 7.33301C7.63148 7.33301 7.33301 7.63148 7.33301 7.99967C7.33301 8.36786 7.63148 8.66634 7.99967 8.66634Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99967 4.00033C8.36786 4.00033 8.66634 3.70185 8.66634 3.33366C8.66634 2.96547 8.36786 2.66699 7.99967 2.66699C7.63148 2.66699 7.33301 2.96547 7.33301 3.33366C7.33301 3.70185 7.63148 4.00033 7.99967 4.00033Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z"
            fill="#47505B"
            stroke="#47505B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <article>
          <article>
            <div @click.stop="openCloseUploadModal('open')">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33317 1.33301H3.99984C3.64622 1.33301 3.30708 1.47348 3.05703 1.72353C2.80698 1.97358 2.6665 2.31272 2.6665 2.66634V13.333C2.6665 13.6866 2.80698 14.0258 3.05703 14.2758C3.30708 14.5259 3.64622 14.6663 3.99984 14.6663H11.9998C12.3535 14.6663 12.6926 14.5259 12.9426 14.2758C13.1927 14.0258 13.3332 13.6866 13.3332 13.333V5.33301L9.33317 1.33301Z"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.3335 1.33301V5.33301H13.3335"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.6668 8.66699H5.3335"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.6668 11.333H5.3335"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.66683 6H6.00016H5.3335"
                  stroke="#071526"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Upload Contract
            </div>
            <div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4L4 12"
                  stroke="#EE3F3F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 4L12 12"
                  stroke="#EE3F3F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Cancel Order
            </div>
          </article>
          <span></span>
        </article>
      </div>
    </ul>
    <UploadFileModal
      v-if="showUploadModals === 'open'"
      :title="'Upload Contract'"
      :description="'The file should be under 5 mb and in .pdf or .docx format'"
      @openCloseUploadModal="openCloseUploadModal"
      @upload_this="upload_This_File"
    />
  </div>
</template>

<script>
import UploadFileModal from "@/components/Common/Elements/UploadFileModal.vue";

export default {
  props: ["event", "index"],
  components: {
    UploadFileModal,
  },
  data() {
    return {
      showUploadModals: "close",
    };
  },
  methods: {
    openCloseUploadModal(payload) {
      this.showUploadModals = payload;
    },
    upload_This_File(payload) {
      console.log(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.wr_resendOrderCard {
  padding: 10px 10px 10px 20px;
  ul {
    font-family: HelveticaNeueNormal;
    letter-spacing: 0.7px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    font-weight: 400;
    line-height: 16px;
    color: #8b949f;
    h4 {
      font-size: 13px;
      margin-bottom: 6px;
    }
    span {
      font-size: 11px;
    }
    :nth-child(2) {
      h4 {
        color: #47505b;
      }
    }
    :nth-child(3) {
      h4 {
        color: #D1433A;
      }
    }
    :nth-child(5),
    :nth-child(4) {
      h4 {
        font-family: HelveticaNeueBold;
        font-weight: 700;
      }
    }
    :nth-child(5) {
      text-align: end;
    }
    .red {
      color: #ee3f3f;
    }
    .green {
      color: #15ca86;
    }
  }
  .wr_menue {
    position: relative;
    &:hover {
      article {
        display: block;
      }
    }
    article {
      display: none;
      z-index: 5;
      width: 210px;
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.08);
      border-radius: 4px;
      top: 15px;
      right: 0px;
      div {
        z-index: 2;
        display: flex;
        align-items: center;
        height: 50px;
        font-family: HelveticaNeueBold;
        padding-left: 27px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #071526;
        svg {
          margin-right: 14px;
        }
        &:hover {
          background: #D1433A1A !important;
        }
      }
      span {
        position: absolute;
        width: 14px;
        height: 20px;
        top: 15px;
        right: 8px;
        background: #ffffff;
        rotate: 21deg;
        transform: skewY(50deg);
      }
      :nth-child(2) {
        border-top: 1px solid #e6eaf0;
        color: #ee3f3f;
      }
    }
  }
}
</style>