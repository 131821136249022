import {
  getSingleEventDetails,
  buyTickets,
  addToCart,
  myCartItems,
  removeCart,
  GetMyContactInfo,
  addContactInfo,
  removeContactInfo,
  promoVerification,
  placeOrder,
  getMyOrderSingle,
  sendTicketViaEmail,
} from "../../apis/APIs";

const INITIAL_STATE = {
  single_Event_Details: "",
  buy_ticket_info: "",
  myCartItems: [],
  myContactInfo: "",
  contactInfo: {
    first_name: "",
    last_name: "",
    birthdate: "",
    country_name: "",
    zip: "",
    phone: "",
    is_default: false,
  },
  promoData: "",
  placeOrderData: {},
  myOrderSingle: {},
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async getSingleEventDetails({ commit }, event_id) {
    let { data } = await getSingleEventDetails(event_id);
    commit("set_single_Event_Details", data.information);
  },
  async getbuyTickets({ commit }, event_id) {
    let { data } = await buyTickets(event_id);
    commit("set_buy_ticket_info", data.information);
  },
  async addToCart({}, payload) {
    await addToCart(payload);
  },
  async myCartItems({ commit }) {
    let { data } = await myCartItems();
    commit("set_my_cart_items", data.information);
  },
  async removeCart({}, cart_id) {
    await removeCart(cart_id);
  },
  async removeContactInfo({ commit }, contact_id) {
    await removeContactInfo(contact_id);
    let { data } = await GetMyContactInfo();
    commit("set_my_contact_info", data.information);
  },
  async addContactInfo({ commit }, payload) {
    await addContactInfo(payload);
    let { data } = await GetMyContactInfo();
    commit("set_my_contact_info", data.information);
  },
  async getMyContactInfo({ commit }) {
    let { data } = await GetMyContactInfo();
    commit("set_my_contact_info", data.information);
  },
  async promoVerification({ commit }, params) {
    let { data } = await promoVerification(params.event_id, {
      promoable_type: params.promoable_type,
      code: params.code,
    });
    commit("set_promo_verification", data);
  },
  async placeOrder({ commit }, payload) {
    await placeOrder(payload);
  },
  async getMyOrderSingle({ commit }) {
    let { data } = await getMyOrderSingle();
    commit("set_my_order_single", data.information);
  },
  async sendTicketViaEmail({}, payload) {
    await sendTicketViaEmail(payload.ticket_id, { email: payload.email });
  },
};

export const mutations = {
  set_single_Event_Details(state, payload) {
    state.single_Event_Details = payload;
  },
  set_buy_ticket_info(state, payload) {
    state.buy_ticket_info = payload;
  },
  set_my_cart_items(state, payload) {
    state.myCartItems = payload;
  },
  set_my_contact_info(state, payload) {
    state.myContactInfo = payload;
  },
  set_comtact_info(state, payload) {
    state.contactInfo = payload;
  },
  set_promo_verification(state, payload) {
    state.promoData = payload;
  },
  set_my_order_single(state, payload) {
    state.myOrderSingle = payload;
  },
};

const getters = {
  GET_SINGLE_EVENT_DETAILS(state) {
    return state.single_Event_Details;
  },
  GET_BUYTICKET_INFO(state) {
    return state.buy_ticket_info;
  },
  GET_MY_CART_ITEMS(state) {
    return state.myCartItems;
  },
  GET_MY_CONTACT_INFO(state) {
    return state.myContactInfo;
  },
  GET_CONTACT_INFO(state) {
    return state.contactInfo;
  },
  GET_PROMO(state) {
    return state.promoData;
  },
  GET_MY_ORDER_SINGLE(state) {
    return state.myOrderSingle;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
