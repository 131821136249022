import Vue from "vue";
import * as Utils from "../../components/utils/Utils";
import {
    basic_info,
    change_password_api,
    add_update_about_and_skills_api,
    get_user_session_list,
    availability_and_rate_api,
    location_api,
    companyInfo,
    social_link_api
} from "../../apis/APIs";
import * as SMStorage from "../../apis/SMStorage";
import {
    EMPTY,
    ERROR,
    EDIT_PROFILE_ERRORS,
    EDIT_PROFILE_FORM,
    EDIT_PROFILE_FORM_EMAIL,
    USER_DATA,
    EDIT_PROFILE_FORM_RELATIONSHIP_STATUS,
    EDIT_PROFILE_KEY,
    EDIT_PROFILE_REQEUST_LOADING,
    EDIT_PROFILE_REQUEST_STATUS,
    MESSAGE,
    STATUS,
    SUCCESS,
    SERVER_VERIFICATION_ERROR,
    SYSTEM_DATA_IS_AUTHENTICATED,
    EDIT_PROFILE_FORM_FIRST_NAME,
    EDIT_PROFILE_FORM_LAST_NAME,
    EDIT_PROFILE_FORM_GENDER,
    EDIT_PROFILE_FORM_COUNTRY,
    EDIT_PROFILE_FORM_WEBSITE,
    EDIT_PROFILE_FORM_DOB_DATE,
    EDIT_PROFILE_FORM_DOB_MONTH,
    EDIT_PROFILE_FORM_DOB_YEAR,
    EDIT_PROFILE_FORM_ABOUT_ME,
    EDIT_CARD_FORM,
    EDIT_CARD_FORM_FIRST_NAME,
    EDIT_CARD_FORM_LAST_NAME,
    EDIT_CARD_FORM_NUMBER,
    EDIT_CARD_FORM_EXPIRY,
    EDIT_CARD_FORM_CVV
} from "../types";
import store from "../../store";
import moment from 'moment-timezone';

const INITIAL_STATE = {
    [EDIT_PROFILE_KEY]: {
        [EDIT_PROFILE_FORM]: {
            [EDIT_PROFILE_FORM_EMAIL]: "",
            [EDIT_PROFILE_FORM_FIRST_NAME]: "",
            [EDIT_PROFILE_FORM_LAST_NAME]: "",
            [EDIT_PROFILE_FORM_GENDER]: "",
            [EDIT_PROFILE_FORM_WEBSITE]: "",
            [EDIT_PROFILE_FORM_COUNTRY]: "",
            [EDIT_PROFILE_FORM_ABOUT_ME]: "",
            [EDIT_PROFILE_FORM_DOB_DATE]: "",
            [EDIT_PROFILE_FORM_DOB_MONTH]: "",
            [EDIT_PROFILE_FORM_DOB_YEAR]: "",
        },
        [EDIT_PROFILE_REQEUST_LOADING]: false,
        [EDIT_PROFILE_REQUEST_STATUS]: {
            [STATUS]: EMPTY,
            [MESSAGE]: ""
        },
        [EDIT_PROFILE_ERRORS]: []
    }
};

export const state = {...INITIAL_STATE};

export const actions = {
    async edit_user_profile({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {
                const edit_profile_key = state[EDIT_PROFILE_KEY];
                const form_data = edit_profile_key && edit_profile_key[EDIT_PROFILE_FORM] ? edit_profile_key[EDIT_PROFILE_FORM] : {};

                const first_name = form_data && form_data[EDIT_PROFILE_FORM_FIRST_NAME] ? form_data[EDIT_PROFILE_FORM_FIRST_NAME] : "";
                const last_name = form_data && form_data[EDIT_PROFILE_FORM_LAST_NAME] ? form_data[EDIT_PROFILE_FORM_LAST_NAME] : "";
                const gender = form_data && form_data[EDIT_PROFILE_FORM_GENDER] ? form_data[EDIT_PROFILE_FORM_GENDER] : "";
                const relationship_status = form_data && form_data[EDIT_PROFILE_FORM_RELATIONSHIP_STATUS] ? form_data[EDIT_PROFILE_FORM_RELATIONSHIP_STATUS] : "";
                if (Array.isArray(form_data[EDIT_PROFILE_FORM_COUNTRY])) {
                    var selected_country = form_data[EDIT_PROFILE_FORM_COUNTRY] && form_data[EDIT_PROFILE_FORM_COUNTRY].length > 0 ? form_data[EDIT_PROFILE_FORM_COUNTRY][0].id : "";
                } else {
                    var selected_country = form_data && form_data[EDIT_PROFILE_FORM_COUNTRY] ? form_data[EDIT_PROFILE_FORM_COUNTRY] : "";
                }
                const website = form_data && form_data[EDIT_PROFILE_FORM_WEBSITE] ? form_data[EDIT_PROFILE_FORM_WEBSITE] : "";
                const dob_date = form_data && form_data[EDIT_PROFILE_FORM_DOB_DATE] ? form_data[EDIT_PROFILE_FORM_DOB_DATE] : "";
                const dob_month = form_data && form_data[EDIT_PROFILE_FORM_DOB_MONTH] ? form_data[EDIT_PROFILE_FORM_DOB_MONTH] : "";
                const dob_year = form_data && form_data[EDIT_PROFILE_FORM_DOB_YEAR] ? form_data[EDIT_PROFILE_FORM_DOB_YEAR] : "";
                const about_me = form_data && form_data[EDIT_PROFILE_FORM_ABOUT_ME] ? form_data[EDIT_PROFILE_FORM_ABOUT_ME] : "";

                const userData = await SMStorage.getSMLoginData();
                const user = userData && userData.response ? userData.response : {};

                const dob = new Date(dob_year, dob_month, dob_date);
                dob.setHours(0);
                dob.setSeconds(0, 0);
                dob.setMinutes(0);

                const formdata = new FormData();
                formdata.append("id", user.id);
                formdata.append("name", first_name);
                formdata.append("last_name", last_name);
                formdata.append("gender", gender);
                formdata.append("relationship_status", relationship_status);
                formdata.append("country", selected_country);
                formdata.append("website_url", website);
                formdata.append("birth", moment(dob).format("MM/DD/YYYY"));
                formdata.append("bio", about_me);

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const res = await basic_info(formdata);

                const {success, message, information} = res.data || {};

                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    commit("updateUserUIConstraints", ({
                        [USER_DATA]: information
                    }));

                    resolve({
                        status: SUCCESS,
                        message: message
                    })
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async change_password({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });
                const {email, password, old_password} = body;
                var formdata = new FormData();
                formdata.append('email', email);
                formdata.append('password', password);
                formdata.append('old-password', old_password);

                const { data } = await change_password_api(formdata);

                const {success, message} = data || {};

                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });

                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async get_sessions({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const {id, page = 1, limit = 10} = body;

                let end_point = page * limit;
                let start_point = end_point - limit;

                var formdata = new FormData();
                formdata.append('', start_point);

                const res = await get_user_session_list(page);

                const {success, message, information} = res.data || {};
                const {data} = information || {};
                const {total} = information;

                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });

                    resolve({
                        status: SUCCESS,
                        message: message,
                        data,
                        total
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async update_basic_info_form({state, commit}) {
        let {user_data} = store.getters && store.getters.user_map_to_props ? store.getters.user_map_to_props : {};

        if (user_data && user_data.id) {
            const {email, name, last_name, bio, birth, relationship_status, gender, website_url, country} = user_data;

            const date = new Date(birth);

            commit("updateEditProfileFormData", {
                [EDIT_PROFILE_FORM_EMAIL]: email,
                [EDIT_PROFILE_FORM_FIRST_NAME]: name,
                [EDIT_PROFILE_FORM_LAST_NAME]: last_name,
                [EDIT_PROFILE_FORM_GENDER]: gender !== "0" ? gender : "",
                [EDIT_PROFILE_FORM_WEBSITE]: website_url,
                [EDIT_PROFILE_FORM_COUNTRY]: country,
                [EDIT_PROFILE_FORM_ABOUT_ME]: bio,
                [EDIT_PROFILE_FORM_RELATIONSHIP_STATUS]: relationship_status,
                [EDIT_PROFILE_FORM_DOB_DATE]: date.getDate(),
                [EDIT_PROFILE_FORM_DOB_MONTH]: date.getMonth(),
                [EDIT_PROFILE_FORM_DOB_YEAR]: date.getFullYear()
            });

            console.log("user_data ===> datadatdadata", user_data, {
                [EDIT_PROFILE_FORM_EMAIL]: email,
                [EDIT_PROFILE_FORM_FIRST_NAME]: name,
                [EDIT_PROFILE_FORM_LAST_NAME]: last_name,
                [EDIT_PROFILE_FORM_GENDER]: gender !== "0" ? gender : "",
                [EDIT_PROFILE_FORM_WEBSITE]: website_url,
                [EDIT_PROFILE_FORM_COUNTRY]: country,
                [EDIT_PROFILE_FORM_ABOUT_ME]: bio,
                [EDIT_PROFILE_FORM_RELATIONSHIP_STATUS]: relationship_status,
                [EDIT_PROFILE_FORM_DOB_DATE]: date.getDate(),
                [EDIT_PROFILE_FORM_DOB_MONTH]: date.getMonth(),
                [EDIT_PROFILE_FORM_DOB_YEAR]: date.getFullYear()
            });
        }
    },
    async availability_and_rate({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const {
                    user_currently_available,
                    availability,
                    rate,
                    you_will_get,
                    id
                } = body;

                var formdata = new FormData();
                formdata.append("availability", user_currently_available);
                formdata.append("availability_time", availability);
                formdata.append("rate", rate);
                const res = await availability_and_rate_api(formdata);

                const {success, message, data} = res.data || {};

                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });

                    commit("updateUserUIConstraints", ({
                        [USER_DATA]: data
                    }));

                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async user_location({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const {
                    hometown,
                    current_city,
                    country
                } = body;

                var formdata = new FormData();
                formdata.append("current_city", current_city);
                formdata.append("hometown", hometown);
                formdata.append("country", country);

                const res = await location_api(formdata);

                const {success, message, data} = res.data || {};

                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });

                    commit("updateUserUIConstraints", ({
                        [USER_DATA]: data
                    }));

                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async company_info({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const res = await companyInfo(body);
                const {success, message, information} = res.data || {};
                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    

                    commit("updateUserUIConstraints", ({
                        [USER_DATA]: information
                    })); 

                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                    console.log("checking success",success);
                }
            } catch (error) {
                console.log("checking",error);
                Utils.log("EditProfile ===> error", error, body);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async social_link({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const {
                    facebook_url,
                    google_url,
                    instagram_url,
                    twitter_url,
                    linkedin_url,
                    youtube_url,
                    vkontake_profile_url,
                    id
                } = body;

                var formdata = new FormData();
                formdata.append("facebook_url", facebook_url);
                formdata.append("twitter_url", twitter_url);
                formdata.append("youtube_url", youtube_url);
                formdata.append("instagram_url", instagram_url);
                formdata.append("soundcloud_url", google_url);
                formdata.append("linkedin_url", linkedin_url);

                const res = await social_link_api(formdata);

                const {success, message, data} = res.data || {};
                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });

                    commit("updateUserUIConstraints", ({
                        [USER_DATA]: data
                    }));

                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
    async add_udpate_about_and_skills({state, commit}, body) {
        return new Promise(async (resolve, reject) => {
            try {

                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: true
                });

                const {
                    title,
                    expertise_level,
                    about,
                    skills,
                    tools,
                    languages,
                    id
                } = body;

                var formdata = new FormData();
                if(skills.length > 0){
                    for (const k in skills) {
                        formdata.append('skills[' + k + ']', skills[k])
                    }
                }
                else{
                    formdata.append('skills[]', [])
                }
                if(tools.length > 0){
                    for (const k in tools) {
                        formdata.append('tools[' + k + ']', tools[k])
                    }
                }
                else{
                    formdata.append('tools[]', [])
                }
                if(languages.length > 0){
                    for (const k in languages) {
                        formdata.append('language[' + k + ']', languages[k])
                    }
                }
                else{
                    formdata.append('language[]', [])
                }
                formdata.append("title", title);
                formdata.append("about", about);
                formdata.append("expertise_level", expertise_level);

                // const query = `?id=${id}&user_work_expertise=${expertise_level}&user_work_about_profile=${about}&user_work_skills=${skills}&user_work_tools=${tools}&user_work_language=${languages}&user_work_title=${title}&work_delete=2`;
                const res = await add_update_about_and_skills_api(formdata);

                const {success, message, information} = res.data || {};
                console.log("DATA", information);
                if (success === 1) {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    commit("updateUserUIConstraints", ({
                        [USER_DATA]: information
                    }));
                    resolve({
                        status: SUCCESS,
                        message: message
                    });
                } else {
                    commit("updateEditProfileUIConstraints", {
                        [EDIT_PROFILE_REQEUST_LOADING]: false
                    });
                    reject({
                        status: ERROR,
                        message: message ? message : "Please try again."
                    });
                }
            } catch (error) {
                Utils.log("EditProfile ===> error", error);
                commit("updateEditProfileUIConstraints", {
                    [EDIT_PROFILE_REQEUST_LOADING]: false
                });
                reject({
                    status: ERROR,
                    message: "Please try again."
                });
            }
        })
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    updateEditProfileFormData(state, obj) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[EDIT_PROFILE_KEY];
            const data = Object.assign(formData[EDIT_PROFILE_FORM], obj);

            _state.test = "data"

            mutations.updateEditProfileState(state, Object.assign(formData, {
                [EDIT_PROFILE_FORM]: data
            }));
        } catch (error) {
            Utils.log("Update Form Data ===> error ", error);
        }
    },
    updateEditProfileUIConstraints(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            const formData = _state[EDIT_PROFILE_KEY];
            const data = Object.assign(formData, obj);

            mutations.updateEditProfileState(state, data);
        } catch (error) {
            Utils.log("Update UI Constraints ===> error ", error);
        }
    },

    updateEditProfileState(state, obj = {}) {
        try {
            let _state = Utils.parseState(state);

            let formData = _state[EDIT_PROFILE_KEY];

            state[EDIT_PROFILE_KEY] = Object.assign(formData, obj);
        } catch (error) {
            Utils.log("Update EditProfile State ===> error ", error);
        }
    },
    resetEditProfileState() {
        for (let f in state) {
            Vue.set(state, f, INITIAL_STATE[f]);
        }
    }
};

const getters = {
    edit_profile_form_map_to_props(state) {
        let _state = Utils.parseState(state);

        const edit_profile_key = _state[EDIT_PROFILE_KEY];
        const form_data = edit_profile_key && edit_profile_key[EDIT_PROFILE_FORM] ? edit_profile_key[EDIT_PROFILE_FORM] : {};

        const email = form_data && form_data[EDIT_PROFILE_FORM_EMAIL] ? form_data[EDIT_PROFILE_FORM_EMAIL] : "";

        const first_name = form_data && form_data[EDIT_PROFILE_FORM_FIRST_NAME] ? form_data[EDIT_PROFILE_FORM_FIRST_NAME] : "";
        const last_name = form_data && form_data[EDIT_PROFILE_FORM_LAST_NAME] ? form_data[EDIT_PROFILE_FORM_LAST_NAME] : "";
        const gender = form_data && form_data[EDIT_PROFILE_FORM_GENDER] ? form_data[EDIT_PROFILE_FORM_GENDER] : "";
        const relationship_status = form_data && form_data[EDIT_PROFILE_FORM_RELATIONSHIP_STATUS] ? form_data[EDIT_PROFILE_FORM_RELATIONSHIP_STATUS] : "";
        const country = form_data && form_data[EDIT_PROFILE_FORM_COUNTRY] ? form_data[EDIT_PROFILE_FORM_COUNTRY] : "";
        const website = form_data && form_data[EDIT_PROFILE_FORM_WEBSITE] ? form_data[EDIT_PROFILE_FORM_WEBSITE] : "";
        const dob_date = form_data && form_data[EDIT_PROFILE_FORM_DOB_DATE] ? form_data[EDIT_PROFILE_FORM_DOB_DATE] : "";
        const dob_month = form_data && form_data[EDIT_PROFILE_FORM_DOB_MONTH] ? form_data[EDIT_PROFILE_FORM_DOB_MONTH] : "";
        const dob_year = form_data && form_data[EDIT_PROFILE_FORM_DOB_YEAR] ? form_data[EDIT_PROFILE_FORM_DOB_YEAR] : "";
        const about_me = form_data && form_data[EDIT_PROFILE_FORM_ABOUT_ME] ? form_data[EDIT_PROFILE_FORM_ABOUT_ME] : "";

        const errors = edit_profile_key && edit_profile_key[EDIT_PROFILE_ERRORS] ? edit_profile_key[EDIT_PROFILE_ERRORS] : [];
        const loading = edit_profile_key && edit_profile_key[EDIT_PROFILE_REQEUST_LOADING] ? edit_profile_key[EDIT_PROFILE_REQEUST_LOADING] : false;

        return {
            email,
            first_name,
            last_name,
            gender,
            relationship_status,
            country,
            website,
            dob_date,
            dob_month,
            dob_year,
            about_me,
            errors,
            loading
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
