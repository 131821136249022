<template>
  <div class="wr_info_online_people">
    <div
      class="wr_info_Img"
      v-for="item in onlinePeople.slice(0, 9)"
      :key="item.id"
    >
      <div class="cursor-pointer"><img
        @click="redirectWidgetsToProfilePage($event, item.username)"
        :src="item.image" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onlinePeople: Array,
  },
};
</script>

<style lang="scss" scoped>
.wr_info_online_people {
  justify-content: unset !important;
  padding-left: 11px;
  div {
    background: #e6eaf0;
    border-radius: 50%;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }
  .wr_info_Img {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff;
    margin-left: -14px;
    &:hover{
      opacity: 0.8;
    }
  }
}
</style>