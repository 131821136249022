<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ChartTopSellingList gridTitle="GridSelling4" :listData="listData4" :hasHeart="true" :isStreaming="true"/>
        <Episodes gridTitle="GridSelling4" :listData="listData4[0]" :hasHeart="true" :isStreaming="true"/>
        <Event gridTitle="GridSelling4" :listData="listData4" :hasHeart="true" :isStreaming="true"/>
        <FeaturedEpisodes gridTitle="GridSelling4" :listData="listData4" :hasHeart="true" :isStreaming="true"/>
        <GridSelling4 gridTitle="GridSelling4" :listData="listData4" :hasHeart="true" :isStreaming="true"/>
        <GridSelling6 gridTitle="GridSelling6 with header" :header="true" :listData="listData6" :hasHeart="true"
                      :isStreaming="true"/>
        <h4>with no title/header</h4>
        <GridSelling6 :listData="listData6" :hasHeart="true" :isStreaming="false"/>
        <GridSelling6 :listData="listData6" :hasHeart="true" :isStreaming="true"/>
        <h4>with no no slider</h4>
        <GridSelling6NoSlider gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6"
                              :hasHeart="true" :isStreaming="true"/>
        <GridSelling6Now gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6" :hasHeart="true"
                         :isStreaming="true"/>
        <GridSellingProfile6 gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6"
                             :hasHeart="true" :isStreaming="true"/>
        <GridSellingProfile7 gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6"
                             :hasHeart="true" :isStreaming="true"/>
        <GridSellingProfile8 gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6"
                             :hasHeart="true" :isStreaming="true"/>
        <GridSellingProfile9 gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6"
                             :hasHeart="true" :isStreaming="true"/>
        <GridSellingProfile10 gridTitle="GridSelling6 with no slider" :header="true" :listData="listData6"
                              :hasHeart="true" :isStreaming="true"/>
        <GridSellingWithSlider gridTitle="GridSelling with slider" :header="true" :listData="listData6" :hasHeart="true"
                               :isStreaming="true"/>
        <LatestRelease gridTitle="GridSelling with slider" :header="true" :listData="listData6" :hasHeart="true"
                       :isStreaming="true"/>
        <NewAlbumSingles :listData="listData6"/>
        <NewEpisodesSlider :listData="listData6"/>
        <PlayList :listData="listData6"/>
        <PodcastSelling/>
        <PopularArtist :listData="listData6"/>
        <PopularLabels :listData="listData6"/>
        <RecentReviews :listData="listData6"/>
        <Review4Slider :listData="listData6"/>
        <ReviewListView :listData="listData6"/>
        <Reviews :listData="listData6"/>
        <SongsAlbum34Selling :loopCount="1"/>
        <SongAlbumSelling :listData="listData6"/>
        <Singles :listData="listData6"/>
        <SongsPopularity :listData="listData6"/>
        <SongStreaming :listData="listData6"/>
        <TopCharts :listData="listData6"/>
        <TopNewSinglesAlbums :listData="listData6"/>
        <TrendingRelease :listData="listData6"/>
        <WhoToFollow :listData="listData6"/>
        <br><br>
      </div>
    </div>

  </div>
</template>

<script>
import ChartTopSellingList from "@/components/Common/Elements/ChartTopSellingList"
import Episodes from "@/components/Common/Elements/Episodes"
import Event from "@/components/Common/Elements/Event"
import FeaturedEpisodes from "@/components/Common/Elements/FeaturedEpisodes"
import GridSelling4 from "@/components/Common/Elements/GridSelling4"
import GridSelling6 from "@/components/Common/Elements/GridSelling6"
import GridSelling6NoSlider from "@/components/Common/Elements/GridSelling6NoSlider"
import GridSelling6Now from "@/components/Common/Elements/GridSellingNow6"
import GridSellingProfile6 from "@/components/Common/Elements/GridSellingProfile6"
import GridSellingProfile7 from "@/components/Common/Elements/GridSellingProfile7"
import GridSellingProfile8 from "@/components/Common/Elements/GridSellingProfile8"
import GridSellingProfile9 from "@/components/Common/Elements/GridSellingProfile9"
import GridSellingProfile10 from "@/components/Common/Elements/GridSellingProfile10"
import GridSellingWithSlider from "@/components/Common/Elements/GridSellingWithSlider"
import LatestRelease from "@/components/Common/Elements/LatestRelease"
import NewAlbumSingles from "@/components/Common/Elements/NewAlbumSingles"
import NewEpisodesSlider from "@/components/Common/Elements/NewEpisodesSlider"
import PlayList from "@/components/Common/Elements/PlayList"
import PodcastSelling from "@/components/Common/Elements/PodcastSelling"
import PopularArtist from "@/components/Common/Elements/PopularArtist"
import PopularLabels from "@/components/Common/Elements/PopularLabels"
import RecentReviews from "@/components/Common/Elements/RecentReviews"
import Review4Slider from "@/components/Common/Elements/Review4Slider"
import ReviewListView from "@/components/Common/Elements/ReviewListView"
import Reviews from "@/components/Common/Elements/Reviews"
import SongsAlbum34Selling from "@/components/Common/Elements/Songs_Album_3_4_Selling"
import SongAlbumSelling from "@/components/Common/Elements/SongAlbumSelling"
import Singles from "@/components/Common/Elements/Singles"
import SongsPopularity from "@/components/Common/Elements/SongsPopularity"
import SongStreaming from "@/components/Common/Elements/SongStreaming"
import TopCharts from "@/components/Common/Elements/TopCharts"
import TopNewSinglesAlbums from "@/components/Common/Elements/TopNewSinglesAlbums"
import TrendingRelease from "@/components/Common/Elements/TrendingRelease"
import WhoToFollow from "@/components/Common/Elements/WhoToFollow"


export default {
  name: "elements",
  components: {
    ChartTopSellingList,
    Episodes,
    Event,
    FeaturedEpisodes,
    GridSelling4,
    GridSelling6,
    GridSelling6NoSlider,
    GridSelling6Now,
    GridSellingProfile6,
    GridSellingProfile7,
    GridSellingProfile8,
    GridSellingProfile9,
    GridSellingProfile10,
    GridSellingWithSlider,
    LatestRelease,
    NewAlbumSingles,
    NewEpisodesSlider, PlayList, PodcastSelling,
    PopularArtist,
    PopularLabels, RecentReviews,
    Review4Slider,
    ReviewListView,
    Reviews,
    SongsAlbum34Selling,
    SongAlbumSelling,
    Singles,
    SongsPopularity,
    SongStreaming,
    TopCharts, TopNewSinglesAlbums,
    TrendingRelease,
    WhoToFollow,


  },
  data() {
    return {
      listData6: [{
        approved: 1,
        artists: [{id: 6, name: "Katty perry"},
          {id: 7, name: "Wiz Khalifa"}],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      },
        {
          approved: 1,
          artists: [{id: 6, name: "Katty perry"},
            {id: 7, name: "Wiz Khalifa"}],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },
        {
          approved: 1,
          artists: [{id: 6, name: "Katty perry"},
            {id: 7, name: "Wiz Khalifa"}],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },
        {
          approved: 1,
          artists: [{id: 6, name: "Katty perry"},
            {id: 7, name: "Wiz Khalifa"}],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },
        {
          approved: 1,
          artists: [{id: 6, name: "Katty perry"},
            {id: 7, name: "Wiz Khalifa"}],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        }, {
          approved: 1,
          artists: [{id: 6, name: "Katty perry"},
            {id: 7, name: "Wiz Khalifa"}],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },],
      listData4: [{
        approved: 1,
        artists: [],
        artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
        audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
        creative_common: null,
        description: null,
        duration: 123,
        favorite: false,
        feature_artist: [],
        flac: 0,
        genre: [],
        guest: null,
        hd: 0,
        hls: 0,
        host_id: 112,
        id: 64,
        include_rss_feed: null,
        loves: 0,
        metadata_language: null,
        moderator: null,
        mp3: 1,
        pending: 0,
        plays: 20,
        preorder_info: null,
        price: null,
        producer: null,
        publisher: null,
        record_label: null,
        released_at: null,
        remixer: null,
        sale_date: null,
        selling: 1,
        streamable: false,
        sub_genre: null,
        subgenre: [],
        subscription: null,
        tags: [],
        title: "Easy on Me",
        updated_at: "2021-11-23T12:42:39.000000Z",
        visibility: 1,
        wav: 0,
        waveform: 0,
        prev_page_url: null,
        to: 7,
        total: 7,
      },
        {
          approved: 1,
          artists: [],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },
        {
          approved: 1,
          artists: [],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },
        {
          approved: 1,
          artists: [],
          artwork_url: "http://auddiodev-backend.theplayground.co.uk/storage/603/conversions/1637048888-lg.jpg",
          audio_url: "http://auddiodev-backend.theplayground.co.uk/storage/604/8vLCrITbjD.mp3",
          creative_common: null,
          description: null,
          duration: 123,
          favorite: false,
          feature_artist: [],
          flac: 0,
          genre: [],
          guest: null,
          hd: 0,
          hls: 0,
          host_id: 112,
          id: 64,
          include_rss_feed: null,
          loves: 0,
          metadata_language: null,
          moderator: null,
          mp3: 1,
          pending: 0,
          plays: 20,
          preorder_info: null,
          price: null,
          producer: null,
          publisher: null,
          record_label: null,
          released_at: null,
          remixer: null,
          sale_date: null,
          selling: 1,
          streamable: false,
          sub_genre: null,
          subgenre: [],
          subscription: null,
          tags: [],
          title: "Easy on Me",
          updated_at: "2021-11-23T12:42:39.000000Z",
          visibility: 1,
          wav: 0,
          waveform: 0,
          prev_page_url: null,
          to: 7,
          total: 7,
        },]
    }
  }
}
</script>

<style scoped>

</style>
