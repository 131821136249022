<template>
  <div class="about-work work-tab">
    <div class="row">
      <div class="col-12">
        <div class="total-projects">
          <h1>Education</h1>
          <span>{{ total }}</span>
          <div class="edit-delete flex-row-reverse ml-auto" v-if="editAble" @click="goToEducationSection">
            <button class="rounded">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 2.00004C11.5083 1.82494 11.7162 1.68605 11.945 1.59129C12.1738 1.49653 12.419 1.44775 12.6666 1.44775C12.9142 1.44775 13.1594 1.49653 13.3882 1.59129C13.617 1.68605 13.8248 1.82494 13.9999 2.00004C14.175 2.17513 14.3139 2.383 14.4087 2.61178C14.5034 2.84055 14.5522 3.08575 14.5522 3.33337C14.5522 3.58099 14.5034 3.82619 14.4087 4.05497C14.3139 4.28374 14.175 4.49161 13.9999 4.66671L4.99992 13.6667L1.33325 14.6667L2.33325 11L11.3333 2.00004Z"
                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="experience-details" v-for="(education, index) in displayedEducations" :key="index">
          <div class="experience-image">
            <img :src="require(`@/assets/img/education.png`)" />
          </div>
          <div class="experience-description pt-2">
            <div class="experience-heading">{{ education.title }}</div>
            <div class="experience-date">{{ education.from }} — {{ education.to }}</div>
            <div class="experience-description-detail">{{ education.description }}</div>
          </div>
        </div>
        <div v-if="remainingEducations > 0" @click="showMoreEducation" class="show-all-list">
          <a href="javascript:;">SHOW {{ remainingEducations }} MORE</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_user_education_api } from "../../../../../apis/APIs";

export default {
  name: "Education",
  data() {
    return {
      user_profile_education_list: [],
      limit: 3,
      offset: 0,
      total: 0
    };
  },
  props: {
    editAble: {
      type: Boolean,
      default: false
    },
    typeCheck: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Boolean
    }
  },
  computed: {
    education_list() {
      return this.current_page ? this.current_page.education : [];
    },
    current_page() {
      return this.$store.getters.getCurrentPage;
    },
    displayedEducations() {
      return this.user_profile_education_list.slice(0, this.offset + this.limit);
    },
    remainingEducations() {
      return this.total - (this.offset + this.limit);
    }
  },
  mounted() {
    if (this.typeCheck === true) {
      this.fetchUserProfileEducationData();
    }
  },
  methods: {
    showMoreEducation() {
      if (this.offset + this.limit < this.total) {
        this.offset += this.limit;
        this.fetchUserProfileEducationData();
      }
    },
    async fetchUserProfileEducationData() {
      try {
        await this.$store.dispatch("loading", true);

        const res = await get_user_education_api(this.offset, this.limit);
        const { success, data } = res.data || {};
        this.total = data.total;
        if (success === 1) {
          if (data && data.data && data.data.length > 0) {
            this.user_profile_education_list = [...this.user_profile_education_list, ...data.data];
          } else {
            this.user_profile_education_list = [];
          }
        }
        await this.$store.dispatch("loading", false);
      } catch (error) {
        await this.$store.dispatch("loading", false);
      }
    },
    goToEducationSection() {
      this.$router.push(`/page-manager/page/work-and-education/${this.$route.params.id}`);
    }
  }
};
</script>

<style scoped>

</style>
