<template>
  <div class="featured-review-main">
    <div class="featured-user-detail">
      <div class="featured-user-detail-img">
        <span><img :src="userPicture"></span>
        <div class="featured-user-details">
          <h1>{{ userName }}</h1>
          <p>{{ item.updated_at | formatDate }}</p>
        </div>
      </div>
      <div class="user-start-review">
        <div class="user-rate">
          <star-rating :read-only="true"
                       v-model="getRating"
                       :inline="true"
                       v-bind:increment="0.5"
                       v-bind:max-rating="5"
                       inactive-color="#47505B"
                       active-color="#0075FF"
                       v-bind:star-size="15" />
          <span style="margin-top: 0.1rem;">{{ (getRating > 0) ? getRating + '.0' : '' }}</span>
        </div>
      </div>
    </div>
    <div class="review-details">
      <p>{{ (item && item.comment) ? item.comment: ''}} </p>
    </div>
    <div class="user-profile-detail-sep-comment"></div>
    <div class="like-reply">
      <ul>
        <li>
          <a href="javascript:;">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.66536 14.6673H2.66536C2.31174 14.6673 1.9726 14.5268 1.72256 14.2768C1.47251 14.0267 1.33203 13.6876 1.33203 13.334V8.66732C1.33203 8.3137 1.47251 7.97456 1.72256 7.72451C1.9726 7.47446 2.31174 7.33398 2.66536 7.33398H4.66536M9.33203 6.00065V3.33398C9.33203 2.80355 9.12132 2.29484 8.74625 1.91977C8.37117 1.5447 7.86246 1.33398 7.33203 1.33398L4.66536 7.33398V14.6673H12.1854C12.5069 14.671 12.819 14.5583 13.064 14.35C13.309 14.1417 13.4705 13.8519 13.5187 13.534L14.4387 7.53398C14.4677 7.34289 14.4548 7.14777 14.4009 6.96215C14.347 6.77653 14.2534 6.60485 14.1266 6.45899C13.9998 6.31314 13.8428 6.19661 13.6664 6.11746C13.4901 6.03832 13.2986 5.99846 13.1054 6.00065H9.33203Z"
                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"></path>
            </svg>
            Like
            <span>24</span>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.3327 1.33268L13.3327 1.33268C13.6863 1.33268 14.0254 1.47316 14.2755 1.72321C14.5255 1.97325 14.666 2.31239 14.666 2.66602L14.666 7.33268C14.666 7.6863 14.5255 8.02544 14.2755 8.27549C14.0254 8.52554 13.6863 8.66602 13.3327 8.66602L11.3327 8.66602M6.66602 9.99935L6.66602 12.666C6.66602 13.1964 6.87673 13.7052 7.2518 14.0802C7.62687 14.4553 8.13558 14.666 8.66602 14.666L11.3327 8.66602L11.3327 1.33268L3.81268 1.33268C3.49113 1.32905 3.1791 1.44174 2.93408 1.65001C2.68906 1.85828 2.52756 2.14808 2.47935 2.46601L1.55935 8.46601C1.53034 8.65711 1.54323 8.85223 1.59712 9.03785C1.65101 9.22347 1.74461 9.39515 1.87144 9.541C1.99827 9.68686 2.1553 9.80339 2.33163 9.88254C2.50797 9.96168 2.69941 10.0015 2.89268 9.99935L6.66602 9.99935Z"
                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Dislike
            <span>12</span>
          </a>
        </li>
        <li>
          <a href="javascript:;">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 9C13 9.35362 12.8595 9.69276 12.6095 9.94281C12.3594 10.1929 12.0203 10.3333 11.6667 10.3333H3.66667L1 13V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V9Z"
                stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Comment
            <span>12</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import moment from "moment";
import '/public/css/vue-star-rating.scss';
export default {
  name: "ReviewTile",
  data(){
    return{
      selected_rating: 0
    }
  },
  props:{
    item: {
      type:Object,
    }
  },
  filters:{
    formatDate(value){
      return moment(value).calendar()
    }
  },
  computed:{
    userName(){
      return (this.item && this.item.reviewable_user && this.item.reviewable_user.name) ? this.item.reviewable_user.name : ''
    },
    getRating(){
      return (this.item && this.item.rating) ? parseInt(this.item.rating) : 0
    },
    userPicture() {
      return (this.item && this.item.reviewable_user && this.item.reviewable_user.artwork_url) ? this.item.reviewable_user.artwork_url : 'require(`@/assets/img/svg/mask-group.svg`)'
    }
  },
  components:{
    StarRating
  }
};
</script>

<style scoped>

</style>
