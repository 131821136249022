export const feelings = [
    {
        index: 0,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M28.88 9.5C28.93 9.96105 28.96 10.427 28.96 10.8977C28.96 18.9395 21.535 25.4573 12.37 25.4573C8.445 25.4573 4.84 24.2586 2 22.259C4.6 26.5881 9.43 29.5 14.965 29.5C23.25 29.5 29.965 22.9822 29.965 14.9404C29.965 13.0137 29.575 11.1792 28.88 9.5Z"
                    fill="#F1C631" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 19C7 23.4183 10.5817 27 15 27C19.4183 27 23 23.4183 23 19H7Z"
                    fill="#393C54" />
                    <path
                    d="M18.9448 15.3701C19.4798 14.9051 20.1798 15.1251 20.9448 15.1251C21.7098 15.1251 22.4048 14.9051 22.9448 15.3701C23.5948 14.8101 24.0048 13.9801 24.0048 13.0551C24.0048 11.3651 22.6348 9.99512 20.9448 9.99512C19.2548 9.99512 17.8848 11.3651 17.8848 13.0551C17.8848 13.9851 18.2948 14.8101 18.9448 15.3701Z"
                    fill="white" />
                    <path
                    d="M20.9405 14.8147C21.9098 14.8147 22.6955 14.0289 22.6955 13.0597C22.6955 12.0904 21.9098 11.3047 20.9405 11.3047C19.9713 11.3047 19.1855 12.0904 19.1855 13.0597C19.1855 14.0289 19.9713 14.8147 20.9405 14.8147Z"
                    fill="#383B53" />
                    <path
                    d="M7.06 15.3701C7.595 14.9051 8.295 15.1251 9.06 15.1251C9.825 15.1251 10.52 14.9051 11.06 15.3701C11.71 14.8101 12.12 13.9801 12.12 13.0551C12.12 11.3651 10.75 9.99512 9.06 9.99512C7.37 10.0001 6 11.3701 6 13.0601C6 13.9851 6.41 14.8101 7.06 15.3701Z"
                    fill="white" />
                    <path
                    d="M9.05969 14.8147C10.0289 14.8147 10.8147 14.0289 10.8147 13.0597C10.8147 12.0904 10.0289 11.3047 9.05969 11.3047C8.09043 11.3047 7.30469 12.0904 7.30469 13.0597C7.30469 14.0289 8.09043 14.8147 9.05969 14.8147Z"
                    fill="#383B53" />
                    <path d="M10 19H20C20 20.1046 19.1046 21 18 21H12C10.8954 21 10 20.1046 10 19Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M19.8866 25.3346C19.6124 24.5571 18.8712 24 17.9999 24H11.9999C11.1286 24 10.3875 24.5571 10.1133 25.3346C11.4649 26.3788 13.1598 27 14.9999 27C16.84 27 18.535 26.3788 19.8866 25.3346Z"
                    fill="white" />
                </svg>
                <span class="ml-2">Happy</span>`,
        value: "Happy",
        question: "How are you feeling?"
    },
    {
        index: 1,
        html: `<svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC25" />
                    <path
                    d="M21.1276 6.74492C21.1276 6.74492 22.265 6.3767 23.6809 7.19415C25.0967 8.0116 25.1729 9.08049 25.1729 9.08049"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M8.70445 6.74492C8.70445 6.74492 7.56703 6.3767 6.15116 7.19415C4.7353 8.0116 4.65912 9.08049 4.65912 9.08049"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M21 15.5C22.933 15.5 24.5 13.933 24.5 12C24.5 10.067 22.933 8.5 21 8.5C19.067 8.5 17.5 10.067 17.5 12C17.5 13.933 19.067 15.5 21 15.5Z"
                    fill="white" />
                    <path
                    d="M21 13.75C21.9665 13.75 22.75 12.9665 22.75 12C22.75 11.0335 21.9665 10.25 21 10.25C20.0335 10.25 19.25 11.0335 19.25 12C19.25 12.9665 20.0335 13.75 21 13.75Z"
                    fill="#383B53" />
                    <path
                    d="M9 15.5C10.933 15.5 12.5 13.933 12.5 12C12.5 10.067 10.933 8.5 9 8.5C7.06701 8.5 5.5 10.067 5.5 12C5.5 13.933 7.06701 15.5 9 15.5Z"
                    fill="white" />
                    <path
                    d="M9 13.75C9.9665 13.75 10.75 12.9665 10.75 12C10.75 11.0335 9.9665 10.25 9 10.25C8.0335 10.25 7.25 11.0335 7.25 12C7.25 12.9665 8.0335 13.75 9 13.75Z"
                    fill="#383B53" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 18C7 22.4183 10.5817 26 15 26C19.4183 26 23 22.4183 23 18H7Z"
                    fill="#393C54" />
                    <path
                    d="M11.5 27.3639C11.5 29.0959 13.067 30.5 15 30.5C16.933 30.5 18.5 29.0959 18.5 27.3639L18.5 20.5C18.5 19.3954 17.6046 18.5 16.5 18.5L13.5 18.5C12.3954 18.5 11.5 19.3954 11.5 20.5L11.5 27.3639Z"
                    fill="#F85565" />
                    <path opacity="0.2"
                    d="M14.5 27.9093C14.5 29.064 14.7239 29 15 29C15.2761 29 15.5 29.064 15.5 27.9093L15.5 19C15.5 18.7239 15.2761 18.5 15 18.5C14.7239 18.5 14.5 18.7239 14.5 19L14.5 27.9093Z"
                    fill="white" />
                    <path opacity="0.2"
                    d="M9 18H21V19C21 20.1046 20.1046 21 19 21H11C9.89543 21 9 20.1046 9 19V18Z"
                    fill="#393C54" />
                    <path d="M10 18H20C20 19.1046 19.1046 20 18 20H12C10.8954 20 10 19.1046 10 18Z"
                    fill="white" />
                </svg>

                <span class="ml-2">Joking</span>`,
        value: "Joking",
        question: "How are you feeling?"
    },
    {
        index: 2,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="#F8DC25" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.4375 24.7498C10.7167 23.0781 12.7324 22 14.9999 22C17.2674 22 19.2831 23.0781 20.5623 24.7498C19.1227 26.1428 17.1614 27 14.9999 27C12.8384 27 10.8771 26.1428 9.4375 24.7498Z"
                    fill="#F85565" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.7473 12.9987C10.5996 12.3623 9.27893 12 7.87365 12C6.46836 12 5.1477 12.3623 4 12.9987C4.4445 11.2741 6.01028 10 7.87365 10C9.73701 10 11.3028 11.2741 11.7473 12.9987Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.7473 12.9987C26.5996 12.3623 25.2789 12 23.8736 12C22.4684 12 21.1477 12.3623 20 12.9987C20.4445 11.2741 22.0103 10 23.8736 10C25.737 10 27.3028 11.2741 27.7473 12.9987Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 19C7 23.4183 10.5817 27 15 27C19.4183 27 23 23.4183 23 19H7Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.4375 24.7498C10.7167 23.0781 12.7324 22 14.9999 22C17.2674 22 19.2831 23.0781 20.5623 24.7498C19.1227 26.1428 17.1614 27 14.9999 27C12.8384 27 10.8771 26.1428 9.4375 24.7498Z"
                    fill="#F85565" />
                </svg>

                <span class="ml-2">Giggling</span>`,
        value: "Giggling"
    },
    {
        index: 3,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="#F8DC25" />
                    <circle cx="15" cy="19" r="8" fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.4375 24.7498C10.7167 23.0781 12.7324 22 14.9999 22C17.2674 22 19.2831 23.0781 20.5623 24.7498C19.1227 26.1428 17.1614 27 14.9999 27C12.8384 27 10.8771 26.1428 9.4375 24.7498Z"
                    fill="#F85565" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.7473 9.99869C10.5996 9.36234 9.27893 9 7.87365 9C6.46836 9 5.1477 9.36234 4 9.99869C4.4445 8.27413 6.01028 7 7.87365 7C9.73701 7 11.3028 8.27413 11.7473 9.99869Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.7473 9.99869C26.5996 9.36234 25.2789 9 23.8736 9C22.4684 9 21.1477 9.36234 20 9.99869C20.4445 8.27413 22.0103 7 23.8736 7C25.737 7 27.3028 8.27413 27.7473 9.99869Z"
                    fill="#393C54" />
                    <circle cx="15" cy="19" r="8" fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.4375 24.7498C10.7167 23.0781 12.7324 22 14.9999 22C17.2674 22 19.2831 23.0781 20.5623 24.7498C19.1227 26.1428 17.1614 27 14.9999 27C12.8384 27 10.8771 26.1428 9.4375 24.7498Z"
                    fill="#F85565" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.7473 9.99869C26.5996 9.36234 25.2789 9 23.8736 9C22.4684 9 21.1477 9.36234 20 9.99869C20.4445 8.27413 22.0103 7 23.8736 7C25.737 7 27.3028 8.27413 27.7473 9.99869Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.7986 14.7488L26.5 11L25.2014 14.7488C25.0733 14.9697 25 15.2263 25 15.5C25 16.3284 25.6716 17 26.5 17C27.3284 17 28 16.3284 28 15.5C28 15.2263 27.9267 14.9697 27.7986 14.7488Z"
                    fill="#00ADFE" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.79861 14.7488L4.5 11L3.20139 14.7488C3.07332 14.9697 3 15.2263 3 15.5C3 16.3284 3.67157 17 4.5 17C5.32843 17 6 16.3284 6 15.5C6 15.2263 5.92668 14.9697 5.79861 14.7488Z"
                    fill="#00ADFE" />
                </svg>
                <span class="ml-2">Hysterical</span>`,
        value: "Hysterical"
    },
    {
        index: 4,
        html: `<svg width="31" height="30" viewBox="0 0 31 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path d="M17.75 12.5C17.75 12.5 19 15.75 21.5 15.75C25 15.75 28.5 15.75 28.5 15.75"
                    stroke="#F6CF1C" stroke-linecap="round" />
                    <path
                    d="M13 12.5C13 12.5 11.75 15.75 9.25 15.75C8.18833 15.75 7.12666 15.75 6.16267 15.75M4.5 17.5C4.5 17.5 4.54233 15.75 5.75 15.75M6.16267 15.75C5.46075 15.75 6.31062 15.75 5.75 15.75M6.16267 15.75H5.75"
                    stroke="#F6CF1C" stroke-linecap="round" />
                    <path
                    d="M23.4186 13.9573C23.4186 13.9573 21.6682 14.017 19.9734 12.8901C18.2786 11.7632 18.5763 10.7376 18.5763 10.7376"
                    stroke="#F6CF1C" stroke-linecap="round" />
                    <path
                    d="M17.5993 5.00166C17.5993 5.00166 16.806 5.88086 15.1865 5.88086C13.5671 5.88086 12.9723 5.00166 12.9723 5.00166"
                    stroke="#F7D31F" stroke-linecap="round" />
                    <path
                    d="M22.6217 6.80346C17.6216 6.80346 17.3716 7.88086 14.7866 7.88086C12.2016 7.88086 12.6216 6.80346 7.37158 6.80346"
                    stroke="#F7D31F" stroke-linecap="round" />
                    <path
                    d="M6.6869 13.9573C6.6869 13.9573 8.43728 14.017 10.1321 12.8901C11.8269 11.7632 11.5292 10.7376 11.5292 10.7376"
                    stroke="#F6CF1C" stroke-linecap="round" />
                    <path
                    d="M9.5 12.5C10.6046 12.5 11.5 11.6046 11.5 10.5C11.5 9.39543 10.6046 8.5 9.5 8.5C8.39543 8.5 7.5 9.39543 7.5 10.5C7.5 11.6046 8.39543 12.5 9.5 12.5Z"
                    fill="white" />
                    <path
                    d="M9.50028 11.6431C10.1315 11.6431 10.6431 11.1315 10.6431 10.5003C10.6431 9.8691 10.1315 9.35742 9.50028 9.35742C8.8691 9.35742 8.35742 9.8691 8.35742 10.5003C8.35742 11.1315 8.8691 11.6431 9.50028 11.6431Z"
                    fill="#383B53" />
                    <path
                    d="M20.5 12.5C21.6046 12.5 22.5 11.6046 22.5 10.5C22.5 9.39543 21.6046 8.5 20.5 8.5C19.3954 8.5 18.5 9.39543 18.5 10.5C18.5 11.6046 19.3954 12.5 20.5 12.5Z"
                    fill="white" />
                    <path
                    d="M20.5003 11.6431C21.1315 11.6431 21.6431 11.1315 21.6431 10.5003C21.6431 9.8691 21.1315 9.35742 20.5003 9.35742C19.8691 9.35742 19.3574 9.8691 19.3574 10.5003C19.3574 11.1315 19.8691 11.6431 20.5003 11.6431Z"
                    fill="#383B53" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path
                    d="M7.49967 18.25C6.94738 18.25 6.49522 18.7008 6.53917 19.2513C7.0362 25.4774 12.2014 28.5 18.5 28.5C24.6306 28.5 29.6875 23.7111 30.4112 17.5286C30.4951 16.8118 29.7533 16.308 29.0719 16.5456C27.1622 17.2115 23.5715 18.25 19.75 18.25C15.3593 18.25 9.7499 18.25 7.49967 18.25Z"
                    fill="#F4C515" />
                    <path
                    d="M7.9978 17C6.34094 17 4.95889 18.3686 5.41037 19.9627C6.80247 24.8782 11.5757 28.5 17.25 28.5C22.9243 28.5 27.6975 24.8782 29.0896 19.9627C29.5411 18.3685 28.1591 17 26.5022 17C24.0777 17 20.5383 17 17.25 17C13.9617 17 10.4223 17 7.9978 17Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M20.8334 28C21.3951 27.8389 21.9397 27.641 22.4638 27.4092C22.1289 24.6494 19.4744 22.5 16.2495 22.5C13.6606 22.5 11.4394 23.8851 10.4906 25.8592C10.3998 26.0481 10.3206 26.2425 10.2539 26.4416C11.2863 27.117 12.4348 27.647 13.6655 28L16.2495 28H20.8334Z"
                    fill="#F85565" />
                    <path d="M15.5 22.5H16.5V24L15.5 22.5Z" fill="white" fill-opacity="0.2" />
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="17"
                    width="25" height="12">
                    <path
                        d="M6.49697 17C5.66854 17 4.98916 17.6755 5.10272 18.4961C5.88381 24.1405 11.0246 28.5 17.25 28.5C23.4754 28.5 28.6162 24.1405 29.3973 18.4961C29.5108 17.6755 28.8315 17 28.003 17C25.7471 17 21.3074 17 17.25 17C13.1926 17 8.75291 17 6.49697 17Z"
                        fill="#393C54" />
                    </mask>
                    <g mask="url(#mask0)">
                    <path
                        d="M2.5 17H24.5C24.5 18.6569 23.1569 20 21.5 20H5.5C3.84315 20 2.5 18.6569 2.5 17Z"
                        fill="#FF8475" />
                    <path
                        d="M2.5 18.5H23C23 19.8807 21.8807 21 20.5 21H5C3.61929 21 2.5 19.8807 2.5 18.5Z"
                        fill="white" />
                    <path
                        d="M23.5 29L2.5 29C2.5 27.3431 3.84315 26 5.5 26L20.5 26C22.1569 26 23.5 27.3431 23.5 29Z"
                        fill="#FF8475" />
                    <path
                        d="M22.5 27L3 27C3 25.6193 4.11929 24.5 5.5 24.5L20 24.5C21.3807 24.5 22.5 25.6193 22.5 27Z"
                        fill="white" />
                    </g>
                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.10229 18.4961C4.98873 17.6755 5.66811 17 6.49654 17H8.99654C8.16811 17 7.48873 17.6755 7.60229 18.4961C8.33044 23.7579 12.8473 27.9031 18.4996 28.4409C18.0886 28.48 17.6716 28.5 17.2496 28.5C11.0242 28.5 5.88339 24.1405 5.10229 18.4961Z"
                    fill="#393C54" />
                    <path
                    d="M15.9133 12.6097C15.9133 12.6097 16.1333 13.8718 16.4015 14.5652C16.6697 15.2587 17.3187 15.5508 17.3187 15.5508"
                    stroke="#F6CF1C" stroke-linecap="round" />
                </svg>
                <span class="ml-2">LOL</span>`,
        value: "LOL"
    },
    {
        index: 5,
        html: `<svg width="31" height="30" viewBox="0 0 31 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15.5 30C23.7843 30 30.5 23.2843 30.5 15C30.5 6.71573 23.7843 0 15.5 0C7.21573 0 0.5 6.71573 0.5 15C0.5 23.2843 7.21573 30 15.5 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M29.38 9.5C29.43 9.97258 29.46 10.4501 29.46 10.9327C29.46 19.1754 22.035 25.8562 12.87 25.8562C8.945 25.8562 5.34 24.6275 2.5 22.578C5.1 27.0153 9.93 30 15.465 30C23.75 30 30.465 23.3192 30.465 15.0764C30.465 13.1016 30.075 11.2212 29.38 9.5Z"
                    fill="#F4C515" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M23.1777 9.50418C24.3082 9.5284 25.5159 9.65685 26.2426 9.83858C26.4485 9.89008 26.6762 9.95062 26.9139 10.0219L30.5 10.5L31 12.25L30 13V14.3087C30 14.8109 29.7953 15.3802 29.5847 15.851C29.3563 16.3617 29.0479 16.9128 28.7119 17.4265C28.3769 17.9389 27.9972 18.4404 27.6175 18.8423C27.4278 19.043 27.2243 19.2337 27.0136 19.3907C26.8136 19.5398 26.5499 19.702 26.2424 19.7789C25.6261 19.9329 24.7818 19.9962 23.9688 19.9962C23.1455 19.9962 22.2632 19.9312 21.5538 19.7893C20.9982 19.6781 20.4841 19.3404 20.0775 19.0041C19.6484 18.6492 19.233 18.2065 18.8716 17.749C18.5098 17.2911 18.1826 16.7937 17.9406 16.3177C17.7431 15.9292 17.5528 15.4604 17.5093 15H17.5L16 13H15.5V11.5H16L18.8598 10.5467C19.1894 10.3373 19.5463 10.1627 19.845 10.0166L19.845 10.0166C19.9181 9.98085 19.9878 9.94677 20.0527 9.9143C20.4727 9.70428 21.0152 9.60927 21.503 9.55904C22.0215 9.50566 22.6042 9.4919 23.1777 9.50418ZM20.2599 10.9274C19.346 11.3768 18.5 11.7927 18.5 12.8087V14.8087C18.5 15.9133 20.5 18.5586 21.75 18.8087C23 19.0588 25 19.0586 26 18.8087C27 18.5588 29 15.4133 29 14.3087V12.8087C29 11.7041 27.25 11.1213 26 10.8087C24.75 10.4961 21.5 10.3086 20.5 10.8087C20.4198 10.8488 20.3396 10.8882 20.26 10.9274L20.2599 10.9274Z"
                    fill="#393C54" />
                    <path
                    d="M18.5 12.8087C18.5 11.7041 19.5 11.3088 20.5 10.8087C21.5 10.3086 24.75 10.4961 26 10.8087C27.25 11.1213 29 11.7041 29 12.8087V14.3087C29 15.4133 27 18.5588 26 18.8087C25 19.0586 23 19.0588 21.75 18.8087C20.5 18.5586 18.5 15.9133 18.5 14.8087V12.8087Z"
                    fill="url(#paint0_radial)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M21.1281 11.9177C21.1281 11.9177 20.3627 12.3964 20.0404 12.9162C19.8725 13.187 19.8031 13.3815 19.7779 13.5177C19.7528 13.653 19.7669 13.7539 19.7974 13.8319C19.8822 14.048 20.0323 14.5022 20.0323 14.5022C20.0323 14.5022 19.6013 14.2883 19.4399 13.9918C19.333 13.7955 19.343 13.2992 19.343 13.2992C19.343 13.2992 19.3419 13.0435 19.571 12.674C20.0429 11.9131 20.4941 11.6943 20.9509 11.4657C21.2417 11.3202 22.1277 11.3014 22.1277 11.3014L21.4606 11.6877L21.2256 11.867L21.1281 11.9177Z"
                    fill="url(#paint1_linear)" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.82233 9.50418C6.69185 9.5284 5.48407 9.65685 4.75739 9.83858C4.55147 9.89008 4.32381 9.95062 4.08609 10.0219L0.5 10.5L0 12.25L1 13V14.3087C1 14.8109 1.2047 15.3802 1.41528 15.851C1.64371 16.3617 1.95208 16.9128 2.28808 17.4265C2.62314 17.9389 3.00283 18.4404 3.38249 18.8423C3.57217 19.043 3.77571 19.2337 3.98641 19.3907C4.18638 19.5398 4.45005 19.702 4.75757 19.7789C5.37388 19.9329 6.21817 19.9962 7.03121 19.9962C7.8545 19.9962 8.73678 19.9312 9.4462 19.7893C10.0018 19.6781 10.5159 19.3404 10.9225 19.0041C11.3516 18.6492 11.767 18.2065 12.1284 17.749C12.4902 17.2911 12.8174 16.7937 13.0594 16.3177C13.2569 15.9292 13.4472 15.4604 13.4907 15H13.5L15 13H15.5V11.5H15L12.1402 10.5467C11.8106 10.3373 11.4537 10.1627 11.155 10.0166L11.155 10.0166C11.0819 9.98085 11.0122 9.94677 10.9473 9.9143C10.5273 9.70428 9.98482 9.60927 9.49696 9.55904C8.97852 9.50566 8.39579 9.4919 7.82233 9.50418ZM10.7401 10.9274C11.654 11.3768 12.5 11.7927 12.5 12.8087V14.8087C12.5 15.9133 10.5 18.5586 9.25 18.8087C8 19.0588 6 19.0586 5 18.8087C4 18.5588 2 15.4133 2 14.3087V12.8087C2 11.7041 3.75 11.1213 5 10.8087C6.25 10.4961 9.5 10.3086 10.5 10.8087C10.5802 10.8488 10.6604 10.8882 10.74 10.9274L10.7401 10.9274Z"
                    fill="#393C54" />
                    <path
                    d="M12.5 12.8087C12.5 11.7041 11.5 11.3088 10.5 10.8087C9.5 10.3086 6.25 10.4961 5 10.8087C3.75 11.1213 2 11.7041 2 12.8087V14.3087C2 15.4133 4 18.5588 5 18.8087C6 19.0586 8 19.0588 9.25 18.8087C10.5 18.5586 12.5 15.9133 12.5 14.8087V12.8087Z"
                    fill="url(#paint2_radial)" />
                    <path opacity="0.7"
                    d="M8 17C8.9665 17 9.75 16.2165 9.75 15.25C9.75 14.2835 8.9665 13.5 8 13.5C7.0335 13.5 6.25 14.2835 6.25 15.25C6.25 16.2165 7.0335 17 8 17Z"
                    fill="#383B53" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M4.62809 11.9177C4.62809 11.9177 3.86272 12.3964 3.54044 12.9162C3.37246 13.187 3.30312 13.3815 3.27787 13.5177C3.25278 13.653 3.26688 13.7539 3.29742 13.8319C3.38216 14.048 3.53226 14.5022 3.53226 14.5022C3.53226 14.5022 3.10132 14.2883 2.93991 13.9918C2.83304 13.7955 2.84299 13.2992 2.84299 13.2992C2.84299 13.2992 2.84186 13.0435 3.07097 12.674C3.54286 11.9131 3.99411 11.6943 4.45093 11.4657C4.74168 11.3202 5.62768 11.3014 5.62768 11.3014L4.96061 11.6877L4.72555 11.867L4.62809 11.9177Z"
                    fill="url(#paint3_linear)" />
                    <path opacity="0.7"
                    d="M22 16C22 16 22.2946 14.5 23.7089 14.5C25.1232 14.5 25.3 16 25.3 16"
                    stroke="#393C54" stroke-linecap="round" />
                    <rect x="21" y="5.5" width="5.08237" height="1" rx="0.5"
                    transform="rotate(15 21 5.5)" fill="#F2BC0F" />
                    <path
                    d="M21.5041 22.2355C21.5041 22.2355 20.1113 23.9558 18.5017 24.749C16.8922 25.5422 15.4361 25.2259 15.4361 25.2259"
                    stroke="#393C54" stroke-width="1.5" stroke-linecap="round" />
                    <rect x="5.5" y="6.81543" width="5.08237" height="1" rx="0.5"
                    transform="rotate(-15 5.5 6.81543)" fill="#F2BC0F" />
                    <defs>
                    <radialGradient id="paint0_radial" cx="0" cy="0" r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(23.75 14.7481) rotate(90) scale(4.2481 5.25)">
                        <stop offset="0.703125" stop-color="#96916E" />
                        <stop offset="1" stop-color="#96916E" stop-opacity="0.87" />
                    </radialGradient>
                    <linearGradient id="paint1_linear" x1="19.9609" y1="14.6024" x2="22.4841"
                        y2="10.8322" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0833333" stop-color="white" stop-opacity="0" />
                        <stop offset="0.375" stop-color="white" />
                        <stop offset="0.8125" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <radialGradient id="paint2_radial" cx="0" cy="0" r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(7.25 14.7481) rotate(90) scale(4.2481 5.25)">
                        <stop offset="0.703125" stop-color="#96916E" />
                        <stop offset="1" stop-color="#96916E" stop-opacity="0.87" />
                    </radialGradient>
                    <linearGradient id="paint3_linear" x1="3.46088" y1="14.6024" x2="5.9841"
                        y2="10.8322" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0833333" stop-color="white" stop-opacity="0" />
                        <stop offset="0.375" stop-color="white" />
                        <stop offset="0.8125" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    </defs>
                </svg>
                <span class="ml-2">Cool</span>`,
        value: "Cool"
    },
    {
        index: 6,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M14.7541 29.5082C22.9026 29.5082 29.5082 22.9026 29.5082 14.7541C29.5082 6.60563 22.9026 0 14.7541 0C6.60563 0 0 6.60563 0 14.7541C0 22.9026 6.60563 29.5082 14.7541 29.5082Z"
                    fill="#F8DC24" />
                    <path
                    d="M28.4061 9.34473C28.4553 9.80578 28.4848 10.2717 28.4848 10.7424C28.4848 18.7842 21.1816 25.302 12.1668 25.302C8.30614 25.302 4.76024 24.1033 1.9668 22.1038C4.52417 26.4328 9.27499 29.3447 14.7193 29.3447C22.8684 29.3447 29.4734 22.8269 29.4734 14.7852C29.4734 12.8584 29.0897 11.0239 28.4061 9.34473Z"
                    fill="#F1C631" />
                    <path
                    d="M20.1637 17.022C21.7934 17.022 23.1145 15.4807 23.1145 13.5793C23.1145 11.678 21.7934 10.1367 20.1637 10.1367C18.534 10.1367 17.2129 11.678 17.2129 13.5793C17.2129 15.4807 18.534 17.022 20.1637 17.022Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M21.6404 18.0815C21.3563 18.1566 21.0607 18.1962 20.7572 18.1962C18.5513 18.1962 16.7631 16.1044 16.7631 13.524C16.7631 13.3569 16.7706 13.1919 16.7852 13.0293C16.4352 13.6604 16.2305 14.4235 16.2305 15.2455C16.2305 17.4184 17.661 19.1799 19.4257 19.1799C20.2856 19.1799 21.066 18.7617 21.6404 18.0815Z"
                    fill="#F1C631" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.8654 23.627C13.8341 23.4119 13.822 23.189 13.8313 22.961C13.8986 21.3042 15.0679 20.0064 16.4429 20.0623C16.5319 20.0659 16.6196 20.0751 16.7057 20.0896C16.3801 19.813 15.9796 19.6427 15.5416 19.6249C14.3837 19.5778 13.4013 20.6141 13.3475 21.9395C13.3212 22.5855 13.5204 23.1809 13.8654 23.627Z"
                    fill="#F1C631" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.86937 18.0815C8.15349 18.1566 8.44909 18.1962 8.75259 18.1962C10.9585 18.1962 12.7467 16.1044 12.7467 13.524C12.7467 13.3569 12.7392 13.1919 12.7246 13.0293C13.0746 13.6604 13.2793 14.4235 13.2793 15.2455C13.2793 17.4184 11.8487 19.1799 10.084 19.1799C9.22421 19.1799 8.44372 18.7617 7.86937 18.0815Z"
                    fill="#F1C631" />
                    <path
                    d="M9.3434 17.022C10.9731 17.022 12.2942 15.4807 12.2942 13.5793C12.2942 11.678 10.9731 10.1367 9.3434 10.1367C7.71371 10.1367 6.39258 11.678 6.39258 13.5793C6.39258 15.4807 7.71371 17.022 9.3434 17.022Z"
                    fill="white" />
                    <path
                    d="M17.2129 26.5569C18.571 26.5569 19.6719 25.2358 19.6719 23.6061C19.6719 21.9764 18.571 20.6553 17.2129 20.6553C15.8548 20.6553 14.7539 21.9764 14.7539 23.6061C14.7539 25.2358 15.8548 26.5569 17.2129 26.5569Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.03125 13.7703C0.197121 11.2498 0.995968 8.90209 2.27033 6.88477H27.2359C28.5102 8.90209 29.3091 11.2498 29.4749 13.7703H0.03125Z"
                    fill="#F85565" />
                    <path opacity="0.8"
                    d="M29.9993 7.86844C29.9993 9.76974 28.6782 10.8193 27.0485 10.8193C25.4188 10.8193 24.0977 9.76974 24.0977 7.86844C24.0977 6.60306 25.4047 4.40795 26.279 3.08112C26.6495 2.5189 27.4475 2.5189 27.818 3.08112C28.6923 4.40795 29.9993 6.60306 29.9993 7.86844Z"
                    fill="#48B8EE" />
                </svg>
                <span class="ml-2">Exhausted</span>`,
        value: "Exhausted"
    },
    {
        index: 7,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#4BC190" />
                    <path
                    d="M15.0007 14.6008C17.3018 14.6008 19.1673 12.7207 19.1673 10.4015C19.1673 8.08226 17.3018 6.20215 15.0007 6.20215C12.6995 6.20215 10.834 8.08226 10.834 10.4015C10.834 12.7207 12.6995 14.6008 15.0007 14.6008Z"
                    fill="white" />
                    <path
                    d="M15.0015 12.4305C16.1986 12.4305 17.169 11.4525 17.169 10.246C17.169 9.03956 16.1986 8.06152 15.0015 8.06152C13.8044 8.06152 12.834 9.03956 12.834 10.246C12.834 11.4525 13.8044 12.4305 15.0015 12.4305Z"
                    fill="#393C54" />
                    <path
                    d="M19.4378 11.1535C19.4787 10.9077 19.5 10.6551 19.5 10.3976C19.5 7.89282 17.4853 5.8623 15 5.8623C12.5147 5.8623 10.5 7.89282 10.5 10.3976C10.5 10.6551 10.5213 10.9077 10.5622 11.1535C10.5622 11.1535 12.075 9.58579 15 9.58579C17.925 9.58579 19.4378 11.1535 19.4378 11.1535Z"
                    fill="#45A89C" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 5.38672C14.7239 5.38672 14.5 5.16286 14.5 4.88672L14.5 2.88672C14.5 2.61058 14.7239 2.38672 15 2.38672C15.2761 2.38672 15.5 2.61058 15.5 2.88672L15.5 4.88672C15.5 5.16286 15.2761 5.38672 15 5.38672Z"
                    fill="#515570" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.8536 6.64547C11.6583 6.84073 11.3681 6.86711 11.2054 6.7044L10.0269 5.52588C9.86414 5.36317 9.89052 5.07297 10.0858 4.8777C10.281 4.68244 10.5712 4.65606 10.734 4.81878L11.9125 5.99729C12.0752 6.16001 12.0488 6.45021 11.8536 6.64547Z"
                    fill="#515570" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.1464 6.64547C18.3417 6.84073 18.6319 6.86711 18.7946 6.7044L19.9731 5.52588C20.1359 5.36317 20.1095 5.07297 19.9142 4.8777C19.719 4.68244 19.4288 4.65606 19.266 4.81878L18.0875 5.99729C17.9248 6.16001 17.9512 6.45021 18.1464 6.64547Z"
                    fill="#515570" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.4524 5.74407C16.2026 5.62653 16.0953 5.32868 16.2128 5.0788L17.0641 3.26902C17.1816 3.01914 17.4795 2.91186 17.7294 3.02939C17.9792 3.14693 18.0865 3.44478 17.969 3.69466L17.1177 5.50444C17.0002 5.75432 16.7023 5.86161 16.4524 5.74407Z"
                    fill="#515570" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.5476 5.74407C13.7974 5.62653 13.9047 5.32868 13.7872 5.0788L12.9359 3.26902C12.8184 3.01914 12.5205 2.91186 12.2706 3.02939C12.0208 3.14693 11.9135 3.44478 12.031 3.69466L12.8823 5.50444C12.9998 5.75432 13.2977 5.86161 13.5476 5.74407Z"
                    fill="#515570" />
                    <path
                    d="M19.5325 16.514C19.5325 16.514 20.3044 16.2641 21.2652 16.8189C22.2261 17.3736 22.2778 18.099 22.2778 18.099"
                    stroke="#45A89C" stroke-linecap="round" />
                    <path
                    d="M10.5339 16.514C10.5339 16.514 9.76203 16.2641 8.80118 16.8189C7.84032 17.3736 7.78863 18.099 7.78863 18.099"
                    stroke="#45A89C" stroke-linecap="round" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#46B098" />
                    <path
                    d="M9 19.5C9 18.6716 9.67157 18 10.5 18H19.5C20.3284 18 21 18.6716 21 19.5V24.5C21 26.1569 19.6569 27.5 18 27.5H12C10.3431 27.5 9 26.1569 9 24.5V19.5Z"
                    fill="#393C54" />
                    <path
                    d="M21 24.176C21 23.5265 18.3137 22.5 15 22.5C11.6863 22.5 9 23.5265 9 24.176L9 25C9 26.3807 10.1193 27.5 11.5 27.5L18.5 27.5C19.8807 27.5 21 26.3807 21 25L21 24.176Z"
                    fill="#F85565" />
                    <path
                    d="M14.5 24.6969C14.5 25.0176 14.7239 24.9998 15 24.9998C15.2761 24.9998 15.5 25.0176 15.5 24.6969L15.5 22.5L14.5 22.5L14.5 24.6969Z"
                    fill="#CC5567" />
                    <path
                    d="M10.5 18H19.5C19.5 18.8284 18.8284 19.5 18 19.5H12C11.1716 19.5 10.5 18.8284 10.5 18Z"
                    fill="white" />
                    <path
                    d="M19 27.5L11 27.5C11 26.6716 11.6716 26 12.5 26L17.5 26C18.3284 26 19 26.6716 19 27.5Z"
                    fill="white" />
                </svg>

                <span class="ml-2">Strange</span>`,
        value: "Strange"
    },
    {
        index: 8,
        html: `<svg width="36" height="32" viewBox="0 0 36 32" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M18.0039 31.8936C26.2882 31.8936 33.0039 25.1778 33.0039 16.8936C33.0039 8.60928 26.2882 1.89355 18.0039 1.89355C9.71964 1.89355 3.00391 8.60928 3.00391 16.8936C3.00391 25.1778 9.71964 31.8936 18.0039 31.8936Z"
                    fill="#F85565" />
                    <path
                    d="M10.7033 18.4409C12.6419 18.4409 14.2133 16.8694 14.2133 14.9309C14.2133 12.9924 12.6419 11.4209 10.7033 11.4209C8.76483 11.4209 7.19336 12.9924 7.19336 14.9309C7.19336 16.8694 8.76483 18.4409 10.7033 18.4409Z"
                    fill="white" />
                    <path
                    d="M11.2049 17.4382C12.3126 17.4382 13.2106 16.5402 13.2106 15.4325C13.2106 14.3247 12.3126 13.4268 11.2049 13.4268C10.0972 13.4268 9.19922 14.3247 9.19922 15.4325C9.19922 16.5402 10.0972 17.4382 11.2049 17.4382Z"
                    fill="#393C54" />
                    <path
                    d="M6.79098 13.9543L14.7726 17.2632C15.3483 14.4901 14.1386 11.8481 11.9346 11.2289C10.0058 10.687 7.92023 11.8642 6.79098 13.9543Z"
                    fill="#F85565" />
                    <line x1="6.57907" y1="13.3681" x2="13.8724" y2="16.5813" stroke="#4F3F56"
                    stroke-linecap="round" />
                    <line opacity="0.2" x1="7.70357" y1="14.6971" x2="12.7045" y2="16.9004"
                    stroke="#4F3F56" stroke-width="1.5" stroke-linecap="round" />
                    <path
                    d="M25.3924 18.4409C23.4538 18.4409 21.8824 16.8694 21.8824 14.9309C21.8824 12.9924 23.4538 11.4209 25.3924 11.4209C27.3309 11.4209 28.9023 12.9924 28.9023 14.9309C28.9023 16.8694 27.3309 18.4409 25.3924 18.4409Z"
                    fill="white" />
                    <path
                    d="M24.8908 17.4382C23.7831 17.4382 22.8851 16.5402 22.8851 15.4325C22.8851 14.3247 23.7831 13.4268 24.8908 13.4268C25.9985 13.4268 26.8965 14.3247 26.8965 15.4325C26.8965 16.5402 25.9985 17.4382 24.8908 17.4382Z"
                    fill="#393C54" />
                    <path
                    d="M29.3047 13.9543L21.3231 17.2632C20.7474 14.4901 21.9571 11.8481 24.1611 11.2289C26.0899 10.687 28.1755 11.8642 29.3047 13.9543Z"
                    fill="#F85565" />
                    <line x1="0.5" y1="-0.5" x2="8.4698" y2="-0.5"
                    transform="matrix(-0.915121 0.403179 0.403179 0.915121 30.1758 13.624)"
                    stroke="#4F3F56" stroke-linecap="round" />
                    <line opacity="0.2" x1="0.75" y1="-0.75" x2="6.21482" y2="-0.75"
                    transform="matrix(-0.915121 0.403179 0.403179 0.915121 29.3809 15.0811)"
                    stroke="#4F3F56" stroke-width="1.5" stroke-linecap="round" />
                    <path
                    d="M31.9665 11.4209C32.0166 11.8948 32.0467 12.3737 32.0467 12.8576C32.0467 21.124 24.6005 27.8238 15.4094 27.8238C11.4732 27.8238 7.85787 26.5916 5.00977 24.5362C7.61719 28.9862 12.461 31.9794 18.0118 31.9794C26.3204 31.9794 33.0546 25.2796 33.0546 17.0133C33.0546 15.0327 32.6635 13.147 31.9665 11.4209Z"
                    fill="#D25061" />
                    <path
                    d="M12.0293 22.9473C12.0293 21.8427 12.9247 20.9473 14.0293 20.9473H22.0635C23.1681 20.9473 24.0635 21.8427 24.0635 22.9473V25.9672C24.0635 27.0718 23.1681 27.9672 22.0635 27.9672H14.0293C12.9247 27.9672 12.0293 27.0718 12.0293 25.9672V22.9473Z"
                    fill="#393C54" />
                    <path
                    d="M22.0586 26.3663C22.0586 25.7963 20.2626 24.0181 18.0472 24.0181C15.8317 24.0181 14.0358 25.7963 14.0358 26.3663L14.0358 27.9668L22.0586 27.9668L22.0586 26.3663Z"
                    fill="#5F4158" />
                    <path
                    d="M12.0293 21.8248C12.0293 21.3401 12.4222 20.9473 12.9068 20.9473H23.186C23.6707 20.9473 24.0635 21.3401 24.0635 21.8248H12.0293Z"
                    fill="white" />
                    <path
                    d="M12.0293 21.8248C12.0293 21.3401 12.4222 20.9473 12.9068 20.9473H13.1575C13.6421 20.9473 14.035 21.3401 14.035 21.8248C14.035 22.3094 13.6421 22.7023 13.1575 22.7023H12.9068C12.4222 22.7023 12.0293 22.3094 12.0293 21.8248Z"
                    fill="white" />
                    <path
                    d="M12.0293 26.6509C12.0293 26.1663 12.4222 25.7734 12.9068 25.7734H13.1575C13.6421 25.7734 14.035 26.1663 14.035 26.6509C14.035 27.1356 13.6421 27.5284 13.1575 27.5284H12.9068C12.4222 27.5284 12.0293 27.1356 12.0293 26.6509Z"
                    fill="white" />
                    <path
                    d="M14.5371 21.8248C14.5371 21.3401 14.93 20.9473 15.4146 20.9473H15.6653C16.1499 20.9473 16.5428 21.3401 16.5428 21.8248C16.5428 22.3094 16.1499 22.7023 15.6653 22.7023H15.4146C14.93 22.7023 14.5371 22.3094 14.5371 21.8248Z"
                    fill="white" />
                    <path
                    d="M14.5371 26.6509C14.5371 26.1663 14.93 25.7734 15.4146 25.7734H15.6653C16.1499 25.7734 16.5428 26.1663 16.5428 26.6509C16.5428 27.1356 16.1499 27.5284 15.6653 27.5284H15.4146C14.93 27.5284 14.5371 27.1356 14.5371 26.6509Z"
                    fill="white" />
                    <path
                    d="M17.043 21.8248C17.043 21.3401 17.4358 20.9473 17.9205 20.9473H18.1712C18.6558 20.9473 19.0487 21.3401 19.0487 21.8248C19.0487 22.3094 18.6558 22.7023 18.1712 22.7023H17.9205C17.4358 22.7023 17.043 22.3094 17.043 21.8248Z"
                    fill="white" />
                    <path
                    d="M17.043 26.6509C17.043 26.1663 17.4358 25.7734 17.9205 25.7734H18.1712C18.6558 25.7734 19.0487 26.1663 19.0487 26.6509C19.0487 27.1356 18.6558 27.5284 18.1712 27.5284H17.9205C17.4358 27.5284 17.043 27.1356 17.043 26.6509Z"
                    fill="white" />
                    <path
                    d="M19.5508 21.8248C19.5508 21.3401 19.9436 20.9473 20.4283 20.9473H20.679C21.1636 20.9473 21.5565 21.3401 21.5565 21.8248C21.5565 22.3094 21.1636 22.7023 20.679 22.7023H20.4283C19.9437 22.7023 19.5508 22.3094 19.5508 21.8248Z"
                    fill="white" />
                    <path
                    d="M19.5508 26.6509C19.5508 26.1663 19.9436 25.7734 20.4283 25.7734H20.679C21.1636 25.7734 21.5565 26.1663 21.5565 26.6509C21.5565 27.1356 21.1636 27.5284 20.679 27.5284H20.4283C19.9437 27.5284 19.5508 27.1356 19.5508 26.6509Z"
                    fill="white" />
                    <path
                    d="M22.0586 21.8248C22.0586 21.3401 22.4515 20.9473 22.9361 20.9473H23.1868C23.6714 20.9473 24.0643 21.3401 24.0643 21.8248C24.0643 22.3094 23.6714 22.7023 23.1868 22.7023H22.9361C22.4515 22.7023 22.0586 22.3094 22.0586 21.8248Z"
                    fill="white" />
                    <path
                    d="M22.0586 26.6509C22.0586 26.1663 22.4515 25.7734 22.9361 25.7734H23.1868C23.6714 25.7734 24.0643 26.1663 24.0643 26.6509C24.0643 27.1356 23.6714 27.5284 23.1868 27.5284H22.9361C22.4515 27.5284 22.0586 27.1356 22.0586 26.6509Z"
                    fill="white" />
                    <path
                    d="M24.0645 26.6506C24.0645 27.3775 23.4751 27.9668 22.7482 27.9668L13.3465 27.9668C12.6195 27.9668 12.0302 27.3775 12.0302 26.6506L24.0645 26.6506Z"
                    fill="white" />
                    <path opacity="0.2"
                    d="M12.0293 21.9473C12.0293 21.395 12.477 20.9473 13.0293 20.9473H23.0635C23.6158 20.9473 24.0635 21.395 24.0635 21.9473V26.9672C24.0635 27.5195 23.6158 27.9672 23.0635 27.9672H13.0293C12.477 27.9672 12.0293 27.5195 12.0293 26.9672V21.9473Z"
                    stroke="black" />
                    <path
                    d="M32.1448 3.32808C32.7913 2.84458 33.7288 3.19297 33.6808 3.99887C33.5839 5.62695 33.0459 8.01713 31.0045 10.0232C29.2438 11.7535 23.8913 6.60887 26.2788 6.09521C28.7925 5.5544 30.8563 4.29162 32.1448 3.32808Z"
                    fill="#F85565" />
                    <path
                    d="M28.0742 8.66281C28.3249 9.16423 30.1319 11.1752 30.8321 10.1671C33.0885 7.91067 33.4679 6.0549 33.6551 4.47168C32.7534 6.03255 32.239 7.24365 29.8292 8.66281C29.5762 8.81181 28.3405 8.64853 28.0742 8.66281Z"
                    fill="#D25061" />
                    <path
                    d="M3.85523 3.32808C3.2087 2.84458 2.27121 3.19297 2.31919 3.99887C2.41613 5.62695 2.95409 8.01713 4.9955 10.0232C6.75624 11.7535 12.1087 6.60887 9.72121 6.09521C7.20752 5.5544 5.14369 4.29162 3.85523 3.32808Z"
                    fill="#F85565" />
                    <path
                    d="M7.92578 8.66281C7.67507 9.16423 5.86806 11.1752 5.16793 10.1671C2.91151 7.91067 2.53208 6.0549 2.34486 4.47168C3.24657 6.03255 3.76099 7.24365 6.17079 8.66281C6.42381 8.81181 7.65947 8.64853 7.92578 8.66281Z"
                    fill="#D25061" />
                </svg>

                <span class="ml-2">Angry</span>`,
        value: "Angry"
    },
    {
        index: 9,
        html: `<svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#A7AECE" />
                    <line x1="18.5616" y1="10.1706" x2="22.8338" y2="9.18216" stroke="#878DAA"
                    stroke-width="1.5" stroke-linecap="round" />
                    <line x1="0.75" y1="-0.75" x2="5.135" y2="-0.75"
                    transform="matrix(-0.974265 -0.225404 -0.225404 0.974265 12 11.0703)"
                    stroke="#878DAA" stroke-width="1.5" stroke-linecap="round" />
                    <path
                    d="M28.88 9.5C28.93 9.96105 28.96 10.427 28.96 10.8977C28.96 18.9395 21.535 25.4573 12.37 25.4573C8.445 25.4573 4.84 24.2586 2 22.259C4.6 26.5881 9.43 29.5 14.965 29.5C23.25 29.5 29.965 22.9822 29.965 14.9404C29.965 13.0137 29.575 11.1792 28.88 9.5Z"
                    fill="#959CBB" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M23.4564 13.2197C23.2133 14.5148 21.9822 15.5001 20.5 15.5001C19.0178 15.5001 17.7867 14.5148 17.5436 13.2197C17.5149 13.3897 17.5 13.5644 17.5 13.7428C17.5 15.4341 18.8431 16.8053 20.5 16.8053C22.1569 16.8053 23.5 15.4341 23.5 13.7428C23.5 13.5644 23.4851 13.3897 23.4564 13.2197Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.4564 13.2197C12.2133 14.5148 10.9822 15.5001 9.5 15.5001C8.01782 15.5001 6.78669 14.5148 6.54358 13.2197C6.51493 13.3897 6.5 13.5644 6.5 13.7428C6.5 15.4341 7.84315 16.8053 9.5 16.8053C11.1569 16.8053 12.5 15.4341 12.5 13.7428C12.5 13.5644 12.4851 13.3897 12.4564 13.2197Z"
                    fill="#393C54" />
                    <path
                    d="M21.46 26.2775C21.522 26.3768 22 26.8596 22 25.9864C22 21.9671 18.9779 19 15.25 19C11.5221 19 8.5 21.9671 8.5 25.9864C8.5 26.3258 8.59176 26.4673 8.72179 26.4687C8.97503 26.4713 9.10336 26.1658 9.25474 25.9628C10.5056 24.2852 12.6001 23.9487 15.25 23.9487C18.058 23.9487 20.2422 24.3265 21.46 26.2775Z"
                    fill="#393C54" />
                    <path
                    d="M10 25.5C10 24.3954 10.8954 23.5 12 23.5H18C19.1046 23.5 20 24.3954 20 25.5V28C20 28.5523 19.5523 29 19 29H17.6667C17.1144 29 16.6667 28.5523 16.6667 28V27.5823C16.6667 27.2085 16.5229 26.849 16.2651 26.5783C15.5766 25.8554 14.4234 25.8554 13.7349 26.5783C13.4771 26.849 13.3333 27.2085 13.3333 27.5823V29.5C13.3333 30.0523 12.8856 30.5 12.3333 30.5H11C10.4477 30.5 10 30.0523 10 29.5V25.5Z"
                    fill="#4BC190" />
                    <circle cx="12.25" cy="25.25" r="1.25" fill="#47A784" />
                    <ellipse cx="18" cy="25.75" rx="1.5" ry="1.75" fill="#47A784" />
                    <ellipse cx="11.75" cy="28.75" rx="0.75" ry="1.25" fill="#47A784" />
                </svg>

                <span class="ml-2">Sick</span>`,
        value: "Sick"
    },
    {
        index: 10,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M21.62 1C21.9569 1 22.23 1.27311 22.23 1.61C22.23 1.69667 22.215 1.78167 22.185 1.865C22.1583 1.94833 22.12 2.02667 22.07 2.1L18.65 5.985H21.47C21.829 5.985 22.12 6.27601 22.12 6.635C22.12 6.99398 21.829 7.285 21.47 7.285H17.15C16.791 7.285 16.5 6.99399 16.5 6.635C16.5 6.55833 16.5133 6.48333 16.54 6.41C16.5667 6.33333 16.6033 6.26333 16.65 6.2L20.08 2.3H17.43C17.071 2.3 16.78 2.00898 16.78 1.65C16.78 1.29101 17.071 1 17.43 1H21.62Z"
                    fill="#00ADFE" />
                    <path
                    d="M26.1118 5C26.3262 5 26.5 5.17381 26.5 5.38823C26.5 5.44338 26.4908 5.49748 26.4725 5.55052C26.4562 5.60355 26.4328 5.65341 26.4023 5.70008L24.3133 8.17263H26.0191C26.2476 8.17263 26.4328 8.35785 26.4328 8.58632C26.4328 8.81479 26.2476 9 26.0191 9H23.4137C23.1852 9 23 8.81479 23 8.58632C23 8.53752 23.0081 8.48979 23.0244 8.44312C23.0407 8.39433 23.0631 8.34977 23.0916 8.30947L25.1867 5.82737H23.5847C23.3562 5.82737 23.171 5.64215 23.171 5.41368C23.171 5.18521 23.3562 5 23.5847 5H26.1118Z"
                    fill="#00ADFE" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M23.5847 5.125C23.4253 5.125 23.296 5.25425 23.296 5.41368C23.296 5.57312 23.4253 5.70237 23.5847 5.70237H25.4558L23.1908 8.38571C23.171 8.41457 23.155 8.44673 23.143 8.4827L23.1425 8.48431C23.1307 8.51792 23.125 8.55173 23.125 8.58632C23.125 8.74575 23.2542 8.875 23.4137 8.875H26.0191C26.1786 8.875 26.3078 8.74575 26.3078 8.58632C26.3078 8.42688 26.1786 8.29763 26.0191 8.29763H24.044L26.3016 5.62558C26.3238 5.59046 26.3409 5.55329 26.353 5.51382L26.3543 5.50967C26.3682 5.46954 26.375 5.42925 26.375 5.38823C26.375 5.24285 26.2571 5.125 26.1118 5.125H23.5847ZM23.046 5.41368C23.046 5.11618 23.2872 4.875 23.5847 4.875H26.1118C26.3952 4.875 26.625 5.10478 26.625 5.38823C26.625 5.4568 26.6137 5.52399 26.5914 5.58928C26.5716 5.65283 26.5434 5.71268 26.5069 5.76852L26.5027 5.77492L24.5825 8.04763H26.0191C26.3166 8.04763 26.5578 8.28881 26.5578 8.58632C26.5578 8.88382 26.3166 9.125 26.0191 9.125H23.4137C23.1162 9.125 22.875 8.88382 22.875 8.58632C22.875 8.5236 22.8855 8.46222 22.9061 8.40274C22.9261 8.34313 22.9539 8.28779 22.9896 8.23729L22.9927 8.23293L24.9176 5.95237H23.5847C23.2872 5.95237 23.046 5.71119 23.046 5.41368Z"
                    fill="#00ADFE" />
                    <path d="M19 15.5C19 15.5 19.4911 17 21.8482 17C24.2054 17 24.5 15.5 24.5 15.5"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M5.5 15.5C5.5 15.5 5.99107 17 8.34821 17C10.7054 17 11 15.5 11 15.5"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <ellipse cx="20.25" cy="23.5" rx="1.75" ry="2" fill="#393C54" />
                    <rect x="19" y="12.1406" width="5.08237" height="1" rx="0.5" fill="#F2BC0F" />
                    <rect x="6" y="12.1406" width="5.08237" height="1" rx="0.5" fill="#F2BC0F" />
                </svg>
                <span class="ml-2">Sleepy</span>`,
        value: "Sleepy"
    },
    {
        index: 11,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#FC7C5F" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#D25061" />
                    <path
                    d="M23 20C23 15.5817 19.4183 12 15 12C10.5817 12 7 15.5817 7 20C7 20 11.25 18.25 15 18.25C18.75 18.25 23 20 23 20Z"
                    fill="#393C54" />
                    <path
                    d="M20 19C20 19 16.9745 18.25 15 18.25C13.0255 18.25 10 19 10 19L10 18C10 16.8954 10.8954 16 12 16L18 16C19.1046 16 20 16.8954 20 18L20 19Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.1134 13.6654C10.3876 14.4429 11.1288 15 12.0001 15L18.0001 15C18.8714 15 19.6125 14.4429 19.8867 13.6654C18.5351 12.6212 16.8402 12 15.0001 12C13.16 12 11.465 12.6212 10.1134 13.6654Z"
                    fill="white" />
                    <line x1="13.5" y1="20" x2="16.5" y2="20" stroke="#D25061" stroke-linecap="round" />
                    <path
                    d="M19.1283 9.29763C19.1283 9.29763 19.2975 8.25797 20.6616 7.59526C22.0256 6.93255 23.0256 7.40418 23.0256 7.40418"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M19.1309 9.33779C19.1309 9.33779 20.1445 8.70387 21.7167 9.15224C23.2889 9.6006 23.6229 10.6188 23.6229 10.6188"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M10.8717 9.29763C10.8717 9.29763 10.7025 8.25797 9.33844 7.59526C7.97437 6.93255 6.97439 7.40418 6.97439 7.40418"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M10.8691 9.33779C10.8691 9.33779 9.85549 8.70387 8.28327 9.15224C6.71105 9.6006 6.37707 10.6188 6.37707 10.6188"
                    stroke="#393C54" stroke-linecap="round" />
                </svg>
                <span class="ml-2">Irritated</span>`,
        value: "Irritated"
    },
    {
        index: 12,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M21.469 19.3945C21.469 19.3945 20.0197 19.4689 18.6164 18.0657C17.2132 16.6624 17.4597 15.3852 17.4597 15.3852"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M8.36305 19.3945C8.36305 19.3945 9.81233 19.4689 11.2156 18.0657C12.6188 16.6624 12.3723 15.3852 12.3723 15.3852"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M21 17.5C22.933 17.5 24.5 15.933 24.5 14C24.5 12.067 22.933 10.5 21 10.5C19.067 10.5 17.5 12.067 17.5 14C17.5 15.933 19.067 17.5 21 17.5Z"
                    fill="white" />
                    <path
                    d="M20 15.75C20.9665 15.75 21.75 14.9665 21.75 14C21.75 13.0335 20.9665 12.25 20 12.25C19.0335 12.25 18.25 13.0335 18.25 14C18.25 14.9665 19.0335 15.75 20 15.75Z"
                    fill="#383B53" />
                    <path
                    d="M21 10.5C19.067 10.5 17.5 12.067 17.5 14H24.5C24.5 12.067 22.933 10.5 21 10.5Z"
                    fill="#F8DC24" />
                    <path
                    d="M9 17.5C10.933 17.5 12.5 15.933 12.5 14C12.5 12.067 10.933 10.5 9 10.5C7.06701 10.5 5.5 12.067 5.5 14C5.5 15.933 7.06701 17.5 9 17.5Z"
                    fill="white" />
                    <path
                    d="M8 15.75C8.9665 15.75 9.75 14.9665 9.75 14C9.75 13.0335 8.9665 12.25 8 12.25C7.0335 12.25 6.25 13.0335 6.25 14C6.25 14.9665 7.0335 15.75 8 15.75Z"
                    fill="#383B53" />
                    <path
                    d="M9 10.5C7.06701 10.5 5.5 12.067 5.5 14H12.5C12.5 12.067 10.933 10.5 9 10.5Z"
                    fill="#F8DC24" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path
                    d="M19.5 25.7061C19.5 23.367 17.4853 21.4708 15 21.4708C12.5147 21.4708 10.5 23.367 10.5 25.7061C10.5 25.7061 12.8906 25.7061 15 25.7061C17.1094 25.7061 19.5 25.7061 19.5 25.7061Z"
                    fill="#393C54" />
                    <path
                    d="M17.9121 25.7061C17.9121 24.2441 16.6085 23.059 15.0003 23.059C13.3922 23.059 12.0886 24.2441 12.0886 25.7061C12.0886 25.7061 13.6355 25.7061 15.0003 25.7061C16.3652 25.7061 17.9121 25.7061 17.9121 25.7061Z"
                    fill="#F85565" />
                    <rect x="12.5" y="26.5" width="5" height="1" rx="0.5" fill="#E4B211" />
                    <rect x="4.5" y="13" width="9" height="1" rx="0.5" fill="#E4B211" />
                    <rect x="16.5" y="13" width="9" height="1" rx="0.5" fill="#E4B211" />
                </svg>

                <span class="ml-2">Bored</span>`,
        value: "Bored"
    },
    {
        index: 13,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path
                    d="M19.5 16.7061C19.5 14.367 17.4853 12.4708 15 12.4708C12.5147 12.4708 10.5 14.367 10.5 16.7061C10.5 16.7061 12.8906 16.7061 15 16.7061C17.1094 16.7061 19.5 16.7061 19.5 16.7061Z"
                    fill="#393C54" />
                    <path
                    d="M17.9121 16.7061C17.9121 15.2441 16.6085 14.059 15.0003 14.059C13.3922 14.059 12.0886 15.2441 12.0886 16.7061C12.0886 16.7061 13.6355 16.7061 15.0003 16.7061C16.3652 16.7061 17.9121 16.7061 17.9121 16.7061Z"
                    fill="#F85565" />
                    <path
                    d="M18.7745 8.60128C18.7745 8.60128 19.4368 10.6013 21.1866 10.6013C22.9365 10.6013 25.937 10.6014 25.937 10.6014"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M11.2255 8.60128C11.2255 8.60128 10.5632 10.6013 8.81337 10.6013C7.06352 10.6013 4.06296 10.6014 4.06296 10.6014"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M9 28.7519V11H4.5V25.7122C5.7937 26.9805 7.31628 28.0162 9 28.7519Z"
                    fill="#00ADFE" />
                    <path d="M21 28.7519V11H25.5V25.7122C24.2063 26.9805 22.6837 28.0162 21 28.7519Z"
                    fill="#00ADFE" />
                    <rect x="4" y="11" width="5.5" height="1" fill="#00ADFE" />
                    <rect x="12.5" y="17.5" width="5" height="1" rx="0.5" fill="#F2BC0F" />
                    <rect x="20.5" y="11" width="5.5" height="1" fill="#00ADFE" />
                </svg>
                <span class="ml-2">Devastated</span>`,
        value: "Devastated"
    },
    {
        index: 14,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC25" />
                    <path
                    d="M23.4765 17.9566C23.4765 17.9566 22.2586 18.7457 20.3417 18.2321C18.4248 17.7184 17.9997 16.4891 17.9997 16.4891"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M6.35556 17.9566C6.35556 17.9566 7.57347 18.7457 9.49035 18.2321C11.4072 17.7184 11.8324 16.4891 11.8324 16.4891"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M21.055 8.52536C21.055 8.52536 22.0675 7.98306 23.4235 8.54281C24.7795 9.10257 24.9293 10.1247 24.9293 10.1247"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M7.99154 6.7981C7.99154 6.7981 6.80307 6.66831 5.58251 7.75602C4.36194 8.84374 4.50422 9.90585 4.50422 9.90585"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M21 17C22.933 17 24.5 15.433 24.5 13.5C24.5 11.567 22.933 10 21 10C19.067 10 17.5 11.567 17.5 13.5C17.5 15.433 19.067 17 21 17Z"
                    fill="white" />
                    <path
                    d="M21 15.25C21.9665 15.25 22.75 14.4665 22.75 13.5C22.75 12.5335 21.9665 11.75 21 11.75C20.0335 11.75 19.25 12.5335 19.25 13.5C19.25 14.4665 20.0335 15.25 21 15.25Z"
                    fill="#383B53" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M24.3547 14.5C23.9244 15.9457 22.5851 17 20.9996 17C19.4141 17 18.0748 15.9457 17.6445 14.5H24.3547ZM24.3547 12.5C23.9244 11.0543 22.5851 10 20.9996 10C19.4141 10 18.0748 11.0543 17.6445 12.5H24.3547Z"
                    fill="#F4C515" />
                    <path
                    d="M9 17C10.933 17 12.5 15.433 12.5 13.5C12.5 11.567 10.933 10 9 10C7.06701 10 5.5 11.567 5.5 13.5C5.5 15.433 7.06701 17 9 17Z"
                    fill="white" />
                    <path
                    d="M9 15.25C9.9665 15.25 10.75 14.4665 10.75 13.5C10.75 12.5335 9.9665 11.75 9 11.75C8.0335 11.75 7.25 12.5335 7.25 13.5C7.25 14.4665 8.0335 15.25 9 15.25Z"
                    fill="#383B53" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path
                    d="M9 23.265C9 22.0141 9.89543 21 11 21H18.5C19.6046 21 20.5 22.0141 20.5 23.265C20.5 24.5159 19.6046 25.53 18.5 25.53H11C9.89543 25.53 9 24.5159 9 23.265Z"
                    fill="#393C54" />
                    <path
                    d="M11 20.9C9.82887 20.9 8.9 21.9709 8.9 23.265C8.9 24.5591 9.82887 25.63 11 25.63H18.5C19.6711 25.63 20.6 24.5591 20.6 23.265C20.6 21.9709 19.6711 20.9 18.5 20.9H11Z"
                    stroke="black" stroke-opacity="0.3" stroke-width="0.2" />
                    <path
                    d="M11 21H14.75H18.5C18.5 21.5523 18.0523 22 17.5 22H14.75H12C11.4477 22 11 21.5523 11 21Z"
                    fill="white" />
                    <path
                    d="M15.9998 23C16.9791 23 20.4996 23 20.4996 23C20.4996 23 20.5743 25.5 18.7497 25.5C16.9251 25.5 14.9082 25.5 12.5 25.5C12.5 23.8653 14.0669 23 15.9998 23Z"
                    fill="#F85565" />
                </svg>
                <span class="ml-2">Hung over</span>`,
        value: "Hung over"
    },
    {
        index: 15,
        html: `<svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path d="M19.5 6L22.3482 7.29464L25 8.5" stroke="#C4B135" stroke-linecap="round" />
                    <path d="M5.5 10.498L7.83074 9.46309L10.0007 8.49951" stroke="#C4B135"
                    stroke-linecap="round" />
                    <path
                    d="M8.53145 17.2505C8.53145 17.2505 9.78145 17.2505 10.7815 16.7505C11.7815 16.2505 12.2815 15.5005 12.2815 15.5005"
                    stroke="#F5CC1A" stroke-linecap="round" />
                    <path
                    d="M17.2072 16.0692C17.2072 16.0692 17.9981 17.0372 19.0179 17.4953C20.0378 17.9534 20.935 17.8661 20.935 17.8661"
                    stroke="#F5CC1A" stroke-linecap="round" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F1C631" />
                    <path
                    d="M21.5 27.728C21.5 26.7737 18.0376 26 15 26C11.9624 26 8.5 26.7737 8.5 27.728L8.54667 27.8574C9.11873 29.4431 10.6236 30.5 12.3093 30.5L17.6907 30.5C19.3764 30.5 20.8813 29.4431 21.4533 27.8574L21.5 27.728Z"
                    fill="#F1C631" />
                    <path
                    d="M19.5 22.2061C19.5 19.867 17.4853 17.9708 15 17.9708C12.5147 17.9708 10.5 19.867 10.5 22.2061L10.5 27C10.5 28.1046 11.3954 29 12.5 29L17.5 29C18.6046 29 19.5 28.1046 19.5 27L19.5 22.2061Z"
                    fill="#393C54" />
                    <path
                    d="M19 29C19 26.2386 17.2091 24 15 24C12.7909 24 11 26.2386 11 29C11 29 13.125 29 15 29C16.875 29 19 29 19 29Z"
                    fill="#5F4158" />
                    <path
                    d="M19 29C19 27.6193 17.2091 26.5 15 26.5C12.7909 26.5 11 27.6193 11 29C11 29 13.125 29 15 29C16.875 29 19 29 19 29Z"
                    fill="#F85565" />
                    <path
                    d="M21 16.5C22.933 16.5 24.5 14.933 24.5 13C24.5 11.067 22.933 9.5 21 9.5C19.067 9.5 17.5 11.067 17.5 13C17.5 14.933 19.067 16.5 21 16.5Z"
                    fill="white" />
                    <path
                    d="M20.9405 14.8147C21.9098 14.8147 22.6955 14.0289 22.6955 13.0597C22.6955 12.0904 21.9098 11.3047 20.9405 11.3047C19.9713 11.3047 19.1855 12.0904 19.1855 13.0597C19.1855 14.0289 19.9713 14.8147 20.9405 14.8147Z"
                    fill="#383B53" />
                    <path
                    d="M9 15.5C10.3807 15.5 11.5 14.3807 11.5 13C11.5 11.6193 10.3807 10.5 9 10.5C7.61929 10.5 6.5 11.6193 6.5 13C6.5 14.3807 7.61929 15.5 9 15.5Z"
                    fill="white" />
                    <path
                    d="M9 14.5C9.82843 14.5 10.5 13.8284 10.5 13C10.5 12.1716 9.82843 11.5 9 11.5C8.17157 11.5 7.5 12.1716 7.5 13C7.5 13.8284 8.17157 14.5 9 14.5Z"
                    fill="#383B53" />
                </svg>
                <span class="ml-2">Shocked</span>`,
        value: "Shocked"
    },
    {
        index: 16,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path d="M18 15.5C18 15.5 18.4911 13 20.8482 13C23.2054 13 23.5 15.5 23.5 15.5"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M6.5 15.5C6.5 15.5 6.99107 13 9.34821 13C11.7054 13 12 15.5 12 15.5"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <rect x="21" y="17" width="5.5" height="2.5" rx="1.25" fill="#F87D52" />
                    <rect x="3.5" y="17" width="5.5" height="2.5" rx="1.25" fill="#F87D52" />
                    <rect x="18.5" y="9" width="5.08237" height="1" rx="0.5"
                    transform="rotate(15 18.5 9)" fill="#F2BC0F" />
                    <path d="M19.5 21C19.5 21 18.1154 22.5 14.8393 22.5C11.5632 22.5 10.5 21 10.5 21"
                    stroke="#393C54" stroke-linecap="round" />
                    <rect width="3.17925" height="0.906664" rx="0.453332"
                    transform="matrix(0.707107 0.707107 -0.537258 0.843418 19.3418 20.0527)"
                    fill="#393C54" />
                    <rect width="3.17925" height="0.906664" rx="0.453332"
                    transform="matrix(-0.707107 0.707107 -0.537258 -0.843418 11.1777 20.8516)"
                    fill="#393C54" />
                    <rect x="7" y="10.3154" width="5.08237" height="1" rx="0.5"
                    transform="rotate(-15 7 10.3154)" fill="#F2BC0F" />
                </svg>

                <span class="ml-2">Blushing</span>`,
        value: "Blushing"
    },
    {
        index: 17,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC25" />
                    <path
                    d="M21.1217 10.7848C21.1217 10.7848 22.2785 10.3921 23.8034 11.1978C25.3283 12.0034 25.4785 13.0866 25.4785 13.0866"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M21 20C22.933 20 24.5 18.433 24.5 16.5C24.5 14.567 22.933 13 21 13C19.067 13 17.5 14.567 17.5 16.5C17.5 18.433 19.067 20 21 20Z"
                    fill="white" />
                    <path
                    d="M21 18.25C21.9665 18.25 22.75 17.4665 22.75 16.5C22.75 15.5335 21.9665 14.75 21 14.75C20.0335 14.75 19.25 15.5335 19.25 16.5C19.25 17.4665 20.0335 18.25 21 18.25Z"
                    fill="#383B53" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.5352 17.4998C17.7778 19.1959 19.2365 20.4998 20.9997 20.4998C22.763 20.4998 24.2217 19.1959 24.4643 17.4998H17.5352ZM24.4995 17.0416C24.4996 17.0276 24.4997 17.0137 24.4997 16.9998C24.4997 16.9858 24.4996 16.9719 24.4995 16.958V17.0416Z"
                    fill="#F8DC25" />
                    <path
                    d="M8.85874 10.7848C8.85874 10.7848 7.70195 10.3921 6.17708 11.1978C4.65221 12.0034 4.50196 13.0866 4.50196 13.0866"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M8.98047 20C7.04747 20 5.48047 18.433 5.48047 16.5C5.48047 14.567 7.04747 13 8.98047 13C10.9135 13 12.4805 14.567 12.4805 16.5C12.4805 18.433 10.9135 20 8.98047 20Z"
                    fill="white" />
                    <path
                    d="M8.98047 18.25C8.01397 18.25 7.23047 17.4665 7.23047 16.5C7.23047 15.5335 8.01397 14.75 8.98047 14.75C9.94697 14.75 10.7305 15.5335 10.7305 16.5C10.7305 17.4665 9.94697 18.25 8.98047 18.25Z"
                    fill="#383B53" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.4453 17.4998C12.2027 19.1959 10.744 20.4998 8.98075 20.4998C7.21752 20.4998 5.75882 19.1959 5.5162 17.4998H12.4453ZM5.481 17.0416C5.48084 17.0276 5.48076 17.0137 5.48076 16.9998C5.48076 16.9858 5.48084 16.9719 5.481 16.958V17.0416Z"
                    fill="#F8DC25" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path
                    d="M10 21.7727C10 21.0698 10.5698 20.5 11.2727 20.5H18.7273C19.4302 20.5 20 21.0698 20 21.7727V22.5C20 25.2614 17.7614 27.5 15 27.5C12.2386 27.5 10 25.2614 10 22.5V21.7727Z"
                    fill="#393C54" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.4173 26.1498C17.5233 26.9872 16.3215 27.5 14.9999 27.5C13.6781 27.5 12.4761 26.9871 11.582 26.1494C11.9285 24.9198 13.3271 24 14.9996 24C16.6724 24 18.071 24.92 18.4173 26.1498Z"
                    fill="#F85565" />
                    <path
                    d="M11 20.5H19C19 21.2249 18.4124 21.8125 17.6875 21.8125H12.3125C11.5876 21.8125 11 21.2249 11 20.5Z"
                    fill="white" />
                    <rect x="19" y="18.5" width="5" height="0.5" rx="0.25" fill="url(#paint0_linear)"
                    fill-opacity="0.4" />
                    <rect x="6.5" y="18.5" width="5" height="0.5" rx="0.25" fill="url(#paint1_linear)"
                    fill-opacity="0.4" />
                    <ellipse cx="15" cy="4" rx="13" ry="3" stroke="#00ADFE" stroke-width="1.5" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M25.199 4C22.5227 1.51765 18.939 0 15.0009 0C11.0627 0 7.47901 1.51765 4.80273 4H25.199Z"
                    fill="#F8DC25" />
                    <defs>
                    <linearGradient id="paint0_linear" x1="19" y1="18.8047" x2="23.8701" y2="18.8047"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F2BC0F" stop-opacity="0" />
                        <stop offset="0.28125" stop-color="white" />
                        <stop offset="0.6875" stop-color="white" />
                        <stop offset="1" stop-color="#F2BC0F" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="6.5" y1="18.8047" x2="11.3701"
                        y2="18.8047" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F2BC0F" stop-opacity="0" />
                        <stop offset="0.28125" stop-color="white" />
                        <stop offset="0.6875" stop-color="white" />
                        <stop offset="1" stop-color="#F2BC0F" stop-opacity="0" />
                    </linearGradient>
                    </defs>
                </svg>
                <span class="ml-2">Innocent</span>`,
        value: "Innocent"
    },
    {
        index: 18,
        html: `<svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30.0117C23.2843 30.0117 30 23.296 30 15.0117C30 6.72745 23.2843 0.0117188 15 0.0117188C6.71573 0.0117188 0 6.72745 0 15.0117C0 23.296 6.71573 30.0117 15 30.0117Z"
                    fill="#F8DC24" />
                    <path d="M6.5008 2.4053C11.5475 3.68333 13.3151 6.51169 13.3151 6.51169"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M16.8557 6.60537C16.2124 4.52844 17.2236 2.28807 17.2236 2.28807"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M16.8911 6.62382C18.4986 2.26124 21.2488 1.26147 21.2488 1.26147"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M10 13.5117C11.933 13.5117 13.5 11.9447 13.5 10.0117C13.5 8.07872 11.933 6.51172 10 6.51172C8.06701 6.51172 6.5 8.07872 6.5 10.0117C6.5 11.9447 8.06701 13.5117 10 13.5117Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.54712 10.5876C6.75678 10.6991 6.99602 10.7622 7.25 10.7622C8.07843 10.7622 8.75 10.0906 8.75 9.26218C8.75 8.45637 8.11459 7.79897 7.31749 7.76367C6.80725 8.37181 6.5 9.15599 6.5 10.0119C6.5 10.208 6.51613 10.4003 6.54712 10.5876Z"
                    fill="#383B53" />
                    <path
                    d="M21.5 12.0117C19.567 12.0117 18 10.4447 18 8.51172C18 6.57872 19.567 5.01172 21.5 5.01172C23.433 5.01172 25 6.57872 25 8.51172C25 10.4447 23.433 12.0117 21.5 12.0117Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M21.7498 6.26223C21.7498 7.09065 22.4214 7.76223 23.2498 7.76223C23.8498 7.76223 24.3675 7.40994 24.6074 6.90093C24.1428 6.00651 23.3033 5.33802 22.2972 5.10352C21.9629 5.37863 21.7498 5.79554 21.7498 6.26223Z"
                    fill="#383B53" />
                    <path
                    d="M28.88 9.51172C28.93 9.9843 28.96 10.4619 28.96 10.9444C28.96 19.1872 21.535 25.8679 12.37 25.8679C8.445 25.8679 4.84 24.6392 2 22.5897C4.6 27.027 9.43 30.0117 14.965 30.0117C23.25 30.0117 29.965 23.3309 29.965 15.0882C29.965 13.1133 29.575 11.2329 28.88 9.51172Z"
                    fill="#F1C631" />
                    <path
                    d="M26.4431 20.0001C26.8873 18.4881 26.4431 18.1085 24.7489 17.0121C21.7489 15.0705 19.0946 12.3489 15.749 13.5119C12.999 15.0121 13.7488 20.4999 9.49885 22.7619C9.48573 23.6701 10.7489 25.0119 11.3303 25.2538C13.3327 26.0868 16.8661 24.6837 20.499 22.262C21.999 21.2621 25.9989 21.5121 26.4431 20.0001Z"
                    fill="#393C54" />
                    <path
                    d="M22.7781 19.8728C22.5174 18.3799 20.4835 17.4879 18.2351 17.8805C15.9868 18.273 14.3754 19.8015 14.6361 21.2944L14.4166 22.901L21.4493 21.7442C22.3355 21.5984 22.9326 20.7576 22.7781 19.8728Z"
                    fill="#F85565" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M25.2253 20.9728L25.2183 20.954C24.9051 20.1095 24.0672 19.5785 23.1742 19.6959C21.7644 19.8812 19.6721 20.2242 18.2489 20.761C16.9311 21.258 15.3672 22.2411 14.3125 22.959C13.5844 23.4546 13.3142 24.384 13.6204 25.2099L13.6919 25.4025C15.6175 25.0768 18.0354 23.903 20.4989 22.2608C21.1205 21.8464 22.1714 21.6467 23.2206 21.4473C23.9349 21.3115 24.6484 21.1759 25.2253 20.9728Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M20.9344 14.4116C19.6963 13.6701 18.4386 13.158 17.0805 13.2401L17.3114 14.0882C17.6057 15.1696 18.7298 15.7705 19.6977 15.2055C20.1703 14.9296 20.6034 14.6423 20.9344 14.4116ZM13.7637 16.2947C14.2382 15.0792 14.7734 14.0436 15.75 13.5108C16.0306 13.4133 16.3064 13.3431 16.5779 13.2974L16.7929 14.0872C17.0992 15.2124 16.3735 16.3384 15.2074 16.3398C14.6615 16.3405 14.153 16.3186 13.7637 16.2947Z"
                    fill="white" />
                    <path
                    d="M24.4773 17.4318C24.9024 17.707 25.2299 17.925 25.4829 18.1228C25.736 18.3207 25.8861 18.477 25.9758 18.6183C26.1255 18.854 26.1725 19.1473 25.9634 19.8592C25.9148 20.0244 25.7499 20.2094 25.3349 20.3938C24.9287 20.5744 24.3879 20.7075 23.7679 20.833C23.5678 20.8735 23.3576 20.9134 23.1439 20.9541C22.7203 21.0346 22.2828 21.1178 21.8826 21.2129C21.2812 21.3557 20.6754 21.5435 20.2217 21.846C18.4264 23.0427 16.6738 23.9751 15.1397 24.5059C13.5845 25.044 12.3492 25.1361 11.5223 24.7921C11.4505 24.7622 11.3103 24.674 11.1252 24.5129C10.9482 24.3589 10.7565 24.1614 10.5807 23.9437C10.4043 23.7251 10.2522 23.4963 10.147 23.281C10.1016 23.188 10.0678 23.1034 10.0441 23.028C12.1201 21.7851 12.995 19.794 13.6549 18.0261C13.7343 17.8131 13.8102 17.605 13.8841 17.402C14.1394 16.7012 14.3723 16.0617 14.6566 15.4968C15.0101 14.7942 15.4105 14.2788 15.9532 13.9705C17.4286 13.4718 18.7659 13.8036 20.1431 14.5389C20.8423 14.9122 21.5386 15.3827 22.2593 15.8911C22.429 16.0108 22.6004 16.1328 22.7734 16.256C23.3272 16.6505 23.8972 17.0564 24.4773 17.4318Z"
                    stroke="black" stroke-opacity="0.2" />
                </svg>
                <span class="ml-2">Confused</span>`,
        value: "Confused"
    },
    {
        index: 19,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#00ADFE" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#1593E1" />
                    <path
                    d="M19.5 25.2061C19.5 22.867 17.4853 20.9708 15 20.9708C12.5147 20.9708 10.5 22.867 10.5 25.2061C10.5 25.2061 12.8906 24.75 15 24.75C17.1094 24.75 19.5 25.2061 19.5 25.2061Z"
                    fill="#393C54" />
                    <path
                    d="M12 27.312C12 28.7965 13.3431 30 15 30C16.6569 30 18 28.7965 18 27.312L18 26C18 24.8954 17.1046 24 16 24L14 24C12.8954 24 12 24.8954 12 26L12 27.312Z"
                    fill="#F85565" />
                    <rect x="17.5" y="12" width="7" height="1" rx="0.5" fill="#1593E1" />
                    <rect x="5.5" y="12" width="7" height="1" rx="0.5" fill="#1593E1" />
                    <rect x="6.5" y="18.5352" width="5.70583" height="0.950972" rx="0.475486"
                    transform="rotate(-45 6.5 18.5352)" fill="#393C54" />
                    <rect x="10.5352" y="19.207" width="5.70583" height="0.950972" rx="0.475486"
                    transform="rotate(-135 10.5352 19.207)" fill="#393C54" />
                    <rect x="19" y="18.5352" width="5.70583" height="0.950972" rx="0.475486"
                    transform="rotate(-45 19 18.5352)" fill="#393C54" />
                    <rect x="23.0352" y="19.207" width="5.70583" height="0.950972" rx="0.475486"
                    transform="rotate(-135 23.0352 19.207)" fill="#393C54" />
                </svg>
                <span class="ml-2">Dead</span>`,
        value: "Dead"
    },
    {
        index: 20,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F1C631" />
                    <path
                    d="M10.6161 20.418C9.32246 19.9892 7.94642 20.9445 8.44775 22.2119C9.77673 25.5714 13.7425 27.1598 15.9369 26.9769C17.6472 26.8344 19.8058 25.9746 21.1719 24.0593C22.2066 22.6086 20.1048 21.4894 18.3645 21.8716C18.016 21.9482 17.6582 21.9923 17.2964 21.9923C15.6957 21.9923 12.7601 21.1285 10.6161 20.418Z"
                    fill="#393C54" />
                    <path
                    d="M10.7266 25.1641C11.7166 24.1036 14.636 23.8047 16.3909 23.8047C18.1458 23.8047 19.0261 24.2621 20.0161 25.3226C18.9019 26.2063 17.1574 26.9767 15.4846 26.9767C13.8118 26.9767 11.8407 26.0479 10.7266 25.1641Z"
                    fill="#F85565" />
                    <path opacity="0.5"
                    d="M17.4962 26.4854L17.4607 27.2543C17.4352 27.806 17.8618 28.2739 18.4134 28.2994C18.9651 28.3249 19.4331 27.8984 19.4586 27.3467L19.4816 26.8472C20.0333 26.8727 20.5012 26.4461 20.5267 25.8944L20.5788 24.7674C19.6609 25.6444 18.5609 26.1937 17.4962 26.4854Z"
                    fill="white" />
                    <path opacity="0.5"
                    d="M9.13006 23.3945C9.67489 24.0994 10.3403 24.6914 11.0503 25.167L10.9736 26.8267C10.9481 27.3784 10.4802 27.8049 9.92846 27.7794C9.37676 27.7539 8.9502 27.286 8.9757 26.7343L9.13006 23.3945Z"
                    fill="white" />
                    <path opacity="0.5"
                    d="M10.6392 25C11.2423 25.524 11.9093 25.9518 12.5822 26.2807L12.499 28.0811C12.4735 28.6328 12.0055 29.0594 11.4538 29.0339C10.9022 29.0084 10.4756 28.5404 10.5011 27.9887L10.6392 25Z"
                    fill="white" />
                    <ellipse cx="22" cy="16.25" rx="3" ry="1.25" fill="#F8C232" />
                    <path
                    d="M25.4068 10.1189C25.2107 9.92266 24.9778 9.76702 24.7216 9.66084C24.4653 9.55465 24.1906 9.5 23.9132 9.5C23.6358 9.5 23.3612 9.55465 23.1049 9.66084C22.8486 9.76702 22.6158 9.92266 22.4197 10.1189L22.0127 10.5258L21.6057 10.1189C21.2096 9.72274 20.6724 9.50021 20.1122 9.50021C19.552 9.50021 19.0148 9.72274 18.6186 10.1189C18.2225 10.515 18 11.0522 18 11.6124C18 12.1726 18.2225 12.7098 18.6186 13.1059L19.0256 13.5129L22.0127 16.5L24.9998 13.5129L25.4068 13.1059C25.603 12.9098 25.7586 12.677 25.8648 12.4207C25.971 12.1645 26.0256 11.8898 26.0256 11.6124C26.0256 11.335 25.971 11.0603 25.8648 10.8041C25.7586 10.5478 25.603 10.315 25.4068 10.1189Z"
                    fill="#F85565" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M24.6564 11.953C24.6564 11.953 24.7373 11.2411 24.5219 10.8387C24.4097 10.6289 24.2986 10.5157 24.2069 10.4533C24.1158 10.3914 24.0283 10.3691 23.949 10.3662C23.7291 10.3582 23.2837 10.321 23.2837 10.321C23.2837 10.321 23.7081 10.0762 24.0367 10.0542C24.2542 10.0397 24.6373 10.2071 24.6373 10.2071C24.6373 10.2071 24.8383 10.2887 24.9914 10.5748C25.3067 11.164 25.2093 11.5633 25.1163 11.9698C25.0571 12.2285 24.5439 12.88 24.5439 12.88L24.6386 12.2694L24.6381 12.0404L24.6564 11.953Z"
                    fill="url(#paint0_linear)" />
                    <ellipse cx="8" cy="16.25" rx="3" ry="1.25" fill="#F8C232" />
                    <path
                    d="M11.4068 10.1189C11.2107 9.92266 10.9778 9.76702 10.7216 9.66084C10.4653 9.55465 10.1906 9.5 9.91323 9.5C9.63584 9.5 9.36116 9.55465 9.10489 9.66084C8.84863 9.76702 8.61579 9.92266 8.41969 10.1189L8.01271 10.5258L7.60573 10.1189C7.20962 9.72274 6.67237 9.50021 6.11219 9.50021C5.552 9.50021 5.01476 9.72274 4.61865 10.1189C4.22253 10.515 4 11.0522 4 11.6124C4 12.1726 4.22253 12.7098 4.61865 13.1059L5.02563 13.5129L8.01271 16.5L10.9998 13.5129L11.4068 13.1059C11.603 12.9098 11.7586 12.677 11.8648 12.4207C11.971 12.1645 12.0256 11.8898 12.0256 11.6124C12.0256 11.335 11.971 11.0603 11.8648 10.8041C11.7586 10.5478 11.603 10.315 11.4068 10.1189Z"
                    fill="#F85565" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.6564 11.953C10.6564 11.953 10.7373 11.2411 10.5219 10.8387C10.4097 10.6289 10.2986 10.5157 10.2069 10.4533C10.1158 10.3914 10.0283 10.3691 9.94902 10.3662C9.72911 10.3582 9.28367 10.321 9.28367 10.321C9.28367 10.321 9.70813 10.0762 10.0367 10.0542C10.2542 10.0397 10.6373 10.2071 10.6373 10.2071C10.6373 10.2071 10.8383 10.2887 10.9914 10.5748C11.3067 11.164 11.2093 11.5633 11.1163 11.9698C11.0571 12.2285 10.5439 12.88 10.5439 12.88L10.6386 12.2694L10.6381 12.0404L10.6564 11.953Z"
                    fill="url(#paint1_linear)" />
                    <defs>
                    <linearGradient id="paint0_linear" x1="23.2476" y1="10.2367" x2="24.6542"
                        y2="13.3116" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0833333" stop-color="white" stop-opacity="0" />
                        <stop offset="0.375" stop-color="white" />
                        <stop offset="0.8125" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="9.24763" y1="10.2367" x2="10.6542"
                        y2="13.3116" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0833333" stop-color="white" stop-opacity="0" />
                        <stop offset="0.375" stop-color="white" />
                        <stop offset="0.8125" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    </defs>
                </svg>

                <span class="ml-2">In Love</span>`,
        value: "In Love"
    },
    {
        index: 21,
        html: `<svg width="30" height="33" viewBox="0 0 30 33" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 31.9277C23.2843 31.9277 30 25.212 30 16.9277C30 8.64346 23.2843 1.92773 15 1.92773C6.71573 1.92773 0 8.64346 0 16.9277C0 25.212 6.71573 31.9277 15 31.9277Z"
                    fill="#F8DC24" />
                    <path
                    d="M14.5916 17.3848C14.5916 17.3848 14.1226 18.2991 12.4901 19.3889C10.8576 20.4787 9.92727 20.4985 9.92727 20.4985"
                    stroke="#F7D31F" stroke-linecap="round" />
                    <path d="M19.4515 5.40922C22.3255 3.51616 25.1578 6.12459 25.1578 6.12459"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M5.09277 9.58848C6.29392 6.36347 10.1201 6.7956 10.1201 6.7956"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M10 15.4277C11.933 15.4277 13.5 13.8607 13.5 11.9277C13.5 9.99474 11.933 8.42773 10 8.42773C8.06701 8.42773 6.5 9.99474 6.5 11.9277C6.5 13.8607 8.06701 15.4277 10 15.4277Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.75 12.9277C9.75 13.6181 9.19036 14.1777 8.5 14.1777C7.80964 14.1777 7.25 13.6181 7.25 12.9277C7.25 12.2374 7.80964 11.6777 8.5 11.6777C9.19036 11.6777 9.75 12.2374 9.75 12.9277Z"
                    fill="#383B53" />
                    <path
                    d="M28.88 11.4277C28.93 11.9003 28.96 12.3779 28.96 12.8604C28.96 21.1032 21.535 27.784 12.37 27.784C8.445 27.784 4.84 26.5553 2 24.5057C4.6 28.943 9.43 31.9277 14.965 31.9277C23.25 31.9277 29.965 25.247 29.965 17.0042C29.965 15.0293 29.575 13.1489 28.88 11.4277Z"
                    fill="#F1C631" />
                    <path
                    d="M18.25 23.416C18.25 23.7232 18.1749 23.9603 18.0686 24.1065C17.9756 24.2343 17.8443 24.317 17.6273 24.317C17.0871 24.3172 16.394 24.2608 15.6551 24.1949C15.5649 24.1869 15.474 24.1787 15.3828 24.1705C14.7378 24.1125 14.075 24.0529 13.4968 24.0287C13.1196 24.0129 12.7729 24.078 12.4874 24.2427C12.1942 24.4119 11.9931 24.6714 11.9023 24.9817C11.727 25.5802 11.9818 26.2489 12.4382 26.7892C13.5119 28.0599 15.0941 29.4561 16.8269 30.5377C18.5499 31.6132 20.4833 32.416 22.25 32.416C25.0114 32.416 27.25 30.1775 27.25 27.416C27.25 26.6915 27.0956 26.0018 26.8174 25.379C26.5042 24.6776 26.25 24.0528 26.25 23.416C26.25 22.7792 26.5042 22.1544 26.8174 21.4531C27.0956 20.8302 27.25 20.1405 27.25 19.416C27.25 16.6546 25.0114 14.416 22.25 14.416C19.4886 14.416 17.25 16.6546 17.25 19.416C17.25 20.1405 17.4044 20.8302 17.6826 21.4531C17.9958 22.1544 18.25 22.7792 18.25 23.416Z"
                    fill="#F8DC25" stroke="#F8DC25" />
                    <path
                    d="M18.75 23.416C18.75 24.1739 18.4481 24.8909 18.1391 25.5829C17.889 26.1428 17.75 26.7632 17.75 27.416C17.75 29.9013 19.7648 31.916 22.25 31.916C24.7352 31.916 26.75 29.9013 26.75 27.416C26.75 26.7632 26.611 26.1428 26.3609 25.5829C26.0519 24.8909 25.75 24.1739 25.75 23.416C25.75 22.6582 26.0519 21.9411 26.3609 21.2492C26.611 20.6892 26.75 20.0689 26.75 19.416C26.75 16.9307 24.7352 14.916 22.25 14.916C19.7648 14.916 17.75 16.9307 17.75 19.416C17.75 20.0689 17.889 20.6892 18.1391 21.2492C18.4481 21.9411 18.75 22.6582 18.75 23.416Z"
                    fill="#393C54" />
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="17" y="14"
                    width="10" height="18">
                    <path
                        d="M18.75 23.416C18.75 24.1739 18.4481 24.8909 18.1391 25.5829C17.889 26.1428 17.75 26.7632 17.75 27.416C17.75 29.9013 19.7648 31.916 22.25 31.916C24.7352 31.916 26.75 29.9013 26.75 27.416C26.75 26.7632 26.611 26.1428 26.3609 25.5829C26.0519 24.8909 25.75 24.1739 25.75 23.416C25.75 22.6582 26.0519 21.9411 26.3609 21.2492C26.611 20.6892 26.75 20.0689 26.75 19.416C26.75 16.9307 24.7352 14.916 22.25 14.916C19.7648 14.916 17.75 16.9307 17.75 19.416C17.75 20.0689 17.889 20.6892 18.1391 21.2492C18.4481 21.9411 18.75 22.6582 18.75 23.416Z"
                        fill="#393C54" />
                    </mask>
                    <g mask="url(#mask0)">
                    <path
                        d="M17.25 14.416H25.25C25.25 16.0729 23.9069 17.416 22.25 17.416H20.25C18.5931 17.416 17.25 16.0729 17.25 14.416Z"
                        fill="#FF8475" />
                    <path
                        d="M17.25 15.916H24.75C24.75 17.2967 23.6307 18.416 22.25 18.416H19.75C18.3693 18.416 17.25 17.2967 17.25 15.916Z"
                        fill="white" />
                    <path
                        d="M25.25 31.916L17.75 31.916C17.75 30.2592 19.0931 28.916 20.75 28.916L22.25 28.916C23.9069 28.916 25.25 30.2592 25.25 31.916Z"
                        fill="#FF8475" />
                    <path
                        d="M24.75 29.916L17.75 29.916C17.75 28.5353 18.8693 27.416 20.25 27.416L22.25 27.416C23.6307 27.416 24.75 28.5353 24.75 29.916Z"
                        fill="white" />
                    </g>
                    <path
                    d="M9.34118 23.3754C9.34118 23.3754 10.3674 23.4276 12.0314 24.4687C13.6954 25.5099 14.0954 26.35 14.0954 26.35"
                    stroke="#F7D31F" stroke-linecap="round" />
                    <path opacity="0.2"
                    d="M18.1391 25.5829C18.4481 24.8909 18.75 24.1739 18.75 23.416C18.75 22.6582 18.4481 21.9411 18.1391 21.2492C17.889 20.6892 17.75 20.0689 17.75 19.416C17.75 16.9307 19.7648 14.916 22.25 14.916C22.5055 14.916 22.7561 14.9373 23 14.9782C20.8718 15.3353 19.25 17.1863 19.25 19.416C19.25 20.0689 19.389 20.6892 19.6391 21.2492C19.9481 21.9411 20.25 22.6582 20.25 23.416C20.25 24.1739 19.9481 24.8909 19.6391 25.5829C19.389 26.1428 19.25 26.7632 19.25 27.416C19.25 29.6458 20.8718 31.4967 23 31.8538C22.7561 31.8947 22.5055 31.916 22.25 31.916C19.7648 31.916 17.75 29.9013 17.75 27.416C17.75 26.7632 17.889 26.1428 18.1391 25.5829Z"
                    fill="#393C54" />
                    <path
                    d="M21 13.4277C19.067 13.4277 17.5 11.8607 17.5 9.92774C17.5 7.99474 19.067 6.42773 21 6.42773C22.933 6.42773 24.5 7.99474 24.5 9.92774C24.5 11.8607 22.933 13.4277 21 13.4277Z"
                    fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M20.75 8.42773C20.75 9.11809 21.3096 9.67773 22 9.67773C22.6904 9.67773 23.25 9.11809 23.25 8.42773C23.25 7.73738 22.6904 7.17773 22 7.17773C21.3096 7.17773 20.75 7.73738 20.75 8.42773Z"
                    fill="#383B53" />
                </svg>
                <span class="ml-2">In Love</span>`,
        value: "In Love"
    },
    {
        index: 22,
        html: `<svg width="30" height="31" viewBox="0 0 30 31" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 31C23.2843 31 30 24.2843 30 16C30 7.71573 23.2843 1 15 1C6.71573 1 0 7.71573 0 16C0 24.2843 6.71573 31 15 31Z"
                    fill="#F8DC24" />
                    <path
                    d="M14.4534 16.3845C14.4534 16.3845 16.1204 16.1492 17.2502 16.3846C18.3801 16.6199 19.3069 17.5857 19.3069 17.5857"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path d="M10.75 1.25L15.25 4.24993L17.5 2.99988" stroke="#393C54"
                    stroke-linecap="round" />
                    <path
                    d="M22 10.5C23.933 10.5 25.5 8.933 25.5 7C25.5 5.067 23.933 3.5 22 3.5C20.067 3.5 18.5 5.067 18.5 7C18.5 8.933 20.067 10.5 22 10.5Z"
                    fill="white" />
                    <path
                    d="M23 8.5C24.1046 8.5 25 7.60457 25 6.5C25 5.39543 24.1046 4.5 23 4.5C21.8954 4.5 21 5.39543 21 6.5C21 7.60457 21.8954 8.5 23 8.5Z"
                    fill="#393C54" />
                    <path
                    d="M18.6328 6.04124L25.4864 6.69109C25.33 4.90278 23.8288 3.5 21.9999 3.5C20.3993 3.5 19.0497 4.57435 18.6328 6.04124Z"
                    fill="#F4C515" />
                    <path d="M19 5.01367L23.3097 3.01418" stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M11.5 10.5C13.433 10.5 15 8.933 15 7C15 5.067 13.433 3.5 11.5 3.5C9.567 3.5 8 5.067 8 7C8 8.933 9.567 10.5 11.5 10.5Z"
                    fill="white" />
                    <path
                    d="M12.5 8.5C13.6046 8.5 14.5 7.60457 14.5 6.5C14.5 5.39543 13.6046 4.5 12.5 4.5C11.3954 4.5 10.5 5.39543 10.5 6.5C10.5 7.60457 11.3954 8.5 12.5 8.5Z"
                    fill="#393C54" />
                    <path
                    d="M8.13281 6.04124L14.9864 6.69109C14.83 4.90278 13.3288 3.5 11.4999 3.5C9.89932 3.5 8.5497 4.57435 8.13281 6.04124Z"
                    fill="#F4C515" />
                    <path
                    d="M28.88 10.5C28.93 10.9726 28.96 11.4501 28.96 11.9327C28.96 20.1754 21.535 26.8562 12.37 26.8562C8.445 26.8562 4.84 25.6275 2 23.578C4.6 28.0153 9.43 31 14.965 31C23.25 31 29.965 24.3192 29.965 16.0764C29.965 14.1016 29.575 12.2212 28.88 10.5Z"
                    fill="#F4C515" />
                    <path
                    d="M10.7483 15.0776C10.7483 15.0776 14.4992 13.7503 17.9992 14.5004C21.4993 15.2506 23.4993 18.0005 23.4993 18.0005"
                    stroke="#393C54" stroke-width="1.5" stroke-linecap="round" />
                    <rect width="4.1463" height="1.63397" rx="0.816986"
                    transform="matrix(-0.513658 -0.857995 0.701896 -0.71228 10.8574 17.9531)"
                    fill="#393C54" />
                </svg>
                <span class="ml-2">Boss</span>`,
        value: "Boss"
    },
    {
        index: 23,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path d="M12.5 18.5C12.5 18.5 12.8846 17.5 15 17.5C17.1154 17.5 17.5 18.5 17.5 18.5"
                    stroke="#F2BC0F" stroke-linecap="round" />
                    <path
                    d="M22 14.5C24.4853 14.5 26.5 12.4853 26.5 10C26.5 7.51472 24.4853 5.5 22 5.5C19.5147 5.5 17.5 7.51472 17.5 10C17.5 12.4853 19.5147 14.5 22 14.5Z"
                    fill="white" />
                    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.1932 5.99715C18.401 6.17903 18.4219 6.49492 18.2401 6.7027C17.8077 7.19662 17.4755 7.77193 17.264 8.39333C17.175 8.65474 16.8909 8.79451 16.6295 8.70551C16.3681 8.61651 16.2283 8.33245 16.3173 8.07104C16.571 7.3259 16.9692 6.6363 17.4876 6.04403C17.6695 5.83625 17.9854 5.81526 18.1932 5.99715ZM16.4768 9.86091C16.7524 9.84256 16.9906 10.0511 17.0089 10.3266C17.0303 10.6474 17.0831 10.9711 17.1697 11.2943C17.2563 11.6175 17.3725 11.9243 17.5143 12.2128C17.6362 12.4606 17.5342 12.7602 17.2864 12.8821C17.0386 13.004 16.7389 12.9019 16.617 12.6541C16.4465 12.3075 16.3073 11.9396 16.2038 11.5531C16.1002 11.1667 16.0368 10.7785 16.0112 10.393C15.9928 10.1175 16.2013 9.87926 16.4768 9.86091ZM17.9963 13.8064C18.1782 13.5986 18.4941 13.5776 18.7018 13.7595C19.1957 14.1919 19.7711 14.524 20.3925 14.7356C20.6539 14.8246 20.7936 15.1086 20.7046 15.37C20.6156 15.6315 20.3316 15.7712 20.0702 15.6822C19.325 15.4285 18.6354 15.0304 18.0432 14.5119C17.8354 14.33 17.8144 14.0142 17.9963 13.8064Z"
                    fill="#F2BC0F" />
                    <path
                    d="M21.5 11.5C22.6046 11.5 23.5 10.6046 23.5 9.5C23.5 8.39543 22.6046 7.5 21.5 7.5C20.3954 7.5 19.5 8.39543 19.5 9.5C19.5 10.6046 20.3954 11.5 21.5 11.5Z"
                    fill="#383B53" />
                    <path
                    d="M19.5746 10.6978C19.8727 9.41004 20.6308 8.27542 21.7064 7.50713L21.7872 7.44941L21.1452 8.47647C20.7477 9.11226 20.537 9.84696 20.537 10.5968L20.537 11.4496L19.5746 10.6978Z"
                    fill="url(#paint0_linear)" />
                    <path
                    d="M8 14.5C10.4853 14.5 12.5 12.4853 12.5 10C12.5 7.51472 10.4853 5.5 8 5.5C5.51472 5.5 3.5 7.51472 3.5 10C3.5 12.4853 5.51472 14.5 8 14.5Z"
                    fill="white" />
                    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.8055 5.99696C12.0133 5.81508 12.3292 5.83607 12.5111 6.04385C13.0295 6.63612 13.4277 7.32571 13.6814 8.07086C13.7704 8.33226 13.6306 8.61632 13.3692 8.70532C13.1078 8.79432 12.8237 8.65456 12.7347 8.39315C12.5231 7.77175 12.191 7.19643 11.7586 6.70251C11.5767 6.49473 11.5977 6.17885 11.8055 5.99696ZM13.5218 9.86072C13.7974 9.87907 14.0059 10.1173 13.9875 10.3928C13.9619 10.7783 13.8984 11.1665 13.7949 11.5529C13.6914 11.9394 13.5522 12.3073 13.3817 12.6539C13.2598 12.9017 12.9601 13.0038 12.7123 12.8819C12.4645 12.76 12.3625 12.4604 12.4843 12.2126C12.6262 11.9241 12.7424 11.6173 12.829 11.2941C12.9156 10.971 12.9684 10.6472 12.9897 10.3264C13.0081 10.0509 13.2463 9.84238 13.5218 9.86072ZM12.0024 13.8062C12.1843 14.014 12.1633 14.3299 11.9555 14.5117C11.3632 15.0302 10.6737 15.4283 9.92851 15.682C9.66711 15.771 9.38304 15.6313 9.29405 15.3699C9.20505 15.1085 9.34481 14.8244 9.60622 14.7354C10.2276 14.5238 10.8029 14.1917 11.2969 13.7593C11.5046 13.5774 11.8205 13.5984 12.0024 13.8062Z"
                    fill="#F2BC0F" />
                    <path
                    d="M7.5 11.5C8.60457 11.5 9.5 10.6046 9.5 9.5C9.5 8.39543 8.60457 7.5 7.5 7.5C6.39543 7.5 5.5 8.39543 5.5 9.5C5.5 10.6046 6.39543 11.5 7.5 11.5Z"
                    fill="#383B53" />
                    <path
                    d="M5.57464 10.6978C5.87273 9.41004 6.63079 8.27542 7.70642 7.50713L7.78723 7.44941L7.1452 8.47647C6.74775 9.11226 6.53701 9.84696 6.53701 10.5968L6.53701 11.4496L5.57464 10.6978Z"
                    fill="url(#paint1_linear)" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#F4C515" />
                    <path
                    d="M12.5 14.9392C12.5 15.8012 13.6193 16.5 15 16.5C16.3807 16.5 17.5 15.8012 17.5 14.9392C17.5 14.9392 16.1719 14.5 15 14.5C13.8281 14.5 12.5 14.9392 12.5 14.9392Z"
                    fill="#393C54" />
                    <path
                    d="M13 14.75C13 15.1814 14.0916 15.5 15.0016 15.5C15.9117 15.5 17 15.1814 17 14.75C17 14.75 15.774 14.5 15.0016 14.5C14.2292 14.5 13 14.75 13 14.75Z"
                    fill="white" />
                    <path
                    d="M17.9053 2.95034C17.9053 2.95034 18.8529 4.69599 21.4762 5.50757C24.0996 6.31915 26.7153 5.74039 26.7153 5.74039"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M12.3095 2.95034C12.3095 2.95034 11.362 4.69599 8.73863 5.50757C6.11526 6.31915 3.49958 5.74039 3.49958 5.74039"
                    stroke="#393C54" stroke-linecap="round" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9 14.75V28.7519C8.48423 28.5265 7.98358 28.273 7.5 27.9933V15.5L5.5 14.25V26.6088C5.15337 26.3248 4.8197 26.0256 4.5 25.7122V12.75L9 14.75Z"
                    fill="#00ADFE" />
                    <path
                    d="M8.5 15.5C9.05229 15.5 9.5 15.0523 9.5 14.5C9.5 13.9477 9.05229 13.5 8.5 13.5C7.94772 13.5 7.5 13.9477 7.5 14.5C7.5 15.0523 7.94772 15.5 8.5 15.5Z"
                    fill="#00ADFE" />
                    <path
                    d="M6 15C6.55229 15 7 14.5523 7 14C7 13.4477 6.55229 13 6 13C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15Z"
                    fill="#00ADFE" />
                    <path
                    d="M4.75903 13.41C5.1784 13.7694 5.66403 13.8908 5.84371 13.6811C6.0234 13.4714 5.8291 13.0101 5.40973 12.6507C4.99036 12.2913 4.50473 12.17 4.32504 12.3797C4.14535 12.5894 4.33966 13.0507 4.75903 13.41Z"
                    fill="#00ADFE" />
                    <path
                    d="M9.85368 14.8537C10.3405 14.5929 10.6295 14.1842 10.4991 13.9407C10.3687 13.6973 9.86835 13.7114 9.38151 13.9722C8.89466 14.2329 8.6057 14.6417 8.73609 14.8851C8.86648 15.1285 9.36684 15.1144 9.85368 14.8537Z"
                    fill="#00ADFE" />
                    <path
                    d="M7.5 16.5C7.77614 16.5 8 16.0523 8 15.5C8 14.9477 7.77614 14.5 7.5 14.5C7.22386 14.5 7 14.9477 7 15.5C7 16.0523 7.22386 16.5 7.5 16.5Z"
                    fill="#00ADFE" />
                    <path
                    d="M6.5 17C6.77614 17 7 16.3284 7 15.5C7 14.6716 6.77614 14 6.5 14C6.22386 14 6 14.6716 6 15.5C6 16.3284 6.22386 17 6.5 17Z"
                    fill="#00ADFE" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M21 14.75V28.7519C21.3401 28.6033 21.6736 28.4424 22 28.2699V16L24.25 15V26.8092C24.6868 26.4666 25.1041 26.1003 25.5 25.7122V12.75L21 14.75Z"
                    fill="#00ADFE" />
                    <path
                    d="M21 15.5C21.5523 15.5 22 15.0523 22 14.5C22 13.9477 21.5523 13.5 21 13.5C20.4477 13.5 20 13.9477 20 14.5C20 15.0523 20.4477 15.5 21 15.5Z"
                    fill="#00ADFE" />
                    <path
                    d="M22 16C22.5523 16 23 15.5523 23 15C23 14.4477 22.5523 14 22 14C21.4477 14 21 14.4477 21 15C21 15.5523 21.4477 16 22 16Z"
                    fill="#00ADFE" />
                    <path
                    d="M23.25 15.5C23.9404 15.5 24.5 15.0523 24.5 14.5C24.5 13.9477 23.9404 13.5 23.25 13.5C22.5596 13.5 22 13.9477 22 14.5C22 15.0523 22.5596 15.5 23.25 15.5Z"
                    fill="#00ADFE" />
                    <path
                    d="M25 14.5C25.5523 14.5 26 14.0523 26 13.5C26 12.9477 25.5523 12.5 25 12.5C24.4477 12.5 24 12.9477 24 13.5C24 14.0523 24.4477 14.5 25 14.5Z"
                    fill="#00ADFE" />
                    <path
                    d="M25.75 13.5C26.1642 13.5 26.5 13.1642 26.5 12.75C26.5 12.3358 26.1642 12 25.75 12C25.3358 12 25 12.3358 25 12.75C25 13.1642 25.3358 13.5 25.75 13.5Z"
                    fill="#00ADFE" />
                    <path
                    d="M20.25 15C20.6642 15 21 14.6642 21 14.25C21 13.8358 20.6642 13.5 20.25 13.5C19.8358 13.5 19.5 13.8358 19.5 14.25C19.5 14.6642 19.8358 15 20.25 15Z"
                    fill="#00ADFE" />
                    <path
                    d="M24 16.5C24.5523 16.5 25 16.0523 25 15.5C25 14.9477 24.5523 14.5 24 14.5C23.4477 14.5 23 14.9477 23 15.5C23 16.0523 23.4477 16.5 24 16.5Z"
                    fill="#00ADFE" />
                    <defs>
                    <linearGradient id="paint0_linear" x1="21.5585" y1="8.14798" x2="19.9077"
                        y2="10.3522" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="7.55845" y1="8.14798" x2="5.90767"
                        y2="10.3522" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="white" />
                    </linearGradient>
                    </defs>
                </svg>

                <span class="ml-2">Too cute</span>`,
        value: "Too cute"
    },
    {
        index: 24,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#00ADFE" />
                    <path d="M18 17C18 17 18.4911 14.5 20.8482 14.5C23.2054 14.5 23.5 17 23.5 17"
                    stroke="#393C54" stroke-linecap="round" />
                    <path d="M6.5 17C6.5 17 6.99107 14.5 9.34821 14.5C11.7054 14.5 12 17 12 17"
                    stroke="#393C54" stroke-linecap="round" />
                    <path
                    d="M28.88 9.5C28.93 9.97258 28.96 10.4501 28.96 10.9327C28.96 19.1754 21.535 25.8562 12.37 25.8562C8.445 25.8562 4.84 24.6275 2 22.578C4.6 27.0153 9.43 30 14.965 30C23.25 30 29.965 23.3192 29.965 15.0764C29.965 13.1016 29.575 11.2212 28.88 9.5Z"
                    fill="#1593E1" />
                    <rect x="18.5" y="11.5" width="5" height="1" rx="0.5" fill="#1593E1" />
                    <rect x="6.5" y="11.5" width="5" height="1" rx="0.5" fill="#1593E1" />
                    <rect x="8" y="20.5" width="14" height="6" rx="3" fill="#393C54" />
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="20"
                    width="14" height="7">
                    <rect x="8" y="20.5" width="14" height="6" rx="3" fill="#393C54" />
                    </mask>
                    <g mask="url(#mask0)">
                    <path
                        d="M10 20.5H20V22C20 22.5523 19.5523 23 19 23H11C10.4477 23 10 22.5523 10 22V20.5Z"
                        fill="white" />
                    <path
                        d="M20 26.5L10 26.5L10 25C10 24.4477 10.4477 24 11 24L19 24C19.5523 24 20 24.4477 20 25L20 26.5Z"
                        fill="white" />
                    </g>
                </svg>
                <span class="ml-2">Numb</span>`,
        value: "Numb"
    },
    {
        index: 25,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.5 17.8125H7.5V21.5625H22.5V17.8125Z" fill="#8C5C3B" />
                    <path
                    d="M26.6719 21.2344C27.4219 20.4844 27.8906 19.4531 27.9844 18.3281V18.2812C28.125 15.75 26.25 13.5937 23.7188 13.4531H23.3906C24 12.9375 24.4219 12.1875 24.5156 11.3437V11.2969C24.75 9.42187 23.4375 7.78125 21.5625 7.54688H21.4219C21.6094 7.03125 21.6562 6.42187 21.6094 5.85937C21.4219 4.5 20.2969 3.46875 18.2344 3.46875C13.875 3.46875 11.2969 1.6875 10.6875 0.09375C8.01562 1.35938 7.96875 4.73437 9.375 6.04687H9.23438C7.35938 5.8125 5.71875 7.125 5.48438 9V9.04687C5.29688 10.5 6.04688 11.8125 7.26562 12.4687H6.9375C4.40625 12.3281 2.25 14.2031 2.10938 16.7344V16.7812C1.96875 18.6562 3.04687 20.3906 4.64062 21.1875H4.45312C2.01562 21.0937 0 23.1094 0 25.5469C0 27.9844 2.01562 30 4.45312 30H25.5469C27.9844 30 30 27.9844 30 25.5469C30 23.4844 28.5938 21.75 26.6719 21.2344Z"
                    fill="#8C5C3B" />
                    <path
                    d="M18.0469 20.625C19.9885 20.625 21.5625 19.051 21.5625 17.1094C21.5625 15.1677 19.9885 13.5938 18.0469 13.5938C16.1052 13.5938 14.5312 15.1677 14.5312 17.1094C14.5312 19.051 16.1052 20.625 18.0469 20.625Z"
                    fill="white" />
                    <path
                    d="M18.0469 18.75C18.953 18.75 19.6875 18.0155 19.6875 17.1094C19.6875 16.2033 18.953 15.4688 18.0469 15.4688C17.1408 15.4688 16.4062 16.2033 16.4062 17.1094C16.4062 18.0155 17.1408 18.75 18.0469 18.75Z"
                    fill="#1A1A54" />
                    <path
                    d="M11.9531 20.625C13.8948 20.625 15.4688 19.051 15.4688 17.1094C15.4688 15.1677 13.8948 13.5938 11.9531 13.5938C10.0115 13.5938 8.4375 15.1677 8.4375 17.1094C8.4375 19.051 10.0115 20.625 11.9531 20.625Z"
                    fill="white" />
                    <path
                    d="M11.9531 18.75C12.8592 18.75 13.5938 18.0155 13.5938 17.1094C13.5938 16.2033 12.8592 15.4688 11.9531 15.4688C11.047 15.4688 10.3125 16.2033 10.3125 17.1094C10.3125 18.0155 11.047 18.75 11.9531 18.75Z"
                    fill="#1A1A54" />
                    <path
                    d="M21.5625 19.6875C21.5625 23.2969 18.6094 26.25 15 26.25C11.3906 26.25 8.4375 23.2969 8.4375 19.6875H21.5625Z"
                    fill="#1A1A54" />
                    <path
                    d="M15 22.6406C13.0313 22.6406 11.2969 23.2969 10.2656 24.2812C11.4844 25.5 13.1719 26.25 15 26.25C16.8281 26.25 18.5156 25.5 19.7344 24.2344C18.7031 23.25 16.9688 22.6406 15 22.6406Z"
                    fill="#DC2863" />
                </svg>
                <span class="ml-2">Shit</span>`,
        value: "Shit"
    },
    {
        index: 26,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M22.9688 16.875C22.9688 21.2812 19.4062 24.8438 15 24.8438C10.5938 24.8438 7.03125 21.2812 7.03125 16.875H22.9688Z"
                    fill="#1A1A54" />
                    <path
                    d="M15 20.4375C12.6094 20.4375 10.5 21.2344 9.28125 22.4062C10.7344 23.9062 12.75 24.8438 15 24.8438C17.25 24.8438 19.2656 23.9062 20.7188 22.4062C19.5 21.2344 17.3906 20.4375 15 20.4375Z"
                    fill="#DC2863" />
                    <path
                    d="M19.7812 18.75H10.2187C9.84375 18.75 9.51562 18.4687 9.42187 18.0937L9.1875 16.875H20.8125L20.5781 18.0937C20.4844 18.4687 20.1562 18.75 19.7812 18.75Z"
                    fill="white" />
                    <path
                    d="M21.3292 13.6406L19.173 14.7656C18.798 14.9531 18.3292 14.625 18.3761 14.2031L18.798 11.7656L17.0636 10.0312C16.7355 9.70312 16.923 9.1875 17.3448 9.14062L19.7823 8.76563L20.8605 6.5625C21.048 6.1875 21.6105 6.1875 21.798 6.5625L22.8761 8.76563L25.3136 9.14062C25.7355 9.1875 25.923 9.75 25.5948 10.0312L23.8605 11.7656L24.2823 14.2031C24.3761 14.625 23.9073 14.9531 23.4855 14.7656L21.3292 13.6406Z"
                    fill="white" />
                    <path
                    d="M8.67297 13.6406L6.51672 14.7656C6.14172 14.9531 5.67297 14.625 5.71984 14.2031L6.14172 11.7656L4.40734 10.0312C4.07922 9.75 4.21984 9.23438 4.68859 9.14062L7.12609 8.76563L8.20422 6.5625C8.39172 6.1875 8.95422 6.1875 9.14172 6.5625L10.2198 8.76563L12.6573 9.14062C13.0792 9.1875 13.2667 9.75 12.9386 10.0312L11.2042 11.7656L11.6261 14.2031C11.7198 14.625 11.2511 14.9531 10.8292 14.7656L8.67297 13.6406Z"
                    fill="white" />
                </svg>
                <span class="ml-2">Excited</span>`,
        value: "Excited"
    },
    {
        index: 27,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M22.7824 15.9375C22.0324 16.2187 21.3293 16.2656 20.6262 16.1719C19.923 16.0781 19.2199 15.7969 18.6105 15.4219C18.0012 15.0469 17.4387 14.5781 17.0637 13.9687C16.8293 13.6875 16.6887 13.3594 16.548 13.0781C16.4074 12.75 16.3137 12.4219 16.2199 12.0937C16.173 11.8594 16.3137 11.625 16.548 11.5781C16.6418 11.5781 16.7355 11.5781 16.8293 11.625L16.8762 11.6719C17.1574 11.8125 17.3918 12.0469 17.673 12.1875C17.9074 12.3281 18.1418 12.5156 18.3762 12.7031C18.8449 13.0312 19.3137 13.3125 19.7355 13.5937C20.2043 13.875 20.673 14.1094 21.1418 14.3437C21.6105 14.5781 22.173 14.7656 22.6887 15L22.7824 15.0469C23.0168 15.1406 23.1574 15.4687 23.0168 15.7031C22.9699 15.7969 22.8762 15.8906 22.7824 15.9375Z"
                    fill="#1A1A54" />
                    <path
                    d="M4.03125 14.9535C4.54688 14.7191 5.10938 14.5316 5.57813 14.2972C6.04688 14.0628 6.51563 13.8285 6.98438 13.5472C7.45313 13.266 7.875 12.9847 8.34375 12.6566C8.57812 12.516 8.8125 12.3285 9.04688 12.141C9.28125 11.9535 9.51563 11.766 9.84375 11.6253L9.89063 11.5785C10.0781 11.4847 10.3594 11.5316 10.4531 11.766C10.5 11.8597 10.5 11.9535 10.5 12.0472C10.4531 12.3753 10.3125 12.7035 10.1719 13.0316C9.98438 13.3128 9.84375 13.641 9.65625 13.9222C9.23438 14.4847 8.71875 14.9535 8.10938 15.3753C7.5 15.7503 6.79688 15.9847 6.09375 16.1253C5.39063 16.2191 4.6875 16.2191 3.9375 15.891C3.65625 15.7972 3.5625 15.4691 3.65625 15.2347C3.70313 15.0941 3.79687 15.0003 3.9375 14.9535H4.03125Z"
                    fill="#1A1A54" />
                    <path
                    d="M17.4375 24.5625C16.6875 24.375 15.9844 24.2344 15.3281 24.0938C14.6719 23.9531 14.0156 23.9062 13.3594 23.9062C12.7031 23.9062 12.0469 23.9531 11.3906 24.0938C10.6875 24.1875 10.0313 24.375 9.23438 24.5156C8.90625 24.5625 8.57813 24.375 8.48438 24.0469C8.4375 23.8594 8.48437 23.625 8.625 23.4844C9.1875 22.8281 9.9375 22.3594 10.7344 21.9844C11.5312 21.6563 12.4687 21.4219 13.3594 21.4219C14.25 21.4219 15.1875 21.6094 15.9844 21.9375C16.7812 22.2656 17.5312 22.7813 18.1406 23.3906C18.375 23.6719 18.375 24.0938 18.0937 24.3281C17.8594 24.5625 17.6719 24.6094 17.4375 24.5625Z"
                    fill="#1A1A54" />
                    <path
                    d="M21.9389 21.4688C21.1889 20.7188 21.0951 19.5469 21.7514 18.7031L23.392 16.5469L25.0326 18.7031C25.6889 19.5469 25.5951 20.7188 24.8451 21.4688C24.0951 22.3125 22.7826 22.3125 21.9389 21.4688Z"
                    fill="#0B85FF" />
                </svg>

                <span class="ml-2">Sad</span>`,
        value: "Sad"
    },
    {
        index: 28,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#FFBF0B" />
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path d="M17.3438 8.4375H12.6562V10.7812H17.3438V8.4375Z" fill="white" />
                    <path
                    d="M15 22.5C12.9375 22.5 11.25 20.8125 11.25 18.75V16.875C11.25 16.3594 11.6719 15.9375 12.1875 15.9375H17.8125C18.3281 15.9375 18.75 16.3594 18.75 16.875V18.75C18.75 20.8125 17.0625 22.5 15 22.5Z"
                    fill="#1A1A54" />
                    <path
                    d="M15 22.5C16.2656 22.5 17.3906 21.8906 18.0469 20.9062C17.3438 19.9687 16.2656 19.3594 15 19.3594C13.7344 19.3594 12.6562 19.9687 11.9531 20.9062C12.6094 21.8906 13.7344 22.5 15 22.5Z"
                    fill="#DC2863" />
                    <path opacity="0.2" d="M12.6562 10.7812H14.0625L13.8281 8.4375H12.6562V10.7812Z"
                    fill="#1A1A54" />
                    <path opacity="0.2" d="M17.3438 10.7812H15.8906L16.1719 8.4375H17.3438V10.7812Z"
                    fill="#1A1A54" />
                    <path
                    d="M11.4375 14.5312H4.6875C3.89062 14.5312 3.28125 13.9219 3.28125 13.125V8.90625C3.28125 8.10938 3.89062 7.5 4.6875 7.5H11.9531C12.7969 7.5 13.4531 8.25 13.3594 9.09375L12.7969 13.3125C12.7031 14.0156 12.1406 14.5312 11.4375 14.5312Z"
                    fill="white" />
                    <path d="M4.6875 13.125V8.90625H11.9531L11.4375 13.125H4.6875Z" fill="#DC2863" />
                    <path
                    d="M18.5636 14.5312H25.3136C26.1105 14.5312 26.7199 13.9219 26.7199 13.125V8.90625C26.7199 8.10938 26.1105 7.5 25.3136 7.5H18.048C17.2042 7.5 16.548 8.25 16.6417 9.09375L17.2042 13.3125C17.298 14.0156 17.8605 14.5312 18.5636 14.5312Z"
                    fill="white" />
                    <path d="M25.3125 13.125V8.90625H18.0469L18.5625 13.125H25.3125Z" fill="#0B85FF" />
                </svg>
                <span class="ml-2">Entertained</span>`,
        value: "Entertained"
    },
    {
        index: 29,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M5.625 26.7188V3.28125C2.20312 6.04688 0 10.2656 0 15C0 19.7344 2.20312 23.9531 5.625 26.7188Z"
                    fill="#DC2863" />
                    <path
                    d="M5.625 26.7188C7.03125 27.8438 8.57812 28.6875 10.3125 29.25V0.75C8.57812 1.3125 7.03125 2.20313 5.625 3.28125V26.7188Z"
                    fill="#E86942" />
                    <path
                    d="M10.3125 0.75V29.25C11.7656 29.7188 13.3594 30 15 30V0C13.3594 0 11.7656 0.28125 10.3125 0.75Z"
                    fill="#FFBF0B" />
                    <path
                    d="M19.6875 0.75C18.2344 0.28125 16.6406 0 15 0V30C16.6406 30 18.2344 29.7188 19.6875 29.25V0.75Z"
                    fill="#00AF64" />
                    <path
                    d="M19.6875 29.25C21.4219 28.6875 22.9688 27.7969 24.375 26.7188V3.28125C22.9688 2.15625 21.4219 1.3125 19.6875 0.75V29.25Z"
                    fill="#0B85FF" />
                    <path
                    d="M24.375 3.28125V26.7188C27.7969 23.9531 30 19.7344 30 15C30 10.2656 27.7969 6.04688 24.375 3.28125Z"
                    fill="#6642AD" />
                    <path
                    d="M8.71875 19.5C9.79688 19.7344 10.875 19.9219 11.9062 20.1094C12.9375 20.25 13.9688 20.3438 15 20.3438C16.0313 20.3438 17.0625 20.25 18.0938 20.1094C19.125 19.9688 20.2031 19.7344 21.2813 19.5469C21.6563 19.5 21.9844 19.7344 22.0781 20.1094C22.125 20.3438 22.0312 20.625 21.8438 20.7656C20.9062 21.5156 19.8281 22.0781 18.6562 22.4531C17.4844 22.8281 16.2656 23.0156 15 23.0156C13.7813 23.0156 12.5156 22.8281 11.3438 22.4531C10.1719 22.0781 9.09375 21.5625 8.10937 20.8125C7.78125 20.5781 7.73438 20.1094 7.96875 19.7813C8.20313 19.5469 8.4375 19.4531 8.71875 19.5Z"
                    fill="#1A1A54" />
                    <path
                    d="M20.8594 16.875C22.801 16.875 24.375 15.301 24.375 13.3594C24.375 11.4177 22.801 9.84375 20.8594 9.84375C18.9177 9.84375 17.3438 11.4177 17.3438 13.3594C17.3438 15.301 18.9177 16.875 20.8594 16.875Z"
                    fill="white" />
                    <path
                    d="M20.8594 15.4688C22.0244 15.4688 22.9688 14.5244 22.9688 13.3594C22.9688 12.1944 22.0244 11.25 20.8594 11.25C19.6944 11.25 18.75 12.1944 18.75 13.3594C18.75 14.5244 19.6944 15.4688 20.8594 15.4688Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.14062 16.875C11.0823 16.875 12.6562 15.301 12.6562 13.3594C12.6562 11.4177 11.0823 9.84375 9.14062 9.84375C7.199 9.84375 5.625 11.4177 5.625 13.3594C5.625 15.301 7.199 16.875 9.14062 16.875Z"
                    fill="white" />
                    <path
                    d="M9.14062 15.4688C10.3056 15.4688 11.25 14.5244 11.25 13.3594C11.25 12.1944 10.3056 11.25 9.14062 11.25C7.97565 11.25 7.03125 12.1944 7.03125 13.3594C7.03125 14.5244 7.97565 15.4688 9.14062 15.4688Z"
                    fill="#1A1A54" />
                </svg>
                <span class="ml-2">LGBTQ</span>`,
        value: "LGBTQ"
    },
    {
        index: 30,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M20.8594 14.0625C22.801 14.0625 24.375 12.4885 24.375 10.5469C24.375 8.60525 22.801 7.03125 20.8594 7.03125C18.9177 7.03125 17.3438 8.60525 17.3438 10.5469C17.3438 12.4885 18.9177 14.0625 20.8594 14.0625Z"
                    fill="white" />
                    <path
                    d="M20.8594 12.6562C22.0244 12.6562 22.9688 11.7119 22.9688 10.5469C22.9688 9.3819 22.0244 8.4375 20.8594 8.4375C19.6944 8.4375 18.75 9.3819 18.75 10.5469C18.75 11.7119 19.6944 12.6562 20.8594 12.6562Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.14062 14.0625C11.0823 14.0625 12.6562 12.4885 12.6562 10.5469C12.6562 8.60525 11.0823 7.03125 9.14062 7.03125C7.199 7.03125 5.625 8.60525 5.625 10.5469C5.625 12.4885 7.199 14.0625 9.14062 14.0625Z"
                    fill="white" />
                    <path
                    d="M9.14062 12.6562C10.3056 12.6562 11.25 11.7119 11.25 10.5469C11.25 9.3819 10.3056 8.4375 9.14062 8.4375C7.97565 8.4375 7.03125 9.3819 7.03125 10.5469C7.03125 11.7119 7.97565 12.6562 9.14062 12.6562Z"
                    fill="#1A1A54" />
                    <path
                    d="M10.4998 26.8591L21.8904 20.2966C22.3592 20.0154 22.4998 19.4529 22.2185 19.031L20.8123 16.5935C20.531 16.1248 19.9685 15.9841 19.5467 16.2654L8.15602 22.8279C7.68727 23.1091 7.54665 23.6716 7.8279 24.0935L9.23415 26.531C9.46852 26.9529 10.031 27.1404 10.4998 26.8591Z"
                    fill="white" />
                    <path opacity="0.1"
                    d="M9.65625 24.0469C9.91513 24.0469 10.125 23.837 10.125 23.5781C10.125 23.3192 9.91513 23.1094 9.65625 23.1094C9.39737 23.1094 9.1875 23.3192 9.1875 23.5781C9.1875 23.837 9.39737 24.0469 9.65625 24.0469Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M19.4062 18.4219C19.6651 18.4219 19.875 18.212 19.875 17.9531C19.875 17.6942 19.6651 17.4844 19.4062 17.4844C19.1474 17.4844 18.9375 17.6942 18.9375 17.9531C18.9375 18.212 19.1474 18.4219 19.4062 18.4219Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M10.5938 25.6406C10.8526 25.6406 11.0625 25.4308 11.0625 25.1719C11.0625 24.913 10.8526 24.7031 10.5938 24.7031C10.3349 24.7031 10.125 24.913 10.125 25.1719C10.125 25.4308 10.3349 25.6406 10.5938 25.6406Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M20.3438 20.0156C20.6026 20.0156 20.8125 19.8058 20.8125 19.5469C20.8125 19.288 20.6026 19.0781 20.3438 19.0781C20.0849 19.0781 19.875 19.288 19.875 19.5469C19.875 19.8058 20.0849 20.0156 20.3438 20.0156Z"
                    fill="#1A1A54" />
                    <path opacity="0.2"
                    d="M9.5625 21.9844L14.0156 24.8438L20.7656 20.9531L15.7969 18.375L9.5625 21.9844Z"
                    fill="#1A1A54" />
                    <path
                    d="M8.15602 20.2968L19.4998 26.8593C19.9685 27.1406 20.531 26.9531 20.7654 26.5312L22.1716 24.0937C22.4529 23.6249 22.2654 23.0624 21.8435 22.8281L10.4998 16.2656C10.031 15.9843 9.46852 16.1718 9.23415 16.5937L7.8279 19.0312C7.54665 19.4999 7.68727 20.0624 8.15602 20.2968Z"
                    fill="white" />
                    <path opacity="0.1"
                    d="M10.5938 18.4219C10.8526 18.4219 11.0625 18.212 11.0625 17.9531C11.0625 17.6942 10.8526 17.4844 10.5938 17.4844C10.3349 17.4844 10.125 17.6942 10.125 17.9531C10.125 18.212 10.3349 18.4219 10.5938 18.4219Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M13.0312 19.8281C13.2901 19.8281 13.5 19.6183 13.5 19.3594C13.5 19.1005 13.2901 18.8906 13.0312 18.8906C12.7724 18.8906 12.5625 19.1005 12.5625 19.3594C12.5625 19.6183 12.7724 19.8281 13.0312 19.8281Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M15.4688 21.2344C15.7276 21.2344 15.9375 21.0245 15.9375 20.7656C15.9375 20.5067 15.7276 20.2969 15.4688 20.2969C15.2099 20.2969 15 20.5067 15 20.7656C15 21.0245 15.2099 21.2344 15.4688 21.2344Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M17.9062 22.6406C18.1651 22.6406 18.375 22.4308 18.375 22.1719C18.375 21.913 18.1651 21.7031 17.9062 21.7031C17.6474 21.7031 17.4375 21.913 17.4375 22.1719C17.4375 22.4308 17.6474 22.6406 17.9062 22.6406Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M20.3438 24.0469C20.6026 24.0469 20.8125 23.837 20.8125 23.5781C20.8125 23.3192 20.6026 23.1094 20.3438 23.1094C20.0849 23.1094 19.875 23.3192 19.875 23.5781C19.875 23.837 20.0849 24.0469 20.3438 24.0469Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M9.65625 20.0156C9.91513 20.0156 10.125 19.8058 10.125 19.5469C10.125 19.288 9.91513 19.0781 9.65625 19.0781C9.39737 19.0781 9.1875 19.288 9.1875 19.5469C9.1875 19.8058 9.39737 20.0156 9.65625 20.0156Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M12.0938 21.4219C12.3526 21.4219 12.5625 21.212 12.5625 20.9531C12.5625 20.6942 12.3526 20.4844 12.0938 20.4844C11.8349 20.4844 11.625 20.6942 11.625 20.9531C11.625 21.212 11.8349 21.4219 12.0938 21.4219Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M14.5312 22.8281C14.7901 22.8281 15 22.6183 15 22.3594C15 22.1005 14.7901 21.8906 14.5312 21.8906C14.2724 21.8906 14.0625 22.1005 14.0625 22.3594C14.0625 22.6183 14.2724 22.8281 14.5312 22.8281Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M16.9688 24.2344C17.2276 24.2344 17.4375 24.0245 17.4375 23.7656C17.4375 23.5067 17.2276 23.2969 16.9688 23.2969C16.7099 23.2969 16.5 23.5067 16.5 23.7656C16.5 24.0245 16.7099 24.2344 16.9688 24.2344Z"
                    fill="#1A1A54" />
                    <path opacity="0.1"
                    d="M19.4062 25.6406C19.6651 25.6406 19.875 25.4308 19.875 25.1719C19.875 24.913 19.6651 24.7031 19.4062 24.7031C19.1474 24.7031 18.9375 24.913 18.9375 25.1719C18.9375 25.4308 19.1474 25.6406 19.4062 25.6406Z"
                    fill="#1A1A54" />
                </svg>

                <span class="ml-2">Shut up</span>`,
        value: "Shut up"
    },
    {
        index: 31,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M22.7344 15C24.676 15 26.25 13.426 26.25 11.4844C26.25 9.54275 24.676 7.96875 22.7344 7.96875C20.7927 7.96875 19.2188 9.54275 19.2188 11.4844C19.2188 13.426 20.7927 15 22.7344 15Z"
                    fill="white" />
                    <path
                    d="M23.2031 12.1875C24.1092 12.1875 24.8438 11.453 24.8438 10.5469C24.8438 9.64078 24.1092 8.90625 23.2031 8.90625C22.297 8.90625 21.5625 9.64078 21.5625 10.5469C21.5625 11.453 22.297 12.1875 23.2031 12.1875Z"
                    fill="#1A1A54" />
                    <path
                    d="M12.4219 15C14.3635 15 15.9375 13.426 15.9375 11.4844C15.9375 9.54275 14.3635 7.96875 12.4219 7.96875C10.4802 7.96875 8.90625 9.54275 8.90625 11.4844C8.90625 13.426 10.4802 15 12.4219 15Z"
                    fill="white" />
                    <path
                    d="M12.8906 12.1875C13.7967 12.1875 14.5312 11.453 14.5312 10.5469C14.5312 9.64078 13.7967 8.90625 12.8906 8.90625C11.9845 8.90625 11.25 9.64078 11.25 10.5469C11.25 11.453 11.9845 12.1875 12.8906 12.1875Z"
                    fill="#1A1A54" />
                    <path
                    d="M8.25 7.03125C8.625 6.375 9.1875 5.95312 9.79687 5.57812C10.4062 5.25 11.1094 5.01562 11.8125 4.92187C12.5156 4.82812 13.2187 4.875 13.875 5.0625C14.2031 5.10938 14.5312 5.29687 14.8594 5.39062C15.1875 5.53125 15.4687 5.71875 15.75 5.90625C15.8906 6.04687 15.9375 6.23437 15.8437 6.42187C15.7969 6.51562 15.7031 6.5625 15.6094 6.5625H15.5625C15.2344 6.60937 14.9531 6.60937 14.625 6.60937H14.2031C14.0625 6.60937 13.9219 6.60937 13.7812 6.60937C13.2187 6.60937 12.6562 6.70312 12.1406 6.75C11.625 6.84375 11.0625 6.9375 10.5469 7.07812C10.0312 7.21875 9.46875 7.40625 8.95312 7.59375L8.85937 7.64062C8.625 7.73437 8.39063 7.59375 8.29688 7.35937C8.15625 7.26562 8.20312 7.125 8.25 7.03125Z"
                    fill="#1A1A54" />
                    <path
                    d="M19.3594 3.2812C19.9687 2.81245 20.625 2.57807 21.3281 2.48432C22.0313 2.39057 22.7344 2.43745 23.4375 2.57807C24.0938 2.76557 24.7969 3.04682 25.3125 3.42182C25.5938 3.60932 25.8281 3.8437 26.1094 4.07807C26.3438 4.31245 26.5313 4.5937 26.7188 4.87495C26.8125 5.06245 26.7656 5.24995 26.625 5.39057C26.5312 5.43745 26.4375 5.43745 26.3438 5.43745H26.2969C25.9687 5.3437 25.6875 5.24995 25.4063 5.1562L24.9844 5.01557C24.8438 4.9687 24.7031 4.92182 24.5625 4.87495C24.0469 4.68745 23.4844 4.54682 22.9688 4.4062C22.5 4.31245 21.9375 4.2187 21.4219 4.17182C20.8594 4.12495 20.2969 4.07807 19.7344 4.07807H19.6406C19.4063 4.07807 19.2188 3.8437 19.2188 3.60932C19.2188 3.51557 19.2656 3.37495 19.3594 3.2812Z"
                    fill="#1A1A54" />
                    <path
                    d="M22.5 22.5C25.0888 22.5 27.1875 20.4013 27.1875 17.8125C27.1875 15.2237 25.0888 13.125 22.5 13.125C19.9112 13.125 17.8125 15.2237 17.8125 17.8125C17.8125 20.4013 19.9112 22.5 22.5 22.5Z"
                    fill="#FFBF0B" />
                    <path
                    d="M22.5 20.1562C23.7944 20.1562 24.8438 19.1069 24.8438 17.8125C24.8438 16.5181 23.7944 15.4688 22.5 15.4688C21.2056 15.4688 20.1562 16.5181 20.1562 17.8125C20.1562 19.1069 21.2056 20.1562 22.5 20.1562Z"
                    fill="#1A1A54" />
                    <path
                    d="M1.03213 8.4375C0.938385 7.78125 0.938385 7.125 1.07901 6.46875C1.21963 5.8125 1.59463 5.10937 2.20401 4.54687C2.81338 4.07812 3.61026 3.79688 4.31338 3.75C4.68838 3.75 5.11026 3.75 5.53214 3.84375C5.90714 3.9375 6.28214 4.125 6.61026 4.3125C7.26651 4.73437 7.78213 5.34375 8.11026 6.09375C8.43838 6.84375 8.48526 7.78125 8.15713 8.57812C7.82901 9.375 7.21963 9.9375 6.65713 10.3125C6.04776 10.6875 5.43838 10.9219 4.82901 11.0625L5.48526 10.1719C5.43838 10.6406 5.39151 11.0625 5.34463 11.5312C5.29776 11.7656 5.29776 12 5.25088 12.2344C5.20401 12.4688 5.15713 12.6562 5.11026 12.9375C5.06338 13.2188 4.73526 13.4062 4.45401 13.3594C4.21964 13.3125 4.07901 13.125 4.03214 12.8906V12.7969C3.89151 12.6562 3.84464 12.4219 3.79776 12.1875C3.75089 11.9531 3.75089 11.7188 3.70401 11.5312C3.65714 11.0625 3.61026 10.6406 3.56338 10.1719C3.51651 9.79688 3.75088 9.46875 4.07901 9.32812L4.21964 9.28125C5.15714 8.95312 5.95401 8.34375 6.09463 7.78125C6.18838 7.5 6.14151 7.21875 6.04776 6.9375C5.90713 6.65625 5.67276 6.375 5.34463 6.14063C5.06338 5.95313 4.73526 5.8125 4.36026 5.8125C3.93839 5.8125 3.61026 5.85937 3.32901 6.04687C3.04776 6.23437 2.81338 6.51562 2.57901 6.9375C2.39151 7.35938 2.25088 7.875 2.15713 8.29688V8.39062C2.11026 8.71875 1.78213 8.95312 1.45401 8.90625C1.26651 8.90625 1.03213 8.71875 1.03213 8.4375Z"
                    fill="#0B85FF" />
                    <path
                    d="M4.45312 15.4688C4.84145 15.4688 5.15625 15.154 5.15625 14.7656C5.15625 14.3773 4.84145 14.0625 4.45312 14.0625C4.0648 14.0625 3.75 14.3773 3.75 14.7656C3.75 15.154 4.0648 15.4688 4.45312 15.4688Z"
                    fill="#0B85FF" />
                </svg>
                <span class="ml-2">Confused</span>`,
        value: "Confused"
    },
    {
        index: 32,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M24.375 30H5.625C3.5625 30 1.875 28.3125 1.875 26.25V8.4375C1.875 6.375 3.5625 4.6875 5.625 4.6875H24.375C26.4375 4.6875 28.125 6.375 28.125 8.4375V26.25C28.125 28.3125 26.4375 30 24.375 30Z"
                    fill="#0B85FF" />
                    <path
                    d="M20.1562 26.7188H9.84375C8.53125 26.7188 7.5 25.6875 7.5 24.375C7.5 23.0625 8.53125 22.0312 9.84375 22.0312H20.1562C21.4688 22.0312 22.5 23.0625 22.5 24.375C22.5 25.6875 21.4688 26.7188 20.1562 26.7188Z"
                    fill="white" />
                    <g opacity="0.2">
                    <path opacity="0.2" d="M11.25 22.0312V26.7188" stroke="#1A1A54" stroke-width="2"
                        stroke-miterlimit="10" />
                    <path opacity="0.2" d="M15 22.0312V26.7188" stroke="#1A1A54" stroke-width="2"
                        stroke-miterlimit="10" />
                    <path opacity="0.2" d="M18.75 22.0312V26.7188" stroke="#1A1A54" stroke-width="2"
                        stroke-miterlimit="10" />
                    <path opacity="0.2" d="M8.90625 24.375H21.0938" stroke="#1A1A54" stroke-width="2"
                        stroke-miterlimit="10" />
                    </g>
                    <path
                    d="M13.4063 3.28125H16.5938C17.6719 3.28125 18.6562 3.79688 19.2188 4.6875H10.7812C11.3438 3.79688 12.375 3.28125 13.4063 3.28125Z"
                    fill="#1A1A54" />
                    <path
                    d="M22.9688 19.2188H7.03125C6.51562 19.2188 6.09375 18.7969 6.09375 18.2812V13.5938C6.09375 13.0781 6.51562 12.6562 7.03125 12.6562H22.9688C23.4844 12.6562 23.9062 13.0781 23.9062 13.5938V18.2812C23.9062 18.7969 23.4844 19.2188 22.9688 19.2188Z"
                    fill="#1A1A54" />
                    <path
                    d="M15.0469 0H15C13.6875 0 12.6562 1.03125 12.6562 2.29688V5.15625H17.3438V2.29688C17.3438 1.03125 16.3125 0 15.0469 0Z"
                    fill="#FFBF0B" />
                    <path
                    d="M18.75 15.4688C18.2344 15.4688 17.8125 15.8906 17.8125 16.4062V18.2812H19.6875V16.4062C19.6875 15.8906 19.2656 15.4688 18.75 15.4688Z"
                    fill="#00AF64" />
                    <path
                    d="M11.25 15.4688C10.7344 15.4688 10.3125 15.8906 10.3125 16.4062V18.2812H12.1875V16.4062C12.1875 15.8906 11.7656 15.4688 11.25 15.4688Z"
                    fill="#00AF64" />
                    <path
                    d="M15 0.609375L15.0469 2.71875L14.5781 2.15625H15.6094H16.2187L16.0312 2.76563L15.2812 5.29688L14.3906 5.01562L15.1406 2.48438L15.6094 3.09375H14.5781H13.875L14.1094 2.53125L15 0.609375Z"
                    fill="#DC2863" />
                    <path
                    d="M1.875 12.6562C0.84375 12.6562 0 13.5 0 14.5312V18.75C0 19.7812 0.84375 20.625 1.875 20.625V12.6562Z"
                    fill="#0B85FF" />
                    <path opacity="0.2"
                    d="M1.875 12.6562C0.84375 12.6562 0 13.5 0 14.5312V18.75C0 19.7812 0.84375 20.625 1.875 20.625V12.6562Z"
                    fill="#1A1A54" />
                    <path
                    d="M28.125 20.625C29.1562 20.625 30 19.7812 30 18.75V14.5312C30 13.5 29.1562 12.6562 28.125 12.6562V20.625Z"
                    fill="#0B85FF" />
                    <path opacity="0.2"
                    d="M28.125 20.625C29.1562 20.625 30 19.7812 30 18.75V14.5312C30 13.5 29.1562 12.6562 28.125 12.6562V20.625Z"
                    fill="#1A1A54" />
                    <path
                    d="M15.75 7.03125H14.25C12.7031 7.03125 11.3438 6.09375 10.7812 4.6875H19.2188C18.6562 6.09375 17.2969 7.03125 15.75 7.03125Z"
                    fill="#1A1A54" />
                </svg>

                <span class="ml-2">Robot</span>`,
        value: "Robot"
    },
    {
        index: 33,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M19.4531 16.875C21.3948 16.875 22.9688 15.301 22.9688 13.3594C22.9688 11.4177 21.3948 9.84375 19.4531 9.84375C17.5115 9.84375 15.9375 11.4177 15.9375 13.3594C15.9375 15.301 17.5115 16.875 19.4531 16.875Z"
                    fill="white" />
                    <path
                    d="M18.5156 15C19.4217 15 20.1562 14.2655 20.1562 13.3594C20.1562 12.4533 19.4217 11.7188 18.5156 11.7188C17.6095 11.7188 16.875 12.4533 16.875 13.3594C16.875 14.2655 17.6095 15 18.5156 15Z"
                    fill="#1A1A54" />
                    <path
                    d="M8.20312 16.875C10.1448 16.875 11.7188 15.301 11.7188 13.3594C11.7188 11.4177 10.1448 9.84375 8.20312 9.84375C6.2615 9.84375 4.6875 11.4177 4.6875 13.3594C4.6875 15.301 6.2615 16.875 8.20312 16.875Z"
                    fill="white" />
                    <path
                    d="M7.26562 15C8.17172 15 8.90625 14.2655 8.90625 13.3594C8.90625 12.4533 8.17172 11.7188 7.26562 11.7188C6.35953 11.7188 5.625 12.4533 5.625 13.3594C5.625 14.2655 6.35953 15 7.26562 15Z"
                    fill="#1A1A54" />
                    <path d="M24.8438 8.90625H14.0625V12.6562H24.8438V8.90625Z" fill="#F8DC24" />
                    <path
                    d="M11.2491 21.0469C12.046 20.8594 12.796 20.7656 13.5929 20.7188C14.3897 20.6719 15.1397 20.625 15.9366 20.625C16.7335 20.625 17.4835 20.6719 18.2804 20.7188C19.0772 20.7656 19.8272 20.9062 20.6241 21.0937C20.8585 21.1406 21.046 21.4219 20.9522 21.6562C20.9054 21.8437 20.7647 21.9375 20.6241 21.9844C19.8272 22.1719 19.0772 22.2656 18.2804 22.3594C17.4835 22.4062 16.7335 22.4531 15.9366 22.4531C15.1397 22.4531 14.3897 22.4062 13.5929 22.3594C12.796 22.3125 12.046 22.2187 11.2491 22.0312C10.9679 21.9844 10.8272 21.7031 10.8741 21.4219C10.921 21.2344 11.0616 21.0938 11.2491 21.0469Z"
                    fill="#1A1A54" />
                    <path
                    d="M6.75077 4.125C7.45389 3.89063 8.15702 3.9375 8.86014 4.07812C9.56327 4.21875 10.2664 4.5 10.8758 4.96875C11.4851 5.39063 12.0008 6 12.3289 6.60938C12.7039 7.26563 12.8445 7.96875 12.8445 8.67188C12.8445 8.85938 12.7039 9.04688 12.4695 9.04688C12.3758 9.04688 12.282 9 12.1883 8.90625C11.7664 8.4375 11.4383 7.92188 11.0164 7.54688C10.6414 7.17188 10.2195 6.79688 9.79764 6.51562C8.90702 5.90625 7.92264 5.48438 6.75077 5.01562H6.70389C6.46952 4.92187 6.37577 4.6875 6.46952 4.45312C6.51639 4.26562 6.61014 4.17188 6.75077 4.125Z"
                    fill="#1A1A54" />
                    <path
                    d="M15.9376 9.28125C16.5001 9.04687 17.1094 8.95312 17.7188 8.85937C18.2813 8.76562 18.8907 8.76562 19.5001 8.76562C20.0626 8.76562 20.6719 8.8125 21.2813 8.90625C21.8438 9 22.4532 9.14062 23.0626 9.375C23.2501 9.46875 23.3438 9.65625 23.2501 9.84375C23.2032 9.9375 23.1563 10.0312 23.0626 10.0312C22.5001 10.2656 21.8907 10.4062 21.2813 10.5C20.7188 10.5937 20.1094 10.6406 19.5001 10.6406C18.9376 10.6406 18.3282 10.5937 17.7188 10.5469C17.1563 10.4531 16.5469 10.3594 15.9376 10.125C15.7032 10.0312 15.5626 9.75 15.6563 9.51562C15.7032 9.42187 15.8438 9.32812 15.9376 9.28125Z"
                    fill="#1A1A54" />
                </svg>
                <span class="ml-2">Suspicious</span>`,
        value: "Suspicious"
    },
    {
        index: 34,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#00AF64" />
                    <path
                    d="M23.9062 18.9844C25.8479 18.9844 27.4219 17.4104 27.4219 15.4688C27.4219 13.5271 25.8479 11.9531 23.9062 11.9531C21.9646 11.9531 20.3906 13.5271 20.3906 15.4688C20.3906 17.4104 21.9646 18.9844 23.9062 18.9844Z"
                    fill="white" />
                    <path
                    d="M23.4375 17.1094C24.3436 17.1094 25.0781 16.3748 25.0781 15.4688C25.0781 14.5627 24.3436 13.8281 23.4375 13.8281C22.5314 13.8281 21.7969 14.5627 21.7969 15.4688C21.7969 16.3748 22.5314 17.1094 23.4375 17.1094Z"
                    fill="#1A1A54" />
                    <path
                    d="M12.6562 18.9844C14.5979 18.9844 16.1719 17.4104 16.1719 15.4688C16.1719 13.5271 14.5979 11.9531 12.6562 11.9531C10.7146 11.9531 9.14062 13.5271 9.14062 15.4688C9.14062 17.4104 10.7146 18.9844 12.6562 18.9844Z"
                    fill="white" />
                    <path
                    d="M12.1875 17.1094C13.0936 17.1094 13.8281 16.3748 13.8281 15.4688C13.8281 14.5627 13.0936 13.8281 12.1875 13.8281C11.2814 13.8281 10.5469 14.5627 10.5469 15.4688C10.5469 16.3748 11.2814 17.1094 12.1875 17.1094Z"
                    fill="#1A1A54" />
                    <path
                    d="M21.0469 24.0938C20.5313 24 20.1094 23.9063 19.6875 23.8594C19.2656 23.8125 18.8906 23.7656 18.4688 23.7656C18.0938 23.7656 17.6719 23.8125 17.25 23.8594C16.8281 23.9063 16.4063 24 15.8906 24.0469H15.8438C15.5625 24.0938 15.3281 23.9063 15.2812 23.625C15.2812 23.4844 15.3281 23.3438 15.375 23.25C16.0781 22.4063 17.25 21.8906 18.4219 21.8438C19.6406 21.8438 20.7656 22.3594 21.5156 23.2031C21.7031 23.4375 21.6563 23.7656 21.4688 23.9531C21.375 24.0469 21.2344 24.0938 21.0469 24.0938Z"
                    fill="#1A1A54" />
                    <path
                    d="M27.6562 11.25H8.90625V14.0625C15.1406 14.9062 21.4219 14.9062 27.6562 14.0625V11.25Z"
                    fill="#00AF64" />
                    <path d="M27.6562 17.8125H8.90625V20.625H27.6562V17.8125Z" fill="#00AF64" />
                    <path
                    d="M19.2656 11.5781C19.7344 11.1094 20.2031 10.7344 20.7188 10.4063C21.2344 10.0781 21.75 9.75 22.3125 9.51562C22.875 9.23437 23.4375 9 24 8.8125C24.6094 8.625 25.1719 8.4375 25.8281 8.39062C26.0156 8.39062 26.2031 8.53125 26.25 8.71875C26.25 8.8125 26.2031 8.90625 26.1562 9C25.7344 9.46875 25.2188 9.89062 24.7031 10.2187C24.1875 10.5937 23.6719 10.875 23.1094 11.1562C22.5469 11.4375 21.9844 11.6719 21.4219 11.9062C20.8594 12.0937 20.25 12.2812 19.5937 12.375C19.3594 12.4219 19.125 12.2344 19.0781 11.9531C19.125 11.8125 19.1719 11.6719 19.2656 11.5781Z"
                    fill="#1A1A54" />
                    <path
                    d="M16.9225 12.3281C16.2663 12.2344 15.7038 12.0938 15.0944 11.8594C14.5319 11.6719 13.9694 11.3906 13.4069 11.1094C12.8444 10.8281 12.3288 10.5 11.8131 10.1719C11.2975 9.79688 10.8288 9.42188 10.36 8.95313C10.2194 8.8125 10.2194 8.57813 10.4069 8.4375C10.5006 8.34375 10.5944 8.34375 10.6881 8.34375C11.3444 8.39062 11.9538 8.57813 12.5163 8.76563C13.1256 8.95313 13.6881 9.1875 14.2038 9.46875C14.7663 9.75 15.2819 10.0313 15.7975 10.3594C16.3131 10.6875 16.8288 11.0625 17.2506 11.5313C17.4381 11.7188 17.4381 12 17.2506 12.1875C17.2038 12.3281 17.0631 12.3281 16.9225 12.3281Z"
                    fill="#1A1A54" />
                </svg>
                <span class="ml-2">Envious</span>`,
        value: "Envious"
    },
    {
        index: 35,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M20.3906 17.8125C22.5911 17.8125 24.375 16.0286 24.375 13.8281C24.375 11.6276 22.5911 9.84375 20.3906 9.84375C18.1901 9.84375 16.4062 11.6276 16.4062 13.8281C16.4062 16.0286 18.1901 17.8125 20.3906 17.8125Z"
                    fill="white" />
                    <path
                    d="M20.3906 15.9375C21.5556 15.9375 22.5 14.9931 22.5 13.8281C22.5 12.6631 21.5556 11.7188 20.3906 11.7188C19.2256 11.7188 18.2812 12.6631 18.2812 13.8281C18.2812 14.9931 19.2256 15.9375 20.3906 15.9375Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.60938 17.8125C11.8099 17.8125 13.5938 16.0286 13.5938 13.8281C13.5938 11.6276 11.8099 9.84375 9.60938 9.84375C7.40887 9.84375 5.625 11.6276 5.625 13.8281C5.625 16.0286 7.40887 17.8125 9.60938 17.8125Z"
                    fill="white" />
                    <path
                    d="M9.60938 15.9375C10.7744 15.9375 11.7188 14.9931 11.7188 13.8281C11.7188 12.6631 10.7744 11.7188 9.60938 11.7188C8.4444 11.7188 7.5 12.6631 7.5 13.8281C7.5 14.9931 8.4444 15.9375 9.60938 15.9375Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.60985 5.29678C9.56297 5.90616 9.37547 6.42178 9.09422 6.93741C8.81297 7.45303 8.43797 7.87491 7.96922 8.24991C7.50047 8.62491 6.98485 8.90616 6.42235 9.04678C5.85985 9.18741 5.25047 9.18741 4.68797 9.09366C4.54735 9.04678 4.4536 8.90616 4.50047 8.76553C4.50047 8.67178 4.59422 8.62491 4.6411 8.57803H4.68797C5.15672 8.34366 5.5786 8.20303 6.00047 7.92178C6.37547 7.68741 6.75047 7.45303 7.0786 7.17178C7.40672 6.89053 7.73485 6.56241 8.06297 6.23428C8.3911 5.90616 8.67235 5.48428 9.00047 5.10928L9.04735 5.06241C9.1411 4.92178 9.37547 4.92178 9.5161 5.01553C9.60985 5.06241 9.65672 5.20303 9.60985 5.29678Z"
                    fill="#1A1A54" />
                    <path
                    d="M21 5.10937C21.3281 5.48437 21.6094 5.85938 21.9375 6.23438C22.2656 6.5625 22.5469 6.89063 22.9219 7.17188C23.25 7.45313 23.625 7.73438 24 7.92188C24.375 8.15625 24.8437 8.34375 25.3125 8.57812H25.3594C25.5 8.625 25.5469 8.8125 25.5 8.95313C25.4531 9.04688 25.4062 9.09375 25.3125 9.09375C24.75 9.23438 24.1875 9.23438 23.5781 9.04688C23.0156 8.90625 22.4531 8.625 22.0312 8.25C21.5625 7.875 21.1875 7.40625 20.9062 6.9375C20.625 6.42188 20.4375 5.90625 20.3906 5.29688C20.3906 5.10938 20.5313 4.96875 20.7188 4.96875C20.7656 4.92188 20.9062 4.96875 21 5.10937Z"
                    fill="#1A1A54" />
                    <path
                    d="M8.4375 23.0156C8.4375 24 9.23437 24.8438 10.2656 24.8438H10.5469C11.0156 24.8438 11.4844 24.6563 11.8125 24.3281L13.4063 22.7812C14.2969 21.8906 15.75 21.8906 16.6406 22.7812L18.2344 24.3281C18.5625 24.6563 19.0312 24.8438 19.5 24.8438H19.7813C20.7656 24.8438 21.6094 24.0469 21.6094 23.0156C21.6094 22.125 21 21.375 20.1563 21.2344C16.7813 20.5781 13.3594 20.5781 9.98437 21.2344C9.04687 21.4219 8.4375 22.1719 8.4375 23.0156Z"
                    fill="#1A1A54" />
                </svg>

                <span class="ml-2">Scared</span>`,
        value: "Scared"
    },
    {
        index: 36,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#1A1A54" />
                    <path
                    d="M20.8594 16.875C22.801 16.875 24.375 15.301 24.375 13.3594C24.375 11.4177 22.801 9.84375 20.8594 9.84375C18.9177 9.84375 17.3438 11.4177 17.3438 13.3594C17.3438 15.301 18.9177 16.875 20.8594 16.875Z"
                    fill="white" />
                    <path
                    d="M20.8594 15C21.7655 15 22.5 14.2655 22.5 13.3594C22.5 12.4533 21.7655 11.7188 20.8594 11.7188C19.9533 11.7188 19.2188 12.4533 19.2188 13.3594C19.2188 14.2655 19.9533 15 20.8594 15Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.14062 16.875C11.0823 16.875 12.6562 15.301 12.6562 13.3594C12.6562 11.4177 11.0823 9.84375 9.14062 9.84375C7.199 9.84375 5.625 11.4177 5.625 13.3594C5.625 15.301 7.199 16.875 9.14062 16.875Z"
                    fill="white" />
                    <path
                    d="M9.14062 15C10.0467 15 10.7812 14.2655 10.7812 13.3594C10.7812 12.4533 10.0467 11.7188 9.14062 11.7188C8.23453 11.7188 7.5 12.4533 7.5 13.3594C7.5 14.2655 8.23453 15 9.14062 15Z"
                    fill="#1A1A54" />
                </svg>
                <span class="ml-2">Incognito</span>`,
        value: "Incognito"
    },
    {
        index: 37,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M20.3906 17.8125C22.5911 17.8125 24.375 16.0286 24.375 13.8281C24.375 11.6276 22.5911 9.84375 20.3906 9.84375C18.1901 9.84375 16.4062 11.6276 16.4062 13.8281C16.4062 16.0286 18.1901 17.8125 20.3906 17.8125Z"
                    fill="white" />
                    <path
                    d="M20.3906 15.9375C21.5556 15.9375 22.5 14.9931 22.5 13.8281C22.5 12.6631 21.5556 11.7188 20.3906 11.7188C19.2256 11.7188 18.2812 12.6631 18.2812 13.8281C18.2812 14.9931 19.2256 15.9375 20.3906 15.9375Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.60938 17.8125C11.8099 17.8125 13.5938 16.0286 13.5938 13.8281C13.5938 11.6276 11.8099 9.84375 9.60938 9.84375C7.40887 9.84375 5.625 11.6276 5.625 13.8281C5.625 16.0286 7.40887 17.8125 9.60938 17.8125Z"
                    fill="white" />
                    <path
                    d="M9.60938 15.9375C10.7744 15.9375 11.7188 14.9931 11.7188 13.8281C11.7188 12.6631 10.7744 11.7188 9.60938 11.7188C8.4444 11.7188 7.5 12.6631 7.5 13.8281C7.5 14.9931 8.4444 15.9375 9.60938 15.9375Z"
                    fill="#1A1A54" />
                    <path
                    d="M22.8744 21.9375C22.1244 21.1875 22.0307 20.0156 22.6869 19.1719L24.3275 17.0156L25.9682 19.1719C26.6244 20.0156 26.5307 21.1875 25.7807 21.9375C25.0307 22.7813 23.7182 22.7813 22.8744 21.9375Z"
                    fill="#0B85FF" />
                    <path
                    d="M20.2974 23.7656C19.3599 23.5781 18.4692 23.3437 17.5786 23.2031C16.688 23.0625 15.8442 22.9687 14.9536 22.9687C14.063 22.9687 13.2192 23.0625 12.3286 23.1562C11.438 23.2969 10.5474 23.4844 9.60985 23.6719C9.28172 23.7187 8.9536 23.5312 8.90672 23.2031C8.85985 22.9687 8.9536 22.7812 9.09422 22.6406C9.8911 21.9844 10.8286 21.4687 11.813 21.0937C12.7974 20.7187 13.8755 20.5781 14.9536 20.5781C16.0317 20.5781 17.1098 20.7656 18.0942 21.0937C19.0786 21.4687 20.0161 21.9375 20.8599 22.5937C21.1411 22.8281 21.188 23.2031 20.9536 23.4844C20.7661 23.7656 20.5317 23.8125 20.2974 23.7656Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.60985 6.23428C9.5161 6.79678 9.28172 7.26553 8.9536 7.64053C8.62547 8.06241 8.2036 8.39053 7.73485 8.62491C7.2661 8.85928 6.79735 9.04678 6.28172 9.09366C6.04735 9.14053 5.7661 9.14053 5.53172 9.14053C5.29735 9.14053 5.0161 9.09366 4.78172 8.99991C4.6411 8.95303 4.54735 8.81241 4.59422 8.67178C4.59422 8.57803 4.68797 8.53116 4.73485 8.48428C5.15672 8.24991 5.5786 8.10928 5.9536 7.92178C6.3286 7.78116 6.7036 7.54678 7.03172 7.35928C7.35985 7.17178 7.73485 6.93741 8.06298 6.70303C8.3911 6.46866 8.71922 6.18741 9.04735 5.90616L9.09422 5.85928C9.23485 5.76553 9.42235 5.76553 9.56297 5.90616C9.60985 6.04678 9.60985 6.18741 9.60985 6.23428Z"
                    fill="#1A1A54" />
                    <path
                    d="M20.9524 6C21.2806 6.28125 21.6087 6.5625 21.9368 6.79688C22.2649 7.03125 22.5931 7.26563 22.9681 7.45313C23.3431 7.64063 23.6712 7.82813 24.0462 8.01563C24.4212 8.20313 24.8431 8.34375 25.2649 8.57812C25.4056 8.625 25.4524 8.8125 25.4056 8.95313C25.3587 9.04688 25.3118 9.09375 25.2181 9.09375C24.9837 9.14063 24.7024 9.1875 24.4681 9.23438C24.2337 9.23438 23.9524 9.23438 23.7181 9.1875C23.2024 9.14063 22.6868 8.95313 22.2649 8.71875C21.7962 8.48438 21.3743 8.10938 21.0462 7.73438C20.7181 7.3125 20.4837 6.89063 20.3899 6.32813C20.3431 6.14063 20.4837 6 20.6712 5.95312C20.7649 5.95312 20.8587 5.95312 20.9524 6.04687V6Z"
                    fill="#1A1A54" />
                </svg>

                <span class="ml-2">Crying</span>`,
        value: "Crying"
    },
    {
        index: 38,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M20.8594 16.875C22.801 16.875 24.375 15.301 24.375 13.3594C24.375 11.4177 22.801 9.84375 20.8594 9.84375C18.9177 9.84375 17.3438 11.4177 17.3438 13.3594C17.3438 15.301 18.9177 16.875 20.8594 16.875Z"
                    fill="white" />
                    <path
                    d="M20.8594 15.4688C22.0244 15.4688 22.9688 14.5244 22.9688 13.3594C22.9688 12.1944 22.0244 11.25 20.8594 11.25C19.6944 11.25 18.75 12.1944 18.75 13.3594C18.75 14.5244 19.6944 15.4688 20.8594 15.4688Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.14062 16.875C11.0823 16.875 12.6562 15.301 12.6562 13.3594C12.6562 11.4177 11.0823 9.84375 9.14062 9.84375C7.199 9.84375 5.625 11.4177 5.625 13.3594C5.625 15.301 7.199 16.875 9.14062 16.875Z"
                    fill="white" />
                    <path
                    d="M9.14062 15.4688C10.3056 15.4688 11.25 14.5244 11.25 13.3594C11.25 12.1944 10.3056 11.25 9.14062 11.25C7.97565 11.25 7.03125 12.1944 7.03125 13.3594C7.03125 14.5244 7.97565 15.4688 9.14062 15.4688Z"
                    fill="#1A1A54" />
                    <path d="M20.625 20.625H8.90625V24.375H20.625V20.625Z" fill="#1A1A54" />
                    <path
                    d="M9.375 24.375H8.90625C7.875 24.375 7.03125 23.5312 7.03125 22.5C7.03125 21.4688 7.875 20.625 8.90625 20.625H9.375V24.375Z"
                    fill="#1A1A54" />
                    <path d="M10.7812 20.625H9.375V22.5H10.7812V20.625Z" fill="white" />
                    <path d="M12.1875 22.5H10.7812V24.375H12.1875V22.5Z" fill="white" />
                    <path d="M13.5938 20.625H12.1875V22.5H13.5938V20.625Z" fill="white" />
                    <path d="M15 22.5H13.5938V24.375H15V22.5Z" fill="white" />
                    <path d="M16.4062 20.625H15V22.5H16.4062V20.625Z" fill="white" />
                    <path d="M17.8125 22.5H16.4062V24.375H17.8125V22.5Z" fill="white" />
                    <path d="M19.2188 20.625H17.8125V22.5H19.2188V20.625Z" fill="white" />
                    <path opacity="0.2" d="M10.7812 20.625H9.375V22.5H10.7812V20.625Z" fill="#1A1A54" />
                    <path opacity="0.2" d="M12.1875 22.5H10.7812V24.375H12.1875V22.5Z" fill="#1A1A54" />
                    <path opacity="0.2" d="M13.5938 20.625H12.1875V22.5H13.5938V20.625Z"
                    fill="#1A1A54" />
                    <path opacity="0.2" d="M15 22.5H13.5938V24.375H15V22.5Z" fill="#1A1A54" />
                    <path opacity="0.2" d="M16.4062 20.625H15V22.5H16.4062V20.625Z" fill="#1A1A54" />
                    <path opacity="0.2" d="M17.8125 22.5H16.4062V24.375H17.8125V22.5Z" fill="#1A1A54" />
                    <path d="M20.625 22.5H19.2188V24.375H20.625V22.5Z" fill="white" />
                    <path opacity="0.2" d="M20.625 22.5H19.2188V24.375H20.625V22.5Z" fill="#1A1A54" />
                    <path opacity="0.2" d="M19.2188 20.625H17.8125V22.5H19.2188V20.625Z"
                    fill="#1A1A54" />
                    <path
                    d="M25.8759 24.5156C26.7196 25.3594 26.7196 26.6719 25.8759 27.5156C25.0321 28.3594 23.7196 28.3594 22.8759 27.5156C22.0321 26.6719 19.8759 23.2031 20.7196 22.3594C21.5634 21.5625 25.079 23.7188 25.8759 24.5156Z"
                    fill="#1A1A54" />
                    <path
                    d="M24.375 26.9531C24.8928 26.9531 25.3125 26.5334 25.3125 26.0156C25.3125 25.4979 24.8928 25.0781 24.375 25.0781C23.8572 25.0781 23.4375 25.4979 23.4375 26.0156C23.4375 26.5334 23.8572 26.9531 24.375 26.9531Z"
                    fill="#FFBF0B" />
                </svg>
                <span class="ml-2">Speechless</span>`,
        value: "Speechless"
    },
    {
        index: 39,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#F8DC24" />
                    <path
                    d="M20.8594 16.875C22.801 16.875 24.375 15.301 24.375 13.3594C24.375 11.4177 22.801 9.84375 20.8594 9.84375C18.9177 9.84375 17.3438 11.4177 17.3438 13.3594C17.3438 15.301 18.9177 16.875 20.8594 16.875Z"
                    fill="white" />
                    <path
                    d="M20.8594 15.4688C22.0244 15.4688 22.9688 14.5244 22.9688 13.3594C22.9688 12.1944 22.0244 11.25 20.8594 11.25C19.6944 11.25 18.75 12.1944 18.75 13.3594C18.75 14.5244 19.6944 15.4688 20.8594 15.4688Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.14062 16.875C11.0823 16.875 12.6562 15.301 12.6562 13.3594C12.6562 11.4177 11.0823 9.84375 9.14062 9.84375C7.199 9.84375 5.625 11.4177 5.625 13.3594C5.625 15.301 7.199 16.875 9.14062 16.875Z"
                    fill="white" />
                    <path
                    d="M9.14062 15.4688C10.3056 15.4688 11.25 14.5244 11.25 13.3594C11.25 12.1944 10.3056 11.25 9.14062 11.25C7.97565 11.25 7.03125 12.1944 7.03125 13.3594C7.03125 14.5244 7.97565 15.4688 9.14062 15.4688Z"
                    fill="#1A1A54" />
                    <path
                    d="M24.375 18.75H5.625C4.59375 18.75 3.75 19.5938 3.75 20.625V25.7812C3.75 26.8125 4.59375 27.6562 5.625 27.6562H24.375C25.4062 27.6562 26.25 26.8125 26.25 25.7812V20.625C26.25 19.5938 25.4062 18.75 24.375 18.75Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.56273 22.6875L8.62523 22.875L9.2346 23.5313C9.37523 23.6719 9.32835 23.8594 9.18773 24L8.6721 24.375C8.53148 24.4688 8.2971 24.4219 8.25023 24.2812L7.82835 23.4375L7.40648 24.2812C7.31273 24.4219 7.12523 24.4688 6.9846 24.375L6.46898 24C6.32835 23.9063 6.28148 23.6719 6.4221 23.5313L7.07835 22.875L6.18773 22.7344C6.00023 22.6875 5.90648 22.5 5.95335 22.3594L6.14085 21.75C6.18773 21.5625 6.37523 21.5156 6.56273 21.5625L7.40648 21.9844L7.26585 21.0469C7.1721 20.7656 7.31273 20.625 7.50023 20.625H8.1096C8.2971 20.625 8.43773 20.7656 8.39085 20.9531L8.25023 21.8906L9.14085 21.4687C9.28148 21.375 9.51585 21.4688 9.56273 21.6563L9.75023 22.2656C9.84398 22.4531 9.75023 22.6406 9.56273 22.6875Z"
                    fill="white" />
                    <path
                    d="M14.2969 24.8438H12.8906C11.7188 24.8438 10.7812 23.9062 10.7812 22.7344C10.7812 21.5625 11.7188 20.625 12.8906 20.625H14.2969C15.4688 20.625 16.4062 21.5625 16.4062 22.7344C16.4062 23.9062 15.4688 24.8438 14.2969 24.8438Z"
                    fill="white" />
                    <path d="M13.5938 25.3125V23.4375" stroke="white" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" />
                    <path d="M12.1875 25.3125V23.4375" stroke="white" stroke-width="2"
                    stroke-miterlimit="10" stroke-linecap="round" />
                    <path d="M15 25.3125V23.4375" stroke="white" stroke-width="2" stroke-miterlimit="10"
                    stroke-linecap="round" />
                    <path
                    d="M12.4219 22.9688C12.8102 22.9688 13.125 22.654 13.125 22.2656C13.125 21.8773 12.8102 21.5625 12.4219 21.5625C12.0335 21.5625 11.7188 21.8773 11.7188 22.2656C11.7188 22.654 12.0335 22.9688 12.4219 22.9688Z"
                    fill="#1A1A54" />
                    <path
                    d="M14.7656 22.9688C15.154 22.9688 15.4688 22.654 15.4688 22.2656C15.4688 21.8773 15.154 21.5625 14.7656 21.5625C14.3773 21.5625 14.0625 21.8773 14.0625 22.2656C14.0625 22.654 14.3773 22.9688 14.7656 22.9688Z"
                    fill="#1A1A54" />
                    <path
                    d="M14.0632 24.375H13.1257C13.032 24.375 12.9382 24.2812 12.9851 24.1875L13.4539 23.25C13.5007 23.1563 13.6414 23.1563 13.6882 23.25L14.157 24.1875C14.2507 24.2812 14.157 24.375 14.0632 24.375Z"
                    fill="#1A1A54" />
                    <path
                    d="M19.2191 21.5628L18.7503 23.9066C18.7035 24.1878 18.4222 24.3285 18.1878 24.2816C18.0003 24.2347 17.8597 24.0941 17.8128 23.9066L17.3441 21.5628C17.2503 21.0472 17.5785 20.5316 18.0941 20.4378C18.6097 20.3441 19.1253 20.6722 19.2191 21.1878C19.266 21.2816 19.266 21.4222 19.2191 21.5628Z"
                    fill="white" />
                    <path
                    d="M18.2812 24.8438C18 24.8438 17.8125 25.0312 17.8125 25.3125C17.8125 25.5938 18 25.7812 18.2812 25.7812C18.5625 25.7812 18.75 25.5938 18.75 25.3125C18.75 25.0312 18.5625 24.8438 18.2812 24.8438Z"
                    fill="white" />
                    <path
                    d="M22.7812 20.8124L21.6563 23.3437L21.0938 22.2656H23.4375H23.5313C23.9063 22.2656 24.1875 22.5468 24.1875 22.9218C24.1875 23.1093 24.1406 23.2499 24 23.3906L21.7969 25.7343C21.6563 25.8749 21.4688 25.8749 21.3281 25.7343C21.2344 25.6406 21.1875 25.4531 21.2813 25.3593L22.8281 22.5468L23.3906 23.6718H21.0469H20.9531C20.5781 23.6718 20.2969 23.3906 20.2969 23.0156C20.2969 22.8749 20.3437 22.7343 20.4375 22.5937L22.125 20.4374C22.2656 20.2968 22.4531 20.2499 22.6406 20.3906C22.8281 20.4843 22.875 20.6718 22.7812 20.8124Z"
                    fill="white" />
                </svg>
                <span class="ml-2">Swear</span>`,
        value: "Swear"
    },
    {
        index: 40,
        html: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
                    fill="#DC2863" />
                    <path
                    d="M24.8438 13.3594C24.8438 15.2812 23.25 16.875 21.3281 16.875C19.4062 16.875 17.8125 15.2812 17.8125 13.3594"
                    fill="white" />
                    <path
                    d="M23.4375 13.3594C23.4375 14.5312 22.5 15.4688 21.3281 15.4688C20.1562 15.4688 19.2188 14.5312 19.2188 13.3594"
                    fill="#1A1A54" />
                    <path
                    d="M17.5786 12.8436C18.2349 12.5623 18.8911 12.4686 19.5474 12.3748C20.2036 12.2811 20.8599 12.2342 21.5161 12.2811C22.1724 12.2811 22.8286 12.328 23.4849 12.4217C24.1411 12.5155 24.7974 12.6561 25.4536 12.9373C25.688 13.0311 25.7817 13.2655 25.688 13.4998C25.6411 13.5936 25.5474 13.6873 25.4536 13.7342C24.7974 14.0155 24.1411 14.1561 23.4849 14.2498C22.8286 14.3436 22.1724 14.3905 21.5161 14.3905C20.8599 14.3905 20.2036 14.3436 19.5474 14.2967C18.8911 14.203 18.2349 14.0623 17.5786 13.828C17.2974 13.7342 17.1567 13.4061 17.2974 13.1248C17.3442 13.0311 17.438 12.9373 17.5786 12.8436Z"
                    fill="#1A1A54" />
                    <path
                    d="M5.15625 13.3594C5.15625 15.2812 6.75 16.875 8.67188 16.875C10.5938 16.875 12.1875 15.2812 12.1875 13.3594"
                    fill="white" />
                    <path
                    d="M6.5625 13.3594C6.5625 14.5312 7.5 15.4688 8.67188 15.4688C9.84375 15.4688 10.7812 14.5312 10.7812 13.3594"
                    fill="#1A1A54" />
                    <path
                    d="M12.4231 13.875C11.7669 14.1562 11.1106 14.25 10.4544 14.3437C9.79813 14.4375 9.14188 14.4844 8.48563 14.4375C7.82938 14.4375 7.17313 14.3906 6.51688 14.2969C5.86063 14.2031 5.20438 14.0625 4.54813 13.7812C4.22 13.6875 4.12625 13.4062 4.22 13.1719C4.26688 13.0781 4.36063 12.9844 4.45438 12.9375C5.11063 12.6562 5.76688 12.5156 6.42313 12.4219C7.07938 12.3281 7.73563 12.2812 8.39188 12.2812C9.04813 12.2812 9.70438 12.3281 10.3606 12.375C11.0169 12.4687 11.6731 12.6094 12.3294 12.8437C12.6106 12.9375 12.7513 13.2656 12.6106 13.5469C12.6575 13.6875 12.5638 13.7812 12.4231 13.875Z"
                    fill="#1A1A54" />
                    <path
                    d="M9.75014 22.1719C10.5939 21.7031 11.4376 21.4688 12.3283 21.2813C13.2189 21.0938 14.1095 21.0469 15.0001 21.0469C15.8908 21.0469 16.7814 21.1406 17.672 21.3281C18.5626 21.5156 19.4533 21.7969 20.2501 22.2656C20.4376 22.3594 20.5314 22.6406 20.3908 22.8281C20.3439 22.9688 20.2033 23.0156 20.0626 23.0156C19.172 23.1094 18.3283 23.1563 17.5314 23.1563C16.6876 23.2031 15.8439 23.2031 15.0001 23.2031C14.1564 23.2031 13.3126 23.2031 12.5158 23.2031C11.672 23.2031 10.8283 23.1562 9.98452 23.1094C9.70327 23.1094 9.46889 22.8281 9.51577 22.5469C9.51577 22.4062 9.60952 22.2656 9.75014 22.1719Z"
                    fill="#1A1A54" />
                    <path
                    d="M27.516 2.8125C27.3285 3.28125 27.0473 3.65625 26.8129 4.03125C26.5317 4.40625 26.2504 4.78125 25.9223 5.10937V4.125C26.2504 4.45312 26.5317 4.78125 26.8129 5.10937C27.0942 5.4375 27.3754 5.8125 27.5629 6.23438C27.6567 6.42188 27.6098 6.65625 27.4223 6.75C27.2817 6.79688 27.1879 6.79688 27.0473 6.75C26.6254 6.51562 26.2973 6.28125 25.9223 6C25.5942 5.71875 25.266 5.4375 24.9379 5.10937C24.6567 4.82812 24.6566 4.40625 24.891 4.17187C25.2191 3.84375 25.5473 3.51562 25.8754 3.1875C26.2504 2.85938 26.5785 2.57812 27.0473 2.34375C27.2348 2.25 27.4692 2.29688 27.5629 2.48438C27.5629 2.57813 27.5629 2.71875 27.516 2.8125Z"
                    fill="#1A1A54" />
                    <path
                    d="M20.3438 6.32812C20.5313 5.85938 20.8125 5.48437 21.0469 5.10937C21.3281 4.73437 21.6094 4.35938 21.9375 4.03125V5.01562C21.6562 4.6875 21.375 4.35938 21.0937 3.98438C20.8125 3.65625 20.5313 3.28125 20.3438 2.85938C20.25 2.67187 20.2969 2.4375 20.4844 2.34375C20.625 2.29688 20.7187 2.29688 20.8594 2.34375C21.2812 2.57812 21.6094 2.8125 21.9844 3.09375C22.3125 3.375 22.6406 3.65625 22.9687 3.98438C23.25 4.26562 23.25 4.6875 23.0156 4.92188C22.6875 5.25 22.3594 5.57812 22.0312 5.90625C21.6562 6.23438 21.3281 6.51562 20.8594 6.75C20.6719 6.84375 20.4375 6.79688 20.3438 6.60938C20.2969 6.51562 20.2969 6.42188 20.3438 6.32812Z"
                    fill="#1A1A54" />
                    <path
                    d="M25.6875 8.15665C25.2187 7.96915 24.8438 7.6879 24.4688 7.45353C24.0938 7.17228 23.7188 6.89103 23.3906 6.5629H24.375C24.0469 6.89103 23.7188 7.17228 23.3906 7.45353C23.0625 7.73478 22.6875 8.01603 22.2656 8.20353C22.0781 8.29728 21.8438 8.2504 21.75 8.0629C21.7031 7.92228 21.7031 7.82853 21.75 7.6879C21.9844 7.26603 22.2187 6.9379 22.5 6.5629C22.7812 6.23478 23.0625 5.90665 23.3906 5.57852C23.6719 5.29727 24.0938 5.29728 24.3281 5.53165C24.6563 5.85978 24.9844 6.1879 25.3125 6.51602C25.6406 6.89103 25.9219 7.21915 26.1562 7.6879C26.25 7.8754 26.2031 8.10978 26.0156 8.20353C25.875 8.20353 25.7812 8.20353 25.6875 8.15665Z"
                    fill="#1A1A54" />
                    <path
                    d="M22.1719 0.98468C22.6406 1.17218 23.0156 1.45343 23.3906 1.68781C23.7656 1.96906 24.1406 2.25031 24.4688 2.57843H23.4844C23.8125 2.25031 24.1406 1.96906 24.4688 1.68781C24.7969 1.40656 25.1719 1.12531 25.5937 0.937805C25.7812 0.89093 26.0156 0.937805 26.1094 1.12531C26.1562 1.26593 26.1562 1.35968 26.1094 1.50031C25.875 1.92218 25.6406 2.25031 25.3594 2.62531C25.0781 2.95343 24.7969 3.28156 24.4688 3.60968C24.1875 3.89093 23.7656 3.89093 23.5313 3.65656C23.2031 3.32843 22.875 3.00031 22.5469 2.67218C22.2187 2.29718 21.9375 1.96906 21.7031 1.50031C21.6094 1.31281 21.6562 1.07843 21.8437 0.98468C21.9375 0.937805 22.0781 0.937805 22.1719 0.98468Z"
                    fill="#1A1A54" />
                </svg>

                <span class="ml-2">Not impressed</span>`,
        value: "Not impressed"
    }
]
