<template>
  <div>
    <div>
      <div v-if="$store.state.friends.friends_suggestion.length">
        <div class="review_request-people">
          <h1>Your friend Suggestion</h1>
          <a href="javascript:;">Review Your Friend Suggestions</a>
        </div>
      </div>
      <div class="wr_content">
        <div
          v-for="(suggested_friend, index) in $store.state.friends
            .friends_suggestion"
          :key="index"
          :class="{ mr_r_0: (index + 1) % 4 === 0 }"
        >
          <div class="frind_list_box Friend_Req_box">
            <div class="friend_profile_image">
              <div
                @click="redirectWidgetsToProfilePage($event,suggested_friend.artwork_url && suggested_friend.username ? suggested_friend.username : '')"
                class="friend-image-wrapper cursor-pointer">
                <img :src="suggested_friend.artwork_url" @error="onProfileImageError($event, getFirstAlphabetOfUserName(suggested_friend))"/>
              </div>
            </div>
            <div class="friend_profile_content">
              <h4
                @click="redirectWidgetsToProfilePage($event,suggested_friend.name && suggested_friend.username ? suggested_friend.username : '')"
                class="friend_profile_name cursor-pointer">{{ suggested_friend.name }}</h4>
              <p class="friend_profile_location">
                <span class="location_map">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="suggested_friend.mututal_friends !== 0"
                  >
                    <path
                      d="M5.25 10.5C5.25 10.5 4.5 10.5 4.5 9.75C4.5 9 5.25 6.75 8.25 6.75C11.25 6.75 12 9 12 9.75C12 10.5 11.25 10.5 11.25 10.5H5.25ZM8.25 6C8.84674 6 9.41903 5.76295 9.84099 5.34099C10.2629 4.91903 10.5 4.34674 10.5 3.75C10.5 3.15326 10.2629 2.58097 9.84099 2.15901C9.41903 1.73705 8.84674 1.5 8.25 1.5C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75C6 4.34674 6.23705 4.91903 6.65901 5.34099C7.08097 5.76295 7.65326 6 8.25 6Z"
                      fill="#47505B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.912 10.4998C3.80082 10.2657 3.74537 10.009 3.75 9.74983C3.75 8.73358 4.26 7.68733 5.202 6.95983C4.73182 6.81496 4.24196 6.74411 3.75 6.74983C0.75 6.74983 0 8.99983 0 9.74983C0 10.4998 0.75 10.4998 0.75 10.4998H3.912Z"
                      fill="#47505B"
                    />
                    <path
                      d="M3.375 6C3.87228 6 4.34919 5.80246 4.70083 5.45083C5.05246 5.09919 5.25 4.62228 5.25 4.125C5.25 3.62772 5.05246 3.15081 4.70083 2.79917C4.34919 2.44754 3.87228 2.25 3.375 2.25C2.87772 2.25 2.40081 2.44754 2.04917 2.79917C1.69754 3.15081 1.5 3.62772 1.5 4.125C1.5 4.62228 1.69754 5.09919 2.04917 5.45083C2.40081 5.80246 2.87772 6 3.375 6Z"
                      fill="#47505B"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="suggested_friend.mututal_friends === 0"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                      fill="#47505B"
                    />
                  </svg>
                </span>
                <span v-if="suggested_friend.mututal_friends !== 0"
                  >{{ suggested_friend.mututal_friends }} mutual friends</span
                >
                <span
                  class="mr-0"
                  v-if="
                    suggested_friend.mututal_friends === 0 &&
                    suggested_friend.current_city
                  "
                  >{{ suggested_friend.current_city.split(' ')[0] }}, </span
                ><span
                  class="ml-0"
                  v-if="
                    suggested_friend.country.length &&
                    suggested_friend.mututal_friends === 0
                  "
                  >{{ suggested_friend.country[0].name.slice(0, 10) + '...' }}</span
                >
              </p>
            </div>
            <div class="Friend_Req_btn">
              <div
                class="btn btn-accept"
                v-if="
                  suggested_friend.friend_status !== null &&
                  suggested_friend.friend_status.status === 'pending'
                "
              >
                Request Sent
              </div>
              <div v-else>
                <div
                  class="btn btn-accept"
                  @click="addFriendApi(suggested_friend)"
                >
                  Add Friend
                </div>
                <div
                  class="btn btn-delete"
                  @click="removeSuggestedFriend(suggested_friend)"
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wr_content">
        <div
          v-for="(suggested_friend, index) in $store.state.friends
            .explore_friend_list"
          :key="index"
          :class="{ mr_r_0: (index + 1) % 4 === 0 }"
        >
          <div class="frind_list_box Friend_Req_box">
            <div class="friend_profile_image">
              <div class="friend-image-wrapper">
                <img :src="suggested_friend.artwork_url" />
              </div>
            </div>
            <div class="friend_profile_content">
              <h4 class="friend_profile_name">{{ suggested_friend.name }}</h4>
              <p class="friend_profile_location">
                <span class="location_map">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="suggested_friend.mututal_friends !== 0"
                  >
                    <path
                      d="M5.25 10.5C5.25 10.5 4.5 10.5 4.5 9.75C4.5 9 5.25 6.75 8.25 6.75C11.25 6.75 12 9 12 9.75C12 10.5 11.25 10.5 11.25 10.5H5.25ZM8.25 6C8.84674 6 9.41903 5.76295 9.84099 5.34099C10.2629 4.91903 10.5 4.34674 10.5 3.75C10.5 3.15326 10.2629 2.58097 9.84099 2.15901C9.41903 1.73705 8.84674 1.5 8.25 1.5C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75C6 4.34674 6.23705 4.91903 6.65901 5.34099C7.08097 5.76295 7.65326 6 8.25 6Z"
                      fill="#47505B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.912 10.4998C3.80082 10.2657 3.74537 10.009 3.75 9.74983C3.75 8.73358 4.26 7.68733 5.202 6.95983C4.73182 6.81496 4.24196 6.74411 3.75 6.74983C0.75 6.74983 0 8.99983 0 9.74983C0 10.4998 0.75 10.4998 0.75 10.4998H3.912Z"
                      fill="#47505B"
                    />
                    <path
                      d="M3.375 6C3.87228 6 4.34919 5.80246 4.70083 5.45083C5.05246 5.09919 5.25 4.62228 5.25 4.125C5.25 3.62772 5.05246 3.15081 4.70083 2.79917C4.34919 2.44754 3.87228 2.25 3.375 2.25C2.87772 2.25 2.40081 2.44754 2.04917 2.79917C1.69754 3.15081 1.5 3.62772 1.5 4.125C1.5 4.62228 1.69754 5.09919 2.04917 5.45083C2.40081 5.80246 2.87772 6 3.375 6Z"
                      fill="#47505B"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="suggested_friend.mututal_friends === 0"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                      fill="#47505B"
                    />
                  </svg>
                </span>
                <span v-if="suggested_friend.mututal_friends !== 0"
                  >{{ suggested_friend.mututal_friends }} mutual friends</span
                >
                <span
                  class="mr-0"
                  v-if="
                    suggested_friend.mututal_friends === 0 &&
                    suggested_friend.current_city
                  "
                  >{{ suggested_friend.current_city }}, </span
                ><span
                  class="ml-0"
                  v-if="
                    suggested_friend.country.length &&
                    suggested_friend.mututal_friends === 0
                  "
                  >{{ suggested_friend.country[0].name }}</span
                >
              </p>
            </div>
            <div class="Friend_Req_btn">
              <div
                class="btn btn-accept"
                v-if="
                  suggested_friend.friend_status !== null &&
                  suggested_friend.friend_status.status === 'pending'
                "
              >
                Request Sent
              </div>
              <div v-else>
                <div
                  class="btn btn-accept"
                  @click="addFriendApi(suggested_friend)"
                >
                  Add Friend
                </div>
                <div
                  class="btn btn-delete"
                  @click="removeSuggestedFriend(suggested_friend)"
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wr_content">
        <div
          v-for="(filtered_friend, index) in $store.state.friends
            .filtered_friends_list"
          :key="index"
          :class="{ mr_r_0: (index + 1) % 4 === 0 }"
        >
          <div class="frind_list_box Friend_Req_box">
            <div class="friend_profile_image">
              <div class="friend-image-wrapper">
                <img :src="filtered_friend.artwork_url" />
              </div>
            </div>
            <div class="friend_profile_content">
              <h4 class="friend_profile_name">{{ filtered_friend.name }}</h4>
              <p class="friend_profile_location">
                <span class="location_map">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="filtered_friend.mututal_friends !== 0"
                  >
                    <path
                      d="M5.25 10.5C5.25 10.5 4.5 10.5 4.5 9.75C4.5 9 5.25 6.75 8.25 6.75C11.25 6.75 12 9 12 9.75C12 10.5 11.25 10.5 11.25 10.5H5.25ZM8.25 6C8.84674 6 9.41903 5.76295 9.84099 5.34099C10.2629 4.91903 10.5 4.34674 10.5 3.75C10.5 3.15326 10.2629 2.58097 9.84099 2.15901C9.41903 1.73705 8.84674 1.5 8.25 1.5C7.65326 1.5 7.08097 1.73705 6.65901 2.15901C6.23705 2.58097 6 3.15326 6 3.75C6 4.34674 6.23705 4.91903 6.65901 5.34099C7.08097 5.76295 7.65326 6 8.25 6Z"
                      fill="#47505B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.912 10.4998C3.80082 10.2657 3.74537 10.009 3.75 9.74983C3.75 8.73358 4.26 7.68733 5.202 6.95983C4.73182 6.81496 4.24196 6.74411 3.75 6.74983C0.75 6.74983 0 8.99983 0 9.74983C0 10.4998 0.75 10.4998 0.75 10.4998H3.912Z"
                      fill="#47505B"
                    />
                    <path
                      d="M3.375 6C3.87228 6 4.34919 5.80246 4.70083 5.45083C5.05246 5.09919 5.25 4.62228 5.25 4.125C5.25 3.62772 5.05246 3.15081 4.70083 2.79917C4.34919 2.44754 3.87228 2.25 3.375 2.25C2.87772 2.25 2.40081 2.44754 2.04917 2.79917C1.69754 3.15081 1.5 3.62772 1.5 4.125C1.5 4.62228 1.69754 5.09919 2.04917 5.45083C2.40081 5.80246 2.87772 6 3.375 6Z"
                      fill="#47505B"
                    />
                  </svg>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="filtered_friend.mututal_friends === 0"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 11.5C5 11.5 9.5 8.49997 9.5 4.99997C9.5 3.8065 9.02589 2.6619 8.18198 1.81799C7.33807 0.974075 6.19347 0.499969 5 0.499969C3.80653 0.499969 2.66193 0.974075 1.81802 1.81799C0.974106 2.6619 0.5 3.8065 0.5 4.99997C0.5 8.49997 5 11.5 5 11.5ZM6.5 4.99997C6.5 5.8284 5.82843 6.49997 5 6.49997C4.17157 6.49997 3.5 5.8284 3.5 4.99997C3.5 4.17154 4.17157 3.49997 5 3.49997C5.82843 3.49997 6.5 4.17154 6.5 4.99997Z"
                      fill="#47505B"
                    />
                  </svg>
                </span>
                <span v-if="filtered_friend.mututal_friends !== 0"
                  >{{ filtered_friend.mututal_friends }} mutual friends</span
                >
                <span
                  class="mr-0"
                  v-if="
                    filtered_friend.mututal_friends === 0 &&
                    filtered_friend.current_city
                  "
                  >{{ filtered_friend.current_city }}, </span
                ><span
                  class="ml-0"
                  v-if="
                    filtered_friend.country.length &&
                    filtered_friend.mututal_friends === 0
                  "
                  >{{ filtered_friend.country[0].name }}</span
                >
              </p>
            </div>
            <div class="Friend_Req_btn">
              <div
                class="btn btn-accept"
                v-if="
                  filtered_friend.friend_status !== null &&
                  filtered_friend.friend_status.status === 'pending'
                "
              >
                Request Sent
              </div>
              <div v-else>
                <div
                  class="btn btn-accept"
                  @click="addFriendApi(filtered_friend)"
                >
                  Add Friend
                </div>
                <div
                  class="btn btn-delete"
                  @click="removeSuggestedFriend(filtered_friend)"
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="show-more musicbar_more-frd"
        v-if="
          $store.state.friends.total_friend_explore_count >
          $store.state.friends.explore_friend_list.length
        "
      >
        <a href="javascript:;" @click="showMore">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW MORE
          </span>
        </a>
      </div>
      <div
        class="show-more musicbar_more-frd"
        v-if="
          $store.state.friends.total_friend_suggestion_count >
          $store.state.friends.friends_suggestion.length
        "
      >
        <a href="javascript:;" @click="showMore">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW MORE
          </span>
        </a>
      </div>
      <div
        class="show-more musicbar_more-frd"
        v-if="
          $store.state.friends.total_filtered_friend_counts >
          $store.state.friends.filtered_friends_list.length
        "
      >
        <a href="javascript:;" @click="filterUserMore">
          <span class="show_more_bg">
            <svg
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.24219 0.689941V7.01994"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 3.85498H7.48702"
                stroke="#071526"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            SHOW MORE
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      filterPayload: null,
    };
  },
  methods: {
    addFriendApi(payload) {
      this.$store.dispatch("addFriendApi", payload);
    },
    showMore() {
      this.$store.dispatch("getFriendsSuggestion");
    },
    filterUserMore() {
      this.$store.dispatch("getfilteredUsers", this.filterPayload);
    },
    removeSuggestedFriend(payload) {
      this.$store.dispatch("removeSuggestedFriend", payload);
    },
  }
};
</script>
<style lang='scss' scoped>
.wr_content {
  display: flex;
  flex-wrap: wrap;
  .mr_r_0 {
    div {
      margin-right: 0px;
    }
  }
  .frind_list_box {
    margin-right: 30px;
    padding: 20px;
    width: 170px;
    flex-direction: column;
  }
  .friend-image-wrapper {
    width: 130px;
    height: 130px;
    img {
      width: 100%;
    }
  }
  .friend_profile_content {
    margin: 16px 0;
    text-align: center;
  }
  .friend_profile_name {
    color: #071526;
    opacity: 0.9;
    font-size: 14px;
  }
}
</style>
