<template>
  <div>
    <h1
      v-if="!$route.path.includes('/event-manager/page/orders/detail')"
      class="EM_Pages_title"
    >
      ORDERS
    </h1>
    <section v-if="$route.path.includes('/event-manager/page/orders/detail')" class="w-1170 wr_nav">
      <router-link class="back-link" to="/event-manager/page/dashboard">
        <div class="svg-icon">
          <svg
            width="7"
            height="14"
            viewBox="0 0 7 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.86328 12.7285L0.134281 7.00052L5.86328 1.27152"
              stroke="#071526"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="text-link">EVENT DASHBO...</div>
      </router-link>
      <div class="title_event">EVENT SETTINGS “Manila Style X Music Fest”</div>
    </section>
    <div class="w-1170 event_manager_contener wr_orders">
      <ResentOredrs :title="'Orders'" />
      <EMRightSidebar />
    </div>
  </div>
</template>    

<script>
import ResentOredrs from "../EMElement/ResentOredrs.vue";
import EMRightSidebar from "../EMElement/EMRightSidebar.vue";
export default {
  components: {
    EMRightSidebar,
    ResentOredrs,
  },
};
</script>

<style lang="scss" scoped>
</style>