<template>
  <div class="related-widget">
    <div class="widget-title">Favorites
      <div class="link-more near_arrow without-slider in-title"><a
          href="https://devmusic.theplayground.co.uk/full_view/podcast_details/related_podcast">show all
        <span class="sl-arrow"> <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo="> </span>
      </a></div>
    </div>
    <div class="widget-item">
      <div class="cover-image">
        <div class="img-wrapper"><img
            src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/music-c23.png" alt="">
        </div>
        <div class="play-button js-play-button new-play-button">
          <div class="svg-icon">
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="play-type-1">
              <path class="c-fill-1 c-line-1"
                    d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    fill="white"></path>
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
              <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="item-desc">
        <div class="item-title">Artist Name</div>
        <div class="item-subtitle">Song Name</div>
        <div class="action">
          <div class="action-item">
            <div class="svg-icon">
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                  <path d="M1 1L8.2339 5.52268L1 10.4425V1Z" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                      d="M12.3 1.8839C12.0051 1.60368 11.655 1.38139 11.2696 1.22972C10.8842 1.07806 10.4711 1 10.054 1C9.63682 1 9.22375 1.07806 8.83837 1.22972C8.45298 1.38139 8.10283 1.60368 7.80793 1.8839L7.19391 2.46707L6.5799 1.8839C5.9777 1.34963 5.18048 1.05851 4.35654 1.072C3.53259 1.08549 2.74641 1.40252 2.16395 1.95618C1.58148 2.50984 1.24833 3.25679 1.2348 4.03935C1.22128 4.82192 1.52845 5.57885 2.09147 6.15037L2.70548 6.73354L7.1976 11L11.6897 6.73354L12.3037 6.15037C12.5988 5.87027 12.8328 5.53771 12.9925 5.17169C13.1522 4.80566 13.2344 4.41334 13.2344 4.01713C13.2344 3.62093 13.1522 3.22861 12.9925 2.86258C12.8328 2.49656 12.5988 2.164 12.3037 1.8839H12.3Z"
                      stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                      d="M12.3 1.8839C12.0051 1.60368 11.655 1.38139 11.2696 1.22972C10.8842 1.07806 10.4711 1 10.054 1C9.63682 1 9.22375 1.07806 8.83837 1.22972C8.45298 1.38139 8.10283 1.60368 7.80793 1.8839L7.19391 2.46707L6.5799 1.8839C5.9777 1.34963 5.18048 1.05851 4.35654 1.072C3.53259 1.08549 2.74641 1.40252 2.16395 1.95618C1.58148 2.50984 1.24833 3.25679 1.2348 4.03935C1.22128 4.82192 1.52845 5.57885 2.09147 6.15037L2.70548 6.73354L7.1976 11L11.6897 6.73354L12.3037 6.15037C12.5988 5.87027 12.8328 5.53771 12.9925 5.17169C13.1522 4.80566 13.2344 4.41334 13.2344 4.01713C13.2344 3.62093 13.1522 3.22861 12.9925 2.86258C12.8328 2.49656 12.5988 2.164 12.3037 1.8839H12.3Z"
                      stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.23437 8L1.23438 2.99977L13.2344 2.99977L9.80482 1" stroke="#8B949F" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M13.2344 7L13.2344 11.247L1.23438 11.247L5.3605 13" stroke="#8B949F" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
        </div>
      </div>
    </div>
    <div class="widget-item">
      <div class="cover-image">
        <div class="img-wrapper"><img
            src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/music-c23.png" alt="">
        </div>
        <div class="play-button js-play-button new-play-button">
          <div class="svg-icon">
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="play-type-1">
              <path class="c-fill-1 c-line-1"
                    d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    fill="white"></path>
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
              <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="item-desc">
        <div class="item-title">Artist Name</div>
        <div class="item-subtitle">Song Name</div>
        <div class="action">
          <div class="action-item">
            <div class="svg-icon">
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                  <path d="M1 1L8.2339 5.52268L1 10.4425V1Z" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                      d="M12.3 1.8839C12.0051 1.60368 11.655 1.38139 11.2696 1.22972C10.8842 1.07806 10.4711 1 10.054 1C9.63682 1 9.22375 1.07806 8.83837 1.22972C8.45298 1.38139 8.10283 1.60368 7.80793 1.8839L7.19391 2.46707L6.5799 1.8839C5.9777 1.34963 5.18048 1.05851 4.35654 1.072C3.53259 1.08549 2.74641 1.40252 2.16395 1.95618C1.58148 2.50984 1.24833 3.25679 1.2348 4.03935C1.22128 4.82192 1.52845 5.57885 2.09147 6.15037L2.70548 6.73354L7.1976 11L11.6897 6.73354L12.3037 6.15037C12.5988 5.87027 12.8328 5.53771 12.9925 5.17169C13.1522 4.80566 13.2344 4.41334 13.2344 4.01713C13.2344 3.62093 13.1522 3.22861 12.9925 2.86258C12.8328 2.49656 12.5988 2.164 12.3037 1.8839H12.3Z"
                      stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                      d="M12.3 1.8839C12.0051 1.60368 11.655 1.38139 11.2696 1.22972C10.8842 1.07806 10.4711 1 10.054 1C9.63682 1 9.22375 1.07806 8.83837 1.22972C8.45298 1.38139 8.10283 1.60368 7.80793 1.8839L7.19391 2.46707L6.5799 1.8839C5.9777 1.34963 5.18048 1.05851 4.35654 1.072C3.53259 1.08549 2.74641 1.40252 2.16395 1.95618C1.58148 2.50984 1.24833 3.25679 1.2348 4.03935C1.22128 4.82192 1.52845 5.57885 2.09147 6.15037L2.70548 6.73354L7.1976 11L11.6897 6.73354L12.3037 6.15037C12.5988 5.87027 12.8328 5.53771 12.9925 5.17169C13.1522 4.80566 13.2344 4.41334 13.2344 4.01713C13.2344 3.62093 13.1522 3.22861 12.9925 2.86258C12.8328 2.49656 12.5988 2.164 12.3037 1.8839H12.3Z"
                      stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.23437 8L1.23438 2.99977L13.2344 2.99977L9.80482 1" stroke="#8B949F" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M13.2344 7L13.2344 11.247L1.23438 11.247L5.3605 13" stroke="#8B949F" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
        </div>
      </div>
    </div>
    <div class="widget-item">
      <div class="cover-image">
        <div class="img-wrapper"><img
            src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/music-c23.png" alt="">
        </div>
        <div class="play-button js-play-button new-play-button">
          <div class="svg-icon">
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="play-type-1">
              <path class="c-fill-1 c-line-1"
                    d="M2.48967 14.5038C2.35174 14.5004 2.21628 14.4666 2.093 14.4046C1.95246 14.322 1.83398 14.2066 1.74764 14.0683C1.66131 13.93 1.60969 13.7729 1.59717 13.6103L1.59717 2.30439C1.60458 2.14673 1.64886 1.99301 1.72644 1.85556C1.80402 1.71811 1.91275 1.60077 2.04389 1.51295C2.18183 1.43494 2.33926 1.39819 2.49748 1.40706C2.6557 1.41593 2.80804 1.47006 2.93639 1.563L11.6139 7.2155C11.7361 7.29735 11.8362 7.40803 11.9054 7.53774C11.9746 7.66746 12.0108 7.81222 12.0108 7.95925C12.0108 8.10628 11.9746 8.25104 11.9054 8.38076C11.8362 8.51047 11.7361 8.62115 11.6139 8.703L2.93639 14.3574C2.80583 14.4508 2.65018 14.5028 2.48967 14.5066"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    fill="white"></path>
              <path class="c-line-1 c-fill-1"
                    d="M2.28289 14.8861C2.13685 14.8825 1.99342 14.8467 1.86289 14.7811C1.71408 14.6936 1.58863 14.5714 1.49722 14.425C1.40581 14.2785 1.35115 14.1122 1.33789 13.9401L1.33789 1.96907C1.34574 1.80213 1.39262 1.63937 1.47477 1.49384C1.55691 1.3483 1.67203 1.22405 1.81089 1.13107C1.95694 1.04847 2.12364 1.00956 2.29116 1.01895C2.45869 1.02835 2.61999 1.08566 2.75589 1.18407L11.9439 7.16907C12.0732 7.25574 12.1792 7.37292 12.2525 7.51026C12.3258 7.64761 12.3641 7.80089 12.3641 7.95657C12.3641 8.11224 12.3258 8.26552 12.2525 8.40287C12.1792 8.54021 12.0732 8.6574 11.9439 8.74407L2.75589 14.7311C2.61765 14.83 2.45284 14.8851 2.28289 14.8891"
                    stroke="white" stroke-width="1.3"></path>
            </svg>
            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1 c-fill-1" d="M1 1V13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
              <path class="c-line-1 c-fill-1" d="M9 1V13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="item-desc">
        <div class="item-title">Artist Name</div>
        <div class="item-subtitle">Song Name</div>
        <div class="action">
          <div class="action-item">
            <div class="svg-icon">
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9">
                  <path d="M1 1L8.2339 5.52268L1 10.4425V1Z" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                      d="M12.3 1.8839C12.0051 1.60368 11.655 1.38139 11.2696 1.22972C10.8842 1.07806 10.4711 1 10.054 1C9.63682 1 9.22375 1.07806 8.83837 1.22972C8.45298 1.38139 8.10283 1.60368 7.80793 1.8839L7.19391 2.46707L6.5799 1.8839C5.9777 1.34963 5.18048 1.05851 4.35654 1.072C3.53259 1.08549 2.74641 1.40252 2.16395 1.95618C1.58148 2.50984 1.24833 3.25679 1.2348 4.03935C1.22128 4.82192 1.52845 5.57885 2.09147 6.15037L2.70548 6.73354L7.1976 11L11.6897 6.73354L12.3037 6.15037C12.5988 5.87027 12.8328 5.53771 12.9925 5.17169C13.1522 4.80566 13.2344 4.41334 13.2344 4.01713C13.2344 3.62093 13.1522 3.22861 12.9925 2.86258C12.8328 2.49656 12.5988 2.164 12.3037 1.8839H12.3Z"
                      stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                      d="M12.3 1.8839C12.0051 1.60368 11.655 1.38139 11.2696 1.22972C10.8842 1.07806 10.4711 1 10.054 1C9.63682 1 9.22375 1.07806 8.83837 1.22972C8.45298 1.38139 8.10283 1.60368 7.80793 1.8839L7.19391 2.46707L6.5799 1.8839C5.9777 1.34963 5.18048 1.05851 4.35654 1.072C3.53259 1.08549 2.74641 1.40252 2.16395 1.95618C1.58148 2.50984 1.24833 3.25679 1.2348 4.03935C1.22128 4.82192 1.52845 5.57885 2.09147 6.15037L2.70548 6.73354L7.1976 11L11.6897 6.73354L12.3037 6.15037C12.5988 5.87027 12.8328 5.53771 12.9925 5.17169C13.1522 4.80566 13.2344 4.41334 13.2344 4.01713C13.2344 3.62093 13.1522 3.22861 12.9925 2.86258C12.8328 2.49656 12.5988 2.164 12.3037 1.8839H12.3Z"
                      stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
          <div class="action-item">
            <div class="svg-icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.23437 8L1.23438 2.99977L13.2344 2.99977L9.80482 1" stroke="#8B949F" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M13.2344 7L13.2344 11.247L1.23438 11.247L5.3605 13" stroke="#8B949F" stroke-width="1.5"
                      stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
            000
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Favorites"
}
</script>

<style scoped>

</style>
