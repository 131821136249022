<template>
  <div class="main-music-tab-content m-t-20">
    <div class="row">
      <div class="col">
        <div class="placeholder_nav_music" v-if="allFavoritePodcasts && allFavoritePodcasts.length < 1" >
          <MyMusicEmptyCard
            @redirectFunction="redirectFunction"
            :title="'You haven’t favorited any Music, Playlists, Podcasts or Artists yet'"
            :text="'Go to Explore music and start favoriting music, Podcasts and Artists that you like'"
            :icon="'heart'"
            :buttonText="'EXPLORE MUSIC'"
          />
        </div>
        <div  v-else >
          <favorite-podcasts-listing @getFavoritePodcasts="getFavoritePodcasts" :listData="allFavoritePodcasts" :totalFavoritePodcasts="totalFavoritePodcasts"/>
        </div>
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script type="application/javascript">

import {getFavoriteSongsOfUsers} from "../../../../../apis/APIs";
import favoritePodcastsListing from "@/components/Home/Profile/favorites/podcasts/favoritePodcastsListing.vue";
import MyMusicEmptyCard from "@/components/Common/UiComponenets/MyMusicEmptyCard.vue";


export default {
  name: "favoritePodcasts",
  data () {
    return {
      loading: false,
      allFavoritePodcasts: [],
      totalFavoritePodcasts: '',
      dataLoaded: false,
    };
  },
  components: {
    MyMusicEmptyCard,
    favoritePodcastsListing
  },
  mounted() {
    this.getFavoritePodcasts();
  },
  methods: {
    async getFavoritePodcasts() {
      try {
        this.loading = true
        let data = await getFavoriteSongsOfUsers('podcast');
        this.allFavoritePodcasts =  data.data.information.data;
        this.totalFavoritePodcasts =  data.data.information.total;
        this.loading = false
        this.dataLoaded = true;
      } catch (error) {
        this.loading = false;
      }
    },
    redirectFunction(){
      this.$router.push('/music/page/overview')
    },
  }
}
</script>

<style scoped>

</style>
