<template>
  <div class="main-music-tab-content m-t-20">
    <div class="row">
      <div class="col">
        <div class="placeholder_nav_music" >
          <MyMusicEmptyCard
            @redirectFunction="redirectFunction"
            :title="'You haven’t favorited any Music, Playlists, Podcasts or Artists yet'"
            :text="'Go to Explore music and start favoriting music, Podcasts and Artists that you like '"
            :icon="'heart'"
            :buttonText="'EXPLORE MUSIC'"
          />
        </div>
        <!-- <div v-else >
          <favorite-artists-listing @getFavoriteArtists="getFavoriteArtists"/>
        </div> -->
      </div>
    </div>
    <full-page-loader :show="loading"/>
  </div>
</template>

<script type="application/javascript">
// import favoriteArtistsListing from "@/components/Home/Profile/favorites/artists/favoriteArtistsListing.vue";
import {getFavoriteSongsOfUsers} from "../../../../../apis/APIs";
import MyMusicEmptyCard from "@/components/Common/UiComponenets/MyMusicEmptyCard.vue";

export default {
  name: "favoriteSamples",
  data () {
    return {
      loading: false,
      dataLoaded: false,
    };
  },
  components: {
    MyMusicEmptyCard,
    // favoriteArtistsListing
  },
  // computed: {
  //   listDataLength() {
  //     return this.$store.getters.getFavouritePages.pages.length
  //   }
  // },
  // mounted() {
  //   this.getFavoriteArtists();
  // },
  // methods: {
  //   getFavoriteArtists(showMore=false) {
  //     this.$store.dispatch('loading', true)
  //     this.$store.dispatch('getLikedPages',showMore)
  //     this.$store.dispatch('loading', false)
  //   },
  // }
  methods:{
    redirectFunction(){
      this.$router.push('/music/page/overview')
    },
  }
}
</script>

<style scoped>

</style>
