<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <Header/>
        <router-view/>
        <!-- <MusicFooter v-if="ShowMusicFooter"/> -->
        <MusicFooter/>
      </div>
    </div>

    <div class="dock-wrapper"></div>
  </div>
</template>
<script>
import Header from "@/components/Common/Header"
import MusicFooter from "@/components/Common/MusicFooter"

export default {
  data() {
    return {
      rightBar: false
    }
  },

  components: {
    Header,
    MusicFooter
  },
  props: {
    ShowMusicFooter: Boolean,
    ShowLeftNav: Boolean
  },
  methods: {
    toggleRightBar(val) {
      this.rightBar = val
    }
  }
}

</script>
