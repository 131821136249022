<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <Header/>
        <router-view></router-view>
        <MusicFooter v-if="ShowMusicFooter"/>
      </div>
    </div>

    <div class="dock-wrapper"></div>
  </div>
</template>
<script>
import Header from "@/components/Common/Header"
import MusicFooter from "@/components/Common/MusicFooter"

export default {
  data() {
    return {}
  },

  components: {
    Header,
    MusicFooter
  },
  props: {
    ShowMusicFooter: Boolean,
    ShowLeftNav: Boolean
  },
  methods: {
    checkUrl: function (comp) {

    }
  }
}

</script>
