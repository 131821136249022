<template>

  <div>
    <ol>
      <li class="pointerClass"><h5><a v-on:click="redirect('/')"> Landing </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/settings')"> Settings </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/news-feed')"> News Feeds </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/profile')"> profile </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/people')"> friends </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/explore-pages')"> Page </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/forum')"> forum </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/article')"> article </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/wishlist')"> wishlist </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/collection')"> collection </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/home/page/events')"> events </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/group/page/discussion')"> discussion </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/group/page/group-admins')"> group admins </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/group/page/member-request')"> member-request </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/group/page/member-general-settings')">
        member-general-settings </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/group/page/group-information')"> group-information </a>
      </h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/artist-profile')"> artist-profile </a>
      </h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/new-page')"> new-page </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/new-page-specification')">
        new-page-specification </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/123')"> first-upload </a></h5>
      </li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/page-information')">
        page-information </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/general-settings')">
        general-settings </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/sponsorship')"> sponsorship </a></h5>
      </li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/events')"> events </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/jobs')"> jobs </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/verification')"> verification </a></h5>
      </li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/admins')"> admins </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/engagement')"> engagement </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/book-artist')"> book-artist </a></h5>
      </li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/company')"> company </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/sponsorship-member')">
        sponsorship-member </a></h5></li>
      <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/partner')"> partner </a></h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/waveform" target="_blank">
        Waveform </a></h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/all-list" target="_blank">
        all-list </a></h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/all-grid" target="_blank">
        all-grid </a></h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/page-elements" target="_blank">
        page-elements </a></h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/chart-list" target="_blank">
        chart-list </a></h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/reviews" target="_blank"> reviews </a>
      </h5></li>
      <li class="pointerClass"><h5><a href="https://socialnetwork.iapplabz.co.in/filters" target="_blank"> filters </a>
      </h5></li>
      <!-- <li class="pointerClass"><h5><a v-on:click="redirect('/page-manager/page/related-album-list')"> related-album-list </a></h5></li> -->
    </ol>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  methods: {
    redirect: function (url) {
      this.$router.push(url);
    }
  }
}
</script>
