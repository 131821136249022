<template>
  <div class="modal d-block custom-modal modalpayment">
    <div class="modals Signup_pop_overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Event Ticket Contract</h5>
          <button
            @click="closeModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <div class="svg-icon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L4.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.5 4.5L13.5 13.5"
                  stroke="#47505B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
        <perfect-scrollbar>
          <div class="wr_contract_info">
            <p>
              THIS LICENSE AGREEMENT is made on
              <b>Mon, 13 Jul 2020 09:40:14 -0400</b>
              ("Effective Date") by and between Licensee (hereinafter referred
              to as the "Licensee") also, if applicable, professionally known as
              Licensee, and Producer Name ("Songwriter"). (hereinafter referred
              to as the "Licensor"). Licensor warrants that it controls the
              mechanical rights in and to the copyrighted musical work entitled
              The Track Title (Contract Preview Only) ("Composition") as of and
              prior to the date first written above. The Composition, including
              the music thereof, was composed by Producer Name ("Songwriter")
              managed under the Licensor.
            </p>
            <p><b>All licenses are non-refundable and non-transferable.</b></p>
            <p>
              <b>Master Use.</b> The Licensor hereby grants to Licensee a
              non-exclusive license (this "License) to record vocal
              synchronization to the Composition partly or in its entirety and
              substantially in its original form ("Master Recording").
            </p>
            <p>
              <b>Mechanical Rights.</b> The Licensor hereby grants to Licensee a
              non-exclusive license to use Master Recording in the reproduction,
              duplication, manufacture, and distribution of phonograph records,
              cassette tapes, compact disk, digital downloads, other
              miscellaneous audio and digital recordings, and any lifts and
              versions thereof (collectively, the "Recordings", and
              individually, a "Recordings") worldwide for up to the pressing or
              selling a total of Two Thousand, Five Hundred (2500) copies of
              such Recordings or any combination of such Recordings, condition
              upon the payment to the Licensor a sum of Thirty-four Point
              Ninety-five dollars ($34.95), receipt of which is confirmed.
              Additionally licensee shall be permitted to distribute Unlimited
              free internet downloads or streams for non-profit and
              non-commercial use. This license allows up to Two Hundred And
              Fifty Thousand (250000) monetized audio streams to sites like
              (Spotify, RDIO, Rhapsody) but not eligible for monetization on
              YouTube. Performance Rights. The Licensor here by grants to
              Licensee a non-exclusive license to use the Master Recording in
              Unlimited non-profit performances, shows, or concerts. Licensee
              may receive compensation from performances with this license.
            </p>
          </div>
        </perfect-scrollbar>
        <div class="btns">
          <div>
            <button class="download">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.66797 6.6665L8.0013 9.99984L11.3346 6.6665"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 10V2"
                  stroke="#D1433A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              DOWNLOAD
            </button>
            <button class="print">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_152031)">
                  <path
                    d="M4 6.00016V1.3335H12V6.00016"
                    stroke="#D1433A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.9987 12H2.66536C2.31174 12 1.9726 11.8595 1.72256 11.6095C1.47251 11.3594 1.33203 11.0203 1.33203 10.6667V7.33333C1.33203 6.97971 1.47251 6.64057 1.72256 6.39052C1.9726 6.14048 2.31174 6 2.66536 6H13.332C13.6857 6 14.0248 6.14048 14.2748 6.39052C14.5249 6.64057 14.6654 6.97971 14.6654 7.33333V10.6667C14.6654 11.0203 14.5249 11.3594 14.2748 11.6095C14.0248 11.8595 13.6857 12 13.332 12H11.9987"
                    stroke="#D1433A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 9.3335H4V14.6668H12V9.3335Z"
                    stroke="#D1433A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_152031">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              PRINT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("openCloseContractModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  max-width: 970px !important;
}
.ps {
  height: 340px;
}
.wr_contract_info {
  margin: 15px 0;
  width: 900px;
  overflow: hidden;
  p {
    font-family: HelveticaNeueNormal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #47505b;
    letter-spacing: 0.7px;
  }
}
.btns {
  box-shadow: -10px -5px 36px 10px white;
  z-index: 1;
  div {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e6eaf0;
    padding-top: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 30px;
      background: #ffffff;
      border: 2px solid #D1433A;
      border-radius: 3px;
      color: #D1433A;
      svg {
        margin-right: 10px;
      }
      &:hover {
        background: #D1433A;
        color: #ffffff;
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
}
</style>