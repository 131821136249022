<template>
  <div class="Signup_pop_overlay">
    <div class="wr_delete_modal">
      <div class="item-title">{{title}}</div>
      <div class="text">
        Are you sure you want to
        <b
          >delete<br />
          {{description}}?</b
        >
      </div>
      <div class="wr_btn">
        <div @click="closeModal('close')" class="cancel">CANCEL</div>
        <div @click="deleteThis(id)" class="delete">DELETE</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "description",'id'],
  methods: {
    closeModal(do_it) {
      this.$emit('openCloseRemoveModal',do_it);
    },
    deleteThis(id) {
        this.$emit('delete_this',id)
    },
  },
};
</script>

<style lang='scss' scoped>
.Signup_pop_overlay {
  font-family: HelveticaNeueNormal;
  .wr_delete_modal {
    background: #ffffff;
    width: 370px;
    padding: 22px 20px 29px;
    border-radius: 2px;
    .item-title {
      font-family: HelveticaNeueBold;
      color: #071526;
      padding-bottom: 17px;
      border-bottom: 1px solid #e6eaf0;
      font-weight: 700;
      font-size: 18px;
    }
    .text {
      text-align: center;
      font-weight: 400;
      margin: 25px 0;
      font-size: 15px;
      line-height: 20px;
      b {
        font-family: HelveticaNeueBold;
      }
    }
    .wr_btn {
      display: flex;
      justify-content: space-between;
      .delete,
      .cancel {
        width: 160px;
        height: 40px;
        font-family: HelveticaNeueBold;
        font-weight: 700;
        font-size: 13px;
        color: #ffffff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .cancel {
        color: #8b949f;
        border: 1.3px solid #8b949f;
      }
      .delete {
        background: #ff0039;
      }
    }
  }
}
</style>