<template>
  <div class="widget contact-widget">
    <div class="form-group">
      <label for="exampleFormControlSelect1">Your Contact Info</label>
      <div
        @click.stop="seeYourContact = !seeYourContact"
        class="wr_select_contact_info"
        :class="{
          active_select: seeYourContact,
          required: your_contact_required,
        }"
      >
        <div>{{ your_contact.first_name }} {{ your_contact.last_name }}</div>
        <div class="div_icon" :class="{ arrow: seeYourContact }">
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 5L9 1"
              stroke="#8B949F"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div v-if="seeYourContact" class="select_contact_info">
          <perfect-scrollbar>
            <div
              v-for="item in GET_MY_CONTACT_INFO"
              :key="item.id"
              class="item_contact_info"
              @click.stop="change_your_contact(item)"
            >
              {{ item.first_name }} {{ item.last_name }}
              <div class="icons">
                <div class="div_edit_icon" @click.stop="editContactInfo(item)">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.91797 1.74985C10.0712 1.59664 10.2531 1.47511 10.4532 1.39219C10.6534 1.30928 10.868 1.2666 11.0846 1.2666C11.3013 1.2666 11.5159 1.30928 11.716 1.39219C11.9162 1.47511 12.0981 1.59664 12.2513 1.74985C12.4045 1.90306 12.526 2.08494 12.609 2.28512C12.6919 2.4853 12.7346 2.69985 12.7346 2.91652C12.7346 3.13319 12.6919 3.34774 12.609 3.54791C12.526 3.74809 12.4045 3.92998 12.2513 4.08318L4.3763 11.9582L1.16797 12.8332L2.04297 9.62485L9.91797 1.74985Z"
                      stroke="#47505B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div @click.stop="removeContact(item.id)">
                  <svg
                    data-v-28b6e6d2=""
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-28b6e6d2=""
                      d="M9.66992 3.60352L3.66992 9.60352"
                      stroke="#FF0039"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="c-line-1"
                    ></path>
                    <path
                      data-v-28b6e6d2=""
                      d="M3.66992 3.60352L9.66992 9.60352"
                      stroke="#FF0039"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="c-line-1"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <button
            @click="
              editContactInfo({
                first_name: '',
                last_name: '',
                birthdate: '',
                country: '',
                zip: '',
                phone: '',
                is_default: false,
              })
            "
          >
            ADD NEW CONTACT INFO
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.0013 12.8332C10.223 12.8332 12.8346 10.2215 12.8346 6.99984C12.8346 3.77818 10.223 1.1665 7.0013 1.1665C3.77964 1.1665 1.16797 3.77818 1.16797 6.99984C1.16797 10.2215 3.77964 12.8332 7.0013 12.8332Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 4.6665V9.33317"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.66797 7H9.33464"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Your E-mail</label>
      <input
        v-model="email"
        type="email"
        class="form-control"
        :class="{ required: email_required }"
        placeholder="Your e-mail to get your online items"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
      />
    </div>
    <button @click.stop="confirm_checkout" class="btn btn-confirm">
      <div class="svg-icon">
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.18616 13C1.56629 13 1.09575 12.4419 1.20055 11.8309L2.91605 1.83092C2.9984 1.35089 3.41461 1 3.90165 1H12.0984C12.5854 1 13.0016 1.35085 13.084 1.83084L14.8003 11.8308C14.9052 12.4418 14.4346 13 13.8147 13H2.18616Z"
            stroke="white"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.97656 3.97363V5.35087C9.90421 5.85296 9.65398 6.30532 9.27843 6.61293C8.90288 6.92055 8.43123 7.05948 7.96256 7.00054C7.49389 7.05948 7.02225 6.92055 6.6467 6.61293C6.27115 6.30532 6.02092 5.85296 5.94856 5.35087V3.97363"
            stroke="white"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span>CONFIRM & CHECKOUT</span>
    </button>
    <RemoveModal
      v-if="showRemoveModals === 'open'"
      :title="'Delete Contact'"
      :description="'this contact'"
      :id="id_contact"
      @openCloseRemoveModal="openCloseRemoveModal"
      @delete_this="delete_this"
    />
  </div>
</template>

<script>
import RemoveModal from "@/components/Common/Elements/RemoveModal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    RemoveModal,
  },
  props: ["events_Ticket_Total_Amoun", "isChecked"],
  data() {
    return {
      email: "",
      email_required: false,
      seeYourContact: false,
      your_contact: "",
      your_contact_required: false,
      showRemoveModals: "close",
      id_contact: "",
    };
  },
  methods: {
    ...mapActions(["getMyContactInfo", "removeContactInfo", "placeOrder"]),
    ...mapMutations(["set_comtact_info"]),
    change_your_contact(contact) {
      this.your_contact = contact;
      this.seeYourContact = !this.seeYourContact;
      this.your_contact_required = false;
    },
    editContactInfo(payload) {
      this.set_comtact_info({ ...payload });
      this.$parent.openCloseModals({ modal: "contactInfo" });
    },
    removeContact(idContact) {
      this.id_contact = idContact;
      this.openCloseRemoveModal("open");
    },
    openCloseRemoveModal(do_it) {
      this.showRemoveModals = do_it;
    },
    ValidateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.email_required = false;
        return true;
      }
      this.email_required = true;
      return false;
    },
    async delete_this(id) {
      await this.$store.dispatch("loading", true);
      await this.removeContactInfo(id);
      await this.$store.dispatch("loading", false);
      this.openCloseRemoveModal("close");
    },
    async confirm_checkout() {
      let total_grose = this.events_Ticket_Total_Amoun.reduce((total, el) => {
        return total + el.total_amoun;
      }, 0);
      if (!this.your_contact) {
        this.your_contact_required = true;
      }

      let data_for_place_order = {
        cart_id: this.GET_MY_CART_ITEMS.map((el) => el.id),
        contact_info_id: this.your_contact.id,
        gross_total: total_grose,
        discount: this.GET_PROMO.information
          ? this.GET_PROMO.information.price
          : 0,
        total:
          total_grose -
          (this.GET_PROMO.information ? this.GET_PROMO.information.price : 0),
        currency: "$",
        email: this.email,
        agree: 1,
        promocode_id: this.GET_PROMO.information
          ? this.GET_PROMO.information.id
          : null,
      };
      if (
        this.isChecked &&
        this.ValidateEmail(this.email) &&
        this.your_contact
      ) {
        await this.$store.dispatch("loading", true);
        await this.placeOrder(data_for_place_order);
        await this.$store.dispatch("loading", false);
        this.$router.push('/events/page/my/orders')
      }
    },
  },
  computed: {
    ...mapGetters(["GET_MY_CONTACT_INFO", "GET_PROMO", "GET_MY_CART_ITEMS"]),
  },
  async created() {
    await this.getMyContactInfo();
  },
};
</script>

<style lang="scss" scoped>
.wr_select_contact_info {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  border: 1px solid #c4ccd6;
  border-radius: 4px;
  padding: 0 20px;
  height: 40px;
  transition: all 0.3s;
  svg {
    transition: all 0.3s;
  }
  .arrow {
    svg {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }
  .select_contact_info {
    position: absolute;
    background: #ffffff;
    border: 1px solid #c4ccd6;
    border-radius: 4px;
    width: 100.7%;
    padding: 20px 10px 20px 20px;
    top: 41px;
    left: -1px;
    .ps {
      height: 82px;
    }
    .item_contact_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f3f3f3;
      border-radius: 4px;
      margin-bottom: 10px;
      height: 30px;
      padding: 0 10px;
      margin-right: 10px;
      cursor: pointer;
    }
    button {
      background: #D1433A;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 30px;
      border: none;
      outline: none;
      color: #fff;
      width: 96.6%;
      margin-right: 10px !important;
      &:hover {
        background: #DA6961 !important;
      }
    }
  }
  .icons {
    display: flex;
    width: 35px;
    justify-content: space-between;
  }
}
.active_select {
  border-color: #D1433A !important;
}
.required {
  border-color: red !important;
}
</style>