<template>
  <div class="container_xxl page-music">
    <div class="feed_wrapper">
      <div class="center_centerbar_wraper_home">
        <div class="row" style="margin-top: 24px;">
          <div class="col">
            <div class="page-filter d-flex d-flex flex-row align-items-center p-r-20">
              <div class="back-link">
                <a class="d-flex flex-row align-items-center" @click="goBack">
                  <div class="svg-icon">
                    <svg
                        width="7"
                        height="14"
                        viewBox="0 0 7 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                          stroke="#071526"
                          stroke-width="1.3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="c-line-1"
                      ></path>
                    </svg>
                  </div>
                  <div v-if="this.$route.params.name" style="margin-top: 2px; cursor: pointer;">
                    {{
                      (this.$route.params.name === 'timeline') ? this.$route.params.name.toUpperCase() : this.$route.params.name.toUpperCase()
                    }}
                  </div>
                  <div v-else style="margin-top: 2px; cursor: pointer;">
                    Create
                  </div>
                </a>
              </div>
              <div class="filter-title">ADD NEW MUSIC</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-card  body-class="text-center" >
              <b-card-text class="card-title">
                What type of audio do you want to launch?
              </b-card-text>
              <b-card-text class="card-desc">
                Please choose type of your product <br> before uploading
              </b-card-text>
              <div class="row">
                <div class="col-3" v-for="(product,index) in productList" :key="index">
                  <ProductTileCard :product="product"/>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductTileCard from '../Components/Music/ProductTile';
export default {
  name: "index.vue",
  components:{
    ProductTileCard
  },
  data(){
    return{
    }
  },
  computed: {
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    pageId(){
      return (this.currentPage)? this.currentPage.id :null
    },
    productList(){
      return[
        {
          title:'Songs & Albums',
          description:'Upload your song or album here',
          icon:require(`@/assets/icons/page-manager/page/music/product-tile/music.svg`),
          buttonText:'New Song & Album',
          url:'/market-manager/page/create-product-song-album/'+this.pageId
        },
        {
          title:'Sample & Beats',
          description:'Upload your sample or beats here',
          icon:require(`@/assets/icons/page-manager/page/music/product-tile/sampleBeats.svg`),
          buttonText:'New Sample & Beats',
          url:''
        },
        {
          title:'Podcasts',
          description:'Upload your podcast or podcast series',
          icon:require(`@/assets/icons/page-manager/page/music/product-tile/podcast.svg`),
          buttonText:'New Podcast',
          url:'/market-manager/page/create-product-podcast/'+this.pageId
        },
        {
          title:'NFT’s',
          description:'Add NFT item to your Shop',
          icon:require(`@/assets/icons/page-manager/page/music/product-tile/nft.svg`),
          buttonText:'New NFT Item',
          url:''
        }
      ]
    }
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="scss">
.page-music{
  .card-title{
    font-family: HelveticaNeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  .card-desc{
    font-family: HelveticaNeueNormal;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
}

</style>
