<template>
  <div
    :key="key_this_component"
    class="overview-content"
    :class="{ dontScroll: createEventsModal }"
  >
    <div class="wrapper">
      <div class="row">
        <div class="col px-0">
          <div class="overview-mains">
            <h1 class="Page_title">{{ title_event_type }}</h1>
            <Search
              @changeFilterKey="changeFilterKey"
              @explorEvents="explorEvents"
            />
          </div>
          <div class="overview-genres">
            <Genres @explorEvents="explorEvents" />
          </div>
          <div class="overview-Venue">
            <VenueContainer @explorEvents="explorEvents" />
          </div>
          <div class="overview-featured">
            <Featured
              v-if="
                GET_ALL_EVENTS.featured &&
                GET_ALL_EVENTS.featured.data.length > 0
              "
              :events="GET_ALL_EVENTS.featured"
              :key="keyFeatured"
              :eventsType="`${
                title_event_type === 'EXPLORE EVENTS'
                  ? 'Featured'
                  : 'Featured' + ' ' + title_event_type
              }`"
              eventsPath="featured-events"
            />
          </div>
          <div class="overview-events">
            <div class="overview-events-left">
              <EventsContainer
                v-if="
                  GET_ALL_EVENTS.popular &&
                  GET_ALL_EVENTS.popular.data.length > 0
                "
                :key="keyPopular"
                :events="GET_ALL_EVENTS.popular"
                class="mt-0"
                :eventsType="`${
                  title_event_type === 'EXPLORE EVENTS'
                    ? 'Popular Events'
                    : 'Popular' + ' ' + title_event_type
                }`"
                eventsPath="popular-events"
              />
              <EventsContainer
                v-if="
                  GET_ALL_EVENTS.thisweek &&
                  GET_ALL_EVENTS.thisweek.data.length > 0
                "
                :key="keyThisweek"
                :events="GET_ALL_EVENTS.thisweek"
                :eventsType="`${
                  title_event_type === 'EXPLORE EVENTS'
                    ? 'This Week'
                    : 'This Week' + ' ' + title_event_type
                }`"
                eventsPath="this-week"
              />
              <EventsContainer
                v-if="
                  GET_ALL_EVENTS.online &&
                  GET_ALL_EVENTS.online.data.length > 0 &&
                  GET_EVENTS_SEARCH_FOR.filterStatus !== 'offline'
                "
                :key="keyOnline"
                :events="GET_ALL_EVENTS.online"
                :eventsType="`${
                  title_event_type === 'EXPLORE EVENTS'
                    ? 'Online Events'
                    : 'Online' + ' ' + title_event_type
                }`"
                eventsPath="online-events"
              />
              <EventsContainer
                v-if="
                  GET_ALL_EVENTS.festivals &&
                  GET_ALL_EVENTS.festivals.data.length > 0
                "
                :key="keyFestivals"
                :events="GET_ALL_EVENTS.festivals"
                :eventsType="`${
                  title_event_type === 'EXPLORE EVENTS'
                    ? 'Festival Events'
                    : 'Festival' + ' ' + title_event_type
                }`"
                eventsPath="festival-events"
              />
              <EventsContainer
                v-if="
                  GET_ALL_EVENTS.latest && GET_ALL_EVENTS.latest.data.length > 0
                "
                :key="keyLatest"
                :events="GET_ALL_EVENTS.latest"
                :eventsType="`${
                  title_event_type === 'EXPLORE EVENTS'
                    ? 'Latest Events'
                    : 'Latest' + ' ' + title_event_type
                }`"
                eventsPath="Latest-events"
              />
            </div>
            <div class="overview-events-filter">
              <div class="create-event-btn">
                <button @click="createEventsModal = true">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.49928 0C5.83111 0 6.10012 0.269006 6.10012 0.600841V10.3992C6.10012 10.731 5.83111 11 5.49928 11C5.16744 11 4.89844 10.731 4.89844 10.3992V0.60084C4.89844 0.269005 5.16744 0 5.49928 0Z"
                      fill="#D1433A"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 5.50025C0 5.16842 0.269006 4.89941 0.600841 4.89941H10.3992C10.731 4.89941 11 5.16842 11 5.50025V5.50025C11 5.83209 10.731 6.10109 10.3992 6.10109H0.60084C0.269005 6.10109 0 5.83209 0 5.50025V5.50025Z"
                      fill="#D1433A"
                    />
                  </svg>

                  ADD New EVENT
                </button>
              </div>
              <FilterEvents
                :key="filtrKey"
                @changeFilterKey="changeFilterKey"
                @explorEvents="explorEvents"
              />
              <news-feed />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="createEventsModal" class="create-events-wrapper">
      <event-post-container @closeEventModal="createEventsModal = false" />
    </div>
    <!-- @dontCloseModal="createEventsModel = true" -->
  </div>
</template>

<script>
import Search from "@/components/Events/components/Search.vue";
import Genres from "../components/Genres.vue";
import VenueContainer from "../components/Venue/VenueContainer.vue";
import Featured from "../components/Featured/Featured.vue";
import EventsContainer from "../components/EventsContainer.vue";
import FilterEvents from "../components/Filter.vue";
import NewsFeed from "../components/NewsFeed.vue";
import { mapActions, mapGetters } from "vuex";
import EventPostContainer from "../components/CreateEvents/EventPostContainer.vue";

export default {
  components: {
    Search,
    Genres,
    VenueContainer,
    Featured,
    EventsContainer,
    FilterEvents,
    NewsFeed,
    EventPostContainer,
  },
  data() {
    return {
      key_this_component: 1,
      title_event_type: "EXPLORE EVENTS",
      filtrKey: 0,
      createEventsModal: false,
      keyLatest: Math.random(),
      keyFeatured: Math.random(),
      keyPopular: Math.random(),
      keyThisweek: Math.random(),
      keyOnline: Math.random(),
      keyFestivals: Math.random(),
    };
  },
  methods: {
    ...mapActions(["getAllEvents"]),
    async explorEvents(params) {
      try {
        await this.$store.dispatch("loading", true);
        await this.getAllEvents(params);
        this.change_title_event_type(this.$route.meta.event_Type_id);
        this.changeKeys();
        console.log("GET_ALL_EVENTS", this.GET_ALL_EVENTS);
        await this.$store.dispatch("loading", false);
      } catch (error) {
        console.error(error);
      }
    },
    changeKeys() {
      this.keyLatest = Math.random();
      this.keyFeatured = Math.random();
      this.keyPopular = Math.random();
      this.keyThisweek = Math.random();
      this.keyOnline = Math.random();
      this.keyFestivals = Math.random();
    },
    change_title_event_type(event_type_id) {
      if (event_type_id === 1) {
        this.title_event_type = "Gigs";
      } else if (event_type_id === 2) {
        this.title_event_type = "Clubs";
      } else if (event_type_id === 3) {
        this.title_event_type = "Festivals";
      } else if (event_type_id === 4) {
        this.title_event_type = "Livestreaming";
      } else {
        this.title_event_type = "EXPLORE EVENTS";
      }
    },
    changeFilterKey() {
      this.filtrKey += 1;
    },
  },

  async created() {
    this.explorEvents({
      event_type: this.$route.meta.event_Type_id,
    });
  },
  watch: {
    "$route.path"() {
      ++this.key_this_component;
      this.explorEvents({
        event_type: this.$route.meta.event_Type_id,
      });
    },
  },
  computed: {
    ...mapGetters([
      "GET_ALL_EVENTS",
      "GET_EVENTS_SEARCH_FOR",
      "GET_EVENT_TYPES",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/style/events/overview.scss";
.overview-mains {
  h1 {
    text-transform: uppercase;
  }
}
.overview-content {
  .row {
    width: 1170px;
    max-width: 100%;
  }
}
.dontScroll {
  height: calc(100vh - 174px);
  overflow: hidden;
}

.overview-Venue {
  margin-top: 22px;
  margin-right: -30px;
}
.overview-featured {
  margin-top: 42px;
  margin-bottom: 30px;
}
.overview-events {
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  &-left {
    width: 770px;
    max-width: 100%;
  }
}
</style>
