<template>
  <div class="modal fade modal-add-to-cart contact_info create-new-ticket job-invite show" id="job-invite" tabindex="-1"
       role="dialog" aria-labelledby="exampleModalLabel" style="display: block; padding-right: 15px;" aria-modal="true">
    <div class="modal-dialog">
      <div class="modal-content justify-content-start">
        <div class="close-button" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="item-title">
          <div class="short-border">
            Invite <span>Jane Cooper</span>
          </div>
        </div>
        <ul class="nav nav-justified nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation"><a class="nav-link active" id="pills-home-tab" data-toggle="pill"
                                                      href="#pills-home" role="tab" aria-controls="pills-home"
                                                      aria-selected="true">Interview</a></li>
          <li class="nav-item" role="presentation"><a class="nav-link" id="pills-profile-tab" data-toggle="pill"
                                                      href="#pills-profile" role="tab" aria-controls="pills-profile"
                                                      aria-selected="false">Job</a></li>
        </ul>
        <div class="tab-content" id="pills-tabC1ontent">
          <div class="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="form-group d-flex flex-row justify-content-between">
              <div class="date-input-wrapper">
                <div class="for-calendar-input f-s">
                  <div class="input-title">End Date</div>
                  <div class="flatpickr-wrapper">
                    <input readonly="readonly" style="width: 150px !important;" placeholder="DD/MM/YYYY"
                           class="filters-select js-datepicker-day form-control datepicker-week custom-caljs flatpickr-input"
                           type="text">
                    <div class="flatpickr-calendar animate static arrowBottom" tabindex="-1">
                      <div class="flatpickr-months">
                                    <span class="flatpickr-prev-month">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="29"
                                            viewBox="0 0 29 29">
                                          <defs>
                                             <path id="vb2fa"
                                                   d="M1087.5 7294c8.008 0 14.5 6.492 14.5 14.5s-6.492 14.5-14.5 14.5-14.5-6.492-14.5-14.5 6.492-14.5 14.5-14.5z"></path>
                                             <path id="vb2fc" d="M1088.5 7304.5l-3.603 3.833 3.603 3.757"></path>
                                             <clipPath id="vb2fb">
                                                <use fill="#fff" xlink:href="#vb2fa"></use>
                                             </clipPath>
                                          </defs>
                                          <g id="svg-prevMonth">
                                             <g transform="translate(-1073 -7294)">
                                                <use class="ccls-1" fill="#fff" fill-opacity="0" stroke="#e1e4e8"
                                                     stroke-miterlimit="50" stroke-width="2"
                                                     clip-path="url(&quot;#vb2fb&quot;)" xlink:href="#vb2fa"></use>
                                             </g>
                                             <g transform="translate(-1073 -7294)">
                                                <use class="ccls-2" fill="#fff" fill-opacity="0" stroke="#616375"
                                                     stroke-linecap="round" stroke-linejoin="round"
                                                     stroke-miterlimit="50" stroke-width="1.5"
                                                     xlink:href="#vb2fc"></use>
                                             </g>
                                          </g>
                                       </svg>
                                    </span>
                        <div class="flatpickr-month">
                          <div class="flatpickr-current-month">
                            <span class="cur-month">January </span>
                            <div class="numInputWrapper"><input class="numInput cur-year" type="number" tabindex="-1"
                                                                aria-label="Year"><span class="arrowUp"></span><span
                                class="arrowDown"></span></div>
                          </div>
                        </div>
                        <span class="flatpickr-next-month">
                                       <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" width="29" height="29"
                                            viewBox="0 0 29 29">
                                          <defs>
                                             <path id="vlooa"
                                                   d="M1274.5 7294c8.008 0 14.5 6.492 14.5 14.5s-6.492 14.5-14.5 14.5-14.5-6.492-14.5-14.5 6.492-14.5 14.5-14.5z"></path>
                                             <path id="vlooc" d="M1273.5 7312.09l3.603-3.833-3.603-3.757"></path>
                                             <clipPath id="vloob">
                                                <use fill="#fff" xlink:href="#vlooa"></use>
                                             </clipPath>
                                          </defs>
                                          <g id="svg-nextArrow">
                                             <g transform="translate(-1260 -7294)">
                                                <use class="ccls-1" fill="#fff" fill-opacity="0" stroke="#e1e4e8"
                                                     stroke-miterlimit="50" stroke-width="2"
                                                     clip-path="url(&quot;#vloob&quot;)" xlink:href="#vlooa"></use>
                                             </g>
                                             <g transform="translate(-1260 -7294)">
                                                <use class="ccls-2" fill="#fff" fill-opacity="0" stroke="#616375"
                                                     stroke-linecap="round" stroke-linejoin="round"
                                                     stroke-miterlimit="50" stroke-width="1.5"
                                                     xlink:href="#vlooc"></use>
                                             </g>
                                          </g>
                                       </svg>
                                    </span>
                      </div>
                      <div class="flatpickr-innerContainer">
                        <div class="flatpickr-rContainer">
                          <div class="flatpickr-weekdays">
                            <div class="flatpickr-weekdaycontainer">
                                             <span class="flatpickr-weekday">
                                             Mon</span><span class="flatpickr-weekday">Tue</span><span
                                class="flatpickr-weekday">Wed</span><span class="flatpickr-weekday">Thu</span><span
                                class="flatpickr-weekday">Fri</span><span class="flatpickr-weekday">Sat</span><span
                                class="flatpickr-weekday">Sun
                                             </span>
                            </div>
                          </div>
                          <div class="flatpickr-days" tabindex="-1">
                            <div class="dayContainer"><span class="flatpickr-day prevMonthDay"
                                                            aria-label="December 27, 2021" tabindex="-1">27</span><span
                                class="flatpickr-day prevMonthDay" aria-label="December 28, 2021"
                                tabindex="-1">28</span><span class="flatpickr-day prevMonthDay"
                                                             aria-label="December 29, 2021" tabindex="-1">29</span><span
                                class="flatpickr-day prevMonthDay" aria-label="December 30, 2021"
                                tabindex="-1">30</span><span class="flatpickr-day prevMonthDay"
                                                             aria-label="December 31, 2021" tabindex="-1">31</span><span
                                class="flatpickr-day " aria-label="January 1, 2022" tabindex="-1">1</span><span
                                class="flatpickr-day " aria-label="January 2, 2022" tabindex="-1">2</span><span
                                class="flatpickr-day " aria-label="January 3, 2022" tabindex="-1">3</span><span
                                class="flatpickr-day " aria-label="January 4, 2022" tabindex="-1">4</span><span
                                class="flatpickr-day " aria-label="January 5, 2022" tabindex="-1">5</span><span
                                class="flatpickr-day " aria-label="January 6, 2022" tabindex="-1">6</span><span
                                class="flatpickr-day " aria-label="January 7, 2022" tabindex="-1">7</span><span
                                class="flatpickr-day " aria-label="January 8, 2022" tabindex="-1">8</span><span
                                class="flatpickr-day " aria-label="January 9, 2022" tabindex="-1">9</span><span
                                class="flatpickr-day " aria-label="January 10, 2022" tabindex="-1">10</span><span
                                class="flatpickr-day " aria-label="January 11, 2022" tabindex="-1">11</span><span
                                class="flatpickr-day " aria-label="January 12, 2022" tabindex="-1">12</span><span
                                class="flatpickr-day " aria-label="January 13, 2022" tabindex="-1">13</span><span
                                class="flatpickr-day " aria-label="January 14, 2022" tabindex="-1">14</span><span
                                class="flatpickr-day " aria-label="January 15, 2022" tabindex="-1">15</span><span
                                class="flatpickr-day " aria-label="January 16, 2022" tabindex="-1">16</span><span
                                class="flatpickr-day " aria-label="January 17, 2022" tabindex="-1">17</span><span
                                class="flatpickr-day " aria-label="January 18, 2022" tabindex="-1">18</span><span
                                class="flatpickr-day " aria-label="January 19, 2022" tabindex="-1">19</span><span
                                class="flatpickr-day " aria-label="January 20, 2022" tabindex="-1">20</span><span
                                class="flatpickr-day " aria-label="January 21, 2022" tabindex="-1">21</span><span
                                class="flatpickr-day " aria-label="January 22, 2022" tabindex="-1">22</span><span
                                class="flatpickr-day " aria-label="January 23, 2022" tabindex="-1">23</span><span
                                class="flatpickr-day " aria-label="January 24, 2022" tabindex="-1">24</span><span
                                class="flatpickr-day " aria-label="January 25, 2022" tabindex="-1">25</span><span
                                class="flatpickr-day " aria-label="January 26, 2022" tabindex="-1">26</span><span
                                class="flatpickr-day " aria-label="January 27, 2022" tabindex="-1">27</span><span
                                class="flatpickr-day " aria-label="January 28, 2022" tabindex="-1">28</span><span
                                class="flatpickr-day " aria-label="January 29, 2022" tabindex="-1">29</span><span
                                class="flatpickr-day " aria-label="January 30, 2022" tabindex="-1">30</span><span
                                class="flatpickr-day " aria-label="January 31, 2022" tabindex="-1">31</span><span
                                class="flatpickr-day nextMonthDay" aria-label="February 1, 2022"
                                tabindex="-1">1</span><span class="flatpickr-day nextMonthDay"
                                                            aria-label="February 2, 2022" tabindex="-1">2</span><span
                                class="flatpickr-day nextMonthDay" aria-label="February 3, 2022"
                                tabindex="-1">3</span><span class="flatpickr-day nextMonthDay"
                                                            aria-label="February 4, 2022" tabindex="-1">4</span><span
                                class="flatpickr-day nextMonthDay" aria-label="February 5, 2022"
                                tabindex="-1">5</span><span class="flatpickr-day nextMonthDay"
                                                            aria-label="February 6, 2022" tabindex="-1">6</span></div>
                          </div>
                        </div>
                      </div>
                      <div class="flatpickr-checks">
                        <div class="btn btn-calendar js-submit-date dropdown-item" title="toggle" data-toggle="">Done
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="svg-icon for-date">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path class="c-line-1"
                            d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                            stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path class="c-line-1" d="M8 1V3" stroke="#D1433A" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                      <path class="c-line-1" d="M4 1V3" stroke="#D1433A" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                      <path class="c-line-1" d="M1.5 5H10.5" stroke="#D1433A" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="time-input-wrapper">
                <div class="input-title">End Time</div>
                <input type="text" class="time-input" style="width: 150px !important;" placeholder="HH:MM"
                       maxlength="8">
              </div>
            </div>
            <div class="form-group">
              <div class="input-title">Your Message</div>
              <textarea name="aaa" class="custom-textarea" id="" cols="30" rows="10"></textarea>
            </div>
            <div class="action-item">
              <div class="btn back-btn">Cancel</div>
              <div class="btn check-btn">Send</div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="form-group">
              <div class="input-title">Your Message</div>
              <textarea name="aaa" class="custom-textarea" id="" cols="30" rows="10"></textarea>
            </div>
            <div class="action-item">
              <div class="btn back-btn">Cancel</div>
              <div class="btn check-btn">Send</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {}
  },
  components: {},
  computed: {},
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    }
  }
}
</script>
